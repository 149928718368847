import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {connect} from "react-redux";
import {Button, message, Spin, Input, Modal} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {
    vCoinString,
    createTwitterLink,
    createTwitterHtml,
    getProfile,
    isSafari,
    isMobile2,
} from "@/utils/common";
import {
    momentTwitterShare,
    createClipTwitterHtml,
} from "@/utils/momentClub";
import {TwitterShareButton} from 'react-twitter-embed';
import {tweetCard, tweetSite} from "@/utils/env";
import Canvas from 'src/common/CanvasMix';
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";
import {linkPath} from "@/routes/config";

const ModalShareMomentClip = (
    {
        closeHandle,
        clubBasicInfo,
        choosedItem,
        canTGE = true,

        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const imageRef = useRef(null);
    const navigate = useNavigate();

    const {
        selfData, 
        platformData
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const {
        displayName
    } = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData]);

    const {
        clubUserId,
        clubInfo,
    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])

    const {
        clubName
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    const {
        cardNo, 
        cardAmount, 
        cardUrl, 
        holdCard, 
        expectedPrice,
        openCoin,
    } = useMemo(() => {
        return choosedItem || {}
    }, [choosedItem])

    const clipTwitterHtml = useMemo(() => {
        return createClipTwitterHtml({creator: displayName, clubName: clubName, cardNo: cardNo});
    }, [])

    const downloadName = useMemo(() => {
        return `CLIP #${cardNo}.webp`;
    }, [cardNo])

    const officialLink = `https://app.turnup.xyz`;

    const [isTweetLinkInit, setIsTweetLinkInit] = useState(false);
    const [tweetUrl, setTweetUrl] = useState('');

    const twitterText = useMemo(() => {
        let momentInfo = `$${clubName.replace(' ', '')} Moment #${cardNo}`;
        let firstLine = `I just snagged this epic #Moment on @TURNUPDOTXYZ!`;
        let secondLine = `It’s now part of my collection, and you can grab yours too`;
        let lastLine = `Ready to #TURNUP and own moments with potential? #OwnTheMoment`;
        return `${firstLine}\n${momentInfo}\n\n${secondLine}\nOfficial Link: ${officialLink}\n\n${lastLine}`
    }, [clubName, cardNo])

    useEffect(() => {
        if (choosedItem && platformData?.platformMap?.[1]){
            momentTwitterShare({
                clubUserId: clubUserId, 
                cardNo: cardNo, 
                htmlTemplate: clipTwitterHtml
            }).then(res => {
                if (res.linkUrl !== undefined && res.linkUrl !== null && res.linkUrl !== '') {
                    setTweetUrl(res.linkUrl);
                    setIsTweetLinkInit(true);
                }
            }).catch(e => {
                console.log(e);
            });
        }
    }, [choosedItem, platformData?.platformMap])

    const downloadImage = () => {
        const link = document.createElement('a');
        let parseUrl = cardUrl;
        if (parseUrl && !parseUrl.startsWith('https')){
            parseUrl = `https${parseUrl.substring(4)}`;
        }
        fetch(parseUrl).then(res => res.blob()).then((blob) => {
            let url = URL.createObjectURL(blob);
            link.href = url;
            link.download = downloadName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }).catch(e => {
            console.error("fetch error", e, cardUrl);
        });
    }

    const share = async (title, text, blob) => {
        const data = {
            files: [
                new File([blob], 'clip.webp', {
                    type: 'image/webp',
                }),
            ],
            title: title,
            text: text,
        };
        try {
            if (!(navigator.canShare(data))) {
                throw new Error("Can't share data.", data);
            }
            await navigator.share(data);
        } catch (err) {
            console.error(err.name, err.message);
        }
    };

    const safariDownload = async () => {
        let parseUrl = cardUrl;
        if (parseUrl && !parseUrl.startsWith('https')){
            parseUrl = `https${parseUrl.substring(4)}`;
        }
        fetch(parseUrl).then(res => res.blob()).then(async(blob) => {
            share("momentClip", "you can save or share", blob);
        }).catch(e => {
            console.error("fetch error", e, cardUrl);
        });
    }

    return (
        <div className={s.wrap}>
            <div className={`${s.cardName} momentFont`}>
                {`${t('Moment')} #${cardNo}`}
            </div>
            {/*<div className={`${s.uniqueRoot} flex_center_center momentFont`}>
                <CustomIcon imgName={'Picture/UI_Picture_Icon_Crown_01'} className="mr4" width={14} height={14}/>
                {t('Unique')}
            </div>*/}
            <div className={`${s.imagePart} flex_center_center`}>
                <img ref={imageRef} className={`${s.imageRoot}`} src={cardUrl}></img>
            </div>
            {
                canTGE &&
                <div className={`${s.tokenLine} flex_center_center`}>
                    <div className={`momentFont fs12 fw-400 color-moment-gray`}>
                        {t('Est.airdrop')}
                    </div>
                    {/*<CustomIcon 
                        className={`ml5 mr3`} 
                        width={17} 
                        height={17} 
                        imgName={`Picture/UI_Picture-Currency_ETH_02`}/>*/}
                    <div className={`momentFont fs16 fb color-EEEEEE ml5 mr5`}>
                        {Number(openCoin || 0).toLocaleString("en-US")}
                    </div>
                    <div className={`momentFont fs12 fw-400 color-moment-gray`}>
                        {`$${clubName}`}
                    </div>
                </div>
            }
            <div className={`${s.buttonList}`}>
                {
                    (isTweetLinkInit && platformData?.platformMap?.[1]) &&
                    <div className={`${s.tweetShareBtn}`}>
                        <TwitterShareButton
                            onLoad={() => {
                            }}
                            options={{
                                size: 'large',
                                title: "Moment Share",
                                text: twitterText
                            }}
                            url={tweetUrl}/>
                    </div>
                }
                {
                    !platformData?.platformMap?.[1] && 
                    <AntButton shape="round" className={`${s.selfBtn}`} onClick={() => {
                        navigate(`${linkPath}/${2}`);
                    }}>
                        <div className={`momentFont color-white fs13 fw-400 lh1`}>
                            {t('Link X')}
                        </div>
                    </AntButton>
                }
                &nbsp;
                {
                    !isMobile2() &&
                    <AntButton shape="round" className={`${s.selfBtn}`} onClick={() => {
                        downloadImage();
                    }}>
                        <div className={`momentFont color-white fs13 fw-400 lh1`}>
                            {t('Download')}
                        </div>
                    </AntButton>
                }
                {
                    (navigator.canShare != null && isMobile2()) &&
                    <AntButton shape="round" className={`${s.selfBtn}`} onClick={() => {
                        safariDownload();
                    }}>
                        <div className={`momentFont color-white fs13 fw-400 lh1`}>
                            {t('Share')}
                        </div>
                    </AntButton>
                }
                &nbsp;
            </div>
            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeHandle();
                }} />
        </div>
    )
}
const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalShareMomentClip));
