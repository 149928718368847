import {memo, useMemo, useRef, useEffect} from "react";
import {createPortal} from "react-dom";
import s from "./index.module.less";
import {useNavigate} from "react-router-dom";
import {Button} from "antd";
import CustomIcon from "src/common/CustomIcon";
import TaskConf from "@/utils/json/TaskConf.json";
import {useTranslation, Trans} from 'react-i18next';
import {displayNewbieTaskStartId} from "@/utils/env";
import {gameReferralPath} from "@/routes/config";
import AntButton from "@/common/AntButton";

const ModelHowToEarn = ({setShowHowToEarn, tasks}) => {
    const {t, i18n} = useTranslation();
    const taskListRef = useRef(null);
    const navigate = useNavigate();
    const newbieTaskList = useMemo(() => {
        let list = [];
        let tempTaskId = displayNewbieTaskStartId;
        while (tempTaskId !== 0) {
            let tempTask = TaskConf.find((v) => v.id === tempTaskId);
            if (tempTask) {
                list.push(tempTask);
                tempTaskId = tempTask.nextTaskID;
            } else {
                tempTaskId = 0;
            }
        }
        list.push({id: -1, bgImagePath: "/img/howtoearn/-1.webp"});
        return list;
    }, []);
    const {oneShotTasks, finishTaskIds} = tasks;
    const currentTaskList = useMemo(() => {
        let tempTaskList = [];
        if (oneShotTasks && oneShotTasks.length > 0 && newbieTaskList && newbieTaskList.length > 0) {
            for (let i = 0; i < newbieTaskList.length; i++) {
                let tempTask = oneShotTasks.find((v) => v.taskId === newbieTaskList[i].id);
                if (tempTask) {
                    tempTaskList.push(tempTask);
                }
            }
        }
        return tempTaskList;
    }, []);
    useEffect(() => {
        goToCurrent();
    }, []);

    const isCurrentTask = (taskId) => {
        let res = false;
        if (currentTaskList && currentTaskList.length > 0) {
            for (let i = 0; i < currentTaskList.length; i++) {
                if (currentTaskList[i].taskId === taskId) {
                    res = true;
                    break;
                }
            }
        }
        return res;
    }

    const getCurrenTask = (taskId) => {
        let res = null;
        if (currentTaskList && currentTaskList.length > 0) {
            for (let i = 0; i < currentTaskList.length; i++) {
                if (currentTaskList[i].taskId === taskId) {
                    res = currentTaskList[i];
                    break;
                }
            }
        }
        return res;
    }

    const status = (taskId) => {
        if (finishTaskIds?.includes(taskId)) {
            return "finished";
        } else if (isCurrentTask(taskId) || (taskId === -1 && currentTaskList.length === 0)) {
            return "current";
        } else {
            return "undo";
        }
    };
    const isDone = (v) => {
        return v === -1 && currentTaskList.length === 0;
    };
    const goToCurrent = () => {
        if (currentTaskList.length === 0) {
            let index = newbieTaskList.length;
            let posY = 135 * (Math.max(index - 1, 0)) + 30 * (Math.max(index - 2, 0));
            taskListRef.current.scrollTo(0, posY);
        } else if (taskListRef && taskListRef.current && newbieTaskList?.length > 1) {
            let focusId = currentTaskList[0].taskId;
            let index = 0;
            while (index < newbieTaskList.length && newbieTaskList[index].id !== focusId) {
                index++;
            }
            let posY = 135 * (Math.max(index - 1, 0)) + 30 * (Math.max(index - 2, 0));
            taskListRef.current.scrollTo(0, posY);
        }
    }

    const getImgUrl = (url) => {
        let res = url;
        if (res) {
            res = res.trim();
            if (!res.startsWith("/")) {
                res = "/" + res;
            }
        }
        return res;
    }

    return createPortal(
        <div className={s.ModelHowToEarn}>
            <div className={s.wrap} ref={taskListRef}>
                {newbieTaskList.map((v, idx) => (
                    <div className="pr" key={idx}>
                        <div
                            className={`${s.item} ${s[status(v.id)]}`}
                            style={{
                                backgroundImage: `url(${getImgUrl(v.bgImagePath)})`,
                            }}
                        >
                            {status(v.id) === "undo" && (
                                <div className={s.lock}>
                                    <CustomIcon
                                        width={32}
                                        height={32}
                                        imgName={"icon_clock"}
                                        name={"icon_clock"}
                                    />
                                </div>
                            )}
                            {status(v.id) !== "current" && <div className={s.mask}></div>}
                            <div className={`${s.banner} `}>
                                {finishTaskIds.includes(v.id) && (
                                    <CustomIcon
                                        width={20}
                                        height={20}
                                        imgName={"icon_complete"}
                                        name={"icon_complete"}
                                    />
                                )}
                                {v.id === -1 ? (
                                        <div
                                            className={`df aic ${
                                                finishTaskIds.includes(v.id) && "color-gray"
                                            }`}
                                        >
                                            {t('REFER FRIENDS TO BOOST YOUR $LFG')}
                                            <CustomIcon
                                                width={14}
                                                height={14}
                                                imgName={"Picture/UI_Picture-Currency_LFG_01"}
                                                name={"Picture/UI_Picture-Currency_LFG_01"}
                                                className="ml5"
                                            />
                                        </div>
                                    ) :
                                    (
                                        <div
                                            className={`df aic white-space-pre-wrap ${
                                                finishTaskIds.includes(v.id) && "color-gray"
                                            }`}
                                        >
                                            {
                                                v.descriptionType === 0 ?
                                                    <Trans
                                                        i18nKey={`TaskConf_${v.id}_description`}
                                                        defaults="xxx"
                                                        components={{color: <span className={s.taskColor}></span>}}
                                                    /> :
                                                    <Trans
                                                        i18nKey={`TaskConf_${v.id}_description`}
                                                        defaults="xxx"
                                                        values={{times: `(${isCurrentTask(v.id) ? (getCurrenTask(v.id)?.count || 0) : finishTaskIds.includes(v.id) ? v.param : 0}/${v.param})`}}
                                                        components={{color: <span className={s.taskColor}></span>}}
                                                    />
                                            }
                                            <span>{` +${v.rewardNum / 10000} $LFG`}</span>
                                            <CustomIcon
                                                width={14}
                                                height={14}
                                                imgName={"Picture/UI_Picture-Currency_LFG_01"}
                                                name={"Picture/UI_Picture-Currency_LFG_01"}
                                                className="ml5"
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {isDone(v.id) && (
                            <AntButton
                                className={`${s.btnInvite} btn_public`}
                                onClick={() => navigate(gameReferralPath)}
                                shape="round"
                            >
                                {t('INVITE MORE!')}
                            </AntButton>
                        )}
                    </div>
                ))}
            </div>
            <AntButton
                className={`${s.btnClose} btn_public btn_dark mtb20 df aic jcc`}
                onClick={() => setShowHowToEarn(false)}
            >
                {t('CLOSE')}
            </AntButton>
        </div>,
        document.body
    );
};

export default memo(ModelHowToEarn);
