import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import { connect } from "react-redux";

import { ApolloClient, InMemoryCache, gql, useQuery } from "@apollo/client";
import { ethers } from "ethers";
import { convertWalletBalance } from "@/utils/common";

const client = new ApolloClient({
    // uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
    uri: 'https://gateway-arbitrum.network.thegraph.com/api/705436c10cf99f44778c38f848143387/deployments/id/QmZetW4DWc8PM8YPN59Y7DfCKxdZWKP1wH4PfmvJsnR4MA',
    cache: new InMemoryCache(),
});
const timeFn = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString()
}
const GET_TOKEN_TRANSACTIONS = gql`
query GetTopHolders($tokenAddress: String!) {
    tokenHolders(first: 10, where: { token: $tokenAddress }, orderBy: balance, orderDirection: desc) {
      id
      balance
    }
  }
`;

const List = (
    {
        tokenAddress = "0x8bff1bD27E2789Fe390AcabC379C380a83B68E84"
    }
) => {
    const { t, i18n } = useTranslation();
    const [list, setList] = useState([])

    const { loading, error, data } = useQuery(GET_TOKEN_TRANSACTIONS, {
        variables: { tokenAddress },
        client,
    });
    console.log(data);
    // const pools = [...(data?.pools0 || []), ...(data?.pools1 || [])];
    // console.log(loading, error, data);\
    // const [trades, setTrades] = useState([]);

    // useEffect(()=>{
    //      const fetchTrades = async () => {
    //         const tradesData = await getUniswapTrades('0x252410eb4aa254fa53681b5c00ead14faa86c9d6');
    //         console.log(tradesData);
    //         setTrades(tradesData);
    //       };
    //       try {
    //         fetchTrades();
    //       } catch (error) {
    //         console.error(error);
    //       }

    // },[])

    return (
        <div className={`${s.box}`}>
            {/* {loading &&
                <div>Loading</div>
            }
            {error &&
                <div>Error: {error.message}</div>
            } */}
            <div className={s.list}>
                {data?.swaps?.map(trade => {
                    const {id,balance} = trade
                    return (
                        <div className={s.item} key={id}>
                            <div className={s.line}><div>id</div> {id}</div>
                            <div className={s.line}><div>balance</div> {convertWalletBalance(ethers.utils.formatUnits(balance))}</div>
                        </div>
                    )
                })}
            </div>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(List));
