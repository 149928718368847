import React, {ReactNode} from "react";
import TabPane from "antd/es/tabs/TabPane";
import {AutoStrangeRule} from "@/utils/strangeRule";
import {TabPaneProps} from "antd";

interface Props extends TabPaneProps {
    children?: ReactNode;
    tab?: ReactNode;
    id?: number;
}


function AntTabPane({children, id, tab, ...rest}: Props): React.Component {
    return (
        <TabPane tab={AutoStrangeRule(tab)} {...rest}>{children}</TabPane>
    );
}

export default AntTabPane;
