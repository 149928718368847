import React, {memo, useEffect, useState, useMemo} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../CustomIcon";
import {connect} from "react-redux";
import {Button, Modal} from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
import {errorCode} from '@/utils/env'
import {bannerData} from "@/utils/taskJson";
import Banner from "../Banner";
import {
    getBindTweetUrl, getTweetChallenge, getTweetOuth1aUrl, getTwitterOauthtoken, siteName, getProfile,
    getAvatarFromProfile
} from "@/utils/common"
import ModelLinkTiktok from "../../model/ModelLinkTiktok"
import {useLocation, useNavigate} from "react-router-dom";
import AvatarInfo from "../AvatarInfo";
import {mainHomePath} from "@/routes/config";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";

const result = localStorage.getItem('_TT_bindRsult')
const filterErrorCode = (code) => {
    for (const item of errorCode) {
        if (item.id == code) {
            return item?.tips;
            break
        }
    }
    return ''
}
const LINKDATA = [
    {
        label: 'X (TWITTER)',
        icon: 'Button/UI_Button_Ball-White_Twitter_On',
        available: true,
    }, {
        label: 'TIKTOK',
        icon: 'iconTikTok',
        available: false,
    }, {
        label: 'INSTAGRAM',
        icon: 'iconIns',
        available: false,
    }, {
        label: 'SNAPCHAT',
        icon: 'iconSnapchatIcon',
        available: false,
    },
]
const WishLink = (
    {
        userInfo,
        setShowLink,
        tweetMaticBindResult,
    }
) => {
    const {t, i18n} = useTranslation();
    // const [linkUrl, setLinkUrl] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [stateCodeLoading, setStateCodeLoading] = useState(true);

    const [errorTips, setErrorTips] = useState('');
    const [openLinkTiktokModel, setOpenLinkTiktokModel] = useState(false);
    const [points, setPoints] = useState(bannerData?.link?.points);

    const [tiktokCodeObj, setTiktokCodeObj] = useState({});
    const [tiktokCodeLoading, setTiktokCodeLoading] = useState(false)


    useEffect(() => {
        if (result !== '' && result !== 0) {
            // message.error({content:'bind Tweet failed!'})
            setErrorTips(filterErrorCode(result))
            localStorage.removeItem('_TT_bindRsult')
        }
        if (tweetMaticBindResult) {
            setErrorTips(filterErrorCode(tweetMaticBindResult))
        }
    }, [result, tweetMaticBindResult])

    const linkTweetAuth1a = () => {
        setLoading(true)
        localStorage.setItem('_TT_showWishLinkNeed', true);
        getTwitterOauthtoken().then(res => {
            // console.log(herf);
            window.location.href = getTweetOuth1aUrl(res?.oauth_token);
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            console.log(e);
        })
    }

    const linkTiktok = () => {
        setLoading(true)
        getTweetChallenge().then(res => {
            // console.log(getBindTweetUrl(res?.challenge));
            const herf = getBindTweetUrl(res?.challenge);
            console.log(herf);
            window.location.href = herf;

            setLoading(false);
        }).catch(e => {
            setLoading(false);
            console.log(e);
        })
    }

    const location = useLocation();
    const colsePage = () => {
        localStorage.setItem('_TT_showLink', false);
        if (location.key !== "default") {
            navigate(-1);
        } else {
            navigate(mainHomePath, {replace: true});
        }
    }

    const openLinkPanel = (label, available) => {
        if (!available) return
        // setOpenLinkType(true)

        switch (label) {
            case 'X (TWITTER)':
                linkTweetAuth1a();
                break;
            case 'TIKTOK':
                setOpenLinkTiktokModel(true)
                break;

            default:
                break;
        }
    }

    const profile = useMemo(() => {
        return getProfile(userInfo?.platformData) || {}
    }, [userInfo])

    return (
        <>
            <div className={`${s.modalWrap} abs`}>
                <div className={s.modal}>
                    <div className={s.header}>
                        <div className="fs20 mt10 fb">{t('Bring Friends to TURNUP')}</div>
                        <div className="fs14 mt5">{t('link your socials')}</div>
                    </div>
                    <div className={s.list}>
                        {LINKDATA?.map((item, index) => {
                            const {label, icon, available} = item
                            return (
                                <div className={`${s.item} ${s['item' + index]}`} key={index}>
                                    <ButtonFactory
                                        color={available ? 1 : 3}
                                        onClick={() => openLinkPanel(label, available)}
                                        disabled={!available}
                                        size={21}
                                    >{available ? t('LINK') : t('COMING SOON')}</ButtonFactory>
                                </div>
                            )
                        })}

                    </div>

                </div>
                <div className={s.bottom}>
                    {errorTips && <div className="fs15 color-red">
                        {t('Link X failed wish')}
                    </div>}
                    <AntButton shape="round" onClick={colsePage}
                               className={`${s.btnSkip} btn_transparent mt20`}>{t('I don\'t want to claim a Wish account')}</AntButton>
                    <div
                        className="fs12 color-gray  mt20">{t('Only accounts that have not yet purchased the first key can claim Wishes')}</div>
                </div>
            </div>
            <AntModal
                width='90%'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={openLinkTiktokModel}
                onOk={() => setOpenLinkTiktokModel(false)}
                onCancel={() => setOpenLinkTiktokModel(false)}
            >
                <ModelLinkTiktok tiktokCodeObj={tiktokCodeObj} loading={tiktokCodeLoading}/>
            </AntModal>
        </>

    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        initTopUpAmount: app.initTopUpAmount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        setShowLink: (params) => {
            return dispatch({
                type: "app/setShowLink",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(WishLink));
