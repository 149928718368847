import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { Slider, Switch, Radio, Input } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { AutoStrangeRule } from "@/utils/strangeRule";
import { getActiveList, getChainInfo } from "@/utils/memeClub";
import AvatarInfo from "@/common/AvatarInfo";
import { convertWalletBalance, getAvatarFromProfile, sliceStr, stringToNumber } from "@/utils/common";
import AntButton from "@/common/AntButton";
import ButtonFactory from "@/common/ButtonFactory";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
const Activity = (
    {
        clubId,
        chainInfo
    }
) => {
    const { t, i18n } = useTranslation();
    const [list, setList] = useState([])
    const [nextCursor,setNextCursor] = useState(-1)
    const getList = (clubUserId,nextCursor=0) => {
        getActiveList(clubUserId,nextCursor).then(res => {
            console.log(res);
            setList(res?.txlist || [])
            setNextCursor(res?.nextCursor)
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        clubId && getList(clubId,0)
    }, [clubId])

    const getMoreList = ()=>{
        getActiveList(clubId,nextCursor)
    }

    const { coinName , displayCoinName} = useMemo(() => {
        return chainInfo || {}
    }, [chainInfo])
    const timeFn = (time) => {
        let d = parseInt(time / 60 / 60 / 24);
        let h = parseInt(time / 60 / 60 % 24);
        let m = parseInt(time / 60 % 60);
        let str = '';
        if (d > 0) {
            str = d + "d";
            return str
        }
        if (h > 0) {
            str = h + "h";
            return str
        }
        if (m > 0) {
            str = m + "m";
            return str
        }
        return t('few seconds');

    }
    const toTranD = (userId, accountType) => {
        // youOwnKeyNum ? setShowOtherTransContent(false) : setShowOtherTransContent(true)
        // if (userId == urlUserId?.userId) {
        //     return false
        // }
        // joinChat(userId, navigate, accountType, true);
    }

    return (
        <div className={`${s.box}`}>
            <div className={s.list}>
                <InfiniteScroll
                    dataLength={list?.length || 0}
                    next={getMoreList}
                    hasMore={nextCursor !== -1}
                    loader={<div
                        style={{
                            textAlign: "center",
                            width: "100%",
                        }}
                    ><Spin /></div>}
                    height={"100%"}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0",
                        width: "100%"
                    }}

                >
                    {
                        list?.map((item, ind) => {
                            const {
                                amount,
                                time,
                                txType,
                                totalPrice,
                                userId,
                                keyId,
                                clubProfile,
                                tradeProfile
                            } = item;
                            const { accType: clubAccType, } = clubProfile;
                            const { accType: tradeAccType } = tradeProfile;
                            const getTextColorByCoin = (coinName) => {
                                switch (coinName) {
                                    case '$MATIC':
                                        return 'color-purple';
                                    case '$LFG':
                                        return 'color-lfg';
                                    case 'ETH':
                                        return 'color-blue-light';
                                    default:
                                        return 'color-blue-light';
                                }
                            };

                            return (
                                <div className={s.item} key={ind}>
                                    <div className={s.tranAvatar}>
                                        <div className={s.userAccType} onClick={() => {
                                            toTranD(userId, tradeAccType)
                                        }}>
                                            <AvatarInfo size={45} src={getAvatarFromProfile(tradeProfile)} />
                                        </div>
                                        {/* <div className={s.onwerAvatar} onClick={() => {
                                            toTranD(keyId, clubAccType)
                                        }}>
                                            <AvatarInfo size={45} src={getAvatarFromProfile(clubProfile)} />
                                        </div> */}
                                    </div>
                                    <div className={s.tranInfo}>
                                        <div className={`${s.info} fs14 `}>
                                            {
                                                txType === 1 ?
                                                    <Trans
                                                        i18nKey="xx bought aa yy sth"
                                                        defaults="<user>{{name1}}</user> <gray>bought</gray> <fb>{{count}}</fb> <fbClick>{{name2}}</fbClick> <gray>{{sth}}<gray>"
                                                        values={{
                                                            name1: sliceStr(tradeProfile.displayName, 'all'),
                                                            name2: sliceStr(clubProfile.displayName, 'all'),
                                                            count: amount,
                                                            sth: "Pack"
                                                        }}
                                                        components={{
                                                            user: <span className="color-yellow"
                                                                onClick={() => {
                                                                    toTranD(userId, tradeAccType)
                                                                }}></span>,
                                                            gray: <span className=""></span>,
                                                            fb: <span className=""></span>,
                                                            fbClick: <span className="color-yellow"
                                                                onClick={() => {
                                                                    toTranD(keyId, clubAccType)
                                                                }}></span>
                                                        }}
                                                    /> :
                                                    <Trans
                                                        i18nKey="xx sold aa yy sth"
                                                        defaults="<user>{{name1}}</user> <gray>sold</gray> <fb>{{count}}</fb> <fbClick>{{name2}}</fbClick> <gray>{{sth}}<gray>"
                                                        values={{
                                                            name1: sliceStr(tradeProfile.displayName, 'all'),
                                                            name2: sliceStr(clubProfile.displayName, 'all'),
                                                            count: amount,
                                                            sth: "Pack"
                                                        }}
                                                        components={{
                                                            user: <span className="color-yellow"
                                                                onClick={() => {
                                                                    toTranD(userId, tradeAccType)
                                                                }}></span>,
                                                            gray: <span className=""></span>,
                                                            fb: <span className=""></span>,
                                                            fbClick: <span className="color-yellow"
                                                                onClick={() => {
                                                                    toTranD(keyId, clubAccType)
                                                                }}></span>
                                                        }}
                                                    />
                                            }
                                        </div>
                                        <div className={s.price}>
                                            <div className={`${getTextColorByCoin(displayCoinName)} fs12`}>
                                                {stringToNumber(totalPrice, 4)} {displayCoinName}
                                            </div>
                                            ,&nbsp; <span
                                                className="fs12">{t('xx ago', { time: timeFn(time) })}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </InfiniteScroll>

            </div>

        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Activity));
