import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import s from "./index.module.less"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { momentClubInfo } from "@/utils/momentClub";
import { gameMomentClubDetailPath, gameMomentClubExplorePath } from "@/routes/config";
const QuickLinkMoments = ({
  setCurrentMomentClubId
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();
  const { clubId, clubName } = useParams() || {};
  const [clubBasicInfo, setClubBasicInfo] = useState(null)
  const getMemeClubInfo = (clubId) => {
    setLoading(true)
    momentClubInfo(clubId).then(res => {
      setClubBasicInfo(res)
    }).catch(e => {
      navigate(gameMomentClubExplorePath)
      console.error(e);
    })
  }
  useEffect(() => {
    if (clubId) {
      getMemeClubInfo(clubId)

    } else {
      navigate(gameMomentClubExplorePath)
    }
  }, [clubId])


  useEffect(()=>{
    if(clubBasicInfo){
      console.log(`quickLink clubId----> `,clubId,` is inMarket----->`,clubBasicInfo?.inMarket);
      setCurrentMomentClubId(clubId)
      navigate(`${gameMomentClubDetailPath}`,{replace:true,state: {backPath:gameMomentClubExplorePath}});
    }
  },[clubBasicInfo])
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 90,
      }}
      spin
    />
  );
  return (
    <div className={s.main}>
      <Spin
        className={s.spin}
        indicator={antIcon}
        spinning={loading}
      />

      <div className="fs30">{t('Check Params...')}</div>
    </div>

  )
}
const mapStateToProps = ({ app }) => {
  return {
    userInfo: app.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentMomentClubId: (params) => {
      return dispatch({
        type: "app/setCurrentMomentClubId",
        payload: params,
      });
    },
  }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(QuickLinkMoments));