import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../common/CustomIcon";
import {numberClamp} from "@/utils/common";

const BidAuctionRules = (
    {
        closeHandle
    }
) => {
    const {t, i18n} = useTranslation();

    const [pageIndex, setPageIndex] = useState(0);

    return (
        <div className={s.wrap}>
            <div className={s.pageControl}>
                <div className={s.prev} onClick={() => {
                    setPageIndex(numberClamp(pageIndex - 1, 0, 2));
                }}>
                    {pageIndex > 0 && <CustomIcon
                        width={14}
                        height={28}
                        imgName={"Button/UI_Button_Arrow_Left_01"}
                    ></CustomIcon>}
                </div>
                <div className={s.next} onClick={() => {
                    setPageIndex(numberClamp(pageIndex + 1, 0, 2));
                }}>
                    {pageIndex < 2 && <CustomIcon
                        width={14}
                        height={28}
                        imgName={"Button/UI_Button_Arrow_Right_01"}
                    ></CustomIcon>}
                </div>
            </div>
            <div className={s.topPart}>
                <div className={s.title}>
                    {
                        pageIndex === 2 ?
                            t('Winning the Auction') :
                            pageIndex === 1 ?
                                t('Placing Bids') :
                                t('Auction Details')
                    }
                </div>
                <div className={s.content}>
                    {
                        pageIndex === 2 ?
                            <>
                                <div className={s.contentText}>
                                    {t('If no new bids are placed in 8 hours, the highest bidder wins!')}
                                </div>
                                <div className={`${s.imgRoot} mt25`}>
                                    <CustomIcon width={285.3} height={111.6}
                                                imgName={'BG/UI_Background-S_BidTipPage_03'}
                                                imgType = {'.webp'}
                                                />
                                </div>
                                <div className={`${s.contentText} mt25`}>
                                    {t('The winner needs to claim the NFT won.')}
                                </div>
                            </> :
                            pageIndex === 1 ?
                                <>
                                    <div className={s.contentText}>
                                        {t('The bid amount is automatically set as per the previous bid.')}
                                    </div>
                                    <div className={`${s.imgRoot} mt25`}>
                                        <CustomIcon width={285.3} height={111.6}
                                                    imgName={'BG/UI_Background-S_BidTipPage_02'}
                                                    imgType = {'.webp'}
                                                    />
                                    </div>
                                    <div className={`${s.contentText} mt25`}>
                                        {t('Each bid is 10% higher than the previous bid.')}
                                    </div>
                                    <div className={`${s.contentText} mt20`}>
                                        {t('You can batch bid on multiple NFTs.')}
                                    </div>
                                </> :
                                <>
                                    <div className={s.contentText}>
                                        {t('Bid Using $LFG/$MATIC in increments of 10%.')}
                                    </div>
                                    <div className={`${s.contentText} mt10`}>
                                        {t('If outbid, you\'ll be reimbursed 100% of your original bid amount back + earn an additional 5% of your bid amount')}
                                    </div>
                                    <div className={`${s.imgRoot} mt15`}>
                                        <CustomIcon width={285.3} height={111.6}
                                                    imgName={'BG/UI_Background-S_BidTipPage_01'}
                                                    imgType = {'.webp'}
                                                    />
                                    </div>
                                    <div className={`${s.contentText} mt10`}>
                                        {t('Duration: Each new bid extends duration for 8 hours until to new bids.')}
                                    </div>
                                </>
                    }
                </div>
            </div>
            <div className={s.pageRoot}>
                <div
                    className={`${pageIndex === 0 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(0)
                    }}>
                </div>
                <div
                    className={`${pageIndex === 1 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(1)
                    }}>
                </div>
                <div
                    className={`${pageIndex === 2 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(2)
                    }}>
                </div>
            </div>
        </div>
    )
}
export default memo(BidAuctionRules);
