import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { Tabs, Modal, Button, Checkbox, message } from 'antd';
import s from "./index.module.less";
import AntButton from "@/common/AntButton";

import {
    post_work_unlock,
    bornCheck_unlock,
    convertWalletBalance,
    getAvatarFromUserInfo,
    getfriendtrade_rank_selfdata,
    getFriendtrade_takecoininfo,
    formatNumberWithUnit,
    getFriendtradeLocklfgInfo,
    vCoinString,
    stringToNumber,
    getFitSilderWidthPercent,
} from "@/utils/common";

import GamesUserInfoPanel from "src/common/GamesUserInfoPanel";
import CustomIcon from "src/common/CustomIcon";
import { useNavigate, useLocation } from "react-router-dom";

import { useTranslation, Trans } from 'react-i18next';
import PopupTrivia from 'src/model/PopupTrivia';
import {
    gameTrillionaireBattleLeaderBoardPath,
    gameTrillionaireMissionDetailPath,
    publicChatsPath,
} from "@/routes/config";
import { getWorklist, getWorkItem, claimWorkprofit } from "@/utils/mission";
import MainHeader from "src/common/MainHeader";
import Dispatch from "./Dispatch";
import ModelEarnAll from "@/model/ModelEarnAll";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import ModelBornLFG from "@/model/ModelBornLFG";
import TabPane from "antd/es/tabs/TabPane";
import { getRarityBgColor, getRarityText, getRarityRankIcon, getRarityFontColor } from "@/utils/bidNft";
import AntModal from "@/common/AntModal";
import ModelClaimAll from "@/model/ModelClaimAll";
import AntTabPane from "@/common/AntTabPane";
import { AutoStrangeRule } from "@/utils/strangeRule";
import RankReward from '@/utils/json/RankReward.json';
import ModelRankReward from 'src/model/ModelRankReward';
import ModelApplyMint from "src/model/ModelApplyMint";
import AvatarInfo from "@/common/AvatarInfo";
import Countdown from "@/common/Countdown";
import CrackModelHistoryActivities from "@/model/CrackModelHistoryActivities";
const Mission = (
    {
        userInfo,
        missionType,
        setMissionType
    }
) => {
    const { t, i18n } = useTranslation();
    const ref = useRef();
    const navigate = useNavigate()
    const [workListInfo, setWorkListInfo] = useState({})
    const [nftWorkListInfo, setNftWorkListInfo] = useState({})
    const [selWorkInfo, setSelWorkInfo] = useState({})
    const [openDispatchPanel, setOpenDispatchPanel] = useState(false)
    const [showDispatchAll, setShowDispatchAll] = useState(false)
    const [isShowClubs, setIsShowClubs] = useState(false)
    const [activeType, setActiveType] = useState(missionType)
    const [showRankRewardPanel, setShowRankRewardPanel] = useState(false);
    const [showApplyMint, setShowApplyMint] = useState(false);
    const [showHistoryActivities, setShowHistoryActivities] = useState(false);
    const { selfData, platformData,userId } = useMemo(() => {
        return userInfo || {}
    }, [userInfo])
    const { unlockWorkIds, lfgBalance, claimCoin } = useMemo(() => {
        return selfData || {}
    }, [selfData])

    const list = useMemo(() => {
        return workListInfo?.workList || []
    }, [workListInfo])

    const nftWorkList = useMemo(() => {
        return nftWorkListInfo?.workList || []
    }, [nftWorkListInfo])


    const getWorklistFn = (workType) => {
        getWorklist(workType).then(res => {
            if (workType == 1) {
                setWorkListInfo(res || {})
            }
            if (workType == 3) {
                setNftWorkListInfo(res || {})
            }

        })
    }


    const openDispatch = (workId, isShowClubs) => {
        // setSelWorkInfo(getWorkItem(workId))
        // setOpenDispatchPanel(true)
        navigate(`${gameTrillionaireMissionDetailPath}/${workId}`, { state: { isShowClubs } })
    }
    const [claimAllInfo, setClaimAllInfo] = useState({})
    const [claimAllModel, setClaimAllModel] = useState(false)
    const claimWorkprofitFn = (workId) => {
        claimWorkprofit(workId).then(res => {
            getWorklistFn(activeType)
            setClaimAllInfo(res)
            setClaimAllModel(true)
            // message.success({content: t('CLAIM SUCCESS')})
        }).catch(e => {
            console.error(e);
        })
    }
    // const [unlockFee, setUnlockFee] = useState(0)
    const [showSelPayType, setShowSelPayType] = useState(false)
    const [showSwapLfg, setShowSwapLfg] = useState(false)
    const unlockClick = (UnlockFee) => {
        if (Number(lfgBalance) < UnlockFee && Number(claimCoin) < UnlockFee) {
            setShowSwapLfg(true);
        } else {
            // setUnlockFee(UnlockFee)
            setShowSelPayType(true)
        }
    }

    const unlockFn = () => {
        post_work_unlock(selWorkInfo?.ID).then(data => {
            setShowSelPayType(false);
            getWorklistFn(1)
        }).catch(err => {
            console.log(err);
        });
    }
    const [checkExpire, setCheckExpire] = useState(0)
    const [checking, setChecking] = useState(false)
    const checkFn = (orderId) => {
        if (!checkExpire) {
            setCheckExpire(new Date().getTime() + 120000)
        }
        setChecking(true)
        if (checkExpire < new Date().getTime()) {
            bornCheck_unlock(orderId).then(res => {
                if (!res?.haveResult) {
                    setTimeout(() => {
                        checkFn(orderId)
                    })
                } else {
                    setChecking(false)
                    setShowSelPayType(false);
                    getWorklistFn(activeType)
                }
            })
        } else {
            setChecking(false)
            // setShowSelPayType(false);
            message.info({ content: 'Checking Expire' })
        }

    }
    const { avatarUrl, originalAvatarUrl } = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [userInfo])

    const [claimInfo, setClaimInfo] = useState(null);
    const [mintInfo, setMintInfo] = useState(null);
    const [lockLfgInfo, setLockLfgInfo] = useState(null);
    const {
        rankIdx,
        leftSendRewardSec
    } = useMemo(() => {
        return claimInfo || {}
    }, [claimInfo]);
    const getClaimInfo = () => {
        getfriendtrade_rank_selfdata().then(res => {
            setClaimInfo(res);
        }).catch(err => {
            console.error(err.message);
        })
    }

    const updateLocklfgInfo = () => {
        getFriendtradeLocklfgInfo().then(res => {
            setLockLfgInfo(res);
        }).catch(e => {
            console.log(e);
        });
    }

    const getTakeCoinInfo = () => {
        getFriendtrade_takecoininfo().then(res => {
            setMintInfo(res);
            //setNum(Math.floor(res?.chainApplyMinCoin))
            //setChainApplyOrderInfo(res?.chainApplyOrderInfo)
        }).catch(e => {
            console.error(e);
        });
    }

    useEffect(() => {
        getWorklistFn(1)
        getClaimInfo()
        getTakeCoinInfo()
        getWorklistFn(3)
        updateLocklfgInfo();
    }, [])

    
    return (
        <div className={`${s.main} mainWrap commonBlueBg`}>
            <MainHeader isGameHeader={true} hasBackBtn={false} leftTitle={t('Mission')} rightMenuIcon ={"Button/UI_Button-D_Home_On"}></MainHeader>
            {/* <GamesUserInfoPanel/> */}
            <div className={s.topContent}>

                <div className={s.selfHead} style={{ backgroundImage: `url(/UI/Picture/UI_Picture-Main_Top_01.png)` }}>
                    <div className={`${s.headLine} flex_center_between`}>
                        <AvatarInfo onClick={()=>{navigate(publicChatsPath+'/'+userId)}} src={avatarUrl || originalAvatarUrl} size={70}></AvatarInfo>
                        <div className={`flex_center_center_col`}>
                            <div className={`flex_center_start`}>
                                <div className={`${s.estReward} flex_center_start`}>
                                    <CustomIcon width={20} height={20} className="ml5 mr5"
                                        imgName={'Picture/UI_Picture-Currency_UP_01'} />
                                    <div className={`tll`} style={{ minWidth: '40px' }}>
                                        {formatNumberWithUnit(RankReward.find(v => rankIdx >= v.rankMin && rankIdx <= v.rankMax)?.rewardNum || 0) || '--'}
                                    </div>
                                    <span className="fs14 color-white textnowrap" style={{ minWidth: '120px' }}>
                                        {
                                            leftSendRewardSec &&
                                            <Trans
                                                i18nKey="Setties in sometime"
                                                defaults="Setties in <time/>"
                                                components={{
                                                    time: leftSendRewardSec && <Countdown leftSecond={leftSendRewardSec} />
                                                }}
                                            />
                                        }
                                    </span>
                                </div>
                                <CustomIcon width={18} height={18} imgName={'Button/UI_Button_QuestionMark_01'}
                                    onClick={() => {
                                        setShowRankRewardPanel(true);
                                    }} />
                            </div>
                            <div className={`mt10 flex_center_start`}>
                                <div className={`${s.lfgPoolLine} flex_center_start`}>
                                    {
                                        (claimCoin !== undefined && claimCoin !== null && mintInfo?.chainApplyMinCoin > 0) &&
                                        <div
                                            className={`${s.lfgPoolContent} ${claimCoin >= mintInfo?.chainApplyMinCoin ? s.lfgPoolContentYellow : s.lfgPoolContentBlue}`}
                                            style={{
                                                width: getFitSilderWidthPercent(22, 194, mintInfo?.chainApplyMinCoin || 0, claimCoin || 0)
                                            }}>
                                        </div>
                                    }
                                    <div className={`${s.lfgPoolSliderContent} flex_center_start`}>
                                        <CustomIcon width={20} height={20} className="ml5 mr5"
                                            imgName={'Picture/UI_Picture-Currency_LFG_01'} />
                                        <div className={`${s.lfgPoolText} fb`}
                                            style={{ color: claimCoin >= mintInfo?.chainApplyMinCoin ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)' }}>
                                            {
                                                claimCoin >= mintInfo?.chainApplyMinCoin ?
                                                <>
                                                    {`${convertWalletBalance(Number(claimCoin) || 0)}`}
                                                </> :
                                                <>
                                                    {`${convertWalletBalance(Number(claimCoin) || 0)}/${vCoinString(mintInfo?.chainApplyMinCoin) || 0}`}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <CustomIcon width={21} height={20} imgName={'Button/UI_Button_Extract-White_01'}
                                    onClick={() => {
                                        setShowApplyMint(true);
                                    }} />
                            </div>
                        </div>
                        <div className={`flex_center_center_col`}>
                            <CustomIcon width={40} height={40} className="" onClick={()=>{navigate(gameTrillionaireBattleLeaderBoardPath)}}
                                imgName={'Picture/UI_Picture-Currency_Rank_01'} />
                            <div className={`fs20 color-yellow`}>
                                {`${rankIdx > 10000 ? '9999+' : rankIdx || '--'}`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.head}>
                <div>{t('YD Yield')}</div>
                <div className={s.infoItem}>
                    <div className="color-blue-light">{t('Dispatch')}</div>
                    <span>: &nbsp;</span>
                    <CustomIcon
                        width={18}
                        height={18}
                        className="mr2"
                        imgName="/Picture/UI_Picture-Currency_LFG_01"
                    />
                    {convertWalletBalance(workListInfo?.yesterdayEarnWorkLfg)}
                </div>
                <div className={s.infoItem}>
                    <div className="color-blue-light">{t('Share')}</div>
                    <span>: &nbsp;</span>
                    <CustomIcon
                        width={18}
                        height={18}
                        className="mr2"
                        imgName="/Picture/UI_Picture-Currency_LFG_01"
                    />
                    {convertWalletBalance(workListInfo?.yesterdayDivideWorkLfg)}
                </div>
                
                <CustomIcon width={22} height={22} className={s.headActivitiesIcon} imgName={'Button/UI_Button_YellowRound-CopyNumberWordName_01'}
                            onClick={()=>{
                                setShowHistoryActivities(true);
                            }}/>
            </div>
            <div className={s.content}>
                <div className={s.bt}>
                    {/* <div className={s.btl}>
                        <div className={s.partTitle}>
                            <CustomIcon width={17} height={39} className=""
                                imgName={'Picture/UI_Picture_Title-Yellow_01'}
                            />
                            {t('MY MISSIONS ')}{`(${unlockWorkIds?.length}/${workListInfo?.totalWorkNum || '--'})`}
                        </div>
                    </div> */}
                    {/* <div className={s.btr}>
                        <CustomIcon width={30} height={30} className="mr10"
                                    imgName={'Button/UI_Button_UpAndDown_01'}
                        />
                        <CustomIcon width={30} height={30} className=""
                                    imgName={'Button/UI_Button_ThreeTieRod_01'}
                        />
                    </div> */}
                </div>
                <Tabs defaultActiveKey={missionType} onChange={(activeKey) => {
                    setActiveType(activeKey)
                    setMissionType(activeKey)
                }} className={`tptabs`}>
                    <AntTabPane tab={AutoStrangeRule(t('CLUB MISSIONS'))} key="1">
                        <div className={s.list}>
                            {
                                list?.map(item => {
                                    const {
                                        workId,
                                        requiredTierId,
                                        profit,
                                        workingUserNum,
                                        userLimitNum,
                                        toClaimLfg,
                                        canUnlock
                                    } = item
                                    const { Image, UnlockFee, Name } = getWorkItem(workId)
                                    const isUnlock = unlockWorkIds.find(i => i === workId);
                                    return (
                                        <div className={s.item} key={workId}
                                            onClick={() => {
                                                if (!isUnlock) return
                                                openDispatch(workId, false)
                                            }}
                                            style={{ backgroundImage: `url(/UI/Mission/Horizon/${Image}.webp)` }}>
                                            {!isUnlock && <div className={s.lockBg}>
                                                <div className={s.locked} onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (!canUnlock) return
                                                    setSelWorkInfo(getWorkItem(workId))
                                                    unlockClick(UnlockFee)
                                                }}>
                                                    <CustomIcon width={34} height={49} className=""
                                                        imgName={'Picture/UI_Picture_Lock_01'}
                                                    />
                                                </div>

                                            </div>}
                                            {!isUnlock && !canUnlock && <div className={s.cannotUnlock}>
                                                {t('REQUIRE UNLOCK PREVIOUS MISSION')}
                                            </div>}
                                            <div className={s.left}>
                                                <div className={s.ltop}>
                                                    <div className={`${s.view} ${!isUnlock ? s.hide : ''}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            if (!isUnlock) return
                                                            openDispatch(workId, false)
                                                        }}>
                                                        <CustomIcon width={24} height={22} className=""
                                                            imgName={'Button/UI_Button_Search_01'}
                                                        />
                                                    </div>
                                                    <div className={s.workAmount}>
                                                            <CustomIcon width={16} height={16} className="mr5"
                                                                imgName={'Button/UI_Button-D_MyClubs_On'}
                                                            />
                                                            <div className={`${s.workNum} ${workingUserNum>0 ? s.hasPadding : ''}`}>
                                                                {workingUserNum}
                                                                {userLimitNum <= workingUserNum &&
                                                                <span className="color-red"> ({t('MAX')})</span>}
                                                            </div>
                                                            <div className={`${s.runMan} ${workingUserNum>0 ? s.show : ''}`} style={{backgroundImage: `url(/UI/Gif/UI_Gif_Run.gif)`}}>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className={s.workName}>
                                                    {Name}
                                                </div>
                                            </div>

                                            <div className={s.info}>
                                                <div className={s.infoT}>
                                                    <div className="df">
                                                        {t('Tier required')}:
                                                        <CustomIcon width={20} height={20} className="ml5"
                                                            imgName={'Picture/Rank/RankStar_' + requiredTierId}
                                                        />
                                                    </div>
                                                    <div className="df">
                                                        <CustomIcon width={16} height={16} className="mr5"
                                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                        />
                                                        {stringToNumber(profit,2)}/{t('hr')}
                                                    </div>
                                                    
                                                </div>
                                                <div className={s.infoB}>

                                                    {
                                                        !isUnlock &&
                                                        <AntButton
                                                            className={`btn_white hasIconMargin ${!canUnlock ? 'btn_disabled' : ''}`}
                                                            disabled={!canUnlock}
                                                            onClick={(e) => {

                                                                e.stopPropagation()
                                                                setSelWorkInfo(getWorkItem(workId))
                                                                unlockClick(UnlockFee)
                                                            }}>

                                                            {t('Unlock')}
                                                            <CustomIcon width={18} height={18} className=""
                                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                            />
                                                            <span>{UnlockFee}</span>


                                                        </AntButton>
                                                    }

                                                    {
                                                        isUnlock && toClaimLfg > 0 &&
                                                        <AntButton className="btn_public hasIconMargin"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                claimWorkprofitFn(workId)
                                                            }}>
                                                            {t('CLAIM')}
                                                            <CustomIcon width={18} height={18} className=""
                                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                            />
                                                            <span>{convertWalletBalance(toClaimLfg)}</span>
                                                        </AntButton>
                                                    }

                                                    {
                                                        isUnlock && toClaimLfg === 0 &&
                                                        <AntButton
                                                            className={`btn_white ${userLimitNum <= workingUserNum ? 'btn_disabled' : ''}`}
                                                            disabled={userLimitNum <= workingUserNum}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                openDispatch(workId, true)
                                                            }}>
                                                            {t('DISPATCH')}
                                                        </AntButton>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </AntTabPane>
                    <AntTabPane tab={AutoStrangeRule(t('NFT MISSIONS'))} key="3">
                        <div className={s.list}>
                            {
                                nftWorkList?.map(item => {
                                    const {
                                        workId,
                                        requiredTierId,
                                        profit,
                                        workingUserNum,
                                        userLimitNum,
                                        toClaimLfg,
                                        canUnlock,
                                        requiredRarity
                                    } = item

                                    const { Image, UnlockFee, Name } = getWorkItem(workId)
                                    const isUnlock = unlockWorkIds.find(i => i === workId);
                                    return (
                                        <div className={s.item} key={workId}
                                            onClick={() => {
                                                if (!isUnlock) return
                                                openDispatch(workId, false)
                                            }}
                                            style={{ backgroundImage: `url(/UI/Mission/Horizon/${Image}.webp)` }}>
                                            {!isUnlock && <div className={s.lockBg}>
                                                <div className={s.locked} onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (!canUnlock) return
                                                    setSelWorkInfo(getWorkItem(workId))
                                                    unlockClick(UnlockFee)
                                                }}>
                                                    <CustomIcon width={34} height={49} className=""
                                                        imgName={'Picture/UI_Picture_Lock_01'}
                                                    />
                                                </div>

                                            </div>}
                                            {!isUnlock && !canUnlock && <div className={s.cannotUnlock}>
                                                {t('REQUIRE UNLOCK PREVIOUS MISSION')}
                                            </div>}

                                            <div className={s.left}>
                                                {/* <div className={`${s.view} ${!isUnlock ? s.hide : ''}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (!isUnlock) return
                                                        openDispatch(workId, false)
                                                    }}>
                                                    <CustomIcon width={24} height={22} className=""
                                                        imgName={'Button/UI_Button_Search_01'}
                                                    />
                                                </div>
                                                <div className={s.workName}>
                                                    {Name}
                                                </div> */}
                                                <div className={s.ltop}>
                                                    <div className={`${s.view} ${!isUnlock ? s.hide : ''}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            if (!isUnlock) return
                                                            openDispatch(workId, false)
                                                        }}>
                                                        <CustomIcon width={24} height={22} className=""
                                                            imgName={'Button/UI_Button_Search_01'}
                                                        />
                                                    </div>
                                                    <div className={s.workAmount}>
                                                            <CustomIcon width={16} height={16} className="mr5"
                                                                imgName={'Button/UI_Button-D_MyClubs_On'}
                                                            />
                                                            <div className={`${s.workNum} ${workingUserNum>0 ? s.hasPadding : ''}`}>
                                                                {workingUserNum}
                                                                {userLimitNum <= workingUserNum &&
                                                                <span className="color-red"> ({t('MAX')})</span>}
                                                            </div>
                                                            <div className={`${s.runMan} ${workingUserNum>0 ? s.show : ''}`} style={{backgroundImage: `url(/UI/Gif/UI_Gif_Run.gif)`}}>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className={s.workName}>
                                                    {Name}
                                                </div>
                                            </div>

                                            <div className={s.info}>
                                                <div className={s.infoT}>
                                                    <div className="df">
                                                        {t('Required')}:
                                                        <div
                                                            className="ml5 rarityWrap"
                                                            style={{
                                                                backgroundColor: `${getRarityBgColor(requiredRarity)}`,
                                                                color: `${getRarityFontColor(requiredRarity)}`
                                                            }}>
                                                            {getRarityText(requiredRarity)}
                                                        </div>
                                                    </div>
                                                    <div className="df">
                                                        <CustomIcon width={16} height={16} className="mr5"
                                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                        />
                                                        {stringToNumber(profit,2)}/{t('hr')}
                                                    </div>
                                                    {/* <div className="df">
                                                        <CustomIcon width={16} height={16} className="mr5"
                                                            imgName={'Button/UI_Button-D_MyClubs_On'}
                                                        />
                                                        {workingUserNum}{userLimitNum <= workingUserNum &&
                                                            <span className="color-red"> ({t('MAX')})</span>}
                                                    </div> */}
                                                </div>
                                                <div className={s.infoB}>

                                                    {
                                                        !isUnlock &&
                                                        <AntButton
                                                            className={`btn_white hasIconMargin ${!canUnlock ? 'btn_disabled' : ''}`}
                                                            disabled={!canUnlock}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setSelWorkInfo(getWorkItem(workId))
                                                                unlockClick(UnlockFee)
                                                            }}>

                                                            {t('Unlock')}
                                                            <CustomIcon width={18} height={18} className=""
                                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                            />
                                                            <span>{UnlockFee}</span>

                                                        </AntButton>
                                                    }
                                                    {
                                                        isUnlock && toClaimLfg > 0 &&
                                                        <AntButton className="btn_public hasIconMargin"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                claimWorkprofitFn(workId)
                                                            }}>
                                                            {t('CLAIM')}
                                                            <CustomIcon width={18} height={18} className=""
                                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                            />
                                                            <span>{convertWalletBalance(toClaimLfg)}</span>
                                                           
                                                        </AntButton>
                                                    }

                                                    {
                                                        isUnlock && toClaimLfg === 0 &&
                                                        <AntButton
                                                            className={`btn_white ${userLimitNum <= workingUserNum ? 'btn_disabled' : ''}`}
                                                            disabled={userLimitNum <= workingUserNum}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                // setIsShowClubs(true)
                                                                openDispatch(workId, true)
                                                            }}>
                                                            {t('DISPATCH')}
                                                        </AntButton>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </AntTabPane>
                </Tabs>

            </div>
            <div className={s.bottom}>
                <div className="mr15">
                    <span className="color-yellow">
                        {activeType === '1' ? workListInfo?.canDispatchNum : nftWorkListInfo?.canDispatchNum}
                    </span>
                    {activeType === '1' ? t(' Clubs available to earn $LFG') : t(' NFTs available to earn $LFG')}
                </div>
                <div className={s.bBtn}>
                    <AntButton className="btn_public" onClick={() => {
                        setShowDispatchAll(true)
                    }}>{t('Auto-deploy')}</AntButton>
                </div>
            </div>
            {/* {openDispatchPanel && <Dispatch  isShowClubs={isShowClubs}
                                            workInfo={selWorkInfo}
                                            closeHandle={() => setOpenDispatchPanel(false)}></Dispatch>} */}
            {
                showHistoryActivities &&
                <CrackModelHistoryActivities closeFn={()=>{setShowHistoryActivities(false)}} showType={1}/>
            }
            <AntModal
                width='330px'
                title={t('Auto Deployment')}
                className="confirmModalWrap p0"
                centered={true}
                open={showDispatchAll}
                destroyOnClose={true}
                onCancel={() => setShowDispatchAll(false)}
                zIndex={4000}
            >
                <ModelEarnAll
                    closeHandle={() => {
                        getWorklistFn(activeType)
                        setShowDispatchAll(false)
                    }}
                    userInfo={userInfo}
                    workType={activeType}
                    refreshPortfolio={() => {
                        getWorklistFn(activeType)
                    }}
                />
            </AntModal>

            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={4000}
            >
                <ModalSwapLfg insufficientAmount={Number(selWorkInfo?.UnlockFee) - Number(lfgBalance)}
                    userInfo={userInfo} closeFn={() => {
                        setShowSwapLfg(false);
                    }} />
            </AntModal>
            <AntModal
                width='330px'
                title={t('UNLOCK MISSION')}
                className="confirmModalWrap"
                centered={true}
                open={showSelPayType}
                destroyOnClose={true}
                onOk={() => setShowSelPayType(false)}
                onCancel={() => setShowSelPayType(false)}
                zIndex={4000}
            >
                <ModelBornLFG
                    cliamPoolFn={unlockFn}
                    bornNum={selWorkInfo?.UnlockFee}
                    workId={selWorkInfo?.ID}
                    bornType={1}
                    checkFn={checkFn}
                    closeFn={() => {
                        setShowSelPayType(false)
                    }}

                />
            </AntModal>
            <AntModal
                width='330px'
                title={t('ALL YIELD CLAIMED')}
                className="confirmModalWrap noImageTitle"
                centered={true}
                open={claimAllModel}
                destroyOnClose={true}
                onOk={() => setClaimAllModel(false)}
                onCancel={() => setClaimAllModel(false)}
                zIndex={4000}
            >
                <ModelClaimAll
                    info={claimAllInfo}
                    closeFn={() => {
                        setClaimAllModel(false)
                    }}

                />
            </AntModal>

            <AntModal
                width='330px'
                title={t('LEADERBOARD & $UP POINTS')}
                className="confirmModalWrap"
                centered={true}
                open={showRankRewardPanel}
                destroyOnClose={true}
                onOk={() => setShowRankRewardPanel(false)}
                onCancel={() => setShowRankRewardPanel(false)}
            >
                <ModelRankReward />
            </AntModal>
            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={showApplyMint}
                title={t('$LFG Withdrawal')}
                destroyOnClose={true}
                onOk={() => setShowApplyMint(false)}
                onCancel={() => setShowApplyMint(false)}
                zIndex={1999}
            >
                <ModelApplyMint
                    lockLfgInfo={lockLfgInfo}
                    updateLocklfgInfoFn={updateLocklfgInfo}
                    closeFn={() => {
                        setShowApplyMint(false)
                    }}
                />
            </AntModal>

        </div>

    )
}
const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        missionType: app.missionType
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setMissionType: (params) => {
            return dispatch({
                type: "app/setMissionType",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Mission));
