import React, { memo, useCallback, useEffect, useMemo, useRef,useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { Button, Checkbox, Spin, message, Modal, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import {
    stringToNumber,
    convertWalletBalance,
    getChainTokenInfoById,
    getTokenBalanceOf,
    GetSupportedChainList,
    getQuotes,
    numberClamp,
} from "@/utils/common";
import {AutoStrangeRule} from "@/utils/strangeRule";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";
import ModelDeposit from "@/model/ModelDeposit";
import MemeAssetClubSort from "@/drawer/MemeAssetClubSort";
import MemeChainToken from "@/utils/json/memeChainToken.json";
import ModalMemeAssetNewPack from "@/model/ModalMemeAssetNewPack";
import { 
    userTabList,
    getMemeSelfHoldList,
    getMemeClubDntList,
    getMemeTgeCanopenList,
} from "@/utils/memeClub";
import { getBalance } from "@/utils/uniswapWeb3";
import MemeClubPack from "@/common/MemeClubPack";
import List from "./List";
import { useWallets } from "@privy-io/react-auth";
import AssetList from "./AssetList";
import { rsort } from "semver";

const Assets = (
    {
        userInfo,
        walletBalanceList,
        setWalletBalanceList,
        memeDntBalanceList,
        setMemeDntBalanceList,
        lastUpdateMemeDntBalanceListTime,
        setLastUpdateMemeDntBalanceListTime,
        memeAssetParam,
        setMemeAssetParam,
        memeAssetUnpackList,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {wallets} = useWallets()
    const [memeClubsShowList, setMemeClubsShowList] = useState([]);
    const [assetDntList, setAssetDntList] = useState([]);
    const [assetDntNextCursor, setAssetDntNextCursor] = useState(-1);
    const [updateDntListCount, setUpdateDntListCount] = useState(0);
    const [assetPackList, setAssetPackList] = useState([]);
    const [openablePackList, setOpenablePackList] = useState([]);
    const [assetPackNextCursor, setAssetPackNextCursor] = useState(-1);
    const [openablePackNextCursor, setOpenablePackNextCursor] = useState(-1);
    const [showMemeClubSort, setShowMemeClubSort] = useState(false);
    const [nextCursor, setNextCursor] = useState(-1);
    const [sortValue, setSortValue] = useState({sortType:1, sortOrder:0});
    const [marketInfo, setMarketInfo] = useState(null);
    const [highlightPackItemId, setHighlightPackItemId] = useState(0);
    const [showMemeAssetNewPack, setShowMemeAssetNewPack] = useState(false);
    const {
        walletAddr,
        topUpCode,
        userId,
        referralTotalEarn,
        platformData,
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    
    const unreadMemeAssetNewPackNum = useMemo(() =>{
        let res = 0;
        if (memeAssetUnpackList?.length > 0){
            for (let i = 0; i < memeAssetUnpackList?.length; i++){
                if (!memeAssetUnpackList[i].isRead){
                    res++;
                }
            }
        }
        return res;
    }, [memeAssetUnpackList])
    
    // avatarUrl: "https://pbs.twimg.com/profile_images/1717072816691113984/FL9cjV62_bigger.jpg",
    // isFavorite: true,
    // name: "MemeClub_Name1",
    // creatorName: "CreatorName1",
    // chainName: "Polygon",
    // coinName: "$Matic",
    // progress: 66,
    // holdingNum: 12,
    // state: 0,
    // currentyAmount: 0,
    // totalValue: 666,
    // haveImage: false,
    // haveMedia: false,
    // unrevealCardNum: 0,

    const getWalletBalance = () =>{
        let tokenList = [];
        if (MemeChainToken?.length > 0){
            for(let i = 0; i < MemeChainToken?.length; i++){
                let tokenInfo = MemeChainToken[i];
                if (tokenInfo?.isEnable){
                    tokenList.push(tokenInfo?.ID);
                }
            }
        }
        getTokenBalanceOf(walletAddr, tokenList).then(res => {
            setWalletBalanceList(res?.balances);
        }).catch(err => {
            console.error(err.message);
        })
    }

    const getMarketInfo = () =>{
        let requestList = [];
        if (MemeChainToken?.length > 0){
            for(let i = 0; i < MemeChainToken?.length; i++){
                let tokenItem = MemeChainToken[i];
                if (tokenItem && !requestList.includes(tokenItem?.coinName)){
                    requestList.push(tokenItem?.coinName);
                }
            }
        }
        getQuotes(requestList).then(res => {
            setMarketInfo(res);
        }).catch(e => {
            console.log(e);
        })
    }

    const nativeTokenList = useMemo(() =>{
        let list = [];
        if (MemeChainToken?.length > 0){
            for(let i = 0; i < MemeChainToken?.length; i++){
                let tokenInfo = MemeChainToken[i];
                if (tokenInfo?.isEnable){
                    if (walletBalanceList?.[tokenInfo?.ID]){
                        tokenInfo.balance = walletBalanceList?.[tokenInfo?.ID];
                    }
                    if (marketInfo?.quotes?.[tokenInfo?.coinName]){
                        tokenInfo.quotes = marketInfo?.quotes?.[tokenInfo?.coinName];
                    }
                    list.push(tokenInfo);
                }
            }
        }
        return list;
    }, [MemeChainToken, walletBalanceList, marketInfo]); 

    const nativeAssetValue = useMemo(() =>{
        let res = 0;
        if (nativeTokenList?.length > 0){
            for (let i = 0; i < nativeTokenList?.length; i++){
                res += (nativeTokenList[i]?.balance || 0) * (nativeTokenList[i]?.quotes || 0);
            }
        }
        return res;
    }, [nativeTokenList]);

    const dntAssetValue = useMemo(() =>{
        let res = 0;
        if (assetDntList?.length > 0){
            for (let i = 0; i < assetDntList?.length; i++){
                let tempBalance = Number(assetDntList[i]?.balance);
                let tempExchangeRate = assetDntList[i]?.exchangeRate;
                if (tempBalance > 0 && tempExchangeRate > 0){
                    res += tempBalance * tempExchangeRate;
                }
            }
        }
        return res;
    }, [assetDntList, updateDntListCount]);

    const packAssetValue = useMemo(() =>{
        let res = 0;
        if (assetPackList?.length > 0){
            for (let i = 0; i < assetPackList?.length; i++){
                let tempPackInfo = assetPackList[i];
                let coinConfig = getChainTokenInfoById(tempPackInfo?.clubProfile?.coinId);
                let coinQuotes = marketInfo?.quotes?.[coinConfig?.coinName];
                if (tempPackInfo?.holdNum > 0 && tempPackInfo?.sellPrice > 0 && coinQuotes > 0){
                    res += tempPackInfo?.holdNum * tempPackInfo?.sellPrice * coinQuotes;
                }
            }
        }
        return res;
    }, [assetPackList, marketInfo]);

    const [totalType, setTotalType] = useState(0);//0:assets, 1:collections
    const [walletAssetsType, setWalletAssetsType] = useState(0);//0:FT,1:DNT,2:Pack
    const [memeClubShowType, setMemeClubShowType] = useState(1);//1:created,2:facorited,3:holdOpenablePack
    const [showDepositPanel, setShowDepositPanel] = useState(false);

    const getList = ({rankTabType=1,rankSortType=1,isAsc=true,cursor=0})=>{
        console.log(rankTabType,rankSortType,isAsc,cursor);
        userTabList({rankTabType,rankSortType,isAsc,cursor}).then(res=>{
            const _list = cursor === 0 ? res?.dataList || [] : [
                ...memeClubsShowList,
                ...(res?.dataList || [])
            ]
            setMemeClubsShowList(_list)
            setNextCursor(res?.nextCursor)
        }).catch(e=>{
            console.error(e);
        })
    }

    const getMoreFn = () =>{
        getList({
            rankTabType:memeClubShowType,
            rankSortType: sortValue?.sortType,
            isAsc: sortValue?.sortOrder === 0,
            cursor:nextCursor,
        })
    }

    const getPackList = (cursor = 0)=>{
        let skipClubUserId = 0;
        if (memeAssetParam?.clubUserId > 0){
            skipClubUserId = memeAssetParam?.clubUserId;
        }
        getMemeSelfHoldList({
            cursor: cursor,
            skipClubUserId: skipClubUserId,
        }).then(res=>{
            if (skipClubUserId > 0 && memeAssetParam?.page === 2){
                setHighlightPackItemId(skipClubUserId);
                setMemeAssetParam(null);
            }

            let _list = [];
            if (res?.skipClubUnit){
                _list.push(res?.skipClubUnit);
            }
            if (cursor !== 0){
                for (let i = 0; i < assetPackList?.length; i++){
                    _list.push({...assetPackList[i]});
                }
            }
            for (let i = 0; i < res?.clubList?.length; i++){
                if (res?.clubList?.[i]?.clubUserId !== res?.skipClubUnit?.clubUserId){
                    _list.push({...res?.clubList[i]});
                }
            }
            setAssetPackList(_list);
            setAssetPackNextCursor(res?.nextCursor);
        }).catch(e=>{
            console.error(e);
        })
    }

    const getMorePackFn = () =>{
        getPackList(assetPackNextCursor);
    }

    const getDntList = (cursor = 0)=>{
        getMemeClubDntList({cursor}).then(res=>{
            if (memeAssetParam?.page === 1){
                setMemeAssetParam(null);
            }
            //无分页
            /*const _list = cursor === 0 ? res?.clubList || [] : [
                ...assetDntList,
                ...(res?.clubList || [])
            ]
            setAssetDntList(_list);
            setAssetDntNextCursor(res?.nextCursor || -1);*/
            
            let needRefreshMemeBalance = false;
            let nowTime = new Date().getTime();
            let newClubList = res?.clubList;
            if (nowTime - lastUpdateMemeDntBalanceListTime >= 30000){
                needRefreshMemeBalance = true;
            }
            else if(newClubList?.length > 0){
                for (let i = 0; i < newClubList?.length; i++){
                    let balanceInfo = memeDntBalanceList?.[newClubList[i]?.clubUserId];
                    if (balanceInfo == null){
                        needRefreshMemeBalance = true;
                        break;
                    }
                }
            }
            if (needRefreshMemeBalance){
                let maxCount = newClubList?.length;
                let newMemeBalanceList = {};
                let count = 0;
                for (let i = 0; i < newClubList?.length; i++){
                    let tempClubInfo = newClubList[i];
                    let tokenInfo = getChainTokenInfoById(tempClubInfo?.coinId);
                    if (tokenInfo){
                        getBalance({ wallets, addr: tempClubInfo?.contractAddr, chainId: tokenInfo?.chainId }).then(res=>{
                            newMemeBalanceList[tempClubInfo?.clubUserId] = res;
                            for (let j = 0; j < newClubList?.length; j++){
                                if (newClubList[j]?.clubUserId === tempClubInfo?.clubUserId){
                                    newClubList[j].balance = res;
                                    break;
                                }
                            }
                            count++;
                            if (count >= maxCount){
                                setAssetDntList(newClubList);
                                setUpdateDntListCount(updateDntListCount + 1);
                                setMemeDntBalanceList(newMemeBalanceList);
                                let newNowTime = new Date().getTime();
                                setLastUpdateMemeDntBalanceListTime(newNowTime);
                            }
                        }).catch(e=>{
                            console.error(e);
                            count++;
                            if (count >= maxCount){
                                setAssetDntList(newClubList);
                                setUpdateDntListCount(updateDntListCount + 1);
                                setMemeDntBalanceList(newMemeBalanceList);
                                let newNowTime = new Date().getTime();
                                setLastUpdateMemeDntBalanceListTime(newNowTime);
                            }
                        })
                    }
                }
            }
            else{
                if (newClubList?.length > 0){
                    for (let i = 0; i < newClubList?.length; i++){
                        newClubList[i].balance = memeDntBalanceList?.[newClubList[i].clubUserId];
                    }
                    setAssetDntList(newClubList);
                }
            }
        }).catch(e=>{
            console.error(e);
        })
    }

    const getMoreDntFn = () =>{
        getDntList(assetDntNextCursor);
    }

    const getOpenablePackList = (cursor = 0)=>{
        getMemeTgeCanopenList({cursor: cursor}).then(res=>{
            const _list = cursor === 0 ? res?.dataList || [] : [
                ...openablePackList,
                ...(res?.dataList || [])
            ]
            setOpenablePackList(_list);
            setOpenablePackNextCursor(res?.nextCursor);
        }).catch(e=>{
            console.error(e);
        })
    }

    const getMoreOpenablePackFn = () =>{
        getOpenablePackList(openablePackNextCursor);
    }

    useEffect(()=>{
        getList({
            rankTabType:memeClubShowType,
            rankSortType: sortValue?.sortType,
            isAsc: sortValue?.sortOrder === 0,
            cursor: 0,
        })
    },[memeClubShowType])

    useEffect(()=>{
        if (walletAssetsType === 1){
            getDntList(0);
        }
        else if (walletAssetsType === 2){
            getPackList(0);
        }
    },[walletAssetsType]);

    useEffect(() =>{
        getWalletBalance();
        getMarketInfo();
        getOpenablePackList();
        getDntList();
        if (memeAssetParam?.page === 1 || memeAssetParam?.page === 2){
            setWalletAssetsType(memeAssetParam?.page)
        }
        else{
            getPackList(0);
        }
    }, []);

    const items = [
        {
            key: 1,
            label: `${AutoStrangeRule(t('Ctreated'))}`,
            children: <List list={memeClubsShowList} nextCursor={nextCursor} getMoreFn={getMoreFn}></List>,
        }, {
            key: 2,
            label: `${AutoStrangeRule(t('Favorited'))}`,
            children: <List list={memeClubsShowList} nextCursor={nextCursor} getMoreFn={getMoreFn}></List>,   
        }, {
            key: 3,
            label: `${AutoStrangeRule(t('Holding Pack & Coin'))}`,
            children: <List list={memeClubsShowList} nextCursor={nextCursor} getMoreFn={getMoreFn}></List>,   
        }
    ]

    const convertPriceValue = (value) =>{
        if (value !== undefined && value !== null){
            if (value < 0.00001){
                return '<0.00001';
            }
            else if (value < 10){
                return `$${value.toFixed(5)}`;
            }
            else {
                return `$${Number(value.toFixed(2)).toLocaleString("en-US")}`;
            }
        }
        return '--';
    }

    return (
        <div className={`${s.modalWrap} mainWrap commonBlueBg`}>
            <MainHeader leftTitle={t('MY ASSETS')} headerType={2} isGameHeader={true} hasBackBtn={false} rightMenuIcon={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={s.modal}>
                <div className={`${s.topbar} flex_center_between`}>
                    <div className={`${s.topbarBtn} ${totalType === 0 ? s.selecedType : s.unselectedType} fb flex_center_center`}
                        onClick={() => {
                            setTotalType(0);
                        }}>
                        {AutoStrangeRule(t('Assets'))}
                    </div>
                    <div className={`${s.topbarBtn} ${totalType === 1 ? s.selecedType : s.unselectedType} fb flex_center_center`}
                        onClick={() => {
                            setTotalType(1);
                        }}>
                        {AutoStrangeRule(t('My Creations 1'))}
                    </div>
                    <div className={`${s.memeAssetRedPointRoot}`}>
                        <CustomIcon width={28} height={28} imgName={'Picture/UI_Picture_icon_Notification'} onClick={() =>{
                            setShowMemeAssetNewPack(true);
                        }}/>
                        {
                            unreadMemeAssetNewPackNum > 0 && 
                            <div className={`${s.memeAssetRedPoint} flex_center_center`}>
                                <span className={`${s.memeAssetRedPointText}`}>
                                    {unreadMemeAssetNewPackNum > 99 ? '99+':unreadMemeAssetNewPackNum}
                                </span>
                            </div>
                        }
                    </div>
                </div>
                {
                    totalType === 0 && 
                    <>
                        <div className={`${s.totalValueRoot} flex_center_start_col`}>
                            <div className={`${s.totalValueRootBg} flex_center_between`}>
                                <div className={`${s.totalValueRootLeft} flex_center_center_col`}>
                                    <div className={`${s.totalAssetText}`}>
                                        {t('Total Aseets Value')}
                                    </div>
                                    <div className={`${s.totalAssetValue}`}>
                                        {`$ ${(nativeAssetValue + dntAssetValue + packAssetValue).toLocaleString("en-US")} USD`}
                                    </div>
                                </div>
                                <div className={`${s.totalValueDepositBtn} flex_center_center`} onClick={() =>{
                                    setShowDepositPanel(true);
                                }}>
                                    {AutoStrangeRule(t('DEPOSIT'))}
                                </div>
                            </div>
                        </div>
                        <div className={`${s.walletAssetsTypeLine} flex_center_start`}>
                            <div className={`${s.walletAssetsTypeBtn} flex_center_center_col`}
                                onClick={() => {
                                    setWalletAssetsType(0);
                                }}>
                                <div className={`${ walletAssetsType === 0 ? s.selecedTypeYellow : s.unselectedTypeYellow}`}>
                                    {t('FT')}
                                </div>
                                {
                                    walletAssetsType === 0 && 
                                    <div className={`${s.walletAssetsTypeBtnSubline}`}>
                                    </div>
                                }
                            </div>
                            <div className={`${s.walletAssetsTypeBtn} flex_center_center_col`}
                                onClick={() => {
                                    setWalletAssetsType(1);
                                }}>
                                <div className={`${ walletAssetsType === 1 ? s.selecedTypeYellow : s.unselectedTypeYellow}`}>
                                    {t('My Coins')}
                                </div>
                                {
                                    walletAssetsType === 1 && 
                                    <div className={`${s.walletAssetsTypeBtnSubline}`}>
                                    </div>
                                }
                            </div>
                            <div className={`${s.walletAssetsTypeBtn} flex_center_center_col`}
                                onClick={() => {
                                    setWalletAssetsType(2);
                                }}>
                                <div className={`${ walletAssetsType === 2 ? s.selecedTypeYellow : s.unselectedTypeYellow}`}>
                                    {t('Packs')}
                                </div>
                                {
                                    walletAssetsType === 2 && 
                                    <div className={`${s.walletAssetsTypeBtnSubline}`}>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            walletAssetsType === 0 &&
                            <AssetList 
                                walletAssetsType={walletAssetsType}
                                list={nativeTokenList}
                                marketInfo={marketInfo}
                            />
                        }
                        {
                            walletAssetsType === 1 &&
                            <AssetList 
                                walletAssetsType={walletAssetsType}
                                list={assetDntList}
                                marketInfo={marketInfo}
                                nextCursor={assetDntNextCursor}
                                getMoreFn={getMoreDntFn}
                            />
                        }
                        {
                            walletAssetsType === 2 &&
                            <AssetList 
                                walletAssetsType={walletAssetsType}
                                list={assetPackList}
                                marketInfo={marketInfo}
                                nextCursor={assetPackNextCursor}
                                getMoreFn={getMorePackFn}
                                highlightPackItemId={highlightPackItemId}
                            />
                        }
                    </>
                }
                {
                    totalType === 1 && 
                    <>
                        <div className={`${s.walletAssetsTypeLine} flex_center_start`}>
                            <div className={`${s.walletAssetsTypeBtn} flex_center_center_col`}
                                onClick={() => {
                                    if (memeClubShowType !== 1){
                                        setNextCursor(0);
                                        setMemeClubShowType(1);
                                    }
                                }}>
                                <div className={`${ memeClubShowType === 1 ? s.selecedTypeYellow : s.unselectedTypeYellow} textnowrap`}>
                                    {t('Created')}
                                </div>
                                {
                                    memeClubShowType === 1 && 
                                    <div className={`${s.walletAssetsTypeBtnSubline}`}>
                                    </div>
                                }
                            </div>
                            <div className={`${s.walletAssetsTypeBtn} flex_center_center_col`}
                                onClick={() => {
                                    if (memeClubShowType !== 2){
                                        setNextCursor(0);
                                        setMemeClubShowType(2);
                                    }
                                }}>
                                <div className={`${ memeClubShowType === 2 ? s.selecedTypeYellow : s.unselectedTypeYellow} textnowrap`}>
                                    {t('Favorited')}
                                </div>
                                {
                                    memeClubShowType === 2 && 
                                    <div className={`${s.walletAssetsTypeBtnSubline}`}>
                                    </div>
                                }
                            </div>
                            {
                                openablePackList?.length > 0 &&
                                <div className={`${s.walletAssetsTypeBtn} flex_center_center_col`}
                                    onClick={() => {
                                        if (memeClubShowType !== 3){
                                            setNextCursor(0);
                                            setMemeClubShowType(3);
                                        }
                                    }}>
                                    <div className={`${ memeClubShowType === 3 ? s.selecedTypeYellow : s.unselectedTypeYellow} textnowrap`}>
                                        {t('Unpack Required')}
                                    </div>
                                    {
                                        memeClubShowType === 3 && 
                                        <div className={`${s.walletAssetsTypeBtnSubline}`}>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className={`${s.collectionListRoot} flex_center_start_col`}>
                            {
                                (memeClubShowType === 1 || memeClubShowType === 2) &&
                                <List 
                                    list={[...memeClubsShowList]} 
                                    nextCursor={nextCursor} 
                                    getMoreFn={getMoreFn}></List>
                            }
                            {
                                memeClubShowType === 3 &&
                                <List 
                                    list={[...openablePackList]} 
                                    nextCursor={openablePackNextCursor} 
                                    getMoreFn={getMoreOpenablePackFn}></List>
                            }
                        </div>
                        {/*<div className="wrap">
                            <Tabs
                                defaultActiveKey={1}
                                items={items}
                                className="tptabs"
                                onChange={(activeKey)=>{
                                    setNextCursor(0)
                                    setMemeClubShowType(Number(activeKey))
                                }}
                                destroyInactiveTabPane={true}>
                            
                            </Tabs>
                        </div>*/}
                    </>
                }
            </div>

            <AntModal
                width='330px'
                title={t('Get More xx', {coin: 'Crypto'})}
                className="confirmModalWrap"
                centered={true}
                open={showDepositPanel}
                onOk={() => setShowDepositPanel(false)}
                onCancel={() => setShowDepositPanel(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModelDeposit 
                    topUpCode={topUpCode} 
                    walletAddr={walletAddr} 
                    setShowDepositPanel={setShowDepositPanel}
                    chainTokenInfo={null}/>
            </AntModal>

            <AntModal
                width='330px'
                title={''}
                className="confirmModalWrap"
                centered={true}
                open={showMemeAssetNewPack}
                onOk={() => setShowMemeAssetNewPack(false)}
                onCancel={() => setShowMemeAssetNewPack(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMemeAssetNewPack 
                    closeHandle={()=>{
                        setShowMemeAssetNewPack(false);
                    }}/>
            </AntModal>

            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowMemeClubSort(false)}
                width={245}
                open={showMemeClubSort}
                destroyOnClose={true}
                className="hireSort"
            >
                <MemeAssetClubSort
                    closeHandle={() => {
                        setShowMemeClubSort(false);
                    }}
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                    applySortFn={({rankSortType,isAsc})=>{
                       setNextCursor(0)
                       getList({
                            rankTabType:memeClubShowType,
                            rankSortType,
                            isAsc,
                            cursor:0,
                        })
                    }}
                />
            </AntDrawer>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        walletBalanceList: app.walletBalanceList,
        memeDntBalanceList: app.memeDntBalanceList,
        lastUpdateMemeDntBalanceListTime: app.lastUpdateMemeDntBalanceListTime,
        memeAssetParam: app.memeAssetParam,
        memeAssetUnpackList: app.memeAssetUnpackList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setMemeDntBalanceList: (params) => {
            return dispatch({
                type: "app/setMemeDntBalanceList",
                payload: params,
            });
        },
        setLastUpdateMemeDntBalanceListTime: (params) => {
            return dispatch({
                type: "app/setLastUpdateMemeDntBalanceListTime",
                payload: params,
            });
        },
        setMemeAssetParam: (params) => {
            return dispatch({
                type: "app/setMemeAssetParam",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Assets));
