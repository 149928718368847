import React, {memo, useCallback, useEffect, useMemo} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import CustomIcon from "../CustomIcon";
import {useState} from "react";
import {Button, Modal} from "antd";
import {
    copyFn,
    sliceStr,
    stringToNumber,
    unit,
    getuserinfo,
    PLATFORM_DATA,
    gettrade_price,
    displayFilter,
    getAvatarFromUserInfo
} from "@/utils/common";
import ModelDeposit from "../../model/ModelDeposit";
import {useNavigate, useLocation} from "react-router-dom";
import {bannerData} from "@/utils/taskJson";
import ModelWishTrans from "src/model/ModelWishTrans";
import AvatarInfo from "../AvatarInfo";
import {mainHomePath} from "@/routes/config";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";

const WishClaim = (
    {
        userInfo,
        setUserInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [showTransPanel, setShowTransPanel] = useState(false);
    const [allDoneBuy, setAllDoneBuy] = useState(false);
    const [points, setPoints] = useState(bannerData?.link?.points);

    const {
        userId,

        topUpCode,
        walletAddr,
        platformData,
        wishFee,
        holdersNum,
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo])

    const {balance} = useMemo(() => {
        return selfData || {}
    }, [selfData])

    const {platformMap, useType, defaultName} = useMemo(() => {
        return platformData || {}
    }, [platformData])


    const platform = useMemo(() => {
        if (platformMap && JSON.stringify(platformMap) !== '{}') {
            return platformMap[useType]
        } else {
            return {
                displayName: defaultName
            }
        }
    }, [platformMap, useType, defaultName])

    const {displayName, profileDeepLink, accountName} = useMemo(() => {
        return platform || {}
    }, [platform])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformMap, useType, userInfo])

    const reFrish = useCallback(() => {
        userId && getuserinfo(userId).then(res => {
            const selfData = res?.selfData || {};

            setUserInfo({
                payEntryFee: selfData?.payEntryFee,
                buyFirstKey: selfData?.buyFirstKey,
                balance: selfData?.balance,
                youOwnKeyNum: res?.youOwnKeyNum,
                wishFee: res?.wishFee,
            })

        }).catch(e => {
            console.log(e);
        })
    }, [userInfo])

    const colsePage = () => {
        localStorage.setItem('_TT_showBalance', false)
        navigate(mainHomePath, {replace: true});
    }

    useEffect(() => {
        if (!userInfo?.selfData.wishNeedClaim) {
            colsePage();
        }
    }, [userInfo])


    const buyFn = () => {
        setShowTransPanel(true)
    }

    useEffect(() => {
        localStorage.setItem('_TT_showWishLinkNeed', false);
    }, []);

    const [currentKeyPriceTotal, setCurrentKeyPriceTotal] = useState('--');
    const [currentKeyPriceTotalUSD, setCurrentKeyPriceTotalUSD] = useState('--');

    useEffect(() => {
        gettrade_price(2, userInfo?.userId, userInfo?.selfData?.wishClaimKeyNum).then(res => {
            setCurrentKeyPriceTotal(res?.keyPrice);
            setCurrentKeyPriceTotalUSD(res?.keyPrice * res?.maticUsd);
        })
    }, [])


    return (
        <>
            <div className={`${s.modalWrap} abs`}>
                <div className={`${s.modal}`}>
                    {/* <CustomIcon width={197} height={86} imgName={'TURNUP_Horizontal_Tilted_Colour@2x'} name={'TURNUP_Horizontal_Tilted_Colour@2x'} /> */}
                    <div className="mt10">
                        <div onClick={() => navigate(-1)} className={s.backIcon}><CustomIcon width={29} height={26}
                                                                                             imgName={'Button/UI_Button_Exit_01'}
                                                                                             name={'Button/UI_Button_Exit_01'}/>
                        </div>
                        <AvatarInfo src={originalAvatarUrl || avatarUrl} size={85}/>
                    </div>

                    <div className="fs16 fb mtb5">{displayFilter(displayName)}</div>
                    {useType !== 0 &&
                        <div className="color-gray fs12">{accountName} <CustomIcon width={16} height={16}
                                                                                   imgName={PLATFORM_DATA[useType]?.iconName}
                                                                                   name={PLATFORM_DATA[useType]?.iconName}/>
                        </div>
                    }
                    <div
                        className="fs14 mtb5">{t('Wish Member', {member: holdersNum > 1 ? t('Members') : t('Member')})}: <span
                        className="fb color-active">{holdersNum}</span></div>
                    {/* <div className={`${s.info} mb10`}>
            <CustomIcon width={39} height={29} imgName={'icon_info'} name={'icon_info'} />
            <div className="tll ml5 fs12">
              Congratulations,
              you have verified your ownership.
              All the royalty fees gathered has been transferred to your account

            </div>
          </div> */}
                    <div className="mt10 tll color-white fs16 fl">
                        {t('Congratulations! You have verified your ownership.')}
                        {t('All the royalty fees gathered have been transferred to your account.')}
                        {t('The first 20 keys of this Wish have been reserved for you.')}
                        {t('Purchase them to set up your profile.')}
                    </div>
                    {/* <div className={s.item}>
            <div className={s.contentWrap}>
              <div className="fs14 mb10">Deposit to Your TURNUP Wallet Address Below</div>
              <div className={s.addr}>
                <div className="fs18 fb" style={{wordBreak:'break-all'}}>{walletAddr}</div>
                <div className="ml10" onClick={() => { copyFn(walletAddr) }}><CustomIcon width={18} height={18} imgName={'Button/UI_Button_CopyNumberWordName_01'} name={'Button/UI_Button_CopyNumberWordName_01'} /></div>
              </div>
            </div>
          </div> */}
                    {/* <div className={s.balance}>
            <div className={s.bg}></div>
            <div>
              <div className="fs12">Unclaimed Royalty Fee:</div>
              <div className="color-green fs16 fb">{wishFee === "" ? 0 : stringToNumber(wishFee, 4)} <span className="color-green fs10 fb">{unit}</span></div>
            </div>
            <div className={s.balanceBottom}>
              <div>
                <div className="fs10">Price of {userInfo?.selfData?.wishClaimKeyNum} Keys</div>
                <div className="fs16 fb">{stringToNumber(userInfo?.selfData?.wishClaimKeyTotalPrice, 4)} <span className="fs10 color-white">{unit}</span></div>
              </div>
              <div className="ml20">
                <div className="fs10">Est. selling price</div>
                <div className="fs16 fb">{stringToNumber(currentKeyPriceTotal, 4)} <span className="fs10 color-white">{unit}</span> <span className="fs10 color-green">$ {stringToNumber(currentKeyPriceTotalUSD, 2)} USD</span></div>
              </div>
            </div>
          </div> */}
                    <div className={s.balance}>
                        <div>
                            <div className="">{t('Unclaimed Royalty Fee')}:</div>
                            <div
                                className="color-greenup fs20 fb">{wishFee === "" ? 0 : stringToNumber(wishFee, 4)} {unit}</div>
                        </div>
                        <div className={s.balanceBottom}>
                            <div>
                                <div className="">{t('Price of 20 Keys')}</div>
                                <div
                                    className="fs18 fb">{stringToNumber(userInfo?.selfData?.wishClaimKeyTotalPrice, 4)} {unit}</div>
                            </div>
                            <div className="ml40">
                                <div className="">{t('Est. selling price')}</div>
                                <div className="fs18 fb">{stringToNumber(currentKeyPriceTotal, 4)} {unit} </div>
                                <div className="color-white fs14 fl">${stringToNumber(currentKeyPriceTotalUSD, 2)} USD
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={s.bottom}>
                    <div onClick={() => copyFn(walletAddr)}>
                        {sliceStr(walletAddr, 6)}
                        <CustomIcon width={18} height={18} className="ml10"
                                    imgName={'Button/UI_Button_CopyNumberWordName_01'}
                                    name={'Button/UI_Button_CopyNumberWordName_01'}/>
                    </div>
                    <div className={s.balanceWrap}>
                        <div className={s.refrish} onClick={reFrish}>
                            <CustomIcon width={22} height={19} isHaveClick={true} imgName={'Button/UI_Button_reload_01'}
                                        name={'Button/UI_Button_reload_01'}/>
                        </div>
                        <div className="fs20 fb">{stringToNumber(balance, '4')} <span className="fs14">{unit}</span>
                        </div>
                        <div className="fs12">{t('My Wallet Balance')}</div>
                    </div>
                    <div className={s.btnWrap}>
                        <AntButton
                            onClick={() => {
                                allDoneBuy ? colsePage() : buyFn()
                            }}
                            className={`${s.btn} btn_public mt10`}>
                            {allDoneBuy ? t('All Done') : t('CLAIM WISHES')}
                        </AntButton>
                        <AntButton onClick={colsePage} className={`${s.btn} btn_dark mt20`}>
                            {t('MAYBE LATER')}
                        </AntButton>
                    </div>

                </div>
            </div>
            <AntModal
                width='330px'
                title={t('GET MORE MATIC')}
                className="confirmModalWrap"
                centered={true}
                open={showDepositPanel}
                onOk={() => setShowDepositPanel(false)}
                onCancel={() => setShowDepositPanel(false)}
            >
                <ModelDeposit topUpCode={topUpCode} setShowDepositPanel={setShowDepositPanel}/>
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={showTransPanel}
                destroyOnClose={true}
                onOk={() => setShowTransPanel(false)}
                onCancel={() => setShowTransPanel(false)} Link
            >
                <ModelWishTrans
                    type={1}
                    keyId={userId}
                    profile={platform}
                    isBuyFristKey={true}
                    keyNumNeed={userInfo?.selfData?.wishClaimKeyNum}
                    closeHandle={() => {
                        setShowTransPanel(false)
                    }}
                />
            </AntModal>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        initTopUpAmount: app.initTopUpAmount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(WishClaim));
// export default memo(Balance);
