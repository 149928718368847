import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Slider, Checkbox} from "antd";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";

const MemeAssetClubSort = (
    {
        closeHandle,
        sortValue,
        setSortValue,
        applySortFn = ()=>{},
    }
) => {
    const {t, i18n} = useTranslation();
    const [sortOrder, setSortOrder] = useState(sortValue?.sortOrder ? sortValue?.sortOrder:0);//0:ascending,1:descending
    const [sortType, setSortType] = useState(sortValue?.sortType ? sortValue?.sortType:1);

    const sortData = [
        {
            label: t('Holding Coin\'s Value'),
            key: 1,
        },
        {
            label: t('Holding Packs\'s Amount'),
            key: 2,
        },
        {
            label: t('TGE Progress'),
            key: 3,
        },
    ]
    const apply = async () => {
        setSortValue({
            sortOrder: sortOrder,
            sortType: sortType,
        });
        applySortFn({
            rankSortType:sortType,
            isAsc: sortOrder === 0
        });
        closeHandle();
    }
    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <CustomIcon width={25} className="mr5" height={25} imgName={'Button/UI_Button_UpAndDown_01'}/> 
                {AutoStrangeRule(t('SORTING'))}
            </div>
            <div className={s.filterCon}>
                <div className={s.sortConlist}>
                    {sortData?.length && sortData.map(item => {
                        const {label, key} = item;
                        return (
                            <div className={s.sortOption} key={key}>
                                <Checkbox onChange={() => setSortType(key)} className="hireCheckBox"
                                          checked={sortType === key} defaultChecked={sortType === key}>
                                    <span className="">{label}</span>
                                </Checkbox>
                            </div>
                        )
                    })}

                </div>
            </div>
            <div className={s.bottom}>
                <AntButton className={`${sortOrder === 0 ? 'btn_white' : 'btn_dark'} mb10`}
                           onClick={() => setSortOrder(0)}>
                    {sortOrder === 0 && <CustomIcon width={15} height={15}
                                                  imgName={'Button/UI_Button_UP_02'}/>}
                    {sortOrder === 1 && <CustomIcon width={15} height={15}
                                                  imgName={'Button/UI_Button_UP_01'}/>}
                    {t('Ascending')}
                    &nbsp;
                </AntButton>
                <AntButton className={`${sortOrder === 1 ? 'btn_white' : 'btn_dark'} mb10`}
                           onClick={() => setSortOrder(1)}>
                    {sortOrder === 0 && <CustomIcon width={15} height={15}
                                                  imgName={'Button/UI_Button_Down_01'}/>}
                    {sortOrder === 1 && <CustomIcon width={15} height={15}
                                                  imgName={'Button/UI_Button_Down_02'}/>}
                    {t('Descending')}
                </AntButton>
                <AntButton className="btn_public mb10" onClick={apply}>{t('APPLY')}</AntButton>
            </div>

        </div>
    )
}
export default memo(MemeAssetClubSort);
