import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button} from "antd";
import CustomIcon from "../../common/CustomIcon";
import imgUrl from "@/assets/images/FingersCrossed.png"

const CrackModelRateIntro = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className={s.wrap}>
            <div className={s.img}>
                <img src={'./UI/Picture/UI_Picture_FingersCrossed_01.png'}/>
            </div>
            <div className={`${s.text} fs12 mt70`}>
                <div className="color-white fl tll">
                    {t('The loot success rate is determined by the power factor. A higher loot fee increases the success rate odds.')}
                </div>
                <div className="fl tll" style={{color: '#f6ff00'}}>
                    {t('Success rate = (1-(R/F)/((R/F)+4))*(A*1.5+0.01)/(A*1.5+D+0.02)*2) * 100%')}
                </div>
                <div className="fl tll" style={{color: '#f6ff00'}}>
                    {t('F = Loot fee paid')}
                </div>
                <div className="fl tll" style={{color: '#f6ff00'}}>
                    {t('R = Expected returns')}
                </div>
                <div className="fl tll" style={{color: '#f6ff00'}}>
                    {t('A = Attacker\'s power')}
                </div>
                <div className="fl tll" style={{color: '#f6ff00'}}>
                    {t('D = Defender\'s power')}
                </div>
                <div className="fl tll" style={{color: '#f6ff00'}}>
                    {t('Delta of level')}
                </div>
                <div className="color-white fl tll">
                    {t('Increasing loot fee can lead to maximum success rate limit of 90%.')}
                </div>
            </div>
        </div>
    )
}
export default memo(CrackModelRateIntro);
