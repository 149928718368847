import React, {memo, useId, useMemo, useState} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import AvatarInfo from "../AvatarInfo";
import { connect } from "react-redux";
import {
    displayFilter, joinChat, renderTime,
    getAvatarFromProfile,
    getFriendtrade_loot_tarinfo,
} from '@/utils/common';
// import useLongPress from "@/utils/useLongPress";
import {useNavigate} from "react-router-dom";
import {Button, Dropdown} from 'antd';
import CustomIcon from "../CustomIcon";
import {
    gameTrillionaireBattlePath,
    gameTrillionaireBattlingPath,
} from "@/routes/config";
import ButtonFactory from "../ButtonFactory";

const MessageLeft = (
    {
        message,
        userId,
        setQuote,
        keyId,
        managerId,
        showNotice,
        isMoment = false,
        userInfo,
        setLootTargetUserInfo,
    }
) => {
    const { t, i18n } = useTranslation();
    const { msg, ext, time, from, status, type, customExts, customEvent } = message;
    const quoteObj = ext?.quote;
    const navigate = useNavigate();
    const isSelfChat = userId.toString() === from
    const isSystemNotify = customEvent === 'systemNotify' || customEvent === 'activityEvent';

    const isManager = (managerId?.toString() || false) === from

    const onLongPress = () => {
        setQuote(message)
    }
    // const longPressEvent = useLongPress(onLongPress);
    const items = [
        {
            label: <div onClick={() => {
                setQuote(message)
            }}>{t('Reply')}</div>,
            key: '0',
        }
    ]

    const quickLoot = (userId) => {
        getFriendtrade_loot_tarinfo(Number(userId) || 0).then(res => {
            setLootTargetUserInfo(res?.targetData);
            navigate(gameTrillionaireBattlingPath);
        }).catch(e => {
            console.error(e);
            navigate(gameTrillionaireBattlingPath);
        })
    }

    return (
        isMoment ?
            isSystemNotify ?
                <>
                    {
                        customEvent === 'activityEvent' ?
                            <div className={`${s.momentModalWrap} w100p ${s.activityEventWrap}`}>
                                {msg}
                            </div> :
                            showNotice ?
                                <div className={`${s.modalWrap} ${isSelfChat ? s.self : ''}`}>
                                    <div className={s.systemNotify}>
                                        <div className="mb5 color-blue-light fs14 tll">{renderTime(time)}</div>
                                        <div className={`fs14 tll ${s.systemMeg}`}>
                                            {customExts?.msg}
                                            {userId.toString() === keyId.toString() && (customExts?.msgId === "5" || customExts?.msgId === "11" || customExts?.msgId === "12") ?
                                                <ButtonFactory
                                                    size={33}
                                                    color={3}
                                                    onClick={() => {
                                                        quickLoot(customExts?.lootMgrId);
                                                    }}>{t('REVENGE')}</ButtonFactory> : ''}
                                        </div>

                                    </div>
                                </div> :
                                null
                    }

                </>

                :
                <div className={`${s.momentModalWrap} ${isSelfChat ? s.self : ''}`}>

                    {isSelfChat ?
                        <>
                            <div className={`${s.right} ${s.self} ${s.txtRootSelf}`}>
                                <div className={`w100p flex_center_between`}>
                                    <div className={`fs10  mr10 ${isMoment && 'momentFont color-moment-gray'}`}>
                                        {renderTime(time)}
                                    </div>
                                    <div className={`flex_center_start`}>
                                        <div className={`momentFont fs12 fb`}>
                                            {t('You')}
                                        </div>
                                    </div>
                                </div>
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="top"
                                    trigger={['click']}
                                    arrow={{ pointAtCenter: true }}
                                >
                                    <div className={isMoment ? s.msgTextMoment : s.msgText}>
                                        {
                                            quoteObj && JSON.stringify(quoteObj) !== '{}' &&
                                            <div className={s.quite}>
                                                {displayFilter(quoteObj?.ext?.displayName)}:<br /> {quoteObj?.msg}
                                            </div>
                                        }
                                        <div className={`${s.selfMsg} momentFont`}>{msg}</div>

                                    </div>
                                </Dropdown>
                            </div>

                            <div className={s.avatar} onClick={() => {
                                keyId !== from && joinChat(from, navigate, 'key', true, isMoment);
                            }}>
                                <AvatarInfo size={48} src={getAvatarFromProfile(ext)} isMoment={isMoment}> </AvatarInfo>
                                {isManager && <div className={s.manager}>
                                    <div className="flipped-image">
                                        <CustomIcon className="m02" width={30} height={30}
                                            imgName={'Picture/UI_Picture_Crown_01'}
                                            name={'Picture/UI_Picture_Crown_01'} />
                                    </div>
                                </div>}
                            </div>
                        </> :
                        <>
                            <div className={s.avatar} onClick={() => {
                                keyId !== from && joinChat(from, navigate, 'key', true, isMoment);
                            }}>
                                <AvatarInfo size={48} src={getAvatarFromProfile(ext)} isMoment={isMoment}></AvatarInfo>
                                {isManager && <div className={s.manager}>
                                    <CustomIcon className="m02" width={30} height={30}
                                        imgName={'Picture/UI_Picture_Crown_01'}
                                        name={'Picture/UI_Picture_Crown_01'} />
                                </div>}
                            </div>
                            <div className={`${s.right} ${s.txtRoot}`}>
                                <div className={`w100p flex_center_between`}>
                                    <div className={`flex_center_start`}>
                                        <div className={`momentFont fs12 fb mr10`}>
                                            {displayFilter(ext?.displayName)}
                                        </div>
                                    </div>
                                    <div className={`momentFont fs10 color-moment-gray`}>
                                        {renderTime(time)}
                                    </div>
                                </div>
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="top"
                                    trigger={['click']}
                                    arrow={{ pointAtCenter: true }}
                                >
                                    <div className={s.msgText2}>
                                        {
                                            quoteObj && JSON.stringify(quoteObj) !== '{}' &&
                                            <div className={s.quite}>
                                                {displayFilter(quoteObj?.ext?.displayName)}: <br />{quoteObj?.msg}
                                            </div>
                                        }
                                        <div className={`${s.msg} momentFont`}>{msg}</div>
                                    </div>
                                </Dropdown>
                            </div>
                        </>
                    }
                </div>
            :
            isSystemNotify ?
            <>
                {
                    customEvent === 'activityEvent' ?
                        <div className={`${s.modalWrap} w100p ${s.activityEventWrap}`}>
                            {msg}
                        </div> :
                        showNotice ?
                            <div className={`${s.modalWrap} ${isSelfChat ? s.self : ''}`}>
                                <div className={s.systemNotify}>
                                    <div className="mb5 color-blue-light fs14 tll">{renderTime(time)}</div>
                                    <div className={`fs14 tll ${s.systemMeg}`}>
                                        {customExts?.msg}
                                        {userId.toString() === keyId.toString() && (customExts?.msgId === "5" || customExts?.msgId === "11" || customExts?.msgId === "12") ?
                                            <ButtonFactory
                                                size={33}
                                                color={3}
                                                onClick={() => {
                                                    quickLoot(customExts?.lootMgrId);
                                                }}>{t('REVENGE')}</ButtonFactory> : ''}
                                    </div>

                                </div>
                            </div> :
                            null
                }

            </>

            :
            <div className={`${s.modalWrap} ${isSelfChat ? s.self : ''}`}>


                {/* <div className={`fs12`}>{customExts?.msg}</div> */}
                {isSelfChat ?
                    <>
                        <div className={`${s.right} ${s.self}`}>
                            <div className={`color-blue-light`}>{t('You')}</div>
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                placement="top"
                                trigger={['click']}
                                arrow={{ pointAtCenter: true }}
                            >
                                <div className={s.msgText}>
                                    {
                                        quoteObj && JSON.stringify(quoteObj) !== '{}' &&
                                        <div className={s.quite}>
                                            {displayFilter(quoteObj?.ext?.displayName)}:<br /> {quoteObj?.msg}
                                        </div>
                                    }
                                    <div className={s.msg}>{msg}</div>

                                </div>
                            </Dropdown>
                            <div className={`${s.time} color-blue-light `}> {renderTime(time)}</div>
                        </div>

                        <div className={s.avatar} onClick={() => {
                            keyId !== from && joinChat(from, navigate, 'key', true);
                        }}>
                            <AvatarInfo size={45} src={getAvatarFromProfile(ext)}></AvatarInfo>
                            {isManager && <div className={s.manager}>
                                <div className="flipped-image">
                                    <CustomIcon className="m02" width={30} height={30}
                                        imgName={'Picture/UI_Picture_Crown_01'}
                                        name={'Picture/UI_Picture_Crown_01'} />
                                </div>
                            </div>}
                        </div>
                    </> :
                    <>
                        <div className={s.avatar} onClick={() => {
                            keyId !== from && joinChat(from, navigate, 'key', true);
                        }}>
                            <AvatarInfo size={45} src={getAvatarFromProfile(ext)}></AvatarInfo>
                            {isManager && <div className={s.manager}>
                                <CustomIcon className="m02" width={30} height={30}
                                    imgName={'Picture/UI_Picture_Crown_01'}
                                    name={'Picture/UI_Picture_Crown_01'} />
                            </div>}
                        </div>
                        <div className={s.right}>
                            <div className="">{displayFilter(ext?.displayName)}</div>
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                placement="top"
                                trigger={['click']}
                                arrow={{ pointAtCenter: true }}
                            >
                                <div className={s.msgText}>
                                    {
                                        quoteObj && JSON.stringify(quoteObj) !== '{}' &&
                                        <div className={s.quite}>
                                            {displayFilter(quoteObj?.ext?.displayName)}: <br />{quoteObj?.msg}
                                        </div>
                                    }
                                    <div className={s.msg}>{msg}</div>
                                </div>
                            </Dropdown>

                            <div className={`${s.time} color-blue-light`}>
                                {status === 'failed' && (<span className={s.failed}>{t('Send Failed')}</span>)}
                                {renderTime(time)}
                            </div>
                        </div>

                    </>

                }
            </div>


    )
}
const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLootTargetUserInfo: (params) => {
            return dispatch({
                type: "app/setLootTargetUserInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MessageLeft));
