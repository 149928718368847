import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    numberClamp,
    bornCheck_loot,
    convertWalletBalance,
} from "@/utils/common"


import TradeKeyPanel from "../TradeKeyPanel";
import KChart from "../../../KChart";
import MomentClubPack from "@/common/MomentClubPack";
import { moment_owncardlist } from "@/utils/momentClub";
import ModelMomentTgeProgressTips from "@/model/ModelMomentTgeProgressTips";
import AntModal from "@/common/AntModal";
import ModalShareMomentClip from "@/model/ModalShareMomentClip";

const Packs = (
    {
        userInfo,
        clubId,
        clubBasicInfo,
        chainInfo,
        showChartDetail,
        setShowChartDetail,
        sourceData,
        upDate,
        defaultOpenBuy,
        setDefaultOpenBuy,
        setDefaultOpenSell,
        defaultOpenSell,
        closeFn = () => { },
        openBuyDrawer = () => {},
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [buyCheckTimes, setBuyCheckTimes] = useState(0);
    const [cardList, setCardList] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [showModel,setShowModel] = useState(false)
    const [showModalShare, setShowModalShare] = useState(false);
    const [choosedItem, setChoosedItem] = useState(null);
    const [openCoin, setOpenCoin] = useState(0);
    const { icon } = useMemo(() => {
        return chainInfo || {}
    }, [chainInfo])
    const {
        clubUserId,
        curCardKindNum,
        clubInfo,
        historyPrice,
        holdNum,
        
        sellPrice,
        buyPrice,
        isFavorite,
        inMarket,
        creatorProfile,
        creatorUserId,
        coinTokenAddr,
        onAlive,
        announceStartTime,
        announceTitle,
        supply
    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])


    const {
        clubName,
        symbolImageUrl,
        cardKindMaxNum,
        canTGE,
        description,
        twitterLink,
        tgLink,
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    useEffect(() => {
        if (buyCheckTimes > 0) {

        }
    }, [buyCheckTimes]);
    const getClubcardlist = (clubId) => {
        moment_owncardlist(clubId).then(res => {
            setCardList(res?.ownCardList || [])
            setTotalPrice(res?.totalPrice)
            setOpenCoin(res?.openCoin || 0);
        }).catch((e) => {
            console.error(e);
        })
    }

    useEffect(() => {
        clubId && getClubcardlist(clubId)
    }, [clubId])

    const range = historyPrice ? Math.round((sellPrice - historyPrice) / historyPrice * 100) : 0;

    const getTokenUsdValue = (count) => {
        if (!count > 0){
            count = 0;
        }
        return count * 67 / 2000;
    }

    return (
        <div className={`${s.modalWrap} flex_center_between_col`}>
            <div className={s.packWrap}>
                <div className={`${s.chartWrap} ${showChartDetail ? '' : s.chartHide}`}>
                    <div className={`${s.chartTitle} momentFont`} onClick={() => { setShowChartDetail(!showChartDetail) }}>
                        {
                            showChartDetail ? 
                                t('Hide Chart') 
                            : 
                            <div className={`momentFont fs12 flex_center_center`}>
                                {`$${clubName} moment`}
                                <CustomIcon imgName={`Picture/${chainInfo?.icon}`} className="ml5 mr5" width={17} height={17}/>
                                {convertWalletBalance(buyPrice)}
                                {/*<CustomIcon imgName={range < 0 ? 'Picture/UI_Picture_PriceDown_01' : 'Picture/UI_Picture_PriceUP_01'} className="mr5" width={18} height={13}></CustomIcon>
                                <span className={range < 0 ? 'color-red fs12' : 'color-green fs12'}>{range}%</span>*/}
                            </div>
                        }

                        <CustomIcon className={s.chartIcon} onClick={() => { setShowChartDetail(!showChartDetail) }} imgName={showChartDetail ? 'Button/UI_Button_Arrow_Up_02' : 'Button/UI_Button_Arrow_Down_02'} width={19} height={10}></CustomIcon>
                    </div>
                    {showChartDetail && <div className={s.kChartWrap}>
                        <KChart sourceData={sourceData} coinName={chainInfo?.displayCoinName} clubId={clubId} symbolName={clubName}></KChart>
                    </div>
                    }
                </div>
                <div className={`${s.packName} flex_center_start`}>
                    <div className={`fs14 color-moment-light-gray fb`}>
                        {clubName}
                    </div>
                </div>
                <div className={`${s.packDes} flex_center_start`}>
                    <div className={`${s.packDes1} fs12`}>
                        {description}
                    </div>
                </div>
                {
                    (twitterLink || tgLink) &&
                    <div className={`${s.linkLine} flex_start_start_col`}>
                        {
                            tgLink && 
                            <div className={`${s.linkItem} flex_center_start`}>
                                <CustomIcon className={`mr4`} imgName={`Button/UI_Button_White_TurnUp_02`} width={10} height={10} />
                                <div className={`fs10 color-moment-gray fw-400`}>
                                    {tgLink}
                                </div>
                            </div>
                        }
                        {
                            twitterLink && 
                            <div className={`${s.linkItem} flex_center_start`}>
                                <CustomIcon className={`mr4`} imgName={`Button/UI_Button_White_Twitter_01`} width={10} height={10} />
                                <div className={`fs10 color-moment-gray fw-400`}>
                                    {twitterLink}
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    cardList?.length > 0 ?
                        <div className={`${s.packListRoot} flex_center_start_col`}>
                            <div className="flex_center_between w100p" style={{padding: `0px 9px`}}>
                                <div className={`fs14 momentFont fb color-moment-light-gray`}>{t('My Moments List')}</div>
                            {
                                canTGE && 
                                <div className={`${s.tokenAmount} flex_center_center_col`}>
                                    <div className={`flex_center_end w100p`}>
                                        <div className={`fs12 momentFont color-moment-light-gray`}>
                                            {t('Est. airdrop')} &nbsp;
                                        </div>
                                        <div className={`fs16 momentFont color-EEEEEE fb mr3`}>
                                            {Number(getTokenUsdValue(openCoin || 0).toFixed(2)).toLocaleString("en-US")}
                                        </div>
                                        <div className={`fs10 color-moment-gray fw-400`}>
                                            {t('USD')}
                                        </div>
                                    </div>
                                </div>
                            } 
                            </div>
                            <div className={`${s.packListBg}`} style={{padding:'0 4px'}}>
                                {
                                    cardList?.map(item => {
                                        const { cardNo, cardAmount, cardUrl, holdCard, expectedPrice } = item
                                        return (
                                            // <div className={s.item} key={cardNo}>
                                            //     <div className={``}>
                                            //         <MemeClubPack
                                            //             width={40}
                                            //             height={62}
                                            //             imageUrl={cardUrl}
                                            //             number={cardNo}
                                            //             view={true}
                                            //             packName={``} >
                                            //         </MemeClubPack>
                                            //     </div>
                                            //     <div className={s.iR}>
                                            //         <div className="fs16">{t('Farme #{{x}}', { x: cardNo })}</div>
                                            //         <div className={s.iRB}>
                                            //             <div className="flex_start_start_col">
                                            //                 <div className="fs10">{t('Supply')}</div>
                                            //                 <div className="fs20">{cardAmount}</div>
                                            //             </div>
                                            //             <div className="flex_center_start_col">
                                            //                 <div className="fs10">{t('Mine')}</div>
                                            //                 <div className="fs20">{holdCard || '--'}</div>
                                            //             </div>
                                            //             <div className="flex_end_start_col">
                                            //                 <div className="fs10">{t('Est Price Per Card')}</div>
                                            //                 <div className="fs16">$ {convertWalletBalance(expectedPrice)}</div>
                                            //             </div>
                                            //         </div>
                                            //     </div>
                                            // </div>
                                            <div className={s.itemPack} key={cardNo}>
                                                <MomentClubPack
                                                    width={74}
                                                    imageUrl={cardUrl}
                                                    number={cardNo}
                                                    packName={``}
                                                    type={2}
                                                    onClick={() => {
                                                        setChoosedItem(item);
                                                        setShowModalShare(true);
                                                    }} >
                                                </MomentClubPack>
                                                <div className={`${s.amount} flex_end_center`}>
                                                    <span className="fs12 fb momentFont" style={{marginBottom:`1px`}}>x</span>
                                                    <span className="fs16 fb momentFont">{cardAmount}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> :
                        <div className={`${s.emptyRoot} flex_center_center_col`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_Moment_ReminderBg_01.png)`}}>
                            <div className={`fs18 color-EEEEEE fb tlc`}>
                                <Trans
                                    i18nKey="You found a valuable moment <br> buy and hold!"
                                    defaults="You found a valuable moment <br> buy and hold!"
                                    components={{br: <br/>}}
                                />
                            </div>
                        </div>
                }
            </div>
            <div className={`${s.transPart} flex_center_start_col`}>
                {canTGE && 
                <>
                <div className={`${s.transBox} flex_center_between`}>
                    <div className="flex_center_between w100p">
                        <div className="flex_center_start fs14 fb momentFont color-moment-light-gray">
                            {t('TGE Progress')}
                            <CustomIcon onClick={()=>{setShowModel(true)}} className="ml5 mr5" imgName={`Button/UI_Button_QuestionMark_04`} width={17} height={17}></CustomIcon>
                        </div>
                        <div className="fs10 flex_center_end" style={{width: `56%`}}>
                            <div className={`${s.progress} flex_center_center`}>
                                <div className={s.proWrap}>
                                    <div className={s.l}>
                                        <div className={s.proVal} style={{
                                            width: `${Math.round(curCardKindNum / cardKindMaxNum * 100)}%`
                                        }}>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className={`fs12 momentFont fb color-moment-gray ml8`}>
                                {curCardKindNum}/{cardKindMaxNum}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${s.line}`}>
                
                </div>
                </>
                } 

                <TradeKeyPanel 
                    upDate={() => {
                        getClubcardlist(clubId)
                        upDate()
                    }}
                    clubBasicInfo={clubBasicInfo}
                    hasBorder={false}
                    defaultOpenBuy={defaultOpenBuy}
                    setDefaultOpenBuy={setDefaultOpenBuy}
                    setDefaultOpenSell={setDefaultOpenSell}
                    defaultOpenSell={defaultOpenSell}
                    sourceData={sourceData}
                    openBuyDrawer={openBuyDrawer}
                ></TradeKeyPanel>
            </div>
            <AntModal
                width='358px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={showModel}
                destroyOnClose={true}
                onOk={() => setShowModel(false)}
                onCancel={() => setShowModel(false)}
            >
                <ModelMomentTgeProgressTips 
                curCardKindNum={curCardKindNum}
                clubName={clubName}
                closeHandle={() => {
                    setShowModel(false);
                    
                }} clubId={clubId} amount={cardKindMaxNum} />
            </AntModal>
            <AntModal
                width='330px'
                title=""
                className="momentModalWrap"
                centered={true}
                open={showModalShare}
                destroyOnClose={true}
                onOk={() => setShowModalShare(false)}
                onCancel={() => setShowModalShare(false)}
            >
                <ModalShareMomentClip 
                    closeHandle={() => {
                        setShowModalShare(false);  
                    }}
                    canTGE={canTGE}
                    choosedItem={choosedItem}
                    clubBasicInfo={clubBasicInfo}
                    totalInfo={null} />
            </AntModal>
        </div>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Packs));
