import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';

const ModalExtraLife = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className={s.wrap}>
            <div className={s.text}>
                <div className="fs16 color-white  tll">
                    {t('For every invitee that joins using your referral code, you get an extra life for the Daily Trivia game. This could potentially increase you chances of winning daily rewards.')}
                </div>
            </div>
        </div>
    );
};
export default memo(ModalExtraLife);
