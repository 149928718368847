import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    copyFn,
    stringToNumber,
    convertWalletBalance,
    getFriendtradeLootLastevent,
    searchuser,
    displayFilter,
    getAvatarFromUserInfo,
    getProfile,
    convertUsdValue,
    addJoinChartPre,
} from "@/utils/common"
import AntModal from "@/common/AntModal";
import {
    coinList,
    momentTgeClublist,
    setFavorite,
    getRecommendClub,
    searchclub,
    getChainInfo,
    getMomentTradevolInfo,
} from "@/utils/momentClub";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntDrawer from "@/common/AntDrawer";
import {
    gameMomentClubCreatPath,
    gameMomentClubExplorePath,
    gameMomentClubDetail,
    gameMomentClubDetailPath,
    gameReferralPath,
    gameMomentClubLotteryPath,
} from "@/routes/config";
import AvatarInfo from "@/common/AvatarInfo";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import MemeClubPack from "@/common/MemeClubPack";
import ModalGetInviteCode from "@/model/ModalGetInviteCode";
import ModelReferralShare from "@/model/ModelReferralShare";
import ModalMemeSharingDetail from "@/model/ModalMemeSharingDetail";
import momentTradeVolSharingRate from "@/utils/json/momentTradeVolSharingRate.json";

const MomentLauncher = (
    {
        userInfo,
        setHideRefreshBtn,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [openSharePanel, setOpenSharePanel] = useState(false);
    const [showGetInviteCode, setShowGetInviteCode] = useState(false);
    const [showMomentSharingDetail, setShowMomentSharingDetail] = useState(false);
    const [tradevolInfo, setTradevolInfo] = useState(null);
    const {
        walletAddr,
        topUpCode,
        userId,
        referralTotalEarn,
        platformData,
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        bindReferralCode,
        referralCode,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const { platformMap, useType, defaultName } = useMemo(() => {
        return platformData || {}
    }, [platformData]);

    const { displayName, accountName, profileDeepLink } = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData]);

    const { avatarUrl, originalAvatarUrl } = useMemo(() => {
        return getAvatarFromUserInfo(userInfo);
    }, [userInfo]);

    const imageList = useMemo(() => {
        let res = [];
        if (tradevolInfo?.imageUrls?.length > 0) {
            for (let i = 0; i < tradevolInfo?.imageUrls?.length && i < 5; i++) {
                res.push(tradevolInfo?.imageUrls[i]);
            }
        }
        return res;
    }, [tradevolInfo]);

    const tradeConfig = useMemo(() => {
        let res = null;
        if (momentTradeVolSharingRate?.length > 0 && tradevolInfo) {
            for (let i = 0; i < momentTradeVolSharingRate?.length; i++) {
                if (momentTradeVolSharingRate[i]?.maxVolume > tradevolInfo?.tradeVolUsd || momentTradeVolSharingRate[i]?.sort === -1) {
                    res = momentTradeVolSharingRate[i];
                    break;
                }
            }
        }
        return res;
    }, [tradevolInfo]);

    const getVolumeProgress = () => {
        if (tradeConfig) {
            return Math.min(100, tradevolInfo?.tradeVolUsd * 100 / tradeConfig.maxVolume);
        }
        return 0;
    }

    const updateMomentTradevolInfo = () => {
        getMomentTradevolInfo({
        }).then(res => {
            setTradevolInfo(res);
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        updateMomentTradevolInfo();
    }, []);

    const converReferralCode = (code) => {
        let tempCode = addJoinChartPre(code);
        if (tempCode?.length > 12) {
            return `${tempCode.substring(0, 6)}...${tempCode.substring(tempCode.length - 6)}`;
        }
        else {
            return tempCode;
        }
    }

    return (
        <div className={`${s.modalWrap} mainWrap`}>
            <MainHeader leftTitle={t('POINTS')} headerType={3} isGameHeader={true} hasBackBtn={false} openType={2}></MainHeader>
            <div className={s.modal}>
                <div className={s.content}>
                    <div>
                        <div className={`${s.selfInfoRoot} flex_center_start`}>
                            <AvatarInfo isMoment={true} size={65} src={avatarUrl || originalAvatarUrl} />
                            <div className={`${s.selfInfo} flex_center_center_col`}>
                                <div className={`momentFont color-EEEEEE ${s.userName}`}>
                                    {displayName}
                                </div>
                                <div className={`${s.sl}`}>
                                    <div className={`momentFont color-EEEEEE ${s.level}`}>
                                        {`${t('Level')}: ${tradeConfig?.level || 0}`}
                                    </div>
                                    <div className={`momentFont color-moment-gray ${s.sharingRate}`}>
                                        {`${t('Sharing Rate')} ${(tradeConfig?.sharingRate || 0) / 100}%`}
                                    </div>
                                </div>
                            </div>
                            <CustomIcon width={18} height={18} className={s.detailBtn} baseUrl={'/UI/'}
                                imgName={'Button/UI_Button_QuestionMark_04'} onClick={() => {
                                    setShowMomentSharingDetail(true);
                                }} />
                        </div>
                        <div className={`${s.volumeProgressRoot}`}>
                            <div className={`${s.progressTitle} flex_center_between momentFont color-moment-light-gray`}>
                                {`${t('Volume')}`}
                                <div className={`fs10 momentFont color-moment-gray`}>
                                    {`$${convertUsdValue(tradevolInfo?.tradeVolUsd || 0, true)}${tradeConfig?.sort === -1 ? '' : `/${tradeConfig?.maxVolume || 0}`}`}
                                </div>
                            </div>
                            <div className={`${s.progressBg} flex_center_start`}>
                                <div className={`${s.progressContent}`} style={{ width: `${getVolumeProgress()}%` }}>
                                </div>
                            </div>
                        </div>
                        <div className={`${s.groupRoot}`}>
                            <div className={`${s.groupTradeBg} flex_center_start_col`}>
                                {
                                    !bindReferralCode ?
                                        <div className={`${s.invitePart} flex_center_center`}>
                                            <div className={`${s.inviteBtn} flex_center_center`} onClick={() => {
                                                setShowGetInviteCode(true);
                                            }}>
                                                <span className="fs14 color-EEEEEE ">
                                                    {t('Invite friends to earn more')}
                                                </span>
                                            </div>
                                        </div> 
                                        :
                                        <div className={`${s.invitedPart} flex_center_between`}>
                                            {
                                                imageList?.length > 0 ?
                                                    <>
                                                        <div className={`${s.invitedMemberList}`}>
                                                            {
                                                                imageList.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className={`${s.blackBg}`} style={{ marginLeft: `${index === 0 ? 0 : -32}px`, zIndex: imageList.length - index }} onClick={() => {
                                                                            //navigate(gameReferralPath);
                                                                        }}>
                                                                            <AvatarInfo isMoment={true} size={52} src={item} />
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                            <span className={`fs14`}>
                                                                x
                                                            </span>
                                                            <div className={`fs20 lh1`}>
                                                                {tradevolInfo?.referralUserNum || 0}
                                                            </div>
                                                        </div>
                                                        <div className={`flex_center_center_col`}>
                                                            <div className={`flex_center_end`} onClick={() => {
                                                                copyFn(addJoinChartPre(referralCode));
                                                            }}>
                                                                <CustomIcon imgName={`Picture/UI_Picture_Icon_Copy_01`} className="mr4" width={12} height={12}></CustomIcon>
                                                                <span className={`fs14 color-EEEEEE `}>{converReferralCode(referralCode)}</span>
                                                            </div>
                                                            <div className={`w100p mt5 flex_center_end`} onClick={() => {
                                                                setOpenSharePanel(true);
                                                            }}>
                                                                <CustomIcon imgName={`Picture/UI_Picture_Icon_Share_01`} className="mr4" width={12} height={12}></CustomIcon>
                                                                <span className={`fs14 tll w100p color-EEEEEE `}>{t('Share')}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className={`${s.invitedAvatar}`} style={{ backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_02.png)` }} onClick={() => {
                                                            navigate(gameReferralPath);
                                                        }}>
                                                            <CustomIcon imgName={`Button/UI_Button_Explore_Create_01`} width={20} height={20}></CustomIcon>
                                                        </div>
                                                        <span className={`fs16 tlc color-EEEEEE `}>{t('Invite friends to earn more')}</span>
                                                    </>
                                            }
                                        </div>
                                }
                                <div className={`${s.tradePart} flex_center_start_col `}>
                                    <div className={`flex_center_between w100p`} style={{ marginTop: '18px' }}>
                                        <span className={`fs14 color-moment-gray`}>
                                            {t('Group Trade Volume')}&nbsp;:
                                        </span>
                                        <span className={`fs14 color-moment-gray fb`}>
                                            {`$${convertUsdValue(tradevolInfo?.groupTradeVol || 0)}`}
                                        </span>
                                    </div>
                                    <div className={`flex_center_between w100p`} style={{ marginTop: '10px' }}>
                                        <span className={`fs14 color-moment-gray`}>
                                            {t('Est. $IYKYK Points Earning')}&nbsp;:
                                        </span>
                                        <div className="momentFont ml5 fb fs24">
                                            <CustomIcon imgName={`Picture/UI_Picture-Currency_UP_01`} className="mr5" width={18} height={18}></CustomIcon>
                                            {`${convertUsdValue(tradevolInfo?.totalSharingVal || 0)}`}
                                        </div>
                                    </div>
                                    <div className={`flex_center_between w100p`} style={{ marginTop: '10px' }}>
                                        <span className={`fs14 color-moment-gray`}>
                                            {t('Est. $Abstract Points Earning')}&nbsp;:
                                        </span>
                                        <div className="momentFont ml5 fb fs24">
                                            <CustomIcon imgName={`Picture/UI_Picture-Currency_BpLevel_01`} className="mr5" width={18} height={18}></CustomIcon>
                                            {`${convertUsdValue(tradevolInfo?.totalSharingVal || 0)}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={`${s.lotteryRoot}`}>
                        <div className={`${s.lotteryBg}`}>
                            <div className={`${s.getFreePackLine} flex_center_center`}>
                                <div className={`${s.getFreePackBtn} flex_center_center`} onClick={() =>{
                                    navigate(gameMomentClubLotteryPath);
                                }}>
                                    {t('Spin To Get Free Packs')}
                                </div>
                                <CustomIcon width={28} height={28} className={s.bellBtn} 
                                    imgName={'Picture/UI_Picture_icon_Notification'} onClick={()=>{
                                        navigate(gameMomentClubLotteryPath);
                                    }}/>
                            </div>
                        </div>
                    </div> */}
                    <div className="flex_center_center_col mb40">
                        <AntButton
                            onClick={() => {
                                navigate(gameMomentClubCreatPath);
                            }}
                            className={`${s.createBtn} mt12 btn_transparent`}>
                            <div className="fs18 fb momentFont color-EEEEEE">
                                {t('Create DNA')}
                            </div>
                        </AntButton>
                        <AntButton
                            onClick={() => {
                                navigate(gameMomentClubExplorePath);
                            }}
                            className={`${s.tradeBtn} mt12 btn_public`}>
                            <div className="fs22 fb momentFont color-black">
                                {t('Trade to Earn')}
                            </div>
                        </AntButton>
                    </div>
                </div>
            </div>
            <AntModal
                width='330px'
                title={t('Commission Levels and Rates')}
                className="confirmModalWrap"
                centered={true}
                open={showMomentSharingDetail}
                onOk={() => setShowMomentSharingDetail(false)}
                onCancel={() => setShowMomentSharingDetail(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMemeSharingDetail
                    tradevolInfo={tradevolInfo}
                    closeFn={() => {
                        setShowMomentSharingDetail(false);
                    }}
                />
            </AntModal>
            <AntModal
                width='330px'
                title={t('Who invited you?')}
                className="confirmModalWrap"
                centered={true}
                open={showGetInviteCode}
                onOk={() => setShowGetInviteCode(false)}
                onCancel={() => setShowGetInviteCode(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalGetInviteCode
                    closeFn={() => {
                        setShowGetInviteCode(false);
                    }}
                />
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap tweetShareModal noImageTitle"
                centered={true}
                open={openSharePanel}
                onOk={() => setOpenSharePanel(false)}
                onCancel={() => setOpenSharePanel(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModelReferralShare inviteCode={referralCode} />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHideRefreshBtn: (params) => {
            return dispatch({
                type: "app/setHideRefreshBtn",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MomentLauncher));
