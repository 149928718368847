import React, {memo, useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../CustomIcon";
import {
    stringToNumber,
    unit,
    chgFn,
    sliceStr,
    getProfile,
    getAvatarFromUserInfo
} from "@/utils/common"
import AvatarInfo from "../AvatarInfo";
import {Button, Tag} from "antd";
import {platformObj} from "@/utils/env";
import {useNavigate} from "react-router-dom";
import {balancePath, publicWishClaimPath} from "@/routes/config";
import ButtonFactory from "../ButtonFactory";


const ChatContentHeader = (
    {
        curentOtherUserInfo,
        curRtcInfo,
        setOpenTranPanel,
        setShowOtherTransContent,
        backFn,
        showLivePanel,
        userInfo,
        setShowBalance
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [curentOtherUserInfoReady, setCurentOtherUserInfoReady] = useState('');
    const {buyPriceObj, youOwnKeyNum, holdersNum, holding, platformData, accountType, managerProfile} = useMemo(() => {
        setCurentOtherUserInfoReady(new Date().getTime())
        return curentOtherUserInfo || {}
    }, [curentOtherUserInfo])


    const {buyFirstKey, payEntryFee} = useMemo(() => {
        return userInfo?.selfData || {}
    }, [userInfo])

    // useEffect(()=>{
    //   const timer = useM
    // },[])
    // const {platformMap,useType,defaultName} = useMemo(()=>{
    //   console.log(platformData);
    //   return platformData || {}
    // },[platformData])


    const {displayName, profileDeepLink, accountName} = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])

    const {onlineNum, heat} = useMemo(() => {
        return curRtcInfo || {}
    }, [curRtcInfo])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(curentOtherUserInfo)
    }, [platformData, curentOtherUserInfo])

    const {chg, isUp} = useMemo(() => {
        const {sellPrice, historyPrice} = buyPriceObj || {};

        const chg = chgFn(sellPrice, historyPrice);
        const isUp = chg === '-' ? true : chg >= 0 ? true : false;
        return {chg, isUp}
    }, [buyPriceObj])

    // useEffect(()=>{
    //   console.log(`showLivePanel--------`,showLivePanel,new Date());
    //   if(!showLivePanel){
    //     console.log(`ShowChatContentHeader-------`,new Date());
    //   }
    // },[showLivePanel])

    return (
        // JSON.stringify(buyPriceObj) !== '{}' &&
        !showLivePanel ? (
                <div className={s.header}>
                    <div className={s.htop}>
                        {/* <div className={s.back} onClick={() => backFn()}>
            <CustomIcon width={28} height={40}
              imgName={'Button/UI_Button_Exit_01'} />
          </div> */}
                        <div className={s.right}>
                            <div className={s.r1}>
                                {profileDeepLink ? <a className={s.user} href={profileDeepLink}>
                                        <AvatarInfo size={70} src={avatarUrl}/>
                                    </a> :
                                    <div className={s.user}>
                                        <AvatarInfo size={70} src={avatarUrl}/>
                                    </div>
                                }

                            </div>
                            <div className={s.r2}>
                                <div className={s.r2l}>
                                    <div className={`fs16 ${s.fl} df alc jcc`}>
                                        <span className="fs18 mr5 ">{sliceStr(displayName, 14, true)}</span>
                                        {profileDeepLink &&
                                            <CustomIcon width={16} height={16}
                                                        imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                        name={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                                        {accountType === "wish" && <div className={`${s.wishTag} ml5`}>
                                            {/*<CustomIcon width={12} height={13} className="mr5" imgName={'icon_wish'} name={'icon_wish'} />*/}
                                            {t('WISH')}
                                        </div>}
                                    </div>
                                    <div className={`${s.keys} fs12 color-blue-light white-space-pre-wrap`}>
                                        <Trans
                                            i18nKey="You own num key/keys/wish/wishes"
                                            defaults="You own <active>{{count}}</active> {{sth}}"
                                            values={{
                                                count: youOwnKeyNum,
                                                sth: accountType === 'wish' ? youOwnKeyNum > 1 ? 'wishes' : 'wish' : youOwnKeyNum > 1 ? t('keys') : t('key')
                                            }}
                                            components={{active: <span className="color-yellow fs12"></span>}}
                                        />
                                    </div>

                                </div>
                                <div className={s.r2r}>
                                    <div className={s.rr1}>


                                        {/* <div className="color-blue-light fs14" onClick={() => { setShowOtherTransContent(true) }}>
                    <Trans
                      i18nKey="xx Member/Members"
                      defaults="<white>{{memberCount}}</white> {{member}}"
                      values={{
                        memberCount: holdersNum,
                        member: holdersNum > 1 ? t('Members') : t('Member')
                      }}
                      components={{ white: <span className=" color-white"></span> }}
                    />
                    {accountType !== 'wish' && (<>,
                      <Trans
                        i18nKey="xx Joined"
                        defaults="<white>{{joinedCount}}</white> Joined"
                        values={{ joinedCount: holding }}
                        components={{ white: <span className=" color-white"></span> }}
                      />
                    </>)}
                  </div> */}
                                        {/* <div className="color-gray fs14">{t('Led by name', { name: sliceStr(managerProfile?.displayName, 'all')})}</div> */}
                                        <div className="color-blue-light">{accountName}</div>
                                    </div>
                                    <div className={s.rr2}>
                                        <ButtonFactory
                                            size={32}
                                            scale={1.2}
                                            onClick={() => {
                                                console.log(`clice TRADE`);

                                                setShowOtherTransContent(true)
                                            }}>
                                            {youOwnKeyNum ? t('TRADE') : t('BUY')}
                                        </ButtonFactory>
                                        {/* <div className="fs14  mtb5">{stringToNumber(buyPriceObj?.keyPrice, 4)} {unit}</div>

                  <div className={s.percent}>
                    <div className={`${isUp ? s.upIcon : s.downIcon}`}></div>
                    <div className={`${s.percentVol} fs10 ${isUp ? s.upColor : s.downColor}`}> {chg}%</div>
                  </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.hbottom}>
                        {/* To Do 多媒体列表展示 */}
                        {/* <div className={s.list}>
            {
               platformMap && JSON.stringify(platformMap) !== "{}" && platformObj.map(item => {
                const { type, label, iconName } = item;

                const { uid, profileDeepLink } = platformMap[type] || {};
                if (uid && profileDeepLink) {
                  return (
                    <a href={profileDeepLink} className={`${s.item} ${useType === type ? s.active : ''}`} key={uid}>
                      <CustomIcon className="mr5" width={20} height={20} imgName={iconName} name={iconName} />
                      <div className="ml5">{label}</div>
                    </a>
                  )
                } else {
                  return ''
                }

              })
            }
          </div> */}
                    </div>

                </div>) :
            (
                <div className={s.liveHead}>
                    <div className={s.top}>
                        <div className={s.left}>
                            <AvatarInfo size={30} className={s.avatar} src={avatarUrl}/>
                            <div className={s.info}>
                                <div style={{fontSize: '12px', marginTop: '2px'}}>
                                    {sliceStr(displayName, 'all')}
                                    {profileDeepLink &&
                                        <CustomIcon width={14} className='ml5' height={14}
                                                    imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                    name={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                                </div>

                                {/*<div className=" fs12">*/}
                                {/*    {stringToNumber(buyPriceObj?.keyPrice, 4)}&nbsp;*/}
                                {/*    <span className="color-blue-light fs12">{unit}</span>*/}
                                {/*</div>*/}
                                <div className=" fs12">
                                    <span className="color-yellow">{onlineNum}</span>&nbsp;
                                    <span
                                        className="color-white fs12">{onlineNum > 1 ? t('Viewers') : t('Viewer')}</span>
                                </div>
                            </div>
                            {/*<ButtonFactory className={`modelBottomBtn`} size={32} onClick={() => {*/}
                            {/*    if (!(buyFirstKey && payEntryFee)) {*/}
                            {/*        navigate(balancePath)*/}
                            {/*    } else if (userInfo?.selfData?.wishNeedClaim && userInfo?.userId === curentOtherUserInfo?.userId) {*/}
                            {/*        navigate(publicWishClaimPath)*/}
                            {/*    } else {*/}
                            {/*        setOpenTranPanel(true)*/}
                            {/*    }*/}

                            {/*}}>*/}
                            {/*    {youOwnKeyNum ? t('TRADE') : t('BUY')}*/}
                            {/*</ButtonFactory>*/}
                        </div>
                        <div className={s.right} onClick={backFn}>
                            <CustomIcon width={23} height={23} imgName={'Button/UI_Button_Close_01'}
                                        name={'Button/UI_Button_Close_01'}></CustomIcon>
                        </div>
                    </div>
                    <div className={s.bottom} style={{paddingLeft: '20px', paddingTop: '8px'}}>
                        {/*<div className={s.item}>*/}
                        {/*    <span className="color-white">{onlineNum}</span>&nbsp;*/}
                        {/*    {onlineNum > 1 ? t('Viewers') : t('Viewer')}*/}
                        {/*</div>*/}
                        {/*<div className={s.item}>*/}
                        {/*    {t('You own')}&nbsp;*/}
                        {/*    <span className="color-white">{youOwnKeyNum}</span>&nbsp;*/}
                        {/*    {youOwnKeyNum > 1 ? t('keys') : t('key')}*/}
                        {/*</div>*/}
                    </div>
                </div>
            )
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        curentOtherUserInfo: app.curentOtherUserInfo,
        curRtcInfo: app.curRtcInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ChatContentHeader));
