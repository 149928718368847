import React, {memo, useMemo, useEffect, useState, useCallback} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Tabs, Button, Modal} from 'antd';
import Navs from "src/common/MainNavs";
import OtherTranList from "@/common/OtherTranList";
import List from "@/views/games/MemeClub/Assets/List";
import CustomIcon from "@/common/CustomIcon";
import AvatarInfo from "@/common/AvatarInfo";
import ModelTrans from "src/model/ModelTrans";
import {sliceStr} from "@/utils/common";
import {useNavigate, useParams} from "react-router-dom";
import {AutoStrangeRule} from "@/utils/strangeRule";
import {
    stringToNumber,
    getearninfo,
    getfriendtrade_rank_selfdata,
    getProfile,
    getTranList,
    gettrade_price,
    getAvatarFromUserInfo,
    unit,
    chgFn,
    getuserinfo,
    getPrice,
    joinChat,
} from "@/utils/common";
import {balancePath, publicWishClaim, publicWishClaimPath} from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";
import MainHeader from "src/common/MainHeader";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import { userTabList } from "@/utils/memeClub";

const MemeClubUserDetail = (
    {
        userInfo,
        curentOtherUserInfo,
        setCurentOtherUserInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const [memeClubShowType, setMemeClubShowType] = useState(0);//0:created,1:favorited,2:holding pack,3:holding coin
    const [memeClubsShowList, setMemeClubsShowList] = useState([]);
    const [nextCursor, setNextCursor] = useState(-1);
    const [sortValue, setSortValue] = useState({sortType:1, sortOrder:0});

    const {
        userId
    } = useMemo(() =>{
        return params || {}
    }, [params]);
    const {
        accountType,
        youOwnKeyNum,
        holdersNum,
        holding,
        portfolio,
        platformData,
        buyPriceObj,
    } = useMemo(() => {
        return curentOtherUserInfo || {};
    }, [curentOtherUserInfo])

    const getMoreFn = () =>{
        getList({
            rankTabType:memeClubShowType,
            rankSortType: sortValue?.sortType,
            isAsc: sortValue?.sortOrder === 0,
            cursor:nextCursor,
        })
    }

    useEffect(()=>{
        getList({
            rankTabType:memeClubShowType,
            rankSortType: sortValue?.sortType,
            isAsc: sortValue?.sortOrder === 0,
            cursor: 0,
        })
    },[memeClubShowType])

    const items = [
        {
            key: 1,
            label: `${AutoStrangeRule(t('Ctreated'))}`,
            children: <List list={memeClubsShowList} nextCursor={nextCursor} getMoreFn={getMoreFn}></List>,
        }, {
            key: 2,
            label: `${AutoStrangeRule(t('Favorited'))}`,
            children: <List list={memeClubsShowList} nextCursor={nextCursor} getMoreFn={getMoreFn}></List>,   
        }, {
            key: 3,
            label: `${AutoStrangeRule(t('Holding Pack & Coin'))}`,
            children: <List list={memeClubsShowList} nextCursor={nextCursor} getMoreFn={getMoreFn}></List>,   
        }
    ]

    const getList = ({rankTabType=1,rankSortType=1,isAsc=true,cursor=0})=>{
        console.log(rankTabType,rankSortType,isAsc,cursor);
        userTabList({rankTabType,rankSortType,isAsc,cursor}).then(res=>{
            const _list = cursor === 0 ? res?.dataList || [] : [
                ...memeClubsShowList,
                ...(res?.dataList || [])
            ]
            setMemeClubsShowList(_list)
            setNextCursor(res?.nextCursor)
        }).catch(e=>{
            console.error(e);
        })
    }

    useEffect(() => {
        if (userId) {
            getPrice(userId);
            getuserinfo(userId).then(res => {
                setCurentOtherUserInfo({
                    ...curentOtherUserInfo,
                    ...res
                });
            }).catch(e => {
                console.log(e);
            })
        }
    }, [userId])

    const {chg, isUp} = useMemo(() => {
        const {sellPrice, historyPrice} = buyPriceObj || {};
        const chg = chgFn(sellPrice, historyPrice);
        const isUp = chg === '-' ? true : chg >= 0 ? true : false;
        return {chg, isUp}
    }, [buyPriceObj])

    const {platformMap, useType, defaultName} = useMemo(() => {
        return platformData || {};
    }, [platformData])

    const {displayName, profileDeepLink} = useMemo(() => {
        if (platformMap && JSON.stringify(platformMap) !== '{}') {
            return platformMap[useType]
        } else {
            return {
                displayName: defaultName
            }
        }
    }, [platformMap, useType, defaultName])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(curentOtherUserInfo);
    }, [platformMap, useType, curentOtherUserInfo])

    return (
        <div className={`${s.wrap} publicBg`}>
            <MainHeader leftTitle={'BACK'} hasBackBtn={true}></MainHeader>
            <div className={s.header} style={{backgroundImage: `url(/UI/Picture/UI_Picture-Main_Top_02.png)`}}>
                <div className={s.htop}>
                    <div className={s.right}>
                        <div className={s.r1}>
                            {profileDeepLink ? <a className={s.user} href={profileDeepLink}>
                                    <AvatarInfo size={70} src={avatarUrl}/>
                                </a> :
                                <div className={s.user}>
                                    <AvatarInfo size={70} src={avatarUrl}/>
                                </div>
                            }
                        </div>
                        <div className={s.r2}>
                            <div className={s.r2t}>
                                <div className={`fs16 ${s.fl}`}>
                                    <span className="fs18 mr5 ">{sliceStr(displayName, 14, true)}</span>
                                    {profileDeepLink &&
                                        <CustomIcon width={16} height={16}
                                                    imgName={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                                    {accountType === "wish" && <div className={`${s.wishTag} ml5`}>
                                        {t('WISH')}
                                    </div>}
                                </div>
                                <div className={`${s.tradeBtn} flex_center_center`} onClick={() =>{
                                    joinChat(userId, navigate, accountType);
                                }}>
                                    {t('DM')}
                                </div>
                            </div>
                            <div className={s.r2b}>
                                <div className={s.rr1}>
                                    <div className={`${s.keys} fs14 color-blue-light white-space-pre-wrap`}>
                                        <Trans
                                            i18nKey="You own num key/keys/wish/wishes"
                                            defaults="You own <active>{{count}}</active> {{sth}}"
                                            values={{
                                                count: youOwnKeyNum,
                                                sth: accountType === 'wish' ? youOwnKeyNum > 1 ? 'wishes' : 'wish' : youOwnKeyNum > 1 ? t('keys') : t('key')
                                            }}
                                            components={{active: <span className="color-yellow fs16"></span>}}
                                        />
                                    </div>
                                    <div className="color-blue-light fs14">
                                        <Trans
                                            i18nKey="xx Member/Members"
                                            defaults="<white>{{memberCount}}</white> {{member}}"
                                            values={{
                                                memberCount: holdersNum,
                                                member: holdersNum > 1 ? t('Members') : t('Member')
                                            }}
                                            components={{white: <span className=" color-white"></span>}}
                                        />
                                        {accountType !== 'wish' && (<>,
                                            <Trans
                                                i18nKey="xx Joined"
                                                defaults="<white>{{joinedCount}}</white> Joined"
                                                values={{joinedCount: holding}}
                                                components={{white: <span className=" color-white"></span>}}
                                            />
                                        </>)}
                                    </div>
                                </div>
                                <div className={s.rr2}>
                                    <div className="fs14">{stringToNumber(buyPriceObj?.keyPrice, 4)} {unit}</div>
                                    <div className={s.percent}>
                                        <div className={`${isUp ? s.upIcon : s.downIcon}`}></div>
                                        <div
                                            className={`${s.percentVol} fs12 ${isUp ? s.upColor : s.downColor}`}> {chg}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.content}>
                <Tabs
                    defaultActiveKey={1}
                    items={items}
                    className="tptabs"
                    onChange={(activeKey)=>{
                        setNextCursor(0)
                        setMemeClubShowType(Number(activeKey))
                    }}
                    destroyInactiveTabPane={true}>
                </Tabs>
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MemeClubUserDetail));
