import React, {memo, useState, useMemo} from "react";
import s from "./index.module.less";
import {Input, Button, Modal} from "antd";
import {
    unit, 
    siteName, 
    getmatic_market,
    getQuotes, 
    stringToNumber, 
    hainName,
    getChainInfoById,
    convertWalletBalance,
    numberClamp,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";

const ModelWithdraw = (
    {
        userInfo,
        selectedNftInfo,
        hasNextNft,
        nextNft = () => {},
        closeFn = () => {},
    }
) => {
    const {t, i18n} = useTranslation();

    const numberAttribute = useMemo(() => {
        let res = [];
        if (selectedNftInfo?.attributeList?.length > 0){
            for (let i = 0; i < selectedNftInfo?.attributeList?.length; i++){
                if (selectedNftInfo?.attributeList[i]?.type === 1){
                    res.push(selectedNftInfo?.attributeList[i]);
                }
            }
        }
        return res;
    }, [selectedNftInfo]);

    const stringAttribute = useMemo(() => {
        let res = [];
        if (selectedNftInfo?.attributeList?.length > 0){
            for (let i = 0; i < selectedNftInfo?.attributeList?.length; i++){
                if (selectedNftInfo?.attributeList[i]?.type === 2){
                    res.push(selectedNftInfo?.attributeList[i]);
                }
            }
        }
        return res;
    }, [selectedNftInfo]);

    const getNumberAttributeProgress = (item) =>{
        if (item){
            let progress = (item?.value-item?.minNum)*100/(item?.maxNum - item?.minNum);
            if (progress <= 0) {
                return 0;
            }
            else {
                return numberClamp(progress, 5, 100);
            }
        }
        return 0;
    }

    return (
        <div className={`${s.wrap} flex_center_start_col`}>
            <div className={`${s.nftAvatar}`} style={{backgroundImage: `url(${selectedNftInfo?.imageUrl})`}}>
            </div>
            <div className={`${s.nftName}`}>
                {selectedNftInfo?.name}
            </div>
            <div className={`${s.nftAttributeRoot} flex_center_start_col`}>
                {
                    numberAttribute?.length > 0 &&
                    numberAttribute.map((item) => {
                        return (
                            <div className={`${s.attributeLine} flex_center_between`}>
                                <div className={`${s.attibuteLineName}`}>
                                    {item?.attrName}:
                                </div>
                                <div className={`${s.attibuteLineNumberValue} flex_center_start`}>
                                    <div className={`${s.attibuteLineNumberValueProgress}`}
                                        style={{width: `${getNumberAttributeProgress(item)}%`}}>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                {
                    stringAttribute?.length > 0 &&
                    stringAttribute.map((item) => {
                        return (
                            <div className={`${s.attributeLine} flex_center_between`}>
                                <div className={`${s.attibuteLineName}`}>
                                    {item?.attrName}:
                                </div>
                                <div className={`${s.attibuteLineStringValue} flex_center_center`}>
                                    <div className={`${s.value}`}>
                                        {item?.value?.enumName}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            {
                hasNextNft && 
                <ButtonFactory className={s.nextBtn} onClick={nextNft}>
                    {t('Next')}
                </ButtonFactory>
            }
        </div>
    )
}
export default memo(ModelWithdraw);
