import React, {memo, useState, useMemo, useEffect, useRef} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {
    unit, referralcode_earndata, stringToNumber, referralcode_regen, copyFn,
    getearninfo, getProfile,
    referralcode_inviteuser,
    displayFilter,
    getAvatarFromProfile,
    getAvatarFromUserInfo, 
    convertWalletBalance, 
    vCoinString, 
    getTransactionErrorMsgId,
    convertUsdValue,
} from "@/utils/common"
import AvatarInfo from "../../../common/AvatarInfo";
import CustomIcon from "../../../common/CustomIcon";
import {Spin, Modal, Button, Tabs} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate} from "react-router-dom";
import ModalExtraLife from '@/model/ModelExtraLife'
import ModalMultiplier from '@/model/ModelMultiplier'
import Achievements from '@/utils/json/Achievements.json'
import ModelReferralStakeTips from "src/model/ModelReferralStakeTips";
import ModelReferralStakeMultiplierTips from "src/model/ModelReferralStakeMultiplierTips";
import {lfgStakeBalanceOf} from "@/utils/lfgStake";
import {useWallets} from "@privy-io/react-auth";
import StakeBonusMultiplier from '@/utils/json/StakeBonusMultiplier.json';
import {
    post_referral_stake_get_reward,
    post_referralcode_stakelfg,
    secondsToDays,
    secondsToHours
} from "@/utils/referralStake";
import {
    getMemeTradevolInfo,
} from "@/utils/memeClub";
import Countdown from "src/common/Countdown";
import ModelApplyMint from "@/model/ModelApplyMint";
import ModelReferralStakeBounsTips from "src/model/ModelReferralStakeBounsTips";
import {applyMint} from "@/utils/lfgMintWeb3";
import {publicChatsPath} from "@/routes/config";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import {AutoStrangeRule} from "@/utils/strangeRule";
import AntTabPane from "@/common/AntTabPane";
import ModelReferralShare from "@/model/ModelReferralShare";
import ButtonFactory from "@/common/ButtonFactory";
import { getMomentTradevolInfo } from "@/utils/momentClub";
const INVITE_WARP_ID = 'inviteWrapId';
const ReferralEarnBonus = (
    {
        inviteCode,
        children,
        userInfo
    }
) => {
    const {t, i18n} = useTranslation();
    const TabPane = Tabs.TabPane;

    const {
        platformData, userId, selfData: {
            multiplyCardNum,
            nextAchieveIndex
        }
    } = userInfo
    const selfProfile = getProfile(platformData);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [earnData, setEarnData] = useState(null);
    const [extraLifeModal, setExtraLifeModal] = useState(false)
    const [multiplierModal, setMultiplierModal] = useState(false)
    const [totalPortfolio, setTotalPortfolio] = useState(0)
    const [portfolio, setPortfolio] = useState(0)
    const [isShowArrow, setIsShowArrow] = useState(true)
    const [inviteUser, setInviteUser] = useState(null)
    const [tradevolInfo, setTradevolInfo] = useState(null);
    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo);
    }, [platformData, userInfo])

    const reInviteCode = useRef('');
    const refresh = () => {
        referralcode_regen().then(res => {
            reInviteCode.current = res?.referralCode
        }).catch(e => {

        })
    }

    const nextCursor = useMemo(() => {
        return earnData ? earnData?.nextCursor : -1
    }, [earnData])

    const [claimFailed, setClaimFailed] = useState(false)
    const [claimSuccess, setClaimSuccess] = useState(false)
    const [claimDone, setClaimDone] = useState(false)

    const getEarnData = (cursor = 0) => {
        referralcode_earndata(cursor).then(res => {
            setEarnData(res);
            setLoading(false)
            setClaimDone(false)
            setClaimFailed(false)
            setClaimSuccess(false)
        }).catch(e => {
            setLoading(false)
        })
    }

    const updateMemeTradevolInfo = () => {
        getMemeTradevolInfo().then(res => {
            setTradevolInfo(res);
        }).catch(e => {
            console.error("get MemeTradevolInfo error", e);
        })
    }

    const getearninfoFn = () => {
        getearninfo().then(res => {
            setTotalPortfolio(res.totalPortfolio)
            setPortfolio(res.portfolio)
        }).catch(e => {
            console.log(e);
        })
    }

    const getInviteuser = (cursor = 0) => {
        referralcode_inviteuser(cursor).then(res => {
            res.userId !== 0 && setInviteUser(res)
        }).catch(e => {

        })
    }
    useEffect(() => {
        updateMemeTradevolInfo();
        getEarnData()
        getearninfoFn()
        getInviteuser()
    }, [])
    const list = useMemo(() => {
        return earnData?.referralUserList || []
        // return [{profile:{}},{profile:{}},{profile:{}},]
    }, [earnData])

    const stakeActivity = useMemo(() => {
        return earnData?.stakeActivity || {}
    }, [earnData])

    const showEarn = useMemo(() => {
        return earnData?.showEarn || false
    }, [earnData])

    const getMore = () => {
        getEarnData(nextCursor)
    }

    const showArrow = (scroll) => {
        if ((list.length - 2) * 60 <= scroll) {
            setIsShowArrow(false)
        } else {
            setIsShowArrow(true)
        }
    }

    const [tipsMultiplierModal, setTipsMultiplierModal] = useState(false)
    const [tipsBounsModal, setTipsBounsModal] = useState(false)

    const [lfgStakeBalance, setLfgStakeBalance] = useState(0)
    const [openSharePanel, setOpenSharePanel] = useState(false);

    const refreshStakeInfo = () => {
        lfgStakeBalanceOf(wallets).then(
            res => {
                setLfgStakeBalance(res)
            }
        )
    }

    const {wallets} = useWallets();

    useEffect(() => {
        if (wallets.length > 0) {
            refreshStakeInfo();
        }
    }, [wallets])

    const currMultiplierLevel = useMemo(() => {
        let level = {}
        StakeBonusMultiplier.map((item, index) => {
            if (item.stakedMin <= lfgStakeBalance && item.stakedMax > lfgStakeBalance) {
                level = item
            }
        })
        return level
    }, [lfgStakeBalance])

    const [claimLoading, setClaimLoading] = useState(false)

    const doClaim = () => {
        setClaimLoading(true);

        post_referral_stake_get_reward().then(res => {
            applyMint({
                wallets,
                amount: res.mintCoin,
                orderId: res.orderId,
                lockedUntil: res.lockSecond,
                timestamp: res.timestamp,
                validFor: res.validFor,
                signature: res.signature
            }).then(res => {
                setClaimDone(true);
                setClaimSuccess(true);
                setClaimLoading(false);
                setTimeout(
                    () => {
                        setLoading(true);
                        getEarnData();
                    }, 5000
                )
            }).catch(e => {
                console.error(e);
                setClaimDone(true);
                setClaimFailed(true);
                setClaimLoading(false);
                setTimeout(
                    () => {
                        setLoading(true);
                        getEarnData();
                    }, 5000
                )
            })
        }).catch(e => {
            setClaimLoading(false);
        })
    }

    const canClaim = useMemo(() => {
        return (stakeActivity?.toClaimLFG > 0 || stakeActivity?.refundPool > 0) && !claimDone
    }, [earnData, stakeActivity, claimDone])

    return (
        <>
            <div className={s.wrap}>
                <div className={s.inviteCode} style={{overflow: 'auto'}}>
                    <div className={s.bt}>
                        <div className={s.btl}>
                            <div className={`${s.partTitle} fb`}>
                                <CustomIcon width={17} height={39} className=""
                                            imgName={'Picture/UI_Picture_Title-Yellow_01'}/>
                                {AutoStrangeRule(t('DNA Launcher1'))}
                            </div>
                        </div>
                    </div>
                    <div className={"w100p"}>
                        <div className={`${s.container} flex_center_start_col`}>
                            <div className={`${s.containerLine} mt5 flex_center_between`}>
                                <div className={`lable fs12 ${s.lable} color-blue-light`}>
                                    {t('My Trade Volume')}:
                                </div>
                                <div className={`fs12`}>
                                    {`$ ${convertUsdValue(tradevolInfo?.tradeVolUsd || 0)}`}
                                </div>
                            </div>
                            <div className={`${s.containerLine} mt5 flex_center_between`}>
                                <div className={`lable fs12 ${s.lable} color-blue-light`}>
                                    {t('Total Group Trade Volume')}:
                                </div>
                                <div className={`fs12`}>
                                    {`$ ${convertUsdValue(tradevolInfo?.groupTradeVol || 0)}`}
                                </div>
                            </div>
                            <div className={`${s.containerLine} mt5 flex_center_between`}>
                                <div className={`lable fs12 ${s.lable} color-blue-light`}>
                                    {t('Est.Total Sharing')}:
                                </div>
                                <div className={`fs14 color-yellow`}>
                                    {`$ ${convertUsdValue(tradevolInfo?.totalSharingVal || 0)}`}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.bt}>
                        <div className={s.btl}>
                            <div className={`${s.partTitle} fb`}>
                                <CustomIcon width={17} height={39} className=""
                                            imgName={'Picture/UI_Picture_Title-Yellow_01'}
                                            name={'Button/UI_Button_Arrow_01'}/>
                                {AutoStrangeRule(t('$LFG BOOSTER'))}
                            </div>
                        </div>
                    </div>
                    <div className={"w100p"}>
                        <div className={`df jc-spaceBetween ${s.container}`}>
                            <div>
                                {/* <div className="tll fb">{t('$LFG BOOSTER')}</div> */}
                                <div className="tll mt5 fs12 color-blue-light"
                                     style={{textWrap: 'nowrap'}}>{t('Get more Multipliers as your group grows')}</div>
                                      <div className="tll mt5 fs12 color-blue-light"
                                     style={{textWrap: 'nowrap'}}>{t('Larger Group = More Boosters')}</div>
                                <div className="one-line df aic mt5">
                                    <div
                                        className={`label ${s.label} color-blue-light`}>{t('Total Group Portfolio')}:
                                    </div>
                                    <div className="value ml5 df aic">
                                        <CustomIcon
                                            width={14}
                                            height={14}
                                            imgName={"Picture/UI_Picture-Currency_MATIC_01"}
                                            name={"Picture/UI_Picture-Currency_MATIC_01"}
                                            className="mr5"
                                        />{stringToNumber(totalPortfolio, 5)}
                                    </div>
                                </div>
                                <div className="one-line df aic mt5">
                                    <div className={`label ${s.label} color-blue-light`}>{t('Next Multiplier at')}:
                                    </div>
                                    <div className="value ml5">{nextAchieveIndex ?
                                        <span className=" df aic fs12 color-white"><CustomIcon
                                            width={14}
                                            height={14}
                                            imgName={"Picture/UI_Picture-Currency_MATIC_01"}
                                            name={"Picture/UI_Picture-Currency_MATIC_01"}
                                            className="mr5"
                                        />{Achievements.find(v => v.index === nextAchieveIndex)?.value / 10000}</span> : '--'}</div>
                                </div>
                            </div>
                            <div className="df" style={{alignItems: 'flex-end'}}>
                                <div className="df aic" style={{marginRight: '3px'}}>
                                    <CustomIcon width={28} height={28} style={{backgroundSize: '100% 100%'}}
                                                imgName={'Picture/UI_Picture_Title-Blue_Rocket_01'}
                                                name={'Picture/UI_Picture_Title-Blue_Rocket_01'}/>
                                    <span className="fs18 fb">&nbsp;x&nbsp;{multiplyCardNum || 0}</span>
                                    <CustomIcon width={18} height={18} className="ml10"
                                                imgName={'Button/UI_Button_QuestionMark_01'}
                                                name={'Button/UI_Button_QuestionMark_01'} onClick={() => {
                                        setMultiplierModal(true);
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className={s.bt}>
                        <div className={s.btl}>
                            <div className={`${s.partTitle} fb`}>
                                <CustomIcon width={17} height={39} className=""
                                            imgName={'Picture/UI_Picture_Title-Yellow_01'}
                                            name={'Button/UI_Button_Arrow_01'}/>
                                {AutoStrangeRule(t('TRIVIA LIVES'))}
                            </div>
                        </div>
                    </div>
                    <div className={"w100p"}>
                        <div className={`df jc-spaceBetween ${s.container}`}>
                            <div>
                                {/* <div className="one-line df">
                                    <div className={`lable fb`}>{t('TRIVIA EXTRA LIVES')}</div>
                                </div> */}
                                <div className="one-line df mt5">
                                    <div
                                        className={`lable fs12 ${s.lable} color-blue-light`}>{t('Every invitee provides an Extra Life')}</div>
                                    {/* <div className="value">{list.length || 0}</div> */}
                                </div>
                            </div>
                            <div className="df jcfe" style={{flexDirection: 'column'}}>
                                {/* <div className="mb5">Total Extra Lives:</div> */}
                                <div className="df jcc aic mb5">
                                    {children}
                                    <CustomIcon width={18} height={18} className="ml10"
                                                imgName={'Button/UI_Button_QuestionMark_01'}
                                                name={'Button/UI_Button_QuestionMark_01'} onClick={() => {
                                        setExtraLifeModal(true);
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {Math.trunc(Number(stakeActivity?.toClaimLFG + stakeActivity?.refundPool || 0)) > 0 &&
                    <>
                    <div className={s.bt}>
                        <div className={s.btl}>
                            <div className={`${s.partTitle} fb`}>
                                <CustomIcon width={17} height={39} className=""
                                            imgName={'Picture/UI_Picture_Title-Yellow_01'}
                                            name={'Button/UI_Button_Arrow_01'}/>
                                {AutoStrangeRule(t('STAKING BONUS'))}
                            </div>
                        </div>
                    </div>
                    <div className={"w100p"} style={{position: 'relative'}}>
                        <div className={`df jc-spaceBetween ${s.container}`}>
                            <div>
                                <div className="tll mt5 fs12" style={{textWrap: 'nowrap'}}>
                                    <span className="color-blue-light fs14">{t('Total bonus pool:')}</span>
                                    &nbsp;
                                    <span
                                        className="fs16">{(Math.trunc(Number(stakeActivity?.poolLeft || 0))).toLocaleString('en-US')}</span> / <span
                                    className="fs16">{(Math.trunc(Number(stakeActivity?.poolSum || 0))).toLocaleString('en-US')}</span>
                                </div>
                                <div className="tll mt5 fs12" style={{textWrap: 'nowrap'}}>
                                        <span
                                            className="color-blue-light fs14">{t('My staking bonus multiplier:')}</span>
                                    &nbsp;
                                    <span
                                        className="fs16">{((currMultiplierLevel?.multiplier || 0) / 100).toFixed(1)} %</span>
                                    <CustomIcon width={16} height={16} className="ml10"
                                                imgName={'Button/UI_Button_QuestionMark_01'}
                                                name={'Button/UI_Button_QuestionMark_01'} onClick={() => {
                                        setTipsMultiplierModal(true);
                                    }}/>
                                </div>
                                <div className="tll mt5 fs12" style={{textWrap: 'nowrap'}}>
                                    <span className="color-blue-light fs14">{t('Claimable staking bonus:')}</span>
                                    &nbsp;
                                    <CustomIcon
                                        width={14}
                                        height={14}
                                        imgName={"Picture/UI_Picture-Currency_LFG_01"}
                                        name={"Picture/UI_Picture-Currency_LFG_01"}
                                        className="mr5"
                                    />
                                    <span className="fs16 color-yellow">
                                    {(Math.trunc(Number(stakeActivity?.toClaimLFG + stakeActivity?.refundPool || 0))).toLocaleString('en-US')}
                                </span>
                                    <CustomIcon width={16} height={16} className="ml10"
                                                imgName={'Button/UI_Button_QuestionMark_01'}
                                                name={'Button/UI_Button_QuestionMark_01'} onClick={() => {
                                        setTipsBounsModal(true);
                                    }}/>
                                </div>
                            </div>
                        </div>
                        {stakeActivity?.claimLeftSec === 0 &&
                            <div className="df" style={{position: 'absolute', top: "55px", right: "10px"}}>
                                <div className="df aic" style={{marginRight: '3px'}}>
                                    <AntButton className={`btn_public ${!canClaim && 'btn_disabled'}`}
                                               loading={claimLoading}
                                               onClick={doClaim} disabled={!canClaim}>
                                        {claimLoading && t('PROCESSING')}
                                        {!claimLoading && !claimDone && t('CLAIM')}
                                        {!claimLoading && claimDone && claimSuccess && t('SUCCESS')}
                                        {!claimLoading && claimDone && claimFailed && t('FAILED')}
                                    </AntButton>
                                </div>
                            </div>
                        }
                        {stakeActivity?.claimLeftSec > 0 &&
                            <div className="df" style={{
                                position: 'absolute',
                                top: "45px",
                                right: "10px"
                            }}>
                                <div className="df aic" style={{marginRight: '3px'}}>

                                    <AntButton className={`btn_public btn_disabled`}
                                               disabled={true} style={{lineHeight: '15px'}}>
                                        {t('Available in:')}<br/>
                                        <span><Countdown leftSecond={stakeActivity?.claimLeftSec}
                                                         refresh={getEarnData}/></span>
                                    </AntButton>
                                </div>
                            </div>
                        }
                        <div className={s.labelItem}>
                            {stakeActivity?.endSec !== 0 && <div className={s.endTime}>
                                {t('Bonus End in')}: &nbsp;
                                {stakeActivity?.endSec >= 86400 && <span className="color-white">
                                {secondsToDays(stakeActivity?.endSec || 0)} {t('Days')}
                            </span>}
                                {stakeActivity?.endSec < 86400 && <span className="color-white">
                                {secondsToHours(stakeActivity?.endSec || 0)} {t('Hours')}
                            </span>}
                            </div>}
                            {stakeActivity?.endSec === 0 && <div className={s.endTime}>
                                {t('Bonus End')}
                            </div>}
                        </div>
                    </div>
                    </>
                     }

                    <AntModal
                        width='330px'
                        className="confirmModalWrap noImageTitle"
                        centered={true}
                        open={extraLifeModal}
                        destroyOnClose={true}
                        onOk={() => setExtraLifeModal(false)}
                        onCancel={() => setExtraLifeModal(false)}
                    >
                        <ModalExtraLife/>
                    </AntModal>
                    <AntModal
                        width='330px'
                        className="confirmModalWrap noImageTitle"
                        centered={true}
                        open={multiplierModal}
                        destroyOnClose={true}
                        onOk={() => setMultiplierModal(false)}
                        onCancel={() => setMultiplierModal(false)}
                    >
                        <ModalMultiplier/>
                    </AntModal>
                    <AntModal
                        width='330px'
                        title={t('Staking Bonus Multiplier')}
                        className="confirmModalWrap"
                        centered={true}
                        open={tipsMultiplierModal}
                        destroyOnClose={true}
                        onOk={() => setTipsMultiplierModal(false)}
                        onCancel={() => setTipsMultiplierModal(false)}
                    >
                        <ModelReferralStakeMultiplierTips
                            currLevel={currMultiplierLevel?.ID}
                        />
                    </AntModal>
                    <AntModal
                        width='330px'
                        className="confirmModalWrap"
                        centered={true}
                        open={tipsBounsModal}
                        title={t('Claimable Bonus')}
                        destroyOnClose={true}
                        onOk={() => setTipsBounsModal(false)}
                        onCancel={() => setTipsBounsModal(false)}
                    >
                        <ModelReferralStakeBounsTips
                            closeFn={() => {
                                setTipsBounsModal(false)
                            }}
                        />
                    </AntModal>
                    <AntModal
                        width='330px'
                        title=''
                        className="confirmModalWrap tweetShareModal noImageTitle"
                        centered={true}
                        open={openSharePanel}
                        onOk={() => setOpenSharePanel(false)}
                        onCancel={() => setOpenSharePanel(false)}
                    >
                        <ModelReferralShare inviteCode={inviteCode}/>
                    </AntModal>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowOtherTransContent: (params) => {
            return dispatch({
                type: "app/setShowOtherTransContent",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ReferralEarnBonus));
