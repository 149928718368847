import React, {memo, useState, useMemo, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";

const ModelPermission = (
    {
        curentOtherUserInfo,
        type,
        openType = 1,
        closeHandle = () => {},
    }
) => {
    const {t, i18n} = useTranslation();
    return (
        <>
            {
                openType === 2 ?
                <div className={s.momentWrap}>
                    <div className={`${s.title} flex_center_center`}>
                        {t('Camera Permission Required')}
                    </div>
                    <div className="momentFont color-moment-gray fs12 fw-400" style={{marginTop: '35.94px', lineHeight: '14px'}}>
                        <span className="momentFont color-EEEEEE fs14 fb">{t('Step 1.')}</span>&nbsp;
                        {t('Please go to \'System Settings\'')} &gt; {t('Find Safari/Chrome to enable xx permission', {ty: type})}
                    </div>
                    <div className="momentFont color-moment-gray fs12 fw-400" style={{marginTop: '31px', lineHeight: '14px', marginBottom: '31.16px'}}>
                        <span className="momentFont color-EEEEEE fs14 fb">{t('Step 2.')}</span>&nbsp;
                        {t('Go to \'Browser Settings\'')} &gt; {t('Find Website Settings')} &gt; {t('enable www.turnup.so website permission')}
                    </div>
                    <CustomIcon 
                        className={`momentCloseBtn`} 
                        width={40} 
                        height={40} 
                        imgName={`Picture/UI_Picture_Icon_Close_02`}
                        onClick={() => {
                            closeHandle();
                        }} />
                </div> :
                <div className={s.wrap}>

                    <div className="color-white fs12">
                        <span className="color-yellow">{t('Step 1.')}</span>&nbsp;
                        {t('Please go to \'System Settings\'')} &gt; {t('Find Safari/Chrome to enable xx permission', {ty: type})}
                    </div>
                    <div className="color-white fs12">
                        <span className="color-yellow">{t('Step 2.')}</span>&nbsp;
                        {t('Go to \'Browser Settings\'')} &gt; {t('Find Website Settings')} &gt; {t('enable www.turnup.so website permission')}
                    </div>
                </div>
            }
        </>
    )
}
export default memo(ModelPermission);
