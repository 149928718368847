import React, {memo, useState, useMemo, useEffect, useRef} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {
    unit, referralcode_earndata, stringToNumber, referralcode_regen, copyFn,
} from "@/utils/common"
import AvatarInfo from "../../../common/AvatarInfo";
import CustomIcon from "../../../common/CustomIcon";
import AntModal from "@/common/AntModal";
import ModelReferralShare from "@/model/ModelReferralShare";
import ButtonFactory from "@/common/ButtonFactory";
import ReferralNavs from "@/common/ReferralNavs";
import ReferralEarnBonus from "@/views/games/ReferralEarn/bonus";
import ReferralEarnGroup from "@/views/games/ReferralEarn/group";


const ReferralEarn = (
    {
        inviteCode,
        children,
        userInfo
    }
) => {
    const {t, i18n} = useTranslation();

    const reInviteCode = useRef('');
    const refresh = () => {
        referralcode_regen().then(res => {
            reInviteCode.current = res?.referralCode
        }).catch(e => {

        })
    }

    const [openSharePanel, setOpenSharePanel] = useState(false);
    const [path, setPath] = useState('My Group');

    return (
        <>
            <div className={s.wrap} style={{paddingBottom: '0'}}>
                {path === 'My Group' && <ReferralEarnGroup children={children}/>}
                {path === 'Bonus' && <ReferralEarnBonus children={children}/>}
            </div>
            <div className={`${s.bottomPart} flex_center_start_col`}
                 style={{backgroundImage: `url(/UI/Picture/UI_Picture-Main_Down_04.png)`}}>
                <div className={s['content-title']}>
                    <div className=" fs20">{t('My Referral Code')}</div>
                    <div className={` mt15 df aic jcc`}>
                        <div className={`${s.nowrap} color-yellow fb`}
                             style={{fontSize: '16px'}}>{inviteCode || reInviteCode.current}</div>
                        {!inviteCode && !reInviteCode.current && <div className={s.refresh} onClick={refresh}>
                            <CustomIcon width={20} className="ml5" isHaveClick={true} height={20}
                                        imgName={'Button/UI_Button_reload_01'} name={'Button/UI_Button_reload_01'}/>
                        </div>}
                        <CustomIcon className="ml5" width={18} onClick={() => copyFn(inviteCode)} height={18}
                                    imgName={'Button/UI_Button_CopyNumberWordName_01'}
                                    name={'Button/UI_Button_CopyNumberWordName_01'}/>
                    </div>
                </div>
                <div className={`mt15`}>
                    <ButtonFactory onClick={() => {
                        setOpenSharePanel(true)
                    }}>
                        <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Share_02'}
                                    name={'Button/UI_Button_Share_02'}/>&nbsp;
                        {t('SHARE ON X')}
                    </ButtonFactory>
                </div>
            </div>
            <ReferralNavs
                path={path}
                setPath={setPath}
            />
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap tweetShareModal noImageTitle"
                centered={true}
                open={openSharePanel}
                onOk={() => setOpenSharePanel(false)}
                onCancel={() => setOpenSharePanel(false)}
            >
                <ModelReferralShare inviteCode={inviteCode}/>
            </AntModal>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowOtherTransContent: (params) => {
            return dispatch({
                type: "app/setShowOtherTransContent",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ReferralEarn));
