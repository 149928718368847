import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { BigNumber, ethers } from "ethers";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWallets, usePrivy } from "@privy-io/react-auth";
import { getExchangeRate, buyCoin, sellCoin, getBalance } from "@/utils/uniswapWeb3";
import MemeChainToken from "@/utils/json/memeChainToken.json";
import {
    LfgMainnetId,
    LfgTestnetId,
} from "@/utils/env";
import {
    checkTransaction,
    getAssetTransfers,
    getNftMetadata,
} from "@/utils/memeClubWeb3";
import { Input } from "antd";
import ButtonFactory from "@/common/ButtonFactory";
import {
    convertWalletBalance,
    stringToNumber,
    commonGet,
    logoutSelf,
    PrivyLoginStatusError,
} from "@/utils/common";
import { swapPresetPackNum, swapPresetSellArr } from "@/utils/memeClub";
import ModelSetSlippage from "@/model/ModelSetSlippage";
import AntModal from "@/common/AntModal";
import useLocalStorageState from "@/common/useLocalStorageState";
import {
    gameMemeClubRevealMemePackPath,
} from "@/routes/config";
import ModelSellCoinTips from "@/model/ModelSellCoinTips";
import ModelDeposit from "@/model/ModelDeposit";
import ModalSwapLfg from "@/model/ModalSwapLfg";

const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    return useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);
};
const SwapTrade = (
    {
        chainInfo,
        tradeType,
        nativeChain,
        coinToNFTRatio,
        coinBalance,
        coinChain,
        reFreshBalance,
        swapPoolAddr,
        nativeBalance = 9999999999999,
        clubBasicInfo,
        symbolName,
        isFT, // ture: only coinToken false: have 404NFT
        setMemeUnpackInfo,

        userInfo,
        walletBalanceList,
    }
) => {
    const { t, i18n } = useTranslation();
    const { wallets } = useWallets();
    const navigate = useNavigate();
    const { sendTransaction, logout } = usePrivy();
    const [isNative, setIsNative] = useState(false); //
    const [baseCoin, setBaseCoin] = useState(tradeType === 1 ? nativeChain : coinChain)
    const [exchangeVal, setExchangeVal] = useState(0)
    const [loading, setLoading] = useState(false);
    const [errorTips, setErrTips] = useState('');
    const [swapTransactionHash, setSwapTransactionHash] = useState(/*"0x7bfe8db027021fe3fb680fe72c89f9452a932f96b69c47b2c2acc1f5bf35ca75"*/'');
    const [swapTransactionHashCheckCount, setSwapTransactionHashCheckCount] = useState(0);
    const [swapTransaction, setSwapTransaction] = useState(null);
    const [done, setDone] = useState(false)
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [depositChainTokenInfo, setDepositChainTokenInfo] = useState(null);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [slippageTolerance, setSlippageTolerance] = useLocalStorageState('memeTrandSlippage', 30)
    const [privyNeedLogin, setPrivyNeedLogin] = useState(false)
    // useEffect(() => {
    //     console.log(`nativeBalance------->`, convertWalletBalance(nativeBalance));
    // }, [nativeBalance])

    const {
        walletAddr,
        selfData,
        topUpCode
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const {
        clubUserId,
        clubInfo,
        coinTokenAddr,
        holdNum,
    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])

    const {
        coinId,
        symbolImageUrl,
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    const nativeCoinInfo = useMemo(() => {
        let id = Math.floor(coinId / 100) * 100;
        return MemeChainToken.find(i => i?.ID === id);
    }, [coinId]);

    const exchangeRate = useDebounce(async (tokenA, tokenB, amount) => {
        if (!amount || Number(amount) === 0) return
        setLoading(true)
        getExchangeRate({
            wallets,
            amount: Number(amount),
            tokenA,
            tokenB,
            chainInfo: chainInfo,
            swapPoolAddr
        }).then(res => {
            setExchangeVal(res)
            setLoading(false)
            console.log(`rateNum`, res);
        }).catch(e => {
            setLoading(false)
            if (e === 'need login') {
                setPrivyNeedLogin(true)
            }
            console.error(e);

        })

    }, 1000)

    const swapPresetNumArr = useMemo(() => {
        const { swapPresetNum } = chainInfo || {};
        return swapPresetNum?.split(',') || []
    }, [chainInfo])

    // useEffect(()=>{
    //     getBalance({
    //         wallets,
    //         chainId:137,
    //         addr:'0x8bff1bD27E2789Fe390AcabC379C380a83B68E84'
    //     }).then(res=>{
    //         console.log(res);
    //     }).catch(e=>{
    //         console.error(e);
    //     })

    // },[])

    useEffect(() => {
        if (isNative) {
            setBaseCoin(nativeChain)
        } else {
            setBaseCoin(coinChain)
        }
        setExchangeVal(0)
    }, [isNative])

    const [amount, setAmount] = useState(0)

    useEffect(() => {
        let tokenA, tokenB;
        if (isNative) {
            tokenA = nativeChain
            tokenB = coinChain
        } else {
            tokenA = coinChain
            tokenB = nativeChain
        }

        exchangeRate(tokenA, tokenB, amount)
    }, [amount, coinToNFTRatio])

    useEffect(() => {
        if (swapTransactionHash) {
            checkTransaction(wallets, swapTransactionHash, coinChain?.chainId).then(res => {
                setSwapTransaction(res);
                setSwapTransactionHash(null);
            }).catch(e => {
                setTimeout(() => {
                    setSwapTransactionHashCheckCount(swapTransactionHashCheckCount + 1);
                }, 2000);
            })
        }
    }, [swapTransactionHash, swapTransactionHashCheckCount]);

    useEffect(() => {
        if (swapTransaction) {
            getAssetTransfers(wallets, coinChain?.chainId, swapTransaction).then(res => {
                console.log("getAssetTransfers", res);
                setSwapTransaction(null);
                let tempGetAmount = 0;
                let transfers = res?.transfers;
                let tokenIdList = [];
                let tokenList = [];
                if (transfers?.length > 0) {
                    for (let i = 0; i < transfers?.length; i++) {
                        if (transfers[i].category === 'erc20') {
                            tempGetAmount = ethers.utils.formatEther(transfers[i]?.rawContract?.value);
                        }
                        else if (transfers[i].category === 'erc721') {
                            let tokenId = parseInt(transfers[i].erc721TokenId.slice(2), 16);
                            tokenIdList.push(tokenId);
                        }
                    }
                }
                if (tokenIdList?.length > 0) {
                    for (let i = 0; i < tokenIdList?.length; i++) {
                        let tokenId = tokenIdList[i];
                        getNftMetadata(wallets, transfers[i]?.rawContract?.address, tokenId, coinChain?.chainId).then(res => {
                            console.log("metadatauri", res);
                            commonGet(res).then(data => {
                                console.log("getmetadata", data);
                                tokenList.push({
                                    token_id: tokenId,
                                    ...data,
                                });
                                if (tokenList.length >= tokenIdList.length) {
                                    setMemeUnpackInfo({
                                        amount: tempGetAmount,
                                        coins: [tempGetAmount],
                                        nftMetaDatas: tokenList
                                    });
                                    navigate(`${gameMemeClubRevealMemePackPath}/${clubUserId}`);
                                }
                            }).catch(e => {
                                console.error(e);
                                tokenList.push({
                                    token_id: tokenId,
                                });
                                if (tokenList.length >= tokenIdList.length) {
                                    setMemeUnpackInfo({
                                        amount: tempGetAmount,
                                        coins: [tempGetAmount],
                                        nftMetaDatas: tokenList
                                    });
                                    navigate(`${gameMemeClubRevealMemePackPath}/${clubUserId}`);
                                }
                            })
                        }).catch(e => {
                            console.error(e);
                        })
                    }
                }
                else {
                    setMemeUnpackInfo({
                        amount: tempGetAmount,
                        coins: [tempGetAmount],
                        nftMetaDatas: []
                    });
                    navigate(`${gameMemeClubRevealMemePackPath}/${clubUserId}`);
                }

            }).catch(e => {
                console.error("get asset transfers error", e);
            })
        }
    }, [swapTransaction]);

    const trandFn = () => {
        if (loading) return
        setLoading(true)
        tradeType === 1 ?
            buyCoin({
                wallets,
                nativeAmount: isNative ? amount : exchangeVal,
                coinAmount: isNative ? exchangeVal : amount,
                sendTransaction,
                tokenA: nativeChain,
                tokenB: coinChain,
                chainInfo: chainInfo,
                slippageTolerance: slippageTolerance,
                swapPoolAddr
            }).then(res => {
                reFreshBalance();
                setAmount(0)
                setLoading(false)
                setDone(true)
                setSwapTransactionHash(res?.transactionHash);
            }).catch(e => {
                setLoading(false)
                setDone(true)
                setErrTips(e)
                console.error(e);
            }) :
            sellCoin({
                wallets,
                nativeAmount: exchangeVal,
                coinAmount: amount,
                sendTransaction,
                tokenA: coinChain,
                tokenB: nativeChain,
                chainInfo: chainInfo,
                slippageTolerance: slippageTolerance,
                swapPoolAddr
            }).then(res => {
                reFreshBalance()
                setAmount(0)
                setLoading(false)
                setDone(true)
            }).catch(e => {
                setLoading(false)
                setDone(true)
                setErrTips(e)
                console.error(e);
            })

    }
    const [currentProgress, setCurrentProgress] = useState(0);
    const [nftAmount, setNftAmount] = useState(0);
    const [leftAmount, setLeftAmount] = useState(0);
    const [addProgress, setAddProgress] = useState(0);
    const [showPanel, setShowPanel] = useState(false)
    useEffect(() => {
        if (!coinToNFTRatio) return
        if (tradeType === 1) {
            const toCoinTotal = (isNative ? Number(exchangeVal) : Number(amount));
            setAddProgress(parseInt((toCoinTotal + coinBalance % coinToNFTRatio) % coinToNFTRatio / coinToNFTRatio * 100))
            setCurrentProgress(parseInt(coinBalance % coinToNFTRatio / coinToNFTRatio * 100))
            setNftAmount(Math.floor(toCoinTotal / coinToNFTRatio))
            setLeftAmount(parseInt(coinToNFTRatio - ((toCoinTotal + coinBalance % coinToNFTRatio) % coinToNFTRatio)))
        }
        if (tradeType === 2) {
            setCurrentProgress(parseInt(coinBalance % coinToNFTRatio / coinToNFTRatio * 100))
            console.log(amount, coinBalance);
            console.log(parseInt((coinBalance % coinToNFTRatio - amount) % coinToNFTRatio / coinToNFTRatio * 100));
            const _plsProgress = parseInt(amount % coinToNFTRatio / coinToNFTRatio * 100)
            setAddProgress(_plsProgress)
            const _b = coinBalance % coinToNFTRatio;
            if (_b - Number(amount) >= 0) {
                setNftAmount(0)
            } else {
                setNftAmount(Math.ceil(Math.abs((_b - Number(amount))) / coinToNFTRatio))
            }
            const _left = parseInt((coinBalance % coinToNFTRatio - amount) % coinToNFTRatio)
            setLeftAmount(_left > 0 ? _left : (coinToNFTRatio + _left))
        }

    }, [coinToNFTRatio, isNative, amount, exchangeVal, tradeType, coinBalance])

    const [balanceEnough, setBalanceEnough] = useState(true)

    const isDisabled = useMemo(() => {
        if (Number(amount) && Number(exchangeVal)) {
            if (tradeType === 1) {
                if (isNative) {
                    setBalanceEnough(nativeBalance >= Number(amount))
                    return nativeBalance < Number(amount)
                } else {
                    setBalanceEnough(nativeBalance >= Number(exchangeVal))
                    return nativeBalance < Number(exchangeVal)
                }
            }

            if (tradeType === 2) {
                setBalanceEnough(Number(amount) <= Number(coinBalance))
                return Number(amount) > Number(coinBalance)
            }
        } else {
            setBalanceEnough(true)
        }
        return true
    }, [amount, exchangeVal, coinBalance, nativeBalance, tradeType, isNative])

    const isNativeCoin = () => {
        if (chainInfo) {
            return chainInfo?.isNative === 1;
        }
        return true;
    }

    const amountChangeHandler = (e) => {
        let newValue = e.target.value;
        if (/^\d*\.?\d*$/.test(newValue) || newValue === '' || newValue === '.') {
            setAmount(newValue);
        }
    }

    const amountBlurHandler = (e) => {
        let newValue = e.target.value;
        if (/^\d*\.?\d*$/.test(newValue) || newValue === '' || newValue === '.') {
            setAmount(newValue);
        }
    }

    const getSpecifiedCoinBalance = (id) => {
        return walletBalanceList?.[id] || 0;
    }

    const [showSellTips, setShowSellTips] = useState(false)


    return (
        <div className={`${s.boxRoot}`} style={{borderRadius: tradeType === 1 ? `0px 8px 8px 8px`:`8px 0px 8px 8px` }}>
            <div className={`${s.balanceRoot} flex_center_between`}>
                <div className="ml10 flex_center_start fb" style={{ padding: "4px 0px" }}>
                    {t('Wallet Balance')}
                    <div className={`${s.memeIcon}`} style={{ backgroundImage: `url(${symbolImageUrl})` }}>
                    </div>
                    {convertWalletBalance(coinBalance || 0)}
                </div>
                <div className={`${s.overview} flex_center_center_col`}>
                    {
                        !isNativeCoin() && <div className={s.overviewItem}>
                            <CustomIcon width={18} height={18} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                            <span className={`${s.balance} color-yellow`}>
                                {convertWalletBalance(getSpecifiedCoinBalance(nativeCoinInfo?.ID))}
                            </span>
                            <span className={`${s.overviewItemName}`}>
                                {nativeCoinInfo?.displayCoinName}
                            </span>
                            <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Plus-Yellow-Sq_01'}
                                onClick={() => {
                                    setShowDepositPanel(true);
                                    setDepositChainTokenInfo(nativeCoinInfo);
                                }} />
                        </div>
                    }
                    <div className={s.overviewItem}>
                        <CustomIcon width={18} height={18} imgName={`Picture/${chainInfo?.icon}`} />
                        <span className={`${s.balance} color-yellow`}>
                            {convertWalletBalance(getSpecifiedCoinBalance(chainInfo?.ID))}
                        </span>
                        <span className={`${s.overviewItemName}`}>
                            {chainInfo?.displayCoinName}
                        </span>
                        <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Plus-Yellow-Sq_01'}
                            onClick={() => {
                                if (isNativeCoin()) {
                                    setShowDepositPanel(true);
                                    setDepositChainTokenInfo(chainInfo);
                                }
                                else if (chainInfo?.ID === LfgMainnetId || chainInfo?.ID === LfgTestnetId) {
                                    setShowSwapLfg(true);
                                }
                            }} />
                    </div>
                </div>
            </div>
            <div className={`${s.box}`}>
                <div className="flex_center_between">
                    {tradeType === 1 ?
                        <div className={s.changeRetaType} onClick={() => {
                            setAmount(0)
                            setIsNative(!isNative)
                        }}>{isNative ? t('Switch to ${{symbolName}} ', { symbolName: symbolName }) : t('Switch to {{nativeName}}', { nativeName: nativeChain?.displayCoinName })} </div>
                        : <div></div>
                    }
                    <div className={s.changeRetaType} onClick={() => {
                        setShowPanel(true)
                    }}>{t('Set Max Slippage')}</div>

                </div>
                <div className={s.input}>
                    <Input
                        // onChange={(e) => {
                        //     setAmount(e.target.value)
                        // }}
                        onChange={amountChangeHandler}
                        onBlur={amountBlurHandler}
                        value={amount}
                        suffix={
                            <div className="flex_center_center">
                                <div className="fs18">{baseCoin?.displayCoinName}</div>
                                {isNative ?
                                    <CustomIcon imgName={`Picture/${baseCoin?.icon}`} className="ml5" width={24} height={24}></CustomIcon>
                                    : <img src={baseCoin?.icon} className="ml5" width={24} height={24} style={{ borderRadius: '12px' }}></img>
                                }

                            </div>
                        }
                        style={{ fontSize: '18px' }}
                    ></Input>
                </div>

                <div className={s.presets}>
                    <div className={s.presetI} onClick={() => { setAmount(0) }}>
                        {t('Reset')}
                    </div>
                    {
                        tradeType === 1 &&
                        <>
                            {
                                isNative ?
                                    swapPresetNumArr?.map((item, ind) => {
                                        return (
                                            <div key={ind} className={s.presetI} onClick={() => {
                                                setAmount(Number(item))
                                            }}>
                                                {item}
                                            </div>
                                        )
                                    })
                                    :
                                    !isFT && swapPresetPackNum?.map((item, ind) => {
                                        // return (
                                        //     <div key={ind} className={s.presetI} onClick={() => {
                                        //         setAmount(Number(item) * coinToNFTRatio)
                                        //     }}>
                                        //         {item} {t('NFT')}
                                        //     </div>
                                        // )
                                    })
                            }
                        </>

                    }
                    {
                        tradeType === 2 &&

                        swapPresetSellArr?.map((item, ind) => {
                            return (
                                <div key={ind} className={s.presetI} onClick={() => {

                                    setAmount(item === 1 ? coinBalance : Number(item * coinBalance))
                                }}>
                                    {parseInt(item * 100)}%
                                </div>
                            )
                        })
                    }
                </div>


                <div className="flex_center_start mt10 mb10">
                    <div className="fs18">
                        {stringToNumber(exchangeVal, 10)} {isNative ? coinChain?.displayCoinName : nativeChain?.displayCoinName}
                    </div>
                </div>

                {
                    !isFT &&
                    <>
                        <div className="fs12 color-blue-light tll ">{tradeType === 1 ? t('Generate 1 NFT for every: {{amount}} ', { amount: convertWalletBalance(coinToNFTRatio) }) : t('Burn 1 NFT for holding less than: {{amount}}', { amount: convertWalletBalance(coinToNFTRatio) })} ${symbolName}</div>
                        {tradeType === 1 &&
                            <div className={s.progress}>
                                <div className={s.proWrap}>
                                    <div className={s.l}>
                                        <div className={s.proVal} style={{
                                            width: `${currentProgress}%`
                                        }}>
                                        </div>
                                        <div className={`${s.proValAdd}`} style={{
                                            width: `${nftAmount > 0 ? '100' : addProgress}%`,

                                        }}>
                                        </div>
                                        {/* <div className={s.val}>{`${nftAmount > 0 ? 'NFT +' + nftAmount + ',' : ''} ${t('Next Req')}: ${convertWalletBalance(leftAmount)}`}</div> */}
                                        <div className={s.val}>{`${nftAmount} NFT. ${t('Buy')} ${convertWalletBalance(leftAmount)} more to generate 1 ${nftAmount > 0 ? 'more' : ''}`}</div>
                                    </div>
                                </div>
                            </div>
                        }
                        {tradeType === 2 &&
                            <div className={s.progress}>
                                <div className={s.proWrap}>
                                    <div className={`${s.l} ${nftAmount > 0 ? s.over : ''}`}>
                                        <div className={s.proVal} style={{
                                            width: `${currentProgress}%`
                                        }}>
                                        </div>
                                        <div className={`${s.proValLeft}`} style={{
                                            width: `${nftAmount > 0 ? '100' : Math.abs(amount ? addProgress : 0)}%`,
                                            left: `${(nftAmount && addProgress) > 0 ? '0' : (currentProgress - addProgress)}%`
                                        }}>
                                        </div>
                                        {/* <div className={s.val}>{`${nftAmount > 0 ? 'NFT -' + nftAmount + ',' : ''} ${t('Next burned')}: ${convertWalletBalance(Math.abs(leftAmount))}`}</div> */}
                                        <div className={s.val}>{`${nftAmount > 0 ? 'Burn ' + nftAmount + ' NFT.' : ''}`}</div>
                                    </div>
                                </div>
                            </div>
                        }

                    </>
                }

                {
                    nftAmount >= 100 &&
                    <div className={`${s.nftTipLine}`}>
                        {t('Num of NFTs minted is too high, transaction may fail.')}
                    </div>
                }
                {/*<div className="w100p flex_center_center">
                    <div className="fs12">{t('Your coinBalance')}</div>
                    <div className="fs18 ml10">{convertWalletBalance(coinBalance)}</div>
                </div>*/}
                <div className={s.exchange}>
                    {
                        !privyNeedLogin &&
                        <>
                            {done ?
                                <ButtonFactory
                                    onClick={() => {
                                        setDone(false)
                                        setErrTips('')
                                    }}
                                >{errorTips ? errorTips : t('Swap Success')}</ButtonFactory> :
                                balanceEnough ?
                                    <ButtonFactory
                                        disabled={isDisabled || loading}
                                        loading={loading}
                                        onClick={() => {
                                            if (tradeType === 2 && nftAmount > 0 && !isFT) {
                                                setShowSellTips(true)
                                                return
                                            }
                                            trandFn()
                                        }}
                                    >{amount <= 0 ? t('Enter Amount') : loading ? t('Checking') : tradeType === 1 ? t('Buy') : t('Sell')}</ButtonFactory>
                                    :
                                    <ButtonFactory
                                        disabled={isDisabled}
                                    >{t('Insufficient Balance')}</ButtonFactory>}

                        </>
                    }
                    {privyNeedLogin && <ButtonFactory
                        onClick={() => {
                            logout()
                            logoutSelf()
                        }}
                    >{PrivyLoginStatusError}</ButtonFactory>
                    }
                </div>
                <AntModal
                    width='330px'
                    title={t('Set max. Slippage (%)')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showPanel}
                    onOk={() => setShowPanel(false)}
                    onCancel={() => setShowPanel(false)}
                >
                    <ModelSetSlippage
                        val={slippageTolerance}
                        setVal={setSlippageTolerance}
                        closeFn={() => {
                            setShowPanel(false)
                        }} />
                </AntModal>

                <AntModal
                    width='330px'
                    title={t('Notice')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showSellTips}
                    onOk={() => setShowSellTips(false)}
                    onCancel={() => setShowSellTips(false)}
                >
                    <ModelSellCoinTips
                        info={{
                            coinBalance: coinBalance,
                            coinToNFTRatio,
                            sellAmount: amount,
                            nftAmount: nftAmount,
                            symbolName: coinChain?.displayCoinName
                        }}
                        tranFn={() => {
                            setShowSellTips(false)
                            trandFn()
                        }}
                        closeFn={() => {
                            setShowSellTips(false)
                        }} />
                </AntModal>


            </div>
            <AntModal
                width='330px'
                title={t('Get More {{coin}}', { coin: nativeCoinInfo?.displayCoinName })}
                className="confirmModalWrap"
                centered={true}
                open={showDepositPanel}
                onOk={() => setShowDepositPanel(false)}
                onCancel={() => setShowDepositPanel(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModelDeposit
                    topUpCode={topUpCode}
                    walletAddr={walletAddr}
                    setShowDepositPanel={setShowDepositPanel}
                    chainTokenInfo={depositChainTokenInfo} />
            </AntModal>

            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg
                    userInfo={userInfo}
                    closeFn={() => {
                        setShowSwapLfg(false);
                    }} />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        walletBalanceList: app.walletBalanceList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMemeUnpackInfo: (params) => {
            return dispatch({
                type: "app/setMemeUnpackInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(SwapTrade));
