import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";

import {Button} from "antd";
import CustomIcon from "../../common/CustomIcon";
import imgUrl from "@/assets/images/FingersCrossed.png";
import {useTranslation, Trans} from 'react-i18next';
import BPLevelConfig from '@/utils/json/BPLevelConfig.json';

const ModelBattlePointTip = (
    {
        userInfo,
        closeFn
    }
) => {
    const {t, i18n} = useTranslation();
    const {selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        bpLevel
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const battlePointsShowList = useMemo(() => {
        let list = [];
        if (BPLevelConfig) {
            if (bpLevel <= 3) {
                for (let i = 0; i < Math.min(BPLevelConfig.length, 4); i++) {
                    list.push(BPLevelConfig[i]);
                }
            } else {
                let startIndex = bpLevel - 2;
                if (startIndex > BPLevelConfig.length - 3) {
                    startIndex = BPLevelConfig.length - 3;
                }
                for (let i = startIndex; i < startIndex + 3; i++) {
                    list.push(BPLevelConfig[i]);
                }
            }
        }
        return list;
    }, [bpLevel]);
    return (
        <div className={s.wrap}>
            <div>
                <div>
                    <div className={`fs18 color-white tll mt15 ${s.bigtit}`}>
                        <CustomIcon width={20} height={20} imgName={'Button/UI_TT_Button_Rank_On'}
                                    name={'Button/UI_TT_Button_Rank_On'}/>
                        <span className="ml5 fb">{t('LOOTER')}</span>
                    </div>
                    <div className={`fs18 color-white mt15 ${s.textlist}`}>
                        <div className={`fs12 color-white  tll mt10 ${s.midtit}`}>
                            <div className={`${s.circle}`}></div>
                            {t('Looting Success')}
                        </div>
                        <div className={`fs12 color-blue-light tll mt5 ${s.text}`}>
                            {t('Looting Success line 1')}
                        </div>
                        <div className={`fs12 color-white  tll mt10 ${s.midtit}`}>
                            <div className={`${s.circle}`}></div>
                            {t('Looting Failure')}
                        </div>
                        <div className={`fs12 color-blue-light tll mt5 ${s.text}`}>
                            {t('Looting Failure line 1')}
                        </div>
                    </div>
                </div>
                <div className={s.textbox}>
                    <div className="fs18 color-white tll mt15">
                        <CustomIcon width={20} height={20} imgName={'icon_Defender'} name={'icon_Defender'}/>
                        <span className="ml5 fb">{t('DEFENDER')}</span>
                    </div>
                    <div className={`fs18 color-white mt15 ${s.textlist}`}>
                        <div className={`fs12 color-white  tll mt10 ${s.midtit}`}>
                            <div className={`${s.circle}`}></div>
                            {t('Defense Success')}
                        </div>
                        <div className={`fs12 color-blue-light tll mt5 ${s.text}`}>
                            {t('Defense Success line 1')}
                        </div>
                        <div className={`fs12 color-white  tll mt10 ${s.midtit}`}>
                            <div className={`${s.circle}`}></div>
                            {t('Defense Failure')}
                        </div>
                        <div className={`fs12 color-blue-light tll mt5 ${s.text}`}>
                            {t('Defense Failure line 1')}<br></br>
                            {t('Defense Failure line 2')}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="fs18 color-white tll mt15">
                        <CustomIcon width={16} height={20} imgName={'battlePointTierIcon'}
                                    name={'battlePointTierIcon'}/>
                        <span className="ml5 fb">{t('BATTLE POINTS TIER')}</span>
                    </div>
                    <table className={s.battleTable}>
                        <thead>
                        <tr className={s.lineHead}>
                            <th className={s.colTier}>{t("Tier")}</th>
                            <th className={s.colRange}>{t("Battle Points Range")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {battlePointsShowList?.length < 4 && (
                            <tr className={s.lineValue}>
                                <td>...</td>
                                <td>...</td>
                            </tr>
                        )}
                        {battlePointsShowList?.map((item, index) => (
                            <tr className={`${item.ID === bpLevel ? s.currentLine : s.lineValue}`}>
                                <td>{item.ID}</td>
                                <td>{`${item.rangeMin} - ${item.rangeMax}`}</td>
                            </tr>))
                        }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}
export default memo(ModelBattlePointTip);
