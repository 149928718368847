import React from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { gameShareDetailPath, gameTrillionaireBattleLeaderBoardPath, mainProfilePath } from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelUpXFarm = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className={s.modelWrap}>
            <div className="fb">{t('Engage With @turnupdotxyz Account Posts')}</div>
            <div className="">
                <Trans
                    i18nKey='Likes = you earn 5 points <frie></frie>'
                    defaults='Likes = you earn 5 points <frie></frie>'
                    components={{ frie: <CustomIcon imgName="Picture/UI_Picture-Currency_UP_01" width={16} height={16}></CustomIcon> }}
                />
                <br />
                <Trans
                    i18nKey='Repost = you earn 10 points <frie></frie>'
                    defaults='Repost = you earn 10 points <frie></frie>'
                    components={{ frie: <CustomIcon imgName="Picture/UI_Picture-Currency_UP_01" width={16} height={16}></CustomIcon> }}
                />
            </div>
            <div className="fb mt20">{t('Create posts mentioning @turnupdotxyz & #IYKYK')}</div>
            <Trans
                    i18nKey='Per View = you earn 0.01 point <frie></frie>'
                    defaults='Per View = you earn 0.01 point <frie></frie>'
                    components={{ frie: <CustomIcon imgName="Picture/UI_Picture-Currency_UP_01" width={16} height={16}></CustomIcon> }}
                />
                <br />
                <Trans
                    i18nKey='Per Like = you earn 1 points <frie></frie>'
                    defaults='Per Like = you earn 1 points <frie></frie>'
                    components={{ frie: <CustomIcon imgName="Picture/UI_Picture-Currency_UP_01" width={16} height={16}></CustomIcon> }}
                />
        </div>
    )
}

export default ModelUpXFarm
