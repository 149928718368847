import React, {useMemo, useEffect, memo, useState, useRef} from "react";
import s from "./index.module.less";


import {connect} from "react-redux";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';

const ModelReferralStakeBounsTips = (
    {
        closeFn,
    }
) => {
    const {t, i18n} = useTranslation();

    return (
        <div className={s.content}>
            <div className={s.wrap}>
                <div className={s.noticetext}>
                    <Trans
                        i18nKey="Mint your $LFG on-chain after an <red>8-hour</red> review"
                        defaults="Mint your $LFG on-chain after an <red>8-hour</red> review"
                        components={{red: <span className="color-red"></span>}}
                    /><br/>
                    <Trans
                        i18nKey="Click <icon></icon> on your profile to view status & claim $LFG"
                        defaults="Click <icon></icon> on your profile to view status & claim $LFG"
                        components={{
                            icon: <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Hourglass_01'}
                                              name={'Button/UI_Button_Hourglass_01'}/>
                        }}
                    /><br/>
                    {t('The claimable bonus consists of the following two components')}<br/>
                    {t('-Total staking bonuses from all clubs')}<br/>
                    {t('-Bonus returned due to on-chain failures')}<br/>

                </div>
            </div>
        </div>
    );
};
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelReferralStakeBounsTips));
