import http from "@/utils/axios";
import {url} from "@/utils/configUri";

export function secondsToDays(seconds) {
    var days = Math.floor(seconds / (24 * 60 * 60));
    return Math.max(0, days);
}

export function secondsToHours(seconds) {
    var hours = Math.floor(seconds / (60 * 60));
    return Math.max(1, hours);
}

export function post_referral_stake_get_reward() {
    return new Promise((resolve, reject) => {
        http('post', url.referral_stake_get_reward, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("post_referral_stake_get_reward body: " + res)
                reject(res.code)
            }
        }).catch(e => {
            console.error("post_referral_stake_get_reward err", e)
            reject('network error')
        });
    })
}


export const post_referralcode_stakelfg = (cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.referralcode_stakelfg, {
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("referralcode_stakelfg: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}
