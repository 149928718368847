import React, {memo, useEffect, useMemo, useState, useRef} from "react";
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import { Input, Radio, Space, Select, Upload, Modal, Checkbox } from "antd";
import AliyunUpload from "aliyun-upload-oss";
import AntButton from "@/common/AntButton";
import s from "./index.module.less"
import {
    getearninfo,
    getProfile,
    formatNumberWithUnit,
    getfriendtrade_rank_selfdata,
    getAvatarFromUserInfo,
    filterErrorCode,
    getQuotes,
    getTokenBalanceOf,
    numberClamp,
    logoutSelf,
} from "@/utils/common";
import {
    coinData,
    creatClub,
    chainData,
    airdropTypeData,
    coinTotalSupplyData,
    airdropPecentageData,
    coinToNFTPecentageData,
    calcTotalValue,
    momentChainToken,
    coinList,
    preferencesObj,
    preferencesItems,
    formatAttr,
    defaultAttr,
    momentGenattrs,
    checkAttrs,
    websiteAfterAddon,
    getMomentGenImage,
    calcNumberCardVal,
    setAnony,
    getChainInfo,
    momentPriceFormular,
    video_upload_token,
    video_upload_token_refresh,
    video_check,
    video_play_token,
    moment_buyresult,
    moment_prebuyprice,
} from "@/utils/momentClub";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import {
    creatMomentClub,
    getMomentEntropyFee,
} from "@/utils/momentClubWeb3";
import { 
    gameMomentClubExplorePath
} from "@/routes/config";
import { BigNumber, ethers } from "ethers";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate} from "react-router-dom";
import CrackList from "src/common/CrackList";
import ModelDeposit from "src/model/ModelDeposit";
import ModelRankReward from 'src/model/ModelRankReward';
import RankReward from '@/utils/json/RankReward.json';
import ButtonFactory from "@/common/ButtonFactory";
import MainHeader from "@/common/MainHeader";
import MemeClubPack from "@/common/MemeClubPack";
import Countdown from "src/common/Countdown";
import ModalMemeLotteryDetail from "@/model/ModalMemeLotteryDetail";
import BattleHireFilter from "@/drawer/BattleHireFilter";
import AntModal from "@/common/AntModal";
import MemeCreatePreview from "./MemeCreatePreview";
import memeChainToken from "@/utils/json/memeChainToken.json";
import MomentImageTemplate from "@/utils/json/momentImageTemplate.json";
import ModalMomentCreatePreBuy from "@/model/ModalMomentCreatePreBuy";
import ModalPackTransResult from "@/model/ModalPackTransResult";

const UPLOAD_USE_ALIYUN_ACCELERATE = true
const ALIYUN_ACCELERATE_ENDPOINT = 'https://oss-accelerate.aliyuncs.com';

const tickerMaxLength = 16;
const curveType = 2;

const MemeCreate = (
    {
        userInfo,
        walletBalanceList,
        setWalletBalanceList,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {logout} = usePrivy();
    const { walletAddr } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const { TextArea } = Input;
    const vodUploaderRef = useRef(null);
    const { wallets } = useWallets();
    const [clubTicker, setClubTicker] = useState('');
    const [clubDesc, setClubDesc] = useState('');
    const [clubXlink, setClubXlink] = useState('');
    const [clubTlink, setClubTlink] = useState('');
    const [selCoinId, setSelCoinId] = useState(coinList[0]?.value);
    const [coverUrl, setCoverUrl] = useState('');
    const [openCoin, setOpenCoin] = useState(false);
    const [isAnony, setIsAnony] = useState(false);
    const [preBuyAmount, setPreBuyAmount] = useState(0);
    const [symbolImageId, setSymbolImageId] = useState(1);
    const [selPreferencesType, setSelPreferencesType] = useState(preferencesItems[0]?.value);
    const [openPreferences, setOpenPreferences] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [showMemeCreatePreview, setShowMemeCreatePreview] = useState(false);
    const [showPreBuyModal, setShowPreBuyModal] = useState(false);
    const [currencyType, setCurrencyType] = useState(1);//0:7631, 1:20
    const [createError, setCreateError] = useState(false);
    const [createErrorMsg, setCreateErrorMsg] = useState(null);
    const [createDone, setCreateDone] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [marketInfo, setMarketInfo] = useState({});
    const [checkVideoSuccess, setCheckVideoSuccess] = useState(false);
    const [totalClipIndex, setTotalClipIndex] = useState(0);

    const [pendingUploadFile, setPendingUploadFile] = useState(null);
    const [pendingUploadFileDesc, setPendingUploadFileDesc] = useState('');
    const [uploadingDesc, setUploadingDesc] = useState('');
    const [uploadingProgress, setUploadingProgress] = useState(0);
    const [lastUploadedVideoId, setLastUploadedVideoId] = useState('');
    const [uploadingInProgress, setUploadingInProgress] = useState(false);
    const [videoChecking, setVideoChecking] = useState(false);
    const [checkVideoTimes, setCheckVideoTimes] = useState(0);
    const [checkVideoFailed, setCheckVideoFailed] = useState(false);
    const [checkVideoFailedMsg, setCheckVideoFailedMsg] = useState('');
    const [checkVideoFailedCode, setCheckVideoFailedCode] = useState(0);
    const [needLogin, setNeedLogin] = useState(false);

    const fileSelectorRef = useRef(null);

    const chainCoinInfo = useMemo(() =>{
        return getChainInfo(selCoinId);
    }, [selCoinId]);

    const onChangeCoinId = (value) => {
        const selChainInfo = getChainInfo(value)
        if(!selChainInfo?.allowTge){
            setCanTGE(2)
        }
        setSelCoinId(value);
    };

    const randomSymbolImageId = () =>{
        if (MomentImageTemplate?.length > 0){
            let random = Math.random();
            let id = Math.floor(random * MomentImageTemplate?.length + 1);
            setSymbolImageId(id);
        }
    }

    const preCreateFn = () =>{
        setShowPreBuyModal(true);
    }

    const priceFormularInfo = useMemo(() =>{
        return momentPriceFormular?.find(v => (v?.chainTokenConfigId === selCoinId && v?.formulaEnum === curveType));
    }, [momentPriceFormular]);

    const paramN = useMemo(() =>{
        let t = totalClipIndex;
        return t * (Math.log(t) + 0.577);
    }, [totalClipIndex]);

    const paramA = useMemo(() =>{
        let res = 0;
        if (priceFormularInfo){
            let c = priceFormularInfo?.divParamC;
            let d = priceFormularInfo?.mulParamD;
            let t = totalClipIndex;
            let ratioToUSD = priceFormularInfo?.ratioToUSD / 10000;
            res = Math.ceil(((800 * t + 3000) * c / d / ratioToUSD) / ((paramN * (paramN + 1) * (2 * paramN + 1)) / 6));
            res = Math.max(1, res);
        }
        return res;
    }, [paramN, priceFormularInfo]);
    const [preBuyTotalValue,setPreBuyTotalValue] = useState(0)
    useEffect(() => {
        // let paramB = 0;
        // let _t = calcTotalValue(priceFormularInfo, paramA, paramB, preBuyAmount);
        // console.log(_t.toString());
        // const bigT = ethers.utils.parseEther(_t);
        // console.log(`preBuyTotalValue------>`,_t,bigT.toString());

        // const bigM = bigT.mul(102).div(100);
        // const bigMStr = ethers.utils.formatEther(bigM).toString();
        // console.log(`preBuyTotalValue X 1.02------>`,ethers.utils.formatEther(bigM).toString());
        // const bigT = ethers.BigNumber.from(bigT.toString())
        // const bigM = ethers.BigNumber.from('1.021')
        // const bigR = bigT.mul(bigM)
        // const bigR = bigT.mul(bigM)
        // console.log(bigMStr);
        if(!preBuyAmount || !totalClipIndex){
            return
        }
        moment_prebuyprice({
            coinId:selCoinId,
            curveType: curveType,
            preBuyNum: preBuyAmount,
            cardKindNum: totalClipIndex
        }).then(res=>{
            setPreBuyTotalValue(res?.buyPriceWithFee)
        })
    }, [preBuyAmount, totalClipIndex])
    const [startPrice,setStartPrice] = useState(0)
    useEffect(()=>{
        if(!totalClipIndex){
            return
        }
        moment_prebuyprice({
            coinId:selCoinId,
            curveType: curveType,
            preBuyNum: 1,
            cardKindNum: totalClipIndex
        }).then(res=>{
            setStartPrice(res?.buyPriceWithFee)
        }) 
    },[totalClipIndex])
    

    useEffect(() => {
        if (!pendingUploadFile) {
          setPendingUploadFileDesc("");
          return;
        }
    
        console.log("[pendingUploadFile]", pendingUploadFile);
    
        let numberOfBytes = pendingUploadFile.size;
        // 近似到最接近的前缀单位
        const units = ["B", "KiB", "MiB", "GiB", "TiB"];
        const exponent = Math.min(
          Math.floor(Math.log(numberOfBytes) / Math.log(1024)),
          units.length - 1
        );
        const approx = numberOfBytes / 1024 ** exponent;
        const output =
          exponent === 0
            ? `[${numberOfBytes} Bytes]`
            : `[${approx.toFixed(3)} ${units[exponent]}]`;
    
        setPendingUploadFileDesc(`${pendingUploadFile.name} ${output}`);
      }, [pendingUploadFile]);
    
    function base64ToBytes(base64) {
        const binString = atob(base64);
        return Uint8Array.from(binString, (m) => m.codePointAt(0));
    }
    
    function bytesToBase64(bytes) {
        const binString = Array.from(bytes, (byte) =>
            String.fromCodePoint(byte),
        ).join("");
        return btoa(binString);
    }

    function modifyUploadAccelerate(uploadAddressBase64){
        if (!UPLOAD_USE_ALIYUN_ACCELERATE){
            return uploadAddressBase64;
        }else{
            let result = uploadAddressBase64;
            try{
                const uploadAddrString = new TextDecoder().decode(base64ToBytes(uploadAddressBase64));
                const uploadAddrObject = JSON.parse(uploadAddrString);
                uploadAddrObject.Endpoint = ALIYUN_ACCELERATE_ENDPOINT;
    
                console.log('[UploadAccelerate]', uploadAddrObject);
    
                const modifiedAddrString = JSON.stringify(uploadAddrObject);
                result = bytesToBase64(new TextEncoder().encode(modifiedAddrString));
            }catch(e){
                console.warn('[useUploadAccelerate] base64 process exception', e);
            }

            return result;
        }
    }

    function createVideoUploader() {
        if (!pendingUploadFile) {
            console.error("[createVideoUploader] select valid video file first!");
            return null;
        }
        let self = this;
        let uploader = new AliyunUpload.Vod({
            //阿里账号ID，必须有值
            userId: userInfo.userId,
            //上传到视频点播的地域，默认值为'cn-shanghai'，//eu-central-1，ap-southeast-1
            region: "",
            //分片大小默认1 MB，不能小于100 KB
            partSize: 1048576,
            //并行上传分片个数，默认5
            parallel: 5,
            //网络原因失败时，重新上传次数，默认为3
            retryCount: 3,
            //网络原因失败时，重新上传间隔时间，默认为2秒
            retryDuration: 2,
        
            localCheckpoint: true, //此参数是禁用服务端缓存，不影响断点续传
    
            // 添加文件成功
            addFileSuccess: function(uploadInfo) {
                self.uploadDisabled = false;
                self.resumeDisabled = false;
                //self.statusText = "添加文件成功, 等待上传...";
                console.log("addFileSuccess: " + uploadInfo.file.name);
            },
    
            // 开始上传
            onUploadstarted: function(uploadInfo) {
                setUploadingInProgress(true);
                setUploadingDesc('Checking Video');
        
                // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
                // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
                // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
                // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
                // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
                // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
                if (!uploadInfo.videoId) {
                    video_upload_token(
                        {fileName : pendingUploadFile.name, fileSize : pendingUploadFile.size}).then((authObject)=>{
        
                            let {uploadAuth, uploadAddress, videoId} = authObject;
                            uploadAddress = modifyUploadAccelerate(uploadAddress);
                            
                            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
                
                            setUploadingDesc('Connecting Server');
        
                            console.log('[onUploadstarted]', 
                                uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object);

                        }).catch((e)=>{
                            console.error('[video_upload_token] FAILED', e);
                            setUploadingInProgress(false);
                            setUploadingDesc('Upload failed ');
                            setCheckVideoFailed(true);
                            if (e === 1205){
                                setCheckVideoFailedMsg(t('Invalid video format'));
                                setCheckVideoFailedCode(e);
                            }
                            else{
                                setCheckVideoFailedMsg(t('Please upload again'));
                                setCheckVideoFailedCode(e);
                            }
                        });
        
                } else {
                // 如果videoId有值，根据videoId刷新上传凭证
                    video_upload_token_refresh(
                        {videoId : uploadInfo.videoId}).then((authObject)=>{
        
                            let {uploadAuth, uploadAddress, videoId} = authObject;
                            uploadAddress = modifyUploadAccelerate(uploadAddress);
                            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
                
                            //self.statusText = "文件开始上传...";
                            console.log(
                            "onUploadStarted:" +
                                uploadInfo.file.name +
                                ", endpoint:" +
                                uploadInfo.endpoint +
                                ", bucket:" +
                                uploadInfo.bucket +
                                ", object:" +
                                uploadInfo.object
                            );
                        }).catch((e)=>{
                            console.error('[video_upload_token_refresh] FAILED', e);
                            setUploadingInProgress(false);
                            setUploadingDesc('Upload failed ');
                            setCheckVideoFailed(true);
                            setCheckVideoFailedMsg(t('Please upload again'));
                            setCheckVideoFailedCode(e);
                        });
                }
            },
    
            // 文件上传成功
            onUploadSucceed: function(uploadInfo) {
        
                setLastUploadedVideoId(uploadInfo.videoId);
                setUploadingDesc(`Uploaded`);
                setPendingUploadFile(null);
                setUploadingInProgress(false);
                setCheckVideoFailed(false);
                console.log(
                "onUploadSucceed: " +  uploadInfo.file.name + ", endpoint:" +  uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object
                );
        
                //self.statusText = "文件上传成功!";
            },
        
            // 文件上传失败
            onUploadFailed: function(uploadInfo, code, message) {
                setUploadingInProgress(false);
                setCheckVideoFailed(true);
                console.log(
                "onUploadFailed: file:" +
                    uploadInfo.file.name +
                    ",code:" +
                    code +
                    ", message:" +
                    message
                );
                //self.statusText = "文件上传失败!";
            },
    
            // 取消文件上传
            onUploadCanceled: function(uploadInfo, code, message) {
                setUploadingInProgress(false);
                console.log(
                "Canceled file: " +
                    uploadInfo.file.name +
                    ", code: " +
                    code +
                    ", message:" +
                    message
                );
                //self.statusText = "文件已暂停上传";
            },
    
            // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
            onUploadProgress: function(uploadInfo, totalSize, progress) {
                let progressPercent = Math.ceil(progress * 100);
                setUploadingProgress(progressPercent);
                setUploadingDesc(`Uploading... ${progressPercent}%`);
                //self.statusText = "文件上传中...";
            },
    
            // 上传凭证超时
            onUploadTokenExpired: function(uploadInfo) {
                // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
                // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
                // 然后调用 resumeUploadWithAuth 方法
                //self.statusText = "文件超时...";
        
                video_upload_token_refresh(
                    {videoId : uploadInfo.videoId}).then((authObject)=>{
                        let {uploadAuth} = authObject;
                        uploader.resumeUploadWithAuth(uploadAuth);
                    }).catch((e)=>{
                        console.error('[video_upload_token_refresh] FAILED', e);
                        setUploadingInProgress(false);
                        setUploadingDesc('Upload failed ');
                        setCheckVideoFailed(true);
                        setCheckVideoFailedMsg(t('Please upload again'));
                        setCheckVideoFailedCode(e);
                    });
            },
            // 全部文件上传结束
            onUploadEnd: function(uploadInfo) {
                console.log("onUploadEnd: uploaded all the files");
                //self.statusText = "文件上传完毕";
            },
        });
        return uploader;
    }

    const chooseFile = () => {
        if (fileSelectorRef.current) {
            fileSelectorRef.current.click();
        }
    }

    const uploadFile = () =>{
        if (pendingUploadFile){
            let vodUploader = createVideoUploader();
            if (vodUploader){
                setLastUploadedVideoId('');
                vodUploader.addFile(pendingUploadFile, null, null, null,'{"Vod":{}}');
                vodUploader.startUpload();
                vodUploaderRef.current = vodUploader;
            }
            else{
                vodUploaderRef.current = null;
            }
        }
    }

    useEffect(() =>{
        if (pendingUploadFile){
            uploadFile();
            setUploadingProgress(0);
            setCheckVideoFailed(false);
            setCheckVideoFailedMsg('');
            setCheckVideoFailedCode(0);
            setCheckVideoSuccess(false);
        }
    }, [pendingUploadFile])

    const checkVideo = () =>{
        video_check({
            videoId: lastUploadedVideoId
        }).then(res => {
            if (res?.coverUrl && res?.duration > 0 && res?.videoId && res?.cardKindMaxNum > 0){
                setTotalClipIndex(res?.cardKindMaxNum);
                setCoverUrl(res?.coverUrl);
                setCheckVideoSuccess(true);
                setVideoChecking(false);
                setCheckVideoTimes(0);
            }
            else{
                setTimeout(() => {
                    setCheckVideoTimes(checkVideoTimes + 1);
                }, 1000);
            }
        }).catch(e => {
            console.log(e);
            setCheckVideoFailed(true);
            if (e === 1203 || e === 1204){
                setCheckVideoFailedMsg(t('Video duration must be between 3 - 60 seconds'));
            }
            else{
                setCheckVideoFailedMsg(filterErrorCode(e));
            }
            setCheckVideoFailedCode(e);
            setVideoChecking(false);
        })
    }

    useEffect(() => {
        if (checkVideoTimes > 0){
            if (!uploadingInProgress && lastUploadedVideoId){
                checkVideo();
            }
            else{
                setVideoChecking(false);
                setCheckVideoTimes(0);
            }
        }
    }, [checkVideoTimes]);

    useEffect(() => {
        if (!uploadingInProgress && lastUploadedVideoId){
            setVideoChecking(true);
            setCheckVideoTimes(checkVideoTimes + 1);
        }
    }, [uploadingInProgress, lastUploadedVideoId]);

    useEffect(() => {
        let info = getChainInfo(selCoinId);
        if (info && !(marketInfo?.[info.coinName] > 0)){
            getQuotes([info.coinName]).then(res => {
                let newMarketInfo = {...marketInfo};
                newMarketInfo[info.coinName] = res?.quotes?.[info.coinName];
                setMarketInfo(newMarketInfo);
            }).catch(e => {
                console.log(e);
            })
        }
    }, [selCoinId])

    const getWalletBalance = ({ walletAddr, selCoinId }) => {
        let tokenList = [selCoinId];
        getTokenBalanceOf(walletAddr, tokenList).then(res => {
            let tempBalance = walletBalanceList || [];
            for (let i = 0; i < tokenList.length; i++) {
                tempBalance[tokenList[i]] = res?.balances?.[tokenList[i]];
            }
            setWalletBalanceList(tempBalance);
        }).catch(err => {
            console.error(err.message);
        })
    }

    const refreshWalletBalance = ()=>{
        getWalletBalance({walletAddr, selCoinId})
    }
    // const [momentEntropyFee,setMomentEntropyFee] = useState('')
    // const getMomentEntropyFeeFn = (wallets,coinId)=>{
    //     getMomentEntropyFee(wallets,coinId).then(res=>{
    //         console.log(`getMomentEntropyFee---->`,ethers.utils.formatUnits(res));
    //         setMomentEntropyFee(ethers.utils.formatUnits(res))
    //     }).catch(e=>{
    //         console.error(e);
    //     })
    // } 
    
    useEffect(() => {
        if (!walletBalanceList?.[selCoinId]){
            getWalletBalance({ walletAddr, selCoinId });
            
        }
        // getMomentEntropyFeeFn(wallets,selCoinId)
    }, [selCoinId])

    const createFn = () =>{
        setCreateLoading(true);
        setCreateError(false);
        setCreateErrorMsg('');
        creatClub({
            coinId: selCoinId,
            symbolName: clubTicker,
            symbolImageId: symbolImageId,
            description: clubDesc,
            videoId: lastUploadedVideoId,
            curveType: curveType,
            paramB: 0,
            canTGE: canTGE === 1,
            // isFT: currencyType === 1,
            withRarity: true,
            anonymous: isAnony,
            preBuyCardNum: preBuyAmount,
            twitterLink: clubXlink,
            tgLink: clubTlink,
        }).then(res => {
            const {
                orderId,
                coinId,
                clubName,
                symbolName,
                cardKindMaxNum,
                curveType,
                paramA,
                paramB,
                tokenUrl,
                canTGE,
                cardsTotalSupply,
                liquidity,
                createFee,
                timestamp,
                validFor,
                preBuyCardNum,
                signature,
                mintFtTotalSupply
            } = res;

            creatMomentClub({
                wallets,
                coinId,
                callId: orderId,
                initBuyAmount_: preBuyAmount,
                value: Number(preBuyTotalValue) + Number(createFee),
                creationFee_: ethers.utils.parseEther(createFee),
                momentConf_: {
                    liquidityAmount: ethers.utils.parseEther(liquidity + ''),
                    seriesTotal: cardKindMaxNum,
                    // isFT,
                    mintTotal: ethers.utils.parseEther(mintFtTotalSupply + ''),
                    name: clubName,
                    symbol: symbolName,
                    baseURI: tokenUrl,
                    // baseUnit: ethers.utils.parseEther(coinToNFTRatio + ''),
                    priceType: curveType,
                    priceArg1: ethers.utils.parseEther(paramA + ''),
                    priceArg2: ethers.utils.parseEther(paramB + ''),
                },
                timestamp,
                validFor,
                signature
            }).then(resq => {
                setCreateLoading(false);
                setCreateDone(true);
                if(preBuyAmount>0){
                    setBuyTx(resq?.hash)
                    setBuyCheckTimes(buyCheckTimes + 1);
                    setShowPackTransResult(true)
                }
                console.log(resq); 
            }).catch(err => {
                setCreateError(true);
                setCreateLoading(false);
                setCreateErrorMsg(err);
                console.error(err);
                if (err === 'need login'){
                    setNeedLogin(true);
                }
            })
        }).catch(e => {
            setCreateError(true);
            setCreateErrorMsg(filterErrorCode(e));
            setCreateLoading(false);
            console.log(e);
        })
    }

    useEffect(() => {
        randomSymbolImageId();
    }, [MomentImageTemplate])

    useEffect(() =>{
        chooseFile();
    }, [])

    const getUploadingProgress = () =>{
        if (videoChecking){
            //return numberClamp(90 + checkVideoTimes, 90, 99);
            return 99;
        }
        else if (uploadingProgress >= 100){
            return 99;
        }
        else{
            return Math.floor(uploadingProgress * 0.99);
        }
    }

    const [showPackTransResult, setShowPackTransResult] = useState(false);
    const [momentBuyResult, setMomentBuyResult] = useState(null);
    const [buyTx,setBuyTx] = useState('')
    const [buyCheckTimes, setBuyCheckTimes] = useState(0);

    const checkOrderResult = (tx) =>{
        //TODO orderId???
        moment_buyresult(chainCoinInfo?.chainId,tx).then(res => {
            if (!res?.cardList?.length > 0) {
                setTimeout(() => {
                    setBuyCheckTimes(buyCheckTimes + 1);
                }, 2000);
            } else {
                console.log("moment_buyresult success", res?.cardList);
                setMomentBuyResult(res);
               
            }
        }).catch(e => {
            console.error(e);
            setTimeout(() => {
                setBuyCheckTimes(buyCheckTimes + 1);
            }, 2000);
        })
    }

    useEffect(() => {
        console.log(buyTx);
        if (buyCheckTimes > 0 && buyTx){
            checkOrderResult(buyTx)
        }
    }, [buyCheckTimes,buyTx]);

    const [openCanTGE,setOpenCanTGE] = useState(false)
    const [canTGE,setCanTGE] = useState(1)

    const onChangeCanTGE = (value)=>{
        setCanTGE(value)
    }

    const airDropItem = [
        {
            value: 1,
            label: t('Airdrop when all moments minted'),
        },{
            value: 2,
            label: t('No airdrop'),
        }
    ]
    
    
    return (
        <div className={`${s.wrap} navBg flex_center_start_col`}>
            <MainHeader title={t('Create Collection')} headerType={3} isGameHeader={true} hasRightMenu={false} rightMenuIcon ={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={`${s.content} flex_center_start_col`}>
                <div className={`${s.videoRoot} flex_center_start_col`}>
                {
                    <div>
                        <input
                            ref={fileSelectorRef}
                            type="file"
                            accept={`video/*`}
                            name="file"
                            id="files"
                            style={{ display: "none" }}
                            onChange={(e) => {
                                
                            for (let i = 0; i < e.target.files.length; i++) {
                                let pendingFile = e.target.files[0];
                                if (pendingFile && pendingFile.size <= 60 *1024*1024){
                                    setPendingUploadFile(e.target.files[0]);
                                }
                                else{
                                    setCheckVideoFailed(true);
                                    setCheckVideoFailedMsg(filterErrorCode(1201));
                                    setCheckVideoFailedCode(1201);
                                }
                                break;
                            }
                            }}
                        />
                    </div>
                }
                </div>
                <div className={`${s.coverRoot} flex_center_start_col`}>
                    {
                        checkVideoFailed ? 
                            <>
                                <div className={`${s.uploadingFailed}`} onClick={() =>{
                                    //chooseFile();
                                }}>
                                    <div className={`${s.uploadingFailedBg}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_Empty_Bg_01.png)`}}>
                                    </div>
                                    <div className={`${s.uploadingFailedContent} flex_center_center_col`}>
                                        <CustomIcon className="" width={72} height={72}
                                            imgName={'Picture/UI_Picture_Icon_Close_02'}/>
                                        <div className={`fs18 mt20 textnowrap fw-700 momentFont`}>
                                            {t('Upload failed')}
                                        </div>
                                        {
                                            checkVideoFailedMsg &&
                                            <div className={`fs12 mt10 momentFont`}>
                                                {checkVideoFailedMsg}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={`flex_center_center`}>
                                    <div className={`${s.reUploadBtn} flex_center_center`} onClick={() =>{
                                        chooseFile();
                                    }}>
                                        <CustomIcon className="mr8" width={16} height={16}
                                            imgName={'Button/UI_Button_Upload_01'}/>
                                        <div className={`fs12 fb momentFont`}>
                                            {t('Re-select video')}
                                        </div>
                                    </div>
                                    {
                                        (checkVideoFailedCode !== 1201 && checkVideoFailedCode !== 1203 && checkVideoFailedCode !== 1204) &&
                                        <div className={`${s.reUploadBtn} flex_center_center`} onClick={() =>{
                                            setCheckVideoFailed(false);
                                            uploadFile();
                                        }}>
                                            <CustomIcon className="mr8" width={16} height={16}
                                                imgName={'Button/UI_Button_reload_01'}/>
                                            <div className={`fs12 fb momentFont`}>
                                                {t('Retry')}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </> :
                        checkVideoSuccess ? 
                            <>
                                <div className={`${s.coverImage}`} style={{backgroundImage: `url(${coverUrl})`}} onClick={() =>{
                                    //chooseFile();
                                }}>
                                </div>
                                <div className={`${s.reUploadBtn} flex_center_center`} onClick={() =>{
                                    chooseFile();
                                }}>
                                    <CustomIcon className="mr8" width={16} height={16}
                                        imgName={'Button/UI_Button_Upload_01'}/>
                                    <div className={`fs12 fb momentFont`}>
                                        {t('Re-select video')}
                                    </div>
                                </div>
                            </> : 
                        pendingUploadFile || videoChecking ?
                            <div className={`${s.uploadingVideo}`}>
                                <div className={`${s.uploadingVideoBg}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_Empty_Bg_01.png)`}}>
                                </div>
                                <div className={`${s.uploadingContent} flex_center_center_col`}>
                                    <CustomIcon rotating={true} className="" width={62} height={62}
                                        imgName={'Picture/UI_Picture_Loading_02'}/>
                                    <div className={`fs18 mt20 textnowrap momentFont fw-400 color-white`}>
                                        {t('Video Uploading')}
                                    </div>
                                    <div className={`fs18 mt10 textnowrap momentFont fw-400 color-white`}>
                                        {`${getUploadingProgress()}%`}
                                    </div>
                                </div>
                            </div> :
                            <div className={`${s.coverEmpty}`} onClick={() => {
                                chooseFile();
                            }}>
                                <div className={`${s.coverEmptyCenter} flex_center_start_col`}>
                                    <CustomIcon imgName={`Button/UI_Button_Explore_Create_01`} className={`${s.icon}`} width={60} height={60}/>
                                    <div className={`fs18 mt20 textnowrap momentFont fw-400 color-moment-light-gray `}>
                                        {t('Upload Video')}
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                <div className={`${s.wrap} flex_center_start_col`}>
                    <div className={s.formWrap}>
                        <div className="flex_center_center w100p">
                            <div className={`${s.group}`} style={{
                                width:"100%"
                            }}>
                                <div className={s.label}>
                                    <div className={`fs14 momentFont fw-400 ${s.ticker}`}>
                                        {t('Ticker')}
                                        <div className={`${s.necessary}`}>
                                            {`*`}
                                        </div>
                                    </div>
                                </div>
                                <Input placeholder={t('Meme Coin')}
                                    maxLength={tickerMaxLength}
                                    className={`${s.input} ${clubTicker ? '' : s.null}`}
                                    value={clubTicker}
                                    onBlur={() => {
                                        const _ = clubTicker.replace(/[^a-zA-Z\s]/g, '').trim().substr(0, tickerMaxLength)
                                        setClubTicker(_)
                                    }}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        const newValue = val.replace(/[^a-zA-Z\s]/g, '');
                                        setClubTicker(newValue)
                                    }}></Input>
                                <div className={`${s.line}`}>
                                </div>
                                <div className={`${s.tickerLimit} fs12 color-moment-light-gray momentFont fw-400`}>{clubTicker?.length}/{tickerMaxLength}</div>
                            </div>
                        </div>
                        <div className={`${s.group}`}>
                            <div className={`${s.label} mt20`}>
                                <div className="fs14 momentFont fw-400">{t('Description')}</div>
                                <div className="fs12 color-moment-light-gray momentFont fw-400">{clubDesc?.length}/{1000}</div>
                            </div>
                            <TextArea maxLength={1000} className={`${s.input} ${s.inputArea}`} rows={4} placeholder={t('Enter the description of your Momentclub')} value={clubDesc} onChange={(e) => {
                                setClubDesc(e.target.value)
                            }}></TextArea>
                        </div>
                        <div className={`${s.moreBtn} flex_center_start`} onClick={() =>{
                            setShowMore(!showMore);
                        }}>
                            <div className={`${s.moreBtnText} fs14 momentFont fw-400`}>
                                {
                                    showMore ? 
                                    t('Hide More Options') :
                                    t('Show More Options')
                                }
                            </div>
                            <CustomIcon imgName={`Button/${showMore ? 'UI_Button_Arrow_Up_01':'UI_Button_Arrow_Down_01'}`} className={`${s.moreIcon}`} width={12} height={7}/>
                        </div>
                        {
                            showMore &&
                            <>
                                <div className={s.selChain}>
                                    <div className={s.group}>
                                        <div className={`${s.label} momentFont`}>
                                            {t('Chain and Currency')}
                                        </div>
                                        <Select
                                            value={selCoinId}
                                            onChange={onChangeCoinId}
                                            popupClassName="liveSettingOption"
                                            className='memeClubSel momentSelect'
                                            options={coinList}
                                            open={openCoin}
                                            onClick={() => {
                                                setOpenCoin(!openCoin)
                                            }}
                                            suffixIcon={
                                                <div className="memeClubSelSuffixIcon">
                                                    <CustomIcon imgName={'Button/UI_Button_Triangle-White_Down_01'} width={10} height={7}/>
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                                {/* <div className={s.group}>
                                    <div className={s.label}>
                                        {t('Type')}
                                    </div>
                                    <div className={`${s.chooseType} flex_center_start`}>
                                        <div className={`${s.checkboxItem} flex_center_start`} style={{marginRight: '50px'}} onClick={() => {
                                            setCurrencyType(0);
                                        }}>
                                            <div className={`${currencyType === 0 ? s.checked:s.unchecked}`}>
                                            </div>
                                            <div className={`${s.checkLabel}`}>
                                                {t('DNA(ERC-7631)')}
                                            </div>
                                        </div>
                                        <div className={`${s.checkboxItem} flex_center_start`} onClick={() =>{
                                            setCurrencyType(1);
                                        }}>
                                            <div className={`${currencyType === 1 ? s.checked:s.unchecked}`}>
                                            </div>
                                            <div className={`${s.checkLabel}`}>
                                                {t('Normal(ERC-20)')}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                    <div className={s.group}>
                                        <div className={`${s.label} momentFont`}>
                                            {t('Airdrop')}
                                        </div>
                                        <Select
                                            value={canTGE}
                                            onChange={onChangeCanTGE}
                                            popupClassName="liveSettingOption"
                                            className='memeClubSel momentSelect'
                                            options={airDropItem}
                                            open={openCanTGE}
                                            onClick={() => {
                                                chainCoinInfo?.allowTge && setOpenCanTGE(!openCanTGE)
                                            }}
                                            suffixIcon={
                                                <div className="memeClubSelSuffixIcon">
                                                    <CustomIcon imgName={'Button/UI_Button_Triangle-White_Down_01'} width={10} height={7}/>
                                                </div>
                                            }
                                        />
                                    </div>
                                <div className={s.group}>
                                    <div className={`${s.label} mt20`}>
                                        <div className="fs14 momentFont fw-400">{t('X(Twitter) Link')}</div>
                                        <div className="fs12 color-moment-light-gray momentFont fw-400">{clubXlink?.length}/{64}</div>
                                    </div>
                                    <Input placeholder={t('X(Twitter) Link placeholder')}
                                        // addonBefore={websiteAfterAddon?.x}
                                        maxLength={64}
                                        className={`${s.input} commonBeforeInput`}
                                        value={clubXlink}
                                        onChange={(e) => {
                                            // const val = e.target.value
                                            // const newValue = val.replace(/[^a-zA-Z0-9_\s]/g, '');
                                            setClubXlink(e.target.value)
                                        }}></Input>
                                    <div className={`${s.line}`}>
                                    </div>
                                </div>
                                <div className={s.group}>
                                    <div className={s.label}>
                                        <div className="fs14 momentFont fw-400">{t('Telegram Link')}</div>
                                        <div className="fs12 color-moment-light-gray momentFont fw-400">{clubTlink?.length}/{64}</div>
                                    </div>
                                    <Input placeholder={t('Telegram Link placeholder')}
                                        // addonBefore={websiteAfterAddon?.t}
                                        maxLength={64}
                                        className={`${s.input} commonBeforeInput`}
                                        value={clubTlink}
                                        onChange={(e) => {
                                            // const val = e.target.value
                                            // const newValue = val.replace(/[^a-zA-Z0-9_\s]/g, '');
                                            setClubTlink(e.target.value)
                                        }}></Input>
                                    <div className={`${s.line}`}>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className={`${s.bottomPart} flex_center_center`}>
                {
                    /*(!createDone && clubTicker && checkVideoSuccess) &&
                    <div className={`${s.previewBtn} flex_center_center_col`} onClick={() =>{
                        setShowMemeCreatePreview(true);
                    }}>
                        <CustomIcon imgName={'Picture/UI_Picture_Eye_01'} width={30} height={30}/>
                        <div className={`${s.previewBtnText}`}>
                            {t('Preview')}
                        </div>
                    </div>*/
                }
                {
                    createDone ? 
                        <AntButton
                            onClick={() => {
                                navigate(gameMomentClubExplorePath);
                            }}
                            className={`${s.commonBtn} btn_public`}>
                            <div className="fs18 momentFont color-black">
                            {t('Creation Success')}
                            </div>
                        </AntButton> :
                    createLoading ?
                        <AntButton
                            className={`${s.commonBtn} btn_public`}>
                            <div className={`flex_center_center`}>
                                <CustomIcon rotating={true} className="mr5" width={18} height={18}
                                    imgName={'Picture/UI_Picture_Loading_01'}/>
                                <div className="fs18 fb momentFont color-black">
                                    {t('Create Moment')}
                                </div>
                            </div>
                        </AntButton> :
                    createError ?
                        <AntButton
                            onClick={() => {
                                if (needLogin){
                                    logoutSelf();
                                }
                                else{
                                    setCreateError(false);
                                    setCreateErrorMsg('');
                                }
                            }}
                            className={`${s.commonBtn} btn_public`}>
                            <div className="fs16 fb momentFont color-black">
                                {createErrorMsg}
                            </div>
                        </AntButton> :
                    clubTicker && checkVideoSuccess ?
                        <AntButton
                            onClick={() => {
                                preCreateFn();
                            }}
                            className={`${s.commonBtn} btn_public`}>
                            <div className="fs18 momentFont color-black">
                                {t('Create Moment')}
                            </div>
                        </AntButton> :
                        <AntButton
                            className={`${s.commonBtn} btn_disabled`}>
                            <div className="fs18 momentFont color-999">
                                {t('Create Moment')}
                            </div>
                        </AntButton>
                }
            </div>
            {
                showMemeCreatePreview &&
                <MemeCreatePreview
                    clubTicker={clubTicker}
                    clubDesc={clubDesc}
                    symbolImageId={symbolImageId}
                    coinId={selCoinId}
                    totalClipIndex={totalClipIndex}
                    marketInfo={marketInfo}
                    coverUrl={coverUrl}
                    paramA={paramA}
                    paramN={paramN}
                    startPrice={startPrice}
                    closeFn={() =>{
                        setShowMemeCreatePreview(false);
                    }}
                    createFn={() =>{
                        setShowMemeCreatePreview(false);
                        preCreateFn();
                    }}
                ></MemeCreatePreview>
            }
            <AntModal
                width='330px'
                title=""
                className="momentModalWrap"
                centered={true}
                open={showPreBuyModal}
                destroyOnClose={true}
                onOk={() => setShowPreBuyModal(false)}
                onCancel={() => setShowPreBuyModal(false)}
            >
                <ModalMomentCreatePreBuy
                    max={totalClipIndex}
                    walletBalanceList={walletBalanceList}
                    preBuyAmount={preBuyAmount}
                    setPreBuyAmount={setPreBuyAmount}
                    preBuyTotalValue={preBuyTotalValue}
                    selCreatChain={chainCoinInfo}
                    isAnony={isAnony}
                    setIsAnony={setIsAnony}
                    coinId={selCoinId}
                    userInfo={userInfo}
                    refreshWalletBalance={refreshWalletBalance}
                    creatFn={() => {
                        setShowPreBuyModal(false);
                        createFn()
                    }}
                    closeFn={() => {
                        setShowPreBuyModal(false)
                    }}
                />
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={showPackTransResult}
                onOk={() => setShowPackTransResult(false)}
                onCancel={() => {
                    setShowPackTransResult(false)
                    setMomentBuyResult(null)
                }}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalPackTransResult
                    transResult={momentBuyResult}
                    clubBasicInfo={{}}
                    closeFn={() => {
                        setShowPackTransResult(false)
                        setMomentBuyResult(null)
                    }}
                />
            </AntModal>
        </div>

    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        walletBalanceList: app.walletBalanceList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        }
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MemeCreate));
