import React, { memo, useEffect, useMemo, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import { publicChatsPath } from "@/routes/config";
import { sliceStr } from "@/utils/common";
import AvatarInfo from "@/common/AvatarInfo";
import { getfarmTopicTweetlist } from "@/utils/xFarm";
import List from "./List";
import AntModal from "@/common/AntModal";
import ModelUpMultiplyCardList from "@/model/ModelUpMultiplyCardList";
const Detail = (
    {
        userInfo,
        info,
        setShowDetail
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {
        displayName, 
        avatarUrl,
        accountName,
        isUser,
        rankIdx,
        userId = 0,
        twitterUid
    } = useMemo(()=>{
        return info || {}
    },[info])
    const {multiplyCardNum} = useMemo(()=>{
        return userInfo?.selfData || {}
    },[userInfo])

    const [modelUpMultiplyCardList,setModelUpMultiplyCardList] = useState(false)

    const [list,setList] = useState([])
    useEffect(()=>{
        getfarmTopicTweetlist(twitterUid).then(res=>{
            setList(res?.tweetList)
        }).catch(e=>{
            console.error(e);
        })
    },[])

    
    return (
        <div className={`${s.wrap} commonBlueBg`}>
            <MainHeader title={`${t('Top Mentioned-')}${rankIdx}`} onBack={()=>{setShowDetail(false)}} hasRightMenu={false} hasBackBtn={true}></MainHeader>
            <div className={s.content}>
                <div className={s.cHead}>
                <div className={s.htop}>
                        <div className={s.right}>
                            <div className={s.r1}>
                                <div className={s.user}>
                                    <AvatarInfo size={70} src={avatarUrl}/>
                                </div>
                            </div>
                            <div className={s.r2}>
                                <div className={s.r2l}>
                                    <div className={`${s.fl} df alc`}>
                                        <span className="fs18 mr5 ">{sliceStr(displayName, 14, true)}</span>
                                            <CustomIcon width={16} height={16}
                                                        imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                       />
                                       
                                    </div>
                                    <div className={s.rr1}>
                                        <div className="color-blue-light">{accountName}</div>
                                    </div>

                                </div>
                                <div className={s.r2r}>
                                    <div className={s.rr2}>
                                        <ButtonFactory
                                            size={32}
                                            scale={1.2}
                                            onClick={() => {
                                                navigate(`${publicChatsPath}/${userId}`)   
                                            }}>
                                            {t('TRADE')}
                                        </ButtonFactory>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.listWrap}>
                    <List list={list} />
                </div>
                
            </div>

            <div className={s.bottom}>
                

                    {rankIdx <= 10 && <div className={s.booster}>
                        <div className={s.boosterL}>
                            <CustomIcon className={s.icon} width={40} height={40} imgName={"Picture/UI_Picture_Title-Blue_Rocket_01"} />
                            <div className={`color-yellow ${s.multiplyCardNum} fs16`}>X {multiplyCardNum || 0}</div>
                        </div>
                        <div className="f1 tll ml10">{t('Holding Cards of Top 10 mentioned user given Booosters everyday!')}</div>
                        <div className="ml10" onClick={() => setModelUpMultiplyCardList(true)}><CustomIcon width={25} height={25} imgName={"Button/UI_Button_YellowRound-CopyNumberWordName_01"} /></div>
                    </div>}

            </div>
            <AntModal
                width='330px'
                title={t('Booster Acquisition Log')}
                className="confirmModalWrap"
                centered={true}
                open={modelUpMultiplyCardList}
                destroyOnClose={true}
                onOk={() => setModelUpMultiplyCardList(false)}
                onCancel={() => setModelUpMultiplyCardList(false)}
            >
                <ModelUpMultiplyCardList />
            </AntModal>
        </div >

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Detail));
