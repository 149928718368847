import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import ImgCrop from 'antd-img-crop';
import { Input, Radio, Space, Select, Upload, Modal } from "antd";
import AntModal from '@/common/AntModal';
import {
    memeImageTemplate,
    preferencesItems,
    uploadImage,
    websiteAfterAddon
} from "@/utils/memeClub";
import AvatarInfo from "@/common/AvatarInfo";
import useLocalStorageState from "@/common/useLocalStorageState";
const New = (
    {
        userInfo,
        clubName,
        nextFn,
        setClubName,
        clubTicker,
        setClubTicker,
        clubDesc,
        setClubDesc,
        // chainType,
        // setChainType,
        // coinType,
        // setCoinType,
        memeChainToken,
        coinData,
        memeClubSymbolUrl,
        setMemeClubSymbolUrl,
        chainData,
        coinList,
        selCoinId,
        setSelCoinId,
        selPreferencesType,
        setSelPreferencesType,
        enumsLoading,
        clubTlink,
        setClubTlink,
        clubXlink,
        setClubXlink,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { TextArea } = Input;
    const onChangeCoinId = (value) => {
        setSelCoinId(value);
    };
    const [imgCoin, setImgCoin] = useState([]);
    const onChangePreferences = (value) => {
        setSelPreferencesType(value);
    };

    const randomImgUrl = () => {
        const img = memeImageTemplate[Math.floor(Math.random() * memeImageTemplate?.length)]?.symbolImageUrl
        console.log(img);
        setMemeClubSymbolUrl(img)
    }

    // const [defaultImg,setDefaultImg] = useState('')




    const isAvabled = useMemo(() => {
        return clubName && clubTicker
    }, [clubName, clubTicker])

    const [openPreferences, setOpenPreferences] = useState(false)
    const [openCoin, setOpenCoin] = useState(false)

    useEffect(() => {
        !memeClubSymbolUrl && randomImgUrl()
    }, [])


    const getBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => callback(reader.result);
        reader.onerror = (error) => console.error('Error: ', error);
    };
    const [isGif, setIsGif] = useState(false);

    const beforeUpload = (file) => {
        // Check file type and size here if necessary
        const isGifFile = file.type === 'image/gif';
        setIsGif(isGifFile);
        return new Promise((resolve) => {
            getBase64(file, (base64) => {
                const { type } = file
                uploadImage({
                    imageType: type,
                    imageContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '').replace('data:image/gif;base64,', '')
                }).then(res => {
                    setMemeClubSymbolUrl(res?.url);
                }).catch(e => {
                    console.error(e);
                })
            });
        });
    };


    return (
        <div className={`${s.modalWrap}`}>
            <MainHeader title={t('Creat Club')} headerType={2} hasRightMenu={false} hasBackBtn={true}></MainHeader>
            {/* <div className={s.banner}>
                <div className={s.text}>{t('CREATE YOUR OWN MEMECLUB WITH CUSTOM COINS & NFTS!')}</div>
            </div> */}

            <div className={s.modal}>

                <div className={s.content}>

                    {/* <div className={`${s.partTitle} mt15`}>
                        <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                            imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                        {AutoStrangeRule(t('CREATE MEMECLUB'))}
                    </div> */}

                    <div className={s.wrap}>
                        <div className={s.formWrap}>
                            <div className="flex_center_center w100p">
                                <div className={s.group}>
                                    <div className={s.label}>
                                        <div className="fs14">{t('Name')}</div>
                                        <div className="fs12 color-blue-light fw-100"><span className="fs12 fw-100">{clubName?.length}</span>/{16}</div>
                                    </div>
                                    <Input placeholder={t('MemeClub Name Doc')}
                                        maxLength={16}
                                        className={`${s.input} ${clubName ? '' : s.null}`}
                                        value={clubName}
                                        // onBlur={() => {
                                        //     setClubTicker(clubTicker.trim())
                                        // }}
                                        onChange={(e) => {
                                            const val = e.target.value
                                            const newValue = val.replace(/[^a-zA-Z0-9\s]/g, '');
                                            setClubName(newValue)
                                        }}></Input>

                                </div>
                                <div className={`${s.group}`} style={{
                                    width:"60%",
                                    marginLeft:'5px'
                                }}>
                                    <div className={s.label}>
                                        <div className="fs14">{t('Ticker1')}</div>
                                        <div className="fs12 color-blue-light fw-100"><span className="fs12 fw-100">{clubTicker?.length}</span>/{5}</div>
                                    </div>
                                    <Input placeholder={t('Ticker Doc')}
                                        maxLength={5}
                                        className={`${s.input} ${clubTicker ? '' : s.null}`}
                                        value={clubTicker}
                                        onBlur={() => {
                                            const _ = clubTicker.replace(/[^a-zA-Z\s]/g, '').trim().substr(0, 5)
                                            setClubTicker(_.toLocaleUpperCase())
                                        }}
                                        onChange={(e) => {
                                            const val = e.target.value
                                            const newValue = val.replace(/[^a-zA-Z\s]/g, '');
                                            setClubTicker(newValue.toLocaleUpperCase())
                                        }}></Input>
                                </div>
                            </div>

                            <div className={s.group}>
                                <div className={s.label}>
                                    {t('Symblo')}
                                </div>
                                <div className={s.symblo}>
                                    <AvatarInfo size={67} className="roundAvatar mr5" src={memeClubSymbolUrl}></AvatarInfo>
                                    {/* <CustomIcon imgName={'Picture/UI_Picture_CardPacks-P_01'} className="mr5" width={67} height={67}></CustomIcon> */}
                                    <div className={s.rightBtn}>
                                        <ButtonFactory
                                            className={'mb5'}
                                            isMirror={true}
                                            size={23} //1:L, 2:M, 3:S, 11:L1, 21:M1, 22:M2, 23:M3, 31:S1, 32:S2, 33:S3
                                            onClick={randomImgUrl}
                                            color={3}
                                        >
                                            {t('Random')}
                                        </ButtonFactory>
                                        <ButtonFactory
                                            size={23} //1:L, 2:M, 3:S, 11:L1, 21:M1, 22:M2, 23:M3, 31:S1, 32:S2, 33:S3
                                            color={3}
                                            scale={0.95}
                                        >

                                            <ImgCrop width={200} height={200} modalTitle={t('Edit image')} rotationSlider modalClassName="imgCropModalWrap">
                                                <Upload
                                                    showUploadList={false}
                                                    accept="image/png, image/jpeg"
                                                    listType="picture-card"
                                                    fileList={imgCoin}
                                                    beforeUpload={beforeUpload}
                                                >
                                                    <span className="color-black fs16">{t('+ Upload')}</span>
                                                </Upload>
                                            </ImgCrop>

                                        </ButtonFactory>
                                    </div>

                                </div>
                            </div>

                            <div className={s.group}>
                                <div className={s.label}>
                                    <div className="fs14">{t('Description')}</div>
                                    <div className="fs12 color-blue-light fw-100"><span className="fs12 fw-100">{clubDesc?.length}</span>/{1000}</div>
                                </div>
                                <TextArea maxLength={1000} className={s.input} rows={4} placeholder={t('Enter the description of your Memeclub')} value={clubDesc} onChange={(e) => {
                                    setClubDesc(e.target.value)
                                }}></TextArea>
                            </div>
                            <div className={s.group}>
                                <div className={s.label}>
                                    <div className="fs14">{t('X(Twitter) Link')}</div>
                                    <div className="fs12 color-blue-light fw-100"><span className="fs12 fw-100">{clubXlink?.length}</span>/{64}</div>
                                </div>
                                <Input placeholder={t('X(Twitter) Link')}
                                    // addonBefore={websiteAfterAddon?.x}
                                    maxLength={64}
                                    className={`${s.input} commonBeforeInput`}
                                    value={clubXlink}
                                    onChange={(e) => {
                                        // const val = e.target.value
                                        // const newValue = val.replace(/[^a-zA-Z0-9_\s]/g, '');
                                        setClubXlink(e.target.value)
                                    }}></Input>

                            </div>
                            <div className={s.group}>
                                <div className={s.label}>
                                    <div className="fs14">{t('Telegram Link')}</div>
                                    <div className="fs12 color-blue-light fw-100"><span className="fs12 fw-100">{clubTlink?.length}</span>/{64}</div>
                                </div>
                                <Input placeholder={t('Telegram Link')}
                                    // addonBefore={websiteAfterAddon?.t}
                                    maxLength={64}
                                    className={`${s.input} commonBeforeInput`}
                                    value={clubTlink}
                                    onChange={(e) => {
                                        // const val = e.target.value
                                        // const newValue = val.replace(/[^a-zA-Z0-9_\s]/g, '');
                                        setClubTlink(e.target.value)
                                    }}></Input>

                            </div>
                            <div className={s.selChain}>

                                <div className={s.group}>
                                    <div className={s.label}>
                                        {t('Chain and Currency')}
                                    </div>
                                    <Select
                                        value={selCoinId}
                                        onChange={onChangeCoinId}
                                        popupClassName="liveSettingOption"
                                        className='memeClubSel'
                                        options={coinList}
                                        open={openCoin}
                                        onClick={() => {
                                            setOpenCoin(!openCoin)
                                        }}
                                        suffixIcon={
                                            <div className="memeClubSelSuffixIcon">
                                                <CustomIcon imgName={'Button/UI_Button_Triangle-White_Down_01'} width={10} height={7}></CustomIcon>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className={s.group}>
                                    <div className={s.label}>
                                        {t('Preferences')}
                                    </div>
                                    <Select
                                        value={selPreferencesType}
                                        onChange={onChangePreferences}
                                        popupClassName="liveSettingOption"
                                        className='memeClubSel'
                                        options={preferencesItems}
                                        open={openPreferences}
                                        onClick={() => {
                                            setOpenPreferences(!openPreferences)
                                        }}
                                        suffixIcon={
                                            <div className="memeClubSelSuffixIcon">
                                                <CustomIcon imgName={'Button/UI_Button_Triangle-White_Down_01'} width={10} height={7}></CustomIcon>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <ButtonFactory
                                size={21}
                                scale={1.4}
                                disabled={!isAvabled || enumsLoading}
                                loading={enumsLoading}
                                onClick={nextFn}
                                className="mt20"
                            >
                                <div className="fs22 color-black">{t('Next')}</div>
                            </ButtonFactory>
                        </div>

                    </div>


                </div>

            </div>
            {/* <AntModal
                width='330px'
                title={t('Upload')}
                className="confirmModalWrap"
                open={previewVisible}
                onCancel={handleCancel}
                onOk={handleCancel}
            >
                <img alt="example" style={{ width: '100%' }} src={memeClubSymbolUrl} />
            </AntModal> */}
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(New));
