import React, {memo, useState, useMemo} from "react";
import s from "./index.module.less";
import {Input, Button, Modal} from "antd";
import {unit, siteName, getmatic_market, stringToNumber, chainName} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import WithdrawDialog from "../WithdrawDialog";
import {sitchI18n, languageEnum} from '@/i18n';
import WorkResultConfig from "@/utils/json/WorkResultConfig.json";
import {supportedLng} from "@/utils/env";
import ButtonFactory from "src/common/ButtonFactory";
import { useTranslation } from "react-i18next";
import {AutoStrangeRule} from "@/utils/strangeRule";

const ModelSwitchLanguage = (
    {
        setShowSwitchLanguage,
        type = 1, //1:common, 2:moment
    }
) => {
    const { t, i18n } = useTranslation();
    const switchLanguage = (language) => {
        sitchI18n(language);
    };

    const isLngSupport = (lng) => {
        let res = false;
        if (supportedLng && supportedLng.length > 0) {
            for (let i = 0; i < supportedLng.length; i++) {
                if (lng === supportedLng[i]) {
                    res = true;
                    break;
                }
            }
        }
        return res;
    }

    return (
        <div className={s.modalWrap}>
            {
                type === 2 &&
                <div className={s.title}>
                    {AutoStrangeRule(AutoStrangeRule(t('LANGUAGE')))}
                </div>
            }
            {
                isLngSupport("en") && 
                <>
                    {
                        type === 2?
                        <div className={`${s.momentBtn}`} onClick={() => {
                            switchLanguage(languageEnum.EN);
                            setShowSwitchLanguage(false);
                        }}>
                            {AutoStrangeRule(t('ENGLISH'))}
                        </div> :
                        <ButtonFactory
                            onClick={() => {
                                switchLanguage(languageEnum.EN);
                                setShowSwitchLanguage(false);
                            }}
                        >
                            {AutoStrangeRule(t('ENGLISH'))}
                        </ButtonFactory>
                    }
                </>
            }
            {
                isLngSupport("zh") && 
                <>
                    {
                        type === 2?
                        <div className={`${s.momentBtn}`} onClick={() => {
                            switchLanguage(languageEnum.ZHTW);
                            setShowSwitchLanguage(false);
                        }}>
                            中文（繁體）
                        </div> :
                        <ButtonFactory
                            isMirror={true}
                            onClick={() => {
                                switchLanguage(languageEnum.ZHTW);
                                setShowSwitchLanguage(false);
                            }}
                        >
                            中文（繁體）
                        </ButtonFactory>
                    }
                </>
            }
            {
                isLngSupport("pt") && 
                <>
                    {
                        type === 2?
                        <div className={`${s.momentBtn}`} onClick={() => {
                            switchLanguage(languageEnum.PT);
                            setShowSwitchLanguage(false);
                        }}>
                            português
                        </div> :
                        <ButtonFactory
                            onClick={() => {
                                switchLanguage(languageEnum.PT);
                                setShowSwitchLanguage(false);
                            }}
                        >
                            português
                        </ButtonFactory>
                    }
                </>
            }
            {
                isLngSupport("ja") && 
                <>
                    {
                        type === 2?
                        <div className={`${s.momentBtn}`} onClick={() => {
                            switchLanguage(languageEnum.JA);
                            setShowSwitchLanguage(false);
                        }}>
                            日本語
                        </div> :
                        <ButtonFactory
                            isMirror={true}
                            onClick={() => {
                                switchLanguage(languageEnum.JA);
                                setShowSwitchLanguage(false);
                            }}
                        >
                            日本語
                        </ButtonFactory>
                    }
                </>
            }
            {
                type === 2 &&
                <div style={{height: '38px'}}>
                </div>
            }
            {
                type === 2 &&
                <CustomIcon 
                    className={`momentCloseBtn`} 
                    width={40} 
                    height={40} 
                    imgName={`Picture/UI_Picture_Icon_Close_02`}
                    onClick={() => {
                        setShowSwitchLanguage(false);
                    }} 
                />
            }
        </div>
    )
}
export default memo(ModelSwitchLanguage);
