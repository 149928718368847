import React, {memo, useState} from "react";
import {Button} from "antd";
import s from "./index.module.less";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {isOnlineEnv} from "@/utils/env";
import {logoutSelf, openMoonPay} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../common/CustomIcon";
import {uniswapLFG, uniswapQuoterLFG} from "@/utils/uniswapWeb3";
import ButtonFactory from "src/common/ButtonFactory";
import {mainHomePath} from "@/routes/config";
import AntButton from "@/common/AntButton";
import {connect} from "react-redux";

const ModelSettingsMenu = (
    {
        unit,
        setShowDepositPanel,
        setShowWithdrawPanel,
        setShowSwitchLanguage,
        setShowSwapLfg,
        setShowAccountLinking,
        isGameHeader,
        navigate,
        setShowGasMode,
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const {exportWallet, logout, sendTransaction} = usePrivy();
    const [testUpdateCount, setTestUpdateCount] = useState(0);
    const link_tw = () => {
        window.location.href = "https://twitter.com/turnupdotxyz"
    }
    const link_t = () => {
        window.location.href = "https://t.me/justTURNUP"
    }

    const link_wp = () => {
        window.location.href = "https://doc.turnup.so/"
    }

    return (
        <div className={s.ModelSettingsMenu} style={{textAlign: "center"}}>
            
                <AntButton className="progressier-subscribe-button noticeBtn" data-icons="false"
                           data-eligible={t('Notifications: ON/OFF')} data-subscribed={t('Notifications: ON/OFF')}
                           data-blocked={t('Notifications: ON/OFF')}></AntButton>
                <AntButton
                    className="btn_white mt10"
                    style={{width: "250px"}}
                    onClick={() => {
                        setShowDepositPanel(true);
                    }}
                >
                    {t('DEPOSIT xx', {unit: 'CRYPTO'})}
                </AntButton>
                {/*<AntButton
                    className="btn_white mt10"
                    style={{width: "250px"}}
                    onClick={() => setShowWithdrawPanel(true)}
                >
                    {t('WITHDRAW xx', {unit: unit})}
                </AntButton>*/}
                <AntButton
                    className="btn_white mt10"
                    style={{width: "250px"}}
                    onClick={() => setShowSwapLfg(true)}
                >
                    {t('MATIC-LFG SWAP')}
                </AntButton>
                {/* <AntButton
                    className="btn_white mt10"
                    style={{width: "250px"}}
                    onClick={()=> setShowAccountLinking(true)}
                >
                    {t('ACCOUNT')}
                </AntButton> */}
                <AntButton
                    className="btn_white mt10"
                    style={{width: "250px"}}
                    onClick={exportWallet}
                >
                    {t('EXPORT WALLET')}
                </AntButton>
                {/* <AntButton
                className={`${s.btn} btn_public`}
                shape="round"
                onClick={}
            >
                PRIVACY POLICY
            </AntButton> */}
                <AntButton
                    className="btn_white mt10"
                    style={{width: "250px"}}
                    onClick={() => {
                        setShowGasMode(true);
                    }}
                >
                    {t('GAS SETTINGS')}
                </AntButton>
                
                <AntButton
                    className="btn_white mt10"
                    style={{width: "250px"}}
                    onClick={() => setShowSwitchLanguage(true)}
                >
                    {t('SWITCH LANGUAGE')}
                </AntButton>
                {
                    isGameHeader && 
                    <AntButton
                        className="btn_white mt10"
                        style={{width: "250px"}}
                        onClick={() => navigate(mainHomePath, {replace: true})}
                    >
                        {t('BACK TO HOME')}
                    </AntButton>
                }
                {
                    !isOnlineEnv() && 
                    <AntButton
                        className="btn_white mt10"
                        style={{width: "250px"}}
                        onClick={() => {
                            window.isMomentsLink = !window.isMomentsLink;
                            setTestUpdateCount(testUpdateCount + 1);
                        }}
                    >
                        {`moment <=> turnup 切换(测试用)`}
                    </AntButton>
                }
                

                {/*{!isOnlineEnv()*/}
                {/*    && <div><br/>*/}
                {/*        <div style={{"textAlign": "center"}}>The following buttons only show on Test Env</div>*/}
                {/*        <br/><br/>*/}
                {/*        <div className={`${s.withdrawFooter}`}>*/}
                {/*            <AntButton className={`btn_public ${s.boostBtn}`} shape="round" onClick={() => {*/}
                {/*                openMoonPay(wallets)*/}
                {/*            }}>*/}
                {/*                wallet.fund(MoonPay) Test*/}
                {/*            </AntButton>*/}
                {/*            <br/><br/>*/}
                {/*            <AntButton className={`btn_public ${s.boostBtn}`} shape="round" onClick={async () => {*/}
                {/*                const amount = 0.1;*/}
                {/*                try {*/}
                {/*                    let lfgAmount = await uniswapQuoterLFG({wallets, amount});*/}
                {/*                    console.log('[Quoter Result]', lfgAmount);*/}
                {/*                    uniswapLFG({wallets, amount, lfgAmount, sendTransaction});*/}
                {/*                } catch (e) {*/}
                {/*                    console.error(e);*/}
                {/*                }*/}
                {/*            }}>*/}
                {/*                uniswap Test 0.1 MATIC buy LFG*/}
                {/*            </AntButton>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

                <div className={`${s.officiallink}`}>
                    <div className={`${s.linktitle}`}>{t('Official Link')}</div>
                    <div className={`${s.linkmain}`}>
                        <div className={`${s.link}`} onClick={link_tw}>
                            <div className={`${s.linkicon}`}>
                                <CustomIcon width={40} height={40}
                                            imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                            name={'Twitter'}/>
                            </div>
                            <span>{t('Twitter')}</span>
                        </div>
                        <div className={`${s.link}`} onClick={link_t}>
                            <div className={`${s.linkicon}`}>
                                <CustomIcon width={40} height={40} imgName={'Button/UI_Button_Ball-White_TurnUp'}
                                            name={'Telegram'}/>
                            </div>
                            <span>{t('Telegram')}</span>
                        </div>
                        {/* <div className={`${s.link}`} onClick={link_wp}>
                            <div className={`${s.linkicon}`}>
                                <CustomIcon width={28} height={28} imgName={'Button/UI_Button_Paper_02'}
                                            name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            </div>
                            <span>{t('White Paper')}</span>
                        </div> */}
                    </div>
                </div>
            


            <AntButton
                className={`${s.btn} btn_transparent df aic jcc`}
                onClick={() => {
                    // if (isGameHeader) {
                    //     navigate(mainHomePath, {replace: true})
                    // } else {
                        logout();
                        logoutSelf();
                    // }

                }}
            >
                <span className={"fs20"}
                      style={{fontWeight: 'bold'}}>{t('LOG OUT')}</span>
            </AntButton>
        </div>
    );
};

const mapStateToProps = ({app}) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelSettingsMenu));
