import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import AntButton from "@/common/AntButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import {
    stringToNumber,
    convertWalletBalance,
    getChainTokenInfoById,
    getTokenBalanceOf,
    GetSupportedChainList,
    getQuotes,
    numberClamp,
    chgFn,
    chainOpenseaUrl,
} from "@/utils/common";
import {
    gameMemeClubDetailPath,
    gameMomentClubDetailPath,
} from "@/routes/config";
import MemeClubPack from "@/common/MemeClubPack";
import { getChainInfo } from "@/utils/momentClub";

const List = (
    {
        list,
        momentClubShowType,
        nextCursor,
        getMoreFn,
        setBackRoute = () => {},
        linkToMoment = () => {},

        userInfo,
        memeDntBalanceList,
        setCurrentMomentClubId,
        setSwitchVideoType,
        setMemeclubSwitchVideoParams,
        setSwitchVideoListCursor,
        setSwitchVideoList,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {userId} = userInfo;

    const totalWidth = useMemo(() => {
        return Math.min(window.innerWidth, 500);
    }, [window.innerWidth]);

    const [leftColumn, setLeftColumn] = useState([]);
    const [leftColumnHeight, setLeftColumnHeight] = useState(0);
    const [rightColumn, setRightColumn] = useState([]);
    const [rightColumnHeight, setRightColumnHeight] = useState(0);
    const marginBottom = 6;
    const oneLineHeight = 48;
    const twoLineHeight = 65;

    const calColumHeightFn = (videoHeight, videoWidth) =>  {
        const maxHeight = 280;
        const minHeight = 134;
        const columWidth = (numberClamp(window.innerWidth, 1, 500) - 34) / 2;
        
        let columHeight = (columWidth / videoWidth) * videoHeight;
        if (columHeight < minHeight) {
          columHeight = minHeight;
        } else if (columHeight > maxHeight) {
          columHeight = maxHeight;
        }
      
        return columHeight;
    }

    const updateShowList = () => {
        let tempLeftList = [];
        let tempLeftHeight = 0;
        let tempRightList = [];
        let tempRightHeight = 0;
        let clubList = list;
        for (let i = 0; i < clubList?.length; i++) {
            let tempClub = clubList[i];
            let bottomHeight = oneLineHeight;
            let videoHeight = tempClub?.videoOriHeight || 1;
            let videoWidth = tempClub?.videoOriWidth || 1;
            let bgHeight = calColumHeightFn(videoHeight,videoWidth);
            if (tempClub?.profile?.displayName?.length + tempClub?.des?.length > 25) {
                bottomHeight = twoLineHeight;
            }
            if (tempLeftHeight - tempRightHeight > 0) {
                tempRightList.push(tempClub);
                tempRightHeight += bgHeight + bottomHeight + marginBottom;
            }
            else {
                tempLeftList.push(tempClub);
                tempLeftHeight += bgHeight + bottomHeight + marginBottom;
            }
        }
        setLeftColumn(tempLeftList);
        setLeftColumnHeight(tempLeftHeight);
        setRightColumn(tempRightList);
        setRightColumnHeight(tempRightHeight);
    }

    useEffect(() => {
        updateShowList();
    }, [list])

    const getColumnItem = (item, index) => {
        const {
            clubUserId,
            clubProfile,
            clubSymbolName,
            isFavorite,
            isLike,
            videoId,
            description,
            isTGE,
            extInfo,
            tgeExtInfo,
            totalWorth,
            likeNum,
            videoFirstFrameUrl,
            videoOriHeight,
            videoOriWidth,
            videoUrlMap,
        } = item || {};
        const {
            accType,
            displayName,
            avatarUrl,
            coinId,
            momentAuditCode,
        } = clubProfile || {};
        const {
            buyPrice,
            sellPrice,
            historyPrice,
        } = extInfo || {};
        const range = historyPrice ? Math.round((sellPrice - historyPrice) / historyPrice * 100) : 0;
        const _itemCoinObj = getChainInfo(coinId);
        
        let coverUrl = '/UI/Picture/UI_Picture_Empty_Bg_01.png';
        if (avatarUrl && (momentAuditCode === 1 || momentAuditCode === 2)) {
            coverUrl = avatarUrl;
        }

        return (
            <div
                className={`${s.item} flex_center_start_col`}
                key={`${userId}_${index}`}
                style={{
                    width: `${totalWidth * 0.445}px`,
                    marginBottom: `${marginBottom}px`
                }}
                onClick={() => {
                    setBackRoute();
                    linkToMoment(item, momentClubShowType);
                }}
            >
                <div className={`${s.imageRoot}`}>
                    <img src={coverUrl} alt="cover" className={s.imageBg} />
                    {
                        momentAuditCode === 0 &&
                        <div className={`${s.progressingPart} flex_center_center_col`}>
                            <CustomIcon className="" width={62} height={62}
                                imgName={'Picture/UI_Picture_Loading_02'} />
                            <div className={`momentFont fs18 fb mt20 textnowrap`}>
                                {t('Video Processing')}
                            </div>
                        </div>
                    }
                    {
                        momentAuditCode === 3 &&
                        <div className={`${s.blockedPart} flex_center_center_col`}>
                            <CustomIcon className="" width={50} height={50}
                                imgName={'Picture/UI_Picture_Icon_Lock_01'} />
                            <div className={`momentFont fs18 fb mt20 textnowrap`}>
                                {t('Video Blocked')}
                            </div>
                        </div>
                    }
                    <div className={`${s.range} flex_center_between`}>
                        <div className={s.chainInfo}>
                            <CustomIcon imgName={`Picture/${_itemCoinObj?.chainicon}`} className="mr5" width={16} height={16}></CustomIcon>
                            <div className="momentFont fs12">{_itemCoinObj?.chainShowName}</div>
                        </div>
                        <div className={`flex_center_start mr10`}>
                            <CustomIcon imgName={isLike ? 'Picture/UI_Picture_MemeDetails_Like_02' : `Picture/UI_Picture_MemeDetails_Like_01`} className="mr5" width={12} height={12}></CustomIcon>
                            <div className={`fs12 color-moment-gray momentFont`}>
                                {likeNum}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${s.bottomRoot} flex_center_start_col `}>
                    <div className={`${s.bottomText}`}>
                        <span className="fs12 color-moment-EEEEEE momentFont">
                            ${displayName || ''}
                        </span>:&nbsp;
                        <span className="fs12 color-moment-light-gray momentFont">
                            {description || ''}
                        </span>
                    </div>
                    <div className={`${s.stateRoot} flex_center_between`}>
                        <div className={`flex_center_end`}>
                            <div className={`flex_center_end fs12 fb color-moment-light-gray momentFont`}>
                                <CustomIcon imgName={`Picture/${_itemCoinObj?.icon}`} className="mr5" width={16} height={16}></CustomIcon>
                                {convertWalletBalance(Number(sellPrice))}
                            </div>
                        </div>
                        <div className="flex_center_center">
                            <CustomIcon imgName={range < 0 ? 'Picture/UI_Picture_PriceDown_01' : 'Picture/UI_Picture_PriceUP_01'} className="mr5" width={18} height={13}></CustomIcon>
                            <span className={range < 0 ? 'color-red-moment fs12' : 'color-green-moment fs12'}>{range}%</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`${s.memeClubListPart} flex_center_start_col`}>
            <div className={`${s.memeClubListBg} flex_center_start_col`} style={{maxHeight: `${window.innerHeight - 405}px`}}>
                <div className={s.list} style={{ width: '100%' }} id="clubList">
                    {
                        list?.length > 0 ?
                            <InfiniteScroll
                                dataLength={list?.length || 0}
                                next={()=>{
                                    getMoreFn();
                                }}
                                hasMore={nextCursor !== -1}
                                loader={<div
                                    style={{
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                ><Spin /></div>}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "0",
                                    width: "100%"
                                }}
                                endMessage={
                                    <div style={{
                                        textAlign: "center",
                                        width: "100%",
                                        color: "var(--color-gray)",
                                        fontSize: '12px'
                                    }}></div>
                                }
                                scrollableTarget={'clubList'}
                            >
                                {
                                    <div className="flex_start_between w100p mb20">
                                        <div className={`${s.listColumn} flex_center_start_col`} id="listColum">
                                            {
                                                leftColumn?.length > 0 &&
                                                leftColumn?.map((item, index) => {
                                                    return (getColumnItem(item, index));
                                                })
                                            }
                                        </div>
                                        <div className={`${s.listColumn} flex_center_start_col`}>
                                            {
                                                rightColumn?.length > 0 &&
                                                rightColumn?.map((item, index) => {
                                                    return (getColumnItem(item, index));
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </InfiniteScroll> :
                            <div className={`${s.assetListNoData} flex_center_center`}>
                                <CustomIcon width={111} height={95} className={`mb30`} imgName={'Picture/UI_Picture_NoData_Empty_02'}></CustomIcon>
                                <div className="mt20 fs18 momentFont fw-400" style={{color: `#555555`}}>
                                    {t('No Data')}
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo:app.userInfo,
        memeDntBalanceList: app.memeDntBalanceList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
        setSwitchVideoType: (params) => {
            return dispatch({
                type: "app/setSwitchVideoType",
                payload: params,
            });
        },
        setMemeclubSwitchVideoParams: (params) => {
            return dispatch({
                type: "app/setMemeclubSwitchVideoParams",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(List));
