import s from './index.module.less';
import {memo} from 'react';
import {Button} from "antd";
import AntButton from "@/common/AntButton";


const VoteBtn = (
    {
        btnType = 0, //0 普通模式显示文字  1 显示结果左边答案 右边人数并显示进度条 默认0
        persent, //回答的百分比
        answerStr, //答案描述
        num, //回答人数
        voteType = 1, //voteType 1表示完整宽度型 2表示小型 默认1
        answerReslut, //答案结果
        disabled = false, //禁用状态 默认false
        selected = false, //选中状态 默认false
        loading = false, //加载状态 默认false
        onClick = () => {
        },
        onDisabledClick = () => {
        },
    }
) => {
    return (
        btnType === 0 ?
            <AntButton
                className={`${s.btn} ${selected ? s.selected : ""} ${voteType === 1 ? '' : s.small}`}
                shape="round"
                disabledd={disabled}
                loading={loading}
                onClick={() => {
                    disabled ? onDisabledClick() : onClick()
                }}
            >
                {answerStr}
            </AntButton>
            :
            <div className={`${s.btn} ${s.result} ${selected ? s.selAnswer : ""} ${voteType === 1 ? '' : s.small}`}>
                <div className={`${s.bg} ${answerReslut ? s.right : s.wrong}`} style={{width: persent}}></div>
                <div>{answerStr}</div>
                <div>{num}</div>
            </div>
    );
};

export default memo(VoteBtn);
