import React, { memo, useEffect, useMemo, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonFactory from "@/common/ButtonFactory";
import { linkPath } from "@/routes/config";
import Countdown from "@/common/Countdown";
import { farmSelfTweetlist,getTopicRanklist,task,topic } from "@/utils/xFarm";
import { convertWalletBalance, getAvatarFromProfile,stringToNumber } from "@/utils/common";
import AntModal from "@/common/AntModal";
import ModelUpFerver from "@/model/ModelUpFerver";
import AvatarInfo from "@/common/AvatarInfo";
import ModelUpFarm from "@/model/ModelUpFarm";
import ModelUpFarmTweetShare from "@/model/ModelUpFarmTweetShare";
import ModelUpXFarm from "@/model/ModelUpXFarm";

const FeverFarm = (
    {
        userInfo,
        setPath,
        wheelInfo
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [activeInd, setActiveInd] = useState(0)
    const [leftSecond,setLeftSecond] = useState(1245);
    const [list,setList] = useState([])
    const [emptyList,setEmptyList] = useState([{},{},{}])
    const [mentionList, setMentionList] = useState([])
    const [showModel ,setShowModel] = useState(false)
    const [openSharePanel,setOpenSharePanel] = useState(false)
    const [taskInfo,setTaskInfo] = useState({})
    const getList = ()=>{
        farmSelfTweetlist().then(res=>{
            setList(res?.tweetList)
        }).catch(e=>{
            console.error(e);
        })
    }
    const getUserInfo = (userInfo, twitterUserInfo, isUser) => {
        let avatarUrl, displayName, accountName, twitterUid, noTargetInfo;
        if (isUser) {
            avatarUrl = getAvatarFromProfile(userInfo?.profile)
            displayName = userInfo?.profile?.displayName
            accountName = userInfo?.profile?.accountName
            twitterUid = userInfo?.twitterUid
        } else {
            avatarUrl = twitterUserInfo?.avatarUrl
            displayName = twitterUserInfo?.displayName
            accountName = twitterUserInfo?.accountName
            twitterUid = twitterUserInfo?.twitterUid
        }
        return {
            displayName,
            avatarUrl,
            accountName,
            twitterUid
        }
    }

    useEffect(()=>{
        getTopicRanklist().then(res=>{
            setMentionList(res?.rankList || [])
        }).catch(e=>{
            console.error(e);
        })
        getList()
        task().then(res=>{
            setTaskInfo(res)
        }).catch(e=>{
            console.error(e);
        })
    },[])


    const [showModelFarm,setShowModelFarm] = useState(false)

    return (
        <div className={`${s.wrap} commonBlueBg`}>
            <div className={s.content}>
                <div className={s.bt}>
                    <div className={s.btl}>
                        <div className={`${s.partTitle} fb`}>
                            <CustomIcon width={17} height={39} className=""
                                        imgName={'Picture/UI_Picture_Title-Yellow_01'}
                                        />
                            {t('Daily Task ')}
                        </div>
                    </div>
                    <div className={s.btr}>
                    <div>{t('Reset in')} {wheelInfo?.leftSecond ? <span className={s.leftTime}><Countdown leftSecond={wheelInfo?.leftSecond} refresh={()=>{
                        setLeftSecond(0)
                    }}></Countdown></span> : t('Ended')} </div>
                    </div>
                </div>
                <div className={s.task}>
                    <div className={s.list}>
                        {/* <a className={s.item} href="https://twitter.com/turnupdotxyz" target="_blank">
                            <div className={s.txt}>
                                <Trans
                                    i18nKey='Like a tweet posted by {{topic}}'
                                    defaults='Like a tweet posted by <yellow>{{topic}}</yellow>'
                                    values={{topic: topic}}
                                    components={{yellow: <span className="fb color-yellow"></span>}}
                                />
                            </div>
                            <div className={s.r}>
                                {taskInfo?.likeCount>0 && <div className="df alc jcc mb10">
                                    <CustomIcon width={20} height={20} className="mr5"
                                        imgName={'Picture/UI_Picture_GreenRound_Hook_01'}
                                        />
                                    <div className="color-yellow"> X {taskInfo?.likeCount}</div> 
                                </div>}
                                <div className="df alc jcc">
                                    <CustomIcon width={20} height={20} className="mr5"
                                        imgName={'Picture/UI_Picture-Currency_UP_01'}
                                        />
                                    <div className="color-yellow">{stringToNumber((taskInfo?.likeCount || 1) * taskInfo?.likePerScore,2)}</div> 
                                </div>
                            </div>
                        </a> */}
                        
                        <a className={s.item} href="https://twitter.com/turnupdotxyz" target="_blank">
                            <div className={s.txt}>
                                <Trans
                                    i18nKey='Retweet a tweet posted by {{topic}}'
                                    defaults='Retweet a tweet posted by <yellow>{{topic}}</yellow>'
                                    values={{topic: topic}}
                                    components={{yellow: <span className="fb color-yellow"></span>}}
                                />
                            </div>
                            <div className={s.r}>
                                {taskInfo?.retweetCount>0 && <div className="df alc jcc mb10">
                                    <CustomIcon width={20} height={20} className="mr5"
                                        imgName={'Picture/UI_Picture_GreenRound_Hook_01'}
                                        />
                                    <div className="color-yellow"> X {taskInfo?.retweetCount}</div> 
                                </div>}
                                <div className="df alc jcc">
                                    <CustomIcon width={20} height={20} className="mr5"
                                        imgName={'Picture/UI_Picture-Currency_UP_01'}
                                        />
                                    <div className="color-yellow">{stringToNumber((taskInfo?.retweetCount || 1) * taskInfo?.retweetPerScore,2)}</div> 
                                </div>
                            </div>
                        </a>
                        {/* <div className={s.item}>
                            <div className={s.txt}>
                                <Trans
                                    i18nKey='Reply to a tweet posted by {{topic}}'
                                    defaults='Reply to a tweet posted by <yellow>{{topic}}</yellow>'
                                    values={{topic: '@GoTurnup'}}
                                    components={{yellow: <span className="fb color-yellow"></span>}}
                                />
                            </div>
                            <div className={s.r}>
                            {taskInfo?.replyCount>0 && <div className="df alc jcc mb10">
                                    <CustomIcon width={20} height={20} className="mr5"
                                        imgName={'Picture/UI_Picture_GreenRound_Hook_01'}
                                        />
                                    <div className="color-yellow"> X {taskInfo?.replyCount}</div> 
                                </div>}
                                <div className="df alc jcc">
                                    <CustomIcon width={20} height={20} className="mr5"
                                        imgName={'Picture/UI_Picture-Currency_UP_01'}
                                        />
                                    <div className="color-yellow">{stringToNumber((taskInfo?.replyCount || 1) * taskInfo?.replyPerScore,2)}</div> 
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={s.bt}>
                    <div className={s.btl}>
                        <div className={`${s.partTitle} fb`}>
                            <CustomIcon width={17} height={39} className=""
                                        imgName={'Picture/UI_Picture_Title-Yellow_01'}
                                        />
                            {t('My X Farm')}
                            <CustomIcon width={24} height={24} className="ml5" onClick={()=>(setShowModelFarm(true))}
                                        imgName={'Button/UI_Button_QuestionMark_02'}
                                        />
                        </div>
                    </div>
                    <div className={s.btr}>

                    </div>
                </div>
                <div className={s.xList}>
                    <div className={`${s.head}`}>
                        <div className={s.ha}>{t('@ Mentioned')}</div>
                        <div className={s.hl}>{t('like/view')}</div>
                        <div className={s.hm}>
                            {t('Fever')}
                            <CustomIcon width={14} onClick={()=>{setShowModel(true)}} className="ml5" height={14} imgName={"Button/UI_Button_QuestionMark_03"} />
                        </div>
                        <div className={s.hr}>{t('IYKYK Rank')} </div>
                    </div>
                    <div className={`${s.list}`}>
                    {
                        emptyList?.map((item,index) => {
                            if(!list?.[index]){
                                return (
                                    <div className={`${s.item} ${s.empty}`} key={index} >
                                        {t('No Tweet yet')}
                                    </div>
                                )
                            }
                            const { fevelVal, topicRankIdx,topicUserInfo,likes,views,topicTwitterUserInfo,topicAccIsUser,showRatioStr,tweetRankIdx } = list[index] || {};
                            const { displayName, avatarUrl,accountName,twitterUid } = getUserInfo(topicUserInfo, topicTwitterUserInfo, topicAccIsUser)
                            return (
                                <div className={s.item} key={index}>
                                    <div className={s.info}>
                                        {twitterUid ? 
                                            <>
                                                <AvatarInfo size={32} src={avatarUrl}></AvatarInfo>
                                                <div className={s.text}>{accountName}</div>
                                                <div className={s.kolRank}>{t('{{rank}}th in all KoL',{rank:topicRankIdx})}</div>
                                            </>:
                                            '--'
                                        }
                                        
                                    </div>
                                    <div className={s.rankIdx}>
                                        {likes}/{views}
                                    </div>
                                    
                                    <div className={s.fire}>
                                        <CustomIcon width={20} height={20} imgName={"Picture/UI_Picture-Currency_UP_01"} />
                                        <div className={s.fr}>
                                            <div>{fevelVal}</div>
                                            <div className="fs12 color-yellow">{showRatioStr}</div>
                                        </div>
                                    </div>
                                    <div className={s.btn}>
                                        {tweetRankIdx? tweetRankIdx + t('th') : '--'}
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>

            <div className={s.bottom}>
                <ButtonFactory
                    size={12}
                    onClick={() => {
                        
                        setOpenSharePanel(true)
                    }}
                >
                    <>
                        <CustomIcon width={20} className="mr5" height={20} imgName={'Button/UI_Button_Share_02'}></CustomIcon>
                        <div className="fs25 color-black">{t('Tweet')}</div>
                    </>
                </ButtonFactory>
                <div className="fs12 mt15">{t('Only the latest 3 posts will be calculated ')}</div>
            </div>
            <AntModal
                width='330px'
                title={t('My Engagement Score')}
                className="confirmModalWrap"
                centered={true}
                open={showModel}
                destroyOnClose={true}
                onOk={() => setShowModel(false)}
                onCancel={() => setShowModel(false)}
            >
                <ModelUpXFarm />
            </AntModal>
            <AntModal
                width='330px'
                title={t('X Farm Detail')}
                className="confirmModalWrap"
                centered={true}
                open={showModelFarm}
                destroyOnClose={true}
                onOk={() => setShowModelFarm(false)}
                onCancel={() => setShowModelFarm(false)}
            >
                <ModelUpFarm />
            </AntModal>
            <AntModal
                width='330px'
                title={t('Tweet Now')}
                className="confirmModalWrap"
                centered={true}
                open={openSharePanel}
                destroyOnClose={true}
                onOk={() => setOpenSharePanel(false)}
                onCancel={() => setOpenSharePanel(false)}
            >
                <ModelUpFarmTweetShare mentionList={mentionList} />
            </AntModal>
        </div >

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(FeverFarm));
