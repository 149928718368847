import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Modal} from "antd";
import {vCoinString} from "@/utils/common";
import HireModelEarnIntro from "../../model/HireModelEarnIntro";
import {useTranslation, Trans} from 'react-i18next';
import RewardTypeShow from '@/utils/json/RewardTypeShow.json';
import WorkConfig from '@/utils/json/WorkConfig.json';
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";

const HireWorkResult = (
    {
        closeFn,
        info,
        selfUserId,

    }
) => {
    const {t, i18n} = useTranslation();
    const [bg, setBg] = useState('');
    const {
        id,
        lootCoin,
        resulteInfo,
        employeeAllKeyNum,
        holdingKeyNum,
        selfClaimCoin,
        employeeId,
        isWish,
        isNft
    } = useMemo(() => {
        return info || {}
    }, [info])
    console.log(info);
    const [showEarnIntro, setShowEarnIntro] = useState(false)
    useEffect(() => {
        if (resulteInfo?.resultImage) {
            const img = require(`@/assets/images/resultImg/${resulteInfo?.resultImage}.jpg`)
            setBg(img)
        }

    }, [resulteInfo])
    const wrokJsonData = WorkConfig.find(item => item?.ID === resulteInfo?.workId)
    return (
        <div className={s.wrap} style={{backgroundImage: `url(${bg})`}}>
            <div className={s.title}>{t(resulteInfo?.resultTitle)}</div>

            <div className={s.content}>
                {/* <div className={''}><CustomIcon width={100} height={120} imgName={'iconCrack'} name={'iconCrack'} /></div> */}
                {/* <div className={s.progress}>
          <div className={s.bg}>
            <div className={s.pg} style={{width:`${progress}%`}}></div>
          </div>
          <div className="fb fs18 color-white mt10">Cracking Progress: <span>{progress}</span>%</div>
        </div> */}

                <div className={s.info}>
                    <div className={s.result}>
                        {t(resulteInfo?.resultText)}
                    </div>
                </div>
                <div className={s.info}>
                    <div className={s.infoWrap}>
                        <div className={s.userItem}>
                            <div className={s.lable}>
                                {isNft ? t('Total NFTs Earnings') : t('Total Club Earnings')}
                            </div>
                            <div className={s.val}>
                                <CustomIcon width={20} className="mr5" height={20}
                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                            name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                <div className="color-white fs18 fb">{vCoinString(lootCoin)}</div>
                            </div>
                        </div>
                        <div className={s.userItem}>
                            <div className={s.lable}>
                                {t('My Earnings')}
                            </div>
                            <div className={s.val}>
                                <CustomIcon width={20} className="mr5" height={20}
                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                            name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                <div className="color-white fs18 fb">{vCoinString(selfClaimCoin)}</div>
                                {!isNft && <div className={s.help} onClick={() => setShowEarnIntro(true)}><CustomIcon
                                    width={18} height={18} className="ml5" imgName={'Button/UI_Button_QuestionMark_01'}
                                    name={'Button/UI_Button_QuestionMark_01'}/></div>}
                            </div>
                        </div>
                        {wrokJsonData?.ExtraRewardType > 0 &&
                            <div className={`${s.userItem}`}>
                                <div className={s.lable}>
                                    {t(`RewardTypeShow_${wrokJsonData?.ExtraRewardType}_rewardDec`)}
                                </div>
                                <div className={s.val}>
                                    <CustomIcon width={14} height={14} className="mr5"
                                                imgName={RewardTypeShow.find(item => item?.id === wrokJsonData?.ExtraRewardType)?.rewardIconPath}
                                                name={RewardTypeShow.find(item => item?.id === wrokJsonData?.ExtraRewardType)?.rewardIconPath}/>
                                    {vCoinString(wrokJsonData?.ExtraRewardNum)}

                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={s.btn}>
                    <AntButton className="btn_public" shape="round" onClick={closeFn}>{t('CLAIM')}</AntButton>
                </div>
                {/*<div className="mt10">{t('WorkResultAddTimes01',{time:5})}</div>*/}
            </div>
            <AntModal
                width='330px'
                title={t('EARNINGS DETAIL')}
                className="confirmModalWrap p0"
                centered={true}
                open={showEarnIntro}
                destroyOnClose={true}
                onOk={() => setShowEarnIntro(false)}
                onCancel={() => setShowEarnIntro(false)}
                zIndex={2000}
            >
                <HireModelEarnIntro
                    closeHandle={() => {
                        setShowEarnIntro(false)
                    }}
                    info={{
                        total: lootCoin,
                        myEarn: selfClaimCoin,
                        employeeAllKeyNum,
                        holdingKeyNum,
                        isSelfClub: employeeId === selfUserId,
                        isWish,
                        isLoot: true
                    }}
                />
            </AntModal>
        </div>
    )
}
export default memo(HireWorkResult);
