import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";

import { airdropTypeData, getChainInfo, getHolderList } from "@/utils/memeClub";
import { convertWalletBalance, displayFilter, getAvatarFromProfile } from "@/utils/common";
import AvatarInfo from "@/common/AvatarInfo";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
const Holders = (
    {
        clubBasicInfo,
        clubId,
        chainInfo
    }
) => {

    const {
        airdropType,
        cardsTotalSupply,
        ftTotalSupply,
        
    } = useMemo(()=>{
        return clubBasicInfo?.clubInfo || {}
    },[clubBasicInfo])
    const { t, i18n } = useTranslation();
    const [list, setList] = useState([]);
    const [nextCursor, setNextCursor] = useState(-1)
    const getList = (clubUserId, nextCursor = 0) => {
        getHolderList(clubUserId, nextCursor).then(res => {
            setList(res?.holderList || [])
            setNextCursor(res?.nextCursor)
        }).catch(e => {
            console.error(e);
        })
    }
    useEffect(() => {
        clubId && getList(clubId, 0)
    }, [clubId])

    const { icon } = useMemo(() => {
        return chainInfo || {}
    }, [chainInfo])

    const getMoreList = () => {
        getHolderList(clubId, nextCursor)
    }


    return (
        <div className={`${s.box}`}>
            <div className={s.list}>
                <InfiniteScroll
                    dataLength={list?.length || 0}
                    next={getMoreList}
                    hasMore={nextCursor !== -1}
                    loader={<div
                        style={{
                            textAlign: "center",
                            width: "100%",
                        }}
                    ><Spin /></div>}
                    height={"100%"}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0",
                        width: "100%"
                    }}

                >
                    {list && list.length > 0 ? (
                        list?.map((item,index) => {
                            const { userId, profile, holderNum } = item;
                            const { displayName, avatarUrl, coinId } = profile;
                            
                            const _base = (parseInt(ftTotalSupply / cardsTotalSupply * airdropTypeData[airdropType]?.min / 10000) + parseInt(ftTotalSupply / cardsTotalSupply * airdropTypeData[airdropType]?.max / 10000)) / 2
                            return (
                                <div className={s.item} key={userId+'_'+index}>
                                    <AvatarInfo className="roundAvatar" src={getAvatarFromProfile(profile)} size={55}></AvatarInfo>
                                    <div className={s.ir}>
                                        <div>{displayFilter(displayName)}</div>
                                        <div className={s.line}>
                                            <div className={s.label}>
                                                <div className="fs12">{t('Meme Packs Held')}</div>
                                            </div>
                                            <div className={s.val}>
                                                <CustomIcon width={16} height={16} className="ml10 mr10" imgName={'Picture/UI_Picture_Cardpack_01'} />
                                                {convertWalletBalance(holderNum)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="fs-12">{t('No Data')}</div>
                    )}
                </InfiniteScroll>
            </div>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Holders));
