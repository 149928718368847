import React, {memo, useCallback, useEffect, useMemo} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {ethers, BigNumber} from "ethers";
import {connect} from "react-redux";
import CustomIcon from "../../common/CustomIcon";
import {
    keyBuy,
    unit,
    stringToNumber,
    sliceStr,
    keySell,
    updateSelfUser,
    updateOtherUser,
    getPrice,
    TransactionError,
    toAsyncAwait,
    gettrade_price,
    keyWishClaimBuy, PrivyLoginStatusError, logoutSelf, displayFilter,
    getAvatarFromProfile
} from "@/utils/common";
import {useState} from "react";
import {url} from "@/utils/configUri";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {Button, Input} from 'antd';
import AvatarInfo from "../../common/AvatarInfo";
import http from "@/utils/axios";
import AntButton from "@/common/AntButton";

const ModelWishTrans = (
    {
        closeHandle,
        type,
        userInfo,
        // info,
        isBuyFristKey = false,
        keyId,
        keyNumNeed = 1,
        curentOtherUserInfo,
        profile,
        accountType = 'key'
    }
) => {
    const {t, i18n} = useTranslation();
    const transType = {
        1: {
            fn: keyWishClaimBuy,
            str: t('Buy')
        }
    }
    const [done, setDone] = useState(false);
    // const [dataInit,setDataInit] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const [subFailded, setSubFailded] = useState(false);
    const [noSuccessText, setNoSuccessText] = useState(transType[type]?.str + ' ' + t('Failed'));
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const {displayName, profileDeepLink} = profile || {};
    const [keyNum, setKeyNum] = useState(keyNumNeed);
    const [getPriceLoding, setGetPriceLoding] = useState(false);
    const [totalPrice, setTotalPrice] = useState(((keyNumNeed - 1) * keyNumNeed * (2 * (keyNumNeed - 1) + 1) / 6 / 100) * 1.1 + 0.1);
    const [info, setInfo] = useState({});
    const {fastGasPrice, keyAddr, maticUsd, contractAddr} = useMemo(() => {
        return info || {}
    }, [info]);

    const {keyPrice, keyPriceWithFee} = useMemo(() => {
        return {
            keyPrice: userInfo?.selfData?.wishClaimKeyTotalPrice,
            keyPriceWithFee: userInfo?.selfData?.wishClaimKeyTotalPriceWithFee,
        }
    }, [userInfo]);


    const {walletAddr, selfData} = userInfo;
    const {balance} = selfData;

    const {logout} = usePrivy();
    const closeFn = () => {
        if (noSuccessText === PrivyLoginStatusError) {
            logout();
            logoutSelf();
        }
        setSubLoading(false)
        setDone(false)
        closeHandle()
    }
    const dataInit = useMemo(() => {
        if (fastGasPrice && balance && walletAddr) {
            return true
        }
        return false
    }, [fastGasPrice, balance, walletAddr])

    const {wallets} = useWallets();
    const transFn = () => {

        if (!dataInit || subLoading) return
        setSubLoading(true);
        // console.log(wallets, keyAddr, keyNum, totalPrice.toString());
        transType[type]?.fn(wallets, keyId, keyAddr, keyNum, keyPriceWithFee).then(res => {
            setSubLoading(false);
            setDone(true);
            setSubFailded(false)
            setTimeout(
                () => {
                    updateSelfUser()
                    updateOtherUser(keyId)
                    getPrice(keyId);
                }, 2000
            )
        }).catch(e => {
            console.log(e);
            // if(e.code){
            //   setNoSuccessText(`failed error code ${e.code}`)
            // }
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }

            if (e === 'need login') {
                setNoSuccessText(PrivyLoginStatusError)
            }

            setSubLoading(false);
            setDone(true);
            setSubFailded(true)
            setTimeout(
                () => {
                    updateSelfUser()
                    updateOtherUser(keyId)
                    getPrice(keyId);
                }, 2000
            )
        })

    }

    const showMoreInfoFn = () => {
        setShowMoreInfo(!showMoreInfo)
    }

    const controlAmount = (control) => {
        if (control === 0 && keyNum > 1) {
            let _keyNum = keyNum - 1;
            if (type === 2) {
                _keyNum = Math.min(_keyNum, youOwnKeyNum)
            }
            setKeyNum(_keyNum)
        }
        if (control === 1) {
            // setKeyNum(keyNum => {
            let _keyNum = keyNum + 1;
            if (type === 2) {
                _keyNum = Math.min(_keyNum, youOwnKeyNum)
            }
            //   return _keyNum
            // })
            setKeyNum(_keyNum)
        }
    }

    const {youOwnKeyNum} = useMemo(() => {
        return curentOtherUserInfo || {}
    }, [curentOtherUserInfo])

    useEffect(() => {
        getKeyPrice()
    }, [keyNum])

    const isAvailable = useMemo(() => {
        return totalPrice <= Number(balance)
    }, [balance, totalPrice])

    const getKeyPrice = useCallback(() => {
        setGetPriceLoding(true)
        gettrade_price(type, keyId, keyNum).then(res => {
            setGetPriceLoding(false);
            // setTotalPrice(Number(res?.keyPriceWithFee) + Number(res?.fastGasPrice))
            // setTotalPrice(res?.keyPriceWithFee)
            // console.log(res);
            setInfo({...res})
        }).catch(e => {
            setGetPriceLoding(false)
            console.log(e);
        })
    }, [keyNum, type, keyId])

    const amountChangeHandler = (e) => {
        let value = e.target.value.replace(/[^\d]/g, '');
        console.log(value);
        setKeyNum(value);

    }
    const amountBlurHandler = (e) => {
        setKeyNum(Math.max(keyNum, 1))
        if (type === 2) {
            setKeyNum(Math.min(keyNum, youOwnKeyNum))
        }
    }

    const getEst = useMemo(() => {
        if (keyPriceWithFee) {
            const bN = ethers.utils.parseEther(keyPriceWithFee);
            const bN2 = ethers.utils.parseEther(keyPrice);
            return ethers.utils.formatUnits(bN.sub(bN2).abs());
        }
        return 0

    }, [keyPrice, keyPriceWithFee])
    // console.log( );

    return (
        <div className={s.dialog}>
            {/* <div className={s.topBar}>
                <CustomIcon width={23} height={23} imgName={'Button/UI_Button_Close_01'}
                            name={'Button/UI_Button_Close_01'}
                            onClick={closeFn}/>
            </div> */}
            <div className={s.content}>
                <div
                    className={s.title}>{transType[type]?.str} {t('xx\'s', {name: displayFilter(displayName)})} {profile?.profileDeepLink &&
                    <CustomIcon width={16} height={16} imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                name={'Button/UI_Button_Ball-White_Twitter_On'}/>} {accountType}</div>
                <div className="mtb20">
                    <AvatarInfo size={80} src={getAvatarFromProfile(profile, 1) || getAvatarFromProfile(profile, 0)}/>
                </div>
                <div className={s.topTips}>
                    {t('buy/sell num key/wish for num2 cur',
                        {
                            do: transType[type]?.str,
                            num: keyNum,
                            sth: accountType === 'key' ? keyNum > 1 ? t('keys') : t('key') : keyNum > 1 ? t('wishes') : t('wish'),
                            num2: stringToNumber(keyPrice, 4),
                            unit: unit
                        })}
                </div>
                <div className={`${s.info} ${s.borderWrap}`}>
                    <div className={s.details}>
                        <div className="fs15 c-title">{t('Order Details')}</div>
                        <div className={s.right}>
                            <div className="mr5 c-title fs15">$ {stringToNumber(keyPrice * maticUsd, 4)}</div>
                            <CustomIcon width={19} height={12}
                                        imgName={showMoreInfo ? 'UI_TT_Icon_ZhanKai' : 'UI_TT_Icon_Shouqi'}
                                        name={showMoreInfo ? 'UI_TT_Icon_ZhanKai' : 'UI_TT_Icon_Shouqi'}
                                        onClick={showMoreInfoFn}/>
                        </div>
                    </div>
                    {
                        showMoreInfo && <>
                            <div className={s.item}>
                                <div className="fs15">{t('From')}</div>
                                <div className={s.bg}>{sliceStr(walletAddr, 4)}</div>
                            </div>
                            <div className={s.item}>
                                <div className="fs15">{t('To')}</div>
                                <div className={s.bg}>{sliceStr(contractAddr, 4)}</div>
                            </div>
                            <div className={s.item}>
                                <div className="fs15">{t('Action')}</div>
                                <div className={s.bg}>{transType[type].str} {accountType === 'key' ? 'Key' : 'Wish'}</div>
                            </div>
                            <div className={s.item}>
                                <div className="fs15 c-title">{t('Est. Fees')}</div>
                                <div className="fs15">USD <span
                                    className="fs15">$ {stringToNumber((Number(getEst) + Number(fastGasPrice)) * maticUsd, 2)}</span>
                                </div>
                            </div>
                            <div
                                className="fs12 ml0">{stringToNumber(Number(getEst) + Number(fastGasPrice), 2)} {unit}</div>
                        </>
                    }
                    <div className={s.total}>
                        <div className="fs15 c-title">{t('In Total (including fees)')}</div>
                        <div>USD $ <span
                            className="fs15">{type === 1 ? stringToNumber((Number(keyPriceWithFee) + Number(fastGasPrice)) * maticUsd, 4) : stringToNumber((Number(keyPriceWithFee) - Number(fastGasPrice)) * maticUsd, 4)}</span>
                        </div>
                    </div>
                    <div
                        className="fs12 ml0">{type === 1 ? stringToNumber(Number(keyPriceWithFee) + Number(fastGasPrice), 4) : stringToNumber(Number(keyPriceWithFee) - Number(fastGasPrice), 4)} {unit}</div>
                </div>
                <div className={`${s.wallet} ${s.borderWrap}`}>
                    <div className={s.walletLeft}>
                        <CustomIcon width={16} height={14} imgName={'UI_TT_Icon_CardHolder'}
                                    name={'UI_TT_Icon_CardHolder'} onClick={showMoreInfoFn}/>
                        <div className="fs15">{sliceStr(walletAddr, 3)}</div>
                    </div>
                    <div
                        className={`${s.balance} ${!isAvailable && s.error}`}>{stringToNumber(balance, 4)} {unit} {!isAvailable ? 'available' : 'unavailable'}</div>
                </div>
                <div className={s.amountWrap}>
                    <div className="">
                        {t('Number of xx',
                            {sth: accountType === 'key' ? t('keys') : t('wishes')})}
                    </div>
                    <div className={s.amount}>
                        <Input className="inputNumber" value={keyNum} readOnly/>
                    </div>
                </div>
                {!done && <AntButton disabled={!isAvailable} shape="round" loading={getPriceLoding}
                                     className={`${s.btn} btn_public ${dataInit ? s.show : ''} ${!isAvailable ? 'btn_disabled' : ''}`}
                                     onClick={transFn}>
                    {isAvailable ? subLoading ? <div className={s.web3LoadingLine}>
                        <CustomIcon rotating={true} className="ml5" width={18} height={18}
                                    imgName={'Picture/UI_Picture_Loading_01'}
                                    name={'Picture/UI_Picture_Loading_01'}/>
                        <span className="fs14 ml5 color-black">{t('PROCESSING')}</span>
                    </div> : type === 1 ? t('BUY NOW') : t('SELL NOW') : t('TransactionError_0xa7777a3d')}
                </AntButton>}
                {done && <AntButton shape="round" className={`${s.btn} btn_public ${subFailded ? '' : s.show}`}
                                    onClick={closeFn}>{subFailded ? noSuccessText : t('TRANSACTION SUCCESSFUL')}</AntButton>}

            </div>
        </div>

    )
}
const mapStateToProps = ({app}) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelWishTrans));
// export default memo(BuyDialog);
