import React, {memo, useState, useMemo, useEffect, useRef, useCallback} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Tooltip, Modal, Button, message, Input, Dropdown, Space, Checkbox, Radio} from "antd";
import {DatePicker} from "antd-mobile";
import {
    convertWalletBalance,
    displayFilter,
    PrivyLoginStatusError,
    TransactionError
} from "@/utils/common";
import enUS from 'antd-mobile/es/locales/en-US'
import {setDefaultConfig} from 'antd-mobile';
import {connect} from "react-redux";
import {DownOutlined} from "@ant-design/icons";
import InputNumber from "@/common/InputNumber";
import CustomIcon from "src/common/CustomIcon";
import {redPacketMaxQuantity} from "@/utils/env";
import {redPacketDeposit, lfgApprove} from "@/utils/red_Packet";
import {useWallets} from "@privy-io/react-auth";
import AntButton from "@/common/AntButton";

setDefaultConfig({
    locale: enUS,
});

document.documentElement.setAttribute(
    'data-prefers-color-scheme',
    'dark'
)

Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1,
        "d+": this.getDate(),
        "h+": this.getHours(),
        "m+": this.getMinutes(),
        "s+": this.getSeconds(),
        "q+": Math.floor((this.getMonth() + 3) / 3),
        "S": this.getMilliseconds()
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}

const RedPacketModelCreate = (
    {
        setOpenRedPacketCreatePanel,
        userInfo,
        curentOtherUserInfo,
        redPacketHostAddress,
    }
) => {
    const {t, i18n} = useTranslation();
    
    const {
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const {
        balance,
        lfgBalance,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData])

    const {holding, holdersNum} = useMemo(() => {
        return curentOtherUserInfo || {}
    }, [curentOtherUserInfo])


    const NowDate = new Date();
    const DateNextH = new Date();
    const DateNextM = new Date();
    DateNextM.setHours(NowDate.getHours() + 24);

    const [visibleDateTime, setVisibleDateTime] = useState(false);
    const [nowDateTime, setNowDateTime] = useState(DateNextH.format("yyyy-MM-dd hh:mm:ss"));
    const unixTimestamp = useRef(parseInt(DateNextH.getTime() / 1000));

    const [selectCoin, setSelectCoin] = useState(0);

    const activeBalance = useMemo(() => {
        if (selectCoin === 1) {
            return balance
        } else if (selectCoin === 0) {
            return lfgBalance
        }
    })

    const [rightNow, setRightNow] = useState(true);

    const onChangeRightNow = (e) => {
        setRightNow(e.target.value);
    }

    const [amount, setAmount] = useState('');
    const onChangeAmount = (e) => {
        setAmount(() => {
            let c = Number(e.target.value);
            if (c < 0) {
                return 0;
            } else if (c > activeBalance) {
                return Math.floor(activeBalance * 1000) / 1000;
            } else {
                return e.target.value;
            }
        });
    }

    const [sharingNum, setSharingNum] = useState('');
    const onChangeSharingNum = (e) => {
        setSharingNum(() => {
            let c = parseInt(e.target.value);
            if (isNaN(c)) return '';
            if (c < 1) {
                return 1;
            } else if (c > redPacketMaxQuantity) {
                return redPacketMaxQuantity;
            } else {
                return c;
            }
        });
    }

    const [disableSubmit, setDisableSubmit] = useState(true);
    useEffect(() => {
        if (amount && sharingNum && ((selectCoin === 1 && Number((sharingNum * 0.1).toFixed(1)) <= amount) || (selectCoin === 0 && sharingNum * 100 <= amount))) {
            setDisableSubmit(false);
        } else {
            setDisableSubmit(true);
        }
    }, [amount, sharingNum, selectCoin])

    const MinAmount = useCallback(() => {
        let min = 0;
        if (selectCoin === 0) {
            min = sharingNum * 100;
        } else if (selectCoin === 1) {
            min = Number((sharingNum * 0.1).toFixed(1));
        }
        if (min > 0 && min > amount) {
            return <div className={s.amountTips}>
                {t('Minimum Total')}: {min}
            </div>
        }

        return <div className={s.amountTips}>&nbsp;</div>
    }, [amount, sharingNum, selectCoin])

    const [txLoading, setTxLoading] = useState(false);

    const {wallets} = useWallets();
    const [done, setDone] = useState(false);
    const [txFailed, setTxFailed] = useState(false);
    const [noSuccessText, setNoSuccessText] = useState(t('FAILED'));
    const submitRedPacket = async () => {
        setTxLoading(true);
        const startTime = rightNow ? 0 : unixTimestamp.current
        if (selectCoin === 0) {
            const [data, err] = await lfgApprove(wallets, amount).then(data => [data, null]).catch(err => [null, err]);
            if (err) {
                console.log(err);
                if (err?.reason != null) {
                    for (const transactionErrorElement in TransactionError) {
                        if (err?.reason.indexOf(transactionErrorElement) !== -1) {
                            setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                            break;
                        }
                    }
                }

                if (err === 'need login') {
                    setNoSuccessText(PrivyLoginStatusError)
                }

                setTxLoading(false);
                setTxFailed(true);
                setDone(true);
                return;
            }
        }
        redPacketDeposit(wallets, redPacketHostAddress, selectCoin, amount, sharingNum, startTime).then(res => {
            setTxLoading(false);
            setTxFailed(false);
            setDone(true);
        }).catch(e => {
            console.log(e);
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }

            if (e === 'need login') {
                setNoSuccessText(PrivyLoginStatusError)
            }

            setTxLoading(false);
            setTxFailed(true);
            setDone(true);
        })
    }

    const closeHandler = () => {
        setOpenRedPacketCreatePanel(false);
    }

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                {t('Send Red Packet')}
            </div>
            <div className={s.changePointType}>
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: (
                                    <div onClick={() => {
                                        setSelectCoin(1)
                                    }}>
                                        {t('$Matic')}
                                    </div>
                                ),
                            },
                            {
                                key: '0',
                                label: (
                                    <div onClick={() => {
                                        setSelectCoin(0)
                                    }}>
                                        {t('$LFG')}
                                    </div>
                                ),
                            }
                        ],
                    }}
                    placement="top"
                    trigger={['click']}
                    arrow={{pointAtCenter: true}}
                    disabled={txLoading}
                    rootClassName={`red-packet-dropdown`}
                >
                    <Space>
                        {selectCoin === 1 ? <>
                            <CustomIcon width={18} height={18} className="choicetype"
                                        imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                        name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                            <span className={s.yellowtxt}>{t('$Matic')}</span>
                            <DownOutlined style={{color: '#FAB600!important'}}/>
                        </> : ''}
                        {selectCoin === 0 ? <>
                            <CustomIcon width={18} height={18} imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            <span className={s.yellowtxt}>{t('$LFG')}</span>
                            <DownOutlined style={{color: '#FAB600!important'}}/>
                        </> : ''}
                    </Space>
                </Dropdown>
                <Space>
                    {selectCoin === 1 ? <>
                        {t('Balance')}
                        <CustomIcon width={18} height={18} imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                    name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                        {convertWalletBalance(activeBalance)}
                    </> : ''}
                    {selectCoin === 0 ? <>
                        {t('Balance')}
                        <CustomIcon width={18} height={18} imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                    name={'Picture/UI_Picture-Currency_LFG_01'}/>
                        {convertWalletBalance(activeBalance)}
                    </> : ''}
                </Space>
            </div>
            <div className={s.inputbox}>
                <div className={`${s.inputname} fs14`}>{t('Quantity')}</div>
                <Input className={`inputNumber ${s.inputNumber}`} variant="borderless"
                       placeholder={t("Club Members: ") + holdersNum}
                       value={sharingNum}
                       onChange={onChangeSharingNum} disabled={txLoading}/>
            </div>
            <MinAmount/>
            <div className={s.inputbox}>
                <div className={`${s.inputname} fs14`}>
                    {t('Total')} &nbsp;
                    {selectCoin === 1 && <>
                        <CustomIcon width={14} height={14} imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                    name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                    </>}
                    {selectCoin === 0 && <>
                        <CustomIcon width={14} height={14} imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                    name={'Picture/UI_Picture-Currency_LFG_01'}/>
                    </>}
                </div>
                <Input className={`inputNumber ${s.inputNumber}`} placeholder="Enter Number" variant="borderless"
                       value={amount}
                       onChange={onChangeAmount} disabled={txLoading}/>
            </div>
            <div className={s.checkBox}>
                <Radio.Group onChange={onChangeRightNow} value={rightNow} disabled={txLoading}>
                    <Radio className="hireCheckBox" value={true}>{t('Send Now')}</Radio>
                    <Radio className="hireCheckBox" value={false}>{t('Schedule Send')}</Radio>
                </Radio.Group>
            </div>
            {!rightNow && <div className={s.choicedate}>
                <a onClick={() => {
                    if (!txLoading) {
                        setVisibleDateTime(true)
                    }
                }}>
                    {nowDateTime}&nbsp;&nbsp;<DownOutlined/>
                </a>
                <DatePicker
                    visible={visibleDateTime}
                    onClose={() => {
                        setVisibleDateTime(false)
                    }}
                    precision='second'
                    locale={enUS}
                    min={DateNextH}
                    max={DateNextM}
                    defaultValue={DateNextH}

                    onConfirm={val => {
                        unixTimestamp.current = val.getTime() / 1000;
                        setNowDateTime(val.format("yyyy-MM-dd hh:mm:ss"))
                    }}
                    style={{zIndex: 3020}}
                />
            </div>}

            <div className={s.btnwrap}>
                {!done && <AntButton className={`btn_public fs18 ${s.btn} ${disableSubmit ? `btn_disabled` : ''}`}
                                     disabled={disableSubmit} onClick={submitRedPacket} loading={txLoading}>
                    {t('SEND')}
                </AntButton>}
                {done && <AntButton className={`btn_public fs18 ${s.btn}`} onClick={closeHandler}>
                    {txFailed ? noSuccessText : t('SUCCESSFUL')}
                </AntButton>}
            </div>
            <div className={`${s.info}`}>
                {t('Only club members can claim with randomized amounts.')} <br/>
                {t('Red Packets not opended within 24 hrs will be refunded')}
            </div>
        </div>
    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        curentOtherUserInfo: app.curentOtherUserInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}


export default memo(connect(mapStateToProps, mapDispatchToProps)(RedPacketModelCreate));
