import React, {memo, useState} from "react";
import {Button} from "antd";
import s from "./index.module.less";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {isOnlineEnv} from "@/utils/env";
import {logoutSelf, openMoonPay} from "@/utils/common";
import {useTranslation, Trans, useSSR} from 'react-i18next';
import CustomIcon from "../../common/CustomIcon";
import {uniswapLFG, uniswapQuoterLFG} from "@/utils/uniswapWeb3";
import ButtonFactory from "src/common/ButtonFactory";
import {mainHomePath, gameMomentReferralPath} from "@/routes/config";
import AntButton from "@/common/AntButton";
import {connect} from "react-redux";
import AntDrawer from "@/common/AntDrawer";
import GasSetting from "../GasSetting";
import {AutoStrangeRule} from "@/utils/strangeRule";

const ModalMomentSettingsMenu = (
    {
        unit,
        showDepositPanel,
        setShowDepositPanel,
        setShowWithdrawPanel,
        showSwitchLanguage,
        setShowSwitchLanguage,
        setShowSwapLfg,
        setShowAccountLinking,
        isGameHeader,
        navigate,
        closeHandle = () => {},
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const [showGasMode, setShowGasMode] = useState(false);
    const {exportWallet, logout, sendTransaction} = usePrivy();
    const [testUpdateCount, setTestUpdateCount] = useState(0);

    const link_tw = () => {
        window.location.href = "https://twitter.com/turnupdotxyz"
    }
    const link_t = () => {
        window.location.href = "https://t.me/justTURNUP"
    }

    const link_wp = () => {
        window.location.href = "https://doc.turnup.so/"
    }

    return (
        <div className={`${s.ModelSettingsMenu} ${showSwitchLanguage||showDepositPanel?s.hide:''} flex_center_start_col`}>
            <div className={`${s.spBtn}`}>
                <div 
                    className={`${s.menuBtn} ${`progressier-subscribe-button`}`}
                    data-icons="false"   
                    data-eligible={AutoStrangeRule(t('Notifications: ON/OFF'))} 
                    data-subscribed={AutoStrangeRule(t('Notifications: ON/OFF'))}
                    data-blocked={AutoStrangeRule(t('Notifications: ON/OFF'))}>
                </div>
                <div className={`${s.menuBtnRightTextAbsolute}`}>
                    {`>`}
                </div>
            </div>
            <div className={`${s.menuLine}`}>
            </div>
            <div className={`${s.menuBtn}`} onClick={() =>{
                setShowDepositPanel(true);
            }}>
                <div className={`${s.menuBtnText}`}>
                    {AutoStrangeRule(t('DEPOSIT xx', {unit: 'CRYPTO'}))}
                </div>
                <div className={`${s.menuBtnRightText}`}>
                    {`>`}
                </div>
            </div>
            <div className={`${s.menuLine}`}>
            </div>
            <div className={`${s.menuBtn}`} onClick={() =>{
                exportWallet();
            }}>
                <div className={`${s.menuBtnText}`}>
                    {AutoStrangeRule(t('EXPORT WALLET'))}
                </div>
                <div className={`${s.menuBtnRightText}`}>
                    {`>`}
                </div>
            </div>
            <div className={`${s.menuLine}`}>
            </div>
            <div className={`${s.menuBtn}`} onClick={() =>{
                setShowGasMode(true);
            }}>
                <div className={`${s.menuBtnText}`}>
                    {AutoStrangeRule(t('GAS SETTINGS'))}
                </div>
                <div className={`${s.menuBtnRightText}`}>
                    {`>`}
                </div>
            </div>
            <div className={`${s.menuLine}`}>
            </div>
            <div className={`${s.menuBtn}`} onClick={() =>{
                setShowSwitchLanguage(true);
            }}>
                <div className={`${s.menuBtnText}`}>
                    {AutoStrangeRule(t('SWITCH LANGUAGE'))}
                </div>
                <div className={`${s.menuBtnRightText}`}>
                    {`>`}
                </div>
            </div>
            {
                (isGameHeader && !window.isMomentsLink && !isOnlineEnv()) &&
                <>
                    <div className={`${s.menuLine}`}>
                    </div>
                    <div className={`${s.menuBtn}`} onClick={() =>{
                        navigate(mainHomePath, {replace: true});
                    }}>
                        <div className={`${s.menuBtnText}`}>
                            {AutoStrangeRule(t('BACK TO HOME'))}
                        </div>
                        <div className={`${s.menuBtnRightText}`}>
                            {`>`}
                        </div>
                    </div>
                </>
            }
            {
                !isOnlineEnv() && 
                <>
                    <div className={`${s.menuLine}`}>
                    </div>
                    <div className={`${s.menuBtn}`} onClick={() =>{
                        window.isMomentsLink = !window.isMomentsLink;
                        setTestUpdateCount(testUpdateCount + 1);
                    }}>
                        <div className={`${s.menuBtnText}`}>
                            {`moment <=> turnup 切换(测试用)`}
                        </div>
                        <div className={`${s.menuBtnRightText}`}>
                            {`>`}
                        </div>
                    </div>
                </>
            }
            <div className={`${s.officiallink} flex_center_start_col`}>
                <div className={`${s.linktitle} flex_center_center`}>
                    {AutoStrangeRule(t('Official Link'))}
                </div>
                <div className={`${s.linkmain}`}>
                    <div className={`${s.link}`} onClick={link_tw}>
                        <CustomIcon width={52} height={52}
                                    imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                    name={'Twitter'}/>
                        <span className={`${s.linkText}`}>{AutoStrangeRule(t('Twitter'))}</span>
                    </div>
                    <div className={`${s.link}`} onClick={link_t}>
                        <CustomIcon width={52} height={52} imgName={'Button/UI_Button_Ball-White_TurnUp'}
                                    name={'Telegram'}/>
                        <span className={`${s.linkText}`}>{AutoStrangeRule(t('Telegram'))}</span>
                    </div>
                    {/*<div className={`${s.link}`} onClick={link_wp}>
                        <div className={`${s.linkBg} flex_center_center`}>
                            <CustomIcon width={26} height={30} imgName={'Button/UI_Button_Paper_02'}/>
                        </div>
                        <span className={`${s.linkText}`}>{t('White Paper')}</span>
                    </div>*/}
                </div>
            </div>
            <AntButton
                className={`${s.logOutBtn} flex_center_center`}
                onClick={() => {
                    logout();
                    logoutSelf();
                }}
            >
                <span className={"fs16 momentFont color-EEEEEE fb"}>{AutoStrangeRule(t('LOG OUT'))}</span>
            </AntButton>
            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeHandle();
                }} />

            <AntDrawer
                title={AutoStrangeRule(t('Gas Mode'))}
                placement={'right'}
                onClose={() => setShowGasMode(false)}
                width={246}
                open={showGasMode}
                className="momentGasFilter"
                zIndex={7778}
            >
                <GasSetting
                    closeHandle={() => {
                        setShowGasMode(false);
                    }}
                    type={2}
                />
            </AntDrawer>
        </div>
    );
};

const mapStateToProps = ({app}) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        /*setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },*/
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalMomentSettingsMenu));
