


import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Slider, Checkbox, Select} from "antd";
import {
    convertWalletBalance
} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";
import NFTRarityInfo from "@/utils/json/NFTRarityInfo.json";

const { Option } = Select;

const MomentClubNftFilter = (
    {
        filterRarityRange = [1, 5],
        filterOnlySelfNft = false,
        applyFn = () =>{},
    }
) => {
    const {t, i18n} = useTranslation();

    const [currentRarityRange, setCurrentRarityRange] = useState(filterRarityRange);
    const [currentOnlySelfNft, setCurrentOnlySelfNft] = useState(filterOnlySelfNft);

    const apply = () => {
        applyFn({
            rarityRange: currentRarityRange,
            onlySelfNft: currentOnlySelfNft,
        });
    }

    const reset = ()=>{
        setCurrentRarityRange([1, 5]);
        setCurrentOnlySelfNft(false);
    }

    const onChangeRarityRange = (value) => {
        setCurrentRarityRange(value);
    }

    const getNFTRarityInfo = (id) =>{
        let res = null;
        if (NFTRarityInfo?.length > 0){
            for (let i = 0; i < NFTRarityInfo?.length; i++){
                if (NFTRarityInfo[i]?.rarity === id){
                    res = NFTRarityInfo[i];
                    break;
                }
            }
        }
        return res;
    }
    
    return (
        <div className={s.wrap}>
            <div className={`${s.title} momentFont mt10`}>
                <CustomIcon width={25} className="mr5" height={25} imgName={'Button/UI_Button_Filters_01'}/> {AutoStrangeRule(t('FILTERS'))}
            </div>
            <div className={s.filterCon}>
                <div className={s.item}>
                    <div className={`${s.itemTitle}`}>
                        <div className={`${s.label} momentFont`}>{t('Rarity')}:</div>
                        <div className={s.rankArea}>
                            <span className="color-white">{getNFTRarityInfo(currentRarityRange?.[0] || 1)?.name}</span>
                            <span className="fs12"> {t('to')} </span>
                            <span className="color-white">{getNFTRarityInfo(currentRarityRange?.[1] || 1)?.name}</span>
                        </div>
                    </div>
                    <div className={s.slider}>
                        <Slider
                            defaultValue={currentRarityRange}
                            className="momentFilterSlider"
                            range={true}
                            min={1}
                            max={5}
                            value={currentRarityRange}
                            tooltip={{ open: false }}
                            step={1}
                            onChange={onChangeRarityRange}/>
                    </div>
                </div>

                <div className="mt10 mb30">
                    <Checkbox 
                        onChange={(e)=>{
                            setCurrentOnlySelfNft(e.target.checked);
                        }} 
                        className="momentCheckBox"  
                        checked={currentOnlySelfNft}>
                        <span className="ml10">{t('Only Held')}</span>
                    </Checkbox>
                </div>
            </div>
            <div className={s.bottom}>
                <AntButton className={`${s.black} btn_transparent_moment`} onClick={reset}>{t('RESET')}</AntButton>
                <AntButton className="btn_public" onClick={apply}>{t('APPLY')}</AntButton>
            </div>

        </div>
    )
}
export default memo(MomentClubNftFilter);
