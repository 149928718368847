import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import s from "./index.module.less";

import {Button, message} from "antd";
import CustomIcon from "../../common/CustomIcon";
import {TwitterShareButton} from 'react-twitter-embed';
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import {getAvatarFromProfile, joinChartPre} from "@/utils/common";
import { TwitterRandomContent, topic, tag } from "@/utils/xFarm";
function copyFn(text) {
    copyText(text, function () {
            message.success({content: 'copied!'})
        }
    );
}

const copyText = function (button, content, success) {
    if (!button) {
        return;
    }

    if (typeof content == 'function') {
        success = content;
        content = null;
    }

    success = success || function () {
    };

    // 是否降级使用
    var isFallback = !navigator.clipboard;

    if (typeof button == 'string' && !content) {
        if (content === false) {
            isFallback = true;
        }
        content = button;
        button = null;
    }

    var eleTextarea = document.querySelector('#tempTextarea');
    if (!eleTextarea && isFallback) {
        eleTextarea = document.createElement('textarea');
        eleTextarea.style.width = 0;
        eleTextarea.style.position = 'fixed';
        eleTextarea.style.left = '-999px';
        eleTextarea.style.top = '10px';
        eleTextarea.setAttribute('readonly', 'readonly');
        document.body.appendChild(eleTextarea);
    }


    var funCopy = function (text, callback) {
        callback = callback || function () {
        };

        if (!isFallback) {
            navigator.clipboard.writeText(text).then(function () {
                callback();
                // 成功回调
                success(text);
            }, function () {
                // 禁止写入剪切板后使用兜底方法
                copyText(text, false);
                callback();
                // 成功回调
                success(text);
            });

            return;
        }

        eleTextarea.value = text;
        eleTextarea.select();
        document.execCommand('copy', true);

        callback();
        // 成功回调
        success(text);
    };

    var strStyle = '.text-popup { animation: textPopup 1s both; -ms-transform: translateY(-20px); color: #01cf97; user-select: none; white-space: nowrap; position: absolute; z-index: 99; }@keyframes textPopup {0%, 100% { opacity: 0; } 5% { opacity: 1; } 100% { transform: translateY(-50px); }}'

    var eleStyle = document.querySelector('#popupStyle');
    if (!eleStyle) {
        eleStyle = document.createElement('style');
        eleStyle.id = 'popupStyle';
        eleStyle.innerHTML = strStyle;
        document.head.appendChild(eleStyle);
    }

    if (!button) {
        funCopy(content);
        return;
    }

    // 事件绑定
    button.addEventListener('click', function (event) {
        var strCopy = content;
        if (content && content.tagName) {
            strCopy = content.textContent || content.value;
        }
        // 复制的文字内容
        if (!strCopy) {
            return;
        }

        funCopy(strCopy, function () {
            copyText.tips(event);
        });
    });
};

const ModelUpFarmTweetShare = (
    {
        userInfo,
        mentionList,
    }
) => {
    const {t, i18n} = useTranslation();
    const copyWrap = useRef(null);
    const [selfAccountName,setSelfAccountName] = useState(userInfo?.platformData?.platformMap[1]?.accountName)
    const [tweetRandom,setTweetRandom] = useState(TwitterRandomContent[Math.floor(Math.random() * TwitterRandomContent?.length)]?.twitterContent)
    const [activeInd,setActiveInd] = useState(0)
    // console.log(tweetStr);
    const copy = () => {
        copyFn(copyWrap.current.innerText);
    }
    const getUserInfo = (userInfo, twitterUserInfo, isUser) => {
        let avatarUrl, displayName, accountName, twitterUid ;
        if (isUser) {
            avatarUrl = getAvatarFromProfile(userInfo?.profile)
            displayName = userInfo?.profile?.displayName
            accountName = userInfo?.profile?.accountName
            twitterUid = userInfo?.twitterUid
        } else {
            avatarUrl = twitterUserInfo?.avatarUrl
            displayName = twitterUserInfo?.displayName
            accountName = twitterUserInfo?.accountName
            twitterUid = twitterUserInfo?.twitterUid
        }
        return {
            displayName,
            avatarUrl,
            accountName,
            twitterUid
        }
    }


    const tweetStr = useMemo(()=>{
        if(mentionList?.length){
            const ind = Math.floor(Math.random() * mentionList?.length)
            const { userInfo, twitterUserInfo, isUser } =  mentionList[ind];
            const {accountName} =   getUserInfo(userInfo, twitterUserInfo, isUser)
            return `${topic} @${accountName} ${tag} ${tweetRandom}`
        }
        return `${topic} @${selfAccountName} ${tag} ${tweetRandom} `
    },[tweetRandom,mentionList])

    // console.log(tweetStr);
    // const shareWords = [];
    // shareWords.push(t('Earn daily $UP Points on @GoTurnUp'));
    // shareWords.push(t('The #1 Gaming & Social dApp on @0xPolygon'));
    // shareWords.push(t('Time to #TURNUP'));
    
    return (
        <div className={s.wrap}>
            
            <div className={s.copyContent}>
                <div ref={copyWrap}>
                    {tweetStr}
                </div>
                <div className={s.copy} onClick={copy}>
                    <CustomIcon width={20} height={20} imgName={'Button/UI_Button_CopyNumberWordName_01'}
                                name={'Button/UI_Button_CopyNumberWordName_01'}/>
                </div>

            </div>
            {/* <div className="fs16 color-white df alc w100p mt15 mb15">
                {t('Popular Mentioned')}
                <CustomIcon width={18} height={18} className="ml5" imgName={'Button/UI_Button_Refresh'}
                                name={'Button/UI_Button_Refresh'}/>
            </div>
            <div className={s.list}>
                
                    {list?.map((i,ind)=>{
                        return(
                            <div key={ind} onClick={()=>{setActiveInd(ind)}} className={`${s.item} ${activeInd === ind ? s.active : ''}`}>
                                {i?.name}
                            </div>
                        )
                        
                    })}
               
            </div> */}
            <br/>
            <TwitterShareButton
                onLoad={() => {
                }}
                options={{
                    size: 'large',
                    text: `${tweetStr}`
                }}
                url={' '}
            />
        </div>
    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelUpFarmTweetShare));
