import React, {memo, useEffect, useState, useMemo} from "react";
import s from "./index.module.less";
import {
    copyFn,
    openMoonPay,
    unit,
    getChainTokenInfoById,
    getQuotes,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {Button} from "antd";
import {
    moonpayBasicDollar, 
    isOnlineEnv,
    BaseChainId,
    PolygonChainId,
} from "@/utils/env";
import {useTranslation, Trans} from 'react-i18next';
import moonpayImg from "@/assets/images/bgImg/UI_Picture_MoonPay.png"
import uniswapImg from "@/assets/images/bgImg/UI_Picture_UNISWAP.png"

const ModalMomentEarning = (
    {
        closeFn = () => {},
    }
) => {
    const {t, i18n} = useTranslation();

    return (
        <div className={s.wrap}>
            <div className={`${s.title} flex_center_center`}>
                {t('Moment Earning')}
            </div>
            <div className={`${s.textLine}`}>
                <Trans
                    i18nKey="MomentEarningTipText"
                    defaults="The moments you create can generate income for you. Each time your created moments are traded ( both buy and sell ), you earn a <white>5%</white> commission."
                    components={{white: <span className={`fs14 color-white fb`}></span>}}
                />
            </div>
            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeFn();
                }} />
        </div>
    )
}
export default memo(ModalMomentEarning);
