import React, {memo, useMemo, useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../../common/CustomIcon";
import {Button, Modal, Spin} from 'antd';
import {
    getuserinfo,
    getPrice,
    timerDelay, 
    convertTimestamp, 
    unit, 
    joinChat, 
    stringToNumber, 
    getProfile, 
    easeJoinGroup,
    displayFilter
} from "@/utils/common";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import ModelTransaction from "../../../model/ModelTransaction";
import ChatContentHeader from "src/common/ChatContentHeader";
import OtherTransRecord from "src/common/OtherTransRecord"
import {LoadingOutlined} from '@ant-design/icons';
import Chat from "src/common/ChatContent";
import Nosleep from "nosleep.js";
import {mainHomePath} from "@/routes/config";
import MainHeader from "@/common/MainHeader";
import AntModal from "@/common/AntModal";

const NftContent = ({
    setShowOtherTransContent,
    curentOtherUserInfo,
    setCurentOtherUserInfo,
    userInfo,
    showOtherTransContent,
    deleteChatCustomMessage,
    customChatMap,
    curRtcInfo,
    setGroupInfo,
    groupInfo,
}) => {
    const {t, i18n} = useTranslation();
    const userId = 1019;
    const {tokenAddr, tokenId} = useParams();
    const nosleep = new Nosleep();
    const navigate = useNavigate();
    const selfUid = userInfo?.userId;

    const [openTranPanel, setOpenTranPanel] = useState(false);
    const [groupReady, setGroupReady] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [groupId, setGroupId] = useState('')
    const LoadingOk = useRef(false);
    const hasBindTweet = useMemo(() => {
        return userInfo?.platformData?.useType !== 0 || false
    }, [userInfo])
    const isSelfChat = useMemo(() => {
        return selfUid === Number(userId)
    }, [selfUid, userId])

    useEffect(() => {
        // setShowOtherTransContent(false)
        setCurentOtherUserInfo({})
    }, [])
    const {buyFirstKey, payEntryFee} = useMemo(() => {
        return userInfo?.selfData || {}
    }, [userInfo])
    useEffect(() => {
        if (userId) {


            getuserinfo(userId).then(res => {
                setCurentOtherUserInfo(res);
                res.youOwnKeyNum ? setShowOtherTransContent(false) : setShowOtherTransContent(true)
                LoadingOk.current = true;
                if (res.accountType !== "wish") {
                    joinChat(userId, navigate, res.accountType, true)
                }
                // setPageLoading(false)
            }).catch(e => {
                console.log(e);
                LoadingOk.current = true;
            })
            getPrice(userId);
            const timer = setInterval(() => {
                getPrice(userId)
            }, timerDelay)
            return () => {
                timer && clearInterval(timer)
            }
        }
    }, [userId])
    const calledJoinGroupFn = useRef(false)
    const easeJoinGroupFn = () => {
        if (!calledJoinGroupFn.current) {
            calledJoinGroupFn.current = true
            easeJoinGroup(userId).then(res => {

                const obj = {};
                obj[userId + ''] = {
                    group_id: res?.groupId,
                    msg_types: ['txt'],
                    expireTimes: new Date().getTime() + 25 * 60 * 1000
                }
                setGroupInfo(obj)
                setGroupId(res?.groupId)
                //   clgFn('easeJoinGroup', '', res)
            }).catch(e => {
                setPageLoading(false);
                // message.error({content:`JoinGroup`})
                //   clgFn('Error easeJoinGroup', 'red', e)
            })
        }

    }

    useEffect(() => {
        LoadingOk.current = false
        setPageLoading(true)
        if (userId && !groupInfo[userId]?.group_id) {
            easeJoinGroupFn()
        }
    }, [userId, curentOtherUserInfo, LoadingOk])

    const {
        youOwnKeyNum,
        platformData,
        canJoinAgoraChan,
        agora_setchantype,
        wishFee,
        maticUsd,
        managerId
    } = useMemo(() => {
        return curentOtherUserInfo || {}
    }, [curentOtherUserInfo, userId])

    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])

    const hasOneKey = useMemo(() => {
        if (youOwnKeyNum) {
            return true
        }
        return false
    }, [youOwnKeyNum])

    const location = useLocation();
    const backFn = async () => {
        // if (location.key !== "default") {
        //     navigate(-1);
        // } else {
        navigate(mainHomePath, {replace: true});
        // }
    }

    useEffect(() => {
        if (userId && youOwnKeyNum) {
            if ((!groupInfo[userId]?.group_id || groupInfo[userId]?.expireTimes < new Date().getTime())) {
                easeJoinGroupFn()
            } else {
                const obj = {};
                const i = groupInfo[userId];
                obj[userId + ''] = {
                    ...i,
                    expireTimes: new Date().getTime() + 25 * 60 * 1000
                }
                setGroupInfo(obj)
                setGroupId(groupInfo[userId]?.group_id)
            }

        }
    }, [userId, youOwnKeyNum])

    return (
        // <Spin
        //     className="loadPageSpin"
        //     indicator={
        //         <LoadingOutlined
        //             style={{
        //                 fontSize: 90,
        //             }}
        //         />
        //     }
        //     tip="Loading..."
        //     spinning={pageLoading}
        // >
        <>
            <div className={`${s.wrap} wishBG`}>
                <MainHeader hasBackBtn={true}></MainHeader>
                <ChatContentHeader
                    setOpenTranPanel={setOpenTranPanel}
                    setShowOtherTransContent={setShowOtherTransContent}
                    backFn={backFn}
                />
                <div className={s.content}>
                    {
                        !hasOneKey && !pageLoading &&
                        <div className={s.loackWrap}>
                            <CustomIcon width={47} height={58} imgName={'loack'}/>
                            <div className={"mtb20 fs15 color-gray"}>
                                <Trans
                                    i18nKey="Hold at least one wish of sth to send messages"
                                    defaults="Hold at least one wish of <br></br>{{name}} to send messages"
                                    values={{name: displayFilter(profile?.displayName)}}
                                    components={{br: <br/>}}
                                />
                            </div>

                            <div className={s.buyBtn} onClick={() => {
                                if (buyFirstKey && payEntryFee) {
                                    setOpenTranPanel(true)
                                } else {
                                    navigate(mainHomePath)
                                }
                            }}></div>
                            <div className={`${s.val} `}>
                                <div>{t('Unclaimed value')}</div>
                                <div className={s.uv}>
                                    <div className="fs18 fb">
                                        {wishFee === "" ? 0 : stringToNumber(wishFee, 4)} <span
                                        className="">{unit}</span>
                                    </div>
                                    <div className="color-gray mt5">
                                        ${stringToNumber(wishFee * maticUsd, 2)} USD
                                    </div>
                                </div>
                            </div>
                            <div className={s.info}>
                                <div className="fs16 fl">
                                    {t('Before a creator is onboarded on TURNUP, you can trade Wishes, which will be converted into Keys upon their platform entry.')}
                                    <br/>
                                    {t('We\'ll reserve 20 keys specifically for Wish account owners, priced at 24.7 $MATIC upon onboarding. Pre-sale initiates from the 21st Wish onward.')}
                                </div>
                            </div>
                        </div>
                    }

                    <Chat
                        keyId={userId}
                        isSelfChat={isSelfChat}
                        hasBindTweet={hasBindTweet}
                        // liveLoading={liveLoading}
                        // setGroupReady={setGroupReady}
                        hasOneKey={hasOneKey}
                        groupId={groupId}
                        setGroupId={setGroupId}
                        setPageLoading={setPageLoading}
                        accountType="nft"
                        managerId={managerId}
                        pageLoading={pageLoading}
                        isGlobalChatGroup={false}
                        curentOtherUserId={curentOtherUserInfo?.userId}
                        nextLiveTime={0}
                    />
                </div>

            </div>

            <AntModal
                width='90%'
                title={t('TRADE NFTS')}
                className="confirmModalWrap"
                centered={true}
                open={openTranPanel}
                destroyOnClose={true}
                zIndex={3000}
                onOk={() => setOpenTranPanel(false)}
                onCancel={() => setOpenTranPanel(false)}
            >
                <ModelTransaction curentOtherUserInfo={curentOtherUserInfo} accountType="nft"/>
            </AntModal>

            {showOtherTransContent && <OtherTransRecord setOpenTranPanel={setOpenTranPanel} backFn={backFn}/>}
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        customChatMap: app.customChatMap,
        showCharContent: app.showCharContent,
        curentOtherUserInfo: app.curentOtherUserInfo,
        curRtcInfo: app.curRtcInfo,
        showOtherTransContent: app.showOtherTransContent,
        groupInfo: app.groupInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCharContent: (params) => {
            return dispatch({
                type: "app/setShowCharContent",
                payload: params,
            });
        },
        setShowOtherTransContent: (params) => {
            return dispatch({
                type: "app/setShowOtherTransContent",
                payload: params,
            });
        },
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        deleteChatCustomMessage: (params) => {
            return dispatch({
                type: "app/deleteChatCustomMessage",
                payload: params,
            });
        },
        setGroupInfo: (params) => {
            return dispatch({
                type: "app/setGroupInfo",
                payload: params,
            });
        },
    }
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(NftContent));
