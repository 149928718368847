import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';

const ModalMultiplier = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className={s.wrap}>
            <div className={s.text}>
                <div className="fs16 color-white  tll">
                    {t('Use your referral code')}
                </div>
                <div className="fs16 color-white  tll">
                    {t('The Larger Your Group Portfolio')}
                </div>
            </div>
        </div>
    );
};
export default memo(ModalMultiplier);
