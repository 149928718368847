import React, {memo, useEffect, useMemo, useState} from "react";
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import {Checkbox, Drawer, Modal, Radio, Tabs} from 'antd';
import s from "./index.module.less"
import {
    getearninfo,
    getProfile,
    formatNumberWithUnit,
    getfriendtrade_rank_selfdata,
    getAvatarFromUserInfo
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate} from "react-router-dom";
import CrackList from "src/common/CrackList";
import ModelDeposit from "src/model/ModelDeposit";
import ModelRankReward from 'src/model/ModelRankReward';
import RankReward from '@/utils/json/RankReward.json';
import ButtonFactory from "@/common/ButtonFactory";
import MainHeader from "@/common/MainHeader";
import MemeClubPack from "@/common/MemeClubPack";
import Countdown from "src/common/Countdown";
import ModalMemeLotteryDetail from "@/model/ModalMemeLotteryDetail";
import BattleHireFilter from "@/drawer/BattleHireFilter";
import AntModal from "@/common/AntModal";

const MemeLottery = (
    {
        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [selectedNeedBuyIndex, setSelectedNeedBuyIndex] = useState(0);
    const [showLotteryDetail, setShowLotteryDetail] = useState(false);
    const [prizeList,setPrizeList] = useState([]);

    const [showResult, setShowResult] = useState(false);

    const CIRCLE_ANGLE = 360;
    const BIGSIZE = 24;
    let angleList = []; // 记录每个奖的位置
    let gift_id =3; //中奖ID
    let index='';//抽中的是第几个奖品
    let isRotating = false; //为了防止重复点击
    let rotateAngle = 0;

    const wheelInfo = null;
    const resultInfo = null;

    const formatPrizeList = (list)=> {			  
        const l = list.length;
        // 计算单个奖项所占的角度
        const average = CIRCLE_ANGLE / l; //60
        const half = average / 2; //30			  
        const rightBig = l == 2 ? '50' : '0';
        const heightBig = l <= 3 ? '100' : '50';
        const topBig = l == 3 ? '-50' : '0';
        const skewMain = l<=2?0:-(l-4)*90/l;
        // 循环计算给每个奖项添加style属性
        list.forEach((item, i) => {
            // 每个奖项旋转的位置为 当前 i * 平均值 + 平均值 / 2
            const angle = -(i * average + half);	
            const bigAge = l>2?i*360/l:'0';
            // 增加 style 这个是给每一个奖项增加的样式
            item.style = {
                transform: `rotate(${-angle}deg)`,
                width:`${(100/l*2)}%`,
                marginLeft:`-${100/l}%`,
                fontSize:`${BIGSIZE-l}px`
            }
            //这是给每一个转盘背景新增的样式
            item.style2 ={
                transform: `rotate(${bigAge}deg) skewY(${skewMain}deg)`,
                right:`${rightBig*i}%`,
                height:`${heightBig}%`,
                top:`${topBig}%`,
                width:`${l==1?100:50}%`,
                // background:`${item.color}`
            }
            angleList.push(angle);         
        });	         
        return list;
    };

    const cardList = useMemo(() => {
        return [{
            isEmpty: false,
            name: 'Meme1',
            needBuy: true,
            canClaim: false,
            image: 'http://meme-nft-test.turnup.so/memeclub_image/coin10.png',
        },
        {
            isEmpty: false,
            name: 'Meme2',
            needBuy: false,
            canClaim: false,
            image: 'http://meme-nft-test.turnup.so/memeclub_image/coin1.png',
        },
        {
            isEmpty: false,
            name: 'Meme3',
            needBuy: false,
            canClaim: true,
            image: 'http://meme-nft-test.turnup.so/memeclub_image/coin1.png',
        },
        {
            isEmpty: true,
            name: 'Meme',
            needBuy: true,
            canClaim: false,
            image: 'http://meme-nft-test.turnup.so/memeclub_image/coin1.png',
        }]
    }, []);

    useEffect(() => {
        setSelectedNeedBuyIndex(1);
    }, [])

    useEffect(()=>{
        setPrizeList(formatPrizeList([{},{},{},{},{},{},{},{}]));
    },[])
    
    return (
        <div className={`${s.wrap} navBg`}>
            <MainHeader title={t('Spin To Get Free Packs')} headerType={2} isGameHeader={true} hasRightMenu={false} rightMenuIcon ={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={`${s.content} flex_center_start_col`}>
                <div className={`${s.cardList} flex_center_between`}>
                    {
                        cardList?.length > 0 && cardList.map((item, index) => {
                            const {
                                isEmpty,
                                name,
                                needBuy,
                                canClaim,
                                image
                            } = item || {};
                            return (
                                <div key={index} className={`${s.cardRoot} flex_center_start_col`}>
                                    <div className={`${s.cardPack}`}>
                                        <MemeClubPack
                                            width={88}
                                            height={88}
                                            imageUrl={image}
                                            packName={name}
                                            isEmpty={isEmpty}
                                            emptyText={t('Empty')}
                                            className={s.packItem}
                                            onClick={() =>{
                                                setSelectedNeedBuyIndex(index + 1);
                                            }}>
                                        </MemeClubPack>
                                    </div>
                                    {
                                        isEmpty ? 
                                            <></> :
                                        canClaim ?
                                            <>
                                                <div className={`${s.claimBtn} flex_center_center`}>
                                                    {t('Claim')}
                                                </div>
                                            </> :
                                        needBuy ? 
                                            <>
                                                <div className={`${s.needBuyPart} flex_center_start_col`}>
                                                    <span className="fs10 color-white textnowrap">
                                                        {t('Buy To Confirm')}
                                                    </span>
                                                    <span className="fs12 color-yellow textnowrap">
                                                        <Countdown refresh={() => {}} leftSecond={30*60} setType={0}/>
                                                    </span>
                                                </div>
                                            </> :
                                            <>
                                                <div className={`${s.waitClaimPart} flex_center_start_col`}>
                                                    <span className="fs12 color-white textnowrap">
                                                        <Countdown refresh={() => {}} leftSecond={60*60} setType={0}/>
                                                    </span>
                                                </div>
                                            </>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
                {
                    selectedNeedBuyIndex > 0 && 
                    <div className={`${s.missionPart} flex_center_start_col`}>
                        <div className={`${s.buyPart} flex_center_between`}>
                            <div className="fs16 fb">
                                {t('Buy To Confirm')}
                            </div>
                            <div className={`${s.count} flex_center_center`}>
                                <CustomIcon width={18} height={18} className={`mr4`} imgName={'Picture/UI_Picture_icon_Notification'}/>
                                <div className={`fs16`}>
                                    {'15.25k'}
                                </div>
                            </div>
                        </div>
                        <div className={`${s.countDownPart} flex_center_between`}>
                            <div className={`${s.countDownText} color-yellow`}>
                                <Countdown refresh={() => {}} leftSecond={30*60} setType={0}/> 
                            </div>
                            <ButtonFactory
                                className={s.buyBtn}
                                size={21}
                                color={1}
                                isFixed={true}
                                fixedStyle={{useHeight: true, height: 31}}
                                onClick={() => {
                                    //TODO
                                }}
                            >
                                <div className={`fs22 fb color-black`}>
                                    {t('Buy')}
                                </div>
                            </ButtonFactory>
                        </div>
                        <CustomIcon width={16} height={16} className={`${s.detailBtn}`} imgName={'Button/UI_Button_QuestionMark_01'} onClick={() =>{
                            setShowLotteryDetail(true);
                        }}/>
                        <div className={`${s.arrow}`} style={{
                            backgroundImage: `url(/UI/Picture/UI_Picture_Arrows_Dark_01.png)`, 
                            left: `${(selectedNeedBuyIndex - 1) * 25 + 12.5}%`}}>
                        </div>
                    </div>
                }
                <div className={s.gameWrap}>
                    <div className={`${s.luckWhellBgMain} ${s.rotateStyle}`} id="luckWhellBgMain">
                        {
                            prizeList?.map(item => {
                                const { style2, id } = item
                                return (
                                    <div key={id} className={s.luckWhellSector} style={style2}>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={s.wheelmain}>
                        <div className={`${s.prizelist} ${s.rotateStyle}`} id="prize-list">
                            {
                                prizeList?.map(item => {
                                    const { style, showStr, id } = item
                                    return (
                                        <div key={id} className={s.prizeitem} style={style}>
                                            <div className={`${s.val} ${s.storke1}`}>{showStr}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={s.gameBg} style={{backgroundImage:`url(/UI/Picture/Wheel/UI_Picture_Spin_02.png)`}}></div>
                    {
                        showResult && 
                        <div className={s.result}>
                            <CustomIcon width={20} className="mr5" height={20} imgName={'Picture/UI_Picture_Title-Blue_Rocket_01'} />
                            <div className="fs16">{t('Congrats! You got a')}</div> &nbsp;
                            <div className="color-yellow fs16">{resultInfo?.showStr}&nbsp; {t('bonus!')}</div>
                        </div>
                    }
                </div>
            </div>
            <AntModal
                width='330px'
                title=""
                className="confirmModalWrap"
                centered={true}
                open={showLotteryDetail}
                onOk={() => setShowLotteryDetail(false)}
                onCancel={() => setShowLotteryDetail(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMemeLotteryDetail
                    closeFn={() => {
                        setShowLotteryDetail(false);
                    }}
                />
            </AntModal>
        </div>

    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MemeLottery));
