import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Slider, Checkbox, Select} from "antd";
import {
    convertWalletBalance
} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";
const { Option } = Select;

const MemeClubFilter = (
    {
        maxCoinPrice = 0.5,
        maxMarketCap = 50000,
        maxFavorite = 200,
        filterPackPrice,
        filterMarketCap,
        // filterLiquidity,
        filterFavoriteRange,
        filterTgePro,
        filterOnlyFavorite,
        filterOnlyHeld,
        coinId,
        applyFn = () =>{},
        coinList,
        isSortByPrice = false,
        showTgeProcess = true,
        isMarketCap = true,
        showPrice = true,
    }
) => {
    const {t, i18n} = useTranslation();

    const [currentCoinId, setCurrentCoinId] = useState(coinId);
    const [currentOnlyFavorite, setCurrentOnlyFavorite] = useState(filterOnlyFavorite);
    const [currentOnlyHeld, setCurrentOnlyHeld] = useState(filterOnlyHeld);
    const [currentPriceRange, setCurrentPriceRange] = useState([filterPackPrice?.[0] || 0, (filterPackPrice?.[1] >= 0 && filterPackPrice?.[1] <= maxCoinPrice) ? filterPackPrice?.[1] : maxCoinPrice]);
    const [currentTgePro, setCurrentTgePro] = useState([filterTgePro?.[0] || 0, filterTgePro?.[1] == null ? 100:filterTgePro?.[1]]);
    const [currentMarketCapRange, setCurrentMarketCapRange] = useState([filterMarketCap?.[0] || 0, (filterMarketCap?.[1] >= 0 && filterMarketCap?.[1] <= maxMarketCap) ? filterMarketCap?.[1] : maxMarketCap]);
    const [currentFavoriteRange, setCurrentFavoriteRange] = useState([filterFavoriteRange?.[0] || 0, (filterFavoriteRange?.[1] >= 0 && filterFavoriteRange?.[1] <= maxFavorite) ? filterFavoriteRange?.[1] : maxFavorite]);

    const [openCoinId, setOpenCoinId] = useState(false);

    const showCoinList = useMemo(() =>{
        let res = [];
        if (!isSortByPrice){
            res.push({
                coinName: "ALL",
                icon: '',
                label: 'ALL',
                name: "ALL",
                value: 0
            });
        }
        res.push(...coinList);
        return res;
    }, [coinList]);

    const apply = () => {
        applyFn({
            coinId: currentCoinId,
            onlyFavorite: currentOnlyFavorite,
            onlyHeld: currentOnlyHeld,
            priceRange: currentPriceRange,
            marketCapRange: currentMarketCapRange,
            // liquidityRange: currentLiquidityRange,
            favoriteRange: currentFavoriteRange,
            tgeProcess: currentTgePro,
        });
    }

    const reset = ()=>{
        //setCurrentCoinId(coinId);
        setCurrentOnlyFavorite(false);
        setCurrentOnlyHeld(false);
        setCurrentPriceRange([0, maxCoinPrice]);
        setCurrentMarketCapRange([0, maxMarketCap]);
        setCurrentFavoriteRange([0, maxFavorite]);
        setCurrentTgePro([0, 100]);
    }
    
    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <CustomIcon width={25} className="mr5" height={25} imgName={'Button/UI_Button_ThreeTieRod_01'}
                            name={'Button/UI_Button_ThreeTieRod_01'}/> {AutoStrangeRule(t('FILTERS'))}
            </div>
            <div className={s.filterCon}>
                
                <div className={s.item}>
                    <Select
                        value={currentCoinId}
                        defaultValue={currentCoinId}
                        onChange={(val) => {
                            setCurrentCoinId(val);
                        }}
                        popupClassName="liveSettingOption"
                        className='memeClubSel'
                        open={openCoinId}
                        onClick={()=>{
                            setOpenCoinId(!openCoinId)
                        }}
                        suffixIcon={
                            <div className="memeClubSelSuffixIcon">
                                <CustomIcon imgName={'Button/UI_Button_Triangle-White_Down_01'} width={10} height={7}></CustomIcon>
                            </div>
                        }
                    >
                        {
                            showCoinList?.map(item => {
                                const { value, label, icon } = item;
                                return (
                                    <Option value={value} key={value}>
                                        <CustomIcon imgName={`Picture/${icon}`} className="mr5" width={18} height={18}></CustomIcon>
                                        {label}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                    
                    {
                        showPrice && 
                        <>
                            <div className={`mt10 flex_center_between`}>
                                <div className={s.label}>{t('Price')} &nbsp;</div>
                                <div className={s.rankArea}>
                                    <span className="color-white">{`$${currentPriceRange[0]}`}</span>
                                    <span className="fs12"> {t('to')} </span>
                                    <span className="color-white">{`$${currentPriceRange[1]}${currentPriceRange[1] === maxCoinPrice ? '+':''}`}</span>
                                </div>
                            </div>
                            <div className={s.slider}>
                                <Slider
                                    defaultValue={currentPriceRange}
                                    className="hireSlider commonSlider"
                                    range={true}
                                    min={0}
                                    max={maxCoinPrice}
                                    value={currentPriceRange}
                                    step={Number((maxCoinPrice / 100).toFixed(3))}
                                    onChange={(value)=>{
                                        setCurrentPriceRange(value);
                                    }}/>
                            </div>
                        </>
                    }
                    {
                        isMarketCap ? (
                            <>
                                <div className={`mt10 flex_center_between`}>
                                    <div className={s.label}>{t('Market Cap')} &nbsp;</div>
                                    <div className={s.rankArea}>
                                        <span className="color-white">{`$${convertWalletBalance(currentMarketCapRange[0])}`}</span>
                                        <span className="fs12"> {t('to')} </span>
                                        <span className="color-white">{`$${convertWalletBalance(currentMarketCapRange[1])}${currentMarketCapRange[1] === maxMarketCap ? '+':''}`}</span>
                                    </div>
                                </div>
                                <div className={s.slider}>
                                    <Slider
                                        defaultValue={currentMarketCapRange}
                                        className="hireSlider commonSlider"
                                        range={true}
                                        min={0}
                                        max={maxMarketCap}
                                        value={currentMarketCapRange}
                                        step={maxMarketCap / 100}
                                        onChange={(value)=>{
                                            setCurrentMarketCapRange(value);
                                        }}/>
                                </div>
                            </>
                        ) :(
                            <>
                                <div className={`mt10 flex_center_between`}>
                                    <div className={s.label}>{t('Liquidity Pool')} &nbsp;</div>
                                    <div className={s.rankArea}>
                                        <span className="color-white">{`$${convertWalletBalance(currentMarketCapRange[0])}`}</span>
                                        <span className="fs12"> {t('to')} </span>
                                        <span className="color-white">{`$${convertWalletBalance(currentMarketCapRange[1])}${currentMarketCapRange[1] === maxMarketCap ? '+':''}`}</span>
                                    </div>
                                </div>
                                <div className={s.slider}>
                                    <Slider
                                        defaultValue={currentMarketCapRange}
                                        className="hireSlider commonSlider"
                                        range={true}
                                        min={0}
                                        max={maxMarketCap}
                                        value={currentMarketCapRange}
                                        step={maxMarketCap / 100}
                                        onChange={(value)=>{
                                            setCurrentMarketCapRange(value);
                                        }}/>
                                </div>
                            </>
                        )}
                    
                    
                    {/* <div className={`mt10 flex_center_start`}>
                        <div className={s.label}>{t('Favorited')} &nbsp;</div>
                        <div className={s.rankArea}>
                            <span className="color-white">{`${currentFavoriteRange[0]}`}</span>
                            <span className="fs12"> {t('to')} </span>
                            <span className="color-white">{`${currentFavoriteRange[1]}${currentFavoriteRange[1] === maxFavorite ? '+':''}`}</span>
                        </div>
                    </div>
                    <div className={s.slider}>
                        <Slider
                            defaultValue={currentFavoriteRange}
                            className="hireSlider commonSlider"
                            range={true}
                            min={0}
                            max={maxFavorite}
                            value={currentFavoriteRange}
                            step={maxFavorite / 100}
                            onChange={(value)=>{
                                setCurrentFavoriteRange(value);
                            }}/>
                    </div> */}
                    {
                        showTgeProcess && 
                        <>
                            <div className={`mt10 flex_center_start`}>
                                <div className={s.label}>{t('TGE Process')} &nbsp;</div>
                                <div className={s.rankArea}>
                                    <span className="color-white">{`${currentTgePro[0]}`}</span>
                                    <span className="fs12"> {t('to')} </span>
                                    <span className="color-white">{`${currentTgePro[1]}`}</span>
                                </div>
                            </div>
                            <div className={s.slider}>
                                <Slider
                                    defaultValue={currentTgePro}
                                    className="hireSlider commonSlider"
                                    range={true}
                                    min={0}
                                    max={100}
                                    value={currentTgePro}
                                    step={1}
                                    onChange={(value)=>{
                                        setCurrentTgePro(value);
                                    }}/>
                            </div>
                        </>
                    }
                </div>

                <div className="mb10">
                    <Checkbox 
                        onChange={(e)=>{
                            setCurrentOnlyFavorite(e.target.checked);
                        }} 
                        className="hireCheckBox"  
                        checked={currentOnlyFavorite}>
                        <span className="ml10">{t('Only Favorite')}</span>
                    </Checkbox>
                </div>
                <div className="mb10">
                    <Checkbox 
                        onChange={(e)=>{
                            setCurrentOnlyHeld(e.target.checked);
                        }} 
                        className="hireCheckBox"  
                        checked={currentOnlyHeld}>
                        <span className="ml10">{t('Only Held')}</span>
                    </Checkbox>
                </div>
            </div>
            <div className={s.bottom}>
                <AntButton className={s.black} onClick={reset}>{t('RESET')}</AntButton>
                <AntButton className="btn_public" onClick={apply}>{t('APPLY')}</AntButton>
            </div>

        </div>
    )
}
export default memo(MemeClubFilter);
