import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import Circle from "../Circle";
import {Button, message, Modal} from "antd";
import {
    get_trivia_run_data, post_trivia_answer_choice,
    getAvatarFromProfile
} from "@/utils/common";
import VoteBtn from "../VoteBtn";
import CustomIcon from "../../../common/CustomIcon";
import ModelEliminated from "src/model/Votes/ModelEliminated";
import ModelVoteRevivalHave from "@/model/Votes/ModelVoteRevivalHave";
import ModelVoteRevivalTips from "@/model/Votes/ModelVoteRevivalTips";
import AntModal from "@/common/AntModal";

const ModelVote = (
    {
        setOpenVotePanel,
        needUpdateVoteInfo,
        hostId,
        lastVoteInfo,
        curentOtherUserInfo,
        setShowRank,
        setRankAward,
        curRtcInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const [state, setState] = useState(-1);
    const forceShow = useRef(false);
    const timeoutVote = () => {
        forceShow.current = true;
        setBtnDisableOption(true);
        btnDisableOptionRef.current = true;
        head_message(1, 3000)
        if (questionOptionsSelectedRef.current < 0 && !isPaused.current) {
            setTimeout(() => {
                head_message(3, 3000)
            }, 3000);
        }

        setTimeout(() => {
            exitVote(true);
        }, 5000);
    }

    const timeWill = () => {
        forceShow.current = true;
    }

    const [count, setCount] = useState(-1);
    const timer = useRef();
    useEffect(() => {
        timer.current = setInterval(() => {
            setCount((count) => {
                if (count === 0) timeoutVote();
                if (count <= 3 && count > 0) timeWill();
                if (count < 0) {
                    return -1;
                }
                return count - 1;
            });
        }, 1000);
        return () => {
            clearInterval(timer.current);
        };
    }, []);

    const timerVoteInfo = useRef();
    useEffect(() => {
        timerVoteInfo.current = setInterval(() => {
            updateVoteInfo();
        }, 5000);

        return () => {
            clearInterval(timerVoteInfo.current);
        };
    }, []);

    useEffect(() => {
        updateVoteInfo();
    }, [needUpdateVoteInfo]);

    const exitVote = (forceClose) => {
        if (forceClose || !forceShow.current) {
            setOpenVotePanel(false);
        }
    }

    const parseVoteInfo = (data) => {

        if (data.isHost) exitVote();
        setVoteType(data?.type);
        switch (data.state) {
            case 0:
                exitVote();
                break;
            case 11:
                setState(11);
                setShowQuestion(data.currQ);
                setQuestionOptionsSelected(data.currQChoiceId);
                questionOptionsSelectedRef.current = data.currQChoiceId;
                isMeetCond.current = data.isMeetCond;
                isPaused.current = data.isPhased;
                setCorrectMax(data.currQCorrectMax);
                setCorrectNow(data.currQCorrectNow);
                setShowQuestionAnswer(false);
                if (data.currQChoiceId !== -1 || data.isPhased) {
                    setBtnDisableOption(true);
                    btnDisableOptionRef.current = true;
                } else if (data.currQCorrectMax > 0 && data.currQCorrectNow !== -1 && data.currQCorrectNow >= data.currQCorrectMax) {
                    setBtnDisableOption(true);
                    btnDisableOptionRef.current = true;
                } else {
                    setBtnDisableOption(false);
                    btnDisableOptionRef.current = false;
                }
                break;
            case 14:
                setState(14);
                setShowQuestion(data.currQ);
                setQuestionOptionsSelected(data.currQChoiceId);
                questionOptionsSelectedRef.current = data.currQChoiceId;
                setBtnDisableOption(true);
                btnDisableOptionRef.current = true;
                setShowQuestionAnswer(true);
                setCorrectMax(data.currQCorrectMax);
                setCorrectNow(data.currQCorrectNow);
                setRevivalCardHoldCount(data.revivalCardHoldCount);
                setRevivalCardCountRemain(data.revivalCardMaxCount - data.revivalCardUsedCount);
                setRevivalCardMaxCount(data.revivalCardMaxCount);

                if (data.currQCorrect) {
                    if (!hasBeenShowHeadMCorrect.current) {
                        hasBeenShowHeadMCorrect.current = true;
                        setTimeout(() => {
                            head_message(2, 3000);
                        }, 2000);
                    }
                }

                if (data.currQPhased) {
                    if (!hasBeenShowEliminated.current) {
                        hasBeenShowEliminated.current = true;
                        if (data?.revivalCardCanUse) {
                            if (data?.revivalCardHoldCount > 0) {
                                setTimeout(() => {
                                    setShowRevivalUse(true);
                                }, 500);
                            } else {
                                setTimeout(() => {
                                    setShowRevivalTips(true);
                                }, 1500);
                            }
                        } else {
                            setTimeout(() => {
                                setShowEliminated(true);
                            }, 2000);
                        }
                    }
                }
                break;
            case 16:
                setState(16);
                setShowQuestion(null);
                setRankAward(data.awardInfo);
                setShowRank(true);
                break;
            default:
                exitVote();
                break;
        }

        setCountExe(data.currQCountDown);
    }

    const setCountExe = (sec) => {
        if (totalTime.current === -1) totalTime.current = sec;
        if (sec > totalTime.current) totalTime.current = sec;
        setCount((count) => {
            if (count === -1 && sec > 0) {
                return sec;
            } else {
                return count;
            }
        });
    }

    const updateVoteInfo = (callback = () => {
    }, fallcallback = () => {
    }) => {
        get_trivia_run_data(hostId).then(data => {
            parseVoteInfo(data);
            callback();
        }).catch(e => {
            console.log(e);
            fallcallback();
        })
    }

    useEffect(() => {
        updateVoteInfo();
    }, []);

    useEffect(() => {
        if (lastVoteInfo && JSON.stringify(lastVoteInfo) !== '{}') {
            parseVoteInfo(lastVoteInfo);
        }
    }, [lastVoteInfo]);

    const setShowQuestion = (question) => {
        setQuestionId(question?.id);
        setQuestionTitle(question?.title);
        setQuestionImg(question?.image);
        setQuestionOptions(question?.options);
        setQuestionType(question?.image ? 2 : 1);
        let sum = 0;
        question?.options?.map((item) => {
            sum += item.choiceNum;
        });
        setSumOptionsNum(sum);
    }

    const [questionId, setQuestionId] = useState('');
    const [questionTitle, setQuestionTitle] = useState('');
    const [questionImg, setQuestionImg] = useState('');
    const [questionOptions, setQuestionOptions] = useState([]);
    const [btnDisableOption, setBtnDisableOption] = useState(false);
    const btnDisableOptionRef = useRef(false);
    const [questionOptionsLoading, setQuestionOptionsLoading] = useState(-1);
    const [questionOptionsSelected, setQuestionOptionsSelected] = useState(-1);
    const questionOptionsSelectedRef = useRef(-1);
    const isPaused = useRef(false);
    const isMeetCond = useRef(false);
    const [showQuestionAnswer, setShowQuestionAnswer] = useState(false);
    const [questionType, setQuestionType] = useState(1);

    const totalTime = useRef(-1);
    const [voteType, setVoteType] = useState(-1);
    const hasBeenShowEliminated = useRef(false);
    const hasBeenShowHeadMCorrect = useRef(false);

    const [showEliminated, setShowEliminated] = useState(false);
    const [showRevivalUse, setShowRevivalUse] = useState(false);
    const [showRevivalTips, setShowRevivalTips] = useState(false);

    const [headType, setHeadType] = useState(0);
    const [sumOptionsNum, setSumOptionsNum] = useState(0);

    const [correctMax, setCorrectMax] = useState(-1);
    const [correctNow, setCorrectNow] = useState(-1);

    const [revivalCardHoldCount, setRevivalCardHoldCount] = useState(0);
    const [revivalCardCountRemain, setRevivalCardCountRemain] = useState(0);
    const [revivalCardMaxCount, setRevivalCardMaxCount] = useState(0);

    const head_message = (value, t) => {
        setHeadType(value);
        setTimeout(() => {
            setHeadType(0);
        }, t)
    }

    const clickOption = (option) => {
        if (btnDisableOptionRef.current) return;

        setQuestionOptionsSelected(option);
        setQuestionOptionsLoading(option);
        setBtnDisableOption(true);
        btnDisableOptionRef.current = true;
        post_trivia_answer_choice(hostId, questionId, option).then(data => {
            updateVoteInfo(() => {
                setQuestionOptionsLoading(-1);
            }, () => {
                setQuestionOptionsLoading(-1);
            });
        }).catch(e => {
            setQuestionOptionsSelected(-1);
            setQuestionOptionsLoading(-1);
            if (e === 'SpotsFilled') {
                updateVoteInfo();
                setBtnDisableOption(true);
                btnDisableOptionRef.current = true;
                head_message(6, 1000);
            } else {
                message.error("Network Issue (" + e + ") retrying...");
                post_trivia_answer_choice(hostId, questionId, option).then(data => {
                    updateVoteInfo(() => {
                        setQuestionOptionsLoading(-1);
                    }, () => {
                        setQuestionOptionsLoading(-1);
                    });
                }).catch(e => {
                    setBtnDisableOption(false);
                    btnDisableOptionRef.current = false;
                    message.error("Network Issue (" + e + ")");
                });
            }
        });
    }

    const {onlineNum, heat} = useMemo(() => {
        return curRtcInfo || {}
    }, [curRtcInfo])

    const {profile} = useMemo(() => {
        return curentOtherUserInfo || {}
    }, [curentOtherUserInfo])

    const disabledClick = () => {
        if (!isMeetCond.current) {
            head_message(5, 1000);
        } else if (isPaused.current) {
            head_message(4, 1000);
        } else if (questionOptionsSelectedRef.current === -1 && correctMax > 0 && correctNow >= correctMax) {
            head_message(6, 1000);
        }
    }

    return (
        <div className={s.wrap}>
            <div className={s.viewer}>
                {/*<CustomIcon width={16} height={16} imgName={'viewer'} name={'viewer'}/>*/}
                {/*<div>{onlineNum}</div>*/}
            </div>
            {
                headType !== 0 ?
                    <div className={`${s.headTips} ${s['head_type' + headType]}`}>
                        {headType === 1 && t('Times up!')}
                        {headType === 2 && t('Correct!')}
                        {headType === 3 && t('Incorrect!')}
                        {headType === 4 && t('Eliminated')}
                        {headType === 5 && t('No Access')}
                        {headType === 6 && t('Spots Filled')}
                    </div> :
                    // <>
                    // <div className={`${s.headTips} ${s['head_type1']}`}>
                    // Times up!
                    // </div>
                    <Circle avatarUrl={getAvatarFromProfile(profile, 1) || getAvatarFromProfile(profile, 0)}
                            leftTime={count} totalTime={totalTime.current}/>
                // </>
            }
            {correctMax > 0 && correctNow !== -1 && <>
                <div className={`w100p fs15 color-white tlc fb ${s.lightningBackground}`}><CustomIcon width={15}
                                                                                                      height={15}
                                                                                                      imgName={'meter'}
                                                                                                      name={'meter'}/> {t('Lightning Round')}
                </div>
                <div className="w100p fs12 color-gray tlc mb10">{t('Remaining Winning Spots')}: <span
                    className="color-green">{correctNow}/{correctMax}</span></div>
            </>}
            <div className="w100p fs20 color-white fb">
                {questionTitle}
            </div>
            {questionImg && <div className={s.imgWrap}>
                <img width="100%" srcSet={questionImg}/>
            </div>}
            <div className={`${s.questionWrap} ${s['vode_type' + voteType]}`}>
                {
                    questionOptions?.map(
                        (item) => {
                            return (
                                <VoteBtn
                                    key={item.id}
                                    btnType={showQuestionAnswer ? 1 : 0}
                                    voteType={questionType}
                                    persent={sumOptionsNum === 0 ? '0%' : (item.choiceNum / sumOptionsNum * 100) + '%'}
                                    answerStr={item.text}
                                    num={item.choiceNum}
                                    answerReslut={showQuestionAnswer && item.isAnswer}
                                    disabled={btnDisableOption}
                                    selected={questionOptionsSelected === item.id}
                                    loading={questionOptionsLoading === item.id}
                                    onClick={() => {
                                        clickOption(item.id)
                                    }}
                                    onDisabledClick={() => {
                                        disabledClick();
                                    }}
                                />
                            )
                        }
                    )
                }
            </div>
            <AntModal
                width='330px'
                className="confirmModalWrap noticeModal noCloseBtn"
                centered={true}
                open={showEliminated}
                destroyOnClose={true}
                zIndex={4000}
                onOk={() => setShowEliminated(false)}
                onCancel={() => setShowEliminated(false)}
            >
                <ModelEliminated setShowEliminated={setShowEliminated}/>
            </AntModal>
            <AntModal
                width='330px'
                className="confirmModalWrap noticeModal noCloseBtn"
                centered={true}
                open={showRevivalUse}
                destroyOnClose={true}
                zIndex={4000}
                onOk={() => setShowRevivalUse(false)}
                onCancel={() => setShowRevivalUse(false)}
            >
                <ModelVoteRevivalHave
                    setShowRevivalUse={setShowRevivalUse}
                    revivalCardHoldCount={revivalCardHoldCount}
                    revivalCardCountRemain={revivalCardCountRemain}
                    revivalCardMaxCount={revivalCardMaxCount}
                    hostId={hostId}
                />
            </AntModal>
            <AntModal
                width='330px'
                className="confirmModalWrap noticeModal noCloseBtn"
                centered={true}
                open={showRevivalTips}
                destroyOnClose={true}
                zIndex={4000}
                onOk={() => setShowRevivalTips(false)}
                onCancel={() => setShowRevivalTips(false)}
            >
                <ModelVoteRevivalTips setShowRevivalTips={setShowRevivalTips}/>
            </AntModal>
        </div>
    )
}
export default memo(ModelVote);
