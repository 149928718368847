import React, {memo, useEffect, useMemo, useState, useRef} from "react";
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import { Input, Radio, Space, Select, Upload, Modal, Checkbox, Spin } from "antd";
import AntButton from "@/common/AntButton";
import InfiniteScroll from "react-infinite-scroll-component";
import s from "./index.module.less"
import {
    getearninfo,
    getProfile,
    formatNumberWithUnit,
    getfriendtrade_rank_selfdata,
    getAvatarFromUserInfo,
    filterErrorCode,
    getQuotes,
    getTokenBalanceOf,
    numberClamp,
    logoutSelf,
    referralcode_earndata,
    getAvatarFromProfile,
    displayFilter,
    stringToNumber,
    addJoinChartPre,
    copyFn,
    convertWalletBalance,
} from "@/utils/common";
import {
    coinData,
    creatClub,
    moment_buyresult,
    moment_prebuyprice,
    getReferralcodeMomenttradeReflist,
} from "@/utils/momentClub";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import {
    creatMomentClub,
    getMomentEntropyFee,
} from "@/utils/momentClubWeb3";
import { 
    gameMomentClubExplorePath,
    gameMomentClubUserDetailPath,
} from "@/routes/config";
import AvatarInfo from "@/common/AvatarInfo";
import { BigNumber, ethers } from "ethers";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate} from "react-router-dom";
import ButtonFactory from "@/common/ButtonFactory";
import MainHeader from "@/common/MainHeader";
import Countdown from "src/common/Countdown";
import ModalMomentReferralShare from "@/model/ModalMomentReferralShare";
import AntModal from "@/common/AntModal";

const MomentReferralEarn = (
    {
        userInfo,
        walletBalanceList,
        setWalletBalanceList,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [earnData, setEarnData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [nextCursor, setNextCursor] = useState(0);
    const [totalPortfolio, setTotalPortfolio] = useState(0);
    const [portfolio, setPortfolio] = useState(0);
    const [openSharePanel, setOpenSharePanel] = useState(false);
    const [tradeDataList, setTradeDataList] = useState(false);
    const {logout} = usePrivy();

    const { 
        walletAddr,
        userId,
        platformData,
        selfData,
    } = useMemo(() => {
        return userInfo || {};
    }, [userInfo])

    const {
        referralCode
    } = useMemo(() => {
        return selfData || {};
    }, [selfData])

    const inviteCode = useMemo(() => {
        return addJoinChartPre(referralCode);
    }, [referralCode])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo);
    }, [platformData, userInfo])

    const selfProfile = useMemo(() => {
        return getProfile(platformData);
    }, [platformData])

    const list = useMemo(() => {
        if (tradeDataList?.dataList){
            return tradeDataList?.dataList;
        }
        else{
            return [];
        }
    }, [tradeDataList])

    const showEarn = useMemo(() => {
        return earnData?.showEarn || false
    }, [earnData])

    const getearninfoFn = () => {
        getearninfo().then(res => {
            setTotalPortfolio(res.totalPortfolio);
            setPortfolio(res.portfolio);
        }).catch(e => {
            console.log(e);
        })
    }

    const getEarnList = (cursor = 0) => {
        getReferralcodeMomenttradeReflist(cursor).then(res => {
            if(cursor === 0){
                setTradeDataList(res);
            }else{
                setTradeDataList({
                    ...res,
                    dataList: [
                        ...tradeDataList?.dataList,
                        ...res?.dataList
                    ]
                })
            }
            setNextCursor(res?.nextCursor);
        }).catch(e => {
            console.log("[referralcode_momenttrade_reflist error]", e);
        })
    }

    const getEarnData = (cursor = 0) => {
        referralcode_earndata(cursor).then(res => {
            setEarnData(res);
        }).catch(e => {
            console.log("[referralcode_earndata error]", e);
        })
    }

    const getMore = () => {
        getEarnList(nextCursor);
    }

    useEffect(() => {
        getearninfoFn();
        getEarnData();
        getEarnList();
    }, [])
    
    return (
        <div className={`${s.wrap} navBg flex_center_between_col`}>
            <div className={`w100p h100p flex_center_start_col overflowHidden`}>
                <MainHeader title={t('Referral')} headerType={3} isGameHeader={true} hasRightMenu={false} rightMenuIcon ={"Button/UI_Button-D_Home_On"}></MainHeader>
                <div className={`${s.title} flex_center_start`}>
                    <div className={`${s.titleText}`}>
                        {t('Invite friends & earn')}
                    </div>
                </div>
                <div className={`${s.tableTitle} flex_center_between`}>
                    <div className={`${s.infoPart}`}>
                        <div className={`${s.clubTitle}`}>
                            {t('Clubs')}
                        </div>
                    </div>
                    <div className={`${s.tradingPart}`}>
                        <div className={`${s.tradingPartTitle}`}>
                            {t('Trading Vol')}
                        </div>
                    </div>
                    <div className={`${s.earningPart}`}>
                        <div className={`${s.tradingPartTitle}`}>
                            {t('Fees Earning')}
                        </div>
                    </div>
                </div>
                <div className={`${s.tableContent} flex_center_start_col`}>
                    <div className={`${s.list} flex_center_start_col`} id="rankList">                           
                        <InfiniteScroll
                            dataLength={list?.length || 0}
                            next={getMore}
                            hasMore={nextCursor !== -1}
                            loader={<div
                                style={{
                                    textAlign: "center",
                                    width: "100%",
                                }}
                            ><Spin /></div>}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                            }}
                            scrollableTarget="rankList"
                        >
                            <>
                                <div className={`${s.item}`}>
                                    <div className={`${s.infoPart}`} onClick={() => {
                                        //navigate(`${publicChatsPath}/${userId}`)
                                    }}>
                                        <AvatarInfo size={36} src={avatarUrl}/>
                                        <CustomIcon 
                                            className={`${s.selfCrown}`}
                                            width={18} 
                                            height={18}
                                            imgName={'Picture/UI_Picture_Crown_02'}
                                        />
                                        <div className={`${s.clubName}`}>
                                            {displayFilter(selfProfile?.displayName)}
                                        </div>
                                    </div>
                                    <div className={`${s.tradingPart}`}>
                                        <div className={`${s.tradingPartText}`}>
                                            {`${convertWalletBalance(earnData?.momentTradeEth||0)} ETH`}
                                        </div>
                                    </div>
                                    <div className={`${s.earningPart}`}>
                                        <div className={`${s.tradingPartText}`}>
                                            {`${convertWalletBalance(earnData?.momentEarnEth||0)} ETH`}
                                        </div>
                                    </div>
                                </div>
                                {
                                    list.map((item, ind) => {
                                        const {
                                            userId, 
                                            earnEth, 
                                            profile, 
                                            tradeVol
                                        } = item || {};
                                        const {
                                            displayName,
                                            avatarUrl
                                        } = profile || {};
                                        return (
                                            <div className={s.item} key={userId}>
                                                <div className={s.infoPart} onClick={() => {
                                                    navigate(`${gameMomentClubUserDetailPath}/${userId}`);
                                                }}>
                                                    <AvatarInfo size={36}
                                                                src={getAvatarFromProfile(profile)}/>
                                                    <div className={`${s.clubName}`}>
                                                        {displayFilter(displayName)}
                                                    </div>
                                                </div>
                                                <div className={`${s.tradingPart}`}>
                                                    <div className={`${s.tradingPartText}`}>
                                                        {`${convertWalletBalance(tradeVol||0)} ETH`}
                                                    </div>
                                                </div>
                                                <div className={`${s.earningPart}`}>
                                                    <div className={`${s.tradingPartText}`}>
                                                        {`${convertWalletBalance(earnEth||0)} ETH`}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
            <div className={`${s.bottomRoot} flex_center_start_col`}>
                <div className={`${s.bottomTitle}`}>
                    {t('My Referral Code')}
                </div>
                <div className={`${s.referralCodeLine} flex_center_center`}>
                    <div className={`${s.referralCode}`}>{inviteCode}</div>
                    <CustomIcon className="" width={18} onClick={() => copyFn(inviteCode)} height={18}
                                imgName={'Picture/UI_Picture_Icon_Copy_01'}/>
                </div>
                <AntButton 
                    className={`${s.shareBtn} btn_public flex_center_center`} 
                    onClick={() =>{
                        setOpenSharePanel(true);
                    }}>
                    <div className={`flex_center_center`}>
                        <CustomIcon width={24} height={24} className={`mr6`} imgName={'Button/UI_Button_Share_02'}/>
                        <div className={`fs20 fb color-gray`}>
                            {t('Share on X')}
                        </div>
                    </div>
                </AntButton>
            </div>
            <AntModal
                width='330px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={openSharePanel}
                onOk={() => setOpenSharePanel(false)}
                onCancel={() => setOpenSharePanel(false)}
            >
                <ModalMomentReferralShare 
                    inviteCode={inviteCode}
                    closeHandle={() => {
                        setOpenSharePanel(false);
                    }}/>
            </AntModal>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MomentReferralEarn));
