import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox, Slider} from 'antd';
import s from "./index.module.less";
import {
    getfriendtrade_rank_selfdata,
    convertWalletBalance,
    searchuser,
    getAvatarFromProfile,
    joinChat,
    displayFilter,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import SearchUser from "src/common/SearchUser";
import AvatarInfo from "src/common/AvatarInfo";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import {
    mainNftNftPanelPath,
    mainClubAllClubsPath,
    balancePath,
    publicWishCreatePath,
} from "@/routes/config";

const SearchMomenttClubList = (
    {
        userInfo,
        setUserInfo,
        earnInfo,
        searchCursor,
        searchResult,
        linkTo,
        favoriteFn,
        getMoreSearchResult = () => {
        },
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();



 

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        }}>
            <div className={s.serarchPanel}>
                {
                    searchResult?.length > 0 ? searchResult.map((item, ind) => {
                        const {clubUserId,isFavorite,profile,creatorProfile} = item
                        const {
                            displayName,
                            profileDeepLink,
                            accountName,
                            platformType
                        } = profile;
                        const avatarUrl = getAvatarFromProfile(item.profile);
                        const creatorAvatarUrl = getAvatarFromProfile(creatorProfile);
                        return (
                            <div className={s.item} key={ind} onClick={() => {
                                // openOtherTransRecord(item?.userId, item?.accountType)
                                linkTo(clubUserId)
                            }}>
                                <div className={s.avatar}>
                                    <AvatarInfo size={40} src={avatarUrl}/>
                                </div>
                                <div className={s.info}>
                                    <div className={s.name}>
                                        {displayFilter(displayName)}
                                    </div>
                                    <div className="df alc jcc">
                                        {t('Created by')}
                                        <AvatarInfo size={16} className="mr5 ml5" src={creatorAvatarUrl}/>
                                        {displayFilter(creatorProfile?.displayName)}
                                    </div>
                                </div>
                                <div className={s.favorite}>
                                    <CustomIcon
                                        onClick={() => {
                                            favoriteFn(clubUserId, !isFavorite, 2)
                                        }}
                                        imgName={isFavorite ? 'Picture/UI_Picture_Collect_01' : 'Picture/UI_Picture_Uncollect_01'}
                                        className="mr5" width={19} height={19}></CustomIcon>
                                </div>
                            </div>
                        )
                    }) : <div className="flex_center_start_col">
                        {t('No search results')}
                    </div>
                }
                <div className={`${s.loadMore} ${searchCursor !== -1 ? s.show : ''}`}
                     onClick={getMoreSearchResult}>{t('Load More')}</div>
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(SearchMomenttClubList));
