import s from './index.module.less';
import {memo} from 'react';
import {Progress} from "antd";
import AvatarInfo from '../../../common/AvatarInfo';


const colors = {'0%': '#D60036', '100%': '#8200C7'};

const Circle = ({leftTime, totalTime, avatarUrl}) => {
    const progress = Math.floor((totalTime - leftTime) / totalTime * 100);
    const formatHandle = (percent) => {
        if (leftTime > 5 && avatarUrl) {
            return <AvatarInfo noBorder={true} size={56} src={avatarUrl}/>
        } else {
            return leftTime
        }
    }
    return (
        <div className={`${s.circle}`}>
            {leftTime === -1 ?
                <AvatarInfo noBorder={true} size={56} src={avatarUrl}/> :
                <Progress size={60} type="circle" strokeWidth={6} percent={progress} strokeColor={colors}
                          format={formatHandle}/>
            }
        </div>
    );
};

export default memo(Circle);
