import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../common/CustomIcon";
import {Button, message, Spin, Input} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {TwitterShareButton} from 'react-twitter-embed';

function copyFn(text) {
    copyText(text, function () {
            message.success({content: 'copied!'})
        }
    );
}

const copyText = function (button, content, success) {
    if (!button) {
        return;
    }

    if (typeof content == 'function') {
        success = content;
        content = null;
    }

    success = success || function () {
    };

    // 是否降级使用
    var isFallback = !navigator.clipboard;

    if (typeof button == 'string' && !content) {
        if (content === false) {
            isFallback = true;
        }
        content = button;
        button = null;
    }

    var eleTextarea = document.querySelector('#tempTextarea');
    if (!eleTextarea && isFallback) {
        eleTextarea = document.createElement('textarea');
        eleTextarea.style.width = 0;
        eleTextarea.style.position = 'fixed';
        eleTextarea.style.left = '-999px';
        eleTextarea.style.top = '10px';
        eleTextarea.setAttribute('readonly', 'readonly');
        document.body.appendChild(eleTextarea);
    }


    var funCopy = function (text, callback) {
        callback = callback || function () {
        };

        if (!isFallback) {
            navigator.clipboard.writeText(text).then(function () {
                callback();
                // 成功回调
                success(text);
            }, function () {
                // 禁止写入剪切板后使用兜底方法
                copyText(text, false);
                callback();
                // 成功回调
                success(text);
            });

            return;
        }

        eleTextarea.value = text;
        eleTextarea.select();
        document.execCommand('copy', true);

        callback();
        // 成功回调
        success(text);
    };

    var strStyle = '.text-popup { animation: textPopup 1s both; -ms-transform: translateY(-20px); color: #01cf97; user-select: none; white-space: nowrap; position: absolute; z-index: 99; }@keyframes textPopup {0%, 100% { opacity: 0; } 5% { opacity: 1; } 100% { transform: translateY(-50px); }}'

    var eleStyle = document.querySelector('#popupStyle');
    if (!eleStyle) {
        eleStyle = document.createElement('style');
        eleStyle.id = 'popupStyle';
        eleStyle.innerHTML = strStyle;
        document.head.appendChild(eleStyle);
    }

    if (!button) {
        funCopy(content);
        return;
    }

    // 事件绑定
    button.addEventListener('click', function (event) {
        var strCopy = content;
        if (content && content.tagName) {
            strCopy = content.textContent || content.value;
        }
        // 复制的文字内容
        if (!strCopy) {
            return;
        }

        funCopy(strCopy, function () {
            copyText.tips(event);
        });
    });
};


const ModelLinkTiktok = ({
                             tweetCodeObj,
                             loading
                         }) => {
    const {t, i18n} = useTranslation();
    const copyWrap = useRef(null);
    // const [loading,setLoading] = useState(true);
    const [check, setCheck] = useState(false);
    const [tryAgain, setTryAgain] = useState(false);
    const [videoUrl, setVideoUrl] = useState('')
    const copy = () => {
        copyFn(copyWrap.current.innerText);
    }
    const {tweetCode, topic, codePrefix, inviteCodes} = useMemo(() => {
        return tweetCodeObj || {}
    }, [tweetCodeObj])


    const videoUrlHandler = (event) => {
        setVideoUrl(event.target.value)
    }
    const shareWords = [
        "Try out @TURNUPDOTXYZ, The First Creator Network. It’s Super Fun.",
        "#TURNUP #SocialGaming #IYKYK #points"
    ]
    return (
        <div className={s.wrap}>
            <div className="fs15 color-white fb">
                {t('Authorize your TIKTOK Account')}
            </div>
            {/*<div className="fs14 color-white mtb20">
          Please follow these quick steps:
      </div>*/}
            <div className="fs15 color-white mtb10">{t('Step 1: Text Body')}</div>
            <div className={s.copyContent}>
                <Spin
                    className="loadPageSpin"
                    indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: 60,
                            }}
                        />
                    }
                    tip={t('Loading...')}
                    spinning={loading}
                >
                    <div ref={copyWrap}>
                        {shareWords[0]}<br/>
                        {shareWords[1]} {codePrefix}{tweetCode}
                    </div>

                </Spin>


                <div className={s.copy} onClick={copy}>
                    <CustomIcon width={18} height={18} imgName={'Button/UI_Button_CopyNumberWordName_01'}
                                name={'Button/UI_Button_CopyNumberWordName_01'}/>
                </div>
            </div>
            <div className="fs15 color-white mtb10">{t('Step 2: Post a video on TIKTOK')}</div>
            <div
                className="fs12">{t('Navigate to your TlKTOK account, paste the copied text into a new post, upload any video and then click \'Post\'')}</div>
            <div className="fs15 color-white mtb10">{t('Step 3: .Paste the URL of the video')}</div>
            <div className={s.urlInput}>
                <Input
                    placeholder="URL"
                    value={videoUrl}
                    className={s.input}
                    onChange={videoUrlHandler}
                />
            </div>
            {/* <div className="color-red">Note: If you fail to tweet within 5 minutes, you’ll need to restart the authorization process</div> */}
            {tryAgain &&
                <div className="color-red mtb10">{t('This may take to a minute. Please wait patiently.')}</div>}
            {/* <AntButton shape="round" className={`btn_public ${s.okey} mt20`} onClick={()=>{ window.location.href = "https://twitter.com/"; }}>
        POST NOW
      </AntButton>
      <AntButton shape="round" loading={check} className={`btn_white mt20`} onClick={()=>{
        setCheck(true);
        setTryAgain(false);
        setTimeout(()=>{
          setTryAgain(true);
          setCheck(false)
        },30000)
        }}>
        {check?'Verifying':'Complete Verification'}
      </AntButton> */}
            {tweetCode &&
                <TwitterShareButton
                    onLoad={() => {
                    }}
                    options={{
                        size: 'large',
                        text: `${shareWords[0]}\n${shareWords[1]} ${codePrefix}${tweetCode}`
                    }}
                    url={' '}
                />
            }
        </div>
    )
}
export default memo(ModelLinkTiktok);
