import React, {useEffect, useState} from "react";
import s from "./index.module.less";
import CustomIcon from "src/common/CustomIcon";
import ButtonFactory from "src/common/ButtonFactory";
import {
    numberClamp,
} from "@/utils/common";

const InputNumber = (
    {
        value,
        min,
        max,
        onChange,
        type = 1,
    }
) => {
    useEffect(() => {
        setCount(parseInt(value));
    }, [value]);

    const [count, setCount] = useState(parseInt(value));
    const onMinsFn = () => {
        setCount((v) => {
            if (v <= min) return min;
            onChange(v - 1)
            return v - 1
        });
    };
    const onPlusFn = () => {
        setCount((value) => {
            if (value >= max) return max;
            onChange(value + 1)
            return value + 1;
        });
    };
    const onChangeFn = () => {
        setCount((value) => {
            if (isNaN(value)) return min;
            if (value < min) return min;
            if (value > max) return max;

            onChange(value);

            return value;
        });
    };

    const onInput = (e) => {
        let value = parseInt(e.target.value);
        setCount(value);
        onChangeFn();
    };

    return (
        <div className={`${s["input-number"]}`}>
            {
                type === 2 ?
                    <CustomIcon
                        width={29}
                        height={29}
                        imgName={"Button/UI_Button_Plus_02"}
                        onClick={onMinsFn}
                    /> :
                    <CustomIcon
                        width={25}
                        height={25}
                        imgName={"Button/UI_Button_Minus-White-Irr_01"}
                        onClick={onMinsFn}
                    />
            }
            <input className={`${type === 2 ? 'momentFont':''}`} type="number" value={count} onChange={onInput}/>
            {
                type === 2 ?
                    <CustomIcon
                        width={29}
                        height={29}
                        imgName={"Button/UI_Button_Plus_01"}
                        onClick={onPlusFn}
                    /> :
                    <CustomIcon
                        width={25}
                        height={25}
                        imgName={"Button/UI_Button_Plus-Yellow-Irr_01"}
                        onClick={onPlusFn}
                    />
            }
        </div>
    );
};

export default InputNumber;
