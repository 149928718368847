import { mainNftBidDetailPath } from "@/routes/config";
import http from "@/utils/axios";
import {url} from "@/utils/configUri";
import BID_NFT from "@/utils/json/BID_NFT.json";

const maxBatchNumber = 20
// import {ethers} from "ethers";
// import {abi_core_pool, abi_token} from "@/utils/abi";
// import {getConfig} from "@/utils/common";
// import {parseString} from "superagent/lib/client";

// const _url = "0x4c16786896Fa2b2DEA0277973f2A384F4E15b61C"

const nftDetail = (navigate, {tokenAddr, tokenId}) => {
    navigate(`${mainNftBidDetailPath}/${tokenAddr}/${tokenId}`)
}

const getRarityBgColor = (l) => {
    const i = BID_NFT.find(item => item.rarity === l)
    return i?.bg
}

const getRarityFontColor = (l) => {
    const i = BID_NFT.find(item => item.rarity === l)
    return i?.color
}

const getRarityImageBg = (rarity) => {
    const i = BID_NFT.find(item => item.rarity === rarity);
    return i?.iconBg;
}

const getRarityRankIcon = (rarity) => {
    const i = BID_NFT.find(item => item.rarity === rarity);
    return `Picture/${i?.rankIcon}`;
}

const coinObj = {
    1: {
        iconName: 'Picture/UI_Picture-Currency_MATIC_01',
        unit: '$MATIC'
    },
    0: {
        iconName: 'Picture/UI_Picture-Currency_LFG_01',
        unit: '$LFG'
    }
}

const getRarityText = (l) => {
    const i = BID_NFT.find(item => item.rarity === l)
    return i?.label
}

const getnft_self_bidlist = (onlyLeading,cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_self_bidlist, {
            onlyLeading,
            cursor: cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getnft_self_claimlist = (cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_self_claimlist, {
            cursor: cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getnft_auction_batchcfg = () => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_auction_batchcfg, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getnft_auction_batchinfo = () => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_auction_batchinfo, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getnft_self_history = (seriesType, cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_self_history, {
            seriesType,
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getnft_detail = ({tokenAddr, tokenId}) => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_detail, {
            tokenAddr,
            tokenId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const set_nftavatar = ({tokenAddr, tokenId}) => {
    return new Promise((resolve, reject) => {
        http('post', url.set_nftavatar, {
            tokenAddr,
            tokenId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getnft_auction = (searchStatus, rankRange, maticPriceRange, lfgPriceRange, includeTopPriceIsSelf, isAsc = false, cursor = 0) => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_auction, {
            searchStatus: searchStatus,
            rankRange: rankRange,
            maticPriceRange: maticPriceRange,
            lfgPriceRange: lfgPriceRange,
            includeTopPriceIsSelf,
            isAsc: isAsc,
            cursor: cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getnft_bid_status = ({tokenAddr, tokenId}) => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_bid_status, {
            tokenAddr,
            tokenId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getnft_bid_history = ({tokenAddr, tokenId, cursor}) => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_bid_history, {
            tokenAddr,
            tokenId,
            cursor: cursor || 0
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getnft_ownList = () => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_ownList, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const bidData = [{
    tokenId: 79,
    tokenAddr: '0x123123',
    succ: false,
}]
const getBidStatus = (hash) => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_batch_bidresult, {
            transHash: hash
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        // resolve([])
    })


}

const getNftAuctionRefund = () => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_auction_refund, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        // resolve([])
    })


}

const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export {
    getnft_self_bidlist,
    getnft_self_claimlist,
    getnft_auction,
    getnft_bid_history,
    getnft_bid_status,
    getnft_detail,
    set_nftavatar,
    getnft_self_history,
    getRarityText,
    nftDetail,
    getRarityBgColor,
    getRarityFontColor,
    getRarityRankIcon,
    getnft_ownList,
    getBidStatus,
    delay,
    coinObj,
    maxBatchNumber,
    getRarityImageBg,
    getnft_auction_batchcfg,
    getnft_auction_batchinfo,
    getNftAuctionRefund
}