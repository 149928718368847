import React, {memo, useCallback, useEffect, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import {connect} from "react-redux";
import {useState} from "react";
import {Input, Button, Modal,} from 'antd';
import {useLocation, useNavigate} from "react-router-dom";
import {
    referralcode_bind,
    joinChartPre,
    getProfile,
    taPush, 
    getConfig, 
    countDownTime,
    getuserinfo,
} from "@/utils/common";
import bg from "@/assets/images/bgImg/UI_Background_FenXiang_New.webp";
import {
    balancePath, 
    mainHomePath,
    gameMomentClubDetailPath,
    gameMomentClubExplorePath,
} from "@/routes/config";
import { 
    defaultMemeSwitchVideoParams, 
    momentClubList 
} from "@/utils/momentClub";
import AntButton from "@/common/AntButton";

const MomentReferral = (
    {
        userInfo,
        setUserInfo,
        defaultInviteCode,
        setCurrentMomentClubId,
        hideNSFW,
        setSwitchVideoList,
        setSwitchVideoListCursor,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [binded, setBinded] = useState(userInfo?.selfData?.bindReferralCode || false);
    const [inviteCode, setInviteCode] = useState(defaultInviteCode);
    const [bindResulet, setBindResulet] = useState(false);
    const [bindResuletStr, setBindResuletStr] = useState('');
    const {
        selfData,
        userId,
    } = useMemo(() => {
        return userInfo || {};
    }, [userInfo]);

    const {
        bindReferralCode
    } = useMemo(() => {
        return selfData || {};
    }, [selfData])

    useEffect(() => {
        if (defaultInviteCode) {
            setInviteCode(defaultInviteCode);
        }
    }, [defaultInviteCode])

    useEffect(() => {
        if (bindReferralCode){
            goMomentClub();
        }
    }, [bindReferralCode])

    const goMomentClub = () => {
        let params = defaultMemeSwitchVideoParams;
        momentClubList({
            rankType: params.rankType,
            coinId: params.coinId,
            priceRange: params.priceRange,
            progressRange: params.progressRange,
            lpRange: params.lpRange,
            onlyFavorite: params.onlyFavorite,
            onlyHeld: params.onlyHeld,
            onlyLive: params.onlyLive,
            isAsc: params.isAsc,
            cursor: ""
        }).then(res => {
            let _list = []; 
            if (res?.clubList?.length > 0){
                for (let i = 0; i < res?.clubList?.length; i++){
                    let stateCode = res?.clubList[i].profile.momentAuditCode;
                    if (stateCode !== 2 || (stateCode === 2 && !hideNSFW)){
                        _list.push({
                            clubId: res?.clubList[i].userId,
                            momentAuditCode: stateCode,
                            videoId: res?.clubList[i].videoId,
                            videoUrlMap: res?.clubList[i].videoUrlMap,
                            coverUrl: res?.clubList[i].profile.avatarUrl,
                            videoOriHeight: res?.clubList[i].videoOriHeight,
                            videoOriWidth: res?.clubList[i].videoOriWidth,
                            videoFirstFrameUrl: res?.clubList[i].videoFirstFrameUrl,
                        });
                    }
                }
            }
            let index = -1;
            if (_list?.length > 0){
                for (let i = 0; i < _list?.length; i++){
                    if (_list[i]?.momentAuditCode !== 3){
                        index = i;
                        break;
                    }
                }
            }
            setSwitchVideoList(_list);
            if (res?.nextCursor === ""){
                setSwitchVideoListCursor("-1");
            }
            else{
                setSwitchVideoListCursor(res?.nextCursor);
            }
            if (index >= 0){
                setCurrentMomentClubId(_list[index].clubId);
                navigate(`${gameMomentClubDetailPath}`,{replace:true,state: {backPath:gameMomentClubExplorePath}});
            }
            else{
                navigate(gameMomentClubExplorePath);
            }
        }).catch(e => {
            console.error(e);
            navigate(gameMomentClubExplorePath);
        })
    }

    const updateUserInfo = () => {
        getuserinfo(userId).then(res => {
            setUserInfo(res);
            goMomentClub();
        }).catch(e => {
            console.log(e);
            setTimeout(() => {
                updateUserInfo();
            }, 1000);
        })
    }

    useEffect(() => {
        if (binded){
            updateUserInfo();
        }
    }, [binded])

    const inviteCodeHandler = (event) => {
        let val = event.target.value;
        setInviteCode(val)
    }

    const bindInvite = () => {
        let val = inviteCode.toLocaleLowerCase();
        if (val.indexOf(joinChartPre) === 0) {
            val = val.substr(1)
        }

        if (val.indexOf('turnup.so/' + joinChartPre) === 0) {
            val = 'turnup.so/' + val.substr(11)
        }

        taPush('bind_invite_code', {code: val})

        val && referralcode_bind(val).then(res => {
            setBindResulet(true);
            setBindResuletStr('bind success!');
            setBinded(true);
            //!userInfo?.selfData?.buyFirstKey && navigate(balancePath);
        }).catch(e => {
            setBindResulet(false);
            setBindResuletStr('Invalid Code!')
        })
    }

    return (
        <div className={`${s.modalWrap} navBg flex_center_center_col`}>
            <div className={`${s.title}`}>
                {t('Unlock with Your Code')}
            </div>
            <div className={`${s.modal} flex_center_center_col`}>
                <div className={`${s.content} flex_center_center_col`}>
                    <div className={`${s.referralCodeLabel} flex_center_start_col`}>
                        <div className={`${s.labelText}`}>
                            {t('Moment Launcher is in Beta')}
                        </div>
                        <div className={`${s.labelText}`}>
                            {t('Please enter your referral code to get started')}
                        </div>
                        <div className={`${s.link} flex_center_between`}>
                            <div className={s.shareCon} onClick={() => {
                                window.location.href = "https://t.me/justTURNUP"
                            }}>
                                <CustomIcon width={44} height={44} imgName={'Button/UI_Button_White_TurnUp_02'}/>
                                <div className={`${s.iconText}`}>TURNUP</div>
                            </div>
                            <div className={`${s.heightLine}`}>
                            </div>
                            <div className={s.shareCon} onClick={() => {
                                window.location.href = "https://twitter.com/turnupdotxyz"
                            }}>
                                <CustomIcon width={44} height={44} imgName={'Button/UI_Button_White_Twitter_01'}/>
                                <div className={`${s.iconText}`}>@TURNUPDOTXYZ</div>
                            </div>
                        </div>
                        <div className={`${s.inviteCode} flex_center_start_col`}>
                            <div className={`${s.tips} ${bindResuletStr ? s.show : ''} flex_center_center`}>
                                {bindResuletStr}
                            </div>
                            <Input
                                placeholder={t('Enter Referral Code')}
                                value={inviteCode}
                                className={s.input}
                                onChange={inviteCodeHandler}
                            />
                            <div className={`${s.inviteBtn} flex_center_start_col`}>
                                <AntButton className={`${s.commonBtn} btn_public`} onClick={bindInvite}>
                                    <div className={`fs16 fb color-gray`}>
                                        {t('PROCEED')}
                                    </div>
                                </AntButton>
                                {/*<AntButton className={`${s.commonBtn} btn_public mt10`} onClick={() =>{
                                    navigate(mainHomePath, {replace: true})
                                }}>
                                    {t('Skip')}
                                </AntButton>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        hideNSFW: app.hideNSFW,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MomentReferral));
