import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button} from "antd";
import CustomIcon from "../../common/CustomIcon";
import imgUrl from "@/assets/images/FingersCrossed.png"

const CrackModelAttackIntro = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className={s.wrap}>
            <div className={s.img}>
                <img src={'./UI/Picture/UI_Picture_FingersCrossed_01.png'}/>
            </div>
            <div className={`${s.text} mt70`}>
                <div className="fs14 color-white fl tll">
                    {t('Power is determined by the total value of all Members and Joined.')}
                </div>
                <div className="fs14 fl tll color-yellow">
                    {t('X = Total value of all Members')}
                </div>
                <div className="fs14 fl tll color-yellow">
                    {t('Y = Total value of all Joined')}
                </div>
                <div className="fs14 fl tll color-yellow">
                    {t('For Wish accounts')}
                </div>
                <div className="fs14 fl tll color-yellow">
                    {t('N = Total supply number of my key')}
                </div>
                <div className="fs14 fl tll color-yellow">
                    {t('T = X * min(N/50,1) + Y')}
                </div>
                <div className="fs14 fl tll color-yellow">
                    {t('Power=√((50 * T)) * 7')}
                </div>
                <div className="fs14 color-white fl tll">
                    {t('Power Tips 01')}
                </div>
                <div className="fs14 color-white fl tll">
                    {t('Power Tips 02')}
                </div>
                <div className="fs14 color-white fl tll">
                    {t('The higher the Power, the higher probability of a successful Loot.')}
                </div>
                <div className="fs14 color-white fl tll">
                    {t('Once your club engages in a battle, it will be in a cool down period for xx mins.', {time: 5})}
                </div>
            </div>

        </div>
    )
}
export default memo(CrackModelAttackIntro);
