import React, {memo, useRef} from "react";
import s from "./index.module.less";

import {Button, message} from "antd";
import CustomIcon from "../../common/CustomIcon";
import {TwitterShareButton} from 'react-twitter-embed';
import {useTranslation, Trans} from 'react-i18next';

function copyFn(text) {
    copyText(text, function () {
            message.success({content: 'copied!'})
        }
    );
}

const copyText = function (button, content, success) {
    if (!button) {
        return;
    }

    if (typeof content == 'function') {
        success = content;
        content = null;
    }

    success = success || function () {
    };

    // 是否降级使用
    var isFallback = !navigator.clipboard;

    if (typeof button == 'string' && !content) {
        if (content === false) {
            isFallback = true;
        }
        content = button;
        button = null;
    }

    var eleTextarea = document.querySelector('#tempTextarea');
    if (!eleTextarea && isFallback) {
        eleTextarea = document.createElement('textarea');
        eleTextarea.style.width = 0;
        eleTextarea.style.position = 'fixed';
        eleTextarea.style.left = '-999px';
        eleTextarea.style.top = '10px';
        eleTextarea.setAttribute('readonly', 'readonly');
        document.body.appendChild(eleTextarea);
    }


    var funCopy = function (text, callback) {
        callback = callback || function () {
        };

        if (!isFallback) {
            navigator.clipboard.writeText(text).then(function () {
                callback();
                // 成功回调
                success(text);
            }, function () {
                // 禁止写入剪切板后使用兜底方法
                copyText(text, false);
                callback();
                // 成功回调
                success(text);
            });

            return;
        }

        eleTextarea.value = text;
        eleTextarea.select();
        document.execCommand('copy', true);

        callback();
        // 成功回调
        success(text);
    };

    var strStyle = '.text-popup { animation: textPopup 1s both; -ms-transform: translateY(-20px); color: #01cf97; user-select: none; white-space: nowrap; position: absolute; z-index: 99; }@keyframes textPopup {0%, 100% { opacity: 0; } 5% { opacity: 1; } 100% { transform: translateY(-50px); }}'

    var eleStyle = document.querySelector('#popupStyle');
    if (!eleStyle) {
        eleStyle = document.createElement('style');
        eleStyle.id = 'popupStyle';
        eleStyle.innerHTML = strStyle;
        document.head.appendChild(eleStyle);
    }

    if (!button) {
        funCopy(content);
        return;
    }

    // 事件绑定
    button.addEventListener('click', function (event) {
        var strCopy = content;
        if (content && content.tagName) {
            strCopy = content.textContent || content.value;
        }
        // 复制的文字内容
        if (!strCopy) {
            return;
        }

        funCopy(strCopy, function () {
            copyText.tips(event);
        });
    });
};

const ModalMomentReferralShare = (
    {
        inviteCode,
        closeHandle = () => {},
    }
) => {
    const {t, i18n} = useTranslation();
    const copyWrap = useRef(null);
    const copy = () => {
        copyFn(copyWrap.current.innerText);
    }

    const shareWords = [];
    
    shareWords.push(t('I\'m earning points by capturing and trading life-changing #Moments on @TURNUPDOTXYZ, the decentralized network where social capital becomes a game.'));
    shareWords.push(`${t('Use my referral code to join and start earning too:')} ${inviteCode}`);
    shareWords.push(`${t('Ready to #TURNUP your #Moments?')}`);

    return (
        <div className={s.wrap}>
            <div className="fs18 fb color-EEEEEE" style={{lineHeight: '1.0', marginTop: '34px'}}>
                {t('Share Your Referral Code')}
            </div>
            <div className="fs18 fb color-EEEEEE" style={{lineHeight: '1.0'}}>
                {t('Through X(Twitter)')}
            </div>
            <div className={`${s.copyContent} flex_center_start_col`}>
                <div ref={copyWrap} className={`${s.copyText}`}>
                    {shareWords[0]}<br/><br/>
                    {shareWords[1]}<br/><br/>
                    {shareWords[2]}
                </div>
                <div className={`${s.copy} flex_center_center`} onClick={copy}>
                    <CustomIcon width={25} height={25} imgName={'Picture/UI_Picture_Icon_Copy_01'}/>
                </div>

            </div>
            <TwitterShareButton
                onLoad={() => {
                }}
                options={{
                    size: 'large',
                    text: `${shareWords[0]}\n\n${shareWords[1]}\n\n${shareWords[2]}`
                }}
                url={' '}
            />
            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeHandle();
                }} />
        </div>
    )
}
export default memo(ModalMomentReferralShare);