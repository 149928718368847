import React, {memo, useCallback, useEffect, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../CustomIcon";
import {connect} from "react-redux";
import {useState} from "react";
import {Input, Button, Modal,} from 'antd';
import {useLocation, useNavigate} from "react-router-dom";

import {
    referralcode_bind,
    joinChartPre,
    getProfile, taPush, getConfig, countDownTime
} from "@/utils/common";
import bg from "@/assets/images/bgImg/UI_Background_FenXiang_New.webp";
import {balancePath, mainHomePath} from "@/routes/config";
import AntButton from "@/common/AntButton";

const addJoinChartPre = (code) => {
    const index = code?.indexOf('turnup.so/') || -1
    if (index > -1) {

        return 'turnup.so/' + joinChartPre + code.substr(10)
    }
    return code
}
const Referral = (
    {
        userInfo,
        defaultInviteCode
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [binded, setBinded] = useState(userInfo?.selfData?.bindReferralCode || false)
    // const [binded, setBinded] = useState(false)
    const [inviteCode, setInviteCode] = useState(defaultInviteCode);
    const [bindResulet, setBindResulet] = useState(false);
    const [bindResuletStr, setBindResuletStr] = useState('');
    //const [triviaState, setTriviaState] = useState(-1);
    const startTime = useRef(0);
    //const [timeStr,setTimeStr] = useState('');

    useEffect(() => {
        if (defaultInviteCode) {
            setInviteCode(defaultInviteCode)
        }
    }, [defaultInviteCode])

    useEffect(() => {
        setBinded(userInfo?.selfData?.bindReferralCode)
    }, [userInfo])

    // useEffect(() => {
    //     if (!binded) {
    //         const profile = getProfile(userInfo?.platformData);
    //         if (profile?.profileDeepLink) {
    //             navigate(mainHomePath, {replace: true});
    //         }
    //     } else {
    //         if (!userInfo?.selfData?.buyFirstKey) {
    //             navigate(balancePath);
    //         } else {
    //             navigate(mainHomePath, {replace: true});
    //         }
    //     }

    // }, [userInfo, binded])

    /*useEffect(()=>{
        getConfig().then(data=>{
            startTime.current = data?.triviaStartTime * 1000;
            let timestamp = new Date().getTime();
            if(timestamp <= startTime.current){
                setTriviaState(1);
                setTimeStr(countDownTime(startTime.current, 2));
            } else {
                setTriviaState(0);
            }
        });

        const timer = setInterval(()=>{
            setTimeStr(countDownTime(startTime.current, 2));
        },1000);

        return ()=>{
            clearInterval(timer)
        }
    },[]);*/

    const inviteCodeHandler = (event) => {
        let val = event.target.value;

        setInviteCode(val)
    }

    const bindInvite = () => {
        let val = inviteCode.toLocaleLowerCase();
        if (val.indexOf(joinChartPre) === 0) {
            val = val.substr(1)
        }

        if (val.indexOf('turnup.so/' + joinChartPre) === 0) {
            val = 'turnup.so/' + val.substr(11)
        }

        taPush('bind_invite_code', {code: val})

        val && referralcode_bind(val).then(res => {
            // setRInviteCode(addJoinChartPre(res?.referralCode))
            setBindResulet(true);
            setBindResuletStr('bind success!')
            setBinded(true)
            !userInfo?.selfData?.buyFirstKey && navigate(balancePath);
        }).catch(e => {
            setBindResulet(false);
            setBindResuletStr('Invalid Code!')
            // message.success({content:'bind failed!'})
        })
    }

    return (
        <div className={`${s.modalWrap} navBg`} style={{
            backgroundImage: `url(${bg})`, backgroundPosition: 'center',
            backgroundSize: 'cover'
        }}>
            
            <div className={s.modal}>
            
                <div className={`${s.content} ${s.jcc}`}>
                    <div className={s.logo}>
                        <CustomIcon width={120} height={120} imgName={'Picture/UI_Picture-turnup_logo'}
                        name={'Picture/UI_Picture-turnup_logo'}/>
                    </div>
                    <div className={s.referralCodeLabel}>
                        <div className={s['content-area']}>
                            <div className={`${s['content-label']} tac`}>
                                {t('Get a Referral Code to Start')}
                            </div>
                            <div className={s.link}>
                                <div className={s.shareCon} onClick={() => {
                                    window.location.href = "https://t.me/justTURNUP"
                                }}>
                                    <CustomIcon width={41} height={41} imgName={'Button/UI_Button_Ball-White_TurnUp'}
                                                name={'Button/UI_Button_Ball-White_TurnUp'}/>
                                    <div className="mt8 fs15">TURNUP</div>
                                </div>
                                <div className={s.shareCon} onClick={() => {
                                    window.location.href = "https://twitter.com/turnupdotxyz"
                                }}>
                                    <CustomIcon width={41} height={41}
                                                imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                name={'Button/UI_Button_Ball-White_Twitter_On'}/>
                                    <div className="mt8 fs15">@TURNUPDOTXYZ</div>
                                </div>
                            </div>
                        </div>
                        <div className={s.inviteCode}>
                            <div className={`${s.tips} ${bindResuletStr ? s.show : ''}`}>
                                <CustomIcon width={16} className="mr5" height={16}
                                            imgName={bindResulet ? 'trivia_yes' : 'trivia_no'}
                                            name={bindResulet ? 'trivia_yes' : 'trivia_no'}/>
                                {bindResuletStr}
                            </div>
                            <Input
                                placeholder={t('Enter Referral Code')}
                                value={inviteCode}
                                className={s.input}
                                onChange={inviteCodeHandler}
                            />
                            <div className={s.inviteBtn}>
                                <AntButton className={`btn_public`} onClick={bindInvite}>
                                    {t('PROCEED')}
                                </AntButton>
                                {/*<AntButton className={`btn_public mt10`} onClick={() =>{
                                    navigate(mainHomePath, {replace: true})
                                }}>
                                    {t('Skip')}
                                </AntButton>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${s.skipPart} skipPart flex_center_end`} onClick={() =>{
                    navigate(mainHomePath, {replace: true})
                }}>
                    <div className={`${s.skipText}`}>
                        {t('Skip')}
                    </div>
                    <CustomIcon 
                        className="mr20" 
                        imgName={`Picture/UI_Picture_Arrows_Skip`} 
                        width={40}
                        height={40}></CustomIcon>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Referral));
