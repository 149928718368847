import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../common/CustomIcon";
import {
    convertWalletBalance, 
    numberClamp, 
    getChainTokenInfoById,
} from "@/utils/common";
import { 
    coinList,
    getMemeKingTgeWinnerList,
} from "@/utils/memeClub";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Slider, Checkbox, Select, Spin} from "antd";

const { Option } = Select;

const ModalKingOfTgeInfo = (
    {
        closeHandle
    }
) => {
    const {t, i18n} = useTranslation();

    const [pageIndex, setPageIndex] = useState(0);
    const [currentCoinId, setCurrentCoinId] = useState(coinList?.length > 0 ? coinList[0].value : 0);
    const [kotHistoryList, setKotHistoryList] = useState([]);
    const [nextCursor, setNextCursor] = useState(-1);

    const [openCoinList, setOpenCoinList] = useState(false);

    const getHistoryList = (coinId, cursor) =>{
        getMemeKingTgeWinnerList({coinId: coinId, cursor: cursor}).then(res=>{
            let winnerList = res?.winnerList;
            const _list = cursor === 0 ? winnerList || [] : [
                ...kotHistoryList,
                ...(winnerList || [])
            ]
            setKotHistoryList(_list);
            setNextCursor(res?.nextCursor);
        }).catch(e=>{
            console.error(e);
        })
    }

    useEffect(() => {
        if (pageIndex === 3){
            getHistoryList(currentCoinId, 0);
        }
    }, [pageIndex])

    const getMoreKotHistoryFn = () => {
        getHistoryList(currentCoinId, nextCursor);
    }

    return (
        <div className={s.wrap}>
            <div className={s.pageControl}>
                <div className={`${s.prev}`} onClick={() => {
                    if (pageIndex > 0){
                        setPageIndex(numberClamp(pageIndex - 1, 0, 3));
                    }
                }}>
                    { pageIndex > 0 && <CustomIcon
                        className={s.prevBtn}
                        width={14}
                        height={28}
                        imgName={"Button/UI_Button_Arrow_Left_01"}
                    ></CustomIcon>}
                </div>
                <div className={s.next} onClick={() => {
                    if (pageIndex < 3){
                        setPageIndex(numberClamp(pageIndex + 1, 0, 3));
                    }
                }}>
                    { pageIndex < 3 && <CustomIcon
                        className={s.nextBtn}
                        width={14}
                        height={28}
                        imgName={"Button/UI_Button_Arrow_Right_01"}
                    ></CustomIcon>}
                </div>
            </div>
            <div className={`${s.title} flex_center_start_col`}>
                {
                    pageIndex === 3 ?
                    <div className={`${s.titleText}`}>
                        {t('King of TGE History')}
                    </div> :
                    <div className={`${s.titleText}`}>
                        {t('King of TGE Info')}
                    </div> 
                }
            </div>
            <div className={s.topPart}>
                <div className={`${s.content} flex_center_start_col`}>
                    {
                        pageIndex === 0 &&
                        <>
                            <div className={`${s.pageImage} mt15`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_KingOfTGE_NotePic_01.png)`}}>
                            </div>
                            <div className={`${s.commonText} ${s.pageTitle} mt20 lh1`}>
                                <span className={`fs22 fb`}>{t('Liquidity Securement')}</span>
                                {/* <span className={`fs12`} style={{marginTop:`4px`, marginLeft:`4px`}}>{t('(Token Generation Event)')}</span> */}
                            </div>
                            <div className={`${s.commonText} ${s.pageText}`}>
                                <Trans
                                    i18nKey="king of tge page 12"
                                    defaults="Once the purchase progress of the <yellow>Meme Packs</yellow> reaches 100%, <yellow>TGE</yellow> event will occur. The assets used to purchase the card packs will serve as the <yellow>Liquidity Pool</yellow> for DNTs."
                                    components={{
                                        yellow: <span className="color-yellow"></span> 
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        pageIndex === 1 &&
                        <>
                            <div className={`${s.pageImage} mt15`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_KingOfTGE_NotePic_02.png)`}}>
                            </div>
                            <div className={`${s.commonText} ${s.pageTitle} mt20 fs22 fb`}>
                                {t('Prize From Pack Trade Fee')}
                            </div>
                            <div className={`${s.commonText} ${s.pageText}`}>
                                <Trans
                                    i18nKey="king of tge page 1"
                                    defaults="Every Meme Pack trade will have 2% fee. And 50% of these will be accumulated into the <yellow>TGE King Prize Pool</yellow> waiting for award Prize Pool will be cleared after each award distribution."
                                    components={{
                                        yellow: <span className="color-yellow"></span> 
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        pageIndex === 2 &&
                        <>
                            <div className={`${s.pageImage} mt15`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_KingOfTGE_NotePic_03.png)`}}>
                            </div>
                            <div className={`${s.commonText} ${s.pageTitle} mt20 fs22 fb`}>
                                {t('TGE King Win The Prize')}
                            </div>
                            <div className={`${s.commonText} ${s.pageText}`}>
                                <Trans
                                    i18nKey="king of tge page 2"
                                    defaults="In each currency group, Meme DNT with the highest <yellow>TGE Liquidity Pool</yellow> in will win the Prize. Every king must maintain this position for over <yellow>8 hours</yellow> to win."
                                    components={{
                                        yellow: <span className="color-yellow"></span> 
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        pageIndex === 3 &&
                        <>
                            <div className={`${s.historyHead} flex_center_between`}>
                                <div className={`${s.historySelectTitle}`}>
                                    {t('Chain and Currency')}
                                </div>
                                <div className={`${s.historySelectRoot}`}>
                                    <Select
                                        value={currentCoinId}
                                        defaultValue={currentCoinId}
                                        onChange={(val) => {
                                            setCurrentCoinId(val);
                                            setKotHistoryList([]);
                                            getHistoryList(val, 0);
                                        }}
                                        popupClassName="liveSettingOption"
                                        className='memeClubSel'
                                        open={openCoinList}
                                        onClick={()=>{
                                            setOpenCoinList(!openCoinList);
                                        }}
                                        suffixIcon={
                                            <div className="memeClubSelSuffixIcon">
                                                <CustomIcon imgName={'Button/UI_Button_Triangle-White_Down_01'} width={10} height={7}></CustomIcon>
                                            </div>
                                        }
                                    >
                                        {
                                            coinList?.map(item => {
                                                const { value, label, icon } = item;
                                                return (
                                                    <Option value={value} key={value} className={`flex_center_center`}>
                                                        <CustomIcon imgName={`Picture/${icon}`} className="mr5" width={18} height={18}></CustomIcon>
                                                        {label}
                                                    </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className={`${s.historyList}`} id="historyList">
                                {
                                    kotHistoryList?.length > 0 ?
                                        <InfiniteScroll
                                            dataLength={kotHistoryList?.length || 0}
                                            next={()=>{
                                                getMoreKotHistoryFn();
                                            }}
                                            hasMore={nextCursor !== -1}
                                            loader={<div
                                                style={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                }}
                                            ><Spin /></div>}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "0",
                                                width: "100%"
                                            }}
                                            endMessage={
                                                <div style={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                    color: "var(--color-gray)",
                                                    fontSize: '12px'
                                                }}></div>
                                            }
                                            scrollableTarget={'historyList'}
                                        >
                                            {
                                                kotHistoryList.map((item, ind)=>{
                                                    const {
                                                        beWinnerTime,
                                                        clubProfile,
                                                        clubSymbolImageUrl,
                                                        clubSymbolName,
                                                        clubUserId,
                                                        isSendReward,
                                                        liquidity,
                                                        rewardPool,
                                                    } = item || {};
                                                    const chainTokenInfo = getChainTokenInfoById(currentCoinId);
                                                    return (
                                                        <div className={`${s.historyItem} flex_center_between`}>
                                                            <div className={`${s.historyItemLeft} flex_center_start`}>
                                                                <div className={`${s.historyItemAvatar}`} style={{backgroundImage: `url(${clubSymbolImageUrl})`}}>
                                                                </div>
                                                                <div className={`${s.basicInfoRoot}`}>
                                                                    <div className={`${s.displayName}`}>
                                                                        {clubProfile?.displayName}
                                                                    </div>
                                                                    <div className={`${s.symbolName}`}>
                                                                        {`$${clubSymbolName}`}
                                                                    </div>
                                                                    <div className={`${s.liquidityPart} flex_center_start`}>
                                                                        <div className={`${s.liquidityText}`}>
                                                                            {t('Liquidity')}
                                                                        </div>
                                                                        <CustomIcon imgName={`Picture/${chainTokenInfo?.icon}`} className="ml2 mr2" width={12} height={12}/>
                                                                        <div className={`${s.liquidityText}`}>
                                                                            {convertWalletBalance(liquidity)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`${s.historyItemRight} flex_center_center_col`}>
                                                                <div className={`${s.rewardPoolRoot} flex_center_end`}>
                                                                    <div className={`${s.rewardPoolText}`}>
                                                                        {t('Won')}
                                                                    </div>
                                                                    <CustomIcon imgName={`Picture/${chainTokenInfo?.icon}`} className="ml2 mr2" width={16} height={16}/>
                                                                    <div className={`${s.rewardPoolText}`}>
                                                                        {convertWalletBalance(rewardPool)}
                                                                    </div>
                                                                </div>
                                                                {
                                                                    isSendReward ?
                                                                        <div className={`${s.completePart}`}>
                                                                            {t('Completed')}
                                                                        </div> :
                                                                        <div className={`${s.completePart} color-yellow`}>
                                                                            {t('Pending')}
                                                                        </div>
                                                                }
                                                                <div className={`${s.settlementTime}`}>
                                                                    {(new Date(beWinnerTime * 1000)).format("yyyy/MM/dd hh:mm:ss")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }    
                                        </InfiniteScroll> :
                                        <div className={`${s.historyListNoData} flex_center_center`}>
                                            <CustomIcon width={111} height={95} className={`mb30`} imgName={'Picture/UI_Picture_NoData_Empty_01'}></CustomIcon>
                                            {t('No Data')}
                                        </div>
                                }   
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className={s.pageRoot}>
                <div
                    className={`${pageIndex === 0 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(0);
                    }}>
                </div>
                <div
                    className={`${pageIndex === 1 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(1);
                    }}>
                </div>
                <div
                    className={`${pageIndex === 2 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(2);
                    }}>
                </div>
                <div
                    className={`${pageIndex === 3 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(3);
                    }}>
                </div>
            </div>
        </div>
    )
}
export default memo(ModalKingOfTgeInfo);
