import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import efficiency from "@/utils/json/EfficiencyConfig.json"
import globalProRate from "@/utils/json/GlobalProductionConfig.json"
import {Button} from "antd";
import CustomIcon from "../../common/CustomIcon";
import imgUrl from "@/assets/images/FingersCrossed.png"
import {vCoinString} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';

const HireModelEarnIntro = (
    {
        info
    }
) => {
    const {t, i18n} = useTranslation();
    const {total, myEarn, employeeAllKeyNum, holdingKeyNum, isSelfClub, isWish = false, isLoot = false} = info;
    let totalS = isLoot ? total : (isWish ? total : total * 1.25);
    const sharePersent = isWish ? 0.5 : 0.4;
    return (
        <div className={s.wrap}>
            <div className="fs13">{isWish ? t('Wish Sharing Tips') : t('Common Club Sharing Tips')}</div>
            <div className={s.content}>
                <div className={s.userItem}>
                    <div className={`${s.lable}`}>
                        {t('Total Club Earnings')}
                    </div>
                    <div className={s.persent}></div>
                    <div className={s.val}>
                        <div className={s.rightIcon}>
                            <CustomIcon width={14} height={14} className="mr5"
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            {vCoinString(totalS)}
                        </div>
                    </div>
                </div>
                <div className={s.userItem}>
                    <div className={`${s.lable}`}>
                        {t('Manager’s Share')}
                    </div>
                    <div className={s.persent}>{sharePersent * 100}%</div>
                    <div className={s.val}>
                        <div className={s.rightIcon}>
                            <CustomIcon width={14} height={14} className="mr5"
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            {vCoinString(totalS * sharePersent)}
                        </div>
                    </div>
                </div>
                {
                    !isWish && <div className={s.userItem}>
                        <div className={`${s.lable}`}>
                            {t('Club Owner\'s Share')}
                        </div>
                        <div className={s.persent}>{isSelfClub ? '20%' : '--'}</div>
                        <div className={s.val}>
                            <div className={s.rightIcon}>
                                <CustomIcon width={14} height={14} className="mr5"
                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                            name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                {isSelfClub ? vCoinString(totalS * 0.2) : '--'}
                            </div>
                        </div>
                    </div>
                }
                {
                    employeeAllKeyNum>0 && 
                    <div className={s.userItem}>
                    <div className={`${s.lable}`}>
                        {t('Key Holders Share')}<br/>
                        {t('You Hold xx Keys', {num: `${holdingKeyNum}\/${employeeAllKeyNum}`})}
                    </div>
                    <div
                        className={s.persent}>{vCoinString(holdingKeyNum / employeeAllKeyNum * (sharePersent * 100) )} %
                    </div>
                    <div className={s.val}>
                        <div className={s.rightIcon}>
                            <CustomIcon width={14} height={14} className="mr5"
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            {vCoinString(totalS * sharePersent * (holdingKeyNum / employeeAllKeyNum))}
                        </div>
                    </div>
                </div>
                }
                
                <div className={`${s.userItem} ${s.topborder}`}>
                    <div className={`${s.lable}`}>
                        {t('My Earnings')}
                    </div>
                    <div
                        className={s.persent}>{vCoinString((holdingKeyNum / employeeAllKeyNum * (sharePersent * 100) || 0) + (isSelfClub ? 20 : 0) + (sharePersent * 100))}%
                    </div>
                    {/* <div
                        className={s.persent}>{vCoinString((isSelfClub ? 20 : 0) + (sharePersent * 100))}%
                    </div> */}
                    <div className={s.val}>
                        <div className={s.rightIcon}>
                            <CustomIcon width={14} height={14} className="mr5"
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            {vCoinString(myEarn)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default memo(HireModelEarnIntro);
