import {memo, useEffect, useMemo, useState} from "react";
import s from "./index.module.less";
import {Button} from "antd";
import AvatarInfo from "@/common/AvatarInfo";
import CustomIcon from "src/common/CustomIcon";
import {useTranslation} from "react-i18next";
import WorkConfig from '@/utils/json/WorkConfig.json'
import {
    displayFilter,
    post_dispatch_detail,
    post_dispatch_detail_mutil,
    post_dispatch_emplyees,
    post_dispatch_emplyees_mutil,
    vCoinString,
    getAvatarFromProfile
} from "@/utils/common";
import ButtonFactory from "src/common/ButtonFactory";
import {getClubsList} from "@/utils/mission";

const ModelEarnAll = (
    {
        userInfo,
        closeHandle,
        refreshPortfolio,
        workType,
    }
) => {
    const {t} = useTranslation();
    const [user2WorkId, setUser2WorkId] = useState({});
    const [workDetail, setWorkDetail] = useState({});

    const [earnAllUser, setEarnAllUser] = useState([])

    const {selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {unlockWorkIds} = useMemo(() => {
        return selfData || {}
    }, [selfData])


    const getClubsListFn = () => {
        console.log(workType);
        getClubsList(workType).then(res => {
            setEarnAllUser(res?.employeeList || [])
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        getClubsListFn()
    }, [])

    const findBestTask = (level, energy) => {
        // 筛选出符合条件的任务
        let availableTasks = WorkConfig.filter(task => {
            return task.TierRequired <= level;
        });

        availableTasks = availableTasks.filter(task => {
            return !!unlockWorkIds.includes(task.ID);
        })

        // 如果没有符合条件的任务，返回null
        if (availableTasks.length === 0) return null;

        // 按收益排序，收益最高的任务在前
        availableTasks.sort((a, b) => b.Profit - a.Profit);

        // 返回收益最高的任务
        if (energy + availableTasks[0].Energy >= 0)
            return availableTasks[0].ID;
        else
            return 2;
    }

    const getTimes = (seconds) => {
        const h = parseInt(seconds / 60 / 60);
        const m = parseInt(seconds / 60 % 60);
        return `${h > 0 ? h + 'h' : ''}${m > 0 ? m + 'm' : ''}`
    }


    useEffect(() => {
        if (earnAllUser?.length === 0) return
        const user2WorkId = {};
        let userIds = earnAllUser.map((v) => v.userId);
        post_dispatch_detail_mutil(userIds,workType).then((data) => {
            data?.canDispatchUsers.forEach((v) => {
                const {employeeId, workId} = v;
                user2WorkId[employeeId] = workId;

                setWorkDetail((workDetail) => {
                    return {
                        ...workDetail,
                        [employeeId]: v
                    }
                });
            });
        }).catch((err) => {
            console.log(err);
        });
        setUser2WorkId(user2WorkId);
    }, [earnAllUser]);

    const SubmitEarn = () => {
        let userIds = Object.keys(user2WorkId).map((v) => parseInt(v));
        post_dispatch_emplyees_mutil(userIds,workType).then((data) => {
            setTimeout(() => {
                refreshPortfolio()
                setTimeout(() => {
                    closeHandle()
                }, 500);
            }, 500);
        }).catch((err) => {
            console.log(err);
            setTimeout(() => {
                refreshPortfolio()
                setTimeout(() => {
                    closeHandle()
                }, 500);
            }, 500);
        });
    }

    return (
        <div>
            <div className="color-white ml20 mr20">
                {t('Efficiently assign missions to clubs based on their capacity for maximum yield')}
            </div>
            <div className={s.gridWrap}>
                <div className={s.gridHeader}>{t('Clubs')}</div>
                <div className={s.gridHeader}>{t('Duration')}</div>
                <div className={s.gridHeader}>{t('Energy')}</div>
                <div className={s.gridHeader}>
                    {t('Expected')}
                    <br/>
                    {t('Earnings')}
                </div>
                {earnAllUser.map((v) => {
                    const {
                        tierId,
                        userId,
                        profile,
                    } = v;
                    const {displayName} = profile;
                    const avatarUrl = getAvatarFromProfile(profile);
                    const work = WorkConfig.find((v) => v.ID === user2WorkId[userId]);

                    return (<>
                        <div className={s.gridCell} key={userId}>
                            <div className="df fdc alc jcc" style={{width: '100px'}}>
                                <AvatarInfo className="" size={40} src={avatarUrl}/>
                                <div className="df aic jcfs ">
                                    {tierId && (
                                        <CustomIcon
                                            className=" mr2 mb5"
                                            width={15}
                                            height={15}
                                            imgName={`Picture/Rank/RankStar_${tierId}`}
                                            name={`RankStar_${tierId}`}
                                        />
                                    )}
                                    <span
                                        className={`ellipse fs12 color-white ${s.maxWidth}`}>{displayFilter(displayName)}</span>
                                </div>
                            </div>
                        </div>
                        <div className={s.gridCell}>
                            <CustomIcon
                                width={16}
                                height={16}
                                imgName={`Picture/UI_Picture_Time_01`}
                                className="mr5"
                            />
                            {workDetail?.[userId] ? (workDetail?.[userId]?.canDispatch ? getTimes(work?.WorkSecond) : '--') : t('Loading...')}
                        </div>
                        <div className={s.gridCell}>
                            <CustomIcon
                                width={13}
                                height={16}
                                imgName={`Picture/UI_Picture-Currency_Thunder_01`}
                                name={`Picture/UI_Picture-Currency_Thunder_01`}
                                className="mr2"
                            />
                            <span
                                className="color-energy">{workDetail?.[userId] ? (workDetail?.[userId]?.canDispatch ? (work?.ExtraRewardType === 5 ? "+" + work?.ExtraRewardNum : work?.Energy) : '--') : t('Loading...')}</span>
                        </div>
                        <div className={s.gridCell}>
                            <CustomIcon
                                width={18}
                                height={18}
                                imgName={`Picture/UI_Picture-Currency_LFG_01`}
                                className="mr5"
                            />
                            {workDetail?.[userId] ? (workDetail?.[userId]?.canDispatch ? vCoinString(workDetail?.[userId]?.totalProfit) : '--') : t('Loading...')}
                        </div>
                    </>)
                })}
            </div>
            <div className="modelBottomBtn">
                <ButtonFactory onClick={SubmitEarn}>
                    {t('Start Missions')}
                </ButtonFactory>
            </div>
        </div>
    );
};

export default memo(ModelEarnAll);
