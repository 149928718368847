import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { Slider, Switch, Radio } from "antd";
import { AutoStrangeRule } from "@/utils/strangeRule";
import { coinEachPackRangeRatioData } from "@/utils/memeClub";
import { vCoinString } from "@/utils/common";
import AntModal from "@/common/AntModal";
import ModelEditVal from "@/model/ModelEditVal";

const Airdrop = (
    {
        totalCardValue,
        cardTotalSupply,
        coinTotalSupply, setCoinTotalSupply,
        airdropPecentage, setAirdropPecentage,
        airdropType, setAirdropType,
        coinTotalSupplyData,
        airdropPecentageData,
        airdropTypeData,
        clubTicker,
        selCreatChain,

        unpackRatio, setUnpackRatio,
        lastCardPrice,
        setShowAirDropTips
    }
) => {
    const { t, i18n } = useTranslation();
    const { min, max } = coinEachPackRangeRatioData;
    // const [unpackRatio,setUnpackRatio] = useState(1)
    const evenCardToToken = useMemo(() => {
        console.log(airdropPecentage);
        return parseInt(coinTotalSupply * (airdropPecentage / 100) / cardTotalSupply * 2)
    }, [cardTotalSupply, coinTotalSupply, airdropPecentage])

    const minAirdrop = useMemo(() => {
        return Math.ceil(lastCardPrice * cardTotalSupply / (totalCardValue * (1 - 0.03) + lastCardPrice * cardTotalSupply) * 100)
    }, [lastCardPrice, totalCardValue, cardTotalSupply])

    // const marks = useMemo(()=>{
    //     if(minAirdrop){
    //         const obj = {}
    //         obj[minAirdrop] = ' '
    //         return obj
    //     } 
    // },[minAirdrop])

    const letPersent = useMemo(() => {
        const p = (minAirdrop - 50) * 2 + 1;
        return p < 12 ? 12 : p
    }, [minAirdrop])

    useEffect(() => {
        setShowAirDropTips(airdropPecentage < minAirdrop)
    }, [airdropPecentage, minAirdrop])

    const sliderTotalSupplyMin = Math.round(Math.log10(coinTotalSupplyData?.min), 2);
    const sliderTotalSupplyMax = Math.round(Math.log10(coinTotalSupplyData?.max), 2);

    const airdropSliderChange = (value) => {
        const minValue = minAirdrop;
        if (value >= minValue) {
            setAirdropPecentage(value);
        }
    };
    const [showEditModel, setShowEditModel] = useState(false)
    return (
        <div className={`${s.box}`}>
            <div className={`${s.partTitle} mb20`}>
                <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                    imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                {AutoStrangeRule(t('Meme Coin Setting'))}
            </div>
            <div className={s.wrap}>
                <div className={s.label}>
                    <span className="color-blue-light">{t('TotalAAA')} ${clubTicker} {t('Supply: ')}</span> &nbsp;
                    {coinTotalSupply?.toLocaleString()}
                </div>
                <div className="mb30 flex_center_center">
                    <Slider
                        className="hireSlider commonSlider memeClubSlider"
                        min={sliderTotalSupplyMin}
                        max={sliderTotalSupplyMax}
                        value={Math.log10(coinTotalSupply)}
                        step={0.1}
                        tooltip={{ open: false }}
                        onChange={(value) => {
                            setCoinTotalSupply(parseInt(Math.pow(10, value) / Math.pow(10, (parseInt(value) - 1))) * Math.pow(10, (parseInt(value) - 1)));
                        }} />
                    <CustomIcon onClick={() => { setShowEditModel(true) }} height={25} width={25} className="ml15" imgName="Button/UI_Picture_Settings_Edit_01" ></CustomIcon>
                </div>
                <div className={s.label}>
                    <span className="color-blue-light">{t('Airdrop / Liquidity Pool:')}</span> &nbsp;
                    {`${airdropPecentage}%/${100 - airdropPecentage}%`}
                </div>
                <div className={s.airdropWarp}>
                    <div className={s.marks} style={{
                        left: letPersent + "%"
                    }}>
                        {t('Min Airdrop Ratio')}
                    </div>
                    <Slider
                        className="hireSlider commonSlider memeClubSlider"
                        min={parseInt(airdropPecentageData?.min / 100)}
                        max={parseInt(airdropPecentageData?.max / 100)}
                        value={airdropPecentage}
                        onChange={airdropSliderChange} />

                    <div style={{
                        left: (minAirdrop - 50) * 2 + 1 + '%'
                    }} className={s.markTop}></div>
                    <div style={{
                        left: (minAirdrop - 50) * 2 + 1 + '%'
                    }} className={s.markBottom}></div>
                </div>
                <div className='mt10 mb10'>
                    <span className="color-yellow fs12">
                        {t('Estimated Token Price: ')}
                        {`${(totalCardValue / (coinTotalSupply * (100 - airdropPecentage) / 100)).toFixed(6)} ${selCreatChain?.displayCoinName}`}
                    </span>
                </div>
                <div className="color-white fs10 tll ">{t('Upon TGE, all')} ${clubTicker} {t('will be minted. The Airdrop/LP ratio determines how much is airdropped to pack owners and the rest is added to the Liquidity pool.')}</div>
            </div>
            <div className={`${s.partTitle} mb20`}>
                <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                    imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                {AutoStrangeRule(t('Unpack Setting'))}
            </div>
            <div className={s.wrap}>
                <div className={s.label}>
                    <span className="color-blue-light">{t('Airdrop Preference:')}</span> &nbsp;
                    {unpackRatio} X
                </div>
                <div>
                    {/* <Radio.Group onChange={(e)=>{
                        setAirdropType(e.target.value)
                    }} value={airdropType}>
                        <Radio className="hireCheckBox" value={1}>{t('Gentle and Fair')}</Radio>
                        <Radio className="hireCheckBox" value={2}>{t('Schedule Send')}</Radio>
                    </Radio.Group> */}
                    <Slider
                        className="hireSlider commonSlider memeClubSlider"
                        min={parseInt(min)}
                        max={parseInt(max)}
                        value={unpackRatio}
                        onChange={(value) => {
                            setUnpackRatio(value);
                        }} />
                </div>
                <div className={`${s.label}`}>
                    <span className="color-blue-light">{t('Coins Each Card Offers:')}</span> &nbsp;
                    {`${vCoinString(parseInt(evenCardToToken * (1 / (unpackRatio + 1))))}-
                    ${vCoinString(parseInt(evenCardToToken * (unpackRatio / (unpackRatio + 1))))}`}
                </div>
                <div className="color-white fs10 tll">
                    {t('The holder of the pack receives varying amounts of coins by unpacking after TGE.')}
                </div>

            </div>
            <AntModal
                width='330px'
                title={t('Edit')}
                className="confirmModalWrap"
                centered={true}
                open={showEditModel}
                onOk={() => setShowEditModel(false)}
                onCancel={() => setShowEditModel(false)}
            >
                <ModelEditVal title={`${t('TotalAAA')} ${clubTicker} ${t('Supply: ')}`}
                    onClose={() => setShowEditModel(false)}
                    val={coinTotalSupply}
                    setVal={setCoinTotalSupply}
                    min={coinTotalSupplyData?.min}
                    max={coinTotalSupplyData?.max} />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Airdrop));
