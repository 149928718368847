import React, {memo, useState, useMemo, useEffect} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {Input, Button, Modal} from "antd";
import {
    officialInviteCode,
    joinChartPre,
    taPush,
    referralcode_bind,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import {useWallets} from "@privy-io/react-auth";
import momentTradeVolSharingRate from "@/utils/json/momentTradeVolSharingRate.json";

const ModalMomentTips = (
    {
        title="tips",
        text="",
        closeFn = () =>{}
    }
) => {
    const {t, i18n} = useTranslation();

    return (
        <div className={`${s.wrap} flex_center_start_col`}>
            <div className="w100p flex_center_center fs20 fb mt40 mb30 color-EEEEEE">{t(title)}</div>
           
            <div className={`${s.des} mt10 fs12 color-moment-light-gray`}>
                {t(text)}
            </div>

            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeFn();
                }} />
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalMomentTips));
