import React, {useEffect, useRef, useState} from "react";
import CustomIcon from "src/common/CustomIcon";
import s from "./index.module.less";
import {Button} from "antd";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";

import gif from "@/assets/images/bgImg/slots.gif";
import bgPng from "@/assets/images/bgImg/slotsStop.png";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";

const ModelBoostResult = (
    {
        claimCoin,
        friendtradeBoostClaimcoin,
        setShowModelBoostResult,
    }
) => {
    const {t, i18n} = useTranslation();
    const [ratio, setRotio] = useState("1.00");
    const [animation, setAnimation] = useState(true);
    const [baseClaim, setBaseClaim] = useState(claimCoin);
    const maxCount = 10;
    const rRatio = Number(ratio) + (friendtradeBoostClaimcoin?.boostRatio || 0);
    const scrollRef = useRef(null);
    const imgRef = useRef(null);
    const [translate, setTranslate] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setAnimation(false);
            setTranslate({transform: `translate3d(0 -402px 0)`});
        }, 3200);
    }, []);

    return (
        <div className="mt10 mb20" style={{padding: "0 35px"}}>
            {/* <div className="fs12 color-white">Your $LFG Pool:</div> */}
            <div className="tlc fs12 color-white mt20">{t('Before')}</div>
            <div className={`${s.bg} mt5`}>
                <CustomIcon
                    width={20}
                    height={20}
                    className="mr5"
                    imgName={"Picture/UI_Picture-Currency_LFG_01"}
                    name={"Picture/UI_Picture-Currency_LFG_01"}
                />
                <div className="fs14 color-white fb ">
                    {(parseInt(baseClaim) || "--").toLocaleString("en-US")}
                </div>
            </div>
            <div className={`pr ${s.boxWrap}`}>
                <img
                    ref={imgRef}
                    width={250}
                    height={125}
                    style={{margin: "0 auto", display: "block"}}
                    src={animation ? gif : bgPng}
                />
                {/* <img className={s.slotsAside} scr="/img/slotsAside.gif" /> */}
                <div className={s.scrollWrap}>
                    <div
                        ref={scrollRef}
                        className={s.scrollContent}
                        style={{translate}}
                    >
                        {new Array(maxCount).fill("").map((v, idx) => {
                            return (
                                <div key={idx} className={s.ratio}>
                                    x{(Number(rRatio) * Math.random()).toFixed(2)}
                                </div>
                            );
                        })}
                        <div className={`${s.ratio} fs16 fb`}>x{rRatio}</div>
                    </div>
                </div>
                <div className={s.boxBg}></div>
            </div>
            <div className="tlc fs12 color-white mt10">{t('After')}</div>
            <div className={`${s.bg} mt5`}>
                <CustomIcon
                    width={20}
                    height={20}
                    className="mr5"
                    imgName={"Picture/UI_Picture-Currency_LFG_01"}
                    name={"Picture/UI_Picture-Currency_LFG_01"}
                />
                <div className="fs14 color-white fb ">
                    {(animation
                            ? "????"
                            : parseInt(baseClaim + friendtradeBoostClaimcoin?.addClaimCoin)
                    ).toLocaleString("en-US")}
                </div>
            </div>
            <div className="modelBottomBtn">
                <ButtonFactory
                    disabled={animation}
                    onClick={() => setShowModelBoostResult(false)}
                >
                    {animation ? t('SPINNING') : t('CLAIM')}
                </ButtonFactory>
            </div>

        </div>
    );
};

export default ModelBoostResult;
