import React, { useEffect, useState } from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import { Button ,Spin} from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { boosterLog } from "@/utils/xFarm";
import { InfiniteScroll } from "antd-mobile";
const ModelUpMultiplyCardList = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const timeFn = (time) => {
        let d = parseInt(time / 60 / 60 / 24);
        let h = parseInt(time / 60 / 60 % 24);
        let m = parseInt(time / 60 % 60);
        let str = '';
        if (d > 0) {
            str = d + "d";
            return str
        }
        if (h > 0) {
            str = h + "h";
            return str
        }
        if (m > 0) {
            str = m + "m";
            return str
        }
        return t('few seconds');

        // return `${d}${h}${m}`
    }
    const [list, setList] = useState([]);
    const [nextCursor,setNextCursor] = useState(-1)

    const getboosterLog = (cursor = 0)=>{
        boosterLog(cursor).then(res => {
            setNextCursor(res?.nextCursor)
            if(cursor !== 0){
                setList([
                    ...list,
                    ...res?.eventList
                ])
            }else{
                setList(res?.eventList || [])
            }
            
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        getboosterLog(0)
    }, [])

    const getMoreFn = ()=>{
        getboosterLog(nextCursor)
    }
    return (
        <div className={s.modelWrap}>
            <div className={s.list}>
            {list?.length === 0 && <div className="tlc">{t('No Data')}</div>}
                <InfiniteScroll
                    dataLength={list?.length || 0}
                    next={getMoreFn}
                    hasMore={nextCursor !== -1}
                    loader={<Spin />}
                    height={"100%"}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0"
                    }}
                    endMessage={
                        <div style={{
                            textAlign: "center",
                            width: "100%",
                            color: "var(--color-gray)",
                            fontSize: '12px'
                        }}></div>
                    }>
                    {
                        list?.map((item, index) => {
                            const {
                                eventType,
                                boostNum,
                                holderKeyId,
                                keyUserName,
                                timeDuration,
                            } = item;
                            return (
                                <div className={s.item} key={index}>
                                    <div className={s.iL}>
                                        <CustomIcon className={s.icon} width={40} height={40} imgName={"Picture/UI_Picture_Title-Blue_Rocket_01"} />
                                        <div className={s.amount}>+{boostNum}</div>
                                    </div>
                                    <div className={s.iR}>
                                        <div className="color-blue-light fs12">{t('xx ago', { time: timeFn(timeDuration) })}</div>
                                        <Trans
                                            i18nKey='Got {{amount}} Booster for holding {{name}} cards.'
                                            defaults='Got {{amount}} Booster for holding {{name}} cards.'
                                            values={{ amount: boostNum,name: keyUserName}}
                                            components={{ fb: <span className="fb color-white"></span> }}
                                        />
                                        <div></div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </InfiniteScroll>
                
            </div>
        </div>
    )
}

export default ModelUpMultiplyCardList
