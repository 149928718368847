import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import efficiency from "@/utils/json/EfficiencyConfig.json";
import globalProRate from "@/utils/json/GlobalProductionConfig.json";
import NFTWorkGlobalConfig from "@/utils/json/NFTWorkGlobalConfig.json";
import {useTranslation, Trans} from "react-i18next";
import {convertWalletBalance, formatNumberWithUnit} from "@/utils/common";

const getEfficiencyTarget = () => {
    const target = {};
    efficiency.forEach((v) => {
        if (!target[v.workId]) {
            target[v.workId] = [];
        }
        target[v.workId].push({
            ...v,
            id: target[v.workId].length,
        });
    });
    return target;
};

const HireModelWorkIntro = ({setShowModelWishIntro, rankInfo}) => {
    const {t, i18n} = useTranslation();
    const {type, workId, currentRate, amount} = useMemo(() => {
        return rankInfo;
    });
    const efficiencyTarget = getEfficiencyTarget();
    const currIdx = useMemo(() => {
        const list = type === 1 ? globalProRate : type === 3 ? NFTWorkGlobalConfig : efficiencyTarget[workId];
        const idx = list.findIndex((item, index) => {
            if (
                amount < (type === 1 || type === 3 ? list[index + 1]?.globalAmount : list[index + 1]?.populationNumber)

            ) {
                return true;
            } else if (index === list.length - 1) {
                return true;
            }
        });
        return idx;
    }, [amount]);
    return (
        <div className={s.wrap}>
            {/* <div className={s.head}>
                {type === 1
                    ? t(
                        "$LFG production decreases into half half each time the emission milestone is reached."
                    )
                    : t(
                        "Work efficiency determines your $LFG earnings. The more clubs that are completing the same mission, the lower the efficiency, thus reducing $LFG production. This motivates clubs to unlock new missions to increase $LFG earnings."
                    )}
            </div> */}

            <div className={s.rankWrap}>
                <div className="color-white mb10">
                    {type === 1 || type === 3 ? t("Current $LFG Vol:") : t("Current club number:")} {convertWalletBalance(amount)}
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>{t("STAGE")}</th>
                        <th>{type === 1 || type === 3 ? t("$LFG PRODUCED") : t("CLUB NUMBER")}</th>
                        <th>{t("$LFG PRODUCTION RATE")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currIdx > 2 && (
                        <tr>
                            <td>...</td>
                            <td>...</td>
                            <td>...</td>
                        </tr>
                    )}
                    {type === 1
                        && globalProRate.map((item, index) => {
                            if (
                                (currIdx === 0 || currIdx === 1 || currIdx === 2) &&
                                index > 3
                            )
                                return;
                            if (
                                currIdx !== 0 &&
                                currIdx !== 1 &&
                                currIdx !== 2 &&
                                (currIdx - index > 1 || currIdx - index < -1)
                            )
                                return;
                            return (
                                <tr key={index} className={`${currIdx === index ? s.curr : ""}`}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {formatNumberWithUnit(item.globalAmount, 0)}-
                                        {formatNumberWithUnit(
                                            globalProRate[index + 1]?.globalAmount,
                                            0
                                        )}
                                    </td>
                                    <td>{item.decayParam / 100}%</td>
                                </tr>
                            );
                        })
                    }
                        {type === 2 && efficiencyTarget[workId]?.map((item, index) => {
                            if (
                                (currIdx === 0 || currIdx === 1 || currIdx === 2) &&
                                item.id > 3
                            )
                                return;
                            if (
                                currIdx !== 0 &&
                                currIdx !== 1 &&
                                currIdx !== 2 &&
                                (currIdx - item.id > 1 || currIdx - item.id < -1)
                            )
                                return;
                            return (
                                <tr key={index} className={`${currIdx === index ? s.curr : ""}`}>
                                    <td>{item.id + 1}</td>
                                    <td>
                                        {formatNumberWithUnit(item.populationNumber, 0)}-
                                        {formatNumberWithUnit(
                                            efficiencyTarget[workId][index + 1]?.populationNumber,
                                            0
                                        )}
                                    </td>
                                    <td>{item.decayParam / 100}%</td>
                                </tr>
                            );
                        })}
                        {type === 3 && NFTWorkGlobalConfig.map((item, index) => {
                            if (
                                (currIdx === 0 || currIdx === 1 || currIdx === 2) &&
                                index > 3
                            )
                                return;
                            if (
                                currIdx !== 0 &&
                                currIdx !== 1 &&
                                currIdx !== 2 &&
                                (currIdx - index > 1 || currIdx - index < -1)
                            )
                                return;
                            return (
                                <tr key={index} className={`${currIdx === index ? s.curr : ""}`}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {formatNumberWithUnit(item.globalAmount, 0)}-
                                        {formatNumberWithUnit(
                                            NFTWorkGlobalConfig[index + 1]?.globalAmount,
                                            0
                                        )}
                                    </td>
                                    <td>{item.decayParam / 100}%</td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
};
export default memo(HireModelWorkIntro);
