import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button} from "antd";
import CustomIcon from "../../../common/CustomIcon";
import {post_trivia_revival} from "@/utils/common";
import AntButton from "@/common/AntButton";


const ModelVoteRevivalHave = (
    {
        setShowRevivalUse,
        hostId,
        revivalCardHoldCount,
        revivalCardCountRemain,
        revivalCardMaxCount,
    }
) => {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(false);
    const useRevival = () => {
        setLoading(true);
        post_trivia_revival(hostId).then(data => {
            setLoading(false);
            setShowRevivalUse(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        })
    }

    return (
        <div className={s.wrap}>
            <div>
                <CustomIcon width={130} height={130} imgName={'SadEmoji'} name={'SadEmoji'}/>
            </div>
            <div className="fs20 color-white fb mb10">
                <Trans
                    i18nKey="YOU'VE BEEN ELIMINATED!"
                    defaults="YOU'VE BEEN <br> ELIMINATED!"
                    components={{br: <br/>}}
                />
            </div>
            <div className="fs16 color-white mb5">
                {t('Chances Per Round')}：{revivalCardCountRemain}/{revivalCardMaxCount}
            </div>
            <div className="fs16 color-white ">
                {t('Extra Lives')}: <CustomIcon width={18} height={18}
                                                imgName={'Picture/UI_Picture_Title-Blue_ManYellowHeart_01'}
                                                name={'Picture/UI_Picture_Title-Blue_ManYellowHeart_01'}/> {revivalCardHoldCount}
            </div>
            <div className="fs12 color-white mtb10 fl">
                {t('Use an Extra Life and stay in the game?')}
            </div>
            <AntButton shape="round" className="btn_public mb10" onClick={useRevival}
                       loading={loading}>{t('USE EXTRA LIFE')}</AntButton>
            <AntButton shape="round" className="btn_public mb10" style={{background: '#333'}}
                       onClick={() => setShowRevivalUse(false)} loading={loading}>{t('TAKE THE L')}</AntButton>
        </div>
    )
}
export default memo(ModelVoteRevivalHave);
