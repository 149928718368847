import React, {memo, useState, useMemo, useEffect, useRef, useCallback} from "react";
import s from "./index.module.less";
import {Modal, Button, Slider, Checkbox, Spin} from "antd";
import CustomIcon from "../../common/CustomIcon";
import AvatarInfo from "../../common/AvatarInfo";
import InputNumber from "../../common/InputNumber"
import {url} from "@/utils/configUri";
import http from "@/utils/axios";
import FilterIcon from "@/assets/images/FilterIcon.png"
import {
    stringToNumber,
    post_batch_price,
    keyBatchBuy,
    TransactionError,
    PrivyLoginStatusError,
    logoutSelf,
    unit,
    displayFilter,
    getAvatarFromProfile,
    lfgIconUrl,
    maticIconUrl,
} from "@/utils/common";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import TierConfig from "@/utils/json/TierConfig.json";
import {useTranslation} from "react-i18next";
import ModelDeposit from "../ModelDeposit";
import ModalInsufficientCrpto from "../ModalInsufficientCrpto";
import ButtonFactory from "src/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import {connect} from "react-redux";

const ModalSweepClub = (
    {
        userInfo,
        setShowModelTrans,
        buyData,
        managerBuyList,
        setShowGasMode,
        cardType = 0,
    }
) => {
    const {t, i18n} = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [allChecked, setAllChecked] = useState(0)
    const [showData, setShowData] = useState(buyData)
    const [keyPrice, setKeyPrice] = useState(0);
    const [keyPriceWithFee, setKeyPriceWithFee] = useState(0);
    const [maticUsd, setMaticUsd] = useState(0);
    const [fastGasPrice, setFastGasPrice] = useState(0);
    const buyList = useRef({});
    const [showBuyList, setShowBuyList] = useState(JSON.parse(JSON.stringify(managerBuyList)));
    const [subLoading, setSubLoading] = useState(false);
    const {balance} = userInfo?.selfData;
    const {walletAddr} = userInfo;
    const walletList = useRef([]);
    const keyIdList = useRef([]);
    const amountList = useRef([]);
    const expectedPricesList = useRef([]);
    const [noSuccessText, setNoSuccessText] = useState('Buy Failed');
    const [done, setDone] = useState(false);
    const [subFailded, setSubFailded] = useState(false);
    const [settleLoading, setSettleLoading] = useState(false)
    const [quicklyStatus, setQuicklyStatus] = useState(0)
    const maxBatchSelectNum = 10
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);

    const currencyIcon = useMemo(() => {
        return cardType === 1?lfgIconUrl:maticIconUrl;
    }, [cardType])

    const isAvailable = useMemo(() => Number(stringToNumber(balance, 4)) > Number(stringToNumber(Number(keyPriceWithFee) + Number(fastGasPrice), 4)), [balance, keyPriceWithFee, fastGasPrice]);

    const formatHeat = (heat) => heat < 0.01 ? '<0.01' : heat.toFixed(2);
    useEffect(() => {
        calcSelectedRows()
    }, [selectedRows])
    const calcSelectedRows = () => {
        post_batch_price(buyList.current).then(data => {
            const buyPricesTotal = data?.buyPriceTotal;
            const buyPricesAfterFeeTotal = data?.buyPriceAfterFeeTotal;

            setKeyPrice(buyPricesTotal);
            setKeyPriceWithFee(buyPricesAfterFeeTotal);
            setMaticUsd(data?.tokenUsd);
            // setContractAddr(data?.contractAddr);
            setFastGasPrice(data?.fastGasPrice);

            walletList.current = Object.values(data?.wallets);
            keyIdList.current = Object.keys(buyList.current).map(Number);
            amountList.current = Object.values(buyList.current).map(Number);
            expectedPricesList.current = Object.values(data.buyPricesAfterFee)

            const kns = Object.values(buyList.current).reduce((a, b) => {
                return Number(a) + Number(b);
            }, 0);
            // setKeyNum(kns);
            // setLoading(false);
            setSettleLoading(false)
        }).catch(e => {
            // setLoading(false);
            console.log(e);
            // message.error("Get price failed");
            setSettleLoading(false)
        })
    }

    const closeModal = async () => {
        setDone(false)
        buyList.current = {}
        setSelectedRows([])
        setShowModelTrans(false)
    };
    useEffect(() => {
        // c all
        setAllChecked(true)
        buyList.current = JSON.parse(JSON.stringify(managerBuyList))
        setSelectedRows(showData)
    }, [])

    const handleCheckboxChange = (row) => {
        let t
        if (selectedRows.includes(row)) {
            t = selectedRows.filter((item) => item !== row)
        } else {
            if (selectedRows.length >= maxBatchSelectNum) return
            t = [...selectedRows, row];
        }
        const temp = {}
        const showTemp = {}
        t.forEach(r => {
            const {userId} = r
            showTemp[userId] = temp[userId] = showBuyList[userId] || 1
        })
        if (showData.length === t.length) {
            setAllChecked(true)
        } else {
            setAllChecked(false)
        }
        setShowBuyList(showBuyList)
        buyList.current = temp
        setSelectedRows(t)
    }

    const onCheckedAll = (e) => {
        setAllChecked(e.target.checked)
        if (e.target.checked) {
            buyList.current = showBuyList
            setSelectedRows(showData)
        } else {
            buyList.current = {}
            setSelectedRows([])
        }
    }

    const {logout} = usePrivy();
    const closeFn = () => {
        if (noSuccessText === PrivyLoginStatusError) {
            logout();
            logoutSelf();
        }
        closeModal();
    }

    const {wallets} = useWallets();
    const transFn = () => {
        if (subLoading) return
        if (!isAvailable) {
            setShowInsufficientCrpto(true);
            return
        }
        setSubLoading(true);
        keyBatchBuy(wallets, keyIdList.current, walletList.current, amountList.current, keyPriceWithFee, expectedPricesList.current).then(res => {
            setSubLoading(false);
            setDone(true);
            setSubFailded(false);
        }).catch(e => {
            console.log(e);
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }

            if (e === 'need login') {
                setNoSuccessText(PrivyLoginStatusError)
            }

            setSubLoading(false);
            setDone(true);
            setSubFailded(true)
        })
    }

    useEffect(() => {
        setSettleLoading(true)
    }, [buyList.current])
    const onChangeInputNumber = (value, id) => {
        showBuyList[id] = value
        setShowBuyList(showBuyList)
        if (buyList.current[id]) {
            setSettleLoading(true)
            buyList.current[id] = value
            calcSelectedRows()
        }
    }

    const changeAllInputNumber = (value) => {
        let newShowBuyList = {}
        Object.keys(showBuyList).forEach((key, index) => {
            newShowBuyList[key] = value
            if (buyList.current[key]) {
                buyList.current[key] = value
            }
        })
        setQuicklyStatus(value)
        setShowBuyList(newShowBuyList)
        setSettleLoading(true)
        calcSelectedRows()
    }

    const changeAllInputNumberDefault = () => {
        let newShowBuyList = showBuyList
        Object.keys(showBuyList).forEach((key, index) => {
            const needBuyKeyNum = showData.find(item => item.userId === parseInt(key)).needBuyKeyNum;
            newShowBuyList[key] = needBuyKeyNum
            if (buyList.current[key]) {
                buyList.current[key] = needBuyKeyNum
            }
        })
        setQuicklyStatus(0)
        setShowBuyList(newShowBuyList)
        setSettleLoading(true)
        calcSelectedRows()
    }

    return (
        <div className={s.container}>
            <div className={s.wrap}>
                <table style={{marginTop: 10}}>
                    <thead>
                    <tr>
                        <th>
                            <div></div>
                        </th>
                        <th style={{textAlign: "left", paddingLeft: '38px', fontSize: '14px'}}>{t('Clubs')}</th>
                        <th style={{fontSize: '14px'}}>{t('Price')}</th>
                        <th style={{fontSize: '14px'}}>{t('Number')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(showData).map((row, index) => (
                        <tr key={"filter" + index} className={`${selectedRows.includes(row) ? s.checkedBG : ''}`}>
                            <td style={{paddingRight: 13}}>
                                <Checkbox onClick={() => handleCheckboxChange(row)}
                                          className="hireCheckBox"
                                          style={{marginLeft: 5}}
                                          checked={selectedRows.includes(row)}
                                />
                            </td>
                            <td style={{display: "flex", alignItems: "center"}}>
                                <AvatarInfo
                                    style={{flex: 'none'}}
                                    size={30}
                                    src={getAvatarFromProfile(row?.profile, 1) || getAvatarFromProfile(row?.profile, 0)}
                                />
                                <div className={s.ellipseUser} style={{maxWidth: '90px'}}>
                                    {displayFilter(row.profile.displayName)} &nbsp;
                                    {row.tierId && <CustomIcon className="mr5 mb5" width={12} height={12}
                                                               imgName={`Picture/Rank/RankStar_${row.tierId}`}
                                                               name={`RankStar_${row.tierId}`}/>}
                                    <br/>
                                    <span className="color-blue-light fs12">{t('Vol')}: {formatHeat(row.heat)}</span>
                                </div>
                            </td>
                            <td>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <CustomIcon
                                        width={18}
                                        height={18}
                                        imgName={currencyIcon}
                                    />
                                    <span style={{marginLeft: '4px'}}>{stringToNumber(row.buyPrice, 4)}</span>
                                </div>
                            </td>
                            <td className="tlc" style={{paddingRight: '5px'}}>
                                <InputNumber min={1} value={showBuyList[row.userId]}
                                             onChange={($event) => onChangeInputNumber($event, row.userId)} max={99}/>
                                <span className={s.showInputTip}>{showBuyList[row.userId] < row.needBuyKeyNum ? <><span
                                        className="color-energy fs10">{row.needBuyKeyNum - showBuyList[row.userId]}</span> {t('more keys to be manager')}</> :
                                    <span
                                        className="color-blue-light fs10">{t('Sufficient to be manager')}</span>}</span>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className={s.sliderContainer}>
                </div>
            </div>
            <div className="df aic jc-spaceBetween mb10 mt10">
                <span className="fs12">{t('Quickly set quantity to')} &nbsp;</span>
                <AntButton size="small"
                           className={`${quicklyStatus === 1 ? 'btn_white ' + s.btn_active : 'btn_dark'} btn_public widthAuto mr5 ${s.btn_border}`}
                           style={{fontWeight: 'normal'}} onClick={() => changeAllInputNumber(1)}>1</AntButton>
                <AntButton size="small"
                           className={`${quicklyStatus === 3 ? 'btn_white ' + s.btn_active : 'btn_dark'} btn_public widthAuto mr5 ${s.btn_border}`}
                           style={{fontWeight: 'normal'}} onClick={() => changeAllInputNumber(3)}>3</AntButton>
                <AntButton size="small"
                           className={`${quicklyStatus === 10 ? 'btn_white ' + s.btn_active : 'btn_dark'} btn_public widthAuto mr5 ${s.btn_border}`}
                           style={{fontWeight: 'normal'}} onClick={() => changeAllInputNumber(10)}>10</AntButton>
                <AntButton size="small"
                           className={`${quicklyStatus === 0 ? 'btn_white ' + s.btn_active : 'btn_dark'} btn_public widthAuto ${s.btn_border}`}
                           style={{fontWeight: 'normal'}}
                           onClick={() => changeAllInputNumberDefault()}>{t('be Manager')}</AntButton>
            </div>
            <div className={s.bottomSettleSweep}>
                <Spin spinning={settleLoading}>
                    <div className={s.settlePanel}>
                        <Checkbox style={{height: 'fit-content', whiteSpace: 'nowrap'}} className="hireCheckBox fs12"
                                  checked={allChecked} onChange={onCheckedAll}>{t('Select All')}</Checkbox>
                        <div className="df">
                            <div className={s.label_list}>
                                <div className={s.label}>{t('Order Details')}</div>
                                <div className={s.label}>{t('Total(inc. fees)')}</div>
                                <div className={s.label}>{t('Available')}</div>
                            </div>
                            <div>
                                <div>
                                    <CustomIcon width={12} height={12} imgName={currencyIcon}/>
                                    <span className={s.coin}>{stringToNumber(Number(keyPriceWithFee), 4)}</span>
                                    <span className={s.money}>${stringToNumber(keyPriceWithFee * maticUsd, 4)}</span>
                                </div>
                                <div>
                                    <CustomIcon width={12} height={12} imgName={currencyIcon}/>
                                    <span
                                        className={s.coin}>{stringToNumber(Number(keyPriceWithFee) + Number(fastGasPrice), 4)} </span>
                                    <span
                                        className={s.money}>${stringToNumber((Number(keyPriceWithFee) + Number(fastGasPrice)) * maticUsd, 4)}</span>
                                </div>
                                <div>
                                    <CustomIcon width={12} height={12} imgName={currencyIcon}/>
                                    <span className={s.coin}>{stringToNumber(balance, 4)}</span>
                                    <span className={s.money}>${stringToNumber(balance * maticUsd, 4)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
                <div style={{height: '20px'}}></div>

            </div>
            <CustomIcon width={30} height={30}
                        className="modelBottomSetGas"
                        imgName={'Button/UI_Button_Gas_01'}
                        onClick={() => {
                            setShowGasMode(true);
                        }}
            />
            <div className={`modelBottomBtn`}>
                {!done && <ButtonFactory onClick={transFn} disabled={!selectedRows.length}
                >
                    {subLoading ? <div className={s.web3LoadingLine}>
                        <CustomIcon rotating={true} className="ml5" width={26} height={26}
                                    imgName={'Picture/UI_Picture_Loading_01'} name={'Picture/UI_Picture_Loading_01'}/>
                        <span className="fs18 ml5 color-black">{t('PROCESSING')}</span>
                    </div> : t('BUY NOW')}
                </ButtonFactory>}
                {done && <ButtonFactory
                    onClick={closeFn}>{subFailded ? noSuccessText : t('TRANSACTION SUCCESSFUL')}</ButtonFactory>}
            </div>
            <AntModal
                width='330px'
                title={t('INSUFFICIENT BALANCE')}
                className="confirmModalWrap"
                centered={true}
                open={showInsufficientCrpto}
                onOk={() => setShowInsufficientCrpto(false)}
                onCancel={() => setShowInsufficientCrpto(false)}
                zIndex={7777}
            >
                <ModalInsufficientCrpto
                    insufficientAmount={Number(stringToNumber(Number(keyPriceWithFee) + Number(fastGasPrice), 4)) - Number(stringToNumber(balance, 4))}
                    userInfo={userInfo} closeFn={() => {
                    setShowInsufficientCrpto(false);
                }}/>
            </AntModal>
        </div>
    )
}

const mapStateToProps = ({app}) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalSweepClub));
