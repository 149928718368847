import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import Banner from "../../../common/Banner";
import {
    stringToNumber,
    convertWalletBalance,
    sliceStr,
    getAvatarFromProfile,
    getTokenBalanceOf,
    copyFn,
    sliceAddr,
    logoutSelf,
    getWeb3Config,
    borwserPlatform,
    isSafari,
    getVideoPlayUrl,
    getVideoUrlFromMap,
} from "@/utils/common"
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import { Tabs, Drawer, message } from "antd";
import { 
    momentClubInfo, 
    getChainInfo, 
    setFavorite, 
    moment_tradelog, 
    video_play_token,
    momentClubList,
    defaultMemeSwitchVideoParams,
    setLike,
} from "@/utils/momentClub";
import { AutoStrangeRule } from "@/utils/strangeRule";
import ModelTrans from "@/model/ModelTrans";
import ModalMemePackTrans from "@/model/ModalMemePackTrans";
import {
    gameMomentClubChatPath,
    gameMomentClubCreatPath,
    gameMomentClubDetail,
    gameMomentClubDetailPath,
    gameMomentClubRevealMomentPackPath,
    gameMomentClubExplorePath,
} from "@/routes/config";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import MemeClubPack from "@/common/MemeClubPack";
import NFTs from "@/views/games/MomentClub/ClubDetail/TGE/NFTs";
import { getBalance } from "@/utils/uniswapWeb3";
import { useWallets, usePrivy } from "@privy-io/react-auth";
import momentChainInfo from "@/utils/json/momentChainInfo.json";
import ModalWalletDisconnect from "@/model/ModalWalletDisconnect";
import MobileBG from "@/assets/video/MobileBG.mp4";
import { backFn } from "@/utils/mianPublic";
import { isOnlineEnv } from "@/utils/env";
import useLocalStorageState from "@/common/useLocalStorageState";

const bottomPartHeight = 72;

const ClubVideo = (
    {
        userInfo,
        clubShortInfo,
        playerRootName,
        videoChangeTimes,
        switchLock,
        videoReady,
        setVideoReady = () => {},
        goPreFn = () => {},
        goNextFn = () => {},
        drawerOpen = false,
        buyDrawerOpen = false,
        hiddenVideo,
        playOrPauseVideoFlag,
        pauseVideoFlag,
        showForceMutedTip,
        setShowForceMutedTip,

        walletBalanceList,
        setWalletBalanceList,
        hideNSFW,
        lastVideoSwitch,
        currentMomentClubId,
        globalVideoMute,
        setGlobalVideoMute,
        switchVideoList,
        setSwitchVideoList,
        videoPreloadString,
    }
) => {
    const { t, i18n } = useTranslation();
    const containerRef = useRef(null);
    const playingVideoUrlRef = useRef(null);
    const { wallets } = useWallets();
    const { logout } = usePrivy()
    const [playingVideoId, setPlayingVideoId] = useState(0);
    const [playingClubId, setPlayingClubId] = useState(0);
    const [isVideoPause, setIsVideoPause] = useState(false);
    const [pauseClickLock, setPauseClickLock] = useState(false);
    const [checkPlay, setCheckPlay] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);

    const [updateMuteState, _setUpdateMuteState] = useState(0);

    const updateMuteStateRef = useRef(updateMuteState);
    const setUpdateMuteState = data =>{
        updateMuteStateRef.current = data;
        _setUpdateMuteState(data);
    }

    const [videoTokenRes, setVideoTokenRes] = useState(null);
    const [videoPlayFlag, setVideoPlayFlag] = useState(false);
    const [videoUrlResData, setVideoUrlResData] = useState(null);
    const [showVideoUICount, setShowVideoUICount] = useState(-1);
    const [showVideoUI, setShowVideoUI] = useState(false);
    const [updateVideoMapCount, setUpdateVideoMapCount] = useState(0);
    //const [videoNo, setVideoNo] = useState(0);//-1:pre, 0:current, 1:next
    const { walletAddr } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const navigate = useNavigate();
    const location = useLocation();

    const playerRef = useRef(null);
    const [playAuthData, setPlayAuthData] = useState(null);

    const [videoWidth, setVideoWidth] = useState(1);
    const [videoHeight, setVideoHeight] = useState(1);
    //const [videoAR, setVideoAR] = useState(1);

    const [videoFill, setVideoFill] = useState(false);

    const videoContainerRef = useRef(null);

    const foregroundVideoElementRef = useRef(null);

    const PRELOAD_VIDEO_COUNT = 2;
    const preloadVideoElementsListRef = useRef([]);


    const updateVideoSize = () =>{
        let screenWidth = Math.min(window.innerWidth, 500);
        let screenHeight = Math.max(drawerOpenLeft ? 0.3 * window.innerHeight : window.innerHeight - bottomPartHeight, 1);
        let screenAR = screenWidth / screenHeight;

        let videoWidth = 1;
        let videoHeight = 1;

        let videoSizeKnown = false;

        if (!videoSizeKnown && clubShortInfo?.videoUrlMap){
            let map = clubShortInfo?.videoUrlMap;
            if (map.SD?.width > 0 && map.SD?.height > 0){
                videoWidth = map.SD?.width;
                videoHeight = map.SD?.height;
                videoSizeKnown = true;
            }
            else if (Object.keys(map)?.length > 0){
                for (let i = 0; i < Object.keys(map)?.length; i++){
                    if (map[Object.keys(map)?.[i]?.toString()]?.width > 0 && map[Object.keys(map)?.[i]?.toString()]?.height > 0){
                        videoWidth = map[Object.keys(map)?.[i]?.toString()]?.width;
                        videoHeight = map[Object.keys(map)?.[i]?.toString()]?.height;
                        videoSizeKnown = true;
                        break;
                    }
                }
            }
        }

        if (!videoSizeKnown && clubShortInfo?.videoOriHeight > 0 && clubShortInfo?.videoOriWidth > 0){
            videoWidth = clubShortInfo?.videoOriWidth;
            videoHeight = clubShortInfo?.videoOriHeight;
            videoSizeKnown = true;
        }

        if (videoSizeKnown){
            setVideoFill(true);

            let tempVideoAR = videoWidth / videoHeight;

            if (screenAR < tempVideoAR){
                setVideoWidth(screenWidth);
                setVideoHeight(screenWidth / tempVideoAR);
            }
            else{
                setVideoWidth(screenHeight * tempVideoAR);
                setVideoHeight(screenHeight);
            }
        }
        else{
            setVideoFill(false);
        }
    }
    
    const handleVideoOnPlayRef = useRef((evt)=>{
        //视频开始播放事件，没啥特殊要处理的，同步一下ui就行了
        try{
            setIsVideoPause(false);

            //console.log('[handleVideoOnPlay] updateMuteState = ', updateMuteStateRef.current);
            setUpdateMuteState(updateMuteStateRef.current + 1);
        }
        catch(e){
            console.error("[playMP4VideoByUrl event play]", e);
        }
    });

    const tryPlayVideo = useRef((foregroundVideo)=>{
        let startPlayPromise = foregroundVideo.play();
        if (startPlayPromise !== undefined) {
        startPlayPromise.then(() => {
                // Start whatever you need to do only after playback has begun.
                setVideoPlayFlag(true);
                setVideoReady(true);

            }) .catch((error) => {
                if (error.name === "NotAllowedError") {
                   
                    console.log("[playMP4VideoByUrl] autoplay count NotAllowedError, try autoplay on muted");

                    foregroundVideo.muted = true;
                    updateMuteStateRef.current = -1; //prevent auto unMute

                    foregroundVideo.play().then(()=>{   //auto play in mute mode
                        try{
                            setGlobalVideoMute(true);
                            setShowForceMutedTip(true);
                            //setIsVideoPause(true);
                        }
                        catch(e){
                            console.error("[playMP4VideoByUrl error on mutedAutoplay]", e);
                        }
                    }).catch((error)=>{
                        console.error("[playMP4VideoByUrl] try muted auto play error", error);
                        setIsVideoPause(true);
                    });
                    
                } else {
                    console.error("[playMP4VideoByUrl] play error", error);
                    setIsVideoPause(true);
                }
            });
        }
    });

    const handleVideoOnCanPlayRef = useRef((evt)=>{
        //视频可以播放事件，浏览器允许播放的话只要让它播放就可以了，如果被阻止自动播放的话就需要尝试静音播放并同步ui状态
        console.log("[playMP4VideoByUrl] canplay evt. try autoplay");
        const foregroundVideo = evt.target;
        tryPlayVideo.current(foregroundVideo);
    })

    const handleVideoOnCanPlayThroughRef = useRef((evt)=>{
        //视频已经完全下载事件，没啥特殊要处理的，再同步一下ui就行了
        setVideoPlayFlag(true);
        setVideoReady(true);
    })

    function playMP4VideoByUrl(url){
        if (!url){
            console.error("[playMP4VideoByUrl] url is null or empty");
            return;
        }

        if (!videoContainerRef?.current){
            console.error("[playMP4VideoByUrl] video container ref is null");
            return;
        }

        setPlayingVideoId(clubShortInfo?.videoId);

        if (foregroundVideoElementRef?.current){
            //播放器已经创建了，尝试复用
            const foregroundVideo = foregroundVideoElementRef.current;
            try{
                const targetURL = url.toString();
                foregroundVideo.pause();
                foregroundVideo.currentTime = 0;

                let reusePreloaded = false;
                if (preloadVideoElementsListRef?.current.length > 0){
                    for (let i = 0; i < PRELOAD_VIDEO_COUNT; i++){
                        const hitVideoElement = preloadVideoElementsListRef.current[i];

                        //console.log(`[playMP4VideoByUrl] find preloaded for [${targetURL}] index[${i}](${hitVideoElement?.currentSrc})`);

                        if (hitVideoElement?.currentSrc === targetURL){
    
                            console.log(`%c[playMP4VideoByUrl] preload matched id[${i}] ${targetURL}`, "color:green");

                            //clear current foreground element
                            foregroundVideo.autoplay = false;
                            foregroundVideo.removeEventListener('canplay', handleVideoOnCanPlayRef.current);
                            foregroundVideo.removeEventListener('canplaythrough', handleVideoOnCanPlayThroughRef.current);
                            foregroundVideo.removeEventListener('play', handleVideoOnPlayRef.current);

                            clearVideoRoot();

                            //swap reference
                            foregroundVideoElementRef.current = hitVideoElement;

                            if (i > 0){
                                preloadVideoElementsListRef.current[i] = preloadVideoElementsListRef.current[i - 1];
                                preloadVideoElementsListRef.current[i - 1] = foregroundVideo;
                            }else{
                                preloadVideoElementsListRef.current[i] = foregroundVideo;
                            }

                            hitVideoElement.autoplay = true;
                            hitVideoElement.currentTime = 0;
                            hitVideoElement.playbackRate = 1.0;
                            hitVideoElement.defaultPlaybackRate = 1.0;
                            videoContainerRef.current.appendChild(hitVideoElement);

                            //make found video element can be played
                            hitVideoElement.addEventListener('canplay', handleVideoOnCanPlayRef.current);
                            hitVideoElement.addEventListener('canplaythrough', handleVideoOnCanPlayThroughRef.current);
                            hitVideoElement.addEventListener('play', handleVideoOnPlayRef.current);
                            
                            tryPlayVideo.current(foregroundVideoElementRef.current);

                            reusePreloaded = true;
                            break;
                        }
                    }
                }

                updateVideoSize();

                if (!reusePreloaded){
                    foregroundVideoElementRef.current.src = targetURL;
                    tryPlayVideo.current(foregroundVideoElementRef.current);
                }
            }
            catch(e){
                console.error("[playMP4VideoByUrl]loadByUrl error", e);
            }
        }
        else{
            //播放器未创建，第一次创建
            console.log("[playMP4VideoByUrl]create vod player");
            
            try{
                const foregroundVideo = document.createElement('video');
                foregroundVideo.style = 'width: 100%; height: 100%';
                foregroundVideo.loop = true;
                foregroundVideo.disablePictureInPicture = true;
                foregroundVideo.playsInline = true;
                foregroundVideo.autoplay = true;
                foregroundVideo.preload = 'auto';
                foregroundVideo.setAttribute('webkit-playsinline','');
                foregroundVideo.setAttribute('x5-playsinline','');
                foregroundVideo.setAttribute('webkitWirelessVideoPlaybackDisabled', 'true');
                foregroundVideo.setAttribute('x-webkit-airplay', 'deny');
                foregroundVideo.setAttribute('controlsList', 'nodownload');
                foregroundVideo.setAttribute('preload','auto');

                foregroundVideo.src = url.toString();

                foregroundVideoElementRef.current = foregroundVideo;

                videoContainerRef.current.appendChild(foregroundVideo);

                //for preload
                for (let i = 0; i < PRELOAD_VIDEO_COUNT; i++){
                    const preloadVideoElement = foregroundVideo.cloneNode(false);
                    preloadVideoElement.autoplay = false;
                    preloadVideoElement.src = '';

                    preloadVideoElementsListRef.current.push(preloadVideoElement);
                }

                //EVENT PROCERSS
                foregroundVideo.addEventListener('canplay', handleVideoOnCanPlayRef.current);
                foregroundVideo.addEventListener('canplaythrough', handleVideoOnCanPlayThroughRef.current);
                foregroundVideo.addEventListener('play', handleVideoOnPlayRef.current);
            }
            catch(e){
                console.error("[new foregroundVideo]", e);
            }
        }
    }

    useEffect(() => {
        if (videoUrlResData && videoUrlResData?.videoId === clubShortInfo?.videoId){
            let videoUrl = getVideoUrlFromMap(videoUrlResData?.urlMap);
            if (videoUrl){
                let tempSwitchVideoList = [...switchVideoList];
                if (tempSwitchVideoList?.length > 0){
                    for (let i = 0; i < tempSwitchVideoList.length; i++){
                        let updateFlag = false;
                        if (videoUrlResData?.videoId.toString() === tempSwitchVideoList[i].videoId.toString()){
                            tempSwitchVideoList[i].videoUrlMap = videoUrlResData?.urlMap;
                            updateFlag = true;
                            break;
                        }
                        //setSwitchVideoList(tempSwitchVideoList);
                    }
                }
                //playMP4VideoByUrl(videoUrl);
            }
            else{
                console.log("no video url yet, under processing, wait");
                setTimeout(() => {
                    setUpdateVideoMapCount(updateVideoMapCount + 1);
                }, 3000);
            }
        }
    }, [videoUrlResData])

    useEffect(() => {
        if (!clubShortInfo || playingVideoId === clubShortInfo?.videoId || !clubShortInfo?.videoId || clubShortInfo?.momentAuditCode === 3){
            return;
        }

        //let tempClub = {...clubShortInfo};
        window.clubShortInfo = clubShortInfo;
        window.tempClub = {...clubShortInfo};

        let videoUrl = getVideoUrlFromMap(clubShortInfo?.videoUrlMap);
        if (videoUrl){
            playMP4VideoByUrl(videoUrl);
        }
        else{
            getVideoPlayUrl(clubShortInfo?.videoId.toString()).then(res => {
                setVideoUrlResData(res);
            }).catch(e => {
                console.error("[getVideoPlayUrl]", e);
            })
        }
    }, [updateVideoMapCount])

    useEffect(() => {
        setUpdateVideoMapCount(updateVideoMapCount + 1);
    }, [clubShortInfo, clubShortInfo?.videoUrlMap]);

    //sync preload array
    useEffect(()=>{
        
        try{
            if (!isVideoPause && foregroundVideoElementRef.current){
                tryPlayVideo.current(foregroundVideoElementRef.current);
            }


            const preloadArray = (videoPreloadString && videoPreloadString.length > 2) ? JSON.parse(videoPreloadString) : [];
            console.log('[videoPreloadString]', preloadArray);

            if (preloadArray.length > 0 && preloadVideoElementsListRef?.current && preloadVideoElementsListRef?.current.length > 0){
                const preloadEleList = preloadVideoElementsListRef.current;
                for (let i = 0; i < preloadArray.length; i++){
                    let found = false;
                    for (let j = 0; j < preloadEleList.length; j++){
                        if (preloadEleList[j] && preloadEleList[j].currentSrc === preloadArray[i]){
                            found = true;
                            break;
                        }
                    }

                    if (!found){
                        for (let j = 0; j < preloadEleList.length; j++){
                            if (preloadEleList[j] && preloadEleList[j].currentSrc === ''){
                                preloadEleList[j].src = preloadArray[i];
                                found = true;
                                break;
                            }
                        }
                    }
                    
                    if (!found && preloadEleList[preloadEleList.length - 1]){
                        preloadEleList[preloadEleList.length - 1].src = preloadArray[i];
                    }
                }
            }

        }catch(e){
            console.error('[effect videoPreloadString]', e);
        };
    }, [videoPreloadString]);


    const [drawerOpenLeft, setDrawerOpenLeft] = useState(false);

    useEffect(() => {
        setDrawerOpenLeft(drawerOpen || buyDrawerOpen);
    }, [drawerOpen, buyDrawerOpen])

    useEffect(() => {

        return () => {
            
            clearVideoRoot();
            
            for (let i = 0; i < PRELOAD_VIDEO_COUNT; i++){
                preloadVideoElementsListRef.current[i] = null;
            }
            preloadVideoElementsListRef.current = null;
            foregroundVideoElementRef.current = null;
        };
    }, []);

    const playOrPauseVideo = () =>{
        try{
            if (foregroundVideoElementRef.current && !pauseClickLock){

                setCheckPlay(false);
                setPauseClickLock(true);

                if (!foregroundVideoElementRef.current.paused){
                    foregroundVideoElementRef.current.pause();
                    setIsVideoPause(true);
                }
                else{
                    foregroundVideoElementRef.current.play();
                    setIsVideoPause(false);
                }
                setTimeout(() => {
                    setPauseClickLock(false);
                }, 200);
            }
        }
        catch(e){
            console.error("[playOrPauseVideo]", e);
        }
    }

    const pauseVideo = () =>{
        try {
            if (foregroundVideoElementRef.current && !foregroundVideoElementRef.current.paused){
                foregroundVideoElementRef.current.pause();
                setIsVideoPause(true);
            }
        }
        catch(e){
            console.error("[videoElement pauseVideo]", e);
        }
    }

    const clearVideoRoot = () =>{
        
        if (videoContainerRef?.current){
            let deleteList = [...videoContainerRef.current.children];
            //console.log('[clearVideoRoot]', deleteList);
            
            for (let i = 0; i < deleteList.length; i++){
                videoContainerRef.current.removeChild(deleteList[i]);
            }
        }
    }

    useEffect(() => {
        try{
            if (foregroundVideoElementRef?.current){
                foregroundVideoElementRef.current.muted = (true === globalVideoMute);
            }
        }
        catch(e){
            console.error("[globalVideoMute effect error]", e);
        }
    }, [globalVideoMute])

    //同步静音状态
    useEffect(() => {
        if (updateMuteState > 0 && foregroundVideoElementRef?.current){
            foregroundVideoElementRef.current.muted = (true === globalVideoMute);
        }
    }, [updateMuteState])

    useEffect(() => {
        if (!showVideoUI && showVideoUICount > 0 && updateCount >= showVideoUICount){
            setShowVideoUI(true);
            setShowVideoUICount(-1);
        }
        if (foregroundVideoElementRef?.current){
            try{
                if (clubShortInfo?.clubId === playingClubId && !globalVideoMute){
                    foregroundVideoElementRef.current.muted = false;
                }
            }
            catch(e){
                console.error("[updateCount unmute]", e)
            }
        }
        setTimeout(() => {
            setUpdateCount(updateCount + 1);
        }, 200);
    }, [updateCount])

    useEffect(() => {
        if (playOrPauseVideoFlag > 0){
            playOrPauseVideo();
        }
    }, [playOrPauseVideoFlag])

    useEffect(() => {
        if (pauseVideoFlag > 0){
            pauseVideo();
        }
    }, [pauseVideoFlag])

    useEffect(() => {
        if (videoPlayFlag){
            setVideoPlayFlag(false);
            setShowVideoUICount(updateCount + 3);
        }
    }, [videoPlayFlag])

    /*useEffect(() => {
        if (videoContainerRef.current){
            videoContainerRef.current.width = '50px';
        }
    }, [])*/

    useEffect(() => {
        updateVideoSize();
    }, [drawerOpenLeft])

    const showVideo = useMemo(() => {
        return !hiddenVideo && clubShortInfo?.momentAuditCode !== 3;
    }, [hiddenVideo, clubShortInfo])

    return (
        <div ref={containerRef} className={`${s.modalWrap} textNoDrag`}>
            <div className={`${s.out} outPart`}>
                <div className={`${s.media} ${drawerOpenLeft ?s.drawerOpenLeft:''}`}>
                    {/*<div className="fs16" style={{zIndex: 999, position: 'relative'}}>
                        {playingUrl}
                    </div>*/}
                    {
                        <>
                            <div className={`${s.videoRoot}`}>
                                <div id={`${playerRootName}`}
                                    className={`${s.bg} ${s.videoContainer} ${!showVideo ? s.hiddenVideo : ''} videoContain`} 
                                    style={{width: `${videoFill?`${videoWidth}px`:`100%`}`, height: `${videoFill?`${videoHeight}px`:`100%`}`}}
                                    ref={videoContainerRef}>
                                </div>
                            </div>
                            {
                                !hiddenVideo &&
                                <div 
                                    className={`${s.dragPart}`}>
                                    {
                                        showVideoUI ?
                                        <>
                                            {
                                                isVideoPause &&
                                                <CustomIcon className={`${s.videoCenterBtn}`} imgName={`Picture/UI_Picture_VideoIcon_play`} width={102} height={102}/>
                                            }
                                        </>:
                                        <>
                                            {/*<CustomIcon className={`${s.videoCenterBtn}`} rotating={true} imgName={`Picture/UI_Picture_Loading_02`} width={80} height={80}/>*/}
                                        </>
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        walletBalanceList: app.walletBalanceList,
        lastVideoSwitch: app.LastVideoSwitch,
        memeclubSwitchVideoParams: app.memeclubSwitchVideoParams,
        switchVideoType: app.switchVideoType,
        switchVideoList: app.switchVideoList,
        switchVideoListCursor: app.switchVideoListCursor,
        hideNSFW: app.hideNSFW,
        currentMomentClubId: app.currentMomentClubId,
        globalVideoMute: app.globalVideoMute,
        videoPreloadString: app.videoPreloadString,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setLastVideoSwitch: (params) => {
            return dispatch({
                type: "app/setLastVideoSwitch",
                payload: params,
            });
        },
        setSwitchVideoType: (params) => {
            return dispatch({
                type: "app/setSwitchVideoType",
                payload: params,
            });
        },
        setGlobalVideoMute: (params) => {
            return dispatch({
                type: "app/setGlobalVideoMute",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ClubVideo));
