import React, {memo, useState, useMemo, useEffect} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {Input, Button, Modal} from "antd";
import {
    officialInviteCode,
    joinChartPre,
    taPush,
    referralcode_bind,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import {useWallets} from "@privy-io/react-auth";
import momentTradeVolSharingRate from "@/utils/json/momentTradeVolSharingRate.json";
import { roundNumber, taskRate } from "@/utils/momentClub";

const ModalMemeSharingDetail = (
    {
        userInfo,
        tradevolInfo,
        closeFn = () =>{}
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const tradeConfig = useMemo(() => {
        let res = null;
        if (momentTradeVolSharingRate?.length > 0 && tradevolInfo){
            for (let i = 0; i < momentTradeVolSharingRate?.length; i++){
                if (momentTradeVolSharingRate[i]?.maxVolume/10000 > (tradevolInfo?.tradeVolEth || 0) || momentTradeVolSharingRate[i]?.sort === -1){
                    res = momentTradeVolSharingRate[i];
                    break;
                }
            }
        }
        return res;
    }, [tradevolInfo]);
    const tradeVolumeShowList = useMemo(() => {
        let list = [];
        if (momentTradeVolSharingRate) {
            if (!tradeConfig || tradeConfig?.level < 4) {
                for (let i = 0; i < Math.min(momentTradeVolSharingRate.length, 4); i++) {
                    list.push(momentTradeVolSharingRate[i]);
                }
            } else {
                let startIndex = tradeConfig?.level - 2;
                if (startIndex > momentTradeVolSharingRate.length - 3) {
                    startIndex = momentTradeVolSharingRate.length - 3;
                }
                for (let i = startIndex; i < startIndex + 3; i++) {
                    list.push(momentTradeVolSharingRate[i]);
                }
            }
        }
        return list;
    }, [tradeConfig]);

    return (
        <div className={`${s.wrap} flex_center_start_col`}>
            <div className="w100p flex_center_center fs20 fb color-EEEEEE" style={{margin:"38px 0 24px"}}>{t('Referral Levels')}</div>
            <div className={`${s.totalLine} flex_center_start`}>
                <span className={`fs16 color-moment-light-gray`}>
                    {t('Current Sharing Levels:')}
                </span>&nbsp;
                <span className={`fs16 color-yellow`}>
                    {tradeConfig?.level || 0}
                </span>
            </div>
            <div className={`${s.des} fs12 color-moment-light-gray`} style={{marginTop:"11px"}}>
                {t('Your level is based on your total trading volume. Higher volumes place you in higher levels, with better benefits. Your level updates automatically with your trading progress.')}
            </div>
            <div className={`${s.totalLine} flex_center_start`} style={{marginTop: '27px'}}>
                <span className={`fs16 color-moment-light-gray`}>
                    {t('Current Sharing Rates:')}
                </span>&nbsp;
                <span className={`fs16 color-yellow`}>
                    {`${(tradeConfig?.sharingRate || 0) / 100}%`}
                </span>
            </div>
            <div className={`${s.des} fs12 color-moment-light-gray`} style={{marginTop:"11px"}}>
                {t('Each level has a different sharing rate. Higher levels offer higher rates and greater potential earnings.')}
            </div>


            <div className={`${s.tradeVolumeTableRoot} flex_center_center`} style={{marginTop:"22px"}}>
                <div className={`${s.tradeVolumeTable} flex_center_start_col`}>
                    <div className={`${s.lineHead} flex_center_between`}>
                        <div className={`${s.colLevel} fs12`}>{t('Level')}</div>
                        <div className={`${s.colVolume} fs12`}>{t('Req. Volume')}</div>
                        <div className={`${s.colRate} fs12`}>{t("Sharing Rate")}</div>
                    </div>
                    {tradeVolumeShowList?.length < 4 && (
                        <div className={`${s.lineValue} flex_center_between`}>
                            <div className={s.colLevel}>...</div>
                            <div className={s.colVolume}>...</div>
                            <div className={s.colRate}>...</div>
                        </div>
                    )}
                    {tradeVolumeShowList?.map((item, index) => (
                        <div key={index} className={`${s.lineValue} ${item.level === tradeConfig?.level ? s.currentLine : s.lineValue} flex_center_between`}>
                            <div className={s.colLevel}>{item.level}</div>
                            <div className={s.colVolume}>{`${roundNumber(item.minVolume / taskRate,4)} ETH`}</div>
                            <div className={s.colRate}>{`${(item.sharingRate || 0) / 100}%`}</div>
                        </div>))
                    }
                </div>
            </div>
            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeFn();
                }} />
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalMemeSharingDetail));
