import React, {memo, useEffect, useRef, useState} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../../common/CustomIcon";
import {countDownTime, getConfig} from "@/utils/common";
import {connect} from "react-redux";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import {gameReferralPath} from "@/routes/config";
import AntButton from "@/common/AntButton";

const ModelLiveTips = (
    {
        hasTrivia = false,
        startTime = 0,
        userInfo,
        revivalCardHoldCount
    }
) => {
    const {t, i18n} = useTranslation();
    const [timeStr, setTimeStr] = useState('')
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeStr(countDownTime(startTime, 0))
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    const navigate = useNavigate();
    const InviteClick = () => {
        navigate(gameReferralPath);
    }

    const [binded, setBinded] = useState(userInfo?.selfData?.bindReferralCode || false);

    return (
        <div className={s.wrap}>
            {!hasTrivia ? <>
                <CustomIcon width={168} height={55} imgName={'TriviaLogo'} name={'TriviaLogo'}/>
                <div className="mt10 fs16 color-white">{t('WELCOME TO TODAY\'S TRIVIA!')}</div>
                <div className="color-gray mb20">{t('Starting in xx', {time: timeStr ? timeStr : '--:--:--'})}</div>
                <div className="fs16 color-white mb20">{t('EXTRA LIVES')}:<CustomIcon className="ml5" width={18}
                                                                                      height={18}
                                                                                      imgName={'Picture/UI_Picture_Title-Blue_ManYellowHeart_01'}
                                                                                      name={'Picture/UI_Picture_Title-Blue_ManYellowHeart_01'}/> X {revivalCardHoldCount}
                </div>
                <div
                    className="fs14 fl mb20">{t('Extra Lives keep you in the game if you give an incorrect answer, giving you another shot at the grand prize!')}</div>
                <div style={{alignSelf: 'flex-start'}}>{t('Want More Extra Lives?')}</div>
                <div
                    className="fs14 fl mb20">{t('Refer more friends! Each friend you refer who joins TURNUP grants on an Extra Life!')}</div>
                <AntButton shape="round" className="btn_public mb10"
                           onClick={InviteClick}>{t('INVITE FRIENDS')}</AntButton>
            </> : <>
                <div className="fs18 mtb20"> {t('Play The Daily Live Trivia')}</div>
                <div className="color-gray mb20">{t('Starting in xx', {time: timeStr ? timeStr : '--:--:--'})}</div>
                <CustomIcon width={168} height={168} imgName={hasTrivia ? 'trivia_yes' : 'trivia_no'}
                            name={hasTrivia ? 'trivia_yes' : 'trivia_no'}/>
                <div
                    className={`${hasTrivia ? 'color-green' : 'color-red'} fs26 fb mt10`}>{hasTrivia ? t('Done') : t('Unfinished')}</div>

                <div className="fs15 mt20 mb10"> {t('Extra Lives')}:<CustomIcon width={18} height={18}
                                                                                imgName={'Picture/UI_Picture_Title-Blue_ManYellowHeart_01'}
                                                                                name={'Picture/UI_Picture_Title-Blue_ManYellowHeart_01'}/> {userInfo?.selfData?.revivalCardHoldCount || 0}
                </div>
                <div className="color-gray mb20 tlc">
                    {t('With Extra Life, you can stay in the game even after an incorrect answer')}
                </div>
                {binded ?
                    <AntButton shape="round" className="btn_public mb10"
                               onClick={InviteClick}>{t('Refer Friends to Earn Extra Lives')}</AntButton>
                    :
                    <AntButton shape="round" className="btn_public mb10"
                               onClick={InviteClick}>{t('Refer Friends to Earn Extra Lives')}</AntButton>
                }</>}

        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelLiveTips));
