import React, { useMemo,useEffect,useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import s from "./index.module.less";
import ButtonFactory from '@/common/ButtonFactory';
import CustomIcon from '@/common/CustomIcon';
import AntButton from "@/common/AntButton";
import ModalPackTransResult from "@/model/ModalPackTransResult";
import ModalMomentBuy from "@/model/ModalMomentBuy";
import ModalMomentSell from "@/model/ModalMomentSell";
import {AutoStrangeRule} from "@/utils/strangeRule";
import { 
    bornCheck_loot,
    convertWalletBalance,
} from '@/utils/common';
import AntModal from "@/common/AntModal";
import { moment_buyresult,getChainInfo } from '@/utils/momentClub';
import { message, Drawer } from 'antd';
import DrawerMomentBuy from '@/model/DrawerMomentBuy';

const TradeKeyPanel = ({
    clubBasicInfo,
    bgColor = null,
    hasBorder = true,
    defaultOpenBuy = false,
    setDefaultOpenBuy = ()=>{},
    setDefaultOpenSell = ()=>{},
    defaultOpenSell = false,
    upDate=()=>{},
    clubId,
    sourceData,
    openBuyDrawer = () => {},
    localOpen = false,
}) => {
    const { t, i18n } = useTranslation();
    const [showPackTransResult, setShowPackTransResult] = useState(false);
    const [momentBuyResult, setMomentBuyResult] = useState(null);
    const [showBuyPacks, setShowBuyPacks] = useState(false);
    const [showSellPacks, setShowSellPacks] = useState(false);
    const [buyDrawerOpen, setBuyDrawerOpen] = useState(false);
    const [memePackBuyDone, setMemePackBuyDone] = useState(false);
    const [memePackBuySubLoading, setMemePackBuySubLoading] = useState(false);
    const [memePackSellDone, setMemePackSellDone] = useState(false);
    const [memePackSellSubLoading, setMemePackSellSubLoading] = useState(false);
    const [buyCheckTimes, setBuyCheckTimes] = useState(0);
    const [buyTx,setBuyTx] = useState('')

    useEffect(()=>{
        defaultOpenBuy && setShowBuyPacks(true)
    },[defaultOpenBuy])

    useEffect(()=>{
        defaultOpenSell && setShowSellPacks(true)
    },[defaultOpenSell])

    const { 
        holdNum, 
        inMarket,
        clubUserId,
        curCardKindNum,
        cardKindMaxNum,
        sellPrice,
        buyPrice,
        clubInfo,
    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])

    const { 
        clubName, 
        imageUrl, 
        coinId, 
        symbolName, 
        symbolImageUrl,
        ftTotalSupply,
        airdropPercent,
        openPackRatio,
        coinToNFTRatio,
        isFT,
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    const checkOrderResult = (tx) =>{
        //TODO orderId???
        const {chainId} = getChainInfo(coinId)
        moment_buyresult(chainId,tx).then(res => {
            if (!res?.cardList?.length > 0) {
                setTimeout(() => {
                    setBuyCheckTimes(buyCheckTimes + 1);
                }, 2000);
            } else {
                console.log("moment_buyresult success", res?.cardList);
                upDate()
                
                setMomentBuyResult(res);
               
            }
        }).catch(e => {
            console.error(e);
            setTimeout(() => {
                setBuyCheckTimes(buyCheckTimes + 1);
            }, 2000);
        })
    }

    

    useEffect(() => {
        if (buyCheckTimes > 0){
            checkOrderResult(buyTx)
        }
    }, [buyCheckTimes]);

    return (
        !inMarket &&
        <div className={`${s.bottomWrap} flex_center_between`}>
            <div 
                className={`${s.sellBtn} flex_center_center`}
                style={{backgroundImage: `url(/UI/Button/UI_Button_Sell_01.svg)`}}
                onClick={() => {
                    if(!holdNum) {
                        message.info({content:t('No moment to sell')})
                        return
                    }
                    setShowSellPacks(true);
                }}>
                <div className={`momentFont fs18 fb color-white lh1`}>
                    {AutoStrangeRule(t('SELL'))}
                </div>
            </div>
            <div className={`${s.middlePart} flex_center_start_col`}>
                <div className={`mt2 momnetFont fs12 color-moment-gray`}>
                    {t('You own:')}
                </div>
                <div className={`flex_center_center mt2`}>
                    <CustomIcon width={32} height={32} imgName={`Picture/UI_Picture_icon_cardpack`} />
                    <span className="fs14 fb momentFont">x</span>
                    <span className="fs20 fb momentFont">
                        {holdNum}
                    </span>
                </div>
            </div>
            <div 
                className={`${s.buyBtn} flex_center_center`} 
                style={{backgroundImage: `url(/UI/Button/UI_Button_Buy_01.svg)`}}
                onClick={() => {
                    if (localOpen){
                        setBuyDrawerOpen(true);
                    }
                    else{
                        openBuyDrawer();
                    }
                }}>
                <div className={`momentFont fs18 fb color-gray lh1`}>
                    {AutoStrangeRule(t('BUY'))}
                </div>
            </div>

            <Drawer
                title=""
                placement={`bottom`}
                closable={false}
                onClose={() => {
                    setBuyDrawerOpen(false)
                    setMemePackBuyDone(false);
                    setMemePackBuySubLoading(false)
                }}
                open={buyDrawerOpen}
                mask={true}
                rootClassName={'momentDrawerWarp'}
                className={'momentDrawer'}
                height={`70%`}
                destroyOnClose={true}
            > 
                <DrawerMomentBuy
                    type={1}
                    tradeMemeClubInfo={{
                        userId: clubUserId,
                        clubName: clubName,
                        coinId: coinId,
                        curCardKindNum: curCardKindNum,
                        cardKindMaxNum: cardKindMaxNum,
                        symbolName: symbolName,
                        holdNum: holdNum,
                        symbolImageUrl: symbolImageUrl,
                        ftTotalSupply: ftTotalSupply,
                        airdropPercent: airdropPercent,
                        openPackRatio: openPackRatio,
                        coinToNFTRatio: coinToNFTRatio,
                        isFT: isFT,
                        buyPrice: buyPrice,
                    }}
                    clubId={clubId}
                    done={memePackBuyDone}
                    setDone={setMemePackBuyDone}
                    subLoading={memePackBuySubLoading}
                    setSubLoading={setMemePackBuySubLoading}
                    closeHandle={() => {
                        setBuyDrawerOpen(false);
                    }}
                    completeFn={(tx) => {
                        setBuyDrawerOpen(false)
                        setMemePackBuyDone(false);
                        setMemePackBuySubLoading(false)
                        upDate()
                        setBuyTx(tx)
                        setShowBuyPacks(false);
                        setShowPackTransResult(true);
                        checkOrderResult(tx)
                    }}
                    sourceData={sourceData}
                />
            </Drawer>
            <AntModal
                width='330px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={showPackTransResult}
                onOk={() => setShowPackTransResult(false)}
                onCancel={() => {
                    setShowPackTransResult(false)
                    setMomentBuyResult(null)
                }}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalPackTransResult
                    transResult={momentBuyResult}
                    clubBasicInfo={clubBasicInfo}
                    closeFn={() => {
                        setShowPackTransResult(false)
                        setMomentBuyResult(null)
                    }}
                />
            </AntModal>
            <AntModal
                width='338px'
                title=''
                className="confirmModalWrap"
                centered={true}
                open={showBuyPacks}
                onOk={() => setShowBuyPacks(false)}
                onCancel={() => {
                    if(!memePackBuySubLoading || memePackBuyDone){
                        setShowBuyPacks(false)
                        setDefaultOpenBuy(false)
                    }
                }}
                destroyOnClose={true}
                zIndex={2000}
                keyboard={!memePackBuySubLoading || memePackBuyDone}
                maskClosable={!memePackBuySubLoading || memePackBuyDone}
            >
                <ModalMomentBuy
                    type={1}
                    tradeMemeClubInfo={{
                        userId: clubUserId,
                        clubName: clubName,
                        coinId: coinId,
                        curCardKindNum: curCardKindNum,
                        cardKindMaxNum: cardKindMaxNum,
                        symbolName: symbolName,
                        holdNum: holdNum,
                        symbolImageUrl: symbolImageUrl,
                        ftTotalSupply: ftTotalSupply,
                        airdropPercent: airdropPercent,
                        openPackRatio: openPackRatio,
                        coinToNFTRatio: coinToNFTRatio,
                        isFT: isFT,
                        buyPrice: buyPrice,
                    }}
                    clubId={clubId}
                    done={memePackBuyDone}
                    setDone={setMemePackBuyDone}
                    subLoading={memePackBuySubLoading}
                    setSubLoading={setMemePackBuySubLoading}
                    closeHandle={() => {
                        setShowBuyPacks(false);
                        setDefaultOpenBuy(false)
                    }}
                    completeFn={(tx) => {
                        setDefaultOpenSell(false)
                        upDate()
                        setBuyTx(tx)
                        setShowBuyPacks(false);
                        setShowPackTransResult(true);
                        checkOrderResult(tx)
                        //TODO check
                    }}
                />
            </AntModal>
            <AntModal
                width='338px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={showSellPacks}
                onOk={() => setShowSellPacks(false)}
                onCancel={() => {
                    if(!memePackSellSubLoading || memePackSellDone){
                        setShowSellPacks(false)
                        setDefaultOpenSell(false)
                    }
                }}
                destroyOnClose={true}
                zIndex={2000}
                keyboard={!memePackSellSubLoading || memePackSellDone}
                maskClosable={!memePackSellSubLoading || memePackSellDone}
            >
                <ModalMomentSell
                    type={2}
                    tradeMemeClubInfo={{
                        userId: clubUserId,
                        clubName: clubName,
                        coinId: coinId,
                        curCardKindNum: curCardKindNum,
                        cardKindMaxNum: cardKindMaxNum,
                        symbolName: symbolName,
                        holdNum: holdNum,
                        symbolImageUrl: symbolImageUrl,
                        ftTotalSupply: ftTotalSupply,
                        airdropPercent: airdropPercent,
                        openPackRatio: openPackRatio,
                        coinToNFTRatio: coinToNFTRatio,
                        isFT: isFT,
                    }}
                    done={memePackSellDone}
                    setDone={setMemePackSellDone}
                    subLoading={memePackSellSubLoading}
                    upDate={upDate}
                    setSubLoading={setMemePackSellSubLoading}
                    closeHandle={() => {
                        setShowSellPacks(false)
                        setDefaultOpenSell(false)
                    }}
                    completeFn={() => {
                        setBuyCheckTimes(buyCheckTimes + 1);
                        setDefaultOpenSell(false)
                    }}
                />
            </AntModal>
        </div>
    )

};

export default TradeKeyPanel;
