import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox, Slider} from 'antd';
import s from "./index.module.less";
import {
    getfriendtrade_rank_selfdata,
    convertWalletBalance,
    searchuser,
    getAvatarFromProfile,
    joinChat,
    displayFilter,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import SearchUser from "src/common/SearchUser";
import AvatarInfo from "src/common/AvatarInfo";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import {
    mainNftNftPanelPath,
    mainClubAllClubsPath,
    balancePath,
    publicWishCreatePath,
} from "@/routes/config";

const SearchUserList = (
    {
        userInfo,
        setUserInfo,
        earnInfo,
        searchIndex,
        searchResult,
        getMoreSearchResult = () => {
        },
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const {buyFirstKey, payEntryFee} = useMemo(() => {
        return userInfo?.selfData || {}
    }, [userInfo]);

    const openOtherTransRecord = (userId) => {
        joinChat(userId, navigate);
    }

    const createWishClick = () => {
        if (!(buyFirstKey && payEntryFee)) {
            navigate(balancePath)
        } else {
            navigate(publicWishCreatePath)
        }
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        }}>
            <div className={s.serarchPanel}>
                {
                    searchResult?.length > 0 ? searchResult.map((item, ind) => {
                        const {
                            displayName,
                            profileDeepLink,
                            accountName,
                            platformType
                        } = item.profile;
                        const avatarUrl = getAvatarFromProfile(item.profile);
                        return (
                            <div className={s.item} key={ind} onClick={() => {
                                openOtherTransRecord(item?.userId, item?.accountType)
                            }}>
                                <div className={s.avatar}>
                                    <AvatarInfo size={40} src={avatarUrl}/>
                                </div>
                                <div className={s.info}>
                                    <div className={s.name}>
                                        {displayFilter(displayName)}
                                        {item?.accountType === "wish" &&
                                            <div className={`${s.wishTag} ml5`}>
                                                {t('WISH')}
                                            </div>}
                                    </div>
                                    {platformType !== 0 && <div className="color-blue-light">
                                        {accountName !== '' && '@'}{accountName}
                                        <CustomIcon width={16} className='ml5' height={16}
                                                    imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                    name={'Button/UI_Button_Ball-White_Twitter_On'}/>
                                    </div>}
                                </div>

                            </div>
                        )
                    }) : <div className="flex_center_start_col mt10">
                        {t('No search results')}
                        <div className={`${s.wishBtn} flex_center_center`} onClick={createWishClick}>
                            {t('Create A Wish')}
                        </div>
                    </div>
                }
                <div className={`${s.loadMore} ${searchIndex !== -1 ? s.show : ''}`}
                     onClick={getMoreSearchResult}>{t('Load More')}</div>
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(SearchUserList));
