import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button} from "antd";
import AvatarInfo from "../../common/AvatarInfo";
import {
    displayFilter, joinChat, PLATFORM_DATA,
    getAvatarFromUserInfo
} from "@/utils/common"
import CustomIcon from "../../common/CustomIcon";
import {useNavigate} from "react-router-dom";
import ButtonFactory from "@/common/ButtonFactory";

const ModelWishCreate = (
    {
        wishUserInfo,
        result
    }
) => {
    const {t, i18n} = useTranslation();
    const {platformData, userId, accountType} = wishUserInfo;
    const {platformMap, useType} = platformData;
    const {displayName, accountName} = platformMap[useType];

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(wishUserInfo)
    }, [platformMap, useType, wishUserInfo])

    const navigate = useNavigate();

    return (
        <div className={s.wrap}>
            <div className="mt10">
                {
                    t('You already created a Wish account')
                }
            </div>
            <div className="mt10">
                {
                    t('You have reached the limit')
                }
            </div>
            <div className="mt10">
                <AvatarInfo src={avatarUrl} size={70}/>
            </div>
            <div className="fs18 fb">{displayFilter(displayName)}</div>
            <div className={s.name}>{accountName} <CustomIcon width={16} height={16}
                                                              imgName={PLATFORM_DATA[useType]?.iconName}
                                                              name={PLATFORM_DATA[useType]?.iconName}/></div>
            <div className="tlc mtb10 color-white">
                {t('New Wishes can only be created after someone has purchased your existing one')}
            </div>
            <ButtonFactory className={`modelBottomBtn`} onClick={() => {
                joinChat(userId, navigate, accountType);
            }}>
                {t('View Details')}
            </ButtonFactory>
        </div>
    )
}
export default memo(ModelWishCreate);
