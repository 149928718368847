import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { supportedLng } from "./utils/env.js";

i18n
    .use(backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        supportedLngs: supportedLng,
        fallbackLng: 'en',
        // debug: true,
        interpolation: {
            escapeValue: false,
        }
    });
/*i18n.addResourceBundle('en', 'testNS', {
    "HelloWorld": "HelloWorldB",
    "TestWorld": "TestWorldB"
});*/

const sitchI18n = (language) =>{
    i18n.changeLanguage(language);
}

const languageEnum = {
    EN: "en",
    ZHTW: "zh",
    PT: "pt",
    JA: "ja"
}

export {i18n, languageEnum, sitchI18n};