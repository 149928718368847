import TaskConf from "@/utils/json/TaskConf.json"
const taskData = TaskConf;
const boostData = [
  {
    'depositBoostPointsCondition':10,
    'depositBoostFactor':12500,
  },{
    'depositBoostPointsCondition':20,
    'depositBoostFactor':15000,
  },{
    'depositBoostPointsCondition':50,
    'depositBoostFactor':25000,
  }
]
const bannerData = {
  link:{
    id:1402,
    points:5000,
  },
  balance:{
    id:1001,
    points:5000,
  }
}
const pathForTaskId = {
  1001:{
    path:'/balance'
  },
  1401:{
    path:'/link'
  },
  1402:{
    path:'/link'
  },
}
const epoch = {
  lastDate:'January 8'
}

export{
  taskData,
  boostData,
  epoch,
  pathForTaskId,
  bannerData
}