import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Slider, Checkbox} from "antd";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";

const HireSort = (
    {
        closeHandle,
        sortKey,
        sortVol,
        setSort,
        getHireList,
        setCheckedArr
    }
) => {
    const {t, i18n} = useTranslation();
    const sortData = [
        {
            label: t('Key Price'),
            key: 1,
        },
        {
            label: t('Club Level'),
            key: 4,
        },
        {
            label: t('Joined Clubs'),
            key: 3,
        },
        {
            label: t('Total Price to be manager'),
            key: 5,
        }
    ]
    const apply = async () => {
        await getHireList("")
        setCheckedArr()
        closeHandle()
    }
    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <CustomIcon width={25} className="mr5" height={25} imgName={'Button/UI_Button_UpAndDown_01'}
                            name={'Button/UI_Button_UpAndDown_01'}/> {AutoStrangeRule(t('SORTING'))}
            </div>
            <div className={s.filterCon}>
                <div className={s.sortConlist}>
                    {sortData?.length && sortData.map(item => {
                        const {label, key} = item;
                        return (
                            <div className={s.sortOption} key={key}>
                                <Checkbox onChange={() => setSort('option', key)} className="hireCheckBox"
                                          checked={sortKey === key} defaultChecked={sortKey === key}>
                                    <span className="">{label}</span>
                                </Checkbox>
                                {/* <div className={s.check}>{sortKey === key &&
                                    <CustomIcon width={19} height={19} imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                        name={'Picture/UI_Picture-Currency_MATIC_01'} />}</div>
                                <div className={s.label}>{label}</div> */}
                            </div>
                        )
                    })}

                </div>
            </div>
            <div className={s.bottom}>
                <AntButton className={`${sortVol === 0 ? 'btn_white' : 'btn_dark'} mb10`}
                           onClick={() => setSort('v', 0)}>
                    {sortVol === 0 && <CustomIcon width={15} height={15}
                                                  imgName={'Button/UI_Button_UP_02'}
                                                  name={'Button/UI_Button_UP_02'}/>}
                    {sortVol === 1 && <CustomIcon width={15} height={15}
                                                  imgName={'Button/UI_Button_UP_01'}
                                                  name={'Button/UI_Button_UP_01'}/>}
                    {t('Ascending')}
                    &nbsp;
                </AntButton>
                <AntButton className={`${sortVol === 1 ? 'btn_white' : 'btn_dark'} mb10`}
                           onClick={() => setSort('v', 1)}>
                    {sortVol === 0 && <CustomIcon width={15} height={15}
                                                  imgName={'Button/UI_Button_Down_01'}
                                                  name={'Button/UI_Button_Down_01'}/>}
                    {sortVol === 1 && <CustomIcon width={15} height={15}
                                                  imgName={'Button/UI_Button_Down_02'}
                                                  name={'Button/UI_Button_Down_02'}/>}
                    {t('Descending')}
                </AntButton>
                <AntButton className="btn_public mb10" onClick={apply}>{t('APPLY')}</AntButton>
                {/* <div onClick={() => setSort('v', 0)} className={`${sortVol === 0 && s.active}`}>Desc</div>
                    <div onClick={() => setSort('v', )} className={`${sortVol === 1 && s.active}`}>↑Asc</div> */}
            </div>

        </div>
    )
}
export default memo(HireSort);
