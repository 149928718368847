import React, {memo, useState, useMemo} from "react";
import s from "./index.module.less";
import {Input, Button, Modal} from "antd";
import {
    unit, 
    siteName, 
    getmatic_market,
    getQuotes, 
    stringToNumber, 
    hainName,
    getChainInfoById,
    convertWalletBalance,
} from "@/utils/common";
import {isOnlineEnv} from "@/utils/env";
import CustomIcon from "../../common/CustomIcon";
import WithdrawDialog from "../WithdrawDialog";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";

const ModelWithdraw = (
    {
        setShowWithdrawPanel,
        userInfo,
        getearninfoFn,
        chainTokenInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const chainInfo = getChainInfoById(chainTokenInfo?.chainId);
    const [toAddr, setToAddr] = useState('');
    const [amount, setAmount] = useState(0);
    const [showWithdrawModel, setShowWithdrawModel] = useState(false);
    const [marketInfo, setMarketInfo] = useState({});

    const {walletAddr} = userInfo || {};
    const toAddrChangeHandler = (event) => {
        setToAddr(event.target.value)
    }
    const amountChangeHandler = (e) => {
        let value = e.target.value.replace(/[^\d-.]/g, '');
        setAmount(value)
    }
    const amountBlurHandler = (e) => {
        setAmount(Math.min(amount, balance))
    }
    const ready = useMemo(() => {
        return toAddr && amount;
    }, [toAddr, amount])
    const transferFn = () => {
        if (!(amount && toAddr)) return
        getQuotes([chainTokenInfo?.coinName]).then(res => {
            setMarketInfo(res);
            setShowWithdrawModel(true)
        }).catch(e => {
            console.log(e);
        })
    }
    const balance = useMemo(() => {
        return stringToNumber(userInfo?.selfData?.balance, 4)
    }, [userInfo])
    return (
        <div className={s.wrap}>
            {/*<div className={s.info}>
        Transfer {unit} from your {siteName} wallet to another wallet on {chainName}.
      </div>*/}
            <div className={s.inputWrap}>
                <Input placeholder={t('Enter Your Wallet Address')} value={toAddr} onChange={toAddrChangeHandler}/>
            </div>
            <div className={s.inputWrap}>
                <Input placeholder={t('Enter amount')} value={amount} onChange={amountChangeHandler}
                       onBlur={amountBlurHandler}/>
            </div>
            <div className={s.balance}>{t('Your balance:')} <span className="color-yellow">{convertWalletBalance(chainTokenInfo?.balance)}</span> {chainInfo?.displayCoinName}</div>
            {/* <div className={`${s.btn} ${s.okey}`} onClick={()=>{setShowWithdrawModel(true)}}>
        Transfer */}
            <div style={{height: 20}}></div>
            <ButtonFactory disabled={!ready || !chainTokenInfo}
                           onClick={transferFn}
                           className="modelBottomBtn"
            >{t('WITHDRAW')}</ButtonFactory>
            {/* </div> */}
            {/* <div className={s.tips} onClick={()=>{setShowWithdrawPanel(false)}}>Close &nbsp;&nbsp;<CustomIcon  width={22} height={18} imgName={'UI_TT_Icon_JianTou'} name={'UI_TT_Icon_JianTou'}/></div> */}
            <AntModal
                width='330px'
                title={t('Withdraw xx From TURNUP', {unit: chainTokenInfo?.displayCoinName})}
                className="confirmModalWrap transModal"
                centered={true}
                open={showWithdrawModel}
                onOk={() => setShowWithdrawModel(false)}
                onCancel={() => setShowWithdrawModel(false)}
                destroyOnClose={true}
            >
                <WithdrawDialog 
                    getearninfoFn={getearninfoFn} 
                    closeHandle={() => {
                        setShowWithdrawModel(false)
                    }} 
                    walletAddr={walletAddr} 
                    marketInfo={marketInfo}
                    amount={amount} 
                    toAddr={toAddr} 
                    balance={balance}
                    chainTokenInfo={chainTokenInfo}/>
            </AntModal>
        </div>
    )
}
export default memo(ModelWithdraw);
