import http from "@/utils/axios";
import {url} from "@/utils/configUri";
import moment from "moment";

const mock = false;

const MAX_LIMIT = 10000;
const DAILY_LFG_POOL = 50000;

export function scoreGroupSum(tweets, type) {
    if (type === 'tweets') {
        return scoreItemSum(tweets, 3, 80, 40, 40, 10, 0.01);
    }

    if (type === 'replies') {
        return scoreItemSum(tweets, 5, 20, 8, 8, 2, 0.002);
    }

    if (type === 'retweets') {
        return scoreItemSum(tweets, 5, 20, 0, 0, 0, 0);
    }

    if (type === 'quotes') {
        return scoreItemSum(tweets, 5, 20, 8, 8, 2, 0.002);
    }

    return 0;
}


export function scoreSum(tweets) {
    let score = 0;
    score += scoreGroupSum(tweets?.tweets, 'tweets');
    score += scoreGroupSum(tweets?.replies, 'replies');
    score += scoreGroupSum(tweets?.retweets, 'retweets');
    score += scoreGroupSum(tweets?.quotes, 'quotes');

    score = score > MAX_LIMIT ? MAX_LIMIT : score;

    return score;
}

export function scoreItemSum(item, maxCount, scoreCount, scoreReply, scoreRetweet, scoreLike, scoreImpression) {
    const currCount = item?.count > maxCount ? maxCount : item?.count;
    let score = 0;
    score += currCount * scoreCount;
    score += item?.reply_count * scoreReply;
    score += item?.retweet_count * scoreRetweet;
    score += item?.like_count * scoreLike;
    score += item?.impression_count * scoreImpression;

    return score
}

export function getCurrentUTCDate() {
    return moment().utc().format('YYYY-MM-DD');
}

export function getPointResult(dateDay) {
    return new Promise((resolve, reject) => {
        // mock
        if (mock) {
            resolve({
                "success": true,
                "content": [
                    {
                        "author_id": "1620965595796333333",
                        "counts": {
                            "tweets": {
                                "count": 3,
                                "retweet_count": 0,
                                "reply_count": 0,
                                "like_count": 0,
                                "quote_count": 0,
                                "bookmark_count": 0,
                                "impression_count": 6
                            },
                            "retweets": {
                                "count": 0,
                                "retweet_count": 0,
                                "reply_count": 0,
                                "like_count": 0,
                                "quote_count": 0,
                                "bookmark_count": 0,
                                "impression_count": 0
                            },
                            "replies": {
                                "count": 0,
                                "retweet_count": 0,
                                "reply_count": 0,
                                "like_count": 0,
                                "quote_count": 0,
                                "bookmark_count": 0,
                                "impression_count": 0
                            },
                            "quotes": {
                                "count": 0,
                                "retweet_count": 0,
                                "reply_count": 0,
                                "like_count": 0,
                                "quote_count": 0,
                                "bookmark_count": 0,
                                "impression_count": 0
                            }
                        }
                    },
                    {
                        "author_id": "1620965595796873224",
                        "counts": {
                            "tweets": {
                                "count": 3,
                                "retweet_count": 0,
                                "reply_count": 0,
                                "like_count": 0,
                                "quote_count": 0,
                                "bookmark_count": 0,
                                "impression_count": 6
                            },
                            "retweets": {
                                "count": 0,
                                "retweet_count": 0,
                                "reply_count": 0,
                                "like_count": 0,
                                "quote_count": 0,
                                "bookmark_count": 0,
                                "impression_count": 0
                            },
                            "replies": {
                                "count": 0,
                                "retweet_count": 0,
                                "reply_count": 0,
                                "like_count": 0,
                                "quote_count": 0,
                                "bookmark_count": 0,
                                "impression_count": 0
                            },
                            "quotes": {
                                "count": 0,
                                "retweet_count": 0,
                                "reply_count": 0,
                                "like_count": 0,
                                "quote_count": 0,
                                "bookmark_count": 0,
                                "impression_count": 0
                            }
                        }
                    }
                ]
            })

            return;
        }

        http('get', "https://turnup-uw-test-apiv2.turnup.so:3200/api/v1/twitter-scrape/points/result", {}).then((res) => {
            if (res.success) {
                resolve(res)
            } else {
                console.log("getPointResult body: " + res)
                reject()
            }
        }).catch(e => {
            console.log("getPointResult error: ", e)
            reject('network error')
        });
    })
}

export async function getPointDetail(selfUserId) {
    const dateDay = getCurrentUTCDate();
    const result = await getPointResult(dateDay);

    const list = result?.content || [];

    let resp = {
        global_points: 0,
        self_data: {
            tweets: {
                "count": 0,
                "retweet_count": 0,
                "reply_count": 0,
                "like_count": 0,
                "quote_count": 0,
                "bookmark_count": 0,
                "impression_count": 0,
                "total_points": 0
            },
            replies: {
                "count": 0,
                "retweet_count": 0,
                "reply_count": 0,
                "like_count": 0,
                "quote_count": 0,
                "bookmark_count": 0,
                "impression_count": 0,
                "total_points": 0
            },
            retweets: {
                "count": 0,
                "retweet_count": 0,
                "reply_count": 0,
                "like_count": 0,
                "quote_count": 0,
                "bookmark_count": 0,
                "impression_count": 0,
                "total_points": 0
            },
            quotes: {
                "count": 0,
                "retweet_count": 0,
                "reply_count": 0,
                "like_count": 0,
                "quote_count": 0,
                "bookmark_count": 0,
                "impression_count": 0,
                "total_points": 0
            },
            sum_points: 0,
            est_lfg: 0,
            rank: 0,
        },
        list: [],
    }

    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        const author_id = item?.author_id;

        if (author_id === "1698194741962391552") {
            continue;
        }

        const sum_points = scoreSum(item?.counts);

        resp.global_points += sum_points;

        resp.list.push({
            author_id,
            sum_points,
        });

        if (author_id === selfUserId) {
            resp.self_data.tweets = item?.counts?.tweets;
            resp.self_data.tweets.total_points = scoreGroupSum(item?.counts?.tweets, 'tweets');
            resp.self_data.replies = item?.counts?.replies;
            resp.self_data.replies.total_points = scoreGroupSum(item?.counts?.replies, 'replies');
            resp.self_data.retweets = item?.counts?.retweets;
            resp.self_data.retweets.total_points = scoreGroupSum(item?.counts?.retweets, 'retweets');
            resp.self_data.quotes = item?.counts?.quotes;
            resp.self_data.quotes.total_points = scoreGroupSum(item?.counts?.quotes, 'quotes');
            resp.self_data.sum_points = sum_points;
        }
    }

    // sort resp.list by sum_points desc
    resp.list.sort((a, b) => b.sum_points - a.sum_points);

    // calculate rank
    for (let i = 0; i < resp.list.length; i++) {
        resp.list[i].rank = i + 1;
        if (selfUserId === resp.list[i].author_id) {
            resp.self_data.rank = i + 1;
        }
    }

    const unitPrePoint = DAILY_LFG_POOL / resp.global_points;

    // calculate est_lfg
    for (let i = 0; i < resp.list.length; i++) {
        resp.list[i].est_lfg = Math.trunc(resp.list[i].sum_points * unitPrePoint);
        if (selfUserId === resp.list[i].author_id) {
            resp.self_data.est_lfg = Math.trunc(resp.self_data.sum_points * unitPrePoint);
        }
    }

    return resp;
}

export const post_uppoints_event = (cursor) => {
    return new Promise((resolve, reject) => {
        // mock
        if (mock) {
            resolve({
                "code": 0, "data": {
                    "nextCursor": -1, "eventList": [
                        {
                            "eventType": 1,
                            "upPoints": 11111111,
                            "rankStr": "5000+",
                            "timeDuration": 100,
                        },
                        {
                            "eventType": 2,
                            "upPoints": 111,
                            "rankStr": "54",
                            "timeDuration": 100,
                        },
                        {
                            "eventType": 1,
                            "upPoints": 111,
                            "rankStr": "500",
                            "timeDuration": 100,
                        },
                        {
                            "eventType": 2,
                            "upPoints": 111,
                            "rankStr": "5",
                            "timeDuration": 100,
                        },
                        {
                            "eventType": 1,
                            "upPoints": 115551,
                            "rankStr": "124",
                            "timeDuration": 100,
                        },
                        {
                            "eventType": 1,
                            "upPoints": 1511,
                            "rankStr": "5000+",
                            "timeDuration": 100,
                        },
                        {
                            "eventType": 1,
                            "upPoints": 1511,
                            "rankStr": "5000+",
                            "timeDuration": 100,
                        },
                        {
                            "eventType": 1,
                            "upPoints": 1511,
                            "rankStr": "5000+",
                            "timeDuration": 100,
                        },
                        {
                            "eventType": 1,
                            "upPoints": 1511,
                            "rankStr": "5000+",
                            "timeDuration": 100,
                        },
                    ]
                }
            })

            return;
        }

        http('post', url.uppoints_event, {
            cursor: cursor,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res)
            } else {
                console.log("post_uppoints_event body: " + res)
                reject(res.code)
            }
        }).catch(e => {
            console.error("post_uppoints_event err", e)
            reject('network error')
        });
    })
}
