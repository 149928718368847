import React, {memo, useState, useMemo} from "react";
import s from "./index.module.less";
import {Button, Modal} from "antd";
import {
    getFriendtradeMintunlocklfg,
    getearninfo,
    TransactionError,
    filterErrorCode,
    convertWalletBalance,
    getTransactionErrorMsgId
} from "@/utils/common";
import {applyMint} from "@/utils/lfgMintWeb3";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import Countdown from "../../common/Countdown";
import {displayLFGPoolRefundTime} from "@/utils/env";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import AntButton from "@/common/AntButton";
import ButtonFactory from "@/common/ButtonFactory";

const ModelWithdrawPool = (
    {
        lockLfgInfo,
        closeFn,
        updateLocklfgInfoFn
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const [web3SubmitDone, setWeb3SubmitDone] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [withdrawErrorMsg, setWithdrawErrorMsg] = useState(null);
    const getUnlockedData = () => {
        if (lockLfgInfo?.stakeTimestamp) {
            var endTime = lockLfgInfo?.stakeTimestamp + 32 * 24 * 60 * 60;
            var date = new Date(endTime * 1000);
            return date.toLocaleDateString();
        } else {
            return '--';
        }
    }
    const applyMintFn = (mintParams) => {
        applyMint({
            wallets,
            amount: mintParams.mintCoin,
            orderId: mintParams.orderId,
            lockedUntil: mintParams.lockSecond,
            timestamp: mintParams.timestamp,
            validFor: mintParams.validFor,
            signature: mintParams.signature
        }).then(res => {
            console.log(res);
            setSubmitLoading(false);
            setWeb3SubmitDone(true);
            setWithdrawErrorMsg(null);
            setTimeout(() => {
                getearninfo().then(res => {
                }).catch(e => {
                    console.log(e);
                })
                updateLocklfgInfoFn();
            }, 1000);
        }).catch(e => {
            console.error(e);
            setSubmitLoading(false);
            setWeb3SubmitDone(false);
            setWithdrawErrorMsg(t(getTransactionErrorMsgId(e?.reason)));
        })
    }
    const applyWithdraw = () => {
        setSubmitLoading(true);
        getFriendtradeMintunlocklfg().then(res => {
            if (res) {
                applyMintFn(res);
            } else {
                setSubmitLoading(false);
                setWithdrawErrorMsg(t('Transaction failed due to other unknown reason'));
            }
        }).catch(e => {
            setSubmitLoading(false);
            setWithdrawErrorMsg(filterErrorCode(e));
            console.log(e);
        });
    }
    return (
        <div className={s.wrap}>
            <div className={s.lfgInfo}>
                <div className={s.lfgLine}>
                    <div className={s.lineTitle}>
                        {t('Claimable $LFG')}
                    </div>
                    <div className={s.lineValue}>
                        <span>
                            {lockLfgInfo?.withDrawableLfg ? lockLfgInfo?.withDrawableLfg.toLocaleString("en-US") : '0'}
                        </span>
                    </div>
                </div>
                <div className={s.lfgLine}>
                    <div className={s.lineTitle}>
                        {t('Locked $LFG')}
                    </div>
                    <div className={s.lineValue}>
                        <span>
                            {lockLfgInfo?.lockedLfg ? lockLfgInfo?.lockedLfg.toLocaleString("en-US") : '0'}
                        </span>
                    </div>
                </div>
                <div className={s.lfgLine}>
                    <div className={s.lineTitle}>
                        {t('Daily $LFG Unlock Rate')}
                    </div>
                    <div className={s.lineValue}>
                        <span>
                            {lockLfgInfo?.releaseLfgPerDay ? lockLfgInfo?.releaseLfgPerDay.toLocaleString("en-US") : '0'}
                        </span>
                    </div>
                </div>
            </div>
            <div className={s.unlockedData}>
                <CustomIcon width={18} height={18} imgName={'locktime_clock'} name={'locktime_clock'}/>
                &nbsp;&nbsp;<span className={s.unlockedDataDes}>{t('Unlock Date')}</span>&nbsp;
                <span className={s.unlockedDataText}>{getUnlockedData()}</span>
            </div>
            <div className={s.errorMsgRoot}>
                {
                    withdrawErrorMsg && <div className={s.errorMessageLine}>
                        <CustomIcon className="m02" width={18} height={18} imgName={'Picture/UI_Picture_RedRound_Cross_01'}
                                    name={'Picture/UI_Picture_RedRound_Cross_01'}/>
                        <span>{withdrawErrorMsg}</span>
                    </div>
                }
            </div>
            {lockLfgInfo?.mintOrderFinishTimeStamp && lockLfgInfo?.mintOrderFinishTimeStamp > 0 ?
                <ButtonFactory
                    className={`${s.btn}`}
                    disabled={true}>
                    {
                        <>
                            {t('Available in:')} <Countdown workEndTimestamp={lockLfgInfo?.mintOrderFinishTimeStamp}
                                                            refresh={() => {
                                                                updateLocklfgInfoFn();
                                                            }}/>
                        </>
                    }
                </ButtonFactory> :
                withdrawErrorMsg ?
                    <ButtonFactory className={`${s.transactionFailedBtn}`} color={1} size={1} scale={1.2}
                                   disabled={true} onClick={closeFn}>
                        <div className="flex_center_center_col">
                            <div className={s.transactionFailedBtnTitle}>
                                {t('TRANSACTION FAILED')}
                            </div>
                            <div className={s.transactionFailedBtnLine}>
                                <CustomIcon className="mr5" width={16} height={16}
                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                            name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                <div className="fs11 fw-300">{t('xx will be refuned after yy minutes', {
                                    lfg: convertWalletBalance(lockLfgInfo?.withDrawableLfg),
                                    time: displayLFGPoolRefundTime
                                })}</div>
                            </div>
                        </div>
                    </ButtonFactory> :
                    submitLoading ?
                        <ButtonFactory disabled={true} className={`${s.btn}`} onClick={() => {
                        }}>
                            <CustomIcon rotating={true} className="ml5 mr5" width={26} height={26}
                                        imgName={'Picture/UI_Picture_Loading_01'}
                                        name={'Picture/UI_Picture_Loading_01'}/>
                            <span className="fs18 color-white">{t('PROCESSING')}</span>
                        </ButtonFactory> :
                        web3SubmitDone ?
                            <ButtonFactory className={`${s.btn}`} onClick={closeFn}>
                                {t('TRANSACTION SUCCESSFUL')}
                            </ButtonFactory> :
                            lockLfgInfo?.withDrawableLfg > 0 ?
                                <ButtonFactory className={`${s.btn}`}
                                               onClick={applyWithdraw}>
                                    {t('WITHDRAW')}
                                </ButtonFactory> :
                                <ButtonFactory className={`${s.btn}`} disabled={true}
                                               onClick={() => {
                                               }}>
                                    {t('WITHDRAW')}
                                </ButtonFactory>
            }
        </div>
    )
}
export default memo(ModelWithdrawPool);
