import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    copyFn,
    getAvatarFromUserInfo,
    getProfile,
    convertUsdValue,
    addJoinChartPre,
    convertWalletBalance,
    getTaskGetreward,
    getuserinfo,
} from "@/utils/common"
import AntModal from "@/common/AntModal";
import {

    getMomentTradevolInfo,
    getTaskInfo,
    goMomentClub,
    roundNumber,
    taskRate
} from "@/utils/momentClub";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntDrawer from "@/common/AntDrawer";
import {
    gameMomentReferralEarnPath,
} from "@/routes/config";
import AvatarInfo from "@/common/AvatarInfo";

import ModalGetInviteCode from "@/model/ModalGetInviteCode";
import ModelReferralShare from "@/model/ModelReferralShare";
import ModalMemeSharingDetail from "@/model/ModalMemeSharingDetail";
import momentTradeVolSharingRate from "@/utils/json/momentTradeVolSharingRate.json";
import * as routePath from "@/routes/config"
import ModalMomentSharingDetail from "@/model/ModalMomentSharingDetail";
import ModalMomentTips from "@/model/ModalMomentTips";
import GetCoinAni from "@/model/GetCoinAni";
import { message } from "antd";
import ModalMomentReferralShare from "@/model/ModalMomentReferralShare";
import ModalMomentTipsRaferralEarned from "@/model/ModalMomentTipsRaferralEarned";
import ModalMomentTipsRaferralTrade from "@/model/ModalMomentTipsRaferralTrade";
import IykykCoinAni from "@/model/IykykCoinAni";
import DealCoinAni from "@/model/DealCoinAni";
const MomentLauncher = (
    {
        userInfo,
        setUserInfo,
        setHideRefreshBtn,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [openSharePanel, setOpenSharePanel] = useState(false);
    const [showGetInviteCode, setShowGetInviteCode] = useState(false);
    const [showMomentSharingDetail, setShowMomentSharingDetail] = useState(false);
    const [tradevolInfo, setTradevolInfo] = useState(null);
    const {
        walletAddr,
        topUpCode,
        userId,
        referralTotalEarn,
        platformData,
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        bindReferralCode,
        referralCode,
        points,
        dealPoints,
        task,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const { platformMap, useType, defaultName } = useMemo(() => {
        return platformData || {}
    }, [platformData]);

    const { displayName, accountName, profileDeepLink } = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData]);

    const { avatarUrl, originalAvatarUrl } = useMemo(() => {
        return getAvatarFromUserInfo(userInfo);
    }, [userInfo]);

    const imageList = useMemo(() => {
        let res = [];
        if (tradevolInfo?.imageUrls?.length > 0) {
            for (let i = 0; i < tradevolInfo?.imageUrls?.length && i < 5; i++) {
                res.push(tradevolInfo?.imageUrls[i]);
            }
        }
        return res;
    }, [tradevolInfo]);

    const tradeConfig = useMemo(() => {
        let res = null;
        if (momentTradeVolSharingRate?.length > 0 && tradevolInfo) {
            for (let i = 0; i < momentTradeVolSharingRate?.length; i++) {
                if ((momentTradeVolSharingRate[i]?.maxVolume / 10000) > (tradevolInfo?.tradeVolEth || 0) || momentTradeVolSharingRate[i]?.sort === -1) {
                    res = momentTradeVolSharingRate[i];
                    break;
                }
            }
        }
        return res;
    }, [tradevolInfo]);
    const getVolumeProgress = () => {
        if (tradeConfig) {
            return Math.min(100, tradevolInfo?.tradeVolEth * 100 / (tradeConfig.maxVolume / 10000));
        }
        return 0;
    }

    const updateMomentTradevolInfo = () => {
        getMomentTradevolInfo({
        }).then(res => {
            setTradevolInfo(res);
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        updateMomentTradevolInfo();
    }, []);

    const converReferralCode = (code) => {
        let tempCode = addJoinChartPre(code);
        if (tempCode?.length > 12) {
            return `${tempCode.substring(0, 6)}...${tempCode.substring(tempCode.length - 6)}`;
        }
        else {
            return tempCode;
        }
    }



    const [taskList, setTaskList] = useState([])
    // const [taskRight, setTaskRight] = useState(null)

    useEffect(() => {
        const list = [];
        task?.oneShotTasks?.map(item => {
            const { taskId, count, finish, haveTakeReward, canTakeReward } = item
            const info = getTaskInfo(taskId)
            if (info?.gameType === 2) {
                const obj = {
                    ...info,
                    taskId,
                    count,
                    finish,
                    haveTakeReward,
                    canTakeReward
                }
                list.push(obj)
            }

        })
        let _list;
        if (list?.length === 0) {
            _list = [
                {
                    taskId: 0,
                    rewardType: 1,
                }, {
                    taskId: 0,
                    rewardType: 6,
                }
            ]
        }
        if (list?.length === 1) {
            if (list[0]?.rewardType === 1) {
                _list = [
                    ...list,
                    {
                        taskId: 0,
                        rewardType: 6,
                    }
                ]
            }
            if (list[0]?.rewardType === 6) {
                _list = [
                    {
                        taskId: 0,
                        rewardType: 1,
                    },
                    ...list,
                ]
            }
        }
        if (list?.length === 2) {
            _list = list?.sort((a, b) => a?.rewardType - b?.rewardType)
        }

        setTaskList(_list)
    }, [task?.oneShotTasks])

    const [showModel, setShowModel] = useState({
        modalRaferralTrade: false,
        modalRaferralEarned: false
    })

    const showModelFn = (obj) => {
        setShowModel({
            ...showModel,
            ...obj
        })
    }

    useEffect(() => {
        console.log({ showModel })
    }, [showModel])

    const [showCoinAni, setShowCoinAni] = useState({
        1: false,
        6: false
    });

    const [showMomentTips, setShowMomentTips] = useState(false)

    const [momentTips, setMomentTips] = useState(null)


    const playGetCoinAni = (rewardType) => {
        const obj = {};
        obj[rewardType] = true
        setShowCoinAni({
            ...showCoinAni,
            ...obj
        });
        setTimeout(() => {
            setShowCoinAni({
                1: false,
                6: false
            });
        }, 1500);
    }
    const takeReward = (id, rewardType) => {
        playGetCoinAni(rewardType);
        getTaskGetreward(id).then(res => {
            if (res?.rewards?.[0]?.num > 0) {
                message.info({
                    content: (<div className="flex_center_center color-black">
                        {t('Successfully claimed reward')}:
                        <CustomIcon width={20} height={20} className="ml2 mr2"
                            imgName={`Picture/${rewardType === 1 ? 'UI_Picture_IYKYK' : 'UI_Picture_MomentCoin'}`} />
                        {res?.rewards?.[0]?.num}
                    </div>)
                });
                
            }
            getuserinfo().then(res => {
                setUserInfo(res)
            }).catch(e => {
                console.log(e);
            })
        }).catch(e => {
            console.error(e);
        });
    }
    const [activeType, setActiveType] = useState(0)

    return (
        <div className={`${s.modalWrap} mainWrap`}>
            <MainHeader leftTitle={t('POINTS')} headerType={3} isGameHeader={true} hasBackBtn={false} openType={2}></MainHeader>
            <div className={s.modal}>
                <div className={s.content}>
                    <div style={{ position: `relative` }}>
                        <div className={`${s.selfInfoRoot} flex_center_start`}>
                            <AvatarInfo isMoment={true} size={65} src={avatarUrl || originalAvatarUrl} />
                            <div className={`${s.selfInfo} f1 flex_center_center_col`}>
                                <div className={`flex_center_between w100p`}>
                                    <div className={`momentFont color-EEEEEE ${s.userName}`}>{displayName}</div>
                                    <div className="flex_center_center position">
                                        <div className={`${s.balanceWrap} mr10`}>
                                            <CustomIcon className="mr5" imgName={`Picture/UI_Picture_IYKYK`} width={13} height={13}></CustomIcon>
                                            <div className="fs12 color-EEEEEE">{convertWalletBalance(points)}</div>
                                            {
                                                showCoinAni[1] &&
                                                <>
                                                    <IykykCoinAni
                                                        className={s.iykykCoinAniRoot}
                                                    ></IykykCoinAni>
                                                </>
                                            }
                                        </div>
                                        <div className={s.balanceWrap}>
                                            <CustomIcon className="mr5" imgName={`Picture/UI_Picture_MomentCoin`} width={13} height={13}></CustomIcon>
                                            <div className="fs12 color-EEEEEE">{convertWalletBalance(dealPoints)}</div>
                                            {
                                                showCoinAni[6] &&
                                                <>
                                                    <DealCoinAni
                                                        className={s.iykykCoinAniRoot}
                                                    ></DealCoinAni>
                                                </>
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className={`${s.sl} w100p flex_center_between`}>
                                    <div className="flex_center_center">
                                        <div className={`momentFont color-EEEEEE ${s.level}`}>
                                            {`${t('Level')}: ${tradeConfig?.level || 0}`}
                                        </div>
                                        <div className={`momentFont color-moment-gray ${s.sharingRate}`}>
                                            {`${t('Sharing Rate')} ${(tradeConfig?.sharingRate || 0) / 100}%`}
                                        </div>
                                    </div>
                                    <CustomIcon width={18} height={18} baseUrl={'/UI/'}
                                        imgName={'Button/UI_Button_QuestionMark_04'} onClick={() => {
                                            setShowMomentSharingDetail(true);
                                        }} />
                                </div>
                            </div>

                        </div>
                        <div className={`${s.volumeProgressRoot}`}>
                            <div className={`${s.progressTitle} flex_center_between momentFont color-moment-light-gray`}>
                                {`${t('Volume')}`}
                                <div className={`fs10 momentFont color-moment-gray`}>
                                    {`${roundNumber(tradevolInfo?.tradeVolEth || 0, 4)}${tradeConfig?.sort === -1 ? '' : `/${roundNumber(tradeConfig?.maxVolume / taskRate || 0, 4)}`} ETH `}
                                </div>
                            </div>
                            <div className={`${s.progressBg} flex_center_start`}>
                                <div className={`${s.progressContent}`} style={{ width: `${getVolumeProgress()}%` }}>
                                </div>
                            </div>
                        </div>
                        <div className={`${s.groupRoot}`}>
                            <div className={`${s.groupTradeBg} flex_center_start_col`}>
                                {
                                    !bindReferralCode ?
                                        <div className={`${s.invitePart} flex_center_center`}>
                                            <div className={`${s.inviteBtn} flex_center_center`} onClick={() => {
                                                setShowGetInviteCode(true);
                                            }}>
                                                <span className="fs14 color-EEEEEE ">
                                                    {t('Invite friends to earn more')}
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        <div className={`${s.invitedPart} flex_center_between`}>
                                            {
                                                imageList?.length > 0 ?
                                                    <>
                                                        <div className={`${s.invitedMemberList}`}>
                                                            {
                                                                imageList.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className={`${s.blackBg}`} style={{ marginLeft: `${index === 0 ? 0 : -32}px`, zIndex: imageList.length - index }} onClick={() => {
                                                                            navigate(gameMomentReferralEarnPath);
                                                                        }}>
                                                                            <AvatarInfo isMoment={true} size={52} src={item} />
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                            <span className={`fs14`}>
                                                                x
                                                            </span>
                                                            <div className={`fs20 lh1`}>
                                                                {tradevolInfo?.referralUserNum || 0}
                                                            </div>
                                                        </div>
                                                        <div className={`flex_center_center_col`}>
                                                            <div className={`flex_center_end`} onClick={() => {
                                                                copyFn(addJoinChartPre(referralCode));
                                                            }}>
                                                                <CustomIcon imgName={`Picture/UI_Picture_Icon_Copy_01`} className="mr4" width={12} height={12}></CustomIcon>
                                                                <span className={`fs14 color-EEEEEE `}>{converReferralCode(referralCode)}</span>
                                                            </div>
                                                            <div className={`w100p mt5 flex_center_end`} onClick={() => {
                                                                setOpenSharePanel(true);
                                                            }}>
                                                                <CustomIcon imgName={`Picture/UI_Picture_Icon_Share_01`} className="mr4" width={12} height={12}></CustomIcon>
                                                                <span className={`fs14 tll w100p color-EEEEEE `}>{t('Share')}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className={`${s.invitedAvatar}`} style={{ backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_02.png)` }} onClick={() => {
                                                            navigate(gameMomentReferralEarnPath);
                                                        }}>
                                                            <CustomIcon imgName={`Button/UI_Button_Explore_Create_01`} width={20} height={20}></CustomIcon>
                                                        </div>
                                                        <span className={`fs16 tlc color-EEEEEE `}>{t('Invite friends to earn more')}</span>
                                                    </>
                                            }
                                        </div>
                                }
                                <div className={`${s.tradePart} flex_center_center `}>

                                    {
                                        taskList?.map(item => {
                                            const {
                                                rewardType,
                                                descriptionType,
                                                bgImagePath,
                                                description,
                                                count,
                                                param,
                                                paramShow,
                                                unitDescription,
                                                homePageDescription,
                                                finish,
                                                failed,
                                                haveTakeReward,
                                                canTakeReward,
                                                rewardNum,
                                                hintType,
                                                hintParam,
                                                taskId,
                                                hintDescription,
                                                multipleType,
                                                conditionType
                                            } = item
                                            // console.log({
                                            //     taskId,
                                            //     count,
                                            //     param
                                            // })
                                            const amount = (conditionType === 106 || conditionType === 107 || conditionType === 108) ? roundNumber(count / taskRate, 4) : roundNumber(count, 4);
                                            const _param = (conditionType === 106 || conditionType === 107 || conditionType === 108) ? param / taskRate : param;
                                            return (
                                                <div key={rewardType}
                                                    className={`${s.task} ${activeType === rewardType ? s['active' + activeType] : ''} flex_center_between_col ${rewardType === 1 ? s.taskL : s.taskR}`}
                                                >
                                                    {
                                                        taskId === 0 ?
                                                            <>
                                                                <div className="flex_center_start_col">
                                                                    <div className="mt30">{t('Congratulations!')}</div>
                                                                    <div className="mt8 color-999 fs10">{t('You have achieved all quests')}</div>
                                                                </div>
                                                                <div className="mt30">
                                                                    <CustomIcon imgName={`Picture/UI_Picture_Icon_Congratulations_01`} width={102} height={102}></CustomIcon>
                                                                </div>
                                                            </> :
                                                            <>
                                                                {
                                                                    descriptionType ?
                                                                        <div className={s.taskT}>
                                                                            <div className="flex_center_center fs14">
                                                                                <CustomIcon className="mr5" imgName={`${bgImagePath}`} imgType=".webp" width={14} height={14}></CustomIcon>
                                                                                {t(homePageDescription)}
                                                                            </div>
                                                                            <div className={`color-999 fs10`} style={{margin:`9px 0 12px`}}>{description}</div>
                                                                            <div className="color-999 fs14">{`${finish ? paramShow : amount}/${paramShow}`}</div>
                                                                        </div>
                                                                        :
                                                                        <div className={s.taskT}>
                                                                            <div className="color-999 fs10">{description}</div>
                                                                            <div className="color-EEEEEE" style={{margin:`11px 0 8px`}}>{finish ? paramShow : amount} {unitDescription}</div>
                                                                            <div className={s.progress}>
                                                                                <div className={s.bg}>
                                                                                    <div className={s.pg} style={{ width: `${amount / _param * 100}%` }}></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="color-999 fs10" style={{marginTop:'12px'}}>{canTakeReward ? t('Goal achieved, get your prize') : t('Target for Next Reward: ') + paramShow + unitDescription}</div>
                                                                        </div>
                                                                }
                                                                <div className="flex_center_start_col">
                                                                    <CustomIcon imgName={`Picture/${rewardType === 1 ? `UI_Picture_IYKYK` : `UI_Picture_MomentCoin`}`} width={58} height={58}></CustomIcon>
                                                                    <div className={s.rewardNum}>
                                                                        <div className="flex_baseline_end fs24"><span className="fs14 mr2">X</span>{multipleType === 1 ? roundNumber((rewardNum / taskRate) * (tradeConfig?.sharingRate / taskRate)) : roundNumber(rewardNum / taskRate)}</div>

                                                                    </div>
                                                                    <div className={s.btn}>
                                                                        {
                                                                            canTakeReward ?
                                                                                <AntButton
                                                                                    onClick={() => {
                                                                                        takeReward(taskId,rewardType)
                                                                                        // playGetCoinAni(rewardType);
                                                                                        setActiveType(rewardType)
                                                                                        setTimeout(() => {
                                                                                            setActiveType(0)
                                                                                        }, 1500)
                                                                                    }}
                                                                                    className={`btn_public`}>
                                                                                    <div className="fs14 color-black">
                                                                                        {t('Claim')}
                                                                                    </div>
                                                                                </AntButton> :
                                                                                <AntButton
                                                                                    onClick={() => {
                                                                                        hintType == 1 && navigate(routePath['' + hintParam])
                                                                                        if (hintType == 2) {
                                                                                            // setShowMomentTips(true)
                                                                                            // setMomentTips({
                                                                                            //     title:homePageDescription,
                                                                                            //     text:hintParam
                                                                                            // })
                                                                                            const obj = {}
                                                                                            obj['' + hintParam] = true;
                                                                                            showModelFn(obj)
                                                                                        }
                                                                                        hintType == 3 && goMomentClub(navigate)

                                                                                    }}
                                                                                    className={`btn_black`}>
                                                                                    <div className="fs14 flex_center_center">
                                                                                        {hintDescription}
                                                                                        {
                                                                                            hintType == 2 &&
                                                                                            <CustomIcon className="ml5" imgName={`Button/UI_Button_QuestionMark_04`} width={17} height={17}></CustomIcon>
                                                                                        }
                                                                                    </div>
                                                                                </AntButton>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>

                                                    }


                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="flex_center_center_col mb40">
                        <AntButton
                            onClick={() => {
                                navigate(gameMomentClubCreatPath);
                            }}
                            className={`${s.createBtn} mt12 btn_transparent`}>
                            <div className="fs18 fb momentFont color-EEEEEE">
                                {t('Create DNA')}
                            </div>
                        </AntButton>
                        <AntButton
                            onClick={() => {
                                navigate(gameMomentClubExplorePath);
                            }}
                            className={`${s.tradeBtn} mt12 btn_public`}>
                            <div className="fs22 fb momentFont color-black">
                                {t('Trade to Earn')}
                            </div>
                        </AntButton>
                    </div> */}
                </div>
            </div>
            <AntModal
                width='330px'
                title={t('')}
                className="momentModalWrap noPaddingModal noImageTitle"
                centered={true}
                open={showMomentSharingDetail}
                onOk={() => setShowMomentSharingDetail(false)}
                onCancel={() => setShowMomentSharingDetail(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMomentSharingDetail
                    tradevolInfo={tradevolInfo}
                    closeFn={() => {
                        setShowMomentSharingDetail(false);
                    }}
                />
            </AntModal>
            <AntModal
                width='330px'
                title={t('')}
                className="momentModalWrap noImageTitle"
                centered={true}
                open={showMomentTips}
                onOk={() => setShowMomentTips(false)}
                onCancel={() => setShowMomentTips(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMomentTips
                    title={momentTips?.title}
                    text={momentTips?.text}
                    closeFn={() => {
                        setShowMomentTips(false);
                    }}
                />
            </AntModal>

            <AntModal
                width='330px'
                title={t('')}
                className="momentModalWrap noImageTitle"
                centered={true}
                open={showModel?.modalRaferralTrade}
                onOk={() => {
                    setShowModel({
                        ...showModel,
                        modalRaferralTrade: false,
                    })
                }}
                onCancel={() => {
                    setShowModel({
                        ...showModel,
                        modalRaferralTrade: false,
                    })
                }}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMomentTipsRaferralTrade
                    closeFn={() => {
                        setShowModel({
                            ...showModel,
                            modalRaferralTrade: false,
                        })
                    }}
                />
            </AntModal>

            <AntModal
                width='330px'
                title={t('')}
                className="momentModalWrap noImageTitle"
                centered={true}
                open={showModel?.modalRaferralEarned}
                onOk={() => {
                    setShowModel({
                        ...showModel,
                        modalRaferralEarned: false,
                    })
                }}
                onCancel={() => {
                    setShowModel({
                        ...showModel,
                        modalRaferralEarned: false,
                    })
                }}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMomentTipsRaferralEarned
                    closeFn={() => {
                        setShowModel({
                            ...showModel,
                            modalRaferralEarned: false,
                        })
                    }}
                />
            </AntModal>

            <AntModal
                width='330px'
                title={t('Who invited you?')}
                className="confirmModalWrap"
                centered={true}
                open={showGetInviteCode}
                onOk={() => setShowGetInviteCode(false)}
                onCancel={() => setShowGetInviteCode(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalGetInviteCode
                    closeFn={() => {
                        setShowGetInviteCode(false);
                    }}
                />
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="momentModalWrap noImageTitle"
                centered={true}
                open={openSharePanel}
                onOk={() => setOpenSharePanel(false)}
                onCancel={() => setOpenSharePanel(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMomentReferralShare 
                    inviteCode={referralCode}
                    closeHandle={() => {
                        setOpenSharePanel(false);
                    }} />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHideRefreshBtn: (params) => {
            return dispatch({
                type: "app/setHideRefreshBtn",
                payload: params,
            });
        },
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MomentLauncher));
