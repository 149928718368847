import React, {memo, useCallback, useEffect, useMemo} from "react";
import s from "./index.module.less";
import {connect} from "react-redux";
import CustomIcon from "../CustomIcon";
import {useState} from "react";
import {Button, Modal, message} from "antd";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import AntButton from "@/common/AntButton";
import {
    copyFn,
    sliceStr,
    stringToNumber,
    unit,
    getuserinfo,
    chainName,
    depositUrl,
    getProfile,
    taPush,
    openMoonPay,
    getQuotes,
    GetSupportedMaticChainTokenInfo,
    getTokenBalanceOf,
} from "@/utils/common";
import ModelDeposit from "../../model/ModelDeposit";
import {useNavigate, useLocation} from "react-router-dom";
import ModelTrans from "../../model/ModelTrans";
import Banner from "../Banner";
import {taskData, bannerData} from "@/utils/taskJson";
import bg from "@/assets/images/bgImg/buyFristKey.png";
import {useTranslation, Trans} from 'react-i18next';
import moonpayImg from "@/assets/images/bgImg/UI_Picture_MoonPay.png";
import {
    moonpayBasicDollar,
    LfgMainnetId,
    LfgTestnetId,
    MaticMainnetId,
    MaticTestnetId,
    isOnlineEnv,
} from "@/utils/env";
import {mainHomePath, gameReferralPath, publicWishLinkPath} from "@/routes/config";
import AntModal from "@/common/AntModal";

const Balance = (
    {
        userInfo,
        setUserInfo,
        setShowBalance,
        initTopUpAmount,
        walletBalanceList,
        setWalletBalanceList,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {wallets} = useWallets();
    const location = useLocation()
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [showTransPanel, setShowTransPanel] = useState(false);
    const [allDoneBuy, setAllDoneBuy] = useState(false);
    const [showEarn, setShowEarn] = useState(false);
    const [maticUsd, setMaticUsd] = useState(0);
    const {userId, topUpCode, walletAddr, platformData, selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo])
    const [done, setDone] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const {balance, buyFirstKey, payEntryFee, bindReferralCode, claimCoin} = useMemo(() => {
        return selfData || {}
    }, [selfData])

    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])

    useEffect(() => {
        setAllDoneBuy(payEntryFee && buyFirstKey)
        if (payEntryFee && buyFirstKey) {
            // if (location.key !== "default") {
            //     navigate(-1);
            // } else {
            if (bindReferralCode) {
                navigate(mainHomePath, {replace: true, state: {showEarn}});
            } else {
                navigate(gameReferralPath);
            }

            // }
        }
    }, [buyFirstKey, payEntryFee])

    useEffect(() => {
        getQuotes(['MATIC']).then(res => {
            setMaticUsd(res?.quotes?.['MATIC']);
        }).catch(e => {
            console.log(e);
        })
    }, [])

    const getBasicMatic = () => {
        if (maticUsd) {
            return Number((moonpayBasicDollar / maticUsd).toFixed(2));
        }
        return moonpayBasicDollar;
    }

    const reFrish = useCallback(() => {
        userId && getuserinfo(userId).then(res => {
            let lfgId = isOnlineEnv() ? LfgMainnetId : LfgTestnetId;
            let maticId = isOnlineEnv() ? MaticMainnetId : MaticTestnetId;
            if (res?.selfData){
                if (walletBalanceList?.[lfgId] > 0){
                    res.selfData.lfgBalance = walletBalanceList?.[lfgId];
                }
                if (walletBalanceList?.[maticId] > 0){
                    res.selfData.balance = walletBalanceList?.[maticId];
                }
            }
            setUserInfo(res);

            let tokenList = [isOnlineEnv()?LfgMainnetId:LfgTestnetId];
            getTokenBalanceOf(res?.walletAddr, tokenList).then(balanceRes => {
                let tempBalance = walletBalanceList || [];
                for (let i = 0; i < tokenList.length; i++) {
                    tempBalance[tokenList[i]] = balanceRes?.balances?.[tokenList[i]];
                }
                setWalletBalanceList(tempBalance);
                if (res?.selfData){
                    res.selfData.lfgBalance = balanceRes?.balances?.[lfgId];
                }
                setUserInfo(res);
            }).catch(err => {
                console.error(err.message);
            })
        }).catch(e => {
            console.log(e);
        })
    }, [userInfo])

    const colsePage = () => {
        localStorage.setItem('_TT_showBalance', false)
        // if (location.key !== "default") {
        //     navigate(-1);
        // } else {
        setShowEarn(true)
        navigate(mainHomePath, {replace: true, state: {showEarn: true}});
        // }
    }


    const buyFn = () => {
        if (balance < initTopUpAmount) {
            message.info({content: `We recommend at least ${initTopUpAmount} ${unit}`})
            return
        }
        setShowTransPanel(true)
    }

    return (
        <>
            <div className={`${s.modalWrap} abs publicBg`}>
                <Banner bgUrl={bg} title={<div className='df aie fs20 mt30'>
                    <Trans
                        i18nKey="CREATE YOUR CLUB TO EARN 200 $LFG"
                        defaults="CREATE YOUR CLUB <br> TO EARN 200 $LFG"
                        components={{br: <br/>}}
                    />
                    <CustomIcon className='ml5' width={20} height={20} imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                name={'Picture/UI_Picture-Currency_LFG_01'}/></div>}/>
                <div className={`${s.modal}`}>
                    {/* <div className={`${s.balanceWrap}`}>
                        <div className="fs26 df alc">{lfgBalance.toLocaleString("en-US") || '--'} $LFG <CustomIcon width={30} height={30} className="ml5" imgName={'Picture/UI_Picture-Currency_LFG_01'} name={'Picture/UI_Picture-Currency_LFG_01'} /></div>
                        <div className="fs12">Already in $LFG Wallet</div>
                    </div> */}
                    <div className="fs14 mb10 fb">
                        <Trans
                            i18nKey="Deposit at least xx yy (on Polygon) to your wallet and create your club"
                            defaults="Deposit at least {{initTopUpAmount}} {{unit}} (on Polygon) <br>to your wallet and create your club"
                            values={{initTopUpAmount: initTopUpAmount, unit: unit}}
                            components={{br: <br/>}}
                        />
                    </div>
                    <div className={`${s.item} mt30`}>
                        <div className={s.contentWrap}>
                            <div
                                className="fs14 mb10 color-blue-light">{t('Deposit to Your TURNUP Wallet Address Below')}</div>
                            <div className={s.addr}>
                                <div className="fs18" style={{wordBreak: 'break-all'}}>{walletAddr}</div>
                                <div className="ml10" onClick={() => {
                                    copyFn(walletAddr)
                                }}><CustomIcon width={18} height={18} imgName={'Button/UI_Button_CopyNumberWordName_01'}
                                               name={'Button/UI_Button_CopyNumberWordName_01'}/>
                                </div>
                            </div>
                            <div className={s.contectLine}>
                            </div>
                            <div className={s.otherPayTitle}>
                                {t('or try')}
                            </div>
                            <div className={s.moonpayRoot} onClick={() => {
                                openMoonPay(wallets, getBasicMatic(), GetSupportedMaticChainTokenInfo())
                            }}>
                                <div className={s.moonpayLine} style={{backgroundImage: `url(${moonpayImg})`}}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.bottom}>
                    {/* <div className="color-red mtb10">Blockchain transactions are slow, please wait.</div> */}
                    {/* <AntButton className={s.btn} loading={loading} onClick={checkEntry} >{loading ? 'Submiting...' : 'Purchase the first key to continue'}</AntButton> */}
                    <div className={s.balanceWrap}>
                        <div className={s.refrish} onClick={reFrish}>
                            <CustomIcon width={25} height={25} isHaveClick={true} imgName={'Button/UI_Button_reload_01'}
                                        name={'Button/UI_Button_reload_01'}/>
                        </div>
                        <div className="fs26">{stringToNumber(balance, '4')} {unit}</div>
                        <div className="fs12">{t('My Wallet Balance')}</div>
                    </div>
                    <div className={s.btnWrap}>
                        <AntButton
                            onClick={() => {
                                allDoneBuy ? colsePage() : buyFn()
                            }}
                            className={`${s.btn} btn_public`}>
                            <span className={"fs16"}>
                                {allDoneBuy ? t('All Done') : t('CREATE CLUB TO EARN 200 $LFG')}
                            </span>
                        </AntButton>

                        <AntButton onClick={() => {
                            taPush('skip_first_buy_key');
                            colsePage();
                        }} className={`${s.btn} btn_white mt20`}>
                            <span className={"fs16"}>{t('MAYBE LATER')}</span>
                        </AntButton>
                        <div className="fs12 mt20">
                            <Trans
                                i18nKey="[Click here] to claim your Wish account"
                                defaults="<click>[Click here]</click> to claim your Wish account"
                                components={{
                                    click: <span
                                        onClick={() => {
                                            navigate(publicWishLinkPath)
                                        }}
                                        className="c-a"
                                    >
                                </span>
                                }}
                            />
                        </div>

                    </div>

                </div>
            </div>
            <AntModal
                width='330px'
                title={t('GET MORE MATIC')}
                className="confirmModalWrap"
                centered={true}
                open={showDepositPanel}
                onOk={() => setShowDepositPanel(false)}
                onCancel={() => setShowDepositPanel(false)}
            >
                <ModelDeposit topUpCode={topUpCode} setShowDepositPanel={setShowDepositPanel}/>
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={showTransPanel}
                destroyOnClose={true}
                onOk={() => setShowTransPanel(false)}
                onCancel={() => setShowTransPanel(false)}
                keyboard={!subLoading || done}
                maskClosable={!subLoading || done}
            >
                <ModelTrans
                    type={1}
                    keyId={userId}
                    profile={profile}
                    isBuyFristKey={true}
                    closeHandle={(showEarn) => {
                        setShowTransPanel(false)
                        setShowEarn(showEarn)
                    }}
                    done={done}
                    setDone={setDone}
                    subLoading={subLoading}
                    setSubLoading={setSubLoading}
                />
            </AntModal>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        initTopUpAmount: app.initTopUpAmount,
        walletBalanceList: app.walletBalanceList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params
            });
        },
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Balance));
// export default memo(Balance);
