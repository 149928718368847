import {memo, useMemo, useState} from "react";
import s from "./index.module.less";
import {useNavigate} from "react-router-dom";
import AvatarInfo from "@/common/AvatarInfo";
import CustomIcon from "@/common/CustomIcon";
import {
    formatNumberWithUnit,
    getPowerXY,
    getAvatarFromProfile,
    getProfile,
    sliceStr
} from "@/utils/common";
import {gameTrillionaireAssetsPath, mainHomePath, mainProfilePath} from "@/routes/config";
import {Button} from "antd";
import {connect} from "react-redux";
import ButtonFactory from "../ButtonFactory";

const GamesUserInfoPanel = ({
                                userInfo,
                            }) => {
    const navigate = useNavigate();
    const {selfData, platformData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo])
    const {power, points, claimCoin, tierId} = selfData || {}
    const [totalValue, setTotalValue] = useState(getPowerXY(power))
    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])
    return (
        <div className={s.gameHeaderWrap}>
            <div className={s.HeaderCommon} onClick={() => navigate(gameTrillionaireAssetsPath)}>
                <div className={s.left}><AvatarInfo size={70} src={getAvatarFromProfile(profile)}/></div>
                <div className={s.right}>
                    <div className={s.rtop}>
                        {sliceStr(profile?.displayName, 'all')}
                        {profile?.profileDeepLink &&
                            <CustomIcon width={16} className='ml5' height={16}
                                        imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                        name={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                    </div>
                    <div className={s.rbottom}>
                        <div className={s.wrap}>
                            {/* {tierId && <CustomIcon
                                className={s.icon}
                                width={28}
                                height={28}
                                imgName={`Picture/Rank/RankStar_${tierId}`}
                                name={`RankStar_${tierId}`}
                            />} */}
                            {/* <div className="df aic">
                                {totalValue ? Number(totalValue).toFixed(2) : '0'}
                                <CustomIcon
                                    className="ml2"
                                    width={20}
                                    height={20}
                                    imgName={`Picture/UI_Picture-Currency_MATIC_01`}
                                    name={`Picture/UI_Picture-Currency_MATIC_01`}
                                />
                            </div> */}

                            <ButtonFactory
                                size={32}
                                color={3}
                                scale={1.2}
                                iconName={'UI_Picture-Currency_MATIC_01'}
                            >
                                <div
                                    className="ml5 color-black fs14">{totalValue ? Number(totalValue).toFixed(2) : '0'}</div>
                            </ButtonFactory>

                        </div>
                        <div className={s.wrap}>
                            <ButtonFactory
                                size={32}
                                color={3}
                                scale={1.2}
                                iconName={'UI_Picture-Currency_UP_01'}
                            >
                                <div className="ml5 color-black fs14">{formatNumberWithUnit(points)}</div>
                            </ButtonFactory>
                        </div>
                        <div className={s.wrap}>
                            <ButtonFactory
                                size={32}
                                color={3}
                                scale={1.2}
                                iconName={'UI_Picture-Currency_LFG_01'}
                            >
                                <div className="ml5 color-black fs14">{formatNumberWithUnit(claimCoin) || '--'}</div>
                            </ButtonFactory>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurRtcInfo: (params) => {
            return dispatch({
                type: "app/setCurRtcInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(GamesUserInfoPanel));
