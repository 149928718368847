
import React, {memo, useMemo, useRef} from "react";
import s from "./index.module.less";
import {Button, message} from "antd";
import CustomIcon from "../../common/CustomIcon";
import {TwitterShareButton} from 'react-twitter-embed';
import {useTranslation, Trans} from 'react-i18next';
import { linkPath, publicQuickLinkMoments } from "@/routes/config";
import { hasBindForType } from "@/utils/common";
import AntButton from "@/common/AntButton";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
function copyFn(text) {
    copyText(text, function () {
            message.success({content: 'copied!'})
        }
    );
}

const copyText = function (button, content, success) {
    if (!button) {
        return;
    }

    if (typeof content == 'function') {
        success = content;
        content = null;
    }

    success = success || function () {
    };

    // 是否降级使用
    var isFallback = !navigator.clipboard;

    if (typeof button == 'string' && !content) {
        if (content === false) {
            isFallback = true;
        }
        content = button;
        button = null;
    }

    var eleTextarea = document.querySelector('#tempTextarea');
    if (!eleTextarea && isFallback) {
        eleTextarea = document.createElement('textarea');
        eleTextarea.style.width = 0;
        eleTextarea.style.position = 'fixed';
        eleTextarea.style.left = '-999px';
        eleTextarea.style.top = '10px';
        eleTextarea.setAttribute('readonly', 'readonly');
        document.body.appendChild(eleTextarea);
    }


    var funCopy = function (text, callback) {
        callback = callback || function () {
        };

        if (!isFallback) {
            navigator.clipboard.writeText(text).then(function () {
                callback();
                // 成功回调
                success(text);
            }, function () {
                // 禁止写入剪切板后使用兜底方法
                copyText(text, false);
                callback();
                // 成功回调
                success(text);
            });

            return;
        }

        eleTextarea.value = text;
        eleTextarea.select();
        document.execCommand('copy', true);

        callback();
        // 成功回调
        success(text);
    };

    var strStyle = '.text-popup { animation: textPopup 1s both; -ms-transform: translateY(-20px); color: #01cf97; user-select: none; white-space: nowrap; position: absolute; z-index: 99; }@keyframes textPopup {0%, 100% { opacity: 0; } 5% { opacity: 1; } 100% { transform: translateY(-50px); }}'

    var eleStyle = document.querySelector('#popupStyle');
    if (!eleStyle) {
        eleStyle = document.createElement('style');
        eleStyle.id = 'popupStyle';
        eleStyle.innerHTML = strStyle;
        document.head.appendChild(eleStyle);
    }

    if (!button) {
        funCopy(content);
        return;
    }

    // 事件绑定
    button.addEventListener('click', function (event) {
        var strCopy = content;
        if (content && content.tagName) {
            strCopy = content.textContent || content.value;
        }
        // 复制的文字内容
        if (!strCopy) {
            return;
        }

        funCopy(strCopy, function () {
            copyText.tips(event);
        });
    });
};

const ModelMomentsShare = (
    {
        clubId,
        clubName,
        userInfo,
        closeHandle = () => {},
    }
) => {
    const {t, i18n} = useTranslation();
    const copyWrap = useRef(null);
    const copy = () => {
        copyFn(copyWrap.current.innerText);
    }
    const navigate = useNavigate();
    const shareWords = [];
    shareWords.push(t('Check out this incredible #Moment I just captured on @TURNUPDOTXYZ!'));
    shareWords.push(`${t('#TURNUP isn’t just about moments—it’s about creating, trading, and owning your most unforgettable experiences. Dive in and see what all the hype is about!')}`);

    const momentLink = useMemo(()=>{
        return `https://alpha.turnup.xyz/#/${publicQuickLinkMoments}/${clubId}/$${clubName?.replace(' ', '')}`
    },[clubId,clubName])

    const hasBindX = useMemo(()=>{
        return hasBindForType('X')
    },[userInfo])

    return (
        <div className={s.wrap}>
            <div className="momentFont fs18 fb color-EEEEEE" style={{lineHeight: '1.0', marginTop: '34px'}}>
                {t('Share intresting Moments')}
            </div>
            <div className="momentFont fs18 fb color-EEEEEE" style={{lineHeight: '1.0', marginTop: '12px'}}>
                {t('Through X(Twitter)')}
            </div>
            <div className={s.copyContent}>
                <div ref={copyWrap} className={`${s.copyContentText}`}>
                    {shareWords[0]}<br/>
                    <br/>
                    {shareWords[1]}<br/>
                    <br/>
                    {momentLink}
                </div>
                <div className={`${s.copy} flex_center_center`} onClick={copy}>
                    <CustomIcon width={25} height={25} imgName={'Picture/UI_Picture_Icon_Copy_01'}/>
                </div>

            </div>
            <br/>
            {hasBindX ? <TwitterShareButton
                onLoad={() => {
                }}
                options={{
                    size: 'large',
                    text: `${shareWords[0]}\n\n${shareWords[1]}\n\n${momentLink}`
                }}
                url={' '}
            />:
            <AntButton onClick={() => {
                navigate(`${linkPath}/${2}`)
            }} className={`btn_public mb20`}>
                <span className={"fs16 momentFont"}>{t('Link X(Twitter)')}</span>
            </AntButton>
            }
            
            {/*<AntButton shape="round" className={`btn_public ${s.okey} mt20`} onClick={()=>{ window.location.href = "https://twitter.com/"; }}>
        POST NOW
        </AntButton>*/}
            {/*<div className="mtb10 fs12">
          Refer friends and secure <span className="color-green fb fs14">100%</span> of what the system gains from them during the event! Start inviting now and reap the rewards
	  </div>*/}
            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeHandle();
                }} />
        </div>
    )
}
const mapStateToProps = ({ app }) => {
    return {
      userInfo: app.userInfo,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      
    }
  }
  export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelMomentsShare));

