import {memo, useMemo, useEffect, useState} from "react";
import {connect} from "react-redux";
import AvatarInfo from "src/common/AvatarInfo";
import CustomIcon from "src/common/CustomIcon";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {
    getProfile,
    getAvatarFromUserInfo,
    getearninfo,
    getPowerXY,
    sliceStr,
    getAvatarFromProfile,
    convertWalletBalance,
    post_global_production_info,
    post_friendtrade_boost_claimcoin,
    getFriendtradeLocklfgInfo,
    sliceAddr,
    getFriendtradeVirtualLfg,
    
} from "@/utils/common";
import {
    getMintAndStakeRequest,
    getMintRequest,
} from "@/utils/lfgMintWeb3";
import s from "./index.module.less";
import store from "@/store";
import TierConfig from "@/utils/json/TierConfig.json"
import BPLevelConfig from "@/utils/json/BPLevelConfig.json"
import MainHeader from "@/common/MainHeader";
import {useTranslation, Trans} from 'react-i18next';
import {Progress, Modal, Button} from "antd";
import ModelTierTip from "@/model/ModelTierTip";
import ModelLfgReview from "@/model/ModelLfgReview";
import {useNavigate} from "react-router-dom";
import {mainHomePath, publicChatsPath} from "@/routes/config";
import ModelBattlePointTip from "@/model/ModelBattlePointTip";
import Countdown from "src/common/Countdown";
import ModelApplyMint from "@/model/ModelApplyMint";
import ModelBoost from "@/model/ModelBoost";
import ModelClubsIntro from "@/model/ModelClubsIntro";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import {AutoStrangeRule} from "@/utils/strangeRule";
import ModelWithdrawLFG from "@/model/ModelWithdrawLFG";
import ModelWithdrawPool from "@/model/ModelWithdrawPool";
import AntButton from "@/common/AntButton";
import ModelBoostResult from "@/model/ModelBoostResult";
import ModelApplyMintNftProtect from "@/model/ModelApplyMintNftProtect";
import ModelProtectedIntro from "@/model/ModelProtectedIntro";
const Assets = ({
                    userInfo,
                    earnInfo
                }) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate()
    const {wallets} = useWallets();
    const {
        walletAddr,
        topUpCode,
        userId,
        referralTotalEarn,
        platformData,
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        boostCD,
        claimCoin,
        nftProtectLfgMaxLimit
    } = useMemo(() => {
        return earnInfo || {}
    }, [earnInfo])
    const {
        balance,
        lfgBalance,
        tierId,
        power,
        points,
        multiplyCardNum,
        battlePoints,
        bpLevel,
        nftProtectLfgPool
    } = useMemo(() => {
        return selfData || {}
    }, [selfData])
    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])
    const {platformMap, useType} = useMemo(() => {
        return platformData || {}
    }, [platformData])
    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformMap, useType, userInfo])

    const [showModelTierTip, setShowModelTierTip] = useState(false)
    const [showBattlePointTip, setShowBattlePointTip] = useState(false);
    const [showApplyMint, setShowApplyMint] = useState(false)
    const [boostLeftSec, setBoostLeftSec] = useState('');
    const [globalProdInfo, setGlobalProdInfo] = useState(false);
    const [showModelBoost, setShowModelBoost] = useState(false);
    const [leftSecLoading, setLeftSecLoading] = useState(true)
    const [friendtradeBoostClaimcoin, setFriendtradeBoostClaimcoin] = useState(null);
    const [showModelBoostResult, setShowModelBoostResult] = useState(false)
    const [showModelClubsIntro, setShowModelClubsIntro] = useState(false)
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [showLfgReview, setShowLfgReview] = useState(false);
    const [lockLfgInfo, setLockLfgInfo] = useState(null);
    const [mintAndStakeRecord, setMintAndStakeRecord] = useState(null);
    const [mintRecord, setMintRecord] = useState(null);
    const [lastMintUpdateTime, setLastMintUpdateTime] = useState(0);
    const [virtualLfg, setVirtualLfg] = useState(-1);
    const [lfgStakeState, setLfgStakeState] = useState(0);
    const [lfgUnlockState, setLfgUnlockState] = useState(0);
    const [showWithdrawLFG, setShowWithdrawLFG] = useState(false);
    const [showWithdrawPool, setShowWithdrawPool] = useState(false);
    const [showModelProtectedIntro,setShowModelProtectedIntro] = useState(false)
    const [showProtectedMint,setShowProtectedMint] = useState(false)
    const getearninfoFn = () => {
        getearninfo().then(res => {
            setBoostLeftSec(res.boostLeftSec)
            setLeftSecLoading(false)
        }).catch(e => {
            console.error(e);
            setLeftSecLoading(false)
        })
    }
    useEffect(() => {
        getearninfoFn()
        updateVirtualLfgInfo();
    }, [])

    const updateMintRequestList = () => {
        let nowTime = new Date().getTime() / 1000;
        if (!lastMintUpdateTime || nowTime - lastMintUpdateTime >= 30) {
            setLastMintUpdateTime(nowTime);
            getMintRequestFn();
            getMintAndStakeRequestFn();
        }
    }

    const getMintRequestFn = () => {
        getMintRequest({
            wallets: wallets
        }).then(res => {
            if (res && res.orderId > 0) {
                setMintRecord(res);
            } else {
                setMintRecord(null);
            }
            console.log('[getMintRequest]', res);
        }).catch(e => {
            console.log(e);
        });
    }

    const getMintAndStakeRequestFn = () => {
        getMintAndStakeRequest({
            wallets: wallets
        }).then(res => {
            if (res && res.orderId > 0) {
                setMintAndStakeRecord(res);
            } else {
                setMintAndStakeRecord(null);
            }
            console.log('[getMintAndStakeRequest]', res)
        }).catch(e => {
            console.log(e);
        });
    }


    const bpProgress = (bpVal) => {
        if (!bpVal) return 0
        const {rangeMin, rangeMax} = BPLevelConfig.find(i => i?.rangeMin <= bpVal && i?.rangeMax >= bpVal) || {};
        return (bpVal - rangeMin) / (rangeMax - rangeMin) * 100
    }

    const powerProgress = (powerVal) => {
        if (!powerVal) return 0
        const {rangeMin, rangeMax} = TierConfig.find(i => i?.rangeMin <= powerVal && i?.rangeMax >= powerVal) || {};
        return (powerVal - rangeMin) / (rangeMax - rangeMin) * 100
    }

    const closeTierTip = () => {
        // setShowModelSweep(true)
        setShowModelTierTip(false)
        navigate(mainHomePath, {state: {tabKey: 'sweep'}})
    }

    const boostClick = () => {
        post_friendtrade_boost_claimcoin().then(data => {
            setShowModelBoostResult(true)
            setShowModelBoost(false)
            setFriendtradeBoostClaimcoin(data);
            getearninfoFn()
            // updateUserInfo({ boostCD: data.boostCD })
        }).catch(e => {
            console.log('post_friendtrade_boost_claimcoin', e);
        })
    }

    const updateVirtualLfgInfo = () => {
        getFriendtradeVirtualLfg().then(res => {
            if (res) {
                setVirtualLfg(res.virtualLFG);
                setLfgStakeState(res.lfgStakeStatus);
                setLfgUnlockState(res.lfgUnlockStatus);
                if (res.lfgStakeStatus === 2) {
                    updateLocklfgInfo();
                }
            }
        }).catch(e => {
            console.log(e);
        });
    }

    const updateLocklfgInfo = () => {
        getFriendtradeLocklfgInfo().then(res => {
            setLockLfgInfo(res);
        }).catch(e => {
            console.log(e);
        });
    }

   

    return (
        <div className={`${s.main} mainWrap commonBlueBg`}>
            <MainHeader isGameHeader={true} headerType={2} hasBackBtn={false} leftTitle={t('Assets')} rightMenuIcon ={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={s.content}>
                <div className={s.head}>
                    <div className={s.avatarInfo}>
                        <AvatarInfo onClick={()=>{navigate(publicChatsPath+'/'+userId)}} size={70} src={avatarUrl || originalAvatarUrl}/>
                        <div className="fs18">
                            {sliceStr(profile?.displayName, 'all')}
                            {profile?.profileDeepLink &&
                                <CustomIcon width={16} className='ml5' height={16}
                                            imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                            name={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                        </div>
                    </div>
                    <div className={s.htop}></div>
                    <div className={s.tier}>

                        <div className={s.block}>
                            <div className={s.l}>
                                <div className={s.li}>
                                    <div className="color-blue-light">{t('Power')}</div>
                                    <div className="df alc jcc">
                                        <CustomIcon width={16} height={16}
                                                    className="mr5"
                                                    imgName={`Picture/UI_Picture_Power_01`}/>
                                        <div className={s.val}>{power}</div>
                                    </div>
                                </div>
                                <div className={s.li}>
                                    <div className="color-blue-light">{t('Club Value')}</div>
                                    <div className="df alc jcc">
                                        <CustomIcon width={16} height={16}
                                                    className="mr5"
                                                    imgName={`Picture/UI_Picture-Currency_MATIC_01`}/>
                                        <div className={s.val}>{getPowerXY(power)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.r}>
                                {/* <CustomIcon width={18} height={18} onClick={() => setShowModelTierTip(true)}
                                            imgName={`Button/UI_Button_QuestionMark_01`}/> */}
                            </div>
                        </div>
                        <div className={s.progressWrap}>
                            <Progress
                                className='assetProgress'
                                size={79}
                                strokeWidth={15}
                                type="circle"
                                percent={powerProgress(power)}
                                format={(percent) => ``}
                                strokeColor="#ffda0a"
                                trailColor="rgba(255,255,255,.5)"
                                strokeLinecap="butt"
                            />

                            <div className={s.cot}>
                                <CustomIcon width={43} height={43}
                                            imgName={`Picture/Rank/RankStar_${tierId}`}/>
                            </div>
                            <div className={s.levelUp}>
                                <ButtonFactory
                                    size={33}
                                    onClick={() => setShowModelTierTip(true)}
                                >{t('LEVEL UP')}</ButtonFactory>
                            </div>
                        </div>
                    </div>
                    <div className={s.bp}>

                        <div className={s.progressWrap}>
                            <Progress
                                className='assetProgress'
                                size={79}
                                strokeWidth={15}
                                type="circle"
                                percent={bpProgress(battlePoints)}
                                format={(percent) => ``}
                                strokeColor="#ffda0a"
                                trailColor="rgba(255,255,255,.5)"
                                strokeLinecap="butt"
                            />

                            <div className={s.cot}>
                                <CustomIcon width={43} height={43}
                                            imgName={`Picture/BPTier/BPTier_${bpLevel || 1}`}/>
                            </div>
                        </div>
                        <div className={s.block}>
                            <div className={s.l}>
                                <div className={s.li}>
                                    <div className="color-blue-light">{t('Battle Points')}</div>
                                    <div className="df alc jcc fb">
                                        <CustomIcon width={18} height={18}
                                                    className="mr5"
                                                    imgName={`Picture/UI_Picture-Currency_Power_01`}/>
                                        {battlePoints || 0}
                                    </div>
                                </div>

                            </div>
                            <div className={s.r}>
                                <CustomIcon width={18} height={18} onClick={() => {
                                    setShowBattlePointTip(true);
                                }}
                                            imgName={`Button/UI_Button_QuestionMark_01`}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.mid}>
                    <div className={s.mi}>
                        <div className={`${s.partTitle} fb`}>
                            <div className={s.l}>
                                <CustomIcon width={17} height={39} className="mr5"
                                            imgName={'Picture/UI_Picture_Title-Yellow_01'}/>
                                {AutoStrangeRule(t('$LFG Pool'))}
                            </div>
                            <div className={`flex_center_start`}>
                                <div className={s.r}>
                                    <CustomIcon width={22} height={22} className="mr5"
                                                imgName={'Picture/UI_Picture_Wallet_01'}/>
                                    <div className="color-blue-light fs12">{sliceAddr(walletAddr, 4)}</div>
                                    <div className="ml20">{convertWalletBalance(lfgBalance)}</div>
                                    <CustomIcon width={22} height={22} className="ml5"
                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}/>
                                </div>
                                <CustomIcon width={22} height={22} className="ml5"
                                            imgName={'Button/UI_Button_BlueRound_Hourglass_01'}
                                            onClick={() => {
                                                updateMintRequestList();
                                                setShowLfgReview(true);
                                            }}/>
                            </div>

                        </div>
                        <div className={s.micon}>

                            <div className={s.lfgblock}>
                                <div className={s.value}>
                                    <div className={`aic ${s.lfgItemInfo}`}>
                                        <CustomIcon width={24} height={24} className=""
                                                    imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        />
                                        <div className="df alc">
                                            <div
                                                className="fs20 color-white mr8">{claimCoin}</div>
                                            <CustomIcon onClick={() => setShowModelClubsIntro(true)} width={21}
                                                        height={21} className="mr5"
                                                        imgName={'Button/UI_Button_QuestionMark_01'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.blockBtn} onClick={() => {
                                    // setShowModelClaim(true)
                                    getearninfoFn();
                                    setShowApplyMint(true)
                                }}>
                                    <CustomIcon width={25} height={25} className="mr5 "
                                                imgName={claimCoin ? 'Button/UI_Button_Extract-White_01' : 'Button/UI_Button_Extract-White_01'}
                                    />
                                </div>
                            </div>
                            <div className={`aic ${s.boostNum}`}>
                                <div className={s.boostNumWrap}>
                                    <div className="df alc">
                                        <div
                                            className="fs14 color-blue-light">{t('BOOSTERS AVAILABLE')}:&nbsp;
                                            <span className="fb">{multiplyCardNum || 0}</span></div>
                                    </div>
                                </div>
                            </div>
                            <ButtonFactory
                                size={22}
                                scale={1.2}
                                className="modelBottomBtn"
                                disabled={boostLeftSec}
                                loading={leftSecLoading}
                                onClick={() =>
                                    post_global_production_info().then(data => {
                                        setGlobalProdInfo(data)
                                        setShowModelBoost(true)
                                    })
                                }>
                                {boostLeftSec ?
                                    <><Countdown refresh={getearninfoFn} leftSecond={boostLeftSec}/></> :
                                    <div className="color-black fs18 fb">
                                        {t('BOOST')}
                                        <CustomIcon width={16} height={16} className="ml5"
                                                    imgName={'Picture/UI_Picture_Title-Blue_Rocket_02'}/>
                                        &nbsp;-&nbsp;1
                                    </div>}
                            </ButtonFactory>


                        </div>
                    </div>
                    <div className={`${s.mi} ${s.mib}`}>
                        <div className={`${s.partTitle} fb`}>
                            <div className={s.l}>
                                <CustomIcon width={17} height={39} className="mr5"
                                            imgName={'Picture/UI_Picture_Title-Yellow_01'}/>
                                {AutoStrangeRule(t('Protected $LFG'))}
                            </div>
                        </div>
                        <div className={s.micon}>

                            <div className={s.lfgblock}>
                                <div className={s.value}>
                                    <div className={`aic ${s.lfgItemInfo}`}>
                                        <CustomIcon width={24} height={24} className=""
                                                    imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        />
                                        <div className="df alc">
                                            <div
                                                className="fs20 color-white mr8">{convertWalletBalance(nftProtectLfgPool)}/{convertWalletBalance(nftProtectLfgMaxLimit)}</div>
                                            <CustomIcon onClick={() => setShowModelProtectedIntro(true)} width={21}
                                                        height={21} className="mr5"
                                                        imgName={'Button/UI_Button_QuestionMark_01'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.blockBtn} 
                                onClick={() => {
                                    // setShowModelClaim(true)
                                    // getearninfoFn();
                                    setShowProtectedMint(true)
                                }}>
                                    <CustomIcon width={25} height={25} className="mr5 "
                                                imgName={nftProtectLfgPool ? 'Button/UI_Button_Extract-White_01' : 'Button/UI_Button_Extract-White_01'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${s.stakeVal}`}>
                    {virtualLfg > 0 && (lfgStakeState === 0 || lfgStakeState === 1) &&
                        <div className={`${s.withdrawFooter} flex_center_between`}>
                            <div className={s.lineString}>
                                <Trans
                                    i18nKey="You have xx $LFG to withdraw or stake"
                                    defaults="You have <yellow>{{flgCount}}</yellow> $LFG to withdraw or stake"
                                    values={{flgCount: virtualLfg > 0 ? Number(virtualLfg.toFixed(2)).toLocaleString("en-US") : '--'}}
                                    components={{yellow: <span className={`color-yellow`}></span>}}
                                />
                            </div>
                            <div className={`${s.withdrawButton} flex_center_center fb`}
                                 style={{width: '60px'}}
                                 onClick={() => {
                                     updateVirtualLfgInfo();
                                     setShowWithdrawLFG(true);
                                 }}>
                                {t('Claim')}
                            </div>
                        </div>
                    }
                    {(lockLfgInfo && (lockLfgInfo?.lockedLfg > 0 || lockLfgInfo?.withDrawableLfg > 0)) &&
                        <div className={`${s.withdrawFooter} flex_center_between`}>
                            <div className={`${s.lineString}`}>
                                <Trans
                                    i18nKey="xx $LFG unlocked can be claimed"
                                    defaults="<yellow>{{flgCount}}</yellow> $LFG unlocked can be claimed"
                                    values={{flgCount: lockLfgInfo?.withDrawableLfg ? Number(lockLfgInfo?.withDrawableLfg.toFixed(2)).toLocaleString("en-US") : '0'}}
                                    components={{yellow: <span className={s.yellowLfg}></span>}}
                                />
                            </div>
                            <div className={`${s.withdrawButton} flex_center_center fb`}
                                 style={{width: '80px'}}
                                 onClick={() => {
                                     setShowWithdrawPool(true);
                                 }}>
                                {t('Withdraw')}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap noCloseBtn"
                centered={true}
                open={showModelTierTip}
                destroyOnClose={true}
                onCancel={() => setShowModelTierTip(false)}
            >
                <ModelTierTip onClose={closeTierTip} power={power}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('BATTLE POINTS 1')}
                className="confirmModalWrap"
                centered={true}
                open={showBattlePointTip}
                destroyOnClose={true}
                onOk={() => setShowBattlePointTip(false)}
                onCancel={() => setShowBattlePointTip(false)}
                zIndex={4000}
            >
                <ModelBattlePointTip userInfo={userInfo} closeFn={() => {
                    setShowBattlePointTip(false)
                }}/>
            </AntModal>
            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={showApplyMint}
                title={t('$LFG Withdrawal')}
                destroyOnClose={true}
                onOk={() => setShowApplyMint(false)}
                onCancel={() => setShowApplyMint(false)}
                zIndex={1999}
            >
                <ModelApplyMint
                    setShowDepositPanel={setShowDepositPanel}
                    lockLfgInfo={lockLfgInfo}
                    updateLocklfgInfoFn={updateLocklfgInfo}
                    closeFn={() => {
                        setShowApplyMint(false)
                    }}
                />
            </AntModal>
            <AntModal
                width='330px'
                title={t('BOOST YOUR $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showModelBoost}
                destroyOnClose={true}
                onCancel={() => setShowModelBoost(false)}
            >
                <ModelBoost claimCoin={claimCoin} boostCD={boostCD} setShowModelBoostResult={setShowModelBoostResult}
                            multiplyCardNum={multiplyCardNum || 0} boostClick={boostClick}
                            globalProdInfo={globalProdInfo}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('BOOST YOUR $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showModelBoostResult}
                destroyOnClose={true}
                onCancel={() => setShowModelBoostResult(false)}
            >
                <ModelBoostResult claimCoin={claimCoin} friendtradeBoostClaimcoin={friendtradeBoostClaimcoin}
                                  setShowModelBoostResult={setShowModelBoostResult}/>
            </AntModal>
            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={showModelClubsIntro}
                destroyOnClose={true}
                onOk={() => setShowModelClubsIntro(false)}
                onCancel={() => setShowModelClubsIntro(false)}
                zIndex={4000}
            >
                <ModelClubsIntro setShowModelClubsIntro={setShowModelClubsIntro}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('$LFG: Mint & Withdrawal Status')}
                className="confirmModalWrap"
                centered={true}
                open={showLfgReview}
                onOk={() => setShowLfgReview(false)}
                onCancel={() => setShowLfgReview(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModelLfgReview
                    mintRecord={mintRecord}
                    mintAndStakeRecord={mintAndStakeRecord}
                    closeFn={() => {
                        setShowLfgReview(false);
                    }}
                    getMintRequestFn={getMintRequestFn}
                    getMintAndStakeRequestFn={getMintAndStakeRequestFn}
                />
            </AntModal>
            <AntModal
                width='330px'
                title={t('Claim unlocked $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showWithdrawLFG}
                destroyOnClose={true}
                onOk={() => {
                    updateVirtualLfgInfo();
                    setShowWithdrawLFG(false);
                }}
                onCancel={() => {
                    updateVirtualLfgInfo();
                    setShowWithdrawLFG(false);
                }}
                zIndex={2000}
            >
                <ModelWithdrawLFG
                    walletAddr={walletAddr}
                    setShowWithdrawLFG={setShowWithdrawLFG}
                    virtualLfg={virtualLfg}
                    lfgStakeState={lfgStakeState}
                    updateVirtualLfgInfo={updateVirtualLfgInfo}
                    getMintAndStakeRequestFn={getMintAndStakeRequestFn}
                    userInfo={userInfo}
                />
            </AntModal>
            <AntModal
                width='330px'
                title={t('WITHDRAW')}
                className="confirmModalWrap"
                centered={true}
                open={showWithdrawPool}
                destroyOnClose={true}
                onOk={() => {
                    setShowWithdrawPool(false);
                    updateLocklfgInfo();
                }}
                onCancel={() => {
                    setShowWithdrawPool(false);
                    updateLocklfgInfo();
                }}
                zIndex={2000}
            >
                <ModelWithdrawPool
                    lockLfgInfo={lockLfgInfo}
                    closeFn={() => {
                        setShowWithdrawPool(false);
                        updateLocklfgInfo();
                    }}
                    updateLocklfgInfoFn={updateLocklfgInfo}
                />
            </AntModal>

            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={showProtectedMint}
                title={t('$LFG Withdrawal')}
                destroyOnClose={true}
                onOk={() => setShowProtectedMint(false)}
                onCancel={() => setShowProtectedMint(false)}
                zIndex={1999}
            >
                <ModelApplyMintNftProtect
                    setShowDepositPanel={setShowDepositPanel}
                    lockLfgInfo={lockLfgInfo}
                    updateLocklfgInfoFn={updateLocklfgInfo}
                    closeFn={() => {
                        setShowProtectedMint(false)
                    }}
                />
            </AntModal>

            <AntModal
                width='330px'
                title={t('PROTECTED $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showModelProtectedIntro}
                destroyOnClose={true}
                onOk={() => setShowModelProtectedIntro(false)}
                onCancel={() => setShowModelProtectedIntro(false)}
                zIndex={4000}
            >
                <ModelProtectedIntro/>
            </AntModal>
            
        </div>
    );
};

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params,
            });
        },
    };
};
export default memo(connect(mapStateToProps, mapDispatchToProps)(Assets));
