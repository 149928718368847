import React, {useRef, useState, memo, useEffect, useCallback, useMemo} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button, Input} from "antd";
import CustomIcon from "../CustomIcon";
import {
    createMsg,
    deliverMsg,
    convertToMessage,
    clgFn,
    getAvatarFromUserInfo
} from "@/utils/common";
import {connect} from "react-redux";
import {CloseCircleOutlined} from '@ant-design/icons';

const {TextArea} = Input;

const MomentMessageInput = (props) => {
    const {t, i18n} = useTranslation();
    const {
        userInfo,
        fromId,
        quote,
        setQuote,
        chatType,
        groupId,
        scrollBottom,
        webIMLogined,
        children,
        clubId,
        openMoreFn,
        dropdownOpen,
        hideMoreIcon = false,
        rightIcon = null,
        rightIconOnClick = null,
        setDropdownOpen
    } = props;
    const [text, setText] = useState("");
    const inputRef = useRef();
    const quoteStr = useMemo(() => {
        if (JSON.stringify(quote) === "{}") return ''
        const {msg, ext, time, from, id} = quote;
        return `${ext?.displayName}: ${msg}`
    }, [quote])

    const {platformMap, useType, defaultName} = useMemo(() => {
        return userInfo?.platformData || {}
    }, [userInfo])

    const {displayName} = useMemo(() => {
        if (platformMap && JSON.stringify(platformMap) !== '{}') {
            return platformMap[useType]
        } else {
            return {
                displayName: defaultName
            }
        }
    }, [platformMap, useType, defaultName])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformMap, useType, userInfo])

    //发消息
    const sendMessage = useCallback(() => {
        clgFn('sendMessage pre', '', `${text} ${groupId} ${webIMLogined}`)
        if (!convertToMessage(text) || !groupId || !webIMLogined) return;
        let msg = createMsg({
            chatType,
            type: "txt",
            to: groupId,
            msg: convertToMessage(text),
            isChatThread: false,
            ext: {
                avatarUrl: avatarUrl || '',
                displayName: displayName || '',
                quote,
                text: convertToMessage(text),
            }
        });

        setText("");
        inputRef.current.focus({cursor: 'end'})
        setQuote({});
        msg.localId = msg.id;
        console.log(msg);
        deliverMsg({msg, needShow: true, keyId:fromId},{
            clubUserId:clubId,
            userId: userInfo?.userId,
            msg: convertToMessage(text)
            
        })
        scrollBottom()
    }, [text, chatType, groupId, userInfo]);


    const textHandler = (event) => {
        setText(event.target.value)
    }
    return (
        <div className={`${s.controlWrap}`}>
            {/* <div className="mr5">
                <CustomIcon width={39} className={"mr5"} height={36} imgName={dropdownOpen ? 'Button/UI_Button_ChatNameList_01_On' : 'Button/UI_Button_ChatNameList_01'}
                    onClick={()=>{setDropdownOpen(!dropdownOpen)}}/>
            </div> */}
            <div className={s.input}>
                <TextArea
                    placeholder={t('Say something…')}
                    value={text}
                    className={`${s.InviteCode} momentFont`}
                    onChange={textHandler}
                    rows={1}
                    ref={inputRef}
                    autoSize={{minRows: 1, maxRows: 3}}
                    // style={{
                    //   height: 30,
                    //   resize: 'none',
                    // }}
                />
                {quoteStr && <div className={s.quote}>
                    <div className="fs12">
                        {quoteStr}
                    </div>
                    <CloseCircleOutlined className={s.close} onClick={() => {
                        setQuote({})
                    }}/>
                </div>}
            </div>
            <div className="">
                {children}
            </div>
            <div className="ml5">
                {/* <AntButton >Send</AntButton> */}
                <CustomIcon width={24} height={35} imgName={'Button/UI_Button_SendMessage_01'}
                            onClick={sendMessage}/>
                {!hideMoreIcon && <CustomIcon width={26} className={"ml5"} height={35} imgName={'Button/UI_Button_CirclePlus-White_01'}
                            onClick={openMoreFn}/>} 
                {hideMoreIcon && rightIcon && <CustomIcon width={26} className={"ml12"} height={35} imgName={rightIcon}
                            onClick={rightIconOnClick}/>}
            </div>
        </div>
    );
};

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        webIMLogined: app.webIMLogined,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateChatMessageUrl: (params) => {
            return dispatch({
                type: "app/updateChatMessageUrl",
                payload: params
            });
        },
    };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(MomentMessageInput));
