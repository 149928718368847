import React, {memo, useCallback, useEffect, useMemo, useRef} from "react";
import s from "./index.module.less";
import {ethers, BigNumber} from "ethers";
import {connect} from "react-redux";
import CustomIcon from "../../common/CustomIcon";
import ModelBuyFristKeyTips from "../ModelBuyFristKeyTips";
import {
    keyBuy,
    unit,
    stringToNumber,
    sliceStr,
    keySell,
    updateSelfUser,
    updateOtherUser,
    getPrice,
    TransactionError,
    toAsyncAwait,
    gettrade_price, PrivyLoginStatusError, logoutSelf,

    copyFn,
    displayFilter,
    getAvatarFromUserInfo, sliceAddr
} from "@/utils/common";
import {useState} from "react";
import {url} from "@/utils/configUri";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {Button, Input, Modal} from 'antd';
import AvatarInfo from "../../common/AvatarInfo";
import http from "@/utils/axios";
import ModelDeposit from "../ModelDeposit";
import ModalInsufficientCrpto from "src/model/ModalInsufficientCrpto";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";
import CrackModelHistoryActivities from "../CrackModelHistoryActivities";
import GasSetting from "@/model/GasSetting";

const ModelTrans = (
    {
        closeHandle,
        type,
        userInfo,
        keyId,
        curentOtherUserInfo,
        profile,
        accountType = 'key',
        done,
        setDone,
        subLoading,
        setSubLoading,
        isBuyFristKey,
        defaultNum = 1,
        setShowGasMode,
    }
) => {
    const {t, i18n} = useTranslation();
    const transType = {
        1: {
            fn: keyBuy,
            str: t('Buy')
        },
        2: {
            fn: keySell,
            str: t('Sell')
        }
    }
    const inputRef = useRef(null)
    const [subFailded, setSubFailded] = useState(false);
    const [noSuccessText, setNoSuccessText] = useState(transType[type]?.str + ' ' + t('Failed'));
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const {displayName, accountName, profileDeepLink} = profile || {};
    const [keyNum, setKeyNum] = useState(isBuyFristKey ? 5 : defaultNum);
    const [getPriceLoding, setGetPriceLoding] = useState(false);
    const [showEarn, setShowEarn] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [info, setInfo] = useState({});
    const [showBuyFirstKeyTips, setShowBuyFirstKeyTips] = useState(true);
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [showTips, setShowTips] = useState(false);
    const {fastGasPrice, keyAddr, keyPrice, maticUsd, contractAddr, keyPriceWithFee} = useMemo(() => {
        return info || {}
    }, [info]);
    const {walletAddr, selfData, topUpCode} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {buyFirstKey, balance} = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(curentOtherUserInfo)
    }, [curentOtherUserInfo])
    const {logout} = usePrivy();
    const closeFn = () => {
        if (noSuccessText === PrivyLoginStatusError) {
            logout();
            logoutSelf();
        }
        setSubLoading(false)
        setDone(false)
        closeHandle(showEarn)
    }
    const dataInit = useMemo(() => {
        if (fastGasPrice && balance && walletAddr) {
            return true
        }
        return false
    }, [fastGasPrice, balance, walletAddr])

    const {wallets} = useWallets();
    const transFn = () => {
        if (!isAvailable) {
            setShowInsufficientCrpto(true)
            return
        }
        if (showBuyFirstKeyTips && isBuyFristKey && keyNum < 5) {
            setShowTips(true)
            setShowBuyFirstKeyTips(false)
            return
        }
        if (!dataInit || subLoading) return
        setSubLoading(true);
        transType[type]?.fn(wallets, keyId, keyAddr, keyNum, keyPriceWithFee).then(res => {
            !buyFirstKey && http('post', url.check_buyfirstkey, {}).then((res) => {
                setShowEarn(true)
            }).catch(e => {
                console.log(e);
            });

            setSubLoading(false);
            setDone(true);
            setSubFailded(false)
            setTimeout(
                () => {
                    updateSelfUser()
                    updateOtherUser(keyId)
                    getPrice(keyId);
                }, 2000
            )
        }).catch(e => {
            console.log(e);
            // if(e.code){
            //   setNoSuccessText(`failed error code ${e.code}`)
            // }
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }

            if (e === 'need login') {
                setNoSuccessText(PrivyLoginStatusError)
            }

            setSubLoading(false);
            setDone(true);
            setSubFailded(true)
            setTimeout(
                () => {
                    updateSelfUser()
                    updateOtherUser(keyId)
                    getPrice(keyId);
                }, 2000
            )
        })

    }

    const showMoreInfoFn = () => {
        setShowMoreInfo(!showMoreInfo)
    }

    const controlAmount = (control) => {
        if (control === 0 && keyNum > 1) {
            let _keyNum = keyNum - 1;
            if (type === 2) {
                _keyNum = Math.min(_keyNum, youOwnKeyNum)
            }
            setKeyNum(_keyNum)
        }
        if (control === 1) {
            // setKeyNum(keyNum => {
            let _keyNum = keyNum + 1;
            if (type === 2) {
                _keyNum = Math.min(_keyNum, youOwnKeyNum)
            }
            //   return _keyNum
            // })
            setKeyNum(_keyNum)
        }
    }

    const {youOwnKeyNum} = useMemo(() => {
        return curentOtherUserInfo || {}
    }, [curentOtherUserInfo])

    useEffect(() => {
        getKeyPrice()
    }, [keyNum])

    const isAvailable = useMemo(() => {
        return Number(totalPrice) < Number(balance)
    }, [balance, totalPrice])

    const getKeyPrice = useCallback(() => {
        setGetPriceLoding(true)
        gettrade_price(type, keyId, keyNum).then(res => {
            setGetPriceLoding(false);
            // setTotalPrice(res?.keyPriceWithFee)
            // console.log(res);
            setInfo({...res})
        }).catch(e => {
            setGetPriceLoding(false)
            console.log(e);
        })
    }, [keyNum, type, keyId])

    const amountChangeHandler = (e) => {
        let value = e.target.value.replace(/[^\d]/g, '');
        console.log(value);
        setKeyNum(value);

    }
    const [keyNumInput, setKeyNumInput] = useState(false)
    const amountBlurHandler = (e) => {
        setKeyNumInput(false)
        setKeyNum(Math.max(keyNum, 1))
        if (type === 2) {
            setKeyNum(Math.min(keyNum, youOwnKeyNum))
        }
    }
    const toggleKeyNumInput = () => {
        setKeyNumInput(true)
        setTimeout(() => {
            inputRef.current.focus({
                cursor: 'all',
            })
        });
    }

    const getEst = useMemo(() => {
        if (keyPriceWithFee) {
            type === 1 && setTotalPrice(keyPriceWithFee)
            const bN = ethers.utils.parseEther(keyPriceWithFee);
            const bN2 = ethers.utils.parseEther(keyPrice);
            return ethers.utils.formatUnits(bN.sub(bN2).abs());
        }
        return 0

    }, [keyPrice, keyPriceWithFee])

    return (
        <>
            <div className={s.dialog}>
                <div className={s.content}>
                    <div className={s.title}>
                        {transType[type]?.str} {accountType === 'key' ? t('Keys') : t('Wishes')}
                    </div>
                    <div className="df jc-spaceBetween aic w100p color-white fs12 mt45 ">
                        <div className="ml10">
                            {t('You Own')}
                            <CustomIcon className="ml5 mr5" width={19} height={19}
                                        imgName={'Picture/UI_Picture_Cards_01'}
                                        name={'Picture/UI_Picture_Cards_01'}/>
                            x {youOwnKeyNum || 0}
                        </div>
                        <div className={s.overview}>
                            <CustomIcon width={18} height={18} imgName={'Picture/UI_Picture_MoneyBag_01'}
                                        name={'Picture/UI_Picture_MoneyBag_01'}/>
                            <span
                                className={`${s.balance} ${!isAvailable && s.error} ml10 mr5`}><span
                                className="color-yellow">{stringToNumber(balance, 4)}</span> {unit}</span>
                            <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Plus-Yellow-Sq_01'}
                                        name={'Button/UI_Button_Plus-Yellow-Sq_01'}
                                        onClick={() => setShowDepositPanel(true)}/>
                        </div>
                    </div>
                    <div className="mt20 pr">
                        <AvatarInfo size={74} src={originalAvatarUrl || avatarUrl}/>
                        <div className={`${s.avatarTag} df fs12 aic jcc`}>
                            <CustomIcon width={20} height={20} imgName={'Picture/UI_Picture_Card-One_01'}
                                        name={'Picture/UI_Picture_Card-One_01'}/>
                            <div className="ml5 color-black fs14 fb">{stringToNumber(keyPrice, 4)} {unit}</div>
                        </div>
                    </div>
                    <div className="mt20 color-white">{displayFilter(displayName)}</div>
                    <div className=" color-blue-light">{accountName ? '@' + accountName : ''}</div>
                    <div className={s.amountWrap}>
                        <CustomIcon width={35} height={35} imgName={'Button/UI_Button_Minus-White-Irr_01'}
                                    name={'Button/UI_Button_Minus-White-Irr_01'}
                                    style={{position: 'absolute', left: '65px'}}
                                    onClick={() => controlAmount(0)}/>
                        <div className="df aic jcc" style={{
                            background: 'rgba(0, 0, 0, 0.3)',
                            width: '100px'
                        }}>
                            <Input ref={inputRef} onChange={amountChangeHandler} onBlur={amountBlurHandler}
                                   value={keyNum}
                                   style={{display: keyNumInput ? 'block' : 'none'}}/>
                            <div style={{display: keyNumInput ? 'none' : 'block', fontSize: '20px'}}
                                 onClick={toggleKeyNumInput}>
                                <CustomIcon
                                    className="mr5" width={25} height={25} imgName={'Picture/UI_Picture_Cards_01'}
                                    name={'Picture/UI_Picture_Cards_01'}/>x {keyNum}
                            </div>
                        </div>
                        <CustomIcon width={35} height={35} imgName={'Button/UI_Button_Plus-Yellow-Irr_01'}
                                    name={'Button/UI_Button_Plus-Yellow-Irr_01'}
                                    style={{position: 'absolute', right: '65px'}}
                                    onClick={() => controlAmount(1)}/>
                    </div>
                    <div className={`${s.borderWrap}`}>
                        <div className={`df jc-spaceBetween mb5`}>
                            <div className="fs14 fl c-title">{t('Total(inc. fees)')}</div>
                            <div>
                                <div className="tlr">
                                    <span className="color-yellow">
                                        {type === 1 ? stringToNumber(Number(keyPriceWithFee) + Number(fastGasPrice), 4) : stringToNumber(Number(keyPriceWithFee) - Number(fastGasPrice), 4)}
                                    </span> {unit}
                                </div>
                                <div className="fl">USD&nbsp;
                                    <span className="color-yellow">$
                                        <span className="color-yellow">
                                            {type === 1 ? stringToNumber((Number(keyPriceWithFee) + Number(fastGasPrice)) * maticUsd, 4) : stringToNumber((Number(keyPriceWithFee) - Number(fastGasPrice)) * maticUsd, 4)}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            showMoreInfo && <div className={s.topBorder}>
                                <div className={s.item}>
                                    <div className="fs12 fl">{t('From')}</div>
                                    <div className={s.bg}>
                                        {sliceAddr(walletAddr, 4)}
                                        <CustomIcon className="ml5"
                                                    onClick={() => copyFn(walletAddr)}
                                                    width={12} height={13}
                                                    imgName={'Button/UI_Button_CopyNumberWordName_01'}
                                                    name={'Button/UI_Button_CopyNumberWordName_01'}/>
                                    </div>
                                </div>
                                <div className={s.item}>
                                    <div className="fs12 fl">{t('To')}</div>
                                    <div className={s.bg}>
                                        {sliceAddr(contractAddr, 4)}
                                        <CustomIcon className="ml5"
                                                    onClick={() => copyFn(contractAddr)}
                                                    width={12} height={13}
                                                    imgName={'Button/UI_Button_CopyNumberWordName_01'}
                                                    name={'Button/UI_Button_CopyNumberWordName_01'}/>
                                    </div>
                                </div>
                                <div className={s.item}>
                                    <div className="fs12 fl">{t('Action')}</div>
                                    <div
                                        className={s.bg}>{transType[type].str} {accountType === 'key' ? t('Key') : t('Wish')}</div>
                                </div>
                                <div className={s.item} style={{color: '#c2c2c2'}}>
                                    <div className="fs12 fl">{t('Key')}</div>
                                    <div className="fs12">{stringToNumber((Number(keyPrice)), 2)} {unit}
                                    </div>
                                </div>
                                <div className={s.item} style={{color: '#c2c2c2'}}>
                                    <div className="fs12 fl">{t('Fees')}</div>
                                    <div
                                        className="fs12">{stringToNumber((Number(getEst) + Number(fastGasPrice)), 2)} {unit}
                                    </div>
                                </div>
                            </div>
                        }
                        <CustomIcon width={30} height={30}
                                    className="modelBottomBtn"
                                    imgName={!showMoreInfo ? 'Button/UI_Button_Arrow_Down_01' : 'Button/UI_Button_Arrow_Up_01'}
                                    name={!showMoreInfo ? 'Button/UI_Button_Arrow_Down_01' : 'Button/UI_Button_Arrow_Up_01'}
                                    onClick={showMoreInfoFn}/>
                    </div>
                    <div style={{height: '15px'}}></div>
                </div>


                <AntModal
                    width='330px'
                    title={t('GET MORE MATIC')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showDepositPanel}
                    onOk={() => setShowDepositPanel(false)}
                    onCancel={() => setShowDepositPanel(false)}
                    zIndex={7777}
                >
                    <ModelDeposit topUpCode={topUpCode} walletAddr={walletAddr}
                                  setShowDepositPanel={setShowDepositPanel}/>
                </AntModal>
                <AntModal
                    width='330px'
                    title={t('INSUFFICIENT BALANCE')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showInsufficientCrpto}
                    onOk={() => setShowInsufficientCrpto(false)}
                    onCancel={() => setShowInsufficientCrpto(false)}
                    zIndex={7777}
                >
                    <ModalInsufficientCrpto
                        insufficientAmount={Number(keyPriceWithFee) + Number(fastGasPrice) - Number(balance)}
                        userInfo={userInfo} closeFn={() => {
                        setShowInsufficientCrpto(false);
                    }}/>
                </AntModal>
                <AntModal
                    width='330px'
                    title={t('NOTICE')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showTips}
                    onOk={() => setShowTips(false)}
                    onCancel={() => setShowTips(false)}
                    zIndex={7777}
                >
                    <ModelBuyFristKeyTips closeFn={() => setShowTips(false)}/>
                </AntModal>
            </div>
            <CustomIcon width={30} height={30}
                        className="modelBottomSetGas"
                        imgName={'Button/UI_Button_Gas_01'}
                        onClick={() => {
                            setShowGasMode(true);
                        }}
            />
            <div className="modelBottomBtn">
                {!done && <ButtonFactory width={150} loading={getPriceLoding} onClick={transFn}>
                    {subLoading ? <div className={s.web3LoadingLine}>
                        <CustomIcon rotating={true} className="ml5" width={16} height={16}
                                    imgName={'Picture/UI_Picture_Loading_01'}
                                    name={'Picture/UI_Picture_Loading_01'}/>
                        <span className="fs18 ml5 color-black">{t('PROCESSING')}</span>
                    </div> : type === 1 ? t('BUY NOW') : t('SELL NOW')}
                </ButtonFactory>}
                {done && <ButtonFactory onClick={closeFn}>
                    {subFailded ? noSuccessText : t('TRANSACTION SUCCESSFUL')}
                </ButtonFactory>}
            </div>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelTrans));
// export default memo(BuyDialog);
