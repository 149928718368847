import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import List from "./List";

const Active = (
    {
        clubId,
        chainInfo
    }
) => {
    const { t, i18n } = useTranslation();


    return (
        <div className={`${s.box}`}>
            <List></List>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Active));
