import React, { useState,useMemo } from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import { Button, Slider, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import { convertWalletBalance } from "@/utils/common";
import AntButton from "@/common/AntButton";
import { momentChainToken } from "@/utils/momentClub";
import {
    LfgMainnetId,
    LfgTestnetId,
} from "@/utils/env";
import momentChainInfo from "@/utils/json/momentChainInfo.json";
import AntModal from "@/common/AntModal";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import ModelDeposit from "../ModelDeposit";
import ModalInsufficientCrpto from "src/model/ModalInsufficientCrpto";
const ModalMomentCreatePreBuy = (
    {
        walletBalanceList,
        min = 0,
        max,
        coinId,
        creatFn = () => {},
        closeFn = () => {},
        preBuyAmount,
        setPreBuyAmount,
        selCreatChain,
        preBuyTotalValue,
        isAnony,
        setIsAnony,
        userInfo,
        refreshWalletBalance
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {walletAddr, selfData, topUpCode} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    // const [isAnony,setIsAnony] = useState(false)
    const getSpecifiedCoinBalance = (id) => {
        return walletBalanceList?.[id] || 0;
    }

    const createFee = useMemo(()=>{
        if(momentChainInfo && selCreatChain){
            return momentChainInfo.find(i=>i?.chainId === selCreatChain?.chainId)?.createFee / 10000
        }
    },[momentChainInfo,selCreatChain])
    const nativeCoinInfo = useMemo(() => {
        let id = Math.floor(coinId/100)*100;
        return momentChainToken.find(i => i?.ID === id);
    }, [coinId]);
    const currentCoinInfo = useMemo(() =>{
        return momentChainToken.find(i => i?.ID === coinId);
    }, [coinId]);
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [depositChainTokenInfo, setDepositChainTokenInfo] = useState(null);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [insufficientAmount, setInsufficientAmount] = useState(0);
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const priceWithFee = useMemo(()=>{
        return (selCreatChain?.isNative ===1 ? Number(preBuyTotalValue):0)  + Number(createFee)
    },[preBuyTotalValue,createFee,selCreatChain])
    const create = ()=>{
        if (isNativeCoin()){
            if (Number(priceWithFee) > Number(getSpecifiedCoinBalance(currentCoinInfo?.ID))){
                setShowInsufficientCrpto(true);
                setInsufficientAmount(Number(priceWithFee) - Number(getSpecifiedCoinBalance(currentCoinInfo?.ID)));
                return;
            }
        }
        else{
            if (Number(priceWithFee) > Number(getSpecifiedCoinBalance(nativeCoinInfo?.ID))){
                setShowInsufficientCrpto(true);
                setInsufficientAmount(Number(getSpecifiedCoinBalance(nativeCoinInfo?.ID)));
                return;
            }
            if (Number(preBuyTotalValue) > Number(getSpecifiedCoinBalance(currentCoinInfo?.ID))){
                setShowSwapLfg(true)
                return;
            }
        }
        creatFn()
    }
    const isNativeCoin = () =>{
        if (momentChainToken?.length > 0){
            for (let i = 0; i < momentChainToken?.length; i++){
                if (momentChainToken[i].ID === coinId){
                    return momentChainToken[i].isNative === 1;
                }
            }
        }
        return true;
    }
    // console.log(walletBalanceList);
    return (
        <div className={s.modelWrap}>
            <div className={`${s.title} fs20 momentFont`}>
                {t('Early Access')}
            </div>
            <div className="df jc-spaceBetween ais w100p color-white fs12" style={{marginBottom: '25px'}}>
                <div className="ml10 flex_center_start momentFont" style={{ padding: "4px 0px" }}>
                    {t('Your Balance')}
                    <CustomIcon isHaveClick={true} className="ml10" onClick={()=>{
                        refreshWalletBalance()
                    }} width={18} height={18}
                    imgName='Picture/UI_Picture_Icon_Refresh_01'/>
                </div>
                <div className={`${s.overview} flex_center_center_col`}>
                    {
                        !selCreatChain?.isNative && <div className={s.overviewItem}>
                            <CustomIcon width={18} height={18} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                            <span className={`${s.balance} momentFont`}>
                                {convertWalletBalance(getSpecifiedCoinBalance(nativeCoinInfo?.ID))}
                            </span>
                            {/* <span className={`${s.overviewItemName} momentFont color-moment-gray`}>
                                {nativeCoinInfo?.displayCoinName}
                            </span> */}
                            <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Plus-Yellow-Sq_01'}
                                onClick={() => {
                                    setShowDepositPanel(true);
                                    setDepositChainTokenInfo(nativeCoinInfo);
                                }} />
                        </div>
                    }
                    <div className={s.overviewItem}>
                        <CustomIcon width={18} height={18} className={`ml8`} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                        <span className={`${s.balance} momentFont`}>
                            {convertWalletBalance(getSpecifiedCoinBalance(currentCoinInfo?.ID))}
                        </span>
                        {/* <span className={`${s.overviewItemName} momentFont color-moment-gray`}>
                            {currentCoinInfo?.displayCoinName}
                        </span> */}
                        <CustomIcon width={24} height={24} imgName={'Button/UI_Button_Explore_Create_01'}
                            onClick={() => {
                                if (isNativeCoin()) {
                                    setShowDepositPanel(true);
                                    setDepositChainTokenInfo(currentCoinInfo);
                                }
                                else if (currentCoinInfo?.ID === LfgMainnetId || currentCoinInfo?.ID === LfgTestnetId) {
                                    setShowSwapLfg(true);
                                }
                            }} />
                    </div>
                </div>
            </div>
            <div className="fs14 momentFont color-moment-gray">{t('You can pre-purchase part of the Pack')}</div>
            <div className={`${s.packRoot} fs16`}>
                <CustomIcon width={42} height={42} imgName={`Picture/UI_Picture_icon_cardpack`} />
                <span className="fs22 momentFont">x</span>
                <span className="fs30 momentFont">{preBuyAmount}</span>
            </div>
            <Slider
                className="hireSlider momentSlider memeClubSlider"
                min={min}
                max={Number(max - 1)}
                value={preBuyAmount}
                onChange={(value) => {
                    setPreBuyAmount(value);
                }} />
            <div className="flex_center_between mt20 w100p">
                <div className="fs14 color-moment-gray momentFont">{t('Pre-buy Cost(inc.fees): ')}</div>
                <div className="flex_center_between">
                    <CustomIcon width={18} height={18} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                    <span className="color-EEEEEE fs14 momentFont ml5">{convertWalletBalance(preBuyTotalValue)}</span>
                </div>
            </div>
            <div className="flex_center_between mt10 w100p">
                <div className="fs14 color-moment-gray momentFont">{t('Create Fee: ')}</div>
                <div className="flex_center_between">
                    <CustomIcon width={18} height={18} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                    <span className="color-EEEEEE fs14 momentFont ml5">{convertWalletBalance(createFee)}</span>
                </div>
            </div>
           
            <div className={s.line}></div>
            <div className="flex_start_between w100p">
                <div className="fs14 color-moment-gray momentFont">{t('Total: ')}</div>
                <div className="flex_end_center_col">
                    <div className="flex_center_between">
                        <CustomIcon width={18} height={18} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                        <span className="color-EEEEEE fs14 momentFont ml5">{convertWalletBalance(selCreatChain?.isNative === 0 ? preBuyTotalValue : priceWithFee)}</span>
                    </div>
                    {selCreatChain?.isNative === 0 &&
                        <div className="flex_center_between">
                            <CustomIcon width={18} height={18} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                            <span className="color-EEEEEE fs14 momentFont ml5">{convertWalletBalance(priceWithFee)}</span>
                        </div>
                    }
                   
                </div>
            </div>
            <div>

                <Checkbox onChange={(e) => setIsAnony(e.target.checked)} className="hireCheckBox mt10 mb20"
                    checked={isAnony}>
                    <span className="fs14 color-moment-gray momentFont">{t('Create Anonymously')}</span>
                </Checkbox>
            </div>

            <AntButton
                onClick={() => {
                    create();
                }}
                className={`${s.launchBtn} btn_public`}>
                <div className="fs16 fb momentFont color-black">
                {t('Create Meme 1')}
                </div>
            </AntButton>

            <CustomIcon 
                className={`${s.closeBtn}`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeFn();
                }} />

            <AntModal
                width='330px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={showDepositPanel}
                onOk={() => setShowDepositPanel(false)}
                onCancel={() => setShowDepositPanel(false)}
                zIndex={7777}
            >
                <ModelDeposit
                    topUpCode={topUpCode}
                    walletAddr={walletAddr}
                    setShowDepositPanel={setShowDepositPanel}
                    chainTokenInfo={depositChainTokenInfo}
                    type={2} />
            </AntModal>
            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg
                    userInfo={userInfo}
                    closeFn={() => {
                        setShowSwapLfg(false);
                    }} />
            </AntModal>
            <AntModal
                width='330px'
                title={t('INSUFFICIENT BALANCE')}
                className="confirmModalWrap"
                centered={true}
                open={showInsufficientCrpto}
                onOk={() => setShowInsufficientCrpto(false)}
                onCancel={() => setShowInsufficientCrpto(false)}
                zIndex={7777}
            >
                <ModalInsufficientCrpto
                    insufficientAmount={insufficientAmount}
                    userInfo={userInfo} 
                    coinId={coinId}
                    closeFn={() => {
                        setShowInsufficientCrpto(false);
                    }}
                    type={2}
                />
            </AntModal>
        </div>
    )
}

export default ModalMomentCreatePreBuy
