import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
// import Banner from "../../../common/Banner";
import {
    stringToNumber,
    convertWalletBalance,
    getFriendtradeLootLastevent,
    searchuser,
    displayFilter
} from "@/utils/common"
import AntModal from "@/common/AntModal";
import ModalMemePackTrans from "@/model/ModalMemePackTrans";
import { coinList, momentTgeClublist, setFavorite, getRecommendClub, searchclub, getChainInfo } from "@/utils/momentClub";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntDrawer from "@/common/AntDrawer";
import { gameMomentClubCreatPath, gameMomentClubDetail, gameMomentClubDetailPath } from "@/routes/config";
import AvatarInfo from "@/common/AvatarInfo";
import SearchUser from "@/common/SearchUser";
import SearchUserList from "@/common/SearchUserList";
import MomentClubFilter from "@/drawer/MomentClubFilter";
import MomentClubSort from "@/drawer/MomentClubSort";
import SearchMemeClubList from "@/common/SearchMemeClubList";
import InfiniteScroll from "react-infinite-scroll-component";
import MomentChainToken from "@/utils/json/momentChainToken.json";
import { isOnlineEnv } from "@/utils/env";
import { Spin } from "antd";
import MemeClubPack from "@/common/MemeClubPack";
import SearchMomenttClubList from "@/common/SearchMomenttClubList";

const maxCoinPrice = 0.1;
const maxMarketCap = 20000;
const maxFavorite = 200;

const MomentTge = (
    {
        userInfo,
        setHideRefreshBtn,
        tgeMomentClubFilterList,
        setTgeMomentClubFilterList,

        setCurrentMomentClubId,
        setSwitchVideoList,
        setSwitchVideoListCursor,
        setSwitchVideoType,
        setMemeclubSwitchVideoParams,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const linkTo = (id) => {
        navigate(`${gameMomentClubDetailPath}`)
        setCurrentMomentClubId(id);
    }
    const [updateLootEventTimes, setUpdateLootEventTimes] = useState(0);
    const [lootLastEventData, setLootLastEventData] = useState(null);
    const [showMomentPackTrans, setShowMomentPackTrans] = useState(false);
    const [momentPackTransDone, setMomentPackTransDone] = useState(false);
    const [momentPackTransSubLoading, setMomentPackTransSubLoading] = useState(false);
    const [tradeMomentClubInfo, setTradeMomentClubInfo] = useState(null);


    const [searchResult, setSearchResult] = useState([]);
    const [searchKeyValue, setSearchKeyValue] = useState('');
    const [searchCursor, setSearchCursor] = useState(-1);
    const [showSearchResultPanel, setShowSearchResultPanel] = useState(false);

    const searchUserHandle = (searchKey) => {
        setSearchKeyValue(searchKey)
        if (!searchKey) {
            setSearchCursor(-1)
            setSearchResult([])
            setHideRefreshBtn(false);
            setShowSearchResultPanel(false);

        } else {
            setHideRefreshBtn(true);
            setShowSearchResultPanel(true);
            
        }
        searchKey && searchclub(searchKey, 0, 1).then(res => {
            setSearchCursor(res?.nextCursor);
            setSearchResult(res?.clubList || []);
        }).catch(e => {
            console.log(e);
        })
    }

    const getMoreSearchResult = () => {
        searchclub(searchKeyValue, searchCursor, 1).then(res => {
            setSearchCursor(res?.nextCursor);
            setSearchResult([
                ...searchResult,
                ...res?.clubList || []
            ]);
        }).catch(e => {
            console.log(e);
        })
    }

    const nativeTokenList = useMemo(() => {
        let list = [];
        if (MomentChainToken?.length > 0) {
            for (let i = 0; i < MomentChainToken?.length; i++) {
                let tokenInfo = MomentChainToken[i];
                if (tokenInfo?.isEnable === 1) {
                    if (isOnlineEnv() && tokenInfo?.isTestChain === 0) {
                        list.push(tokenInfo);
                    }
                    else if (!isOnlineEnv() && tokenInfo?.isTestChain === 1) {
                        list.push(tokenInfo);
                    }
                }
            }
        }
        return list;
    }, [MomentChainToken]);



    // filter sort start

    const [showSort, setShowSort] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const sortData = [
        {
            label: t('Recommiend'),
            key: 3,
        },
        {
            label: t('Transactions Time'),
            key: 0,
        },
        {
            label: t('Market Cap'),
            key: 8,
        },{
            label: t('Token Price'),
            key: 4,
        },
    ]
    const [sortType, setSortType] = useState(3)
    const [coinId, setCoinId] = useState(0)
    const [isAsc, setIsAsc] = useState(false)


    const [filterPackPrice, setFilterPackPrice] = useState([0, -1]);
    const [filterMarketCap, setFilterMarketCap] = useState([0, -1]);
    const [filterFavoriteRange, setFilterFavoriteRange] = useState([0, -1]);
    const [filterOnlyFavorite, setFilterOnlyFavorite] = useState(false);
    const [filterOnlyHeld, setFilterOnlyHeld] = useState(false);
    const [filterOnlyLive, setFilterOnlyLive] = useState(false);
    const [filterCoinList, setFilterCoinList] = useState(coinList.map(item=> item.chainId))
    // filter sort end
    const [list, setList] = useState([])
    const [nextCursor, setNextCursor] = useState('');
    const getList = ({
        rankType,
        coinId,
        priceRange,
        marketCapRange,
        onlyFavorite,
        onlyHeld,
        onlyLive,
        chainIds,
        isAsc,
        cursor = ``
    }) => {
        let tempPriceRange = [priceRange[0] * 1000, -1];
        if (priceRange[1] !== -1) {
            tempPriceRange[1] = Math.floor(priceRange[1] * 1000);
        }
        momentTgeClublist({
            rankType,
            coinId,
            priceRange: tempPriceRange,
            marketCapRange,
            onlyFavorite,
            onlyHeld,
            onlyLive,
            chainIds,
            isAsc,
            cursor
        }).then(res => {
            if (res?.dataList) {
                setList(cursor === '' ? res?.dataList : [
                    ...list,
                    ...(res?.dataList || [])
                ])
            }
            else if (cursor === '') {
                setList([]);
            }
            setNextCursor(res?.nextCursor);
        }).catch(e => {
            console.error(e);
        })
    }




    const favoriteFn = (userId, favorite, type = 0) => {
        setFavorite(userId, favorite).then(res => {
            const { clubUserId, favorite } = res;

            const _list = list.map(item => {
                if (item.clubUserId === clubUserId) {
                    return {
                        ...item,
                        isFavorite: favorite
                    };
                } else {
                    return item
                }
            });
            setList(_list)

        }).catch(e => {
            console.error(e);
        })
    }

    const [recommendData, setRecommendData] = useState(null)



    const [isSticky, setIsSticky] = useState(false);
    const stickyRef = useRef(null);
    const containerRef = useRef(null);

    const [rotatingItems, setRotatingItems] = useState({});

    useEffect(() => {
        let filterParam = {
            rankType: sortType,
            coinId: coinId,
            priceRange: filterPackPrice,
            favoriteRange: filterFavoriteRange,
            marketCapRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            onlyLive: filterOnlyLive,
            chainIds: filterCoinList,
            isAsc: isAsc,
            cursor: ``
        };

        if (tgeMomentClubFilterList) {
            if (tgeMomentClubFilterList?.rankType) {
                filterParam.rankType = tgeMomentClubFilterList?.rankType;
                setSortType(tgeMomentClubFilterList?.rankType);
            }
            if (tgeMomentClubFilterList?.coinId) {
                filterParam.coinId = tgeMomentClubFilterList?.coinId;
                setCoinId(tgeMomentClubFilterList?.coinId);
            }
            if (tgeMomentClubFilterList?.priceRange) {
                filterParam.priceRange = tgeMomentClubFilterList?.priceRange;
                setFilterPackPrice(tgeMomentClubFilterList?.priceRange);
            }
            if (tgeMomentClubFilterList?.favoriteRange) {
                filterParam.favoriteRange = tgeMomentClubFilterList?.favoriteRange;
                setFilterFavoriteRange(tgeMomentClubFilterList?.favoriteRange);
            }
            if (tgeMomentClubFilterList?.marketCapRange) {
                filterParam.marketCapRange = tgeMomentClubFilterList?.marketCapRange;
                setFilterMarketCap(tgeMomentClubFilterList?.marketCapRange);
            }
            if (tgeMomentClubFilterList?.onlyFavorite) {
                filterParam.onlyFavorite = tgeMomentClubFilterList?.onlyFavorite;
                setFilterOnlyFavorite(tgeMomentClubFilterList?.onlyFavorite);
            }
            if (tgeMomentClubFilterList?.onlyHeld) {
                filterParam.onlyHeld = tgeMomentClubFilterList?.onlyHeld;
                setFilterOnlyHeld(tgeMomentClubFilterList?.onlyHeld);
            }
            if (tgeMomentClubFilterList?.filterCoinList){
                filterParam.chainIds = tgeMomentClubFilterList?.filterCoinList;
                setFilterCoinList(tgeMomentClubFilterList?.filterCoinList);
            }
            if (tgeMomentClubFilterList?.onlyLive) {
                filterParam.onlyLive = tgeMomentClubFilterList?.onlyLive;
                setFilterOnlyLive(tgeMomentClubFilterList?.onlyLive);
            }
            if (tgeMomentClubFilterList?.isAsc) {
                filterParam.isAsc = tgeMomentClubFilterList?.isAsc;
                setIsAsc(tgeMomentClubFilterList?.isAsc);
            }
        }

        getList(filterParam);
    }, [])


    const getMoreList = () => {
        getList({
            rankType: sortType,
            coinId: coinId,
            priceRange: filterPackPrice,
            favoriteRange: filterFavoriteRange,
            marketCapRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            onlyLive: filterOnlyLive,
            chainIds: filterCoinList,
            isAsc: isAsc,
            cursor: nextCursor
        })
    }

    const applyClubFilter = (item) => {
        let tempCoinId = coinId;
        if (item?.sortType !== 1 || item?.coinId !== 0) {
            tempCoinId = item?.coinId;
            setCoinId(item?.coinId);
        }
        setFilterOnlyFavorite(item?.onlyFavorite);
        setFilterOnlyHeld(item?.onlyHeld);
        setFilterOnlyLive(item?.onlyLive);
        let tempPackPrice = [0, -1];
        if (item?.priceRange?.[0] >= 0) {
            tempPackPrice[0] = item?.priceRange?.[0];
        }
        if (item?.priceRange?.[1] < maxCoinPrice) {
            tempPackPrice[1] = item?.priceRange?.[1];
        }
        setFilterPackPrice(tempPackPrice);

        let tempMarketCap = [0, -1];
        if (item?.marketCapRange?.[0] >= 0) {
            tempMarketCap[0] = item?.marketCapRange?.[0];
        }
        if (item?.priceRange?.[1] < maxMarketCap) {
            tempMarketCap[1] = item?.marketCapRange?.[1];
        }
        setFilterMarketCap(tempMarketCap);

        let tempFavoriteRange = [0, -1];
        if (item?.favoriteRange?.[0] >= 0) {
            tempFavoriteRange[0] = item?.favoriteRange?.[0];
        }
        if (item?.favoriteRange?.[1] < maxFavorite) {
            tempFavoriteRange[1] = item?.favoriteRange?.[1];
        }
        setFilterFavoriteRange(tempFavoriteRange);

        let filter = {
            rankType: sortType,
            coinId: tempCoinId,
            priceRange: tempPackPrice,
            favoriteRange: tempFavoriteRange,
            marketCapRange: tempMarketCap,
            onlyFavorite: item?.onlyFavorite,
            onlyHeld: item?.onlyHeld,
            onlyLive: item?.onlyLive,
            chainIds: item?.selCoinList,
            isAsc: isAsc,
        }

        getList({
            ...filter,
            cursor: ''
        })

        setTgeMomentClubFilterList(filter);
    }

    const applyClubSort = (item) => {
        setIsAsc(item?.isAsc);
        setSortType(item?.sortType);
        let sortCoin = coinId;
        if (item?.sortType === 1) {
            sortCoin = item?.coinId;
            setCoinId(item?.coinId);
        }

        let filter = {
            rankType: item?.sortType,
            coinId: sortCoin,
            priceRange: filterPackPrice,
            favoriteRange: filterFavoriteRange,
            marketCapRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            onlyLive: filterOnlyLive,
            chainIds: filterCoinList,
            isAsc: item?.isAsc,
        }

        getList({
            ...filter,
            cursor: ''
        })

        setTgeMomentClubFilterList(filter);
    }

    useEffect(() => {
        const container = containerRef.current;

        const handleScroll = () => {
            const stickyOffset = stickyRef.current.offsetTop;
            setIsSticky(357 < stickyOffset);
        };

        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const range = "50";

    return (
        <div className={`${s.modalWrap} mainWrap`}>
            <MainHeader leftTitle={t('TGE Moment')} headerType={3} isGameHeader={true} hasBackBtn={false} openType={2}></MainHeader>

            <div className={s.modal} ref={containerRef}>
                <div className={`wrap ${isSticky ? 'stickyElmentBg' : 'stickyElment'}`} ref={stickyRef}>
                    <div className={s.controlBar}>
                        <SearchUser useSearchValue={true} searchKeyValue={searchKeyValue} searchUserFn={searchUserHandle} type={2}/>
                        <div className={s.r}>
                            <CustomIcon onClick={() => {
                                setShowSort(true)
                            }} imgName={`Button/UI_Button_FilterIcon_02`} className="mr5" width={26} height={26}></CustomIcon>
                            <CustomIcon onClick={() => {
                                setShowFilter(true)
                            }} imgName={`Button/UI_Button_Filters_01`} className="mr5" width={32} height={32}></CustomIcon>

                        </div>
                    </div>

                </div>
                <div className={s.content}>
                    <div className={s.wrap}>
                        {
                            showSearchResultPanel &&
                            <SearchMomenttClubList
                                favoriteFn={favoriteFn}
                                searchCursor={searchCursor}
                                searchKeyValue={searchKeyValue}
                                linkTo={linkTo}
                                searchResult={searchResult}
                                isMaket={true}
                                getMoreSearchResult={getMoreSearchResult} />
                        }
                        {
                            !showSearchResultPanel &&
                            <div className={s.list}>
                                <InfiniteScroll
                                    dataLength={list?.length || 0}
                                    next={getMoreList}
                                    hasMore={nextCursor !== ""}
                                    loader={<div
                                        style={{
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    ><Spin /></div>}
                                    height={"100%"}
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        width: "100%",
                                        justifyContent: "space-between",
                                        alignContent: "flex-start"
                                    }}

                                >
                                    {
                                        list?.length > 0 ?
                                        list?.map(item => {
                                            const {
                                                easeChatLogNum,
                                                favoriteNum,
                                                isFavorite,
                                                clubProfile,
                                                clubSymbolImageUrl,
                                                liquidityWorth,
                                                Price24Change,
                                                clubUserId,
                                                marketCap,
                                                priceUSD,
                                                clubSymbolName,
                                                onAlive,
                                                announceStartTime,
                                                announceTitle,
                                                videoFirstFrameUrl,
                                                videoId,
                                                videoOriHeight,
                                                videoOriWidth,
                                                videoUrlMap,
                                            } = item;
                                            const { 
                                                displayName, 
                                                avatarUrl, 
                                                coinId,
                                            } = clubProfile || {};

                                            const chainInfo = getChainInfo(coinId)

                                            const handleFavoriteClick = (userId, isFavorite) => {
                                                setRotatingItems(prevState => ({ ...prevState, [userId]: true }));
                                                setTimeout(() => {
                                                    setRotatingItems(prevState => ({ ...prevState, [userId]: false }));
                                                }, 500);
                                                favoriteFn(userId, !isFavorite);
                                            };
                                            const _marketCap = marketCap === 0 ? '--' : marketCap < 0.000001 ? '<0.000001' : convertWalletBalance(marketCap)
                                            const _priceUSD = priceUSD === 0 ? '--' : priceUSD < 0.000001 ? '<0.000001' : convertWalletBalance(priceUSD)
                                            const _liquidityWorth = liquidityWorth === 0 ? '--' : liquidityWorth < 0.000001 ? '<0.000001' : convertWalletBalance(liquidityWorth)
                                            const _Price24Change = Price24Change === 0 ? '--' : Price24Change < 0.000001 ? '<0.000001' : convertWalletBalance(Price24Change)
                                            return (
                                                <div className={s.item} key={clubUserId} onClick={() => {
                                                    let _list = [];
                                                    if (list.length > 0) {
                                                        for (let i = 0; i < list.length; i++) {
                                                            _list.push({
                                                                clubId: list[i].clubUserId,
                                                                momentAuditCode: list[i].clubProfile.momentAuditCode,
                                                                videoId: list[i].videoId,
                                                                videoUrlMap: list[i].videoUrlMap,
                                                                coverUrl: list[i].clubProfile.avatarUrl,
                                                                videoOriHeight: list[i].videoOriHeight,
                                                                videoOriWidth: list[i].videoOriWidth,
                                                                videoFirstFrameUrl: list[i].videoFirstFrameUrl,
                                                            });
                                                        }
                                                    }
                                                    setSwitchVideoList(_list);
                                                    setSwitchVideoType(2);
                                                    setMemeclubSwitchVideoParams({
                                                        rankType: sortType,
                                                        coinId: coinId,
                                                        favoriteRange: filterFavoriteRange,
                                                        onlyFavorite: filterOnlyFavorite,
                                                        onlyHeld: filterOnlyHeld,
                                                        onlyLive: filterOnlyLive,
                                                        isAsc: isAsc,
                                                        priceRange: filterPackPrice,
                                                        marketCapRange: filterMarketCap,
                                                        chainIds: filterCoinList,
                                                    });
                                                    if (nextCursor !== ""){
                                                        setSwitchVideoListCursor(nextCursor);
                                                    }
                                                    else{
                                                        setSwitchVideoListCursor("-1");
                                                    }
                                                    linkTo(clubUserId);
                                                }}>
                                                    <div className={s.l}>
                                                        <div className={s.ll}>
                                                            <div className={`${s.imageRoot}`} style={{backgroundImage: `url(${clubSymbolImageUrl})`}}>
                                                            </div>
                                                        </div>
                                                        <div className={s.lr}>
                                                            <div className={s.line}>
                                                                <div className="momentFont ellipse fb fs12">${displayFilter(clubSymbolName)} / </div>
                                                                <div className="momentFont ml5 fb fs12">
                                                                    <CustomIcon imgName={`Picture/${chainInfo?.icon}`} className="mr5" width={12} height={12}></CustomIcon>
                                                                    {chainInfo?.displayCoinName}
                                                                </div>
                                                            </div>
                                                            <div className={s.line}>
                                                                <div className="momentFont color-moment-gray fs12">{t('Market Cap')} :</div>
                                                                <div className="momentFont ml5 fb fs12">$ {_marketCap}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={s.r}>

                                                        <div className="flex_center_center">
                                                            {
                                                                onAlive ?
                                                                    <div className={`${s.liveIcon} flex_center_center`}>
                                                                        <CustomIcon imgName={`Picture/UI_Picture_Icon_Live_01_black`} className="mr4" width={12} height={12}></CustomIcon>
                                                                        <span className={`fs10 lh1 fb color-black`}>
                                                                            {t('Live')}
                                                                        </span>
                                                                    </div> :
                                                                    announceStartTime > 0 ?
                                                                        <div className={`${s.liveScheduledIcon} flex_center_center`}>
                                                                            <CustomIcon imgName={`Picture/UI_Picture_Icon_Live_01`} className="mr4" width={12} height={12}></CustomIcon>
                                                                            <span className={`fs10 lh1 color-white`}>
                                                                                {t('Scheduled')}
                                                                            </span>
                                                                        </div> :
                                                                        <></>
                                                            }
                                                            <CustomIcon imgName={Price24Change < 0 ? 'Picture/UI_Picture_PriceDown_01' : 'Picture/UI_Picture_PriceUP_01'} className="mr5" width={22} height={13}></CustomIcon>
                                                            <span className={`${Price24Change < 0 ? 'color-red fs12 fb' : 'color-green fs12 fb'} momentFont`}>{_Price24Change}%</span>
                                                        </div>
                                                        <div className={s.line}>
                                                            <div className="color-moment-gray fs12 momentFont">{t('Token Price')} :</div>
                                                            <span className="ml5 fs12 momentFont" style={{ alignSelf: 'end' }}>$ {_priceUSD}</span>
                                                        </div>
                                                    </div>
                                                    {/* <div className={`${s.rightTopPart} flex_center_end`}> */}

                                                        {/* <CustomIcon
                                                            onClick={() => handleFavoriteClick(clubUserId, isFavorite)}
                                                            imgName={isFavorite ? 'Picture/UI_Picture_Collect_01' : 'Picture/UI_Picture_Uncollect_01'}
                                                            className={rotatingItems[clubUserId] ? 'rotate' : ''} width={20} height={20}></CustomIcon> */}
                                                    {/* </div> */}
                                                </div>
                                            )
                                        }):
                                        <div className={`flex_center_center_col w100p h100p`} style={{padding: '0  0 140px 0'}}>
                                            <CustomIcon width={111} height={95} imgName={`Picture/UI_Picture_NoData_Empty_02`}></CustomIcon>
                                            <div className="fs18 momentFont mt40 fw-400" style={{color: `#555555`}}>{t('No Data')}</div>
                                        </div>
                                    }
                                </InfiniteScroll>

                            </div>
                        }
                    </div>

                </div>
            </div>

            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowSort(false)}
                width={245}
                open={showSort}
                destroyOnClose={true}
                className="momentSort"
            >
                <MomentClubSort
                    sortData={sortData}
                    nativeTokenList={nativeTokenList}
                    sortType={sortType}
                    setSortType={setSortType}
                    coinId={coinId}
                    setCoinId={setCoinId}
                    isAsc={isAsc}
                    setIsAsc={setIsAsc}
                    coinList={coinList}
                    applyFn={(item) => {
                        applyClubSort(item);
                        setShowSort(false);
                    }}
                />
            </AntDrawer>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowFilter(false)}
                width={245}
                open={showFilter}
                destroyOnClose={true}
                className="momentSort"
            >
                <MomentClubFilter
                    maxCoinPrice={maxCoinPrice}
                    maxMarketCap={maxMarketCap}
                    maxFavorite={maxFavorite}
                    filterPackPrice={filterPackPrice}
                    filterMarketCap={filterMarketCap}
                    filterFavoriteRange={filterFavoriteRange}
                    filterOnlyFavorite={filterOnlyFavorite}
                    filterOnlyHeld={filterOnlyHeld}
                    filterOnlyLive={filterOnlyLive}
                    filterCoinList={filterCoinList}
                    coinId={coinId}
                    applyFn={(item) => {
                        applyClubFilter(item);
                        setShowFilter(false);
                    }}
                    coinList={coinList}
                    isSortByPrice={sortType === 1}
                    showTgeProcess={false}
                    showPrice={true}
                />
            </AntDrawer>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={showMomentPackTrans}
                destroyOnClose={true}
                onOk={() => setShowMomentPackTrans(false)}
                onCancel={() => setShowMomentPackTrans(false)}
                keyboard={!momentPackTransSubLoading || momentPackTransDone}
                maskClosable={!momentPackTransSubLoading || momentPackTransDone}
            >
                <ModalMemePackTrans
                    type={1}
                    tradeMomentClubInfo={tradeMomentClubInfo}
                    closeHandle={() => {
                        setShowMomentPackTrans(false);
                    }}
                    done={momentPackTransDone}
                    setDone={setMomentPackTransDone}
                    subLoading={momentPackTransSubLoading}
                    setSubLoading={setMomentPackTransSubLoading}
                />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        tgeMomentClubFilterList: app.tgeMomentClubFilterList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHideRefreshBtn: (params) => {
            return dispatch({
                type: "app/setHideRefreshBtn",
                payload: params,
            });
        },
        setTgeMomentClubFilterList: (params) => {
            return dispatch({
                type: "app/setTgeMomentClubFilterList",
                payload: params,
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoType: (params) => {
            return dispatch({
                type: "app/setSwitchVideoType",
                payload: params,
            });
        },
        setMemeclubSwitchVideoParams: (params) => {
            return dispatch({
                type: "app/setMemeclubSwitchVideoParams",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MomentTge));
