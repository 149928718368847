import React from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { gameShareDetailPath, gameTrillionaireBattleLeaderBoardPath, mainProfilePath } from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelRankReward = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (<div style={{ padding: '0 20px 20px 20px' }}>
        <div
            className="fl mtb10">{t('Complete missions & engage in PVP battles to accumulate $LFG to climb up the leaderboard.')} </div>
        <div
            className="fl mtb10">{t('Your unclaimed $LFG determines your Leaderboard rank, which impacts the daily $UP points earnings. More $LFG = higher leaderboard rank.')} </div>
        <div
            className="fl mtb10">
           <Trans
                    i18nKey="Extracting $LFG from the pool resets your rank and start in the bottom of the leaderboard."
                    defaults="Extracting $LFG from the pool resets your rank and start in the bottom of the leaderboard."
                    components={{ frie: <CustomIcon width={18} height={18} imgName={"Picture/UI_Picture-Currency_UP_01"} ></CustomIcon> }}
                /> </div>
        <CustomIcon width={260} height={370} className="mr5 ml5" imgName={'BG/UI_Background-S_LFG-Pool -Rank_01'} imgType = {'.webp'}/>
        {/*<ButtonFactory className="mt10 df aic jcc modelBottomBtn"*/}
        {/*               onClick={() => navigate(gameShareDetailPath)}>{t('CHECK MY POINTS')}*/}
        {/*</ButtonFactory>*/}
    </div>)
}

export default ModelRankReward
