import React, {useMemo, useEffect, memo, useState, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button, Modal, Checkbox} from "antd";
import {
    TransactionError,
    getearninfo,
    PrivyLoginStatusError,
    formatEther,
    stringToNumber,
    getBurnParam_ulock,
    unit,
    bornUnlock,
    getBurnParam_loot,
    formatNumberWithUnit,
    vCoinString,
    filterErrorCode,
    convertWalletBalance,
    getTransactionErrorMsgId
} from "@/utils/common";

import {bornLfg} from "@/utils/lfgMintWeb3";

import {connect} from "react-redux";
// import Countdown from "../Countdown";
import CustomIcon from "../../common/CustomIcon";
import {displayUnlockMissionRefundTime} from "@/utils/env";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import ModalSwapLfg from "../ModalSwapLfg";
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";

const ModelBornLFG = (
    {
        closeFn,
        userInfo,
        earnInfo,
        employeeId,
        tarUserId,
        cliamPoolFn = () => {
        },
        checkFn = () => {
        },
        lootBornLfg = () => {
        },
        bornNum = 5000,
        workId = -1,
        bornType = 1, //1 unlockWrok 2 loot
        setShowGasMode,
    }
) => {
    const {t, i18n} = useTranslation();
    const bornTxtObj = {
        1: {
            name: t('Unlock')
        },
        2: {
            name: t('Loot')
        },
    }
    const {logout} = usePrivy();
    const {wallets} = useWallets();
    const [num, setNum] = useState(bornNum);
    const inputRef = useRef(null);
    const [subFailded, setSubFailded] = useState(false);
    //const [noSuccessText, setNoSuccessText] = useState(t('TRANSACTION FAILED'));
    const [done, setDone] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const [checkType, setCheckType] = useState(localStorage.getItem('sel_born_type') || 'chain');
    const [failedMessage, setFailedMessage] = useState(null);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    // const [chainDisabled , setChainDisabled] = useState(false);
    // const [poolDisabled , setPoolDisabled] = useState(false);

    // const [isAvailable,setIsAvailable] = useState(false)
    const {walletAddr, selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {balance, lfgBalance, claimCoin} = useMemo(() => {
        return selfData || {}
    }, [selfData]);

    // const {
    //     claimCoin,
    //     // lfgB = 1000
    // } = useMemo(() => {
    //     return earnInfo || {}
    // }, [earnInfo])

    const isAvailable = useMemo(() => {
        if (checkType === 'chain') {
            return lfgBalance >= bornNum
        }
        if (checkType === 'pool') {
            return claimCoin >= bornNum
        }
    }, [checkType])


    const bornLfgFunc = (
        {
            amount,
            orderId,
            reason,
            mintNow,
            timestamp,
            validFor,
            signature
        }
    ) => {
        bornLfg({
            wallets,
            amount,
            orderId,
            reason,
            mintNow,
            timestamp,
            validFor,
            signature
        }).then(res => {
            console.log(res);

            setSubLoading(false);
            setDone(true);
            setSubFailded(false);
            checkFn(orderId)
        }).catch(e => {
            setFailedMessage(t(getTransactionErrorMsgId(e?.reason)));
            setSubLoading(false);
            setDone(true);
            setSubFailded(true)
            setTimeout(
                () => {
                    getearninfo().then(res => {
                    }).catch(e => {
                        console.log(e);
                    })
                }, 2000
            )
        })
    }


    const applyFn = () => {
        var costType = checkType === 'pool' ? 1 : 0;
        if (!isAvailable) {
            setShowSwapLfg(true);
            return
        }
        if (bornType === 1) {
            //mission unlock
            setSubLoading(true);
            getBurnParam_ulock({bornType, workId, costType}).then(res => {

                console.log(res);
                getearninfo().then(res => {
                }).catch(e => {
                    console.log(e);
                })
                bornLfgFunc(res);
            }).catch(e => {
                console.error(e);
            })
        }
        if (bornType === 2) {
            //loot
            setSubLoading(true);
            getBurnParam_loot({employeeId, tarUserId, bornNum, costType}).then(res => {

                console.log(res);
                if (res) {
                    getearninfo().then(res => {
                    }).catch(e => {
                        console.log(e);
                    })
                    lootBornLfg(res);
                } else {
                    setSubLoading(false);
                    setSubFailded(true);
                    setFailedMessage(t('Transaction failed due to other unknown reason'));
                }
                //bornLfgFunc(res);
            }).catch(e => {
                if (e === 336) {
                    setFailedMessage(filterErrorCode(e));
                }
                setSubLoading(false);
                setSubFailded(true);
                console.error(e);
            })
        }
    }
    const selFn = (type) => {
        // console.log(type);
        localStorage.setItem('sel_born_type', type)
        setCheckType(type)
    }
    return (
        <div className={s.content}>
            <div className={s.amountWrap}>
                <div className="color-white w100p tll">{t('Burn Your $LFG from:')}</div>
                <div className={s.t}>
                    <div className={s.i}>
                        <div>
                            <Checkbox onClick={() => selFn('chain')}
                                      className="hireCheckBox"
                                      checked={checkType === 'chain'}
                            />
                        </div>
                        <div className={s.label}>{t('$LFG Wallet')}</div>
                        <div className={s.val}>
                            <CustomIcon className="m02" width={20} height={20}
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            <div className={s.num}>{vCoinString(lfgBalance)}</div>
                        </div>
                    </div>
                    <div className={s.i}>
                        <div>
                            <Checkbox onClick={() => selFn('pool')}
                                      className="hireCheckBox"
                                      checked={checkType === 'pool'}
                            />
                        </div>
                        <div className={s.label}>{t('$LFG Pool')}</div>
                        <div className={s.val}>
                            <CustomIcon className="m02" width={20} height={20}
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            <div className={s.num}>{formatNumberWithUnit(parseInt(claimCoin))}</div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={s.errorMessageRoot}>
                {
                    failedMessage && <div className={s.errorMessageLine}>
                        <CustomIcon className="m02" width={18} height={18} imgName={'Picture/UI_Picture_RedRound_Cross_01'}
                                    name={'Picture/UI_Picture_RedRound_Cross_01'}/>
                        <span>{failedMessage}</span>
                    </div>
                }
            </div>
            <div className={s.btnWrap}>
                <div className="fs12 w100p df alc jcc mt8">{bornType === 1 ? t('Unlock Fee') : t('Loot Fee')}
                    <CustomIcon className="ml5" width={18} height={18} imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                name={'Picture/UI_Picture-Currency_LFG_01'}/><span
                        className="color-yellow">&nbsp;{vCoinString(bornNum)}</span></div>
            </div>
            <div style={{height: '10px'}}></div>

            <CustomIcon width={30} height={30}
                        className="modelBottomSetGas"
                        imgName={'Button/UI_Button_Gas_01'}
                        onClick={() => {
                            setShowGasMode(true);
                        }}
            />
            <div className="modelBottomBtn">
                {subFailded ?
                    <ButtonFactory onClick={closeFn}>
                        <div className="flex_center_center_col">
                            <div className={s.transactionFailedBtnTitle}>
                                {t('TRANSACTION FAILED')}
                            </div>
                            <div className={s.transactionFailedBtnLine}>
                                <CustomIcon className="mr5" width={16} height={16}
                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                            name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                <div className="fs11 fw-300">{t('xx will be refuned after yy minutes', {
                                    lfg: convertWalletBalance(bornNum),
                                    time: displayUnlockMissionRefundTime
                                })}</div>
                            </div>
                        </div>
                    </ButtonFactory> :
                    subLoading ?
                        <ButtonFactory disabled={true} onClick={() => {
                        }}>
                            <CustomIcon rotating={true} className="ml5" width={26} height={26}
                                        imgName={'Picture/UI_Picture_Loading_01'}
                                        name={'Picture/UI_Picture_Loading_01'}/>
                            <span className="fs18 color-black">{t('PROCESSING')}</span>
                        </ButtonFactory> :
                        done ?
                            <ButtonFactory onClick={closeFn}>
                                {t('TRANSACTION SUCCESSFUL')}
                            </ButtonFactory> :
                            <ButtonFactory
                                onClick={applyFn}>
                                {bornTxtObj[bornType]?.name}
                            </ButtonFactory>
                }
            </div>

            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg insufficientAmount={Number(bornNum - lfgBalance)} userInfo={userInfo} closeFn={() => {
                    setShowSwapLfg(false);
                }}/>
            </AntModal>
        </div>
    );
};
const mapStateToProps = ({app}) => {
    return {
        earnInfo: app.earnInfo,
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelBornLFG));
