import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "src/common/CustomIcon";
import {convertWalletBalance, formatNumberWithUnit, getAvatarFromProfile, numberClamp, sliceStr, stringToNumber, unit} from "@/utils/common";
import {connect} from "react-redux";
import AvatarInfo from "src/common/AvatarInfo";
import {post_uppoints_event} from "@/utils/questScore";
import { getChainInfo } from "@/utils/memeClub";

const ModelUpPointLog = (
    {
        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();

    const {
        selfData,

    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const {
        points,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData])

    const timeFn = (time) => {
        let d = parseInt(time / 60 / 60 / 24);
        let h = parseInt(time / 60 / 60 % 24);
        let m = parseInt(time / 60 % 60);
        let str = '';
        if (d > 0) {
            str = d + "d";
            return str
        }
        if (h > 0) {
            str = h + "h";
            return str
        }
        if (m > 0) {
            str = m + "m";
            return str
        }
        return t('few seconds');

        // return `${d}${h}${m}`
    }

    const [list, setList] = useState([]);

    useEffect(() => {
        post_uppoints_event(0).then(res => {
            setList(res?.data?.eventList || [])
        })
    }, [])


    return (
        <div className={s.wrap}>
            <div className={s.topPart}>
                <div className={s.content}>
                    <div className={`${s.imgRoot} fs30`}>
                        <CustomIcon
                            width={40}
                            height={40}
                            imgName={"Picture/UI_Picture-Currency_UP_01"}
                            name={"Picture/UI_Picture-Currency_UP_01"}
                            className="mr5"
                        /> {Math.trunc(points)}
                    </div>
                    <div className={`${s.contentText} mt10`} style={{height: "400px", overflowY: "auto"}}>
                        {
                            list.length > 0 ? list.map((item, ind) => {
                                const {
                                    eventType,
                                    upPoints,
                                    rankStr,
                                    timeDuration,
                                    farmWheelVal,
                                    farmFevelVal,
                                    coinId,
                                    costValue
                                } = item;
                                return (
                                    <div className={`${s.item}`} key={ind}>
                                        <div className={`${s.tranInfo} w100p`}>
                                            <div className="w100p fs10 color-blue-light tlr" style={{padding: '0 5px'}}>
                                                {t('xx ago', {time: timeFn(timeDuration)})}
                                            </div>
                                            <div className="fs20 tlc w100p" style={{paddingBottom: '6px'}}>
                                                <CustomIcon
                                                    className=""
                                                    width={20}
                                                    height={20}
                                                    imgName={'Picture/UI_Picture-Currency_UP_01'}
                                                    name={'Picture/UI_Picture-Currency_UP_01'}/>
                                                <span
                                                    className="color-white fs20"> +{stringToNumber(upPoints, 4)}</span>
                                            </div>
                                            {eventType === 1 &&
                                                <div className={`fs12 tll w100p tlc color-blue-light`}
                                                     style={{padding: '0 5px'}}>
                                                    <Trans
                                                        i18nKey='Ranked {{rank}} on the LFG leaderboard.'
                                                        defaults='Ranked <fb>{{rank}}</fb> on the LFG leaderboard.'
                                                        values={{rank: rankStr}}
                                                        components={{fb: <span className="fb color-white"></span>}}
                                                    />
                                                </div>}
                                            {eventType === 2 &&
                                                <div className={`fs12 tll w100p tll color-blue-light`}
                                                     style={{padding: '0 5px'}}>
                                                    <Trans
                                                        i18nKey='Engaged with TURNUP across various actions, ranked {{rank}} on the TURNUP score leaderboard.'
                                                        defaults='Engaged with TURNUP across various actions, ranked <fb>{{rank}}</fb> on the TURNUP score leaderboard.'
                                                        values={{rank: rankStr}}
                                                        components={{fb: <span className="fb color-white"></span>}}
                                                    />
                                                </div>}
                                            {eventType === 3 &&
                                                <div className={`fs12 tll w100p tlc color-blue-light`}
                                                     style={{padding: '0 5px'}}>
                                                    <Trans
                                                        i18nKey='Compensation for Missed UP Points on {{rank}}.'
                                                        defaults='Compensation for Missed UP Points on {{rank}}.'
                                                        values={{rank: rankStr}}
                                                        components={{fb: <span className="fb color-white"></span>}}
                                                    />
                                                </div>}
                                            {eventType === 4 &&
                                                <div className={`fs12 tll w100p tlc color-blue-light df alc jcc`}
                                                     style={{padding: '0 5px'}}>
                                                    <Trans
                                                        i18nKey='<fire></fire>{{fireVal}} x<points></points>{{pointVal}}% X Farming'
                                                        defaults='<fire></fire>{{fireVal}} x<points></points>{{pointVal}}% X Farming'
                                                        values={{fireVal: Math.round(farmFevelVal),pointVal:stringToNumber(farmWheelVal * 100, 0)}}
                                                        components={{
                                                            fire: <CustomIcon
                                                            width={14}
                                                            className="ml2 mr2"
                                                            height={14}
                                                            imgName={'Picture/UI_Picture-Currency_UP_01'}
                                                            ></CustomIcon>,
                                                            points:<CustomIcon
                                                                width={14}
                                                                className="ml2 mr2"
                                                                height={14}
                                                                imgName={'Picture/UI_Picture_Bonus'}
                                                                ></CustomIcon>
                                                        }}
                                                    />
                                                </div>}
                                            {eventType === 5 &&
                                                <div className={`fs12 tll w100p tlc color-blue-light`}
                                                     style={{padding: '0 5px'}}>
                                                    <Trans
                                                        i18nKey='Spent <fb>{{amount}}</fb> {{coinName}} to buy packs'
                                                        defaults='Spent <fb>{{amount}}</fb> {{coinName}} to buy packs'
                                                        values={{
                                                            amount: convertWalletBalance(costValue),
                                                            coinName: getChainInfo(coinId)?.displayCoinName
                                                        }}
                                                        components={{fb: <span className="fb color-white"></span>}}
                                                    />
                                                </div>}
                                        </div>
                                    </div>
                                )
                            }) : t('no data')
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelUpPointLog));
