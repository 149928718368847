import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
// import Banner from "../../../common/Banner";
import {
    stringToNumber,
    convertWalletBalance,
    getFriendtradeLootLastevent,
    searchuser,
    displayFilter,
    getChainTokenInfoById,
    numberClamp,
    sliceStr,
    convertTimestamp,
} from "@/utils/common"
import AntModal from "@/common/AntModal";
import ModalMemePackTrans from "@/model/ModalMemePackTrans";
import ModalKingOfTgeInfo from "@/model/ModalKingOfTgeInfo";
import MemePackTrans from "@/views/games/MomentClub/ClubDetail/Club/Packs";
import {
    coinList,
    momentClubList,
    setFavorite,
    getRecommendClub,
    searchclub,
    getChainInfo,
    getMomentKingTgeList,
} from "@/utils/momentClub";
/*import {
    coinList,
    memeClubList,
    setFavorite,
    getRecommendClub,
    searchclub,
    getChainInfo,
    getMemeKingTgeList,
} from "@/utils/memeClub";*/
import MainHeader from "@/common/MainHeader";
import AntDrawer from "@/common/AntDrawer";
import MomentChainToken from "@/utils/json/momentChainToken.json";
import { isOnlineEnv } from "@/utils/env";
import {
    gameMomentClubDetailPath,
} from "@/routes/config";
import AvatarInfo from "@/common/AvatarInfo";
import SearchUser from "@/common/SearchUser";
import SearchUserList from "@/common/SearchUserList";
import MomentClubFilter from "@/drawer/MomentClubFilter";
import MomentClubSort from "@/drawer/MomentClubSort";
import SearchMemeClubList from "@/common/SearchMemeClubList";
import InfiniteScroll from "react-infinite-scroll-component";
import Countdown from "@/common/Countdown";
import {
    Spin,
    Carousel,
    Switch,
    message,
} from "antd";
import MemeClubPack from "@/common/MemeClubPack";
import useLocalStorageState from "@/common/useLocalStorageState";
import SearchMomenttClubList from "@/common/SearchMomenttClubList";

const maxCoinPrice = 200;
const maxMarketCap = 20000;
const maxFavorite = 200;


const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    return useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);
};

const throttle = (func, limit) => {
    let inThrottle;
    return (...args) => {
        if (!inThrottle) {
            func(...args);
            inThrottle = true;
            setTimeout(() => {
                inThrottle = false;
            }, limit);
        }
    };
};

const MomentExplore = (
    {
        userInfo,
        setHideRefreshBtn,
        momentClubFilterList,
        setMomentClubFilterList,
        hideNSFW,
        setHideNSFW,
        setSwitchVideoType,
        memeclubSwitchVideoParams,
        setMemeclubSwitchVideoParams,
        switchVideoListCursor,
        setSwitchVideoListCursor,
        switchVideoList,
        setSwitchVideoList,
        setCurrentMomentClubId,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const totalWidth = useMemo(() => {
        return Math.min(window.innerWidth, 500);
    }, [window.innerWidth]);

    const linkTo = (id) => {
        navigate(`${gameMomentClubDetailPath}`)
        setCurrentMomentClubId(id);
    }
    const [updateLootEventTimes, setUpdateLootEventTimes] = useState(0);
    const [showMemePackTrans, setShowMemePackTrans] = useState(false);
    const [transPackId, setTransPackId] = useState(0);
    const [clubList, setClubList] = useState(null);

    const [leftColumn, setLeftColumn] = useState([]);
    const [leftColumnHeight, setLeftColumnHeight] = useState(0);
    const [rightColumn, setRightColumn] = useState([]);
    const [rightColumnHeight, setRightColumnHeight] = useState(0);
    const marginBottom = 6;
    const oneLineHeight = 48;
    const twoLineHeight = 65;
    const [columWidth, setListWidth] = useState(0);

    useEffect(() => {
        const columContainer = document.getElementById('listColum');
        if (columContainer) {
          const columWidth = columContainer.getBoundingClientRect().width;
          setListWidth(columWidth);
        }
    }, []);

    const calColumHeightFn = (videoHeight, videoWidth) =>  {
        const maxHeight = 280;
        const minHeight = 134;
        
        let columHeight = (columWidth / videoWidth) * videoHeight;
        if (columHeight < minHeight) {
          columHeight = minHeight;
        } else if (columHeight > maxHeight) {
          columHeight = maxHeight;
        }
      
        return columHeight;
    }

    const showList = useMemo(() => {
        return [...leftColumn || [], ...rightColumn || []];
    }, [leftColumn, rightColumn])

    /*const updateLootEvent = () => {
        getFriendtradeLootLastevent().then(data => {
            setLootLastEventData(data?.lastLootEventData);
            setTimeout(() => {
                setUpdateLootEventTimes(updateLootEventTimes + 1);
            }, 10000);
        }).catch(err => {
            console.error(err);
            setTimeout(() => {
                setUpdateLootEventTimes(updateLootEventTimes + 1);
            }, 10000);
        });
    }*/

    useEffect(() => {
        // updateLootEvent();
    }, [updateLootEventTimes])


    const [searchResult, setSearchResult] = useState([]);
    const [searchKeyValue, setSearchKeyValue] = useState('');
    const [searchCursor, setSearchCursor] = useState(-1);


    const searchUserHandle = (searchKey) => {
        if (!searchKey) {
            setSearchCursor(-1)
            setSearchResult([])
        } 
        // else {
        //     setHideRefreshBtn(true);
            setSearchKeyValue(searchKey)
        // }
        searchKey && searchclub(searchKey, 0, 0).then(res => {
            setSearchCursor(res?.nextCursor);
            setSearchResult(res?.clubList || []);
        }).catch(e => {
            console.log(e);
        })
    }


    const getMoreSearchResult = () => {
        searchclub(searchKeyValue, searchCursor, 0).then(res => {
            setSearchCursor(res?.nextCursor);
            setSearchResult([
                ...searchResult,
                ...res?.clubList || []
            ]);
        }).catch(e => {
            console.log(e);
        })
    }

    const nativeTokenList = useMemo(() => {
        let list = [];
        if (MomentChainToken?.length > 0) {
            for (let i = 0; i < MomentChainToken?.length; i++) {
                let tokenInfo = MomentChainToken[i];
                if (tokenInfo?.isEnable === 1) {
                    if (isOnlineEnv() && tokenInfo?.isTestChain === 0) {
                        list.push(tokenInfo);
                    }
                    else if (!isOnlineEnv() && tokenInfo?.isTestChain === 1) {
                        list.push(tokenInfo);
                    }
                }
            }
        }
        return list;
    }, [MomentChainToken]);

    // filter sort start

    const [showSort, setShowSort] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const sortData = [
        {
            label: t('Recommiend'),
            key: 3,
        },
        {
            label: t('Transactions Time'),
            key: 0,
        },
        {
            label: t('Create Time'),
            key: 1,
        }, {
            label: t('Currently Price'),
            key: 4,
        }/*, {
            label: t('Currently Liquidity Pool'),
            key: 6,
        }, {
            label: t('TGE Progress'),
            key: 2,
        },*/
    ]
    const [sortType, setSortType] = useState(3)
    const [coinId, setCoinId] = useState(0)
    const [isAsc, setIsAsc] = useState(false)

    const [filterPackPrice, setFilterPackPrice] = useState([0, -1]);
    const [filterMarketCap, setFilterMarketCap] = useState([0, -1]);
    const [filterTgePro, setFilterTgePro] = useState([0, 100]);
    const [filterFavoriteRange, setFilterFavoriteRange] = useState([0, -1]);
    const [filterOnlyFavorite, setFilterOnlyFavorite] = useState(false);
    const [filterOnlyLive, setFilterOnlyLive] = useState(false);
    const [filterCoinList, setFilterCoinList] = useState(coinList.map(item=> item.chainId))
    const [filterOnlyHeld, setFilterOnlyHeld] = useState(false);
    // filter sort end
    const [list, setList] = useState([])
    const [nextCursor, setNextCursor] = useState('');
    const [loadMoreList, setLoadMoreList] = useState(false)
    const [refreshListCount, setRefreshListCount] = useState(0);

    const getList = ({
        rankType,
        coinId,
        priceRange,
        progressRange,
        favoriteRange,
        lpRange,
        onlyFavorite,
        onlyHeld,
        onlyLive,
        isAsc,
        chainIds,
        cursor = ``
    }) => {
        let tempPriceRange = [priceRange[0] * 1000, -1];
        if (priceRange[1] !== -1) {
            tempPriceRange[1] = Math.floor(priceRange[1] * 1000);
        }
        if (cursor) {
            setLoadMoreList(true)
        } else {
            setLoadMoreList(false)
        }
        momentClubList({
            rankType,
            coinId,
            priceRange: tempPriceRange,
            progressRange,
            favoriteRange,
            lpRange,
            onlyFavorite,
            onlyHeld,
            onlyLive,
            isAsc,
            chainIds,
            cursor
        }).then(res => {
            // if (res?.clubList?.length > 0 || res?.nextCursor !== ""){
            if (cursor === '') {
                setClubList(res?.clubList || []);
            }
            else {
                setClubList([
                    ...clubList,
                    ...(res?.clubList || []),
                ])
            }
            setNextCursor(res?.nextCursor);
            // }
            // else{
            //     getList({
            //         rankType: sortType,
            //         coinId: coinId,
            //         priceRange: filterPackPrice,
            //         progressRange: filterTgePro,

            //         lpRange: filterMarketCap,
            //         onlyFavorite: filterOnlyFavorite,
            //         onlyHeld: filterOnlyHeld,
            //         onlyLive: filterOnlyLive,
            //         isAsc: isAsc,
            //         cursor: res?.nextCursor
            //     })
            // }
        }).catch(e => {
            console.error(e);
        })
    }

    const updateShowList = () => {
        let tempLeftList = [];
        let tempLeftHeight = 0;
        let tempRightList = [];
        let tempRightHeight = 0;
        for (let i = 0; i < clubList?.length; i++) {
            let tempClub = clubList[i];
            if (hideNSFW && tempClub?.profile?.momentAuditCode === 2) {
                continue;
            }
            let bottomHeight = oneLineHeight;
            let videoHeight = tempClub?.videoOriHeight;
            let videoWidth = tempClub?.videoOriWidth;
            let bgHeight = calColumHeightFn(videoHeight,videoWidth);
            if (tempClub?.profile?.displayName?.length + tempClub?.des?.length > 25) {
                bottomHeight = twoLineHeight;
            }
            if (tempLeftHeight - tempRightHeight > 0) {
                tempRightList.push(tempClub);
                tempRightHeight += bgHeight + bottomHeight + marginBottom;
            }
            else {
                tempLeftList.push(tempClub);
                tempLeftHeight += bgHeight + bottomHeight + marginBottom;
            }
        }
        setLeftColumn(tempLeftList);
        setLeftColumnHeight(tempLeftHeight);
        setRightColumn(tempRightList);
        setRightColumnHeight(tempRightHeight);
    }

    const favoriteFn = (userId, favorite, type = 0) => {
        setFavorite(userId, favorite).then(res => {
            const { clubUserId, favorite } = res;
            // if(type === 1){
            if (clubUserId === recommendData?.clubUserId) {
                setRecommendData({
                    ...recommendData,
                    isFavorite: favorite
                })
            }
            // }else if(type === 2){
            const _searchResult = searchResult.map(item => {
                if (item.clubUserId === clubUserId) {
                    return {
                        ...item,
                        isFavorite: favorite
                    };
                } else {
                    return item
                }
            });
            setSearchResult(_searchResult)
            // } else{
            const _list = list.map(item => {
                if (item.userId === clubUserId) {
                    return {
                        ...item,
                        isFavorite: favorite
                    };
                } else {
                    return item
                }
            });
            setList(_list)
            // }

        }).catch(e => {
            console.error(e);
        })
    }



    const [recommendData, setRecommendData] = useState(null)

    const getRecommend = () => {
        getRecommendClub().then(res => {
            setRecommendData(res)
            console.log(res);
        }).catch(e => {
            console.error(e);
        })
    }

    const [isSticky, setIsSticky] = useState(false);
    const stickyRef = useRef(null);
    const containerRef = useRef(null);

    const [rotatingItems, setRotatingItems] = useState({});


    useEffect(() => {
        let filterParam = {
            rankType: sortType,
            coinId: coinId,
            priceRange: filterPackPrice,
            progressRange: filterTgePro,

            lpRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            onlyLive: filterOnlyLive,
            chainIds: filterCoinList,
            isAsc: isAsc,
            cursor: ``
        };

        if (momentClubFilterList) {
            if (momentClubFilterList?.rankType) {
                filterParam.rankType = momentClubFilterList?.rankType;
                setSortType(momentClubFilterList?.rankType);
            }
            if (momentClubFilterList?.coinId) {
                filterParam.coinId = momentClubFilterList?.coinId;
                setCoinId(momentClubFilterList?.coinId);
            }
            if (momentClubFilterList?.priceRange) {
                filterParam.priceRange = momentClubFilterList?.priceRange;
                setFilterPackPrice(momentClubFilterList?.priceRange);
            }
            if (momentClubFilterList?.progressRange) {
                filterParam.progressRange = momentClubFilterList?.progressRange;
                setFilterTgePro(momentClubFilterList?.progressRange);
            }
            if (momentClubFilterList?.favoriteRange) {
                filterParam.favoriteRange = momentClubFilterList?.favoriteRange;
                setFilterFavoriteRange(momentClubFilterList?.favoriteRange);
            }
            if (momentClubFilterList?.lpRange) {
                filterParam.lpRange = momentClubFilterList?.lpRange;
                setFilterMarketCap(momentClubFilterList?.lpRange);
            }
            if (momentClubFilterList?.onlyFavorite) {
                filterParam.onlyFavorite = momentClubFilterList?.onlyFavorite;
                setFilterOnlyFavorite(momentClubFilterList?.onlyFavorite);
            }
            if (momentClubFilterList?.onlyLive) {
                filterParam.onlyLive = momentClubFilterList?.onlyLive;
                setFilterOnlyLive(momentClubFilterList?.onlyLive);
            }
            if (momentClubFilterList?.filterCoinList){
                filterParam.chainIds = momentClubFilterList?.filterCoinList;
                setFilterCoinList(momentClubFilterList?.filterCoinList);
            }
            if (momentClubFilterList?.onlyHeld) {
                filterParam.onlyHeld = momentClubFilterList?.onlyHeld;
                setFilterOnlyHeld(momentClubFilterList?.onlyHeld);
            }
            if (momentClubFilterList?.isAsc) {
                filterParam.isAsc = momentClubFilterList?.isAsc;
                setIsAsc(momentClubFilterList?.isAsc);
            }
        }

        getList(filterParam)
        getRecommend()
    }, [])

    const getMoreList = () => {
        getList({
            rankType: sortType,
            coinId: coinId,
            priceRange: filterPackPrice,
            progressRange: filterTgePro,

            lpRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            onlyLive: filterOnlyLive,
            isAsc: isAsc,
            chainIds: filterCoinList,
            cursor: nextCursor
        })
    }

    const applyClubFilter = (item) => {
        let tempCoinId = coinId;
        if (item?.sortType !== 1 || item?.coinId !== 0) {
            tempCoinId = item?.coinId;
            setCoinId(item?.coinId);
        }
        setFilterOnlyFavorite(item?.onlyFavorite);
        setFilterOnlyLive(item?.onlyLive);
        setFilterOnlyHeld(item?.onlyHeld);
        setFilterCoinList(item?.selCoinList)

        let tempPackPrice = [0, -1];
        if (item?.priceRange?.[0] >= 0) {
            tempPackPrice[0] = item?.priceRange?.[0];
        }
        if (item?.priceRange?.[1] < maxCoinPrice) {
            tempPackPrice[1] = item?.priceRange?.[1];
        }
        setFilterPackPrice(tempPackPrice);

        let tempMarketCap = [0, -1];
        if (item?.lpRange?.[0] >= 0) {
            tempMarketCap[0] = item?.lpRange?.[0];
        }
        if (item?.lpRange?.[1] < maxMarketCap) {
            tempMarketCap[1] = item?.lpRange?.[1];
        }
        setFilterMarketCap(tempMarketCap);

        let tempFavoriteRange = [0, -1];
        if (item?.favoriteRange?.[0] >= 0) {
            tempFavoriteRange[0] = item?.favoriteRange?.[0];
        }
        if (item?.favoriteRange?.[1] < maxFavorite) {
            tempFavoriteRange[1] = item?.favoriteRange?.[1];
        }
        setFilterFavoriteRange(tempFavoriteRange);

        let tgeProcessRange = [0, 100];

        if (item?.tgeProcess?.[0] >= 0) {
            tgeProcessRange[0] = item?.tgeProcess?.[0];
        }
        if (item?.tgeProcess?.[1] < 100) {
            tgeProcessRange[1] = item?.tgeProcess?.[1];
        }
        setFilterTgePro(tgeProcessRange);
        let filter = {
            rankType: sortType,
            coinId: tempCoinId,
            priceRange: tempPackPrice,
            progressRange: tgeProcessRange,
            favoriteRange: tempFavoriteRange,
            lpRange: tempMarketCap,
            onlyFavorite: item?.onlyFavorite,
            onlyHeld: item?.onlyHeld,
            onlyLive: item?.onlyLive,
            chainIds: item?.selCoinList,
            isAsc: isAsc,
        }

        getList({
            ...filter,
            cursor: ''
        })
        setMomentClubFilterList(filter);
    }

    const applyClubSort = (item) => {
        setIsAsc(item?.isAsc);
        setSortType(item?.sortType);
        let sortCoin = coinId;
        /*if (item?.sortType === 4) {
            sortCoin = item?.coinId;
            setCoinId(item?.coinId);
        }*/

        let filter = {
            rankType: item?.sortType,
            coinId: sortCoin,
            priceRange: filterPackPrice,
            progressRange: filterTgePro,
            
            lpRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            onlyLive: filterOnlyLive,
            chainIds: filterCoinList,
            isAsc: item?.isAsc,
        }

        getList({
            ...filter,
            cursor: ''
        })

        setMomentClubFilterList(filter);
    }
    const handleScroll = throttle(() => {
        const stickyOffset = stickyRef.current.offsetTop;
        setIsSticky(100 < stickyOffset);
    }, 100)
    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const reFreshClubInfo = () => {
        getList({
            rankType: sortType,
            coinId,
            priceRange: filterPackPrice,
            progressRange: filterTgePro,
            favoriteRange: [0, -1],
            lpRange: [0, -1],
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: false,
            onlyLive: false,
            chainIds: filterCoinList,
            isAsc,
            cursor: ``
        })
    }


    const [showCheckFlash, setshowCheckFlash] = useState(false)

    const [flashShow, setFlashShow] = useLocalStorageState('momentClub_flashShow', true)

    const changeClubList = () => {
        setList(prevArray => {
            const newArray = [...prevArray];
            const lastElement = newArray.pop();
            newArray.unshift(lastElement);
            return newArray;
        });
    }

    useEffect(() => {
        if (sortType === 0 && isAsc === false) {
            setshowCheckFlash(true)
        } else {
            setshowCheckFlash(false)
        }
    }, [sortType, isAsc])

    useEffect(() => {
        updateShowList();
    }, [clubList, hideNSFW])

    useEffect(() => {
        let timer = null;
        if (list && list?.length && !timer && flashShow && showCheckFlash) {
            timer = setInterval(() => {
                changeClubList()
            }, 6000)
        }
        if (!flashShow || !showCheckFlash) {
            timer && clearInterval(timer)
        }
        return () => {
            timer && clearInterval(timer)
        }
    }, [list, flashShow, showCheckFlash])

    useEffect(() => {
        if (refreshListCount > 0) {
            getList({
                rankType: sortType,
                coinId: coinId,
                priceRange: filterPackPrice,
                progressRange: filterTgePro,

                lpRange: filterMarketCap,
                onlyFavorite: filterOnlyFavorite,
                onlyHeld: filterOnlyHeld,
                onlyLive: filterOnlyLive,
                chainIds: filterCoinList,
                isAsc: isAsc,
                cursor: ``
            })
        }
    }, [refreshListCount]);

    const showNSFWInfo = (isNSFWHidden) => {
        const messageContent = isNSFWHidden ? t('NSFW content display disabled') : t('NSFW content display enabled');
        message.info({ content: messageContent });
    };

    useEffect(() => {
        /*let timer = null;
        if (!timer && !loadMoreList) {
            let count = refreshListCount;
            timer = setInterval(() => {
                count++;
                setRefreshListCount(count);
            }, 30000)
        }
        if (loadMoreList) {
            timer && clearInterval(timer)
        }
        
        return () => {
            timer && clearInterval(timer)
        }*/

    }, [flashShow, nextCursor, showCheckFlash])

    const getColumnItem = (item, index) => {
        const {
            buyPrice,
            currentCardNum,
            historyPrice,
            isFavorite,
            maxCardNum,
            profile,
            userId,
            totalWorth,
            holdNum,
            clubSymbolName,
            sellPrice,
            ftTotalSupply,
            airdropPercent,
            openPackRatio,
            coinToNFTRatio,
            isFT,
            onAlive,
            announceStartTime,
            announceTitle,
            aliveImage,
            videoOriHeight,
            videoOriWidth,
            description,
            likeNum,
            isLike,
        } = item || {};
        const {
            accType,
            displayName,
            avatarUrl,
            coinId,
            momentAuditCode,
        } = profile || {};
        const range = historyPrice ? Math.round((sellPrice - historyPrice) / historyPrice * 100) : 0;
        const _itemCoinObj = getChainInfo(coinId);

        const handleFavoriteClick = (userId, isFavorite) => {
            setRotatingItems(prevState => ({ ...prevState, [userId]: true }));
            setTimeout(() => {
                setRotatingItems(prevState => ({ ...prevState, [userId]: false }));
            }, 500);
            favoriteFn(userId, !isFavorite);
        };

        const progressP = Math.round(currentCardNum / maxCardNum * 100);
        const bgUrl = progressP > 20 ? progressP > 70 ? '/UI/Picture/UI_Picture_Explore_CardPacks_Effect_01.png' : '/UI/Picture/UI_Picture_Explore_CardPacks_Effect_02.png' : 'none';

        let coverUrl = '/UI/Picture/UI_Picture_Empty_Bg_01.png';
        if (avatarUrl && (momentAuditCode === 0 || momentAuditCode === 1 || momentAuditCode === 2)) {
            coverUrl = avatarUrl;
        }

        return (
            <div
                className={`${s.item} flex_center_start_col`}
                key={`${userId}_${index}`}
                style={{
                    width: `${totalWidth * 0.4564}px`,
                    marginBottom: `${marginBottom}px`
                }}
                onClick={() => {
                    linkTo(userId)
                    setShowMemePackTrans(true);
                    setTransPackId(userId);
                    setSwitchVideoType(1);
                    setMemeclubSwitchVideoParams({
                        rankType: sortType,
                        coinId: coinId,
                        favoriteRange: '',
                        priceRange: filterPackPrice,
                        progressRange: filterTgePro,
                        lpRange: filterMarketCap,
                        onlyFavorite: filterOnlyFavorite,
                        onlyHeld: filterOnlyHeld,
                        onlyLive: filterOnlyLive,
                        isAsc: isAsc,
                        cursor: nextCursor
                    });
                    if (nextCursor !== ""){
                        setSwitchVideoListCursor(nextCursor);
                    }
                    else{
                        setSwitchVideoListCursor("-1");
                    }
                    let tempSwitchVideoList = [];
                    if (clubList.length > 0) {
                        for (let i = 0; i < clubList.length; i++) {
                            let stateCode = clubList[i].profile.momentAuditCode;
                            if (stateCode !== 2 || (stateCode === 2 && !hideNSFW)) {
                                tempSwitchVideoList.push({
                                    clubId: clubList[i].userId,
                                    momentAuditCode: stateCode,
                                    videoId: clubList[i].videoId,
                                    videoUrlMap: clubList[i].videoUrlMap,
                                    coverUrl: clubList[i].profile.avatarUrl,
                                    videoOriHeight: clubList[i].videoOriHeight,
                                    videoOriWidth: clubList[i].videoOriWidth,
                                    videoFirstFrameUrl: clubList[i].videoFirstFrameUrl,
                                });
                            }
                        }
                    }
                    setSwitchVideoList(tempSwitchVideoList);
                }}
            >
                <div className={`${s.imageRoot}`}>
                    <img src={coverUrl} alt="cover" className={s.imageBg} />
                    {
                        /*momentAuditCode === 0 &&
                        <div className={`${s.progressingPart} flex_center_center_col`}>
                            <CustomIcon className="" width={62} height={62}
                                imgName={'Picture/UI_Picture_Loading_02'} />
                            <div className={`momentFont fs18 fb mt20 textnowrap`}>
                                {t('Video Processing')}
                            </div>
                        </div>*/
                    }
                    {
                        momentAuditCode === 3 &&
                        <div className={`${s.blockedPart} flex_center_center_col`}>
                            <CustomIcon className="" width={50} height={50}
                                imgName={'Picture/UI_Picture_Icon_Lock_01'} />
                            <div className={`momentFont fs18 fb mt20 textnowrap`}>
                                {t('Video Blocked')}
                            </div>
                        </div>
                    }
                    <div className={`${s.range} flex_center_between`}>
                        <div className={s.chainInfo}>
                            <CustomIcon imgName={`Picture/${_itemCoinObj?.chainicon}`} className="mr5" width={16} height={16}></CustomIcon>
                            <div className="momentFont fs12">{_itemCoinObj?.chainShowName}</div>
                        </div>
                        <div className={`flex_center_start mr10`}>
                            <CustomIcon imgName={isLike ? 'Picture/UI_Picture_MemeDetails_Like_02' : `Picture/UI_Picture_MemeDetails_Like_01`} className="mr5" width={12} height={12}></CustomIcon>
                            <div className={`fs12 color-moment-gray momentFont`}>
                                {likeNum}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${s.bottomRoot} flex_center_start_col `}>
                    <div className={`${s.bottomText}`}>
                        <span className="momentFont fs12 color-EEEEEE momentFont">
                            ${displayName || ''}
                        </span>:&nbsp;
                        <span className="momentFont fs12 color-moment-light-gray momentFont">
                            {description || ''}
                        </span>
                    </div>
                    <div className={`${s.stateRoot} flex_center_between`}>
                        
                        <div className={`flex_center_end`}>
                            <div className={`flex_center_end fs12 fb color-moment-light-gray momentFont`}>
                                <CustomIcon imgName={`Picture/${_itemCoinObj?.icon}`} className="mr5" width={16} height={16}></CustomIcon>
                                {convertWalletBalance(Number(sellPrice))}
                            </div>
                        </div>
                        <div className="flex_center_center">
                            <CustomIcon imgName={range < 0 ? 'Picture/UI_Picture_PriceDown_01' : 'Picture/UI_Picture_PriceUP_01'} className="mr5" width={18} height={13}></CustomIcon>
                            <span className={range < 0 ? 'color-red-moment fs12' : 'color-green-moment fs12'}>{range}%</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const [showSearchInput,setShowSearchInput] = useState(false)
    return (
        <div className={`${s.modalWrap} mainWrap`}>
            <MainHeader leftTitle={t('Explore')} headerType={3} isGameHeader={true} hasBackBtn={false} openType={2}></MainHeader>
            <div className={s.modal} ref={containerRef} >
                <div className={`wrap stickyElment`} ref={stickyRef}>
                    {showSearchInput &&
                        <div className="flex_center_center w100p mt5 mb10 ml5">
                            <SearchUser useSearchValue={true} searchKeyValue={searchKeyValue} searchUserFn={searchUserHandle} type={2}/>
                            <div className="fs16 ml10 mr10 momentFont color-moment-light-gray" onClick={()=>{
                                setShowSearchInput(false)
                            }}>{t('Cancel')}</div>
                        </div>

                    }
                    {!showSearchInput &&
                        <>
                            <div className="flex_center_between w100p" style={{ margin: '5px 0px' }}>
                                <div className="flex_center_start ml10">
                                    <Switch
                                        checked={hideNSFW}
                                        size="small"
                                        className="momentSwitch"
                                        checkedChildren={<CustomIcon imgName='Picture/UI_Picture_MomentClub_Right_01' width={15}
                                            height={15} />}
                                        onChange={(checked) => {
                                            setHideNSFW(checked);
                                            localStorage.setItem('_TT_showNSFW', !checked);
                                            showNSFWInfo(checked);
                                        }} />
                                    <div className={`${s.nsfwBtn} ml5 momentFont`}>{t('Hide NSFW')}</div>
                                </div>
                                <div className={`${s.r} flex_center_center mr5`}>
                                    <CustomIcon onClick={() => {
                                        setShowSearchInput(true)
                                    }} imgName={`Button/UI_Button_Search_03`} className="mr5" width={26} height={26}></CustomIcon>
                                    <CustomIcon onClick={() => {
                                        setShowSort(true)
                                    }} imgName={`Button/UI_Button_FilterIcon_02`} className="mr5" width={26} height={26}></CustomIcon>
                                    <CustomIcon onClick={() => {
                                        setShowFilter(true)
                                    }} imgName={`Button/UI_Button_Filters_01`} className="" width={32} height={32}></CustomIcon>
                                </div>
                            </div>
                        </>

                    }
                </div>
                {/*<div className={`${s.testLine}`}>
                    {'This seems to be the easiest way. I can upload the metadata of each token to IPFS (using Pinata or something else) and get the URLs which I can then hardcode as a mapping in the contract itself.'}
                </div>*/}
                {/*<div className={`${s.testList}`}>
                    {
                        showList.map((item) => {
                            return (
                                <div className={`${s.testItem} flex_center_center`} style={{height: `${item.height}px`, marginBottom: `${marginBottom}px`}}>
                                    <div className="fs16">
                                        {item.number}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>*/}

                <div className={s.content} >
                    <div className={s.wrap}>
                        {
                            showSearchInput &&
                            <SearchMomenttClubList
                                favoriteFn={favoriteFn}
                                searchKeyValue={searchKeyValue}
                                searchCursor={searchCursor}
                                linkTo={linkTo}
                                isMaket={false}
                                searchResult={searchResult}
                                getMoreSearchResult={getMoreSearchResult} />
                        }
                        {
                            !showSearchInput &&
                            <>
                                {
                                    (leftColumn?.length || 0) + (rightColumn?.length || 0) > 0 ?
                                    <div className={s.list} id="list">
                                        <InfiniteScroll
                                            dataLength={showList?.length || 0}
                                            next={getMoreList}
                                            hasMore={nextCursor !== ""}
                                            // hasMore={false}
                                            loader={<div
                                                style={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                }}
                                            ><Spin /></div>}
                                            style={{
                                                overflow: `hidden`
                                            }}
                                            scrollableTarget={'list'}
                                        >
                                            <div className="flex_start_between w100p">
                                                <div className={`${s.listColumn} flex_center_start_col`} id="listColum">
                                                    {
                                                        leftColumn?.length > 0 &&
                                                        leftColumn?.map((item, index) => {
                                                            return (getColumnItem(item, index));
                                                        })
                                                    }
                                                </div>
                                                <div className={`${s.listColumn} flex_center_start_col`}>
                                                    {
                                                        rightColumn?.length > 0 &&
                                                        rightColumn?.map((item, index) => {
                                                            return (getColumnItem(item, index));
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </InfiniteScroll>
                                    </div>:
                                    <div className={`${s.emptyList} flex_center_start_col`}>
                                        <CustomIcon width={111} height={95} className={`${s.emptyListIcon}`} imgName={'Picture/UI_Picture_NoData_Empty_02'}></CustomIcon>
                                        <div className="mt20 fs18 momentFont fw-400" style={{color: `#555555`}}>{t('No results found')}</div>
                                        <div className={`${s.resetBtn}`} onClick={() => {
                                            setShowFilter(true);
                                        }}>
                                            {t('Reset filter')}
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>

            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowSort(false)}
                width={245}
                open={showSort}
                destroyOnClose={true}
                className="momentSort"
            >
                <MomentClubSort
                    sortData={sortData}
                    nativeTokenList={nativeTokenList}
                    sortType={sortType}
                    setSortType={setSortType}
                    coinId={coinId}
                    setCoinId={setCoinId}
                    isAsc={isAsc}
                    setIsAsc={setIsAsc}
                    coinList={coinList}
                    applyFn={(item) => {
                        applyClubSort(item);
                        setShowSort(false);
                    }}
                />
            </AntDrawer>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowFilter(false)}
                width={245}
                open={showFilter}
                destroyOnClose={true}
                className="momentSort"
            >
                <MomentClubFilter
                    maxCoinPrice={maxCoinPrice}
                    maxMarketCap={maxMarketCap}
                    maxFavorite={maxFavorite}
                    filterPackPrice={filterPackPrice}
                    filterMarketCap={filterMarketCap}
                    filterFavoriteRange={filterFavoriteRange}
                    filterTgePro={filterTgePro}
                    filterOnlyFavorite={filterOnlyFavorite}
                    filterOnlyHeld={filterOnlyHeld}
                    filterOnlyLive={filterOnlyLive}
                    filterCoinList={filterCoinList}
                    coinId={coinId}
                    applyFn={(item) => {
                        applyClubFilter(item);
                        setShowFilter(false);
                    }}
                    coinList={coinList}
                    isSortByPrice={sortType === 1}
                    showTgeProcess={true}
                    isMarketCap={false}
                />
            </AntDrawer>
            {
                showMemePackTrans &&
                <MemePackTrans
                    clubId={transPackId}
                    closeFn={() => { setShowMemePackTrans(false); }}
                ></MemePackTrans>
            }
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        momentClubFilterList: app.momentClubFilterList,
        hideNSFW: app.hideNSFW,
        memeclubSwitchVideoParams: app.memeclubSwitchVideoParams,
        switchVideoListCursor: app.switchVideoListCursor,
        switchVideoList: app.switchVideoList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHideRefreshBtn: (params) => {
            return dispatch({
                type: "app/setHideRefreshBtn",
                payload: params,
            });
        },
        setMomentClubFilterList: (params) => {
            return dispatch({
                type: "app/setMomentClubFilterList",
                payload: params,
            });
        },
        setHideNSFW: (params) => {
            return dispatch({
                type: "app/setHideNSFW",
                payload: params,
            });
        },
        setSwitchVideoType: (params) => {
            return dispatch({
                type: "app/setSwitchVideoType",
                payload: params,
            });
        },
        setMemeclubSwitchVideoParams: (params) => {
            return dispatch({
                type: "app/setMemeclubSwitchVideoParams",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MomentExplore));
