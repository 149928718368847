import React, {memo, useCallback, useEffect, useRef, useState, useMemo} from "react";
import s from "./index.module.less";
import {connect} from "react-redux";
import {
    post_dispatch_detail,
    post_dispatch_emplyees,
    vCoinString,
    post_work_unlock,
    bornCheck_unlock,
    displayFilter,
    formatTimestampToTime,
    getBattlePointRankImg
} from "@/utils/common";
import {message, Modal} from "antd";

import {Swiper, SwiperSlide} from "swiper/react";

import WorkConfig from '@/utils/json/WorkConfig.json'
import "swiper/css";

import CustomIcon from "../../../../../common/CustomIcon";

import {useTranslation, Trans} from 'react-i18next';
import ModelBornLFG from "../../../../../model/ModelBornLFG";
// import CityInfoJson from "@/utils/json/CityInfoJson.json";

import {getCitydetail, CityInfoJson, iconPath, buttonPath, bgPath, citywarUnlockCity} from "@/utils/warfare"
import AntModal from "@/common/AntModal";

let curentSelectedWork

const HireModelWorkAssign = (
    {
        closeHandle,
        refreshHomeList,
        userInfo,
        defalutCityId = 2,
    }
) => {
    // console.log(`HireModelWorkAssign`, unlockWorkIds);

    const {t, i18n} = useTranslation();


    const {selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {cityWarUnlockIds} = useMemo(() => {
        return selfData || []
    }, [selfData]);


    const selfUserId = userInfo?.userId;
    const [selectedWork, setSelectedWork] = useState(defalutCityId);
    const [selectedWorkInfo, setSelectedWorkInfo] = useState(CityInfoJson.find(v => v.id === Number(defalutCityId)));
    const [swiper, setSwiper] = useState(null);


    // const [loadingWorkInfo, setLoadingWorkInfo] = useState(true);
    const [showNeedCliam, setShowNeedCliam] = useState(false)
    const [workDetail, setWorkDetail] = useState({})
    const [unlockFee, setUnlockFee] = useState(0)
    const [checking, setChecking] = useState(false)


    useEffect(() => {
        if (!selectedWork) {
            return;
        }

        curentSelectedWork = selectedWork;

        if (workDetail && workDetail.workId != selectedWork) {
            setWorkDetail(null);    //Clear Former Work Details
        }
        getCitydetail(selectedWork).then(res => {
            setWorkDetail(res)
        }).catch(e => {
            console.error(e);
        })


    }, [selectedWork]);


    useEffect(() => {
        console.log('swiper', swiper);
        if (swiper) {
            let currentWorkList = CityInfoJson;
            let index = currentWorkList?.findIndex(v => v.id == defalutCityId);
            swiper?.slideTo(index);
        }
    }, [swiper]);


    const [showModelWorkUnlock, setShowModelWorkUnlock] = useState(false);
    const [showSelPayType, setShowSelPayType] = useState(false);

    const rankInfo = useRef(null);


    const getTimes = (seconds) => {
        const h = parseInt(seconds / 60 / 60);
        const m = parseInt(seconds / 60 % 60);
        return `${h > 0 ? h + 'h' : ''}${m > 0 ? m + 'm' : ''}`
    }
    // console.log(selectedWorkInfo);
    const UnlockClick = () => {


        setUnlockFee(selectedWorkInfo?.UnlockFee)
        setShowSelPayType(true)
    }

    const unlockFn = () => {
        post_work_unlock(selectedWorkInfo?.id).then(data => {
            setShowNeedCliam(false);
            setShowSelPayType(false);
        }).catch(err => {
            console.log(err);
        });
    }

    const totalCoin = userInfo?.selfData?.claimCoin + userInfo?.selfData?.lfgBalance;
    const [checkExpire, setCheckExpire] = useState(0)
    const checkFn = (orderId) => {
        if (!checkExpire) {
            setCheckExpire(new Date().getTime() + 120000)
        }
        setChecking(true)
        if (checkExpire < new Date().getTime()) {
            bornCheck_unlock(orderId).then(res => {
                if (!res?.haveResult) {
                    setTimeout(() => {
                        checkFn(orderId)
                    })
                } else {
                    setChecking(false)
                    setShowSelPayType(false);
                }
            })
        } else {
            setChecking(false)
            // setShowSelPayType(false);
            message.info({content: 'Checking Expire'})
        }

    }


    const unlockCity = () => {
        citywarUnlockCity(selectedWork, 1).then(res => {
            console.log(res);
        }).catch(e => {
            console.error(e);
        })
    }

    const isUnlock = useMemo(() => {
        return cityWarUnlockIds.find(i => i === Number(selectedWork)) || false
    }, [cityWarUnlockIds, selectedWork])


    return (
        <div className={s.HireModelWorkAssign}>
            <div className={s.close}>
                <CustomIcon width={28}
                            height={41}
                            baseUrl={'/'}
                            imgName={buttonPath + 'UI_Button_Exit_01'}
                            name={buttonPath + 'UI_Button_Exit_01'}
                            onClick={closeHandle}/>
            </div>
            <div className={s.content}>
                <div className={s.swiperControl}>
                    <div className={s.prev} id="slidePrev" onClick={() => swiper?.slidePrev()}>
                        {selectedWorkInfo?.id !== CityInfoJson[0]?.id && <CustomIcon
                            width={48}
                            height={81}
                            baseUrl={'/'}
                            imgName={buttonPath + "UI_Button_Arrow_Left_01"}
                            name={buttonPath + "UI_Button_Arrow_Left_01"}
                        ></CustomIcon>}
                    </div>
                    <div className={s.next} id="slideNext" onClick={() => swiper?.slideNext()}>
                        {selectedWorkInfo?.id !== CityInfoJson[CityInfoJson?.length - 1]?.id && <CustomIcon
                            width={48}
                            height={81}
                            baseUrl={'/'}
                            imgName={buttonPath + "UI_Button_Arrow_Right_01"}
                            name={buttonPath + "UI_Button_Arrow_Right_01"}
                        ></CustomIcon>}
                    </div>
                </div>

                <div className={s.list}>
                    <div className={s.fixedBottom}>
                        {/* const { id, initTotalLFGPool, name, icon, imageBG } = item */}
                        <CustomIcon
                            width={180}
                            height={180}
                            baseUrl={'/'}
                            imgType={'.webp'}
                            className={`${s.coin} ${s.locked}`}
                            imgName={iconPath + selectedWorkInfo?.icon}
                            name={iconPath + selectedWorkInfo?.icon}>


                        </CustomIcon>
                        <div className={s.lock}>
                            <CustomIcon width={60} height={80} baseUrl={'/'} imgName={iconPath + 'UI_Picture_Lock_01'}
                                        name={iconPath + 'UI_Picture_Lock_01'}/>
                        </div>
                        <div className={s.joinState}
                             style={{backgroundImage: `url(/UI/Button/UI_Button_White-L_01.png)`}}>
                            Joined Club:9.999K
                            <CustomIcon width={16} className="ml10" height={16} baseUrl={'/'}
                                        imgName={buttonPath + 'UI_Button_YellowQuestionMark_01'}
                                        name={buttonPath + 'UI_Button_YellowQuestionMark_01'}/>
                        </div>

                        <div className={s.detailInfo}>
                            <div className={s.dI}>
                                <div className={s.label}>Unlock Fee:</div>
                                <div className="df alc jcc">30000 <CustomIcon height={30} width={30} baseUrl={'/'}
                                                                              imgName={iconPath + 'UI_Picture_LFG_01'}
                                                                              name={iconPath + 'UI_Picture_LFG_01'}/>
                                </div>
                            </div>
                            <div className={s.dI}>
                                <div className={s.label}>My Balance:</div>
                                <div className="df alc jcc">30000 <CustomIcon height={30} width={30} baseUrl={'/'}
                                                                              imgName={iconPath + 'UI_Picture_LFG_01'}
                                                                              name={iconPath + 'UI_Picture_LFG_01'}/>
                                </div>
                            </div>
                        </div>
                        <div className={s.btn} onClick={unlockCity}
                             style={{backgroundImage: `url(/UI/Button/UI_Button_Yellow-M_02.png)`}}>
                            UNLOCK ECOSYSTEM
                        </div>
                    </div>
                    <Swiper
                        slidesPerView={1}
                        centeredSlides={true}
                        onSlideChange={(i) => {
                            console.log('[changeWork ID] activeIndex ', i.activeIndex + 1, ' selectedWork ', selectedWork);
                            const work = CityInfoJson[i.activeIndex];
                            // isCanUnlock()
                            console.log(work?.id);
                            setSelectedWork(work?.id);
                            setSelectedWorkInfo(work);
                        }}
                        className="hireSwiper"
                        slideToClickedSlide={true}
                        onSwiper={(sw) => {
                            setSwiper(sw)
                        }}
                        // navigation
                        navigation={{enabled: true}}
                        //initialSlide={getMaxIdIdx() || 0}
                    >
                        {CityInfoJson.map(item => {
                            const {id, initTotalLFGPool, name, icon, imageBG} = item
                            return (
                                <SwiperSlide key={item.id}>
                                    <div className={`card needMask ${s.card}`}
                                         style={{backgroundImage: `url(${bgPath + imageBG}.webp)`}}>
                                        {/* {!unlockWorkIds.includes(selectedWorkInfo?.id) && } */}
                                        <div className={s.unlockMask}>
                                            <CustomIcon
                                                width={70}
                                                height={70}
                                                imgName={"UI_TurnUP_Lock_001"}
                                                name={"UI_TurnUP_Lock_001"}
                                            />
                                        </div>

                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
            <AntModal
                width='90%'
                title={t('UNLOCK MISSION')}
                className="confirmModalWrap"
                centered={true}
                open={showSelPayType}
                destroyOnClose={true}
                onOk={() => setShowSelPayType(false)}
                onCancel={() => setShowSelPayType(false)}
                zIndex={4000}
            >
                <ModelBornLFG
                    cliamPoolFn={unlockFn}
                    bornNum={unlockFee}
                    workId={selectedWork}
                    bornType={1}
                    checkFn={checkFn}
                    closeFn={() => {
                        setShowSelPayType(false)
                    }}

                />
            </AntModal>
        </div>
    );
};
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default memo(
    connect(mapStateToProps, mapDispatchToProps)(HireModelWorkAssign)
);
