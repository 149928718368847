// src/getUniswapTrades.js
import { ethers } from 'ethers';

const provider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com/'); // 使用Polygon的RPC

const UNISWAP_V3_POOL_ABI = [
  'event Swap(address indexed sender, address indexed recipient, int256 amount0, int256 amount1, uint160 sqrtPriceX96, uint128 liquidity, int24 tick)',
];

const getUniswapTrades = async (poolAddress) => {
  const poolContract = new ethers.Contract(poolAddress, UNISWAP_V3_POOL_ABI, provider);

  const filter = poolContract.filters.Swap();
  const events = await poolContract.queryFilter(filter, -100); // 获取最近10000个区块的交易记录

  const trades = events.map((event) => ({
    sender: event.args.sender,
    recipient: event.args.recipient,
    amount0: event.args.amount0.toString(),
    amount1: event.args.amount1.toString(),
    sqrtPriceX96: event.args.sqrtPriceX96.toString(),
    liquidity: event.args.liquidity.toString(),
    tick: event.args.tick,
    transactionHash: event.transactionHash,
  }));

  return trades;
};

export default getUniswapTrades;
