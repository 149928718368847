
import React, { memo, useState, useMemo, useRef, useEffect } from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import { Button, Select, Checkbox } from "antd";
import { useTranslation, Trans } from 'react-i18next';
import AntButton from "@/common/AntButton";
import { AutoStrangeRule } from "@/utils/strangeRule";

const { Option } = Select;

const MomentClubNftSort = (
    {
        applyFn = () =>{},
        sortType = 1,
        isAsc = true,
    }
) => {
    const { t, i18n } = useTranslation();

    const [currentSortType, setCurrentSortType] = useState(sortType);
    const [currentIsAsc, setCurrentIsAsc] = useState(isAsc);

    const apply = () => {
        applyFn({
            sortType: currentSortType,
            isAsc: currentIsAsc,
        });
    }

    return (
        <div className={s.wrap}>
            <div className={`${s.title} mt10 momentFont`}>
                <CustomIcon width={25} className="mr5" height={25} imgName={'Button/UI_Button_FilterIcon_02'} /> {AutoStrangeRule(t('SORTING'))}
            </div>
            <div className={s.filterCon}>
                <div className={s.sortConlist}>
                    <div className={s.sortOption}>
                        <Checkbox 
                            onChange={() => {
                                setCurrentSortType(1);
                            }}
                            className="momentCheckBox"
                            checked={currentSortType === 1}>
                            <span className="">{t('Rarity')}</span>
                        </Checkbox>
                    </div>
                    <div className={s.sortOption}>
                        <Checkbox 
                            onChange={() => {
                                setCurrentSortType(2);
                            }}
                            className="momentCheckBox"
                            checked={currentSortType === 2}>
                            <span className="">{t('Token ID')}</span>
                        </Checkbox>
                    </div>
                </div>
            </div>

            <div className={`${s.bottom}`}>
                <AntButton 
                    className={`${currentIsAsc === true ? 'btn_white' : 'btn_transparent_moment'} mb10`}
                    onClick={() => {
                        setCurrentIsAsc(true);
                    }}>
                    <CustomIcon width={15} height={15}
                        imgName={currentIsAsc ? 'Button/UI_Button_FilterIcon_Up_02' : 'Button/UI_Button_FilterIcon_Up_01'}></CustomIcon>


                    {t('Ascending')}
                    &nbsp;
                </AntButton>
                <AntButton 
                    className={`${currentIsAsc === false ? 'btn_white' : 'btn_transparent_moment'} mb10`}
                    onClick={() => {
                        setCurrentIsAsc(false);
                    }}>
                    <CustomIcon width={15} height={15}
                        imgName={!currentIsAsc ? 'Button/UI_Button_FilterIcon_Down_02' : 'Button/UI_Button_FilterIcon_Down_01'}></CustomIcon>

                    {t('Descending')}
                </AntButton>
                <AntButton className="btn_public mb10" onClick={apply}>{t('APPLY')}</AntButton>
            </div>

        </div>
    )
}
export default memo(MomentClubNftSort);
