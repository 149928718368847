import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Slider, Checkbox} from "antd";
import {unit} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";


const BattleHireFilter = (
    {
        closeHandle,
        currentLevel,
        filterLevel,
        setFilterLevel,
        rankType,
        setRanktype,
    }
) => {
    const {t, i18n} = useTranslation();
    const [currentRankType, setCurrentRankType] = useState(rankType || 0);
    const [currentFilterLevel, setCurrentFilterLevel] = useState([filterLevel?.[0] || Math.max(1, currentLevel - 2), filterLevel?.[1] || Math.max(1, currentLevel + 2)])
    const [showSlider, setShowSlider] = useState(false); 
    
    const onChangeLevel = (value) => {
        setCurrentFilterLevel(value);
    }

    const reset = () => {
        let level1 = Math.max(1, currentLevel - 2);
        let level2 = Math.max(1, currentLevel + 2);
        setCurrentFilterLevel([level1, level2]);
        setCurrentRankType(rankType || 0);
    }

    const apply = async () => {
        setFilterLevel(currentFilterLevel);
        setRanktype(currentRankType);
        closeHandle();
    }

    const changeSortByLfgPool = (e) => {
        if (e.target.checked) {
            setCurrentRankType(0);
            setShowSlider(false); 
        }
    }

    const changeSortByBattleLevel = (e) => {
        if (e.target.checked) {
            setCurrentRankType(2);
            setShowSlider(false); 
        }
    }

    const changeSortByClubLevel = (e) => {
        if (e.target.checked) {
            setCurrentRankType(1);
            setShowSlider(true); 
        }
    }

    const changeSortNft = (e) => {
        if (e.target.checked) {
            setCurrentRankType(3);
            setShowSlider(false); 
        }
    }

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <CustomIcon width={25} className="mr5" height={25} imgName={'Button/UI_Button_ThreeTieRod_01'}
                            name={'Button/UI_Button_ThreeTieRod_01'}/> {AutoStrangeRule(t('FILTERS'))}
            </div>
            <div className={s.filterCon}>
                <div className="mb10">
                    <Checkbox onChange={changeSortByLfgPool} className="hireCheckBox"
                              checked={currentRankType === 0} defaultChecked={currentRankType === 0}>
                        <span className="ml10">{t('Sort By $LFG Pool')}</span>
                    </Checkbox>
                </div>
                <div className="mb10">
                    <Checkbox onChange={changeSortByBattleLevel} className="hireCheckBox"
                              checked={currentRankType === 2} defaultChecked={currentRankType === 2}>
                        <span className="ml10">{t('Sort By Battle Level')}</span>
                    </Checkbox>
                </div>
                <div className="mb10">
                    <Checkbox onChange={changeSortByClubLevel} className="hireCheckBox"
                              checked={currentRankType === 1} defaultChecked={currentRankType === 1}>
                        <span className="ml10">{t('Filter By Club Level')}</span>
                    </Checkbox>
                </div>
                {showSlider && (
                    <div className={s.item}>
                        <div className="df">
                            <div className={s.label}>{t('Level From')}: &nbsp;</div>
                            <div className={s.rankArea}>
                                <span className="color-white fb">{currentFilterLevel[0]}</span>
                                <span className="fs12"> {t('to')} </span>
                                <span className="color-white fb">{currentFilterLevel[1]}</span>
                            </div>
                        </div>
                        <div className={s.slider}>
                            <Slider
                                defaultValue={currentFilterLevel}
                                className="hireSlider commonSlider"
                                range={true}
                                min={1}
                                value={currentFilterLevel}
                                max={15}
                                onChange={onChangeLevel}/>
                        </div>
                    </div>
                 )}
                <div className="mb10">
                    <Checkbox onChange={changeSortNft} className="hireCheckBox"
                              checked={currentRankType === 3} defaultChecked={currentRankType === 3}>
                        <span className="ml10">{t('Filter Only NFTs')}</span>
                    </Checkbox>
                </div>
            </div>
            <div className={s.bottom}>
                <AntButton className={s.black} onClick={reset}>{t('RESET')}</AntButton>
                <AntButton className="btn_public" onClick={apply}>{t('APPLY')}</AntButton>
            </div>

        </div>
    )
}
export default memo(BattleHireFilter);
