import React, { memo, useCallback, useEffect, useMemo, useRef,useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import { Button, Checkbox, Spin, message, Modal } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
    stringToNumber,
    convertWalletBalance,
    getChainTokenInfoById,
    bornCheck_loot,
    getRarityByValue,
    PrivyLoginStatusError,
} from "@/utils/common";
import {AutoStrangeRule} from "@/utils/strangeRule";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";
import InfiniteScroll from "react-infinite-scroll-component";
import AntButton from "@/common/AntButton";
import MemeClubPack from "@/common/MemeClubPack";
import MomentClubUnpackCard from "@/common/MomentClubUnpackCard";
import {backFn} from "@/utils/mianPublic";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import TestMemeUnpackInfo from "src/utils/json/TestMemeUnpackInfo.json";
import MomentClubPack from "@/common/MomentClubPack";
import CustomEase from "gsap/CustomEase";
import { 
    mintMomentToken 
} from "@/utils/momentClubWeb3";
import { 
    momentClubInfo, 
    getChainInfo,
    momentMintCoin,
    moment_owncardlist,
    momentPreOpenpack,
    momentMintResult,
} from "@/utils/momentClub";
import ModalMemeNftDetail from "@/model/ModalMemeNftDetail";
import MemeUnpackFx from "@/common/MemeUnpackFx";
import MemeChainToken from "@/utils/json/memeChainToken.json";
import { isOnlineEnv } from "@/utils/env";

const RevealMomentPack = (
    {
        userInfo,
        memeUnpackInfo,
        setMemeUnpackInfo,
        memeUnpackCount,
        setMemeUnpackCount,
    }
) => {
    const { t, i18n } = useTranslation();
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const {wallets} = useWallets();
    const location = useLocation();
    const {clubUserId} = useParams();
    const [mintingTimeout, setMintingTimeout] = useState(false);
    const [state, setState] = useState(0);//0:loading,2:summarization
    const [mintingProgress, setMintingProgress] = useState(0);
    const [mintingProgressCount, setMintingProgressCount] = useState(0);
    const [playPackShakeAni, setPlayPackShakeAni] = useState(false);
    const [clubBasicInfo, setClubBasicInfo] = useState(null);
    const [mintOrderId, setMintOrderId] = useState(null);
    const [checkTimes, setCheckTimes] = useState(0);
    const [mintResult, setMintResult] = useState(null);
    const [preOpenPackData, setPreOpenPackData] = useState(null);
    const [needWeb3Check, setNeedWeb3Check] = useState(false);
    const [mintCheckTxHash, setMintCheckTxHash] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [hasOpenPackError, setHasOpenPackError] = useState(false);
    const [showMemeNftDetail, setShowMemeNftDetail] = useState(false);
    const [selectedNftIndex, setSelectedNftIndex] = useState(0);
    const [finishPlayUnpackTimeline, setFinishPlayUnpackTimeline] = useState(false);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [nextCardIndex, setNextCardIndex] = useState(1);
    const [playOpenPackLock, setPlayOpenPackLock] = useState(false);
    const [switchCardLock, setSwitchCardLock] = useState(false);
    const [showOpenPackBtn, setShowOpenPackBtn] = useState(false);
    const loadingProcessingIncrease = 1;
    const finishProcessingIncrease = 20;
    const highestProcessBeforeResult = 90;
    const checkInterval = 250;//ms
    const timeoutTime = 120000;//ms

    const unpackFxRef = useRef(null);

    const totalWidth = useMemo(() => {
        return Math.min(window.innerWidth, 500);
    }, [window.innerWidth]);

    const {
        clubInfo,
        holdNum,
    } = useMemo(() => {
        return clubBasicInfo || {};
    }, [clubBasicInfo]);

    const {
        coinToNFTRatio,
        imageUrl,
        symbolName,
        clubName,
        attrs,
        symbolImageUrl,
        coinId,
        coverUrl,
    } = useMemo(() => {
        if (clubInfo && unpackFxRef.current){
            unpackFxRef.current.CreateUnpackScene(clubInfo.coverUrl, /*clubInfo.symbolName*/'');
        }

        return clubInfo || {};
    }, [clubInfo]);

    const chainTokenInfo = useMemo(() =>{
        return getChainTokenInfoById(coinId);
    }, [coinId]);

    const packResultList = useMemo(() => {
        let res = [];
        if (preOpenPackData?.cardList?.length > 0){
            for (let i = 0; i < preOpenPackData?.cardList?.length; i++){
                let cardInfo = preOpenPackData?.cardList[i];
                if (cardInfo?.cardAmount > 0){
                    for (let j = 0; j < cardInfo?.cardAmount; j++){
                        res.push({
                            cardNo: cardInfo?.cardNo,
                            cardValue: cardInfo?.openCoin / cardInfo?.cardAmount,
                            cardUrl: cardInfo?.cardUrl
                        })
                    }
                }
            }
        }
        return res;
    }, [preOpenPackData]);

    //随机品质
    const randomQuality = () =>{
        let random = Math.random();
        if (random > 0.9){
            return 5;
        }
        else if (random > 0.7){
            return 4;
        }
        else if (random > 0.5){
            return 3;
        }
        else if (random > 0.3){
            return 2;
        }
        else{
            return 1;
        }
    }

    const showNftInfo = useMemo(() =>{
        let res = [];
        let nftMetaDataList = mintResult?.nftList;
        if (nftMetaDataList?.length > 0){
            for (let i = 0; i < nftMetaDataList?.length; i++){
                let tempInfo = {};
                if (nftMetaDataList[i]){
                    tempInfo = {...nftMetaDataList[i]};
                }
                tempInfo.rarityValue = getRarityByValue(tempInfo?.rarity);
                if (i === 0){
                    res.push(tempInfo);
                }
                else{
                    let insert = false;
                    for (let j = 0; j < res?.length; j++){
                        if (tempInfo.rarityValue >= res[j].rarityValue){
                            res.splice(j, 0, tempInfo);
                            insert = true;
                            break;
                        }
                    }
                    if (!insert){
                        res.push(tempInfo);
                    }
                }
            }
        }
        return res;
    }, [mintResult, clubInfo]);

    const {
        walletAddr,
        topUpCode,
        userId,
        referralTotalEarn,
        platformData,
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const mintCheck = () => {
        momentMintResult(chainTokenInfo?.chainId, mintCheckTxHash?.hash).then(res => {
            if (res?.nftList?.length > 0){
                setMintResult(res);
            }
            else{
                setCheckTimes(checkTimes + 1);
            }
        }).catch(e => {
            console.error(e);
            setCheckTimes(checkTimes + 1);
        });
        /*bornCheck_loot(orderId).then(res => {
            if (!res?.haveResult) {
                setCheckTimes(checkTimes + 1);
            } else if (res.resultCode > 0) {
                console.log("server transaction error");
                setHasOpenPackError(true);
                setErrorMsg(t('Caused by server data error'));
            } else {
                if (res?.result) {
                    console.log("mint meme success", res);
                    setMintResult(res?.result);
                } else {
                    console.log("order result is null");
                    setHasOpenPackError(true);
                    setErrorMsg(t('Caused by server data error'));
                }
            }
        }).catch(e => {
            console.error(e);
            setCheckTimes(checkTimes + 1);
        })*/
    }

    useEffect(() => {
        if (needWeb3Check){
            setTimeout(() => {
                mintCheck();
            }, 2000);
        }
    }, [checkTimes]);

    const loadOpenPackData = () => {
        //获取club信息
        momentClubInfo(clubUserId).then((infoRes) => {
            setClubBasicInfo(infoRes);
            if (infoRes) {
                if (memeUnpackInfo){
                    //transfer open pack
                    setPlayPackShakeAni(true);
                    setMintingProgressCount(mintingProgressCount + 1);
                    setMintResult(memeUnpackInfo);
                }
                else{
                    //mint pack
                    setPlayPackShakeAni(true);
                    setMintingProgressCount(mintingProgressCount + 1);
                    //获取已有卡包
                    moment_owncardlist(clubUserId).then((ownRes) =>{
                        if (ownRes?.ownCardList?.length > 0){
                            let ownCardList = ownRes?.ownCardList;
                            let cardArr = [];
                            let amountArr = [];
                            for (let i = 0; i < ownCardList?.length; i++){
                                cardArr.push(ownCardList[i].cardNo);
                                amountArr.push(ownCardList[i].cardAmount);
                            }
                            //预开包
                            momentPreOpenpack(Number(clubUserId), ownCardList).then((preRes) => {
                                let tempPackData = preRes;
                                if (tempPackData?.cardList?.length > 0){
                                    for (let i = 0; i < tempPackData?.cardList?.length; i++){
                                        if (ownCardList?.length > 0){
                                            for (let j = 0; j < ownCardList?.length; j++){
                                                if (tempPackData.cardList[i].cardNo == ownCardList[j].cardNo){
                                                    tempPackData.cardList[i].cardUrl = ownCardList[j].cardUrl;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                                setPreOpenPackData(tempPackData);
                                //开包
                                mintMomentToken({
                                    wallets, 
                                    clubId: preRes?.chainClubId, 
                                    cardArr: cardArr,
                                    amountArr: amountArr,
                                    coinId: infoRes?.clubInfo?.coinId,
                                }).then(res => {
                                    console.log("mintMomentToken", res);
                                    setMintCheckTxHash(res);
                                    setNeedWeb3Check(true);
                                    setCheckTimes(checkTimes + 1);
                                }).catch(e => {
                                    console.error(e);
                                    setHasOpenPackError(true);
                                    if(e==='need login'){
                                        setErrorMsg(PrivyLoginStatusError);
                                    }else{
                                        setErrorMsg(t('Caused by transaction failure'));
                                    }
                                })
                            }).catch(e => {
                                console.error("[momentPreOpenpack]", e);
                                setHasOpenPackError(true);
                                setErrorMsg(t('Caused by server data error'));
                            })
                        }
                    }).catch(e => {
                        console.error("[moment_owncardlist]", e);
                        setHasOpenPackError(true);
                        setErrorMsg(t('Caused by server data error'));
                    })
                }
            }
            else {
                console.log("memeclub info empty");
                setHasOpenPackError(true);
                setErrorMsg(t('Caused by server data error'));
            }
        }).catch(e => {
            console.error("[momentClubInfo]", e);
            setHasOpenPackError(true);
            setErrorMsg(t('Caused by server data error'));
        })
    }

    const getTotalCoin = () =>{
        return preOpenPackData?.totalOpenCoin || 0;
    }

    useEffect(() => {
        //加载开包数据
        loadOpenPackData();
    }, []);

    useEffect(() => {
        //开包进度条显示
        if (mintingProgressCount < 1 && hasOpenPackError){
            return;
        }
        if (!mintingTimeout && mintingProgressCount * checkInterval > timeoutTime){
            setMintingTimeout(true);
        }
        let progressIncrease = mintResult ? finishProcessingIncrease:loadingProcessingIncrease;
        let currentProgress = mintingProgress + progressIncrease;
        if (!mintResult){
            currentProgress = Math.min(currentProgress, highestProcessBeforeResult);
        }
        else{
            currentProgress = Math.min(currentProgress, 100);
        }
        setMintingProgress(currentProgress);
        if (currentProgress >= 100){
            setTimeout(() => {
                prepareOpenPack();
            }, checkInterval);
        }
        else{
            setTimeout(() => {
                setMintingProgressCount(mintingProgressCount + 1);
            }, checkInterval);
        }
    }, [mintingProgressCount]);

    const showRarityList = [5,4,3,2,1,0];

    const {contextSafe} = useGSAP(
        () => {
        },
        { scope: containerRef }
    );

    const playOpenPackTimeline = contextSafe(() => {
        if (playOpenPackLock){
            return;
        }
        if (mintingProgress < 100){
            return;
        }

        setPlayOpenPackLock(true);
        /*if (unpackFxRef.current){
            //撕开粒子特效
            unpackFxRef.current.switchAnimationGroup(2);
            unpackFxRef.current.switchParticleEmit(1);
        }*/

        let tl = gsap.timeline();
        //隐藏无关UI
        tl.to('.packMini', {
            duration: 0.2,
            y: '-20%',
            autoAlpha: 0,
        });
        tl.to('.memeClubName', {
            duration: 0.2,
            y: '-20%',
            autoAlpha: 0,
        }, "<");
        tl.to('.fullScreenOpenPackBtn', {
            duation: 0.5,
            autoAlpha: 0,
        }, ">-=0.3");

        tl.to('.completeProgressRoot', {
            duation: 0.5,
            autoAlpha: 0,
        }, "<");

        tl.to('.completeText', {
            duation: 0.5,
            autoAlpha: 0,
        }, "<");

        tl.to('.openPackTip', {
            duation: 0.5,
            autoAlpha: 0,
            onComplete: () =>{
                if (unpackFxRef.current){
                    //撕开粒子特效
                    unpackFxRef.current.switchAnimationGroup(2);
                    unpackFxRef.current.switchParticleEmit(1);
                }
            }
        }, "<");

        //暂时关闭开包相关动画
        if (showNftInfo?.length > 0){
            /*tl.to('.resultCardRoot', {
                duration: 0.5,
                scale: 0.5,
                onComplete: () =>{
                    const nextCardInfo = showNftInfo[0];
                    if (unpackFxRef.current){
                        //撕开粒子特效
                        unpackFxRef.current.switchParticleEmit(Math.min(6 - nextCardInfo.rarityValue, 3)); //IYKYK
                    }
                }
            }, ">+=0.45");
            tl.to('.resultCardRoot', {
                duration: 0.1,
                autoAlpha: 1
            }, ">");
            tl.to('.currentCardRoot', {
                duration: 0.1,
                autoAlpha: 1
            }, "<");*/

            tl.to('.resultCardListRoot', {
                duration: 0.1,
                autoAlpha: 1
            }, ">");

            //隐藏物体位置恢复
            tl.to('.packMini', {
                duration: 0.1,
                y: '0%',
            }, "<");
            tl.to('.memeClubName', {
                duration: 0.1,
                y: '0%',
            }, "<");

            /*tl.to('.resultCardRoot', {
                duration: 0.25,
                scale: 1
            }, "<");*/
        }
        else{
            //如果没有卡牌则直接显示金币
            if (unpackFxRef.current){
                //撕开粒子特效
                unpackFxRef.current.switchParticleEmit(1);
            }

            /*tl.to('.resultCardRoot', {
                duration: 0.1,
                autoAlpha: 1
            }, ">");*/
            
            tl.to('.resultCardListRoot', {
                duration: 0.1,
                autoAlpha: 1
            }, ">");

            //隐藏物体位置恢复
            tl.to('.packMini', {
                duration: 0.1,
                y: '0%',
            }, "<");
            tl.to('.memeClubName', {
                duration: 0.1,
                y: '0%',
            }, "<");

            /*tl.to('.resultCoinRoot', {
                duration: 0.1,
                autoAlpha: 1
            }, "<");*/
        }

        tl.to('.resultNextLine', {
            duration: 0.1,
            autoAlpha: 1,
            onStart: () =>{
                setFinishPlayUnpackTimeline(true);
            },
            onComplete: () =>{
                setPlayOpenPackLock(false);
            }
        }, ">+=0.2");
        /*tl.to('.skipPart', {
            duration: 0.1,
            autoAlpha: 1,
            onStart: () =>{
                setFinishPlayUnpackTimeline(true);
            },
            onComplete: () =>{
                setPlayOpenPackLock(false);
            }
        }, ">+=0.2");*/
    });

    const playSwitchCardTimeline = contextSafe(() => {

        if (currentCardIndex > showNftInfo.length - 1){
            if (!switchCardLock){
                skipFn();
            }
            return;
        }
        if (switchCardLock){
            return;
        }

        setSwitchCardLock(true);

        let tl = gsap.timeline();

        if (currentCardIndex < showNftInfo.length - 1){
            tl.to('.nextCardRoot', {
                duration: 0.1,
                autoAlpha: 1,
                onStart:()=>{
                    const nextCardInfo = showNftInfo[nextCardIndex];
                    if (unpackFxRef.current){
                        //撕开粒子特效
                        unpackFxRef.current.switchParticleEmit(Math.min(6 - nextCardInfo.rarityValue, 3)); //IYKYK
                    }
                    //console.log('[playSwitchCardTimeline]', nextCardInfo.rarityValue);
                }
            }, ">");
        }
        else{
            //最后一张为金币资源
            if (unpackFxRef.current){
                //撕开粒子特效
                unpackFxRef.current.switchParticleEmit(1);
            }
            tl.to('.resultCoinRoot', {
                duration: 0.1,
                autoAlpha: 1,
            }, ">");
        }

        tl.to('.currentCardPerspectiveRoot', {
            duration: 0.1,
            rotateY: '0.2deg',
        }, ">");
        tl.to('.currentCardRoot', {
            duration: 0.5,
            left: '150%',
            rotateZ: '20deg',
            onComplete: () =>{
                setCurrentCardIndex(nextCardIndex);
            }
        }, ">");
        tl.to('.currentCardRoot', {
            duration: 0.1,
            autoAlpha: 0,
        }, ">-=0.1");
        if (currentCardIndex < showNftInfo.length - 1){
            tl.set('.currentCardRoot', {
                left: '50%',
                rotateZ: '0deg',
                autoAlpha: 1,
            }, ">+=0.1");
            tl.set('.currentCardPerspectiveRoot', {
                rotateY: '0deg',
            }, "<");
            tl.to('.nextCardRoot', {
                duration: 0.1,
                autoAlpha: 0,
                onComplete: () =>{
                    setNextCardIndex(nextCardIndex + 1);
                    setSwitchCardLock(false);
                }
            }, ">");
        }
        else{
            tl.to('.currentCardRoot', {
                duration: 0.1,
                onComplete: () =>{
                    setSwitchCardLock(false);
                }
            }, ">");
        }
    });

    const switchTosummarizationPart = contextSafe(() =>{
        let tl = gsap.timeline();
        tl.to('.packMini', {
            duration: 0.1,
            autoAlpha: 1,
        });
        tl.to('.memeClubName', {
            duration: 0.1,
            autoAlpha: 1,
        }, "<");
    })

    //播放切换卡牌动画
    const switchNextCard = () =>{
        if (finishPlayUnpackTimeline){
            playSwitchCardTimeline();
        }
    }

    /*const getNextNftAmount = () => {
        let totalAmount = getTotalCoin();
        if (coinToNFTRatio > 0){
            return Math.max(0, coinToNFTRatio - totalAmount % coinToNFTRatio);
        }
        else{
            return coinToNFTRatio;
        }
    }*/

    const prepareOpenPack = () =>{
        setPlayPackShakeAni(false);
        setShowOpenPackBtn(true);

        if (unpackFxRef.current){
            unpackFxRef.current.switchAnimationGroup(1);
            unpackFxRef.current.switchParticleEmit(0);
        }
    }

    const closeFn = () =>{
        backFn(location, navigate);
        setMemeUnpackInfo(null);
        setMemeUnpackCount(0);
        console.log("back");
    }

    const getUnpackingProgress = (progress) =>{
        if (progress >= 100){
            return 100;
        }
        else if(progress > 0){
            return Math.max(progress, 13);
        }
        else{
            return 0;
        }
    }

    const skipFn = () =>{
        
        if (clubInfo && unpackFxRef.current){
            unpackFxRef.current.disposeScene();
        }

        if (!memeUnpackInfo){
            switchTosummarizationPart();
        }
        setState(2);
    }

    const coveertValue = (value) =>{
        let res = Number(value);
        if (res>=10){
            return Number(res.toFixed(0)).toLocaleString("en-US");
        }
        else{
            return Number(res.toFixed(2)).toLocaleString("en-US");
        }
    }

    return (
        <div ref={containerRef} className={`${s.wrap}`}>
            {/*<MainHeader headerType={2} hasRightMenu={false} hasBackBtn={mintingTimeout || hasOpenPackError}></MainHeader>*/}
            {
                (mintingTimeout || hasOpenPackError) &&
                <CustomIcon className={`${s.returnBtn}`} width={29} height={26}
                    imgName={'Button/UI_Button_Arrow_01'} onClick={() => {
                        closeFn();
                    }}/>
            }
            {
                /*state === 0 && 
                <div className={`${s.mainPackRoot}`}>
                    {<MemeUnpackFx ref={unpackFxRef}/>}
                </div>*/
            }
            <div className={s.modal}>
                <div className={`${s.modalRoot} ${state === 0?s.cannotScroll:s.canScroll} flex_center_start_col`}>
                    <div className="flex_center_start_col" style={{width: '100%'}}>

                        {
                            memeUnpackInfo ? 
                            <div style={{height: '80px'}}>
                            </div> :
                            <div className={`${s.packMini} packMini`}>
                                <CustomIcon className="ml5 mr5" width={42} height={42}
                                    imgName={'Picture/UI_Picture_icon_cardpack'} />
                                <div className={`${s.packNumRoot} flex_center_center`}>
                                    <div className={`${s.packNumText}`}>
                                        {`x${holdNum}`}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className={`${s.memeClubName} memeClubName`}>
                            {clubName}
                        </div>
                        {
                            state === 0 &&
                            <>
                                <div className={`${s.mintingPart} flex_center_start_col`}>
                                    {
                                        hasOpenPackError ? 
                                            <>
                                                <div className={`${s.errorMsg}`}>
                                                    {t('Unpack Failed')}
                                                </div>
                                                {
                                                    errorMsg && 
                                                    <div className={`${s.extraErrorMsg}`}>
                                                        {errorMsg}
                                                    </div>
                                                }
                                            </> :
                                        showOpenPackBtn ?
                                            <>
                                                <div className={`${s.mintingProgressRoot} completeProgressRoot flex_center_start`}>
                                                    <div className={`${s.mintingProgressContent}`}
                                                        style={{
                                                            width: '100%'
                                                        }}>
                                                    </div>
                                                    <div className={`${s.mintingProgressText}`}>
                                                        {`100%`}
                                                    </div>
                                                </div>
                                                <div className={`${s.mintingText} completeText`}>
                                                    {t('Complete')}
                                                </div>
                                                <div className={`${s.openPackTip} openPackTip flex_center_center`}>
                                                    {t('Click To Open')}
                                                </div>
                                            </> :
                                            <>
                                                <div className={`${s.mintingProgressRoot} mintingProgressRoot flex_center_start`}>
                                                    <div className={`${s.mintingProgressContent}`}
                                                        style={{
                                                            width: mintingProgress >= 100 ? '100%' : `${getUnpackingProgress(mintingProgress)}%`
                                                        }}>
                                                    </div>
                                                    <div className={`${s.mintingProgressText}`}>
                                                        {`${mintingProgress}%`}
                                                    </div>
                                                </div>
                                                {
                                                    mintingTimeout ? 
                                                        <div className={`${s.mintingText}`}>
                                                            {t('Blockchain is busy now you can check the results on-chain later')}
                                                        </div> :
                                                        <div className={`${s.mintingText}`}>
                                                            {t('Minting...')}
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                                <div className={`${s.resultTotalRoot} flex_center_center_col`} onClick={() =>{
                                    //switchNextCard();
                                }}>
                                    <div className={`${s.resultCardListRoot} resultCardListRoot flex_center_start_col`}>
                                        {
                                            showNftInfo?.length > 0 &&
                                            <div className={`${s.resultCardList} ${showNftInfo?.length<3?'flex_center_center':'flex_center_start'}`} style={{padding: `0px ${(totalWidth-315)*0.2183}px`}}>
                                                {
                                                    showNftInfo.map((item, index) => {
                                                        const {
                                                            token_id, 
                                                            name, 
                                                            description,
                                                            image,
                                                            rarity,
                                                        } = item || {};
                                                        const rarityValue = getRarityByValue(rarity);
                                                        if (index < 9){
                                                            return (
                                                                <div className={s.nftItemRoot} key={index} style={{margin: `13px ${(totalWidth-315)*0.0938}px`}} onClick={() => {
                                                                    
                                                                }}>
                                                                    <div className={`${s.nftCard}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_Card_0${rarityValue}.png)`}}>
                                                                        <div 
                                                                            className={`${s.nftAvatar}`} 
                                                                            style={{
                                                                                backgroundImage: `url(${image})`,
                                                                                maskImage: `url(/UI/Picture/UI_Picture_Card_Mask.png)`
                                                                            }}>
                                                                        </div>
                                                                        {/*<div className={`${s.nftName} flex_center_center_col`}>
                                                                            <div className={`${s.name}`}>
                                                                                {name}
                                                                            </div>
                                                                        </div>*/}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        }
                                        {
                                            showNftInfo?.length > 9 && 
                                            <div className={`${s.moreLine} flex_center_center`}>
                                                {t('and more {{count}} NFTs', {count: showNftInfo?.length - 9})}
                                            </div>
                                        }
                                    </div>
                                    <div className={`${s.resultNextLine} resultNextLine flex_center_end`} onClick={() => {
                                        skipFn();
                                    }}>
                                        <div className={`${s.nextText}`}>
                                            {t('Next')}
                                        </div>
                                        <CustomIcon 
                                            className={`${s.nextIcon}`}
                                            imgName={`Button/UI_Button_Arrow_Right_01`} 
                                            width={14}
                                            height={14} />
                                    </div>
                                    {/*<div className={`${s.resultCardRoot} resultCardRoot`}>
                                        <div className={`${s.resultCoinRoot} resultCoinRoot flex_center_center_col`}>
                                            <div className={`${s.resultCoinBg}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardCoinBig_01.png)`}}>
                                                <div className={`${s.resultCoinImageRoot}`}>
                                                    <div className={`${s.resultCoinAvatar}`} style={{backgroundImage: `url(${symbolImageUrl})`}}>
                                                    </div>
                                                    <div className={`${s.resultCoinOuter}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardCoin-Mask_02.png)`}}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${s.resultCoinAmountBg} flex_center_center`} style={{backgroundImage: `url(/UI/Button/UI_Button_White-L_02.png)`}}>
                                                <div className={`${s.resultCoinAmount}`} style={{fontSize: `${totalWidth*0.1025}px`}}>
                                                    {`x ${convertWalletBalance(getTotalCoin())}`}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${s.nextCardRoot} nextCardRoot flex_center_center`}>
                                            <MomentClubUnpackCard
                                                width={totalWidth*0.65}
                                                selectedNftInfo={showNftInfo[nextCardIndex]}
                                                attributeSetting={attrs}
                                                rewardAmount={coinToNFTRatio}
                                                symbolImageUrl={symbolImageUrl}/>
                                        </div>
                                        <div className={`${s.currentCardRoot} currentCardRoot flex_center_center`}>
                                            <div className={`${s.currentCardPerspectiveRoot} currentCardPerspectiveRoot flex_center_center`}>
                                                <MomentClubUnpackCard
                                                    width={totalWidth*0.65}
                                                    selectedNftInfo={showNftInfo[currentCardIndex]}
                                                    attributeSetting={attrs}
                                                    rewardAmount={coinToNFTRatio}
                                                    symbolImageUrl={symbolImageUrl}
                                                    onClick={() =>{
                                                        switchNextCard();
                                                    }}/>
                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                            </>
                        }
                        {
                            state === 2 && 
                            <div className={`${s.summarizationPart} flex_center_start_col`}>
                                {
                                    memeUnpackInfo ? 
                                    <div className={`${s.swapResultRoot} flex_center_center_col`}>
                                        <div className={`${s.resultCoinRoot} resultCoinRoot flex_center_center_col`}>
                                            <div className={`${s.resultCoinBg}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardCoinBig_01.png)`}}>
                                                <div className={`${s.resultCoinImageRoot}`}>
                                                    <div className={`${s.resultCoinAvatar}`} style={{backgroundImage: `url(${symbolImageUrl})`}}>
                                                    </div>
                                                    <div className={`${s.resultCoinOuter}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardCoin-Mask_02.png)`}}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${s.resultCoinAmountBg} flex_center_center`} style={{backgroundImage: `url(/UI/Button/UI_Button_White-L_02.png)`}}>
                                                <div className={`${s.resultCoinAmount}`} style={{fontSize: `${totalWidth*0.1025}px`}}>
                                                    {`x ${convertWalletBalance(getTotalCoin())}`}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            showNftInfo?.length > 0 && 
                                            <div className={`${s.swapResultNftLine} flex_center_between`}>
                                                <div className={`${s.swapResultNftTitle} color-yellow`}>
                                                    {t('NFT')}
                                                </div>
                                                <div className={`flex_center_end`}>
                                                    {
                                                        showRarityList.map((item, index) => {
                                                            let count = 0;
                                                            if (showNftInfo?.length > 0){
                                                                for (let i = 0; i < showNftInfo?.length ; i++){
                                                                    if (showNftInfo[i].rarityValue === item){
                                                                        count++;
                                                                    }
                                                                }
                                                            }
                                                            if (count > 0){
                                                                return (
                                                                    <div key={index} className={`${s.nftRarity}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardQuality_${item.toString().padStart(2, '0')}.png)`}}>
                                                                        <div className={`${s.nftRarityCount}`}>
                                                                            {count}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div> :
                                    <div className={`flex_center_center_col`} style={{width: '100%'}}>
                                        <div className={`${s.summarizationBoard} flex_center_start_col`}>
                                            <div className={`${s.summarizationBoardList} flex_center_start_col`}>
                                                {
                                                    packResultList?.length > 0 &&
                                                    packResultList.map((item, index) =>{
                                                        const {
                                                            cardNo,
                                                            cardValue,
                                                            cardUrl,
                                                        } = item || {};
                                                        return (
                                                            <div key={index} className={`${s.summarizationBoardLine} ${s.summarizationBoardItemLine} flex_center_between`}>
                                                                <div className={`flex_center_start`}>
                                                                    <div className={s.itemPack}>
                                                                        <MomentClubPack
                                                                            width={45}
                                                                            imageUrl={cardUrl}
                                                                            number={''}
                                                                            packName={``}
                                                                            type={2}>
                                                                        </MomentClubPack>
                                                                        <div className={`${s.cardNo} flex_center_center`}>
                                                                            {`#${cardNo}`}
                                                                        </div>
                                                                    </div>
                                                                    {/*<div className={`${s.summarizationBoardLineIndex} flex_center_center`}>
                                                                        {cardNo}
                                                                    </div>*/}
                                                                    <div className={`${s.summarizationBoardLineName}`}>
                                                                        {`MOMENT #${cardNo}`}
                                                                    </div>
                                                                </div>
                                                                <div className={`flex_end_center_col`} style={{marginRight: '23px'}}>
                                                                    <div className={`${s.summarizationBoardLineValue}`}>
                                                                        {coveertValue(cardValue || 0)}
                                                                    </div>
                                                                    <div className={`${s.symbolName}`}>
                                                                        {`$${clubName}`}
                                                                    </div>
                                                                    {/*<div className={`${s.summarizationBoardLineIcon} ${s.summarizationBoardLineCommonIcon}`} style={{backgroundImage: `url(${symbolImageUrl})`}}>
                                                                    </div>*/}
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <div className={`${s.summarizationLine}`}>
                                            </div>
                                            <div className={`${s.summarizationTotalPart} flex_center_start_col`}>
                                                <div className={`${s.summarizationBoardLine} flex_center_between`}>
                                                    <div className={`${s.summarizationBoardLineIndexText}`}>
                                                        {t('Total')}
                                                    </div>
                                                    <div className={`flex_center_center_col`} style={{marginRight: '23px'}}>
                                                        <div className={`${s.summarizationBoardLineValue}`}>
                                                            {coveertValue(getTotalCoin() || 0)}
                                                        </div>
                                                        <div className={`${s.symbolName}`} style={{marginTop: `-4px`}}>
                                                            {`$${clubName}`}
                                                        </div>
                                                        {/*<div className={`${s.summarizationBoardLineIcon} ${s.summarizationBoardLineCommonIcon}`} style={{backgroundImage: `url(${symbolImageUrl})`}}>
                                                        </div>*/}
                                                    </div>
                                                </div>
                                                {
                                                    showNftInfo?.length > 0 && 
                                                    <div className={`${s.summarizationBoardLine} flex_center_between`} style={{marginTop: '6px'}}>
                                                        <div className={`${s.summarizationBoardLineIndexText}`}>
                                                            {t('NFT')}
                                                        </div>
                                                        <div className={`flex_center_end mr15`}>
                                                            {
                                                                showRarityList.map((item, index) => {
                                                                    let count = 0;
                                                                    if (showNftInfo?.length>0){
                                                                        for(let i = 0; i < showNftInfo?.length;i++){
                                                                            if (showNftInfo[i].rarityValue === item){
                                                                                count++;
                                                                            }
                                                                        }
                                                                    }
                                                                    if (count > 0){
                                                                        return (
                                                                            <div key={index} className={`${s.nftRarity}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardQuality_${item.toString().padStart(2, '0')}.png)`}}>
                                                                                <div className={`${s.nftRarityCount}`}>
                                                                                    {count}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/*<div className={`${s.nftDescription}`}>
                                    {t('x {{count}} ${{name}} needed to generate 1 NFT' , {name: symbolName,count: convertWalletBalance(coinToNFTRatio)})}
                                </div>
                                <div className={`${s.nextNftProgressRoot} flex_center_start`}>
                                    <div className={`${s.nextNftProgressContent}`}
                                        style={{
                                            width: coinToNFTRatio <= getNextNftAmount()  ? '0%' : `${Math.max(10, (coinToNFTRatio - getNextNftAmount())*100/coinToNFTRatio)}%`
                                        }}>
                                    </div>
                                    <div className={`${s.nextNftProgressText}`}>
                                        {t(`Next Req`)}:&nbsp;{convertWalletBalance(getNextNftAmount() || coinToNFTRatio)}
                                    </div>
                                </div>*/}

                                <AntButton
                                    className={`${s.closeBtn} btn_public`}
                                    onClick={() => {
                                        closeFn();
                                    }}
                                >
                                    <span className={"fs20 momentFont color-gray fb"}>
                                        {t('Close')}
                                    </span>
                                </AntButton>
                            </div>
                        }
                    </div>
                </div>
                {/*!isOnlineEnv() && <div className="flex_center_start" style={{position: 'absolute', width: '200px'}}>
                    <CustomIcon width={16} height={16} className="mr5" imgName={'Picture/UI_Picture_RedCircle_01'}
                        onClick={()=>{
                            //setPreOpenPackData(TestMemeUnpackInfo?.preOpenData);
                            setMintResult(TestMemeUnpackInfo?.mintResult);
                            setNeedWeb3Check(true);
                            setCheckTimes(checkTimes + 1);
                            setHasOpenPackError(false);
                            setErrorMsg(null);
                        }}/>
                    </div>*/}
                {
                    /*state === 0 && 
                    <div className={`${s.skipPart} skipPart flex_center_end`} onClick={() =>{
                        skipFn();
                    }}>
                        <div className={`${s.skipText}`}>
                            {t('Skip')}
                        </div>
                        <CustomIcon 
                            className="mr20" 
                            imgName={`Picture/UI_Picture_Arrows_Skip`} 
                            width={40}
                            height={40}></CustomIcon>
                    </div>*/
                }
                {
                    showOpenPackBtn &&
                    <div 
                        className={`${s.fullScreenOpenPackBtn} fullScreenOpenPackBtn`} 
                        onClick={() =>{
                            playOpenPackTimeline();
                        }} 
                        onTouchEnd={() =>{
                            playOpenPackTimeline();
                        }}
                    >
                    </div>
                }
            </div>
            {<AntModal
                width='338px'
                title=''
                className="memeNftModalWrap"
                centered={true}
                open={showMemeNftDetail}
                onOk={() => setShowMemeNftDetail(false)}
                onCancel={() => setShowMemeNftDetail(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMemeNftDetail
                    userInfo={userInfo}
                    selectedNftInfo={showNftInfo[selectedNftIndex]}
                    nextNft={() =>{
                        if (selectedNftIndex < showNftInfo.length - 1){
                            setSelectedNftIndex(selectedNftIndex + 1);
                        }
                    }}
                    hasNextNft={selectedNftIndex < showNftInfo.length - 1}
                    closeFn={() => {
                        setShowMemeNftDetail(false);
                    }}
                />
            </AntModal>}
            <div className={`${s.lightenScreen} lightenScreen`}>
            </div>
        </div>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        memeUnpackInfo: app.memeUnpackInfo,
        memeUnpackCount: app.memeUnpackCount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMemeUnpackInfo: (params) => {
            return dispatch({
                type: "app/setMemeUnpackInfo",
                payload: params,
            });
        },
        setMemeUnpackCount: (params) => {
            return dispatch({
                type: "app/setMemeUnpackCount",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(RevealMomentPack));
