import React, {memo, useState, useMemo, useEffect, useRef, useCallback} from "react";
import s from "./index.module.less";
import {Modal, Button, Slider, Checkbox, Spin, Drawer} from "antd";
import CustomIcon from "../../../../common/CustomIcon";
import AvatarInfo from "../../../../common/AvatarInfo";
import {ethers} from "ethers";
import {
    stringToNumber,
    post_batch_price,
    keyBatchBuy,
    TransactionError,
    PrivyLoginStatusError,
    logoutSelf,
    unit,
    displayFilter,
    getAvatarFromProfile
} from "@/utils/common";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    getnft_auction,
    nftDetail,
    coinObj,
    getRarityText,
    getRarityBgColor,
    getRarityFontColor,
    getBidStatus,
    delay,
    getRarityImageBg,
    getRarityRankIcon,
} from "@/utils/bidNft";
import {bidBatch, bidBatchPrice, addFloat} from "@/utils/bidNftWeb3";
import InfiniteScroll from "react-infinite-scroll-component";
import BidFilter from "@/drawer/BidFilter";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import ModalInsufficientCrpto from "@/model/ModalInsufficientCrpto";
import MainHeader from "src/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";

const maxBatchSelectNum = 20;
const BidSweepNFT = (
    {
        userInfo,
        currentAuctionBatchCfg,
        bidSlippage,
        setFilterBidNftSweep,
        filterBidNftSweep,
    }
) => {
    const {t, i18n} = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [allChecked, setAllChecked] = useState(0)
    const [showData, setShowData] = useState([])
    const [keyPrice, setKeyPrice] = useState({
        lfgBalance: 0,
        balance: 0,
        lfgBalanceWithSlippage: 0,
        balanceWithSlippage: 0
    });

    const buyList = useRef({});
    // const [showBuyList, setShowBuyList] = useState(JSON.parse(JSON.stringify(managerBuyList)));
    const [subLoading, setSubLoading] = useState(false);
    const {userId, walletAddr} = userInfo;
    const {balance, lfgBalance} = userInfo?.selfData || {};

    const [noSuccessText, setNoSuccessText] = useState('Bid Failed');
    const [done, setDone] = useState(false);
    const [subFailded, setSubFailded] = useState(false);
    const [settleLoading, setSettleLoading] = useState(false);
    const [nextCursor, setNextCursor] = useState(0);
    const [showBidFilter, setShowBidFilter] = useState(false);
    const [filterRank, setFilterRank] = useState(filterBidNftSweep?.rankRange);

    const [filterMaticPrice, setFilterMaticPrice] = useState(filterBidNftSweep?.maticPriceRange);
    const [filterLFGPrice, setFilterLFGPrice] = useState(filterBidNftSweep?.lfgPriceRange);
    const [filterIncludeTopPriceIsSelf, setFilterIncludeTopPriceIsSelf] = useState(filterBidNftSweep?.includeTopPriceIsSelf)
    const [filterIsAsc, setFilterIsAsc] = useState(filterBidNftSweep?.isAsc || true)

    const [reset, setReset] = useState(true)

    const [bidState, setBidState] = useState([])

    // const [list, setList] = useState([])
    const [txHash, setTxHash] = useState('')

    const getFilterParam = () => {
        let rankRange = [1, -1];
        if (filterRank?.[0] !== null) {
            rankRange[0] = Math.max(1, filterRank?.[0]);
        }
        if (filterRank?.[1] !== null) {
            rankRange[1] = filterRank?.[1];
        }

        let maticPriceRange = ["0", "-1"];
        if (filterMaticPrice?.[0] !== null) {
            maticPriceRange[0] = filterMaticPrice?.[0].toString();
        }
        if (filterMaticPrice?.[1] !== null) {
            maticPriceRange[1] = filterMaticPrice?.[1].toString();
        }

        let lfgPriceRange = ["0", "-1"];
        if (filterLFGPrice?.[0] !== null) {
            lfgPriceRange[0] = filterLFGPrice?.[0].toString();
        }
        if (filterLFGPrice?.[1] !== null) {
            lfgPriceRange[1] = filterLFGPrice?.[1].toString();
        }
        let includeTopPriceIsSelf = filterIncludeTopPriceIsSelf;
        return {rankRange, maticPriceRange, lfgPriceRange, includeTopPriceIsSelf};
    }

    const getList = (rankRange, maticPriceRange, lfgPriceRange, includeTopPriceIsSelf, cursor = 0, oriList = []) => {
        setSubLoading(true)
        getnft_auction(
            1, //searchStatus
            rankRange || [1, -1], //rankRange
            maticPriceRange || ["0", "-1"], //maticPriceRange
            lfgPriceRange || ["0", "-1"], //lfgPriceRange
            false, //includeTopPriceIsSelf
            filterIsAsc,
            cursor || 0  //cursor,
        ).then(res => {
            const {auctionList, nextCursor} = res;
            // setList(auctionList || []);
            setNextCursor(nextCursor || 0);
            if (auctionList?.length > 0) {
                setShowData([...oriList, ...auctionList]);
            }
            // setSelectedRows(auctionList || [])
            // list.current = auctionList || []
            setSubLoading(false)
        }).catch(e => {
            setSubLoading(false)
            console.error(e);
        })
    }

    useEffect(() => {
        let param = getFilterParam();
        getList(param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, 0);
    }, [])

    const getMoreList = () => {
        let param = getFilterParam();
        getList(param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, nextCursor, showData);
    }

    const isBlanceAvailable = useMemo(() => {
        if (Number(balance) < 0.1) return false
        return keyPrice?.balance < balance && keyPrice?.lfgBalance <= lfgBalance
    }, [balance, keyPrice, lfgBalance]);

    useEffect(() => {
        calcSelectedRows()
    }, [selectedRows])
    const calcSelectedRows = () => {
        // console.log(selectedRows);


        if (selectedRows?.length === 0) {
            setKeyPrice({
                lfgBalance: 0,
                balance: 0,
                lfgBalanceWithSlippage: 0,
                balanceWithSlippage: 0
            })
            return
        }

        let _lfgBalance = 0, _balance = 0;
        setSettleLoading(true)
        selectedRows.map(item => {
            const {biddingInfo, coinType} = item || {};
            const {nextPrice} = biddingInfo || {};
            coinType === 0 ? _lfgBalance = addFloat(Number(nextPrice), _lfgBalance) : _balance = addFloat(Number(nextPrice), _balance)
        })
        setKeyPrice({
            lfgBalance: _lfgBalance,
            balance: _balance,
            lfgBalanceWithSlippage: Number(_lfgBalance) * (Number(bidSlippage) + 100) / 100,
            balanceWithSlippage: Number(_balance) * (Number(bidSlippage) + 100) / 100,
        })
        console.log(_lfgBalance, _balance);
        setSettleLoading(false)
        // bidBatchPrice(wallets,selectedRows).then(res=>{
        //     setSettleLoading(false)
        //     console.log(res);
        // }).catch(e=>{
        //     setSettleLoading(false)
        //     console.error(e);
        // })
    }

    const closeModal = async () => {
        setTxHash('')
        setShowData([]);
        let param = getFilterParam();
        getList(param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, 0);
        setReset(true)
        setDone(false)
        setBidState([])
        buyList.current = {}
        setSelectedRows([])

        // setShowModelTrans(false)
    };
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);

    const handleCheckboxChange = (row) => {
        let t
        if (selectedRows.includes(row)) {
            t = selectedRows.filter((item) => item !== row)
        } else {
            if (selectedRows.length >= maxBatchSelectNum) return
            t = [...selectedRows, row];
        }
        const temp = {}
        const showTemp = {}
        // t.forEach(r => {
        //     const {userId} = r
        //     showTemp[userId] = temp[userId] = showBuyList[userId] || 1
        // })
        if (showData.length === t.length) {
            setAllChecked(true)
        } else {
            setAllChecked(false)
        }
        // setShowBuyList(showBuyList)
        buyList.current = temp
        setSelectedRows(t)
    }


    const {logout} = usePrivy();
    const closeFn = () => {

        if (noSuccessText === 'need login') {
            logout();
            logoutSelf();
        }
        closeModal();
    }

    const {wallets} = useWallets();
    const sweepBid = () => {
        if (subLoading) return

        let bidLfgBalance = Number(keyPrice?.lfgBalance) || 0;
        let bidMaticBalance = Number(keyPrice?.balance) || 0;

        let obj = {
            lfg: {
                tokenAddrs: [],
                tokenIds: [],
                expectedSpendings: [],
                val: bidLfgBalance > 0 ? bidLfgBalance + 0.0001 : 0,
                // val: 0.1,
                coinType: 1
            },
            matic: {
                tokenAddrs: [],
                tokenIds: [],
                expectedSpendings: [],
                val: bidMaticBalance > 0 ? bidMaticBalance + 0.0001 : 0,
                // val: 0.1,
                coinType: 0
            }
        }

        console.log("expectedPriceObj", obj);

        if (!isBlanceAvailable) {
            if (keyPrice?.balance > balance) {
                setShowInsufficientCrpto(true);
            } else if (keyPrice?.lfgBalance > lfgBalance) {
                setShowSwapLfg(true);
            }
            return;
        }
        setReset(false)
        setSubLoading(true);

        selectedRows.map(item => {
            // const { nftSimpleData, coinType, nextPrice } = item;
            // const { tokenAddr, tokenId } = nftSimpleData;

            const {biddingInfo, coinType, nftSimpleData} = item || {};
            const {nextPrice} = biddingInfo || {};
            const {tokenAddr, tokenId} = nftSimpleData || {};
            let typeObj = {...obj[coinType === 0 ? 'lfg' : 'matic']};
            typeObj['tokenAddrs'] = [...typeObj?.tokenAddrs, tokenAddr];
            typeObj['tokenIds'] = [...typeObj?.tokenIds, tokenId];
            typeObj['expectedSpendings'] = [...typeObj?.expectedSpendings, ethers.utils.parseEther(nextPrice)];
            // typeObj['expectedSpendings'] = [...typeObj?.expectedSpendings, ethers.utils.parseEther('0.01')];

            obj[coinType === 0 ? 'lfg' : 'matic'] = {
                ...obj[coinType === 0 ? 'lfg' : 'matic'],
                ...typeObj
            }
        })


        // console.log(object);


        bidBatch(wallets, obj, Number(bidSlippage), Number(balance)).then(res => {
            setDone(true);
            setSubLoading(false);
            setSubFailded(false);
            setTxHash(res?.hash)

            // setSelectedRows([])
            // console.log(res);
        }).catch(e => {
            console.error(e);
            setDone(true);
            setSubLoading(false);
            // setBidState
            let l = [];
            selectedRows.map(item => {
                const {tokenAddr, tokenId} = item?.nftSimpleData;
                l.push({
                    tokenAddr,
                    tokenId,
                    bidSucc: false
                })

            })
            setBidState(l)
            setSubFailded(true)

            // setSelectedRows([])
        })
    }


    useEffect(() => {
        let timer = null
        if (!reset && done && txHash) {
            timer = setInterval(() => {
                getBidStatus(txHash).then(async res => {
                    console.log(txHash, res);
                    const {result} = res
                    setBidState(result || [])
                    if (result?.length === selectedRows?.length) {
                        clearInterval(timer)
                        setTxHash('')
                    }
                })
            }, [2000])

        }
        return () => {
            timer && clearInterval(timer)
        }
    }, [reset, done, txHash])


    // useEffect(() => {
    //     setSettleLoading(true)
    // }, [buyList.current])

    const setFilterLocalData = (obj) => {
        setFilterBidNftSweep({
            ...filterBidNftSweep,
            ...obj,
        })
    }

    const applyFilter = (filterRank, filterLFGPrice = null, filterMaticPrice = null, includeTopPriceIsSelf) => {
        let rankRange = [1, -1];
        if (filterRank?.[0] != null) {
            rankRange[0] = Math.max(1, filterRank?.[0]);
        }
        if (filterRank?.[1] != null) {
            rankRange[1] = filterRank?.[1];
        }

        let maticPriceRange = ["0", "-1"];
        if (filterMaticPrice?.[0] != null) {
            maticPriceRange[0] = filterMaticPrice?.[0].toString();
        }
        if (filterMaticPrice?.[1] != null) {
            maticPriceRange[1] = filterMaticPrice?.[1].toString();
        }

        let lfgPriceRange = ["0", "-1"];
        if (filterLFGPrice?.[0] != null) {
            lfgPriceRange[0] = filterLFGPrice?.[0].toString();
        }
        if (filterLFGPrice?.[1] != null) {
            lfgPriceRange[1] = filterLFGPrice?.[1].toString();
        }
        setShowData([]);
        setFilterLocalData({rankRange, maticPriceRange, lfgPriceRange, includeTopPriceIsSelf});
        getList(rankRange, maticPriceRange, lfgPriceRange, includeTopPriceIsSelf, 0);
        setSelectedRows([]);
        setShowBidFilter(false);
    }

    const onChangeRank = (value) => {
        let arr = [];
        if (value) {
            for (let i = 0; i < value; i++) {
                arr.push(showData[i])
            }
        }
        setSelectedRows([...arr])
    }

    return (
        <>
            <div className={`${s.container} publicBg`}>
                <MainHeader title={t('SWEEP NFTS')} hasRightMenu={false} headerType={2}></MainHeader>
                <div className={s.bidFilterRoot}>
                    <CustomIcon width={24} height={24} imgName={'Button/UI_Button_ThreeTieRod_01'}
                                name={'Button/UI_Button_ThreeTieRod_01'}
                                onClick={() => setShowBidFilter(true)}/>
                </div>
                <div className={s.wrap} id="bidList">
                    <InfiniteScroll
                        dataLength={showData?.length || 0}
                        next={getMoreList}
                        hasMore={nextCursor !== -1}
                        loader={<Spin/>}
                        scrollableTarget={'bidList'}
                        endMessage={
                            <div style={{
                                textAlign: "center",
                                width: "100%",
                                color: "var(--color-white)",
                                fontSize: '12px'
                            }}>{showData?.length === 0 ? t('No auction in progress') : t('No More Data')}</div>
                        }
                    >
                        {showData.map((item, index) => {
                            const {
                                winnerInfo,
                                rankIdx,
                                status,
                                biddingInfo,
                                coinType,
                                nftSimpleData,
                                seriesTotalAmount
                            } = item || {};
                            const {currentPrice, startTime, nextPrice, endTime, curPriceUserId} = biddingInfo || {};
                            const {tokenAddr, tokenId, name, rarity, image} = nftSimpleData || {};
                            const resObj = bidState?.find(i => i?.tokenId === tokenId) || {}
                            const isHighest = curPriceUserId === userId;
                            if (isHighest) return null
                            return (
                                <div key={"filter" + index}
                                     className={` ${s.item} ${selectedRows.includes(item) ? s.checkedBG : ''} ${!reset ? s.loading : ''} ${!reset && !selectedRows.includes(item) ? s.hide : ''}`}>
                                    <div className={s.l}>
                                        <Checkbox onClick={() => reset && handleCheckboxChange(item)}
                                                  className="hireCheckBox"
                                                  checked={selectedRows.includes(item)}
                                        />
                                    </div>
                                    <div className={s.rWrap}>
                                        <div className={s.mid}>
                                            {
                                                currentAuctionBatchCfg?.auctionBatchType === 2 &&
                                                <div className={s.bidItemBg}
                                                     style={{backgroundImage: `url(/img/${getRarityImageBg(rarity)}.png)`}}>
                                                </div>
                                            }
                                            <AvatarInfo size={65} src={image} className={'roundAvatar'}/>
                                        </div>
                                        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{
                                                width: '100%',
                                                flexDirection: 'column',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginLeft: '5px'
                                            }}>
                                                <div style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    <div className={s.ellipseUser}>
                                                        <div className="mb5 tll fs14">{name} </div>
                                                    </div>
                                                    <div className={s.r}>
                                                        {
                                                            currentAuctionBatchCfg?.auctionBatchType === 2 ?
                                                                <div className={`${s.rankTag} bidRarity_${rarity}`}
                                                                     style={{
                                                                         backgroundColor: `${getRarityBgColor(rarity)}`,
                                                                         color: `${getRarityFontColor(rarity)}`
                                                                     }}>
                                                                    <CustomIcon width={17} height={17}
                                                                                imgName={getRarityRankIcon(rarity)}
                                                                                name={getRarityRankIcon(rarity)}/>
                                                                    {rankIdx || '--'}
                                                                </div> :
                                                                <div className={`${s.tag} bidRarity_${rarity}`} style={{
                                                                    backgroundColor: `${getRarityBgColor(rarity)}`,
                                                                    color: `${getRarityFontColor(rarity)}`
                                                                }}>
                                                                    {getRarityText(rarity)}
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={s.labelItem}>
                                                        <div
                                                            className={`${s.label} color-blue-light`}>{t('Next Bid')}:
                                                        </div>
                                                        <div className={s.val}>
                                                            <CustomIcon width={24} height={24} className="mr5"
                                                                        imgName={coinObj[coinType]?.iconName}
                                                                        name={coinObj[coinType]?.iconName}/>
                                                            <div className="df">
                                                                {stringToNumber(nextPrice, 2)}&nbsp;
                                                                {(done || subLoading) && <div className={s.control}>
                                                                    {JSON.stringify(resObj) === '{}' &&
                                                                        <div className={`${s.res} ${s.loading}`}>
                                                                            <CustomIcon width={14} height={14}
                                                                                        imgName={'icon_loding_white'}
                                                                                        name={'icon_loding_white'}/>
                                                                        </div>}

                                                                    {JSON.stringify(resObj) !== '{}' && resObj?.bidSucc &&
                                                                        <div className={`${s.res} ${s.succ}`}>
                                                                            <CustomIcon width={14} height={14}
                                                                                        imgName={'icon_right_white'}
                                                                                        name={'icon_right_white'}/>
                                                                        </div>}

                                                                    {JSON.stringify(resObj) !== '{}' && !resObj?.bidSucc &&
                                                                        <div className={`${s.res} ${s.failed}`}>
                                                                            <CustomIcon width={14} height={14}
                                                                                        imgName={'icon_wrong_white'}
                                                                                        name={'icon_wrong_white'}/>
                                                                        </div>}

                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            )

                        })}
                    </InfiniteScroll>
                    <div className={s.sliderContainer}>
                    </div>
                </div>
                <div className={s.bottom}>
                    <div className={s.sliderWrap}>
                        <Slider
                            defaultValue={0}
                            value={selectedRows.length}
                            className={`hireSlider commonSlider ${s.slider}`}
                            onChange={onChangeRank}
                            max={Math.min(showData?.length, maxBatchSelectNum)}/>
                        <div className="fs14 color-white mr5">
                            <span
                                className="color-white fs14">{selectedRows.length}</span> {t('Selected')}
                        </div>
                    </div>
                    <div className={s.bottomSettleSweep}>
                        <Spin spinning={settleLoading}>
                            <div className={s.settlePanel}>
                                <div className={s.st}>
                                    <div className={s.label}>
                                        {t('Order Details')}
                                    </div>
                                    <div className={s.sr}>
                                        <div className={s.val}>
                                            <CustomIcon className="mr5" width={18} height={18}
                                                        imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                                        name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                                            {stringToNumber(keyPrice?.balance, 2)}
                                        </div>
                                        <div className={s.val}>
                                            <CustomIcon className="mr5" width={18} height={18}
                                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                            {stringToNumber(keyPrice?.lfgBalance, 2)}
                                        </div>
                                    </div>

                                </div>
                                <div className={s.st}>
                                    <div className={s.label}>
                                        {t('Total(inc Slippage)')}
                                    </div>
                                    <div className={s.sr}>
                                        <div className={s.val}>
                                            <CustomIcon className="mr5" width={18} height={18}
                                                        imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                                        name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                                            {stringToNumber(keyPrice?.balanceWithSlippage, 2)}
                                        </div>
                                        <div className={s.val}>
                                            <CustomIcon className="mr5" width={18} height={18}
                                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                            {stringToNumber(keyPrice?.lfgBalanceWithSlippage, 2)}
                                        </div>
                                    </div>

                                </div>
                                <div className={s.st}>
                                    <div className={s.label}>
                                        Available
                                    </div>
                                    <div className={s.sr}>
                                        <div className={s.val}>
                                            <CustomIcon className="mr5" width={18} height={18}
                                                        imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                                        name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                                            {stringToNumber(balance, 2)}
                                        </div>
                                        <div className={s.val}>
                                            <CustomIcon className="mr5" width={18} height={18}
                                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                            {stringToNumber(lfgBalance, 2)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className={s.sweepButton}>
                                {!done && <ButtonFactory onClick={sweepBid} disabled={!selectedRows.length}>
                                    {subLoading ? <div className={s.web3LoadingLine}>
                                        <CustomIcon rotating={true} className="ml5" width={26} height={26}
                                                    imgName={'Picture/UI_Picture_Loading_01'}
                                                    name={'Picture/UI_Picture_Loading_01'}/>
                                        <span className="fs18 ml5 color-black">{t('PROCESSING')}</span>
                                    </div> : t('BID')}
                                </ButtonFactory>}
                                {done && <ButtonFactory
                                    onClick={closeFn}>{subFailded ? noSuccessText : t('TRANSACTION SUCCESSFUL')}</ButtonFactory>}
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowBidFilter(false)}
                width={300}
                open={showBidFilter}
                className="hireFilter"
                destroyOnClose={true}
            >
                <BidFilter
                    closeHandle={() => {
                        setShowBidFilter(false)
                    }}
                    maxRank={2000}
                    filterRank={filterRank}
                    setFilterRank={setFilterRank}
                    filterLFGPrice={filterLFGPrice}
                    setFilterLFGPrice={setFilterLFGPrice}
                    filterMaticPrice={filterMaticPrice}
                    setFilterMaticPrice={setFilterMaticPrice}
                    applyFilter={applyFilter}
                    filterIncludeTopPriceIsSelf={filterIncludeTopPriceIsSelf}
                    setFilterIncludeTopPriceIsSelf={setFilterIncludeTopPriceIsSelf}
                    isHideFilterIncludeTopPriceIsSelf={true}
                />
            </AntDrawer>
            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg
                    insufficientAmount={Number((stringToNumber(keyPrice?.lfgBalance, 2) - lfgBalance).toFixed(2))}
                    userInfo={userInfo} closeFn={() => {
                    setShowSwapLfg(false);
                }}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('INSUFFICIENT BALANCE')}
                className="confirmModalWrap"
                centered={true}
                open={showInsufficientCrpto}
                onOk={() => setShowInsufficientCrpto(false)}
                onCancel={() => setShowInsufficientCrpto(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalInsufficientCrpto
                    insufficientAmount={Number((stringToNumber(keyPrice?.balance, 2) - balance).toFixed(2))}
                    userInfo={userInfo} closeFn={() => {
                    setShowInsufficientCrpto(false);
                }}/>
            </AntModal>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        currentAuctionBatchCfg: app.currentAuctionBatchCfg,
        bidSlippage: app.bidSlippage,
        filterBidNftSweep: app.filterBidNftSweep
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        setShowLink: (params) => {
            return dispatch({
                type: "app/setShowLink",
                payload: params
            });
        },
        setFilterBidNftSweep: (params) => {
            return dispatch({
                type: "app/setFilterBidNftSweep",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(BidSweepNFT));

