import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Slider, Checkbox} from "antd";
import {unit} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";

const HireFilter = (
    {
        closeHandle,
        filterPrice,
        setFilterPrice,
        filterHolding,
        setFilterHolding,
        filterHolder,
        setFilterHolder,
        selMyholders,
        setSelMyholders,
        selMyholding,
        setSelMyholding,
        getHireList,
        setCheckedArr,
        filterLevel,
        setFilterLevel,
        filterTwitterFollower,
        setFilterTwitterFollower,
        selIgnoreGuestUsers,
        setSelIgnoreGuestUsers,
        selIgnoreWishUsers,
        setSelIgnoreWishUsers,
    }
) => {
    const {t, i18n} = useTranslation();
    const maxPrice = 100
    const maxTwitterFollower = 200000
    const onChangePrice = (value) => {
        setFilterPrice(value)
    }
    const onChangeHolding = (value) => {
        setFilterHolding(value)
    }
    const onChangeHolder = (value) => {
        setFilterHolder(value);
    }
    const changeSelHolders = (e) => {
        setSelMyholders(e.target.checked)
    }
    const changeSelHolding = (e) => {
        setSelMyholding(e.target.checked)
    }
    const onChangeLevel = (value) => {
        setFilterLevel(value);
    }
    const onChangeTwitterFollower = (value) => {
        setFilterTwitterFollower(value);
    }
    const changeSelIgnoreGuestUsers = (e) => {
        setSelIgnoreGuestUsers(e.target.checked)
    }
    const changeSelIgnoreWishUsers = (e) => {
        setSelIgnoreWishUsers(e.target.checked)
    }

    const reset = () => {
        setFilterPrice([0, maxPrice])
        setFilterHolding([0, 200])
        setFilterHolder([0, 200])
        setSelMyholders(false)
        setSelMyholding(false)
        setFilterLevel([1, 15])
        setFilterTwitterFollower([0, maxTwitterFollower])
        setSelIgnoreGuestUsers(true)
        setSelIgnoreWishUsers(true)
    }
    const apply = async () => {
        await getHireList("")
        setCheckedArr()
        closeHandle()
    }
    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <CustomIcon width={25} className="mr5" height={25} imgName={'Button/UI_Button_ThreeTieRod_01'}
                            name={'Button/UI_Button_ThreeTieRod_01'}/> {AutoStrangeRule(t('FILTERS'))}
            </div>
            <div className={s.filterCon}>
                <div className={s.item}>
                    <div className="df" style={{justifyContent: "space-between"}}>
                        <div className={s.label}>{t('Key Price From:')}</div>
                        <div className={s.rankArea}>
                            <CustomIcon width={14} className="m02" height={14}
                                        imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                        name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                            <span className="color-white">{filterPrice[0]}</span>
                            <span className="fs12"> {t('to')} </span>
                            <CustomIcon width={14} className="m02" height={14}
                                        imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                        name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                            <span className="color-white">{filterPrice[1] === maxPrice
                                ? maxPrice + "+"
                                : filterPrice[1].toLocaleString("en-US")}</span>
                        </div>
                    </div>
                    <div className={s.slider}>
                        <Slider
                            defaultValue={filterPrice}
                            className="hireSlider commonSlider"
                            range={true}
                            min={0}
                            max={maxPrice}
                            value={filterPrice}
                            step={0.1}
                            onChange={onChangePrice}/>
                    </div>
                </div>
                <div className={s.item}>
                    <div className="df">
                        <div className={s.label}>{t('Level From')}: &nbsp;</div>
                        <div className={s.rankArea}>
                            <span className="color-white">{filterLevel[0]}</span>
                            <span className="fs12"> {t('to')} </span>
                            <span className="color-white">{filterLevel[1]}</span>
                        </div>
                    </div>
                    <div className={s.slider}>
                        <Slider
                            defaultValue={filterLevel}
                            className="hireSlider commonSlider"
                            range={true}
                            min={1}
                            value={filterLevel}
                            max={15}
                            onChange={onChangeLevel}/>
                    </div>
                </div>
                <div className={s.item}>
                    <div className="df">
                        <div className={s.label}>{t('Joined Clubs From:')} &nbsp;</div>
                        <div className={s.rankArea}>
                            <span className="color-white">{filterHolding[0]}</span>
                            <span className="fs12"> {t('to')} </span>
                            <span className="color-white">{filterHolding[1] === 200
                                ? 200 + "+"
                                : filterHolding[1].toLocaleString("en-US")}</span>
                        </div>
                    </div>
                    <div className={s.slider}>
                        <Slider
                            defaultValue={filterHolding}
                            className="hireSlider commonSlider"
                            range={true}
                            min={0}
                            value={filterHolding}
                            max={200}
                            onChange={onChangeHolding}/>
                    </div>
                </div>
                <div className={s.item}>
                    <div className="df">
                        <div className={s.label}>{t('Members From:')} &nbsp;</div>
                        <div className={s.rankArea}>
                            <span className="color-white">{filterHolder[0]}</span>
                            <span className="fs12"> {t('to')} </span>
                            <span className="color-white">{filterHolder[1] === 200
                                ? 200 + "+"
                                : filterHolder[1].toLocaleString("en-US")}</span>
                        </div>
                    </div>
                    <div className={s.slider}>
                        <Slider
                            defaultValue={filterHolder}
                            className="hireSlider commonSlider"
                            range={true}
                            min={0}
                            max={200}
                            value={filterHolder}
                            onChange={onChangeHolder}/>
                    </div>
                </div>
                <div className={s.item}>
                    <div className="df">
                        <div className={s.label}>{t('Twitter Followers From')}: &nbsp;</div>
                        <div className={s.rankArea}>
                            <span className="color-white">{filterTwitterFollower[0]}</span>
                            <span className="fs12"> {t('to')} </span>
                            <span className="color-white">{filterTwitterFollower[1] === maxTwitterFollower
                                ? maxTwitterFollower + "+"
                                : filterTwitterFollower[1].toLocaleString("en-US")}</span>
                        </div>
                    </div>
                    <div className={s.slider}>
                        <Slider
                            defaultValue={filterTwitterFollower}
                            className="hireSlider commonSlider"
                            range={true}
                            min={0}
                            max={200000}
                            value={filterTwitterFollower}
                            onChange={onChangeTwitterFollower}/>
                    </div>
                </div>

                <div className="mb10">
                    <Checkbox onChange={changeSelIgnoreGuestUsers} className="hireCheckBox"
                              checked={selIgnoreGuestUsers} defaultChecked={selIgnoreGuestUsers}>
                        <span className="ml10">{t('Exclude guest users')}</span>
                    </Checkbox>
                </div>
                <div className="mb10">
                    <Checkbox onChange={changeSelIgnoreWishUsers} className="hireCheckBox" checked={selIgnoreWishUsers}
                              defaultChecked={selIgnoreWishUsers}>
                        <span className="ml10">{t('Exclude wish users')}</span>
                    </Checkbox>
                </div>
            </div>
            <div className={s.bottom}>
                <AntButton className={s.black} onClick={reset}>{t('RESET')}</AntButton>
                <AntButton className="btn_public" onClick={apply}>{t('APPLY')}</AntButton>
            </div>

        </div>
    )
}
export default memo(HireFilter);
