import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../common/CustomIcon";
import {
    convertWalletBalance, 
    numberClamp, 
    getChainTokenInfoById,
    logoutSelf,
} from "@/utils/common";
import ButtonFactory from "@/common/ButtonFactory";

const ModalWalletDisconnect = (
    {
    }
) => {
    const {t, i18n} = useTranslation();

    const logoutFn = () =>{
        logoutSelf();
    }

    return (
        <div className={s.wrap}>
            <div className={s.topPart}>
                <div className={`${s.content} flex_center_start_col`}>
                    <div className={`${s.contentDes}`}>
                        {t('Your Privy login session has timed out. Some features are now restricted. Please log in again to regain full access.')}
                    </div>
                    <ButtonFactory
                        className={s.logoutBtn}
                        size={21}
                        isFixed={true}
                        fixedStyle={{useHeight: true, height: 42}}
                        onClick={() => {
                            logoutFn();
                        }}
                    >
                        <div className={`${s.logoutBtnText}`}>
                            {t('Relogin')}
                        </div>
                    </ButtonFactory>
                </div>
            </div>
        </div>
    )
}
export default memo(ModalWalletDisconnect);
