import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useState } from "react";
import { Input, Button, Modal, Spin, Popover } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
// import Banner from "../../../common/Banner";
import {
    stringToNumber,
    convertWalletBalance
} from "@/utils/common"
import sharewinImg from '@/assets/images/bgImg/sharewinrewards.webp';

import { twitterShareEndTime } from "@/utils/env";

import MainHeader from "@/common/MainHeader";
import { getWheelInfo,farmReport } from "@/utils/xFarm";
import ButtonFactory from "@/common/ButtonFactory";
import ShareUpPointsNavs from "@/common/ShareUpPointsNavs";
import Bonus from "./Bonus";
import Guide from "./Guide";
import FeverFarm from "./FeverFarm";
import LeaderShip from "./LeaderShip";
import ModelUpPointLog from "@/model/ModelUpPointLog";
import AntModal from "@/common/AntModal";
const ShareUpPoints = (
    {
        userInfo,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {points,buyFirstKey} = useMemo(()=>{
        return userInfo?.selfData
    },[userInfo])
    const hasLinkX = useMemo(()=>{
        return userInfo?.platformData?.platformMap[1]
    },[userInfo])
    const [wheelInfo,setWheelInfo] = useState(null)

    const [path, setPath] = useState(/*hasLinkX?'Bonus':*/'Guide');
    const [showUpPointLog,setShowUpPointLog] = useState(false)
    const [report,setReport] = useState(false)
    const getWheelInfoFn = ()=>{
        getWheelInfo().then(res=>{
            setWheelInfo(res)
        }).catch(e=>{

        })
    }

    // useEffect(()=>{
    //     getWheelInfoFn()
    // },[])

    useEffect(()=>{
        if(hasLinkX && !report) {
            farmReport()
            setReport(true)
        } 
        
    },[hasLinkX])

    useEffect(()=>{
        getWheelInfoFn()
    },[path])
    
    const [shake,setShake] = useState(false)

    return (
        <div className={`${s.modalWrap} mainWrap commonBlueBg`}>
            <MainHeader leftTitle = {<Trans
                i18nKey="Earn $UP Points"
                defaults="Earn <yellow>{{tag}}</yellow> Points"
                values={{tag:'$IYKYK'}}
                components={{yellow: <span className="color-yellow fs28"></span>}}
            />}
            headerType={2} isGameHeader={true} hasBackBtn={false} rightMenuIcon={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={s.mid}>
                <div className={s.item}>
                    <ButtonFactory
                        size={33}
                        color={3}
                        iconName={'UI_Picture-Currency_UP_01'}
                    >
                        <div className="ml10 color-black fs18 fb">{stringToNumber(wheelInfo?.totalBaseFevel, 2)}</div>
                    </ButtonFactory>
                    <div className={s.label}>{t('Fever')}</div>
                </div>
                <div className={s.item}>
                    <ButtonFactory
                        size={33}
                        color={3}
                        iconName={'UI_Picture_Bonus'}
                    >
                        <div className="ml10 color-black fs18 fb">{Math.round(wheelInfo?.wheelVal * 100)}%</div>
                    </ButtonFactory>
                    <div className={s.label}>{t('Bonus')}</div>
                </div>
                {/* <div className={`${s.item} ${s.midIcon}`}>
                    <CustomIcon width={16} height={16} imgName={'Button/UI_Button_Close_01'}></CustomIcon>
                </div> */}
                
                {/* <div className={`${s.item} ${s.midIcon}`}>
                    <CustomIcon width={16} height={16} imgName={'Button/UI_Button_Right_01'}></CustomIcon>
                </div> */}
                <div className={s.item}>
                    <ButtonFactory
                        size={33}
                        color={3}
                        iconName={'UI_Picture-Currency_UP_01'}
                        onClick={()=>{
                            setShowUpPointLog(true)
                        }}
                    >
                        <div className="ml10 color-black fs18 fb">{convertWalletBalance(wheelInfo?.totalBaseFevel * wheelInfo?.wheelVal)}</div>
                    </ButtonFactory>
                    <div className={s.label}>{t('$UP Points')}</div>
                </div>
            </div>
            {/* <div style={{ marginTop: '0', width: '100%' }}>
                <Banner bgUrl={sharewinImg} title={<div className="mt25" style={{ fontSize: '20px' }}>
                    <Trans
                        i18nKey="Share TURNUP on X every day"
                        defaults="<sp>Share on X to</sp><br></br> Earn 50000 $UP Points!"
                        components={{
                            sp: <span style={{ fontSize: '20px' }}></span>,
                            br: <br />
                        }}
                    />
                </div>}
                    showTimerShare={true}
                    hasCurndown={true}
                    customTimestamp={twitterShareEndTime}
                />
            </div> */}
            <div className={s.modal}>
                <div className={s.content}>
                    {path === 'Bonus' && <Bonus wheelInfo={wheelInfo} setWheelInfo = {setWheelInfo}></Bonus>}
                    {path === 'Guide' && <Guide setPath={setPath} shake={shake} setShake={setShake} wheelInfo={wheelInfo}></Guide>}
                    {path === 'Fever Farm' && <FeverFarm setPath={setPath} wheelInfo={wheelInfo}></FeverFarm>}
                    {path === 'LeaderShip' && <LeaderShip setPath={setPath} wheelInfo={wheelInfo}></LeaderShip>}
                    
                    <div className={s.bottom}>
                        <ShareUpPointsNavs 
                            buyFirstKey={buyFirstKey}
                            hasLinkX={hasLinkX}
                            path={path}
                            setPath={setPath}
                            setShake={setShake}
                        />
                    
                    </div>
                </div>
            </div>
            <AntModal
                width='330px'
                title={t('My $UP Points')}
                className="confirmModalWrap"
                centered={true}
                open={showUpPointLog}
                destroyOnClose={true}
                onOk={() => setShowUpPointLog(false)}
                onCancel={() => setShowUpPointLog(false)}
            >
                <ModelUpPointLog />
            </AntModal>
            
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ShareUpPoints));
