import React, { memo, useState, useEffect } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import { moment_clubcardlist } from "@/utils/momentClub";
import CustomIcon from "src/common/CustomIcon";

const ModelMomentTgeProgressTips = ({
    closeHandle = () => {},
    amount,
    clubId,
    curCardKindNum,
    clubName,
}) => {
    const { t, i18n } = useTranslation();
    const [cardList, setCardList] = useState([]);
    const [showMore, setShowMore] = useState(false);

    const getcardlist = () => {
        moment_clubcardlist(clubId).then(res => {
            setCardList(res?.cardList || [])
        }).catch((e) => {
            console.error(e);
        })
    }
    useEffect(() => {
        clubId && getcardlist()
    }, [clubId])
    const [ownedAmount,setOwnedAmount] = useState(0)
    useEffect(()=>{
        let ownedA = 0;
        
        cardList?.map(item=>{
            const {cardAmount,holdCard} = item;
            if(holdCard>0){
                ownedA+=1
            }
        })
        setOwnedAmount(ownedA)
    },[cardList])
    return (
        <div className={`${s.wrap} flex_center_start_col`}>
            <div className={`${s.title} momentFont`}>
                {t('TGE Progress')}
            </div>
            <div className="w100p mt20 fs14 fw-400 momentFont color-moment-light-gray">
                <Trans
                    i18nKey="tgeProgressTips"
                    defaults="TGE will occur once <yellow>{{amount}}</yellow> moments have been sold."
                    values={{ amount: amount }}
                    components={{ yellow: <span className="color-yellow fs16"></span> }}
                ></Trans><br></br>
                <div className={s.tag}>
                    <Trans
                        i18nKey="Liquidity: All liquidity will be auto-added to the DEX, locked, and burnt once <white>{{amount}}</white> Moments are sold"
                        defaults="Liquidity: All liquidity will be auto-added to the DEX, locked, and burnt once <white>{{amount}}</white> Moments are sold"
                        values={{ amount: amount }}
                        components={{ white: <span className="color-white fs16"></span> }}
                    ></Trans>
                </div>
                <div className={s.tag}>
                {t('All Moments will be minted as NFTs.')}
                </div>
                <div className={s.tag}>
                {t('Users who hold Moments will get {{clubName}} based on the rarity of Moments',{clubName:`$`+clubName})}
                </div>

                <div className={`${s.moreBtn} flex_center_start`} onClick={() =>{
                    setShowMore(!showMore);
                }}>
                    <div className={`${s.moreBtnText}`}>
                        {
                            showMore ? 
                            t('Hide More Options') :
                            t('Show More Options')
                        }
                    </div>
                    <CustomIcon imgName={`Button/${showMore ? 'UI_Button_Arrow_Up_01':'UI_Button_Arrow_Down_01'}`} className={`${s.moreIcon}`} width={12} height={7}/>
                </div>
                
                {
                    showMore &&
                    <div className={s.card}>
                        <div className={s.labelWrap}>
                            <div className="flex_center_start">
                                <div className={s.labelItem}>
                                    <div className={s.labelMy}></div>
                                    <div className="fs10 momentFont color-moment-light-gray">{t('Owned: {{amount}}', { amount: ownedAmount })}</div>
                                </div>
                                <div className={s.labelItem}>
                                    <div className={s.labelOthers}></div>
                                    <div className="fs10 momentFont color-moment-light-gray">{t('Others: {{amount}}', { amount: curCardKindNum - ownedAmount })}</div>
                                </div>
                                <div className={s.labelItem}>
                                    <div className={s.labelNone}></div>
                                    <div className="fs10 momentFont color-moment-light-gray">{t('Remaining: {{amount}}', { amount: amount - curCardKindNum })}</div>
                                </div>
                            </div>
                            
                        </div>
                        <div className={s.cardList}>
                            {
                                cardList?.map(item => {
                                    const { cardNo, cardAmount, holdCard } = item;
                                    return (
                                        <div key={cardNo} className={`momentFont ${s.cardItem} ${cardAmount > 0 ? holdCard > 0 ? s.my : s.other : s.none}`}>{`#${cardNo}`}</div>
                                    )
                                })
                            }
                        </div>

                    </div>
                }
            </div>
            <CustomIcon 
                className={`${s.closeBtn}`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeHandle();
                }} />
        </div>
    )
}
export default memo(ModelMomentTgeProgressTips);
