import s from "./index.module.less";
import React, { useRef, useEffect, memo, useImperativeHandle } from "react";
import { parseGIF, decompressFrames } from "gifuct-js";

import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  BoxGeometry,
  BufferGeometry,
  ShaderMaterial,
  TextureLoader,
  Float32BufferAttribute,
  Points,
  Color,
  Vector2,
  Raycaster,
  CustomBlending,
  AddEquation,
  OneFactor,
  AdditiveBlending,
  DoubleSide,
  Vector3,
  ZeroFactor,
  LinearMipMapLinearFilter,
  NormalBlending,
  Object3D,
  MeshBasicMaterial,
  MeshLambertMaterial,
  SphereGeometry,
  Mesh,
  Group,
  Matrix4,
  DirectionalLight,
  DirectionalLightHelper,
  AnimationMixer,
  Cache,
  RepeatWrapping,
  Vector4,
  LoopOnce,
} from "three";

import * as THREE from "three";

import ParticleSystem, {
  Color as PsColor,
  Alpha,
  Body,
  BoxZone,
  Emitter,
  Gravity,
  Life,
  Mass,
  MeshRenderer,
  PointZone,
  Position,
  RadialVelocity,
  Radius,
  Rate,
  Rotate,
  Scale,
  Span,
  SphereZone,
  SpriteRenderer,
  System,
  Vector3D,
} from "three-nebula";

import { GLTFLoader } from "three/examples/jsm/Addons.js";
// import { DRACOLoader } from "three/examples/jsm/Addons.js"
import { lerp } from "three/src/math/MathUtils.js";

// Bloom
import { EffectComposer } from "three/addons/postprocessing/EffectComposer.js";
import { RenderPass } from "three/addons/postprocessing/RenderPass.js";
// import { UnrealBloomPass } from "three/addons/postprocessing/UnrealBloomPass.js"
import { BloomPass } from "./BloomPass.js";
import { OutputPass } from "three/addons/postprocessing/OutputPass.js";
// RGBShift
// import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js'
// import { RGBShiftShader } from 'three/addons/shaders/RGBShiftShader.js'
// import { mx_bits_to_01 } from "three/examples/jsm/nodes/materialx/lib/mx_noise.js"

const Index = React.forwardRef((props, ref) => {
  const canvasRef = useRef(null);
  // const video_section_ref = useRef(null)

  let renderer, scene, camera, animMixer;
  let animateActionGroups = [];
  let composer, bloomPass;
  let gltfRoot;

  let lineEffect;
  let lineMaterial;

  let ballEffect;
  let handEffect;

  let lightEffect;
  let lightMaterial;
  let cardBack01;
  let cardBack02;
  let cardBackMaterial;
  let alphaEffectController;

  const bloomPassParams = {
    threshold: 1.2,
    strength: 0.8,
    radius: 1.0,
    exposure: 1,
  };

  let created = false;

  let startTimeStamp = -1;

  // const nonePoint = new Vector3(9999, 9999, 9999)

  useImperativeHandle(ref, () => ({
    CreateUnpackScene,
    switchAnimationGroup,
    switchParticleEmit,
    disposeScene,
  }));

  useEffect(() => {
    return ()=>{
      console.log('[MemeUpackFx] cleaned up');
      disposeScene();
    }
  }, []);

  function traverseMaterials(scene, callback) {
    scene.traverse((obj) => {
      if (obj instanceof Mesh) {
        if (Array.isArray(obj.material)) {
          obj.material.forEach((mat) => callback(mat));
        } else {
          callback(obj.material);
        }
      }
    });
  }

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`load ${url} fail`));
      img.src = url;
    });
  };

  ////GIF PROCESSING/////
  let playing = false;
  let loadedFrames;
  let frameIndex;

  let gifCanvas;
  let gifCtx;

  let textureCtx;

  let tempCanvas;
  let tempCtx;

  let gifTokenName;

  function playpause() {
    playing = !playing;
    if (playing) {
      renderFrame();
    }
  }

  function renderGIF(frames) {
    loadedFrames = frames;
    frameIndex = 0;

    if (!gifCanvas){
      gifCanvas = document.createElement("canvas");
    }

    gifCanvas.width = frames[0].dims.width;
    gifCanvas.height = frames[0].dims.height;
    
    gifCtx = gifCanvas.getContext("2d");
    gifCtx.fillStyle = "white";
    gifCtx.fillRect(0, 0, gifCanvas.width, gifCanvas.height);

    if (!playing) {
      playpause();
    }
  }

  let frameImageData = null;

  function drawPatch(frame) {
    var dims = frame.dims;

    if (!tempCanvas) {
      tempCanvas = document.createElement("canvas");
      tempCtx = tempCanvas.getContext("2d");
    }

    if (
      !frameImageData ||
      dims.width != frameImageData.width ||
      dims.height != frameImageData.height
    ) {
      tempCanvas.width = dims.width;
      tempCanvas.height = dims.height;
      frameImageData = tempCtx.createImageData(dims.width, dims.height);
    }

    // set the patch data as an override
    frameImageData.data.set(frame.patch);

    // draw the patch back over the canvas
    tempCtx.putImageData(frameImageData, 0, 0);

    //gifCtx.fillRect(0,0,gifCanvas.width,gifCanvas.height);
    gifCtx.drawImage(tempCanvas, dims.left, dims.top);

    const canvasWidth = 512;
    const canvasHeight = 512;
    textureCtx.clearRect(0,0,canvasWidth,canvasHeight);

    const aspect = gifCanvas.width / gifCanvas.height;
    if (aspect > 1) {
      textureCtx.drawImage(
        gifCanvas,
        0.5 * (gifCanvas.width - gifCanvas.height),
        0,
        gifCanvas.height,
        gifCanvas.height,
        0,
        0,
        canvasWidth,
        canvasHeight
      );
    } else {
      textureCtx.drawImage(
        gifCanvas,
        0,
        0.5 * (gifCanvas.height - gifCanvas.width),
        gifCanvas.width,
        gifCanvas.width,
        0,
        0,
        canvasWidth,
        canvasHeight
      );
    }

    const fontSize = 54;
    textureCtx.font = `${fontSize}px ProtestStrikeRegular`;
    textureCtx.textAlign = "center";

    const measure = textureCtx.measureText(gifTokenName);
    if (measure.width > canvasWidth) {
      textureCtx.font = `${(fontSize * canvasWidth) /
        measure.width}px ProtestStrikeRegular`;
    }

    textureCtx.fillStyle = "white";
    textureCtx.strokeStyle = "rgb(0 0 0 / 80%)";
    textureCtx.lineWidth = 3;
    textureCtx.fillText(gifTokenName, 0.5 * canvasWidth, 0.78 * canvasHeight);
    textureCtx.strokeText(gifTokenName, 0.5 * canvasWidth, 0.78 * canvasHeight);

    if (canvasTexture){
      canvasTexture.needsUpdate = true;
    }
  }

  let needsDisposal = true;
  function renderFrame() {
    // get the frame

    let frame = loadedFrames[frameIndex];

    let start = new Date().getTime();

    if (needsDisposal) {
      gifCtx.fillRect(0, 0, gifCanvas.width, gifCanvas.height);
      needsDisposal = false;
    }

    // draw the patch
    drawPatch(frame);

    // update the frame index
    frameIndex++;
    if (frameIndex >= loadedFrames.length) {
      frameIndex = 0;
    }

    if (frame.disposalType === 2) {
      needsDisposal = true;
    }

    let end = new Date().getTime();
    let diff = end - start;

    if (playing) {
      // delay the next gif frame
      setTimeout(function() {
        requestAnimationFrame(renderFrame);
        //renderFrame();
      }, Math.max(0, Math.floor(frame.delay - diff)));
    }
  }

  ///////////////////////

  function CreateUnpackScene(imageURL, tokenName) {
    gifTokenName = tokenName;

    if (created && gltfRoot) {
      // Clear all
      if (cancelUpdateToken) {
        window.cancelAnimationFrame(cancelUpdateToken);
        cancelUpdateToken = null;
      }

      disposeScene();
    }

    if (!created) {
      created = true;

      const canvasWidth = 512;
      const canvasHeight = 512;

      if (imageURL?.slice(-4)?.toLowerCase() !== ".gif") {
        loadImage(imageURL)
          .then((image) => {
            const imageCanvas = createCanvas(canvasWidth, canvasHeight);
            const ctx = imageCanvas.getContext("2d");
            ctx.imageSmoothingEnabled = true;

            ctx.fillStyle = "blue";
            ctx.fillRect(0, 0, imageCanvas.width, imageCanvas.height);

            //drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
            //draw into full size of the square canvas
            const aspect = image.width / image.height;

            if (aspect > 1) {
              ctx.drawImage(
                image,
                0.5 * (image.width - image.height),
                0,
                image.height,
                image.height,
                0,
                0,
                canvasWidth,
                canvasHeight
              );
            } else {
              ctx.drawImage(
                image,
                0,
                0.5 * (image.height - image.width),
                image.width,
                image.width,
                0,
                0,
                canvasWidth,
                canvasHeight
              );
            }

            const fontSize = 54;
            ctx.font = `${fontSize}px ProtestStrikeRegular`;
            ctx.textAlign = "center";

            const measure = ctx.measureText(tokenName);
            if (measure.width > canvasWidth) {
              ctx.font = `${(fontSize * canvasWidth) /
                measure.width}px ProtestStrikeRegular`;
            }

            ctx.fillStyle = "white";
            ctx.strokeStyle = "rgb(0 0 0 / 80%)";
            ctx.lineWidth = 3;
            ctx.fillText(tokenName, 0.5 * canvasWidth, 0.78 * canvasHeight);
            ctx.strokeText(tokenName, 0.5 * canvasWidth, 0.78 * canvasHeight);

            //console.log({aspect, w: image.width, h: image.height});
            //console.log('[imageCanvas.toDataURL]',imageCanvas.toDataURL("image/jpeg", 0.1));

            loadGL(imageCanvas, tokenName);
          })
          .catch((e) => {
            console.error("[CreateUnpackScene exception]", imageURL, e);
            loadGL(null, tokenName);
          });
      } else {
        //GIF Animation
        try {
          const imageCanvas = createCanvas(canvasWidth, canvasHeight);
          const ctx = imageCanvas.getContext("2d");
          ctx.imageSmoothingEnabled = true;

          ctx.fillStyle = "blue";
          ctx.fillRect(0, 0, canvasWidth, canvasHeight);

          fetch(imageURL)
            .then((resp) => resp.arrayBuffer())
            .then((buff) => {
              var gif = parseGIF(buff);
              var frames = decompressFrames(gif, true);

              //console.log({frames});
              playing = false;
              textureCtx = ctx;
              renderGIF(frames);

              loadGL(imageCanvas, tokenName);
            })
            .catch((e) => {
              throw e;
            });
        } catch (e) {
          console.error("[CreateUnpackScene with GIF exception]", imageURL, e);
          loadGL(null, tokenName);
        }
      }
    }
  }

  const cleanMaterial = (material) => {
    // console.log('dispose material!')
    material.dispose();

    // dispose textures
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === "object" && "minFilter" in value) {
        // console.log('dispose texture!')
        value.dispose();
      }
    }
  };

  function disposeScene() {
    if (!renderer || !gltfRoot) {
      return;
    }

    created = false;

    if (composer) {
      composer.passes.forEach((pass) => {
        // console.log(pass)
        pass.dispose();
      });
      composer.dispose();
      composer = null;
    }

    if (canvasTexture) {
      canvasTexture.dispose();
      canvasTexture = null;
    }

    scene = null;
    composer = null;
    camera = null;
    animMixer = null;

    lineEffect = null;
    ballEffect = null;
    handEffect = null;
    lightEffect = null;
    cardBack01 = null;
    cardBack02 = null;

    renderer.setSize(1, 1, false);
    renderer.forceContextLoss();
    renderer.dispose();

    renderer = null;

    gltfRoot.scenes.forEach((scene) =>
      scene.traverse((object) => {
        if (!object.isMesh) return;

        // console.log('dispose geometry!')
        object.geometry.dispose();

        if (object.material.isMaterial) {
          cleanMaterial(object.material);
        } else {
          // an array of materials
          for (const material of object.material) cleanMaterial(material);
        }
      })
    );

    Cache.clear();
  }

  const PIXEL_RATIO = (function() {
    const ctx = document.createElement("canvas").getContext("2d"),
      dpr = window.devicePixelRatio || 1,
      bsr =
        ctx.webkitBackingStorePixelRatio ||
        ctx.mozBackingStorePixelRatio ||
        ctx.msBackingStorePixelRatio ||
        ctx.oBackingStorePixelRatio ||
        ctx.backingStorePixelRatio ||
        1;
    return dpr / bsr;
  })();

  const createCanvas = function(w, h, ratio) {
    if (!ratio) {
      ratio = PIXEL_RATIO;
    }
    var can = document.createElement("canvas");
    can.width = w * ratio;
    can.height = h * ratio;
    can.style.width = w + "px";
    can.style.height = h + "px";
    can.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
    return can;
  };

  function addTokenNameNode(scene, tokenName, fontSize) {
    const canvasWidth = 512,
      canvasHeight = 128;
    const textBitmap = createCanvas(canvasWidth, canvasHeight);

    const g = textBitmap.getContext("2d");
    g.font = `${fontSize}px ProtestStrikeRegular`;
    g.textAlign = "center";

    const measure = g.measureText(tokenName);
    if (measure.width > canvasWidth) {
      g.font = `${(fontSize * canvasWidth) /
        measure.width}px ProtestStrikeRegular`;
    }

    g.clearRect(0, 0, textBitmap.width, textBitmap.height);
    g.fillStyle = "white";
    g.strokeStyle = "rgb(0 0 0 / 80%)";
    g.lineWidth = 3;
    g.fillText(tokenName, 0.5 * canvasWidth, 0.5 * canvasHeight);
    g.strokeText(tokenName, 0.5 * canvasWidth, 0.5 * canvasHeight);

    const textTexture = new THREE.Texture(textBitmap);
    textTexture.colorSpace = THREE.SRGBColorSpace;
    textTexture.needsUpdate = true;

    const createMesh = ({ geometry, material }) => new Mesh(geometry, material);
    const nameTag = createMesh({
      geometry: new THREE.PlaneGeometry(32, 8),
      //material: new THREE.MeshBasicMaterial({map: new TextureLoader().load("img/3.jpg",(tex)=>{tex.colorSpace = THREE.SRGBColorSpace;}), transparent:true}),
      material: new THREE.MeshBasicMaterial({
        map: textTexture,
        transparent: true,
      }),
    });

    const packageObject = scene.children[3].children[0].children[1].children[0];

    packageObject.add(nameTag);
    nameTag.translateZ(0.1);
    nameTag.translateY(-15);
  }

  let particleSystem;
  let cameraForParticle;
  let canvasTexture;

  function buildScene(gltf, imageCanvas, tokenName) {
    gltfRoot = gltf;
    scene = gltf.scene;

    if (null == imageCanvas) {
      addTokenNameNode(scene, tokenName, 72);
    }

    scene.traverse((obj) => {
      if (obj instanceof Object3D) {
        obj.layers.set(1);
      }
    });

    camera = gltf.cameras[0];
    // camera.zoom = 2
    camera.layers.set(1); // default layer leave for particle fx

    const lightGroup = new Group();
    lightGroup.name = "lightGroup";
    lightGroup.position.x = 0;
    lightGroup.position.y = 0;
    lightGroup.position.z = 0;
    scene.add(lightGroup);

    const mainLight = new DirectionalLight(0xffffff, 12);
    mainLight.layers.set(1); // default layer leave for particle fx
    mainLight.position.y = 0;
    mainLight.target.translateOnAxis(new Vector3(-1, -1, -1), 1);
    lightGroup.add(mainLight);
    lightGroup.add(mainLight.target);

    let found = false;

    if (imageCanvas) {
      canvasTexture = new THREE.CanvasTexture(imageCanvas);
      canvasTexture.colorSpace = THREE.SRGBColorSpace;
      canvasTexture.needsUpdate = true;
      canvasTexture.flipY = false;

      traverseMaterials(scene, function(mat) {
        if (!found && mat.name.toLowerCase() === "card_load") {
          //console.log(mat.name);
          found = true;

          const formerMap = mat.map;
          mat.map = canvasTexture;
          if (formerMap) formerMap.dispose();
        }
      });
    }

    const lineEffectTex = new TextureLoader().load("img/LineEffectTex.webp");
    lineEffectTex.wrapS = RepeatWrapping;
    // lineEffectTex.wrapT = RepeatWrapping
    const lineEffectMaskTex = new TextureLoader().load(
      "img/LineEffectMaskTex.webp"
    );

    const whiteTex = new TextureLoader().load("img/White.webp");
    // EffectShader
    lineMaterial = new ShaderMaterial({
      uniforms: {
        effectTexture: { value: null },
        maskTexture: { value: null },
        color: { value: new Color(0xffa127) },
        intensity: { value: 4 },
        alpha: { value: 1.0 },
        effectST: { value: new Vector4(1, 2.0, 0.0, -0.5) },
        maskST: { value: new Vector4(1, 1.0, 0.0, 0) },
        uOffset: { value: -0.127 },
        vOffset: { value: 0 },
        time: { value: 0 },
      },
      vertexShader: document.getElementById("vertexshader").textContent,
      fragmentShader: document.getElementById("fragmentshader").textContent,
      blending: NormalBlending,
      transparent: true,
      depthWrite: false,
      side: DoubleSide,
    });

    const cardMaskMaterial = new ShaderMaterial({
      uniforms: {
        effectTexture: { value: whiteTex },
        maskTexture: { value: whiteTex },
        color: { value: new Color(0xffffff) },
        intensity: { value: 0.95 },
        alpha: { value: 0.6 },
        effectST: { value: new Vector4(1, -1.0, 0.0, 0) },
        maskST: { value: new Vector4(1, 1.0, 0.0, 0) },
        uOffset: { value: 0 },
        vOffset: { value: 0 },
        time: { value: 0 },
      },
      vertexShader: document.getElementById("vertexshader").textContent,
      fragmentShader: document.getElementById("fragmentshader").textContent,
      blending: NormalBlending,
      transparent: true,
      depthWrite: false,
    });

    const ballMaterial = new ShaderMaterial({
      uniforms: {
        effectTexture: { value: null },
        maskTexture: { value: null },
        color: { value: new Color(0xffa127) },
        intensity: { value: 4 },
        alpha: { value: 0 },
        effectST: { value: new Vector4(2, 2, 0.0, -0.5) },
        maskST: { value: new Vector4(2, 2.0, 0.0, -0.5) },
        uOffset: { value: 0 },
        vOffset: { value: 0 },
        time: { value: 0 },
      },
      vertexShader: document.getElementById("vertexshader").textContent,
      fragmentShader: document.getElementById("fragmentshader").textContent,
      blending: AdditiveBlending,
      transparent: true,
      depthWrite: false,
    });

    lightMaterial = new ShaderMaterial({
      uniforms: {
        effectTexture: { value: null },
        maskTexture: { value: null },
        color: { value: new Color(0xaaeeff) },
        intensity: { value: 1 },
        alpha: { value: 0.9 },
        effectST: { value: new Vector4(1, 1.0, 0.0, 0) },
        maskST: { value: new Vector4(1, 1.0, 0.0, 0) },
        uOffset: { value: 0 },
        vOffset: { value: 0 },
        time: { value: 0 },
      },
      vertexShader: document.getElementById("vertexshader").textContent,
      fragmentShader: document.getElementById("fragmentshader").textContent,
      blending: AdditiveBlending,
      transparent: true,
      depthWrite: false,
    });

    cardBackMaterial = new ShaderMaterial({
      uniforms: {
        effectTexture: { value: null },
        maskTexture: { value: whiteTex },
        color: { value: new Color(0xffffff) },
        intensity: { value: 0.9 },
        alpha: { value: 1.0 },
        effectST: { value: new Vector4(1, -1.0, 0.0, 0) },
        maskST: { value: new Vector4(1, 1.0, 0.0, 0) },
        uOffset: { value: 0 },
        vOffset: { value: 0 },
        time: { value: 0 },
      },
      vertexShader: document.getElementById("vertexshader").textContent,
      fragmentShader: document.getElementById("fragmentshader").textContent,
      blending: NormalBlending,
      transparent: true,
      depthWrite: false,
    });

    const topMask = scene.children[3].children[0].children[0].children[1];
    //console.log("[TopMask]", topMask);

    const cardMask = scene.children[3].children[0].children[1].children[1];
    //console.log("[CardMask]", cardMask);

    // const maskMap = topMask.material.map;
    // maskMap.flipY = false;
    // maskMap.colorSpace = THREE.SRGBColorSpace;
    // cardMaskMaterial.uniforms["effectTexture"].value = maskMap;
    // topMask.material = cardMaskMaterial;
    // cardMask.material = cardMaskMaterial;

    lineEffect = scene.children[2].children[2];
    ballEffect = scene.children[2].children[1];
    handEffect = scene.children[2].children[0];
    lightEffect = scene.children[1].children[2];
    cardBack01 = scene.children[1].children[1].children[1];
    cardBack02 = scene.children[1].children[1].children[0];

    alphaEffectController = scene.children[1].children[0];
    //console.log("[alphaEffectController]", alphaEffectController,"[lightEffect]", lightEffect);

    const lightEffectTex = new TextureLoader().load("img/LightTexture.webp");
    lineEffect.material = lineMaterial;
    lineMaterial.uniforms["effectTexture"].value = lineEffectTex;
    lineMaterial.uniforms["maskTexture"].value = lineEffectMaskTex;

    handEffect.material.color = new Color(0xeeeeee);

    ballEffect.material = ballMaterial;
    ballMaterial.uniforms["effectTexture"].value = lightEffectTex;
    ballMaterial.uniforms["maskTexture"].value = lightEffectTex;

    lightEffect.material = lightMaterial;
    lightMaterial.uniforms["effectTexture"].value = lightEffectTex;
    lightMaterial.uniforms["maskTexture"].value = lightEffectTex;

    const cardBackMap = cardBack01.material.map;
    cardBackMap.flipY = false;
    cardBackMaterial.uniforms["effectTexture"].value = cardBackMap;
    cardBack01.material = cardBackMaterial;
    cardBack02.material = cardBackMaterial;

    // const helper = new DirectionalLightHelper( mainLight, 5)
    // lightGroup.add( helper )

    // Particle Effects
    createParticleSystem();

    // Animation
    animMixer = new AnimationMixer(gltf.scene);
    animateActionGroups = [];

    const clips = gltf.animations;

    // clips.forEach((clip)=>{
    // 	animMixer.clipAction(clip).play()
    // })

    // Part-1
    animateActionGroups[0] = [animMixer.clipAction(clips[6])];

    // Part-2
    animateActionGroups[1] = [
      animMixer.clipAction(clips[3]),
      animMixer.clipAction(clips[4]),
      animMixer.clipAction(clips[5]),
    ];

    // Part-3
    animateActionGroups[2] = [
      animMixer.clipAction(clips[0]),
      animMixer.clipAction(clips[1]),
      animMixer.clipAction(clips[2]),
      animMixer.clipAction(clips[7]),
      animMixer.clipAction(clips[8]),
    ];
    animateActionGroups[2].forEach((action) => {
      action.loop = LoopOnce;
      action.clampWhenFinished = true;
    });

    switchAnimationGroup(0);

    renderer = new WebGLRenderer({ alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x0060ee, 1);

    //const canvasStyle = window.getComputedStyle(canvasRef.current);
    const rect = canvasRef.current.getBoundingClientRect();
    renderer.setSize(rect.width, rect.height);

    // Post Processing
    composer = new EffectComposer(renderer);

    const renderFx = new RenderPass(scene, cameraForParticle);
    composer.addPass(renderFx);

    const renderScene = new RenderPass(scene, camera);
    renderScene.clear = false;
    renderScene.clearDepth = true;
    composer.addPass(renderScene);

    // Bloom Params
    bloomPass = new BloomPass(
      new Vector2(window.innerWidth, window.innerHeight),
      1.5,
      0.4,
      0.85,
      window.devicePixelRatio
    );
    bloomPass.threshold = bloomPassParams.threshold;
    bloomPass.strength = bloomPassParams.strength;
    bloomPass.radius = bloomPassParams.radius;
    composer.addPass(bloomPass);

    const outputPass = new OutputPass();
    composer.addPass(outputPass);

    // RGBShift
    // const effect = new ShaderPass( RGBShiftShader )
    // effect.uniforms[ 'amount' ].value = 0.0004
    // composer.addPass( effect )

    onWindowResize();
    window.addEventListener("resize", onWindowResize);

    // console.log('[scene]', scene)
  }

  function createParticleSystem() {
    cameraForParticle = new PerspectiveCamera(
      100,
      window.innerWidth / window.innerHeight,
      0.01,
      200
    );
    cameraForParticle.layers.set(0);

    cameraForParticle.position.x = 100;
    cameraForParticle.position.y = 0;
    cameraForParticle.position.z = 0;
    cameraForParticle.rotateY(0.5 * Math.PI);

    const particleGroup = new Group();
    particleGroup.name = "particleGroup";
    particleGroup.position.x = 0;
    particleGroup.position.y = 0;
    particleGroup.position.z = 0;
    scene.add(particleGroup);

    const createMesh = ({ geometry, material }) => new Mesh(geometry, material);

    const zone = new SphereZone(0, 0, 0, 30);
    zone.getPosition = (function() {
      var tha, phi, r;

      return function() {
        this.random = Math.random();

        r = (0.4 + 0.8 * this.random) * this.radius;

        // console.log(r)
        tha = Math.PI * Math.random(); // [0-pi]
        phi = Math.PI * 2 * Math.random(); // [0-2pi]

        this.vector.x = this.x + r * Math.sin(tha) * Math.cos(phi);
        this.vector.y = this.y + r * Math.sin(phi) * Math.sin(tha);
        this.vector.z = this.z + r * Math.cos(tha);

        return this.vector;
      };
    })();

    const tunnel = new Position();
    tunnel.initialize = (particle) => {
      const tg = particle.position.y / particle.position.z;
      const theta = Math.atan(tg);

      particle.rotation.x = theta;
    };

    const createEmitter = ({ position, body }) => {
      const emitter = new Emitter();

      return emitter
        .setRate(new Rate(new Span(8, 10), new Span(0.1, 0.25)))
        .addInitializers([
          // new Mass(1),
          new Radius(new Span(0.5, 1.0)),
          new Life(2, 3),
          new Body(body),
          new Position(zone),
          new RadialVelocity(110, new Vector3D(1, 0, 0), 0),
          tunnel,
        ])
        .addBehaviours([
          // new Rotate('random', 'random'),
          // new Scale(1, 0.1),
          // new Gravity(3),
          //   new PsColor(0xffc600, 0xffc600),
          new Alpha(new Span(0.1, 1)),
        ])
        .setPosition(position)
        .emit(0);
    };

    const lineEffectTex = new TextureLoader().load("img/LineEffectTex.webp");
    lineEffectTex.wrapS = RepeatWrapping;

    const blueQEmitter = createEmitter({
      position: {
        x: 0,
        y: 0,
      },
      body: createMesh({
        geometry: new THREE.PlaneGeometry(30, 2.5),
        material: new ShaderMaterial({
          uniforms: {
            effectTexture: { value: lineEffectTex },
            maskTexture: { value: lineEffectTex },
            color: { value: new Color(0x64eeff) },
            intensity: { value: 4.0 },
            alpha: { value: 1.0 },
            effectST: { value: new Vector4(1, 1.0, 0.0, 0) },
            uOffset: { value: 0 },
            vOffset: { value: 0 },
            time: { value: 0 },
          },
          vertexShader: document.getElementById("vertexshader").textContent,
          fragmentShader: document.getElementById("fragmentshader").textContent,
          side: THREE.DoubleSide,
          blending: NormalBlending,
          transparent: true,
          depthWrite: false,
        }),
      }),
    });

    const purpleQEmitter = createEmitter({
      position: {
        x: 0,
        y: 0,
      },
      body: createMesh({
        geometry: new THREE.PlaneGeometry(30, 2.5),
        material: new ShaderMaterial({
          uniforms: {
            effectTexture: { value: lineEffectTex },
            maskTexture: { value: lineEffectTex },
            color: { value: new Color(0x6000ff) },
            intensity: { value: 18.0 },
            alpha: { value: 1.0 },
            effectST: { value: new Vector4(1, 1.0, 0.0, 0) },
            uOffset: { value: 0 },
            vOffset: { value: 0 },
            time: { value: 0 },
          },
          vertexShader: document.getElementById("vertexshader").textContent,
          fragmentShader: document.getElementById("fragmentshader").textContent,
          side: THREE.DoubleSide,
          blending: NormalBlending,
          transparent: true,
          depthWrite: false,
        }),
      }),
    });

    const goldenQEmitter = createEmitter({
      position: {
        x: 0,
        y: 0,
      },
      body: createMesh({
        geometry: new THREE.PlaneGeometry(30, 2.5),
        material: new ShaderMaterial({
          uniforms: {
            effectTexture: { value: lineEffectTex },
            maskTexture: { value: lineEffectTex },
            color: { value: new Color(0xff9c00) },
            intensity: { value: 8.0 },
            alpha: { value: 1.0 },
            effectST: { value: new Vector4(1, 1.0, 0.0, 0) },
            uOffset: { value: 0 },
            vOffset: { value: 0 },
            time: { value: 0 },
          },
          vertexShader: document.getElementById("vertexshader").textContent,
          fragmentShader: document.getElementById("fragmentshader").textContent,
          side: THREE.DoubleSide,
          blending: NormalBlending,
          transparent: true,
          depthWrite: false,
        }),
      }),
    });

    const createSparkEmitter = ({ position, body, radius }) => {
      const emitter = new Emitter();

      return emitter
        .setRate(new Rate(new Span(2, 3), new Span(0.1, 0.2)))
        .addInitializers([
          // new Mass(1),
          new Radius(new Span(0.2, 1)),
          new Life(4, 6),
          new Body(body),
          new Position(zone),
          new RadialVelocity(radius, new Vector3D(1, 0, 0), 0),
        ])
        .addBehaviours([
          // new Rotate('random', 'random'),
          // new Scale(1, 0.1),
          // new Gravity(3),
          new Alpha(new Span(0.8, 1)),
        ])
        .setPosition(position)
        .emit();
    };

    const spark1Emitter = createSparkEmitter({
      position: {
        x: 0,
        y: 0,
      },
      body: createMesh({
        geometry: new THREE.SphereGeometry(0.3),
        material: new THREE.MeshBasicMaterial({
          color: 0xffffff,
          side: THREE.DoubleSide,
          blending: AdditiveBlending,
          transparent: true,
          depthWrite: false,
        }),
      }),
      radius: 80,
    });

    const spark2Emitter = createSparkEmitter({
      position: {
        x: 0,
        y: 0,
      },
      body: createMesh({
        geometry: new THREE.SphereGeometry(0.2),
        material: new THREE.MeshBasicMaterial({
          color: 0x64e0ff,
          side: THREE.DoubleSide,
          blending: AdditiveBlending,
          transparent: true,
          depthWrite: false,
        }),
      }),
      radius: 40,
    });

    const meshParticleRenderer = new MeshRenderer(particleGroup, THREE);

    particleSystem = new System();
    particleSystem
      .addEmitter(blueQEmitter)
      .addEmitter(spark1Emitter)
      .addEmitter(spark2Emitter)
      .addEmitter(purpleQEmitter)
      .addEmitter(goldenQEmitter)
      .addRenderer(meshParticleRenderer)
      .emit(
        () => {},
        () => {},
        () => {}
      );
  }

  function switchParticleEmit(stage) {
    if (null == particleSystem) {
      console.error("[switchParticleEmit] particleSystem is null");
      return;
    }
    console.log(`[switchParticleEmit] stage[${stage}]`);

    particleSystem.emitters.forEach((emt) => {
      emt.setTotalEmitTimes(0);
    });

    switch (stage) {
      case 0:
        //未开状态，低品质通用
        particleSystem.emitters[1]?.setTotalEmitTimes(Infinity);
        particleSystem.emitters[2]?.setTotalEmitTimes(Infinity);
        break;

      case 1:
        //金色传说
        particleSystem.emitters[4]?.setTotalEmitTimes(Infinity);
        particleSystem.emitters[1]?.setTotalEmitTimes(Infinity);
        particleSystem.emitters[2]?.setTotalEmitTimes(Infinity);
        break;

      case 2:
        //紫色
        particleSystem.emitters[3]?.setTotalEmitTimes(Infinity);
        particleSystem.emitters[1]?.setTotalEmitTimes(Infinity);
        particleSystem.emitters[2]?.setTotalEmitTimes(Infinity);
        break;

      case 3:
        //蓝色
        particleSystem.emitters[0]?.setTotalEmitTimes(Infinity);
        particleSystem.emitters[1]?.setTotalEmitTimes(Infinity);
        particleSystem.emitters[2]?.setTotalEmitTimes(Infinity);
        break;

      default:
        console.warn(
          `[switchParticleEmit] unknown stage[${stage}] all emitters stopped.`
        );
        break;
    }
  }

  function loadGL(imageCanvas, tokenName) {
    const q = canvasRef.current;
    if (q && q.children.length > 0) {
      q.children[0].remove();
    }

    const modelLoader = new GLTFLoader();
    modelLoader.load(
      "model/memeCard.glb",
      function(gltf) {
        // console.log("[GLB]", gltf);
        // gltf.scene.scale.z = 2
        buildScene(gltf, imageCanvas, tokenName);
        if (renderer) {
          q.appendChild(renderer.domElement);
          animate(0);
        }
      },
      undefined,
      function(error) {
        console.error(error);
      }
    );
  }

  function onWindowResize() {
    if (!canvasRef.current) {
      return;
    }

    const rect = canvasRef.current.getBoundingClientRect();

    if (bloomPass) {
      bloomPass.compositeMaterial.uniforms["windowHeight"].value = rect.height;
      bloomPass.compositeMaterial.uniforms["windowWidth"].value = rect.width;
    }

    if (camera) {
      if (camera.isOrthographicCamera) {
        const aspect = rect.width / rect.height;
        camera.left = -aspect * 0.5;
        camera.right = aspect * 0.5;
      } else {
        camera.aspect = rect.width / rect.height;
      }
      camera.updateProjectionMatrix();
      camera.updateMatrix();
    }

    if (cameraForParticle) {
      cameraForParticle.aspect = rect.width / rect.height;
      cameraForParticle.updateProjectionMatrix();
      cameraForParticle.updateMatrix();
    }

    if (renderer) renderer.setSize(rect.width, rect.height);
    if (composer) composer.setSize(rect.width, rect.height);
  }

  // let currentAnimGroupId = 0
  function switchAnimationGroup(groupId) {
    if (!animMixer) {
      console.error(`[switchAnimationGroup] is null`);
      return;
    }

    console.log("[switchAnimationGroup]", groupId);
    animMixer.stopAllAction();

    animateActionGroups[groupId].forEach((action) => {
      action.play();
    });

    switch (groupId) {
      case 1:
        lineEffect.visible = true;
        ballEffect.visible = true;
        handEffect.visible = true;
        lightEffect.visible = false;
        break;
      case 2:
        lineEffect.visible = false;
        ballEffect.visible = false;
        handEffect.visible = false;
        lightEffect.visible = true;
        break;
      default:
        lineEffect.visible = false;
        ballEffect.visible = false;
        handEffect.visible = false;
        lightEffect.visible = false;
        break;
    }
  }

  let cancelUpdateToken = null;

  function animate(timeStamp) {
    if (startTimeStamp < 0) {
      startTimeStamp = timeStamp;
    }
    const elapsed = timeStamp - startTimeStamp;

    if (animMixer) {
      animMixer.update(elapsed * 0.001);
    }

    if (particleSystem) {
      particleSystem.update(elapsed * 0.001);
      // console.log('[particleSystem getCount]', particleSystem.getCount())
    }

    if (lineEffect) {
      if (lineEffect.material === lineMaterial) {
        lineEffect.material.uniforms["time"].value += elapsed * 0.006;
      }
    }

    if (alphaEffectController) {
      if (lightEffect) {
        if (lightEffect.material == lightMaterial) {
          lightEffect.material.uniforms["alpha"].value =
            alphaEffectController.position.y;
          //console.log("[Alpha]", alphaEffectController.position.y);
        }
      }

      if (cardBack01 && cardBack02) {
        if (cardBack01.material == cardBackMaterial) {
          cardBack01.material.uniforms["alpha"].value =
            alphaEffectController.position.y * 10 - 7;
        }
      }
    }

    startTimeStamp = timeStamp;
    render();

    if (cancelUpdateToken) window.cancelAnimationFrame(cancelUpdateToken);
    cancelUpdateToken = window.requestAnimationFrame(animate);
  }

  function render() {
    if (!scene || !camera || !composer) {
      return;
    }

    composer.render(scene, camera);
  }

  return (
    <div className={`${s.root}`}>
      <div
        className={s.rt}
        ref={canvasRef}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
});

export default memo(Index);
