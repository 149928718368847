import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {connect} from "react-redux";
import {useTranslation, Trans} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import AntButton from "@/common/AntButton";
import CustomIcon from "../../common/CustomIcon";
import {AutoStrangeRule} from "@/utils/strangeRule";
import {
    convertWalletBalance, 
    numberClamp, 
    getChainTokenInfoById,
} from "@/utils/common";
import { 
    coinList,
    getMomentKingTgeWinnerList,
    setMomentTgeNoticeRead,
    getMomentTgeNoticeList,
    momentClubInfo,
} from "@/utils/momentClub";
import {
    gameMomentClubDetailPath,
} from "@/routes/config";
import ButtonFactory from "@/common/ButtonFactory";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Slider, Checkbox, Select, Spin} from "antd";

const { Option } = Select;

const ModalMomentAssetNewPack = (
    {
        closeHandle,
        setCurrentMomentClubId,
        momentAssetUnpackList,
        setMomentAssetUnpackList,
        momentAssetUnpackListQueryLastTime,
        setMomentAssetUnpackListQueryLastTime,
        setSwitchVideoType,
        setSwitchVideoList,
        setSwitchVideoListCursor,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [getClubLock, setGetClubLock] = useState(false);

    const showList = useMemo(() =>{
        if (momentAssetUnpackList?.length > 0){
            return [...momentAssetUnpackList];
        }
        return [];
    }, [momentAssetUnpackList]);

    const updateUnpackList = () =>{
        getMomentTgeNoticeList().then(res => {
            if (res?.noticeList?.length > 0){
                setMomentAssetUnpackList(res?.noticeList);
            }
            else{
                setMomentAssetUnpackList(null);
            }
            let nowTime = new Date().getTime();
            setMomentAssetUnpackListQueryLastTime(nowTime);
        }).catch(e => {
            console.error(e);
        })
    }
    
    const setAllRead = () => {
        let clubUserIds = [];
        if (momentAssetUnpackList?.length > 0){
            for (let i = 0; i < momentAssetUnpackList?.length; i++){
                clubUserIds.push(momentAssetUnpackList[i].clubUserId);
            }
        }
        if (clubUserIds?.length > 0){
            setMomentTgeNoticeRead(clubUserIds).then(res => {
                updateUnpackList();
            }).catch(e => {
                console.error(e);
            })
        }
    }

    const setOneRead = (clubUserId) =>{
        let clubUserIds = [clubUserId];
        setMomentTgeNoticeRead(clubUserIds).then(res => {
            updateUnpackList();
        }).catch(e => {
            console.error(e);
        })
    }

    const getClubInfoAndRedirect = (clubId) => {
        if (getClubLock){
            return;
        }
        setGetClubLock(true);
        momentClubInfo(clubId).then(res => {
            setGetClubLock(false);
            if (res){
                const {
                    clubInfo,
                    clubUserId,
                    momentAuditCode,
                } = res || {};
                const {
                    videoId,
                    coverUrl,
                    videoUrlMap,
                    videoOriHeight,
                    videoOriWidth,
                    videoFirstFrameUrl,
                } = clubInfo || {};
                setCurrentMomentClubId(clubUserId);
                setSwitchVideoType(-1);
                let _list = [];
                _list.push({
                    clubId: clubUserId,
                    momentAuditCode: momentAuditCode,
                    videoId: videoId,
                    videoUrlMap: videoUrlMap,
                    coverUrl: coverUrl,
                    videoOriHeight: videoOriHeight,
                    videoOriWidth: videoOriWidth,
                    videoFirstFrameUrl: videoFirstFrameUrl,
                });
                setSwitchVideoList(_list);
                setSwitchVideoListCursor("-1");
                navigate(`${gameMomentClubDetailPath}`);
            }
        }).catch(e => {
            console.error(e);
            setGetClubLock(false);
        })
    }

    return (
        <div className={`${s.wrap} flex_center_start_col`}>
            <div className={`${s.title} flex_center_center_col`}>
                <div className={`${s.titleText}`}>
                    {t('Notification')}
                </div> 
            </div>
            <div className={s.contentPart}>
                <div className={`${s.content} flex_center_start_col`}>
                    {
                        showList?.length > 0 ?
                        <>
                            {
                                showList?.map((item) =>{
                                    const {
                                        clubUserId,
                                        clubUserName,
                                        holdNum,
                                        isRead,
                                        timestamp,
                                    } = item || {};
                                    return (
                                        <div key={clubUserId} className={`${s.notificationItem} flex_center_start_col`} onClick={() =>{
                                            if (!isRead){
                                                setOneRead(clubUserId);
                                            }
                                            getClubInfoAndRedirect(clubUserId);
                                        }}>
                                            <div className={`${s.achievedText}`}>
                                                <span className="momentFont fs12 color-EEEEEE fb">
                                                    {clubUserName}&nbsp;
                                                </span>
                                                <span className="momentFont fs12 color-moment-gray fw-400">
                                                    {t('achieved TGE prgress')}
                                                </span>
                                            </div>
                                            <div className={`${s.unpackText} mt5 momentFont fs12 color-moment-gray fw-400`}>
                                                <Trans
                                                    i18nKey="You have <yellow>{{count}} Packs</yellow> to unpack 12"
                                                    defaults="You have <yellow>{{count}} Packs</yellow> to unpack"
                                                    values={{
                                                        count: holdNum
                                                    }}
                                                    components={{
                                                        yellow: <span className="momentFont color-EEEEEE fs12 fb"></span> 
                                                    }}
                                                />
                                            </div>
                                            {
                                                !isRead && 
                                                <div className={`${s.newRoot} flex_center_center`}>
                                                    <span className="momentFont fs10 fb color-gray">
                                                        {AutoStrangeRule(t('NEW'))}
                                                    </span>
                                                </div>
                                            }
                                            <div className={`${s.tgeTimeRoot} flex_center_end`}>
                                                {(new Date(timestamp * 1000)).format("yyyy/MM/dd hh:mm")}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </> :
                        <div className={`${s.listNoData} flex_center_center`}>
                            <CustomIcon width={111} height={95} className={`mb30`} imgName={'Picture/UI_Picture_NoData_Empty_02'}></CustomIcon>
                            <div className="mt20 fs18 momentFont fw-400" style={{color: `#555555`}}>{t('No Data')}</div>
                        </div>
                    }
                </div>
            </div>
            <AntButton 
                className={`${s.setBtn} btn_public`} 
                onClick={() => {
                    setAllRead();
                }}>
                <div className={`momentFont fs16 fb color-gray lh1`}>
                    {t('Set All as Read')}
                </div>
            </AntButton>
            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeHandle();
                }} />
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        momentAssetUnpackList: app.momentAssetUnpackList,
        momentAssetUnpackListQueryLastTime: app.momentAssetUnpackListQueryLastTime,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMomentAssetUnpackList: (params) => {
            return dispatch({
                type: "app/setMomentAssetUnpackList",
                payload: params,
            });
        },
        setMomentAssetUnpackListQueryLastTime: (params) => {
            return dispatch({
                type: "app/setMomentAssetUnpackListQueryLastTime",
                payload: params,
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
        setSwitchVideoType: (params) => {
            return dispatch({
                type: "app/setSwitchVideoType",
                payload: params,
            });
        },
        setMemeclubSwitchVideoParams: (params) => {
            return dispatch({
                type: "app/setMemeclubSwitchVideoParams",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalMomentAssetNewPack));
