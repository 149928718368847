import React, {memo, useState, useMemo, useEffect} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Tooltip, Modal, Button} from "antd";
import ModelTrans from "../ModelTrans";
import {
    stringToNumber, unit, getProfile, displayFilter,
    getAvatarFromUserInfo
} from "@/utils/common";
import AvatarInfo from "../../common/AvatarInfo";
import CustomIcon from "../../common/CustomIcon";
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";

const ModelTransaction = (
    {
        curentOtherUserInfo,
        accountType = 'key',
    }
) => {
    const {t, i18n} = useTranslation();
    const [showTransModel, setShowTransModel] = useState(false);
    const [type, setType] = useState(1);
    const [done, setDone] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const {buyPriceObj, sellPriceObj, userId, youOwnKeyNum, holdersNum, holding, platformData} = useMemo(() => {
        return curentOtherUserInfo || {}
    }, [curentOtherUserInfo]);

    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(curentOtherUserInfo);
    }, [platformData, curentOtherUserInfo])

    const showTransModelFn = (type) => {
        setType(type)
        setShowTransModel(true)
    }

    return (
        <div className={s.wrap}>
            <div className={s.info}>
                <AvatarInfo size={70} noBorder={true} src={originalAvatarUrl || avatarUrl}/>
                <div className={s.item}>
                    <span className="fs18 fb color-white mr5">{displayFilter(profile?.displayName)} </span>
                    {profile?.profileDeepLink &&
                        <CustomIcon width={16} height={16} imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                    name={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                </div>
                <div className="fs16 color-blue-light mb10">
                    <Trans
                        i18nKey="You own num key/keys/wish/wishes"
                        defaults="You own <active>{{count}} {{sth}}</active>"
                        values={{
                            count: youOwnKeyNum,
                            sth: accountType === 'wish' ? youOwnKeyNum > 1 ? 'wishes' : 'wish' : youOwnKeyNum > 1 ? t('keys') : t('key')
                        }}
                        components={{active: <span style={{color: 'var(--TP-yellow)'}}></span>}}
                    />
                </div>
                <div className="fs18 fb color-white">{stringToNumber(buyPriceObj?.keyPrice, 4)}&nbsp;
                    <span className="color-blue-light">{unit}</span></div>
            </div>
            <ButtonFactory onClick={() => {
                showTransModelFn(1)
            }}>
                {t('BUY xx', {sth: accountType === 'key' ? 'CARD' : 'WISH'})}
            </ButtonFactory>
            <ButtonFactory color={3} disabled={!youOwnKeyNum}
                           isMirror={true}
                           onClick={() => {
                               youOwnKeyNum && showTransModelFn(2)
                           }}
                           className={"mt5"}
            >
                {t('SELL xx', {sth: accountType === 'key' ? 'CARD' : 'WISH'})}
            </ButtonFactory>
            <div className={`${s.pricemsg} color-blue-light`}>
                {t('Sell Price')}: <span
                className="color-white">{stringToNumber(sellPriceObj?.keyPrice, 4)}</span>&nbsp;
                <span
                    className="color-blue-light">$MATIC</span>
            </div>

            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={showTransModel}
                destroyOnClose={true}
                zIndex={4000}
                onOk={() => setShowTransModel(false)}
                onCancel={() => setShowTransModel(false)}
                keyboard={!subLoading || done}
                maskClosable={!subLoading || done}
            >
                <ModelTrans
                    type={type}
                    keyId={userId}
                    profile={profile}
                    closeHandle={() => {
                        setShowTransModel(false)
                    }}
                    accountType={accountType}
                    done={done}
                    setDone={setDone}
                    subLoading={subLoading}
                    setSubLoading={setSubLoading}
                />
            </AntModal>


        </div>


    )
}
export default memo(ModelTransaction);
