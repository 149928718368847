import React, {memo, useState, useMemo, useEffect, useRef} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {
    unit, referralcode_earndata, stringToNumber, referralcode_regen, copyFn,
    getearninfo, getProfile,
    referralcode_inviteuser,
    displayFilter,
    getAvatarFromProfile,
    getAvatarFromUserInfo, convertWalletBalance, vCoinString, getTransactionErrorMsgId
} from "@/utils/common";

import AvatarInfo from "../../../common/AvatarInfo";
import CustomIcon from "../../../common/CustomIcon";
import {Spin, Modal, Button, Tabs} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate} from "react-router-dom";
import ModalExtraLife from '@/model/ModelExtraLife'
import ModalMultiplier from '@/model/ModelMultiplier'
import Achievements from '@/utils/json/Achievements.json'
import ModelReferralStakeTips from "src/model/ModelReferralStakeTips";
import ModelReferralStakeMultiplierTips from "src/model/ModelReferralStakeMultiplierTips";
import {lfgStakeBalanceOf} from "@/utils/lfgStake";
import {useWallets} from "@privy-io/react-auth";
import StakeBonusMultiplier from '@/utils/json/StakeBonusMultiplier.json';
import {
    post_referral_stake_get_reward,
    post_referralcode_stakelfg,
    secondsToDays,
    secondsToHours
} from "@/utils/referralStake";
import Countdown from "src/common/Countdown";
import ModelApplyMint from "@/model/ModelApplyMint";
import ModelReferralStakeBounsTips from "src/model/ModelReferralStakeBounsTips";
import {applyMint} from "@/utils/lfgMintWeb3";
import {publicChatsPath} from "@/routes/config";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import {AutoStrangeRule} from "@/utils/strangeRule";
import AntTabPane from "@/common/AntTabPane";
import ModelReferralShare from "@/model/ModelReferralShare";
import ButtonFactory from "@/common/ButtonFactory";
import {
    getReferralcodeMemetradeReflist,
    getMemeTradevolInfo,
} from "@/utils/memeClub";
import { 
    getReferralcodeMomenttradeReflist,
    getMomentTradevolInfo 
} from "@/utils/momentClub";

const INVITE_WARP_ID = 'inviteWrapId';
const ReferralEarnGroup = (
    {
        inviteCode,
        children,
        userInfo
    }
) => {
    const {t, i18n} = useTranslation();
    const TabPane = Tabs.TabPane;

    const {
        platformData, userId, selfData: {
            multiplyCardNum,
            nextAchieveIndex
        }
    } = userInfo
    const selfProfile = getProfile(platformData);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [earnData, setEarnData] = useState(null);
    const [tradeDataList, setTradeDataList] = useState(null);
    //const [extraLifeModal, setExtraLifeModal] = useState(false)
    //const [multiplierModal, setMultiplierModal] = useState(false)
    const [totalPortfolio, setTotalPortfolio] = useState(0)
    const [portfolio, setPortfolio] = useState(0)
    const [isShowArrow, setIsShowArrow] = useState(true)
    const [inviteUser, setInviteUser] = useState(null)
    const [tradevolInfo, setTradevolInfo] = useState(null);
    const [groupType, setGroupType] = useState(0);//0:DNA; 1:club
    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo);
    }, [platformData, userInfo])

    const reInviteCode = useRef('');
    /*const refresh = () => {
        referralcode_regen().then(res => {
            reInviteCode.current = res?.referralCode
        }).catch(e => {

        })
    }*/

    const nextCursor = useMemo(() => {
        return earnData ? earnData?.nextCursor : -1
    }, [earnData])

    //const [claimFailed, setClaimFailed] = useState(false)
    //const [claimSuccess, setClaimSuccess] = useState(false)
    //const [claimDone, setClaimDone] = useState(false)

    const updateMemeTradevolInfo = () => {
        /*getMomentTradevolInfo().then(res => {
            setTradevolInfo(res);
        }).catch(e => {
            console.error("get MomentTradevolInfo error", e);
        })*/
        getMemeTradevolInfo().then(res => {
            setTradevolInfo(res);
        }).catch(e => {
            console.error("get MemeTradevolInfo error", e);
        })
    }

    const getEarnData = (cursor = 0) => {
        referralcode_earndata(cursor).then(res => {
            
            if(cursor === 0){
                setEarnData(res);
            }else{
                setEarnData({
                    ...res,
                    referralUserList: [
                        ...earnData?.referralUserList,
                        ...res?.referralUserList
                    ]
                })
            }
            
            setLoading(false)
            //setClaimDone(false)
            //setClaimFailed(false)
            //setClaimSuccess(false)
        }).catch(e => {
            setLoading(false)
        })
    }

    /*const getMomentTradeList = (cursor = 0) => {
        getReferralcodeMomenttradeReflist(cursor).then(res => {
            if(cursor === 0){
                setTradeDataList(res);
            }else{
                setTradeDataList({
                    ...res,
                    dataList: [
                        ...tradeDataList?.dataList,
                        ...res?.dataList
                    ]
                })
            }
            setLoading(false);
        }).catch(e => {
            console.error("get ReferralcodeMomenttradeReflist error", e);
            setLoading(false);
        })
    }*/

    const getMemeTradeList = (cursor = 0) => {
        getReferralcodeMemetradeReflist(cursor).then(res => {
            if(cursor === 0){
                setTradeDataList(res);
            }else{
                setTradeDataList({
                    ...res,
                    dataList: [
                        ...tradeDataList?.dataList,
                        ...res?.dataList
                    ]
                })
            }
            setLoading(false);
        }).catch(e => {
            console.error("get ReferralcodeMemetradeReflist error", e);
            setLoading(false);
        })
    }

    const getearninfoFn = () => {
        getearninfo().then(res => {
            setTotalPortfolio(res.totalPortfolio)
            setPortfolio(res.portfolio)
        }).catch(e => {
            console.log(e);
        })
    }

    const getInviteuser = (cursor = 0) => {
        referralcode_inviteuser(cursor).then(res => {
            res.userId !== 0 && setInviteUser(res)
        }).catch(e => {

        })
    }

    useEffect(() => {
        getearninfoFn()
        getInviteuser()
    }, [])

    useEffect(() =>{
        if (groupType === 1){
            getEarnData();
        }
        else{
            getMemeTradeList();
            updateMemeTradevolInfo();
        }
    }, [groupType]);

    const list = useMemo(() => {
        return earnData?.referralUserList || []
        // return [{profile:{}},{profile:{}},{profile:{}},]
    }, [earnData])

    const tradeList = useMemo(() => {
        return tradeDataList?.dataList || []
    }, [tradeDataList])

    /*const stakeActivity = useMemo(() => {
        return earnData?.stakeActivity || {}
    }, [earnData])*/

    const showEarn = useMemo(() => {
        return earnData?.showEarn || false
    }, [earnData])

    const getMore = () => {
        getEarnData(nextCursor);
    }

    const getMoreMemeTrade = () =>{
        getMemeTradeList(nextCursor);
    }

    const showArrow = (scroll) => {
        if ((list.length - 2) * 60 <= scroll) {
            setIsShowArrow(false)
        } else {
            setIsShowArrow(true)
        }
    }

    const [tipsModal, setTipsModal] = useState(false)
    //const [tipsMultiplierModal, setTipsMultiplierModal] = useState(false)
    //const [tipsBounsModal, setTipsBounsModal] = useState(false)

    const [lfgStakeBalance, setLfgStakeBalance] = useState(0)
    //const [openSharePanel, setOpenSharePanel] = useState(false);

    const refreshStakeInfo = () => {
        lfgStakeBalanceOf(wallets).then(
            res => {
                setLfgStakeBalance(res)
            }
        )
    }

    const {wallets} = useWallets();

    useEffect(() => {
        if (wallets.length > 0) {
            refreshStakeInfo();
        }
    }, [wallets])

    /*const currMultiplierLevel = useMemo(() => {
        let level = {}
        StakeBonusMultiplier.map((item, index) => {
            if (item.stakedMin <= lfgStakeBalance && item.stakedMax > lfgStakeBalance) {
                level = item
            }
        })
        return level
    }, [lfgStakeBalance])*/

    //const [claimLoading, setClaimLoading] = useState(false)

    /*const doClaim = () => {
        setClaimLoading(true);

        post_referral_stake_get_reward().then(res => {
            applyMint({
                wallets,
                amount: res.mintCoin,
                orderId: res.orderId,
                lockedUntil: res.lockSecond,
                timestamp: res.timestamp,
                validFor: res.validFor,
                signature: res.signature
            }).then(res => {
                setClaimDone(true);
                setClaimSuccess(true);
                setClaimLoading(false);
                setTimeout(
                    () => {
                        setLoading(true);
                        getEarnData();
                    }, 5000
                )
            }).catch(e => {
                console.error(e);
                setClaimDone(true);
                setClaimFailed(true);
                setClaimLoading(false);
                setTimeout(
                    () => {
                        setLoading(true);
                        getEarnData();
                    }, 5000
                )
            })
        }).catch(e => {
            setClaimLoading(false);
        })
    }

    const canClaim = useMemo(() => {
        return (stakeActivity?.toClaimLFG > 0 || stakeActivity?.refundPool > 0) && !claimDone
    }, [earnData, stakeActivity, claimDone])*/

    return (
        <>
            <div className={s.wrap} style={{paddingBottom: '0'}}>
                <div className={s.inviteCode}>
                    <div className={`${s.topbar} flex_center_between`}>
                        <div className={`${s.topbarBtn} ${groupType === 0 ? s.selecedType : s.unselectedType} fb flex_center_center`}
                            onClick={() => {
                                if (groupType !== 0){
                                    setGroupType(0);
                                }
                            }}>
                            {AutoStrangeRule(t('Packs Trading Vol'))}
                        </div>
                        <div className={`${s.topbarBtn} ${groupType === 1 ? s.selecedType : s.unselectedType} fb flex_center_center`}
                            onClick={() => {
                                if (groupType !== 1){
                                    setGroupType(1);
                                }
                            }}>
                            {AutoStrangeRule(t('Club Portfolio'))}
                        </div>
                    </div>
                    <div className="mt10 df jc-spaceBetween fs16 mb10 w100p">
                        <span className="ml40 fb">{t('Clubs')}</span>
                        <span className="mr10 fb">{groupType === 1 ? t('Portfolio') : t('Packs Trading Vol')}</span>
                    </div>
                    {
                        !loading ?
                            groupType === 1 ?
                            <div className={s.list} id="rankList">                           
                                <InfiniteScroll
                                    dataLength={list?.length || 0}
                                    next={getMore}
                                    hasMore={nextCursor !== -1}
                                    loader={<Spin/>}
                                    height={"100%"}
                                    onScroll={(e) => showArrow(e.target.scrollTop)}
                                    endMessage={
                                        <div style={{
                                            textAlign: "center",
                                            width: "100%",
                                            color: "var(--color-gray)",
                                            fontSize: '12px'
                                        }}></div>
                                    }
                                    // scrollableTarget="rankList"
                                >
                                    <>
                                        <div className={`${s.item}`}>
                                            <div className={s.info} onClick={() => {
                                                navigate(`${publicChatsPath}/${userId}`)
                                            }}>
                                                <AvatarInfo size={46} src={avatarUrl} dot/>
                                                <CustomIcon 
                                                    className={`${s.selfCrown}`}
                                                    width={31} 
                                                    height={31}
                                                    imgName={'Picture/UI_Picture_Crown_01'}
                                                />
                                                <div className="ml5 tll">
                                                    <div className={`${s['text-ellipsis']} fs16`}>
                                                        {displayFilter(selfProfile?.displayName)}
                                                        {selfProfile?.platformType === 1 &&
                                                            <CustomIcon className="ml5" width={14} height={14}
                                                                        imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                                        name={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                                                    </div>
                                                    <div
                                                        className="fl fs16 color-blue-light">{selfProfile?.platformType === 1 ? '@' + selfProfile?.accountName : ''}</div>
                                                </div>
                                            </div>
                                            <div className="fs20" style={{width: '30%', textAlign: 'right'}}>
                                                {showEarn ? stringToNumber(portfolio, 5) : '--'}
                                                <CustomIcon
                                                    width={20}
                                                    height={20}
                                                    imgName={"Picture/UI_Picture-Currency_MATIC_01"}
                                                    className="ml5"
                                                />
                                            </div>
                                        </div>
                                        {inviteUser && <div className={s.item}>
                                            <div className={s.info} onClick={() => {
                                                navigate(`${publicChatsPath}/${userId}`)
                                            }}>
                                                <AvatarInfo size={46} src={getAvatarFromProfile(inviteUser.profile)} upper/>
                                                <div className="ml5 tll">
                                                    <div className={`${s['text-ellipsis']} fs16`}>
                                                        {displayFilter(inviteUser.profile.displayName)}
                                                        {inviteUser.profile.platformType === 1 &&
                                                            <CustomIcon className="ml5" width={14} height={14}
                                                                        imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                                        name={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                                                    </div>
                                                    <div
                                                        className="fl fs16 color-blue-light">{inviteUser.profile.platformType === 1 ? '@' + inviteUser.profile.accountName : ''}</div>
                                                </div>
                                            </div>
                                            <div className="fs20" style={{width: '30%', textAlign: 'right'}}>
                                                {showEarn ? stringToNumber(inviteUser.portfolio, 5) : '--'}
                                                <CustomIcon
                                                    width={20}
                                                    height={20}
                                                    imgName={"Picture/UI_Picture-Currency_MATIC_01"}
                                                    className="ml5"
                                                />
                                            </div>
                                        </div>}
                                        {
                                            list.map((item, ind) => {
                                                const {userId, portfolio, earn, earnStakeLfg} = item;
                                                const {displayName, accountName, platformType, uid} = item?.profile;
                                                return (
                                                
                                                        <div className={s.item} key={userId}>
                                                            <div className={s.info} onClick={() => {
                                                                navigate(`${publicChatsPath}/${userId}`)
                                                            }}>
                                                                <AvatarInfo size={46}
                                                                            src={getAvatarFromProfile(item?.profile)}/>
                                                                <div className="ml5 tll">
                                                                    <div className={s['text-ellipsis']}>
                                                                        {displayFilter(displayName)}
                                                                        {platformType === 1 &&
                                                                            <CustomIcon className="ml5" width={14}
                                                                                        height={14}
                                                                                        imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                                                        name={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                                                                    </div>
                                                                    <div
                                                                        className="fl">{platformType === 1 ? '@' + accountName : ''}</div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="fs18">
                                                                <CustomIcon
                                                                    width={20}
                                                                    height={20}
                                                                    imgName={"Picture/UI_Picture-Currency_LFG_01"}
                                                                    name={"Picture/UI_Picture-Currency_LFG_01"}
                                                                    className="mr5"
                                                                /> {convertWalletBalance(earnStakeLfg)}
                                                            </div> */}
                                                            <div className="fs20"
                                                                style={{width: '30%', textAlign: 'right'}}>
                                                                {showEarn ? stringToNumber(portfolio, 5) : '-'}
                                                                <CustomIcon
                                                                    width={20}
                                                                    height={20}
                                                                    imgName={"Picture/UI_Picture-Currency_MATIC_01"}
                                                                    className="ml5"
                                                                />
                                                            </div>
                                                        </div>
                                                )
                                            })
                                        }
                                    </>
                                </InfiniteScroll>
                            </div> : 
                            <div className={s.list} id="rankList">                           
                                <InfiniteScroll
                                    dataLength={tradeList?.length || 0}
                                    next={getMoreMemeTrade}
                                    hasMore={nextCursor !== -1}
                                    loader={<Spin/>}
                                    height={"100%"}
                                    onScroll={(e) => showArrow(e.target.scrollTop)}
                                    endMessage={
                                        <div style={{
                                            textAlign: "center",
                                            width: "100%",
                                            color: "var(--color-gray)",
                                            fontSize: '12px'
                                        }}></div>
                                    }
                                >
                                    <>
                                        <div className={`${s.item}`}>
                                            <div className={s.info} onClick={() => {
                                                navigate(`${publicChatsPath}/${userId}`)
                                            }}>
                                                <AvatarInfo size={46} src={avatarUrl} dot/>
                                                <CustomIcon 
                                                    className={`${s.selfCrown}`}
                                                    width={31} 
                                                    height={31}
                                                    imgName={'Picture/UI_Picture_Crown_01'}
                                                />
                                                <div className="ml5 tll">
                                                    <div className={`${s['text-ellipsis']} fs16`}>
                                                        {displayFilter(selfProfile?.displayName)}
                                                        {selfProfile?.platformType === 1 &&
                                                            <CustomIcon 
                                                                className="ml5" 
                                                                width={14} 
                                                                height={14}
                                                                imgName={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                                                    </div>
                                                    <div
                                                        className="fl fs16 color-blue-light">{selfProfile?.platformType === 1 ? '@' + selfProfile?.accountName : ''}</div>
                                                </div>
                                            </div>
                                            <div className="fs20" style={{textAlign: 'right'}}>
                                                {`$ ${tradevolInfo?.tradeVolUsd > 0 ? convertWalletBalance(tradevolInfo?.tradeVolUsd || 0) : 0}`}
                                            </div>
                                        </div>
                                        {
                                            tradeList.map((item, ind) => {
                                                const {
                                                    userId, 
                                                    portfolio, 
                                                    tradeVol,
                                                } = item;
                                                const {
                                                    displayName, 
                                                    accountName, 
                                                    platformType, 
                                                    uid
                                                } = item?.profile;
                                                return (
                                                    <div className={s.item} key={userId}>
                                                        <div className={s.info} onClick={() => {
                                                            navigate(`${publicChatsPath}/${userId}`)
                                                        }}>
                                                            <AvatarInfo size={46}
                                                                        src={getAvatarFromProfile(item?.profile)}/>
                                                            <div className="ml5 tll">
                                                                <div className={s['text-ellipsis']}>
                                                                    {displayFilter(displayName)}
                                                                    {
                                                                        platformType === 1 &&
                                                                        <CustomIcon 
                                                                            className="ml5" 
                                                                            width={14}
                                                                            height={14}
                                                                            imgName={'Button/UI_Button_Ball-White_Twitter_On'}/>
                                                                    }
                                                                </div>
                                                                <div className="fl">
                                                                    {platformType === 1 ? '@' + accountName : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="fs20" style={{textAlign: 'right'}}>
                                                            {`$ ${convertWalletBalance(tradeVol || 0)}`}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                </InfiniteScroll>
                            </div> :
                            <div className="">{t('Loading...')}
                            </div>
                    }
                </div>
            </div>
            <AntModal
                width='330px'
                title={t('Staking Bonus')}
                className="confirmModalWrap"
                centered={true}
                open={tipsModal}
                destroyOnClose={true}
                onOk={() => setTipsModal(false)}
                onCancel={() => setTipsModal(false)}
            >
                <ModelReferralStakeTips/>
            </AntModal>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowOtherTransContent: (params) => {
            return dispatch({
                type: "app/setShowOtherTransContent",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ReferralEarnGroup));
