import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {
    convertWalletBalance
} from "@/utils/common";
import {Button, Modal} from "antd";
import {useNavigate} from "react-router-dom";
// import AuctionBanner1 from "@/assets/images/AuctionBanner1.png";
import CustomIcon from "../../../../common/CustomIcon";
import ModelGenesisAuctionTip from "@/model/ModelGenesisAuctionTip";
import AntModal from "@/common/AntModal";

const AuctionBanner = (
    {
        currentAuctionBatchCfg,
        currentAuctionBatchInfo,
        setCurrentAuctionBatchCfg
    }
) => {
    const {t, i18n} = useTranslation();
    const [showAuctionTip, setShowAuctionTip] = useState(false);
    const AuctionBanner2 = "/UI/Banner/UI_Banner_NFT-Auction_01-D.webp";

    return (
        <>
            <div className={s.banner}
                 style={{backgroundImage: `url(${currentAuctionBatchCfg?.auctionBatchType === 2 ? AuctionBanner2 : '/UI/Banner/UI_Banner_AuctionBanner_01.webp'})`}}
                 onClick={() => {
                     if (currentAuctionBatchCfg?.auctionBatchType === 2) {
                         setShowAuctionTip(true);
                     }
                 }}>
                {
                    currentAuctionBatchCfg?.auctionBatchType === 2 ? <>
                        <div className={s.auctionRewardRootLeft}
                             style={{backgroundImage: `url(/UI/Banner/UI_Banner_NFT-Auction_01-L.webp)`}}>
                            <div className={s.auctionRewardValueText}>
                                {currentAuctionBatchInfo?.rewardLFGPool != null && convertWalletBalance(currentAuctionBatchInfo?.rewardLFGPool)}
                            </div>
                            <CustomIcon width={32} height={32} className="mr10"
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                        </div>
                        <div className={s.auctionRewardRootRight}
                             style={{backgroundImage: `url(/UI/Banner/UI_Banner_NFT-Auction_01-R.webp)`}}>
                            <CustomIcon width={32} height={32} className="ml10"
                                        imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                        name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                            <div className={s.auctionRewardValueText}>
                                {currentAuctionBatchInfo?.rewardMaticPool != null && convertWalletBalance(currentAuctionBatchInfo?.rewardMaticPool)}
                            </div>
                        </div>
                        <div className={`${s.vbtn}`} shape="round">
                            {t('DETAILS')}
                        </div>
                        <div className={s.title2}>
                            <div className={s.titel2BigLine}>
                                {t('Bid and Earn')}
                            </div>
                        </div>
                        <div className={`${s.title2}`}>
                            <div className={s.titel2Line}>
                                {<Trans
                                    i18nKey="Last xx Winner's bonus"
                                    defaults="Last <red>{{count}}</red> Winner's bonus"
                                    values={{count: 3}}
                                    components={{
                                        red: <span className="mr2" style={{color: "#ff9212"}}></span>,
                                        br: <br/>
                                    }}
                                />}
                            </div>
                        </div>
                    </> : <>
                        <div className={s.title1}>
                            <div className={s.titel1BigLine}>
                                {t('Bid to Earn')}
                            </div>
                            <div className={s.titel1SmallLine}>
                                <Trans
                                    i18nKey="The Auction with a Bonus"
                                    defaults="Get <red>105%</yellow> of the bid when outbid!"
                                    values={{count: 3}}
                                    components={{
                                        red: <span className="mr2" style={{color: "#ffd400", fontSize: '22px'}}></span>,
                                        br: <br/>
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap"
                centered={true}
                open={showAuctionTip}
                destroyOnClose={true}
                onOk={() => setShowAuctionTip(false)}
                onCancel={() => setShowAuctionTip(false)}
                zIndex={2000}
            >
                <ModelGenesisAuctionTip
                    closeHandle={() => {
                        setShowAuctionTip(false)
                    }}
                />
            </AntModal>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        showBalance: app.showBalance,
        currentAuctionBatchCfg: app.currentAuctionBatchCfg,
        currentAuctionBatchInfo: app.currentAuctionBatchInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCharContent: (params) => {
            return dispatch({
                type: "app/setShowCharContent",
                payload: params,
            });
        },
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params,
            });
        },
        setCurrentAuctionBatchCfg: (params) => {
            return dispatch({
                type: "app/setCurrentAuctionBatchCfg",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(AuctionBanner));
