import React, {memo, useMemo, useEffect, useState, useRef} from "react";
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import s from "./index.module.less";
import {
    post_portfolio,
    chgFn,
    vCoinString,
    getProfile,
    stringToNumber,
    joinChat,
    getfriendtrade_rank_claim,
    getfriendtrade_loot_nftrank,
    getFriendtrade_loot_employeelist,
    getFriendtrade_loot_info,
    getfriendtrade_loot_crack,
    getfriendtrade_loot_randomsearch,
    PrivyLoginStatusError,
    getearninfo,
    TransactionError,
    bornCheck_loot,
    filterErrorCode,
    formatNumberWithUnit,
    displayFilter,
    getBattlePointRankImg,
    getTransactionErrorMsgId,
    getAvatarFromProfile,
    logoutSelf,
    convertWalletBalance,
    formatCardDisplayName,
} from "@/utils/common";
import {
    gameTrillionaireBattlingPath,
} from "@/routes/config";
import AvatarInfo from "../AvatarInfo";
import CustomIcon from "../CustomIcon";
import {useLocation, useNavigate} from "react-router-dom";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {Button, Checkbox, Modal, Spin, message, Radio, Drawer} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import CrackModelClubList from "../../model/CrackModelClubList";
import CrackModelAtack from "../../model/CrackModelAtack";
import CrackModelAtacking from "../../model/CrackModelAtacking";
import CrackModelAtackResult from "../../model/CrackModelAtackResult";
import Countdown from "../Countdown";
import HireClaimedResult from "../../components/HireClaimedResult";
import ModelLootFailed from "@/components/ModelLootFailed";
import HireWorkResult from "../../components/HireWorkResult";
import RankReward from '@/utils/json/RankReward.json'
import TierConfig from '@/utils/json/TierConfig.json'
import ModelNeedUseCliamCoin from "../../components/ModelNeedUseCliamCoin";
import lootWinGif from "@/assets/images/bgImg/win.gif";
import lootLoseGif from "@/assets/images/bgImg/lose.gif";
import win_result from "@/assets/images/bgImg/win_result.gif";
import HireFilter from "@/drawer/HireFilter";
import {bornLfg} from "@/utils/lfgMintWeb3";
import BattleHireFilter from "@/drawer/BattleHireFilter";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";

const CrackList = (
    {
        userInfo,
        setReFresh,
        rankType,
        showFilter,
        setShowFilter,
        lootTargetUserInfo,
        setLootTargetUserInfo,
        setLastLootTargetUserInfo,
        filterLevel,
    }
) => {
    const {t, i18n} = useTranslation();
    const selfUserId = userInfo?.userId;
    const {userId, platformData, selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo])

    const {lfgBalance, tierId} = useMemo(() => {
        return selfData || {}
    }, [selfData])

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [protfolioData, setProtfolioData] = useState({});
    const [nftListData, setNftListData] = useState(null);

    useEffect(() => {
        localStorage.setItem('battle_filterLevel', JSON.stringify(filterLevel));
        if (rankType === 1){
            setProtfolioData({});
            refreshPortfolio({refresh: true});
        }
    }, [filterLevel])

    const list = useMemo(() => {
        return protfolioData?.list || []
    }, [protfolioData])

    const nftList = useMemo(() => {
        return nftListData?.nftList || []
    }, [nftListData]);

    useEffect(() => {
        if (rankType === 3){
            updateNftListData({refresh: true});
        }
        else{
            refreshPortfolio({refresh: true});
        }
    }, []);

    useEffect(() => {
        if (rankType === 3){
            setNftListData(null);
            updateNftListData({refresh: true});
        }
        else{
            setProtfolioData({});
            refreshPortfolio({refresh: true})
        }
    }, [rankType]);

    const refreshPortfolio = ({nextCursor = '', refresh = false}) => {
        setLoading(true);
        getfriendtrade_rank_claim(nextCursor, rankType, filterLevel).then(data => {
            const {nextCursor} = data
            const unitDatas = data?.unitDatas || [];

            if (!refresh) {
                setProtfolioData((protfolioData) => {

                    const arr = protfolioData?.list || []
                    return {
                        nextCursor,
                        list: [
                            ...arr,
                            ...unitDatas
                        ]
                    }
                })
            } else {
                setProtfolioData({
                    nextCursor,
                    list: unitDatas
                })
            }

            // setNextCursor(data?.nextCursor || -1)
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    }

    const updateNftListData = ({nextCursor = 0, refresh = false}) => {
        setLoading(true);
        getfriendtrade_loot_nftrank(nextCursor || 0).then(data => {
            const {nextCursor} = data
            const newListData = data?.nftList || [];

            if (!refresh) {
                setNftListData((preNftListData) => {

                    const arr = preNftListData?.nftList || []
                    return {
                        nextCursor,
                        nftList: [
                            ...arr,
                            ...newListData
                        ]
                    }
                })
            } else {
                setNftListData({
                    nextCursor,
                    nftList: newListData
                })
            }

            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    }

    const getMoreList = () => {
        refreshPortfolio({nextCursor: protfolioData?.nextCursor});
    }

    const getMoreNftList = () =>{
        updateNftListData({nextCursor: nftListData?.nextCursor || 0});
    }

    const updateLeftTime = (ind) => {
        const {list, nextCursor} = protfolioData || {}

        list[ind] = {
            ...list[ind],
            protectCD: 0
        }

        setProtfolioData((protfolioData) => {
            return {
                nextCursor,
                list
            }
        })

    }

    const startLoot = (item) => {
        /*if (lootTargetUserInfo && ){
            if (lootTargetUserInfo?.profile?.accType === item?.profile?.accType){
                if (item?.profile?.accType === 2){
                    if (lootTargetUserInfo?.profile?.useNftInfo?.tokenId !== item?.profile?.useNftInfo?.tokenId || lootTargetUserInfo?.profile?.useNftInfo?.tokenAddr !== item?.profile?.useNftInfo?.tokenAddr){
                        setLastLootTargetUserInfo(lootTargetUserInfo);
                    }
                }
                else{
                    if (lootTargetUserInfo && lootTargetUserInfo?.userId !== item?.userId){
                        setLastLootTargetUserInfo(lootTargetUserInfo);
                    }
                }
            }
            else{
                setLastLootTargetUserInfo(lootTargetUserInfo);
            }
        }*/
        if (item?.userId === userId || item?.managerId === userId){
            return;
        }
        if (lootTargetUserInfo && lootTargetUserInfo?.userId !== item?.userId){
            setLastLootTargetUserInfo(lootTargetUserInfo);
        }
        setLootTargetUserInfo(item);
        navigate(gameTrillionaireBattlingPath, {replace: true});
    }

    return (
        <div className={s.wrap}>
            <div className={s.list} id="rankList">
                {
                    rankType === 3 ?
                        <InfiniteScroll
                            dataLength={nftList?.length || 0}
                            next={getMoreNftList}
                            hasMore={nftListData && nftListData?.nextCursor !== -1}
                            loader={<Spin/>}
                            style={{
                                width: '100%'
                            }}
                            endMessage={
                                <div style={{
                                    textAlign: "center",
                                    width: "100%",
                                    color: "var(--color-gray)",
                                    fontSize: '12px'
                                }}>{t('No More Data')}</div>
                            }
                            scrollableTarget={'rankList'}
                        >
                            {
                                nftList?.length > 0 && nftList.map((item, index) => {
                                    const {
                                        protectCD,
                                        userId,
                                        profile,
                                        tierId,
                                        bpLevel,
                                        claimCoin,
                                    } = item;
                                    const {displayName, accType} = profile;
                                    const originalAvatarUrl = getAvatarFromProfile(profile, 1);
                                    const avatarUrl = getAvatarFromProfile(profile);
                                    return (
                                        <div className={`${s.item} flex_center_between`} key={userId} onClick={() => {
                                            if (protectCD === 0){
                                                startLoot(item);
                                            }
                                        }}>
                                            <div className={`${s.mid} ml10`}>
                                                <div className={`${s.avatarRoot}`} onClick={(e) => {
                                                    e.stopPropagation();
                                                    joinChat(userId, navigate, accType);
                                                }}>
                                                    <div className={s.avatarInfo}
                                                        style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                    </div>
                                                    <div className={s.avatarInfo}
                                                        style={{backgroundImage: `url(${originalAvatarUrl || avatarUrl})`}}>
                                                    </div>
                                                    {
                                                        accType === 2 && 
                                                        <CustomIcon className={s.nftIcon} width={24} height={24}
                                                                        imgName={'Picture/UI_Picture-NFT'}/>
                                                    }
                                                </div>
                                            </div>
                                            <div className={s.right}>
                                                <div className={`${s.rightUp} flex_center_between`}>
                                                    <div className={`${s.nameRoot} flex_center_start`}>
                                                        {formatCardDisplayName(displayFilter(displayName))}
                                                    </div>
                                                    {
                                                        protectCD > 0 &&
                                                        <div className={`${s.shildRoot} flex_center_end`}>
                                                            <CustomIcon className="mr5" width={16} height={16}
                                                                        imgName={'Picture/UI_Picture_ProtectiveCover_01'}/>
                                                            <div className="flex_center_center" style={{width: '50px'}}>
                                                                <Countdown refresh={() => updateLeftTime(index)}
                                                                        leftSecond={protectCD} setType={1}/>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={`${s.rightDown} flex_center_start`}>
                                                    <div className={`${s.bpPart} flex_center_center`}>
                                                        <CustomIcon className="" width={24} height={24}
                                                                    imgName={`Picture/BPTier/${getBattlePointRankImg(bpLevel || 1)}`}/>
                                                    </div>
                                                    <div className={s.verticalLine}>
                                                    </div>
                                                    <div className={`${s.tierPart} flex_center_center`}>
                                                        <CustomIcon className="" width={24} height={24}
                                                                    imgName={`Picture/Rank/RankStar_${tierId}`}/>
                                                    </div>
                                                    <div className={s.verticalLine}>
                                                    </div>
                                                    <div className={`${s.lfgPool} flex_center_start`}>
                                                        <CustomIcon className="ml10" width={24} height={24}
                                                                    imgName="Picture/UI_Picture-Currency_LFG_01"/>
                                                        <div className={`${s.lfgPoolValue} flex_center_center`}>
                                                            {convertWalletBalance(Number(claimCoin) || 0)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </InfiniteScroll> :
                        <InfiniteScroll
                            dataLength={list?.length || 0}
                            next={getMoreList}
                            // onScroll={onScroll}
                            // initialScrollY={rankList[type]?.scrollTop || 0}
                            hasMore={protfolioData && protfolioData.nextCursor !== '-1'}
                            loader={<Spin/>}
                            style={{
                                width: '100%'
                            }}
                            endMessage={
                                <div style={{
                                    textAlign: "center",
                                    width: "100%",
                                    color: "var(--color-gray)",
                                    fontSize: '12px'
                                }}>{t('No More Data')}</div>
                            }
                            scrollableTarget={'rankList'}
                        >
                            {
                                list?.length > 0 && list.map((item, index) => {
                                    const {
                                        claimCoin,
                                        protectCD,
                                        rankIdx,
                                        userId,
                                        profile,
                                        tierId,
                                        bpLevel,
                                    } = item;
                                    const {displayName, accType} = profile;
                                    const isSelf = selfUserId == userId;
                                    const originalAvatarUrl = getAvatarFromProfile(profile, 1);
                                    const avatarUrl = getAvatarFromProfile(profile);
                                    return (
                                        <div className={`${s.item} flex_center_between`} key={userId} onClick={() => {
                                            if (protectCD === 0){
                                                startLoot(item);
                                            }
                                        }}>
                                            {
                                                rankIdx > 0 &&
                                                <div className={`${s.left} flex_center_center`}>
                                                    {
                                                        rankIdx === 1 ?
                                                            <CustomIcon width={40} height={40} className=""
                                                                        imgName={'Picture/UI_Picture_NO-First_01'}/> :
                                                            rankIdx === 2 ?
                                                                <CustomIcon width={40} height={40} className=""
                                                                            imgName={'Picture/UI_Picture_NO-Second_01'}/> :
                                                                rankIdx === 3 ?
                                                                    <CustomIcon width={40} height={40} className=""
                                                                                imgName={'Picture/UI_Picture_NO-Third_01'}/> :
                                                                    <div className="fs18">
                                                                        {`#${rankIdx}`}
                                                                    </div>
                                                    }
                                                </div>
                                            }
                                            <div className={`${s.mid} ${rankIdx === 0 ? 'ml10' : ''}`}>
                                                <div className={`${s.avatarRoot}`} onClick={(e) => {
                                                    e.stopPropagation();
                                                    joinChat(userId, navigate, accType);
                                                }}>
                                                    <div className={s.avatarInfo}
                                                        style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                    </div>
                                                    <div className={s.avatarInfo}
                                                        style={{backgroundImage: `url(${originalAvatarUrl || avatarUrl})`}}>
                                                    </div>
                                                    {
                                                        accType === 2 && 
                                                        <CustomIcon className={s.nftIcon} width={24} height={24}
                                                                        imgName={'Picture/UI_Picture-NFT'}/>
                                                    }
                                                </div>
                                            </div>
                                            <div className={s.right}>
                                                <div className={`${s.rightUp} flex_center_between`}>
                                                    <div className={`${s.nameRoot} flex_center_start`}>
                                                        {formatCardDisplayName(displayFilter(displayName))}
                                                        {profile?.platformType === 1 &&
                                                            <CustomIcon className="ml10" width={16} height={16}
                                                                        imgName={'Picture/UI_Picture_TikTok_01'}/>}
                                                    </div>
                                                    {
                                                        protectCD > 0 &&
                                                        <div className={`${s.shildRoot} flex_center_end`}>
                                                            <CustomIcon className="mr5" width={16} height={16}
                                                                        imgName={'Picture/UI_Picture_ProtectiveCover_01'}/>
                                                            <div className="flex_center_center" style={{width: '50px'}}>
                                                                <Countdown refresh={() => updateLeftTime(index)}
                                                                        leftSecond={protectCD} setType={1}/>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={`${s.rightDown} flex_center_start`}>
                                                    <div className={`${s.bpPart} flex_center_center`}>
                                                        <CustomIcon className="" width={24} height={24}
                                                                    imgName={`Picture/BPTier/${getBattlePointRankImg(bpLevel || 1)}`}/>
                                                    </div>
                                                    <div className={s.verticalLine}>
                                                    </div>
                                                    <div className={`${s.tierPart} flex_center_center`}>
                                                        <CustomIcon className="" width={24} height={24}
                                                                    imgName={`Picture/Rank/RankStar_${tierId}`}/>
                                                    </div>
                                                    <div className={s.verticalLine}>
                                                    </div>
                                                    <div className={`${s.lfgPool} flex_center_start`}>
                                                        <CustomIcon className="ml10" width={24} height={24}
                                                                    imgName="Picture/UI_Picture-Currency_LFG_01"/>
                                                        <div className={`${s.lfgPoolValue} flex_center_center`}>
                                                            {convertWalletBalance(Number(claimCoin) || 0)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </InfiniteScroll>
                }
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        activeInd: app.activeInd,
        tranList: app.tranList,
        userInfo: app.userInfo,
        rankHome: app.rankHome,
        lootTargetUserInfo: app.lootTargetUserInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveInd: (params) => {
            return dispatch({
                type: "app/setActiveInd",
                payload: params,
            });
        },
        setTranList: (params) => {
            return dispatch({
                type: "app/setTranList",
                payload: params,
            });
        },
        setRankHome: (params) => {
            return dispatch({
                type: "app/setRankHome",
                payload: params,
            });
        },
        setLootTargetUserInfo: (params) => {
            return dispatch({
                type: "app/setLootTargetUserInfo",
                payload: params,
            });
        },
        setLastLootTargetUserInfo: (params) => {
            return dispatch({
                type: "app/setLastLootTargetUserInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(CrackList));
