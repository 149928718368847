import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, message, Spin, Input, Modal} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {
    vCoinString,
    createTwitterLink,
    createTwitterHtml,
    getProfile,
} from "@/utils/common";
import {TwitterShareButton} from 'react-twitter-embed';
import {tweetCard, tweetSite} from "@/utils/env";
import Canvas from 'src/common/CanvasMix';
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";


const Countdown = ({closeHandle}) => {
    const [minutes, setMinutes] = useState(5);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let intervalId;

        if (minutes === 0 && seconds === 0) {
            clearInterval(intervalId);
            closeHandle();
        } else if (seconds !== 0) {
            intervalId = setInterval(() => setSeconds(seconds - 1), 1000);
        } else if (minutes !== 0 && seconds === 0) {
            setMinutes(minutes - 1);
            setSeconds(59);
        }

        return () => clearInterval(intervalId);
    }, [minutes, seconds]);

    return (
        <>
            {minutes.toLocaleString("en-US", {minimumIntegerDigits: 2})}
            :
            {seconds.toLocaleString("en-US", {minimumIntegerDigits: 2})}
        </>
    );
};


const HireModelClaimed = (
    {
        closeHandle,
        totalInfo
    }
) => {
    const {t, i18n} = useTranslation();
    const copyWrap = useRef(null);
    const {
        lootCoin,  //获得的coin
        resulteInfo, //工作或者劫掠结果对应的JOSN结构体
        employeeTwitterProfile, //派遣者用户信息
        tarTwitterProfile, //只有type为2时 才有 被攻击者用户信息
        profile, //当前用户信息
        tweetTopic,
        type, //1: 工作, 2:劫掠
        referralCode,  //邀请链接
        selfClaimCoin   //个人收获
    } = useMemo(() => {
        return totalInfo || {}
    }, [totalInfo]);
    const card = tweetCard;
    const site = tweetSite;
    const creator = profile?.accountName;
    const inviteUrl = `https://${referralCode?.replace(('/'), '/@')}`;
    var title = resulteInfo?.resultTitle;
    var tweetText = "";
    if (type == 2 && tarTwitterProfile?.accountName) {
        tweetText = resulteInfo?.twitterTextWithUser.replace(("{twittername}"), `@${tarTwitterProfile.accountName}`);
    } else {
        tweetText = resulteInfo?.twitterText;
    }
    const description = `${tweetText}\n${type == 2 ? "Battle now on " : ""}@TURNUPDOTXYZ\n${tweetTopic}`;
    var downloadName = `${title ? title : `shareImage`}.jpg`;

    const [isTweetLinkInit, setIsTweetLinkInit] = useState(false);
    const [tweetUrl, setTweetUrl] = useState('');
    const [linkedCanvas, setLinkedCanvas] = useState('');


    var inviteUrlWithoutHttps = referralCode.replace(('/'), '/@');
    var resultImageUri = resulteInfo?.twitterImage ? require(`@/assets/images/resultImg/${resulteInfo?.twitterImage}.jpg`) : ``;
    var parsedInviteUrl = inviteUrlWithoutHttps.length > 24 ? `${inviteUrlWithoutHttps.substring(0, 25)}...` : inviteUrlWithoutHttps;
    var canvasWidth = 850;
    var canvasHeight = 445;

    const imgElements = [
        {uri: resultImageUri, x: 0.705, y: 0, cw: 0.295, ch: 1},
        {uri: require('@/assets/images/twittercard/ShareOnTwitter_BackGround.webp'), x: 0, y: 0, cw: 1, ch: 1},
        {
            uri: require(`@/assets/images/twittercard/${type === 1 ? 'ShareOnTwitter_BackGround_Word_02' : 'ShareOnTwitter_BackGround_Word_01'}.webp`),
            x: 0.04,
            y: 0.0822,
            cw: 0.52,
            ch: 0.0589
        },
        {
            text: `${parsedInviteUrl} just earned...`,
            color: "#c76eff",
            font: "sans-serif",
            minFontSize: 18,
            maxFontSize: 18,
            x: 0.04,
            y: 0.5,
            cw: 0.6,
            ch: 0.5
        },
        {
            text: `${vCoinString(selfClaimCoin)}  $LFG`,
            color: "#ffffff",
            font: "RaceSport",
            minFontSize: 40,
            maxFontSize: 40,
            x: 0.2,
            y: 0.57,
            cw: 0.6,
            ch: 0.5,
            bold: true
        },
        {
            text: title,
            color: "#FFFFFFE0",
            font: "RaceSport",
            minFontSize: 30,
            maxFontSize: 30,
            lineHeight: 1,
            x: 0.04,
            y: 0.21,
            cw: 0.65,
            ch: 0.4
        },
        {qr: true, uri: inviteUrl, x: 0.56, y: 0.68, cw: 0.2 * canvasHeight / canvasWidth, ch: 0.2},
    ];

    console.log("[imgElements]", imgElements);

    const initSharedItem = (sharedImg) => {
        var htmlData = createTwitterHtml(card, site, title, description, creator, inviteUrl);
        createTwitterLink(htmlData, sharedImg, profile.accountName).then(res => {
            if (res.linkUrl !== undefined && res.linkUrl !== null && res.linkUrl !== '') {
                setTweetUrl(res.linkUrl);
                setIsTweetLinkInit(true);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    const downloadImage = () => {
        if (linkedCanvas) {
            linkedCanvas.toBlob((blob) => {
                const url = URL.createObjectURL(blob);
                var link = document.createElement("a");
                link.download = downloadName;
                link.href = url;
                link.click();
                URL.revokeObjectURL(url);
            }, "image/jpeg", 0.98);
        }
    }

    return (
        <div className={s.wrap}>
            <div className={s.loadFont}>load Font</div>
            <Canvas
                width={canvasWidth}
                height={canvasHeight}
                setImageData={initSharedItem}
                setLinkedCanvas={setLinkedCanvas}
                imgElements={imgElements}></Canvas>
            <div className={`${s.buttonList} mt20`}>
                {isTweetLinkInit && <div className={`${s.tweetShareBtn}`}><TwitterShareButton
                    onLoad={() => {
                    }}
                    options={{
                        size: 'large',
                        title: title,
                        text: description
                    }}
                    url={tweetUrl}
                /></div>}
                &nbsp;
                {isTweetLinkInit && <AntButton shape="round" className={`${s.selfBtn}`} onClick={() => {
                    downloadImage();
                }}>{t('Download')}</AntButton>}
                &nbsp;
                <AntButton shape="round" className={`${s.selfBtn}`} onClick={closeHandle}>
                    {t('Close')}
                </AntButton>
            </div>
        </div>
    )
}
export default memo(HireModelClaimed);
