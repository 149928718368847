import React from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import {gameShareDetailPath, gameTrillionaireBattleLeaderBoardPath, mainProfilePath} from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelUpFerver = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    return (
    <div className={s.modelWrap}>
        <div className="mb15">{t('The trending mention leaderboard is determined by all eligible tweets of the day.')}</div>
        <div className="mt15">{t('The top 100 tweets in terms of social scores that mention the top 10 trending mentions will receive an engagement score bonus.')}</div>
        <div className="mt15">{t('The higher the individual social scores, the bigger the bonus.')}</div>
        <div className="mt15">{t('The leaderboard resets and recalculates daily at UTC 0.')}</div>
        <div className={s.item}>{t('The higher the trending mention ranks')}</div>
        <div className={s.item}>{t('The higher your tweet ranks in the top 100')}</div>
    </div>
    )
}

export default ModelUpFerver
