import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AntButton from "@/common/AntButton";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from 'swiper/modules';
// import Banner from "../../../common/Banner";
import {
    stringToNumber,
    convertWalletBalance,
    sliceStr,
    getAvatarFromProfile,
    getTokenBalanceOf,
    copyFn,
    sliceAddr,
    logoutSelf,
    getWeb3Config,
    borwserPlatform,
    isSafari,
    getVideoPlayUrl,
    ALIPLAYER_KEY,
    getVideoUrlFromMap,
    numberClamp,
    TransactionError,
    PrivyLoginStatusError,
    updateSelfUser,
} from "@/utils/common"
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import DrawerMomentBuy from "@/model/DrawerMomentBuy";

import { Tabs, Drawer, message, Input } from "antd";
import Detail from "./Club/Detail";
import {
    momentClubInfo,
    getChainInfo,
    setFavorite,
    moment_tradelog,
    video_play_token,
    momentClubList,
    defaultMemeSwitchVideoParams,
    setLike,
    getMomentTradeInfo,
    getMomentClubPackGasPrice,
    buyMomentClubPack,
    moment_buyresult,
} from "@/utils/momentClub";
import Activity from "./Club/Activity";
import Holders from "./Club/Holders";
import { AutoStrangeRule } from "@/utils/strangeRule";
import ModelTrans from "@/model/ModelTrans";
import ModalMemePackTrans from "@/model/ModalMemePackTrans";
import MemeChainToken from "@/utils/json/memeChainToken.json";
import {
    gameMomentClubChatPath,
    gameMomentClubCreatPath,
    gameMomentClubDetail,
    gameMomentClubDetailPath,
    gameMomentClubRevealMomentPackPath,
    gameMomentClubExplorePath,
    gameMomentClubUserDetailPath,
} from "@/routes/config";
import Active from "./TGE/Active";
import Info from "./TGE/Info";
import Trade from "./TGE/Trade";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import MemeClubPack from "@/common/MemeClubPack";
import NFTs from "@/views/games/MomentClub/ClubDetail/TGE/NFTs";
import { getBalance } from "@/utils/uniswapWeb3";
import { useWallets, usePrivy } from "@privy-io/react-auth";
import momentChainInfo from "@/utils/json/momentChainInfo.json";
import ModalWalletDisconnect from "@/model/ModalWalletDisconnect";
import Chat from "../Chat";
import KChart from "../KChart";
import MobileBG from "@/assets/video/MobileBG.mp4";
import { backFn } from "@/utils/mianPublic";
import Charts from "./TGE/Trade/Charts";
import MemePackTrans from "./Club/Packs";
import { isOnlineEnv } from "@/utils/env";
import useLocalStorageState from "@/common/useLocalStorageState";
import ModelMomentsShare from "@/model/ModelMomentsShare";
import ModalPackTransResult from "@/model/ModalPackTransResult";
import ModalInsufficientCrpto from "src/model/ModalInsufficientCrpto";
import AvatarInfo from "@/common/AvatarInfo";

const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    return useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);
}

const ClubDetail = (
    {
        userInfo,
        isCurrentClub,
        clubId,
        clubShortInfo,
        playerRootName,
        drawerOpen,
        setDrawerOpen = () => { },
        buyDrawerOpen,
        setBuyDrawerOpen = () => { },
        showForceMutedTip,
        setShowForceMutedTip = () => { },
        pauseVideo = () => { },
        index,
        walletBalanceList,
        setWalletBalanceList,
        hideNSFW,
        lastVideoSwitch,
        currentMomentClubId,
        globalVideoMute,
        setGlobalVideoMute,
    }
) => {
    const { t, i18n } = useTranslation();
    const containerRef = useRef(null);
    const aliPlayerRef = useRef(null);
    const inputRef = useRef(null);
    const { wallets } = useWallets();
    const { logout } = usePrivy()
    const [playingClubId, setPlayingClubId] = useState(0);
    const [playingVideoId, setPlayingVideoId] = useState(0);
    const [clubBasicInfo, setClubBasicInfo] = useState(null)
    const [showWalletDisconnect, setShowWalletDisconnect] = useState(false);
    const [videoStartDragY, setVideoStartDragY] = useState(0);
    const [isVideoPause, setIsVideoPause] = useState(false);
    const [pauseClickLock, setPauseClickLock] = useState(false);
    const [hideVideo, setHideVideo] = useState(!isCurrentClub);
    const [videoReadyFlag, setVideoReadyFlag] = useState('');
    const [checkPlay, setCheckPlay] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [videoUrlResData, setVideoUrlResData] = useState(null);
    const [lastOpenDrawerKey, setLastOpenDrawerKey] = useState(-1);
    const [openLivePanel, setOpenLivePanel] = useState(false)
    const { walletAddr, userId } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const navigate = useNavigate();
    const location = useLocation();
    const { backPath } = location?.state || {};
    const [videoTokenRes, setVideoTokenRes] = useState(null);

    const [memePackBuyDone, setMemePackBuyDone] = useState(false);
    const [memePackBuySubLoading, setMemePackBuySubLoading] = useState(false);

    const getMomentClubInfo = (clubId) => {
        momentClubInfo(clubId).then(res => {
            //console.log(`getMomentClubInfo----->`, res);
            setClubBasicInfo(res)
        }).catch(e => {
            console.error(e);
        })
    }

    const [videoProcessing, setVideoProcessing] = useState(false);

    const upDateFn = () => {
        getMomentTradelog(clubId, startCursor, endCursor, true)
        getMomentClubInfo(clubId)
        reFreshBalance()
    }

    const reFreshClubInfo = () => {
        clubId && getMomentClubInfo(clubId)
    }
    const [time,setTime] = useState(null)
    const {
        currentCardNum,
        clubInfo,
        historyPrice,
        holdNum,
        maxCardNum,
        sellPrice,
        buyPrice,
        isFavorite:_isFavorite,
        isLike:_isLike,
        inMarket,
        creatorProfile,
        creatorUserId,
        coinTokenAddr,
        onAlive,
        announceStartTime,
        announceTitle,
        momentAuditCode,
        favoriteNum:_favoriteNum,
        likeNum:_likeNum,
        curCardKindNum,
        cardKindMaxNum,
        chatLogs = [],
    } = useMemo(() => {
        setTime(new Date().getTime())
        // return {...clubBasicInfo,inMarket:true} || {}
        return clubBasicInfo || {}
    }, [clubBasicInfo])

    const [likeNum,setLikeNum] = useState(0)

    useEffect(()=>{
        setLikeNum(_likeNum)
    },[_likeNum])

    const [isLike,setIsLike] = useState(false)

    useEffect(()=>{
        setIsLike(_isLike)
    },[_isLike])

    const [isFavorite,setIsFavorite] = useState(false)

    useEffect(()=>{
        setIsFavorite(_isFavorite)
    },[_isFavorite])


    const [favoriteNum,setFavoriteNum] = useState(0)

    useEffect(()=>{
        setFavoriteNum(_favoriteNum)
    },[_favoriteNum])

    


    // [{
    //     logType: 1,  //1:chartMsg 2:activity 3:custom
    //     profile:{
    //         userId,
    //         displayName,
    //         avatarUrl
    //     },
    //     txType: 1, //1 buy,2 sell  logType: 2
    //     amount, //买或者卖的数量 logType: 2
    //     totalPrice,
    //     msg:"werewr" //logType: 1 || 3
    // }]

    const isSelfChat = useMemo(() => {
        return userId === Number(creatorUserId)
    }, [userId, creatorUserId])

    const range = useMemo(() => {
        if (sellPrice && historyPrice) {
            return Math.round(((sellPrice || 0) - (historyPrice || 0)) / (historyPrice || 0) * 100)
        }
        return 0
    }, [sellPrice, historyPrice])

    const {
        clubName,
        imageUrl,
        coinId,
        symbolName,
        symbolImageUrl,
        description,
        videoId,
        coverUrl,
        tgLink,
        twitterLink,
        ftTotalSupply,
        airdropPercent,
        openPackRatio,
        coinToNFTRatio,
        isFT,
        priceUSD,
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    const chainInfo = useMemo(() => {
        let _ = {}
        _ = coinId && getChainInfo(coinId)
        return _
    }, [coinId])

    const [exploreScanUrl, holdScanUrl] = useMemo(() => {
        if (momentChainInfo && chainInfo?.chainId) {
            const chainInfoMatch = momentChainInfo.find(i => i?.chainId === chainInfo.chainId);
            return [chainInfoMatch?.exploreScanUrl, chainInfoMatch?.holdScanUrl];
        }
        return [undefined, undefined];
    }, [momentChainInfo, chainInfo]);


    const [coinBalance, setCoinBalance] = useState(0)

    const getWalletBalance = ({ walletAddr, chainInfo }) => {
        const { ID, isNative } = chainInfo
        let tokenList = [ID];
        if (isNative === 0) {
            tokenList.push(Math.round(Math.floor(ID / 100) * 100));
        }
        getTokenBalanceOf(walletAddr, tokenList).then(res => {
            let tempBalance = walletBalanceList || {};
            for (let i = 0; i < tokenList.length; i++) {
                tempBalance[tokenList[i]] = res?.balances?.[tokenList[i]];
            }
            setWalletBalanceList({
                ...tempBalance,
                ...res?.balances
            });
        }).catch(err => {
            console.error(err.message);
        })
    }

    const reFreshBalance = () => {
        getWalletBalance({ walletAddr, chainInfo })
        coinTokenAddr && getBalance({ wallets, addr: coinTokenAddr, chainId: chainInfo?.chainId }).then(res => {
            setCoinBalance(res)
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        if (!coinTokenAddr || !chainInfo) return
        reFreshBalance();
    }, [coinTokenAddr, chainInfo])

    useEffect(() => {
        if (chainInfo && walletAddr) {
            getWalletBalance({ walletAddr, chainInfo });
        }
    }, [chainInfo, walletAddr])

    const [showMomentPackTrans, setShowMomentPackTrans] = useState(false);
    const [momentPackTransType, setMomentPackTransType] = useState(1);
    const showTransModelFn = useCallback((type) => {
        setShowMomentPackTrans(true);
        setMomentPackTransType(type);
    }, [])

    /*const aliplayerPlayByUrl = (url) => {
        if (!url) {
            console.log("[aliplayerPlayByUrl] url is null or empty");
            return;
        }
        setPlayingVideoId(clubShortInfo?.videoId);
        //console.log("[playUrl]", url);
        if (aliPlayerRef.current) {
            try {
                aliPlayerRef.current.loadByUrl(url);
            }
            catch (e) {
                console.error("[replayByVidAndPlayAuth error]", e);
            }
        }
        else {
            try{
                const player = new window.Aliplayer({
                    id: playerRootName,
                    width: '100%',
                    source: url,
                    //vid: videoId,
                    //playauth: playAuthData.playAuth,
                    isLive: false,
                    rePlay: true,
                    playsinline: true,
                    isVBR: true,
                    //format: "mp4",
                    language: 'en-us',
                    controlBarVisibility: "never",
                    useH5Prism: true,
                    preload: true,
                    autoplay: false,
                    cover: './img/a_50x50.png',
                    license: {
                        domain: "turnup.xyz",
                        key: ALIPLAYER_KEY,
                    },
                    autoplayPolicy: {
                        fallbackToMute: true,
                        showUnmuteBtn: false,
                    },
                    skinLayout: [
                        {
                            "name": "H5Loading",
                            "align": "cc"
                        },
                        {
                            "name": "controlBar",
                            "align": "blabs",
                            "x": 0,
                            "y": 0,
                            "children": []
                        }
                    ]
                })
    
                aliPlayerRef.current = player;
            }
            catch(e){
                console.error("[new aliplayer error]", e);
            }
        }
    }*/

    /*useEffect(() => {
        if (videoUrlResData && videoUrlResData?.videoId === clubShortInfo?.videoId) {
            let videoUrl = getVideoUrlFromMap(videoUrlResData?.urlMap);
            if (videoUrl) {
                setVideoProcessing(false);
                aliplayerPlayByUrl(videoUrl);
            }
            else if (Object.keys(videoUrlResData?.urlMap)?.length === 1){
                setVideoProcessing(true);
            }
        }
    }, [videoUrlResData])*/

    /*useEffect(() => {
        if (!clubShortInfo || playingVideoId === clubShortInfo?.videoId || !clubShortInfo?.videoId || clubShortInfo?.momentAuditCode === 3) {
            return;
        }

        let videoUrl = getVideoUrlFromMap(clubShortInfo?.videoUrlMap);

        if (videoUrl) {
            setVideoProcessing(false);
            aliplayerPlayByUrl(videoUrl);
        }
        else {
            getVideoPlayUrl(clubShortInfo?.videoId.toString()).then(res => {
                setVideoUrlResData(res);
            }).catch(e => {
                console.error("[getVideoPlayUrl]", e);
            })
        }
    }, [clubShortInfo, clubShortInfo?.videoUrlMap]);*/

    const tradeMomentClubInfo = useMemo(() => {
        return {
            userId: clubBasicInfo?.clubUserId,
            clubName: clubBasicInfo?.clubInfo?.clubName,
            coinId: clubBasicInfo?.clubInfo?.coinId,
            curCardKindNum: clubBasicInfo?.curCardKindNum,
            cardKindMaxNum: clubBasicInfo?.cardKindMaxNum,
            holdNum: clubBasicInfo?.holdNum,
            imageUrl: clubBasicInfo?.clubInfo?.imageUrl,
            symbolName: clubBasicInfo?.clubInfo?.symbolName,
            ftTotalSupply: clubBasicInfo?.clubInfo?.ftTotalSupply,
            airdropPercent: clubBasicInfo?.clubInfo?.airdropPercent,
            openPackRatio: clubBasicInfo?.clubInfo?.openPackRatio,
            coinToNFTRatio: clubBasicInfo?.clubInfo?.coinToNFTRatio,
            isFT: clubBasicInfo?.clubInfo?.isFT,
            symbolImageUrl: clubBasicInfo?.clubInfo?.symbolImageUrl,
        }
    }, [clubBasicInfo])

    const [rotating, setRotating] = useState(false)

    const favoriteFn = useDebounce((userId, favorite) => {
        setFavorite(userId, favorite).then(res => {
            // reFreshClubInfo()
        }).catch(e => {
            console.error(e);
        })
    },500)

    const likeFn = useDebounce((userId, like) => {
        setLike(userId, like).then(res => {
            // reFreshClubInfo()
        }).catch(e => {
            console.error(e);
        })
    },500)


    const handleFavoriteClick = () => {
        setIsFavorite(!isFavorite)
        setFavoriteNum(Math.max(0,!isFavorite?favoriteNum+1:favoriteNum-1))
        favoriteFn(clubId, !isFavorite);
    };

    const handleLikeClick = () => {
        setIsLike(!isLike)
        setLikeNum(Math.max(0,!isLike?likeNum+1:likeNum-1))
        likeFn(clubId, !isLike)
        
    };



    // const linkToChat = () => {
    //     navigate(`${gameMomentClubChatPath}/${clubId}`);
    // }

    const [activekey, setAcicekey] = useState(4)
    const [headerTitle, setHeaderTitle] = useState(t('Chat'))

    const videoRef = useRef(null)
    const [showChart, setShowChart] = useLocalStorageState("momentDetailShowChart", true)
    const [drawerOpenLeft, setDrawerOpenLeft] = useState(false);

    const [isPlaying, setIsPlaying] = useState(true)

    // Kchart Data start
    const pageSise = 200; //max 500
    const layout = 30000; //update time ms
    const [sourceData, setSourceData] = useState([])
    const [currentCursor, setCurrentCursor] = useState(-1);
    const [lastCursor, setLastCursor] = useState(-1);
    const [startCursor, setStartCursor] = useState(-1);
    const [endCursor, setEndCursor] = useState(-1);
    const [upDate, setUpdate] = useState(false)

    useEffect(() => {
        if (clubId !== playingClubId) {
            //setPlayAuth('');
            setClubBasicInfo(null);
            //clearVideoRoot();
            //setVideoReady(false);
            clubId && getMomentClubInfo(clubId);
        }
        setShowChart(JSON.parse(localStorage.getItem(`momentDetailShowChart`)))
    }, [clubId])

    const getMomentTradelog = (clubId, start = -1, end = -1, getLast = false) => {

        moment_tradelog({
            clubUserId: parseInt(clubId),
            startCursor: parseInt(start),
            endCursor: parseInt(end)
        }).then(res => {
            const { startCursor, endCursor, lastCursor, tradeLogs } = res;
            if (getLast && !(tradeLogs && tradeLogs?.length > 0)) return
            if (start === -1 && end === -1) {
                setCurrentCursor(lastCursor)
            }
            setLastCursor(lastCursor)
            /*console.log(`getMomentTradelog result`, {
                startCursor, endCursor, lastCursor, tradeLogs
            });*/
            if (getLast) {

                setStartCursor(lastCursor + 1)
                setEndCursor(lastCursor + pageSise)
                setCurrentCursor(lastCursor)
                setSourceData(sourceData => {
                    return [
                        ...(sourceData || []),
                        ...(tradeLogs || []),
                    ]
                })
            } else {

                setSourceData(sourceData => {
                    return [
                        ...(tradeLogs || []),
                        ...(sourceData || []),
                    ]
                })

                if (startCursor > 0) {
                    getMomentTradelog(clubId, Math.max(0, startCursor - pageSise), Math.max(startCursor - 1, endCursor - pageSise))
                } else {
                    setStartCursor(start === -1 && end === -1 ? lastCursor + 1 : currentCursor + 1)
                    setEndCursor(start === -1 && end === -1 ? lastCursor + pageSise : currentCursor + pageSise)
                    setUpdate(true)
                }
            }
        }).catch((e) => {
            console.error('[moment_tradelog] error', e);
        });
    }

    useEffect(() => {
        if (!clubId) return
        setCurrentCursor(-1);
        setLastCursor(-1);
        setStartCursor(-1);
        setEndCursor(-1);
        // setSourceData([]);
        setUpdate(false)
        setSourceData([]);
        !inMarket && getMomentTradelog(clubId)
    }, [clubId, inMarket])

    useEffect(() => {
        setDrawerOpenLeft(drawerOpen || buyDrawerOpen);
    }, [drawerOpen, buyDrawerOpen])

    useEffect(() => {
        let timer = null;
        if (inMarket) return
        if (upDate && !timer) {
            timer = setInterval(() => {
                getMomentTradelog(clubId, startCursor, endCursor, true)
            }, layout)
        }
        if (!upDate) {
            timer && clearInterval(timer)
        }
        return () => {
            timer && clearInterval(timer)
        }
    }, [upDate, startCursor, endCursor, inMarket])

    // Kchart Data start

    const [coinContract, setCoinContract] = useState('')
    const getContractInfo = async () => {
        const web3Config = await getWeb3Config();
        const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === chainInfo?.ID));
        setCoinContract(contractInfo?.coinContract)
    }

    useEffect(() => {
        getContractInfo();
    }, [chainInfo])

    const coinChain = useMemo(() => {
        return {
            chainId: chainInfo?.chainId,
            address: coinTokenAddr,
            decimals: 18,
            icon: symbolImageUrl,
            displayCoinName: "$" + symbolName
        }
    }, [coinTokenAddr, imageUrl, symbolName, symbolImageUrl])

    const nativeChain = useMemo(() => {
        return {
            chainId: chainInfo?.chainId,
            address: coinContract,
            decimals: 18,
            icon: chainInfo?.icon,
            displayCoinName: chainInfo?.displayCoinName,
            chainName: chainInfo?.name.split(' ')[0].toLocaleLowerCase()
        }
    }, [coinContract, chainInfo])

    const [showChartDetail, setShowChartDetail] = useState(true)
    const [defaultOpenBuy, setDefaultOpenBuy] = useState(false)
    const [defaultOpenSell, setDefaultOpenSell] = useState(false)

    useEffect(() => {
        const updateObjectFit = () => {
            const video = videoRef.current;
            const container = video.parentElement;

            if (video && container) {
                const videoRatio = video.videoWidth / video.videoHeight;
                const containerRatio = container.offsetWidth / container.offsetHeight;

                if (videoRatio < containerRatio) {
                    video.style.objectFit = 'cover';
                } else {
                    video.style.objectFit = 'contain';
                }
            }
        };

        const video = videoRef.current;
        if (video) {
            video.addEventListener('loadedmetadata', updateObjectFit);
            window.addEventListener('resize', updateObjectFit);
            updateObjectFit();
        }

        return () => {
            if (video) {
                video.removeEventListener('loadedmetadata', updateObjectFit);
            }
            window.removeEventListener('resize', updateObjectFit);

            clearVideoRoot();
        };
    }, []);

    const clearVideoRoot = () => {
        if (videoRef?.current) {
            let deleteList = [...videoRef?.current?.children];
            for (let i = 0; i < deleteList?.length; i++) {
                videoRef.current.removeChild(deleteList[i]);
            }
        }

        try {
            if (aliPlayerRef.current) {
                let status = aliPlayerRef.current.getStatus();
                if (status === "init") {
                    aliPlayerRef.current.on("ready", function (data) {
                        aliPlayerRef.current.dispose();
                    })
                }
                else {
                    aliPlayerRef.current.dispose();
                }

            }
        }
        catch (e) {
            console.error("[dispose play]", clubId, e);
        }
    }

    const items = [
        {
            key: 3,
            label: `${AutoStrangeRule(t('Trade 01'))}`,
            children: <MemePackTrans
                clubId={clubId}
                clubBasicInfo={clubBasicInfo}
                chainInfo={chainInfo}
                showChartDetail={showChartDetail}
                setShowChartDetail={setShowChartDetail}
                sourceData={sourceData}
                upDate={upDateFn}
                defaultOpenBuy={defaultOpenBuy}
                setDefaultOpenBuy={setDefaultOpenBuy}
                setDefaultOpenSell={setDefaultOpenSell}
                defaultOpenSell={defaultOpenSell}
                openBuyDrawer={() => {
                    setDrawerOpen(false);
                    setBuyDrawerOpen(true);
                    setLastOpenDrawerKey(3);
                }}
            ></MemePackTrans>,

        },
        {
            key: 4,
            label: `${AutoStrangeRule(t('Chat'))}`,
            children: <Chat
                showTransModelFn={showTransModelFn}
                clubId={clubId}
                clubBasicInfo={clubBasicInfo}
                chainInfo={chainInfo}
                sourceData={sourceData}
                nativeChain={nativeChain}
                coinChain={coinChain}
                showChartDetail={showChartDetail}
                setShowChartDetail={setShowChartDetail}
                upDate={upDateFn}
                pauseVideo={pauseVideo}
                setOpenLivePanel={setOpenLivePanel}
                openLivePanel={openLivePanel}
                openBuyDrawer={() => {
                    setDrawerOpen(false);
                    setBuyDrawerOpen(true);
                    setLastOpenDrawerKey(4);
                }}
            ></Chat>,
            // children: <div>Chat</div>,
        },
        // {
        //     key: 1,
        //     label: `${AutoStrangeRule(t('DETAILS'))}`,
        //     children: <Detail
        //         clubId={clubId}
        //         clubBasicInfo={clubBasicInfo}
        //         chainInfo={chainInfo}
        //         sourceData={sourceData}
        //         showTransModelFn={showTransModelFn}
        //         showChartDetail={showChartDetail}
        //         setShowChartDetail={setShowChartDetail}
        //         upDate={upDateFn}
        //     ></Detail>,
        // }, 
        {
            key: 2,
            label: `${AutoStrangeRule(t('Holders'))}`,
            children: <Holders
                clubId={clubId}
                sourceData={sourceData}
                clubBasicInfo={clubBasicInfo}
                showChartDetail={showChartDetail}
                setShowChartDetail={setShowChartDetail}
                chainInfo={chainInfo}
                openBuyDrawer={() => {
                    setDrawerOpen(false);
                    setBuyDrawerOpen(true);
                    setLastOpenDrawerKey(2);
                }}></Holders>,
        }
    ]

    const items_tge = [
        {
            key: 1,
            label: `${AutoStrangeRule(t('Trade'))}`,
            children: <Trade
                coinBalance={coinBalance}
                reFreshBalance={reFreshBalance}
                walletBalanceList={walletBalanceList}
                clubId={clubId}
                showChartDetail={showChartDetail}
                setShowChartDetail={setShowChartDetail}
                clubBasicInfo={clubBasicInfo}
                chainInfo={chainInfo}></Trade>,
        }, {
            key: 4,
            label: `${AutoStrangeRule(t('Chat'))}`,
            children: <Chat
                showTransModelFn={showTransModelFn}
                clubId={clubId}
                clubBasicInfo={clubBasicInfo}
                chainInfo={chainInfo}
                nativeChain={nativeChain}
                showChartDetail={showChartDetail}
                setShowChartDetail={setShowChartDetail}
                coinChain={coinChain}
            ></Chat>,
            // children: <div>Chat</div>,
        },
        {
            key: 2,
            label: `${AutoStrangeRule(t('NFTs'))}`,
            children: <NFTs
                clubBasicInfo={clubBasicInfo}
                nativeChain={nativeChain}
                showChartDetail={showChartDetail}
                setShowChartDetail={setShowChartDetail}
                coinChain={coinChain}
                coinBalance={coinBalance}>
            </NFTs>,
        }
        /*, {
            key: 2,
            label: `${AutoStrangeRule(t('Info'))}`,
            children: <Info clubId={clubId} clubBasicInfo={clubBasicInfo} chainInfo={chainInfo}></Info>,
        }, {
            key: 3,
            label: `${AutoStrangeRule(t('Active'))}`,
            children: <Active clubId={clubId} chainInfo={chainInfo}></Active>
        },*/
    ]

    const muteVideo = () => {
        if (globalVideoMute) {
            setGlobalVideoMute(false);
        }
        else {
            setGlobalVideoMute(true);
        }
    }

    const [openSharePanel, setOpenSharePanel] = useState(false)

    //buy moment
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const [insufficientAmount, setInsufficientAmount] = useState(0);
    const [showPackTransResult, setShowPackTransResult] = useState(false);
    const [buyTx, setBuyTx] = useState('');
    const [buyCheckTimes, setBuyCheckTimes] = useState(0);
    const [momentBuyResult, setMomentBuyResult] = useState(null);
    const currentCoinInfo = useMemo(() => {
        return MemeChainToken.find(i => i?.ID === coinId);
    }, [coinId]);

    const refreshData = () => {
        upDateFn();
    }

    const checkOrderResult = (tx) => {
        const { chainId } = getChainInfo(coinId)
        moment_buyresult(chainId, tx).then(res => {
            if (!res?.cardList?.length > 0) {
                setTimeout(() => {
                    setBuyCheckTimes(buyCheckTimes + 1);
                }, 2000);
            } else {
                refreshData();
                setMomentBuyResult(res);
            }
        }).catch(e => {
            console.error(e);
            setTimeout(() => {
                setBuyCheckTimes(buyCheckTimes + 1);
            }, 2000);
        })
    }

    useEffect(() => {
        if (buyCheckTimes > 0) {
            checkOrderResult(buyTx);
        }
    }, [buyCheckTimes]);

    const [currentIndex, setCurrentIndex] = useState(0);


    return (
        <div ref={containerRef} className={`${s.modalWrap} textNoDrag ${inMarket || activekey === 4 ? "" : 'mainMomentDetailWrap'}`}>
            <div className={`${s.mediaRoot}`}>

            </div>
            <div className={`${s.rootBlackBg}`}>

            </div>
            <div className={`${s.out} outPart`}>
                <div className={`${s.media} ${drawerOpenLeft ? s.drawerOpenLeft : ''}`}>
                    <div className={`${s.blackBg}`}>
                    </div>
                    <div id={`${playerRootName}`} className={`${s.bg}`} ref={videoRef}></div>
                    {
                        clubShortInfo?.momentAuditCode === 3 ?
                            <>
                                <div
                                    className={`${s.blockedBg}`}
                                    style={{ backgroundImage: `url(/UI/Picture/UI_Picture_Empty_Bg_01.png)` }}>
                                </div>
                                <div className={`${s.blockedRoot} flex_center_center_col`}>
                                    <CustomIcon className="" width={74} height={74}
                                        imgName={'Picture/UI_Picture_Icon_Lock_01'} />
                                    <div className={`momentFont fs24 fb mt40 textnowrap`}>
                                        {t('Video Blocked')}
                                    </div>
                                </div>
                            </> :
                            videoProcessing ?
                                <>
                                    <div
                                        className={`${s.loadingBg}`}
                                        style={{ backgroundImage: `url(/UI/Picture/UI_Picture_Empty_Bg_01.png)` }}>
                                    </div>
                                    <div className={`${s.loadingRoot} flex_center_center_col`}>
                                        <CustomIcon rotating={true} className="" width={74} height={74}
                                            imgName={'Picture/UI_Picture_Loading_02'} />
                                        <div className={`momentFont fs24 fb mt40 textnowrap`}>
                                            {t('Video Processing')}
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className={`${s.blackBg}`}>
                                    </div>
                                    {
                                        !isSafari() &&
                                        <div className={`${s.blurBg}`} style={{ backgroundImage: `url(${clubShortInfo?.videoFirstFrameUrl || clubShortInfo?.coverUrl})` }}>
                                        </div>
                                    }
                                    {
                                        !drawerOpenLeft &&
                                        <div className={`${s.clearBg}`} style={{ backgroundImage: `url(${clubShortInfo?.videoFirstFrameUrl || clubShortInfo?.coverUrl})` }}>
                                        </div>
                                    }
                                </>
                    }
                </div>
                <div className={s.head}>
                    <CustomIcon onClick={() => {
                        // navigate(gameMomentClubExplorePath);+
                        backFn(location, navigate, backPath);
                    }} imgName={`Button/UI_Button_Exit_01`} width={29} height={26}></CustomIcon>
                    <div className="flex_center_center">
                        <div className={`${s.voiceBtnRoot}`}>
                            {
                                globalVideoMute ?
                                    <CustomIcon onClick={() => {
                                        muteVideo(false);
                                        setShowForceMutedTip(false);
                                    }} className="" imgName={`Picture/UI_Picture_VideoIcon_sound-off`} width={36} height={36} /> :
                                    <CustomIcon onClick={() => {
                                        muteVideo(true);
                                        setShowForceMutedTip(false);
                                    }} className="" imgName={`Picture/UI_Picture_VideoIcon_sound-on`} width={36} height={36} />
                            }
                            {
                                showForceMutedTip &&
                                <div className={`${s.voiceTip} flex_center_center momentFont`} onClick={() => {
                                    setShowForceMutedTip(false);
                                }}>
                                    {t('Due to browser policies, Video muted.')}
                                    <CustomIcon className="ml5" imgName={`Button/UI_Button_Close_01`} width={12} height={12} />
                                    <div className={`${s.voiceTipCor}`}>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={`${s.bottom}`} >
                    <div className="f1">
                        {!inMarket &&
                            <>
                                {!showChart ?
                                    <div className="flex_center_start">
                                        <CustomIcon className={`${s.chartShowBtn}`} onClick={() => { setShowChart(true) }} imgName={`Picture/UI_Picture_Icon_Chart_01`} width={28} height={28}></CustomIcon>
                                        <div className={`flex_center_center ml5`}>
                                            <CustomIcon imgName={range < 0 ? 'Picture/UI_Picture_PriceDown_01' : 'Picture/UI_Picture_PriceUP_01'} className="mr5" width={18} height={13}></CustomIcon>
                                            <span className={range < 0 ? 'color-red fs12' : 'color-green fs12'}>{range}%</span>
                                        </div>
                                    </div> :
                                    <div className={s.kWrap}>
                                        <div className={s.bg} onClick={() => {
                                            { inMarket ? setAcicekey(1) : setAcicekey(3) }
                                            setDrawerOpen(true)
                                        }}></div>
                                        <CustomIcon onClick={() => {
                                            setShowChart(false)
                                        }} className={s.closeBtn} imgName={`Button/UI_Button_Close_01`} width={12} height={12}></CustomIcon>
                                        {!inMarket ? <KChart
                                            coinName={chainInfo?.displayCoinName}
                                            clubId={clubId}
                                            symbolName={clubName}
                                            sourceData={sourceData}

                                            lightMode={true}
                                        ></KChart> :
                                            <Charts coinChain={coinChain} nativeChain={nativeChain} />
                                        }
                                    </div>

                                }


                            </>
                        }

                        <div className="flex_center_start fs16 mt20 mb15 fb" >
                            {inMarket ?
                                <>
                                    {/* <img src={symbolImageUrl} className="mr5" width="18" height="18" style={{ borderRadius: '9px' }}></img> */}
                                    <div className="fs16 mr5 momentFont fb peAuto" onClick={() => {
                                        setDrawerOpen(true);
                                    }}>${clubName}</div>
                                </>
                                :
                                <>
                                    <div className="fs16 mr10 momentFont peAuto" onClick={() => {
                                        setDrawerOpen(true);
                                    }}>${clubName}</div>
                                    <CustomIcon className="ml5 mr5 peAuto" imgName={`Picture/${chainInfo?.icon}`} width={16} height={16} onClick={() => {
                                        setDrawerOpen(true);
                                    }}></CustomIcon>
                                    <div className="fs16 mr10 peAuto" onClick={() => {
                                        setDrawerOpen(true);
                                    }}>{convertWalletBalance(buyPrice)}</div>
                                    {
                                        tgLink &&
                                        <a href={`${tgLink}`}>
                                            <CustomIcon style={{ pointerEvents: 'all' }} className="mr10" imgName={`Button/UI_Button_White_TurnUp_02`} width={18} height={18} />
                                        </a>
                                    }
                                    {
                                        twitterLink &&
                                        <a href={`${twitterLink}`}>
                                            <CustomIcon style={{ pointerEvents: 'all' }} className="mr10" imgName={`Button/UI_Button_White_Twitter_01`} width={14} height={14} />
                                        </a>

                                    }
                                </>
                            }
                        </div>
                        <div className={`${s.des} momentFont color-EEEEEE`} onClick={() => {
                            setAcicekey(3)
                            setDrawerOpen(true)
                        }}>
                            {description}
                        </div>
                        <div className={s.botInfo}>
                            <div className={`${s.chat} color-999 momentFont`} onClick={() => {
                                setAcicekey(4)
                                setDrawerOpen(true)
                            }}>

                                {
                                    chatLogs?.length > 0 ? <div className={`${s.msgList}`}>
                                        <Swiper
                                            slidesPerView={1}
                                            slidesPerGroup={1}
                                            centeredSlides={true}
                                            direction="vertical"
                                            loop={true}
                                            autoplay={{
                                                delay: 3000,
                                            }}
                                            modules={[Autoplay]}
                                            onSlideChange={(i) => { }}
                                            initialSlide={0}
                                        >
                                            {
                                                chatLogs?.map((item, ind) => {
                                                    const { logType,profile,amount,msg } = item || {};
                                                    const { avatarUrl, displayName, userId } = profile

                                                    // {
                                                    //     logType:1,
                                                    //     profile:{
                                                    //         avatarUrl: avatarUrl || '',
                                                    //         displayName: displayName || '',
                                                    //         userId: userInfo?.userId
                                                    //     },
                                                    //     amount:0,
                                                    //     totalPrice:0,
                                                    //     msg: convertToMessage(text)
                                                    // }
                                                    return (
                                                        <SwiperSlide key={ind}>
                                                            <div className={s.msgWrap}>
                                                                <AvatarInfo size={31} src={avatarUrl} isMoment={true}></AvatarInfo>
                                                                <div className="flex_start_start_col ml10 f1">
                                                                    <div className="fs16 color-moment-light-gray">{displayName}</div>
                                                                    <div className={`fs12 color-moment-light-gray ellipse ${s.msgBox}`}>
                                                                        {
                                                                            logType === 1 && msg
                                                                        }
                                                                        {
                                                                            (logType === 2 || logType === 3) && 
                                                                            <Trans
                                                                                i18nKey="Buy/Sold x Moment/Moments"
                                                                                defaults="{{trade}} {{x}} {{sth}}"
                                                                                values={{
                                                                                    trade: logType === 2 ? 'Bought' : 'Sold',
                                                                                    x: amount,
                                                                                    sth: amount > 1 ? 'Moments' : 'Moment',
                                                                                }}
                                                                            />
                                                                        }
                                                                        {
                                                                            logType === 4 && t('Created this moment')
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    )

                                                })
                                            }
                                        </Swiper>
                                    </div> :
                                        <div className={s.msgWrap}>
                                            <AvatarInfo size={31} src={''} isMoment={true}></AvatarInfo>
                                            <div className="flex_center_start ml10 f1">
                                                {(holdNum > 0 || isSelfChat || inMarket) ? t('Say something…') : <Trans
                                                    i18nKey="You need to purchase a pack to speak"
                                                    defaults="You need to purchase a <pack></pack> to speak"
                                                    components={{
                                                        pack: <CustomIcon className="" width={20} height={20}
                                                            imgName={'Picture/UI_Picture_icon_cardpack'}></CustomIcon>
                                                    }}
                                                />}
                                                {/* <div className="fs12 color-moment-light-gray ellipse">{msg}</div> */}
                                            </div>
                                        </div>

                                }
                            </div>

                            {/* <div className={s.botItem}>
                            <CustomIcon
                                onClick={() => handleLikeClick()}
                                imgName={isLike ? 'Picture/UI_Picture_MemeDetails_Like_02' : `Picture/UI_Picture_MemeDetails_Like_01`} width={36} height={36}></CustomIcon>
                            <div className={s.bg}>{likeNum}</div>
                        </div>
                        <div className={s.botItem}>
                            <CustomIcon
                                onClick={() => handleFavoriteClick()}
                                imgName={isFavorite ? 'Picture/UI_Picture_MemeDetails_Collect_02' : 'Picture/UI_Picture_MemeDetails_Collect_01'}
                                className={``} width={36} height={36}></CustomIcon>
                            <div className={s.bg}>{favoriteNum}</div>
                        </div> */}
                            {/* <div className={s.botItem}>
                            <CustomIcon imgName={`Picture/UI_Picture_MemeDetails_Chat_01`} width={36} height={36}></CustomIcon>
                            <div className={s.bg}>33</div>
                        </div> */}
                        </div>
                    </div>
                    <div className={s.rightPanel}>
                        {onAlive &&
                            <div className={`${s.aliveIcon} `} onClick={() => {
                                setOpenLivePanel(true)
                                setAcicekey(4)
                                setDrawerOpen(true)
                                pauseVideo();
                            }}>
                                <CustomIcon imgName={`Picture/UI_Picture_MemeDetails_Live_01`} className="" width={48} height={48}></CustomIcon>
                            </div>
                        }
                        {creatorUserId > 0 && <div className={`${s.creatorAvatarRoot}`} onClick={() => {
                            navigate(`${gameMomentClubUserDetailPath}/${creatorUserId}`)
                        }}>
                            <div className={`${s.creatorAvatar}`} style={{ backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_02.png)` }}>
                            </div>
                            <div className={`${s.creatorAvatar}`} style={{ backgroundImage: `url(${creatorProfile?.originalAvatarUrl || creatorProfile?.avatarUrl})` }}>
                            </div>
                            <div className={`${s.creatorAvatarBorder}`}>
                            </div>
                        </div>
                        }
                        <div className={s.botItem}>
                            <CustomIcon
                                onClick={() => handleLikeClick()}
                                imgName={isLike ? 'Picture/UI_Picture_MemeDetails_Like_02' : `Picture/UI_Picture_MemeDetails_Like_01`} width={36} height={36}></CustomIcon>
                            <div className={s.bg}>{likeNum}</div>
                        </div>
                        <div className={s.botItem}>
                            <CustomIcon
                                onClick={() => handleFavoriteClick()}
                                imgName={isFavorite ? 'Picture/UI_Picture_MemeDetails_Collect_02' : 'Picture/UI_Picture_MemeDetails_Collect_01'}
                                className={``} width={36} height={36}></CustomIcon>
                            <div className={s.bg}>{favoriteNum}</div>
                        </div>
                        <CustomIcon onClick={() => {
                            setOpenSharePanel(true)
                        }} imgName={`Picture/UI_Picture_Icon_Share_01`} width={36} height={36} className={`mt10 mb10`}></CustomIcon>
                    </div>


                </div>
            </div>
            <div className={s.modal}>


                <div className={s.content}>
                    <div className={s.wrap}>
                        {isCurrentClub &&
                            <Drawer
                                title=""
                                placement={`bottom`}
                                closable={false}
                                onClose={() => {
                                    setDrawerOpen(false)
                                }}
                                open={drawerOpen}
                                mask={true}
                                rootClassName={'momentDrawerWarp'}
                                className={'momentDrawer'}
                                height={`73.3%`}
                                push={false}
                            > {
                                    !inMarket ?
                                        <Tabs
                                            defaultActiveKey={activekey}
                                            items={items}
                                            activeKey={activekey}
                                            className="momentTabs tpPlr15"
                                            onChange={activeKey => {
                                                setAcicekey(activeKey)
                                                switch (activeKey) {
                                                    case 1:
                                                        setHeaderTitle(AutoStrangeRule(t('DETAILS')))
                                                        break;
                                                    case 2:
                                                        setHeaderTitle(AutoStrangeRule(t('Holders')))
                                                        break;
                                                    case 4:
                                                        setHeaderTitle(AutoStrangeRule(t('Chat')))
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }}
                                            destroyInactiveTabPane={true}>
                                        </Tabs> :
                                        <Tabs
                                            defaultActiveKey={1}
                                            items={items_tge}
                                            activeKey={activekey}
                                            className="momentTabs tpPlr15"
                                            onChange={activeKey => {
                                                setAcicekey(activeKey)
                                                switch (activeKey) {
                                                    case 1:
                                                        setHeaderTitle(AutoStrangeRule(t('Trade')))
                                                        break;
                                                    case 2:
                                                        setHeaderTitle(AutoStrangeRule(t('NFTs')))
                                                        break;
                                                    case 4:
                                                        setHeaderTitle(AutoStrangeRule(t('Chat')))
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }}
                                            destroyInactiveTabPane={true}>
                                        </Tabs>
                                }

                            </Drawer>
                        }
                    </div>
                </div>
            </div>
            {isCurrentClub && <Drawer
                title=""
                placement={`bottom`}
                closable={false}
                onClose={() => {
                    setBuyDrawerOpen(false);
                    setMemePackBuyDone(false);
                    setMemePackBuySubLoading(false)
                    if (lastOpenDrawerKey >= 0) {
                        setAcicekey(lastOpenDrawerKey);
                        setLastOpenDrawerKey(-1);
                        setDrawerOpen(true);
                    }
                }}
                open={buyDrawerOpen}
                mask={true}
                rootClassName={'momentDrawerWarp'}
                className={'momentDrawer'}
                height={`73.3%`}
                destroyOnClose={true}
                push={false}
            >
                <DrawerMomentBuy
                    type={1}
                    tradeMemeClubInfo={{
                        userId: clubId,
                        clubName: clubName,
                        coinId: coinId,
                        curCardKindNum: curCardKindNum,
                        cardKindMaxNum: cardKindMaxNum,
                        symbolName: symbolName,
                        holdNum: holdNum,
                        symbolImageUrl: symbolImageUrl,
                        ftTotalSupply: ftTotalSupply,
                        airdropPercent: airdropPercent,
                        openPackRatio: openPackRatio,
                        coinToNFTRatio: coinToNFTRatio,
                        isFT: isFT,
                        buyPrice: buyPrice,
                    }}
                    clubId={clubId}
                    done={memePackBuyDone}
                    setDone={setMemePackBuyDone}
                    subLoading={memePackBuySubLoading}
                    setSubLoading={setMemePackBuySubLoading}
                    closeHandle={() => {
                        setBuyDrawerOpen(false);
                    }}
                    completeFn={(tx) => {
                        setBuyDrawerOpen(false);
                        setMemePackBuyDone(false);
                        setMemePackBuySubLoading(false)
                        upDateFn();
                        setBuyTx(tx);
                        setShowPackTransResult(true);
                        setBuyCheckTimes(buyCheckTimes + 1);
                    }}
                    sourceData={sourceData}
                />
            </Drawer>}
            {
                <>
                {!inMarket ?
                    <div className={`${s.bottomWrap} flex_center_between`}>
                        <div
                            className={`${s.sellBtn} flex_center_center`}
                            style={{ backgroundImage: `url(/UI/Button/UI_Button_Sell_01.svg)` }}
                            onClick={() => {
                                if (!holdNum) {
                                    message.info({ content: t('No moment to sell') });
                                    return;
                                }
                                setDefaultOpenSell(true);
                                setAcicekey(3);
                                setDrawerOpen(true);
                            }}>
                            <div className={`momentFont fs18 fb color-white lh1`}>
                                {AutoStrangeRule(t('SELL'))}
                            </div>
                        </div>
                        <div className={`${s.middlePart} flex_center_start_col`} onClick={() => {
                            /*if (!isOnlineEnv()){
                                navigate(`${gameMomentClubRevealMomentPackPath}/${clubId}`);
                            }*/
                            setDrawerOpen(true);
                        }}>
                            <div className={`mt2 momnetFont fs12 color-moment-gray`}>
                                {t('You own:')}
                            </div>
                            <div className={`flex_center_center mt2`}>
                                <CustomIcon width={32} height={32} imgName={`Picture/UI_Picture_icon_cardpack`} />
                                <span className="fs14 fb momentFont">x</span>
                                <span className="fs20 fb momentFont">
                                    {holdNum}
                                </span>
                            </div>
                        </div>
                        <div
                            className={`${s.buyBtn} flex_center_center`}
                            style={{ backgroundImage: `url(/UI/Button/UI_Button_Buy_01.svg)` }}
                            onClick={() => {
                                setBuyDrawerOpen(true);
                            }}>
                            <div className={`momentFont fs18 fb color-gray lh1`}>
                                {AutoStrangeRule(t('BUY'))}
                            </div>
                        </div>
                    </div> :
                    <div className={`${s.bottomWrap} flex_center_between`}>
                        {/*<ButtonFactory
                        onClick={() => {
                            setAcicekey(1)
                            setDrawerOpen(true)
                        }}>{t('Trade Token')}</ButtonFactory>*/}
                        <div className={`flex_start_start_col ml0`}>
                            <div className={`momentFont fs12 fb color-moment-gray`}>
                                {t('You own')}
                            </div>
                            <div className={`flex_center_start mt5 w100p`}>
                                <div className={`${s.tokenIcon} mr2`} style={{ backgroundImage: `url(${symbolImageUrl})` }}>
                                </div>
                                <div className={`momentFont fs14 fb color-EEEEEE mr4`}>
                                    {`${convertWalletBalance(coinBalance)}`}
                                </div>
                                <div className={`momentFont fs12 fb color-moment-gray`}>
                                    {`$ ${convertWalletBalance(coinBalance * (priceUSD || 0))}`}
                                </div>
                            </div>
                        </div>
                        <AntButton
                            shape="round"
                            className={`${s.tradeBtn} btn_public`}
                            onClick={() => {
                                setAcicekey(1);
                                setDrawerOpen(true);
                            }}>
                            <div className={`momentFont fs20 fb color-gray`}>
                                {t('TRADE TOKEN')}
                            </div>
                        </AntButton>
                    </div>
                }
                </>
            }

            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={showMomentPackTrans}
                destroyOnClose={true}
                zIndex={4000}
                onOk={() => setShowMomentPackTrans(false)}
                onCancel={() => setShowMomentPackTrans(false)}
            >
                <ModalMemePackTrans
                    type={momentPackTransType}
                    tradeMemeClubInfo={tradeMomentClubInfo}
                    reFreshClubInfo={reFreshClubInfo}
                    closeHandle={() => {
                        setShowMomentPackTrans(false);
                    }}
                />
            </AntModal>

            <AntModal
                width='330px'
                title={t('Notice')}
                className="confirmModalWrap transModal"
                centered={true}
                open={showWalletDisconnect}
                destroyOnClose={true}
                zIndex={4000}
                onOk={() => logoutSelf()}
                onCancel={() => logoutSelf()}
            >
                <ModalWalletDisconnect />
            </AntModal>

            <AntModal
                width='330px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={openSharePanel}
                onOk={() => setOpenSharePanel(false)}
                onCancel={() => setOpenSharePanel(false)}
            >
                <ModelMomentsShare
                    clubId={clubId}
                    clubName={clubName}
                    closeHandle={() => {
                        setOpenSharePanel(false);
                    }} />
            </AntModal>
            <AntModal
                width='330px'
                title={t('INSUFFICIENT BALANCE')}
                className="confirmModalWrap"
                centered={true}
                open={showInsufficientCrpto}
                onOk={() => setShowInsufficientCrpto(false)}
                onCancel={() => setShowInsufficientCrpto(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalInsufficientCrpto
                    insufficientAmount={insufficientAmount}
                    userInfo={userInfo}
                    coinId={coinId}
                    closeFn={() => {
                        setShowInsufficientCrpto(false);
                    }}
                    type={2} />
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={showPackTransResult}
                onOk={() => setShowPackTransResult(false)}
                onCancel={() => {

                    setShowPackTransResult(false)
                    setMomentBuyResult(null)
                }}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalPackTransResult
                    transResult={momentBuyResult}
                    clubBasicInfo={clubBasicInfo}
                    closeFn={() => {

                        setShowPackTransResult(false);
                        setMomentBuyResult(null)
                    }}
                />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        walletBalanceList: app.walletBalanceList,
        lastVideoSwitch: app.LastVideoSwitch,
        memeclubSwitchVideoParams: app.memeclubSwitchVideoParams,
        switchVideoType: app.switchVideoType,
        switchVideoList: app.switchVideoList,
        switchVideoListCursor: app.switchVideoListCursor,
        hideNSFW: app.hideNSFW,
        currentMomentClubId: app.currentMomentClubId,
        globalVideoMute: app.globalVideoMute,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setLastVideoSwitch: (params) => {
            return dispatch({
                type: "app/setLastVideoSwitch",
                payload: params,
            });
        },
        setSwitchVideoType: (params) => {
            return dispatch({
                type: "app/setSwitchVideoType",
                payload: params,
            });
        },
        setGlobalVideoMute: (params) => {
            return dispatch({
                type: "app/setGlobalVideoMute",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ClubDetail));
