import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {Input, Button, Modal, Spin} from "antd";
import {} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {uniswapLFG, uniswapQuoterLFG} from "@/utils/uniswapWeb3";
import ButtonFactory from "src/common/ButtonFactory";

const ModalSwapLfg = (
    {
        closeFn,
        insufficientAmount = 0,
        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const {sendTransaction} = usePrivy();
    const inputRef = useRef(null);
    const [maticAmount, setMaticAmount] = useState('');
    const [lfgAmount, setLfgAmount] = useState(0);
    const [convertTimer, setConvertTimer] = useState(null);
    const [convertLock, setConvertLock] = useState(false);
    const [exchangeRate, setExchangeRate] = useState(0);
    const tryQuoteMaticAmount = 1;
    const [savedLfgAmountTime, setSavedLfgAmountTime] = useState(new Map());//<matic, time>
    const [savedLfgAmount, setSavedLfgAmount] = useState(new Map());//<matic, lfg>
    const [lastCheckTime, setLastCheckTime] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);
    const [checkResultState, setCheckResultState] = useState(0);//0:成功,1:进行中,2:失败
    const [swapState, setSwapState] = useState(0);//0:未进行,1:进行中,2:失败,3:成功
    const {walletAddr, selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const {
        balance,
        lfgBalance,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData])

    useEffect(() => {
        let nowTime = new Date().getTime();
        if (nowTime - lastCheckTime >= 15 * 1000 && Number(maticAmount) > 0 && swapState !== 1) {
            quoteLfg(Number(maticAmount), false);
        }
        setTimeout(() => {
            setUpdateCount(updateCount + 1);
        }, 1000);
    }, [updateCount])

    useEffect(() => {
        if (insufficientAmount > 0) {
            quoteLfg(tryQuoteMaticAmount, true);
        }

        if (inputRef?.current){
            inputRef.current.focus({
                cursor: 'start',
            });
        }
    }, [])

    const quoteLfg = async (amount, needEstimateMatic = false) => {
        try {
            setCheckResultState(1);
            let lfgAmount = await uniswapQuoterLFG({wallets, amount});
            if (needEstimateMatic) {
                //保留两位小数向上取值
                let needMatic = Number((tryQuoteMaticAmount * insufficientAmount / lfgAmount * 1.1).toFixed(2));
                setMaticAmount(needMatic);
                quoteLfg(needMatic, false);
            } else {
                setLfgAmount(lfgAmount);
                setConvertLock(false);
                setCheckResultState(0);
                if (amount != 0) {
                    setExchangeRate(lfgAmount / amount);
                }
                let nowTime = new Date().getTime();
                let newSavedLfgAmountTime = savedLfgAmountTime.set(amount, nowTime);
                let newSavedLfgAmount = savedLfgAmount.set(amount, lfgAmount);
                setSavedLfgAmountTime(newSavedLfgAmountTime);
                setSavedLfgAmount(newSavedLfgAmount);
                setLastCheckTime(nowTime);
            }
        } catch (e) {
            setConvertLock(false);
            setCheckResultState(2);
            let nowTime = new Date().getTime();
            setLastCheckTime(nowTime);
            console.error(e);
        }
    }

    const convertLongValue = (value) => {
        return Number(Number(value).toFixed(2)).toLocaleString("en-US");
    }

    const updateLfgAmount = (value) => {
        if (value == null || value <= 0) {
            setLfgAmount(0);
            return;
        }
        let nowTime = new Date().getTime();
        let savedAmountTime = savedLfgAmountTime?.get(value);
        let savedAmount = savedLfgAmount?.get(value);
        if (savedAmountTime != null && nowTime - savedAmountTime < 15 * 1000) {
            setLfgAmount(lfgAmount);
            if (value != 0) {
                setExchangeRate(savedAmount / value);
            }
        } else {
            if (convertTimer) {
                clearInterval(convertTimer);
            }
            let timer = setTimeout(() => {
                setConvertLock(true);
                quoteLfg(value, false);
            }, 1500);
            setConvertTimer(timer);
        }
    }

    const amountChangeHandler = (e) => {
        let c = Number(e.target.value);
        if (c || c === 0) {
            setMaticAmount(e.target.value);
            updateLfgAmount(c);
        }
    }

    const amountBlurHandler = (e) => {
        let c = Number(e.target.value);
        if (c || c === 0) {
            setMaticAmount(e.target.value);
            updateLfgAmount(c);
        }
    }

    const swapLfg = async () => {
        try {
            setSwapState(1);
            console.log('[ModalSwapLfg][maticAmount]', maticAmount, '[lfgAmount]', lfgAmount);
            await uniswapLFG({wallets, amount: Number(maticAmount), lfgAmount: Number(lfgAmount), sendTransaction});
            setMaticAmount('');
            setLfgAmount(0);
            setSwapState(3);
        } catch (e) {
            setSwapState(2);
            console.error(e);
        }
    }

    return (
        <div className={s.wrap}>
            <div className={`${s.currencyRoot} ${s.currencyRootBorder}`}>
                <div className={s.currencyTitle}>
                    {t('You pay')}
                </div>
                <div className={s.currencyContent}>
                    <Input ref={inputRef} className={s.maticInput}
                           value={maticAmount}
                           onChange={amountChangeHandler}
                           onBlur={amountBlurHandler}
                           disabled={convertLock}/>
                    <div className={s.rightRoot}>
                        <div className={s.currencyType}>
                            <CustomIcon width={24} height={24} className="mr5"
                                        imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                        name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                            <span className={s.currencyTypeText}>{`$MATIC`}</span>
                        </div>
                    </div>
                </div>
                <div className={s.currencyReserves}>
                    {t('Balance')}&nbsp;:&nbsp;{Number(Number(balance).toFixed(2)).toLocaleString("en-US")}
                </div>
            </div>
            <CustomIcon width={14} height={21} className={s.middleImg} imgName={'Button/UI_Button_Arrow_Down_01'}
                        name={'Button/UI_Button_Arrow_Down_01'}/>
            <div className={s.currencyRoot}>
                <div className={s.currencyTitle}>
                    {t('You receive')}
                </div>
                <div className={s.currencyContent}>
                    <div className={s.maticInputText}>
                        {convertLongValue(lfgAmount)}
                    </div>
                    <div className={s.rightRoot}>
                        <div className={s.currencyType}>
                            <CustomIcon width={24} height={24} className="mr5"
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            <span className={s.currencyTypeText}>{`$LFG`}</span>
                        </div>
                    </div>
                </div>
                <div className={s.currencyReserves}>
                    {t('Balance')}&nbsp;:&nbsp;{Number(Number(lfgBalance).toFixed(2)).toLocaleString("en-US")}
                </div>
            </div>
            <div className={s.exchangeRateRoot}>
                {(exchangeRate != null && exchangeRate > 0) && `${1} $MATIC = ${Number(exchangeRate.toFixed(2)).toLocaleString("en-US")} $LFG`}
            </div>
            <div className={s.buttonRoot}>
                {
                    swapState === 1 ?
                        <ButtonFactory>
                            <div className={s.btnLine}>
                                <CustomIcon rotating={true} className="mr5" width={20} height={20}
                                            imgName={'Picture/UI_Picture_Loading_01'}
                                            name={'Picture/UI_Picture_Loading_01'}/>
                                {t('PROCESSING')}
                            </div>
                        </ButtonFactory> :
                        swapState === 2 ?
                            <ButtonFactory
                                onClick={() => {
                                    closeFn();
                                }}
                            >
                                <div className={s.btnLine}>
                                    {t('SWAP FAILED')}
                                </div>
                            </ButtonFactory> :
                            swapState === 3 ?
                                <ButtonFactory
                                    onClick={() => {
                                        closeFn();
                                    }}
                                >
                                    <div className={s.btnLine}>
                                        {t('SWAP SUBMITTED')}
                                    </div>
                                </ButtonFactory> :
                                maticAmount == null || Number(maticAmount) <= 0 ?
                                    <ButtonFactory
                                        disabled={true}
                                    >
                                        {t('ENTER AN AMOUNT')}
                                    </ButtonFactory> :
                                    Number(maticAmount) > balance ?
                                        <ButtonFactory
                                            disabled={true}
                                        >
                                            {t('INSUFFICIENT BALANCE')}
                                        </ButtonFactory> :
                                        checkResultState === 1 ?
                                            <ButtonFactory
                                                disabled={true}
                                            >
                                                <div className={s.btnLine}>
                                                    <CustomIcon rotating={true} className="mr5" width={20} height={20}
                                                                imgName={'Picture/UI_Picture_Loading_01'}
                                                                name={'Picture/UI_Picture_Loading_01'}/>
                                                    {t('CHECKING')}
                                                </div>
                                            </ButtonFactory> :
                                            checkResultState === 2 ?
                                                <ButtonFactory
                                                    disabled={true}
                                                >
                                                    {t('GET RATIO FAILED')}
                                                </ButtonFactory> :
                                                <ButtonFactory
                                                    onClick={() => {
                                                        swapLfg();
                                                    }}
                                                >
                                                    {t('SWAP')}
                                                </ButtonFactory>
                }
            </div>
            <div className={s.tipRoot}>
                <div className={s.tipTitle}>
                    {t('NOTES')}
                </div>
                <div className={s.tipContent}>
                    {t('1. Our transactions are facilitated through the Uniswap protocol.')}
                </div>
                <div className={s.tipContent}>
                    {t('2. We maintain a maximum slippage of 1% and a transaction time limit of 5 minutes.')}
                </div>
                <div className={s.tipContent}>
                    {t('3. Should a transaction fail, Matic will be promptly refunded to your wallet.')}
                </div>
            </div>
        </div>
    )
}
export default memo(ModalSwapLfg);
