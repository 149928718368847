import React, { memo, useState, useMemo, useEffect, useRef, useCallback } from "react";
import s from "./index.module.less";
import { Modal, Button, Slider, Checkbox, Spin } from "antd";
import CustomIcon from "../../common/CustomIcon";
import AvatarInfo from "../../common/AvatarInfo";
import InputNumber from "./InputNumber";
import MomentChainToken from "@/utils/json/momentChainToken.json";
import MomentClubPack from "@/common/MomentClubPack";
import {
    stringToNumber,
    post_batch_price,
    keyBatchBuy,
    TransactionError,
    PrivyLoginStatusError,
    logoutSelf,
    unit,
    displayFilter,
    getAvatarFromProfile,
    lfgIconUrl,
    maticIconUrl,
    convertWalletBalance,
    getQuotes,
    getTokenBalanceOf,
} from "@/utils/common";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import TierConfig from "@/utils/json/TierConfig.json";
import { useTranslation } from "react-i18next";
import ModelDeposit from "../ModelDeposit";
import ModalInsufficientCrpto from "../ModalInsufficientCrpto";
import ButtonFactory from "src/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";
import { connect } from "react-redux";
import GasSetting from "../GasSetting";
import { getMomentClubPackGasPrice, getMomentTradeInfo, sellMomentClubPack, moment_owncardlist } from "@/utils/momentClub";

const ModalMomentSell = (
    {
        userInfo,
        walletBalanceList,
        setWalletBalanceList,
        closeHandle = () => { },
        type,
        upDate,
        tradeMemeClubInfo,
        defaultNum = 1,
        setMemeAssetParam,
        done, 
        setDone,
        subLoading, 
        setSubLoading,
        completeFn = () => { },
    }
) => {
    const { t, i18n } = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [allChecked, setAllChecked] = useState(0)
    const [showData, setShowData] = useState({});
    const [keyPrice, setKeyPrice] = useState(0);
    const [tokenUsd, setTokenUsd] = useState(0);
    const [fastGasPrice, setFastGasPrice] = useState(0);
    // const [done, setDone] = useState(false);
    // const [subLoading, setSubLoading] = useState(false);
    const [balance, setBalance] = useState(false);
    // const { balance } = userInfo?.selfData;
    const { walletAddr } = userInfo;
    const walletList = useRef([]);
    const keyIdList = useRef([]);
    const amountList = useRef([]);
    const expectedPricesList = useRef([]);
    const [noSuccessText, setNoSuccessText] = useState('Sell Failed');
    
    const [subFailded, setSubFailded] = useState(false);
    const [settleLoading, setSettleLoading] = useState(false)
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const [sellList, setSellList] = useState({});
    const [selectedList, setSelectedList] = useState([]);
    const [memeTradeInfo, setMemeTradeInfo] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [sellPackNoArr, setSellPackNoArr] = useState([]);
    const [sellPackNoAmountArr, setSellPackNoAmountArr] = useState([]);
    const [showGasMode, setShowGasMode] = useState(false);


    const {
        userId,
        clubName,
        coinId,
        holdNum,
        imageUrl,
        symbolName,
        ftTotalSupply,
        airdropPercent,
        openPackRatio,
        coinToNFTRatio,
        isFT,
    } = useMemo(() => {
        return tradeMemeClubInfo || {}
    }, [tradeMemeClubInfo]);
    const [cardList, setCardList] = useState([])

    const getOwncardlist = (userId) => {
        moment_owncardlist(userId).then(res => {
            // console.log(res?.cardList);
            setCardList(res?.ownCardList || [])
        }).catch((e) => {
            console.error(e);
        })
    }

    useEffect(() => {
        userId && getOwncardlist(userId)
    }, [userId])

    const {
        clubUserId,
        chainClubId,
        cardNum,
        isBuy,
        price,
        priceWithFee,
    } = useMemo(() => {
        return memeTradeInfo || {}
    }, [memeTradeInfo]);

    const currentCoinInfo = useMemo(() => {
        return MomentChainToken.find(i => i?.ID === coinId);
    }, [coinId]);

    const currencyIcon = useMemo(() => {
        return `Picture/${currentCoinInfo?.icon}`
    }, [currentCoinInfo]);

    const isAvailable = useMemo(() => Number(stringToNumber(balance, 4)) > Number(stringToNumber(Number(priceWithFee) + Number(fastGasPrice), 4)), [balance, priceWithFee, fastGasPrice]);


    const closeModal = async () => {
        setDone(false)
        closeHandle()
    };


    const { logout } = usePrivy();
    const closeFn = () => {
        if (noSuccessText === PrivyLoginStatusError) {
            logout();
            logoutSelf();
        }
        closeModal();
    }

    const { wallets } = useWallets();

    const sellPacks = () => {

        sellMomentClubPack({
            wallets,
            clubId: Number(chainClubId),
            cardArr: sellPackNoArr,
            amountArr: sellPackNoAmountArr, 
            coinInfo: currentCoinInfo
        }).then(res => {
            setSubLoading(false);
            setDone(true);
            setSubFailded(false);
            getOwncardlist(userId)
            setTimeout(()=>{
                upDate();
            },2000)
           
        }).catch(e => {
            console.log(e);
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }

            if (e === 'need login') {
                setNoSuccessText(PrivyLoginStatusError)
            }

            setSubLoading(false);
            setDone(true);
            setSubFailded(true)
        })
    }


    const transFn = () => {
        if (subLoading) return
        // if (!isAvailable) {
        //     setShowInsufficientCrpto(true);
        //     return
        // }
        setSubLoading(true);
        sellPacks()
    }

    useEffect(() => {
        console.log(currentCoinInfo);
        if (currentCoinInfo){
            getQuotes([currentCoinInfo.coinName]).then(res => {
                setTokenUsd(res?.quotes?.[currentCoinInfo.coinName]);
            }).catch(e => {
                console.log(e);
            })
            getTokenBalanceOf(walletAddr, [currentCoinInfo?.ID]).then(res=>{
                setBalance(res?.balances[currentCoinInfo?.ID] || 0)
            })
        }
    }, [currentCoinInfo])

    // useEffect(() => {
    //     setSettleLoading(true)
    // }, [buyList.current])


    const isChecked = (id) => {
        if (selectedList?.indexOf(id) >= 0) {
            return true;
        }
        else {
            return false;
        }
    }

    const isSelectedAll = () => {
        let res = true;
        if (cardList?.length !== selectedList?.length) {
            res = false;
        }
        else {
            for (let i = 0; i < cardList?.length; i++) {
                let number = cardList[i]?.cardNo;
                if (selectedList.indexOf(number) == -1) {
                    res = false;
                    break;
                }
            }
        }
        return res;
    }

    const selectAll = () => {
        let tempSellList = {}, _selectedList = [];
        if (cardList?.length > 0) {
            for (let i = 0; i < cardList?.length; i++) {
                tempSellList[cardList[i].cardNo] = cardList[i].cardAmount;
                _selectedList.push(cardList[i].cardNo)
            }
        }
        setSellList(tempSellList);
        setSelectedList(_selectedList)
    }


    useEffect(()=>{
       cardList?.length && selectAll()
    },[cardList])

    const selectRetainOne = () => {
        let tempSellList = {}, _selectedList = [];;
        if (cardList?.length > 0) {
            for (let i = 0; i < cardList?.length; i++) {
                let setCount = Math.max(0, cardList[i].cardAmount - 1);
                tempSellList[cardList[i].cardNo] = setCount;
                _selectedList.push(cardList[i].cardNo)
            }
        }
        setSellList(tempSellList);
        setSelectedList(_selectedList)
    }

    useEffect(() => {
        console.log(selectedList, sellList);
    }, [selectedList, sellList])

    useEffect(() => {
        if (JSON.stringify(sellList) !== '' && selectedList?.length > 0) {
            let _sellList = {}
            for (let key in sellList) {
                if (selectedList.indexOf(Number(key)) !== -1 && sellList[key] !== 0) {
                    _sellList[key] = sellList[key]
                }
            }
            // const _sellList = Object.keys(sellList).filter(key=>sellList[key] !== 0 && selectedList.indexOf(Number(key)) !== -1)
            console.log(_sellList);
            const keys = Object.keys(_sellList).map(Number);
            const values = Object.values(_sellList);
            const sum = values.reduce((acc, curr) => Number(acc) + Number(curr), 0);
            console.log(keys, values, sum);
            setTotalAmount(sum)
            setSellPackNoArr(keys)
            setSellPackNoAmountArr(values)
        }
    }, [sellList, selectedList])

    const getKeyPrice = () => {

        setSettleLoading(true);
        getMomentTradeInfo(userId, totalAmount, false).then(res => {
            setMemeTradeInfo({ ...res });
            getMomentClubPackGasPrice(wallets, currentCoinInfo).then(res => {
                console.log("getMomentClubPackGasPrice", res);
                setFastGasPrice(res || 0);
                setSettleLoading(false);
            }).catch(e => {
                setSettleLoading(false);
                console.log(e);
            })
        }).catch(e => {
            setSettleLoading(false)
            console.log(e);
        })
    }


    useEffect(() => {
        totalAmount > 0 && getKeyPrice();
    }, [totalAmount, userId])




    return (
        <div className={`${s.container} flex_center_start_col`}>
            <div className={`${s.title} momentFont`}>
                {t('Sell Packs 1')}
            </div>
            <div className={`${s.cardList} flex_center_start_col`}>
                {
                    cardList?.length > 0 &&
                    cardList.map((item, index) => {
                        const {
                            cardNo, 
                            cardAmount, 
                            cardUrl, 
                            openCoin, 
                            expectedPrice
                        } = item || {};
                        return (
                            <div className={`${s.cardInfoLine} flex_center_between`} key={index}>
                                <div className={`flex_center_start`}>
                                    {
                                        isChecked(item?.cardNo) ?
                                            <div className={`${s.checked}`} style={{ backgroundImage: `url(/UI/Picture/UI_Picture_Icon_Tick_w_01.png)` }} onClick={() => {
                                                let tempList = selectedList.filter((item2) => {

                                                    return item2 != item?.cardNo;
                                                })
                                                setSelectedList(tempList);
                                            }}>
                                            </div> :
                                            <div className={`${s.unchecked}`} onClick={() => {
                                                let tempList = [...selectedList];
                                                if (tempList.indexOf(item?.cardNo) == -1) {
                                                    tempList.push(item?.cardNo);
                                                    setSelectedList(tempList);
                                                }
                                            }}>
                                            </div>
                                    }
                                    {/*<div className={`${s.numberRoot} flex_center_center`}>
                                        <div className={`${s.number} momentFont flex_center_center`}>
                                            {`#${item?.cardNo}`}
                                        </div>
                                    </div>*/}
                                    <div className={`${s.cardImageRoot}`}>
                                        <MomentClubPack
                                            width={44}
                                            height={44}
                                            imageUrl={cardUrl || '/UI/Picture/UI_Picture_Empty_Bg_01.png'}
                                            packName={''}
                                            number={cardNo}
                                            view={cardUrl ? true : false}
                                            className={s.packItem}>
                                        </MomentClubPack>
                                    </div>
                                    <div className={`${s.packName} momentFont`}>
                                        {`Moment #${item?.cardNo}`}
                                    </div>
                                    {/*<div className={`${s.packCount}`}>
                                        {`total: ${item?.cardAmount}`}
                                    </div>*/}
                                </div>
                                <div className={`momentFont flex_center_end fs18`}>
                                    <InputNumber 
                                        min={0} 
                                        type={2}
                                        value={sellList?.[item?.cardNo] || 0}
                                        onChange={($event) => {
                                            let tempList = { ...sellList };
                                            tempList[item?.cardNo] = $event;
                                            setSellList(tempList);
                                        }} 
                                        max={item?.cardAmount} />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <div className={`${s.buttonLine} flex_center_start_col`}>
                <span className="momentFont fs12 color-moment-gray">{t('Quickly set quantity to 2')}</span>
                <div className={`flex_center_between w100p mt10`}>
                    <AntButton size="small"
                        className={`${s.btn_border}`}
                        style={{ fontWeight: 'normal', width: '60px' }}
                        onClick={() => {
                            selectAll();
                        }}>{t('All')}</AntButton>
                    <AntButton size="small"
                        className={`${s.btn_border}`}
                        style={{ fontWeight: 'normal', width: '120px' }}
                        onClick={() => {
                            selectRetainOne();
                        }}>{t('Retain 1')}</AntButton>
                </div>
            </div>
            <div className={`${s.line}`}>
            </div>
            <div className={`${s.bottomSettleSweep}`}>
                <Spin spinning={settleLoading}>
                    <div className={s.settlePanel}>
                        {/*<div className={`${s.selectAll} flex_start_start`}>
                            {
                                isSelectedAll() ?
                                    <div className={`${s.checked}`} style={{ backgroundImage: `url(/UI/Picture/UI_Picture_Icon_Tick_w_01.png)` }} onClick={() => {
                                        let tempList = [];
                                        setSelectedList(tempList);
                                    }}>
                                    </div> :
                                    <div className={`${s.unchecked}`} onClick={() => {
                                        let tempList = [];
                                        if (cardList?.length > 0) {
                                            for (let i = 0; i < cardList?.length; i++) {
                                                tempList.push(cardList[i].cardNo);
                                            }
                                        }
                                        setSelectedList(tempList);
                                    }}>
                                    </div>
                            }
                            <div className={`fs12 fb ml8 lh1`}>
                                {t('Select All')}
                            </div>
                        </div>*/}
                        <div className={`${s.totalInfo} flex_center_center`}>
                            <div className={`flex_center_center mr10`}>
                                <CustomIcon width={29} height={29} imgName={`Picture/${currentCoinInfo?.icon}`} />
                                <div className={`momentFont fs22 fb ml5`}>
                                    {convertWalletBalance(priceWithFee)}
                                </div>
                            </div>
                            <div className={`momentFont fs14 color-moment-gray fb lh1`}>
                                {`$ ${stringToNumber(Number(priceWithFee) * tokenUsd, 2)}`}
                            </div>
                        </div>
                        {/*<div className="df">
                            <div className={s.label_list}>
                                <div className={s.label}>{t('Order Details')}</div>
                                <div className={s.label}>{t('Total(inc. fees)')}</div>
                                <div className={s.label}>{t('Available')}</div>
                            </div>
                            <div>
                                <div>
                                    <CustomIcon width={12} height={12} imgName={currencyIcon} />
                                    <span className={s.coin}>{convertWalletBalance(priceWithFee)}</span>
                                    <span className={s.money}>${stringToNumber(Number(priceWithFee) * tokenUsd, 2)}</span>
                                </div>
                                <div>
                                    <CustomIcon width={12} height={12} imgName={currencyIcon} />
                                    <span
                                        className={s.coin}>{convertWalletBalance(Number(priceWithFee) + Number(fastGasPrice))} </span>
                                    <span
                                        className={s.money}>${stringToNumber((Number(priceWithFee) + Number(fastGasPrice)) * tokenUsd, 2)}</span>
                                </div>
                                <div>
                                    <CustomIcon width={12} height={12} imgName={currencyIcon} />
                                    <span className={s.coin}>{convertWalletBalance(balance)}</span>
                                    <span className={s.money}>${stringToNumber(balance * tokenUsd, 2)}</span>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </Spin>
            </div>
            <div className={`${s.buttonRoot} flex_center_center`}>
                {
                    done ? 
                        <AntButton
                            onClick={() => {
                                closeFn();
                            }}
                            className={`${s.commonBtn} btn_public`}>
                            <div className="fs16 fb momentFont color-black">
                                {subFailded ? noSuccessText : t('TRANSACTION SUCCESSFUL')} 
                            </div>
                        </AntButton> :
                    subLoading ?
                        <AntButton
                            className={`${s.commonBtn} btn_public`}>
                            <div className={`flex_center_center`}>
                                <CustomIcon rotating={true} className="mr5" width={18} height={18}
                                    imgName={'Picture/UI_Picture_Loading_01'}/>
                                <div className="fs16 fb momentFont color-black">
                                    {t('PROCESSING')}
                                </div>
                            </div>
                        </AntButton> :
                    settleLoading ?
                        <AntButton
                            className={`${s.commonBtn} btn_public`}>
                            <div className={`flex_center_center`}>
                                <CustomIcon rotating={true} className="mr5" width={18} height={18}
                                    imgName={'Picture/UI_Picture_Loading_01'}/>
                                <div className="fs16 fb momentFont color-black">
                                    {t('SELL NOW')}
                                </div>
                            </div>
                        </AntButton> :
                    totalAmount > 0 ?
                        <AntButton
                            onClick={() => {
                                transFn();
                            }}
                            className={`${s.commonBtn} btn_public`}>
                            <div className="fs16 fb momentFont color-black">
                                {t('SELL NOW')}
                            </div>
                        </AntButton> :
                        <AntButton
                            className={`${s.commonBtn} btn_disabled`}>
                            <div className="fs16 fb momentFont color-moment-light-gray">
                                {t('SELL NOW')}
                            </div>
                        </AntButton>
                }
                <CustomIcon 
                    width={30} 
                    height={30}
                    className={`${s.gasBtn}`}
                    imgName={'Button/UI_Button_Gas_01'}
                    onClick={() => {
                        setShowGasMode(true);
                    }}
                />
            </div>
            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeHandle();
                }} />
            <AntModal
                width='330px'
                title={t('INSUFFICIENT BALANCE')}
                className="confirmModalWrap"
                centered={true}
                open={showInsufficientCrpto}
                onOk={() => setShowInsufficientCrpto(false)}
                onCancel={() => setShowInsufficientCrpto(false)}
                zIndex={7777}
            >
                <ModalInsufficientCrpto
                    insufficientAmount={Number(stringToNumber(Number(priceWithFee) + Number(fastGasPrice), 4)) - Number(stringToNumber(balance, 4))}
                    userInfo={userInfo} closeFn={() => {
                        setShowInsufficientCrpto(false);
                    }}
                    type={2} />
            </AntModal>
            <AntDrawer
                title={t('Gas Mode')}
                placement={'right'}
                onClose={() => setShowGasMode(false)}
                width={246}
                open={showGasMode}
                className="momentGasFilter"
                zIndex={7778}
            >
                <GasSetting
                    closeHandle={() => {
                        setShowGasMode(false);
                    }}
                    type={2}
                />
            </AntDrawer>
        </div>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        walletBalanceList: app.walletBalanceList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setMemeAssetParam: (params) => {
            return dispatch({
                type: "app/setMemeAssetParam",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalMomentSell));
