import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "src/common/CustomIcon";
import {formatNumberWithUnit, numberClamp} from "@/utils/common";
import StakeBonusMultiplier from '@/utils/json/StakeBonusMultiplier.json';


const ModelReferralStakeMultiplierTips = (
    {
        currLevel,
    }
) => {
    const {t, i18n} = useTranslation();

    return (
        <div className={s.wrap}>
            <div className={s.topPart}>
                <div className={s.content}>
                    <div className={`${s.contentText} mt25 fs13`}>
                        <Trans
                            i18nKey="Staking Bonus Multiplier Tips 01"
                            defaults="This is determined by the amount of $LFG you have staked <br/> Total Bonus Pool–Staking Bonus Claimed <br/> Claimable Staking Bonus–Claimable Bonus "
                            components={{
                                font: <span style={{color: '#fab600'}}></span>,
                                fb: <span className="fb"></span>,
                                br: <br/>,
                            }}
                        />
                        <br/>
                        <br/>
                        <table>
                            <thead>
                            <tr>
                                <th>{t("My Staked $LFG")}</th>
                                <th>{t("Staking Bonus Multiplier")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {StakeBonusMultiplier.map((item, index) => {
                                return (
                                    <tr className={`${item.ID === currLevel ? s.curr : ""}`}>
                                        <td>
                                            {formatNumberWithUnit(item.stakedMin, 0)} -&nbsp;
                                            {formatNumberWithUnit(item.stakedMax, 0)}
                                        </td>
                                        {item.multiplier !== 0 && <td>{(item.multiplier / 100).toFixed(1)} %</td>}
                                        {item.multiplier === 0 && <td>{t('none')}</td>}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default memo(ModelReferralStakeMultiplierTips);
