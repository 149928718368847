import React from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { gameShareDetailPath, gameTrillionaireBattleLeaderBoardPath, mainProfilePath } from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelMemeCreatBasicTips = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className={s.modelWrap}>
            <div>{t('Launch your unigue meme collection with a limited set of collectible packs')}</div>
            <div className="mt20 mb20">{t('Once all packs are sold, your meme collection will officially launch on the market.')}</div>
            <div>{t('Start creating and trading your exclusive memes today')}</div>
            
        </div>
    )
}

export default ModelMemeCreatBasicTips
