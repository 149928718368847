import React, {memo, useEffect, useMemo, useState, useCallback, useRef} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Modal, Spin, Input} from 'antd';
import CustomIcon from "../CustomIcon";
import ChatContentHeader from "../ChatContentHeader";
import MediaPlayer from "@/components/MediaPlayer";
import InfiniteScroll from "react-infinite-scroll-component";
import ModelLiveSetting from "../../model/ModelLiveSetting";
import {joinChannel, leave, rtc, joinTrack, closeTrack} from "@/utils/basicVoiceCall";
import {
    getRtcTokenUri,
    timerDelay,
    agoraHostLivestate,
    getAgoraChannelInfo,
    createMsg,
    deliverMsg,
    convertToMessage,
    scrollToBottom,
    LIVE_WARP_ID,
    CHAT_TYPE,
    clgFn,
    agora_channel_joinstate,
    displayFilter,
    getAvatarFromProfile,
    getAvatarFromUserInfo
} from "@/utils/common"
import LiveMessageLeft from "../LiveMessageLeft";
import {useParams, useNavigate} from "react-router-dom";
import AntModal from "@/common/AntModal";

const {TextArea} = Input;
const LivePanel = (
    {
        curRtcInfo,
        setCurRtcInfo,
        isSelfChat,
        avatarurl,
        selfUid,
        nosleep,
        setOpenTranPanel,
        setShowOtherTransContent,
        backFn,
        userInfo,
        groupId,
        hasOneKey,
        setLiveMessage,
        liveMessage,
        profile,
        customChatMap,
        deleteChatCustomMessage,
        chatMap,
        showLivePanel,
        setNeedUpdateVoteInfo,
        keyId
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {userId} = useParams();
    const [openSettingPanel, setOpenSettingPanel] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [text, setText] = useState("");
    const inputRef = useRef();
    const [showQuote, setShowQuote] = useState(false)
    const scrollBottom = () => {
        setTimeout(() => {
            scrollToBottom(document.getElementById(LIVE_WARP_ID));
        }, 300);
    };
    const messageInfo = useMemo(() => {
        if (!groupId) {
            return {}
        }
        // console.log(chatMap[CHAT_TYPE.groupChat].get(groupId));

        return chatMap[CHAT_TYPE.groupChat].get(groupId) || {};
    }, [chatMap, groupId]);

    const {platformMap, useType, defaultName} = useMemo(() => {
        return userInfo?.platformData || {}
    }, [userInfo])

    const {displayName} = useMemo(() => {
        if (platformMap && JSON.stringify(platformMap) !== '{}') {
            return platformMap[useType]
        } else {
            return {
                displayName: defaultName
            }
        }
    }, [platformMap, useType, defaultName])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformMap, useType, userInfo])

    //发消息
    const sendMessage = useCallback(() => {
        // e.preventDefault();//阻止页面提交刷新
        if (!convertToMessage(text) && !groupId) return;
        // setShowInput(false)
        let msg = createMsg({
            chatType: CHAT_TYPE.groupChat,
            type: "txt",
            to: groupId,
            msg: convertToMessage(text),
            isChatThread: false,
            ext: {
                avatarUrl: avatarUrl || '',
                displayName: displayName || '',
                text: convertToMessage(text),
            }
        });

        setText("");
        inputRef.current.focus({cursor: 'end'})

        msg.localId = msg.id;
        console.log(msg);
        deliverMsg({msg, needShow: true, keyId})
        scrollBottom()
    }, [text, groupId]);

    useEffect(() => {
        showInput && inputRef.current.focus({cursor: 'end'})
    }, [showInput])

    const textHandler = (event) => {
        setText(event.target.value)
    }
    useEffect(() => {
        // clgFn('rtcLog rtc.remoteUsers', '', rtc.remoteUsers)
        if (rtc.remoteUsers.length) {
            setTimeout(() => {
                for (const item of rtc.remoteUsers) {
                    const {uid, hasAudio, hasVideo, audioTrack, videoTrack} = item;
                    if (uid?.toString() === userId) {
                        setCurRtcInfo({
                            audioTrack: hasAudio ? audioTrack : null,
                            videoTrack: hasVideo ? videoTrack : null
                        })
                    }
                }
            }, 200)

        }
    }, [rtc.remoteUsers, rtc.hostArr])

    const {videoTrack, audioTrack, role} = useMemo(() => {
        return curRtcInfo
    }, [curRtcInfo])

    let upTimer = null;


    // useEffect(() => {
    //   if (rtc.joinState) {
    //     setLiveLoading(false);
    //   }
    // }, [rtc.joinState])

    const updataChannelInfo = () => {
        getAgoraChannelInfo(userId).then(res => {
            // console.log(res);
            const {heat, onlineNum} = res;
            clgFn('rtcLog getAgoraChannelInfo', '', res)
            setCurRtcInfo({
                heat,
                onlineNum
            })
        }).catch(e => {
            console.log(e);
        })
    }

    const customEvent = {
        exitAgoraChannel: (message) => {
            console.log(`---->`, message);
            const {chatType, to, id, customExts} = message;
            if (!hasOneKey && customExts?.keyId === userId) {
                backFn()
            }
            deleteChatCustomMessage({
                chatType,
                id,
                fromId: to
            })
        }
    }

    useEffect(() => {

        if (isSelfChat) {
            if (rtc.joinState) {
                agora_channel_joinstate(keyId, rtc.joinState)
                agoraHostLivestate(rtc.joinState,parseInt(keyId),0).then().catch(e => console.log(e))
                updataChannelInfo()
                upTimer = setInterval(() => {

                    agoraHostLivestate(rtc.joinState,parseInt(keyId),0).then().catch(e => console.log(e));
                    updataChannelInfo()
                }, timerDelay)
            } else {
                agoraHostLivestate(rtc.joinState,parseInt(keyId),0).then().catch(e => console.log(e))
            }
            return () => {
                upTimer && clearInterval(upTimer);
                upTimer && agora_channel_joinstate(keyId, false)
            }
        } else {
            if (rtc.joinState) {
                agora_channel_joinstate(keyId, rtc.joinState)
                updataChannelInfo()
                upTimer = setInterval(() => {
                    updataChannelInfo()
                }, timerDelay)
            }
            return () => {
                upTimer && agora_channel_joinstate(keyId, false);
                upTimer && clearInterval(upTimer);
            }
        }
    }, [rtc.joinState])

    const customMessage = useMemo(() => {
        return customChatMap[CHAT_TYPE.groupChat].get(groupId) || {};
    }, [groupId, customChatMap]);

    useEffect(() => {
        if (customMessage?.list && customMessage.list.length) {
            const message = customMessage.list[0];
            const type = message.customEvent;
            switch (type) {
                case 'exitAgoraChannel':
                    customEvent.exitAgoraChannel(message)
                default:
                    break;
            }

        }
    }, [customMessage])

    const [title, setTitle] = useState('');

    const joinRtcChannel = () => {
        // setLiveLoading(true)
        try {

            getRtcTokenUri(userId).then(async (res) => {
                // console.log(res);
                const {channel_name, role, token, title} = res;

                setTitle(title);

                setCurRtcInfo({
                    userId: selfUid,
                    keyId: userId,
                    role,
                    channelName: channel_name,
                    title: title,
                })
                await joinChannel({
                    accessToken: token,
                    channel: channel_name,
                    agoraUid: selfUid
                })
                nosleep.enable()
                if (role === 1) {
                    joinTrack('audio');
                    // joinTrack('camera');
                }
            }).catch(e => {
                clgFn('rtcLog error', 'red', e)


            })
        } catch (error) {
            clgFn('rtcLog error', 'red', error)
            // setLiveLoading(false)
        }

    }

    useEffect(() => {
        !rtc.joinState && joinRtcChannel()
        rtc.joinState && leave()
    }, [])


    const leaveRtc = async () => {
        // setLeaveLoading(true);
        await leave();
        nosleep.disable();
        // setLeaveLoading(false);
    }

    return (
        <>
            {rtc.joinState &&
                <div className={s.wrap}>
                    <ChatContentHeader
                        setOpenTranPanel={setOpenTranPanel}
                        setShowOtherTransContent={setShowOtherTransContent}
                        backFn={backFn}
                        showLivePanel={showLivePanel}
                    />
                    <MediaPlayer avatarurl={getAvatarFromProfile(profile)} videoTrack={videoTrack} audioTrack={audioTrack}
                                 isIm={isSelfChat}/>
                    <div className={s.bottom}>
                        <div className={s.messageWrap} id={LIVE_WARP_ID}>
                            <InfiniteScroll
                                inverse={true}
                                dataLength={messageInfo?.list?.length || 0}
                                // next={loadMoreData}
                                hasMore={false}
                                style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                }}
                                endMessage=''
                                loader={<Spin/>}
                                scrollableTarget={LIVE_WARP_ID}
                            >
                                {messageInfo?.list?.map((item, index) => {
                                    return (
                                        item?.msg ? <div className={s.msgWrap} key={item.localId || item.id}>
                                            <LiveMessageLeft
                                                message={item}
                                                userId={userId}
                                                setShowQuote={setShowQuote}
                                                showQuote={showQuote}
                                                index={index}
                                                scrollBottom={scrollBottom}
                                            />
                                        </div> : null
                                    );
                                })}
                                <div className={s.msgWrap}>
                                    <div
                                        className={`${s.tips} color-blue-light`}>{t('welcome to xx\'s live', {name: displayFilter(profile?.displayName)})}</div>
                                </div>
                                {title !== '' && <div className={s.msgWrap}>
                                    <div className={s.tips}>{title}</div>
                                </div>}
                            </InfiniteScroll>
                        </div>
                        <div className={s.btnWrap}>
                            <div className={s.rtcEntry}>
                                {/* {hasOneKey && */}
                                <div className={s.form}>
                                    {/* <Input
                                    placeholder="Say something..."
                                    value={text}
                                    enterKeyHint="Send"
                                    className={s.input}
                                    onChange={textHandler}
                                    ref={inputRef}
                                    // onBlur={() => { setShowInput(false) }}
                                    onPressEnter={sendMessage}
                                    /> */}

                                    <TextArea
                                        placeholder={t('Say something...')}
                                        value={text}
                                        ref={inputRef}
                                        className={s.input}
                                        onChange={textHandler}
                                        rows={1}
                                        autoSize={{minRows: 1, maxRows: 3}}

                                    />
                                    <div className="ml5" onClick={sendMessage}>
                                        <CustomIcon width={32} height={32} imgName={'Button/UI_Button_SendMessage_01'}
                                                    name={'Button/UI_Button_SendMessage_01'}/>
                                    </div>
                                </div>
                                {/* } */}
                                {
                                    rtc.joinState && isSelfChat &&
                                    <div className={s.control}>
                                        <Spin size="middle" spinning={rtc.camera_loading}>
                                            <div className={s.item}>
                                                <div className={s.btnBG}>
                                                    <CustomIcon
                                                        width={24}
                                                        height={24}
                                                        imgName={`${rtc.localVideoTrack ? 'Button/UI_Button_Camera_On' : 'Button/UI_Button_Camera_Off'}`}
                                                        name={`${rtc.localVideoTrack ? 'Button/UI_Button_Camera_On' : 'Button/UI_Button_Camera_Off'}`}
                                                        onClick={() => {
                                                            rtc.localVideoTrack ? closeTrack('camera') : joinTrack('camera')
                                                        }}
                                                    />
                                                </div>
                                                <div>{rtc.localVideoTrack ? t('Camera On') : t('Camera Off')}</div>
                                            </div>
                                        </Spin>
                                        <Spin size="middle" spinning={rtc.audio_loading}>
                                            <div className={s.item}>
                                                <div className={s.btnBG}>
                                                    <CustomIcon
                                                        width={24}
                                                        height={24}
                                                        imgName={`${rtc.localAudioTrack ? 'Button/UI_Button_Microphone_On' : 'Button/UI_Button_Microphone_Off'}`}
                                                        name={`${rtc.localAudioTrack ? 'Button/UI_Button_Microphone_On' : 'Button/UI_Button_Microphone_Off'}`}
                                                        onClick={() => {
                                                            rtc.localAudioTrack ? closeTrack('audio') : joinTrack('audio')
                                                        }}
                                                    />
                                                </div>
                                                <div>{rtc.localAudioTrack ? t('Mic On') : t('Mic Off')}</div>
                                            </div>
                                        </Spin>
                                        <div className={s.item}>
                                            <div className={s.btnBG}>
                                                <CustomIcon
                                                    width={24}
                                                    height={24}
                                                    imgName={'Picture/UI_Picture_InterfaceChat_01'}
                                                    name={'Picture/UI_Picture_InterfaceChat_01'}
                                                    onClick={() => {
                                                        setShowInput(true)
                                                    }}
                                                />
                                            </div>
                                            <div>{t('Chat')}</div>
                                        </div>
                                        <div className={s.item}>
                                            <div className={s.btnBG}>
                                                <CustomIcon
                                                    width={24}
                                                    height={24}
                                                    imgName={'Picture/UI_Picture_SetUp_01'}
                                                    name={'Picture/UI_Picture_SetUp_01'}
                                                    onClick={() => {
                                                        setOpenSettingPanel(true)
                                                    }}
                                                />
                                            </div>
                                            <div>{t('Settings')}</div>
                                        </div>
                                        {/* <div className={s.item} onClick={()=>{setOpenSettingPanel(true)}}>Setting</div> */}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>}

            <AntModal
                width='330px'
                title={t('Settings')}
                className="confirmModalWrap"
                centered={true}
                open={openSettingPanel}
                destroyOnClose={true}
                onOk={() => setOpenSettingPanel(false)}
                onCancel={() => setOpenSettingPanel(false)}
            >
                <ModelLiveSetting setOpenSettingPanel={setOpenSettingPanel}
                                  setNeedUpdateVoteInfo={setNeedUpdateVoteInfo}/>
            </AntModal>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        curRtcInfo: app.curRtcInfo,
        userInfo: app.userInfo,
        liveMessage: app.liveMessage,
        customChatMap: app.customChatMap,
        chatMap: app.chatMap,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurRtcInfo: (params) => {
            return dispatch({
                type: "app/setCurRtcInfo",
                payload: params,
            });
        },
        setLiveMessage: (params) => {
            return dispatch({
                type: "app/setLiveMessage",
                payload: params,
            });
        },
        deleteChatCustomMessage: (params) => {
            return dispatch({
                type: "app/deleteChatCustomMessage",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(LivePanel));
