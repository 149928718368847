import React from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { gameShareDetailPath, gameTrillionaireBattleLeaderBoardPath, mainProfilePath } from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelUpPointsReward = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className={s.modelWrap}>
            <div className={s.item}>{t('Playing X Farming to earn more $UP Points')}</div>
            <div className={s.item}>{t('More unclaimed $LFG = higher leaderboard rank. The rank impacts the daily $UP points earnings.')}</div>
        </div>
    )
}

export default ModelUpPointsReward
