import {ethers} from "ethers";
import {abi_mint} from "@/utils/abi";
import {isOnlineEnv} from "./env";
import {getConfig} from "./common";
import {getGasConfig} from "@/utils/gas";

function stringToHex(str) {
    const val = [...str].map(c => c.charCodeAt(0).toString(16).padStart(2, 0)).join``
    return '0x' + val;
}

async function getMintContract(wallets, isWriter) {

    const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

    const {lfgFactoryAddr, chainId} = await getConfig();
    console.log("[lfgFactoryAddr]", lfgFactoryAddr, "[chainId]", chainId);

    await embeddedWallet.switchChain(chainId);

    const provider = await embeddedWallet.getEthersProvider();

    if (isWriter) {
        const signer = provider.getSigner();

        const gasConfig = await getGasConfig(signer);
        const contract = new ethers.Contract(lfgFactoryAddr, abi_mint, provider).connect(signer);
        return {contract, addr: embeddedWallet.address, gasConfig};
    } else {
        const contract = new ethers.Contract(lfgFactoryAddr, abi_mint, provider);
        return {contract, addr: embeddedWallet.address};
    }
}

export const applyMint = ({wallets, amount, orderId, lockedUntil, timestamp, validFor, signature}
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {contract, addr, gasConfig} = await getMintContract(wallets, true);

            const amountWei = ethers.utils.parseEther(amount.toString());
            const sig = stringToHex(atob(signature));

            console.log(
                "orderId", orderId,
                "amount", ethers.utils.formatUnits(amountWei, "wei"),
                "to", addr,
                "lockedUntil", lockedUntil,
                "timestamp", timestamp,
                "validFor", validFor,
                "sig", sig);

            if (!isOnlineEnv()) {
                const forBurn = false;
                const hashForApplyToMintLfg = await contract.hashForApplyToMintLfg(orderId, amountWei, lockedUntil, forBurn, addr, timestamp, validFor);
                console.log("[hashForApplyToMintLfg]", hashForApplyToMintLfg);
                const checkSignResult = await contract.signedByValidator(hashForApplyToMintLfg, sig);
                console.log("[signedByValidator check]", checkSignResult);
            }

            contract.applyToMintLfg(
                orderId,
                amountWei,
                lockedUntil,
                timestamp,
                validFor,
                sig,
                gasConfig
            ).then(resp =>
                resolve(resp)
            ).catch(e => {
                console.log('[contract applyToMintLfg] exception: ' + e);
                reject(e);
            });
        } catch (e) {
            console.log("[applyToMintLfg] e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export const applyMintAndStake = (
    {
        wallets,
        amount,
        orderId,
        lockedUntil,
        stakeLockedUntil,
        timestamp,
        validFor,
        signature
    }
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {contract, addr, gasConfig} = await getMintContract(wallets, true);

            const amountWei = ethers.utils.parseEther(amount.toString());
            const sig = stringToHex(atob(signature));
            console.log(
                "orderId", orderId,
                "amount", ethers.utils.formatUnits(amountWei, "wei"),
                "to", addr,
                "lockedUntil", lockedUntil,
                "stakeLockedUntil", stakeLockedUntil,
                "timestamp", timestamp,
                "validFor", validFor,
                "sig", sig);

            if (!isOnlineEnv()) {
                const hashForApplyToMintLfgAndStake = await contract.hashForApplyToMintLfgAndStake(orderId, amountWei, lockedUntil, stakeLockedUntil, addr, timestamp, validFor);
                console.log("[hashForApplyToMintLfgAndStake]", hashForApplyToMintLfgAndStake);
                const checkSignResult = await contract.signedByValidator(hashForApplyToMintLfgAndStake, sig);
                console.log("[signedByValidator check]", checkSignResult);
            }

            contract.applyToMintLfgAndStake(
                orderId,
                amountWei,
                lockedUntil,
                stakeLockedUntil,
                timestamp,
                validFor,
                sig,
                gasConfig
            ).then(resp =>
                resolve(resp)
            ).catch(e => {
                console.log('[contract applyToMintLfgAndStake] exception: ' + e);
                reject(e);
            });
        } catch (e) {
            console.log("[applyToMintLfgAndStake] e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export const bornLfg = ({wallets, amount, mintNow, orderId, reason, timestamp, validFor, signature}
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {contract, addr, gasConfig} = await getMintContract(wallets, true);

            const amountWei = ethers.utils.parseEther(amount.toString());
            const sig = stringToHex(atob(signature));

            console.log(
                "orderId", orderId,
                "amount", ethers.utils.formatUnits(amountWei, "wei"),
                "mintNow", mintNow,
                "reason", reason,
                "timestamp", timestamp,
                "validFor", validFor,
                "sig", sig);

            if (!isOnlineEnv()) {
                const hashBurnLfg = await contract.hashBurnLfg(orderId, addr, reason, amountWei, mintNow, timestamp, validFor);
                console.log("[hashBurnLfg]", hashBurnLfg);
                const checkSignResult = await contract.signedByValidator(hashBurnLfg, sig);
                console.log("[signedByValidator check]", checkSignResult);
            }

            contract.burnLfg(
                orderId,
                amountWei,
                mintNow,
                reason,
                timestamp,
                validFor,
                sig,
                gasConfig
            ).then(
                resp => resolve(resp)
            ).catch(e => {
                console.log("[contract burnLfg] exception: " + e);
                reject(e);
            });
        } catch (e) {
            console.log("[burnLfg] exception: " + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export const claimAllPendingLfg = ({wallets}
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {contract, addr, gasConfig} = await getMintContract(wallets, true);

            console.log("[claimAllPendingLfg]", addr);

            contract.claimAllPending(
                gasConfig
            ).then(
                resp => resolve(resp)
            ).catch(e => {
                console.log("[contract claimAllPendingLfg] exception: " + e);
                reject(e);
            });
        } catch (e) {
            console.log("[claimAllPendingLfg] exception: " + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

//Viewer Function
export const getMintRequest = ({wallets}
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {contract, addr} = await getMintContract(wallets, false);
            const requestRecords = await contract.getMintRequest(addr);

            ///console.log("[getMintRequest]", requestRecords);

            if (requestRecords) {
                const res = {
                    orderId: Number(requestRecords.orderId || 0),
                    amount: Number(ethers.utils.formatUnits(requestRecords.amount || 0, "ether")),
                    lockedUntil: Number(requestRecords.lockedUntil || 0),
                }
                resolve(res);
            } else {
                reject("getMintRequest get null");
            }
        } catch (e) {
            console.log("[getMintRequest] e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export const getMintAndStakeRequest = ({wallets}
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {contract, addr} = await getMintContract(wallets, false);
            const requestRecords = await contract.getMintAndStakeRequest(addr);

            if (requestRecords) {
                const res = {
                    orderId: Number(requestRecords.orderId || 0),
                    amount: Number(ethers.utils.formatUnits(requestRecords.amount || 0, "ether")),
                    lockedUntil: Number(requestRecords.lockedUntil || 0),
                    requestedAt: Number(requestRecords.requestedAt || 0),
                    stakeLockedUntil: Number(requestRecords.stakeLockedUntil || 0),
                }
                resolve(res);
            } else {
                reject("getMintAndStakeRequest get null");
            }
        } catch (e) {
            console.log("[getMintAndStakeRequest] e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

//Viewer Function
export const checkSigUsed = ({wallets, sig}
) => {
    return new Promise(async (resolve, reject) => {

        try {
            const {contract, addr} = await getMintContract(wallets, false);

            const result = await contract.isSignatureUsed(sig);
            console.log("[isSignatureUsed]", result);

            resolve(result);

        } catch (e) {
            console.log("[checkSigUsed] e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}
