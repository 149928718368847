import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button} from "antd";
import CustomIcon from "../../../common/CustomIcon";
import ButtonFactory from "@/common/ButtonFactory";


const ModelEliminated = (
    {
        setShowEliminated,
    }
) => {
    const {t, i18n} = useTranslation();
    return (
        <div className={s.wrap}>
            <div>
                <CustomIcon width={130} height={130} imgName={'SadEmoji'} name={'SadEmoji'}/>
            </div>
            <div className="fs15 color-white">
                <Trans
                    i18nKey="YOU'VE BEEN ELIMINATED!"
                    defaults="YOU'VE BEEN <br> ELIMINATED!"
                    components={{br: <br/>}}
                />
            </div>
            <div className="fs14 color-white mtb20">
                {t('You can no longer play for the Daily Prize, but you can still watch and chat to others.')}
            </div>
            <ButtonFactory className="modelBottomBtn" onClick={() => {
                setShowEliminated(false);
            }}>{t('CONTINUE')}</ButtonFactory>
        </div>
    )
}
export default memo(ModelEliminated);
