import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import Login from "@/views/public/Login";
import NewHome from "@/views/NewHome";
import NewExplore from "@/views/NewExplore";
import NFTPanel from "@/views/main/Bid/NFTPanel";
import AllClubs from "@/views/main/Club/AllClubs";
import SweepClubs from "@/views/main/Club/SweepClubs";
import Profile from "@/views/main/Profile";
import Chat from "@/views/public/Chat";
import Callback from "@/views/public/Callback";
import Balance from "src/common/Balance";
import Link from "src/common/Link";
import Referral from "@/views/games/Referral";
import MomentReferral from "@/views/games/MomentReferral";
import WishCreate from "src/common/WishCreate";
import WishContent from "@/views/public/WishContent";
import NftContent from "@/views/public/NftContent";
import WishLink from "src/common/WishLink";
import WishClaim from "src/common/WishClaim";
// import Rank from "@/views/games/trillionaire/Rank";
import Invite from "src/common/Invite";
import BidMyBids from "src/views/main/Bid/BidMyBids";
import BidItemDetail from "src/views/main/Bid/BidItemDetail";
import BidHistory from "src/views/main/Bid/BidHistory";
import ModelMyNFT from "src/views/main/Bid/ModelMyNFT";
import BidSweepNFT from "src/views/main/Bid/BidSweepNFT";
import ShareDetail from "@/views/games/ShareDetail";
import EcosystemDetail from "src/views/games/trillionaire/Ecosystem/WarfareDetail";
import Ecosystem from "@/views/games/trillionaire/Ecosystem/comingsoon";
import MyClubs from "@/views/main/MyClubs";
import BattleHome from "@/views/games/trillionaire/BattleHome";
import BattleLeaderBoard from "@/views/games/trillionaire/Battle/BattleLeaderBoard";
import Battling from "@/views/games/trillionaire/Battle/Battling";
import Mission from "@/views/games/trillionaire/Mission/";
import Assets from "@/views/games/trillionaire/Assets";
import Stake from "@/views/games/Stake";
import Launcher from "@/views/games/MemeClub/Launcher";
import Dispatch from "@/views/games/trillionaire/Mission/Dispatch";
import ShareUpPoints from "@/views/games/ShareUpPoints";
import MemeClub from "@/views/games/MemeClub/ClubList";
import MemeClubDetail from "@/views/games/MemeClub/ClubDetail";
import MemeClubCreat from "@/views/games/MemeClub/Creat";
import RevealMemePack from "@/views/games/MemeClub/RevealMemePack";
import MemeClubUserDetail from "@/views/games/MemeClub/MemeClubUserDetail";
import MemeClubTge from "@/views/games/MemeClub/TGE";
import MemeLottery from "@/views/games/MemeClub/MemeLottery";
import MemeCreate from "@/views/games/MomentClub/MemeCreate";
import MemeClubAssets from "@/views/games/MemeClub/Assets";
import MemeExplore from "@/views/games/MomentClub/MemeExplore";
import MemeLauncher from "@/views/games/MomentClub/MemeLauncher";
import MomentLauncher from "@/views/games/MomentClub/MomentLauncher";
import MomentReferralEarn from "@/views/games/MomentReferralEarn";
import MemeTge from "@/views/games/MomentClub/MemeTge";
import MemeAssets from "@/views/games/MomentClub/MemeAssets";
import MemeClubChat from "@/views/games/MemeClub/Chat";
import MomentClubCreat from "@/views/games/MomentClub/Creat"
import MomentClubDetail from "@/views/games/MomentClub/ClubDetail"
import ClubDetailTotal from "@/views/games/MomentClub/ClubDetailTotal";
import RevealMomentPack from "@/views/games/MomentClub/RevealMomentPack";
import QuickLinkMoments from "@/views/public/QuickLinkMoments";
import MomentUserDetail from "@/views/games/MomentClub/MomentUserDetail";
import {
    _public,
    _main,
    _game,
    mainHome,
    mainExplore,
    mainMyclubs,
    mainProfile,
    gameTrillionaireMission,
    _battle,
    gameTrillionaireBattle,
    gameTrillionaireBattleLeaderBoard,
    gameTrillionaireBattling,
    gameTrillionaireEcosystem,
    gameTrillionaireEcosystemDetail,
    gameTrillionaireAssets,
    gameShareDetail,
    gameReferral,
    gameMomentReferral,
    publicChats,
    publicWishCreate,
    publicWishLink,
    publicWishClaim,
    publicWishes,
    publicNftContent,
    _mainNft,
    mainNftNftPanel,
    mainNftBidMyBids,
    mainNftBidHistory,
    mainNftBidMynft,
    mainNftBidSweep,
    mainNftBidDetail,
    _mainClub,
    mainClubAllClubs,
    mainClubSweepClubs,
    link,
    invite,
    callback,
    balance,
    gameTrillionaire,
    gameStake,
    gameTrillionaireMissionDetailPath,
    gameTrillionaireMissionDetail,
    gameMemeClubUserDetail,
    gameShareUpPoint,
    gameMemeClub,
    gameMemeClubCreat,
    gameMemeClubDetail,
    gameMemeClubNftDetail,
    gameMomentClub,
    gameMomentClubExplore,
    gameMomentClubLauncher,
    gameMomentReferralEarn,
    gameMomentClubTge,
    gameMomentClubAssets,
    gameMemeClubAssets,
    gameMemeClubRevealMemePack,
    gameMemeClubTge,
    gameMemeClubLauncher,
    gameMemeClubLottery,
    gameMomentClubCreat,
    gameMemeClubChat,
    gameMomentClubDetail,
    gameMomentClubRevealMomentPack,
    publicQuickLinkMoments,
    gameMomentClubUserDetail
} from "./config"

const routes = () => {
    return (
        <Routes>
            {/* <Route path="/" index element={<Home />} /> */}
            <Route path="/" index element={<Login/>}/>
            <Route path={callback} element={<Callback/>}/>
            <Route path={`${publicQuickLinkMoments}/:clubId/:clubName`} element={<QuickLinkMoments/>}/>
            {/* <Route path="home" element={<Home/>}/> */}
            {/* <Route path="explore" element={<Explore/>}></Route> */}
            {/* <Route path="rank" element={<Rank/>}></Route> 改为 Battle 地址为 gameTrillionaireBattlePath */}
            <Route path={balance} element={<Balance/>}></Route>
            <Route path={`${link}/:type`} element={<Link/>}></Route>
            <Route path={invite} element={<Invite/>}/>
            {/* <Route path="chats/:userId" element={<ChatContent/>}/> */}
            <Route path={_main}>
                <Route index element={<NewHome/>}/>
                <Route path={mainHome} element={<NewHome/>}/>
                <Route path={mainExplore} element={<NewExplore/>}/>
                <Route path={mainMyclubs} element={<MyClubs/>}/>
                <Route path={mainProfile} element={<Profile/>}/>
                <Route path={_mainNft}>
                    <Route index element={<NFTPanel/>}/>
                    <Route path={mainNftNftPanel} element={<NFTPanel/>}/>
                    <Route path={mainNftBidMyBids} element={<BidMyBids/>}/>
                    <Route path={mainNftBidHistory} element={<BidHistory/>}/>
                    <Route path={mainNftBidMynft} element={<ModelMyNFT/>}/>
                    <Route path={mainNftBidSweep} element={<BidSweepNFT/>}/>
                    <Route path={`${mainNftBidDetail}/:tokenAddr/:tokenId`} element={<BidItemDetail/>}/>
                </Route>
                <Route path={_mainClub}>
                    <Route index element={<AllClubs/>}/>
                    <Route path={mainClubAllClubs} element={<AllClubs/>}/>
                    <Route path={`${mainClubSweepClubs}/:cardType`} element={<SweepClubs/>}/>
                </Route>
            </Route>

            {/* <Route path="events" element={<Events/>}>
                <Route index element={<Navigate to="how-points-work" replace={true}/>}/>
                <Route path="how-points-work" element={<HowPointsWork/>}/>
                <Route path="my-quests" element={<MyQuest/>}/>
                <Route path="points-calculator" element={<Calculator/>}/>
                <Route path="statistic" element={<Statistic/>}/>
            </Route> */}
            {/* <Route path="leaderboard" element={<Leaderboard/>}/> */}
            <Route path={_game}>
                <Route path={gameTrillionaire}>
                    <Route index element={<Mission/>}/>
                    <Route path={gameTrillionaireMission}>
                        <Route index element={<Mission/>}/>
                        <Route path={`${gameTrillionaireMissionDetail}/:workId`} element={<Dispatch/>}/>
                    </Route>
                    <Route path={gameTrillionaireBattle} element={<BattleHome/>}/>
                    <Route path={_battle}>
                        <Route path={`${gameTrillionaireBattleLeaderBoard}`} element={<BattleLeaderBoard/>}/>
                        <Route path={`${gameTrillionaireBattling}`} element={<Battling/>}/>
                    </Route>
                    <Route path={gameTrillionaireEcosystem}>
                        <Route index element={<Ecosystem/>}/>
                        <Route path={`${gameTrillionaireEcosystemDetail}/:areaId`} element={<EcosystemDetail/>}/>
                    </Route>
                    <Route path={gameTrillionaireAssets} element={<Assets/>}/>
                </Route>
                <Route path={gameShareDetail} element={<ShareDetail/>}/>
                <Route path={gameReferral} element={<Referral/>}/>
                <Route path={gameStake} element={<Stake/>}/>
                {/* <Route path={gameShareUpPoint} element={<ShareUpPoints/>}/> */}
                <Route path={gameMemeClub} >
                    <Route index element={<MemeClub/>}/>
                    <Route path={gameMemeClubCreat} element={<MemeClubCreat/>}/>
                    <Route path={gameMemeClubTge} element={<MemeClubTge/>}/>
                    <Route path={gameMemeClubLauncher} element={<Launcher/>}/>
                    <Route path={`${gameMemeClubRevealMemePack}/:clubUserId`} element={<RevealMemePack/>}/>
                    <Route path={gameMemeClubAssets} element={<MemeClubAssets/>}/>
                    <Route path={`${gameMemeClubUserDetail}/:userId`} element={<MemeClubUserDetail/>}/>
                    <Route path={`${gameMemeClubDetail}/:clubId`} element={<MemeClubDetail/>}/>
                    {/* <Route path={`${gameMemeClubChat}/:clubId`} element={<MemeClubChat/>}/> */}
                    <Route path={gameMemeClubLottery} element={<MemeLottery/>}/>
                </Route>
                <Route path={gameMomentClub} >
                    <Route index element={<MemeExplore/>}/>
                    <Route path={gameMomentClubExplore} element={<MemeExplore/>}/>
                    <Route path={gameMomentReferral} element={<MomentReferral/>}/>
                    <Route path={gameMomentReferralEarn} element={<MomentReferralEarn/>}/>
                    <Route path={gameMomentClubLauncher} element={<MomentLauncher/>}/>
                    <Route path={gameMomentClubTge} element={<MemeTge/>}/>
                    <Route path={gameMomentClubAssets} element={<MemeAssets/>}/>
                    <Route path={gameMomentClubCreat} element={<MemeCreate/>}/>
                    {/*<Route path={`${gameMomentClubDetail}/:clubId`} element={<MomentClubDetail/>}/>*/}
                    <Route path={`${gameMomentClubDetail}`} element={<ClubDetailTotal/>}/>
                    <Route path={`${gameMomentClubRevealMomentPack}/:clubUserId`} element={<RevealMomentPack/>}/>
                    <Route path={`${gameMomentClubUserDetail}/:clubUserId`} element={<MomentUserDetail/>}/>
                    
                </Route>
            </Route>

            <Route path={_public}>
                <Route path={`${publicChats}/:userId`} element={<Chat/>}/>
                <Route path={publicWishCreate} element={<WishCreate/>}/>
                <Route path={publicWishLink} element={<WishLink/>}/>
                <Route path={publicWishClaim} element={<WishClaim/>}/>
                <Route path={`${publicWishes}/:userId`} element={<WishContent/>}/>
                <Route path={`${publicNftContent}/:tokenAddr/:tokenId`} element={<NftContent/>}/>
            </Route>


            {/* <Route path="warfare" element={<Bid/>}>
                <Route index element={<WarfareHome/>}/>
                <Route path="detail/:areaId" element={<WarfareDetail/>}/>
                <Route path="map" element={<WarfareMap/>}/>
            </Route> */}


            <Route path="*" element={<Navigate to='/' replace={true}/>}/>
        </Routes>
    );
};

export default routes;
