import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import AvatarInfo from "../../../common/AvatarInfo";
import {unit} from "@/utils/common";
import {Button, message} from "antd";
import {
    get_trivia_run_data,
    post_trivia_answer_pub,
    post_trivia_question_next,
    post_trivia_question_pub, post_trivia_rank_pub, post_trivia_stop
} from "@/utils/common";
import VoteBtn from "src/model/Votes/VoteBtn";
import AntButton from "@/common/AntButton";

const ModelVoteControl = (
    {
        setOpenVoteControlPanel,
        needUpdateVoteInfo,
        setNeedUpdateVoteInfo,
        hostId,
        lastVoteInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const [state, setState] = useState(-1);
    const clickSendQuesion = () => {
        setClickSendQuesionLoading(true)
        setTimeout(
            () => {
                post_trivia_question_pub().then(data => {
                    updateVoteInfo(() => {
                        setClickSendQuesionLoading(false)
                    }, () => {
                        setClickSendQuesionLoading(false)
                    });
                }).catch(e => {
                    setClickSendQuesionLoading(false)
                });
            }, 2000
        )
    }

    const clickSendAnswer = () => {
        setClickSendAnswerLoading(true)
        setTimeout(
            () => {
                post_trivia_answer_pub().then(data => {
                    updateVoteInfo(() => {
                        setClickSendAnswerLoading(false)
                    }, () => {
                        setClickSendAnswerLoading(false)
                    });
                }).catch(e => {
                    setClickSendAnswerLoading(false)
                });
            }, 5500
        )

    }

    const clickNextQuesion = () => {
        setClickNextQuesionLoading(true)
        post_trivia_question_next().then(data => {
            updateVoteInfo(() => {
                setClickNextQuesionLoading(false)
            }, () => {
                setClickNextQuesionLoading(false)
            });
        }).catch(e => {
            setClickNextQuesionLoading(false)
        });
    }

    const clickShowRank = () => {
        setClickShowRankLoading(true)
        setTimeout(
            () => {
                post_trivia_rank_pub().then(data => {
                    updateVoteInfo(() => {
                        setClickShowRankLoading(false)
                    }, () => {
                        setClickShowRankLoading(false)
                    });
                }).catch(e => {
                    setClickShowRankLoading(false)
                });
            }, 3000
        )
    }

    const clickStop = () => {
        setClickStopLoading(true)
        post_trivia_stop().then(data => {
            updateVoteInfo(() => {
                setClickStopLoading(false)
            }, () => {
                setClickStopLoading(false)
            });
        }).catch(e => {
            setClickStopLoading(false)
        });
    }

    const timeoutVote = () => {
        setTimeout(() => {
            updateVoteInfo()
        }, 1000)
    }

    const [count, setCount] = useState(-1);
    const timer = useRef();
    useEffect(() => {
        timer.current = setInterval(() => {
            setCount((count) => {
                if (count === 0) timeoutVote();
                if (count < 0) {
                    return -1;
                }
                return count - 1;
            });
        }, 1000);
        return () => {
            clearInterval(timer.current);
        };
    }, []);

    const timerVoteInfo = useRef();
    useEffect(() => {
        timerVoteInfo.current = setInterval(() => {
            updateVoteInfo();
        }, 2000);
        return () => {
            clearInterval(timerVoteInfo.current);
        };
    }, []);

    useEffect(() => {
        updateVoteInfo();
    }, [needUpdateVoteInfo]);

    const exitVoteControl = () => {
        setOpenVoteControlPanel(false);
    }

    const parseVoteInfo = (data) => {
        if (!data.isHost) exitVoteControl();

        switch (data.state) {
            case 0:
                exitVoteControl();
                break;
            case 1:
                setState(1);
                setShowQuestion(data.currQ);
                setCorrectMax(data.currQCorrectMax);
                setCorrectNow(data.currQCorrectNow);
                break;
            case 2:
                setState(2);
                setShowQuestion(data.currQ);
                setCorrectMax(data.currQCorrectMax);
                setCorrectNow(data.currQCorrectNow);
                break;
            case 3:
                setState(3);
                setShowQuestion(data.currQ);
                setCorrectMax(data.currQCorrectMax);
                setCorrectNow(data.currQCorrectNow);
                break;
            case 4:
                setState(4);
                setShowQuestion(data.currQ);
                setCorrectMax(data.currQCorrectMax);
                setCorrectNow(data.currQCorrectNow);
                break;
            case 5:
                setState(5);
                setShowQuestion(null);
                setRankAward(data.awardInfo);
                setShowRank(true);
                setCorrectMax(-1);
                setCorrectNow(-1);
                break;
            case 6:
                setState(6);
                setShowQuestion(null);
                setRankAward(data.awardInfo);
                setShowRank(true);
                setCorrectMax(-1);
                setCorrectNow(-1);
                break;
            default:
                exitVoteControl();
                break;
        }

        setCount(data.currQCountDown);
    }

    const updateVoteInfo = (callback = () => {
    }, fallcallback = () => {
    }) => {
        get_trivia_run_data(hostId).then(data => {
            parseVoteInfo(data);
            callback();
        }).catch(e => {
            console.log(e);
            fallcallback();
        })
    }

    useEffect(() => {
        updateVoteInfo();
    }, []);

    useEffect(() => {
        parseVoteInfo(lastVoteInfo);
    }, [lastVoteInfo]);

    const setShowQuestion = (question) => {
        setQuestionId(question?.id);
        setQuestionTitle(question?.title);
        setQuestionImg(question?.image);
        setQuestionOptions(question?.options);
        setQuestionType(question?.image ? 2 : 1);
        let sum = 0;
        question?.options?.map((item) => {
            sum += item.choiceNum;
        });
        setSumOptionsNum(sum);
    }

    const [questionId, setQuestionId] = useState('');
    const [questionTitle, setQuestionTitle] = useState('');
    const [questionImg, setQuestionImg] = useState('');
    const [questionOptions, setQuestionOptions] = useState([]);

    const [clickSendQuesionLoading, setClickSendQuesionLoading] = useState(false);
    const [clickSendAnswerLoading, setClickSendAnswerLoading] = useState(false);
    const [clickNextQuesionLoading, setClickNextQuesionLoading] = useState(false);
    const [clickShowRankLoading, setClickShowRankLoading] = useState(false);
    const [clickStopLoading, setClickStopLoading] = useState(false);
    const [showRank, setShowRank] = useState(false);
    const [rankAward, setRankAward] = useState({});
    const [questionType, setQuestionType] = useState(1);
    const [voteType, setVoteType] = useState(-1);
    const [sumOptionsNum, setSumOptionsNum] = useState(1);

    const [correctMax, setCorrectMax] = useState(-1);
    const [correctNow, setCorrectNow] = useState(-1);

    return (
        <div className={s.wrap}>
            {state === -1 && <div className="fs15 color-white fb">
                {t('Loading...')}
            </div>}
            {count !== -1 && <div className="fs15 color-white fb">
                {t('CountDown')}：{count}
            </div>}
            {showRank &&
                <div className={s.list}>
                    <div className={s.item}>
                        <div className={s.left}>
                            <div className="fs18 ml10">{t('Jackpot Size')}</div>
                        </div>
                        <div className={`${s.right} fs18`}>
                            {rankAward?.tokenCount} $
                        </div>
                    </div>
                    <br/>
                    {rankAward?.winners?.map((item, index) => {
                        return (
                            <div className={s.item}>
                                <div className={s.left}>
                                    <AvatarInfo src={item.logo}/>
                                    <div className="fs18 ml10">{item.name}</div>
                                </div>
                                <div className={`${s.right} fs18`}>
                                    {Number(item?.bonus || 0).toFixed(2)} $
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            {correctMax > 0 && correctNow !== -1 && <>
                <div className="fs15 color-white fb ">{t('Lightning Round')}: {correctNow}/{correctMax}</div>
            </>}
            <div className="fs20 color-white fb">
                {questionTitle}
            </div>
            {questionImg && <div className={s.imgWrap}>
                <img width="100%" srcSet={questionImg}/>
            </div>}
            <div className={`${s.questionWrap} ${s['vode_type' + voteType]}`}>
                {
                    questionOptions?.map(
                        (item) => {
                            return (
                                <VoteBtn
                                    key={item.id}
                                    btnType={1}
                                    voteType={questionType}
                                    persent={sumOptionsNum === 0 ? '0%' : (item.choiceNum / sumOptionsNum * 100) + '%'}
                                    answerStr={item.text}
                                    num={item.choiceNum}
                                    answerReslut={item.isAnswer}
                                    disabled={true}
                                />
                            )
                        }
                    )
                }
            </div>
            <br/>
            {state === 1 &&
                <AntButton type="primary" shape="round" className={`${s.btn} ${s.okey} btn_public`} onClick={() => {
                    clickSendQuesion()
                }} loading={clickSendQuesionLoading}>{t('Post Question')}</AntButton>}
            {state === 3 &&
                <AntButton type="primary" shape="round" className={`${s.btn} ${s.okey} btn_public`} onClick={() => {
                    clickSendAnswer()
                }} loading={clickSendAnswerLoading}>{t('Announcement Answers')}</AntButton>}
            {state === 4 &&
                <AntButton type="primary" shape="round" className={`${s.btn} ${s.okey} btn_public`} onClick={() => {
                    clickNextQuesion()
                }} loading={clickNextQuesionLoading}>{t('Close Answers Dialog')}</AntButton>}
            {state === 5 &&
                <AntButton type="primary" shape="round" className={`${s.btn} ${s.okey} btn_public`} onClick={() => {
                    clickShowRank()
                }} loading={clickShowRankLoading}>{t('Show Rank')}</AntButton>}
            {state === 6 &&
                <AntButton type="primary" shape="round" className={`${s.btn} ${s.okey} btn_public`} onClick={() => {
                    clickStop()
                }} loading={clickStopLoading}>{t('Stop Game')}</AntButton>}
        </div>
    )
}
export default memo(ModelVoteControl);
