import React, {memo, useMemo, useEffect, useState} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {
    post_history_friends,
    stringToNumber,
    unit,
    sliceStr,
    joinChat, vCoinString, getProfile,
    getAvatarFromProfile,
    getAvatarFromUserInfo
} from "@/utils/common"
import AvatarInfo from "../../common/AvatarInfo";
import {useNavigate} from "react-router-dom";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import HistoryActivitiesInfo from "@/common/HistoryActivitiesInfo";

const HireFriendsTranList = (
    {
        tranList,
        setTranList,
        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const timeFn = (time) => {
        let d = parseInt(time / 60 / 60 / 24);
        let h = parseInt(time / 60 / 60 % 24);
        let m = parseInt(time / 60 % 60);
        let str = '';
        if (d > 0) {
            str = d + "d";
            return str
        }
        if (h > 0) {
            str = h + "h";
            return str
        }
        if (m > 0) {
            str = m + "m";
            return str
        }
        return t('few seconds');

        // return `${d}${h}${m}`
    }
    const type = "hire-friends-tran-list";

    const {userId, platformData} = userInfo;
    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])
    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformData, userInfo])
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const getDefaultData = () => {
        post_history_friends().then(res => {
            const {nextCursor} = res || -1;
            setLoading(false);
            setTranList({
                type, data: {
                    list: res?.logs,
                    nextCursor
                }
            })
        }).catch(e => {
            setLoading(false);
            console.log(e);
        })
    }
    useEffect(() => {
        getDefaultData()
    }, [])
    const list = useMemo(() => {
        return tranList[type]?.list || []
    }, [tranList])
    const nextCursor = useMemo(() => {
        return tranList[type]?.nextCursor || -1
    }, [tranList])

    const getMore = () => {
        // getkeyhistoryglobal(tranList[type]?.nextCursor).then(res => {
        //     const {nextCursor} = res || -1;
        //     setTranList({
        //         type, data: {
        //             list: [
        //                 ...tranList[type].list,
        //                 ...res?.txlist,
        //             ],
        //             nextCursor
        //         }
        //     })
        // }).catch(e => {
        //     console.log(e);
        // })
    }
    const reFresh = () => {
        getDefaultData()
    }
    const toTranD = (userId, accountType) => {
        if(userId > 0 ){
            joinChat(userId, navigate, accountType);
        }
    }
    return (
        <div className={s.wrap}>

            {
                !loading ?
                    <div className={s.content}>

                        {
                            list.length > 0 ? list.map((item, ind) => {
                                const {logType, txData, friendTradeData} = item;
                                if (logType === 1) {
                                    const {
                                        amount,
                                        time,
                                        txType,
                                        totalPrice,
                                        keyId,
                                        userId,
                                        keyAccountType,
                                        keyProfile,
                                        userAccountType,
                                        userProfile
                                    } = txData;

                                    //let type = item?.type;
                                    //if (!type) type = 0;

                                    return (
                                        <div className={`${s.item} ${s.itemBg}`} key={ind}>
                                            <div className={s.tranAvatar}>
                                                <div className={s.onwerAvatar} onClick={() => {
                                                    toTranD(userId, userAccountType)
                                                }}>
                                                    <AvatarInfo size={40} src={getAvatarFromProfile(userProfile)}/>
                                                </div>
                                                <div className={s.sponsorAvatar} onClick={() => {
                                                    toTranD(keyId, keyAccountType)
                                                }}>
                                                    <AvatarInfo size={40} src={getAvatarFromProfile(keyProfile)}/>
                                                </div>
                                            </div>
                                            <div className={s.tranInfo}>
                                                <div className={`${s.info} fs14`}>
                                                    <HistoryActivitiesInfo 
                                                        userInfo={userInfo}
                                                        textType={0}
                                                        data={item}>
                                                    </HistoryActivitiesInfo>
                                                </div>
                                                <div className={s.price}>
                                                    <HistoryActivitiesInfo 
                                                        userInfo={userInfo}
                                                        textType={1}
                                                        data={item}>
                                                    </HistoryActivitiesInfo>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    const {
                                        type,
                                        managerId,
                                        managerAccountType,
                                        managerProfile,
                                        employeeId,
                                        employeeAccountType,
                                        employeeProfile,
                                        coin,
                                        time,
                                        workName,
                                        bpChangeVal
                                    } = friendTradeData;
                                    return (
                                        <div className={`${s.item} ${s.itemBg}`} key={ind}>
                                            <div className={s.tranAvatar}>
                                                {type === 8 || type === 9 || type === 1? <>
                                                    <div className={s.onwerAvatar} onClick={() => {
                                                        toTranD(employeeId, employeeAccountType)
                                                    }}>
                                                        <AvatarInfo size={40}
                                                                    src={getAvatarFromProfile(employeeProfile)}/>
                                                    </div>
                                                    <div className={s.sponsorAvatar} onClick={() => {
                                                        toTranD(type === 5 ? userId : managerId, managerAccountType)
                                                    }}>
                                                        <AvatarInfo size={40}
                                                                    src={type === 5 ? avatarUrl : getAvatarFromProfile(managerProfile)}/>
                                                    </div>
                                                </> : type === 14 ? <>
                                                    <div className={s.onwerAvatar} onClick={() => {
                                                        toTranD(userId, 'key')
                                                    }}>
                                                        <AvatarInfo size={40}
                                                                    src={getAvatarFromProfile(profile)}/>
                                                    </div>
                                                </> : <>
                                                    <div className={s.onwerAvatar} onClick={() => {
                                                        toTranD(type === 5 ? userId : managerId, managerAccountType)
                                                    }}>
                                                        <AvatarInfo size={40}
                                                                    src={type === 5 ? avatarUrl : getAvatarFromProfile(managerProfile)}/>
                                                    </div>
                                                    <div className={s.sponsorAvatar} onClick={() => {
                                                        toTranD(employeeId, employeeAccountType)
                                                    }}>
                                                        <AvatarInfo size={40}
                                                                    src={getAvatarFromProfile(employeeProfile)}/>
                                                    </div>
                                                </>}

                                            </div>
                                            <div className={s.tranInfo}>
                                                <div className={`${s.info} fs14`}>
                                                    <HistoryActivitiesInfo 
                                                        className={''}
                                                        userInfo={userInfo}
                                                        textType={0}
                                                        data={item}>
                                                    </HistoryActivitiesInfo>
                                                </div>
                                                <div className={s.price}>
                                                    <div className="color-blue-light fs12">
                                                        <HistoryActivitiesInfo 
                                                            className={''}
                                                            userInfo={userInfo}
                                                            textType={1}
                                                            data={item}>
                                                        </HistoryActivitiesInfo>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            }) : <div className="fs-12">{t('No Data')}</div>
                        }
                        <div className={`${s.loadMore} ${nextCursor !== -1 ? s.show : ''}`}
                             onClick={getMore}>{t('Load More')}</div>
                    </div> :
                    <div className="">{t('Loading...')}</div>
            }
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        activeInd: app.activeInd,
        tranList: app.tranList,
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveInd: (params) => {
            return dispatch({
                type: "app/setActiveInd",
                payload: params,
            });
        },
        setTranList: (params) => {
            return dispatch({
                type: "app/setTranList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(HireFriendsTranList));
