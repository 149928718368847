import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "src/common/CustomIcon";
import {numberClamp} from "@/utils/common";

const ModelShareTips = () => {
    const {t, i18n} = useTranslation();

    return (
        <div className={s.wrap}>
            <div className={s.topPart}>
                <div className={s.content}>
                    <div className={`color-blue-light mt25 fs13`}>
                        <Trans
                            i18nKey="Score Breakdown Tips 01"
                            defaults="The table below shows how your score is affected by each action. Each day you can make <fb>3 POSTS</fb>, <fb>5 REPLIES</fb> and <fb>5 REPOSTS</fb> using both <font>@GoTurnUp</font> and <font>$LFG</font> "
                            components={{
                                font: <span style={{color: '#fab600'}}></span>,
                                fb: <span className="fb"></span>,
                            }}
                        />
                    </div>
                    <div className={`${s.imgRoot} mt25`}>
                        <CustomIcon width="100%" height={180} imgName={'twitterscore'}
                                    name={'twitterscore'}/>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default memo(ModelShareTips);
