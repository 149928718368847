import React, {memo, useEffect, useState, useMemo} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../../../common/CustomIcon";
import {connect} from "react-redux";
import {Button, Modal, Spin, Checkbox} from 'antd';
import {
    stringToNumber,
    PrivyLoginStatusError,
    logout,
    logoutSelf,
    convertWalletBalance,
} from "@/utils/common"
import {useLocation, useNavigate} from "react-router-dom";
import AvatarInfo from "../../../../common/AvatarInfo";
import Countdown from "../../../../common/Countdown";
import InfiniteScroll from "react-infinite-scroll-component";
import {ethers, BigNumber} from "ethers";
import {
    getnft_self_bidlist,
    getnft_self_claimlist,
    getRarityText,
    coinObj,
    nftDetail,
    getRarityBgColor,
    getRarityFontColor,
    getRarityImageBg,
    getRarityRankIcon,
    maxBatchNumber
} from "@/utils/bidNft";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {bidBatch, addFloat, claim, claimBatch} from "@/utils/bidNftWeb3";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import ModalInsufficientCrpto from "@/model/ModalInsufficientCrpto";
import MainHeader from "src/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";

Number.prototype.add = function (arg) {
    var r1, r2, m;
    try {
        r1 = this.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    return (this * m + arg * m) / m
}

const BidMyBids = (
    {
        userInfo,
        setShowLink,
        tweetMaticBindResult,
        currentAuctionBatchCfg,
        bidSlippage,
    }
) => {
    const {t, i18n} = useTranslation();
    const {userId} = userInfo;
    // const [linkUrl, setLinkUrl] = useState('');
    const navigate = useNavigate();
    const [bidingList, setBidingList] = useState([]);
    const [bidNextCursor, setBidNextCursor] = useState(0);
    const [claimList, setClaimList] = useState([]);
    const [claimNextCursor, setClaimNextCursor] = useState(0);
    const [noSuccessText, setNoSuccessText] = useState('Bid Failed');
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const [sweepObj, setSweepObj] = useState({})
    const [keyPrice, setKeyPrice] = useState({
        lfgBalance: 0,
        balance: 0
    });
    const {balance, lfgBalance} = userInfo?.selfData || {balance: 0, lfgBalance: 0};
    const getBidList = (cursor) => {

        getnft_self_bidlist(hideOutbid, cursor).then(res => {
            setBidNextCursor(res?.nextCursor || -1);
            if (res?.inBidNFTs?.length > 0) {
                setBidingList(cursor === 0 ? res?.inBidNFTs || [] : [...bidingList, ...res?.inBidNFTs]);
            }
        }).catch(e => {
            console.error(e);
        });
    }

    const getClaimList = (cursor) => {
        getnft_self_claimlist(cursor).then(res => {
            setClaimNextCursor(res?.nextCursor || -1);
            if (res?.toClaimNFTs?.length > 0) {
                setClaimList(cursor === 0 ? res?.toClaimNFTs || [] : [...claimList, ...res?.toClaimNFTs]);
            }
            if (cursor === 0 && !res?.toClaimNFTs) {
                setClaimList([])
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const outBidAmount = useMemo(() => {
        if (bidingList?.length === 0) return 0
        return bidingList?.filter(item => item?.highestBid !== item?.myBid)?.length
    }, [bidingList])

    useEffect(() => {

        getClaimList(0);
    }, [])

    const getMoreBidingList = () => {
        getBidList(bidNextCursor);
    }

    const getMoreClaimList = () => {
        getClaimList(claimNextCursor);
    }
    const [subLoading, setSubLoading] = useState(false);
    const [done, setDone] = useState(false);
    const [subFailded, setSubFailded] = useState(false);
    const {wallets} = useWallets();
    const [activeType, setActiveType] = useState(0)

    useEffect(() => {
        if (bidingList?.length === 0) return
        let obj = {
            lfg: {
                tokenAddrs: [],
                tokenIds: [],
                expectedSpendings: [],
                val: 0,
                // val: 0.1,
                coinType: 1
            },
            matic: {
                tokenAddrs: [],
                tokenIds: [],
                expectedSpendings: [],
                val: 0,
                // val: 0.1,
                coinType: 0
            }
        }

        bidingList.map((item, index) => {
            if (index < maxBatchNumber) {
                const {coinType, nextPrice, nftSimpleData} = item;
                const {tokenAddr, tokenId} = nftSimpleData;
                if (Number(item?.highestBid) !== Number(item?.myBid)) {
                    let typeObj = {...obj[coinType === 0 ? 'lfg' : 'matic']};
                    typeObj['tokenAddrs'] = [...typeObj?.tokenAddrs, tokenAddr];
                    typeObj['tokenIds'] = [...typeObj?.tokenIds, tokenId];
                    typeObj['expectedSpendings'] = [...typeObj?.expectedSpendings, ethers.utils.parseEther(nextPrice)];
                    // typeObj['val'] = addFloat(Number(nextPrice), typeObj?.val)
                    typeObj['val'] = typeObj?.val.add(Number(nextPrice))

                    obj[coinType === 0 ? 'lfg' : 'matic'] = {
                        ...obj[coinType === 0 ? 'lfg' : 'matic'],
                        ...typeObj
                    }

                }
            }


        })
        setKeyPrice({
            lfgBalance: obj['lfg']?.val,
            balance: obj['matic']?.val
        })
        setSweepObj(obj)
    }, [bidingList])

    const [batchClaimList, setBatchClaimList] = useState([])

    useEffect(() => {
        const selList = claimList?.filter((i, _ind) => _ind < maxBatchNumber);
        setBatchClaimList(selList)
    }, [claimList])

    const [upData, setUpdata] = useState(null)
    const setListFn = (obj, ind) => {

        let _l = claimList;
        _l[ind] = {
            ...claimList[ind],
            ...obj
        }
        setClaimList(_l)
        setUpdata(new Date())
        // if (delayHide) {
        //     setTimeout(() => {
        //         _l[ind] = {
        //             ...list[ind],
        //             bidState: {}
        //         }
        //         setClaimList(_l)
        //         setUpdata(new Date())
        //         let param = getFilterParam();
        //         setClaimNextCursor(0)
        //         getList(genesisAuctionState, param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, rangeRise, 0);
        //     }, 5000)
        // }
    }
    const [singleClaim, setSingleClaim] = useState(false)
    const claimNft = (tokenAddr, tokenId) => {
        if (subLoading) return
        const _ind = claimList.findIndex(i => i?.nftSimpleData?.tokenAddr === tokenAddr && i?.nftSimpleData?.tokenId === tokenId);
        let obj = {};
        obj['claimState'] = {
            isLoading: true,
            hasResult: false,
            isSucc: false
        }
        setListFn(obj, _ind)
        setSubLoading(true)
        setSingleClaim(true)
        claim(wallets, tokenAddr, tokenId).then(res => {
            setSubLoading(false)
            setSingleClaim(false)
            // setClaimSucc(true)
            obj['claimState'] = {
                isLoading: false,
                hasResult: true,
                isSucc: true
            }
            setListFn(obj, _ind)
            setTimeout(() => {
                // getState(tokenAddr, tokenId)
                getClaimList(0);
            }, 2000)
            console.log(res);
        }).catch(e => {
            setSubLoading(false)
            setSingleClaim(false)
            obj['claimState'] = {
                isLoading: false,
                hasResult: true,
                isSucc: false
            }
            setListFn(obj, _ind)
            console.error(e);
        })
    }

    const batchChangeItem = (data) => {
        let _list = []
        batchClaimList.map(item => {
            let obj = {};
            obj['claimState'] = {
                ...data
            }
            _list.push({
                ...item,
                ...obj
            })
        })
        // if(batchClaimList?.length === claimList.length){
        //     console.log(_list);
        //     setClaimList(_list)
        // }else{
        const _claimList = claimList.slice(batchClaimList?.length) || []
        setClaimList([
            ..._list,
            ..._claimList
        ])
        // }
    }

    const claimBatchNft = () => {
        if (subLoading) return
        setSubLoading(true)

        let tokenAddrs = [], tokenIds = [];
        batchClaimList.map(item => {
            const {tokenAddr, tokenId, name, rarity, image} = item?.nftSimpleData;
            tokenAddrs.push(tokenAddr)
            tokenIds.push(tokenId)

        })
        batchChangeItem({
            isLoading: true,
            isSucc: false,
            hasResult: false
        })


        claimBatch(wallets, tokenAddrs, tokenIds).then(res => {
            setSubLoading(false);
            setDone(true);
            setSubFailded(false);
            batchChangeItem({
                isLoading: false,
                isSucc: true,
                hasResult: true
            })
        }).catch(e => {
            console.error(e);
            setDone(true);
            setSubFailded(true);
            batchChangeItem({
                isLoading: false,
                isSucc: false,
                hasResult: true
            })
        })
    }

    const sweepBid = () => {
        if (subLoading) return

        if (keyPrice?.balance > balance) {
            setShowInsufficientCrpto(true);
            return;
        } else if (keyPrice?.lfgBalance > lfgBalance) {
            setShowSwapLfg(true);
            return;
        }

        setSubLoading(true);
        bidBatch(wallets, sweepObj, Number(bidSlippage), Number(balance)).then(res => {
            setDone(true);
            setSubLoading(false);
            setSubFailded(false);
        }).catch(e => {
            console.error(e);
            setNoSuccessText(e)
            setDone(true);
            setSubLoading(false);
            setSubFailded(true);
            // setSelectedRows([])
        })
    }


    const {logout} = usePrivy();
    const closeFn = () => {

        if (noSuccessText === 'need login') {
            logout();
            logoutSelf();
        }
        closeModal();
    }

    const closeModal = async () => {
        setDone(false);
        setSubFailded(false);
        setSubLoading(false)
        getClaimList(0)
        getBidList(0)
    };

    const [hideOutbid, setHideOutBid] = useState(false)

    const onChange = (e) => {
        setHideOutBid(e.target.checked);
    };

    useEffect(() => {
        getBidList(0);
    }, [hideOutbid])

    return (
        // <Spin
        //   className="loadPageSpin"
        //   indicator={
        //     <LoadingOutlined
        //       style={{
        //         fontSize: 90,
        //       }}
        //     />
        //   }
        //   tip="Loading..."
        //   spinning={loading}
        // >
        // </Spin>
        <>
            <div className={`${s.modalWrap} abs publicBg`}>
                <MainHeader title={t('BidTitleMyBids')} hasRightMenu={false} headerType={2}></MainHeader>
                <div className={s.nav}>
                    <AntButton
                        onClick={() => {
                            setActiveType(0)
                        }}
                        className={`${activeType === 0 ? 'btn_blue' : 'btn_blue_light'} mr10`}
                        style={{width: '100px'}}
                    >
                        {t('BidMyBidsBiddingList')}
                    </AntButton>
                    <AntButton
                        onClick={() => {
                            setActiveType(1)
                        }}
                        className={`${activeType === 1 ? 'btn_blue' : 'btn_blue_light'}`}
                        style={{width: '100px'}}
                    >
                        {t('BidMyBidsClaimableList')}
                    </AntButton>
                </div>
                <div className={s.content}>
                    <div className={`${s.block} ${activeType === 0 ? s.show : ''}`}>
                        {/* <div className={s.title}>{t('BidMyBidsBiddingList')}</div> */}
                        {/* <div className="w100p tll mb10">{t('BidMyBidsTips1', { bidAmount: bidingList?.length })}</div> */}
                        {outBidAmount > 0 && <div
                            className={`color-red ${s.tooltips}`}>{t('BidMyBidsTips2', {nftAmout: outBidAmount})}</div>}
                        <Checkbox className={`hireCheckBox ${s.outbid}`} onChange={onChange}
                                  defaultChecked={hideOutbid}>{t('Hide Outbid')}</Checkbox>
                        {
                            bidingList?.length > 0 ?
                                <>
                                    {/* <div className={s.filter}>
                                    <div>{t('BidMyBidsFilterby')}</div>
                                    <div className={`${s.filterItem} ${s.active}`}>{t('AllBids')}</div>
                                    <div className={s.filterItem}>{t('Winning')}</div>
                                    <div className={s.filterItem}>{t('Outbid')}</div>
                                </div> */}
                                    <div className={s.bidList} id="bidList">
                                        <InfiniteScroll
                                            dataLength={bidingList?.length || 0}
                                            next={getMoreBidingList}
                                            hasMore={bidNextCursor !== -1}
                                            loader={<Spin/>}
                                            scrollableTarget={'bidList'}
                                        >
                                            {
                                                bidingList.map((item, ind) => {
                                                    const {
                                                        coinType,
                                                        myBid,
                                                        highestBid,
                                                        endTime,
                                                        nftSimpleData,
                                                        rankIdx
                                                    } = item;
                                                    const {tokenAddr, tokenId, name, rarity, image} = nftSimpleData;
                                                    return (
                                                        <div className={s.bidItem} key={ind}
                                                             onClick={() => nftDetail(navigate, {tokenAddr, tokenId})}>
                                                            <div className={s.l}>
                                                                <div className={s.avatar}>
                                                                    {currentAuctionBatchCfg?.auctionBatchType === 2 &&
                                                                        <div className={s.bidItemBg}
                                                                             style={{backgroundImage: `url(/img/${getRarityImageBg(rarity)}.png)`}}>
                                                                        </div>
                                                                    }
                                                                    <AvatarInfo size={65} src={image}
                                                                                className={'roundAvatar'}/>
                                                                    {/* <div className={`${s.tag} bidRarity_${rarity}`}>{getRarityText(rarity)}</div> */}
                                                                    {currentAuctionBatchCfg?.auctionBatchType === 2 ?
                                                                        <div
                                                                            className={`${s.rankTag} bidRarity_${rarity}`}
                                                                            style={{
                                                                                backgroundColor: `${getRarityBgColor(rarity)}`,
                                                                                color: `${getRarityFontColor(rarity)}`
                                                                            }}>
                                                                            <CustomIcon width={16} height={16}
                                                                                        imgName={getRarityRankIcon(rarity)}
                                                                                        name={getRarityRankIcon(rarity)}/>
                                                                            {rankIdx || '--'}
                                                                        </div> :
                                                                        getRarityText(rarity) != null ?
                                                                            <div
                                                                                className={`${s.tag} bidRarity_${rarity}`}
                                                                                style={{
                                                                                    backgroundColor: `${getRarityBgColor(rarity)}`,
                                                                                    color: `${getRarityFontColor(rarity)}`
                                                                                }}>
                                                                                {getRarityText(rarity)}
                                                                            </div> :
                                                                            <></>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className={s.r}>
                                                                <div className={s.t}>
                                                                    <div>{name}</div>
                                                                    <div className={s.tb}>
                                                                        <div
                                                                            className={`${s.state} mt2 ${Number(highestBid) === Number(myBid) ? s.green : s.red}`}>
                                                                            {Number(highestBid) === Number(myBid) ? t('Leading') : t('Outbid')}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className={s.b}>
                                                                    <div className={s.labelItem}>
                                                                        <div
                                                                            className={`${s.label} color-blue-light`}>{t('BidMyBidsMyBid')}</div>
                                                                        <div className={s.val}>
                                                                            <CustomIcon width={24} height={24}
                                                                                        className=""
                                                                                        imgName={coinObj[coinType]?.iconName}
                                                                                        name={coinObj[coinType]?.iconName}/>
                                                                            <div>{stringToNumber(myBid, 2)}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={s.labelItem}>
                                                                        <div
                                                                            className={`${s.label} color-blue-light`}>{t('BidMyBidsWinningBid')}</div>
                                                                        <div className={s.val}>
                                                                            <CustomIcon width={24} height={24}
                                                                                        className=""
                                                                                        imgName={coinObj[coinType]?.iconName}
                                                                                        name={coinObj[coinType]?.iconName}/>
                                                                            <div>{stringToNumber(highestBid, 4)}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={s.labelItem}
                                                                         style={{
                                                                             maxWidth: '90px',
                                                                             height: '22px',
                                                                             marginTop: '15px',
                                                                         }}>
                                                                        <div className={`${s.val}`}
                                                                             style={{
                                                                                 backgroundColor: 'rgba(0,0,0,0.3)',
                                                                                 width: '80px',
                                                                                 paddingLeft: '0'
                                                                             }}
                                                                        >
                                                                            <CustomIcon width={14} height={14}
                                                                                        className="ml5 mr5"
                                                                                        imgName={'workEndTime_clock'}
                                                                                        name={'workEndTime_clock'}/>
                                                                            <div className="fs12 color-white">
                                                                                <Countdown
                                                                                    workEndTimestamp={endTime}
                                                                                    refresh={() => {
                                                                                    }}/></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </InfiniteScroll>
                                    </div>
                                    <div className={`fs12 tll ${s.bottom}`}>
                                        <div className={s.bidOutBidLine}>
                                            <div className={s.coinRootLeft}>
                                                <CustomIcon className="mr5 ml2" width={20}
                                                            height={20} imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                                            name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                                                <div className={s.coinValue}>
                                                    {convertWalletBalance(keyPrice?.balance)}
                                                </div>
                                            </div>
                                            <div className={s.coinRootRight}>
                                                <CustomIcon className="ml5 mr2" width={20}
                                                            height={20} imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                            name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                                <div className={s.coinValue}>
                                                    {convertWalletBalance(keyPrice?.lfgBalance)}
                                                </div>
                                            </div>
                                        </div>

                                        {outBidAmount > 0 && <div className={s.outBidInfo}>

                                            <div className={s.bidInfo}>
                                                <div className={s.sweepButton}>
                                                    {!done && <ButtonFactory onClick={sweepBid}>
                                                        {subLoading ? <div className={s.web3LoadingLine}>
                                                                <CustomIcon rotating={true} className="ml5" width={16}
                                                                            height={16}
                                                                            imgName={'Picture/UI_Picture_Loading_01'}
                                                                            name={'Picture/UI_Picture_Loading_01'}/>
                                                                <span
                                                                    className="fs18 ml5 color-black">{t('PROCESSING')}</span>
                                                            </div> :
                                                            <>
                                                                {t('BID xx OUTBID NFT', {count: Math.min(outBidAmount, maxBatchNumber)})}
                                                            </>
                                                        }
                                                    </ButtonFactory>}
                                                    {done && <ButtonFactory
                                                        onClick={closeFn}>{subFailded ? noSuccessText : t('TRANSACTION SUCCESSFUL')}</ButtonFactory>}
                                                </div>

                                            </div>

                                        </div>}
                                    </div>
                                </> :
                                <div className={s.noData}>{t('BidMyBidsNoDataTips1')}</div>
                        }

                    </div>
                    <div className={`${s.block} ${activeType === 1 ? s.show : ''}`}>
                        {/* <div className={s.title}>{t('BidMyBidsClaimableList')}</div> */}

                        {
                            claimList?.length > 0 ?
                                <>

                                    <div className={s.bidList} id="claimList">
                                        <InfiniteScroll
                                            dataLength={claimList?.length || 0}
                                            next={getMoreClaimList}
                                            hasMore={claimNextCursor !== -1}
                                            loader={<Spin/>}
                                            scrollableTarget={'claimList'}
                                        >
                                            {claimList.map((item, ind) => {
                                                const {coinType, myBid, nftSimpleData, claimState} = item;
                                                const {tokenAddr, tokenId, name, rarity, image} = nftSimpleData;
                                                const {isLoading, isSucc, hasResult} = claimState || {}
                                                return (
                                                    <div className={s.bidItem} key={ind}>
                                                        <div className={s.l}>
                                                            <div className={s.avatar}
                                                                 onClick={() => nftDetail(navigate, {
                                                                     tokenAddr,
                                                                     tokenId
                                                                 })}>
                                                                <AvatarInfo size={65} src={image}
                                                                            className={'roundAvatar'}/>


                                                            </div>
                                                        </div>
                                                        <div className={s.r}>
                                                            <div className={s.t} style={{flexDirection: 'row'}}>
                                                                <div className="fs16">{name}</div>
                                                                {getRarityText(rarity) != null &&
                                                                    <div className={`${s.tag} bidRarity_${rarity}`}
                                                                         style={{
                                                                             backgroundColor: `${getRarityBgColor(rarity)}`,
                                                                             color: `${getRarityFontColor(rarity)}`
                                                                         }}>
                                                                        {getRarityText(rarity)}
                                                                    </div>}
                                                            </div>
                                                            <div className={s.b}>
                                                                <div className={s.labelItem}>
                                                                    <div
                                                                        className={`${s.label} color-blue-light`}>{t('My Winning Bid')}</div>
                                                                    <div className={s.sp}>
                                                                        <div className={s.val}>
                                                                            <CustomIcon width={24} height={24}
                                                                                        className=""
                                                                                        imgName={coinObj[coinType]?.iconName}
                                                                                        name={coinObj[coinType]?.iconName}/>
                                                                            <div>{stringToNumber(myBid, 2)}</div>

                                                                        </div>
                                                                        {isLoading ?
                                                                            <AntButton
                                                                                className={`btn_gray ${s.spBtn}`}>
                                                                                ···
                                                                            </AntButton> :
                                                                            <AntButton
                                                                                className={`btn_public ${s.spBtn} ${subLoading ? 'btn_disabled' : ''}`}
                                                                                disabled={subLoading}
                                                                                onClick={() => {
                                                                                    !hasResult ? claimNft(tokenAddr, tokenId) : closeFn()
                                                                                }}>
                                                                                {hasResult ? isSucc ? t('SUCCESS') : t('Failed') : t('CLAIM')}
                                                                            </AntButton>
                                                                        }

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </InfiniteScroll>
                                    </div>
                                    <div className={`fs12 tll ${s.bottom}`}>

                                        {claimList.length > 0 && <div className={s.outBidInfo}>

                                            <div className={s.bidInfo}>
                                                <div className={s.sweepButton}>
                                                    {!done && <ButtonFactory onClick={claimBatchNft}
                                                                             disabled={subLoading}
                                                    >
                                                        {subLoading && !singleClaim ?
                                                            <div className={s.web3LoadingLine}>
                                                                <CustomIcon rotating={true} className="ml5" width={16}
                                                                            height={16}
                                                                            imgName={'Picture/UI_Picture_Loading_01'}
                                                                            name={'Picture/UI_Picture_Loading_01'}/>
                                                                <span
                                                                    className="fs18 ml5 color-black">{t('PROCESSING')}</span>
                                                            </div> :
                                                            t('CLAIM x NFT', {amount: Math.min(claimList?.length, maxBatchNumber)})
                                                        }
                                                    </ButtonFactory>}
                                                    {done && <ButtonFactory
                                                        onClick={closeFn}>{subFailded ? t('ClaimFailed') : t('ClaimSuccess')}</ButtonFactory>}
                                                </div>

                                            </div>

                                        </div>}
                                    </div>
                                </> :
                                <div className={s.noData}>{t('BidMyBidsNoDataTips2')}</div>
                        }
                    </div>
                </div>
            </div>
            {/* <AntModal
                width='90%'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={openLinkTiktokModel}
                onOk={() => setOpenLinkTiktokModel(false)}
                onCancel={() => setOpenLinkTiktokModel(false)}
            >
                <ModelLinkTiktok tiktokCodeObj={tiktokCodeObj} loading={tiktokCodeLoading}/>
            </AntModal> */}
            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg insufficientAmount={(Number(keyPrice?.lfgBalance) - lfgBalance) || 0}
                              userInfo={userInfo} closeFn={() => {
                    setShowSwapLfg(false);
                }}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('INSUFFICIENT BALANCE')}
                className="confirmModalWrap"
                centered={true}
                open={showInsufficientCrpto}
                onOk={() => setShowInsufficientCrpto(false)}
                onCancel={() => setShowInsufficientCrpto(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalInsufficientCrpto
                    insufficientAmount={(Number(keyPrice?.balance) - balance) || 0}
                    userInfo={userInfo} closeFn={() => {
                    setShowInsufficientCrpto(false);
                }}/>
            </AntModal>
        </>

    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        initTopUpAmount: app.initTopUpAmount,
        currentAuctionBatchCfg: app.currentAuctionBatchCfg,
        bidSlippage: app.bidSlippage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        setShowLink: (params) => {
            return dispatch({
                type: "app/setShowLink",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(BidMyBids));
