import React, { memo, useState, useMemo, useEffect, useRef } from "react";
import { connect } from "react-redux";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import { Radio, message, Button, Select } from "antd";
import { agora_setchantype, CHAT_TYPE, getMessageFromId, isMobile, post_trivia_start } from "@/utils/common";
import { getConfig } from "@/utils/common";
import {
    getCameras,
    getCameraTrackLabel,
    getMicrophones,

    getMicrophoneTrackLabel,
    setCameraDevice, setCameraResolution, setMicrophoneDevice, switchTrack
} from "@/utils/basicVoiceCall";
import AntButton from "@/common/AntButton";
import CustomIcon from "@/common/CustomIcon";

const ModelLiveSetting = (
    {
        curentOtherUserInfo,
        setCurentOtherUserInfo,
        setOpenSettingPanel,
        setNeedUpdateVoteInfo,
        curRtcInfo,
        disabledLiveMode,
        type = 1,
    }
) => {
    const { t, i18n } = useTranslation();
    const options = [
        { label: t('Public'), value: 1 },
        { label: t('Private'), value: 0 },
    ];
    const agoraChanType = useMemo(() => {
        return curentOtherUserInfo?.agoraChanType
    }, [curentOtherUserInfo]);
    const setLiveMode = ({ target: { value } }) => {
        agora_setchantype(value).then(res => {
            console.log(res);

            if (value === 1) {
                message.info({ content: 'Public streaming mode active' });
            } else {
                message.info({ content: 'Private streaming mode active' });
            }
            setCurentOtherUserInfo({
                ...curentOtherUserInfo,
                agoraChanType: res?.agoraChanType
            })
            setOpenSettingPanel(false)
        }).catch(e => {
            console.log(e);
            message.error({ content: 'set live mode failed, please retry' })
        })
    }

    const [loadingStartVote, setLoadingStartVote] = useState(false);
    const startVote = () => {
        setLoadingStartVote(true);
        post_trivia_start().then(data => {
            setNeedUpdateVoteInfo(true);
            setLoadingStartVote(false);
            setOpenSettingPanel(false);
        }).catch(e => {
            setLoadingStartVote(false);
        });
    }

    const [canStartVoteSystem, setCanStartVoteSystem] = useState(false);

    const userId = useMemo(() => {
        return curentOtherUserInfo?.userId
    }, [curentOtherUserInfo])

    const parseConfig = () => {
        getConfig().then(res => {
            const { hostId } = res;
            setCanStartVoteSystem(hostId === userId);
        }).catch(e => {
            console.log(e);
        });
    }

    useEffect(() => {
        parseConfig();
    }, [userId]);

    const [cameraList, setCameraList] = useState([]);
    const [cameraSelectNow, setCameraSelectNow] = useState('');

    useEffect(() => {
        getCameras().then(r => {
            let trackLabel = getCameraTrackLabel();
            const list = r.map(item => {
                if (trackLabel === item.label) {
                    setCameraSelectNow(item.deviceId);
                }
                return {
                    label: item.label,
                    value: item.deviceId
                }
            });
            if (!isMobile()) {
                list.push({
                    label: 'Desktop Screen',
                    value: 'desktop-screen'
                })
                if (curRtcInfo.videoTrackType === 'screen') {
                    setCameraSelectNow('desktop-screen');
                }
            }
            setCameraList(list);
        }).catch(e => {
            console.log(e);
        })
    }, []);

    const applyCamera = async (value) => {
        if (!value) return;
        if (value === 'desktop-screen') {
            await switchTrack('screen');
            message.success('apply success');
        } else {
            await switchTrack('camera');
            setCameraDevice(value).then(r => {
                message.success('apply success');
            });
        }
    }

    const [microphoneList, setMicrophoneList] = useState([]);
    const [microphoneSelectNow, setMicrophoneSelectNow] = useState('');

    useEffect(() => {
        getMicrophones().then(r => {
            let trackLabel = getMicrophoneTrackLabel();
            const list = r.map(item => {
                if (trackLabel === item.label) {
                    setMicrophoneSelectNow(item.deviceId);
                }
                return {
                    label: item.label,
                    value: item.deviceId
                }
            });
            setMicrophoneList(list);
        }).catch(e => {
            console.log(e);
        })
    }, []);

    const applyMicrophone = (value) => {
        if (!value) return;
        setMicrophoneDevice(value).then(r => {
            message.success('apply success');
        });
    }

    const horizontal = [
        {
            label: '1920x1080 16:9',
            value: '1920x1080'
        },
        {
            label: '1280x720 16:9',
            value: '1280x720'
        },
        {
            label: '960x540 16:9',
            value: '960x540'
        },
        {
            label: '640x360 16:9',
            value: '640x360'
        },
    ];

    const vertical = [
        {
            label: '1080x1920 9:16',
            value: '1080x1920'
        },
        {
            label: '808x1440 101:180',
            value: '808x1440'
        },
        {
            label: '720x1280 9:16',
            value: '720x1280'
        },
        {
            label: '540x960 9:16',
            value: '540x960'
        },
        {
            label: '360x640 9:16',
            value: '360x640'
        },
    ];

    const [resolutionSelectNow, setResolutionSelectNow] = useState(curRtcInfo.resolution.width + 'x' + curRtcInfo.resolution.height);

    const applyResolution = (value) => {
        if (!value) return;
        const v = value.split('x');
        const width = v[0];
        const height = v[1];
        setCameraResolution(height, width).then(r => {
            message.success('apply success');
        });
    }

    return (
        <>
            {
                type === 2 ?
                <div className={`${s.momentWrap} flex_center_start_col`}>
                    <div className={`${s.title} flex_center_center`}>
                        {t('Setting')}
                    </div>
                    {!disabledLiveMode &&
                        <div className={s.item}>
                            <div className={s.label}>{t('Live Mode')}</div>
                            <div className={s.radioWrap}>
                                <Radio.Group
                                    options={options}
                                    defaultValue={agoraChanType}
                                    onChange={setLiveMode}
                                    optionType="button"
                                    className="radioGroup"
                                    buttonStyle="solid">
                                </Radio.Group>
                            </div>
                        </div>
                    }
                    <div className={s.select}>
                        <div className={s.label}>{t('Switch Camera')}</div>
                        <div className={s.selectWrap}>
                            <Select
                                value={cameraSelectNow}
                                options={cameraList}
                                popupClassName="momentLiveSettingOption"
                                className={`momentLiveSetting`}
                                onChange={(value) => {
                                    setCameraSelectNow(value);
                                    applyCamera(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.select}>
                        <div className={s.label}>{t('Switch Microphone')}</div>
                        <div className={s.selectWrap}>
                            <Select
                                value={microphoneSelectNow}
                                options={microphoneList}
                                popupClassName="momentLiveSettingOption"
                                className='momentLiveSetting'
                                onChange={(value) => {
                                    setMicrophoneSelectNow(value);
                                    applyMicrophone(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.select}>
                        <div className={s.label}>{t('Resolution')}</div>
                        <div className={s.selectWrap}>
                            <Select
                                value={resolutionSelectNow}
                                options={[
                                    {
                                        label: t('Horizontal'),
                                        options: horizontal,
                                    },
                                    {
                                        label: t('Vertical'),
                                        options: vertical,
                                    },
                                ]}
                                popupClassName="momentLiveSettingOption"
                                className='momentLiveSetting'
                                onChange={(value) => {
                                    setResolutionSelectNow(value);
                                    applyResolution(value);
                                }}
                            />
                        </div>
                    </div>
                    <div style={{marginBottom: '38px'}}></div>
                    <CustomIcon 
                        className={`momentCloseBtn`} 
                        width={40} 
                        height={40} 
                        imgName={`Picture/UI_Picture_Icon_Close_02`}
                        onClick={() => {
                            setOpenSettingPanel();
                        }} />
                    {canStartVoteSystem &&
                        <div className={s.item}>
                            <div className={s.label}>{t('Trivia System')}</div>
                            <AntButton type="primary" onClick={() => {
                                startVote();
                            }} loading={loadingStartVote}>{t('Start Trivia')}</AntButton>
                        </div>
                    }
                </div> :
                <div className={s.wrap}>
                    {!disabledLiveMode &&
                        <div className={s.item}>
                            <div className={s.label}>{t('Live Mode')}</div>
                            <div className={s.radioWrap}>
                                <Radio.Group
                                    options={options}
                                    defaultValue={agoraChanType}
                                    onChange={setLiveMode}
                                    optionType="button"
                                    className="radioGroup"
                                    buttonStyle="solid">
                                </Radio.Group>
                            </div>
                        </div>
                    }
                    <div className={s.select}>
                        <div className={s.label}>{t('Switch Camera')}</div>
                        <div className={s.selectWrap}>
                            <Select
                                value={cameraSelectNow}
                                options={cameraList}
                                popupClassName="liveSettingOption"
                                className='liveSetting'
                                onChange={(value) => {
                                    setCameraSelectNow(value);
                                    applyCamera(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.select}>
                        <div className={s.label}>{t('Switch Microphone')}</div>
                        <div className={s.selectWrap}>
                            <Select
                                value={microphoneSelectNow}
                                options={microphoneList}
                                popupClassName="liveSettingOption"
                                className='liveSetting'
                                onChange={(value) => {
                                    setMicrophoneSelectNow(value);
                                    applyMicrophone(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.select}>
                        <div className={s.label}>{t('Resolution')}</div>
                        <div className={s.selectWrap}>
                            <Select
                                value={resolutionSelectNow}
                                options={[
                                    {
                                        label: t('Horizontal'),
                                        options: horizontal,
                                    },
                                    {
                                        label: t('Vertical'),
                                        options: vertical,
                                    },
                                ]}
                                popupClassName="liveSettingOption"
                                className='liveSetting'
                                onChange={(value) => {
                                    setResolutionSelectNow(value);
                                    applyResolution(value);
                                }}
                            />
                        </div>
                    </div>
                    {canStartVoteSystem &&
                        <div className={s.item}>
                            <div className={s.label}>{t('Trivia System')}</div>
                            <AntButton type="primary" onClick={() => {
                                startVote();
                            }} loading={loadingStartVote}>{t('Start Trivia')}</AntButton>
                        </div>
                    }
                </div>
            }
        </>
    )
}
const mapStateToProps = ({ app }) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        curRtcInfo: app.curRtcInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params
            });
        },
        setCurRtcInfo: (params) => {
            return dispatch({
                type: "app/setCurRtcInfo",
                payload: params,
            });
        },
    };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelLiveSetting));
