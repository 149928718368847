import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox, Slider, Spin} from 'antd';
import s from "./index.module.less";
import {
    post_portfolio,
    displayFilter,
    getAvatarFromProfile,
    getAvatarFromUserInfo,
    stringToNumber,
    getBattlePointRankImg,
    convertWalletBalance,
    formatCardDisplayName,
    getearninfo,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import MainHeader from "src/common/MainHeader";
import {
    mainNftNftPanelPath,
    mainClubAllClubsPath,
    publicChatsPath,
    publicNftContentPath,
    mainClubSweepClubsPath,
    balancePath,
} from "@/routes/config";
import InfiniteScroll from "react-infinite-scroll-component";
import HireActivityList from "src/common/HireActivityList";
import ModalBatchSell from "@/model/ModalBatchSell";
import AvatarInfo from "src/common/AvatarInfo";
import {AutoStrangeRule} from "@/utils/strangeRule";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import HireModelTrans from "@/model/HireModelTrans";

const normalCardBg = 'UI_Picture_CardWindow_02.png';
const detailCardBg = 'UI_Picture_CardWindow_01.png';

const Index = (
    {
        userInfo,
        earnInfo,
        showClubCardDetailInfo,
        setShowClubCardDetailInfo,
        allClubCardType,
        setAllClubCardType,
        myClubChooseType,
        setMyClubChooseType,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {userId, selfData, platformData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        balance,
        buyFirstKey,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const {platformMap, useType, defaultName} = useMemo(() => {
        return platformData || {}
    }, [platformData])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformMap, useType, userInfo])
    const [protfolioData, setProtfolioData] = useState({});
    const [needRefresh, setNeedRefresh] = useState(false);
    const [isFriends, setIsFriends] = useState(false);
    const [showBatchSell, setShowBatchSell] = useState(false);
    const [singleBuy, setSingleBuy] = useState(null);
    const [showModelTransSign, setShowModelTransSign] = useState(false);
    const [modelTransSubLoading, setModelTransSubLoading] = useState(false);
    const [modelTransDone, setModelTransDone] = useState(false);

    const list = useMemo(() => {
        return protfolioData?.holding || []
    }, [protfolioData])

    const showList = useMemo(() => {
        if (list?.length > 0) {
            let needEmpty = 4;
            let res = [...list];
            for (let i = 0; i < needEmpty; i++) {
                res.push({
                    isEmptyObj: true
                })
            }
            return res;
        } else {
            return list;
        }
    }, [list])

    const cursor = useMemo(() => {
        return protfolioData?.nextCursor || 0
    }, [protfolioData])

    const refreshCurrentPage = () => {
        refreshPortfolio();
    }
   
    useEffect(() => {
        getearninfo().then(res => {
        }).catch(e => {
            console.log(e);
        })
        refreshCurrentPage();
        if (!buyFirstKey){
            navigate(balancePath, {replace: true});
        }
    }, []);

    const {portfolio} = useMemo(()=>{
        return earnInfo || {}
    },[earnInfo])

    useEffect(() => {
        if (needRefresh) {
            refreshCurrentPage()
            setNeedRefresh(false)
        }
    }, [needRefresh])

    const refreshPortfolio = (params) => {
        const {cursor} = params || {cursor: 0}
        post_portfolio({cursor}).then(data => {
            if (cursor) {
                const l = data.holding || [];
                setProtfolioData({
                    holding: [...protfolioData.holding, ...l],
                    nextCursor: data.nextCursor,
                });
            } else {
                setProtfolioData(data);
            }
        }).catch(err => {
            console.error(err);
        });
    }

    const getMoreHireList = () => {
        refreshPortfolio({cursor})
    }

    const onGlobalChange = (e) => {
        setIsFriends(e.target.checked);
    }

    const changeShowCardDetail = (e) => {
        setShowClubCardDetailInfo(e.target.checked);
        localStorage.setItem('_TT_showClubCardDetailInfo', e.target.checked);
    }

    const onSingleBuy = (userId, needBuyKeyNum) => {
        const params = {}
        params[userId] = needBuyKeyNum < 1 ? 1 : needBuyKeyNum;
        setSingleBuy(params)
        setShowModelTransSign(true);
    }

    const updateList = () =>{
        refreshPortfolio();
    }

    const getCardBg = () =>{
        if (showClubCardDetailInfo){
            return `/UI/Picture/${detailCardBg}`;
        }
        else{
            return `/UI/Picture/${normalCardBg}`;
        }
    }

    const setAllClub = () =>{
        if (myClubChooseType === 2){
            setAllClubCardType(1);
        }
        else{
            setAllClubCardType(0);
        }
    }

    return (
        <div className={`${s.wrap} mainWrap commonBlueBg`}>
            <MainHeader hasBackBtn={false} leftTitle={t('My Clubs')}></MainHeader>
            <div className={s.selfHead} style={{backgroundImage: `url(/UI/Picture/UI_Picture-Main_Top_01.png)`}}>
                <div className={s.headLine}>
                    <AvatarInfo src={avatarUrl || originalAvatarUrl} size={70}></AvatarInfo>
                    <div className={s.headValuePart}>
                        {AutoStrangeRule(t('Portfolio Value'))}:
                        <ButtonFactory
                            size={21}
                            color={3}
                            scale={1}
                            iconName={'UI_Picture-Currency_MATIC_01'}
                            className={s.headValue}
                        >
                            <div className="ml10 color-black fs18">{convertWalletBalance(portfolio)}</div>
                        </ButtonFactory>
                    </div>
                </div>
            </div>
            <div className={s.content}>
                <div className={`${s.topPart} flex_center_start_col`}>
                    <div className="flex_center_between w100p">
                        <div className={s.topPartLeft}>
                            <div className={`${s.typeBtn} ${myClubChooseType === 0 ? s.selecedType : s.unselectedType} mr8`}
                                onClick={() => {
                                    setMyClubChooseType(0);
                                }}>
                                {AutoStrangeRule(t('Players'))}
                            </div>
                            {/* <div className={`${s.typeBtn} ${myClubChooseType === 2 ? s.selecedType : s.unselectedType} mr8`}
                                onClick={() => {
                                    setMyClubChooseType(2);
                                }}>
                                {AutoStrangeRule(t('NFTs'))}
                            </div> */}
                            <div className={`${s.typeBtn} ${myClubChooseType === 1 ? s.selecedType : s.unselectedType} mr8`}
                                onClick={() => {
                                    setMyClubChooseType(1);
                                }}>
                                {AutoStrangeRule(t('Activities'))}
                            </div>
                        </div>
                        {
                            /*myClubChooseType === 0 &&
                            <div className={`flex_center_end`}>
                                <div className={`${s.moreBtn}`}
                                    onClick={() => {
                                        if (buyFirstKey){
                                            navigate(`${mainClubSweepClubsPath}/0`);
                                        }
                                        else{
                                            navigate(balancePath);
                                        }
                                    }}>
                                    {AutoStrangeRule(t('GET MORE'))}
                                </div>
                            </div>*/
                        }
                        {
                            myClubChooseType === 1 &&
                            <>
                                <Checkbox className="hireCheckBox" onChange={onGlobalChange}
                                        defaultChecked={isFriends}>{t('Global')}</Checkbox>
                            </>
                        }
                    </div>
                    {
                        (myClubChooseType === 0 || myClubChooseType === 2) &&
                        <div className="w100p mt10 flex_center_start">
                            <Checkbox onChange={changeShowCardDetail} className="hireCheckBox"
                                checked={showClubCardDetailInfo}>
                                <span className="ml2">{t('Show Detailed Info')}</span>
                            </Checkbox>
                        </div>
                    }
                </div>
                {
                    myClubChooseType === 0 &&
                    <>
                        <div className={s.clubPart} id="clubPartRoot">
                            <InfiniteScroll
                                dataLength={list?.length || 0}
                                next={getMoreHireList}
                                hasMore={cursor !== -1}
                                loader={<div
                                    style={{
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                ><Spin/></div>}
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: `${list?.length > 1 ? 'space-between' : 'flex-start'}`,
                                    width: '100%'
                                }}
                                endMessage={
                                    <div className={s.noMore}>

                                    </div>
                                }
                                scrollableTarget={'hireHome'}
                            >
                                {
                                    list?.length > 0 && showList?.length > 0 && showList.map((item, idx) => {
                                        const {
                                            heat,
                                            holdingNum,
                                            workName,
                                            workProfit,
                                            friendliness,
                                            energy,
                                            buyPrice,
                                            userId,
                                            accountType,
                                            needBuyKeyNum,
                                            managerId,
                                            profile,
                                            needBuyTotalPrice,
                                            historyPrice,
                                            workEndTimestamp,
                                            managerProfile,
                                            workIcon,
                                            workId,
                                            tierId,
                                            selfWorkProfit,
                                            bpLevel,
                                        } = item;
                                        const {displayName} = profile || {};
                                        const originalAvatarUrl = getAvatarFromProfile(profile, 1);
                                        const avatarUrl = getAvatarFromProfile(profile);
                                        const isManager = managerId === userInfo?.userId;
                                        const showSell = userInfo?.userId === userId && holdingNum > 2 && holdingNum <= 20 && needBuyKeyNum >= holdingNum
                                        return (
                                            <>
                                                {
                                                    item?.isEmptyObj ?
                                                        <div className={s.emptyItem}>
                                                        </div> :
                                                        <div className={`${s.clubItemRoot}`} onClick={() => {
                                                            navigate(`${publicChatsPath}/${userId}`, true)
                                                        }}>
                                                            {
                                                                holdingNum > 1 &&
                                                                <div className={`${s.mulClubAvatar}`}
                                                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                                </div>
                                                            }
                                                            {
                                                                holdingNum > 1 &&
                                                                <div className={`${s.mulClubAvatar}`}
                                                                     style={{backgroundImage: `url(${originalAvatarUrl || avatarUrl})`}}>
                                                                </div>
                                                            }
                                                            {
                                                                holdingNum > 1 &&
                                                                <div className={`${s.mulClubCard}`}
                                                                     style={{backgroundImage: `url(${getCardBg()})`}}>
                                                                </div>
                                                            }
                                                            <div className={s.clubAvatar}
                                                                 style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                            </div>
                                                            <div className={s.clubAvatar}
                                                                 style={{backgroundImage: `url(${originalAvatarUrl || avatarUrl})`}}>
                                                            </div>
                                                            <div className={s.clubCard}
                                                                 style={{backgroundImage: `url(${getCardBg()})`}}>
                                                                <div className={s.cardTop}>
                                                                    <div className={s.cardName}>
                                                                        {formatCardDisplayName(displayFilter(displayName), showClubCardDetailInfo?7:14)}
                                                                    </div>
                                                                    <div className={s.cardRank}>
                                                                        {
                                                                            showClubCardDetailInfo &&
                                                                            <>
                                                                                <CustomIcon className="" width={16} height={16}
                                                                                        imgName={`Picture/Rank/RankStar_${tierId}`}/>
                                                                                <CustomIcon className="ml2" width={16} height={16}
                                                                                        imgName={`Picture/BPTier/${getBattlePointRankImg(bpLevel || 1)}`}/>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className={s.cardBottom}>
                                                                    {
                                                                        showClubCardDetailInfo ? 
                                                                            <div className={`${s.cardBottomDetail} flex_center_start_col`} 
                                                                                style={{
                                                                                    marginBottom: `12px`
                                                                                }}>
                                                                                <div className={`flex_center_center`}>
                                                                                    <CustomIcon width={16} height={16}
                                                                                                imgName={'Picture/UI_Picture-Currency_MATIC_01'}></CustomIcon>
                                                                                    <div style={{
                                                                                        fontSize: `12px`,
                                                                                        textAlign: 'center',
                                                                                    }}>
                                                                                        {stringToNumber(buyPrice, '4')}
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    isManager ? 
                                                                                        <div className={`${s.cardManagerPart} flex_center_center`}>
                                                                                            <CustomIcon width={18} height={18} className="mr2"
                                                                                                    imgName={'Picture/UI_Picture_Crown_01'}></CustomIcon>
                                                                                            <div className="fb fs12 color-white">{t('You\'re Manager')}</div>
                                                                                        </div> : 
                                                                                        <div className={`${s.cardNotManagerPart} flex_center_center_col`} 
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                onSingleBuy(userId, needBuyKeyNum);
                                                                                            }}>
                                                                                            <div className="fb fs10 color-black">{t('Be Manager')}</div>
                                                                                            <div className={`flex_center_center`}>
                                                                                                <CustomIcon width={16} height={16} className="mr2"
                                                                                                    imgName={'Picture/UI_Picture-Currency_MATIC_01'}></CustomIcon>
                                                                                                <span className="fb fs12 color-black">{stringToNumber(needBuyTotalPrice, '4')} </span>
                                                                                            </div>
                                                                                        </div>
                                                                                }
                                                                            </div> :
                                                                            <div className={`${s.cardBottomPublic}`} 
                                                                                style={{
                                                                                    marginBottom: '18px'
                                                                                }}>
                                                                                <CustomIcon width={24} height={24}
                                                                                            imgName={'Picture/UI_Picture-Currency_MATIC_01'}></CustomIcon>
                                                                                <div style={{
                                                                                    fontSize: `16px`,
                                                                                    textAlign: 'center',
                                                                                    width: '50px'
                                                                                }}>
                                                                                    {stringToNumber(buyPrice, '4')}
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                {
                                                                    accountType === "wish" &&
                                                                    <div className={s.wishPart}
                                                                         style={{backgroundImage: `url(/UI/Picture/UI_Picture-CardWish_01.png)`}}>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {
                                                                holdingNum > 1 &&
                                                                <ButtonFactory className={s.holdingCount} color={3}
                                                                               size={31} useRule={false}>
                                                                    <div style={{fontSize: '18px', color: 'rgba(0,0,0,1)'}}>
                                                                        {`x${holdingNum}`}
                                                                    </div>
                                                                </ButtonFactory>
                                                            }
                                                        </div>
                                                }
                                            </>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                            <div className={`${s.clubBottom} flex_center_start_col`}>
                                <AntButton className={`${s.clubBottomBtn} btn_yellow flex_center_center`}
                                        onClick={() => {
                                            setAllClub();
                                            navigate(mainClubAllClubsPath);
                                        }}>
                                    <div className="flex_center_center fb"
                                        style={{color: 'rgba(0,0,0,1)'}}>
                                        {t('Looking For More Clubs To Join?')}
                                    </div>
                                </AntButton>
                                <AntButton className={`${s.clubBottomBtn} mt10 btn_white flex_center_center`}
                                        onClick={() => {
                                            setShowBatchSell(true);
                                        }}>
                                    <div className="flex_center_center fb"
                                        style={{color: 'rgba(0,0,0,1)'}}>
                                        {t('Batch Sell')}
                                    </div>
                                </AntButton>
                            </div>
                        </div>
                    </>
                }
                {
                    myClubChooseType === 2 &&
                    <>
                        <div className={s.clubPart} id="clubPartRoot">
                            <InfiniteScroll
                                dataLength={list?.length || 0}
                                next={getMoreHireList}
                                hasMore={cursor !== -1}
                                loader={<div
                                    style={{
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                ><Spin/></div>}
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: `${list?.length > 1 ? 'space-between' : 'flex-start'}`,
                                    width: '100%'
                                }}
                                endMessage={
                                    <div className={s.noMore}>

                                    </div>
                                }
                                scrollableTarget={'hireHome'}
                            >
                                {
                                    list?.length > 0 && showList?.length > 0 && showList.map((item, idx) => {
                                        const {
                                            heat,
                                            holdingNum,
                                            workName,
                                            workProfit,
                                            friendliness,
                                            energy,
                                            buyPrice,
                                            userId,
                                            accountType,
                                            needBuyKeyNum,
                                            managerId,
                                            profile,
                                            needBuyTotalPrice,
                                            historyPrice,
                                            workEndTimestamp,
                                            managerProfile,
                                            workIcon,
                                            workId,
                                            tierId,
                                            selfWorkProfit,
                                            bpLevel,
                                        } = item;
                                        const {displayName} = profile || {};
                                        const originalAvatarUrl = getAvatarFromProfile(profile, 1);
                                        const avatarUrl = getAvatarFromProfile(profile);
                                        const isManager = managerId === userInfo?.userId;
                                        const showSell = userInfo?.userId === userId && holdingNum > 2 && holdingNum <= 20 && needBuyKeyNum >= holdingNum
                                        return (
                                            <>
                                                {
                                                    item?.isEmptyObj ?
                                                        <div className={s.emptyItem}>
                                                        </div> :
                                                        <div className={`${s.clubItemRoot}`} onClick={() => {
                                                            //navigate(`${publicChatsPath}/${userId}`, true)
                                                            //TODOnft
                                                            navigate(`${publicNftContentPath}/${userId}/${userId}`, true);
                                                        }}>
                                                            {
                                                                holdingNum > 1 &&
                                                                <div className={`${s.mulClubAvatar}`}
                                                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                                </div>
                                                            }
                                                            {
                                                                holdingNum > 1 &&
                                                                <div className={`${s.mulClubAvatar}`}
                                                                     style={{backgroundImage: `url(${originalAvatarUrl || avatarUrl})`}}>
                                                                </div>
                                                            }
                                                            {
                                                                holdingNum > 1 &&
                                                                <div className={`${s.mulClubCard}`}
                                                                     style={{backgroundImage: `url(${getCardBg()})`}}>
                                                                </div>
                                                            }
                                                            <div className={s.clubAvatar}
                                                                 style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                            </div>
                                                            <div className={s.clubAvatar}
                                                                 style={{backgroundImage: `url(${originalAvatarUrl || avatarUrl})`}}>
                                                            </div>
                                                            <div className={s.clubCard}
                                                                 style={{backgroundImage: `url(${getCardBg()})`}}>
                                                                <div className={s.cardTop}>
                                                                    <div className={s.cardName}>
                                                                        {formatCardDisplayName(displayFilter(displayName), showClubCardDetailInfo?7:14)}
                                                                    </div>
                                                                    <div className={s.cardRank}>
                                                                        {
                                                                            showClubCardDetailInfo &&
                                                                            <>
                                                                                <CustomIcon className="" width={16} height={16}
                                                                                        imgName={`Picture/Rank/RankStar_${tierId}`}/>
                                                                                <CustomIcon className="ml2" width={16} height={16}
                                                                                        imgName={`Picture/BPTier/${getBattlePointRankImg(bpLevel || 1)}`}/>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className={s.cardBottom}>
                                                                    {
                                                                        showClubCardDetailInfo ? 
                                                                            <div className={`${s.cardBottomDetail} flex_center_start_col`} 
                                                                                style={{
                                                                                    marginBottom: `12px`
                                                                                }}>
                                                                                <div className={`flex_center_center`}>
                                                                                    <CustomIcon width={16} height={16}
                                                                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}></CustomIcon>
                                                                                    <div style={{
                                                                                        fontSize: `12px`,
                                                                                        textAlign: 'center',
                                                                                    }}>
                                                                                        {convertWalletBalance(buyPrice)}
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    isManager ? 
                                                                                        <div className={`${s.cardManagerPart} flex_center_center`}>
                                                                                            <CustomIcon width={18} height={18} className="mr2"
                                                                                                    imgName={'Picture/UI_Picture_Crown_01'}></CustomIcon>
                                                                                            <div className="fb fs12 color-white">{t('You\'re Manager')}</div>
                                                                                        </div> : 
                                                                                        <div className={`${s.cardNotManagerPart} flex_center_center_col`} 
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                onSingleBuy(userId, needBuyKeyNum);
                                                                                            }}>
                                                                                            <div className="fb fs10 color-black">{t('Be Manager')}</div>
                                                                                            <div className={`flex_center_center`}>
                                                                                                <CustomIcon width={16} height={16} className="mr2"
                                                                                                    imgName={'Picture/UI_Picture-Currency_LFG_01'}></CustomIcon>
                                                                                                <span className="fb fs12 color-black">{convertWalletBalance(needBuyTotalPrice)} </span>
                                                                                            </div>
                                                                                        </div>
                                                                                }
                                                                            </div> :
                                                                            <div className={`${s.cardBottomPublic}`} 
                                                                                style={{
                                                                                    marginBottom: '18px'
                                                                                }}>
                                                                                <CustomIcon width={24} height={24}
                                                                                            imgName={'Picture/UI_Picture-Currency_MATIC_01'}></CustomIcon>
                                                                                <div style={{
                                                                                    fontSize: `16px`,
                                                                                    textAlign: 'center',
                                                                                    width: '50px'
                                                                                }}>
                                                                                    {stringToNumber(buyPrice, '4')}
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                {
                                                                    accountType === "wish" &&
                                                                    <div className={s.wishPart}
                                                                         style={{backgroundImage: `url(/UI/Picture/UI_Picture-CardWish_01.png)`}}>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {
                                                                holdingNum > 1 &&
                                                                <ButtonFactory className={s.holdingCount} color={3}
                                                                               size={31} useRule={false}>
                                                                    <div style={{fontSize: '18px', color: 'rgba(0,0,0,1)'}}>
                                                                        {`x${holdingNum}`}
                                                                    </div>
                                                                </ButtonFactory>
                                                            }
                                                        </div>
                                                }
                                            </>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                            <div className={`${s.clubBottom} flex_center_start_col`}>
                                <AntButton className={`${s.clubBottomBtn} btn_yellow flex_center_center`}
                                        onClick={() => {
                                            setAllClub();
                                            navigate(mainClubAllClubsPath);
                                        }}>
                                    <div className="flex_center_center fb"
                                        style={{color: 'rgba(0,0,0,1)'}}>
                                        {t('Looking For More Clubs To Join?')}
                                    </div>
                                </AntButton>
                                <AntButton className={`${s.clubBottomBtn} mt10 btn_white flex_center_center`}
                                        onClick={() => {
                                            setShowBatchSell(true);
                                        }}>
                                    <div className="flex_center_center fb"
                                        style={{color: 'rgba(0,0,0,1)'}}>
                                        {t('Batch Sell')}
                                    </div>
                                </AntButton>
                            </div>
                        </div>
                    </>
                }
                {
                    myClubChooseType === 1 &&
                    <div className={s.activityList}>
                        <HireActivityList isFriends={isFriends}/>
                    </div>
                }
            </div>
            <AntModal
                width='330px'
                title={t('BATCH SELL')}
                className="confirmModalWrap"
                centered={true}
                open={showBatchSell}
                destroyOnClose={true}
                onOk={() => setShowBatchSell(false)}
                onCancel={() => setShowBatchSell(false)}
                zIndex={2000}
                bodyStyle={{height: '600px'}}
            >
                <ModalBatchSell nowData={list} 
                                refreshPortfolio={refreshCurrentPage}
                                setShowModelTrans={setShowBatchSell} 
                                userInfo={userInfo}
                                cardType={myClubChooseType === 2 ? 1:0}
                                laterUpdate={()=>{
                                    setTimeout(() => {
                                        refreshCurrentPage();
                                    }, 5000);
                                }}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('BECOME THE MANAGER')}
                className="confirmModalWrap"
                centered={true}
                open={showModelTransSign}
                destroyOnClose={true}
                onOk={() => setShowModelTransSign(false)}
                onCancel={() => {
                    setShowModelTransSign(false);
                    setSingleBuy(null)
                }}
                keyboard={!modelTransSubLoading || modelTransDone}
                maskClosable={!modelTransSubLoading || modelTransDone}
            >
                <HireModelTrans
                    closeHandle={() => {
                        setShowModelTransSign(false);
                        setSingleBuy(null);
                    }}
                    buyList={singleBuy}
                    refreshHomeList={updateList}
                    done={modelTransDone}
                    setDone={setModelTransDone}
                    subLoading={modelTransSubLoading}
                    setSubLoading={setModelTransSubLoading}
                    cardType={myClubChooseType === 2 ? 1:0}
                />
            </AntModal>
        </div>
    )
};

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo,
        showClubCardDetailInfo: app.showClubCardDetailInfo,
        allClubCardType: app.allClubCardType,
        myClubChooseType: app.myClubChooseType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params,
            });
        },
        setShowClubCardDetailInfo: (params) => {
            return dispatch({
                type: "app/setShowClubCardDetailInfo",
                payload: params,
            });
        },
        setAllClubCardType: (params) => {
            return dispatch({
                type: "app/setAllClubCardType",
                payload: params,
            });
        },
        setMyClubChooseType: (params) => {
            return dispatch({
                type: "app/setMyClubChooseType",
                payload: params,
            });
        },
    };
};
export default memo(connect(mapStateToProps, mapDispatchToProps)(Index));
