import React, { memo, useState, useMemo, useRef, useEffect } from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import { Button, Select, Checkbox } from "antd";
import { useTranslation, Trans } from 'react-i18next';
import AntButton from "@/common/AntButton";
import { AutoStrangeRule } from "@/utils/strangeRule";

const { Option } = Select;
const MemeClubSort = (
    {
        applyFn = () =>{},
        sortData,
        nativeTokenList,
        sortType,
        setSortType,
        coinId,
        setCoinId,
        isAsc,
        setIsAsc,
        coinList,
    }
) => {
    const { t, i18n } = useTranslation();

    const [currentSortType, setCurrentSortType] = useState(sortType);
    const [currentIsAsc, setCurrentIsAsc] = useState(isAsc);
    const [currentCoinId, setCurrentCoinId] = useState(coinId);

    const [openCoinId, setOpenCoinId] = useState(false);

    useEffect(() => {
        console.log("nativeTokenList", nativeTokenList, currentCoinId);
        if (nativeTokenList?.length > 0){
            let supportCurrentCoin = false;
            for (let i = 0; i < nativeTokenList?.length; i++){
                if (nativeTokenList[i].ID === currentCoinId){
                    supportCurrentCoin = true;
                    break;
                }
            }
            console.log("supportCurrentCoin", supportCurrentCoin);
            if (!supportCurrentCoin){
                setCurrentCoinId(nativeTokenList[0].ID);
            }
        }
    }, [])

    const apply = () => {
        applyFn({
            sortType: currentSortType,
            isAsc: currentIsAsc,
            coinId: currentCoinId,
        });
    }

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <CustomIcon width={25} className="mr5" height={25} imgName={'Button/UI_Button_UpAndDown_01'}
                    name={'Button/UI_Button_UpAndDown_01'} /> {AutoStrangeRule(t('SORTING'))}
            </div>
            <div className={s.filterCon}>
                <div className={s.sortConlist}>
                    {sortData?.length && sortData.map(item => {
                        const { label, key } = item;
                        return (
                            <>
                                <div className={s.sortOption} key={key}>
                                    <Checkbox 
                                        onChange={() => {
                                            setCurrentSortType(key);
                                        }}
                                        className="hireCheckBox"
                                        checked={currentSortType === key}>
                                        <span className="">{label}</span>
                                    </Checkbox>
                                </div>
                                {
                                    (currentSortType === 4 && key === 4) &&
                                    <></>
                                    /*<Select
                                        value={currentCoinId}
                                        defaultValue={currentCoinId}
                                        onChange={(val) => {
                                            setCurrentCoinId(val);
                                        }}
                                        popupClassName="liveSettingOption"
                                        className='memeClubSel'
                                        open={openCoinId}
                                        onClick={()=>{
                                            setOpenCoinId(!openCoinId)
                                        }}
                                        suffixIcon={
                                            <div className="memeClubSelSuffixIcon">
                                                <CustomIcon imgName={'Button/UI_Button_Triangle-White_Down_01'} width={10} height={7}></CustomIcon>
                                            </div>
                                        }
                                    >
                                        {
                                            coinList?.map(item => {
                                                const { value, label, icon } = item;
                                                return (
                                                    <Option value={value} key={value}>
                                                        <CustomIcon imgName={`Picture/${icon}`} className="mr5" width={18} height={18}></CustomIcon>
                                                        {label}
                                                    </Option>
                                                )
                                            })
                                        }
                                    </Select>*/
                                }
                            </>
                        )
                    })}

                </div>
            </div>

            <div className={s.bottom}>
                <AntButton 
                    className={`${currentIsAsc === true ? 'btn_white' : 'btn_dark'} mb10`}
                    onClick={() => {
                        setCurrentIsAsc(true);
                    }}>
                    <CustomIcon width={15} height={15}
                        imgName={currentIsAsc ? 'Button/UI_Button_UP_02' : 'Button/UI_Button_UP_01'}></CustomIcon>


                    {t('Ascending')}
                    &nbsp;
                </AntButton>
                <AntButton 
                    className={`${currentIsAsc === false ? 'btn_white' : 'btn_dark'} mb10`}
                    onClick={() => {
                        setCurrentIsAsc(false);
                    }}>
                    <CustomIcon width={15} height={15}
                        imgName={!currentIsAsc ? 'Button/UI_Button_Down_02' : 'Button/UI_Button_Down_01'}></CustomIcon>

                    {t('Descending')}
                </AntButton>
                <AntButton className="btn_public mb10" onClick={apply}>{t('APPLY')}</AntButton>
                {/* <div onClick={() => setSort('v', 0)} className={`${sortVol === 0 && s.active}`}>Desc</div>
                    <div onClick={() => setSort('v', )} className={`${sortVol === 1 && s.active}`}>↑Asc</div> */}
            </div>

        </div>
    )
}
export default memo(MemeClubSort);
