import React, {memo, useMemo, useEffect, useState, useRef, useReducer} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {
    unit,
    getProfile,
    post_portfolio,
    post_hire_list,
    stringToNumber,
    joinChat,
    sliceStr,
    displayFilter,
    getAvatarFromProfile,
    getBattlePointRankImg,
    formatCardDisplayName,
    maticIconUrl,
    lfgIconUrl,
} from "@/utils/common";
import AvatarInfo from "../AvatarInfo";
import ButtonFactory from "@/common/ButtonFactory";
import CustomIcon from "../CustomIcon";
import {useNavigate} from "react-router-dom";
import {Button, Checkbox, Modal, Slider, Spin, Drawer} from "antd";
import HireModelTrans from "@/model/HireModelTrans";
import HireFilter from "../../drawer/HireFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import HireSort from "../../drawer/HireSort";
import TierConfig from '@/utils/json/TierConfig.json';
import {useTranslation, Trans} from 'react-i18next';
import ModalSweepClub from "@/model/ModalSweepClub";
import {isNumber} from "mathjs";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";

const HireList = (
    {
        cardType,
        userInfo,
        showSort,
        setShowSort,
        showFilter,
        setShowFilter,
    }
) => {
    const {t, i18n} = useTranslation();
    const {userId} = userInfo;
    const maxBatchSelectNum = 10;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    const [showModelTrans, setShowModelTrans] = useState(false);
    const [showModelTransSign, setShowModelTransSign] = useState(false);

    const [disableHire, setDisableHire] = useState(true);
    const [checkedArr, setCheckedArr] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [done, setDone] = useState(false);
    const [subLoading, setSubLoading] = useState(false);

    const showList = useMemo(() => {
        if (list?.length > 0) {
            let needEmpty = 4;
            let res = [...list];
            for (let i = 0; i < needEmpty; i++) {
                res.push({
                    isEmptyObj: true
                })
            }
            return res;
        } else {
            return list;
        }
    }, [list])

    const currencyIcon = useMemo(() => {
        return cardType === 1?lfgIconUrl:maticIconUrl;
    }, [cardType])

    const onChange = (checkedValues) => {
        if (checkedValues.length > maxBatchSelectNum) {
            return;
        }
        setCheckedArr([...checkedValues]);
        setDisableHire(checkedValues.length === 0);
    };
    const onChangeRank = (value) => {
        let arr = [];
        if (value) {
            for (let i = 0; i < value; i++) {
                arr.push(list[i]?.userId)
            }
        }
        setCheckedArr([...arr])
        setDisableHire(arr.length === 0);
    }

    useEffect(() => {
        let price = 0;
        checkedArr?.length > 0 && checkedArr.map(item => {
            const u = list.find(i => i.userId === item);
            price += Number(u?.needBuyTotalPrice);
        })
        setTotalPrice(price);
    }, [checkedArr]);

    const nextCursor = useRef(0);
    const [cursor, setCursor] = useState("")
    //---------------------------------------------------------------------------------//
    const [sortKey, setSortKey] = useState(() => {
        const localData = localStorage.getItem('all_club_sortKey');
        return localData ? JSON.parse(localData) : 1;
    });
    useEffect(() => {
        localStorage.setItem('all_club_sortKey', JSON.stringify(sortKey));
    }, [sortKey])
    //---------------------------------------------------------------------------------//
    const [sortVol, setSortVol] = useState(() => {
        const localData = localStorage.getItem('all_club_sortVol');
        return localData ? JSON.parse(localData) : 0;
    });
    useEffect(() => {
        localStorage.setItem('all_club_sortVol', JSON.stringify(sortVol));
    }, [sortVol])
    //---------------------------------------------------------------------------------//
    const [filterPrice, setFilterPrice] = useState(() => {
        const localData = localStorage.getItem('all_club_filterPrice');
        return localData ? JSON.parse(localData) : [0, 100];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterPrice', JSON.stringify(filterPrice));
    }, [filterPrice])
    //---------------------------------------------------------------------------------//
    const [filterHolding, setFilterHolding] = useState(() => {
        const localData = localStorage.getItem('all_club_filterHolding');
        return localData ? JSON.parse(localData) : [0, 200];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterHolding', JSON.stringify(filterHolding));
    }, [filterHolding])
    //---------------------------------------------------------------------------------//
    const [filterHolder, setFilterHolder] = useState(() => {
        const localData = localStorage.getItem('all_club_filterHolder');
        return localData ? JSON.parse(localData) : [0, 200];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterHolder', JSON.stringify(filterHolder));
    }, [filterHolder])
    //---------------------------------------------------------------------------------//
    const [filterLevel, setFilterLevel] = useState(() => {
        const localData = localStorage.getItem('all_club_filterLevel');
        return localData ? JSON.parse(localData) : [1, 15];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterLevel', JSON.stringify(filterLevel));
    }, [filterLevel])
    //---------------------------------------------------------------------------------//
    const [filterTwitterFollower, setFilterTwitterFollower] = useState(() => {
        const localData = localStorage.getItem('all_club_filterTwitterFollower');
        return localData ? JSON.parse(localData) : [0, 200000];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterTwitterFollower', JSON.stringify(filterTwitterFollower));
    }, [filterTwitterFollower])
    //---------------------------------------------------------------------------------//
    const [selMyholders, setSelMyholders] = useState(() => {
        const localData = localStorage.getItem('all_club_selMyholders');
        return localData ? JSON.parse(localData) : false;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selMyholders', JSON.stringify(selMyholders));
    }, [selMyholders])
    //---------------------------------------------------------------------------------//
    const [selMyholding, setSelMyholding] = useState(() => {
        const localData = localStorage.getItem('all_club_selMyholding');
        return localData ? JSON.parse(localData) : false;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selMyholding', JSON.stringify(selMyholding));
    }, [selMyholding])
    //---------------------------------------------------------------------------------//
    const [selIgnoreGuestUsers, setSelIgnoreGuestUsers] = useState(() => {
        const localData = localStorage.getItem('all_club_selIgnoreGuestUsers_D15');
        return localData ? JSON.parse(localData) : true;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selIgnoreGuestUsers_D15', JSON.stringify(selIgnoreGuestUsers));
    }, [selIgnoreGuestUsers])
    //---------------------------------------------------------------------------------//
    const [selIgnoreWishUsers, setSelIgnoreWishUsers] = useState(() => {
        const localData = localStorage.getItem('all_club_selIgnoreWishUsers_D15');
        return localData ? JSON.parse(localData) : true;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selIgnoreWishUsers_D15', JSON.stringify(selIgnoreWishUsers));
    }, [selIgnoreWishUsers])
    //---------------------------------------------------------------------------------//

    const setSort = (type, key) => {
        if (type === 'v') {
            setSortVol(key)
        } else {
            setSortKey(key)
        }
    }

    const getMoreHireList = (cs = "") => {
        const priceRange = filterPrice[1] === 100 ? [filterPrice[0], -1] : filterPrice
        const holdersRange = filterHolder[1] === 200 ? [filterHolder[0], -1] : filterHolder
        const holdingRange = filterHolding[1] === 200 ? [filterHolding[0], -1] : filterHolding
        const levelRange = filterLevel
        const twitterFollowerRange = filterTwitterFollower[1] === 200000 ? [filterTwitterFollower[0], -1] : filterTwitterFollower
        post_hire_list({
            cursor: cs === "" ? `${cs}` : `${cursor}`,
            sortType: sortKey,
            isAsc: !sortVol,
            priceRange,
            holdersRange,
            holdingRange,
            levelRange,
            twitterFollowerRange,
            prioritizeMyHolders: false,
            prioritizeMyHolding: false,
            prioritizeIgnoreGuestUsers: selIgnoreGuestUsers,
            prioritizeIgnoreWishUsers: selIgnoreWishUsers,
        }).then(data => {
            setList(cs === "" ? (data?.usersData || []) : [...list, ...data?.usersData || []]);
            let _nextCursor = data?.nextCursor;
            nextCursor.current = _nextCursor;
            setCursor(_nextCursor)
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    }

    const getMoreClubList = () =>{
        getMoreHireList(cursor);
    }

    useEffect(() => {
        getMoreHireList();
    }, []);

    const refreshHireList = () => {
        setCursor("");
        setList([]);
        setCheckedArr([]);
        setDisableHire(true);
        getMoreHireList("");
    }

    const buyList = useRef({})
    const buyData = useRef([])
    const [singleBuy, setSingleBuy] = useState(null)
    const HireClick = () => {
        if (disableHire) return;
        buyList.current = {};
        buyData.current = [];
        checkedArr?.length > 0 && checkedArr.map(item => {
            const u = list.find(i => i.userId === item);
            buyList.current[item] = u?.needBuyKeyNum < 1 ? 1 : u?.needBuyKeyNum;
            buyData.current.push(u)
        })
        setShowModelTrans(true);
    }
    const onSingleBuy = (userId, needBuyKeyNum) => {
        const params = {}
        params[userId] = needBuyKeyNum < 1 ? 1 : needBuyKeyNum
        setSingleBuy(params)
        setShowModelTransSign(true);
    }

    function followerNumString(num) {
        if (!isNumber(num)) return '';
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            return num.toString();
        }
    }

    return (
        <div className={s.wrap}>
            <div className={s.list} id="hireList">
                <Checkbox.Group
                    style={{
                        width: '100%'
                    }}
                    value={checkedArr}
                    onChange={onChange}
                >
                    <InfiniteScroll
                        dataLength={list?.length || 0}
                        next={getMoreClubList}
                        hasMore={cursor !== ""}
                        loader={<div
                            style={{
                                textAlign: "center",
                                width: "100%",
                            }}
                        ><Spin/></div>}
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: `${list?.length > 1 ? 'space-between' : 'flex-start'}`,
                            width: '100%',
                        }}
                        endMessage={
                            <div style={{
                                textAlign: "center",
                                width: "100%",
                                color: "var(--color-gray)",
                                fontSize: '12px'
                            }}>{t('No More Data')}</div>
                        }
                        scrollableTarget={'hireList'}
                    >
                        {
                            list?.length > 0 && showList?.length > 0 && showList.map((item, idx) => {
                                const {
                                    heat,
                                    holdingNum,
                                    friendliness,
                                    buyPrice,
                                    userId,
                                    accountType,
                                    managerOwnKeyNum,
                                    needBuyKeyNum,
                                    needBuyTotalPrice,
                                    profile,
                                    managerProfile,
                                    ownKeyNum,
                                    tierId,
                                    followerNum,
                                    bpLevel,
                                } = item;
                                const {displayName} = profile || {};
                                const originalAvatarUrl = getAvatarFromProfile(profile, 1);
                                const avatarUrl = getAvatarFromProfile(profile);
                                return (
                                    <>
                                        {
                                            item?.isEmptyObj ?
                                                <div className={s.emptyItem}></div> :
                                                <label className={`${s.clubItemRoot}`} key={userId}>
                                                    {
                                                        /*holdingNum > 1 &&
                                                        <div className={`${s.mulClubAvatar}`}
                                                             style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                        </div>*/
                                                    }
                                                    {
                                                        /*holdingNum > 1 &&
                                                        <div className={`${s.mulClubAvatar}`}
                                                             style={{backgroundImage: `url(${originalAvatarUrl || avatarUrl})`}}>
                                                        </div>*/
                                                    }
                                                    {
                                                        /*holdingNum > 1 &&
                                                        <div className={`${s.mulClubCard}`}
                                                             style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)`}}>
                                                        </div>*/
                                                    }
                                                    <div className={s.clubAvatar}
                                                         style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                    </div>
                                                    <div className={s.clubAvatar}
                                                         style={{backgroundImage: `url(${originalAvatarUrl || avatarUrl})`}}>
                                                    </div>
                                                    <div className={s.clubCard}
                                                         style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)`}}>
                                                        <div className={s.cardTop}>
                                                            <div className={s.cardName}>
                                                                {formatCardDisplayName(displayFilter(displayName))}
                                                            </div>
                                                            <div className={s.cardRank}>
                                                                <CustomIcon className="" width={16} height={16}
                                                                            imgName={`Picture/Rank/RankStar_${tierId}`}/>
                                                                <CustomIcon className="ml2" width={16} height={16}
                                                                            imgName={`Picture/BPTier/${getBattlePointRankImg(bpLevel || 1)}`}/>
                                                            </div>
                                                        </div>
                                                        <div className={s.cardBottom}>
                                                            <div className={s.cardBottomCheckBox}>
                                                                <Checkbox className={`sweepCheckBox`} value={userId}/>
                                                            </div>
                                                            <div className={s.cardBottomRightLine}>
                                                                <CustomIcon width={16} height={16}
                                                                            imgName={currencyIcon}></CustomIcon>
                                                                <div className={s.cardBottomRightLineValue}>
                                                                    {stringToNumber(buyPrice, '4')}
                                                                </div>
                                                            </div>
                                                            <div className={s.managerTitle}>
                                                                {t('Be Manager')}:
                                                            </div>
                                                            <div className={s.cardBottomRightLine}>
                                                                <CustomIcon width={16} height={16}
                                                                            imgName={currencyIcon}></CustomIcon>
                                                                <div className={s.cardBottomRightLineValue}>
                                                                    {stringToNumber(needBuyTotalPrice, '4')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            accountType === "wish" &&
                                                            <div className={s.wishPart}
                                                                 style={{backgroundImage: `url(/UI/Picture/UI_Picture-CardWish_01.png)`}}>
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        /*holdingNum > 1 &&
                                                        <ButtonFactory className={s.holdingCount} color={3} size={31}>
                                                            <div style={{fontSize: '18px', color: 'rgba(0,0,0,1)'}}>
                                                                {`x ${holdingNum}`}
                                                            </div>
                                                        </ButtonFactory>*/
                                                    }
                                                </label>
                                        }
                                    </>
                                )
                            })
                        }
                    </InfiniteScroll>
                </Checkbox.Group>
            </div>
            <div className={s.bottom} style={{backgroundImage: `url(/UI/Picture/UI_Picture-Main_Down_01.png)`}}>
                <div className={s.slider}>
                    <div className={s.sliderWrap}>
                        <Slider
                            defaultValue={0}
                            value={checkedArr?.length}
                            className="hireSlider commonSlider"
                            onChange={onChangeRank}
                            max={Math.min(list?.length, maxBatchSelectNum)}/>
                    </div>
                    <div className="df jc-spaceBetween aic w100p mb5">
                        <div className="fs14 color-white">
                            <span className="color-white fb">{checkedArr?.length}</span>
                            &nbsp;{t('selected')}
                        </div>
                        <div className="fs12 color-white df aic">
                            <CustomIcon width={20} height={20}
                                        imgName={currencyIcon}/>
                            <span
                                className="color-white ml5 fb">{stringToNumber(totalPrice, '4')}</span>
                        </div>
                    </div>
                </div>
                <div className={s.btn}>
                    <ButtonFactory className={s.joinBtn} color={1} size={33} scale={1.2} onClick={() => {
                        HireClick();
                    }}>
                        <div style={{fontSize: `14px`, color: `rgba(0, 0, 0, 1)`, fontWeight: 'bold'}}>
                            {t('JOIN CLUBS')}
                        </div>
                    </ButtonFactory>
                </div>

            </div>
            <AntModal
                width='330px'
                title={t('BECOME THE MANAGER')}
                className="confirmModalWrap"
                centered={true}
                open={showModelTransSign}
                destroyOnClose={true}
                onOk={() => setShowModelTransSign(false)}
                onCancel={() => {
                    setShowModelTransSign(false);
                    setSingleBuy(null)
                }}
                keyboard={!subLoading || done}
                maskClosable={!subLoading || done}
            >
                <HireModelTrans
                    closeHandle={() => {
                        setShowModelTransSign(false)
                        setSingleBuy(null)
                    }}
                    buyList={singleBuy}
                    refreshHomeList={refreshHireList}
                    done={done}
                    setDone={setDone}
                    subLoading={subLoading}
                    setSubLoading={setSubLoading}
                />
            </AntModal>
            <AntModal
                width='330px'
                title={t('SWEEP KEYS')}
                className="confirmModalWrap transModal"
                centered={true}
                open={showModelTrans}
                destroyOnClose={true}
                zIndex={3000}
                onOk={() => setShowModelTrans(false)}
                onCancel={() => {
                    setShowModelTrans(false);
                    setSingleBuy(null)
                }}
                bodyStyle={{height: 600, overflow: 'hidden'}}
                keyboard={!subLoading || done}
                maskClosable={!subLoading || done}
            >
                <ModalSweepClub managerBuyList={singleBuy ? singleBuy : buyList.current} buyData={buyData.current}
                                refreshHomeList={refreshHireList} setShowModelTrans={setShowModelTrans}
                                userInfo={userInfo}
                                cardType={cardType}/>
            </AntModal>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowFilter(false)}
                width={300}
                open={showFilter}
                className="hireFilter"
            >
                <HireFilter
                    filterPrice={filterPrice}
                    setFilterPrice={setFilterPrice}
                    filterHolder={filterHolder}
                    setFilterHolding={setFilterHolding}
                    filterHolding={filterHolding}
                    setFilterHolder={setFilterHolder}
                    selMyholders={selMyholders}
                    setSelMyholders={setSelMyholders}
                    selMyholding={selMyholding}
                    setSelMyholding={setSelMyholding}
                    closeHandle={() => {
                        setShowFilter(false)
                    }}
                    getHireList={getMoreHireList}
                    setCheckedArr={setCheckedArr}
                    filterLevel={filterLevel}
                    setFilterLevel={setFilterLevel}
                    filterTwitterFollower={filterTwitterFollower}
                    setFilterTwitterFollower={setFilterTwitterFollower}
                    selIgnoreGuestUsers={selIgnoreGuestUsers}
                    setSelIgnoreGuestUsers={setSelIgnoreGuestUsers}
                    selIgnoreWishUsers={selIgnoreWishUsers}
                    setSelIgnoreWishUsers={setSelIgnoreWishUsers}
                />
            </AntDrawer>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowSort(false)}
                width={245}
                open={showSort}
                className="hireSort"
            >
                <HireSort
                    sortKey={sortKey}
                    sortVol={sortVol}
                    setSort={setSort}
                    closeHandle={() => {
                        setShowSort(false)
                    }}
                    getHireList={getMoreHireList}
                    setCheckedArr={setCheckedArr}
                />
            </AntDrawer>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        activeInd: app.activeInd,
        tranList: app.tranList,
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveInd: (params) => {
            return dispatch({
                type: "app/setActiveInd",
                payload: params,
            });
        },
        setTranList: (params) => {
            return dispatch({
                type: "app/setTranList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(HireList));
