import React, {memo, useEffect, useRef, useState} from "react";
import s from "./index.module.less";
import {Button, Input, Modal} from "antd";
import {
    PrivyLoginStatusError, 
    TransactionError,
    stringToNumber,
} from "@/utils/common";
import CustomIcon from "../../../../common/CustomIcon";
import {useTranslation} from 'react-i18next';
import {
    lfgApproveOld,
    lfgStake, lfgStakeBlockNumber,
    lfgStakeConfig,
    lfgStakeGetCurrentValues, lfgStakeUsers,
    lfgStakeUsersLockingWeight
} from "@/utils/lfgStake";
import {useWallets} from "@privy-io/react-auth";
import {ethers} from "ethers";
import {bignumber} from "mathjs";
import {BigNumber} from "@ethersproject/bignumber";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import ModelStakeIntro from "@/model/ModelStakeIntro";
import AntModal from "@/common/AntModal";
import {AutoStrangeRule} from "@/utils/strangeRule";

const ModelStake = (
    {
        LFGbalance,
        setShowStake,
        refreshStakeInfo,
        setMaskClosable,
        setReFresh
    }
) => {
    const {t, i18n} = useTranslation();
    const [amount, setAmount] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState('90');
    const [subLoading, setSubLoading] = useState(false);
    const [subFailded, setSubFailded] = useState(false);
    const [done, setDone] = useState(false);
    const [noSuccessText, setNoSuccessText] = useState('Failed');
    const [showModelIntro, setShowModelIntro] = useState(false)
    const amountChangeHandler = (e) => {
        let value = e.target.value.replace(/[^\d-.]/g, '');
        setAmount(value)
    }
    const amountBlurHandler = (e) => {
        setAmount(Math.min(amount, LFGbalance))
    }

    const {wallets} = useWallets();
    const transferFn = () => {
        if (!(amount)) return
        // day time
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getTime() + parseInt(selectedPeriod) * 24 * 60 * 60 * 1000);
        // const futureDate = new Date(currentDate.getTime() + 200 * 1000);
        const futureTimestamp = parseInt(futureDate.getTime() / 1000);

        setSubLoading(true);
        setMaskClosable(false);
        lfgApproveOld(wallets, amount).then(res => {
            lfgStake(wallets, amount, futureTimestamp).then(res => {
                setSubLoading(false);
                setMaskClosable(true);
                setDone(true);
                setSubFailded(false)
                refreshStakeInfo();
                setAmount(0)
                setReFresh(new Date().getTime())
            }).catch(e => {
                console.log(e);
                if (e?.reason != null) {
                    for (const transactionErrorElement in TransactionError) {
                        if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                            setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                            break;
                        }
                    }
                }

                if (e === 'need login') {
                    setNoSuccessText(PrivyLoginStatusError)
                }

                setSubLoading(false);
                setMaskClosable(true);
                setDone(true);
                setSubFailded(true)
            })
        }).catch(e => {
            console.log(e);
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }

            if (e === 'need login') {
                setNoSuccessText(PrivyLoginStatusError)
            }

            setSubLoading(false);
            setMaskClosable(true);
            setDone(true);
            setSubFailded(true)
        })

    }
    const getMax = () => {
        setAmount(LFGbalance)
    }
    const handlePeriodChange = (period) => {
        setSelectedPeriod(period);
    }

    // const usersLockingWeight = useRef(0);
    const stakeConfig = useRef({});
    const stakeCurrentValues = useRef({});
    const stakeBlockNumber = useRef({});
    const stakeUsers = useRef({});
    useEffect(() => {
        if (!wallets.length) return
        // lfgStakeUsersLockingWeight(wallets).then(res => {
        //     usersLockingWeight.current = res
        // });
        lfgStakeConfig(wallets).then(res => {
            stakeConfig.current = res
        })
        lfgStakeGetCurrentValues(wallets).then(res => {
            stakeCurrentValues.current = res
        })
        lfgStakeBlockNumber(wallets).then(res => {
            stakeBlockNumber.current = res
        })
        lfgStakeUsers(wallets).then(res => {
            stakeUsers.current = res
        })
    }, [wallets])

    const [apyData, setApyData] = useState('0');

    const getAPY = (amount, lockedTimeDay) => {
        try {
            const amountEth = ethers.utils.parseEther(amount.toString());
            const lockedTime = parseInt(lockedTimeDay) * 3600 * 24;

            let poolConfig = stakeConfig.current;
            let endBlock = poolConfig.endBlock;
            let lockedBlocks = BigNumber.from(lockedTime).mul(42000).div(3600 * 24);
            let [tokensPerBlock, totalYieldRewards, yieldRewardsPerWeight, lastRatioUpdate, usersLockingWeight, lastYieldDistribution] = stakeCurrentValues.current;
            // console.log(tokensPerBlock.toString(), totalYieldRewards.toString(), yieldRewardsPerWeight.toString(), lastRatioUpdate.toString(), usersLockingWeight.toString(), lastYieldDistribution.toString());
            // we get the value of tokensPerBlock at the time the claim happens
            let numberOfWeeks = lockedBlocks.div(poolConfig.blocksPerUpdate);
            for (let i = 0; i < numberOfWeeks.toNumber(); i++) {
                tokensPerBlock = tokensPerBlock.mul(poolConfig.decayFactor).div(100);
            }
            const newWeight = getStakeWeight(lockedTime, amountEth);
            usersLockingWeight = usersLockingWeight.add(newWeight);
            let futureBlockNumber = lockedBlocks.add(stakeBlockNumber.current);
            let multiplier =
                futureBlockNumber.gt(endBlock)
                    ? endBlock.sub(lastYieldDistribution)
                    : futureBlockNumber.sub(lastYieldDistribution);

            let rewards = tokensPerBlock.mul(multiplier);
            let newYieldRewardsPerWeight = rewards
                .mul(poolConfig.rewardPerWeightMultiplier)
                .div(usersLockingWeight)
                .add(yieldRewardsPerWeight);
            let user = stakeUsers.current;

            let userTotalWeight = user.totalWeight.add(newWeight);
            let userSubYieldRewards = userTotalWeight.mul(yieldRewardsPerWeight).div(poolConfig.rewardPerWeightMultiplier);
            let expected = userTotalWeight
                .mul(newYieldRewardsPerWeight)
                .div(poolConfig.rewardPerWeightMultiplier)
                .sub(userSubYieldRewards);
            // return expected.mul(100).div(amount).toNumber();

            const apyStr = (expected.div('365000000000000000').mul(BigNumber.from(lockedTimeDay)).toNumber() / 1000).toLocaleString('en-US', {maximumFractionDigits: 2});

            setApyData(apyStr);


            // const tokenPerBlock = stakeConfig.current?.tokensPerBlock;
            //
            // const poolFactor = 15330000;
            // const totalYieldOverYear = tokenPerBlock.mul(poolFactor);
            //
            // const depositWeight = getStakeWeight(lockedTime, amountEth);
            //
            // const yieldOnAmount = totalYieldOverYear
            //     .mul(depositWeight).div(depositWeight.add(usersLockingWeight.current));

            // const apy = yieldOnAmount.mul(100).div(amountEth);


        } catch (e) {
            console.log(e)
            // setApyData('0');
        }
    }


    const getStakeWeight = (lockedTime, addedAmount) => {
        return ((BigNumber.from(lockedTime).add(stakeConfig.current.weightMultiplier)).div('31536000').add(stakeConfig.current.weightMultiplier)).mul(addedAmount);
    }

    useEffect(() => {
        if (amount > 0) getAPY(amount, selectedPeriod);
        else setApyData('0');
    }, [amount, selectedPeriod, stakeConfig.current, stakeCurrentValues.current, stakeBlockNumber.current, stakeUsers.current])


    const getAPYPersent = (amount, lockedTimeDay) => {
        try {
            amount = 10000;
            const amountEth = ethers.utils.parseEther(amount.toString());
            const lockedTime = parseInt(lockedTimeDay) * 3600 * 24;

            let poolConfig = stakeConfig.current;
            let endBlock = poolConfig.endBlock;
            let lockedBlocks = BigNumber.from(lockedTime).mul(42000).div(3600 * 24);
            let [tokensPerBlock, totalYieldRewards, yieldRewardsPerWeight, lastRatioUpdate, usersLockingWeight, lastYieldDistribution] = stakeCurrentValues.current;
            // console.log(tokensPerBlock.toString(), totalYieldRewards.toString(), yieldRewardsPerWeight.toString(), lastRatioUpdate.toString(), usersLockingWeight.toString(), lastYieldDistribution.toString());
            // we get the value of tokensPerBlock at the time the claim happens
            let numberOfWeeks = lockedBlocks.div(poolConfig.blocksPerUpdate);
            for (let i = 0; i < numberOfWeeks.toNumber(); i++) {
                tokensPerBlock = tokensPerBlock.mul(poolConfig.decayFactor).div(100);
            }
            const newWeight = getStakeWeight(lockedTime, amountEth);
            usersLockingWeight = usersLockingWeight.add(newWeight);
            let futureBlockNumber = lockedBlocks.add(stakeBlockNumber.current);
            let multiplier =
                futureBlockNumber.gt(endBlock)
                    ? endBlock.sub(lastYieldDistribution)
                    : futureBlockNumber.sub(lastYieldDistribution);

            let rewards = tokensPerBlock.mul(multiplier);
            let newYieldRewardsPerWeight = rewards
                .mul(poolConfig.rewardPerWeightMultiplier)
                .div(usersLockingWeight)
                .add(yieldRewardsPerWeight);
            let user = stakeUsers.current;

            let userTotalWeight = user.totalWeight.add(newWeight);
            let userSubYieldRewards = userTotalWeight.mul(yieldRewardsPerWeight).div(poolConfig.rewardPerWeightMultiplier);
            let expected = userTotalWeight
                .mul(newYieldRewardsPerWeight)
                .div(poolConfig.rewardPerWeightMultiplier)
                .sub(userSubYieldRewards);
            // return expected.mul(100).div(amount).toNumber();

            const apyStr = (expected.div('365000000000000000').mul(BigNumber.from(lockedTimeDay)).toNumber() / 1000 / amount * 100).toFixed(2);

            return apyStr


            // const tokenPerBlock = stakeConfig.current?.tokensPerBlock;
            //
            // const poolFactor = 15330000;
            // const totalYieldOverYear = tokenPerBlock.mul(poolFactor);
            //
            // const depositWeight = getStakeWeight(lockedTime, amountEth);
            //
            // const yieldOnAmount = totalYieldOverYear
            //     .mul(depositWeight).div(depositWeight.add(usersLockingWeight.current));

            // const apy = yieldOnAmount.mul(100).div(amountEth);


        } catch (e) {
            console.log(e)
            // setApyData('0');
        }
    }
    const [apyObj, setApyObj] = useState({})
    useEffect(() => {
        if (stakeConfig.current) {
            const apy90 = getAPYPersent(10000, 90)
            const apy180 = getAPYPersent(10000, 180)
            const apy360 = getAPYPersent(10000, 360)
            setApyObj({
                apy90,
                apy180,
                apy360
            })
        }
    }, [stakeConfig.current])
    return (
        <div className={s.wrap}>

            <div className={s.bt}>
                <div className={s.btl}>
                    <div className={`${s.partTitle} fb`}>
                        <CustomIcon width={17} height={39} className=""
                                    imgName={'Picture/UI_Picture_Title-Yellow_01'}
                        />
                        <div className="fs18 ml5">{AutoStrangeRule(t('$LFG Wallet'))}</div>
                    </div>
                </div>
                <div className={s.btr}>
                    <CustomIcon
                        width={24}
                        height={24}
                        className="ml5 mr5"
                        imgName="Picture/UI_Picture-Currency_LFG_01"
                    />
                    <div className="fs16">{stringToNumber(LFGbalance, 2)}</div>
                </div>
            </div>
            <div className={s.box}>
                <div className={s.inputWrap}>
                    <div className={`${s.inputName}`}>{t('Stake amount')}: &nbsp;</div>
                    <div className={s.inputBox}>
                        <Input className={s.AmountInput} placeholder={t('Enter amount')} value={amount}
                               onChange={amountChangeHandler}
                               onBlur={amountBlurHandler}/>
                        <AntButton className={`btn_white ${s.max}`}
                                   onClick={getMax}>{t('MAX')}</AntButton>
                    </div>
                </div>
                <div className={`${s.checkBoxes}`}>
                    {/* <div className={`${s.inputName}`}>{t('Staking Duration')}</div> */}
                    <div
                        onClick={() => handlePeriodChange('90')}
                        className={`${s.cbItem} ${selectedPeriod === '90' ? s.active : ''}`}>
                        <div className="df jcc">
                            <div className={`fs30 ${selectedPeriod === '90' ? 'color-black' : ''}`}>90</div>
                            <div className={`mt5 ${selectedPeriod === '90' ? 'color-black' : ''}`}>{t('Days')}</div>
                        </div>
                        <div className={s.arp}>{apyObj['apy90'] || '--'}% {t('APR')}</div>
                        {selectedPeriod === '90' && <CustomIcon
                            width={18}
                            height={18}
                            imgName="Picture/UI_Picture_Hook_01"
                            className={s.point}>
                        </CustomIcon>}
                    </div>
                    <div
                        onClick={() => handlePeriodChange('180')}
                        className={`${s.cbItem} ${selectedPeriod === '180' ? s.active : ''}`}>
                        <div className="df jcc">
                            <div className={`fs30 ${selectedPeriod === '180' ? 'color-black' : ''}`}>180</div>
                            <div className={`mt5 ${selectedPeriod === '180' ? 'color-black' : ''}`}>{t('Days')}</div>
                        </div>
                        <div className={s.arp}>{apyObj['apy180'] || '--'}% {t('APR')}</div>
                        {selectedPeriod === '180' && <CustomIcon
                            width={18}
                            height={18}
                            imgName="Picture/UI_Picture_Hook_01"
                            className={s.point}>
                        </CustomIcon>}
                    </div>
                    <div
                        onClick={() => handlePeriodChange('360')}
                        className={`${s.cbItem} ${selectedPeriod === '360' ? s.active : ''}`}>
                        <div className="df jcc">
                            <div className={`fs30 ${selectedPeriod === '360' ? 'color-black' : ''}`}>360</div>
                            <div className={`mt5 ${selectedPeriod === '360' ? 'color-black' : ''}`}>{t('Days')}</div>
                        </div>
                        <div className={s.arp}>{apyObj['apy360'] || '--'}% {t('APR')}</div>
                        {selectedPeriod === '360' && <CustomIcon
                            width={18}
                            height={18}
                            imgName="Picture/UI_Picture_Hook_01"
                            className={s.point}>
                        </CustomIcon>}
                    </div>
                    {/* <div className={s.inputBox}>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input type="radio" id="element1" name="stakingPeriod"
                                checked={selectedPeriod === '90'}
                                onChange={() => handlePeriodChange('90')} />
                            <label htmlFor="element1">{t('90 days')}</label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input type="radio" id="element2" name="stakingPeriod"
                                checked={selectedPeriod === '180'}
                                onChange={() => handlePeriodChange('180')} />
                            <label htmlFor="element2">{t('180 days')}</label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input type="radio" id="element3" name="stakingPeriod"
                                checked={selectedPeriod === '360'}
                                onChange={() => handlePeriodChange('360')} />
                            <label htmlFor="element3">{t('360 days')}</label>
                        </div>
                    </div> */}
                </div>
                <div className={s.ExpectedinputWrap}>
                    <div className={s.Expectedinputname}>{t('Est. $LFG Rewards')}: &nbsp;</div>
                    <div className="fs24 color-yellow">{apyData}</div>
                    <CustomIcon
                        width={18}
                        height={18}
                        imgName="Button/UI_Button_QuestionMark_01"
                        className='ml5'
                        onClick={() => {
                            setShowModelIntro(true)
                        }}
                    >
                    </CustomIcon>
                </div>
                <div className={s.stakeBtn}>
                    {!done && <ButtonFactory disabled={amount <= 0 || subLoading}
                                             size={11}
                                             loading={subLoading}
                                             onClick={transferFn}>
                        {subLoading ? t('PROCESSING') : t('STAKE')}
                    </ButtonFactory>}
                    {done && <ButtonFactory
                        size={11}
                        onClick={() => {
                            setDone(false)
                            setSubLoading(false)
                        }}>
                        {subFailded ? noSuccessText : t('TRANSACTION SUCCESSFUL')}
                    </ButtonFactory>}
                </div>

            </div>
            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={showModelIntro}
                destroyOnClose={true}
                onOk={() => setShowModelIntro(false)}
                onCancel={() => setShowModelIntro(false)}
                zIndex={4000}
            >
                <ModelStakeIntro setShowModelIntro={setShowModelIntro}/>
            </AntModal>
        </div>
    )
}
export default memo(ModelStake);
