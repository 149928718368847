import React, {memo, useState, useMemo, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Tooltip, Modal, Button, Input, message} from "antd";
import AntButton from "@/common/AntButton";
import ModelLiveScheduleTime from "src/model/ModelLiveScheduleTime";
import {
    convertTimestamp,
    post_live_announce_del,
    post_live_announce_query,
    post_live_title_replace
} from "@/utils/common";
import ImgDel from "@/assets/images/del.png";
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import CustomIcon from "@/common/CustomIcon";

const ModelLiveSelect = (
    {
        startLive,
        setOpenLiveSelectPanel,
        userId,
        setNextLiveTime,
        setTitle,
        groupType,
        keyId,
        type = 1, //1:common, 2:moment
    }
) => {
    const {t, i18n} = useTranslation();
    const [OpenLiveScheduleTimePanel, setOpenLiveScheduleTimePanel] = useState(false);
    const [loadingScheduleInfo, setLoadingScheduleInfo] = useState(true);
    const [scheduleHaveTime, setScheduleHaveTime] = useState(0);
    const [scheduleDateTimeStr, setScheduleDateTimeStr] = useState('');

    const updateAnnounceTime = (callback = () => {
    }) => {
        setLoadingScheduleInfo(true);
        post_live_announce_query({
            keyId: parseInt(keyId),
        }).then(
            (data) => {
                const {startTime, title} = data;

                setScheduleHaveTime(startTime);
                setNextLiveTime(startTime);
                setScheduleDateTimeStr(convertTimestamp(startTime * 1000));
                setLoadingScheduleInfo(false);
                setTitle(title);
                setLiveTitle(title);
                callback();
            }
        ).catch(
            (err) => {
                setLoadingScheduleInfo(false);
                callback();
            }
        );
    }

    useEffect(
        () => {
            updateAnnounceTime();
        }, [userId]
    )

    const deleteSchedule = () => {
        setLoadingScheduleInfo(true);
        post_live_announce_del({
            keyId: parseInt(keyId),
            groupType: parseInt(groupType)
        }).then(
            (data) => {
                updateAnnounceTime(() => {
                    setLoadingScheduleInfo(false);
                });
            }
        ).catch(
            (err) => {
                updateAnnounceTime();
                setLoadingScheduleInfo(false);
            }
        );
    }

    const clickSchedule = () => {
        if (scheduleHaveTime !== 0) {
            deleteSchedule();
        } else {
            setOpenLiveScheduleTimePanel(true);
        }
    }

    const [liveTitle, setLiveTitle] = useState('');

    const titleHandler = (e) => {
        setLiveTitle(e.target.value);
    }

    const sendTitle = (callback) => {
        post_live_title_replace({
            title: liveTitle,
            keyId: parseInt(keyId),
            groupType: parseInt(groupType)
        }).then((data) => {
            console.log(data);
            callback();
        }).catch((err) => {
            console.log(err);
            if (err === 702) {
                message.error("title too long");
            }
        });
    }


    return (
        <>
            {
                type === 2 ?
                <div className={s.wrap}>
                    <div className={`${s.title}`}>
                        {t('Create your streaming')}
                    </div>
                    <Input.TextArea
                        placeholder={t('What do you want to talk about?')}
                        value={liveTitle}
                        onChange={titleHandler}
                        className={`${s.textArea}`}
                        rows={1}
                        autoSize={{minRows: 1, maxRows: 1}}
                    />
                    <AntButton 
                        shape="round" 
                        className={`${s.startBtn} btn_public`} 
                        onClick={() => {
                            sendTitle(() => {
                                startLive();
                                setOpenLiveSelectPanel(false);
                            });
                        }}>
                        <div className={`momentFont fs16 fb color-gray lh1`}>
                            {t('Start now')}
                        </div>
                    </AntButton>
                    
                    <div className={`${s.scheduleBtn}`} onClick={() => {
                        clickSchedule();
                    }}>
                        <div className={`${s.scheduleBtnText}`}>
                            {
                                loadingScheduleInfo ?
                                    t('Loading...'):
                                scheduleHaveTime === 0 ?
                                    t('Schedule for later'):
                                    <>
                                        {t('Scheduled')}: {scheduleDateTimeStr} <img src={ImgDel} width={12}></img>
                                    </>
                            }
                        </div>
                    </div>
                    
                    <AntModal
                        width='330px'
                        title=''
                        className="momentModalWrap"
                        centered={true}
                        open={OpenLiveScheduleTimePanel}
                        destroyOnClose={true}
                        onOk={() => setOpenLiveScheduleTimePanel(false)}
                        onCancel={() => setOpenLiveScheduleTimePanel(false)}
                    >
                        <ModelLiveScheduleTime 
                            groupType={groupType} 
                            keyId={keyId}
                            setOpenLiveScheduleTimePanel={setOpenLiveScheduleTimePanel}
                            updateAnnounceTime={updateAnnounceTime}
                            type={2}/>
                    </AntModal>

                    <CustomIcon 
                        className={`momentCloseBtn`} 
                        width={40} 
                        height={40} 
                        imgName={`Picture/UI_Picture_Icon_Close_02`}
                        onClick={() => {
                            setOpenLiveSelectPanel(false);
                        }} />
                </div> :
                <div className={s.wrap}>
                    <Input.TextArea
                        placeholder={t('What do you want to talk about?')}
                        value={liveTitle}
                        onChange={titleHandler}
                        className="whiteInput"
                        style={{width: '270px'}}
                        rows={1}
                        autoSize={{minRows: 1, maxRows: 1}}
                    />
                    <div>
                        <ButtonFactory onClick={() => {
                            sendTitle(() => {
                                startLive();
                                setOpenLiveSelectPanel(false);
                            });
                        }}>
                            {t('Start now')}
                        </ButtonFactory>
                        <div style={{height: '10px'}}></div>
                    </div>
                    <ButtonFactory className={`modelBottomBtn`}
                                color={3}
                                isMirror={true}
                                onClick={() => {
                                    clickSchedule();
                                }}
                                loading={loadingScheduleInfo}
                    >
                        {loadingScheduleInfo && t('Loading...')}
                        {
                            !loadingScheduleInfo && <>
                                {scheduleHaveTime === 0 && t('Schedule for later')}
                                {scheduleHaveTime !== 0 && <>
                                    {t('Scheduled')}: {scheduleDateTimeStr} <img src={ImgDel} width={12}></img>
                                </>}
                            </>
                        }
                    </ButtonFactory>
                    <AntModal
                        width='330px'
                        title={t('Schedule your streaming')}
                        className="confirmModalWrap"
                        centered={true}
                        open={OpenLiveScheduleTimePanel}
                        destroyOnClose={true}
                        onOk={() => setOpenLiveScheduleTimePanel(false)}
                        onCancel={() => setOpenLiveScheduleTimePanel(false)}
                    >
                        <ModelLiveScheduleTime 
                            groupType={groupType} 
                            keyId={keyId}
                            setOpenLiveScheduleTimePanel={setOpenLiveScheduleTimePanel}
                            updateAnnounceTime={updateAnnounceTime}/>
                    </AntModal>
                </div>
            }
        </>
    )
}
export default memo(ModelLiveSelect);
