import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";

import CustomIcon from "src/common/CustomIcon";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import {usePrivy, useWallets} from "@privy-io/react-auth";

const coin = 'Picture/UI_Picture_IYKYK';


const IykykCoinAni = (
    {
        coinType = 1,//1:lfg; 2:matic; 3:lfg&matic; 4:iykyk 5:dealIcon
        className = '',
        coinNum = 3,
        width = 13,
    }
) => {


    const iconImageNameList = useMemo(() =>{
        let res = [];
        for (let i = 0; i < coinNum; i++){
            res.push(coin);
        }
        
        return res;
    }, [coinType]);

    const height = useMemo(() =>{
        return width;
    }, [width]);

    return (
        <div className={`${s.coinRoot} ${className}`}>
            {
                iconImageNameList?.length > 0 && 
                iconImageNameList.map((item, index) => {
                  return (
                    <div key={index}>
                        <CustomIcon width={width} height={height} className={`${s.commonCoin} ${s[`earnCoin${index + 1}`]}`}
                            imgName={item}></CustomIcon>
                    </div>
                  );  
                })
            }
        </div>

    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(IykykCoinAni));
