import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button, Modal, Spin} from "antd";
import CustomIcon from "../../common/CustomIcon";
import imgUrl from "@/assets/images/FingersCrossed.png"
import AvatarInfo from "../../common/AvatarInfo";
import CrackModelAttackIntro from "../CrackModelAttackIntro";
import Countdown from "../../common/Countdown";
import {
    sliceStr,
    stringToNumber,
    getAvatarFromProfile,
    getFriendtrade_loot_employeelist,
    formatCardDisplayName,
    displayFilter,
    getBattlePointRankImg,
    convertWalletBalance,
    joinChat,
} from "@/utils/common";
import {
    mainClubSweepClubsPath,
    balancePath,
} from "@/routes/config";
import {useNavigate} from "react-router-dom";
import {mainHome, mainHomePath} from "@/routes/config";
import ButtonFactory from "@/common/ButtonFactory";
import MainHeader from "@/common/MainHeader";
import AntModal from "@/common/AntModal";
import InfiniteScroll from "react-infinite-scroll-component";
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";
import HireModelTrans from "@/model/HireModelTrans";

const CrackModelClubList = (
    {
        userInfo,
        closeFn,
        tarUserId,
        lootEmployeeUserInfo,
        setLootEmployeeUserInfo,
        lastLootEmployeeUserInfo,
        setLastLootEmployeeUserInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const [holdingData, setHoldingData] = useState([]);
    const [singleBuy, setSingleBuy] = useState(null);
    const [showModelTransSign, setShowModelTransSign] = useState(false);
    const [modelTransSubLoading, setModelTransSubLoading] = useState(false);
    const [modelTransDone, setModelTransDone] = useState(false);

    const {
        selfData
    } = useMemo(() => {
        return userInfo || 0;
    }, [userInfo])

    const {
        buyFirstKey,
    } = useMemo(() => {
        return selfData || 0;
    }, [selfData])

    const {
        employees, 
        costEnergy,
        notEmployees,
    } = useMemo(() => {
        return holdingData || {}
    }, [holdingData])

    const showEmployeesList = useMemo(() => {
        if (employees?.length > 0) {
            let needEmpty = 4;
            let res = [];
            if (employees?.length > 0){
                for (let i=0;i<employees?.length;i++){
                    res.push({...employees[i], isManager: true});
                }
            }
            if (notEmployees?.length){
                for (let i=0;i<notEmployees?.length;i++){
                    res.push({...notEmployees[i], isManager: false});
                }
            }
            for (let i = 0; i < needEmpty; i++) {
                res.push({
                    isEmptyObj: true
                })
            }
            return res;
        } else {
            return employees;
        }
    }, [employees]);

    const getClubList = () => {
        getFriendtrade_loot_employeelist().then(res => {
            setHoldingData(res);
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        getClubList();
    }, []);

    const updateList = () =>{
        getClubList();
    }

    const onSingleBuy = (userId, needBuyKeyNum) => {
        const params = {};
        params[userId] = needBuyKeyNum < 1 ? 1 : needBuyKeyNum;
        setSingleBuy(params);
        setShowModelTransSign(true);
    }

    return (
        <>
            <div className={`${s.wrap} mainwrap`}>
                <MainHeader title={t('Switch')} hasRightMenu={false} headerType={2}
                            onBack={closeFn}></MainHeader>
                <div className={s.content}>
                    <div className={`${s.contentTop} flex_center_between`}>
                        <div className={`${s.partTitle} fb`}>
                            <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                                        imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'}/>
                            {AutoStrangeRule(t('Choose Your Club'))}
                        </div>
                        <AntButton className={`${s.customBtn} btn_yellow flex_center_center fb`}
                                   onClick={() => {
                                        if (buyFirstKey){
                                            navigate(`${mainClubSweepClubsPath}/0`);
                                        }
                                        else{
                                            navigate(balancePath);
                                        }
                                   }}>
                            <div className="flex_center_center fb"
                                 style={{color: 'rgba(0,0,0,1)'}}>
                                {t('Get More')}
                            </div>
                        </AntButton>
                    </div>
                    <div className={`${s.clubPart} mt10`} id="clubPartRoot">
                        <InfiniteScroll
                            dataLength={employees?.length || 0}
                            next={() => {
                            }}
                            hasMore={false}
                            loader={<div
                                style={{
                                    textAlign: "center",
                                    width: "100%",
                                }}
                            ><Spin/></div>}
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: `${employees?.length > 1 ? 'space-between' : 'flex-start'}`,
                                width: '100%'
                            }}
                            endMessage={
                                <div className={s.noMore}>

                                </div>
                            }
                            scrollableTarget={'hireHome'}
                        >
                            {
                                employees?.length > 0 && showEmployeesList?.length > 0 && showEmployeesList.map((item, idx) => {
                                    const {
                                        attackCD,
                                        battlePoints,
                                        bpLevel,
                                        energy,
                                        power,
                                        profile,
                                        tierId,
                                        userId,
                                        tobeMgrPrice,
                                        isManager,
                                        needBuyKeyNum,
                                    } = item;
                                    const {displayName, accType} = profile || {};
                                    const originalAvatarUrl = getAvatarFromProfile(profile, 1);
                                    const avatarUrl = getAvatarFromProfile(profile);
                                    return (
                                        <>
                                            {
                                                item?.isEmptyObj ?
                                                    <div className={s.emptyItem} key={idx}>
                                                    </div> :
                                                    <div className={`${s.clubItemRoot}`} key={idx} onClick={() => {
                                                        if (!isManager){
                                                            joinChat(userId, navigate, accType);
                                                        }
                                                        else if (tarUserId !== userId && attackCD === 0 && energy >= 5) {
                                                            if (lootEmployeeUserInfo && lootEmployeeUserInfo?.userId !== item?.userId){
                                                                setLastLootEmployeeUserInfo(lootEmployeeUserInfo);
                                                            }
                                                            setLootEmployeeUserInfo(item);
                                                            closeFn();
                                                        }
                                                    }}>
                                                        <div className={s.clubAvatar}
                                                             style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                        </div>
                                                        <div className={s.clubAvatar}
                                                             style={{backgroundImage: `url(${originalAvatarUrl || avatarUrl})`}}>
                                                        </div>
                                                        <div className={s.clubCard}
                                                             style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)`}}>
                                                            <div className={s.cardTop}>
                                                                <div className={s.cardName}>
                                                                    {formatCardDisplayName(displayFilter(displayName))}
                                                                </div>
                                                                <div className={s.cardRank}>
                                                                    <CustomIcon className="" width={16} height={16}
                                                                                imgName={`Picture/Rank/RankStar_${tierId}`}/>
                                                                    <CustomIcon className="ml2" width={16} height={16}
                                                                                imgName={`Picture/BPTier/${getBattlePointRankImg(bpLevel || 1)}`}/>
                                                                </div>
                                                            </div>
                                                            <div className={s.cardBottom}>
                                                                {
                                                                    isManager ?
                                                                        <>
                                                                            <div
                                                                                className={`${s.cardBottomPublic} ${s.cardBottomLeft}`}>
                                                                                <CustomIcon width={28} height={28}
                                                                                            imgName={'Picture/UI_Picture_Power_01'}></CustomIcon>
                                                                                {Number(power || 0)?.toLocaleString("en-US")}
                                                                            </div>
                                                                            <div
                                                                                className={`${s.cardBottomPublic} ${s.cardBottomRight}`}>
                                                                                <CustomIcon width={28} height={28}
                                                                                            imgName={'Picture/UI_Picture-Currency_Thunder_01'}></CustomIcon>
                                                                                <div className="flex_center_center">
                                                                                    {energy}
                                                                                    <div className="color-red">
                                                                                        (-5)
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> :
                                                                        <div className={`${s.cardNotManagerPart} flex_center_center_col`} 
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                onSingleBuy(userId, needBuyKeyNum);
                                                                            }}>
                                                                            <div className="fb fs10 color-black">{t('Be Manager')}</div>
                                                                            <div className={`flex_center_center`}>
                                                                                <CustomIcon width={16} height={16} className="mr2"
                                                                                    imgName={'Picture/UI_Picture-Currency_MATIC_01'}></CustomIcon>
                                                                                <span className="fb fs12 color-black">{stringToNumber(tobeMgrPrice, '4')} </span>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                            {
                                                                accType === 1 &&
                                                                <div className={s.wishPart}
                                                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture-CardWish_01.png)`}}>
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            accType === 2 && 
                                                            <CustomIcon className={s.nftIcon} width={32} height={32}
                                                                            imgName={'Picture/UI_Picture-NFT'}/>
                                                        }
                                                        {
                                                            (tarUserId === userId || attackCD > 0) &&
                                                            <div className={`${s.clubMask} flex_center_center_col`}>
                                                                {
                                                                    tarUserId === userId ?
                                                                        <div>
                                                                            {t('Can not loot')}
                                                                        </div> :
                                                                        attackCD > 0 ?
                                                                            <>
                                                                                <div>
                                                                                    {t('Available in')}
                                                                                </div>
                                                                                <div className="color-yellow">
                                                                                    <Countdown
                                                                                        refresh={getClubList}
                                                                                        leftSecond={attackCD}/>
                                                                                </div>
                                                                            </> :
                                                                            <></>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                            }
                                        </>
                                    )
                                })
                            }
                        </InfiniteScroll>
                    </div>
                </div>
                <AntModal
                    width='330px'
                    className="confirmModalWrap"
                    centered={true}
                    open={showModel}
                    destroyOnClose={true}
                    onOk={() => setShowModel(false)}
                    onCancel={() => setShowModel(false)}
                    zIndex={2000}
                >
                    <CrackModelAttackIntro/>
                </AntModal>
                <AntModal
                    width='330px'
                    title={t('BECOME THE MANAGER')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showModelTransSign}
                    destroyOnClose={true}
                    onOk={() => setShowModelTransSign(false)}
                    onCancel={() => {
                        setShowModelTransSign(false);
                        setSingleBuy(null)
                    }}
                    keyboard={!modelTransSubLoading || modelTransDone}
                    maskClosable={!modelTransSubLoading || modelTransDone}
                >
                    <HireModelTrans
                        closeHandle={() => {
                            setShowModelTransSign(false);
                            setSingleBuy(null);
                        }}
                        buyList={singleBuy}
                        refreshHomeList={updateList}
                        done={modelTransDone}
                        setDone={setModelTransDone}
                        subLoading={modelTransSubLoading}
                        setSubLoading={setModelTransSubLoading}
                    />
                </AntModal>
            </div>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(CrackModelClubList));
