import React, {memo, useState, useMemo, useEffect} from "react";
import s from "./index.module.less";
import {Input, Button, Modal, Spin} from "antd";
import {
    openMoonPay,
    getQuotes,
    convertWalletBalance,
    copyFn,
    GetSupportedMaticChainTokenInfo,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import {useWallets} from "@privy-io/react-auth";
import {
    moonpayBasicDollar,
    isOnlineEnv,
    MaticMainnetId,
    MaticTestnetId,
} from "@/utils/env";
import MemeChainToken from "@/utils/json/memeChainToken.json";
import MomentChainToken from "@/utils/json/momentChainToken.json";
import moonpayImg from "@/assets/images/bgImg/UI_Picture_MoonPay.png";

const ModalInsufficientCrpto = (
    {
        closeFn,
        insufficientAmount = 0,
        userInfo,
        coinId = 0,
        type = 1,//1:common, 2:moment
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const [tokenUsd, setTokenUsd] = useState(0);
    const {walletAddr} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const chainTokenInfo = useMemo(() =>{
        let res = null;
        let chainTokenList = type === 2 ? MomentChainToken : MemeChainToken;
        if (chainTokenList?.length > 0){
            if (coinId > 0){
                for (let i = 0; i < chainTokenList?.length; i++){
                    if (chainTokenList[i].ID === coinId){
                        res = chainTokenList[i];
                        break;
                    }
                }
            }
            
            if (res === null){
                let tempCoinId = isOnlineEnv() ? MaticMainnetId : MaticTestnetId;
                for (let i = 0; i < chainTokenList?.length; i++){
                    if (chainTokenList[i].ID === tempCoinId){
                        res = chainTokenList[i];
                        break;
                    }
                }
            }
        }
        return res;
    }, [coinId, type]);

    useEffect(() => {
        if (chainTokenInfo){
            getQuotes([chainTokenInfo.coinName]).then(res => {
                setTokenUsd(res?.quotes?.[chainTokenInfo.coinName]);
            }).catch(e => {
                console.log(e);
            })
        }
    }, [chainTokenInfo])

    const getBasicAmount = () =>{
        if (tokenUsd) {
            let basicToken = Math.max(moonpayBasicDollar / tokenUsd, insufficientAmount);
            return Number(basicToken.toFixed(2));
        }
        return insufficientAmount;
    }

    return (
        <div className={s.wrap}>
            <div className={s.des}>
                <CustomIcon width={36} height={36} className="mr5" imgName={`Picture/${chainTokenInfo?.icon}`}/>
                <div className={s.priceText}>
                    {`${convertWalletBalance(insufficientAmount)} ${chainTokenInfo?.displayCoinName}`}
                </div>
            </div>
            <div className={s.depositRoot}>
                <div className={s.depositText}>
                    {t('Deposit to Your TURNUP Wallet Address Below')}
                </div>
                <div className={s.walletRoot}>
                    <div className={s.walletAddr} style={{wordBreak: 'break-all'}}>{walletAddr}</div>
                    <div className={s.walletCopy} onClick={() => {
                        copyFn(walletAddr)
                    }}>
                        <CustomIcon width={18} height={18} imgName={'Button/UI_Button_CopyNumberWordName_01'}/>
                    </div>
                </div>
                {
                    (chainTokenInfo?.moonpayCurrencyCode) && 
                    <>
                        <div className={s.contectLine}>
                        </div>
                        <div className={s.otherPayTitle}>
                            {t('or try')}
                        </div>
                        <div className={s.moonpayRoot} onClick={() => {
                            openMoonPay(wallets, getBasicAmount(), chainTokenInfo)
                        }}>
                            <div className={s.moonpayLine} style={{backgroundImage: `url(${moonpayImg})`}}></div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
export default memo(ModalInsufficientCrpto);
