import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox, Slider} from 'antd';
import s from "./index.module.less";
import {
    getfriendtrade_rank_selfdata,
    convertWalletBalance,
    searchuser,
    getAvatarFromProfile,
    joinChat,
    displayFilter,
} from "@/utils/common";
import { 
    momentClubInfo,
} from "@/utils/momentClub";
import CustomIcon from "src/common/CustomIcon";
import SearchUser from "src/common/SearchUser";
import AvatarInfo from "src/common/AvatarInfo";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import {
    mainNftNftPanelPath,
    mainClubAllClubsPath,
    balancePath,
    publicWishCreatePath,
    gameMomentClubDetailPath,
} from "@/routes/config";

const SearchMomenttClubList = (
    {
        userInfo,
        setUserInfo,
        earnInfo,
        searchCursor,
        searchResult,
        linkTo,
        isMaket,
        searchKeyValue,
        favoriteFn,
        endCursor = -1,
        getMoreSearchResult = () => {
        },
        setCurrentMomentClubId,
        setSwitchVideoList,
        setSwitchVideoListCursor,
        setSwitchVideoType,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [getClubLock, setGetClubLock] = useState(false);

    const getClubInfoAndRedirect = (clubId) => {
        if (getClubLock){
            return;
        }
        setGetClubLock(true);
        momentClubInfo(clubId).then(res => {
            setGetClubLock(false);
            if (res){
                const {
                    clubInfo,
                    clubUserId,
                    momentAuditCode,
                } = res || {};
                const {
                    videoId,
                    coverUrl,
                    videoUrlMap,
                    videoOriHeight,
                    videoOriWidth,
                    videoFirstFrameUrl,
                } = clubInfo || {};
                setCurrentMomentClubId(clubUserId);
                setSwitchVideoType(-1);
                let _list = [];
                _list.push({
                    clubId: clubUserId,
                    momentAuditCode: momentAuditCode,
                    videoId: videoId,
                    videoUrlMap: videoUrlMap,
                    coverUrl: coverUrl,
                    videoOriHeight: videoOriHeight,
                    videoOriWidth: videoOriWidth,
                    videoFirstFrameUrl: videoFirstFrameUrl,
                });
                setSwitchVideoList(_list);
                setSwitchVideoListCursor("-1");
                navigate(`${gameMomentClubDetailPath}`);
            }
        }).catch(e => {
            console.error(e);
            setGetClubLock(false);
        })
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            overflowY: 'auto'
        }}>
            <div className={s.serarchPanel}>
                {
                    searchResult?.length > 0 ? searchResult.map((item, ind) => {
                        const {clubUserId,isFavorite,profile,creatorProfile,symbolImageUrl} = item
                        const {
                            displayName,
                            profileDeepLink,
                            accountName,
                            platformType
                        } = profile;
                        const avatarUrl = getAvatarFromProfile(item.profile);
                        const creatorAvatarUrl = getAvatarFromProfile(creatorProfile);
                        return (
                            <div className={s.item} key={ind} onClick={() => {
                                // openOtherTransRecord(item?.userId, item?.accountType)
                                getClubInfoAndRedirect(clubUserId);
                            }}>
                                <div className={s.avatar}>
                                    <AvatarInfo className="momentRoundAvatar" noBorder={true} size={44} src={isMaket ? symbolImageUrl : avatarUrl}/>
                                </div>
                                <div className={s.info}>
                                    <div className={`${s.name} momentFont color-EEEEEE`}>
                                        {displayFilter(displayName)}
                                    </div>
                                    <div className="df alc jcc momentFont color-moment-gray fs12">
                                        {t('Created by')}
                                        <AvatarInfo size={16} className="mr5 ml5 color-moment-gray fs12" src={creatorAvatarUrl}/>
                                        {displayFilter(creatorProfile?.displayName)}
                                    </div>
                                </div>
                                {/* <div className={s.favorite}>
                                    <CustomIcon
                                        onClick={() => {
                                            favoriteFn(clubUserId, !isFavorite, 2)
                                        }}
                                        imgName={isFavorite ? 'Picture/UI_Picture_Collect_01' : 'Picture/UI_Picture_Uncollect_01'}
                                        className="mr5" width={19} height={19}></CustomIcon>
                                </div> */}
                            </div>
                        )
                    }) : <div className="flex_center_start_col momentFont">
                        {searchKeyValue?t('No search results'):t('Enter Name To Search')}
                    </div>
                }
                <div className={`${s.loadMore} ${searchCursor !== endCursor ? s.show : s.hide} momentFont`}
                     onClick={getMoreSearchResult}>{t('Load More')}</div>
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
        setSwitchVideoType: (params) => {
            return dispatch({
                type: "app/setSwitchVideoType",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(SearchMomenttClubList));
