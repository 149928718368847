import React, { memo, useCallback, useEffect, useMemo } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "../../../common/CustomIcon";
import { connect } from "react-redux";
import { useState } from "react";
import { Input, Button, Modal, } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../../common/Banner";
import {
    referralcode_bind,
    activity_info,
    joinChartPre, taPush
} from "@/utils/common"
import ReferralEarn from "../ReferralEarn";
import ModelReferralShare from "../../../model/ModelReferralShare";
import inviteImg from '@/assets/images/bgImg/invite.webp';
import { 
    balancePath, 
    mainHomePath,
    invitePath,
} from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";
import MainHeader from "@/common/MainHeader";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";

const addJoinChartPre = (code) => {
    const index = code?.indexOf('turnup.so/');
    if (index > -1) {

        return 'turnup.so/' + joinChartPre + code.substr(10)
    }
    return code
}
const Referral = (
    {
        userInfo,
        defaultInviteCode
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [binded, setBinded] = useState(userInfo?.selfData?.bindReferralCode || false)
    // const [binded, setBinded] = useState(false)
    const [inviteCode, setInviteCode] = useState(defaultInviteCode);
    const [rinviteCode, setRInviteCode] = useState(addJoinChartPre(userInfo?.selfData?.referralCode) || '')
    const [bindResulet, setBindResulet] = useState(false);
    const [bindResuletStr, setBindResuletStr] = useState('');
    const [customTimestamp, setCustomTimestamp] = useState();

    useEffect(() => {
        if (defaultInviteCode) {
            setInviteCode(defaultInviteCode)
        }
    }, [defaultInviteCode])

    useEffect(() => {
        binded && !userInfo?.selfData?.buyFirstKey && navigate(balancePath);
    }, [userInfo?.selfData?.buyFirstKey, binded])

    useEffect(() => {
        setBinded(userInfo?.selfData?.bindReferralCode)
    }, [userInfo])
    const inviteCodeHandler = (event) => {
        let val = event.target.value;

        setInviteCode(val)
    }

    const location = useLocation()
    const colsePage = () => {
        if (location.key !== "default") {
            navigate(-1);
        } else {
            navigate(mainHomePath, { replace: true });
        }
    }

    useEffect(() => {
        activity_info(1).then(res => {
            const { activityConf, inProcessed, inShow } = res;
            const { startTimestamp, endTimestamp } = activityConf;
            setCustomTimestamp(endTimestamp)
        }).catch(e => {

        })
    }, [])

    const bindInvite = () => {
        let val = inviteCode.toLocaleLowerCase();
        if (val.indexOf(joinChartPre) === 0) {
            val = val.substr(1)
        }

        if (val.indexOf('turnup.so/' + joinChartPre) === 0) {
            val = 'turnup.so/' + val.substr(11)
        }

        taPush('bind_invite_code', { code: val })

        val && referralcode_bind(val).then(res => {
            setRInviteCode(addJoinChartPre(res?.referralCode))
            setBindResulet(true);
            setBindResuletStr('bind success!')
            setBinded(true)
            !userInfo?.selfData?.buyFirstKey && navigate(balancePath);
        }).catch(e => {
            setBindResulet(false);
            setBindResuletStr('Invalid Code!')
            // message.success({content:'bind failed!'})
        })
    }
    const timeStr = useMemo(() => {
        let D = 0;
        if (customTimestamp) {
            const nowtime = new Date();
            let timestamp = customTimestamp * 1000 - nowtime.getTime();
            D = Math.ceil(timestamp / (1000 * 60 * 60 * 24)); //计算天数
        }
        if (D > 0) {
            return D > 1 ? D + ' days' : D + ' day'
        }
        return '';
    }, [customTimestamp])
    return (
        <div className={`${s.modalWrap} commonBlueBg`}>
            <MainHeader hasBackBtn={false} leftTitle={t('Referral')} headerType={2} isGameHeader={true} rightMenuIcon={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={s.head} style={{ backgroundImage: `url(/UI/Picture/UI_Picture-Main_TopBlackMask_01.png)` }}>
                <div className={`${s.headLine} flex_center_center`}>
                    <Trans
                        i18nKey="Bring Friends to TURNUP and Reap Rewards!"
                        defaults="<sp>Bring Friends to TURNUP</sp><br></br> and Reap Rewards!"
                        components={{
                            sp: <span></span>,
                            br: <br />
                        }}
                    />
                </div>
            </div>
            <div className={s.modal}>
                {
                    binded ?
                        <div className={s.content}>
                            <ReferralEarn inviteCode={rinviteCode} userInfo={userInfo}>
                                <div className={`df aic`}>
                                    <CustomIcon width={24} height={35} style={{ backgroundSize: '100% 100%' }}
                                        imgName={'Picture/UI_Picture_Title-Blue_ManYellowHeart_01'}
                                        name={'Picture/UI_Picture_Title-Blue_ManYellowHeart_01'} />
                                    <span
                                        className="fs20 color-white fb ml5">&nbsp;x&nbsp;{userInfo?.selfData?.revivalCardHoldCount || 0}</span>
                                </div>
                            </ReferralEarn>
                        </div>
                        :
                        <div className={`${s.content} ${s.jcc}`}>
                            {/* <div className="mt20">
                                <CustomIcon width={88} height={88} imgName={'inviteIcon2'} name={'inviteIcon2'}/>
                            </div>
                            <div className={s['content-area']}>
                                <div className={`${s['content-label']} tac`}>
                                    {t('Getting a Referral Code to Start')}
                                </div>
                                <div className={s.link}>
                                    <div onClick={() => {
                                        window.location.href = "https://twitter.com/turnupdotxyz"
                                    }}>
                                        <CustomIcon width={50} height={50}
                                            imgName={'Button/UI_Button_Ball-White_Twitter_Black'}
                                            name={'Button/UI_Button_Ball-White_Twitter_Black'} />
                                        <div className="mt5">@TURNUPDOTXYZ</div>
                                    </div>
                                    <div onClick={() => {
                                        window.location.href = "https://t.me/justTURNUP"
                                    }}>
                                        <CustomIcon width={50} height={50}
                                            imgName={'Button/UI_Button_Ball-White_TurnUp'}
                                            name={'Button/UI_Button_Ball-White_TurnUp'} />
                                        <div className="mt5">TURNUP</div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.inviteCode}>
                                <Input
                                    placeholder={t('Enter Referral Code')}
                                    value={inviteCode}
                                    className={s.input}
                                    onChange={inviteCodeHandler}
                                />
                                <div className={`${s.tips} ${bindResuletStr ? s.show : ''}`}>
                                    <CustomIcon width={20} className="mr5" height={20}
                                        imgName={bindResulet ? 'trivia_yes' : 'trivia_no'}
                                        name={bindResulet ? 'trivia_yes' : 'trivia_no'} />
                                    {bindResuletStr}
                                </div>
                            </div>
                            <div className={`${s.bottom} ${s.inviteBottom}`}>
                                <div className={s.btn}>
                                    <AntButton disabled={!inviteCode} shape="round" className={`btn_public mt20`}
                                        onClick={bindInvite}>
                                        {t('PROCEED')}
                                    </AntButton>
                                </div>
                                
                            </div> */}

                            <div className={s.referralCodeLabel}>
                                <div className={s['content-area']}>
                                    <div className={`${s['content-label']} tac`}>
                                        {t('Get a Referral Code to Start')}
                                    </div>
                                    <div className={s.link}>
                                        <div className={s.shareCon} onClick={() => {
                                            window.location.href = "https://t.me/justTURNUP"
                                        }}>
                                            <CustomIcon width={41} height={41} imgName={'Button/UI_Button_Ball-White_TurnUp'}
                                                name={'Button/UI_Button_Ball-White_TurnUp'} />
                                            <div className="mt8 fs15">TURNUP</div>
                                        </div>
                                        <div className={s.shareCon} onClick={() => {
                                            window.location.href = "https://twitter.com/turnupdotxyz"
                                        }}>
                                            <CustomIcon width={41} height={41}
                                                imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                name={'Button/UI_Button_Ball-White_Twitter_On'} />
                                            <div className="mt8 fs15">@TURNUPDOTXYZ</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.inviteCode}>
                                    <div className={`${s.tips} ${bindResuletStr ? s.show : ''}`}>
                                        <CustomIcon width={16} className="mr5" height={16}
                                            imgName={bindResulet ? 'trivia_yes' : 'trivia_no'}
                                            name={bindResulet ? 'trivia_yes' : 'trivia_no'} />
                                        {bindResuletStr}
                                    </div>
                                    <Input
                                        placeholder={t('Enter Referral Code')}
                                        value={inviteCode}
                                        className={s.input}
                                        onChange={inviteCodeHandler}
                                    />
                                    <div className={s.inviteBtn}>
                                        <AntButton className={`btn_public`} onClick={()=>{
                                            inviteCode && bindInvite()
                                        }}>
                                            {t('PROCEED')}
                                        </AntButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Referral));
