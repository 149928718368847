import React, {useRef, useEffect, useState} from "react";
import s from "./index.module.less";
import AvatarInfo from "../../common/AvatarInfo";
// import { Avatar } from "antd";
const MediaPlayer = ({
                         videoTrack,
                         audioTrack,
                         avatarurl,
                         isIm
                     }) => {
    const container = useRef(null);
    useEffect(() => {
        if (!container.current) return;
        videoTrack?.play(container.current, {fit: 'cover'});
        return () => {
            videoTrack?.stop();
        };
    }, [container, videoTrack]);

    useEffect(() => {
        if (isIm) return
        if (audioTrack) {
            audioTrack?.play();
        }
        return () => {
            audioTrack?.stop();
        };
    }, [audioTrack]);
    return (
        <div ref={container} className={`${s.videoPlayer}`}>
            {videoTrack ?
                <>

                </>
                :
                <div className={s.avatar}>
                    <AvatarInfo noBorder={true} size={200} src={avatarurl}/>
                </div>
            }
        </div>
    );
}

export default MediaPlayer;
