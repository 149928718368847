import React, {memo} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {
    getTranList, stringToNumber, unit, chgFn, sliceStr, joinChat,
    getAvatarFromProfile
} from "@/utils/common"
import {useMemo, useEffect} from "react";
import AvatarInfo from "../AvatarInfo";
import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import CustomIcon from "../CustomIcon";

const keys = {
    1: 'holders',
    2: 'holding',
    3: 'activity',
    4: 'trades',
}
const TranList = (
    {
        type,
        curentOtherUserInfo,
        self = false,
        setShowOtherTransContent,
        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const timeFn = (time) => {
        let d = parseInt(time / 60 / 60 / 24);
        let h = parseInt(time / 60 / 60 % 24);
        let m = parseInt(time / 60 % 60);
        let str = '';
        if (d > 0) {
            str = d + "d";
            return str
        }
        if (h > 0) {
            str = h + "h";
            return str
        }
        if (m > 0) {
            str = m + "m";
            return str
        }
        return t('few seconds');

        // return `${d}${h}${m}`
    }
    const selfUserId = useMemo(() => {
        return userInfo?.userId
    }, [userInfo])
    const urlUserId = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [nextCursor, setNextCursor] = useState(-1);
    const [managerId, setManagerId] = useState(-1);
    const userId = useMemo(() => {
        return curentOtherUserInfo?.userId
    }, [curentOtherUserInfo]);
    const {youOwnKeyNum} = curentOtherUserInfo || {};
    const getList = () => {
        if (self ? selfUserId : userId) {
            getTranList(self ? selfUserId : userId, type, 0).then(res => {
                setNextCursor(res.data.nextCursor);
                setList(res?.data[keys[type]] || [])
                setManagerId(res.data.managerId)
                setLoading(false)
            }).catch(e => {
                console.log(e);
                setLoading(false)
            })
        } else {
            setTimeout(() => getList())
        }
    }
    useEffect(() => {
        if (userId && selfUserId) getList()
    }, [selfUserId, userId])
    const getMore = () => {
        getTranList(userId, type, nextCursor).then(res => {
            setNextCursor(res.data.nextCursor);
            setList([
                ...list,
                ...res?.data[keys[type]] || []
            ])
        }).catch(e => {
            console.log(e);
        })
    }
    const toTranD = (userId, accountType) => {
        youOwnKeyNum ? setShowOtherTransContent(false) : setShowOtherTransContent(true)
        if (userId == urlUserId?.userId) {
            return false
        }
        joinChat(userId, navigate, accountType, true);
    }
    const reFresh = () => {
        getTranList(self ? selfUserId : userId, type, 0).then(res => {
            setNextCursor(res.data.nextCursor);
            setList(res?.data[keys[type]] || [])
        }).catch(e => {
            console.log(e);
        })
    }
    return (
        <div className={s.wrap}>
            {/* <div className={s.reFresh} onClick={reFresh}>
                <CustomIcon width={22} height={22} isHaveClick={true} imgName={'Button/UI_TT_Button_ShuaXin'}
                            name={'Button/UI_TT_Button_ShuaXin'}/>
            </div> */}
            {
                !loading ?
                    <div className={s.content}>
                        {
                            type !== 1 && type !== 2 ?
                                list.length > 0 ? list.map((item, ind) => {
                                    const {
                                        amount,
                                        time,
                                        txType,
                                        totalPrice,
                                        userId,
                                        keyId,
                                        userAccountType,
                                        keyAccountType
                                    } = item;
                                    const {keyProfile} = item;
                                    const {userProfile} = item;
                                    return (
                                        <div className={s.item} key={ind}>
                                            <div className={s.tranAvatar}>
                                                <div className={s.sponsorAvatar} onClick={() => {
                                                    toTranD(userId, userAccountType)
                                                }}>
                                                    <AvatarInfo size={45} src={getAvatarFromProfile(userProfile)}/>
                                                </div>
                                                <div className={s.onwerAvatar} onClick={() => {
                                                    toTranD(keyId, keyAccountType)
                                                }}>
                                                    <AvatarInfo size={45} src={getAvatarFromProfile(keyProfile)}/>
                                                </div>
                                            </div>
                                            <div className={s.tranInfo}>
                                                <div className={`${s.info} fs14 `}>
                                                    {
                                                        txType === 1 ?
                                                            <Trans
                                                                i18nKey="xx bought aa yy sth"
                                                                defaults="<user>{{name1}}</user> <gray>bought</gray> <fb>{{count}}</fb> <fbClick>{{name2}}</fbClick> <gray>{{sth}}<gray>"
                                                                values={{
                                                                    name1: sliceStr(userProfile.displayName, 'all'),
                                                                    name2: sliceStr(keyProfile.displayName, 'all'),
                                                                    count: amount,
                                                                    sth: keyAccountType === 'wish' ? 'wish' : 'key'
                                                                }}
                                                                components={{
                                                                    user: <span className="color-yellow"
                                                                                onClick={() => {
                                                                                    toTranD(userId, userAccountType)
                                                                                }}></span>,
                                                                    gray: <span className=""></span>,
                                                                    fb: <span className=""></span>,
                                                                    fbClick: <span className="color-yellow"
                                                                                   onClick={() => {
                                                                                       toTranD(keyId, keyAccountType)
                                                                                   }}></span>
                                                                }}
                                                            /> :
                                                            <Trans
                                                                i18nKey="xx sold aa yy sth"
                                                                defaults="<user>{{name1}}</user> <gray>sold</gray> <fb>{{count}}</fb> <fbClick>{{name2}}</fbClick> <gray>{{sth}}<gray>"
                                                                values={{
                                                                    name1: sliceStr(userProfile.displayName, 'all'),
                                                                    name2: sliceStr(keyProfile.displayName, 'all'),
                                                                    count: amount,
                                                                    sth: keyAccountType === 'wish' ? 'wish' : 'key'
                                                                }}
                                                                components={{
                                                                    user: <span className="color-yellow"
                                                                                onClick={() => {
                                                                                    toTranD(userId, userAccountType)
                                                                                }}></span>,
                                                                    gray: <span className=""></span>,
                                                                    fb: <span className=""></span>,
                                                                    fbClick: <span className="color-yellow"
                                                                                   onClick={() => {
                                                                                       toTranD(keyId, keyAccountType)
                                                                                   }}></span>
                                                                }}
                                                            />
                                                    }
                                                </div>
                                                <div className={s.price}>
                                                    <div
                                                        className="color-blue-light">{stringToNumber(totalPrice, 4)} {unit}</div>
                                                    ,&nbsp; <span
                                                    className="">{t('xx ago', {time: timeFn(time)})}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <div className="fs-12">{t('No Data')}</div> :
                                list.length > 0 ? list.map((item, ind) => {
                                        const {displayName, profileDeepLink} = item.profile;
                                        const avatarUrl = getAvatarFromProfile(item.profile);
                                        const {
                                            holdersNum,
                                            holdingNum,
                                            amount,
                                            userId,
                                            buyPrice,
                                            historyPrice,
                                            sellPrice,
                                            accountType
                                        } = item;
                                        const chg = chgFn(sellPrice, historyPrice);
                                        const isUp = chg === '-' ? true : chg >= 0 ? true : false;
                                        return (
                                            <div className={`${s.hItem} ${managerId == userId ? s.hactive : ''}`} key={ind}
                                                 onClick={() => {
                                                     toTranD(userId, accountType)
                                                 }}>
                                                <div className={s.left}>
                                                    <div className={s.sponsorAvatar}>
                                                        {managerId == userId && <div className={s.manager}>
                                                            <CustomIcon className="m02" width={35} height={35}
                                                                        imgName={'Picture/UI_Picture_Crown_01'}
                                                                        name={'Picture/UI_Picture_Crown_01'}/>
                                                        </div>}
                                                        <AvatarInfo size={85} src={avatarUrl} className={'roundAvatar'}/>
                                                    </div>
                                                    <div className={s.num}>
                                                        <div className='fs18 df aic jc-spaceBetween' onClick={() => {
                                                            toTranD(userId, accountType)
                                                        }}>
                                                            <div className="fs18">
                                                                {sliceStr(displayName, 14, true)}
                                                                {profileDeepLink &&
                                                                    <CustomIcon width={14} className='ml5' height={14}
                                                                                imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                                                name={'Button/UI_Button_Ball-White_Twitter_On'}/>}{accountType === "wish" &&
                                                                <span className={`${s.wishTag} `}>
                                                                {t('WISH')}
                                                            </span>}
                                                            </div>

                                                            <div className="fs18">
                                                                <CustomIcon className="m02"
                                                                            width={22}
                                                                            height={22}
                                                                            imgName={'Picture/UI_Picture_Cards_01'}
                                                                            name={'Picture/UI_Picture_Cards_01'}/>x {amount}
                                                            </div>
                                                        </div>
                                                        {/* <div
                                                            className="fs14 color-gary">Owns {amount} {(self ? accountType : curentOtherUserInfo?.accountType) === 'wish' ? 'wish' : 'key'}{(self ? accountType : curentOtherUserInfo?.accountType) !== 'wish' && amount > 1 && 's'}{(self ? accountType : curentOtherUserInfo?.accountType) === 'wish' && amount > 1 && 'es'}</div> */}
                                                        <div className={`df aic w100p ${s.lItem}`}>
                                                            <div className="df aic jc-spaceBetween" style={{flex: '1'}}>
                                                                <div className="df aic jc-spaceBetween w100p">
                                                                    <div style={{width: '65px'}}>{t('Key Price')}</div>
                                                                    <span className={`color-white`} style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <CustomIcon width={18} height={18}
                                                                                    imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                                                                    name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                                                                        <span className="tlc fs16 ml2"
                                                                              style={{
                                                                                  width: '50px',
                                                                                  textAlign: 'left'
                                                                              }}>
                                                                            {stringToNumber(buyPrice, 4)}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="color-white">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`df aic ${s.lItem} jc-spaceBetween`}>
                                                            <div style={{
                                                                width: '65px'
                                                            }}>{t('Joined')}</div>
                                                            <span className={`fs12 color-white`} style={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}>
                                                                <CustomIcon width={18} height={18}
                                                                            imgName={'Button/UI_Button-D_MyClubs_On'}
                                                                            name={'Button/UI_Button-D_MyClubs_On'}/>
                                                                <span className="tlc fs16 ml2" style={{
                                                                    width: '50px',
                                                                    textAlign: 'left'
                                                                }}>
                                                                    {type === 1 ? holdersNum : holdingNum}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                ) : <div className="fs-12">{t('No Data')}</div>
                        }
                        <div className={`${s.loadMore} ${nextCursor !== -1 ? s.show : ''}`} onClick={getMore}>
                            {t('Load More')}
                        </div>
                    </div> :
                    <div className="">{t('Loading...')}</div>
            }
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowOtherTransContent: (params) => {
            return dispatch({
                type: "app/setShowOtherTransContent",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(TranList));
