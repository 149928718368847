import React, {useMemo, useEffect, memo} from "react";
import s from "./index.module.less";
import {Button} from "antd";
import {connect} from "react-redux";
import CustomIcon from "../../common/CustomIcon";
import {stringToNumber} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";

const ModelNeedUseCliamCoin = ({closeHandle, cliamFn, claimCoinBalance, needCoin}) => {
    const {t, i18n} = useTranslation();
    return (
        <div className={s.content}>
            <div className="mb20 tlc">
                <Trans
                    i18nKey="You need $LFG xx more to cover the required fee Would you like to extract it now from your $LFG pool?"
                    defaults="You need $LFG {{needCoin}} more to cover the required fee<br>Would you like to extract it now from your $LFG pool?"
                    values={{needCoin: needCoin}}
                    components={{br: <br/>}}
                />
            </div>

            <div className={s.item}>
                <div className="">{t('Your $LFG Pool')}</div>
                <div className={s.bg}>
                    <CustomIcon width={20} className='mr5' height={20} imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                name={'Picture/UI_Picture-Currency_LFG_01'}/>
                    {stringToNumber(claimCoinBalance, 4)}
                </div>
            </div>
            <div className={s.item}>
                <div className="">{t('Extra $LFG Needed')} </div>
                <div className={s.bg}>
                    <CustomIcon width={20} className='mr5' height={20} imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                name={'Picture/UI_Picture-Currency_LFG_01'}/>
                    {stringToNumber(needCoin, 4)}
                </div>
            </div>
            <div className="df alc mt20">
                <AntButton className="btn_public mr10 df alc jcc" shape="round"
                           onClick={cliamFn}>{t('EXTRACT')}</AntButton>
                <AntButton className="btn_white ml10 df alc jcc" shape="round"
                           onClick={closeHandle}>{t('LATER')}</AntButton>
            </div>
        </div>
    );
};
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelNeedUseCliamCoin));
