import React, {memo, useCallback, useEffect, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import {useState, useMemo} from "react";
import {Spin, Button, Dropdown, Modal, message} from "antd";
import CustomIcon from "../CustomIcon";
import RedPacketModelCreate from "@/model/RedPacket/RedPacketModelCreate";
import {post_red_packet_status} from "@/utils/red_Packet";
import RedPacketModelOpen from "@/model/RedPacket/RedPacketModelOpen";
import RedPacketModelView from "@/model/RedPacket/RedPacketModelView";
import AntModal from "@/common/AntModal";

const RedPacket = (
    {
        keyId,
        isSelfChat,
        hasBindTweet,
        pageLoading,
        needUpdateRedPacketInfo,
        userInfo,
        curentOtherUserInfo,
        openRedPacketCreatePanel,
        setOpenRedPacketCreatePanel
    }
) => {
    const {t, i18n} = useTranslation();

    const {
        youOwnKeyNum,
        platformData,
        canJoinAgoraChan,
        agora_setchantype,
        managerId,
        isGlobalChatGroup
    } = useMemo(() => {
        return curentOtherUserInfo || {}
    }, [curentOtherUserInfo])

    // const [openRedPacketCreatePanel, setOpenRedPacketCreatePanel] = useState(false)
    const [openRedPacketOpenPanel, setOpenRedPacketOpenPanel] = useState(false)

    const [showRedPacketButton, setShowRedPacketButton] = useState(false)
    const [redPacketList, setRedPacketList] = useState([])
    const [redPacketHostAddress, setRedPacketHostAddress] = useState("");
    const reflushStatus = () => {
        post_red_packet_status(keyId).then((res) => {
            setRedPacketHostAddress(res?.data?.tarWallet)
            setRedPacketList(res?.data?.redPacketList)
            setShowRedPacketButton(res?.data?.redPacketList && res?.data?.redPacketList.length > 0)
        })
    }

    useEffect(() => {
        reflushStatus()
    }, [needUpdateRedPacketInfo, curentOtherUserInfo])


    const [openRedPacketStatusPanel, setOpenRedPacketStatusPanel] = useState(false)
    const clickRedPacketButton = () => {
        setOpenRedPacketOpenPanel(true)
    }

    const [statusCurrentRedPacket, setStatusCurrentRedPacket] = useState({})
    const openRedPacketStatus = (currentRedPacket) => {
        setStatusCurrentRedPacket(currentRedPacket);
        setOpenRedPacketStatusPanel(true);
    }
    const [openRedPacketMaskClosable, setOpenRedPacketMaskClosable] = useState(true);

    return (
        <>
            {/* {!pageLoading && youOwnKeyNum > 0 &&
                <div className={`${s.startVideo} ${!hasBindTweet ? s.noBind : ''}`}
                     style={{top: hasBindTweet ? '250px' : '340px'}} onClick={() => {
                    setOpenRedPacketCreatePanel(true)
                }}>
                    <CustomIcon width={45} height={45} imgName={'Button/UI_Button_RedPack_On'}
                                name={'Button/UI_Button_RedPack_On'}/>
                </div>
            } */}
            <AntModal
                width='330px'
                className="confirmModalWrap redpacket noCloseBtn"
                centered={true}
                open={openRedPacketCreatePanel}
                destroyOnClose={true}
                onOk={() => setOpenRedPacketCreatePanel(false)}
                onCancel={() => setOpenRedPacketCreatePanel(false)}
            >
                <RedPacketModelCreate setOpenRedPacketCreatePanel={setOpenRedPacketCreatePanel}
                                      redPacketHostAddress={redPacketHostAddress}
                />
            </AntModal>
            {showRedPacketButton &&
                <div className={`${s.startRedPacket}`}
                     style={{bottom: '130px'}}
                     onClick={clickRedPacketButton}>
                    <CustomIcon width={45} height={45} imgName={'Button/UI_Button_RedGet'}/>
                </div>
            }
            <AntModal
                width='330px'
                className="confirmModalWrap fullModal redPacketFullModel noCloseBtn"
                centered={true}
                open={openRedPacketOpenPanel}
                destroyOnClose={true}
                onOk={() => setOpenRedPacketOpenPanel(false)}
                onCancel={() => setOpenRedPacketOpenPanel(false)}
                maskClosable={openRedPacketMaskClosable}
            >
                <RedPacketModelOpen setOpenRedPacketOpenPanel={setOpenRedPacketOpenPanel}
                                    keyId={keyId}
                                    openRedPacketStatus={openRedPacketStatus}
                                    curentOtherUserInfo={curentOtherUserInfo} redPacketList={redPacketList}
                                    isSelfChat={isSelfChat}
                                    reflushStatus={reflushStatus}
                                    setOpenRedPacketMaskClosable={setOpenRedPacketMaskClosable}
                />
            </AntModal>
            <AntModal
                width='330px'
                className="confirmModalWrap redpacket noCloseBtn"
                centered={true}
                open={openRedPacketStatusPanel}
                destroyOnClose={true}
                onOk={() => setOpenRedPacketStatusPanel(false)}
                onCancel={() => setOpenRedPacketStatusPanel(false)}
            >
                <RedPacketModelView setOpenRedPacketStatusPanel={setOpenRedPacketStatusPanel}
                                    keyId={keyId}
                                    curentOtherUserInfo={curentOtherUserInfo}
                                    statusCurrentRedPacket={statusCurrentRedPacket}
                                    isSelfChat={isSelfChat}
                />
            </AntModal>
        </>
    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        curentOtherUserInfo: app.curentOtherUserInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}


export default memo(connect(mapStateToProps, mapDispatchToProps)(RedPacket));
