import React, { memo } from 'react';
import { connect } from 'react-redux';

const Chats = ({ coinChain, nativeChain }) => (
    <div style={{ position: 'relative', width: '100%', paddingBottom: '80%' }}>
        <iframe
            src={`https://dexscreener.com/${nativeChain?.chainName}/${coinChain.address}?embed=1&theme=dark&trades=0`}
            style={{
                position: 'absolute',
                width: '167%',
                height: '167%',
                top: '0%',
                left: '0%',
                border: 0,
                borderRadius: '8px',
                transform: 'scale(0.6)',
                transformOrigin: '0 0'
            }}
        />
    </div>
);

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Chats));
