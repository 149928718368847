import {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Modal} from "antd";
// import bg from "@/assets/images/bgImg/atackingBg.png";
import {vCoinString} from "@/utils/common";
import HireModelClaimed from "@/components/HireModelClaimed";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";

const HireClaimedResult = ({closeFn, info}) => {
    const {t, i18n} = useTranslation();
    const [atackbg, setAtackbg] = useState('');
    const [showModelClaimed, setShowModelClaimed] = useState(false);

    const {
        claimCoin,
        selfClaimCoin,
        lootCoin,  //获得的coin
        resulteInfo, //工作或者劫掠结果对应的JOSN结构体
        employeeTwitterProfile, //派遣者用户信息
        tarTwitterProfile, //只有type为2时 才有 攻击者用户信息
        profile, //当前用户信息
        tweetTopic,
        type //1: 工作, 2:劫掠
    } = useMemo(() => {
        return info || {}
    }, [info])
    useEffect(() => {
        if (resulteInfo?.resultImage) {
            const img = require(`@/assets/images/resultImg/${resulteInfo?.resultImage}.jpg`)
            setAtackbg(img)
        }

    }, [resulteInfo])


    return (
        <div className={s.wrap} style={{backgroundImage: `url(${atackbg})`}}>
            <div>
                <div className={s.title}>{t('CLAIMED SUCCESSFULLY')}</div>
                <div className={s.bg}>
                    <CustomIcon
                        width={20}
                        className="mr5"
                        height={20}
                        imgName={"Picture/UI_Picture-Currency_LFG_01"}
                        name={"Picture/UI_Picture-Currency_LFG_01"}
                    />
                    {selfClaimCoin?.toLocaleString("en-US")}
                </div>
            </div>
            <div className={s.content}>

                <div className={s.info}>
                    <div className="color-white fs12 tll mb10">
                        {t('Share with your club on X for bonus')}
                        <CustomIcon
                            width={12}
                            className="ml5 mr5"
                            height={12}
                            imgName={"Picture/UI_Picture-Currency_LFG_01"}
                            name={"Picture/UI_Picture-Currency_LFG_01"}
                        />
                        {t('$LFG!')}
                    </div>
                    <div className={s.result}>
                        {t(resulteInfo?.resultText)}
                    </div>
                </div>
                <div className={`${s.btn} mb20`}>
                    <ButtonFactory onClick={() => {
                        setShowModelClaimed(true);
                    }}>
                        <div className="lh1 color-black">
                            {t('POST TO X')} <br/>
                            <span className="fs12 color-black">
                <Trans
                    i18nKey="GET xx $LFG BONUS"
                    defaults="GET <icon></icon>{{amount}} $LFG BONUS"
                    values={{amount: (selfClaimCoin * 0.1)?.toLocaleString("en-US")}}
                    components={{
                        icon: <CustomIcon
                            className="ml5 mr5"
                            width={12}
                            height={12}
                            imgName={"Picture/UI_Picture-Currency_LFG_01"}
                            name={"Picture/UI_Picture-Currency_LFG_01"}
                        />
                    }}
                />
              </span>
                        </div>
                    </ButtonFactory>
                </div>
                <div className={s.btn}>
                    <ButtonFactory
                        color={3}
                        onClick={closeFn}
                    >
                        {t('I DON’T WANT A BONUS')}
                    </ButtonFactory>
                </div>
            </div>
            <AntModal
                width='90%'
                title=''
                className="confirmModalWrap tweetShareModal noImageTitle"
                centered={true}
                open={showModelClaimed}
                destroyOnClose={true}
                onOk={() => setShowModelClaimed(false)}
                onCancel={() => setShowModelClaimed(false)}
                zIndex={3000}
            >
                <HireModelClaimed
                    closeHandle={() => {
                        setShowModelClaimed(false);
                        closeFn();
                    }}
                    totalInfo={info}
                />
            </AntModal>
        </div>
    );
};
export default memo(HireClaimedResult);
