import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox} from 'antd';
import s from "./index.module.less";
import {
    getfriendtrade_rank_selfdata,
    convertWalletBalance,
    vCoinString,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import MainHeader from "src/common/MainHeader";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {
    mainNftBidHistoryPath,
    mainNftBidMyBidsPath,
    mainNftBidMynftPath,
} from "@/routes/config";
import {lfgStakeBalanceOf} from "@/utils/lfgStake";
import BidAuction from "src/views/main/Bid/BidAuction";
import {
    getNftAuctionRefund,
    getnft_auction_batchcfg,
    getnft_auction_batchinfo,
} from "@/utils/bidNft";
import AuctionBanner from "src/views/main/Bid/AuctionBanner";
import AntButton from "@/common/AntButton";

const customMaticIcon = 'Picture/UI_Picture-Currency_MATIC_01';
const customLfgIcon = 'Picture/UI_Picture-Currency_LFG_01';

const NFTPanel = (
    {
        userInfo,
        setUserInfo,
        earnInfo,
        currentAuctionBatchCfg,
        setCurrentAuctionBatchCfg,
        currentAuctionBatchInfo,
        setCurrentAuctionBatchInfo,
        lastGetAuctionInfoTimestamp,
        setLastGetAuctionInfoTimestamp,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {wallets} = useWallets();
    const [showCoinAni, setShowCoinAni] = useState(false);
    const [showCoinAni2, setShowCoinAni2] = useState(false);
    const [earnLfgAmount, setEarnLfgAmount] = useState(1.5566);
    const [earnMaticAmount, setEarnMaticAmount] = useState(77889911);
    const [showEarnCoinTooltip, setShowEarnCoinTooltip] = useState(false);

    const getMyBidCount = () => {
        return (currentAuctionBatchInfo?.inBidNftNum || 0) + (currentAuctionBatchInfo?.toClaimNftNum || 0);
    }

    const showEarnCoinFn = (lfgAmount, maticAmount) => {
        console.log("showEarnCoinFn", lfgAmount, maticAmount);
        if (lfgAmount > 0 || maticAmount > 0) {
            setShowEarnCoinTooltip(true);
            setTimeout(() => {
                setEarnLfgAmount(0);
                setEarnMaticAmount(0);
                setShowEarnCoinTooltip(false);
            }, 5000);
        }
    }

    const updateBatchCfg = () => {
        getnft_auction_batchcfg().then(res => {
            if (res) {
                let endTime = new Date().getTime() / 1000 + res?.batchStartLeftSec;
                setCurrentAuctionBatchCfg({
                    ...res,
                    endTime
                });
            }
        }).catch(e => {
            console.log(e);
        })
    }

    const updateBatchInfo = () => {
        getnft_auction_batchinfo().then(res => {
            if (res && res.auctionBatchId != currentAuctionBatchCfg?.auctionBatchId) {
                updateBatchCfg();
            }
            setCurrentAuctionBatchInfo(res);
        }).catch(e => {
            console.log(e);
        })
    }

    useEffect(() => {
        let nowTimestamp = new Date().getTime();
        if (nowTimestamp - lastGetAuctionInfoTimestamp > 60000) {
            setLastGetAuctionInfoTimestamp(nowTimestamp);
            updateBatchInfo();
        }
    }, [])

    useEffect(() => {
        if (!currentAuctionBatchCfg) {
            updateBatchCfg();
        }
    }, []);

    useEffect(() => {
        getNftAuctionRefund().then(res => {
            const localRefundData = JSON.parse(localStorage.getItem('nft_auction_refund') || '{"refundLFG":"0","refundMatic": "0"}')
            console.log(localRefundData, res);
            const earnLfg = res?.refundLFG - localRefundData?.refundLFG;
            const earnMatic = res?.refundMatic - localRefundData?.refundMatic;
            localStorage.setItem('nft_auction_refund', JSON.stringify(res))
            if (earnLfg <= 0 && earnMatic <= 0) {
                return
            }
            console.log(`earnLfg---->${earnLfg}earnMatic---->${earnMatic}`);
            setEarnLfgAmount(earnLfg || 0);
            setEarnMaticAmount(earnMatic || 0);
            setShowCoinAni(true);
            setTimeout(() => {
                setShowCoinAni(false);
                setShowCoinAni2(true);
                setTimeout(() => {
                    setShowCoinAni2(false);
                    showEarnCoinFn(earnLfg, earnMatic);
                }, 1000);
            }, 600);
        }).catch(e => {
            console.error(e);
        })
    }, [])

    return (
        <div className={`${s.wrap} mainWrap publicBg`}>
            <MainHeader title={'NFTS'} hasRightMenu={false}></MainHeader>
            <div className={s.banner}>
                <AuctionBanner/>
            </div>
            <div className={`${s.content}`}>
                <div className={s.topButtonRoot}>
                    <AntButton className={`btn_blue ${s.topButton}`} onClick={() => {
                        navigate(mainNftBidMynftPath);
                    }}>
                        <div className="fs18">
                            {t('My NFTs')}
                        </div>
                    </AntButton>
                    <AntButton className={`btn_blue ${s.topButton}`} onClick={() => {
                        navigate(mainNftBidMyBidsPath);
                    }}>
                        {
                            getMyBidCount() > 0 &&
                            <div className={s.redPointRoot}>{getMyBidCount() > 99 ? '99+' : getMyBidCount()}</div>
                        }
                        <div className="fs18">
                            {t('MY BIDS')}
                        </div>
                    </AntButton>
                    <AntButton className={`btn_blue ${s.topButton} ${showCoinAni2 ? s.enlargeBtn : ''}`}
                               onClick={() => {
                                   navigate(mainNftBidHistoryPath);
                               }}>
                        <div className={`fs18`}>
                            {t('History')}
                        </div>
                        {
                            showCoinAni && <>
                                <CustomIcon width={24} height={24} className={s.earnCoin1}
                                            imgName={earnLfgAmount > 0 ? customLfgIcon : customMaticIcon}/>
                                <CustomIcon width={24} height={24} className={s.earnCoin2}
                                            imgName={earnMaticAmount > 0 ? customMaticIcon : customLfgIcon}/>
                                <CustomIcon width={24} height={24} className={s.earnCoin3}
                                            imgName={earnLfgAmount > 0 ? customLfgIcon : customMaticIcon}/>
                                <CustomIcon width={24} height={24} className={s.earnCoin4}
                                            imgName={earnMaticAmount > 0 ? customMaticIcon : customLfgIcon}/>
                                <CustomIcon width={24} height={24} className={s.earnCoin5}
                                            imgName={earnLfgAmount > 0 ? customLfgIcon : customMaticIcon}/>
                                <CustomIcon width={24} height={24} className={s.earnCoin6}
                                            imgName={earnMaticAmount > 0 ? customMaticIcon : customLfgIcon}/>
                                <CustomIcon width={24} height={24} className={s.earnCoin7}
                                            imgName={earnLfgAmount > 0 ? customLfgIcon : customMaticIcon}/>
                                <CustomIcon width={24} height={24} className={s.earnCoin8}
                                            imgName={earnMaticAmount > 0 ? customMaticIcon : customLfgIcon}/>
                            </>
                        }
                        {
                            showEarnCoinTooltip ?
                                earnLfgAmount > 0 && earnMaticAmount > 0 ?
                                    <div className={`${s.earnCoinBigToolTipRoot} ${s.toolTipAniRoot}`}
                                         style={{backgroundImage: `url(/img/Picture/UI_Picture_NFT-Bubble_02.png)`}}>
                                        <div className={s.earnCoinBigToolTipLine}>
                                            <CustomIcon width={24} height={24} className="" imgName={customLfgIcon}/>
                                            <div className={s.earnCoinToolTipText}>
                                                {`+${convertWalletBalance(earnLfgAmount)}`}
                                            </div>
                                        </div>
                                        <div className={s.earnCoinBigToolTipLine}>
                                            <CustomIcon width={24} height={24} className="" imgName={customMaticIcon}/>
                                            <div className={s.earnCoinToolTipText}>
                                                {`+${convertWalletBalance(earnMaticAmount)}`}
                                            </div>
                                        </div>
                                    </div> :
                                    <div className={`${s.earnCoinSmallToolTipRoot} ${s.toolTipAniRoot}`}
                                         style={{backgroundImage: `url(/img/Picture/UI_Picture_NFT-Bubble_01.png)`}}>
                                        <div className={s.earnCoinSmallToolTipLine}>
                                            <CustomIcon width={24} height={24} className=""
                                                        imgName={earnLfgAmount > 0 ? customLfgIcon : customMaticIcon}/>
                                            <div className={s.earnCoinToolTipText}>
                                                {`+${convertWalletBalance(earnLfgAmount > 0 ? earnLfgAmount : earnMaticAmount)}`}
                                            </div>
                                        </div>
                                    </div> :
                                <></>
                        }
                    </AntButton>
                </div>
                <div className={s.bidAuction}>
                    <BidAuction></BidAuction>
                </div>
            </div>
        </div>

    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo,
        currentAuctionBatchCfg: app.currentAuctionBatchCfg,
        currentAuctionBatchInfo: app.currentAuctionBatchInfo,
        lastGetAuctionInfoTimestamp: app.lastGetAuctionInfoTimestamp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setLastGetAuctionInfoTimestamp: (params) => {
            return dispatch({
                type: "app/setLastGetAuctionInfoTimestamp",
                payload: params,
            });
        },
        setCurrentAuctionBatchCfg: (params) => {
            return dispatch({
                type: "app/setCurrentAuctionBatchCfg",
                payload: params,
            });
        },
        setCurrentAuctionBatchInfo: (params) => {
            return dispatch({
                type: "app/setCurrentAuctionBatchInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(NFTPanel));
