import { useRef, useState, useEffect,memo } from 'react';
import QRCode from 'qrcode';
import {DrawMultilineText} from '@/canvas/canvas-multiline-text-mod.js';

//elements example
/*const imgElements = [
    { uri: resultImageUri, x: 0.705, y: 0, cw: 0.295, ch: 1 },
    { uri: require('@/assets/images/twittercard/card_bg.webp'), x: 0, y: 0, cw: 1, ch: 1},
    { text: parsedInviteUrl, color: "#fffb00", font: "sans-serif", minFontSize: 20, maxFontSize: 20, x:0.05, y: 0.3, cw:0.6, ch: 0.5 },
    { uri: '/img/coin.png', x:0.05, y: 0.4, sw: 0.7, sh: 0.7 },
    { text: `${selfCoin}  $LFG`, color: "#ff8800", font: "sans-serif", minFontSize: 50, maxFontSize: 50, x:0.18, y: 0.42, cw:0.6, ch: 0.5 },
    { text: `earned by the activity`, color: "#FFFFFFE0", font: "sans-serif", minFontSize: 20, maxFontSize: 20, x:0.05, y: 0.6, cw:0.6, ch: 0.5 },
    { text: contentText, color: "#FFFFFFE0", font: "sans-serif", minFontSize: 22, maxFontSize: 22, lineHeight: 1.2, x:0.05, y: 0.7, cw:0.6, ch: 0.5 },
    { qr : true, uri: qrcodeUrl, x: 0.51, y: 0.05, cw: 0.3 * height / width, ch: 0.3},
];*/

const Canvas = ({width, height, setImageData, setLinkedCanvas, imgElements}) => {

    //const imageARef = useRef(null);
    const canvasRef = useRef(null);
    const [canvasDraw, setCanvasDraw] = useState(false);
    const [canvasBlobUri, setCanvasBlobUri] = useState();

    useEffect(()=>{
        if (canvasBlobUri) {
            var link = document.createElement("a");
            link.download = "turnupShare.webp";
            link.href = canvasBlobUri;
            link.click();
            URL.revokeObjectURL(canvasBlobUri);
            setCanvasBlobUri('');
        }
    }, [canvasBlobUri]);

    useEffect(()=>{
        drawFunc();
    },[]);

    const genQRImage = (qrContent) => {
        return new Promise((resolve, reject) => {
            //console.log(qrContent)
            QRCode.toDataURL(qrContent, {
                errorCorrectionLevel : 'M',
                margin: 1,
                color:{light:'#ffffff00', dark:'#FFFFFFD0'},
            }, (err, url)=>{
                //console.log(url)
                if (err) {
                    reject(new Error(`QRCode.toDataURL ${qrContent} fail`));
                } else {
                    const img = new Image();
                    img.onload = () => resolve(img);
                    img.onerror = () => reject(new Error(`load ${url} fail`));
                    img.src = url;
                }
            });
        });
    };

    // It's better to use async image loading.
    const loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };

    const drawCanvas = async(options, ctx, width, height) => {
        // And this is the key to this solution
        // Always remember to make a copy of original object, then it just works :)
        const opt = Object.assign({}, options);

        if (opt.uri)  {
            const fn = !opt.qr ? loadImage : genQRImage;
            return fn(opt.uri).then(img => {
                ctx.drawImage(
                    img,
                    width * opt.x,
                    height * opt.y,
                    opt.cw ? opt.cw * width : (opt.sw || 1.0) * img.width,
                    opt.ch ? opt.ch * height : (opt.sh || 1.0) * img.height);
    
            }).catch(e=>console.log(e));
        } else if (opt.text) {
            //ctx.font = opt.font;
            //ctx.textAlign = opt.align || 'start';
            ctx.fillStyle = opt.color || 'white';
            //ctx.fillText(opt.text, width * opt.x, height * opt.y);

            const fontSizeUsed = DrawMultilineText(
                ctx,
                opt.text,
                {
                    rect: {
                        x: width * opt.x,
                        y: height * opt.y,
                        width: opt.cw ? opt.cw * width : width,
                        height: opt.ch ? opt.ch * height : height,
                    },
                    font: opt.font || 'Arial',
                    verbose: false,
                    bold: !!opt.bold,
                    lineHeight: opt.lineHeight || 1.4,
                    minFontSize: opt.minFontSize || 30,
                    maxFontSize: opt.maxFontSize || 40,
                }
            )
        }
    };

    const downloadImage = () =>{
        const canvas = canvasRef.current;
        canvas.toBlob((blob)=>{
            const url = URL.createObjectURL(blob);
            setCanvasBlobUri(url);
        },"image/jpeg", 0.98);
    }

    const drawFunc = async ()=>{
        if (!imgElements){
            return;
        }
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.imageSmoothingEnabled = true;
      
        const { width, height } = canvas;
        context.clearRect(0, 0, width, height);
        
        for (let i = 0; i < imgElements.length; i++ ){
            await drawCanvas(imgElements[i], context, width, height);
        }
        
        const imageQuality = 0.85
        const imageDataURL = canvas.toDataURL("image/jpeg", imageQuality);
        //console.log(imageDataURL)
        var sharedImageData = imageDataURL.replace('data:image/jpeg;base64,', '');
        setCanvasDraw(true);
        setImageData(sharedImageData);
        setLinkedCanvas(canvas);
    }

    return (
        <p>
            <canvas ref={canvasRef} width={width} height={height} style={{width:"100%", borderRadius:"10px"}} /><br/>
        </p>
    );
}

export default memo(Canvas);