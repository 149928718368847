import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import {
    stringToNumber,
    convertWalletBalance,
    getMomentChainTokenInfoById,
    getTokenBalanceOf,
    GetSupportedChainList,
    getQuotes,
    numberClamp,
    chgFn,
    chainOpenseaUrl,
} from "@/utils/common";
import {
    gameMemeClubDetailPath,
    gameMomentClubDetailPath,
} from "@/routes/config";
import AntButton from "@/common/AntButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import { getChainInfo } from "@/utils/memeClub";
import MemeClubPack from "@/common/MemeClubPack";
import MomentClubPack from "@/common/MomentClubPack";

const List = (
    {
        userInfo,
        walletAssetsType,
        list,
        marketInfo,
        nextCursor = -1,
        getMoreFn = () =>{},
        highlightPackItemId = 0,
        linkToClub = () =>{},
        shortType = false,

        setCurrentMomentClubId,
        setSwitchVideoType,
        setMemeclubSwitchVideoParams,
        setSwitchVideoListCursor,
        setSwitchVideoList,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {userId} = userInfo;

    const convertPriceValue = (value) =>{
        if (value !== undefined && value !== null && typeof(value) === 'number'){
            if (value < 0.00001){
                return '<0.00001';
            }
            else if (value < 10){
                return `${Number(value.toFixed(5))}`;
            }
            else {
                return `${Number(value.toFixed(2)).toLocaleString("en-US")}`;
            }
        }
        return '--';
    }

    const convertDisplayName = (name) =>{
        if (name){
            if (name?.length > 8){
                return `${name.substring(0, 8)}...`;
            }
            else{
                return name;
            }
        }
        return '--';
    } 

    const convertLongText = (text) => {
        if (text?.length > 12){
            return `${text.substring(0, 9)}...`;
        }
        return text;
    } 

    return (
        <div className={`${s.assetListPart}`} 
            style={{
                minHeight: `${shortType?walletAssetsType===2?230:170: window.innerHeight-450}px`,
                maxHeight: `${window.innerHeight-450}px`}}>
            <div className={`${s.assetListBg} flex_center_start_col`}>
                {
                    walletAssetsType === 0 && 
                    <>
                        <div className={`${s.assetListLabel} flex_center_start`}>
                            <div className={` ${s.assetTokenPart} `}>
                                <div className={`${s.assetLabelLeft} ml40 momentFont fs12 color-moment-gray`}>{t('Token')}</div>
                            </div>
                            <div className={`${s.assetLabelRight} ${s.assetAmountPart}`}>
                                {t('Amount')}
                            </div>
                            <div className={`${s.assetLabelRight} ${s.assetPricePart}`}>
                                {t('Price')}
                            </div>
                        </div>
                        <div className={`${s.assetList}`} id="assetList">
                            {
                                list?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={list?.length || 0}
                                        next={()=>{
                                            getMoreFn();
                                        }}
                                        hasMore={nextCursor !== -1}
                                        loader={<div
                                            style={{
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        ><Spin /></div>}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "0",
                                            width: "100%"
                                        }}
                                        endMessage={
                                            <div style={{
                                                textAlign: "center",
                                                width: "100%",
                                                color: "var(--color-gray)",
                                                fontSize: '12px'
                                            }}></div>
                                        }
                                        scrollableTarget={'assetList'}
                                    >
                                        {
                                            list.map((item,ind)=>{
                                                let isUp = true;
                                                return (
                                                    <div key={ind} className={`${s.assetTokenLine} flex_center_start`}>
                                                        <div className={`${s.assetTokenPart} flex_center_start`}>
                                                            <CustomIcon
                                                                width={30} 
                                                                height={30}
                                                                className="mr5"
                                                                imgName={`Picture/${item?.icon}`}/>
                                                            <div className={`${s.assetTokenPartRight} flex_center_center_col tlr`}>
                                                                <div className={`${s.assetTokenName}`}>
                                                                    {item?.displayCoinName}
                                                                </div>
                                                                <div className={`${s.assetTokenChain}`}>
                                                                    {item?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`${s.assetAmountPart} flex_center_center_col tlr`}>
                                                            <div className={`${s.assetTokenAmount}`}>
                                                                {convertWalletBalance(item?.balance || 0)}
                                                            </div>
                                                            <div className={`${s.assetTokenTotalValue}`}>
                                                                {item?.quotes > 0 ? `$${convertWalletBalance((item?.balance || 0) * item?.quotes)}` : '--'}
                                                            </div>
                                                        </div>
                                                        <div className={`${s.assetPricePart} flex_center_center_col`}>
                                                            <div className={`${s.assetPrice} w100p`}>
                                                                {item?.quotes > 0 ? `$${convertPriceValue(item?.quotes)}` : '--'}
                                                            </div>
                                                            {/*<div className={`flex_center_end w100p`}>
                                                                <span className={`${isUp?s.isUpText:s.isDownText} fs12`}>
                                                                    {
                                                                    isUp ? 
                                                                        '':
                                                                        '-'
                                                                    }
                                                                    {`0.00%`}
                                                                    </span>
                                                            </div>*/}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }    
                                    </InfiniteScroll> :
                                    <div className={`${s.assetListNoData} flex_center_center`}>
                                        <CustomIcon width={111} height={95} className={`mb30`} imgName={'Picture/UI_Picture_NoData_Empty_02'}></CustomIcon>
                                        <div className="mt20 fs18 momentFont fw-400" style={{color: `#555555`}}>
                                            {t('No Data')}
                                        </div>
                                    </div>
                            }   
                        </div>
                    </>
                }
                {
                    walletAssetsType === 1 && 
                    <>
                        <div className={`${s.assetListLabel} flex_center_start`}>
                            <div className={` ${s.assetTokenPart} `}>
                                <div className={`${s.assetLabelLeft} ml30`}>{t('Token')}</div>
                            </div>
                            <div className={`${s.assetLabelRight} ${s.assetAmountPart}`}>
                                {t('Amount')}
                            </div>
                            <div className={`${s.assetLabelRight} ${s.assetPricePart}`}>
                                {t('Price')}
                            </div>
                            <div className={`${s.assetLabelRight} ${s.assetCardPart}`}>
                                {t('Card')}
                            </div>
                        </div>
                        <div className={`${s.assetList}`} id="assetList">
                            {
                                list?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={list?.length || 0}
                                        next={()=>{
                                            getMoreFn();
                                        }}
                                        hasMore={nextCursor !== -1}
                                        loader={<div
                                            style={{
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        ><Spin /></div>}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "0",
                                            width: "100%"
                                        }}
                                        endMessage={
                                            <div style={{
                                                textAlign: "center",
                                                width: "100%",
                                                color: "var(--color-gray)",
                                                fontSize: '12px'
                                            }}></div>
                                        }
                                        scrollableTarget={'assetList'}
                                    >
                                        {
                                            list.map((item,ind)=>{
                                                const {
                                                    clubUserId,
                                                    coinId,
                                                    symbolName,
                                                    contractAddr,
                                                    nftContractAddr,
                                                    exchangeRate,
                                                    percent24Change,
                                                    coinToNFTRatio,
                                                    balance,
                                                    symbolImageUrl,
                                                    nftOwnNum
                                                } = item;
                                                let tokenInfo = getMomentChainTokenInfoById(coinId);
                                                let isUp = percent24Change >= 0;
                                                let cardNum = 0;
                                                if (Number(balance) > 0 && coinToNFTRatio > 0){
                                                    cardNum = Math.floor(Number(balance) / coinToNFTRatio);
                                                }
                                                return (
                                                    <div key={ind} className={`${s.assetTokenLine} flex_center_start`} onClick={() =>{
                                                        linkToClub(clubUserId, 2);
                                                    }}>
                                                        <div className={`${s.assetTokenPart} flex_center_start`}>
                                                            <div className={`${s.tokenImage}`} style={{backgroundImage: `url(${symbolImageUrl})`}}>
                                                            </div>
                                                            <div className={`${s.assetTokenPartRight} flex_center_center_col`}>
                                                                <div className={`${s.assetTokenName}`}>
                                                                    {symbolName}
                                                                </div>
                                                                <div className={`${s.assetTokenChain}`}>
                                                                    {tokenInfo?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`${s.assetAmountPart} flex_center_center_col`}>
                                                            <div className={`${s.assetTokenAmount}`}>
                                                                {convertWalletBalance(Number(balance) || 0)}
                                                            </div>
                                                            <div className={`${s.assetTokenTotalValue}`}>
                                                                {exchangeRate > 0 ? `$${convertWalletBalance((Number(balance) * exchangeRate) || 0)}` : '--'}
                                                            </div>
                                                        </div>
                                                        <div className={`${s.assetPricePart} flex_center_center_col`}>
                                                            <div className={`${s.assetPrice} w100p`}>
                                                                {exchangeRate > 0 ? `$${convertPriceValue(exchangeRate)}` : '--'}
                                                            </div>
                                                            <div className={`flex_center_end w100p`}>
                                                                <span className={`${isUp?s.isUpText:s.isDownText} fs12`}>
                                                                    {`${percent24Change ? percent24Change.toFixed(2):'0.00'}%`}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                        <div className={`${s.assetCardPart} flex_center_end w100p`} onClick={(e) =>{
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            let baseUrl = chainOpenseaUrl?.[tokenInfo?.chainId];
                                                            if (baseUrl){
                                                                window.open(`${baseUrl}${nftContractAddr}`);
                                                            }
                                                        }}>
                                                            {
                                                                nftOwnNum > 0 ?
                                                                    <>
                                                                        <div className={`${s.assetCardText}`}>
                                                                            {nftOwnNum}
                                                                        </div>
                                                                        <CustomIcon width={14} height={14} className={`ml2`} imgName={'Picture/UI_Picture_MyAssets_LinkIcon_01'}></CustomIcon>
                                                                    </>:
                                                                    <>
                                                                        <div className={`${s.assetCardText}`}>
                                                                            {`--`}
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }    
                                    </InfiniteScroll> :
                                    <div className={`${s.assetListNoData} flex_center_center`}>
                                        <CustomIcon width={111} height={95} className={`mb30`} imgName={'Picture/UI_Picture_NoData_Empty_02'}></CustomIcon>
                                        <div className="mt20 fs18 momentFont fw-400" style={{color: `#555555`}}>
                                            {t('No Data')}
                                        </div>
                                    </div>
                            }   
                        </div>
                    </>
                }
                {
                    walletAssetsType === 2 && 
                    <>
                        <div className={`${s.assetListLabel} flex_center_start`}>
                            <div className={` ${s.holdingCollectionPart} `}>
                                <div className={`${s.assetLabelLeft}`}>{t('Moments')}</div>
                            </div>
                            <div className={`${s.assetLabelRight} ${s.holdingPackPart}`}>
                                {t('Packs2')}
                            </div>
                            <div className={`${s.assetLabelRight} ${s.holdingPricePart}`}>
                                {t('Price')}
                            </div>
                            <div className={`${s.assetLabelRight} ${s.holdingTGEPart}`}>
                                {t('TGE1')}
                            </div>
                        </div>
                        <div className={`${s.assetList}`} id="assetList">
                            {
                                list?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={list?.length || 0}
                                        next={()=>{
                                            getMoreFn();
                                        }}
                                        hasMore={nextCursor !== -1}
                                        loader={<div
                                            style={{
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        ><Spin /></div>}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "0",
                                            width: "100%"
                                        }}
                                        endMessage={
                                            <div style={{
                                                textAlign: "center",
                                                width: "100%",
                                                color: "var(--color-gray)",
                                                fontSize: '12px'
                                            }}></div>
                                        }
                                        scrollableTarget={'assetList'}
                                    >
                                        {
                                            list.map((item,ind)=>{
                                                const {
                                                    clubUserId,
                                                    clubProfile,
                                                    clubSymbolName,
                                                    holdNum,
                                                    buyPrice,
                                                    sellPrice,
                                                    historyPrice,
                                                    curCardKindNum,
                                                    cardKindMaxNum,
                                                    videoId,
                                                    videoOriHeight,
                                                    videoOriWidth,
                                                    videoFirstFrameUrl,
                                                    videoUrlMap,
                                                    canTGE,
                                                } = item;
                                                const {
                                                    momentAuditCode,
                                                    displayName,
                                                    avatarUrl,
                                                    coinId,
                                                } = clubProfile;
                                                let tokenInfo = getMomentChainTokenInfoById(coinId);
                                                let tgeProgress = Math.floor((curCardKindNum || 0) * 100 / (cardKindMaxNum || 1));
                                                tgeProgress = numberClamp(tgeProgress, 0, 100);
                                                let chg = chgFn(sellPrice, historyPrice);
                                                let isUp = chg === '-' ? true : chg >= 0 ? true : false;
                                                let quotes = marketInfo?.quotes?.[tokenInfo?.coinName];
                                                let currencyPrice = '--';
                                                let endLine = shortType ? Math.min(list?.length - 1, 2) : (list?.length - 1);
                                                if (quotes > 0){
                                                    currencyPrice = holdNum * sellPrice * quotes;
                                                }
                                                if (shortType && ind >= 3){
                                                    return <></>;
                                                }
                                                else{
                                                    return (
                                                        <div key={ind} className={`${s.assetTokenLine} ${ind < endLine ? s.bottomLine:''} ${s.holdingPackLine} ${highlightPackItemId === clubUserId ? s.highlightLine:''} flex_center_start`} onClick={() =>{
                                                            let _list = [];
                                                            _list.push({
                                                                clubId: clubUserId,
                                                                momentAuditCode: momentAuditCode,
                                                                videoId: videoId,
                                                                videoUrlMap: videoUrlMap,
                                                                coverUrl: avatarUrl,
                                                                videoOriHeight: videoOriHeight,
                                                                videoOriWidth: videoOriWidth,
                                                                videoFirstFrameUrl: videoFirstFrameUrl,
                                                            });
                                                            setSwitchVideoList(_list);
                                                            setMemeclubSwitchVideoParams(null);
                                                            setSwitchVideoListCursor("-1");
                                                            linkToClub(clubUserId, -1);
                                                        }}>
                                                            <div className={`${s.holdingCollectionPart} flex_center_start`}>
                                                                <div className={`${s.holdingPackImage}`}>
                                                                    <MomentClubPack 
                                                                        width={44} 
                                                                        height={44} 
                                                                        imageUrl={avatarUrl} 
                                                                        packName={''/*clubSymbolName*/}>
                                                                    </MomentClubPack>
                                                                </div>
                                                                <div className={`${s.holdingTokenPartRight} flex_center_center_col`}>
                                                                    <div className={`${s.assetTokenName} fs12`}>
                                                                        {convertDisplayName(displayName)}
                                                                    </div>
                                                                    <div className={`${s.assetTokenChain}`}>
                                                                        {convertLongText(tokenInfo?.name || '--')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`${s.holdingPackPart} flex_center_center_col`}>
                                                                <div className={`flex_center_end w100p`}>
                                                                    <CustomIcon width={14} height={14} className="mr3"
                                                                        imgName={'Picture/UI_Picture_icon_cardpack'}/>
                                                                    <span className={`momentFont fs12 color-EEEEEE`}>
                                                                        {`x${holdNum || 0}`}
                                                                    </span>
                                                                </div>
                                                                <div className={`flex_center_end w100p mt2`}>
                                                                    <span className={`momentFont fs12 color-moment-gray`}>
                                                                        {`${quotes > 0 ? `$${convertPriceValue(currencyPrice)}` :'--'}`}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className={`${s.holdingPricePart} flex_center_center_col`}>
                                                                <div className={`flex_center_end w100p`}>
                                                                    <CustomIcon
                                                                        width={14} 
                                                                        height={14}
                                                                        className="mr2"
                                                                        imgName={`Picture/${tokenInfo?.icon}`}/>
                                                                    <div className={`${s.holdingAssetPrice} fb`}>
                                                                        {convertPriceValue(Number(sellPrice) || 0)}
                                                                    </div>
                                                                </div>
                                                                <div className={`flex_center_end w100p`}>
                                                                    <span className={`${isUp?s.isUpText:s.isDownText} momentFont fs12`}>
                                                                        {`${chg}%`}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className={`${s.holdingTGEPart} flex_center_center_col`}>
                                                                <div className={`${s.holdingTGEProgress} momentFont fs16 fw-400 flex_center_end w100p`}>
                                                                    {`${canTGE ? `${tgeProgress}%`:'--'}`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </InfiniteScroll> :
                                    <div className={`${s.assetListNoData} flex_center_center`}>
                                        <CustomIcon width={111} height={95} className={``} imgName={'Picture/UI_Picture_NoData_Empty_02'}></CustomIcon>
                                        <div className="mt20 fs18 momentFont fw-400" style={{color: `#555555`}}>
                                            {t('No Data')}
                                        </div>
                                    </div>
                            }
                        </div>
                        
                    </>
                }
                {
                    walletAssetsType === 3 && 
                    <>
                        <div className={`${s.assetListLabel} flex_center_start`}>
                            <div className={` ${s.assetTokenPart} `}>
                                <div className={`${s.assetLabelLeft}`}>{t('Token')}</div>
                            </div>
                            <div className={`${s.assetLabelRight} ${s.assetAmountPart}`}>
                                {t('Amount')}
                            </div>
                            <div className={`${s.assetLabelRight} ${s.assetPricePart}`}>
                                {t('Price')}
                            </div>
                            <div className={`${s.assetLabelRight} ${s.assetCardPart}`}>
                                {t('NFTs')}
                            </div>
                        </div>
                        <div className={`${s.assetList}`} id="assetList">
                            {
                                (list?.dnt?.length || 0) + (list?.native?.length) > 0 > 0 ?
                                    <InfiniteScroll
                                        dataLength={list?.length || 0}
                                        next={()=>{
                                            getMoreFn();
                                        }}
                                        hasMore={nextCursor !== -1}
                                        loader={<div
                                            style={{
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        ><Spin /></div>}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "0",
                                            width: "100%"
                                        }}
                                        endMessage={
                                            <div style={{
                                                textAlign: "center",
                                                width: "100%",
                                                color: "var(--color-gray)",
                                                fontSize: '12px'
                                            }}></div>
                                        }
                                        scrollableTarget={'assetList'}
                                    >
                                        {
                                            list.native?.length > 0 && list.native.map((item,ind)=>{
                                                let isUp = true;
                                                let maxLenInd = (list.native?.length || 0) + (list.dnt?.length || 0) - 1;
                                                let endLine = shortType ? Math.min(maxLenInd, 2) : maxLenInd;
                                                if (shortType && ind >= 3){
                                                    return <></>;
                                                }
                                                else{
                                                    return (
                                                        <div key={ind} className={`${s.assetTokenLine} ${ind < endLine?s.bottomLine:''} flex_center_start`}>
                                                            <div className={`${s.assetTokenPart} flex_center_start`}>
                                                                <div className={`${s.nativeTokenImage}`} style={{backgroundImage: `url(/UI/Picture/${item?.icon}.png)`}}>
                                                                </div>
                                                                <div className={`${s.assetTokenPartRight} flex_center_center_col`}>
                                                                    <div className={`${s.assetTokenName}`}>
                                                                        {item?.displayCoinName}
                                                                    </div>
                                                                    <div className={`${s.assetTokenChain}`}>
                                                                        {convertLongText(item?.name)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`${s.assetAmountPart} flex_center_center_col`}>
                                                                <div className={`${s.assetTokenAmount}`}>
                                                                    {convertWalletBalance(Number(item?.balance) || 0)}
                                                                </div>
                                                                <div className={`${s.assetTokenTotalValue}`}>
                                                                    {item?.quotes > 0 ? `$${convertWalletBalance(((Number(item?.balance) || 0) * item?.quotes))}` : '--'}
                                                                </div>
                                                            </div>
                                                            <div className={`${s.assetPricePart} flex_center_center_col`}>
                                                                <div className={`${s.assetPrice} w100p`}>
                                                                    {item?.quotes > 0 ? `$${convertPriceValue(item?.quotes)}` : '--'}
                                                                </div>
                                                                <div className={`flex_center_end w100p`}>
                                                                    <span className={`${isUp?s.isUpText:s.isDownText} momentFont fs12`}>
                                                                        {`${'0.00'}%`}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className={`${s.assetCardPart} flex_center_end w100p`}>
                                                                <div className={`${s.assetCardText} momentFont`}>
                                                                    {`--`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }  
                                        {
                                            list.dnt?.length > 0 && list.dnt.map((item,ind)=>{
                                                const {
                                                    clubUserId,
                                                    clubProfile,
                                                    coinId,
                                                    symbolName,
                                                    contractAddr,
                                                    nftContractAddr,
                                                    exchangeRate,
                                                    percent24Change,
                                                    coinToNFTRatio,
                                                    balance,
                                                    symbolImageUrl,
                                                    nftOwnNum,
                                                    videoId,
                                                    videoOriHeight,
                                                    videoOriWidth,
                                                    videoFirstFrameUrl,
                                                    videoUrlMap,
                                                } = item;
                                                const {
                                                    momentAuditCode,
                                                    avatarUrl,
                                                } = clubProfile || {};
                                                let tokenInfo = getMomentChainTokenInfoById(coinId); 
                                                let isUp = percent24Change >= 0;
                                                let cardNum = 0;
                                                let maxLenInd = (list.native?.length || 0) + (list.dnt?.length || 0) - 1;
                                                let endLine = shortType ? Math.min(maxLenInd, 2) : maxLenInd;
                                                if (Number(balance) > 0 && coinToNFTRatio > 0){
                                                    cardNum = Math.floor(Number(balance) / coinToNFTRatio);
                                                }
                                                if (shortType && ind + (list.native?.length || 0) >= 3){
                                                    return <></>;
                                                }
                                                else{
                                                    return (
                                                        <div key={ind} className={`${s.assetTokenLine} ${ind + (list.native?.length || 0) < endLine?s.bottomLine:''} flex_center_start`} onClick={() =>{
                                                            setMemeclubSwitchVideoParams(null);
                                                            let _list = [];
                                                            _list.push({
                                                                clubId: clubUserId,
                                                                momentAuditCode: momentAuditCode,
                                                                videoId: videoId,
                                                                videoUrlMap: videoUrlMap,
                                                                coverUrl: avatarUrl,
                                                                videoOriHeight: videoOriHeight,
                                                                videoOriWidth: videoOriWidth,
                                                                videoFirstFrameUrl: videoFirstFrameUrl,
                                                            });
                                                            setSwitchVideoList(_list);
                                                            setSwitchVideoListCursor("-1");
                                                            linkToClub(clubUserId, -1);
                                                        }}>
                                                            <div className={`${s.assetTokenPart} flex_center_start`}>
                                                                <div className={`${s.tokenImage}`} style={{backgroundImage: `url(${symbolImageUrl})`}}>
                                                                </div>
                                                                <div className={`${s.assetTokenPartRight} flex_center_center_col`}>
                                                                    <div className={`${s.assetTokenName}`}>
                                                                        {symbolName}
                                                                    </div>
                                                                    <div className={`${s.assetTokenChain}`}>
                                                                        {tokenInfo?.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`${s.assetAmountPart} flex_center_center_col`}>
                                                                <div className={`${s.assetTokenAmount}`}>
                                                                    {convertWalletBalance(Number(balance) || 0)}
                                                                </div>
                                                                <div className={`${s.assetTokenTotalValue}`}>
                                                                    {exchangeRate > 0 ? `$${convertWalletBalance((Number(balance) * exchangeRate) || 0)}` : '--'}
                                                                </div>
                                                            </div>
                                                            <div className={`${s.assetPricePart} flex_center_center_col`}>
                                                                <div className={`${s.assetPrice} w100p`}>
                                                                    {exchangeRate > 0 ? `$${convertPriceValue(exchangeRate)}` : '--'}
                                                                </div>
                                                                <div className={`flex_center_end w100p`}>
                                                                    <span className={`${isUp?s.isUpText:s.isDownText} momentFont fs12`}>
                                                                        {`${percent24Change ? percent24Change.toFixed(2):'0.00'}%`}
                                                                        </span>
                                                                </div>
                                                            </div>
                                                            <div className={`${s.assetCardPart} flex_center_end w100p`} onClick={(e) =>{
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                let baseUrl = chainOpenseaUrl?.[tokenInfo?.chainId];
                                                                if (baseUrl){
                                                                    window.open(`${baseUrl}${nftContractAddr}`);
                                                                }
                                                            }}>
                                                                {
                                                                    nftOwnNum > 0 ?
                                                                        <>
                                                                            <div className={`${s.assetCardText} momentFont assetCardText`}>
                                                                                {nftOwnNum}
                                                                            </div>
                                                                            <CustomIcon width={14} height={14} className={`ml2`} imgName={'Picture/UI_Picture_MyAssets_LinkIcon_01'}></CustomIcon>
                                                                        </>:
                                                                        <>
                                                                            <div className={`${s.assetCardText} momentFont`}>
                                                                                {`--`}
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }    
                                    </InfiniteScroll> :
                                    <div className={`${s.assetListNoData} flex_center_center`}>
                                        <CustomIcon width={111} height={95} className={``} imgName={'Picture/UI_Picture_NoData_Empty_02'}></CustomIcon>
                                        <div className="mt20 fs18 momentFont fw-400" style={{color: `#555555`}}>
                                            {t('No Data')}
                                        </div>
                                    </div>
                            }   
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo:app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
        setSwitchVideoType: (params) => {
            return dispatch({
                type: "app/setSwitchVideoType",
                payload: params,
            });
        },
        setMemeclubSwitchVideoParams: (params) => {
            return dispatch({
                type: "app/setMemeclubSwitchVideoParams",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(List));
