import React, {memo, useState, useMemo, useEffect} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {Input, Button, Modal} from "antd";
import {
    officialInviteCode,
    joinChartPre,
    taPush,
    referralcode_bind,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import {useWallets} from "@privy-io/react-auth";

const ModalGetInviteCode = (
    {
        userInfo,
        closeFn = () =>{}
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const [inviteCode, setInviteCode] = useState(officialInviteCode);
    const [bindState, setBindState] = useState(0);//0:before, 1:binding, 2:success
    const toInputChangeHandler = (event) => {
        setInviteCode(event.target.value);
    }

    const bindInvite = () => {
        if (bindState !== 0){
            return;
        }
        let val = inviteCode.toLocaleLowerCase();
        if (val.indexOf(joinChartPre) === 0) {
            val = val.substr(1);
        }
        if (val.indexOf('turnup.so/' + joinChartPre) === 0) {
            val = 'turnup.so/' + val.substr(11);
        }
        taPush('bind_invite_code', {code: val});
        setBindState(1);
        val && referralcode_bind(val).then(res => {
            setBindState(2);
        }).catch(e => {
            console.log("bind code error", e);
            setBindState(0);
        })
    }

    return (
        <div className={`${s.wrap} flex_center_start_col`}>
            <div className={s.inputWrap}>
                <Input className={`${s.inviteCodeInput}`} value={inviteCode} onChange={toInputChangeHandler}/>
            </div>
            {
                bindState === 2 ?
                <ButtonFactory
                    className={s.bindBtn}
                    size={22}
                    isFixed={true}
                    fixedStyle={{useHeight: true, height: 38}}
                    onClick={() => {
                        closeFn();
                    }}
                >
                    <div className={`fs18 fb color-black`}>
                        {t('Success')}
                    </div>
                </ButtonFactory> :
                bindState === 1 ?
                <ButtonFactory
                    className={s.bindBtn}
                    size={22}
                    disabled={true}
                    isFixed={true}
                    fixedStyle={{useHeight: true, height: 38}}
                >
                    <div className={`fs18 fb color-white`}>
                        {t('Binding')}
                    </div>
                </ButtonFactory> : 
                <ButtonFactory
                    className={s.bindBtn}
                    size={22}
                    isFixed={true}
                    fixedStyle={{useHeight: true, height: 38}}
                    onClick={() => {
                        bindInvite();
                    }}
                >
                    <div className={`fs18 fb color-black`}>
                        {t('Get my ref code')}
                    </div>
                </ButtonFactory>
            }
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalGetInviteCode));
