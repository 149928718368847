import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import {useNavigate, useParams} from "react-router-dom";
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";

import { AutoStrangeRule } from "@/utils/strangeRule";
import { getHolderList, detailInfoShowHolderNum, calcTotalValue,memePriceFormular } from "@/utils/memeClub";

import { convertWalletBalance, stringToNumber } from "@/utils/common";
import MemeClubPack from "@/common/MemeClubPack";
import List from "./List";

const Info = (
    {
        clubBasicInfo,
        clubId,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showMoreMetaData, setShowMoreMetaData] = useState(false)
    const [holder, setHolder] = useState([])
    // const a = useMemo(()=>{},[])

    const { 
        curCardKindNum, 
        clubInfo, 
        cardKindMaxNum, 
    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])

    const {
        cardsTotalSupply,
        description,
        attrs,
        coinId,
        imageUrl,
        paramA,
        paramB,
        curveType,
        symbolName
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])


    const totalVal = useMemo(()=>{
        if(!coinId) return 0
        console.log(coinId,curveType);
        console.log(memePriceFormular.find(i=>i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType));
        return calcTotalValue(memePriceFormular.find(i=>i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType),paramA,paramB,cardsTotalSupply)
    },[coinId,paramA,paramB,curveType,cardsTotalSupply]) 


    const getHolderListFn = (clubUserId, nextCursor) => {
        getHolderList(clubUserId, nextCursor).then(res => {
            const _list = (res?.holderList || []).slice(0, detailInfoShowHolderNum);
            setHolder(_list)
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        getHolderListFn(clubId, 0)
    }, [clubId])

    const attrList = useMemo(() => {
        let attrType1 = [], attrType2 = [];
        attrs?.map(item => {
            const { type } = item;
            if (type === 1) {
                attrType1.push(item)
            }
            if (type === 2) {
                attrType2.push(item)
            }
        })
        const _attrType1 = attrType1.sort((a, b) => {
            return a?.attrName?.toString().length - b?.attrName?.toString().length
        })
        return [
            ..._attrType1,
            ...attrType2
        ]
    }, [attrs])

    const [showBigAvatar, setShowBigAvatar] = useState(false)
    return (
        <div className={`${s.box}`}>
            
            <div className={`${s.partTitle}`}>
                <CustomIcon width={17} height={39} className="mr5"
                    imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                {AutoStrangeRule(t('Meme Info'))}
            </div>
            <div className={`flex_start_start w100p mt15 ${s.infoWrap} ${showBigAvatar ? s.vBig : ''}`}>
                <MemeClubPack 
                   onClick={() => {
                    setShowBigAvatar(!showBigAvatar)
                    }} 
                    width={showBigAvatar ? 200 : 47} height={showBigAvatar ? 315 : 74 } imageUrl={imageUrl} packName={symbolName} >

                </MemeClubPack>
                <div className={`fs12 fl tll flex_start_center ${s.des}`}>{description}</div>
            </div>
            { !clubInfo?.isFT && (
                <div className={s.metaDataWrap}>
                    <div className="flex_center_between">
                        <div>{t('TGE NFT Attributes:')}</div>
                        <CustomIcon width={21} height={11} className="mr5" onClick={() => {
                            setShowMoreMetaData(!showMoreMetaData)
                        }}
                            imgName={showMoreMetaData ? 'Button/UI_Button_Arrow_Up_01' : 'Button/UI_Button_Arrow_Down_01'} />
                    </div>
                    {
                        showMoreMetaData &&
                        <div className={s.list}>
                            {attrList?.map((item, ind) => {
                                const { attrName, enumStrs, maxNum, minNum, type } = item;
                                const vLen = attrName.toString().length;
                                return (
                                    <div key={ind} className={`${s.attr} ${vLen <= 15 ? s.lenType1 : s.lenType2} ${s['attrType' + type]}`}>
                                        <div className={`${s.label} ${s.metaData}`}>{attrName}</div>
                                        <div className={`${s.val}`}>
                                            {
                                                type === 1 ?
                                                    `${convertWalletBalance(minNum)}-${convertWalletBalance(maxNum)}` :
                                                    <>
                                                        {
                                                            enumStrs?.map((attr, index) => {
                                                                const { enumName, weight } = attr
                                                                return (
                                                                    <div className={s.enum} key={index}>
                                                                        {enumName} ({weight})
                                                                    </div>
                                                                )

                                                            })
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            )}
            <List></List>
            <div className={`${s.partTitle}`}>
                <CustomIcon width={17} height={39} className="mr5"
                    imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                {AutoStrangeRule(t('Holder Distribution'))}
            </div>
            <div className={`${s.partTitle}`}>
                <CustomIcon width={17} height={39} className="mr5"
                    imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                {AutoStrangeRule(t('Bonding Cure Progress'))}
            </div>
            <div className={s.progress}>
                <div className={s.proWrap}>
                    <div className={s.l}>
                        <div className={s.proVal} style={{
                            width: `${Math.round(curCardKindNum / cardKindMaxNum * 100)}%`
                        }}>

                        </div>
                        <div className={s.val}>{`${curCardKindNum}/${cardKindMaxNum}`}</div>
                    </div>

                </div>
            </div>
            
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Info));
