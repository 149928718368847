import React, { memo, useEffect, useMemo, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonFactory from "@/common/ButtonFactory";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Spin } from "antd";
import AvatarInfo from "@/common/AvatarInfo";
import AntButton from "@/common/AntButton";
import { convertWalletBalance, getAvatarFromProfile, sliceStr } from "@/utils/common";
import Detail from "../../Detail";
import AntModal from "@/common/AntModal";
import ModelUpFerver from "@/model/ModelUpFerver";
import ModelUpTweetShare from "@/model/ModelUpTweetShare";

const List = (
    {
        list,
        type,
        getMoreFn = () => { },
        nextCursor = -1,
        showAmount,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showDetail,setShowDetail] = useState(false)
    const [detailInfo,setDetailInfo] = useState(null)
    const [showModel ,setShowModel] = useState(false)
    const [openSharePanel,setOpenSharePanel] = useState(false)
    const [selAccountName,setSelAccountName] = useState('')
    const amount = useMemo(()=>{
        return showAmount[type]
    },[showAmount])
    const [activeInd, setActiveInd] = useState(0)
    const rankRander = (rankIdx) => {
        if (rankIdx === 1) {
            return <CustomIcon width={25} height={25} imgName={`Picture/UI_Picture_NO-First_01`} />
        }
        if (rankIdx === 2) {
            return <CustomIcon width={25} height={25} imgName={`Picture/UI_Picture_NO-Second_01`} />
        }
        if (rankIdx === 3) {
            return <CustomIcon width={25} height={25} imgName={`Picture/UI_Picture_NO-Third_01`} />
        }
        
        return `${rankIdx}th`
    }
    const getUserInfo = (userInfo, twitterUserInfo, isUser) => {
        let avatarUrl, displayName, accountName, twitterUid ;
        if (isUser) {
            avatarUrl = getAvatarFromProfile(userInfo?.profile)
            displayName = userInfo?.profile?.displayName
            accountName = userInfo?.profile?.accountName
            twitterUid = userInfo?.twitterUid
        } else {
            avatarUrl = twitterUserInfo?.avatarUrl
            displayName = twitterUserInfo?.accountName
            accountName = twitterUserInfo?.accountName
            twitterUid = twitterUserInfo?.twitterUid
        }
        return {
            displayName,
            avatarUrl,
            accountName,
            twitterUid
        }
    }

    return (
        <div className={`${s.wrap} `}>
            <div className={`${s.head} ${s['type'+type]}`}>
                <div className={s.hl}>{type === '1' ? t('Top KOL'): t('Top Clubs')}</div>
                <div className={s.hm}>
                    {type === '1' ? t('Total Fever') : t('Total Top Clubs')} 
                    {type === '1' && <CustomIcon width={14} onClick={()=>{setShowModel(true)}} className="ml5" height={14} imgName={"Button/UI_Button_QuestionMark_03"} />}
                </div>
                {type === '1' && <div className={s.hr}>{t('Tweet Now')}</div>}
            </div>
            <div className={`${s.list}`} id="list">
                <InfiniteScroll
                    dataLength={list?.length || 0}
                    next={getMoreFn}
                    hasMore={nextCursor !== -1}
                    loader={<Spin />}
                    height={"100%"}
                    endMessage={
                        <div style={{
                            textAlign: "center",
                            width: "100%",
                            color: "var(--color-gray)",
                            fontSize: '12px'
                        }}></div>
                    }>
                    {
                       type === '1' && list?.map((item,index) => {
                            const { rankIdx, fevelVal, userInfo, twitterUserInfo, isUser } = item;
                            const { displayName, avatarUrl,accountName,twitterUid } = getUserInfo(userInfo, twitterUserInfo, isUser)
                            if(index >= amount) return null
                            return (
                                <div className={s.item} key={rankIdx} onClick={
                                    ()=>{
                                        setDetailInfo({
                                            displayName, 
                                            avatarUrl,
                                            isUser,
                                            rankIdx,
                                            accountName,
                                            twitterUid,
                                            userId:userInfo?.userId || 0
                                        })
                                        setShowDetail(true)
                                    }
                                }>
                                    <div className={s.rankIdx}>
                                        {rankRander(rankIdx)}
                                    </div>
                                    <div className={s.info}>
                                        {twitterUid ? 
                                            <>
                                                <AvatarInfo className="mr5" size={32} src={avatarUrl}></AvatarInfo>
                                                {sliceStr(displayName,'all') }
                                            </>:
                                            '--'
                                        }
                                    </div>
                                    <div className={s.fire}>
                                        <CustomIcon width={20} height={20} imgName={"Picture/UI_Picture-Currency_UP_01"} />
                                        {convertWalletBalance(fevelVal)}
                                    </div>
                                    <div className={s.btn}>
                                        <AntButton className="btn_white hirePost" shape="round" onClick={(e)=>{
                                            e.stopPropagation()
                                            setSelAccountName('@'+accountName)
                                            setOpenSharePanel(true)
                                        }}>
                                            <CustomIcon width={18} height={18} imgName={"Button/UI_Button_Ball-White_Twitter_On"} />
                                            {t('Post')}
                                        </AntButton>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                       type === '2' && list?.map((item,index) => {
                            const { rankIdx, fevelVal, userId, profile } = item;
                            const { displayName, avatarUrl } = getUserInfo({profile}, {}, true)
                            if(index >= amount) return null
                            return (
                                <div className={`${s.item} ${s.type2}`} key={rankIdx}>
                                    <div className={s.rankIdx}>
                                        {rankRander(rankIdx)}
                                    </div>
                                    <div className={s.info}>
                                        <AvatarInfo className="mr5" size={32} src={avatarUrl}></AvatarInfo>
                                        {displayName}
                                    </div>
                                    <div className={s.fire}>
                                        <CustomIcon width={20} height={20} imgName={"Picture/UI_Picture-Currency_UP_01"} />
                                        {convertWalletBalance(fevelVal)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </InfiniteScroll>

            </div>
            {showDetail && <Detail 
                info={detailInfo}
                setShowDetail={setShowDetail}
            ></Detail>}
            <AntModal
                width='330px'
                title={t('Fever Mulitplier')}
                className="confirmModalWrap"
                centered={true}
                open={showModel}
                destroyOnClose={true}
                onOk={() => setShowModel(false)}
                onCancel={() => setShowModel(false)}
            >
                <ModelUpFerver />
            </AntModal>
            <AntModal
                width='330px'
                title={t('Tweet Now')}
                className="confirmModalWrap"
                centered={true}
                open={openSharePanel}
                destroyOnClose={true}
                onOk={() => setOpenSharePanel(false)}
                onCancel={() => setOpenSharePanel(false)}
            >
                <ModelUpTweetShare accountName={selAccountName} />
            </AntModal>
        </div>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(List));
