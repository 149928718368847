import http from "@/utils/axios";
import {url} from "@/utils/configUri";
import {getConfig} from "@/utils/common";
import {BigNumber, ethers} from "ethers";
import {abi_red_packet, abi_token} from "@/utils/abi";
import {getGasConfig} from "@/utils/gas";

const mock = false;

export const post_red_packet_status = (userId) => {
    return new Promise((resolve, reject) => {
        // mock
        if (mock) {
            resolve({
                "code": 0,
                "data": {
                    "tarWallet": "0x0000000000000000000000",
                    "redPacketList": [
                        {
                            userId: 1819,
                            "senderProfile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                            isOpen: false,
                            startTime: 1806685529,
                            endTime: 1706685529,
                            coinType: 1, // 1 matic 0 lfg
                            redPacketId: "1",
                        },
                        {
                            userId: 1819,
                            "senderProfile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-401911111111111111111122222",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                            isOpen: true,
                            startTime: 1706685529,
                            endTime: 1706685529,
                            coinType: 0, // 1 matic 0 lfg
                            redPacketId: "2",
                        },
                        {
                            userId: 1819,
                            "senderProfile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                            isOpen: false,
                            startTime: 1706685529,
                            endTime: 1706685529,
                            coinType: 0, // 1 matic 0 lfg
                            redPacketId: "2",
                        },
                        // {
                        //     userId: 1819,
                        //     "senderProfile": {
                        //         "platformType": 0,
                        //         "avatarUrl": "",
                        //         "originalAvatarUrl": "",
                        //         "displayName": "Guest-4019",
                        //         "accountName": "Guest-4019",
                        //         "profileDeepLink": ""
                        //     },
                        //     isOpen: true,
                        //     startTime: 1706685529,
                        //     endTime: 1706685529,
                        //     coinType: 0, // 1 matic 0 lfg
                        //     redPacketId: "2",
                        // },
                        // {
                        //     userId: 1819,
                        //     "senderProfile": {
                        //         "platformType": 0,
                        //         "avatarUrl": "",
                        //         "originalAvatarUrl": "",
                        //         "displayName": "Guest-4019",
                        //         "accountName": "Guest-4019",
                        //         "profileDeepLink": ""
                        //     },
                        //     isOpen: true,
                        //     startTime: 1706685529,
                        //     endTime: 1706685529,
                        //     coinType: 0, // 1 matic 0 lfg
                        //     redPacketId: "2",
                        // },
                        // {
                        //     userId: 1819,
                        //     "senderProfile": {
                        //         "platformType": 0,
                        //         "avatarUrl": "",
                        //         "originalAvatarUrl": "",
                        //         "displayName": "Guest-4019",
                        //         "accountName": "Guest-4019",
                        //         "profileDeepLink": ""
                        //     },
                        //     isOpen: true,
                        //     startTime: 1706685529,
                        //     endTime: 1706685529,
                        //     coinType: 0, // 1 matic 0 lfg
                        //     redPacketId: "2",
                        // },
                        // {
                        //     userId: 1819,
                        //     "senderProfile": {
                        //         "platformType": 0,
                        //         "avatarUrl": "",
                        //         "originalAvatarUrl": "",
                        //         "displayName": "Guest-4019",
                        //         "accountName": "Guest-4019",
                        //         "profileDeepLink": ""
                        //     },
                        //     isOpen: true,
                        //     startTime: 1706685529,
                        //     endTime: 1706685529,
                        //     coinType: 0, // 1 matic 0 lfg
                        //     redPacketId: "2",
                        // },
                        // {
                        //     userId: 1819,
                        //     "senderProfile": {
                        //         "platformType": 0,
                        //         "avatarUrl": "",
                        //         "originalAvatarUrl": "",
                        //         "displayName": "Guest-4019",
                        //         "accountName": "Guest-4019",
                        //         "profileDeepLink": ""
                        //     },
                        //     isOpen: true,
                        //     startTime: 1706685529,
                        //     endTime: 1706685529,
                        //     coinType: 0, // 1 matic 0 lfg
                        //     redPacketId: "2",
                        // }
                    ],
                }
            })

            return;
        }

        http('post', url.redpacket_list, {
            tarUserId: parseInt(userId),
        }).then((res) => {
            if (res.code === 0) {
                resolve(res)
            } else {
                console.log("post_red_packet_status body: " + res)
                reject(res.code)
            }
        }).catch(e => {
            console.error("post_red_packet_status err", e)
            reject('network error')
        });
    })
}

export const post_red_packet_detail = (redPacketId) => {
    return new Promise((resolve, reject) => {
        // mock
        if (mock) {
            resolve({
                "code": 0,
                "data": {
                    senderId: 1819,
                    "senderProfile": {
                        "platformType": 0,
                        "avatarUrl": "",
                        "originalAvatarUrl": "",
                        "displayName": "Guest-4019",
                        "accountName": "Guest-4019",
                        "profileDeepLink": ""
                    },
                    startTime: 1706685529,
                    endTime: 1806685529,
                    coinType: 1, // 1 matic 0 lfg
                    redPacketId: "1",
                    isOpen: true,
                    openedAmount: "5",
                    amountLeft: 10,
                    amountTotal: 100,
                    pickNumLeft: 10,
                    pickNumTotal: 20,
                    pickList: [
                        {
                            userId: 1819,
                            amount: "5",
                            time: 1706685529,
                            "profile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-401911111111111111111222222",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                        },
                        {
                            userId: 1819,
                            amount: 5,
                            time: 1706685529,
                            "profile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019111111111",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                        },
                        {
                            userId: 1819,
                            amount: 5,
                            time: 1706685529,
                            "profile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                        },
                        {
                            userId: 1819,
                            amount: 5,
                            time: 1706685529,
                            "profile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                        },
                        {
                            userId: 1819,
                            amount: 5,
                            time: 1706685529,
                            "profile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                        },
                        {
                            userId: 1819,
                            amount: 5,
                            time: 1706685529,
                            "profile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                        },
                        {
                            userId: 1819,
                            amount: 5,
                            time: 1706685529,
                            "profile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                        },
                        {
                            userId: 1819,
                            amount: 5,
                            time: 1706685529,
                            "profile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                        },
                        {
                            userId: 1819,
                            amount: 5,
                            time: 1706685529,
                            "profile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                        },
                        {
                            userId: 1819,
                            amount: 5,
                            time: 1706685529,
                            "profile": {
                                "platformType": 0,
                                "avatarUrl": "",
                                "originalAvatarUrl": "",
                                "displayName": "Guest-4019",
                                "accountName": "Guest-4019",
                                "profileDeepLink": ""
                            },
                        }
                    ]
                }
            })

            return;
        }

        http('post', url.redpacket_detail, {
            redPacketId: redPacketId,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res)
            } else {
                console.log("post_red_packet_detail body: " + res)
                reject(res.code)
            }
        }).catch(e => {
            console.error("post_red_packet_detail err", e)
            reject('network error')
        });
    })
}

export const post_red_packet_expire = (userId) => {
    return new Promise((resolve, reject) => {
        // mock
        if (mock) {
            resolve({
                "code": 0,
                "data": {
                    "leftLfg": "5000",
                    "leftMatic": "0",
                    "redPacketIdList": ["1", "2", "3"],
                }
            })

            return;
        }

        http('post', url.redpacket_expire, {}).then((res) => {
            if (res.code === 0) {
                resolve(res)
            } else {
                console.log("redpacket_expire body: " + res)
                reject(res.code)
            }
        }).catch(e => {
            console.error("redpacket_expire err", e)
            reject('network error')
        });
    })
}

export function redPacketPick(wallets, _packId) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {lotteryAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

            const provider = await embeddedWallet.getEthersProvider();

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(lotteryAddr, abi_red_packet, provider).connect(signer);

            contract.populateTransaction.pickRedPack(_packId, await getGasConfig(signer)).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function lfgApprove(wallets, amount) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {lfgTokenAddr, lotteryAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

            const provider = await embeddedWallet.getEthersProvider();

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(lfgTokenAddr, abi_token, provider).connect(signer);

            contract.populateTransaction.approve(lotteryAddr, ethers.utils.parseEther(amount.toString()), await getGasConfig(signer)).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}


export function redPacketDeposit(wallets, hostAddr, coinType, tokenTotal, pickTotal, startTime) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {lotteryAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

            const provider = await embeddedWallet.getEthersProvider();

            const signer = provider.getSigner(); // ethers signer object

            const gasData = await getGasConfig(signer);

            const contract = new ethers.Contract(lotteryAddr, abi_red_packet, provider).connect(signer);

            let v = 0;
            if (coinType === 1) {
                v = ethers.utils.parseEther(tokenTotal);
            }

            contract.populateTransaction.depositRedPack(hostAddr, coinType, ethers.utils.parseEther(tokenTotal.toString()), pickTotal, startTime, {
                ...gasData,
                value: v,
            }).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

export function redPacketWithdraw(wallets, _packIds) {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const {lotteryAddr, chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);

            console.log(embeddedWallet);

            const provider = await embeddedWallet.getEthersProvider();

            const signer = provider.getSigner(); // ethers signer object

            const contract = new ethers.Contract(lotteryAddr, abi_red_packet, provider).connect(signer);

            contract.populateTransaction.batchWithdrawRedPack(_packIds, await getGasConfig(signer)).then(unsignedTx => {
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}
