import React, {memo, useState, useMemo, useEffect} from "react";
import s from "./index.module.less";
import {Input, Button, Modal} from "antd";
import {
    unit, 
    siteName, 
    stringToNumber, 
    chainName, 
    LFG,
    getWeb3Config,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import WithdrawDialog from "../WithdrawDialog";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import { lfgTransfer } from "@/utils/lfgStake";
import {useWallets} from "@privy-io/react-auth";
import {
    isOnlineEnv,
    LfgMainnetId,
    LfgTestnetId,
    PolygonChainId,
    AmoyChainId,
} from "@/utils/env";

const ModelLfgTransfer = (
    {
        setShowLfgTransferPanel,
        userInfo,
        getearninfoFn,
        lfgTokenInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const [toAddr, setToAddr] = useState('');
    const [amount, setAmount] = useState(0);
    const {walletAddr} = userInfo || {};
    const [subloading,setSubloading] = useState(false)
    const [done,setDone] = useState(false)
    const [errText,setErrText] = useState('')
    const [lfgContractAddr, setLfgContractAddr] = useState(null);
    const toAddrChangeHandler = (event) => {
        setToAddr(event.target.value)
    }
    const amountChangeHandler = (e) => {
        let value = e.target.value.replace(/[^\d-.]/g, '');
        setAmount(value)
    }
    const amountBlurHandler = (e) => {
        setAmount(Math.min(amount, lfgTokenInfo?.balance || 0))
    }
    const ready = useMemo(() => {
        return toAddr && amount;
    }, [toAddr, amount])

    const getLfgContractInfo = async () =>{
        const web3Config = await getWeb3Config();
        const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === lfgTokenInfo?.ID));
        setLfgContractAddr(contractInfo?.coinContract);
    }

    useEffect(() => {
        getLfgContractInfo();
    }, [])

    const transferFn = () => {
        if (!lfgTokenInfo){
            console.error("[transfer lfg]chain id null");
            return;
        }
        if (!lfgContractAddr){
            console.error("[transfer lfg]lfg contract address is null");
            return;
        }
        if (!(amount && toAddr) || subloading) return;
        setSubloading(true);
        lfgTransfer(wallets,toAddr,amount, lfgTokenInfo?.chainId, lfgContractAddr).then(res => {
            console.log(res);
            setDone(true)
            setSubloading(false);
        }).catch(e => {
            setErrText(t('Transfer Failed'))
            setSubloading(false);
            setDone(true)
            console.log(e);
        })
    }
    const reset = ()=>{
        setDone(false)
        setSubloading(false);
        if(errText){
            setErrText('')
        }else{
            setShowLfgTransferPanel(false)
        }
    }

    return (
        <div className={s.wrap}>
            {/*<div className={s.info}>
        Transfer {unit} from your {siteName} wallet to another wallet on {chainName}.
      </div>*/}
            <div className={s.inputWrap}>
                <Input placeholder={t('Enter Your Wallet Address')} value={toAddr} onChange={toAddrChangeHandler}/>
            </div>
            <div className={s.inputWrap}>
                <Input placeholder={t('Enter amount')} value={amount} onChange={amountChangeHandler}
                       onBlur={amountBlurHandler}/>
            </div>
            <div className={s.balance}>{t('Your balance:')} <span className="color-yellow">{stringToNumber(lfgTokenInfo?.balance || 0, 4)}</span> {LFG}</div>
            <div style={{height: 20}}></div>
            {
                done ?
                <ButtonFactory disabled={!ready}
                            onClick={reset}
                            className="modelBottomBtn"
                >{errText?errText:t('Transfer Success')}</ButtonFactory>
                :<ButtonFactory disabled={!ready || subloading || !lfgTokenInfo}
                           onClick={transferFn}
                           loading={subloading}
                           className="modelBottomBtn"
            >{subloading?t('loading'):t('Transfer')}</ButtonFactory>
            }
            {/* </div> */}
            {/* <div className={s.tips} onClick={()=>{setShowWithdrawPanel(false)}}>Close &nbsp;&nbsp;<CustomIcon  width={22} height={18} imgName={'UI_TT_Icon_JianTou'} name={'UI_TT_Icon_JianTou'}/></div> */}
        </div>
    )
}
export default memo(ModelLfgTransfer);
