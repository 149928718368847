import React, {memo, useMemo, useEffect, useState} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {
    getkeyhistoryself,
    getkeyhistoryfriends,
    getkeyhistoryglobal,
    stringToNumber,
    unit,
    sliceStr,
    joinChat, vCoinString,
    getAvatarFromProfile
} from "@/utils/common"
import AvatarInfo from "../AvatarInfo";
import CustomIcon from "../CustomIcon";
import {useNavigate} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';

const keysFn = {
    self: getkeyhistoryself,
    friends: getkeyhistoryfriends,
    global: getkeyhistoryglobal,
}
const TranList = (
    {
        type,
        tranList,
        setTranList,
        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const timeFn = (time) => {
        let d = parseInt(time / 60 / 60 / 24);
        let h = parseInt(time / 60 / 60 % 24);
        let m = parseInt(time / 60 % 60);
        let str = '';
        if (d > 0) {
            str = d + "d";
            return str
        }
        if (h > 0) {
            str = h + "h";
            return str
        }
        if (m > 0) {
            str = m + "m";
            return str
        }
        return t('few seconds');

        // return `${d}${h}${m}`
    }
    const {userId} = userInfo;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const getDefaultData = () => {

        keysFn[type](0).then(res => {
            const {nextCursor} = res || -1;
            setLoading(false);
            setTranList({
                type, data: {
                    list: res?.txlist,
                    nextCursor
                }
            })
        }).catch(e => {
            setLoading(false);
            console.log(e);
        })
    }
    useEffect(() => {
        getDefaultData()
    }, [type])
    const list = useMemo(() => {
        return tranList[type]?.list || []
    }, [tranList])
    const nextCursor = useMemo(() => {
        return tranList[type]?.nextCursor || -1
    }, [tranList])

    const getMore = () => {
        getkeyhistoryglobal(tranList[type]?.nextCursor).then(res => {
            const {nextCursor} = res || -1;
            setTranList({
                type, data: {
                    list: [
                        ...tranList[type].list,
                        ...res?.txlist,
                    ],
                    nextCursor
                }
            })
        }).catch(e => {
            console.log(e);
        })
    }
    const reFresh = () => {
        getDefaultData()
    }
    const toTranD = (userId, accountType) => {
        joinChat(userId, navigate, accountType);
    }
    return (
        <div className={s.wrap}>

            {
                !loading ?
                    <div className={s.content}>

                        {
                            list.length > 0 ? list.map((item, ind) => {
                                const {
                                    amount,
                                    time,
                                    txType,
                                    totalPrice,
                                    keyId,
                                    userId,
                                    keyAccountType,
                                    userAccountType,
                                    lfgBalance
                                } = item;
                                const {keyProfile} = item;
                                const {userProfile} = item;
                                let type = item?.type;
                                if (!type) type = 0;
                                return (
                                    <div className={`${s.item} ${s.itemBg}`} key={ind}>
                                        <div className={s.tranAvatar}>
                                            <div className={s.onwerAvatar} onClick={() => {
                                                toTranD(userId, userAccountType)
                                            }}>
                                                <AvatarInfo size={40} src={getAvatarFromProfile(userProfile)}/>
                                            </div>
                                            <div className={s.sponsorAvatar} onClick={() => {
                                                toTranD(keyId, keyAccountType)
                                            }}>
                                                <AvatarInfo size={40} src={getAvatarFromProfile(keyProfile)}/>
                                            </div>
                                        </div>
                                        <div className={s.tranInfo}>
                                            <div className={`${s.info} fs14`}>
                                                {type === 0 ? <>
                                                        {
                                                            txType === 1 ?
                                                                <Trans
                                                                    i18nKey="xx bought aa yy sth"
                                                                    defaults="<user>{{name1}}</user> <gray>bought</gray> <fb>{{count}}</fb> <fbClick>{{name2}}</fbClick> <gray>{{sth}}<gray>"
                                                                    values={{
                                                                        name1: sliceStr(userProfile.displayName, 'all'),
                                                                        name2: sliceStr(keyProfile.displayName, 'all'),
                                                                        count: amount,
                                                                        sth: keyAccountType === 'wish' ? 'wish' : 'key'
                                                                    }}
                                                                    components={{
                                                                        user: <span className="color-white" onClick={() => {
                                                                            toTranD(userId, userAccountType)
                                                                        }}></span>,
                                                                        gray: <span className="color-blue-light"></span>,
                                                                        fb: <span className="color-white"></span>,
                                                                        fbClick: <span className="color-white" onClick={() => {
                                                                            toTranD(keyId, keyAccountType)
                                                                        }}></span>
                                                                    }}
                                                                /> :
                                                                <Trans
                                                                    i18nKey="xx sold aa yy sth"
                                                                    defaults="<user>{{name1}}</user> <gray>sold</gray> <fb>{{count}}</fb> <fbClick>{{name2}}</fbClick> <gray>{{sth}}<gray>"
                                                                    values={{
                                                                        name1: sliceStr(userProfile.displayName, 'all'),
                                                                        name2: sliceStr(keyProfile.displayName, 'all'),
                                                                        count: amount,
                                                                        sth: keyAccountType === 'wish' ? 'wish' : 'key'
                                                                    }}
                                                                    components={{
                                                                        user: <span className="color-white" onClick={() => {
                                                                            toTranD(userId, userAccountType)
                                                                        }}></span>,
                                                                        gray: <span className="color-blue-light"></span>,
                                                                        fb: <span className="color-white"></span>,
                                                                        fbClick: <span className="color-white" onClick={() => {
                                                                            toTranD(keyId, keyAccountType)
                                                                        }}></span>
                                                                    }}
                                                                />
                                                        }
                                                    </> :
                                                    type === 1 || type === 2 ? <>
                                                            <Trans
                                                                i18nKey="name1 hired by name2 wish/key"
                                                                defaults="<click1>{{name1}}</click1> hired by <click2>{{name2}}</click2> {{sth}}"
                                                                values={{
                                                                    name1: sliceStr(userProfile.displayName, 'all'),
                                                                    name2: sliceStr(keyProfile.displayName, 'all'),
                                                                    sth: keyAccountType === 'wish' ? 'wish' : 'key'
                                                                }}
                                                                components={{
                                                                    click1: <span className="color-white" onClick={() => {
                                                                        toTranD(userId, userAccountType)
                                                                    }}></span>,
                                                                    click2: <span className="color-white" onClick={() => {
                                                                        toTranD(keyId, keyAccountType)
                                                                    }}></span>
                                                                }}
                                                            />
                                                        </> :
                                                        type === 3 ? <>
                                                                <Trans
                                                                    i18nKey="name1 finished work name2 wish/key"
                                                                    defaults="<click1>{{name1}}</click1> finished work <click2>{{name2}}</click2> {{sth}}"
                                                                    values={{
                                                                        name1: sliceStr(userProfile.displayName, 'all'),
                                                                        name2: sliceStr(keyProfile.displayName, 'all'),
                                                                        sth: keyAccountType === 'wish' ? 'wish' : 'key'
                                                                    }}
                                                                    components={{
                                                                        click1: <span className="color-white" onClick={() => {
                                                                            toTranD(userId, userAccountType)
                                                                        }}></span>,
                                                                        click2: <span className="color-white" onClick={() => {
                                                                            toTranD(keyId, keyAccountType)
                                                                        }}></span>
                                                                    }}
                                                                />
                                                            </> :
                                                            type === 4 || type === 5 ? <>
                                                                <Trans
                                                                    i18nKey="name1 claimed name2 wish/key"
                                                                    defaults="<click1>{{name1}}</click1> claimed <click2>{{name2}}</click2> {{sth}}"
                                                                    values={{
                                                                        name1: sliceStr(userProfile.displayName, 'all'),
                                                                        name2: sliceStr(keyProfile.displayName, 'all'),
                                                                        sth: keyAccountType === 'wish' ? 'wish' : 'key'
                                                                    }}
                                                                    components={{
                                                                        click1: <span className="color-white" onClick={() => {
                                                                            toTranD(userId, userAccountType)
                                                                        }}></span>,
                                                                        click2: <span className="color-white" onClick={() => {
                                                                            toTranD(keyId, keyAccountType)
                                                                        }}></span>
                                                                    }}
                                                                />
                                                            </> : ''}
                                            </div>
                                            <div className={s.price}>
                                                {type === 0 && <>
                                                    <div className="color-purple">{stringToNumber(totalPrice, 4)} {unit}</div>
                                                    <span
                                                    className="color-blue-light">{`, ${t('xx ago', {time: timeFn(time)})}`}</span>
                                                </>}
                                                {type === 1 || type === 4 && <>
                                                    <Trans
                                                        i18nKey="count work profit claimed, xx ago"
                                                        defaults="<ca>{{count}} work profit claimed<ca><gray>, {{time}} ago</gray>"
                                                        values={{
                                                            count: vCoinString(lfgBalance),
                                                            time: timeFn(time)
                                                        }}
                                                        components={{
                                                            ca: <div className="color-blue-light"></div>,
                                                            gray: <span className="color-blue-light"></span>
                                                        }}
                                                    />
                                                </>}
                                                {type === 3 && <>
                                                    <Trans
                                                        i18nKey="count profit produced, xx ago"
                                                        defaults="<ca>{{count}} profit produced</ca><gray>, {{time}} ago</gray>"
                                                        values={{
                                                            count: vCoinString(lfgBalance),
                                                            time: timeFn(time)
                                                        }}
                                                        components={{
                                                            ca: <div className="color-blue-light"></div>,
                                                            gray: <span className="color-blue-light"></span>
                                                        }}
                                                    />
                                                </>}
                                                {type === 5 && <>
                                                    <Trans
                                                        i18nKey="count key sharing claimed, xx ago"
                                                        defaults="<ca>{{count}} key sharing claimed</ca><gray>, {{time}} ago</gray>"
                                                        values={{
                                                            count: vCoinString(lfgBalance),
                                                            time: timeFn(time)
                                                        }}
                                                        components={{
                                                            ca: <div className="color-blue-light"></div>,
                                                            gray: <span className="color-blue-light"></span>
                                                        }}
                                                    />
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <div className="fs-12">{t('No Data')}</div>
                        }
                        <div className={`${s.loadMore} ${nextCursor !== -1 ? s.show : ''}`}
                             onClick={getMore}>{t('Load More')}</div>
                    </div> :
                    <div className="">{t('Loading...')}</div>
            }
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        activeInd: app.activeInd,
        tranList: app.tranList,
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveInd: (params) => {
            return dispatch({
                type: "app/setActiveInd",
                payload: params,
            });
        },
        setTranList: (params) => {
            return dispatch({
                type: "app/setTranList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(TranList));
