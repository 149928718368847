import React, {useMemo, useEffect, memo, useState, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button, Modal, Checkbox} from "antd";
import {
    getearninfo,
    formatNumberWithUnit,
    vCoinString,
} from "@/utils/common";
import {connect} from "react-redux";
import CustomIcon from "../../common/CustomIcon";
import ButtonFactory from "src/common/ButtonFactory";

const ModalCostLfgType = (
    {
        closeFn,
        userInfo,
        earnInfo,
        costLfgType,
        setCostLfgType,
        setShowGasMode,
    }
) => {
    const {t, i18n} = useTranslation();
    const [checkType, setCheckType] = useState(localStorage.getItem('sel_born_type') || 'chain');
    const {walletAddr, selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {balance, lfgBalance} = useMemo(() => {
        return selfData || {}
    }, [selfData]);

    useEffect(() => {
        getearninfo().then(res => {
        }).catch(e => {
            console.log(e);
        })

        if (costLfgType === 0) {
            setCheckType('chain');
        } else {
            setCheckType('pool');
        }
    }, [])

    const {
        claimCoin,
    } = useMemo(() => {
        return earnInfo || {}
    }, [earnInfo])

    const selFn = (type) => {
        localStorage.setItem('sel_born_type', type)
        setCheckType(type)
    }

    const confirmCostLfgType = () => {
        let costType = checkType === 'pool' ? 1 : 0;
        setCostLfgType(costType);
        closeFn();
    }

    return (
        <div className={s.content}>
            <div className={s.amountWrap}>
                <div className="color-white w100p tll">{t('Set your default Loot Fee from:')}</div>
                <div className={s.t}>
                    <div className={s.i}>
                        <div>
                            <Checkbox onClick={() => selFn('chain')}
                                      className="hireCheckBox"
                                      checked={checkType === 'chain'}
                            />
                        </div>
                        <div className={s.label}>{t('$LFG Wallet')}</div>
                        <div className={s.val}>
                            <CustomIcon className="m02" width={20} height={20}
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            <div className={s.num}>{vCoinString(lfgBalance)}</div>
                        </div>
                    </div>
                    <div className={s.i}>
                        <div>
                            <Checkbox onClick={() => selFn('pool')}
                                      className="hireCheckBox"
                                      checked={checkType === 'pool'}
                            />
                        </div>
                        <div className={s.label}>{t('$LFG Pool')}</div>
                        <div className={s.val}>
                            <CustomIcon className="m02" width={20} height={20}
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>
                            <div className={s.num}>{formatNumberWithUnit(parseInt(claimCoin))}</div>
                        </div>
                    </div>
                </div>

            </div>
            <div style={{height: '10px'}}></div>
            <div className="modelBottomBtn">
                <ButtonFactory onClick={() => {
                    confirmCostLfgType();
                }}>
                    {t('CONFIRM')}
                </ButtonFactory>
            </div>
            <CustomIcon width={30} height={30}
                        className="modelBottomSetGas"
                        imgName={'Button/UI_Button_Gas_01'}
                        onClick={() => {
                            setShowGasMode(true);
                        }}
            />
        </div>
    );
};
const mapStateToProps = ({app}) => {
    return {
        earnInfo: app.earnInfo,
        userInfo: app.userInfo,
        costLfgType: app.costLfgType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCostLfgType: (params) => {
            return dispatch({
                type: "app/setCostLfgType",
                payload: params,
            });
        },
        setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalCostLfgType));
