import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { Slider } from "antd";
import { Bullet } from "@ant-design/charts";
import { AutoStrangeRule } from "@/utils/strangeRule";
import memeSettingValue from "@/utils/json/memeSettingValue.json"
import AntModal from "@/common/AntModal";
import ModelEditVal from "@/model/ModelEditVal";



const liquidityPoolLimitData = memeSettingValue.find(item => item?.value === 'liquidityPoolLimit')



const Price = (
    {
        activeCurve,setActiveCurve,
        paramA, setParamA,
        paramB, setParamB,
        cardTotalSupply,setCardTotalSupply,
        totalCardValue,
        memePriceFormular,
        cardTotalSupplyData,
        selCreatChain,
        paramAMin,
        paramAMax,
        setParamAMin,
        setParamAMax,
        paramBPercent,
        paramAPercent,
        setParamBPercent,
        setParamAPercent,
    }
) => {
    const { t, i18n } = useTranslation();
    
    const changeFormulaEnum = useRef(0)
    const [showEditModel,setShowEditModel] = useState(false)
   
    useEffect(() => {
        // console.log(`---------->`,changeFormulaEnum.current,paramA,paramAMax,paramAMin,paramAPercent,paramBPercent);
        if(changeFormulaEnum.current === 0 && paramA && paramAMax) return
        if(!(cardTotalSupply && activeCurve?.formulaEnum && liquidityPoolLimitData)) return

        const minLP = liquidityPoolLimitData?.min
        const maxLP = liquidityPoolLimitData?.max
        const ratio = activeCurve?.divParamC/activeCurve?.mulParamD/activeCurve?.ratioToUSD*10000

        let min, max;
        switch (activeCurve?.formulaEnum) {
            case 1:
                min = Math.round((2 * minLP) / (cardTotalSupply * (cardTotalSupply + 1))*ratio);
                max = Math.round((2 * maxLP) / (cardTotalSupply * (cardTotalSupply + 1))*ratio);
                break;
            case 2:
                min = Math.round((6 * minLP)/(cardTotalSupply * (cardTotalSupply + 1) * (2 * cardTotalSupply + 1))*ratio);
                max = Math.round((6 * maxLP)/(cardTotalSupply * (cardTotalSupply + 1) * (2 * cardTotalSupply + 1))*ratio);
                break;
            case 3:
                min = Math.round(minLP/cardTotalSupply*ratio);
                max = Math.round(maxLP/cardTotalSupply*ratio);
                break;
            default:
                min = 1;
                max = 2;
        }
        min = Math.max(min, 1);
        max = Math.max(max, 10);
        setParamAMin(min);
        setParamAMax(max);

        console.log(`inSet setParamAMax---------->`,min,max);
        // if(activeCurve?.formulaEnum !== 3){
        //     const {minB,maxB} = activeCurve;
        //     const newParamB = Math.max(Math.round(minB + paramBPercent * (maxB - minB)), 1);
        //     setParamB(newParamB);
        // }

        if (paramAPercent !== null) {
            const newParamA = Math.max(Math.round(min + paramAPercent * (max - min)), 1);
            setParamA(newParamA);
            const currentPercentage = (newParamA - min) / (max - min);
            setParamAPercent(currentPercentage)
            console.log(`inSet newParamA---------->`,newParamA);
        }

        if (paramBPercent !== null) {
            if(activeCurve?.formulaEnum !== 3){
                const {minB,maxB} = activeCurve
                const newParamB = Math.max(Math.round(minB + paramBPercent * (maxB - minB)), 0);
                setParamB(newParamB);
                const currentPercentage = (newParamB - minB) / (maxB - minB);
                setParamBPercent(currentPercentage)
                console.log(`inSet newParamA---------->`,newParamB);
            }
        }

       
        
        // selPreferences
            
    }, [cardTotalSupply, activeCurve]);
   
    


    return (
        <div className={`${s.box}`}>
            <div className={`${s.partTitle} mb20`}>
                <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                    imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                {AutoStrangeRule(t('Advanced Settings1'))}
            </div>
            <div className={s.wrap}>
                    
                <div className={s.label}>{t('Pack Price Curve')}</div>
                <div className={s.curve}>
                    {memePriceFormular?.map(item=>{
                        const {ID,defalutA,minA,maxA,defalutB,minB,maxB,formular,formulaEnum,totalSupply} = item;
                        return (
                            <div onClick={()=>{
                                setActiveCurve(item)
                                changeFormulaEnum.current += 1;
                            }} key={ID} className={`${s.item} ${activeCurve['formulaEnum'] === formulaEnum ? s.active : ''}`}>
                                <img src={`./UI/BG/UI_Background_Back_Curve0${formulaEnum}.webp`} width='100%'></img>
                            </div>
                        )
                    })}
                    
                </div>
                <div className={s.formular}>
                    {activeCurve['formular']}*{activeCurve?.ratioShow} {selCreatChain?.displayCoinName}
                </div>
                {activeCurve?.formulaEnum !== 3 && (
                    <div style={{ color: 'var(--TP-blue-light)', fontSize: '12px', justifyContent: 'flex-start', display: 'flex' }}>
                        {t('(N is next pack supply)')}
                    </div>
                )}
                
                <div className={s.param}>
                    <div className={s.r}>
                        {
                            activeCurve?.defaultA !== -1 && 
                            <div className={s.paramI}>
                                <div className={s.des}>Adjust A:{paramA}</div>
                                <Slider
                                    className="hireSlider commonSlider memeClubSlider"
                                    min={paramAMin}
                                    max={paramAMax}
                                    value={paramA}
                                    onChange={(value) => {
                                        setParamA(value);
                                        setParamAPercent((value - paramAMin) / (paramAMax - paramAMin))
                                    }}/>
                            </div>
                        }
                        {
                            activeCurve?.defaultB !== -1 && 
                            <div className={s.paramI}>
                                <div className={s.des}>Adjust B:{paramB}</div>
                                <Slider
                                    className="hireSlider commonSlider memeClubSlider"
                                    min={activeCurve?.minB}
                                    max={activeCurve?.maxB}
                                    value={paramB}
                                    onChange={(value) => {
                                        setParamB(value);
                                        setParamBPercent((value - activeCurve?.minB) / (activeCurve?.maxB - activeCurve?.minB))
                                    }}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={`${s.partTitle} mb20`}>
                <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                    imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                {AutoStrangeRule(t('Total Pack Supply'))}
            </div>
            <div className={s.wrap}>
                <div className={s.tips}>
                    {t('TGE Once')} &nbsp; 
                    <span style={{color:'var(--white)'}} > {cardTotalSupply} </span>
                    
                    &nbsp;{t('packs are sold')}</div>
                <div className="flex_center_center">
                    <Slider
                        className="hireSlider commonSlider memeClubSlider"
                        min={cardTotalSupplyData?.min}
                        max={cardTotalSupplyData?.max}
                        value={cardTotalSupply}
                        onChange={(value) => {
                            changeFormulaEnum.current += 1
                            setCardTotalSupply(value);
                        }}/>
                    <CustomIcon onClick={() => {setShowEditModel(true)}} height={25} width={25} className="ml15" imgName="Button/UI_Picture_Settings_Edit_01" ></CustomIcon>
                </div>
                <div className={s.label}>
                    <span className="color-blue-light" >
                        {t('Total Card Value:')} &nbsp;
                        </span>
                    <span className="color-white">{totalCardValue} {selCreatChain?.displayCoinName}</span></div>
            </div>
            <AntModal
                width='330px'
                title={t('Edit')}
                className="confirmModalWrap"
                centered={true}
                open={showEditModel}
                onOk={() => setShowEditModel(false)}
                onCancel={() => setShowEditModel(false)}
            >
                <ModelEditVal title={`${t('Total Pack Supply')} :`} onClose={() => setShowEditModel(false)} val={cardTotalSupply} setVal={setCardTotalSupply} min={cardTotalSupplyData?.min} max={cardTotalSupplyData?.max} />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Price));
