import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Slider, Checkbox} from "antd";
import {unit} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";

const BidFilter = (
    {
        closeHandle,
        maxRank = 2000,
        filterRank,
        setFilterRank,
        filterLFGPrice,
        setFilterLFGPrice,
        filterMaticPrice,
        setFilterMaticPrice,
        filterIncludeTopPriceIsSelf,
        setFilterIncludeTopPriceIsSelf,
        applyFilter,
        isHideFilterIncludeTopPriceIsSelf = false
    }
) => {
    const {t, i18n} = useTranslation();
    const minRank = 1;
    const [sliderCurrentFilterRank, setSliderCurrentFilterRank] = useState([filterRank?.[0] !== null ? filterRank?.[0] : minRank, filterRank?.[1] !== '-1' ? filterRank?.[1] : maxRank]);
    const minPrice = 0;
    const maxPrice = 200000;
    const [sliderCurrentFilterPrice, setSliderCurrentFilterPrice] = useState([filterLFGPrice?.[0] !== null ? filterLFGPrice?.[0] : minPrice, filterLFGPrice?.[1] !== '-1' ? filterLFGPrice?.[1] : maxPrice]);
    const minMaticPrice = 0;
    const maxMaticPrice = 2000;
    const [sliderCurrentFilterMaticPrice, setSliderCurrentFilterMaticPrice] = useState([filterMaticPrice?.[0] !== null ? filterMaticPrice?.[0] : minMaticPrice, filterMaticPrice?.[1] !== '-1' ? filterMaticPrice?.[1] : maxMaticPrice]);
    const [includeTopPriceIsSelf, setIncludeTopPriceIsSelf] = useState(filterIncludeTopPriceIsSelf || false)
    const onChangeRank = (value) => {
        setSliderCurrentFilterRank(value);
    }
    const onChangePrice = (value) => {
        setSliderCurrentFilterPrice(value);
    }
    const onChangeMaticPrice = (value) => {
        setSliderCurrentFilterMaticPrice(value);
    }

    const apply = () => {
        setFilterRank(sliderCurrentFilterRank);
        let tempFilterPrice = [];
        if (sliderCurrentFilterPrice?.[0] !== undefined && sliderCurrentFilterPrice?.[0] !== null) {
            tempFilterPrice.push(sliderCurrentFilterPrice?.[0]);
        }
        if (sliderCurrentFilterPrice?.[1] < maxPrice) {
            tempFilterPrice.push(sliderCurrentFilterPrice?.[1]);
        }
        if (sliderCurrentFilterPrice?.[1] == maxPrice) {
            tempFilterPrice.push('-1');
        }
        setFilterLFGPrice(tempFilterPrice);
        let tempFilterMaticPrice = [];
        if (sliderCurrentFilterMaticPrice?.[0] !== undefined && sliderCurrentFilterMaticPrice?.[0] !== null) {
            tempFilterMaticPrice.push(sliderCurrentFilterMaticPrice?.[0]);
        }
        if (sliderCurrentFilterMaticPrice?.[1] < maxMaticPrice) {
            tempFilterMaticPrice.push(sliderCurrentFilterMaticPrice?.[1]);
        }
        if (sliderCurrentFilterMaticPrice?.[1] == maxMaticPrice) {
            tempFilterMaticPrice.push('-1');
        }
        console.log(tempFilterPrice, tempFilterMaticPrice);
        setFilterMaticPrice(tempFilterMaticPrice);
        setFilterIncludeTopPriceIsSelf(includeTopPriceIsSelf)
        applyFilter(sliderCurrentFilterRank, tempFilterPrice, tempFilterMaticPrice, includeTopPriceIsSelf);

    }

    const resetFilter = () => {
        setSliderCurrentFilterRank([minRank, maxRank]);
        setSliderCurrentFilterPrice([minPrice, maxPrice]);
        setSliderCurrentFilterMaticPrice([minMaticPrice, maxMaticPrice]);
        setIncludeTopPriceIsSelf(true)
    }

    const formatShowPrice = (price) => {
        if (price >= 1000000) {
            return `${price / 1000000}m`;
        } else if (price >= 1000) {
            return `${price / 1000}k`;
        } else {
            return price;
        }
    }

    const onChangeIncludeTopPriceIsSelf = (e) => {
        setIncludeTopPriceIsSelf(e.target.checked)
    }

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <CustomIcon width={25} className="mr5" height={25} imgName={'Button/UI_Button_ThreeTieRod_01'}
                            name={'Button/UI_Button_ThreeTieRod_01'}/> {AutoStrangeRule(t('FILTERS'))}
            </div>
            <div className={s.filterCon}>
                <div className={s.item}>
                    <div className={s.itemTitle}>
                        <div className={s.label}>{t('Rank from')}:</div>
                        <div className={s.rankArea}>
                            <span className="color-white">{sliderCurrentFilterRank?.[0] || minRank}</span>
                            <span className="fs12"> {t('to')} </span>
                            <span className="color-white">{sliderCurrentFilterRank?.[1] || maxRank}</span>
                        </div>
                    </div>
                    <div className={s.slider}>
                        <Slider
                            defaultValue={sliderCurrentFilterRank}
                            className="hireSlider commonSlider"
                            range={true}
                            min={minRank}
                            max={maxRank}
                            value={sliderCurrentFilterRank}
                            step={Math.floor(maxRank / 200)}
                            onChange={onChangeRank}/>
                    </div>
                </div>
                <div className={s.item}>
                    <div className={s.itemTitle}>
                        <div className={s.label}>{t('$LFG Price from')}: &nbsp;</div>
                        <div className={s.rankArea}>
                            <span
                                className="color-white">{formatShowPrice(sliderCurrentFilterPrice?.[0]) || 0}</span>
                            <span className="fs12"> {t('to')} </span>
                            <span
                                className="color-white">{sliderCurrentFilterPrice?.[1] == maxPrice ? `${formatShowPrice(maxPrice)}+` : formatShowPrice(sliderCurrentFilterPrice?.[1])}</span>
                        </div>
                    </div>
                    <div className={s.slider}>
                        <Slider
                            defaultValue={sliderCurrentFilterPrice}
                            className="hireSlider commonSlider"
                            range={true}
                            min={minPrice}
                            max={maxPrice}
                            value={sliderCurrentFilterPrice}
                            step={10000}
                            onChange={onChangePrice}/>
                    </div>
                </div>
                <div className={s.item}>
                    <div className={s.itemTitle}>
                        <div className={s.label}>{t('$MATIC Price from')}: &nbsp;</div>
                        <div className={s.rankArea}>
                            <span
                                className="color-white">{formatShowPrice(sliderCurrentFilterMaticPrice?.[0]) || 0}</span>
                            <span className="fs12"> {t('to')} </span>
                            <span
                                className="color-white">{sliderCurrentFilterMaticPrice?.[1] == maxMaticPrice ? `${formatShowPrice(maxMaticPrice)}+` : formatShowPrice(sliderCurrentFilterMaticPrice?.[1])}</span>
                        </div>
                    </div>
                    <div className={s.slider}>
                        <Slider
                            defaultValue={sliderCurrentFilterMaticPrice}
                            className="hireSlider commonSlider"
                            range={true}
                            min={minMaticPrice}
                            max={maxMaticPrice}
                            value={sliderCurrentFilterMaticPrice}
                            step={10}
                            onChange={onChangeMaticPrice}/>
                    </div>
                </div>
                {!isHideFilterIncludeTopPriceIsSelf && <div className={s.item}>
                    <Checkbox onChange={onChangeIncludeTopPriceIsSelf} className="hireCheckBox"
                              checked={includeTopPriceIsSelf} defaultChecked={includeTopPriceIsSelf}>
                        <span className="ml10">{t('Ignore Leading Auction')}</span>
                    </Checkbox>
                </div>}
                {/* includeTopPriceIsSelf */}
            </div>
            <div className={s.bottom}>
                <AntButton className={s.black} onClick={() => {
                    resetFilter();
                }}>{t('RESET')}</AntButton>
                <AntButton className="btn_public" onClick={() => {
                    apply();
                }}>{t('APPLY')}</AntButton>
            </div>

        </div>
    )
}
export default memo(BidFilter);
