import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
    stringToNumber,
    convertWalletBalance,
    sliceStr,
    getAvatarFromProfile,
    getTokenBalanceOf,
    copyFn,
    sliceAddr,
    logoutSelf,
    getWeb3Config,
    percentStrToNum,
    getVideoUrlFromMap,
} from "@/utils/common"
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";

import { Tabs, Drawer } from "antd";
import { 
    momentClubInfo, 
    getChainInfo, 
    setFavorite, 
    moment_tradelog, 
    video_play_token,
    momentClubList,
    defaultMemeSwitchVideoParams,
    momentTgeClublist,
    getMomentTgeCanopenList,
    userTabList,
} from "@/utils/momentClub";
import { AutoStrangeRule } from "@/utils/strangeRule";
import ModelTrans from "@/model/ModelTrans";
import ModalMemePackTrans from "@/model/ModalMemePackTrans";
import {
    gameMomentClubChatPath,
    gameMomentClubCreatPath,
    gameMomentClubDetail,
    gameMomentClubDetailPath,
    gameMomentClubRevealMomentPackPath,
    gameMomentClubExplorePath,
} from "@/routes/config";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import MemeClubPack from "@/common/MemeClubPack";
import NFTs from "@/views/games/MomentClub/ClubDetail/TGE/NFTs";
import { getBalance } from "@/utils/uniswapWeb3";
import { useWallets, usePrivy } from "@privy-io/react-auth";
import momentChainInfo from "@/utils/json/momentChainInfo.json";
import ModalWalletDisconnect from "@/model/ModalWalletDisconnect";
import MobileBG from "@/assets/video/MobileBG.mp4";
import { backFn } from "@/utils/mianPublic";
import ClubVideo from "../ClubVideo";
import ClubDetail from "../ClubDetail";
import { Observer } from "gsap/Observer";

const minStartDragY = 30;
const fastSlideTime = 200;
const switchDuration = 0.25;

const switchListNeedNextFetchCount = 3;//当前club为列表倒数第x个数据的时候需要触发切页

gsap.registerPlugin(Observer);

const ClubDetailTotal = (
    {
        userInfo,

        walletBalanceList,
        setWalletBalanceList,
        currentMomentClubId,
        setCurrentMomentClubId,
        hideNSFW,
        lastVideoSwitch,
        memeclubSwitchVideoParams,
        switchVideoType,
        setSwitchVideoType,
        setLastVideoSwitch,
        switchVideoList,
        setSwitchVideoList,
        switchVideoListCursor,
        setSwitchVideoListCursor,
        videoPreloadString,
        setVideoPreloadString,
    }
) => {
    const { t, i18n } = useTranslation();
    const containerRef = useRef(null);
    const timelineRef = useRef(null);
    const observerLockRef = useRef(false);
    const videoDragRef = useRef(null);
    const { wallets } = useWallets();
    const { logout } = usePrivy()
    const [clubBasicInfo, setClubBasicInfo] = useState(null);
    const [alreadyInitSwitchList, setAlreadyInitSwitchList] = useState(false);
    const { walletAddr } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const navigate = useNavigate();
    const location = useLocation();
    const mediaRootNameList = ["mediaRoot0", "mediaRoot1", "mediaRoot2"];
    const playerRootNameList = ["player0", "player1", "player2"];
    const [playClubIdList, setPlayClubIdList] = useState([Number(currentMomentClubId || 0), 0, 0]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [videoChangeTimes, setVideoChangeTimes] = useState(0);
    const [preIndex, setPreIndex] = useState(1);
    const [nextIndex, setNextIndex] = useState(2);
    const [switchLock, setSwitchLock] = useState(false);
    const [switchDirection, setSwitchDirection] = useState(0);//1:next, -1:pre
    const [videoReady, setVideoReady] = useState(false);
    const [hiddenVideo, setHiddenVideo] = useState(false);
    const [updateCount, setUpdateCount] = useState(false);
    const [showForceMutedTip, setShowForceMutedTip] = useState(false);
    const drawerOpenRef = useRef(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [buyDrawerOpen, setBuyDrawerOpen] = useState(false);
    const [videoNo, setVideoNo] = useState(0);//-1:pre, 0:current, 1:next
    const touchDownRef = useRef(false);
    //const [touchDown, setTouchDown] = useState(false);
    const startMoveRef = useRef(false);
    const [videoStartDragY, setVideoStartDragY] = useState(0);
    const [playOrPauseVideoFlag, setPlayOrPauseVideoFlag] = useState(0);
    const [pauseVideoFlag, setPauseVideoFlag] = useState(0);
    const [touchDownTime, setTouchDownTime] = useState(-1);
    const detailRef0 = useRef(null);
    const detailRef1 = useRef(null);
    const detailRef2 = useRef(null);
    const detailRefList = [detailRef0, detailRef1, detailRef2];

    //当前播放器信息
    const [currentPlayingClubShortInfo, setCurrentPlayingClubShortInfo] = useState(null);
    //当前附近三页信息
    const [currentClubShortInfo, setCurrentClubShortInfo] = useState(null);
    const [nextClubShortInfo, setNextClubShortInfo] = useState(null);
    const [preClubShortInfo, setPreClubShortInfo] = useState(null);
    //const [switchToClubShortInfo, setSwitchToClubShortInfo] = useState(null);

    const [preloadVideoList, setPreloadVideoList] = useState(null);

    const getMomentClubInfo = (clubId) => {
        momentClubInfo(clubId).then(res => {
            setClubBasicInfo(res)
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        function keyHandler(e) {
            if (drawerOpen || observerLockRef.current) return;
            if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
                e.preventDefault();
                //console.log("KEY UP");
                
                observerLockRef.current = true;
                fastGoPreFlagRef.current = fastGoPreFlagRef.current + 1;
                setTimeout(() => {
                    observerLockRef.current = false;
                }, 1000);

            }else if(e.key === "ArrowDown" || e.key === "ArrowRight"){
                e.preventDefault();
                //console.log("KEY Down");
                
                observerLockRef.current = true;
                fastGoNextFlagRef.current = fastGoNextFlagRef.current + 1;
                setTimeout(() => {
                    observerLockRef.current = false;
                }, 1000);
            }
        }
  
        document.addEventListener("keyup", keyHandler);
  
        return () => {
            document.removeEventListener("keyup", keyHandler);
      };
    }, [])

    useEffect(() => {
        drawerOpenRef.current = drawerOpen;
    }, [drawerOpen])

    useEffect(() => {
        if (!currentClubShortInfo){
            let info = null;
            if (switchVideoList?.length > 0){
                for (let i = 0; i < switchVideoList.length; i++){
                    if (Number(switchVideoList[i]?.clubId) === Number(currentMomentClubId)){
                        info = switchVideoList[i];
                        break;
                    }
                }
            }
            if (info){
                setCurrentClubShortInfo(info);
                setCurrentPlayingClubShortInfo(info);
            }
            else{
                if (switchVideoList?.length > 0){
                    setCurrentMomentClubId(switchVideoList[0]?.clubId)
                    setCurrentClubShortInfo(switchVideoList[0]);
                    setCurrentPlayingClubShortInfo(switchVideoList[0]);
                }
            }
        }
        else{
            let info = null;
            if (switchVideoList?.length > 0){
                for (let i = 0; i < switchVideoList.length; i++){
                    if (Number(switchVideoList[i]?.clubId) === Number(currentMomentClubId)){
                        info = switchVideoList[i];
                        break;
                    }
                }
            }
            if (info){
                info.ready = true;
                setCurrentClubShortInfo(info);
                setCurrentPlayingClubShortInfo(info);
            }
        }
    }, [switchVideoList])

    useEffect(() => {
        currentMomentClubId && getMomentClubInfo(currentMomentClubId)
    }, [currentMomentClubId])

    const getMemeClubList = (params, cursor) =>{
        momentClubList({
            rankType: params.rankType,
            coinId: params.coinId,
            priceRange: params.priceRange,
            progressRange: params.progressRange,
            lpRange: params.lpRange,
            onlyFavorite: params.onlyFavorite,
            onlyHeld: params.onlyHeld,
            onlyLive: params.onlyLive,
            isAsc: params.isAsc,
            cursor: cursor
        }).then(res => {
            let _list = []; 
            if (!(cursor === '')){
                _list = [...switchVideoList];
            }
            if (res?.clubList?.length > 0){
                for (let i = 0; i < res?.clubList?.length; i++){
                    let stateCode = res?.clubList[i].profile.momentAuditCode;
                    if (stateCode !== 2 || (stateCode === 2 && !hideNSFW)){
                        _list.push({
                            clubId: res?.clubList[i].userId,
                            momentAuditCode: stateCode,
                            videoId: res?.clubList[i].videoId,
                            videoUrlMap: res?.clubList[i].videoUrlMap,
                            coverUrl: res?.clubList[i].profile.avatarUrl,
                            videoOriHeight: res?.clubList[i].videoOriHeight,
                            videoOriWidth: res?.clubList[i].videoOriWidth,
                            videoFirstFrameUrl: res?.clubList[i].videoFirstFrameUrl,
                        });
                    }
                }
            }
            setSwitchVideoList(_list);
            if (res?.nextCursor === ""){
                setSwitchVideoListCursor("-1");
            }
            else{
                setSwitchVideoListCursor(res?.nextCursor);
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const getTgeClubList = (params, cursor) => {
        const {
            rankType,
            coinId,
            priceRange,
            marketCapRange,
            onlyFavorite,
            onlyHeld,
            onlyLive,
            chainIds,
            isAsc,
        } = params || {};
        let tempPriceRange = [priceRange[0] * 1000, -1];
        if (priceRange[1] !== -1) {
            tempPriceRange[1] = Math.floor(priceRange[1] * 1000);
        }
        momentTgeClublist({
            rankType: rankType,
            coinId: coinId,
            priceRange: tempPriceRange,
            marketCapRange: marketCapRange,
            onlyFavorite: onlyFavorite,
            onlyHeld: onlyHeld,
            onlyLive: onlyLive,
            chainIds: chainIds,
            isAsc: isAsc,
            cursor: cursor
        }).then(res => {
            let _list = []; 
            if (!(cursor === '')){
                _list = [...switchVideoList];
            }
            if (res?.dataList?.length > 0){
                let clubList = res?.dataList;
                for (let i = 0; i < clubList?.length; i++){
                    _list.push({
                        clubId: clubList[i].clubUserId,
                        momentAuditCode: clubList[i].clubProfile.momentAuditCode,
                        videoId: clubList[i].videoId,
                        videoUrlMap: clubList[i].videoUrlMap,
                        coverUrl: clubList[i].clubProfile.avatarUrl,
                        videoOriHeight: clubList[i].videoOriHeight,
                        videoOriWidth: clubList[i].videoOriWidth,
                        videoFirstFrameUrl: clubList[i].videoFirstFrameUrl,
                    });
                }
            }
            setSwitchVideoList(_list);
            if (res?.nextCursor === ""){
                setSwitchVideoListCursor("-1");
            }
            else{
                setSwitchVideoListCursor(res?.nextCursor);
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const getOpenablePackList = (cursor = 0)=>{
        getMomentTgeCanopenList({cursor: cursor}).then(res=>{
            let _list = []; 
            if (!(cursor === 0)){
                _list = [...switchVideoList];
            }
            if (res?.dataList?.length > 0){
                let clubList = res?.dataList;
                for (let i = 0; i < clubList?.length; i++){
                    _list.push({
                        clubId: clubList[i].clubUserId,
                        momentAuditCode: clubList[i].clubProfile.momentAuditCode,
                        videoId: clubList[i].videoId,
                        videoUrlMap: clubList[i].videoUrlMap,
                        coverUrl: clubList[i].clubProfile.avatarUrl,
                        videoOriHeight: clubList[i].videoOriHeight,
                        videoOriWidth: clubList[i].videoOriWidth,
                        videoFirstFrameUrl: clubList[i].videoFirstFrameUrl,
                    });
                }
            }
            setSwitchVideoList(_list);
            setSwitchVideoListCursor(res?.nextCursor);
        }).catch(e=>{
            console.error(e);
        })
    }

    const getUserTabList = ({tarUserId,rankTabType=1,rankSortType=1,isAsc=true,cursor=0})=>{
        userTabList({tarUserId,rankTabType,rankSortType,isAsc,cursor}).then(res=>{
            let _list = []; 
            if (!(cursor === 0)){
                _list = [...switchVideoList];
            }
            if (res?.dataList?.length > 0){
                let clubList = res?.dataList;
                for (let i = 0; i < clubList?.length; i++){
                    _list.push({
                        clubId: clubList[i].clubUserId,
                        momentAuditCode: clubList[i].clubProfile.momentAuditCode,
                        videoId: clubList[i].videoId,
                        videoUrlMap: clubList[i].videoUrlMap,
                        coverUrl: clubList[i].clubProfile.avatarUrl,
                        videoOriHeight: clubList[i].videoOriHeight,
                        videoOriWidth: clubList[i].videoOriWidth,
                        videoFirstFrameUrl: clubList[i].videoFirstFrameUrl,
                    });
                }
            }
            setSwitchVideoList(_list);
            setSwitchVideoListCursor(res?.nextCursor);
        }).catch(e=>{
            console.error(e);
        })
    }

    const getSwitchVideoList = (cursor) =>{
        if (cursor === "-1" || cursor === -1){
            return;
        }
        let params = memeclubSwitchVideoParams;
        if (!params){
            params = defaultMemeSwitchVideoParams;
        }
        if (switchVideoType === 1){
            //explore
            getMemeClubList(params, cursor);
        }
        else if (switchVideoType === 2){
            //tge
            getTgeClubList(params, cursor);
        }
        else if (switchVideoType === 3){
            //unclaimed
            getOpenablePackList(cursor);
        }
        else if (switchVideoType === 4){
            //created
            getUserTabList({
                tarUserId: params?.tarUserId,
                rankTabType: 1,
                rankSortType: 1,
                isAsc: true,
                cursor:cursor
            });
        }
        else if (switchVideoType === 5){
            //favorited
            getUserTabList({
                tarUserId: params?.tarUserId,
                rankTabType: 2,
                rankSortType: 1,
                isAsc: true,
                cursor:cursor
            });
        }
        else if (switchVideoType !== -1){
            getMemeClubList(params, cursor);
        }
    }

    const findSuitableInde = (startIndex, order) => {
        let tempIndex = startIndex;
        if (tempIndex >= switchVideoList?.length){
            tempIndex = 0;
        }
        if (tempIndex < 0){
            tempIndex = switchVideoList?.length - 1;
        }
        if (switchVideoList?.[tempIndex]?.momentAuditCode !== 3){
            return tempIndex;
        }
        else{
            if (switchVideoList?.length > 1){
                if (order){
                    if (tempIndex >= switchVideoList?.length - 1){
                        tempIndex = 0;
                    }   
                    else{
                        tempIndex++;
                    }
                }
                else{
                    if (tempIndex <= 0){
                        tempIndex = switchVideoList?.length - 1;
                    }   
                    else{
                        tempIndex--;
                    }
                }
                while (tempIndex !== startIndex && switchVideoList?.[tempIndex]?.momentAuditCode === 3){
                    if (order){
                        if (tempIndex >= switchVideoList?.length - 1){
                            tempIndex = 0;
                        }
                        else{
                            tempIndex++;
                        }
                    }
                    else{
                        if (tempIndex <= 0){
                            tempIndex = switchVideoList?.length - 1;
                        }
                        else{
                            tempIndex--;
                        }
                    }
                }
            }
            else{
                tempIndex = 0;
            }
            return tempIndex;
        }
    }

    const updateClubIdList = (callback = () =>{}) =>{
        if (currentMomentClubId > 0){
            if (switchVideoList?.length > 0){
                let listReady = false;
                let tempMomentIndex = -1;
                for (let i = 0; i < switchVideoList.length; i++){
                    if (Number(switchVideoList[i].clubId) === Number(currentMomentClubId)){
                        if (i >= switchVideoList.length - switchListNeedNextFetchCount && switchVideoListCursor !== "-1" && switchVideoListCursor !== -1){
                            getSwitchVideoList(switchVideoListCursor);
                            return;
                        }
                        else{
                            listReady = true;
                            tempMomentIndex = i;
                            break;
                        }
                    }
                    else if (i === switchVideoList.length - 1){
                        listReady = true;
                    }
                }
                if (listReady){
                    let nextIdx = -1;
                    let preIdx = -1;
                    if (tempMomentIndex === -1){
                        //视频找不到，默认下一个为头，上一个为尾
                        nextIdx = findSuitableInde(0, true);
                        preIdx = findSuitableInde(switchVideoList.length - 1, false);
                    }
                    else if (tempMomentIndex === 0){
                        //视频为首个视频，上一个为尾
                        nextIdx = findSuitableInde(tempMomentIndex + 1, true);
                        preIdx = findSuitableInde(switchVideoList.length - 1, false);
                    }
                    else if (tempMomentIndex === switchVideoList.length - 1){
                        //视频为最后视频，下一个为头
                        nextIdx = findSuitableInde(0, true);
                        preIdx = findSuitableInde(tempMomentIndex - 1, false);
                    }
                    else{
                        nextIdx = findSuitableInde(tempMomentIndex + 1, true);
                        preIdx = findSuitableInde(tempMomentIndex - 1, false);
                    }
                    setCurrentClubShortInfo(switchVideoList[tempMomentIndex]);
                    setNextClubShortInfo(switchVideoList[nextIdx]);
                    setPreClubShortInfo(switchVideoList[preIdx]);
                }
            }
            else if (!alreadyInitSwitchList) {
                setAlreadyInitSwitchList(true);
                getSwitchVideoList("");
            }
        }
    }

    const onStartSwitchAni = () =>{
        setHiddenVideo(true);
        setVideoReady(false);
    }

    const onCompleteSwitchAni = (isNext) =>{
        if (isNext){
            let tempCurIndex = nextIndex;
            let tempNextIndex = preIndex;
            let tempPreIndex = currentIndex;
            setNextIndex(tempNextIndex);
            setCurrentIndex(tempCurIndex);
            setPreIndex(tempPreIndex);
            let tempCurClubId = nextClubShortInfo?.clubId;
            setCurrentMomentClubId(tempCurClubId);
        }
        else{
            let tempCurIndex = preIndex;
            let tempNextIndex = currentIndex;
            let tempPreIndex = nextIndex;
            setNextIndex(tempNextIndex);
            setCurrentIndex(tempCurIndex);
            setPreIndex(tempPreIndex);
            let tempCurClubId = preClubShortInfo?.clubId;
            setCurrentMomentClubId(tempCurClubId);
        }

        setVideoChangeTimes(videoChangeTimes + 1);
        setVideoNo(0);
        setSwitchLock(false);
    }

    useEffect(() =>{
        updateClubIdList();
    }, [currentMomentClubId, switchVideoList, switchVideoListCursor]);

    useEffect(() =>{
        let tempList = [0,0,0];
        tempList[currentIndex] = currentClubShortInfo?.clubId;
        tempList[nextIndex] = nextClubShortInfo?.clubId;
        tempList[preIndex] = preClubShortInfo?.clubId;
        setPlayClubIdList(tempList);
    }, [nextClubShortInfo, preClubShortInfo, currentClubShortInfo]);

    const fastGoPreFlagRef = useRef(0);
    const fastGoNextFlagRef = useRef(0);

    useEffect(() => {
        if (fastGoPreFlagRef.current > 0){
            goPreFn(true);
        }
    }, [fastGoPreFlagRef.current])

    useEffect(() => {
        if (fastGoNextFlagRef.current > 0){
            goNextFn(true);
        }
    }, [fastGoNextFlagRef.current])

    const {contextSafe} = useGSAP(
        () => {
            Observer.create({
                target: videoDragRef.current, // can be any element (selector text is fine)
                type: "wheel", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
                onUp: () => {
                    if (!observerLockRef.current && !drawerOpenRef.current && !touchDownRef.current){
                        observerLockRef.current = true;
                        fastGoPreFlagRef.current = fastGoPreFlagRef.current + 1;
                        setTimeout(() => {
                            observerLockRef.current = false;
                        }, 1000);
                    }
                },
                onDown: () => {
                    if (!observerLockRef.current && !drawerOpenRef.current && !touchDownRef.current){
                        observerLockRef.current = true;
                        fastGoNextFlagRef.current = fastGoNextFlagRef.current + 1;
                        setTimeout(() => {
                            observerLockRef.current = false;
                        }, 1000);
                    }
                },
            });
        },
        { scope: containerRef }
    );

    const switchVideoAni = contextSafe((isNext, fastSlide) =>{
        let currentRootName = mediaRootNameList[currentIndex];
        let nextRootName = mediaRootNameList[nextIndex];
        let preRootName = mediaRootNameList[preIndex];
        let tl = gsap.timeline();
        timelineRef.current = tl;
        if (switchVideoList?.length < 2){
            return;
        }
        setSwitchLock(true);
        /*let needNextLoadingAni = true;
        if (isNext){
            console.log("next", nextIndex, video0Ready, video1Ready, video2Ready);
        }
        else{
            console.log("pre", preIndex, video0Ready, video1Ready, video2Ready);
        }
        if (isNext){
            if ((nextIndex === 1 && video1Ready) || (nextIndex === 2 && video2Ready) || (nextIndex === 0 && video0Ready)){
                needNextLoadingAni = false;
            }
        }
        else{
            if ((preIndex === 1 && video1Ready) || (preIndex === 2 && video2Ready) || (preIndex === 0 && video0Ready)){
                needNextLoadingAni = false;
            }
        }*/
        //needNextLoadingAni = true;
        /*if (needNextLoadingAni) {
            tl.to(`.${currentRootName}`, {
                duration: 0.25,
                top: isNext ? '-50%' : '50%',
            });
            tl.to(`.${currentRootName}`, {
                duration: 0.25,
                top: '0%',
                onComplete: () =>{
                    setSwitchLock(false);

                    //test
                    let tempCurClubId = nextClubId;
                    setCurrentMomentClubId(tempCurClubId);
                    setVideoChangeTimes(videoChangeTimes + 1);
                }
            });
            return;
        }*/
        if (isNext){
            setSwitchDirection(1);
            tl.to(`.${currentRootName}`, {
                duration: switchDuration,
                top: '-100%',
            });
            tl.to(`.${nextRootName}`, {
                duration: switchDuration,
                top: '0%',
                onStart: () => {
                    if (fastSlide){
                        changeCurrentVideoClubInfo(nextClubShortInfo);
                    }
                },
                onComplete: () =>{
                    onCompleteSwitchAni(true);
                }
            }, "<");
            tl.to(`.videoRoot`, {
                duration: switchDuration,
                top: fastSlide?'-100%':'0%',
            }, "<");
            tl.set(`.${preRootName}`, {
                top: `100%`
            });
            if (fastSlide){
                tl.set(`.videoRoot`, {
                    top: `0%`,
                });
            }
        }
        else{
            setSwitchDirection(-1);
            tl.to(`.${currentRootName}`, {
                duration: switchDuration,
                top: '100%',
            });
            tl.to(`.${preRootName}`, {
                duration: switchDuration,
                top: '0%',
                onStart: () => {
                    if (fastSlide){
                        changeCurrentVideoClubInfo(preClubShortInfo);
                    }
                },
                onComplete: () =>{
                    onCompleteSwitchAni(false);
                }
            }, "<");
            tl.to(`.videoRoot`, {
                duration: switchDuration,
                top: fastSlide?'100%':'0%',
            }, "<");
            tl.set(`.${nextRootName}`, {
                top: `-100%`
            });
            if (fastSlide){
                tl.set(`.videoRoot`, {
                    top: `0%`,
                });
            }
        }
    })

    const moveVideo = contextSafe((deltaY) =>{
        let currentRootName = mediaRootNameList[currentIndex];
        let nextRootName = mediaRootNameList[nextIndex];
        let preRootName = mediaRootNameList[preIndex];
        let tl = gsap.timeline();
        let videoPercent = 0;
        if (videoNo === 1){
            videoPercent = 100;
        }
        else if (videoNo === -1){
            videoPercent = -100;
        }
        tl.set(`.videoRoot`, {
            top: `calc(${videoPercent}% + ${deltaY}px)`,
        });
        tl.set(`.${currentRootName}`, {
            top: `${deltaY}px`,
        });
        if (switchVideoList?.length > 1){
            tl.set(`.${nextRootName}`, {
                top: `calc(100% + ${deltaY}px)`,
            });
            tl.set(`.${preRootName}`, {
                top: `calc(-100% + ${deltaY}px)`,
            });
        }
    })

    const onMove = (deltaY) => {
        moveVideo(deltaY);
    }

    const resetVideoAni = contextSafe(() =>{
        setSwitchLock(true);
        let currentRootName = mediaRootNameList[currentIndex];
        let nextRootName = mediaRootNameList[nextIndex];
        let preRootName = mediaRootNameList[preIndex];
        let tl = gsap.timeline();
        timelineRef.current = tl;
        tl.to(`.videoRoot`, {
            duration: switchDuration / 2,
            top: `0%`,
            onComplete: () => {
                setSwitchLock(false);
            }
        });
        tl.to(`.${currentRootName}`, {
            duration: switchDuration / 2,
            top: `0%`,
        }, "<");
        tl.to(`.${nextRootName}`, {
            duration: switchDuration / 2,
            top: `100%`,
        }, "<");
        tl.to(`.${preRootName}`, {
            duration: switchDuration / 2,
            top: `-100%`,
        }, "<");
    })

    const setNextState = contextSafe((isNext) =>{
        let currentRootName = mediaRootNameList[currentIndex];
        let nextRootName = mediaRootNameList[nextIndex];
        let preRootName = mediaRootNameList[preIndex];
        let tl = gsap.timeline();
        if (isNext){
            tl.set(`.${currentRootName}`, {
                top: `-100%`,
            });
            tl.set(`.${nextRootName}`, {
                top: `0%`,
            });
            tl.set(`.${preRootName}`, {
                top: `100%`,
            });
            tl.set(`.videoRoot`, {
                top: `0%`,
            });
        }
        else{
            tl.set(`.${currentRootName}`, {
                top: `100%`,
            });
            tl.set(`.${preRootName}`, {
                top: `0%`,
            });
            tl.set(`.${nextRootName}`, {
                top: `-100%`,
            });
            tl.set(`.videoRoot`, {
                top: `0%`,
            });
        }
    })

    const resetVideo = () => {
        resetVideoAni();
    }

    const goNextFn = (fastSlide) => {
        if (!switchLock){
            switchVideoAni(true, fastSlide);
        }
    }

    const goPreFn = (fastSlide) => {
        if (!switchLock){
            switchVideoAni(false, fastSlide);
        }
    }

    const onVideoDragDown = (e) => {
        onVideoDragStart(e?.screenY);
    }

    const onVideoTouchDown = (e) => {
        onVideoDragStart(e?.touches[0]?.clientY);
    }

    const onVideoDragStart = (y) => {

        if (switchLock){
            setSwitchLock(false);
            if (timelineRef.current){
                timelineRef.current.pause(switchDuration);
                //setNextState(switchDirection === 1);
            }
            onCompleteSwitchAni(switchDirection === 1);
        }

        touchDownRef.current = true;
        setVideoStartDragY(y);
        let nowTime = new Date().getTime();
        setTouchDownTime(nowTime);
    }

    const onVideoDragUp = (e) => {
        onVideoDragEnd(e?.screenY);
    }

    const onVideoTouchUp = (e) => {
        onVideoDragEnd(e?.changedTouches[0]?.clientY);
    }

    const onVideoDragEnd = (y) => {
        if (touchDownRef.current && !switchLock){
            touchDownRef.current = false;
            if (startMoveRef.current){
                if (switchVideoList?.length > 1){
                    let nowTime = new Date().getTime();
                    if (nowTime - touchDownTime >= fastSlideTime){
                        if (videoNo === -1){
                            //切club详细信息
                            goPreFn(false);
                        }
                        else if(videoNo === 1){
                            //切club详细信息
                            goNextFn(false);
                        }
                        else{
                            //复位
                            resetVideo();
                        }
                    }
                    else{
                        if (y - videoStartDragY >= 0){
                            goPreFn(true);
                        }
                        else{
                            goNextFn(true);
                        }
                    }
                } 
                else{
                    //复位
                    resetVideo();
                }
                startMoveRef.current = false;
            }
            else{
                setPlayOrPauseVideoFlag(playOrPauseVideoFlag + 1);
                //resetVideo();
            }
        }
    }

    const onVideoMouseMove = (e) => {
        onVideoDragMove(e?.screenY);
    }

    const onVideoTouchMove = (e) => {
        onVideoDragMove(e?.changedTouches[0]?.clientY);
    }

    const onVideoDragMove = (y) => {
        if (touchDownRef.current && switchVideoList?.length > 1){
            if (startMoveRef.current){
                let deltaY = y - videoStartDragY;
                onMove(deltaY);
                if (switchVideoList?.length > 1){
                    let nowTime = new Date().getTime();
                    if (nowTime - touchDownTime >= fastSlideTime){
                        //先切视频
                        if (videoNo === -1){
                            if (deltaY < window.innerHeight * 1 / 3){
                                changeCurrentVideoClubInfo(currentClubShortInfo);
                                setVideoNo(0);
                            }
                            else if (deltaY < window.innerHeight * 1 / -2){
                                changeCurrentVideoClubInfo(nextClubShortInfo);
                                setVideoNo(1);
                            }
                        }
                        else if(videoNo === 1){
                            if (deltaY > window.innerHeight * 1 / -3){
                                changeCurrentVideoClubInfo(currentClubShortInfo);
                                setVideoNo(0);
                            }
                            else if (deltaY > window.innerHeight * 1 / 2){
                                changeCurrentVideoClubInfo(preClubShortInfo);
                                setVideoNo(-1);
                            }
                        }
                        else{
                            if (deltaY > window.innerHeight * 1 / 2){
                                changeCurrentVideoClubInfo(preClubShortInfo);
                                setVideoNo(-1);
                            }
                            else if (deltaY < window.innerHeight * 1 / -2){
                                changeCurrentVideoClubInfo(nextClubShortInfo);
                                setVideoNo(1);
                            }
                        }
                    }
                }
            }
            else{
                if (y - videoStartDragY >= minStartDragY) {
                    startMoveRef.current = true;
                    setVideoStartDragY(videoStartDragY + minStartDragY);
                    let deltaY = y - videoStartDragY - minStartDragY;
                    onMove(deltaY);
                }
                else if (videoStartDragY - y >= minStartDragY) {
                    startMoveRef.current = true;
                    setVideoStartDragY(videoStartDragY - minStartDragY);
                    let deltaY = y - videoStartDragY + minStartDragY;
                    onMove(deltaY);
                }
            }
        }
    }

    const changeCurrentVideoClubInfo = (info) => {
        setCurrentPlayingClubShortInfo(info);
        setHiddenVideo(true);
        setVideoReady(false);
        setHiddenCount(-1);
    }

    const onVideoTouchLeave = (y) => {
        if (touchDownRef.current && !switchLock){
            touchDownRef.current = false;
            if (startMoveRef.current){
                startMoveRef.current = false;
                if (y > 0){
                    goPreFn(videoNo === 0);
                }
                else{
                    goNextFn(videoNo === 0);
                }
            }
        }
        else{
            resetVideo();
        }
    }

    const onTouchCancel = (e) => {
        //onVideoTouchLeave(e?.changedTouches[0]?.clientY);
        if (switchLock){
            resetVideo();
        }
        if (touchDownRef.current){
            touchDownRef.current = false;
        }
    }

    const onMouseOut = (e) => {
        if (touchDownRef.current){
            onVideoTouchLeave(e?.clientY);
        }
    }

    const [hiddenCount, setHiddenCount] = useState(-1);

    //VIDEO PRELOADING
    const preloadVideoAsync = async () => {
        /*
        let needPreload = true;

        if (preloadVideoList?.length > 0){
            for (let i = 0; i < preloadVideoList?.length; i++){
                if (Number(preloadVideoList[i].clubId) === Number(currentMomentClubId)){
                    needPreload = false;
                    break;
                }
            }
        }*/
        /*if (needPreload)*/{
            let tempPreloadVideoList = [];
            if (switchVideoList?.length > 0){
                let currentVideoIndex = 0;
                for (let i = 0; i < switchVideoList?.length; i++){
                    if (Number(switchVideoList[i]?.clubId) === Number(currentMomentClubId)){
                        currentVideoIndex = i;
                    }
                }

                let indexToAdd = (currentVideoIndex + 1) < switchVideoList?.length ? currentVideoIndex + 1 : 0;

                while (tempPreloadVideoList?.length <= 0 && indexToAdd !== currentVideoIndex){
                    tempPreloadVideoList.push(switchVideoList[indexToAdd]);
                    indexToAdd++;
                    if (indexToAdd >= switchVideoList?.length){
                        indexToAdd = 0;
                    }
                }
            }

            setPreloadVideoList(tempPreloadVideoList);

            
            if (tempPreloadVideoList?.length > 0){

                const preloadArray = [];

                for (let i = 0; i < tempPreloadVideoList?.length; i++){
                    let url = getVideoUrlFromMap(tempPreloadVideoList[i]?.videoUrlMap);

                    if (url){
                        preloadArray.push(url);
                    }
                }

                setVideoPreloadString(JSON.stringify(preloadArray));
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            preloadVideoAsync();
        }, 1000);
    }, [currentMomentClubId])

    useEffect(() => {
        if (videoReady && hiddenVideo){
            if (hiddenVideo){
                if (hiddenCount === -1){
                    setHiddenCount(updateCount + 1);
                }
                else if (updateCount >= hiddenCount){
                    setHiddenVideo(false);
                    setHiddenCount(-1);
                }
            }
            //setHiddenVideo(false);
        }
        setTimeout(() => {
            setUpdateCount(updateCount + 1);
        }, 100);
    }, [updateCount]);

    const getMediaRootDom = (index) => {
        let tempClubId = playClubIdList[index];
        let tempClubShortInfo = currentClubShortInfo;
        let topValue = "0";
        if (index === nextIndex){
            tempClubShortInfo = nextClubShortInfo;
            topValue = "100%";
        }
        else if (index === preIndex) {
            tempClubShortInfo = preClubShortInfo;
            topValue = "-100%";
        }
        
        if (!tempClubId){
            return (<></>)
        }
        else{
            return (
                <div 
                    ref={detailRefList[index]}
                    className={`${s.mediaRoot} ${mediaRootNameList[index]}`}
                    style={{
                        top: topValue
                    }}>
                    <ClubDetail 
                        isCurrentClub={index === currentIndex}
                        clubId={tempClubId}
                        clubShortInfo={tempClubShortInfo}
                        playerRootName={playerRootNameList[index]}
                        switchLock={switchLock}
                        drawerOpen={drawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        buyDrawerOpen={buyDrawerOpen}
                        setBuyDrawerOpen={setBuyDrawerOpen}
                        showForceMutedTip={showForceMutedTip}
                        setShowForceMutedTip={setShowForceMutedTip}
                        pauseVideo={() => {
                            setPauseVideoFlag(pauseVideoFlag + 1);
                        }}></ClubDetail>
                </div>
            )
        }
    }

    return (
        <div ref={containerRef} className={`${s.modalWrap}`}>
            {
                getMediaRootDom(0)
            }
            {
                getMediaRootDom(1)
            }
            {
                getMediaRootDom(2)
            }
            <div className={`${s.videoRoot} videoRoot`}>
                <ClubVideo 
                    clubShortInfo={currentPlayingClubShortInfo}
                    //switchToClubShortInfo={switchToClubShortInfo}
                    playerRootName={'player00'}
                    videoChangeTimes={videoChangeTimes}
                    switchLock={switchLock}
                    goNextFn={goNextFn}
                    goPreFn={goPreFn}
                    videoReady={videoReady}
                    setVideoReady={setVideoReady}
                    drawerOpen={drawerOpen}
                    buyDrawerOpen={buyDrawerOpen}
                    hiddenVideo={hiddenVideo}
                    playOrPauseVideoFlag={playOrPauseVideoFlag}
                    pauseVideoFlag={pauseVideoFlag}
                    showForceMutedTip={showForceMutedTip}
                    setShowForceMutedTip={setShowForceMutedTip}></ClubVideo>
            </div>
            <div 
                ref={videoDragRef}
                className={`${s.videoDrag}`}
                onMouseDown={onVideoDragDown}
                onMouseUp={onVideoDragUp}
                onMouseMove={onVideoMouseMove}
                onTouchMove={onVideoTouchMove}
                onTouchStart={onVideoTouchDown}
                onTouchEnd={onVideoTouchUp}
                onTouchCancel={onTouchCancel}
                onMouseOut={onMouseOut}
                style={{
                    zIndex: switchLock ? 50:5
                }}>
            </div>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        walletBalanceList: app.walletBalanceList,
        lastVideoSwitch: app.LastVideoSwitch,
        memeclubSwitchVideoParams: app.memeclubSwitchVideoParams,
        switchVideoType: app.switchVideoType,
        switchVideoList: app.switchVideoList,
        switchVideoListCursor: app.switchVideoListCursor,
        hideNSFW: app.hideNSFW,
        currentMomentClubId: app.currentMomentClubId,
        videoPreloadString: app.videoPreloadString,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setLastVideoSwitch: (params) => {
            return dispatch({
                type: "app/setLastVideoSwitch",
                payload: params,
            });
        },
        setSwitchVideoType: (params) => {
            return dispatch({
                type: "app/setSwitchVideoType",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
        setVideoPreloadString: (params) => {
            return dispatch({
                type: "app/setVideoPreloadString",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ClubDetailTotal));
