import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "src/common/CustomIcon";
import {numberClamp} from "@/utils/common";

const ModelReferralStakeTips = () => {
    const {t, i18n} = useTranslation();

    return (
        <div className={s.wrap}>
            <div className={s.topPart}>
                <div className={s.content}>
                    <div className={`${s.contentText} mt25 fs13`} style={{textAlign: 'left'}}>
                        <Trans
                            i18nKey="Staking Bonus Tips 01"
                            defaults="Earn a staking bonus each time your referee stakes $LFG. You can claim the bonus every time your referees stake $LFG."
                            components={{
                                font: <span style={{color: '#fab600'}}></span>,
                                fb: <span className="fb"></span>,
                            }}
                        />
                    </div>
                    <div className={`${s.contentText} mt25 fs14`} style={{textAlign: 'left'}}>
                        <Trans
                            i18nKey="Staking Bonus Tips 02"
                            defaults="Staking Bonus = Referee staked amount * referee staked days / 30 * My staking bonus multiplier"
                            components={{
                                font: <span style={{color: '#fab600'}}></span>,
                                fb: <span className="fb"></span>,
                                br: <br/>,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};
export default memo(ModelReferralStakeTips);
