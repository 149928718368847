import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Select, Form, List, Card, Radio, Modal, Switch, Slider, message } from 'antd';
import TemplateForm from "./TemplateForm";
import { attrMap } from "@/utils/memeClub";
import AntButton from "@/common/AntButton"
import { vCoinString } from "@/utils/common";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import ModelEditVal from "@/model/ModelEditVal";
const { Option } = Select;

const Attr = (
    {
        attrs,
        setAttrs,
        withRarity,
        setWithRarity,
        clubTicker,
        coinToNFTPecentageData,
        coinTotalSupply,
        receiveNFTAmount, setReceiveNFTAmount,
        receiveNFT, setReceiveNFT,
        memeGenattrsFn

    }
) => {
    const { t, i18n } = useTranslation();
    const attrTypeConfig = {
        1: {
            name: t('Numerical')
        },
        2: {
            name: t('Categorical')
        }

    }
    const attrTempleteList = Object.keys(attrMap);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [formVisible, setFormVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [selectedInd, setSelectedInd] = useState(null)



    const handleAddTemplete = (templateType) => {
        const templateArr = attrMap[templateType];
        if (templateArr && templateArr?.length > 0) {
            const template = templateArr[Math.floor(Math.random() * templateArr?.length)]
            console.log(`sel selectedTemplate --->`, template);
            setSelectedTemplate(template);
            setFormVisible(true);
            setIsEdit(false);
        } else {
            console.error(`not fund Attr templates`);
        }

    };

    const handleCreate = (values) => {
        if (attrs?.length < 10) {
            setAttrs([...attrs, { ...selectedTemplate, ...values }]);
            setFormVisible(false);
        } else {
            message.info({ content: 'Up to ten attributes' })
        }
    };

    const handleEdit = (index) => {
        setSelectedTemplate(attrs[index]);
        setSelectedInd(index)
        setFormVisible(true);
        setIsEdit(true);
        setEditIndex(index);
    };

    const handleSaveEdit = (values) => {
        const updatedAttrs = attrs.slice();
        // console.log(selectedTemplate,values ,{ ...selectedTemplate, ...values });
        updatedAttrs[editIndex] = { ...selectedTemplate, ...values };
        setAttrs(updatedAttrs);
        setFormVisible(false);
    };

    const handleDelete = (index) => {
        const updatedAttrs = attrs.slice();
        updatedAttrs.splice(index, 1);
        setAttrs(updatedAttrs);
        setFormVisible(false);
    };

    const newTemplate = () => {
        if (attrs.length < 10) {
            setSelectedTemplate({
                attrName: "Attribute",
                enumStrs: [],
                maxNum: 100,
                minNum: 1,
                type: 1,
            });
            setFormVisible(true);
            setIsEdit(false);

        } else {
            message.info({ content: 'Up to ten attributes' })
        }
    }

    const [aiAttrLoading, setAiAttrLoading] = useState(false)

    const sliderTotalNFTSupplyMin = Math.round(Math.log10(coinToNFTPecentageData?.min / 10000 * coinTotalSupply) * 100) / 100
    const sliderTotalNFTSupplyMax = Math.round(Math.log10(coinToNFTPecentageData?.max / 10000 * coinTotalSupply) * 100) / 100
    const [showEditModel, setShowEditModel] = useState(false)
    return (
        <div className={`${s.box}`}>
            <div className={`${s.partTitle} mt10 mb20 w100p`}>
                <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                    imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                {t('Card Info')}
            </div>
            <div className={s.item}>

                <div className={s.label}>
                    {t('Receive NFT when you have enough coins?')}
                    <Switch checked={receiveNFT}
                        className="commonSwitch ml15"
                        checkedChildren={<CustomIcon imgName='Picture/UI_Picture_MemeClub_Right_01' width={16}
                            height={15} />}
                        onChange={(checked) => {
                            setReceiveNFT(checked)
                        }} />
                </div>

                {receiveNFT &&
                    <>

                        <div className={s.label}>
                            <span className="color-blue-light">{t('Mint 1 NFT for every: ')}</span> &nbsp;
                            {`${vCoinString(receiveNFTAmount)}`} ${clubTicker} held

                        </div>
                        <div className="flex_center_center">
                            <Slider
                                className="hireSlider commonSlider memeClubSlider"
                                min={sliderTotalNFTSupplyMin}
                                max={sliderTotalNFTSupplyMax}
                                value={Math.log10(receiveNFTAmount)}
                                step={0.05}
                                tooltip={{ open: false }}
                                onChange={(value) => {
                                    setReceiveNFTAmount(parseInt(Math.pow(10, value) / Math.pow(10, (parseInt(value) - 1))) * Math.pow(10, (parseInt(value) - 1)));
                                }} />
                            <CustomIcon onClick={() => { setShowEditModel(true) }} height={25} width={25} className="ml15" imgName="Button/UI_Picture_Settings_Edit_01" ></CustomIcon>
                        </div>

                        <div className={s.label}>
                            <span className="color-blue-light">{t('Total NFT Supply:')}</span> &nbsp;
                            {`${Math.floor(coinTotalSupply / receiveNFTAmount)}`}

                        </div>
                    </>


                }
            </div>
            {receiveNFT &&
                <>
                    <div className="flex_center_between w100p mb10">
                        <div className={`${s.partTitle}`}>
                            <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                                imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                            {t('Trait List')}
                        </div>
                        {/* <div className='flex_center_center fs12'>
                        {t('Distinguished by Rarity')}
                        <Switch
                            className="commonSwitch ml15"
                            checkedChildren={<CustomIcon imgName='Picture/UI_Picture_MemeClub_Right_01' width={16}
                                height={15} />}
                            checked={withRarity}
                            onChange={(checked) => {
                                setWithRarity(checked)
                            }} />
                    </div> */}
                    </div>

                    {/* <div className={s.label}>
                        {t('Preset Examples:')}
                    </div> */}

                    <div className={s.templateWrap}>
                        <div className="flex_center_center mb15">
                            <AntButton
                                // className={s.addBtn}
                                className={`btn_public ${s.addBtn}`}
                                onClick={() => {
                                    if (aiAttrLoading) return
                                    setAiAttrLoading(true)
                                    memeGenattrsFn().then(() => {
                                        setAiAttrLoading(false)
                                    }).catch(e => {
                                        setAiAttrLoading(false)
                                    })
                                }}
                                loading={aiAttrLoading}

                            ><div className="flex_center_center color-black "> {t('AUTO GENERATE TRAITS')} </div>
                            </AntButton>
                        </div>
                        {/* <div className="flex_center_center fs14 color-blue-light mt5 mb5">{t('Or you can try')}</div>
                        <div className={s.templateList}>
                            {
                                attrTempleteList?.map((i, index) => {
                                    return (
                                        <div className={s.templateTypeItem} onClick={() => {
                                            handleAddTemplete(i)
                                        }} key={index}>+ {i}</div>
                                    )
                                })
                            }
                        </div> */}
                        {
                            attrs?.map((item, ind) => {
                                const { type, attrName, Template, minNum, maxNum, ID, enumStrs } = item;
                                const totalweight = type === 2 ? enumStrs?.reduce((accumulator, currentObject) => {
                                    return accumulator + (currentObject?.weight || 0);
                                }, 0) : 0
                                return (
                                    <div className={s.item} key={ind}>
                                        <Input readOnly={true} className={s.input} value={attrName} onChange={(e) => {

                                        }}></Input>
                                        <div className={s.valWrap}>
                                            <div className={s.type}>{attrTypeConfig[type]?.name}</div>
                                            {
                                                type === 1 ?
                                                    <div className={s.attrWrap}>{`${minNum}-${maxNum}`}</div> :
                                                    <>{enumStrs.map((i, index) => {
                                                        if (i?.enumName) {
                                                            return <div key={index} className={s.attrWrap}>{i?.enumName} {Math.floor((i?.weight / totalweight) * 100)}%</div>
                                                        }
                                                    })}
                                                    </>

                                            }
                                        </div>
                                        <div className={s.edit}>
                                            <CustomIcon onClick={() => handleEdit(ind)} height={25} width={25} imgName="Button/UI_Picture_Settings_Edit_01" ></CustomIcon>
                                            {/* <AntButton className="btn_blue" onClick={() => handleEdit(ind)}>{t('Edit')}</AntButton> */}
                                            {/* <AntButton className="btn_gray" onClick={() => handleDelete(ind)}>{t('Delete')}</AntButton> */}
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {attrs?.length < 10 &&
                            <div className="flex_center_start mt15">
                                <div className={s.templateTypeItem} onClick={newTemplate}>{t('+ Add Trait')}</div>
                            </div>
                        }

                        {selectedTemplate && (
                            <TemplateForm
                                template={selectedTemplate}
                                visible={formVisible}
                                onCancel={() => setFormVisible(false)}
                                onCreate={handleCreate}
                                onEdit={handleSaveEdit}
                                onDelete={handleDelete}
                                selectedInd={selectedInd}
                                isEdit={isEdit}
                                attrTypeConfig={attrTypeConfig}
                            />
                        )}
                    </div>

                </>
            }




            <AntModal
                width='330px'
                title={t('Edit')}
                className="confirmModalWrap"
                centered={true}
                open={showEditModel}
                onOk={() => setShowEditModel(false)}
                onCancel={() => setShowEditModel(false)}
            >
                <ModelEditVal title={t('Mint 1 NFT for every: ')}
                    onClose={() => setShowEditModel(false)}
                    val={receiveNFTAmount}
                    setVal={setReceiveNFTAmount}
                    min={Math.floor(coinToNFTPecentageData?.min / 10000 * coinTotalSupply)}
                    max={Math.floor(coinToNFTPecentageData?.max / 10000 * coinTotalSupply)} />
            </AntModal>

        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Attr));
