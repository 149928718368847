import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../common/CustomIcon";
import {
    convertWalletBalance, 
    numberClamp, 
    getChainTokenInfoById,
} from "@/utils/common";
import { 
    coinList,
    getMemeKingTgeWinnerList,
} from "@/utils/memeClub";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Slider, Checkbox, Select, Spin} from "antd";

const ModalMemeLotteryDetail = (
    {
        closeFn = () =>{}
    }
) => {
    const {t, i18n} = useTranslation();

    const [pageIndex, setPageIndex] = useState(0);

    return (
        <div className={s.wrap}>
            <div className={s.pageControl}>
                <div className={`${s.prev}`} onClick={() => {
                    if (pageIndex > 0){
                        setPageIndex(numberClamp(pageIndex - 1, 0, 1));
                    }
                }}>
                    { pageIndex > 0 && <CustomIcon
                        className={s.prevBtn}
                        width={14}
                        height={28}
                        imgName={"Button/UI_Button_Arrow_Left_01"}
                    ></CustomIcon>}
                </div>
                <div className={s.next} onClick={() => {
                    if (pageIndex < 3){
                        setPageIndex(numberClamp(pageIndex + 1, 0, 1));
                    }
                }}>
                    { pageIndex < 1 && <CustomIcon
                        className={s.nextBtn}
                        width={14}
                        height={28}
                        imgName={"Button/UI_Button_Arrow_Right_01"}
                    ></CustomIcon>}
                </div>
            </div>
            <div className={`${s.title} flex_center_start_col`}>
                {
                    pageIndex === 1 ?
                    <div className={`${s.titleText}`}>
                        {t('Claim Your Pack')}
                    </div> :
                    <div className={`${s.titleText}`}>
                        {t('Confirm Your Prize')}
                    </div> 
                }
            </div>
            <div className={s.topPart}>
                <div className={`${s.content} flex_center_start_col`}>
                    {
                        pageIndex === 0 &&
                        <>
                            <div className={`${s.importantLine} mt18`}>
                                {t('Congratulations! You’ve won a Pack')}
                            </div>
                            <div className={`${s.importantLine} mt25`}>
                                {t('What’s Next?')}
                            </div>
                            <div className={`${s.commonLine} mt10`}>
                                {t('1. Confirm Your Prize')}:
                            </div>
                            <div className={`${s.commonLine}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t('You have 30 minutes to confirm this reward.')}
                            </div>
                            <div className={`${s.commonLine}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t('To confirm, you need to purchase the pack.')}
                            </div>
                            <div className={`${s.commonLine} mt10`}>
                                {t('2. How to Confirm')}:
                            </div>
                            <div className={`${s.commonLine}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t('Click the “Buy” button below.')}
                            </div>
                            <div className={`${s.commonLine}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t('Complete the purchase process.')}
                            </div>
                            <div className={`${s.importantLine} mt25`}>
                                {t('Note')}:
                            </div>
                            <div className={`${s.commonLine} mt10`}>
                                {t('If you do not confirm and purchase within 30 minutes, the reward will expire.')}
                            </div>
                        </>
                    }
                    {
                        pageIndex === 1 &&
                        <>
                            <div className={`${s.importantLine} mt3`}>
                                {t('How to Claim?')}
                            </div>
                            <div className={`${s.commonLine} mt6`}>
                                {t('1. Waiting Period')}:
                            </div>
                            <div className={`${s.commonLine}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t('After confirming your prize, there is a 24-hour wait time.')}
                            </div>
                            <div className={`${s.commonLine} mt10`}>
                                {t('2. Claiming Your Reward')}:
                            </div>
                            <div className={`${s.commonLine}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t('Once 24 hours have passed, you can see and click  the “Claim” button.')}
                            </div>
                            <div className={`${s.commonLine}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t('Your pack will be added to your inventory.')}
                            </div>
                            <div className={`${s.commonLine} mt10`}>
                                {t('3. Manage Your Slots')}:
                            </div>
                            <div className={`${s.commonLine}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t('You can have up to 4 pending rewards at a time.')}
                            </div>
                            <div className={`${s.commonLine}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t('Make sure to claim rewards regularly to free up slots for new rewards.')}
                            </div>
                            <div className={`${s.importantLine} mt18`}>
                                {t('Note')}:
                            </div>
                            <div className={`${s.commonLine} mt3`}>
                                {t('Unclaimed rewards after 24 hours will stay in the slot until you claim them. Ensure to manage your slots for continuous rewards.')}
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className={s.pageRoot}>
                <div
                    className={`${pageIndex === 0 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(0);
                    }}>
                </div>
                <div
                    className={`${pageIndex === 1 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(1);
                    }}>
                </div>
            </div>
        </div>
    )
}
export default memo(ModalMemeLotteryDetail);
