import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    copyFn,
    stringToNumber,
    convertWalletBalance,
    getFriendtradeLootLastevent,
    searchuser,
    displayFilter,
    getAvatarFromUserInfo,
    getProfile,
    convertUsdValue,
    addJoinChartPre,
} from "@/utils/common"
import AntModal from "@/common/AntModal";
import { 
    coinList, 
    memeTgeClublist, 
    setFavorite, 
    getRecommendClub, 
    searchclub, 
    getChainInfo,
    getMemeTradevolInfo,
} from "@/utils/memeClub";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntDrawer from "@/common/AntDrawer";
import { 
    gameMemeClubCreatPath, 
    gameMemeClubDetail, 
    gameMemeClubDetailPath,
    gameMemeClubPath,
    gameReferralPath,
    gameMemeClubLotteryPath,
} from "@/routes/config";
import AvatarInfo from "@/common/AvatarInfo";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import MemeClubPack from "@/common/MemeClubPack";
import ModalGetInviteCode from "@/model/ModalGetInviteCode";
import ModelReferralShare from "@/model/ModelReferralShare";
import ModalMemeSharingDetail from "@/model/ModalMemeSharingDetail";
import memeTradeVolSharingRate from "@/utils/json/memeTradeVolSharingRate.json";

const Launcher = (
    {
        userInfo,
        setHideRefreshBtn,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [openSharePanel, setOpenSharePanel] = useState(false);
    const [showGetInviteCode, setShowGetInviteCode] = useState(false);
    const [showMemeSharingDetail, setShowMemeSharingDetail] = useState(false);
    const [tradevolInfo, setTradevolInfo] = useState(null);
    const {
        walletAddr,
        topUpCode,
        userId,
        referralTotalEarn,
        platformData,
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        bindReferralCode,
        referralCode,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const {platformMap, useType, defaultName} = useMemo(() => {
        return platformData || {}
    }, [platformData]);

    const {displayName, accountName, profileDeepLink} = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData]); 

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo);
    }, [userInfo]);

    const imageList = useMemo(() =>{
        let res = [];
        if (tradevolInfo?.imageUrls?.length > 0){
            for (let i = 0; i < tradevolInfo?.imageUrls?.length && i < 5; i++){
                res.push(tradevolInfo?.imageUrls[i]);
            }
        }
        return res;
    }, [tradevolInfo]);

    const tradeConfig = useMemo(() => {
        let res = null;
        if (memeTradeVolSharingRate?.length > 0 && tradevolInfo){
            for (let i = 0; i < memeTradeVolSharingRate?.length; i++){
                if (memeTradeVolSharingRate[i]?.maxVolume > tradevolInfo?.tradeVolUsd || memeTradeVolSharingRate[i]?.sort === -1){
                    res = memeTradeVolSharingRate[i];
                    break;
                }
            }
        }
        return res;
    }, [tradevolInfo]);

    const getVolumeProgress = () =>{
        if (tradeConfig){
            return Math.min(100, tradevolInfo?.tradeVolUsd * 100 / tradeConfig.maxVolume);
        }
        return 0;
    }

    const updateMemeTradevolInfo = () =>{
        getMemeTradevolInfo({
        }).then(res => {
            setTradevolInfo(res);
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        updateMemeTradevolInfo();
    }, []); 

    const converReferralCode = (code) =>{
        let tempCode = addJoinChartPre(code);
        if (tempCode?.length > 12){
            return `${tempCode.substring(0, 6)}...${tempCode.substring(tempCode.length - 6)}`;
        }
        else{
            return tempCode;
        }
    }

    return (
        <div className={`${s.modalWrap} mainWrap commonBlueBg`}>
            <MainHeader leftTitle={t('DNA Launcher')} headerType={2} isGameHeader={true} hasBackBtn={false} rightMenuIcon={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={s.modal}>
                <div className={s.content}>
                    <div className={`${s.selfInfoRoot} flex_center_start`}>
                        <AvatarInfo size={66} src={avatarUrl || originalAvatarUrl} />
                        <div className={`${s.selfInfo} flex_center_center_col`}>
                            <div className={`${s.userName}`}>
                                {displayName}
                            </div>
                            <div className={`${s.sl}`}>
                                <div className={`${s.level}`}>
                                    {`${t('Level')}: ${tradeConfig?.level || 0}`}
                                </div>
                                <div className={`${s.sharingRate}`}>
                                    {`${t('Sharing Rate')} ${(tradeConfig?.sharingRate || 0)/100}%`}
                                </div>
                            </div>
                        </div>
                        <CustomIcon width={18} height={18} className={s.detailBtn} baseUrl={'/UI/'}
                            imgName={'Button/UI_Button_QuestionMark_02'} onClick={()=>{
                                setShowMemeSharingDetail(true);
                            }}/>
                    </div>
                    <div className={`${s.volumeProgressRoot}`}>
                        <div className={`${s.progressBg} flex_center_start`}>
                            <div className={`${s.progressContent}`} style={{width: `${getVolumeProgress()}%`}}>
                            </div>
                            <div className={`${s.progressText}`}>
                                {`${t('Volume')}: $${convertUsdValue(tradevolInfo?.tradeVolUsd || 0,2)}${tradeConfig?.sort === -1 ? '':`/${tradeConfig?.maxVolume || 0}`}`}
                            </div>
                        </div>
                    </div>
                    <div className={`${s.groupRoot}`}>
                        <div className={`${s.groupTradeBg} flex_center_start_col`}>
                            {
                                !bindReferralCode ?
                                <div className={`${s.invitePart} flex_center_center`}>
                                    <div className={`${s.inviteBtn} flex_center_center`} onClick={() =>{
                                        setShowGetInviteCode(true);
                                    }}>
                                        <span className="color-black fs18 fb">
                                            {t('Invite friends to earn more')}
                                        </span>
                                    </div>
                                </div> :
                                <div className={`${s.invitedPart} flex_center_between`}>
                                    <div className={`${s.invitedMemberList}`}>
                                        {
                                            imageList?.length > 0 ?
                                            <>
                                                {
                                                    imageList.map((item, index) => {
                                                        return (
                                                            <div key={index} className={`${s.invitedAvatar}`} style={{marginLeft: `${index === 0?0:-32}px`, zIndex: imageList.length - index}} onClick={() => {
                                                                navigate(gameReferralPath);
                                                            }}>
                                                                <AvatarInfo size={52} src={item} />
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </>:
                                            <>
                                                <div className={`${s.invitedAvatar}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}} onClick={() => {
                                                    navigate(gameReferralPath);
                                                }}>
                                                </div>
                                            </>
                                        }
                                        <span className={`fs14 fb`}>
                                            x
                                        </span>
                                        <div className={`fs20 fb lh1`}>
                                            {tradevolInfo?.referralUserNum || 0}
                                        </div>
                                    </div>
                                    <div className={`flex_center_center_col`}>
                                        <div className={`flex_center_end`} onClick={() =>{
                                            copyFn(addJoinChartPre(referralCode));
                                        }}>
                                            <CustomIcon imgName={`Picture/UI_Picture_Icon_Copy_01`} className="mr4" width={12} height={12}></CustomIcon>
                                            <span className={`fs14 fb`}>{converReferralCode(referralCode)}</span>
                                        </div>
                                        <div className={`w100p mt5 flex_center_end`} onClick={() =>{
                                            setOpenSharePanel(true);
                                        }}>
                                            <CustomIcon imgName={`Picture/UI_Picture_Icon_Share_01`} className="mr4" width={12} height={12}></CustomIcon>
                                            <span className={`fs14 fb tll w100p`}>{t('Share')}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={`${s.tradePart} flex_center_start_col`}>
                                <div className={`flex_center_between w100p`} style={{marginTop: '18px'}}>
                                    <span className={`fs14 fb`}>
                                        {t('Group Trade Volume')}&nbsp;:
                                    </span>
                                    <span className={`fs14 fb`}>
                                        {`$${convertUsdValue(tradevolInfo?.groupTradeVol || 0)}`}
                                    </span>
                                </div>
                                <div className={`flex_center_between w100p`} style={{marginTop: '6px'}}>
                                    <span className={`fs14 fb`}>
                                        {t('Est. Total Sharing')}&nbsp;:
                                    </span>
                                    <span className={`fs24 fb color-yellow`}>
                                        {`$${convertUsdValue(tradevolInfo?.totalSharingVal || 0)}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={`${s.lotteryRoot}`}>
                        <div className={`${s.lotteryBg}`}>
                            <div className={`${s.getFreePackLine} flex_center_center`}>
                                <div className={`${s.getFreePackBtn} flex_center_center`} onClick={() =>{
                                    navigate(gameMemeClubLotteryPath);
                                }}>
                                    {t('Spin To Get Free Packs')}
                                </div>
                                <CustomIcon width={28} height={28} className={s.bellBtn} 
                                    imgName={'Picture/UI_Picture_icon_Notification'} onClick={()=>{
                                        navigate(gameMemeClubLotteryPath);
                                    }}/>
                            </div>
                        </div>
                    </div> */}
                    <ButtonFactory
                        className={s.createBtn}
                        size={23}
                        color={3}
                        isFixed={true}
                        isMirror={true}
                        fixedStyle={{useHeight: true, height: 54}}
                        onClick={() => {
                            navigate(gameMemeClubCreatPath);
                        }}
                    >
                        <div className={`fs22 fb color-black`}>
                            {t('Create DNA')}
                        </div>
                    </ButtonFactory>
                    <ButtonFactory
                        className="mt2"
                        size={21}
                        isFixed={true}
                        fixedStyle={{useHeight: true, height: 80}}
                        onClick={() => {
                            navigate(gameMemeClubPath);
                        }}
                    >
                        <div className={`fs26 fb color-black`}>
                            {t('Trade to Earn')}
                        </div>
                    </ButtonFactory>
                </div>
            </div>
            <AntModal
                width='330px'
                title={t('Commission Levels and Rates')}
                className="confirmModalWrap"
                centered={true}
                open={showMemeSharingDetail}
                onOk={() => setShowMemeSharingDetail(false)}
                onCancel={() => setShowMemeSharingDetail(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMemeSharingDetail
                    tradevolInfo={tradevolInfo}
                    closeFn={() => {
                        setShowMemeSharingDetail(false);
                    }}
                />
            </AntModal>
            <AntModal
                width='330px'
                title={t('Who invited you?')}
                className="confirmModalWrap"
                centered={true}
                open={showGetInviteCode}
                onOk={() => setShowGetInviteCode(false)}
                onCancel={() => setShowGetInviteCode(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalGetInviteCode
                    closeFn={() => {
                        setShowGetInviteCode(false);
                    }}
                />
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap tweetShareModal noImageTitle"
                centered={true}
                open={openSharePanel}
                onOk={() => setOpenSharePanel(false)}
                onCancel={() => setOpenSharePanel(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModelReferralShare inviteCode={referralCode}/>
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHideRefreshBtn: (params) => {
            return dispatch({
                type: "app/setHideRefreshBtn",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Launcher));
