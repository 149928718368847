import React, { useMemo, useState } from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import {useNavigate} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import { getWheelResultArr } from "@/utils/xFarm";

const ModelUpBonusIntro = (
    {
        currentTireId
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [tierId,setTierId] = useState(currentTireId)
    const list = useMemo(()=>{
        return getWheelResultArr(tierId)
    },[tierId])
    return (
    <div className={s.modelWrap}>
        <div className="fs16">{t('Club Tiers deteminted Bonus who can get Wheel Bonus rewards: ')}</div>
        <div className="fs16 mt15">{t('Earn Up To 10 Multplier')}</div>
        <div className="tlc fs18 color-yellow mt15">{t('Current level:')}{tierId}</div>
        <div className={s.list}>
            {list?.map(i=>{
                const {id,showStr} = i;
                return (
                    <div key={id} className={s.item}>
                        {showStr}
                    </div>
                )
            })}
            
        </div>
        <div className={s.btnWrap}>
            <div className={s.btn} onClick={()=>{
                    tierId>1 && setTierId(tierId-1)
                }}>
                <CustomIcon imgName={'Button/UI_Button_Triangle-White_Left_01'} width={16} height={16} />
                <div className={s.text} >{t('Last level')}</div>
            </div>
            <div className={s.btn} onClick={()=>{
                    tierId<15 && setTierId(tierId+1)
                }}>
                <div className={s.text} >{t('Next level')}</div>
                <CustomIcon imgName={'Button/UI_Button_Triangle-White_Right_01'} width={16} height={16} />
            </div>
        </div>
    </div>
    )
}

export default ModelUpBonusIntro
