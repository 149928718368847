import React, {memo, useMemo, useRef,useState} from "react";
import s from "./index.module.less";

import {Button, message} from "antd";
import CustomIcon from "../../common/CustomIcon";
import {TwitterShareButton} from 'react-twitter-embed';
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import {joinChartPre} from "@/utils/common";
import { TwitterRandomContent, topic, tag } from "@/utils/xFarm";
function copyFn(text) {
    copyText(text, function () {
            message.success({content: 'copied!'})
        }
    );
}

const copyText = function (button, content, success) {
    if (!button) {
        return;
    }

    if (typeof content == 'function') {
        success = content;
        content = null;
    }

    success = success || function () {
    };

    // 是否降级使用
    var isFallback = !navigator.clipboard;

    if (typeof button == 'string' && !content) {
        if (content === false) {
            isFallback = true;
        }
        content = button;
        button = null;
    }

    var eleTextarea = document.querySelector('#tempTextarea');
    if (!eleTextarea && isFallback) {
        eleTextarea = document.createElement('textarea');
        eleTextarea.style.width = 0;
        eleTextarea.style.position = 'fixed';
        eleTextarea.style.left = '-999px';
        eleTextarea.style.top = '10px';
        eleTextarea.setAttribute('readonly', 'readonly');
        document.body.appendChild(eleTextarea);
    }


    var funCopy = function (text, callback) {
        callback = callback || function () {
        };

        if (!isFallback) {
            navigator.clipboard.writeText(text).then(function () {
                callback();
                // 成功回调
                success(text);
            }, function () {
                // 禁止写入剪切板后使用兜底方法
                copyText(text, false);
                callback();
                // 成功回调
                success(text);
            });

            return;
        }

        eleTextarea.value = text;
        eleTextarea.select();
        document.execCommand('copy', true);

        callback();
        // 成功回调
        success(text);
    };

    var strStyle = '.text-popup { animation: textPopup 1s both; -ms-transform: translateY(-20px); color: #01cf97; user-select: none; white-space: nowrap; position: absolute; z-index: 99; }@keyframes textPopup {0%, 100% { opacity: 0; } 5% { opacity: 1; } 100% { transform: translateY(-50px); }}'

    var eleStyle = document.querySelector('#popupStyle');
    if (!eleStyle) {
        eleStyle = document.createElement('style');
        eleStyle.id = 'popupStyle';
        eleStyle.innerHTML = strStyle;
        document.head.appendChild(eleStyle);
    }

    if (!button) {
        funCopy(content);
        return;
    }

    // 事件绑定
    button.addEventListener('click', function (event) {
        var strCopy = content;
        if (content && content.tagName) {
            strCopy = content.textContent || content.value;
        }
        // 复制的文字内容
        if (!strCopy) {
            return;
        }

        funCopy(strCopy, function () {
            copyText.tips(event);
        });
    });
};

const ModelTweetsShare = (
    {
        userInfo,
        accountName,
    }
) => {
    const {t, i18n} = useTranslation();
    const copyWrap = useRef(null);
    const copy = () => {
        copyFn(copyWrap.current.innerText);
    }
    const [tweetRandom,setTweetRandom] = useState(TwitterRandomContent[Math.floor(Math.random() * TwitterRandomContent?.length)]?.twitterContent)
    const tweetStr = useMemo(()=>{
        return `${topic} ${accountName} ${tag} ${tweetRandom} `
    },[tweetRandom])





    return (
        <div className={s.wrap}>
            <div className={s.copyContent}>
                <div ref={copyWrap}>
                    {tweetStr}
                </div>
                <div className={s.copy} onClick={copy}>
                    <CustomIcon width={20} height={20} imgName={'Button/UI_Button_CopyNumberWordName_01'}
                                name={'Button/UI_Button_CopyNumberWordName_01'}/>
                </div>

            </div>
            <br/>
            <TwitterShareButton
                onLoad={() => {
                }}
                options={{
                    size: 'large',
                    text: `${tweetStr}`
                }}
                url={' '}
            />
        </div>
    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelTweetsShare));
