import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/pagination';
import { useNavigate } from "react-router-dom";
import { getProfile } from "@/utils/common";
import ButtonFactory from "@/common/ButtonFactory";
import { linkPath } from "@/routes/config";
import { Pagination } from "swiper/modules";

const Guide = (
    {
        userInfo,
        setPath,
        shake,
        setShake
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [activeInd, setActiveInd] = useState(0)
    
    const deepLink = useMemo(() => {
        return getProfile(userInfo?.platformData)?.profileDeepLink
    }, [userInfo])
    const items = [
        {
            title: t('How-To Earn Airdrop'),
            img: './UI/BG/UI_Background_Farm_Guide_02.webp',
            path: 'Fever Farm',
            label:  <Trans
            i18nKey="More Likes, More Views More $IYKYK Points"
            defaults="More Likes, More Views <br> More <yellow>{{tag}}</yellow> Points"
            values={{tag:'$IYKYK'}}
            components={{
                yellow: <span className="color-yellow fs26 ml5 mr5"></span>,
                br:<br/>
            }}
        />,
            btnStr: t('Visit Farm')
        }, {
            title: t('Daily Free Multiplier Spin'),
            img: './UI/BG/UI_Background_Farm_Guide_03.webp',
            path: 'Bonus',
            label: <Trans
                i18nKey="Spin The Wheel Up To 540X Multplier"
                defaults="Spin The Wheel <br> Up To <yellow>{{tag}}</yellow> Multplier"
                values={{tag:'540X'}}
                components={{
                    yellow: <span className="color-yellow fs26 ml5 mr5"></span>,
                    br:<br/>
                }}
            />,
            btnStr: t('UPGRADE BOUNS')
        }, {
            title: t('Top Ranks, Bigger Airdrop'),
            img: './UI/BG/UI_Background_Farm_Guide_04.webp',
            path: 'LeaderShip',
            label: <Trans
                i18nKey="Harvest Your $UP Points"
                defaults="The Higher Your Rank <br> The Bigger Your <yellow>{{tag}}</yellow> Airdrop"
                values={{tag:'$IYKYK'}}
                components={{
                    yellow: <span className="color-yellow fs26 ml5 mr5"></span>,
                    br:<br/>
                }}
            />,
            btnStr: t('Get it !')
        }
    ]


    // const toDetail = useCallback(()=>{
    //     setPath(items[activeInd.current]?.path) 
    // },[activeInd])

    return (
        <div className={`${s.wrap} commonBlueBg`}>
            {!deepLink ?
                <div className={s.swiper}>
                    <div className={`${s.item} fs24`}>
                        <div className={`${s.partTitle} fb`}>
                            <CustomIcon width={17} height={39} className="" imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                            {t('Link X to Earn $IYKYK')}
                        </div>
                        <img width={'90%'} src="./UI/BG/UI_Background_Farm_Guide_01.webp"></img>
                        {/* <Trans
                                i18nKey="Create, Engage & Earn $IYKYK Points"
                                defaults="Link X Account <br> Post & Engage on X <br> Earn <yellow>{{tag}}</yellow> Airdrops"
                                values={{tag:'$IYKYK'}}
                                components={{yellow: <span className="color-yellow fs26 ml5 mr5"></span>}}
                            /> */}
                        <div className={s.bottom}>
                            <ButtonFactory
                                size={12}
                                scale={1.1}
                                className={shake?s.shake:''}
                                onClick={() => {
                                    navigate(`${linkPath}/${1}`)
                                }}>
                                <div className="color-black fs24">
                                    {t('Link X Now')}
                                </div>
                            </ButtonFactory>
                        </div>
                    </div>
                </div>
                :
                <div className={s.swiper}>
                    <Swiper
                    slidesPerView={1}
                    centeredSlides={true}
                    onSlideChange={(i) => {
                        setActiveInd(i?.activeIndex)
                    }}
                    className='upPintsSwiper'
                    modules={[Pagination]}
                    pagination={
                        {
                            clickable:true,
                        }
                    }
                >
                    {
                        items?.map((item, ind) => {
                            const { label, img, title } = item
                            return (
                                <SwiperSlide key={ind}>
                                    
                                    <div className={s.item}>
                                        <div className={`${s.partTitle} fb`}>
                                            <CustomIcon width={17} height={39} className="" imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                                            {title}
                                        </div>
                                        <img width={'90%'} src={img}></img>
                                        <div className={s.bottom}>
                                            {deepLink && activeInd === 0 && <ButtonFactory
                                                size={12}
                                                scale={1.1}
                                                onClick={() => {
                                                    setPath(items[0]?.path)
                                                }}
                                            >
                                                <div className="color-black fs24">{items[0]?.btnStr}</div>
                                            </ButtonFactory>}
                                            {deepLink && activeInd === 1 && <ButtonFactory
                                                size={12}
                                                scale={1.1}
                                                onClick={() => {
                                                    setPath(items[1]?.path)
                                                }}
                                            >
                                                <div className="color-black fs24">{items[1]?.btnStr}</div>
                                            </ButtonFactory>}
                                            {deepLink && activeInd === 2 && <ButtonFactory
                                                size={12}
                                                scale={1.1}
                                                onClick={() => {
                                                    setPath(items[2]?.path)
                                                }}
                                            >
                                                <div className="color-black fs24">{items[2]?.btnStr}</div>
                                            </ButtonFactory>}
                                        </div>
                                        {/* <div className={s.title}>
                                            {label}
                                        </div> */}
                                    </div>
                                </SwiperSlide>)
                        })
                    }
                </Swiper>
                </div>
            }
        </div >

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Guide));
