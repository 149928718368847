import http from "@/utils/axios";
import {url} from "@/utils/configUri";
import CityInfoJson from "@/utils/json/CityInfo.json";
const iconPath = 'UI/Picture/';
const buttonPath = 'UI/Button/';
const bgPath = 'UI/BG/';
const getCitydetail= (cityId) =>{
    return new Promise((resolve, reject) => {
        http('post', url.citywar_citydetail, {
            cityId:Number(cityId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getCitywarInfolist = ()=>{
    return new Promise((resolve, reject) => {
        http('post', url.citywar_infolist, {
            
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const citywarUnlockCity = (cityId,costType)=>{
    return new Promise((resolve, reject) => {
        http('post', url.citywar_unlock, {
            cityId:Number(cityId),
            costType:Number(costType),
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

export{
    getCitydetail,
    getCitywarInfolist,
    citywarUnlockCity,
    CityInfoJson,
    iconPath,
    bgPath,
    buttonPath,
}