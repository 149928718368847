import React, {memo, useEffect, useMemo, useState, useRef} from "react";
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import { Input, Radio, Space, Select, Upload, Modal, Checkbox } from "antd";
import s from "./index.module.less"
import {
    getearninfo,
    getProfile,
    formatNumberWithUnit,
    getfriendtrade_rank_selfdata,
    getAvatarFromUserInfo,
    convertWalletBalance,
} from "@/utils/common";
import {
    coinData,
    creatClub,
    chainData,
    airdropTypeData,
    coinTotalSupplyData,
    airdropPecentageData,
    coinToNFTPecentageData,
    calcTotalValue,
    momentChainToken,
    coinList,
    preferencesObj,
    preferencesItems,
    formatAttr,
    defaultAttr,
    momentGenattrs,
    checkAttrs,
    websiteAfterAddon,
    getMomentGenImage,
    calcNumberCardVal,
    setAnony,
    momentPriceFormular,
    getChainInfo,
} from "@/utils/momentClub";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate} from "react-router-dom";
import CrackList from "src/common/CrackList";
import ModelDeposit from "src/model/ModelDeposit";
import ModelRankReward from 'src/model/ModelRankReward';
import RankReward from '@/utils/json/RankReward.json';
import ButtonFactory from "@/common/ButtonFactory";
import MainHeader from "@/common/MainHeader";
import MemeClubPack from "@/common/MemeClubPack";
import Countdown from "src/common/Countdown";
import ModalMemeLotteryDetail from "@/model/ModalMemeLotteryDetail";
import BattleHireFilter from "@/drawer/BattleHireFilter";
import AntModal from "@/common/AntModal";
import MomentImageTemplate from "@/utils/json/momentImageTemplate.json";

const MemeCreatePreview = (
    {
        userInfo,
        clubTicker,
        clubDesc,
        symbolImageId,
        coinId,
        totalClipIndex,
        marketInfo,
        coverUrl,
        paramN,
        startPrice,
        paramA,
        closeFn = () =>{},
        createFn = () =>{}
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const chainCoinInfo = useMemo(() =>{
        return getChainInfo(coinId);
    }, [coinId]);

    const airDropPercent = 80;
    const coinToNftRatio = 10000;

    const priceFormularInfo = useMemo(() =>{
        return momentPriceFormular?.find(v => (v?.chainTokenConfigId === coinId && v?.formulaEnum === 2));
    }, [momentPriceFormular]);


    const supplyAmount = useMemo(() =>{
        return 125000 * totalClipIndex;
    }, [coinToNftRatio, totalClipIndex])

    const startPriceUsd = useMemo(() =>{
        return startPrice * marketInfo?.[chainCoinInfo?.coinName];
    }, [startPrice, marketInfo, chainCoinInfo])

    const tgePrice = useMemo(() =>{
        let paramB = 0;
        let c = priceFormularInfo?.divParamC;
        let d = priceFormularInfo?.mulParamD;
        return (paramN * (paramN + 1) * (2 * paramN + 1) / 6 * paramA + paramN * (paramN + 1) * 2 * paramB)/c * d;
    }, [priceFormularInfo, paramA,paramN]);

    const getSymbolImage = () =>{
        let res = '';
        if (MomentImageTemplate?.length > 0){
            let tmp = MomentImageTemplate.find(v => v?.ID === symbolImageId);
            res = tmp.symbolImageUrl;
        }
        return res;
    }

    const showUsd = (price) => {
        let res = '--';
        if (price > 0.000001){
            res = Number(price.toFixed(6));
        }
        else if (price > 0){
            res = '<0.0000001';
        }
        return res;
    }
    
    return (
        <div className={`${s.wrap} flex_center_end_col`}>
            <div className={`${s.content} flex_center_end_col`}>
                <div className={`${s.media}`}>
                    <div className={`${s.bg}`} style={{backgroundImage: `url(${coverUrl})`}}>
                    </div>
                    <div className={`${s.bgCover}`} style={{backgroundImage: `url(${coverUrl})`}}>
                    </div>
                </div>
                <div className={`${s.topPart} flex_start_between`}>
                    <CustomIcon onClick={()=>{closeFn();}} imgName={'Button/UI_Button_Arrow_01'} className={`${s.backBtn}`} width={30} height={30}/>
                </div>
                <div className={`${s.previewInfoRoot} flex_center_end_col`}>
                    <div className={`${s.packName}`}>
                        {clubTicker}
                    </div>
                    <div className={`${s.packDesc}`}>
                        {clubDesc}
                    </div>
                    <div className={`flex_center_start w100p mt10`}>
                        <div className={`flex_center_start_col mr15`}>
                            <div className={`${s.title}`}>
                                {t('Total Clip Index')}
                            </div>
                            <div className={`${s.bigText}`}>
                                {totalClipIndex}
                            </div>
                        </div>
                        <div className={`flex_center_start_col`}>
                            <div className={`${s.title}`}>
                                {t('Intail Pack Price')}
                            </div>
                            <div className={`${s.bigText}`}>
                                {`${showUsd(startPriceUsd)} USD`}
                            </div>
                        </div>
                    </div>
                    <div className={`flex_center_start mt10 w100p`}>
                        <div className={`fs12 color-blue-light`}>
                            {t('Expect Liquidity to TGE')}
                        </div>
                        <div className={`fs14 color-white ml10`}>
                            {`${convertWalletBalance(tgePrice)} ${chainCoinInfo?.coinName}(${chainCoinInfo?.name})`}
                        </div>
                    </div>
                    <div className={`flex_center_start mt6 w100p`}>
                        <div className={`fs12 color-blue-light textnowrap`}>
                            {`$${clubTicker} ${t('Supply')}`}
                        </div>
                        <div className={`${s.symbolImage}`} style={{backgroundImage: `url(${getSymbolImage()})`}}>
                        </div>
                        <div className={`fs14 color-white ml10`}>
                            {`${supplyAmount > 0 ? supplyAmount.toLocaleString("en-US"):0}(${airDropPercent}% Airdrop)`}
                        </div>
                    </div>
                </div>
                <div className={`${s.bottomPart} flex_center_center`}>
                    <ButtonFactory
                        size={23}
                        isFixed={true}
                        fixedStyle={{
                            useHeight: true,
                            height: 48
                        }}
                        className={`${s.createBtn}`}
                        onClick={() => {
                            createFn();
                        }}>
                        <div className="fs18 fb color-black">
                            {t('Create Moment')}
                        </div>
                    </ButtonFactory>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MemeCreatePreview));
