import React, {useMemo, useEffect, memo, useState, useRef} from "react";
import s from "./index.module.less";
import NFTRarityInfo from "@/utils/json/NFTRarityInfo.json"
import {useTranslation, Trans} from 'react-i18next';
import { formatNumberWithUnit } from "@/utils/common";
import { connect } from "react-redux";

const ModelProtectedIntro = (
    {
        earnInfo
    }   
) => {
    const {t, i18n} = useTranslation();
   

    return (
        <div className={s.content}>
            <div className={s.wrap}>
                <div className={`${s.noticetext} color-white`}>
                    {t('The revenues generated from the NFT missions will first be allocated to the Protected $LFG. When the Protected LFG reaches its cap, any excess earnings will then flow into the LFG POOL')}<br/>
                </div>
                <div className={s.amountWrap}>
                    <table className={s.battleTable}>
                        <thead>
                        <tr className={s.lineHead}>
                            <th className={s.colTier}>{t("Owned NFT")}</th>
                            <th className={s.colRange}>{t("Protected $LFG Cap")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        
                        {NFTRarityInfo?.map((item, index) => {
                            const {name,protectedLFG} = item
                            return (<tr key={index} className={`${s.lineValue}`}>
                                <td>{name}</td>
                                <td>{formatNumberWithUnit(protectedLFG)}</td>
                            </tr>)
                            })
                        }
                        </tbody>
                    </table>
                    <div className="mt20">{t('Current Protected $LFG Cap:')}{earnInfo?.nftProtectLfgMaxLimit}</div>
                </div>
                
                
            </div>
        </div>
    );
};
const mapStateToProps = ({app}) => {
    return {
        earnInfo: app.earnInfo,
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelProtectedIntro));
