import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
// import Banner from "../../../common/Banner";
import {
    stringToNumber,
    convertWalletBalance,
    getFriendtradeLootLastevent,
    searchuser,
    displayFilter,
    getChainTokenInfoById,
    numberClamp,
    sliceStr,
    convertTimestamp,
} from "@/utils/common"
import AntModal from "@/common/AntModal";
import ModalMemePackTrans from "@/model/ModalMemePackTrans";
import ModalKingOfTgeInfo from "@/model/ModalKingOfTgeInfo";
import {
    coinList,
    memeClubList,
    setFavorite,
    getRecommendClub,
    searchclub,
    getChainInfo,
    getMemeKingTgeList,
} from "@/utils/memeClub";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntDrawer from "@/common/AntDrawer";
import MemeChainToken from "@/utils/json/memeChainToken.json";
import { isOnlineEnv } from "@/utils/env";
import {
    gameMemeClubChatPath,
    gameMemeClubCreatPath,
    gameMemeClubDetail,
    gameMemeClubDetailPath,
    gameMemeClubRevealMemePackPath,
} from "@/routes/config";
import AvatarInfo from "@/common/AvatarInfo";
import SearchUser from "@/common/SearchUser";
import SearchUserList from "@/common/SearchUserList";
import MemeClubFilter from "@/drawer/MemeClubFilter";
import MemeClubSort from "@/drawer/MemeClubSort";
import SearchMemeClubList from "@/common/SearchMemeClubList";
import InfiniteScroll from "react-infinite-scroll-component";
import Countdown from "@/common/Countdown";
import {
    Spin,
    Carousel,
    Switch,
} from "antd";
import MemeClubPack from "@/common/MemeClubPack";
import useLocalStorageState from "@/common/useLocalStorageState";

const maxCoinPrice = 1000;
const maxMarketCap = 50000;
const maxFavorite = 200;


const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    return useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);
};

const throttle = (func, limit) => {
    let inThrottle;
    return (...args) => {
      if (!inThrottle) {
        func(...args);
        inThrottle = true;
        setTimeout(() => {
          inThrottle = false;
        }, limit);
      }
    };
  };

const ClubList = (
    {
        userInfo,
        setHideRefreshBtn,
        memeClubFilterList,
        setMemeClubFilterList,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const totalWidth = useMemo(() => {
        return Math.min(window.innerWidth, 500);
    }, [window.innerWidth]);

    const linkTo = (id) => {
        navigate(`${gameMemeClubDetailPath}/${id}`)
    }
    const carouselRef = useRef(null);
    const [updateLootEventTimes, setUpdateLootEventTimes] = useState(0);
    const [lootLastEventData, setLootLastEventData] = useState(null);
    const [showMemePackTrans, setShowMemePackTrans] = useState(false);
    const [showKingOfTgeInfo, setShowKingOfTgeInfo] = useState(false);
    const [memePackTransDone, setMemePackTransDone] = useState(false);
    const [memePackTransSubLoading, setMemePackTransSubLoading] = useState(false);
    const [tradeMemeClubInfo, setTradeMemeClubInfo] = useState(null);
    const [bannerStartDragX, setBannerStartDragX] = useState(0);
    const [lastQueryKotTime, setLastQueryKotTime] = useState(0);
    const [queryKotCount, setQueryKotCount] = useState(0);
    const [lastTgeSwitch, setLastTgeSwitch] = useState(new Date().getTime());
    const [tgeSwitchCount, setTgeSwitchCount] = useState(0);
    const [topTgeList, setTopTgeList] = useState(null);

    const updateLootEvent = () => {
        getFriendtradeLootLastevent().then(data => {
            setLootLastEventData(data?.lastLootEventData);
            setTimeout(() => {
                setUpdateLootEventTimes(updateLootEventTimes + 1);
            }, 10000);
        }).catch(err => {
            console.error(err);
            setTimeout(() => {
                setUpdateLootEventTimes(updateLootEventTimes + 1);
            }, 10000);
        });
    }

    useEffect(() => {
        // updateLootEvent();
    }, [updateLootEventTimes])


    const [searchResult, setSearchResult] = useState([]);
    const [searchKeyValue, setSearchKeyValue] = useState('');
    const [searchCursor, setSearchCursor] = useState(-1);
    const [showSearchResultPanel, setShowSearchResultPanel] = useState(false);

    const searchUserHandle = (searchKey) => {
        if (!searchKey) {
            setHideRefreshBtn(false);
            setShowSearchResultPanel(false);

        } else {
            setHideRefreshBtn(true);
            setShowSearchResultPanel(true);
            setSearchKeyValue(searchKey)
        }
        searchKey && searchclub(searchKey, 0, 0).then(res => {
            setSearchCursor(res?.nextCursor);
            setSearchResult(res?.clubList || []);
        }).catch(e => {
            console.log(e);
        })
    }

    const getMoreSearchResult = () => {
        searchclub(searchKeyValue, searchCursor, 0).then(res => {
            setSearchCursor(res?.data?.nextCursor);
            setSearchResult([
                ...searchResult,
                ...res?.data?.userlist || []
            ]);
        }).catch(e => {
            console.log(e);
        })
    }

    const nativeTokenList = useMemo(() => {
        let list = [];
        if (MemeChainToken?.length > 0) {
            for (let i = 0; i < MemeChainToken?.length; i++) {
                let tokenInfo = MemeChainToken[i];
                if (tokenInfo?.isEnable === 1) {
                    if (isOnlineEnv() && tokenInfo?.isTestChain === 0) {
                        list.push(tokenInfo);
                    }
                    else if (!isOnlineEnv() && tokenInfo?.isTestChain === 1) {
                        list.push(tokenInfo);
                    }
                }
            }
        }
        return list;
    }, [MemeChainToken]);



    // filter sort start

    const [showSort, setShowSort] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const sortData = [
        {
            label: t('Txn Time'),
            key: 0,
        },
        {
            label: t('Pack Price'),
            key: 4,
        },
        {
            label: t('Liquidity Pool'),
            key: 5,
        },
    ]
    const [sortType, setSortType] = useState(0)
    const [coinId, setCoinId] = useState(0)
    const [isAsc, setIsAsc] = useState(false)


    const [filterPackPrice, setFilterPackPrice] = useState([0, -1]);
    const [filterMarketCap, setFilterMarketCap] = useState([0, -1]);
    const [filterTgePro, setFilterTgePro] = useState([0, 100]);
    const [filterFavoriteRange, setFilterFavoriteRange] = useState([0, -1]);
    const [filterOnlyFavorite, setFilterOnlyFavorite] = useState(false);
    const [filterOnlyHeld, setFilterOnlyHeld] = useState(false);
    // filter sort end
    const [list, setList] = useState([])
    const [nextCursor, setNextCursor] = useState('');
    const [loadMoreList,setLoadMoreList] = useState(false)
    const [refreshListCount, setRefreshListCount] = useState(0);
    const getList = ({
        rankType,
        coinId,
        priceRange,
        progressRange,
        favoriteRange,
        marketCapRange,
        onlyFavorite,
        onlyHeld,
        isAsc,
        cursor = ``
    }) => {
        let tempPriceRange = [priceRange[0] * 1000, -1];
        if (priceRange[1] !== -1) {
            tempPriceRange[1] = Math.floor(priceRange[1] * 1000);
        }
        if(cursor){
            setLoadMoreList(true)
        }else{
            setLoadMoreList(false)
        }
        memeClubList({
            rankType,
            coinId,
            priceRange: tempPriceRange,
            progressRange,
            favoriteRange,
            marketCapRange,
            onlyFavorite,
            onlyHeld,
            isAsc,
            cursor
        }).then(res => {
            if (res?.clubList) {
                setList(cursor === '' ? res?.clubList : [
                    ...list,
                    ...(res?.clubList || [])
                ])
            }
            else if (cursor === '') {
                setList([]);
            }
            setNextCursor(res?.nextCursor);
        }).catch(e => {
            console.error(e);
        })
    }

    const favoriteFn = (userId, favorite, type = 0) => {
        setFavorite(userId, favorite).then(res => {
            const { clubUserId, favorite } = res;
            // if(type === 1){
            if (clubUserId === recommendData?.clubUserId) {
                setRecommendData({
                    ...recommendData,
                    isFavorite: favorite
                })
            }
            // }else if(type === 2){
            const _searchResult = searchResult.map(item => {
                if (item.clubUserId === clubUserId) {
                    return {
                        ...item,
                        isFavorite: favorite
                    };
                } else {
                    return item
                }
            });
            setSearchResult(_searchResult)
            // } else{
            const _list = list.map(item => {
                if (item.userId === clubUserId) {
                    return {
                        ...item,
                        isFavorite: favorite
                    };
                } else {
                    return item
                }
            });
            setList(_list)
            // }

        }).catch(e => {
            console.error(e);
        })
    }



    const [recommendData, setRecommendData] = useState(null)

    const getRecommend = () => {
        getRecommendClub().then(res => {
            setRecommendData(res)
            console.log(res);
        }).catch(e => {
            console.error(e);
        })
    }

    const getKingTgeList = () => {
        let coinIdList = [];
        if (coinList.length > 0) {
            for (let i = 0; i < coinList.length; i++) {
                coinIdList.push(coinList[i].value);
            }
        }
        if (coinIdList.length > 0) {
            getMemeKingTgeList({
                coinIds: coinIdList
            }).then(res => {
                setTopTgeList(res?.topTgeList);
            }).catch(e => {
                console.error(e);
            })
        }
    }


    const [isSticky, setIsSticky] = useState(false);
    const stickyRef = useRef(null);
    const containerRef = useRef(null);

    const [rotatingItems, setRotatingItems] = useState({});


    useEffect(() => {
        let filterParam = {
            rankType: sortType,
            coinId: coinId,
            priceRange: filterPackPrice,
            progressRange: filterTgePro,
            favoriteRange: filterFavoriteRange,
            marketCapRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            isAsc: isAsc,
            cursor: ``
        };

        if (memeClubFilterList) {
            if (memeClubFilterList?.rankType) {
                filterParam.rankType = memeClubFilterList?.rankType;
                setSortType(memeClubFilterList?.rankType);
            }
            if (memeClubFilterList?.coinId) {
                filterParam.coinId = memeClubFilterList?.coinId;
                setCoinId(memeClubFilterList?.coinId);
            }
            if (memeClubFilterList?.priceRange) {
                filterParam.priceRange = memeClubFilterList?.priceRange;
                setFilterPackPrice(memeClubFilterList?.priceRange);
            }
            if (memeClubFilterList?.progressRange) {
                filterParam.progressRange = memeClubFilterList?.progressRange;
                setFilterTgePro(memeClubFilterList?.progressRange);
            }
            if (memeClubFilterList?.favoriteRange) {
                filterParam.favoriteRange = memeClubFilterList?.favoriteRange;
                setFilterFavoriteRange(memeClubFilterList?.favoriteRange);
            }
            if (memeClubFilterList?.marketCapRange) {
                filterParam.marketCapRange = memeClubFilterList?.marketCapRange;
                setFilterMarketCap(memeClubFilterList?.marketCapRange);
            }
            if (memeClubFilterList?.onlyFavorite) {
                filterParam.onlyFavorite = memeClubFilterList?.onlyFavorite;
                setFilterOnlyFavorite(memeClubFilterList?.onlyFavorite);
            }
            if (memeClubFilterList?.onlyHeld) {
                filterParam.onlyHeld = memeClubFilterList?.onlyHeld;
                setFilterOnlyHeld(memeClubFilterList?.onlyHeld);
            }
            if (memeClubFilterList?.isAsc) {
                filterParam.isAsc = memeClubFilterList?.isAsc;
                setIsAsc(memeClubFilterList?.isAsc);
            }
        }

        getList(filterParam)
        getRecommend()
    }, [])

    const getMoreList = () => {
        getList({
            rankType: sortType,
            coinId: coinId,
            priceRange: filterPackPrice,
            progressRange: filterTgePro,
            favoriteRange: filterFavoriteRange,
            marketCapRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            isAsc: isAsc,
            cursor: nextCursor
        })
    }

    const applyClubFilter = (item) => {
        let tempCoinId = coinId;
        if (item?.sortType !== 1 || item?.coinId !== 0) {
            tempCoinId = item?.coinId;
            setCoinId(item?.coinId);
        }
        setFilterOnlyFavorite(item?.onlyFavorite);
        setFilterOnlyHeld(item?.onlyHeld);

        let tempPackPrice = [0, -1];
        if (item?.priceRange?.[0] >= 0) {
            tempPackPrice[0] = item?.priceRange?.[0];
        }
        if (item?.priceRange?.[1] < maxCoinPrice) {
            tempPackPrice[1] = item?.priceRange?.[1];
        }
        setFilterPackPrice(tempPackPrice);

        let tempMarketCap = [0, -1];
        if (item?.marketCapRange?.[0] >= 0) {
            tempMarketCap[0] = item?.marketCapRange?.[0];
        }
        if (item?.marketCapRange?.[1] < maxMarketCap) {
            tempMarketCap[1] = item?.marketCapRange?.[1];
        }
        setFilterMarketCap(tempMarketCap);

        let tempFavoriteRange = [0, -1];
        if (item?.favoriteRange?.[0] >= 0) {
            tempFavoriteRange[0] = item?.favoriteRange?.[0];
        }
        if (item?.favoriteRange?.[1] < maxFavorite) {
            tempFavoriteRange[1] = item?.favoriteRange?.[1];
        }
        setFilterFavoriteRange(tempFavoriteRange);

        let tgeProcessRange = [0, 100];
        if (item?.tgeProcess?.[0] >= 0) {
            tgeProcessRange[0] = item?.tgeProcess?.[0];
        }
        if (item?.favoriteRange?.[1] < 100) {
            tgeProcessRange[1] = item?.tgeProcess?.[1];
        }
        setFilterTgePro(tgeProcessRange);

        let filter = {
            rankType: sortType,
            coinId: tempCoinId,
            priceRange: tempPackPrice,
            progressRange: tgeProcessRange,
            favoriteRange: tempFavoriteRange,
            marketCapRange: tempMarketCap,
            onlyFavorite: item?.onlyFavorite,
            onlyHeld: item?.onlyHeld,
            isAsc: isAsc,
        }

        getList({
            ...filter,
            cursor: ''
        })

        setMemeClubFilterList(filter);
    }

    const applyClubSort = (item) => {
        setIsAsc(item?.isAsc);
        setSortType(item?.sortType);
        let sortCoin = coinId;
        /*if (item?.sortType === 4) {
            sortCoin = item?.coinId;
            setCoinId(item?.coinId);
        }*/

        let filter = {
            rankType: item?.sortType,
            coinId: sortCoin,
            priceRange: filterPackPrice,
            progressRange: filterTgePro,
            favoriteRange: filterFavoriteRange,
            marketCapRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            isAsc: item?.isAsc,
        }

        getList({
            ...filter,
            cursor: ''
        })

        setMemeClubFilterList(filter);
    }
    const handleScroll = useDebounce(() => {
        const stickyOffset = stickyRef.current.offsetTop;
        setIsSticky(390 < stickyOffset);
    },100)
    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const reFreshClubInfo = () => {
        getList({
            rankType: sortType,
            coinId,
            priceRange: filterPackPrice,
            progressRange: filterTgePro,
            favoriteRange: [0, -1],
            marketCapRange: [0, -1],
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: false,
            isAsc,
            cursor: ``
        })
    }

    const onCarouselChange = (currentSlide) => {
        //console.log(currentSlide);
    };

    const onBannerDragStart = (e) => {
        if (carouselRef?.current) {
            setBannerStartDragX(e?.screenX);
        }
    }

    const onBannerDragEnd = (e) => {
        if (carouselRef?.current) {
            let endPosX = e?.screenX;
            if (endPosX - bannerStartDragX >= 1) {
                carouselRef.current.prev();
                let nowTime = new Date().getTime();
                setLastTgeSwitch(nowTime);
            }
            else if (bannerStartDragX - endPosX >= 1) {
                carouselRef.current.next();
                let nowTime = new Date().getTime();
                setLastTgeSwitch(nowTime);
            }
            else {
                setShowKingOfTgeInfo(true);
            }
        }
    }

    const getKotInfoByCoinId = (coinid) => {
        let res = {};
        if (topTgeList?.length > 0) {
            for (let i = 0; i < topTgeList?.length; i++) {
                if (topTgeList[i].coinId === coinid) {
                    res = topTgeList[i];
                    break;
                }
            }
        }
        return res;
    }

    const [showCheckFlash, setshowCheckFlash] = useState(false)

    const [flashShow, setFlashShow] = useLocalStorageState('memeClub_flashShow', true)


    // const moveLastToFront = (arr) => {
    //     if (arr.length === 0) return arr;
    //     let lastElement = arr.pop();
    //     arr.unshift(lastElement);
    //     return arr;
    // }

    const changeClubList = () => {
        // const _list = moveLastToFront(list)
        // setList(_list)
        // console.log(`------>`);
        
        setList(prevArray => {
            const newArray = [...prevArray];
            const lastElement = newArray.pop();
            newArray.unshift(lastElement);
            return newArray;
        });
    }

    useEffect(() => {
        if (sortType === 0 && isAsc === false) {
            setshowCheckFlash(true)
        } else {
            setshowCheckFlash(false)
        }
    }, [sortType, isAsc])


    useEffect(() => {
        let timer = null;
        if (list && list?.length && !timer && flashShow && showCheckFlash) {
            timer = setInterval(() => {
                changeClubList()
            }, 6000)
        }
        if (!flashShow || !showCheckFlash) {
            timer && clearInterval(timer)
        }
        return () => {
            timer && clearInterval(timer)
        }
    }, [list, flashShow, showCheckFlash])

    useEffect(() => {
        if (refreshListCount > 0){
            getList({
                rankType: sortType,
                coinId: coinId,
                priceRange: filterPackPrice,
                progressRange: filterTgePro,
                favoriteRange: filterFavoriteRange,
                marketCapRange: filterMarketCap,
                onlyFavorite: filterOnlyFavorite,
                onlyHeld: filterOnlyHeld,
                isAsc: isAsc,
                cursor: ``
            })
        }
    }, [refreshListCount]);

    useEffect(() => {
        let timer = null;
        if (!timer && !loadMoreList) {
            let count = refreshListCount;
            timer = setInterval(() => {
                count++;
                setRefreshListCount(count);
            }, 30000)
        }
        if (loadMoreList) {
            timer && clearInterval(timer)
        }
        
        return () => {
            timer && clearInterval(timer)
        }

    }, [flashShow, nextCursor, showCheckFlash])

    useEffect(() => {
        let nowTime = new Date().getTime();
        if (nowTime - lastQueryKotTime >= 60000){
            setLastQueryKotTime(nowTime);
            getKingTgeList();
        }
        setTimeout(() => {
            setQueryKotCount(queryKotCount + 1);
        }, 1000);
    }, [queryKotCount]);

    useEffect(() => {
        let nowTime = new Date().getTime();
        if (nowTime - lastTgeSwitch >= 8000 && carouselRef?.current){
            carouselRef.current.next();
            setLastTgeSwitch(nowTime);
        }
        setTimeout(() => {
            setTgeSwitchCount(tgeSwitchCount + 1);
        }, 1000);
    }, [tgeSwitchCount]);

    return (
        <div className={`${s.modalWrap} mainWrap commonBlueBg`}>
            <MainHeader leftTitle={t('Explore')} headerType={1} isGameHeader={true} hasBackBtn={false} rightMenuIcon={"Button/UI_Button-D_Home_On"}></MainHeader>
            {
                coinList?.length > 0 ?
                    <div className={`${s.headRoot} flex_center_start_col`} >
                        <Carousel ref={carouselRef} className={`kotBanner ${s.carouselPart}`} afterChange={onCarouselChange}>
                            {
                                coinList.map((item, index) => {
                                    const coinId = item?.value;
                                    const kotInfo = getKotInfoByCoinId(coinId);
                                    const {
                                        topClubUserId,
                                        topSymbolName,
                                        topSymbolImageUrl,
                                        rewardPool,
                                        endingSecond,
                                        nextClubUserId,
                                        nextClubProfile,
                                        nextCurCardNum,
                                        nextTotalCardNum,
                                        nextClubSymbolName,
                                        topTgeWorth,
                                    } = kotInfo || {};
                                    const chainTokenInfo = getChainTokenInfoById(coinId);
                                    const hasNext = nextClubUserId > 0;
                                    let progress = 0;
                                    if (nextCurCardNum >= nextTotalCardNum) {
                                        progress = 100;
                                    }
                                    else if (nextCurCardNum <= 0) {
                                        progress = 0;
                                    }
                                    else {
                                        progress = Math.floor(nextCurCardNum * 100 / nextTotalCardNum);
                                        progress = numberClamp(progress, 5, 95);
                                    }
                                    return (
                                        <div key={`${topClubUserId}_${index}`}>
                                            <div
                                                className={`${s.commonPage} flex_center_start`}
                                                style={{ backgroundImage: `url(/UI/Banner/${chainTokenInfo?.kotBanner}.webp)` }}
                                                onDragStart={onBannerDragStart}
                                                onDragEnd={onBannerDragEnd}
                                                onMouseDown={onBannerDragStart}
                                                onMouseUp={onBannerDragEnd}>
                                                <div className={`${s.kotCurrentPart} flex_center_start_col`}>
                                                    {
                                                        topClubUserId > 0 ?
                                                            <div className={`flex_center_center_col`}>
                                                                <div className={`flex_center_center fb`} style={{ fontSize: `${0.0425 * totalWidth}px` }}>
                                                                    <Trans
                                                                    i18nKey="Hold <icon></icon> {{name}} to Win Prize"
                                                                    defaults="Hold <icon></icon> {{name}} to Win Prize"
                                                                    values={{
                                                                        name: `$ ${topSymbolName}`
                                                                    }}
                                                                    components={{
                                                                        icon: <div className={`${s.coinImageRoot}`} style={{ width: `${0.0718 * totalWidth}px` }}
                                                                        onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                linkTo(topClubUserId)
                                                                            }}
                                                                        >
                                                                            <div className={`${s.coinAvatar}`} style={{ backgroundImage: `url(${topSymbolImageUrl})` }}>
                                                                            </div>
                                                                            <div className={`${s.coinOuter}`} style={{ backgroundImage: `url(/UI/Picture/UI_Picture_CardCoin-Mask_02.png)` }}>
                                                                            </div>
                                                                        </div>
                                                                    }}
                                                                    />
                                                                </div>
                                                                <div className={`flex_center_center w100p`} style={{ fontSize: `${0.0287 * totalWidth}px` }}>
                                                                    {t('Bonding Curve Liq: ')}
                                                                    <div className={`${s.liquidityCoin}`} style={{ backgroundImage: `url(/UI/Picture/${chainTokenInfo?.icon}.png)` }}></div>
                                                                    {convertWalletBalance(topTgeWorth || 0)}
                                                                </div>
                                                            </div>
                                                                :
                                                            <div className={`flex_center_center fb`} style={{ fontSize: `${0.0425 * totalWidth}px` }}>
                                                                {t('Make a Meme TGE to Win Prize')}
                                                            </div>
                                                    }
                                                    <div className={`${s.valuePart} flex_center_center_col`}>
                                                        <div className={`${s.valueLine} flex_center_center`}>
                                                            <div className={`${s.nativeCoin}`} style={{ backgroundImage: `url(/UI/Picture/${chainTokenInfo?.icon}.png)` }}>
                                                            </div>
                                                            <div className={`${s.valueText}`} style={{ fontSize: `${0.1231 * totalWidth}px` }}>
                                                                {convertWalletBalance(rewardPool || 0)}
                                                            </div>
                                                        </div>
                                                        <div className={`${s.endInLine} flex_center_center`}>
                                                            {
                                                                topClubUserId > 0 ?
                                                                    <>
                                                                        {t('End in')}:&nbsp;
                                                                        <Countdown refresh={() => { getKingTgeList(); }} leftSecond={endingSecond} />
                                                                    </> :
                                                                    <>
                                                                        {t('Hold the position for 8 hrs to win.')}
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${s.kotNextPart} flex_center_center_col`}>
                                                    <div className={`${s.kotNextPack}`} style={{ fontSize: `${totalWidth * 0.0307}px` }}>
                                                        {t('Competitor')}
                                                    </div>
                                                    <MemeClubPack
                                                        width={0.1179 * totalWidth}
                                                        height={0.1179 * totalWidth}
                                                        imageUrl={nextClubProfile?.avatarUrl}
                                                        packName={nextClubSymbolName}
                                                        isEmpty={!hasNext}
                                                        onClick={() => {
                                                            nextClubUserId && linkTo(nextClubUserId)
                                                        }}>
                                                    </MemeClubPack>
                                                    {
                                                        hasNext &&
                                                        <div className={`${s.tgeProgressRoot} mintingProgressRoot flex_center_start`}>
                                                            <div className={`${s.tgeProgressContent}`}
                                                                style={{
                                                                    width: progress >= 100 ? '100%' : `${progress}%`
                                                                }}>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <CustomIcon imgName={`Button/UI_Button_QuestionMark_01`} className={s.hintMark} width={15} height={15}></CustomIcon>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Carousel>
                    </div> :
                    <div className={s.head}>
                        <div className={s.headWrap}>
                            {t('CREATE MEME COLLECTION WITH CUSTOM COINS & CARDS!')}
                        </div>
                    </div>
            }
            {/* <div className={s.headEvent}>
                {
                    lootLastEventData?.eventId > 0 &&
                    <div className={s.headWrap}>
                        <CustomIcon width={31.68} height={26.64} className={s.headLineIcon}
                            imgName={'Picture/UI_Picture-Currency_Horn_01'} />
                        <div className={`${s.headLine}`}>
                            <div className={`${s.headScroll} ${s.headScrollAniRoot} flex_center_center textnowrap color-blue-light`}>
                                <Trans
                                    i18nKey="userA Looted userB and earned:"
                                    defaults="<userA>{{userA}}</userA> Looted <userB>{{userB}}</userB> and earned:"
                                    values={{
                                        userA: lootLastEventData?.employeeProfile?.displayName,
                                        userB: lootLastEventData?.managerProfile?.displayName
                                    }}
                                    components={{
                                        userA: <span className="color-white ml5 mr5"></span>,
                                        userB: <span className="color-white ml5 mr5"></span>
                                    }}
                                />
                                <CustomIcon width={20} height={20} className="ml2 mr2"
                                    imgName={'Picture/UI_Picture-Currency_LFG_01'} />
                                <div className={`color-white`}>
                                    {convertWalletBalance(Number(lootLastEventData?.coin)) || 0}
                                </div>
                                <CustomIcon width={20} height={20} className="ml2 mr2"
                                    imgName={'Picture/UI_Picture-Currency_Power_01'} />
                                <div className={`color-white`}>
                                    {lootLastEventData?.bpChangeVal || 0}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div> */}
            <div className={s.modal} ref={containerRef} id="list">
                <div>
                    <div className={s.createBtn}>
                        <ButtonFactory size={23} scale={1.2} onClick={() => {
                            navigate(gameMemeClubCreatPath)
                        }}>
                            <div className="color-black fs22 fb">{t('Create Meme Pack')}</div>
                        </ButtonFactory>
                    </div>

                </div>

                <div className={`wrap mt30 ${isSticky ? 'stickyElmentBg' : 'stickyElment'}`} ref={stickyRef}>
                    {!isSticky &&
                        <>
                            <div className={s.controlBar}>
                                <SearchUser searchUserFn={searchUserHandle} />
                                {/* <div className={s.sweepBtn}>
                                <AntButton className={`btn_public fb`} onClick={() => {
                                    navigate(gameMemeClubCreatPath)
                                }}>
                                    <div className="color-black fs12 fb">{t('Sweep')}</div>
                                </AntButton>
                            </div> */}

                            </div>
                            <div className="flex_center_between w100p">
                                <div className="flex_center_start">

                                    {showCheckFlash &&
                                        <>
                                            <Switch
                                                checked={flashShow}
                                                size="small"
                                                className="commonSwitch"
                                                checkedChildren={<CustomIcon imgName='Picture/UI_Picture_MemeClub_Right_01' width={12}
                                                    height={12} />}
                                                onChange={(checked) => {
                                                    setFlashShow(checked)
                                                }} />
                                            <div className="fs12 ml5">{t('Show animations')}</div>
                                        </>
                                    }

                                </div>

                                <div className={s.r}>
                                    <CustomIcon onClick={() => {
                                        setShowSort(true)
                                    }} imgName={`Button/UI_Button_UpAndDown_01`} className="mr10" width={32} height={32}></CustomIcon>
                                    <CustomIcon onClick={() => {
                                        setShowFilter(true)
                                    }} imgName={`Button/UI_Button_ThreeTieRod_01`} className="mr5" width={32} height={32}></CustomIcon>

                                </div>
                            </div>
                        </>

                    }
                    {isSticky && <div className={`${s.controlBar} w100p`}>
                        <div className="flex_center_start">
                            {showCheckFlash &&
                                <>
                                    <Switch
                                        checked={flashShow}
                                        size="small"
                                        className="commonSwitch"
                                        checkedChildren={<CustomIcon imgName='Picture/UI_Picture_MemeClub_Right_01' width={12}
                                            height={12} />}
                                        onChange={(checked) => {
                                            setFlashShow(checked)
                                        }} />
                                    <div className="fs12 ml5">{t('Show animations')}</div>
                                </>
                            }

                        </div>


                        <div className={s.r}>

                            <CustomIcon onClick={() => {
                                setShowSort(true)
                            }} imgName={`Button/UI_Button_UpAndDown_01`} className="mr10" width={32} height={32}></CustomIcon>
                            <CustomIcon onClick={() => {
                                setShowFilter(true)
                            }} imgName={`Button/UI_Button_ThreeTieRod_01`} className="mr5" width={32} height={32}></CustomIcon>
                            {/* <div className={s.sweepBtn}>
                                <AntButton className={`btn_public fb`} onClick={() => {
                                    navigate(gameMemeClubCreatPath)
                                }}>
                                    <div className="color-black fs12 fb">{t('Sweep')}</div>
                                </AntButton>
                            </div> */}
                        </div>
                    </div>}


                </div>
                <div className={s.content} >
                    <div className={s.wrap}>
                        {
                            showSearchResultPanel &&
                            <SearchMemeClubList
                                favoriteFn={favoriteFn}
                                searchCursor={searchCursor}
                                linkTo={linkTo}
                                searchResult={searchResult}
                                getMoreSearchResult={getMoreSearchResult} />
                        }
                        {
                            !showSearchResultPanel &&
                            <div className={s.list} >
                                <InfiniteScroll
                                    dataLength={list?.length || 0}
                                    next={getMoreList}
                                    hasMore={nextCursor !== ""}
                                    loader={<div
                                        style={{
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    ><Spin /></div>}
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        width: "100%",
                                        justifyContent: "space-between",
                                        alignContent: "flex-start",
                                    }}
                                    scrollableTarget={"list"}
                                >
                                    {
                                        list?.length > 0 &&
                                        list?.map((item, index) => {
                                            const {
                                                buyPrice,
                                                currentCardNum,
                                                historyPrice,
                                                isFavorite,
                                                maxCardNum,
                                                profile,
                                                userId,
                                                totalWorth,
                                                holdNum,
                                                clubSymbolName,
                                                sellPrice,
                                                ftTotalSupply,
                                                airdropPercent,
                                                openPackRatio,
                                                coinToNFTRatio,
                                                isFT,
                                                onAlive,
                                                announceStartTime,
                                                announceTitle,
                                                aliveImage,
                                            } = item
                                            const { displayName, avatarUrl, coinId } = profile;
                                            const range = historyPrice ? Math.round((sellPrice - historyPrice) / historyPrice * 100) : 0;
                                            const _itemCoinObj = getChainInfo(coinId);

                                            const handleFavoriteClick = (userId, isFavorite) => {
                                                setRotatingItems(prevState => ({ ...prevState, [userId]: true }));
                                                setTimeout(() => {
                                                    setRotatingItems(prevState => ({ ...prevState, [userId]: false }));
                                                }, 500);
                                                favoriteFn(userId, !isFavorite);
                                            };

                                            const progressP = Math.round(currentCardNum / maxCardNum * 100);
                                            const bgUrl = progressP > 20 ? progressP > 70 ? '/UI/Picture/UI_Picture_Explore_CardPacks_Effect_01.png' : '/UI/Picture/UI_Picture_Explore_CardPacks_Effect_02.png' : 'none';
                                            
                                            return (
                                                <div className={`${s.item} ${index === 0 && flashShow && showCheckFlash ? s.animate : ''}`} key={`${userId}_${index}`} onClick={() => {
                                                    linkTo(userId)
                                                }}>
                                                    <div className={`${s.packBg}`} style={{
                                                        backgroundImage: `url(${bgUrl})`,
                                                    }}></div>

                                                    <div className={`${s.contentRoot} flex_center_start_col`}>
                                                        <div className={`${s.top} flex_center_start_col`}>
                                                            {
                                                                index === 0 && flashShow && <div className={s.animateBg}></div>
                                                            }
                                                            {
                                                                onAlive ?
                                                                <div className={`${s.liveCard}`} style={{height: `${0.55 * totalWidth}px`, backgroundImage: `url(${aliveImage || avatarUrl})`}}>
                                                                    
                                                                </div> :
                                                                <MemeClubPack
                                                                    width={0.55 * totalWidth}
                                                                    height={0.55 * totalWidth}
                                                                    imageUrl={avatarUrl}
                                                                    packName={''}
                                                                    blackShadow={false} >
                                                                </MemeClubPack>
                                                            }
                                                            {
                                                                onAlive ?
                                                                <div className={`${s.liveDrop}`}>
                                                                    <div className={`ellipse fb fs12 ${s.displayName}`}>{sliceStr(displayName, 15, true)}</div>
                                                                    <div>
                                                                        <CustomIcon
                                                                            onClick={() => {
                                                                                handleFavoriteClick(userId, isFavorite);
                                                                            }}
                                                                            imgName={isFavorite ? 'Picture/UI_Picture_Collect_01' : 'Picture/UI_Picture_Uncollect_01'}
                                                                            className={rotatingItems[userId] ? 'rotate' : ''} 
                                                                            width={16} 
                                                                            height={16}></CustomIcon>
                                                                    </div>
                                                                </div> :
                                                                <div className={s.drop}>
                                                                    <div className={`ellipse fb fs12 ${s.displayName}`}>{sliceStr(displayName, 15, true)}</div>
                                                                    <div>
                                                                        <CustomIcon
                                                                            onClick={() => {
                                                                                handleFavoriteClick(userId, isFavorite);
                                                                            }}
                                                                            imgName={isFavorite ? 'Picture/UI_Picture_Collect_01' : 'Picture/UI_Picture_Uncollect_01'}
                                                                            className={rotatingItems[userId] ? 'rotate' : ''} 
                                                                            width={16} 
                                                                            height={16}></CustomIcon>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className={s.symbol} style={{fontSize: `${0.0359 * totalWidth}px`}}>
                                                                {clubSymbolName}
                                                            </div>
                                                            {
                                                                (!onAlive && announceStartTime > 0) &&
                                                                <div className={`${s.liveScheduleRoot} flex_center_center_col`}>
                                                                    <span className={`fb textnowrap`} style={{fontSize: `${0.0257 * totalWidth}px`}}>
                                                                        {t('Stream Scheduled')}
                                                                    </span>
                                                                    <span className={`textnowrap`} style={{fontSize: `${0.0257 * totalWidth}px`}}>
                                                                        {t('Start at {{time}}', {time: convertTimestamp(announceStartTime)})}
                                                                    </span>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className={s.mid}>
                                                            <div className={s.lp}>
                                                                <div className={s.lpLabel}>LP</div>
                                                                <div className={s.progress}>
                                                                    <div className={s.proVal} style={{
                                                                        left: `${progressP - 100}%`
                                                                    }}></div>
                                                                    <div className={s.lpVal}>
                                                                        <CustomIcon imgName={`Picture/${_itemCoinObj?.icon}`} className="mr5" width={16} height={16}></CustomIcon>
                                                                        {convertWalletBalance(Number(totalWorth))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            onAlive ? 
                                                            <div className={`${s.goLiveBtn} ${s.liveBtnAniRoot} flex_center_center`} onClick={(e) =>{
                                                                e.stopPropagation();
                                                                navigate(`${gameMemeClubDetailPath}/${userId}`)
                                                            }}>
                                                                <CustomIcon imgName={`Picture/UI_Picture_Icon_Live_01`} className="mr5" width={17} height={17}></CustomIcon>
                                                                <span className={`fs14 fb`}>
                                                                    {t('Live')}
                                                                </span>
                                                            </div>:
                                                            <div className={`${s.tradeBtn} flex_center_center`} onClick={(e) =>{
                                                                e.stopPropagation();
                                                                setShowMemePackTrans(true);
                                                                setTradeMemeClubInfo({
                                                                    userId: userId,
                                                                    clubName: profile?.displayName,
                                                                    coinId: coinId,
                                                                    currentCardNum: currentCardNum,
                                                                    maxCardNum: maxCardNum,
                                                                    symbolName: clubSymbolName,
                                                                    holdNum: holdNum,
                                                                    imageUrl: profile?.avatarUrl,
                                                                    ftTotalSupply: ftTotalSupply,
                                                                    airdropPercent: airdropPercent,
                                                                    openPackRatio: openPackRatio,
                                                                    coinToNFTRatio: coinToNFTRatio,
                                                                    isFT: isFT,
                                                                });
                                                            }}>
                                                                <div className={s.price}>
                                                                    <span className="fs14">{holdNum > 0 ? t('Trade') : t('BUY')}</span>
                                                                    <CustomIcon imgName={`Picture/${_itemCoinObj?.icon}`} className="ml2 mr2" width={16} height={16}></CustomIcon>
                                                                    <span>{convertWalletBalance(Number(buyPrice))}</span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </InfiniteScroll>

                            </div>
                        }
                    </div>

                </div>
            </div>

            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowSort(false)}
                width={245}
                open={showSort}
                destroyOnClose={true}
                className="hireSort"
            >
                <MemeClubSort
                    sortData={sortData}
                    nativeTokenList={nativeTokenList}
                    sortType={sortType}
                    setSortType={setSortType}
                    coinId={coinId}
                    setCoinId={setCoinId}
                    isAsc={isAsc}
                    setIsAsc={setIsAsc}
                    coinList={coinList}
                    applyFn={(item) => {
                        applyClubSort(item);
                        setShowSort(false);
                    }}
                />
            </AntDrawer>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowFilter(false)}
                width={245}
                open={showFilter}
                destroyOnClose={true}
                className="hireSort"
            >
                <MemeClubFilter
                    maxCoinPrice={maxCoinPrice}
                    maxMarketCap={maxMarketCap}
                    maxFavorite={maxFavorite}
                    filterPackPrice={filterPackPrice}
                    filterMarketCap={filterMarketCap}
                    filterFavoriteRange={filterFavoriteRange}
                    filterTgePro={filterTgePro}
                    filterOnlyFavorite={filterOnlyFavorite}
                    filterOnlyHeld={filterOnlyHeld}
                    coinId={coinId}
                    applyFn={(item) => {
                        applyClubFilter(item);
                        setShowFilter(false);
                    }}
                    coinList={coinList}
                    isSortByPrice={sortType === 1}
                    showTgeProcess={true}
                    isMarketCap={false}
                />
            </AntDrawer>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={showMemePackTrans}
                destroyOnClose={true}
                onOk={() => setShowMemePackTrans(false)}
                onCancel={() => setShowMemePackTrans(false)}
                keyboard={!memePackTransSubLoading || memePackTransDone}
                maskClosable={!memePackTransSubLoading || memePackTransDone}
            >
                <ModalMemePackTrans
                    type={1}
                    tradeMemeClubInfo={tradeMemeClubInfo}
                    closeHandle={() => {
                        setShowMemePackTrans(false);
                    }}
                    done={memePackTransDone}
                    reFreshClubInfo={reFreshClubInfo}
                    setDone={setMemePackTransDone}
                    subLoading={memePackTransSubLoading}
                    setSubLoading={setMemePackTransSubLoading}
                />
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap"
                centered={true}
                open={showKingOfTgeInfo}
                onOk={() => setShowKingOfTgeInfo(false)}
                onCancel={() => setShowKingOfTgeInfo(false)}
                destroyOnClose={true}
                zIndex={1001}
            >
                <ModalKingOfTgeInfo />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        memeClubFilterList: app.memeClubFilterList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHideRefreshBtn: (params) => {
            return dispatch({
                type: "app/setHideRefreshBtn",
                payload: params,
            });
        },
        setMemeClubFilterList: (params) => {
            return dispatch({
                type: "app/setMemeClubFilterList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ClubList));
