import React, {memo, useState, useMemo, useEffect, useRef, useCallback} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button} from "antd";
import {
    PrivyLoginStatusError, sliceStr, TransactionError,
    getAvatarFromProfile
} from "@/utils/common";
import AvatarInfo from "@/common/AvatarInfo";
import CustomIcon from "../../../common/CustomIcon";
import Data from "moment";
import {redPacketPick} from "@/utils/red_Packet";
import {useWallets} from "@privy-io/react-auth";
import AntButton from "@/common/AntButton";


const Countdown = (
    {
        leftSecond,
        onlyText,
        onEnd,
    }
) => {
    const [leftSeconds, setLeftSeconds] = useState(leftSecond)
    useEffect(() => {
        let intervalId;

        if (leftSeconds <= 0) {
            if (onEnd) onEnd();
            clearInterval(intervalId);
        } else if (leftSeconds !== 0) {
            intervalId = setInterval(() => setLeftSeconds(leftSeconds - 1), 1000);
        }

        return () => clearInterval(intervalId);
    }, [leftSeconds]);

    const leftStr = useMemo(() => {
        if (leftSeconds < 0) return '--'
        if (leftSeconds) {
            let H = Math.floor(leftSeconds / (60 * 60)); //计算小时数
            let m = Math.floor(leftSeconds / (60) % 60); //计算分钟数
            let s = Math.floor(leftSeconds % 60); //计算秒数
            if (H < 10) {
                H = '0' + H
            }
            if (m < 10) {
                m = '0' + m
            }
            if (s < 10) {
                s = '0' + s
            }
            if (onlyText) {
                return H + ':' + m + ':' + s
            }
            return <><span>{H}</span>h&nbsp;<span>{m}</span>m&nbsp;<span>{s}</span>s</>
        } else {
            return ''
        }
    }, [leftSeconds])

    return (
        leftStr
    );
};

const RedPacketMain = (
    {
        currentRedPacket,
        setOpenRedPacketOpenPanel,
        openRedPacketStatus,
        reflushStatus,
        curentOtherUserInfo,
        setOpening,
    }
) => {
    const {t, i18n} = useTranslation();

    const {
        youOwnKeyNum,
    } = useMemo(() => {
        return curentOtherUserInfo || {}
    }, [curentOtherUserInfo])

    const [updateSecond, setUpdateSecond] = useState('0');

    const leftSecond = useMemo(() => {
        return currentRedPacket?.startTime - Data.now() / 1000;
    }, [currentRedPacket, updateSecond])

    const {wallets} = useWallets();
    const [openLoading, setOpenLoading] = useState(false);
    useEffect(() => {
        setOpening(openLoading)
    }, [openLoading]);

    const [done, setDone] = useState(false);
    const [txFailed, setTxFailed] = useState(false);
    const [noSuccessText, setNoSuccessText] = useState(t('FAILED'));


    const [tempStatusOn, setTempStatusOn] = useState(false);
    const [tempStatusOpend, setTempStatusOpend] = useState(false);
    const [tempStatusClaimed, setTempStatusClaimed] = useState(false);
    const [tempStatusEnded, setTempStatusEnded] = useState(false);
    const [tempStatusNotHold, setTempStatusNotHold] = useState(false);
    const openRedPacketTx = () => {
        setOpenLoading(true);
        redPacketPick(wallets, currentRedPacket?.redPacketId).then(r => {
            setTimeout(() => {
                setOpenLoading(false);
                setTxFailed(false);
                setDone(true);
                setTempStatusOpend(true);
                setTempStatusOn(true);
                openRedPacketStatus(currentRedPacket);
                setTimeout(() => {
                    reflushStatus();
                }, 1000);
            }, 3000);
        }).catch(e => {
            let preCheck = false;
            if (e?.reason != null) {
                // RedPackPickDuplidate
                if (e?.reason.indexOf("0xa9e02867") !== -1) {
                    preCheck = true

                    setTempStatusOpend(true);
                    setTempStatusOn(true);

                    openRedPacketStatus(currentRedPacket);
                }

                // RedPackPickAlreadyEndPick RedPackPickAlreadyEndToken
                if (e?.reason.indexOf("0xd2b26953") !== -1 || e?.reason.indexOf("0xb32bc730") !== -1) {
                    preCheck = true
                    setTempStatusClaimed(true);
                    setTempStatusOn(true);
                }

                // RedPackPickAlreadyClaim RedPackAlreadyEndTime
                if (e?.reason.indexOf("0xf01abc22") !== -1 || e?.reason.indexOf("0xc8438433") !== -1) {
                    preCheck = true
                    setTempStatusEnded(true);
                    setTempStatusOn(true);
                }

                // RedPackPickNotHolder
                if (e?.reason.indexOf("0xe9721cae") !== -1) {
                    preCheck = true
                    setTempStatusNotHold(true);
                    setTempStatusOn(true);
                }

                // RedPackNotStartYet
                if (e?.reason.indexOf("0x76a174df") !== -1) {
                    preCheck = true
                    reflushStatus();
                }
            }

            if (preCheck) {
                setOpenLoading(false);
                setTxFailed(false);
                setDone(true);
                return;
            }

            console.log(e);
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }

            if (e === 'need login') {
                setNoSuccessText(PrivyLoginStatusError)
            }

            setOpenLoading(false);
            setTxFailed(true);
            setDone(true);
        })
    }

    const {
        displayName,
    } = currentRedPacket?.senderProfile;

    const avatarUrl = getAvatarFromProfile(currentRedPacket?.senderProfile, 0);
    const originalAvatarUrl = getAvatarFromProfile(currentRedPacket?.senderProfile, 1);

    return (
        <div className={s.wrap} style={{pointerEvents: 'auto'}}>
            <div className={s.topBar}>
                <CustomIcon width={15} height={15} imgName={'icon_close'} name={'icon_close'} onClick={() => {
                    setOpenRedPacketOpenPanel(false);
                }}/>
            </div>
            <div className={s.useinfo}>
                <AvatarInfo size={70} src={originalAvatarUrl || avatarUrl}/>
                <div className={s.usename}>{t('Sent by')} {sliceStr(displayName, 20)}</div>
            </div>

            <div className={s.leftsecond}>
                {leftSecond > 0 && <>
                    <span>{t('It\'s not time yet')}</span>
                    <div className={s.time}>
                        <Countdown leftSecond={leftSecond} onEnd={() => {
                            const timestamp = new Date().getTime();
                            setUpdateSecond(Math.ceil(Math.random() * 1000) + "v" + timestamp);
                        }}/>
                    </div>
                </>}
            </div>

            <div className={s.btnwrap}>
                {youOwnKeyNum > 0 && !currentRedPacket?.isOpen && !tempStatusOn &&
                    <AntButton className={`btn_public fs18 ${s.btn} ${leftSecond > 0 ? `btn_disabled` : ''}`}
                               disabled={leftSecond > 0}
                               loading={openLoading}
                               onClick={openRedPacketTx}>
                        {done ?
                            txFailed ? noSuccessText : t('SUCCESS')
                            : t('OPEN')
                        }
                    </AntButton>}
                {(currentRedPacket?.isOpen || tempStatusOpend) &&
                    <div style={{textAlign: 'center'}}>{t('Opened')}</div>}
                {(youOwnKeyNum === 0 || tempStatusNotHold) && !currentRedPacket?.isOpen &&
                    <div style={{textAlign: 'center'}}>{t('Hold at least 1 key to Open Red Packets')}</div>}
                {tempStatusClaimed &&
                    <div style={{textAlign: 'center'}}>{t('None Left')}</div>}
                {tempStatusEnded &&
                    <div style={{textAlign: 'center'}}>{t('Expired')}</div>}
            </div>
            <br/>
            {<a onClick={() => {
                openRedPacketStatus(currentRedPacket);
            }}>View Details</a>}
        </div>
    );
};

const RedPacketList = (
    {
        redPacketList,
        currentRedPacketIndex,
        setCurrentRedPacketIndex,
    }
) => {
    const {t, i18n} = useTranslation();

    const selectRedPacket = (index) => {
        setCurrentRedPacketIndex(index);
    }

    const moreList = useRef();
    const [overflowCenter, setOverflowCenter] = useState(true);

    useEffect(() => {

        const resize = () => {
            if (moreList.current.scrollWidth > window.innerWidth) {
                setOverflowCenter(false);
            } else {
                setOverflowCenter(true);
            }
        }

        window.addEventListener('resize', resize);
        resize();

        return () => {
            window.removeEventListener('resize', resize);
        }
    })


    return (
        <div className={`${s.overflow} ${overflowCenter ? s.overflowCenter : ''}`} style={{pointerEvents: 'auto'}}>
            <div className={s.moreredpacket} ref={moreList}>
                {redPacketList?.map((item, index) => {

                    const leftSecond = item?.startTime - Data.now() / 1000;

                    const {
                        displayName,
                    } = item?.senderProfile;
                    const avatarUrl = getAvatarFromProfile(item?.senderProfile, 0);
                    const originalAvatarUrl = getAvatarFromProfile(item?.senderProfile, 1);

                    const coinType = item?.coinType;

                    return (
                        <div className={`${s.redpacket_list} ${currentRedPacketIndex === index ? s.scale : ''}`}
                             onClick={() => selectRedPacket(index)}>
                            <div className={s.NumTag}>
                                {coinType === 1 &&
                                    <CustomIcon className=" mr5 mb5" width={30} height={30}
                                                imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                                name={'Picture/UI_Picture-Currency_MATIC_01'}/>}
                                {coinType === 0 &&
                                    <CustomIcon className=" mr5 mb5" width={30} height={30}
                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                name={'Picture/UI_Picture-Currency_LFG_01'}/>}
                            </div>
                            <div className={s.redpacketbox}>
                                <AvatarInfo size={26} src={originalAvatarUrl || avatarUrl}/>
                                <br/>
                                <div className={s.val}>
                                    {leftSecond > 0 ?
                                        <Countdown leftSecond={leftSecond} onlyText={true}/> : <>&nbsp;</>}
                                </div>
                            </div>
                            <span style={{
                                textAlign: 'center',
                                paddingRight: '12px'
                            }}>{sliceStr(displayName, 'all')}'s</span>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

const RedPacketModelOpen = (
    {
        setOpenRedPacketOpenPanel,
        keyId,
        curentOtherUserInfo,
        isSelfChat,
        redPacketList,
        openRedPacketStatus,
        reflushStatus,
        setOpenRedPacketMaskClosable,
    }
) => {
    const [opening, setOpening] = useState(false);
    useEffect(() => {
        setOpenRedPacketMaskClosable(!opening);
    }, [opening]);

    const [currentRedPacketIndex, setCurrentRedPacketIndex] = useState(0);
    const currentRedPacket = useMemo(() => {
        if (!redPacketList || !redPacketList[currentRedPacketIndex]) {
            if (redPacketList && redPacketList.length > 0) {
                setCurrentRedPacketIndex(0);
                return redPacketList[0];
            } else {
                return null;
            }
        }
        return redPacketList[currentRedPacketIndex]
    }, [redPacketList, currentRedPacketIndex]);

    let packetMainList = {};

    const MainView = useCallback(() => {
        if (!currentRedPacket) {
            setOpenRedPacketOpenPanel(false);
            return;
        }

        if (!packetMainList[currentRedPacket]) {
            packetMainList[currentRedPacket] = (
                <RedPacketMain currentRedPacket={currentRedPacket}
                               setOpenRedPacketOpenPanel={setOpenRedPacketOpenPanel}
                               openRedPacketStatus={openRedPacketStatus}
                               reflushStatus={reflushStatus}
                               curentOtherUserInfo={curentOtherUserInfo}
                               setOpening={setOpening}
                />
            );
        }

        return packetMainList[currentRedPacket];
    }, [currentRedPacket])

    return (
        <div style={{overflowY: 'hidden', pointerEvents: 'none'}}>
            <div className={s.redpacketWrap} style={{pointerEvents: 'none'}}>
                <MainView/>
            </div>
            {!opening && <RedPacketList redPacketList={redPacketList}
                                        currentRedPacketIndex={currentRedPacketIndex}
                                        setCurrentRedPacketIndex={setCurrentRedPacketIndex}
            />}
        </div>
    )
}
export default memo(RedPacketModelOpen);
