import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import AntButton from "@/common/AntButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import {
    stringToNumber,
    convertWalletBalance,
    getChainTokenInfoById,
    getTokenBalanceOf,
    GetSupportedChainList,
    getQuotes,
    numberClamp,
    chgFn,
    chainOpenseaUrl,
} from "@/utils/common";
import {
    gameMemeClubDetailPath,
} from "@/routes/config";
import MemeClubPack from "@/common/MemeClubPack";
import { getChainInfo } from "@/utils/memeClub";
const List = (
    {
        list,
        userInfo,
        nextCursor,
        getMoreFn,
        memeDntBalanceList,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {userId} = userInfo;
    return (
        <div className={`${s.memeClubListPart} flex_center_start_col`}>
            <div className={`${s.memeClubListBg} flex_center_start_col`}>
                <div className={s.list} style={{ width: '100%' }} id="clubList">
                    {
                        list?.length > 0 ?
                            <InfiniteScroll
                                dataLength={list?.length || 0}
                                next={()=>{
                                    console.log("nextnextnext");
                                    getMoreFn();
                                }}
                                hasMore={nextCursor !== -1}
                                loader={<div
                                    style={{
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                ><Spin /></div>}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "0",
                                    width: "100%"
                                }}
                                endMessage={
                                    <div style={{
                                        textAlign: "center",
                                        width: "100%",
                                        color: "var(--color-gray)",
                                        fontSize: '12px'
                                    }}></div>
                                }
                                scrollableTarget={'clubList'}
                            >
                                {
                                    list?.map((item, index) => {
                                        const {
                                            clubProfile,
                                            isFavorite,
                                            creatorProfile,
                                            clubUserId,
                                            currentCardNum,
                                            holdNum,
                                            maxCardNum,
                                            creatorUserId,
                                            haveImage = false,
                                            haveMedia = false,
                                            priceUSD,
                                            clubSymbolName
                                        } = item;
                                        let amount = memeDntBalanceList[clubUserId] || 0;
                                        const creatorName = creatorProfile?.displayName;
                                        const state = currentCardNum === maxCardNum;
                                        const progress = parseInt((currentCardNum/maxCardNum) * 100) 
                                        const {avatarUrl,displayName,coinId} = clubProfile;
                                        const {coinName,name} = getChainInfo(coinId);
                                        const isSelf = creatorUserId === userId;
                                        return (
                                            <div key={clubUserId} className={`${s.clubItem} ${index === 0 ? s.fistClub : s.commonClub} flex_center_between`} onClick={() =>{
                                                navigate(`${gameMemeClubDetailPath}/${clubUserId}`);
                                            }}>
                                                <div className={`${s.clubItemLeft} flex_center_start_col`}>
                                                    <MemeClubPack
                                                        width={44} 
                                                        height={68} 
                                                        imageUrl={avatarUrl} 
                                                        packName={clubSymbolName}>
                                                    </MemeClubPack>
                                                    {/*<div className={`${s.clubItemImage}`} style={{ backgroundImage: `url(${avatarUrl})` }}>
                                                        {
                                                            isFavorite ?
                                                                <CustomIcon width={14} height={14} className={s.favoriteIcon}
                                                                    imgName={'Picture/UI_Picture_Collect_01'} /> :
                                                                <CustomIcon width={14} height={14} className={s.favoriteIcon}
                                                                    imgName={'Picture/UI_Picture_Uncollect_01'} />
                                                        }
                                                    </div>*/}
                                                    
                                                </div>
                                                <div className={`${s.clubItemRight}`}>
                                                    <div className={`flex_center_between w100p`}>
                                                        <div className={`flex_center_start_col  w100p`} style={{ minWidth: '165px' }}>
                                                            <div className={`${s.memeClubName}`}>
                                                                {displayName}
                                                            </div>
                                                            <div className={`${s.memeClubCreatorName}`}>
                                                                {`${t('Created By {{sb}}', {sb: creatorName})}`}
                                                            </div>
                                                        </div>
                                                        <div className={`flex_end_center_col`} style={{ minWidth: '65px' }}>
                                                            <div className={`${s.memeClubChainName}`}>
                                                                {name}
                                                            </div>
                                                            <div className={`${s.memeClubCoinName}`}>
                                                                {coinName}
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    {/* <div className={`${s.tgeProgressRoot} flex_center_start`}>
                                                        <div className={`${s.tgeProgress} flex_center_start`}>
                                                            <div className={`${s.l}`}>
                                                                <div className={`${s.tgeProgressContent}`}
                                                                    style={{
                                                                        width: progress >= 100 ? '100%' : `${progress}%`
                                                                    }}>
                                                                </div>
                                                                <div className={`${s.tgeProgressText} flex_center_end`}>
                                                                    {`${progress}%`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            !state ?
                                                                <div className={`${s.holdingPart} flex_center_center`}>
                                                                    <CustomIcon width={18} height={18} className={s.favoriteIcon}
                                                                        imgName={'Picture/UI_Picture_Cardpack_01'} />
                                                                    <span className={`ml2`}>{`x${holdNum}`}</span>
                                                                </div> :
                                                                <div className={`${s.holdingPart} flex_end_center_col fs12`}>
                                                                    <span className="color-blue-light fs12">{`$${clubSymbolName}`}</span>
                                                                    {`${convertWalletBalance(amount || 0)}`}
                                                                </div>
                                                        }
                                                        
                                                    </div> */}
                                                    {
                                                        !state && 
                                                        <>
                                                            <div className={`${s.nftInfo} flex_center_between`}>
                                                                <div className={s.nftI}>
                                                                    {/* <div className="color-blue-light fs10 w100p flex_center_between">
                                                                        {t('NFT Imgage:')}
                                                                        <span className={`fs10 ${haveImage ? 'color-green' : ''}`}>{haveImage ? t('Uploaded') : t('None')}</span>
                                                                    </div> */}
                                                                    {/* {
                                                                        !haveImage &&
                                                                        <div className={s.nftEdit}>
                                                                            <AntButton className="btn_white">
                                                                                {t('Edit Image')}
                                                                            </AntButton>
                                                                        </div>
                                                                    } */}

                                                                </div>
                                                                <div className={s.nftI}>
                                                                    {/* <div className="color-blue-light fs10 w100p flex_center_between">
                                                                        {t('NFT Media:')}
                                                                        <span className={`fs10 ${haveMedia ? 'color-green' : ''}`}>{haveMedia ? t('Uploaded') : t('None')}</span>
                                                                    </div> */}
                                                                    {/* {
                                                                        !haveMedia &&
                                                                        <div className={s.nftEdit}>
                                                                            <AntButton className="btn_white">
                                                                                {t('Edit Media')}
                                                                            </AntButton>
                                                                        </div>
                                                                    } */}

                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        state &&
                                                        <>
                                                            <div className={`${s.coinInfo} `}>
                                                                <div className="mt10 tll">
                                                                    <span className="color-blue-light fs10">{t('Total Coin Value: ')}</span>
                                                                    <span className="fs12">{t('$ xx USD', { val: convertWalletBalance(amount * priceUSD || 0) })}</span>
                                                                </div>
                                                                {
                                                                    (isSelf && holdNum > 0) && 
                                                                    <>
                                                                        <div className={s.revealPack}>
                                                                            {t('You have xx Packs to reveal!', { amount: holdNum })}
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </InfiniteScroll> :
                            <div className={`${s.assetListNoData} flex_center_center`}>
                                <CustomIcon width={111} height={95} className={`mb30`} imgName={'Picture/UI_Picture_NoData_Empty_01'}></CustomIcon>
                                {t('No Data')}
                            </div>
                    }

                </div>
            </div>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo:app.userInfo,
        memeDntBalanceList: app.memeDntBalanceList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(List));
