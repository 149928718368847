import {useEffect, useState} from "react";
import s from "./index.module.less";
import {Button} from "antd";
import CustomIcon from "src/common/CustomIcon";
import {getFriendtrade_loot_employeelist, getPowerXY, stringToNumber} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import {gameTrillionaireMissionPath, mainClubSweepClubsPath} from "@/routes/config";
import {useNavigate} from "react-router-dom";

const ModelTierTip = (
    {
        onClose,
        power,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const [value, setValue] = useState(0);
    return (
        <div style={{padding: "0 10px"}}>
            <div className="df mb40" style={{justifyContent: "center", paddingLeft: "50px"}}>
                <div style={{width: "150px"}}>
                    <div>{t('CLUB VALUE')}</div>
                    <ButtonFactory
                        color={3}
                        // scale={1.3}
                        size={33}
                        iconName="UI_Picture-Currency_MATIC_01"
                    >
                        {getPowerXY(power)}
                    </ButtonFactory>
                </div>
                <div style={{width: "150px"}}>
                    <div style={{marginLeft: "10px"}}>{t('POWER')}</div>
                    <ButtonFactory
                        color={3}
                        // scale={1.3}
                        size={33}
                        iconName="UI_Picture_Power_01"
                    >
                        {(power || '--').toLocaleString("en-US")}
                    </ButtonFactory>
                </div>
            </div>
            <div className="color-white">
                {t('The Club power is determined by 3 factors, X, Y and N')}
                <br/>
                <span className="color-yellow">
                    {t('X = Total value of all Members')}
                    <br/>
                    {t('Y = Total value of all Joined')}
                    <br/>
                    {t('For Wish accounts')}
                    <br/>
                    {t('N = Total supply number of my key')}
                    <br/>
                </span>
                {t('Power Tips 01')}
                <br/>
                {t('Power Tips 02')}
                <br/>
                {t('Club Level Tips')}
            </div>
            <div
                style={{
                    height: "300px",
                    overflow: "auto",
                    width: "fit-content",
                    margin: "10px auto 10px",
                }}
            >
                <CustomIcon
                    width={260}
                    height={450}
                    imgName={"BG/UI_Background-S_Power-Value-Level_01"}
                    imgType = {'.webp'}
                />
            </div>
            <ButtonFactory className="mt20 df aic jcc modelBottomBtn" onClick={() => navigate(`${mainClubSweepClubsPath}/0`)}>
                {t('BUY MORE KEYS TO LEVEL UP')}
            </ButtonFactory>
        </div>
    );
};

export default ModelTierTip;
