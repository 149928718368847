import React, { memo, useCallback, useEffect, useMemo, useRef,useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { Button, Checkbox, Spin, message, Modal, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import {
    stringToNumber,
    convertWalletBalance,
    getChainTokenInfoById,
    getTokenBalanceOf,
    GetSupportedChainList,
    getQuotes,
    numberClamp,
    refreshAvatar,
    getuserinfo,
    updateUserInfo,
    getAvatarFromUserInfo,
    getProfile,
    isSafari,
} from "@/utils/common";
import {AutoStrangeRule} from "@/utils/strangeRule";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";
import ModelDeposit from "@/model/ModelDeposit";
import MomentAssetClubSort from "@/drawer/MomentAssetClubSort";
import MomentChainToken from "@/utils/json/momentChainToken.json";
import ModalMomentAssetNewPack from "@/model/ModalMomentAssetNewPack";
import ModalMomentEarning from "@/model/ModalMomentEarning";
import { 
    userTabList,
    getMomentSelfHoldList,
    getMomentClubDntList,
    getMomentTgeCanopenList,
} from "@/utils/momentClub";
import { getBalance } from "@/utils/uniswapWeb3";
// import MomentClubPack from "@/common/MomentClubPack";
import List from "./List";
import { useWallets } from "@privy-io/react-auth";
import AssetList from "./AssetList";
import AvatarInfo from "@/common/AvatarInfo";
import { 
    linkPath,
    gameMomentClubDetailPath,
} from "@/routes/config";
import ModelChangeAvatar from "@/model/ModelChangeAvatar";
const LINKDATA = [
    {
        label: 'X (TWITTER)',
        icon: 'Button/UI_Button_Ball-White_Twitter',
        activeIcon: "Button/UI_Button_Ball-White_Twitter_On",
        available: true,
        type: 1,
    }, {
        label: 'TIKTOK',
        icon: 'Button/UI_Button_Ball-White_Snapchat_Unlock',
        activeIcon: "Button/UI_Button_Ball-White_Snapchat",
        available: false,
        type: 2,
    }, {
        label: 'INSTAGRAM',
        icon: 'Button/UI_Button_Ball-White_Tiktok_Unlock',
        activeIcon: "Button/UI_Button_Ball-White_Tiktok",
        available: false,
        type: 3,
    }, {
        label: 'SNAPCHAT',
        icon: 'Button/UI_Button_Ball-White_Instagram_Unlock',
        activeIcon: "Button/UI_Button_Ball-White_Instagram",
        available: false,
        type: 4,
    },
]
const MomentAssets = (
    {
        userInfo,
        walletBalanceList,
        setWalletBalanceList,
        DntBalanceList,
        setMomentDntBalanceList,
        lastUpdateMomentDntBalanceListTime,
        setLastUpdateMomentDntBalanceListTime,
        AssetParam,
        setMomentAssetParam,
        momentAssetParam,
        momentAssetUnpackList,
        momentDntBalanceList,
        setSwitchVideoType,
        setMemeclubSwitchVideoParams,
        setSwitchVideoListCursor,
        setSwitchVideoList,
        setCurrentMomentClubId,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {wallets} = useWallets()
    const [momentClubsShowList, setMomentClubsShowList] = useState([]);
    const [assetDntList, setAssetDntList] = useState([]);
    const [assetDntNextCursor, setAssetDntNextCursor] = useState(-1);
    const [updateDntListCount, setUpdateDntListCount] = useState(0);
    const [assetPackList, setAssetPackList] = useState([]);
    const [openablePackList, setOpenablePackList] = useState([]);
    const [assetPackNextCursor, setAssetPackNextCursor] = useState(-1);
    const [openablePackNextCursor, setOpenablePackNextCursor] = useState(-1);
    const [showMomentClubSort, setShowMomentClubSort] = useState(false);
    const [nextCursor, setNextCursor] = useState(-1);
    const [sortValue, setSortValue] = useState({sortType:1, sortOrder:0});
    const [marketInfo, setMarketInfo] = useState(null);
    const [highlightPackItemId, setHighlightPackItemId] = useState(0);
    const [showMomentAssetNewPack, setShowMomentAssetNewPack] = useState(false);
    const [showMomentEarningTip, setShowMomentEarningTip] = useState(false);
    const {
        walletAddr,
        topUpCode,
        userId,
        referralTotalEarn,
        platformData,
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const {
        momentEarnEth
    } = useMemo(() => {
        return selfData || {};
    }, [selfData]);

    const {displayName, accountName, profileDeepLink,avatarUrl,originalAvatarUrl} =  useMemo(() =>{
        return getProfile(platformData) || {}
    },[platformData])

    const {useType} = useMemo(() => {
        return platformData || {}
    }, [platformData])
    const [lock, setLock] = useState(false)
    const refresh = () => {
        !lock && refreshAvatar().then(() => {
            setLock(true)
            setTimeout(() => {
                setLock(false)
            }, 5000)
            getuserinfo(userId).then(res => {
                updateUserInfo(res)
            })
        }).catch(e => {

        })

    }

    const [showChangeAvatar, setShowChangeAvatar] = useState(false)
    
    const unreadMomentAssetNewPackNum = useMemo(() =>{
        let res = 0;
        if (momentAssetUnpackList?.length > 0){
            for (let i = 0; i < momentAssetUnpackList?.length; i++){
                if (!momentAssetUnpackList[i].isRead){
                    res++;
                }
            }
        }
        return res;
    }, [momentAssetUnpackList])
    
    // avatarUrl: "https://pbs.twimg.com/profile_images/1717072816691113984/FL9cjV62_bigger.jpg",
    // isFavorite: true,
    // name: "MomentClub_Name1",
    // creatorName: "CreatorName1",
    // chainName: "Polygon",
    // coinName: "$Matic",
    // progress: 66,
    // holdingNum: 12,
    // state: 0,
    // currentyAmount: 0,
    // totalValue: 666,
    // haveImage: false,
    // haveMedia: false,
    // unrevealCardNum: 0,

    const getWalletBalance = () =>{
        let tokenList = [];
        if (MomentChainToken?.length > 0){
            for(let i = 0; i < MomentChainToken?.length; i++){
                let tokenInfo = MomentChainToken[i];
                if (tokenInfo?.isEnable){
                    tokenList.push(tokenInfo?.ID);
                }
            }
        }
        getTokenBalanceOf(walletAddr, tokenList).then(res => {
            setWalletBalanceList(res?.balances);
        }).catch(err => {
            console.error(err.message);
        })
    }

    const getMarketInfo = () =>{
        let requestList = [];
        if (MomentChainToken?.length > 0){
            for(let i = 0; i < MomentChainToken?.length; i++){
                let tokenItem = MomentChainToken[i];
                if (tokenItem && !requestList.includes(tokenItem?.coinName)){
                    requestList.push(tokenItem?.coinName);
                }
            }
        }
        getQuotes(requestList).then(res => {
            setMarketInfo(res);
        }).catch(e => {
            console.log(e);
        })
    }

    const nativeTokenList = useMemo(() =>{
        let list = [];
        if (MomentChainToken?.length > 0){
            for(let i = 0; i < MomentChainToken?.length; i++){
                let tokenInfo = MomentChainToken[i];
                if (tokenInfo?.isEnable){
                    if (walletBalanceList?.[tokenInfo?.ID]){
                        tokenInfo.balance = walletBalanceList?.[tokenInfo?.ID];
                    }
                    if (marketInfo?.quotes?.[tokenInfo?.coinName]){
                        tokenInfo.quotes = marketInfo?.quotes?.[tokenInfo?.coinName];
                    }
                    list.push(tokenInfo);
                }
            }
        }
        return list;
    }, [MomentChainToken, walletBalanceList, marketInfo]); 

    const nativeAssetValue = useMemo(() =>{
        let res = 0;
        if (nativeTokenList?.length > 0){
            for (let i = 0; i < nativeTokenList?.length; i++){
                res += (nativeTokenList[i]?.balance || 0) * (nativeTokenList[i]?.quotes || 0);
            }
        }
        return res;
    }, [nativeTokenList]);

    const ftList = useMemo(() => {
        return {
            dnt: assetDntList,
            native: nativeTokenList,
        }
    }, [assetDntList, nativeTokenList])

    const dntAssetValue = useMemo(() =>{
        let res = 0;
        if (assetDntList?.length > 0){
            for (let i = 0; i < assetDntList?.length; i++){
                let tempBalance = Number(assetDntList[i]?.balance);
                let tempExchangeRate = assetDntList[i]?.exchangeRate;
                if (tempBalance > 0 && tempExchangeRate > 0){
                    res += tempBalance * tempExchangeRate;
                }
            }
        }
        return res;
    }, [assetDntList, updateDntListCount]);

    const packAssetValue = useMemo(() =>{
        let res = 0;
        if (assetPackList?.length > 0){
            for (let i = 0; i < assetPackList?.length; i++){
                let tempPackInfo = assetPackList[i];
                let coinConfig = getChainTokenInfoById(tempPackInfo?.clubProfile?.coinId);
                let coinQuotes = marketInfo?.quotes?.[coinConfig?.coinName];
                if (tempPackInfo?.holdNum > 0 && tempPackInfo?.sellPrice > 0 && coinQuotes > 0){
                    res += tempPackInfo?.holdNum * tempPackInfo?.sellPrice * coinQuotes;
                }
            }
        }
        return res;
    }, [assetPackList, marketInfo]);

    const [totalType, setTotalType] = useState(0);//0:assets, 1:collections
    const [walletAssetsType, setWalletAssetsType] = useState(3);//0:FT,1:DNT,2:Pack,3:new FT
    const [momentClubShowType, setMomentClubShowType] = useState(1);//1:created,2:facorited,3:holdOpenablePack
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [shortTypeListIndex, setShortTypeListIndex] = useState(0);//0:none,1:token,2:moments

    const getList = ({rankTabType=1,rankSortType=1,isAsc=true,cursor=0})=>{
        userTabList({rankTabType,rankSortType,isAsc,cursor}).then(res=>{
            const _list = cursor === 0 ? res?.dataList || [] : [
                ...momentClubsShowList,
                ...(res?.dataList || [])
            ]
            setMomentClubsShowList(_list)
            setNextCursor(res?.nextCursor)
        }).catch(e=>{
            console.error(e);
        })
    }

    const getMoreFn = () =>{
        getList({
            rankTabType:momentClubShowType,
            rankSortType: sortValue?.sortType,
            isAsc: sortValue?.sortOrder === 0,
            cursor:nextCursor,
        })
    }

    const getPackList = (cursor = 0)=>{
        let skipClubUserId = 0;
        if (momentAssetParam?.clubUserId > 0){
            skipClubUserId = momentAssetParam?.clubUserId;
        }
        getMomentSelfHoldList({
            cursor: cursor,
            skipClubUserId: skipClubUserId,
        }).then(res=>{
            if (skipClubUserId > 0 && momentAssetParam?.page === 2){
                setHighlightPackItemId(skipClubUserId);
                let newParam = {...momentAssetParam};
                newParam.page = -1;
                setMomentAssetParam(newParam);
            }

            let _list = [];
            if (res?.skipClubUnit){
                _list.push(res?.skipClubUnit);
            }
            if (cursor !== 0){
                for (let i = 0; i < assetPackList?.length; i++){
                    _list.push({...assetPackList[i]});
                }
            }
            for (let i = 0; i < res?.clubList?.length; i++){
                if (res?.clubList?.[i]?.clubUserId !== res?.skipClubUnit?.clubUserId){
                    _list.push({...res?.clubList[i]});
                }
            }
            setAssetPackList(_list);
            setAssetPackNextCursor(res?.nextCursor);
        }).catch(e=>{
            console.error(e);
        })
    }

    const getMorePackFn = () =>{
        getPackList(assetPackNextCursor);
    }

    const getDntList = (cursor = 0)=>{
        getMomentClubDntList({cursor}).then(res=>{
            if (momentAssetParam?.page === 1){
                let newParam = {...momentAssetParam};
                newParam.page = -1;
                setMomentAssetParam(newParam);
            }
            setAssetDntNextCursor(res?.nextCursor || -1);
            
            let needRefreshMomentBalance = false;
            let nowTime = new Date().getTime();
            let newClubList = res?.clubList;
            if (nowTime - lastUpdateMomentDntBalanceListTime >= 30000){
                needRefreshMomentBalance = true;
            }
            else if(newClubList?.length > 0){
                for (let i = 0; i < newClubList?.length; i++){
                    let balanceInfo = momentDntBalanceList?.[newClubList[i]?.clubUserId];
                    if (balanceInfo == null){
                        needRefreshMomentBalance = true;
                        break;
                    }
                }
            }
            if (needRefreshMomentBalance){
                let maxCount = newClubList?.length;
                let newMomentBalanceList = {};
                let count = 0;
                for (let i = 0; i < newClubList?.length; i++){
                    let tempClubInfo = newClubList[i];
                    let tokenInfo = getChainTokenInfoById(tempClubInfo?.coinId);
                    if (tokenInfo){
                        getBalance({ wallets, addr: tempClubInfo?.contractAddr, chainId: tokenInfo?.chainId }).then(res=>{
                            newMomentBalanceList[tempClubInfo?.clubUserId] = res;
                            for (let j = 0; j < newClubList?.length; j++){
                                if (newClubList[j]?.clubUserId === tempClubInfo?.clubUserId){
                                    newClubList[j].balance = res;
                                    break;
                                }
                            }
                            count++;
                            if (count >= maxCount){
                                setAssetDntList(newClubList);
                                setUpdateDntListCount(updateDntListCount + 1);
                                setMomentDntBalanceList(newMomentBalanceList);
                                let newNowTime = new Date().getTime();
                                setLastUpdateMomentDntBalanceListTime(newNowTime);
                            }
                        }).catch(e=>{
                            console.error(e);
                            count++;
                            if (count >= maxCount){
                                setAssetDntList(newClubList);
                                setUpdateDntListCount(updateDntListCount + 1);
                                setMomentDntBalanceList(newMomentBalanceList);
                                let newNowTime = new Date().getTime();
                                setLastUpdateMomentDntBalanceListTime(newNowTime);
                            }
                        })
                    }
                }
            }
            else{
                if (newClubList?.length > 0){
                    for (let i = 0; i < newClubList?.length; i++){
                        newClubList[i].balance = momentDntBalanceList?.[newClubList[i].clubUserId];
                    }
                    setAssetDntList(newClubList);
                }
            }
        }).catch(e=>{
            console.error(e);
        })
    }

    const getMoreDntFn = () =>{
        getDntList(assetDntNextCursor);
    }

    const getOpenablePackList = (cursor = 0)=>{
        getMomentTgeCanopenList({cursor: cursor}).then(res=>{
            const _list = cursor === 0 ? res?.dataList || [] : [
                ...openablePackList,
                ...(res?.dataList || [])
            ]
            setOpenablePackList(_list);
            setOpenablePackNextCursor(res?.nextCursor);
        }).catch(e=>{
            console.error(e);
        })
    }

    const getMoreOpenablePackFn = () =>{
        getOpenablePackList(openablePackNextCursor);
    }

    useEffect(()=>{
        getList({
            rankTabType:momentClubShowType,
            rankSortType: sortValue?.sortType,
            isAsc: sortValue?.sortOrder === 0,
            cursor: 0,
        })
    },[momentClubShowType])

    useEffect(() => {
        if (momentAssetParam?.videoPage > 0){
            setTotalType(1);
            setMomentClubShowType(momentAssetParam?.videoPage);
            let newParam = {...momentAssetParam};
            newParam.videoPage = -1;
            setMomentAssetParam(newParam);
        }
    }, [momentAssetParam])

    useEffect(()=>{
        if (walletAssetsType === 1){
            getDntList(0);
        }
        else if (walletAssetsType === 2){
            getPackList(0);
        }
        else if (walletAssetsType === 3){
            getDntList(0);
        }
    },[walletAssetsType]);

    useEffect(() =>{
        getWalletBalance();
        getMarketInfo();
        getOpenablePackList();
        getDntList();
        if (momentAssetParam?.page === 1 || momentAssetParam?.page === 2){
            setWalletAssetsType(momentAssetParam?.page);
        }
        else{
            getPackList(0);
        }
    }, []);

    const items = [
        {
            key: 1,
            label: `${AutoStrangeRule(t('Ctreated'))}`,
            children: <List list={momentClubsShowList} nextCursor={nextCursor} getMoreFn={getMoreFn}></List>,
        }, {
            key: 2,
            label: `${AutoStrangeRule(t('Favorited'))}`,
            children: <List list={momentClubsShowList} nextCursor={nextCursor} getMoreFn={getMoreFn}></List>,   
        }, {
            key: 3,
            label: `${AutoStrangeRule(t('Holding Pack & Coin'))}`,
            children: <List list={momentClubsShowList} nextCursor={nextCursor} getMoreFn={getMoreFn}></List>,   
        }
    ]

    const convertPriceValue = (value) =>{
        if (value !== undefined && value !== null){
            if (value < 0.00001){
                return '<0.00001';
            }
            else if (value < 10){
                return `$${value.toFixed(5)}`;
            }
            else {
                return `$${Number(value.toFixed(2)).toLocaleString("en-US")}`;
            }
        }
        return '--';
    }

    const linkToClub = (clubId, switchType = 0) => {
        setSwitchVideoType(switchType);
        setCurrentMomentClubId(clubId);
        navigate(`${gameMomentClubDetailPath}`);
    }

    const videoLinkToMoment = (item, showType) => {
        let clubList = momentClubsShowList;
        if (showType === 3){
            clubList = openablePackList;
        }
        let _list = [];
        if (clubList.length > 0) {
            for (let i = 0; i < clubList.length; i++) {
                _list.push({
                    clubId: clubList[i].clubUserId,
                    momentAuditCode: clubList[i].clubProfile.momentAuditCode,
                    videoId: clubList[i].videoId,
                    videoUrlMap: clubList[i].videoUrlMap,
                    coverUrl: clubList[i].clubProfile.avatarUrl,
                    videoOriHeight: clubList[i].videoOriHeight,
                    videoOriWidth: clubList[i].videoOriWidth,
                    videoFirstFrameUrl: clubList[i].videoFirstFrameUrl,
                });
            }
        }
        setSwitchVideoList(_list);
        if (showType === 1){
            //created
            setSwitchVideoType(4);
            setSwitchVideoListCursor(nextCursor);
        }
        else if (showType === 2){
            //favorited
            setSwitchVideoType(5);
            setSwitchVideoListCursor(nextCursor);
        }
        else if (showType === 3){
            //unclaimed
            setSwitchVideoType(3);
            setSwitchVideoListCursor(openablePackNextCursor);
        }
        else{
            setSwitchVideoType(-1);
            setSwitchVideoListCursor(-1);
        }
        setMemeclubSwitchVideoParams({
            tarUserId: userId
        });
        setCurrentMomentClubId(item?.clubUserId);
        navigate(`${gameMomentClubDetailPath}`);
    }

    return (
        <div className={`${s.modalWrap} mainWrap`}>
            <MainHeader leftTitle={t('Profile')} headerType={3} isGameHeader={true} hasBackBtn={false} openType={2}></MainHeader>
            <div className={s.head}>
                <div className={s.ht}>
                    <div className={s.avatar}>
                        <AvatarInfo src={avatarUrl || originalAvatarUrl} size={66} isMoment={true}></AvatarInfo>
                        {/*<div className={s.changeAvatar} onClick={() => {
                            setShowChangeAvatar(true)
                        }}>
                            <CustomIcon width={22} height={22}
                                        imgName='Button/UI_Button_Refresh'/>
                        </div>*/}

                    </div>
                    <div className={s.htr}>
                        <div className="fs18 momentFont fb color-EEEEEE">{displayName}</div>
                        <div className="fs12 momentFont color-moment-gray">{accountName}</div>
                        <div className="fs12 momentFont color-moment-gray">UID:{userId}</div>
                    </div>
                </div>
                <div className={s.hb}>
                    <div className={s.list}>
                        {LINKDATA?.map((item, index) => {
                            const {label, icon, available, type, activeIcon} = item
                            return (
                                <div className={s.item} key={index}>
                                    <div className={`f1 ${s.left}`}>
                                        <CustomIcon width={22} height={22}
                                                    imgName={useType === type ? activeIcon : icon}/>
                                    </div>

                                    {/* <AntButton onClick={() => openLinkPanel(label, available)} className={`${available ? 'btn_public' : 'btn_gray'} ${s.btn}`} shape="round">{available ? t('LINK') : t('COMING SOON')}</AntButton> */}
                                </div>
                            )
                        })}

                    </div>
                    {
                        !profileDeepLink &&
                        <div 
                            className={`${s.linkBtn} flex_center_center`}
                            onClick={() => navigate(`${linkPath}/${2}`)}
                        >
                            {t('LINK SOCIALS')}
                        </div>
                    }
                </div>
            </div>
            <div className={s.modal}>
                <div className={`${s.topbar} flex_center_between`}>
                    <div className={`${s.topbarBtn} ${totalType === 0 ? s.selecedType : s.unselectedType} momentFont fb flex_center_center`}
                        onClick={() => {
                            setTotalType(0);
                        }}>
                        {AutoStrangeRule(t('Assets1'))}
                    </div>
                    <div className={`${s.topbarBtn} ${totalType === 1 ? s.selecedType : s.unselectedType} momentFont fb flex_center_center`}
                        onClick={() => {
                            setTotalType(1);
                        }}>
                        {t('My Creations')}
                    </div>
                    <div className={`${s.momentAssetRedPointRoot}`}>
                        <CustomIcon width={28} height={28} imgName={'Picture/UI_Picture_icon_Notification'} onClick={() =>{
                            setShowMomentAssetNewPack(true);
                        }}/>
                        {
                            unreadMomentAssetNewPackNum > 0 && 
                            <div className={`${s.momentAssetRedPoint} flex_center_center`}>
                                <span className={`${s.momentAssetRedPointText}`}>
                                    {unreadMomentAssetNewPackNum > 99 ? '99+':unreadMomentAssetNewPackNum}
                                </span>
                            </div>
                        }
                    </div>
                </div>
                {
                    totalType === 0 && 
                    <>
                        <div className={`${s.totalValueRoot} flex_center_start_col`}>
                            <div className={`${s.totalValueRootBg} flex_center_between`}>
                                <div className={`${s.totalValueRootLeft} flex_center_center_col`}>
                                    <div className={`${s.totalAssetText} momentFont color-moment-gray`}>
                                        {t('Total Aseets Value')}
                                    </div>
                                    <div className={`${s.totalAssetValue} momentFont`}>
                                        {`$ ${(nativeAssetValue + dntAssetValue + packAssetValue).toLocaleString("en-US")} USD`}
                                    </div>
                                </div>
                                <div className={`${s.totalValueDepositBtn} momentFont flex_center_center`} onClick={() =>{
                                    setShowDepositPanel(true);
                                }}>
                                    {AutoStrangeRule(t('DEPOSIT'))}
                                </div>
                            </div>
                        </div>
                        <div className={`${s.scrollList} flex_center_start_col`} style={{maxHeight: `${window.innerHeight - 370}px`}}>
                            {
                                shortTypeListIndex !== 2 &&
                                <>
                                    <AssetList 
                                        linkToClub={linkToClub}
                                        walletAssetsType={3}
                                        list={ftList}
                                        marketInfo={marketInfo}
                                        nextCursor={assetDntNextCursor}
                                        getMoreFn={getMoreDntFn}
                                        shortType={shortTypeListIndex !== 1}
                                    />
                                    <div className={`${s.listArrow} w100p flex_center_center`}>
                                        {
                                            shortTypeListIndex === 1 &&
                                                <div className={`${s.showBtn} flex_center_center`} onClick={() => {
                                                    setShortTypeListIndex(0);
                                                }}>
                                                    <CustomIcon width={18} height={18} className={`mr10`} imgName={'Button/UI_Button_Arrow_Up_02'}/>
                                                    <div className={`fs14 color-EEEEEE fw-400`}>
                                                        {t('Hide more')}
                                                    </div>
                                                </div>
                                        }
                                        {   
                                            (shortTypeListIndex === 0 && ((ftList.native?.length || 0) + (ftList.dnt?.length || 0)) > 3) &&
                                                <div className={`${s.showBtn} flex_center_center`} onClick={() => {
                                                    setShortTypeListIndex(1);
                                                }}>
                                                    <CustomIcon width={18} height={18} className={`mr10`} imgName={'Button/UI_Button_Arrow_Down_02'}/>
                                                    <div className={`fs14 color-EEEEEE fw-400`}>
                                                        {t('Show more')}
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </>
                            }
                            {
                                shortTypeListIndex !== 1 &&
                                <>
                                    <AssetList 
                                        linkToClub={linkToClub}
                                        walletAssetsType={2}
                                        list={assetPackList}
                                        marketInfo={marketInfo}
                                        nextCursor={assetPackNextCursor}
                                        getMoreFn={getMorePackFn}
                                        highlightPackItemId={highlightPackItemId}
                                        shortType={shortTypeListIndex !== 2}
                                    />
                                    <div className={`${s.listArrow} w100p flex_center_center`}>
                                        {
                                            shortTypeListIndex === 2 &&
                                                <div className={`${s.showBtn} flex_center_center`} onClick={() => {
                                                    setShortTypeListIndex(0);
                                                }}>
                                                    <CustomIcon width={18} height={18} className={`mr10`} imgName={'Button/UI_Button_Arrow_Up_02'}/>
                                                    <div className={`fs14 color-EEEEEE fw-400`}>
                                                        {t('Hide more')}
                                                    </div>
                                                </div>
                                        }
                                        {
                                            (shortTypeListIndex === 0 && assetPackList?.length > 3) &&
                                                <div className={`${s.showBtn} flex_center_center`} onClick={() => {
                                                    setShortTypeListIndex(2);
                                                }}>
                                                    <CustomIcon width={18} height={18} className={`mr10`} imgName={'Button/UI_Button_Arrow_Down_02'}/>
                                                    <div className={`fs14 color-EEEEEE fw-400`}>
                                                        {t('Show more')}
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
                {
                    totalType === 1 && 
                    <>
                        <div className={`${s.totalEarningRoot} flex_center_start_col`}>
                            <div className={`${s.totalValueRootBg} flex_center_between`}>
                                <div className={`${s.totalValueRootLeft} flex_center_center_col`}>
                                    <div className={`w100p flex_center_start`}>
                                        <div className={`${s.totalAssetText} momentFont color-moment-gray`}>
                                            {t('Total earning from your moments')}
                                        </div>
                                        <CustomIcon width={13} height={13} imgName={`Button/UI_Button_QuestionMark_04`} onClick={() => {
                                            setShowMomentEarningTip(true);
                                        }}/>
                                    </div>
                                    <div className={`w100p flex_center_start`}>
                                        <CustomIcon width={20} height={20} className={`mr8`} imgName={`Picture/UI_Picture-Currency_ETH_02`}/>
                                        <div className={`${s.totalAssetValue} momentFont`}>
                                            {`${(momentEarnEth).toLocaleString("en-US")} ETH`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${s.walletAssetsTypeLine} flex_center_start`}>
                            <div className={`${s.walletAssetsTypeBtn} flex_center_start_col`}
                                onClick={() => {
                                    if (momentClubShowType !== 1){
                                        setNextCursor(0);
                                        setMomentClubShowType(1);
                                    }
                                }}>
                                <div className={`${ momentClubShowType === 1 ? s.selecedTypeSubline : s.unselectedTypeSubline} momentFont textnowrap`}>
                                    {t('Created')}
                                </div>
                                {
                                    momentClubShowType === 1 && 
                                    <div className={`${s.walletAssetsTypeBtnSubline}`}>
                                    </div>
                                }
                            </div>
                            <div className={`${s.walletAssetsTypeBtn} flex_center_start_col`}
                                onClick={() => {
                                    if (momentClubShowType !== 2){
                                        setNextCursor(0);
                                        setMomentClubShowType(2);
                                    }
                                }}>
                                <div className={`${ momentClubShowType === 2 ? s.selecedTypeSubline : s.unselectedTypeSubline} momentFont textnowrap`}>
                                    {t('Favorited')}
                                </div>
                                {
                                    momentClubShowType === 2 && 
                                    <div className={`${s.walletAssetsTypeBtnSubline}`}>
                                    </div>
                                }
                            </div>
                            {
                                openablePackList?.length > 0 &&
                                <div className={`${s.walletAssetsTypeBtn} flex_center_start_col`}
                                    onClick={() => {
                                        if (momentClubShowType !== 3){
                                            setNextCursor(0);
                                            setMomentClubShowType(3);
                                        }
                                    }}>
                                    <div className={`${ momentClubShowType === 3 ? s.selecedTypeSubline : s.unselectedTypeSubline} momentFont textnowrap`}>
                                        {t('Unpack Required')}
                                    </div>
                                    {
                                        momentClubShowType === 3 && 
                                        <div className={`${s.walletAssetsTypeBtnSubline}`}>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className={`${s.collectionListRoot} flex_center_start_col`}>
                            {
                                (momentClubShowType === 1 || momentClubShowType === 2) &&
                                <List 
                                    list={[...momentClubsShowList]} 
                                    momentClubShowType={momentClubShowType}
                                    nextCursor={nextCursor} 
                                    linkToMoment={videoLinkToMoment}
                                    getMoreFn={getMoreFn}
                                    setBackRoute={() =>{
                                        setMomentAssetParam({
                                            videoPage: momentClubShowType,
                                        })
                                    }}></List>
                            }
                            {
                                momentClubShowType === 3 &&
                                <List 
                                    list={[...openablePackList]} 
                                    momentClubShowType={momentClubShowType}
                                    nextCursor={openablePackNextCursor} 
                                    linkToMoment={videoLinkToMoment}
                                    getMoreFn={getMoreOpenablePackFn}
                                    setBackRoute={() =>{
                                        setMomentAssetParam({
                                            videoPage: momentClubShowType,
                                        })
                                    }}></List>
                            }
                        </div>
                        {/*<div className="wrap">
                            <Tabs
                                defaultActiveKey={1}
                                items={items}
                                className="tptabs"
                                onChange={(activeKey)=>{
                                    setNextCursor(0)
                                    setMomentClubShowType(Number(activeKey))
                                }}
                                destroyInactiveTabPane={true}>
                            
                            </Tabs>
                        </div>*/}
                    </>
                }
            </div>

            <AntModal
                width='330px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={showDepositPanel}
                onOk={() => setShowDepositPanel(false)}
                onCancel={() => setShowDepositPanel(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModelDeposit 
                    topUpCode={topUpCode} 
                    walletAddr={walletAddr} 
                    setShowDepositPanel={setShowDepositPanel}
                    chainTokenInfo={null}
                    type={2}/>
            </AntModal>

            <AntModal
                width='330px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={showMomentEarningTip}
                onOk={() => setShowMomentEarningTip(false)}
                onCancel={() => setShowMomentEarningTip(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMomentEarning 
                    closeFn={()=> {
                        setShowMomentEarningTip(false);
                    }}/>
            </AntModal>

            <AntModal
                width='330px'
                title={''}
                className="momentModalWrap noPaddingModal"
                centered={true}
                open={showMomentAssetNewPack}
                onOk={() => setShowMomentAssetNewPack(false)}
                onCancel={() => setShowMomentAssetNewPack(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalMomentAssetNewPack 
                    closeHandle={()=>{
                        setShowMomentAssetNewPack(false);
                    }}/>
            </AntModal>

            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowMomentClubSort(false)}
                width={245}
                open={showMomentClubSort}
                destroyOnClose={true}
                className="hireSort"
            >
                <MomentAssetClubSort
                    closeHandle={() => {
                        setShowMomentClubSort(false);
                    }}
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                    applySortFn={({rankSortType,isAsc})=>{
                       setNextCursor(0)
                       getList({
                            rankTabType:momentClubShowType,
                            rankSortType,
                            isAsc,
                            cursor:0,
                        })
                    }}
                />
            </AntDrawer>

            <AntModal
                width='330px'
                title={t('SELECT YOUR AVATAR')}
                className="confirmModalWrap"
                centered={true}
                open={showChangeAvatar}
                onOk={() => setShowChangeAvatar(false)}
                onCancel={() => setShowChangeAvatar(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModelChangeAvatar
                    userInfo={userInfo}
                    refreshIcon={refresh}
                    closeFn={() => {
                        setShowChangeAvatar(false);
                    }}
                />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        walletBalanceList: app.walletBalanceList,
        momentDntBalanceList: app.momentDntBalanceList,
        lastUpdateMomentDntBalanceListTime: app.lastUpdateMomentDntBalanceListTime,
        momentAssetParam: app.momentAssetParam,
        momentAssetUnpackList: app.momentAssetUnpackList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setMomentDntBalanceList: (params) => {
            return dispatch({
                type: "app/setMomentDntBalanceList",
                payload: params,
            });
        },
        setLastUpdateMomentDntBalanceListTime: (params) => {
            return dispatch({
                type: "app/setLastUpdateMomentDntBalanceListTime",
                payload: params,
            });
        },
        setMomentAssetParam: (params) => {
            return dispatch({
                type: "app/setMomentAssetParam",
                payload: params,
            });
        },
        setSwitchVideoType: (params) => {
            return dispatch({
                type: "app/setSwitchVideoType",
                payload: params,
            });
        },
        setMemeclubSwitchVideoParams: (params) => {
            return dispatch({
                type: "app/setMemeclubSwitchVideoParams",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MomentAssets));
