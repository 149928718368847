import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import {useNavigate, useParams} from "react-router-dom";
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { getActiveList, getChainInfo } from "@/utils/memeClub";
import MemeClubPack from "@/common/MemeClubPack";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import { Tabs } from "antd";
import SwapTrade from "./SwapTrade";
import { 
    gameMemeClubRevealMemePackPath,
} from "@/routes/config";
import {
    maxMemeUnpackNftCount,
} from "@/utils/env";
import { AutoStrangeRule } from "@/utils/strangeRule";
import Chats from "./Chats";
import { 
    getWeb3Config,
    convertWalletBalance,
} from "@/utils/common";
const Trade = (
    {
        clubId,
        chainInfo,
        coinBalance,
        walletBalanceList,
        reFreshBalance,
        // coinChain = {
        //     chainId:137,
        //     address: '0x8bff1bD27E2789Fe390AcabC379C380a83B68E84',
        //     decimals:18,
        //     icon:"UI_Picture-Currency_LFG_01",
        //     displayCoinName: '$LFG'
        // },
       
        clubBasicInfo,
        setMemeUnpackInfo,
        setMemeUnpackCount,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [list, setList] = useState([])
    const [nextCursor,setNextCursor] = useState(-1)
    const [swapTradeType, setSwapTradeType] = useState(0);
    const [nftRange, setNftRange] = useState([0, 0]);
    const [suitablePackCount, setSuitablePackCount] = useState(0);
    const [reachMaxPack, setReachMaxPack] = useState(false);
    const getList = (clubUserId,nextCursor=0) => {
        
    }


    useEffect(() => {
        clubId && getList(clubId,0)
    }, [clubId])

    const getMoreList = ()=>{
        getActiveList(clubId,nextCursor)
    }

    const {
        clubUserId,
        coinTokenAddr,
        swapPoolAddr,
        clubInfo,
        holdNum,
    } = useMemo(()=>{
        return clubBasicInfo || {}
    },[clubBasicInfo])


    

    const {coinToNFTRatio,imageUrl,symbolName,ftTotalSupply,airdropPercent,cardsTotalSupply,openPackRatio,symbolImageUrl,isFT} = useMemo(()=>{
        return clubInfo || {}
    },[clubInfo])

    const { name ,chainId,icon,displayCoinName} = useMemo(() => {
        return chainInfo || {}
    }, [chainInfo])
    const [coinContract,setCoinContract] = useState('')

    const getContractInfo = async () =>{
        const web3Config = await getWeb3Config();
        const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === chainInfo?.ID));
        setCoinContract( contractInfo?.coinContract)
    }

    useEffect(()=>{
        getContractInfo();
    },[chainInfo])

    useEffect(() => {
        if (clubInfo){
            const {
                coinToNFTRatio,
                ftTotalSupply,
                airdropPercent,
                cardsTotalSupply,
                openPackRatio
            } = clubInfo;
            let range = [];
            let evenCardToToken = parseInt(ftTotalSupply * (airdropPercent / 10000) / cardsTotalSupply * 2);
            let min = evenCardToToken * ( 1 / (openPackRatio + 1));
            let minCount = Math.floor(min / coinToNFTRatio) * holdNum;
            let max = evenCardToToken * ( openPackRatio / (openPackRatio + 1));
            let maxCount = Math.floor(max / coinToNFTRatio) * holdNum;
            range.push(minCount);
            range.push(maxCount);
            setNftRange(range);
            if (maxCount > maxMemeUnpackNftCount){
                setReachMaxPack(true);
                let suitablePackNum = maxMemeUnpackNftCount * coinToNFTRatio / max;
                setSuitablePackCount(Math.max(Math.floor(suitablePackNum), 1));
            }
            else{
                setReachMaxPack(false);
                setSuitablePackCount(holdNum);
            }
        }
    }, [clubInfo, holdNum])

    // coinChain = {
    //     chainId:137,
    //     address: '0x8bff1bD27E2789Fe390AcabC379C380a83B68E84',
    //     decimals:18,
    //     icon:"UI_Picture-Currency_LFG_01",
    //     displayCoinName: '$LFG'
    // },
    // nativeChain = {
    //     chainId:137,
    //     address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    //     decimals:18,
    //     icon:"UI_Picture-Currency_MATIC_01",
    //     displayCoinName: "$MATIC"
    // },
    const coinChain = useMemo(()=>{
        return {
            chainId: chainId,
            address: coinTokenAddr,
            // chainId: 137,
            // address: '0x8bff1bD27E2789Fe390AcabC379C380a83B68E84',
            decimals:18,
            icon:symbolImageUrl,
            displayCoinName:"$"+symbolName
        }
    },[coinTokenAddr,chainId,imageUrl,symbolName,symbolImageUrl])

    const nativeChain = useMemo(()=>{
        return {
            chainId: chainId,
            address: coinContract,
            // chainId: 137,
            // address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
            decimals:18,
            icon:icon,
            displayCoinName:displayCoinName,
            chainName: name.split(' ')[0].toLocaleLowerCase()
        }
    },[coinContract,chainId,icon,displayCoinName,name])
    const nativeBalance = useMemo(()=>{
        // console.log(`walletBalanceList=------>`,walletBalanceList,chainInfo?.ID);
        return walletBalanceList?.[chainInfo?.ID] || 0
    },[walletBalanceList,chainInfo])
     
    const items = [
        {
            key: 1,
            label: `${AutoStrangeRule(t('Buy'))}`,
            children: <SwapTrade 
            swapPoolAddr={swapPoolAddr} 
            coinBalance={coinBalance} 
            chainInfo={chainInfo} 
            coinToNFTRatio={coinToNFTRatio} 
            tradeType={1} 
            nativeChain={nativeChain} 
            reFreshBalance={reFreshBalance}
            nativeBalance={nativeBalance}
            coinChain={coinChain}
            isFT={isFT}
            symbolName={symbolName}
            clubBasicInfo={clubBasicInfo}>
            
            
            </SwapTrade>,
        }, {
            key: 2,
            label: `${AutoStrangeRule(t('Sell'))}`,
            children: <SwapTrade 
            swapPoolAddr={swapPoolAddr} 
            coinBalance={coinBalance} 
            chainInfo={chainInfo} 
            reFreshBalance={reFreshBalance}
            coinToNFTRatio={coinToNFTRatio} 
            tradeType={2}
            nativeBalance={nativeBalance}
            coinChain={coinChain} 
            nativeChain={nativeChain}
            isFT={isFT}
            symbolName={symbolName}
            clubBasicInfo={clubBasicInfo}></SwapTrade>,
        }
    
    ]

    const evenCardToToken = useMemo(()=>{
        return parseInt(ftTotalSupply * (airdropPercent / 10000) / cardsTotalSupply * 2)
    },[ftTotalSupply,airdropPercent,cardsTotalSupply])

    return (
        <div className={`${s.box}`}>
           {holdNum> 0 && <div className={s.blockWrap}>
                <div className="w100p flex_center_between">
                    <div className="fs12">{t('You have Packs to Reveal')}</div>
                    <div className="flex_center_center">
                        <CustomIcon imgName='Picture/UI_Picture_Cardpack_01' width={20} height={20}></CustomIcon>
                        <span className="fs18">X&nbsp;{holdNum}</span> 
                    </div>
                </div>
                <div className="w100p flex_center_between">
                    <div className="fs12">{t('Each Pack Offer Coins')}</div>
                    <div className="flex_center_center">
                    <MemeClubPack width={16} height={20} imageUrl={imageUrl} packName={``} >

                    </MemeClubPack>
                    <span className="fs18 ml5"> X&nbsp;
                        {`${convertWalletBalance(parseInt(evenCardToToken * ( 1 / (openPackRatio + 1))) * holdNum)} - ${convertWalletBalance(parseInt(evenCardToToken * ( openPackRatio / (openPackRatio+1))) * holdNum)}`}
                    </span>
                    </div>
                </div>
                <div className="w100p flex_center_between">
                    <div className="fs12">{t('NFT Generate')}</div>
                    <div className={`fs18`}>
                        {`${Number(nftRange?.[0] || 0).toLocaleString("en-US")} - ${Number(nftRange?.[1] || 0).toLocaleString("en-US")}`}
                    </div>
                </div>    
                <ButtonFactory className="flex_center_center w100p mt10 mb10" onClick={() =>{
                    navigate(`${gameMemeClubRevealMemePackPath}/${clubUserId}`);
                    setMemeUnpackInfo(null);
                    setMemeUnpackCount(suitablePackCount);
                }}>
                    {
                        reachMaxPack ? t('Open {{count}} Packs', {count: suitablePackCount}) : t('Reveal All Packs')
                    }
                </ButtonFactory>
            </div>
            }
            <Chats coinChain={coinChain} nativeChain={nativeChain} />
            {/*<div className={s.tradeWrap}>
                <Tabs
                    defaultActiveKey={1}
                    items={items}
                    className="buyAndSellTabs activeSizeChange"
                    destroyInactiveTabPane={true}>
                </Tabs>
            </div>*/}
            <div className={`${s.tradeWrap} flex_center_start_col`}>
                {
                    swapTradeType === 0 &&
                    <>
                        <div className={`${s.tradeTypeRoot} flex_center_between`}>
                            <div className={`${s.selectedBar} ${s.buyBar} ${s.commonBarType} flex_center_center`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_TabBar_Buy_01.png)`}}>
                                {t('Buy')}
                            </div>
                            <div className={`${s.unselectedBar} ${s.sellBar} ${s.commonBarType} flex_center_center`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_TabBar_Sell_02.png)`}} onClick={() =>{
                                setSwapTradeType(1);
                            }}>
                                {t('Sell')}
                            </div>
                        </div>
                        <SwapTrade 
                            swapPoolAddr={swapPoolAddr} 
                            coinBalance={coinBalance} 
                            chainInfo={chainInfo} 
                            coinToNFTRatio={coinToNFTRatio} 
                            tradeType={1} 
                            nativeChain={nativeChain} 
                            reFreshBalance={reFreshBalance}
                            nativeBalance={nativeBalance}
                            coinChain={coinChain}
                            isFT={isFT}
                            symbolName={symbolName}
                            clubBasicInfo={clubBasicInfo}>
                        </SwapTrade>
                    </>
                }
                {
                    swapTradeType === 1 &&
                    <>
                        <div className={`${s.tradeTypeRoot} flex_center_between`}>
                            <div className={`${s.unselectedBar} ${s.buyBar} ${s.commonBarType} flex_center_center`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_TabBar_Buy_02.png)`}} 
                                onClick={() =>{
                                    setSwapTradeType(0);
                            }}>
                                {t('Buy')}
                            </div>
                            <div className={`${s.selectedBar} ${s.sellBar} ${s.commonBarType} flex_center_center`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_TabBar_Sell_01.png)`}}>
                                {t('Sell')}
                            </div>
                        </div>
                        <SwapTrade 
                            swapPoolAddr={swapPoolAddr} 
                            coinBalance={coinBalance} 
                            chainInfo={chainInfo} 
                            reFreshBalance={reFreshBalance}
                            coinToNFTRatio={coinToNFTRatio} 
                            tradeType={2}
                            nativeBalance={nativeBalance}
                            coinChain={coinChain} 
                            nativeChain={nativeChain}
                            isFT={isFT}
                            symbolName={symbolName}
                            clubBasicInfo={clubBasicInfo}>
                        </SwapTrade>
                    </>
                }
            </div>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMemeUnpackInfo: (params) => {
            return dispatch({
                type: "app/setMemeUnpackInfo",
                payload: params,
            });
        },
        setMemeUnpackCount: (params) => {
            return dispatch({
                type: "app/setMemeUnpackCount",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Trade));
