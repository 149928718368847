import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button} from "antd";
import CustomIcon from "../../../common/CustomIcon";
import AvatarInfo from "../../../common/AvatarInfo";
import {unit, sliceStr, stringToNumber} from "@/utils/common";

const ModelRank = (
    {
        rankAward,
        curRtcInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const {onlineNum, heat} = useMemo(() => {
        return curRtcInfo || {}
    }, [curRtcInfo])

    return (
        <div className={s.wrap}>
            <div className={s.viewer}>
                {/*<CustomIcon width={16} height={16} imgName={'viewer'} name={'viewer'}/>*/}
                {/*<div>{onlineNum}</div>*/}
            </div>
            <div className={`${s.headTips} color-white`}>
                {rankAward?.winners?.length} Winner{rankAward?.winners?.length > 1 && 's'}!
            </div>
            <div className={s.list}>
                {
                    rankAward?.winners?.map((item, index) => {
                        return (
                            <div className={s.item}>
                                <div className={s.left}>
                                    <AvatarInfo size={35} src={item.logo}/>
                                </div>
                                <div className="f1">
                                    <div className={s.r1}>
                                        <div className="fs18 ml10 color-white">
                                            {sliceStr(item.name, 'all')}
                                        </div>
                                        <div className={`fs18 color-white`}>{Number(item?.bonus || 0).toFixed(2)} $
                                        </div>
                                    </div>
                                    <div className={s.r1}>
                                        <div className={`ml10 ${s.downText} color-white`}>
                                            {t('xx’s Members will split a yy $ prize pool', {
                                                name: sliceStr(item.name, 'all'),
                                                amount: Number(item?.bonus || 0).toFixed(2)
                                            })}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}
export default memo(ModelRank);
