import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import s from "./index.module.less"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getUrlParams, tweetAuthorize } from "@/utils/common";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "@/contexts/StoreContext";
import { useTranslation, Trans } from 'react-i18next';
const twitterCode = getUrlParams('code');
const Callback = ({
  setUserInfo,
  userInfo
}) => {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false)
  const { db } = React.useContext(StoreContext);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (twitterCode) {
      setLoading(true)
      let code = decodeURIComponent(twitterCode);
      // console.log(code);
      // code = code.slice(0,code.indexOf('#'));
      // console.log(code.slice(0,code.indexOf('#')));
      tweetAuthorize(code).then(res => {
        db.update({
          hasLink: true,
          userInfo: {
            ...db.get('userInfo'),
            platform: {
              ...db.get('userInfo')?.platform,
              ...res
            }
          }
        })
        setUserInfo({
          platform:{
            ...userInfo?.platform,
            ...res
          }
        })
        setLoading(false)
        // navigate('/');
        window.location.href = window.location.origin + "/"
      }).catch(e => {
        setLoading(false)
        console.log(e);
      })
    }else{
      window.location.href = window.location.origin + "/"
    }
  }, [twitterCode])
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 90,
      }}
      spin
    />
  );
  return (
    <div className={s.main}>
      <Spin
        className={s.spin}
        indicator={antIcon}
        spinning={loading}
      />

      <div className="fs30">{t('Link...')}</div>
    </div>

  )
}
const mapStateToProps = ({ app }) => {
  return {
    userInfo: app.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserInfo: (params) => {
      return dispatch({
        type: "app/setUserInfo",
        payload: params
      });
    },
  }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Callback));