import React from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { gameShareDetailPath, gameTrillionaireBattleLeaderBoardPath, mainProfilePath } from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelMemeCreatTips = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className={s.modelWrap}>
            <div>{t('Acquire a set number of coins to mint an NFT that dynamically evolves.')}</div>
            <div className="mt20 mb20">{t('Any transaction or trade of the coin triggers an update to the corresponding NFT, reflecting the changes in a visual or attribute-based manner, ensuring your digital asset remains current with your coin holdings')}</div>
            <div>{t('Transactions or trades of DNAs might trigger an update to the NFTs attributes, ensuring an engaging and dynamic experience.')}</div>
            {/* <div className="fs20 color-blue-light mb20">{t('Fee: 0.1 $Matic')}</div>
            <div>
                <ButtonFactory>
                {t('PAY NOW')}
                </ButtonFactory>
            </div> */}
        </div>
    )
}

export default ModelMemeCreatTips
