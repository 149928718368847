import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";

import {Button} from "antd";
import CustomIcon from "../../common/CustomIcon";

import {useTranslation, Trans} from 'react-i18next';
import NFTRarityInfo from "@/utils/json/NFTRarityInfo.json"
import HireModelWorkIntro from "../HireModelWorkIntro";
const NftModelYieldIntro = (
    {
        rankInfo,
        nftSumWeight,
        profit,
        NFTCapacity
    }
) => {
    const {t, i18n} = useTranslation();
    console.log(rankInfo);
    return (
        <div className={s.wrap}>
            <div className={s.text}>
                {t("$LFG production decreases into half each time the emission milestone is reached.")}
            </div>
            {/* <div className="df w100p">{t('Current SLFG Vol:')}{rankInfo?.amount}</div> */}
            <HireModelWorkIntro
                rankInfo={rankInfo}
            />
            <div className={s.text}>
                {t("The yield from the NFT mission distributed among all NFTs executing that mission based on their respective yield weights.")}
            </div>
            <div className="df w100p alc">
                {t('Total Mission Capacity:')}
                <CustomIcon width={16} height={16} className=" ml5 mr5"
                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                            />
                {NFTCapacity}{t('/hr')}
            </div>
            <table className="mt10 mb10">
                    <thead>
                    <tr>
                        <th>{t("RARITY")}</th>
                        <th>{t("WEIGHT")}</th>
                        <th>{t("MAX CAPACITY RATE")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        NFTRarityInfo?.map(item=>{
                            const {multiplier,maxCapacityRate,name} = item;
                            return(
                                <tr key={multiplier} className={s.item}>
                                    <td>{name}</td>
                                    <td>{multiplier}&nbsp;{t('× Level')}</td>
                                    <td>{maxCapacityRate/100}%</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
            </table>
            <div className="mt10 df w100p alc jc-spaceBetween">
                <div>{t('Current Total Weight')}</div>
                <div>{nftSumWeight}</div>
            </div>
            <div className="mt10 df w100p alc jc-spaceBetween">
                <div>{t('Est Yield')}</div>
                <div>{profit}</div>
            </div>
        </div>
    )
}
export default memo(NftModelYieldIntro);
