import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button} from "antd";
import CustomIcon from "../../../common/CustomIcon";
import AntButton from "@/common/AntButton";


const ModelVoteRevivalTips = (
    {
        setShowRevivalTips,
    }
) => {
    const {t, i18n} = useTranslation();
    return (
        <div className={s.wrap}>
            <div>
                <CustomIcon width={130} height={130} imgName={'SadEmoji'} name={'SadEmoji'}/>
            </div>
            <div className="fs15 color-white fb mb10">
                <Trans
                    i18nKey="YOU'VE BEEN ELIMINATED!"
                    defaults="YOU'VE BEEN <br> ELIMINATED!"
                    components={{br: <br/>}}
                />
            </div>
            <div className="fs12 color-white mtb10">
                {t('Extra Lives')}: <CustomIcon width={18} height={18}
                                                imgName={'Picture/UI_Picture_Title-Blue_ManYellowHeart_01'}
                                                name={'Picture/UI_Picture_Title-Blue_ManYellowHeart_01'}/> 0
            </div>
            <div className="fs12 color-white mb20 tlc">
                {t('Didn\'t make it this time?')}<br/> {t('Extra Life keeps you in! Refer friends and Earn Extra Lives to be prepared for next trivia!')}
            </div>
            <AntButton shape="round" className="btn_public mb10" onClick={() => {
                setShowRevivalTips(false);
            }}>{t('CONTINUE')}</AntButton>
        </div>
    )
}
export default memo(ModelVoteRevivalTips);
