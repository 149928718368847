import React, { memo, useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import s from "./index.module.less";
import { Input, Button, Modal } from "antd";
import {
    officialInviteCode,
    joinChartPre,
    taPush,
    referralcode_bind,
    numberClamp,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import { useTranslation, Trans } from 'react-i18next';
import AntButton from "@/common/AntButton";
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import { useWallets } from "@privy-io/react-auth";
import MomentClubPack from "@/common/MomentClubPack";
import memeTradeVolSharingRate from "@/utils/json/memeTradeVolSharingRate.json";

const ModalPackTransResult = (
    {
        userInfo,
        transResult,
        clubBasicInfo,
        closeFn = () => { }
    }
) => {
    const { t, i18n } = useTranslation();
    const { wallets } = useWallets();
    const testUrl = 'https://abs.twimg.com/sticky/default_profile_images/default_profile_bigger.png';

    const {
        curCardKindNum,
        clubInfo,
        cardKindMaxNum,

    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])

    const {
        clubName,
        imageUrl,
        coinId,
        symbolName,
        symbolImageUrl
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    const {
        cardKindMax,
        cardList, 
    } = useMemo(() => {
        return transResult || {}
    }, [transResult])

    const totalCardNum = useMemo(() => {
        let res = 0;
        if (cardList?.length > 0) {
            for (let i = 0; i < cardList?.length; i++) {
                res += cardList[i].cardAmount;
            }
        }
        return res;
    }, [cardList])

    const getNowTgeDisplayProgress = () => {
        if (curCardKindNum === 0) {
            return 0;
        }
        else {
            return numberClamp(curCardKindNum * 100 / cardKindMaxNum, 1, 100);
        }
    }

    return (
        <div className={`${s.wrap} flex_center_start_col`}>
            <div className={`${s.title} momentFont`}>
                {t('Packs Claimed')}
            </div>
            {
                !cardList?.length ? <div className="fs20 momentFont mt20 mb10">{t('Checking Result...')}</div>
                    :
                    <>
                        <div className={`${s.packRoot}`}>
                            <CustomIcon width={42} height={42} className={`${s.packIcon}`} imgName={'Picture/UI_Picture_icon_cardpack'} />
                            <div className={`${s.packNum} momentFont ml2`}>
                                {`x`}
                            </div>
                            <div className={`${s.packNum2}`}>
                                {totalCardNum}
                            </div>
                        </div>
                        <div className={`${s.packDetail} flex_center_start_col`}>
                            <div className={`${s.packList} flex_center_start`}>
                                {
                                    cardList?.length > 0 &&
                                    cardList.map((item, index) => {
                                        const {
                                            cardNo,
                                            cardAmount,
                                            cardUrl
                                        } = item || {};
                                        return (
                                            <div key={`line_${index}`} className={`${s.packRoot} flex_center_between`}>
                                                <MomentClubPack
                                                    width={74}
                                                    height={74}
                                                    imageUrl={cardUrl || '/UI/Picture/UI_Picture_Empty_Bg_01.png'}
                                                    packName={''}
                                                    number={cardNo}
                                                    view={cardUrl ? true : false}
                                                    className={s.packItem}>
                                                </MomentClubPack>
                                                <div className={`${s.packAmount} flex_center_center`}>
                                                    <div className={`momentFont fs14 color-white fb`}>
                                                        {`x${cardAmount}`}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        {/*cardKindMaxNum &&
                            <>
                                <div className={`${s.tgeTitle}`}>
                                    {t('TGE Progress')}
                                </div>
                                <div className={`${s.tgeProgressRoot}`}>
                                    <div className={`${s.tgeProgressBg} flex_center_start`}>
                                        <div className={`${s.tgeCurrentProgress}`} style={{
                                            width: `${getNowTgeDisplayProgress()}%`
                                        }}>
                                        </div>

                                        <div className={`${s.tgeText}`}>
                                            {`${curCardKindNum}/${cardKindMaxNum}`}
                                        </div>
                                    </div>
                                </div>
                            </>*/
                        }
                        <AntButton
                            onClick={() => {
                                closeFn();
                            }}
                            className={`${s.comfirmBtn} btn_public`}>
                            <div className="fs18 fb momentFont color-black">
                                {t('CONFIRM')}
                            </div>
                        </AntButton>
                    </>
            }
            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeFn();
                }} />
        </div>
    )
}
const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalPackTransResult));
