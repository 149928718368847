import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox, Slider} from 'antd';
import s from "./index.module.less";
import {
    convertWalletBalance,
    getAvatarFromProfile,
    displayFilter,
    getFriendtrade_loot_info,
    vCoinString,
    formatCardDisplayName,
    getBattlePointRankImg,
    getfriendtrade_loot_randomsearch,
    getFriendtrade_loot_employeelist,
    getBurnParam_loot,
    filterErrorCode,
    getearninfo,
    bornCheck_loot,
    getTransactionErrorMsgId,
    getProfile,
    joinChat,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate, useLocation} from "react-router-dom";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "@/common/ButtonFactory";
import {bornLfg} from "@/utils/lfgMintWeb3";
import {
    gameTrillionaireBattleLeaderBoardPath,
    mainClubSweepClubsPath,
    balancePath,
    gameTrillionaireBattlePath,
} from "@/routes/config";
import {backFn} from "@/utils/mianPublic";
import {displayLootRefundTime} from "@/utils/env";
import LootResultConfig from "@/utils/json/LootResultConfig.json";
import HireModelEarnIntro from "@/model/HireModelEarnIntro";
import ModelBattlePointTip from "@/model/ModelBattlePointTip";
import CrackModelRateIntro from "@/model/CrackModelRateIntro";
import ModalCostLfgType from "@/model/ModalCostLfgType";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import CrackModelClubList from "src/model/CrackModelClubList";
import HireClaimedResult from "@/components/HireClaimedResult";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import GetCoinAni from "@/model/GetCoinAni";

const customLfgIcon = 'Picture/UI_Picture-Currency_LFG_01';

const Battling = (
    {
        userInfo,
        setUserInfo,
        earnInfo,
        lootTargetUserInfo,
        setLootTargetUserInfo,
        lootSearchClubLvRange,
        costLfgType,
        setCostLfgType,
        lootEmployeeUserInfo,
        setLootEmployeeUserInfo,
        lastLootTargetUserInfo,
        setLastLootTargetUserInfo,
        lastLootEmployeeUserInfo,
        setLastLootEmployeeUserInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const {wallets} = useWallets();
    const [attackData, setAttackData] = useState(null);
    const inputRef = useRef(null);
    const {
        platformData,
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        buyFirstKey,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const {
        employeeProfile,
        tarProfile,
        coinBalance,
        crackRatio,
        crackCoin,
        crackSelfCoin,
        defaultCalRatio,
        defaultPutCoin,
        employeePower,
        tarClaimCoin,
        tarPower,
        tarWorkingCoin,
        employeeAllKeyNum,
        holdingKeyNum,
        claimCoinBalance,
        lfgBalance,
        employeeLevel,
        tarLevel
    } = useMemo(() => {
        return attackData || {}
    }, [attackData]);

    const totalCoin = useMemo(() => {
        return Math.max(claimCoinBalance || 0, selfData?.lfgBalance || 0);
    }, [claimCoinBalance, lfgBalance])
    const [keyNum, setKeyNum] = useState(0);
    const [failedMessage, setFailedMessage] = useState('');
    const [checkTimes, setCheckTimes] = useState(0);
    const [needWeb3Check, setNeedWeb3Check] = useState(false);
    const [checkOrderId, setCheckOrderId] = useState(0);
    const [returnClaimCoin, setReturnClaimCoin] = useState(0);
    const [lootProgress, setLootProgress] = useState(50);
    const [costLFG, setCostLFG] = useState(0);
    const [lootState, setLootState] = useState(0);//0:beforeLoot, 1:looting, 2:failed, 3:success, 4:claim, 5:error, 6:refundError
    const [lootLock, setLootLock] = useState(false);
    const [timeoutTimestamp, setTimeoutTimestamp] = useState(null);
    const [progressUpdateTimes, setProgressUpdateTimes] = useState(0);
    const [lootResultData, setLootResultData] = useState(null);
    const [lootTimeout, setLootTimeout] = useState(false);
    const [modelEarnIntroInfo, setModelEarnIntroInfo] = useState({});
    const [randomLootLock, setRandomLootLock] = useState(false);
    const [playingReadyAni, setPlayingReadyAni] = useState(false);

    //modal
    const [showEarnIntro, setShowEarnIntro] = useState(false);
    const [showBattlePointTip, setShowBattlePointTip] = useState(false);
    const [showCrackModelRateIntro, setShowCrackModelRateIntro] = useState(false);
    const [showCostLfgTypeModal, setShowCostLfgTypeModal] = useState(false);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [showChooseLootClub, setShowChooseLootClub] = useState(false);
    const [showClaimResult, setShowClaimResult] = useState(false);
    const [showLootResult, setShowLootResult] = useState(false);

    //ani
    const [showBlackMask, setShowBlackMask] = useState(false);
    const [bottomHide, setBottomHide] = useState(false);
    const [showAttackCardInAni, setShowAttackCardInAni] = useState(false);
    const [showDefenceCardInAni, setShowDefenceCardInAni] = useState(false);
    const [playBattingGif, setPlayBattingGif] = useState(false);
    const [attackCardBatting, setAttackCardBatting] = useState(false);
    const [defenceCardBatting, setDefenceCardBatting] = useState(false);
    const [defenceLose, setDefenceLose] = useState(false);
    const [attackLose, setAttackLose] = useState(false);
    const [bottomShow, setBottomShow] = useState(false);
    const [playChangeTargetAni, setPlayChangeTargetAni] = useState(false);
    const [playChangeEmployeeAni, setPlayChangeEmployeeAni] = useState(false);
    const [showLeftClaimCoinAni, setShowLeftClaimCoinAni] = useState(false);
    const [showRightClaimCoinAni, setShowRightClaimCoinAni] = useState(false);

    const [refreshBtnRotate, setRefreshBtnRotate] = useState(false);

    const loadingProcessingIncrease = 1;
    const finishProcessingIncrease = 5;

    //before loot

    const successRate = useMemo(() => {
        const LootNum = crackCoin;
        const AttackPower = employeePower;
        const DefensePower = tarPower;
        const deltaLv = Math.max(0, (tarLevel || 0) - (employeeLevel || 0));
        return Math.min(defaultCalRatio, (1 - (LootNum / (keyNum / (1 + 0.1 * deltaLv * deltaLv))) / ((LootNum / (keyNum / (1 + 0.1 * deltaLv * deltaLv))) + 4)) * (AttackPower * 1.5 + 0.01) / (AttackPower * 1.5 + DefensePower + 0.02) * 2) || '--'
    }, [keyNum])

    const getSelfEmployeeList = () => {
        getFriendtrade_loot_employeelist().then(res => {
            if (res?.employees && res?.employees?.length > 0) {
                for (let i = 0; i < res.employees.length; i++) {
                    let tempEmployee = res.employees[i];
                    if (tempEmployee.energy >= 5 && tempEmployee.attackCD === 0) {
                        setLootEmployeeUserInfo(tempEmployee);
                        break;
                    }
                }
            }
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        getAttackData();
    }, [lootTargetUserInfo, lootEmployeeUserInfo]);

    useEffect(() => {
        if (!lootEmployeeUserInfo || !(lootEmployeeUserInfo?.userId > 0)) {
            getSelfEmployeeList();
        }
    }, []);

    useEffect(() => {
        if (lastLootTargetUserInfo){
            console.log("startAni");
            setPlayChangeTargetAni(true);
            setTimeout(() => {
                setPlayChangeTargetAni(false);
                setLastLootTargetUserInfo(null);
            }, 1000);
        }
    }, [lastLootTargetUserInfo])

    useEffect(() => {
        if (lastLootEmployeeUserInfo){
            console.log("startAni");
            setPlayChangeEmployeeAni(true);
            setTimeout(() => {
                setPlayChangeEmployeeAni(false);
                setLastLootEmployeeUserInfo(null);
            }, 1000);
        }
    }, [lastLootEmployeeUserInfo])

    const getAttackData = () => {
        console.log("start get attack data", lootTargetUserInfo, lootEmployeeUserInfo);
        if (lootTargetUserInfo && lootEmployeeUserInfo) {
            getFriendtrade_loot_info(lootEmployeeUserInfo?.userId, lootTargetUserInfo?.userId).then(res => {
                setAttackData({
                    ...res,
                    lfgBalance
                });
                setKeyNum(res?.defaultPutCoin * 2 || 0);
            }).catch(e => {
                console.error(e);
            })
        }
    }

    const controlAmount = (control) => {
        if (!defaultPutCoin) {
            return;
        }
        if (control === 0 && keyNum > defaultPutCoin) {
            let _keyNum = keyNum - defaultPutCoin;
            _keyNum = parseInt(Math.max(_keyNum, defaultPutCoin))
            setKeyNum(_keyNum)
        }
        if (control === 1) {
            let _keyNum = keyNum + defaultPutCoin;
            setKeyNum(parseInt(_keyNum));
        }
    }

    const amountChangeHandler = (e) => {
        let value = e.target.textContent.replace(/[^\d]/g, '');
        setKeyNum(value);
        document.execCommand("selectAll", false, null);
        document.getSelection().collapseToEnd();
    }

    const amountBlurHandler = (e) => {
        setKeyNum(Math.max(keyNum, defaultPutCoin))
    }

    const randomLoot = () => {
        if (randomLootLock) {
            return;
        }
        setRandomLootLock(true);
        setTimeout(() => {
            setRandomLootLock(false);
        }, 1000);
        getfriendtrade_loot_randomsearch([0, -1], lootEmployeeUserInfo?.userId || 0).then(res => {
            if (res?.searchData && res?.searchData?.userId > 0) {
                if (lootTargetUserInfo){
                    setLastLootTargetUserInfo(lootTargetUserInfo);
                }
                setLootTargetUserInfo(res?.searchData);
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const getDeltaBP = (newBP, consumeLFG) => {
        return newBP - (Math.sqrt(newBP * newBP / 16 - consumeLFG) * 4);
    }

    //looting

    useEffect(() => {
        if (lootState === 1) {
            let nowTime = new Date().getTime() / 1000;
            setTimeoutTimestamp(nowTime + 120);
            setLootTimeout(false);
            setLootProgress(0);
            setTimeout(() => {
                setProgressUpdateTimes(progressUpdateTimes + 1);
            }, 250);
        } else if (lootState === 2 || lootState === 3) {

        }
    }, [lootState]);

    useEffect(() => {
        if (!lootResultData) {
            setLootProgress((progress) => {
                return progress + loadingProcessingIncrease > 90 ? 90 : progress + loadingProcessingIncrease;
            });
        } else {
            setLootProgress((progress) => {
                return progress + finishProcessingIncrease > 100 ? 100 : progress + finishProcessingIncrease;
            });
        }

        setTimeout(() => {
            setProgressUpdateTimes(progressUpdateTimes + 1);
        }, 250);

        let nowTime = new Date().getTime() / 1000;
        if (nowTime > timeoutTimestamp && !lootTimeout) {
            setLootTimeout(true);
        }
        if (lootProgress >= 100 && lootResultData && lootState === 1) {
            if (lootResultData.succ) {
                playSuccessAni();
                setLootState(4);
            } else {
                playFailedAni();
                setLootState(2);
            }
            setTimeout(() => {
                setShowLootResult(true);
            }, 3000);
        }
    }, [progressUpdateTimes]);

    useEffect(() => {
        if (needWeb3Check) {
            setTimeout(() => {
                lootCheck(checkOrderId)
            }, 1000);
        } else {
            console.log("do not check");
        }
    }, [checkTimes])

    const lootCheck = (orderId) => {
        bornCheck_loot(orderId).then(res => {
            if (!res?.haveResult) {
                setCheckOrderId(orderId);
                setCheckTimes(checkTimes + 1);
            } else if (res.resultCode > 0 && res.returnClaimCoin > 0) {
                resetAni();
                setReturnClaimCoin(res.returnClaimCoin);
                setLootState(6);
            } else {
                if (res?.result) {
                    setLootResultData(res?.result);
                } else {
                    setFailedMessage(t('loot failed'));
                    resetAni();
                    setLootState(6);
                }
            }
        }).catch(e => {
            console.error(e);
            //setFailedMessage(filterErrorCode(e));
            setCheckOrderId(orderId);
            setCheckTimes(checkTimes + 1);
        })
    }

    const lootBornLfg = (data) => {
        if (!data) {
            resetAni();
            return;
        }
        setCostLFG(data.amount);
        setFailedMessage(null);
        setNeedWeb3Check(true);
        setLootState(1);
        const {
            amount,
            orderId,
            reason,
            mintNow,
            timestamp,
            validFor,
            signature
        } = data;
        bornLfg({
            wallets,
            amount,
            orderId,
            reason,
            mintNow,
            timestamp,
            validFor,
            signature
        }).then(res => {
            console.log(res);
            lootCheck(orderId);
        }).catch(e => {
            var errMsg = e === "need login" ? "need login" : t(getTransactionErrorMsgId(e?.reason));
            setFailedMessage(errMsg);
            setLootState(5);
            resetAni();
            setTimeout(
                () => {
                    getearninfo().then(res => {
                    }).catch(e => {
                        console.log(e);
                    })
                }, 2000
            )
        })
    }

    const startLoot = () => {
        if (lootEmployeeUserInfo && lootTargetUserInfo && !lootLock) {
            if (costLfgType === 0) {
                //wallet
                if (keyNum > userInfo?.selfData?.lfgBalance) {
                    setShowSwapLfg(true);
                    return;
                }
            } else {
                //pool
                if (keyNum > earnInfo?.claimCoin) {
                    setShowCostLfgTypeModal(true);
                    return;
                }
            }
            setLootLock(true);
            setLootResultData(null);
            getBurnParam_loot({
                employeeId: lootEmployeeUserInfo?.userId,
                tarUserId: lootTargetUserInfo?.userId,
                bornNum: keyNum,
                costType: costLfgType === 0 ? 0 : 1
            }).then(res => {
                console.log(res);
                setLootLock(false);
                if (res) {
                    getearninfo().then(res => {
                    }).catch(e => {
                        console.log(e);
                    })
                    playAdmissionAni();
                    setTimeout(() => {
                        lootBornLfg(res);
                    }, 1000);
                } else {
                    setFailedMessage(t('Transaction failed due to other unknown reason'));
                    setLootState(5);
                }
            }).catch(e => {
                setLootLock(false);
                if (e === 336) {
                    setFailedMessage(filterErrorCode(e));
                }
                console.error(e);
                setLootState(5);
            })
        }
    }

    const playAdmissionAni = () => {
        setShowBlackMask(true);
        setBottomHide(true);
        setShowAttackCardInAni(true);
        setShowDefenceCardInAni(true);
        setPlayingReadyAni(true);
        setTimeout(() => {
            setShowBlackMask(false);
            setShowAttackCardInAni(false);
            setShowDefenceCardInAni(false);
            setAttackCardBatting(true);
            setDefenceCardBatting(true);
            setBottomHide(false);
            setTimeout(() => {
                setPlayBattingGif(true);
            }, 243);
        }, 1000);
    }

    const playSuccessAni = () => {
        setAttackCardBatting(false);
        setDefenceCardBatting(false);
        setDefenceLose(true);
        setPlayBattingGif(false);
        setBottomShow(true);
    }

    const playFailedAni = () => {
        setAttackCardBatting(false);
        setDefenceCardBatting(false);
        setAttackLose(true);
        setPlayBattingGif(false);
        setBottomShow(true);
    }

    const resetAni = () => {
        setShowBlackMask(false);
        setBottomHide(false);
        setShowAttackCardInAni(false);
        setShowDefenceCardInAni(false);
        setPlayBattingGif(false);
        setPlayingReadyAni(false);
        setAttackCardBatting(false);
        setDefenceCardBatting(false);
        setDefenceLose(false);
        setAttackLose(false);
        setBottomShow(false);
    }

    const canLoot = () => {
        return lootTargetUserInfo?.userId &&
            lootEmployeeUserInfo?.userId &&
            lootTargetUserInfo?.userId !== lootEmployeeUserInfo?.userId &&
            lootEmployeeUserInfo?.energy >= 5 &&
            lootEmployeeUserInfo?.attackCD === 0 &&
            lootTargetUserInfo?.protectCD === 0;
    }

    //after loot

    const lootAgain = () => {
        resetAni();
        setLootState(0);
        setShowLootResult(false);
        getSelfEmployeeList();
    }

    const getResultBg = () =>{
        if (LootResultConfig?.length > 0){
            let imageName = LootResultConfig[0].resultImage;
            for (let i=0;i<LootResultConfig.length;i++){
                if (LootResultConfig[i].ID === lootResultData?.resultCfgId){
                    imageName = LootResultConfig[i].resultImage;
                    break;
                }
            }
            let img = require(`@/assets/images/resultImg/${imageName}.jpg`);
            return img;
        }
        else{
            return '';
        }
    }

    const closeFn = () =>{
        navigate(gameTrillionaireBattlePath);
        //backFn(location, navigate);
    }

    return (
        <div className={`${s.wrap}`}>
            <div className={s.content}>
                <div className={s.contentDefaultBg}>
                </div>
                <div className={`${s.contentBg} ${attackLose ? s.loseBg : ''}`}
                     style={{backgroundImage: `url(/UI/BG/UI_Background-S_Battle_02.webp)`}}>
                </div>
                <div className={`${s.contentBg} ${defenceLose ? s.loseBg : ''}`}
                     style={{backgroundImage: `url(/UI/BG/UI_Background-S_Battle_01.webp)`}}>
                </div>
                {
                    (lootState === 0 || lootState > 6) &&
                    <div className={s.backBtn}>
                        <CustomIcon width={32} height={32} imgName={'Button/UI_Button_Arrow_02'}
                                    onClick={() => {
                                        setLastLootTargetUserInfo(null);
                                        setLootTargetUserInfo(null);
                                        setLastLootEmployeeUserInfo(null);
                                        setLootEmployeeUserInfo(null);
                                        closeFn();
                                    }}/>
                    </div>
                }
                {/*<>
                <div style={{position: 'absolute', left: '60px', top: '20px'}}>
                    <CustomIcon width={32} height={32} imgName={'Button/UI_Button_QuestionMark_01'}
                        onClick={()=>{
                            playAdmissionAni();
                            setLootState(1);
                        }}/>
                </div>
                <div style={{position: 'absolute', left: '100px', top: '20px'}}>
                    <CustomIcon width={32} height={32} imgName={'Button/UI_Button_QuestionMark_01'}
                        onClick={()=>{
                            playSuccessAni();
                            setLootState(4);
                        }}/>
                </div>
                <div style={{position: 'absolute', left: '140px', top: '20px'}}>
                    <CustomIcon width={32} height={32} imgName={'Button/UI_Button_QuestionMark_01'}
                        onClick={()=>{
                            playFailedAni();
                            setLootState(2);
                        }}/>
                </div>
                <div style={{position: 'absolute', left: '180px', top: '20px'}}>
                    <CustomIcon width={32} height={32} imgName={'Button/UI_Button_QuestionMark_01'}
                        onClick={()=>{
                            resetAni();
                            setLootState(0);
                        }}/>
                </div>
                </>*/}
                <div
                    className={`${s.bottom} ${bottomHide ? s.bottomHide : ''} ${bottomShow ? s.bottomShow : ''} flex_center_start_col`}
                    style={{backgroundImage: lootState === 1 || lootState === 2 || lootState === 3 || lootState === 4 ? '' : `url(/UI/Picture/UI_Picture-Main_Down_01.png)`}}>
                    {
                        lootState === 0 ?
                            <>
                                <div className={s.lootFeeDes}>
                                    {t('Enter Loot Fee to Improve Success Rate')}
                                </div>
                                <div className={s.lootFeeRoot}>
                                    <div className={`${s.controlBtn} ${s.controlBtnLeft}`} onClick={() => {
                                        controlAmount(0)
                                    }}>
                                        <CustomIcon width={30} height={30}
                                                    imgName={'Button/UI_Button_Minus-White-Irr_01'}/>
                                    </div>
                                    <div className="df aic">
                                        <CustomIcon width={18} height={18}
                                                    imgName={'Picture/UI_Picture-Currency_LFG_01'}/>
                                        <div ref={inputRef}
                                             className={`${s.editable} ${keyNum > crackSelfCoin ? 'color-yellow' : ''}`}
                                             contentEditable={true} onInput={amountChangeHandler}
                                             onBlur={amountBlurHandler}
                                             dangerouslySetInnerHTML={{__html: keyNum.toLocaleString('US-en') || ''}}
                                             onKeyDown={(e) => {
                                                 if (e.key.length === 1 && isNaN(e.key)) {
                                                     e.preventDefault()
                                                 }
                                             }}></div>
                                    </div>
                                    <div className={`${s.controlBtn} ${s.controlBtnRight}`} onClick={() => {
                                        controlAmount(1)
                                    }}>
                                        <CustomIcon width={30} height={30}
                                                    imgName={'Button/UI_Button_Plus-Yellow-Irr_01'}/>
                                    </div>
                                    <div className={`${s.costLfgTypeBtn}`}
                                         style={{backgroundImage: 'url(/UI/Button/UI_Button_SetUp-Block-White_01.png)'}}
                                         onClick={() => {
                                             setShowCostLfgTypeModal(true);
                                         }}>
                                    </div>
                                </div>
                                <div className={`${s.showValue} flex_center_between`}>
                                    <div className="fs12">{t('Min Fee')}: <span
                                        className="fs12 color-yellow">{vCoinString(defaultPutCoin || 0)}</span></div>
                                    <div className="fs12">{t('Available $LFG')}: <span
                                        className="fs12 color-yellow">{vCoinString(totalCoin || 0)}</span></div>
                                </div>
                                <div className={s.lootBtn}>
                                    <ButtonFactory disabled={!canLoot()} color={1} size={21} scale={1.3}
                                                   onClick={() => {
                                                       startLoot();
                                                   }}>
                                        <div className="fb" style={{
                                            fontSize: `22px`,
                                            color: canLoot() ? `rgba(0, 0, 0, 1)` : `rgba(255, 255, 255, 1)`
                                        }}>
                                            {t('LOOT')}
                                        </div>
                                        <CustomIcon width={26} height={26} className="ml5"
                                                    imgName={'Picture/UI_Picture_Battle_02'}/>
                                    </ButtonFactory>
                                </div>
                            </> :
                            lootState === 1 ? //looting
                                <>
                                    <div className={s.lootingSlider}>
                                        <div className={s.lootingSliderTotal}>
                                            <div className={s.lootingSlidercontent}
                                                 style={{width: `${lootProgress}%`}}></div>
                                        </div>
                                    </div>
                                    <div className={`${s.lootingText}`}>
                                        {`${t('loading')}: ${lootProgress}%`}
                                    </div>
                                    {
                                        lootTimeout &&
                                        <div className={`flex_center_start_col`}>
                                            <div className={`fs12`}>
                                                {t('xx will be refuned after yy minutes(when failed)', {
                                                    lfg: convertWalletBalance(costLFG),
                                                    time: displayLootRefundTime
                                                })}
                                            </div>
                                            <AntButton className={`${s.lootBackBtn} mt10 btn_white flex_center_center`}
                                                       onClick={() => {
                                                            closeFn();
                                                       }}>
                                                {t('BACK')}
                                            </AntButton>
                                        </div>
                                    }
                                </> :
                                lootState === 2 ? //failed
                                    <>
                                    </> :
                                    lootState === 3 ? //success
                                        <>
                                        </> :
                                        lootState === 4 ? //claim
                                            <>
                                            </> :
                                            lootState === 5 ? //error
                                                <>
                                                    <div className={`fs16 mt40`}>
                                                        {failedMessage}
                                                    </div>
                                                    <AntButton
                                                        className={`${s.lootBackBtn} mt20 btn_white flex_center_center`}
                                                        onClick={() => {
                                                            closeFn();
                                                        }}>
                                                        {t('BACK')}
                                                    </AntButton>
                                                </> :
                                                lootState === 6 ? //refund
                                                    <>
                                                        <div className={`fs20 mt20`}>
                                                            {t('Loot Suspend')}
                                                        </div>
                                                        <div className={`fs16 mt5`}>
                                                            {t('The attack fee will be refunded to the LFG pool')}
                                                        </div>
                                                        <div className={`fs16 flex_center_center`}>
                                                            <CustomIcon width={26} height={26} className="ml5"
                                                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}/>
                                                            {Number(returnClaimCoin).toLocaleString("en-US") || 0}
                                                        </div>
                                                        <AntButton
                                                            className={`${s.lootBackBtn} mt10 btn_white flex_center_center`}
                                                            onClick={() => {
                                                                closeFn();
                                                            }}>
                                                            {t('BACK')}
                                                        </AntButton>
                                                    </> :
                                                    <>
                                                        <AntButton
                                                            className={`${s.lootBackBtn} mt20 btn_white flex_center_center`}
                                                            onClick={() => {
                                                                closeFn();
                                                            }}>
                                                            {t('BACK')}
                                                        </AntButton>
                                                    </>
                    }
                </div>
                <div
                    className={`${s.commonCard} ${s.attackCardRoot} 
                        ${showAttackCardInAni ? s.attackCardInAniRoot : ''} 
                        ${attackCardBatting ? s.attackCardBattingAniRoot : ''} 
                        ${attackLose ? s.attackCardLoseAniRoot : ''}
                        ${defenceLose ? s.attackCardWinAniRoot : ''}`}>
                    {
                        lastLootEmployeeUserInfo &&
                            <div className={`${s.clubItemRoot} ${playChangeEmployeeAni?s.clubCardOutAniRoot:''}`}>
                                <div className={s.clubAvatar}
                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                </div>
                                <div className={s.clubAvatar}
                                     style={{backgroundImage: `url(${getAvatarFromProfile(lastLootEmployeeUserInfo?.profile, 1) || getAvatarFromProfile(lastLootEmployeeUserInfo?.profile)})`}}>
                                </div>
                                <div className={s.clubCard}
                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)`}}>
                                    <div className={s.cardTop}>
                                        <div className={s.cardName}>
                                            {formatCardDisplayName(displayFilter(lastLootEmployeeUserInfo?.profile?.displayName || ''))}
                                        </div>
                                        <div className={s.cardRank}>
                                            <CustomIcon className="" width={16} height={16}
                                                        imgName={`Picture/Rank/RankStar_${lastLootEmployeeUserInfo?.tierId}`}/>
                                            <CustomIcon className="ml2" width={16} height={16}
                                                        imgName={`Picture/BPTier/${getBattlePointRankImg(lastLootEmployeeUserInfo?.bpLevel || 1)}`}/>
                                        </div>
                                    </div>
                                    <div className={s.cardBottom}>
                                        <div className={`${s.cardBottomPublic} ${s.cardBottomLeft}`}>
                                            <CustomIcon width={28} height={28}
                                                        imgName={'Picture/UI_Picture_Power_01'}></CustomIcon>
                                            {Number(lastLootEmployeeUserInfo?.power || 0)?.toLocaleString("en-US")}
                                        </div>
                                        <div className={`${s.cardBottomPublic} ${s.cardBottomRight}`}>
                                            <CustomIcon width={28} height={28}
                                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}></CustomIcon>
                                            {convertWalletBalance(lastLootEmployeeUserInfo?.claimCoin || 0)}
                                        </div>
                                    </div>
                                </div>
                                {
                                    lastLootEmployeeUserInfo?.profile?.accType === 2 && 
                                    <CustomIcon className={s.nftIcon} width={32} height={32}
                                                    imgName={'Picture/UI_Picture-NFT'}/>
                                }
                            </div>
                    }
                    {
                        lootEmployeeUserInfo ?
                            <div className={`${s.clubItemRoot} ${playChangeEmployeeAni?s.clubCardInAniRoot:''}`} 
                                onClick={()=>{
                                    if (lootEmployeeUserInfo?.userId > 0 && !lootLock && lootState === 0){
                                        joinChat(lootEmployeeUserInfo.userId, navigate, lootEmployeeUserInfo?.profile?.accType || 0);
                                    }
                                }}>
                                <div className={s.clubAvatar}
                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                </div>
                                <div className={s.clubAvatar}
                                     style={{backgroundImage: `url(${getAvatarFromProfile(lootEmployeeUserInfo?.profile, 1) || getAvatarFromProfile(lootEmployeeUserInfo?.profile)})`}}>
                                </div>
                                <div className={s.clubCard}
                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)`}}>
                                    <div className={s.cardTop}>
                                        <div className={s.cardName}>
                                            {formatCardDisplayName(displayFilter(lootEmployeeUserInfo?.profile?.displayName || ''))}
                                        </div>
                                        <div className={s.cardRank}>
                                            <CustomIcon className="" width={16} height={16}
                                                        imgName={`Picture/Rank/RankStar_${lootEmployeeUserInfo?.tierId}`}/>
                                            <CustomIcon className="ml2" width={16} height={16}
                                                        imgName={`Picture/BPTier/${getBattlePointRankImg(lootEmployeeUserInfo?.bpLevel || 1)}`}/>
                                        </div>
                                    </div>
                                    <div className={s.cardBottom}>
                                        <div className={`${s.cardBottomPublic} ${s.cardBottomLeft}`}>
                                            <CustomIcon width={28} height={28}
                                                        imgName={'Picture/UI_Picture_Power_01'}></CustomIcon>
                                            {Number(lootEmployeeUserInfo?.power || 0)?.toLocaleString("en-US")}
                                        </div>
                                        <div className={`${s.cardBottomPublic} ${s.cardBottomRight}`}>
                                            <CustomIcon width={28} height={28}
                                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}></CustomIcon>
                                            {convertWalletBalance(lootEmployeeUserInfo?.claimCoin || 0)}
                                        </div>
                                    </div>
                                </div>
                                {
                                    lootEmployeeUserInfo?.profile?.accType === 2 && 
                                    <CustomIcon className={s.nftIcon} width={32} height={32}
                                                    imgName={'Picture/UI_Picture-NFT'}/>
                                }
                            </div> :
                            <div className={s.emptyCard}
                                 style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindowPlus_01.png)`}}
                                 onClick={() => {
                                    if (buyFirstKey){
                                        navigate(`${mainClubSweepClubsPath}/0`);
                                    }
                                    else{
                                        navigate(balancePath);
                                    }
                                 }}>
                                <div className={`${s.attackGetMore} flex_center_center`}>
                                    {t('GET MORE')}
                                </div>
                            </div>
                    }
                    {
                        (lootState === 0 && !playingReadyAni) &&
                        <>
                            <CustomIcon width={28} height={28} className={s.switchBtn} imgName={'Button/UI_Button-Diamond_UpAndDown_01'}
                                onClick={()=>{
                                    setShowChooseLootClub(true);
                                }}/>
                        </>
                    }
                </div>
                <div
                    className={`${s.commonCard} ${s.defanceCardRoot} ${showDefenceCardInAni ? s.defenceCardInAniRoot : ''} ${defenceCardBatting ? s.defenceCardBattingAniRoot : ''} ${defenceLose ? s.defenceCardLoseAniRoot : ''}`}>
                    {
                        lastLootTargetUserInfo ?
                            <div className={`${s.clubItemRoot} ${playChangeTargetAni?s.clubCardOutAniRoot:''}`}>
                                <div className={s.clubAvatar}
                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                </div>
                                <div className={s.clubAvatar}
                                     style={{backgroundImage: `url(${getAvatarFromProfile(lastLootTargetUserInfo?.profile, 1) || getAvatarFromProfile(lastLootTargetUserInfo?.profile)})`}}>
                                </div>
                                <div className={s.clubCard}
                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)`}}>
                                    <div className={s.cardTop}>
                                        <div className={s.cardName}>
                                            {formatCardDisplayName(displayFilter(lastLootTargetUserInfo?.profile?.displayName || ''))}
                                        </div>
                                        <div className={s.cardRank}>
                                            <CustomIcon className="" width={14} height={14}
                                                        imgName={`Picture/Rank/RankStar_${lastLootTargetUserInfo?.tierId}`}/>
                                            <CustomIcon className="ml2" width={14} height={14}
                                                        imgName={`Picture/BPTier/${getBattlePointRankImg(lastLootTargetUserInfo?.bpLevel || 1)}`}/>
                                        </div>
                                    </div>
                                    <div className={s.cardBottom}>
                                        <div className={`${s.cardBottomPublic} ${s.cardBottomLeft}`}>
                                            <CustomIcon width={28} height={28}
                                                        imgName={'Picture/UI_Picture_Power_01'}></CustomIcon>
                                            {Number(lastLootTargetUserInfo?.power || 0)?.toLocaleString("en-US")}
                                        </div>
                                        <div className={`${s.cardBottomPublic} ${s.cardBottomRight}`}>
                                            <CustomIcon width={28} height={28}
                                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}></CustomIcon>
                                            {convertWalletBalance(lastLootTargetUserInfo?.claimCoin || 0)}
                                        </div>
                                    </div>
                                </div>
                                {
                                    lastLootTargetUserInfo?.profile?.accType === 2 && 
                                    <CustomIcon className={s.nftIcon} width={32} height={32}
                                                    imgName={'Picture/UI_Picture-NFT'}/>
                                }
                            </div> :
                            <div className={s.emptyCard}
                                 style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindowPlus_01.png)`}}
                                 onClick={() => {
                                     navigate(gameTrillionaireBattleLeaderBoardPath);
                                 }}>
                            </div>
                    }
                    {
                        lootTargetUserInfo ?
                            <div className={`${s.clubItemRoot} ${playChangeTargetAni?s.clubCardInAniRoot:''}`}
                                onClick={() =>{
                                    if (lootTargetUserInfo?.userId > 0 && !lootLock && lootState === 0){
                                        joinChat(lootTargetUserInfo.userId, navigate, lootTargetUserInfo?.profile?.accType || 'key');
                                    }
                                }}>
                                <div className={s.clubAvatar}
                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                </div>
                                <div className={s.clubAvatar}
                                     style={{backgroundImage: `url(${getAvatarFromProfile(lootTargetUserInfo?.profile, 1) || getAvatarFromProfile(lootTargetUserInfo?.profile)})`}}>
                                </div>
                                <div className={s.clubCard}
                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)`}}>
                                    <div className={s.cardTop}>
                                        <div className={s.cardName}>
                                            {formatCardDisplayName(displayFilter(lootTargetUserInfo?.profile?.displayName || ''))}
                                        </div>
                                        <div className={s.cardRank}>
                                            <CustomIcon className="" width={14} height={14}
                                                        imgName={`Picture/Rank/RankStar_${lootTargetUserInfo?.tierId}`}/>
                                            <CustomIcon className="ml2" width={14} height={14}
                                                        imgName={`Picture/BPTier/${getBattlePointRankImg(lootTargetUserInfo?.bpLevel || 1)}`}/>
                                        </div>
                                    </div>
                                    <div className={s.cardBottom}>
                                        <div className={`${s.cardBottomPublic} ${s.cardBottomLeft}`}>
                                            <CustomIcon width={28} height={28}
                                                        imgName={'Picture/UI_Picture_Power_01'}></CustomIcon>
                                            {Number(lootTargetUserInfo?.power || 0)?.toLocaleString("en-US")}
                                        </div>
                                        <div className={`${s.cardBottomPublic} ${s.cardBottomRight}`}>
                                            <CustomIcon width={28} height={28}
                                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}></CustomIcon>
                                            {convertWalletBalance(lootTargetUserInfo?.claimCoin || 0)}
                                        </div>
                                    </div>
                                </div>
                                {
                                    lootTargetUserInfo?.profile?.accType === 2 && 
                                    <CustomIcon className={s.nftIcon} width={32} height={32}
                                                    imgName={'Picture/UI_Picture-NFT'}/>
                                }
                            </div> :
                            <div className={s.emptyCard}
                                 style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindowPlus_01.png)`}}
                                 onClick={() => {
                                     navigate(gameTrillionaireBattleLeaderBoardPath);
                                 }}>
                            </div>
                    }
                    {
                        (lootState === 0 && !playingReadyAni) &&
                        <>
                            <CustomIcon width={28} height={28} className={s.customBtn} imgName={'Button/UI_Button-Diamond_ThreeTieRod_01'}
                                onClick={()=>{
                                    navigate(gameTrillionaireBattleLeaderBoardPath);
                                }}/>
                            <CustomIcon width={28} height={28} className={s.refreshBtn} imgName={'Button/UI_Button-Diamond_Refresh_01'}
                                onClick={()=>{
                                    randomLoot();
                                    /*setRefreshBtnRotate(true);
                                    setTimeout(() => {
                                        setRefreshBtnRotate(false);
                                    }, 500);*/
                                }}/>
                        </>
                    }
                </div>
                {
                    (lootState === 0 && !playingReadyAni) &&
                    <div className={`${s.centerPart} flex_center_center_col bgShadow`}
                         style={{backgroundImage: `url(/UI/Picture/UI_Picture_PartYellow_Battle_01.png)`}}>
                        <div className={`${s.centerPartLine} mb5 flex_center_center`}>
                            <div className={`${s.centerPartLineTitle} fs16 color-white`}>
                                {t('Success Rate')}
                            </div>
                            <div className={`${s.centerPartLineValue} fs16 color-white`}>
                                {`${(Math.ceil(successRate * 10000)) / 100 || 0}%`}
                            </div>
                            <CustomIcon width={18} height={18} className="ml5"
                                        imgName={'Button/UI_Button_QuestionMark_03'}
                                        onClick={() => {
                                            setShowCrackModelRateIntro(true);
                                        }}/>
                        </div>
                        <div className={`${s.centerPartLine} flex_center_center`}>
                            <div className={`${s.centerPartLineTitle} fs16 color-white`}>
                                {t('My Earnings')}
                            </div>
                            <div className={`${s.centerPartLineValue} fs16 color-white`}>
                                <CustomIcon width={20} height={20} className="mr5"
                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}/>
                                {vCoinString(crackSelfCoin) || 0}
                            </div>
                            {lootEmployeeUserInfo?.profile?.accType !== 2 && <CustomIcon width={18} height={18} className="ml5"
                                        imgName={'Button/UI_Button_QuestionMark_03'}
                                        onClick={() => {
                                            setModelEarnIntroInfo({
                                                total: crackCoin,
                                                myEarn: crackSelfCoin,
                                                employeeAllKeyNum: employeeAllKeyNum,
                                                holdingKeyNum: holdingKeyNum,
                                                isSelfClub: Number(userInfo?.userId) === Number(lootEmployeeUserInfo?.userId),
                                                isLoot: true,
                                                isWish: lootEmployeeUserInfo?.accountType === 'wish'
                                            });
                                            setShowEarnIntro(true);
                                        }}/> }
                        </div>
                        <div className={s.centerPartLootGifRight}
                             style={{backgroundImage: `url(/UI/Gif/UI_Gif_Looter_01.gif)`}}>
                        </div>
                        <div className={s.centerPartLootGifLeft}
                             style={{backgroundImage: `url(/UI/Gif/UI_Gif_Looter_01.gif)`}}>
                        </div>
                    </div>
                }
                {
                    playBattingGif &&
                    <div className={`${s.battingGif}`} style={{backgroundImage: `url(/UI/Gif/UI_Gif_Battle_01.gif)`}}>
                    </div>
                }
                {
                    defenceLose &&
                    <div className={s.battingWinPerformanceRoot} onClick={()=>{
                            //setShowLootResult(true);
                        }}>
                        <div className={`${s.battingWinGif}`} style={{backgroundImage: `url(/UI/Gif/UI_Gif_LootWin.gif)`}}>
                        </div>
                        <div className={`${s.battingWinBgGif}`} style={{backgroundImage: `url(/UI/Gif/UI_Gif_LootWin_BG.gif)`}}>
                        </div>
                    </div>
                }
                {
                    attackLose &&
                    <div className={s.battingLosePerformanceRoot} onClick={() =>{
                            //setShowLootResult(true);
                        }}>
                        <div className={`${s.battingLoseBgGif}`}
                            style={{backgroundImage: `url(/UI/Gif/UI_Gif_LootLose_BG.gif)`}}>
                        </div>
                        <div className={`${s.battingLoseGif}`}
                            style={{backgroundImage: `url(/UI/Gif/UI_Gif_LootLose.gif)`}}>
                        </div>
                    </div>
                }
            </div>
            {
                (playChangeEmployeeAni || playChangeTargetAni || showLeftClaimCoinAni || showRightClaimCoinAni) &&
                <div className={s.lootSheld}>
                </div>
            }
            {
                showChooseLootClub &&
                <CrackModelClubList closeFn={() => setShowChooseLootClub(false)}
                                    tarUserId={lootTargetUserInfo?.userId}
                                    lootEmployeeUserInfo={lootEmployeeUserInfo}
                                    setLootEmployeeUserInfo={setLootEmployeeUserInfo}
                                    lastLootEmployeeUserInfo={lastLootEmployeeUserInfo}
                                    setLastLootEmployeeUserInfo={setLastLootEmployeeUserInfo}/>
            }
            {
                showLootResult && (lootState === 3 || lootState === 4 || lootState === 2) && 
                <div className={`${s.lootResult}`} style={{ backgroundImage: `url(${getResultBg()})` }}>
                    {
                        lootState === 3 || lootState === 4 && 
                        <div className={`${s.winCenterPart} flex_center_center_col bgShadow`}
                            style={{backgroundImage: `url(/UI/Picture/UI_Picture_PartYellow_Battle_01.png)`}}>
                            <div className={`fs18 fb flex_center_center color-yellow`}>
                                {t('Success! You looted this club!')}
                            </div>
                            <div className={`${s.centerPartLine} mb2 flex_center_center`}>
                                <div className={`${s.centerPartLineTitle} fs16`}>
                                    {t('My Earnings')}
                                </div>
                                <div className={`${s.centerPartLineValue} fs16`}>
                                    <CustomIcon width={20} height={20} className="mr5"
                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}/>
                                    {vCoinString(lootResultData?.selfTakeCoin || 0)}
                                </div>
                                {lootEmployeeUserInfo?.profile?.accType !== 2 &&  <CustomIcon width={18} height={18} className="ml5"
                                            imgName={'Button/UI_Button_QuestionMark_03'}
                                            onClick={() => {
                                                setModelEarnIntroInfo({
                                                    total: lootResultData?.lootCoin,
                                                    myEarn: lootResultData?.selfTakeCoin,
                                                    employeeAllKeyNum: lootResultData?.employeeAllKeyNum,
                                                    holdingKeyNum: lootResultData?.holdingKeyNum,
                                                    isSelfClub: Number(userInfo?.userId) === Number(lootEmployeeUserInfo?.userId),
                                                    isLoot: true,
                                                    isWish: lootEmployeeUserInfo?.accountType === 'wish'
                                                });
                                                setShowEarnIntro(true);
                                            }}/>}
                            </div>
                            <div className={`${s.centerPartLine} flex_center_center`}>
                                <div className={`${s.centerPartLineTitle} fs16`}>
                                    {t('My Battle Points')}
                                </div>
                                <div className={`${s.centerPartLineValue} fs16`}>
                                    <CustomIcon width={20} height={20} className="mr5"
                                                imgName={'Picture/UI_Picture-Currency_Power_01'}/>
                                    <div className={`fs16 color-white`}>
                                        {Number(lootResultData?.battlePoint || 0).toLocaleString("en-US")}
                                    </div>
                                    <div className={`fs16 color-white`}>
                                        {`(+${lootResultData?.battlePointChangeVal > 0 ? lootResultData?.battlePointChangeVal?.toLocaleString("en-US") : Number(Number(getDeltaBP(lootResultData?.battlePoint, costLFG) || 0).toFixed(4))})`}
                                    </div>
                                </div>
                                {lootEmployeeUserInfo?.profile?.accType !== 2 && <CustomIcon width={18} height={18} className="ml5"
                                            imgName={'Button/UI_Button_QuestionMark_03'}
                                            onClick={() => {
                                                setModelEarnIntroInfo({
                                                    total: crackCoin,
                                                    myEarn: crackSelfCoin,
                                                    employeeAllKeyNum: employeeAllKeyNum,
                                                    holdingKeyNum: holdingKeyNum,
                                                    isSelfClub: Number(userInfo?.userId) === Number(lootEmployeeUserInfo?.userId),
                                                    isLoot: true,
                                                    isWish: lootEmployeeUserInfo?.accountType === 'wish'
                                                });
                                                setShowEarnIntro(true);
                                            }}/>}
                            </div>
                            <div className={`${s.centerBtnRoot} flex_center_center`}>
                                <AntButton
                                    className={`btn_white flex_center_center`}
                                    onClick={() => {
                                        setShowLeftClaimCoinAni(true);
                                        setTimeout(() => {
                                            setShowLeftClaimCoinAni(false);
                                            setLootTargetUserInfo(null);
                                            setLootEmployeeUserInfo(null);
                                            closeFn();
                                        }, 800);
                                    }}>
                                    {t('CLAIM')}
                                </AntButton>
                                <AntButton
                                    className={`ml20 btn_yellow flex_center_center`}
                                    onClick={() => {
                                        setShowRightClaimCoinAni(true);
                                        setTimeout(() => {
                                            setShowRightClaimCoinAni(false);
                                            setLootTargetUserInfo(null);
                                            setLootEmployeeUserInfo(null);
                                            setShowClaimResult(true);
                                        }, 800);
                                    }}>
                                    <CustomIcon width={16} height={16} className=""
                                                imgName={'Button/UI_Button_Share_02'}/>
                                    {t('SHARE&CLAIM')}
                                </AntButton>
                                {
                                    showLeftClaimCoinAni && 
                                    <GetCoinAni 
                                        className={s.getLeftCoinAniRoot} 
                                        coinType={1}></GetCoinAni>
                                }
                                {
                                    showRightClaimCoinAni && 
                                    <GetCoinAni 
                                        className={s.getRightCoinAniRoot} 
                                        coinType={1}></GetCoinAni>
                                }
                            </div>
                        </div>
                    }
                    {
                        lootState === 2 &&
                        <div className={`${s.loseCenterPart} flex_center_center_col bgShadow`}
                            style={{backgroundImage: `url(/UI/Picture/UI_Picture_PartYellow_Battle_02.png)`}}>
                            <div className={`fs18 fb flex_center_center color-light-gray`}>
                                {t('LOOT FAILED')}
                            </div>
                            <div className={s.loseCenterText}>
                                {t(`LootResultConfig_${lootResultData?.resultCfgId}_resultText`)}
                            </div>
                            <div className={`${s.centerBtnRoot} flex_center_center`}>
                                <AntButton className={`${s.lootBackBtn} btn_white flex_center_center`}
                                        onClick={() => {
                                            closeFn();
                                        }}>
                                    {t('BACK')}
                                </AntButton>
                                <AntButton className={`${s.lootBackBtn} ml20 btn_yellow flex_center_center`}
                                        onClick={() => {
                                            lootAgain();
                                        }}>
                                    {t('Try Again')}
                                </AntButton>
                            </div>
                        </div>
                    }
                </div>
            }
            {
                showClaimResult &&
                <HireClaimedResult info={{
                    lootCoin: lootResultData?.lootCoin,
                    selfClaimCoin: lootResultData?.selfTakeCoin,
                    resulteInfo: LootResultConfig.find(v => v.ID === lootResultData?.resultCfgId),
                    employeeTwitterProfile: lootResultData?.employeeTwitterProfile,
                    tarTwitterProfile: lootResultData?.tarTwitterProfile,
                    tweetTopic: lootResultData?.tweetTopic,
                    type: 2,
                    profile: getProfile(platformData),
                    referralCode: selfData?.referralCode,
                }}
                                   closeFn={() => {
                                       setShowClaimResult(false);
                                       closeFn();
                                   }}/>
            }
            {
                showBlackMask &&
                <div className={s.blackMask}>
                </div>
            }
            <AntModal
                width='330px'
                title={t('EARNINGS DETAIL')}
                className="confirmModalWrap p0"
                centered={true}
                open={showEarnIntro}
                destroyOnClose={true}
                onOk={() => setShowEarnIntro(false)}
                onCancel={() => setShowEarnIntro(false)}
                zIndex={2000}
            >
                <HireModelEarnIntro
                    closeHandle={() => {
                        setShowEarnIntro(false)
                    }}
                    info={modelEarnIntroInfo}
                />
            </AntModal>
            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={showBattlePointTip}
                destroyOnClose={true}
                onOk={() => setShowBattlePointTip(false)}
                onCancel={() => setShowBattlePointTip(false)}
                zIndex={2000}
            >
                <ModelBattlePointTip userInfo={userInfo} closeFn={() => {
                    setShowBattlePointTip(false)
                }}/>
            </AntModal>
            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={showCrackModelRateIntro}
                destroyOnClose={true}
                onOk={() => setShowCrackModelRateIntro(false)}
                onCancel={() => setShowCrackModelRateIntro(false)}
                zIndex={2000}
            >
                <CrackModelRateIntro/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('LOOT CLUB')}
                className="confirmModalWrap"
                centered={true}
                open={showCostLfgTypeModal}
                destroyOnClose={true}
                onOk={() => setShowCostLfgTypeModal(false)}
                onCancel={() => setShowCostLfgTypeModal(false)}
                zIndex={2000}
            >
                <ModalCostLfgType closeFn={() => {
                    setShowCostLfgTypeModal(false);
                }}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg insufficientAmount={Number(keyNum - userInfo?.selfData?.lfgBalance) || 0}
                              userInfo={userInfo} closeFn={() => {
                    setShowSwapLfg(false);
                }}/>
            </AntModal>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo,
        lootTargetUserInfo: app.lootTargetUserInfo,
        lootEmployeeUserInfo: app.lootEmployeeUserInfo,
        lootSearchClubLvRange: app.lootSearchClubLvRange,
        costLfgType: app.costLfgType,
        lastLootTargetUserInfo: app.lastLootTargetUserInfo,
        lastLootEmployeeUserInfo: app.lastLootEmployeeUserInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setLootTargetUserInfo: (params) => {
            return dispatch({
                type: "app/setLootTargetUserInfo",
                payload: params,
            });
        },
        setLastLootTargetUserInfo: (params) => {
            return dispatch({
                type: "app/setLastLootTargetUserInfo",
                payload: params,
            });
        },
        setLootEmployeeUserInfo: (params) => {
            return dispatch({
                type: "app/setLootEmployeeUserInfo",
                payload: params,
            });
        },
        setLastLootEmployeeUserInfo: (params) => {
            return dispatch({
                type: "app/setLastLootEmployeeUserInfo",
                payload: params,
            });
        },
        setCostLfgType: (params) => {
            return dispatch({
                type: "app/setCostLfgType",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Battling));
