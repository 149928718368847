import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { Tabs, Modal, Button, Checkbox } from 'antd';
import s from "./index.module.less";
import {
    getfriendtrade_rank_selfdata,
    convertWalletBalance,
    vCoinString,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import MainHeader from "src/common/MainHeader";
import ModelRankReward from "@/model/ModelRankReward";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import {
    gameReferralPath,
    gameStakePath,
    gameTrillionaireBattlePath,
    gameShareUpPointPath,
    gameMemeClubPath,
    balancePath,
    gameMemeClubLauncherPath,
    gameMomentClubPath,
    gameMomentClubExplorePath,
    gameMomentClubDetailPath,
} from "@/routes/config";
import {
    momentClubInfo, 
    getChainInfo, 
    setFavorite, 
    moment_tradelog, 
    video_play_token,
    momentClubList,
    defaultMemeSwitchVideoParams,
} from "@/utils/momentClub";
import { lfgStakeBalanceOf } from "@/utils/lfgStake";
import AntModal from "@/common/AntModal";
import { AutoStrangeRule } from "@/utils/strangeRule";
import ModelUpPointsReward from "@/model/ModelUpPointsReward";
const NewHome = (
    {
        userInfo,
        setUserInfo,
        earnInfo,
        hideNSFW,
        setSwitchVideoListCursor,
        setSwitchVideoList,
        setCurrentMomentClubId,
    }
) => {
    const { t, i18n } = useTranslation();
    const ref = useRef();
    const navigate = useNavigate();
    const { wallets } = useWallets();
    const {
        platformData,
        selfData,
        walletAddr,
        managerProfile,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        balance,
        claimCoin,
        points,
        payEntryFee, 
        buyFirstKey
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);

    const [rankIdx, setRankIdx] = useState('--');
    const [lfgStakeBalance, setLfgStakeBalance] = useState('--');
    const [showRankRewardPanel, setShowRankRewardPanel] = useState(false);

    const refreshStakeInfo = () => {
        lfgStakeBalanceOf(wallets).then(
            res => {
                setLfgStakeBalance(vCoinString(res))
            }
        )
    }
    const goMomentClub = () => {
        let params = defaultMemeSwitchVideoParams;
        momentClubList({
            rankType: params.rankType,
            coinId: params.coinId,
            priceRange: params.priceRange,
            progressRange: params.progressRange,
            lpRange: params.lpRange,
            onlyFavorite: params.onlyFavorite,
            onlyHeld: params.onlyHeld,
            onlyLive: params.onlyLive,
            isAsc: params.isAsc,
            cursor: ""
        }).then(res => {
            let _list = []; 
            if (res?.clubList?.length > 0){
                for (let i = 0; i < res?.clubList?.length; i++){
                    let stateCode = res?.clubList[i].profile.momentAuditCode;
                    if (stateCode !== 2 || (stateCode === 2 && !hideNSFW)){
                        _list.push({
                            clubId: res?.clubList[i].userId,
                            momentAuditCode: stateCode,
                            videoId: res?.clubList[i].videoId,
                            videoUrlMap: res?.clubList[i].videoUrlMap,
                            coverUrl: res?.clubList[i].profile.avatarUrl,
                            videoOriHeight: res?.clubList[i].videoOriHeight,
                            videoOriWidth: res?.clubList[i].videoOriWidth,
                            videoFirstFrameUrl: res?.clubList[i].videoFirstFrameUrl,
                        });
                    }
                }
            }
            let index = -1;
            if (_list?.length > 0){
                for (let i = 0; i < _list?.length; i++){
                    if (_list[i]?.momentAuditCode !== 3){
                        index = i;
                        break;
                    }
                }
            }
            setSwitchVideoList(_list);
            if (res?.nextCursor === ""){
                setSwitchVideoListCursor("-1");
            }
            else{
                setSwitchVideoListCursor(res?.nextCursor);
            }
            if (index >= 0){
                setCurrentMomentClubId(_list[index].clubId);
                navigate(`${gameMomentClubDetailPath}`,{replace:true,state: {backPath:gameMomentClubExplorePath}});
                
            }
            else{
                navigate(gameMomentClubExplorePath);
            }
        }).catch(e => {
            console.error(e);
            navigate(gameMomentClubExplorePath);
        })
    }

    useEffect(() => {
        if (wallets.length > 0) {
            refreshStakeInfo();
        }
    }, [wallets])

    useEffect(() => {
        getfriendtrade_rank_selfdata().then(res => {
            if (res?.rankIdx && res?.rankIdx > 10000) {
                setRankIdx('9999+');
            } else {
                setRankIdx(res?.rankIdx);
            }
        }).catch(err => {
            console.error(err.message);
        })
    }, [])

    return (
        <div className={`${s.wrap} mainWrap commonBlueBg`}>
            <MainHeader headerType={2} hasBackBtn={false} leftTitle={t('Home')}></MainHeader>
            <div className={s.content}>
                <div className={s.partRoot}>
                    {
                        window.isMomentsLink ?
                        <>
                            <div className={`${s.partTitle} fb`}>
                                <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                                    imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />

                                <Trans
                                    i18nKey="Moments COLLECTION"
                                    defaults="Moments COLLECTION"
                                    values={{ tag: '$IYKYK' }}
                                    components={{ yellow: <span className="color-yellow fs20 ml5 mr5"></span> }}
                                />

                            </div>
                            <div className={`${s.bigContentPart}`}
                                style={{ backgroundImage: 'url(/UI/BG/UI_Background-S_Home_11.webp)' }}
                                onClick={() => {
                                    //navigate(gameMomentClubExplorePath);
                                    goMomentClub();
                                }}
                            >
                            </div>
                        </> :
                        <>
                            <div className={`${s.partTitle} fb`}>
                                <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                                    imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                                <Trans
                                    i18nKey="Meme Collection"
                                    defaults="Meme Collection"
                                    values={{ tag: '$IYKYK' }}
                                    components={{ yellow: <span className="color-yellow fs20 ml5 mr5"></span> }}
                                />

                            </div>
                            <div className={`${s.bigContentPart}`}
                                    style={{ backgroundImage: 'url(/UI/BG/UI_Background-S_Home_10.webp)' }}
                                    onClick={() => {
                                        navigate(gameMemeClubLauncherPath);
                                    }}
                                >
                            </div>
                        </>
                    }
                    
                    {/* <div className={`${s.partTitle} fb mt30`}>
                        <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                            imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                        <Trans
                            i18nKey="Post on X & Earn Airdrops"
                            defaults="Post on X & Earn Airdrops"
                            values={{ tag: '$IYKYK' }}
                            components={{ yellow: <span className="color-yellow fs20 ml5 mr5"></span> }}
                        />

                    </div>
                    <div className={`${s.middleContentPart}`} onClick={() => navigate(gameShareUpPointPath)}
                        style={{ backgroundImage: 'url(/UI/BG/UI_Background-S_Home_04.webp)' }}>
                        <ButtonFactory className={s.lowerRightBtn} color={2} size={23}>
                            <div className={s.lowerRightBtnContent}>
                                <div className={s.lowerRightBtnLeft}>
                                    <CustomIcon width={18} height={18} className="mr2" baseUrl={'/UI/'}
                                        imgName={'Picture/UI_Picture-Currency_UP_01'}
                                        name={'Picture/UI_Picture-Currency_UP_01'} />
                                    <span className={s.lowerRightBtnSpan}>{convertWalletBalance(points)}</span>
                                    <CustomIcon width={18} height={18} className="ml2 mr2" baseUrl={'/UI/'}
                                        imgName={'Button/UI_Button_QuestionMark_01'}
                                        name={'Button/UI_Button_QuestionMark_01'}
                                        onClick={() => {
                                            setShowRankRewardPanel(true);
                                        }} />
                                </div>
                                <div className={s.lowerRightBtnRight}>
                                    <CustomIcon width={18} height={18} className="" baseUrl={'/UI/'}
                                        imgName={'Picture/UI_Picture-Currency_UP_02'}
                                        name={'Picture/UI_Picture-Currency_UP_02'} />
                                    <span className={s.lowerRightBtnSpan}>{'SOON'}</span>
                                </div>
                            </div>
                        </ButtonFactory>

                    </div> */}

                    <div className={`${s.partTitle} mt30`}>
                        <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                            imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                        {AutoStrangeRule(t('EARN $LFG'))}
                    </div>
                    <div className={`${s.middleContentPart}`}
                        style={{ backgroundImage: 'url(/UI/BG/UI_Background-S_Home_07.webp)' }}
                        onClick={() => {
                            if(payEntryFee && buyFirstKey){
                                navigate(gameTrillionaireBattlePath)
                            }else{
                                navigate(balancePath);
                            }
                            
                        }}
                    >
                        <ButtonFactory className={s.lowerRightBtn} color={2} size={23}>
                            <div className={s.lowerRightBtnContent}>
                                <div className={s.lowerRightBtnLeft}>
                                    <CustomIcon width={18} height={18} baseUrl={'/UI/'} className=""
                                        imgName={'Picture/UI_Picture-Currency_Rank_01'}
                                        name={'Picture/UI_Picture-Currency_Rank_01'} />
                                    <span className={s.lowerRightBtnSpan}>{rankIdx}</span>
                                </div>
                                <div className={s.lowerRightBtnRight}>
                                    <CustomIcon width={18} height={18} baseUrl={'/UI/'} className=""
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'} />
                                    <span className={s.lowerRightBtnSpan}>{convertWalletBalance(claimCoin)}</span>
                                </div>
                            </div>
                        </ButtonFactory>
                    </div>

                    <div className={`${s.smallContentPart} mt30`}>
                        <div className={`${s.smallContent}`}
                            style={{ backgroundImage: 'url(/UI/BG/UI_Background-S_Home_03.webp)' }}
                            onClick={() => navigate(gameReferralPath)}>
                            <div className={s.smallContentTitle}>
                                {t('REFERRALS')}
                            </div>
                            {/*<ButtonFactory className={s.lowerBtn} color={1} size={21}>
                                {t('INVITE FRIENDS')}
                            </ButtonFactory>*/}
                        </div>
                        <div className={`${s.smallContent}`}
                            onClick={() => navigate(gameStakePath)}
                            style={{ backgroundImage: 'url(/UI/BG/UI_Background-S_Home_08.webp)' }}>
                            <div className={s.smallContentTitle}>
                                {t('STAKE $LFG')}
                            </div>
                            {/*<ButtonFactory className={s.lowerBtn} color={2} size={21}>
                                <div className={s.lowerBtnContent}>
                                    <CustomIcon width={18} height={18} className="" baseUrl={'/UI/'}
                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                    <span className={s.lowerBtnSpan}>{convertWalletBalance(lfgStakeBalance)}</span>
                                </div>
                            </ButtonFactory>*/}
                        </div>
                    </div>
                    {/*<div className={`${s.partTitle} mt20`}>
                        <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'} imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'}/>
                        {t('COMING SOON')}
                    </div>
                    <div className={`${s.smallContentPart} mt8`}>
                        <div className={`${s.smallContent}`} style={{backgroundImage: 'url(/UI/BG/UI_Background-S_Home_05.webp)'}}>
                            <ButtonFactory className={s.lowerBtn} color={1} size={21}>
                                {t('BRUCE LEE')}
                            </ButtonFactory>
                        </div>
                        <div className={`${s.smallContent}`} style={{backgroundImage: 'url(/UI/BG/UI_Background-S_Home_06.webp)'}}>
                            <ButtonFactory className={s.lowerBtn} color={1} size={21}>
                                {t('MOBLAND')}
                            </ButtonFactory>
                        </div>
                    </div>*/}
                </div>
            </div>
            <AntModal
                width='330px'
                title={t('Home $IYKYK Points')}
                className="confirmModalWrap"
                centered={true}
                open={showRankRewardPanel}
                destroyOnClose={true}
                onOk={() => setShowRankRewardPanel(false)}
                onCancel={() => setShowRankRewardPanel(false)}
            >
                <ModelUpPointsReward closeHandle={() => {
                    setShowRankRewardPanel(false);
                }} showBtn={false} />
            </AntModal>
        </div>

    )
}
const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo,
        hideNSFW: app.hideNSFW,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(NewHome));
