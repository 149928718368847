import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";

import {Button,InputNumber} from "antd";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "@/common/ButtonFactory";
const ModelEditVal = (
    {
        val,
        setVal,
        min,
        max,
        onClose,
        title=""
    }
) => {
    const {t, i18n} = useTranslation();
    const [inputVal,setInputVal] = useState(val)
    useEffect(()=>{
        setInputVal(val)
    },[val])
    return (
        <div className={s.wrap}>
            <div className="w100p flex_start_start mb10">{title}</div>
            <InputNumber className={s.input} min={min} max={max} value={inputVal} onChange={setInputVal}/>
            <div className="flex_center_center mt30">
                <ButtonFactory size={21} scale={1.1}  onClick={()=>{
                    setVal(inputVal)
                    onClose()
                }}>{t('Save')}</ButtonFactory>
            </div>
        </div>
    )
}
export default memo(ModelEditVal);
