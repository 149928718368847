import { polygon, base, baseSepolia } from 'viem/chains';

import { FeeAmount, Pool, Route, computePoolAddress, SwapQuoter, SwapRouter, Trade } from '@uniswap/v3-sdk';
import { CHAIN_TO_ADDRESSES_MAP, CurrencyAmount, TradeType, Percent, Token } from "@uniswap/sdk-core";
import SwapRouter02ABI from "@uniswap/swap-router-contracts/artifacts/contracts/SwapRouter02.sol/SwapRouter02.json";
import IUniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json'
//import Quoter from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json'
//import JSBI from 'jsbi'
import { TurnUpLFG_TOKEN, WMATIC_TOKEN, SWAP_ROUTER_ADDRESS, QUOTER_V2_ADDRESS/*, V3_SWAP_ROUTER_ADDRESS*/ } from "@/uniswapLibs/constants.ts";
import { fromReadableAmount, toReadableAmount } from "@/uniswapLibs/conversion.ts";
import { ethers } from 'ethers';
import { getConfig } from "./common";
import { getGasConfig } from "@/utils/gas";
import { abi_token } from "@/utils/abi";
export const uniswapQuoterLFG = async ({ wallets, amount }) => {

  const currentChainId = polygon.id;

  const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
  await embeddedWallet.switchChain(currentChainId);

  if (embeddedWallet && embeddedWallet.getEthersProvider) {
    const provider = await embeddedWallet.getEthersProvider();
    //const signer = provider.getSigner(); 

    const UNISWAP_ADDRESSES = CHAIN_TO_ADDRESSES_MAP[currentChainId];

    const currentPoolAddress = computePoolAddress({
      factoryAddress: UNISWAP_ADDRESSES.v3CoreFactoryAddress,
      tokenA: WMATIC_TOKEN,
      tokenB: TurnUpLFG_TOKEN,
      fee: FeeAmount.HIGH,
    });
    console.log('[current MATIC --> LFG PoolAddress]', currentPoolAddress);

    const poolContract = new ethers.Contract(currentPoolAddress, IUniswapV3PoolABI.abi, provider);

    const [fee, liquidity, slot0] =
      await Promise.all([
        poolContract.fee(),
        poolContract.liquidity(),
        poolContract.slot0(),
      ])

    console.log('[amount]', amount, '[fee]', fee, '[liquidity]', ethers.utils.formatUnits(liquidity, "ether"));
    console.log('[sqrtPriceX96]', ethers.utils.formatUnits(slot0[0], "ether"), '[tick]', slot0[1]);

    const poolLFG = new Pool(WMATIC_TOKEN, TurnUpLFG_TOKEN, fee, slot0[0].toString(), liquidity.toString(), slot0[1]);
    const swapRoute = new Route([poolLFG], WMATIC_TOKEN, TurnUpLFG_TOKEN);

    //查询给定amount的Matic Swap LFG 的数量
    const { calldata } = await SwapQuoter.quoteCallParameters(
      swapRoute,
      CurrencyAmount.fromRawAmount(
        WMATIC_TOKEN,
        fromReadableAmount(amount, WMATIC_TOKEN.decimals).toString()),
      TradeType.EXACT_INPUT,
      { useQuoterV2: true }
    );

    const quoteCallReturnData = await provider.call({
      to: QUOTER_V2_ADDRESS,
      data: calldata,
    });
    const amountOut = ethers.utils.defaultAbiCoder.decode(['uint256'], quoteCallReturnData);
    const amountOutStr = toReadableAmount(amountOut, TurnUpLFG_TOKEN.decimals - 5);
    const amountNumber = amountOutStr * 0.00001;
    console.log("[Recv LFG]", amountNumber);

    return amountNumber;
  }
};

export const uniswapLFG = async ({ wallets, amount, lfgAmount, sendTransaction }) => {

  const currentChainId = polygon.id;

  const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
  await embeddedWallet.switchChain(currentChainId);

  if (embeddedWallet && embeddedWallet.getEthersProvider) {
    const provider = await embeddedWallet.getEthersProvider();

    const UNISWAP_ADDRESSES = CHAIN_TO_ADDRESSES_MAP[currentChainId];

    const currentPoolAddress = computePoolAddress({
      factoryAddress: UNISWAP_ADDRESSES.v3CoreFactoryAddress,
      tokenA: WMATIC_TOKEN,
      tokenB: TurnUpLFG_TOKEN,
      fee: FeeAmount.HIGH,
    });

    const poolContract = new ethers.Contract(currentPoolAddress, IUniswapV3PoolABI.abi, provider);

    const [fee, liquidity, slot0] =
      await Promise.all([
        poolContract.fee(),
        poolContract.liquidity(),
        poolContract.slot0(),
      ])

    console.log('[amount]', amount, '[for lfg]', lfgAmount, '[fee]', fee, '[liquidity]', ethers.utils.formatUnits(liquidity, "ether"));
    console.log('[sqrtPriceX96]', ethers.utils.formatUnits(slot0[0], "ether"), '[tick]', slot0[1]);

    const poolLFG = new Pool(WMATIC_TOKEN, TurnUpLFG_TOKEN, fee, slot0[0].toString(), liquidity.toString(), slot0[1]);
    const swapRoute = new Route([poolLFG], WMATIC_TOKEN, TurnUpLFG_TOKEN);

    const uncheckedTrade = await Trade.createUncheckedTrade({
      route: swapRoute,
      inputAmount: CurrencyAmount.fromRawAmount(
        WMATIC_TOKEN,
        fromReadableAmount(amount, WMATIC_TOKEN.decimals).toString()
      ),
      outputAmount: CurrencyAmount.fromRawAmount(
        TurnUpLFG_TOKEN,
        fromReadableAmount(lfgAmount, TurnUpLFG_TOKEN.decimals).toString()
      ),
      tradeType: TradeType.EXACT_INPUT,
    });

    const options = {
      slippageTolerance: new Percent(100, 10_000), // 100 bips, or 1.0%
      deadline: Math.floor(Date.now() / 1000) + 60 * 5, // 5 minutes from the current Unix time
      recipient: embeddedWallet.address,
    };

    const methodParameters = SwapRouter.swapCallParameters([uncheckedTrade], options);
    const tx = {
      data: methodParameters.calldata,
      to: SWAP_ROUTER_ADDRESS,
      value: ethers.utils.parseEther(amount.toString())._hex,
      from: embeddedWallet.address,
      //maxFeePerGas: MAX_FEE_PER_GAS,
      //maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS,
    };

    const res = await sendTransaction(tx);
    console.log("[uniswapLFG Transcation Send]", res);
  }
};


export const getExchangeRate = ({ wallets, amount, tokenA, tokenB, chainInfo, swapPoolAddr }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { chainId, QUOTER_V2_ADDRESS, SWAP_ROUTER_ADDRESS } = chainInfo

      const _tokenA = new Token(
        tokenA?.chainId,
        tokenA?.address,
        tokenA?.decimals
      )
      const _tokenB = new Token(
        tokenB?.chainId,
        tokenB?.address,
        tokenB?.decimals
      )


      console.log(wallets, amount, tokenA, tokenB, chainId, swapPoolAddr);
      const currentChainId = chainId;

      const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
      await embeddedWallet.switchChain(currentChainId);
      console.log(`[embeddedWallet:]${embeddedWallet} [amount:]${amount} [chainId:]${chainId} [QUOTER_V2_ADDRESS:]${QUOTER_V2_ADDRESS} [SWAP_ROUTER_ADDRESS:]${SWAP_ROUTER_ADDRESS} [swapPoolAddr:]${swapPoolAddr} `);
      if (embeddedWallet && embeddedWallet.getEthersProvider) {
        const provider = await embeddedWallet.getEthersProvider();
        //const signer = provider.getSigner(); 

        const UNISWAP_ADDRESSES = CHAIN_TO_ADDRESSES_MAP[currentChainId];

        const currentPoolAddress = computePoolAddress({
          factoryAddress: UNISWAP_ADDRESSES.v3CoreFactoryAddress,
          tokenA: _tokenA,
          tokenB: _tokenB,
          fee: FeeAmount.HIGH,
        });
        console.log(`[to ${tokenA?.displayCoinName} --> form ${tokenB?.displayCoinName}] PoolAddress`, currentPoolAddress);

        // const currentPoolAddress = swapPoolAddr;
        // const currentPoolAddress = "0x252410eb4aa254fa53681b5c00ead14faa86c9d6";
        const poolContract = new ethers.Contract(currentPoolAddress, IUniswapV3PoolABI.abi, provider);

        const [fee, liquidity, slot0] =
          await Promise.all([
            poolContract.fee(),
            poolContract.liquidity(),
            poolContract.slot0(),
          ])

        console.log('[amount]', amount, '[fee]', fee, '[liquidity]', ethers.utils.formatUnits(liquidity, "ether"));
        console.log('[sqrtPriceX96]', ethers.utils.formatUnits(slot0[0], "ether"), '[tick]', slot0[1]);

        const poolLFG = new Pool(_tokenA, _tokenB, fee, slot0[0].toString(), liquidity.toString(), slot0[1]);
        const swapRoute = new Route([poolLFG], _tokenA, _tokenB);

        //查询给定A的数量获取能兑换B的数量
        const { calldata } = await SwapQuoter.quoteCallParameters(
          swapRoute,
          CurrencyAmount.fromRawAmount(
            _tokenA,
            fromReadableAmount(amount, _tokenA.decimals).toString()),
          TradeType.EXACT_INPUT,
          { useQuoterV2: true }
        );

        const quoteCallReturnData = await provider.call({
          to: QUOTER_V2_ADDRESS,
          data: calldata,
        });
        const amountOut = ethers.utils.defaultAbiCoder.decode(['uint256'], quoteCallReturnData);
        // const amountOutStr = toReadableAmount(amountOut, _tokenB.decimals );
        // const amountNumber = amountOutStr * 0.00001;
        const amountOutStr = ethers.utils.formatUnits(amountOut.toString(),_tokenB.decimals).toString()
        console.log("[Recv LFG]", amountOutStr, amountOut.toString());
        resolve(amountOutStr)
      }
    } catch (error) {
      if (error.message.indexOf('(reading \'switchChain\')') > 0) {
          console.log("privy need login")
          reject('need login');
      } else {
          reject('Get Exchange Rate Failed');
      }
    }
  })

};
function limitDecimals(value, decimals) {
  const parts = value.split('.');
  if (parts.length === 2 && parts[1].length > decimals) {
    parts[1] = parts[1].slice(0, decimals);
    return parts.join('.');
  }
  return value;
}

export const buyCoin = ({ wallets, nativeAmount: amount, coinAmount, sendTransaction, tokenA, tokenB, chainInfo, slippageTolerance, swapPoolAddr }) => {

  return new Promise(async (resolve, reject) => {
    // try {
    //   const {chainId,QUOTER_V2_ADDRESS,SWAP_ROUTER_ADDRESS,isNative} = chainInfo
    //   const _tokenA = new Token(
    //     tokenA?.chainId,
    //     tokenA?.address,
    //     tokenA?.decimals
    //   )
    //   const _tokenB = tokenB = new Token(
    //     tokenB?.chainId,
    //     tokenB?.address,
    //     tokenB?.decimals
    //   )
    //   const currentChainId = chainId;

    //   const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
    //   await embeddedWallet.switchChain(currentChainId);
    //   console.log(`[embeddedWallet:]${embeddedWallet} \n [chainId:]${chainId} \n [nativeAmount:]${amount} \n [coinAmount:]${coinAmount} \n [slippageTolerance:]${slippageTolerance} \n [QUOTER_V2_ADDRESS:]${QUOTER_V2_ADDRESS} \n [SWAP_ROUTER_ADDRESS:]${SWAP_ROUTER_ADDRESS} \n [swapPoolAddr:]${swapPoolAddr} `);
    //   if (embeddedWallet && embeddedWallet.getEthersProvider) {
    //     const provider = await embeddedWallet.getEthersProvider();

    //     // const UNISWAP_ADDRESSES = CHAIN_TO_ADDRESSES_MAP[currentChainId];

    //     // const currentPoolAddress = computePoolAddress({
    //     //   factoryAddress: UNISWAP_ADDRESSES.v3CoreFactoryAddress,
    //     //   tokenA: _tokenA,
    //     //   tokenB: _tokenB,
    //     //   fee: FeeAmount.HIGH,
    //     // });

    //     const currentPoolAddress = swapPoolAddr

    //     const poolContract = new ethers.Contract(currentPoolAddress, IUniswapV3PoolABI.abi, provider);

    //     const [fee, liquidity, slot0] =
    //       await Promise.all([
    //         poolContract.fee(),
    //         poolContract.liquidity(),
    //         poolContract.slot0(),
    //       ])

    //     console.log('[amount]', amount, '[for lfg]', coinAmount, '[fee]', fee, '[liquidity]', ethers.utils.formatUnits(liquidity, "ether"));
    //     console.log('[sqrtPriceX96]', ethers.utils.formatUnits(slot0[0], "ether"), '[tick]', slot0[1]);

    //     const pool = new Pool(_tokenA, _tokenB, fee, slot0[0].toString(), liquidity.toString(), slot0[1]);
    //     const swapRoute = new Route([pool], _tokenA, _tokenB);
    //     console.log('[pool]', JSON.stringify(pool),'[swapRoute]', JSON.stringify(swapRoute));
    //     const uncheckedTrade = await Trade.createUncheckedTrade({
    //       route: swapRoute,
    //       inputAmount: CurrencyAmount.fromRawAmount(
    //         _tokenA,
    //         fromReadableAmount(amount, _tokenA.decimals).toString()
    //       ),
    //       outputAmount: CurrencyAmount.fromRawAmount(
    //         _tokenB,
    //         fromReadableAmount(coinAmount, _tokenB.decimals).toString()
    //       ),
    //       tradeType: TradeType.EXACT_INPUT,
    //     });

    //     const options = {
    //       slippageTolerance: new Percent(slippageTolerance, 10000), // 100 bips, or 1.0%
    //       deadline: Math.floor(Date.now() / 1000) + 60 * 5, // 5 minutes from the current Unix time
    //       recipient: embeddedWallet.address,
    //     };

    //     console.log('[options]', JSON.stringify(options));

    //     const methodParameters = SwapRouter.swapCallParameters([uncheckedTrade], options);

    //     console.log('[methodParameters]', JSON.stringify(methodParameters));

    //     !isNative && await lfgApprove(wallets, ethers.utils.parseEther(coinAmount.toString()), _tokenA.address, SWAP_ROUTER_ADDRESS, chainId)
    //     console.log(fromReadableAmount(amount, _tokenA.decimals).toString());
    //     const tx = {
    //       data: methodParameters.calldata,
    //       to: SWAP_ROUTER_ADDRESS,
    //       value: ethers.utils.parseEther(limitDecimals(amount.toString(),18).toString())._hex,
    //       from: embeddedWallet.address,
    //       gasLimit: 500000
    //     }; 

    //     // const router = new AlphaRouter({chainId:currentChainId, provider});
    //     // const options02 =  {
    //     //   type: SwapType.SWAP_ROUTER_02,
    //     //   slippageTolerance: new Percent(slippageTolerance, 10000), // 50 bips, or 0.50%
    //     //   deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from the current Unix time
    //     //   recipient: embeddedWallet.address,
    //     // }

    //     // const route02 = await router.route(
    //     //   CurrencyAmount.fromRawAmount(
    //     //     _tokenA, 
    //     //     fromReadableAmount(
    //     //       amount,
    //     //       _tokenA.decimals
    //     //     ).toString()
    //     //   ),
    //     //   _tokenB,
    //     //   TradeType.EXACT_INPUT,
    //     //   options02
    //     // )

    //     // if (route02 && route02.methodParameters){
    //     //   console.log('[route02]', route02);
    //     // }

    //     // const amountOutStr = route02.quote.toExact();
    //     // console.log("[You Got LFG]", amountOutStr);

    //     // const tx = {
    //     //   data: route02.methodParameters?.calldata,
    //     //   to: SWAP_ROUTER_ADDRESS,
    //     //   value: ethers.utils.parseEther(limitDecimals(amount.toString(),18).toString())._hex,
    //     //   // value: route02.methodParameters.value,
    //     //   from: embeddedWallet.address,
    //     //   gasLimit: 500000
    //     //   //maxFeePerGas: MAX_FEE_PER_GAS,
    //     //   //maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS,
    //     // };

    //     // await lfgApprove(wallets, ethers.utils.parseEther(limitDecimals(amount.toString(),18).toString()), _tokenA?.address, SWAP_ROUTER_ADDRESS, chainId)

    //     console.log('[tx]', JSON.stringify(tx));

    //     const res = await sendTransaction(tx);
    //     console.log("[uniswapLFG Transcation Send]", res);
    //     resolve(res)
    //   }
    // } catch (error) {
    //   reject(error)
    // }

    try {

      const { chainId, QUOTER_V2_ADDRESS, SWAP_ROUTER_ADDRESS, isNative } = chainInfo
      const currentChainId = chainId;

      const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
      await embeddedWallet.switchChain(currentChainId);
      console.log(`[embeddedWallet:]${embeddedWallet} \n [chainId:]${chainId} \n [nativeAmount:]${amount} \n [coinAmount:]${coinAmount} \n [slippageTolerance:]${slippageTolerance} \n [QUOTER_V2_ADDRESS:]${QUOTER_V2_ADDRESS} \n [SWAP_ROUTER_ADDRESS:]${SWAP_ROUTER_ADDRESS} \n [swapPoolAddr:]${swapPoolAddr} `);
      if (embeddedWallet && embeddedWallet.getEthersProvider) { }
      const provider = await embeddedWallet.getEthersProvider();

      const swapRouter02 = new ethers.Contract(SWAP_ROUTER_ADDRESS, SwapRouter02ABI.abi, provider);
      swapRouter02.populateTransaction.exactInputSingle({
        tokenIn: tokenA.address,
        tokenOut: tokenB.address,
        fee: FeeAmount.HIGH,
        recipient: embeddedWallet.address,
        deadline: Math.floor(Date.now() / 1000) + 60 * 5,
        amountIn: ethers.utils.parseEther(limitDecimals(amount.toString(),18))._hex,
        amountOutMinimum: ethers.utils.parseEther(amount).mul(100-slippageTolerance).div(100)._hex || 1,
        sqrtPriceLimitX96: 0,
      }).then(
        async unsignedTx => {
          if (isNative) {
            unsignedTx.value = ethers.utils.parseEther(limitDecimals(amount.toString(),18))._hex;
            console.log('unsignedTx', unsignedTx);
          } else {
            await lfgApprove(wallets, ethers.utils.parseEther(limitDecimals(amount.toString(),18)), tokenA?.address, SWAP_ROUTER_ADDRESS, chainId)

          }

          sendTransaction(unsignedTx).then(res => {
            console.log("res", res);
            resolve(res)
          }).catch(e => {
            console.error(e);
            reject('Swap failed')
          })
        }
      ).catch(
        e => {
          console.error(e)
          reject('Swap failed')
        }
      );
    } catch (error) {
      console.error(error)
      reject('Swap failed')
    }
  })



};

const lfgApprove = (wallets, amount, _tokenAddress, address, chainId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
      console.log(`lfgApprove data------>`,{amount, _tokenAddress, address, chainId});
      // const {lfgTokenAddr} = await getConfig();
      const tokenAddr = _tokenAddress || '0x8bff1bD27E2789Fe390AcabC379C380a83B68E84';

      await embeddedWallet.switchChain(chainId);

      console.log(embeddedWallet);

      const provider = await embeddedWallet.getEthersProvider();

      const signer = provider.getSigner(); // ethers signer object

      const contract = new ethers.Contract(tokenAddr, abi_token, provider).connect(signer);
      contract.populateTransaction.approve(address, amount, await getGasConfig(signer)).then(unsignedTx => {
        signer.sendTransaction(unsignedTx).then(
          resp => {
            console.log(`[lfgApprove] for[${address}] value[${ethers.utils.formatEther(amount)}]`);
            resolve(resp);
          }
        ).catch(
          e => reject(e)
        );
      }).catch(e => {
        reject(e);
      });
    } catch (e) {
      if (e.message.indexOf('(reading \'switchChain\')') > 0) {
        console.log("privy need login")
        reject('need login');
      } else {
        reject(e);
      }
    }
  })
}
export const sellCoin = async ({ wallets, nativeAmount: amount, coinAmount, sendTransaction, tokenA, tokenB, chainInfo, slippageTolerance, swapPoolAddr }) => {

  return new Promise(async (resolve, reject) => {
    // try {
    //   const {chainId,QUOTER_V2_ADDRESS,SWAP_ROUTER_ADDRESS} =  chainInfo
    //   const _tokenA = new Token(
    //     tokenA?.chainId,
    //     tokenA?.address,
    //     tokenA?.decimals
    //   )
    //   const _tokenB = tokenB = new Token(
    //     tokenB?.chainId,
    //     tokenB?.address,
    //     tokenB?.decimals
    //   )
    //   const currentChainId = chainId;

    //   const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
    //   await embeddedWallet.switchChain(currentChainId);
    //   console.log(`[embeddedWallet:]${embeddedWallet} \n [chainId:]${chainId} \n [nativeAmount:]${amount} \n [coinAmount:]${coinAmount} \n [slippageTolerance:]${slippageTolerance} \n [QUOTER_V2_ADDRESS:]${QUOTER_V2_ADDRESS} \n [SWAP_ROUTER_ADDRESS:]${SWAP_ROUTER_ADDRESS} \n [swapPoolAddr:]${swapPoolAddr} `);
    //   if (embeddedWallet && embeddedWallet.getEthersProvider) {
    //     const provider = await embeddedWallet.getEthersProvider();

    //     // const UNISWAP_ADDRESSES = CHAIN_TO_ADDRESSES_MAP[currentChainId];

    //     // const currentPoolAddress = computePoolAddress({
    //     //   factoryAddress: UNISWAP_ADDRESSES.v3CoreFactoryAddress,
    //     //   tokenA: _tokenA,
    //     //   tokenB: _tokenB,
    //     //   fee: FeeAmount.HIGH,
    //     // });
    //     // console.log(`currentPoolAddress`, currentPoolAddress);

    //     //  swapRouter01 start
    //     const currentPoolAddress = swapPoolAddr;
    //     console.log(`[swapPoolAddr]`, currentPoolAddress);
    //     const poolContract = new ethers.Contract(currentPoolAddress, IUniswapV3PoolABI.abi, provider);

    //     const [fee, liquidity, slot0] =
    //       await Promise.all([
    //         poolContract.fee(),
    //         poolContract.liquidity(),
    //         poolContract.slot0(),
    //       ])

    //     console.log('[amount]', amount, '[for lfg]', coinAmount, '[fee]', fee, '[liquidity]', ethers.utils.formatUnits(liquidity, "ether"));
    //     console.log('[sqrtPriceX96]', ethers.utils.formatUnits(slot0[0], "ether"), '[tick]', slot0[1]);

    //     const pool = new Pool(_tokenA, _tokenB, fee, slot0[0].toString(), liquidity.toString(), slot0[1]);
    //     const swapRoute = new Route([pool], _tokenA, _tokenB);
    //     console.log('[pool]', JSON.stringify(pool),'[swapRoute]', JSON.stringify(swapRoute));
    //     const uncheckedTrade = await Trade.createUncheckedTrade({
    //       route: swapRoute,
    //       inputAmount: CurrencyAmount.fromRawAmount(
    //         _tokenA,
    //         fromReadableAmount(coinAmount, _tokenA.decimals).toString()
    //       ),
    //       outputAmount: CurrencyAmount.fromRawAmount(
    //         _tokenB,
    //         fromReadableAmount(amount, _tokenB.decimals).toString()
    //       ),
    //       tradeType: TradeType.EXACT_INPUT,
    //     });

    //     const options = {
    //       slippageTolerance: new Percent(slippageTolerance, 10000), // 100 bips, or 1.0%
    //       deadline: Math.floor(Date.now() / 1000) + 60 * 5, // 5 minutes from the current Unix time
    //       recipient: embeddedWallet.address,
    //     };
    //     console.log('[options]', JSON.stringify(options));
    //     const methodParameters = SwapRouter.swapCallParameters([uncheckedTrade], options);
    //     const tx = {
    //       data: methodParameters.calldata,
    //       to: SWAP_ROUTER_ADDRESS,
    //       from: embeddedWallet.address,
    //       //maxFeePerGas: MAX_FEE_PER_GAS,
    //       //maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS,
    //     };
    //     //  swapRouter01 end


    //     //  swapRouter02 start
    //     // const router = new AlphaRouter({chainId:currentChainId, provider});
    //     // const options02 =  {
    //     //   type: SwapType.SWAP_ROUTER_02,
    //     //   slippageTolerance: new Percent(slippageTolerance, 10000), // 50 bips, or 0.50%
    //     //   deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from the current Unix time
    //     //   recipient: embeddedWallet.address,
    //     // }

    //     // const route02 = await router.route(
    //     //   CurrencyAmount.fromRawAmount(
    //     //     _tokenA, 
    //     //     fromReadableAmount(
    //     //       coinAmount,
    //     //       _tokenA.decimals
    //     //     ).toString()
    //     //   ),
    //     //   _tokenB,
    //     //   TradeType.EXACT_INPUT,
    //     //   options02
    //     // )

    //     // if (route02 && route02.methodParameters){
    //     //   console.log('[route02]', route02);
    //     // }

    //     // const amountOutStr = route02.quote.toExact();
    //     // console.log("[You Got LFG]", amountOutStr);

    //     // const tx = {
    //     //   data: route02.methodParameters?.calldata,
    //     //   to: SWAP_ROUTER_ADDRESS,
    //     //   // value:  ethers.utils.parseEther(limitDecimals(coinAmount.toString(),18).toString())._hex,
    //     //   from: embeddedWallet.address,
    //     //   //maxFeePerGas: MAX_FEE_PER_GAS,
    //     //   //maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS,
    //     // };

    //     //  swapRouter02 end
    //     await lfgApprove(wallets, ethers.utils.parseEther(coinAmount.toString()), _tokenA?.address ,SWAP_ROUTER_ADDRESS, chainId)

    //     console.log('[tx]', JSON.stringify(tx));
    //     const res = await sendTransaction(tx);
    //     console.log("[uniswapLFG Transcation Send]", res);
    //     resolve(res)
    //   }
    // } catch (error) {
    //   reject(error)
    // }

    try {

      const { chainId, QUOTER_V2_ADDRESS, SWAP_ROUTER_ADDRESS, isNative } = chainInfo
      const currentChainId = chainId;

      const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
      await embeddedWallet.switchChain(currentChainId);
      console.log(`[embeddedWallet:]${embeddedWallet} \n [chainId:]${chainId} \n [nativeAmount:]${amount} \n [coinAmount:]${coinAmount} \n [slippageTolerance:]${slippageTolerance} \n [QUOTER_V2_ADDRESS:]${QUOTER_V2_ADDRESS} \n [SWAP_ROUTER_ADDRESS:]${SWAP_ROUTER_ADDRESS} \n [swapPoolAddr:]${swapPoolAddr} `);
      if (embeddedWallet && embeddedWallet.getEthersProvider) {
        const provider = await embeddedWallet.getEthersProvider();

        const swapRouter02 = new ethers.Contract(SWAP_ROUTER_ADDRESS, SwapRouter02ABI.abi, provider);
        swapRouter02.populateTransaction.exactInputSingle({
          tokenIn: tokenA.address,
          tokenOut: tokenB.address,
          fee: FeeAmount.HIGH,
          recipient: embeddedWallet.address,
          deadline: Math.floor(Date.now() / 1000) + 60 * 5,
          amountIn: ethers.utils.parseEther(limitDecimals(coinAmount.toString(),18))._hex,
          // amountOutMinimum: 1,
          amountOutMinimum: ethers.utils.parseEther(amount).mul(100-slippageTolerance).div(100)._hex || 1,
          sqrtPriceLimitX96: 0,
        }).then(
          async unsignedTx => {
            // unsignedTx.value = ethers.utils.parseEther(amount.toString())._hex;
            // console.log('unsignedTx', unsignedTx);
            await lfgApprove(wallets, ethers.utils.parseEther(limitDecimals(coinAmount.toString(),18)), tokenA?.address, SWAP_ROUTER_ADDRESS, chainId)
            sendTransaction(unsignedTx).then(res => {
              console.log("res", res);
              resolve(res)
            }).catch(e => {
              console.error(e)
              reject('Swap failed')
            })
          }
        ).catch(
          e => {
            console.error(e)
            reject('Swap failed')
          }
        );
      }
    } catch (error) {
      console.error(error)
      reject('Swap failed')
    }
  })


};

const ERC20_ABI = [
  "function balanceOf(address owner) view returns (uint256)"
];

export const getBalance = ({ wallets, addr, chainId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
      await embeddedWallet.switchChain(chainId);
      const provider = await embeddedWallet.getEthersProvider();
      const tokenContract = new ethers.Contract(addr, ERC20_ABI, provider);
      const balance = await tokenContract.balanceOf(embeddedWallet.address)
      console.log(ethers.utils.formatUnits(balance));
      resolve(ethers.utils.formatUnits(balance))
    } catch (error) {
      reject('get balance failed')
    }
  })

}
