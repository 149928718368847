import React, {memo, useState, useMemo} from "react";
import s from "./index.module.less";
import {copyFn, depositUrl, unit, chainName, sliceStr} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {Button} from "antd";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";

const ModelBuyFristKeyTips = (
    {
        closeFn
    }
) => {
    const {t, i18n} = useTranslation();
    return (
        <div className={s.wrap}>
            <div className={s.item}>
                <div className={s.contentWrap}>
                    <div className={s.addr}>
                        <div className="fs14 tlc"
                             style={{wordBreak: 'break-all'}}>{t('Buying 5 or more keys will potentially increase your PvE yield and PvP odds.')}</div>
                    </div>
                </div>
            </div>
            <AntButton className={`${s.btn} btn_public widthAuto`} onClick={closeFn}>
                {t('I UNDERSTAND')}
            </AntButton>
        </div>
    )
}
export default memo(ModelBuyFristKeyTips);
