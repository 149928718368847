import { mainHomePath } from "@/routes/config";

const backFn = (location,navigate,backPath="")=>{
    if(backPath){
        navigate(backPath)
        return
    }
    if (location.key !== "default") {
        navigate(-1);
    } else {
        navigate(mainHomePath, {replace: true});
    }
}

export {
    backFn
}