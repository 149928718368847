import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import s from "./index.module.less";
import { ethers, BigNumber } from "ethers";
import { connect } from "react-redux";
import {useNavigate} from "react-router-dom";
import AntButton from "@/common/AntButton";
import CustomIcon from "../../common/CustomIcon";
import KChart from "@/views/games/MomentClub/KChart";
import { AutoStrangeRule } from "@/utils/strangeRule";
import {
    keyBuy,
    stringToNumber,
    sliceStr,
    keySell,
    updateSelfUser,
    updateOtherUser,
    getPrice,
    TransactionError,
    PrivyLoginStatusError,
    logoutSelf,
    copyFn,
    displayFilter,
    getAvatarFromUserInfo,
    sliceAddr,
    getAvatarFromProfile,
    getTokenBalanceOf,
    getQuotes,
    numberClamp,
    convertWalletBalance,
    getWeb3Config,
} from "@/utils/common";
import { useState } from "react";
import { url } from "@/utils/configUri";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { Button, Input, Modal, Radio, Checkbox } from 'antd';
import http from "@/utils/axios";
import {
    LfgMainnetId,
    LfgTestnetId,
} from "@/utils/env";
import ModelDeposit from "../ModelDeposit";
import ModalInsufficientCrpto from "src/model/ModalInsufficientCrpto";
import { useTranslation, Trans } from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import MomentChainToken from "@/utils/json/momentChainToken.json";
import {
    momentBuyCard,
    buyMomentClubPack,
    getMomentTradeInfo,
    getMomentClubPackGasPrice,
    setAnony,
    sellMomentClubPack
} from "@/utils/momentClub";
import {
    gameMemeClubAssetsPath
} from "@/routes/config";
import MemeClubPack from "@/common/MemeClubPack";
import IykykAnimate from "@/common/IykykAnimate";
import { getMomentEntropyFee } from "@/utils/momentClubWeb3";
import GasSetting from "../GasSetting";

const DrawerMomentBuy = (
    {
        userInfo,
        walletBalanceList,
        setWalletBalanceList,
        closeHandle = () => {},
        type,
        done,
        setDone,
        subLoading,
        setSubLoading,
        tradeMemeClubInfo,
        defaultNum = 1,
        setMemeAssetParam,
        clubId,
        completeFn = () => {},
        sourceData,
    }
) => {
    const { t, i18n } = useTranslation();
    const transType = {
        1: {
            fn: buyMomentClubPack,
            str: t('Buy')
        },
        2: {
            fn: sellMomentClubPack,
            str: t('Sell')
        }
    }
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const { wallets } = useWallets();
    const [subFailded, setSubFailded] = useState(false);
    const [noSuccessText, setNoSuccessText] = useState(transType[type]?.str + ' ' + t('Failed'));
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [keyNum, setKeyNum] = useState(defaultNum);
    const [getPriceLoding, setGetPriceLoding] = useState(false);
    const [memeTradeInfo, setMemeTradeInfo] = useState(null);
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [depositChainTokenInfo, setDepositChainTokenInfo] = useState(null);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [coinUsd, setCoinUsd] = useState(1);
    const [fastGasPrice, setFastGasPrice] = useState(0);
    const [insufficientAmount, setInsufficientAmount] = useState(0);
    const [keyNumInput, setKeyNumInput] = useState(false);
    const [showIykykPanel, setShowIykykPanel] = useState(true);
    const [showPackInAsset, setShowPackInAsset] = useState(false);
    const [curCoinMemeFactoryContract, setCurCoinMemeFactoryContract] = useState('');
    const [showChartDetail, setShowChartDetail] = useState(true);
    const [showGasMode, setShowGasMode] = useState(false);
    const {
        userId,
        clubName,
        coinId,
        curCardKindNum,
        cardKindMaxNum,
        holdNum,
        imageUrl,
        symbolName,
        ftTotalSupply,
        airdropPercent,
        openPackRatio,
        coinToNFTRatio,
        isFT,
        buyPrice,
    } = useMemo(() => {
        return tradeMemeClubInfo || {}
    }, [tradeMemeClubInfo]);

    const nativeCoinInfo = useMemo(() => {
        let id = Math.floor(coinId / 100) * 100;
        return MomentChainToken.find(i => i?.ID === id);
    }, [coinId]);
    const currentCoinInfo = useMemo(() => {
        return MomentChainToken.find(i => i?.ID === coinId);
    }, [coinId]);


    useEffect(()=>{
        console.log(`clubId,userId`,clubId,userId);
    },[clubId,userId])

    const {
        clubUserId,
        chainClubId,
        cardNum,
        isBuy,
        price,
        priceWithFee,
    } = useMemo(() => {
        return memeTradeInfo || {}
    }, [memeTradeInfo]);
    const { walletAddr, selfData, topUpCode } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);



    const {
        balance,
        momentAnonymous,
        points
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const { logout } = usePrivy();
    const closeFn = () => {
        if (noSuccessText === PrivyLoginStatusError) {
            logout();
            logoutSelf();
        }
        setSubLoading(false)
        setDone(false)
        closeHandle()
    }
    const dataInit = useMemo(() => {
        if (memeTradeInfo && walletAddr) {
            return true
        }
        return false
    }, [memeTradeInfo, walletAddr]);

    const transFn = () => {
        if (isNativeCoin()) {
            if (Number(priceWithFee) + Number(fastGasPrice) > Number(getSpecifiedCoinBalance(currentCoinInfo?.ID))) {
                //setShowInsufficientCrpto(true);
                setShowDepositPanel(true);
                setDepositChainTokenInfo(currentCoinInfo);
                setInsufficientAmount(Number(priceWithFee) + Number(fastGasPrice) - Number(getSpecifiedCoinBalance(currentCoinInfo?.ID)));
                return;
            }
        }
        else {
            if (Number(fastGasPrice) > Number(getSpecifiedCoinBalance(nativeCoinInfo?.ID))) {
                //setShowInsufficientCrpto(true);
                setShowDepositPanel(true);
                setDepositChainTokenInfo(currentCoinInfo);
                setInsufficientAmount(Number(fastGasPrice) - Number(getSpecifiedCoinBalance(nativeCoinInfo?.ID)));
                return;
            }
        }
        if (!dataInit || subLoading) return
        setSubLoading(true);
        // momentBuyCard({
        //     clubUserId: userId,
        //     buyNum: keyNum,
        //     costPrice: priceWithFee.toString(),
        // }).then(res => {
            buyMomentClubPack({
                wallets: wallets,
                // callId: res?.orderId,
                clubId: Number(chainClubId),
                amount: keyNum,
                expectedPrice: priceWithFee.toString(),
                // entropyFee: momentEntropyFee.toString(),
                // timestamp: res?.timestamp,
                // validFor: res?.validFor,
                // signature: res?.signature,
                coinInfo: currentCoinInfo
            }).then(res => {
                console.log(res);
                setSubLoading(false);
                if (type === 1){
                    //setShowIykykPanel(true);
                    completeFn(res.hash);
                    // setMemePackBuySubLoading(false)
                    //setShowPackInAsset(true);
                }
                // setDone(true);
                setSubFailded(false);
                setTimeout(
                    () => {
                        updateSelfUser();
                        getWalletBalance();
                    }, 2000
                )
            }).catch(e => {
                console.log(e);
                if (e?.reason != null) {
                    for (const transactionErrorElement in TransactionError) {
                        if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                            setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                            break;
                        }
                    }
                }
    
                if (e === 'need login') {
                    setNoSuccessText(PrivyLoginStatusError)
                }
    
                setSubLoading(false);
                setDone(true);
                setSubFailded(true);
                setTimeout(
                    () => {
                        updateSelfUser();
                        getWalletBalance();
                    }, 2000
                )
            })
        // }).catch(e => {
        //     console.log(e);
        //     if (e?.reason != null) {
        //         for (const transactionErrorElement in TransactionError) {
        //             if (e?.reason.indexOf(transactionErrorElement) !== -1) {
        //                 setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
        //                 break;
        //             }
        //         }
        //     }

        //     if (e === 'need login') {
        //         setNoSuccessText(PrivyLoginStatusError)
        //     }

        //     setSubLoading(false);
        //     setDone(true);
        //     setSubFailded(true);
        //     setTimeout(
        //         () => {
        //             updateSelfUser();
        //             getWalletBalance();
        //         }, 2000
        //     )
        // })


    }

    const showMoreInfoFn = () => {
        setShowMoreInfo(!showMoreInfo)
    }

    const controlAmount = (control) => {
        if (control === 0) {
            if (keyNum > 1) {
                let _keyNum = keyNum - 1;
                setKeyNum(_keyNum);
            }
        }
        else if (control === 1) {
            if (type === 1) {
                let _keyNum = keyNum + 1;
                setKeyNum(_keyNum);
            }
            else {
                if (keyNum < holdNum) {
                    let _keyNum = keyNum + 1;
                    setKeyNum(_keyNum);
                }
            }
        }
    }

    useEffect(() => {
        getKeyPrice();
    }, [keyNum, type, userId])

    const getContractInfo = async () =>{
        const web3Config = await getWeb3Config();
        const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === coinId));
        setCurCoinMemeFactoryContract(contractInfo?.memeFactoryContract);
    }

    // const getMomentEntropyFeeFn = (wallets,coinId)=>{
    //     getMomentEntropyFee(wallets,coinId).then(res=>{
    //         console.log(`getMomentEntropyFee---->`,ethers.utils.formatUnits(res));
    //         setMomentEntropyFee(ethers.utils.formatUnits(res))
    //     }).catch(e=>{
    //         console.error(e);
    //     })
    // } 

    useEffect(() =>{
        getContractInfo();
        // coinId && getMomentEntropyFeeFn(wallets,coinId)
    }, [coinId]);

    useEffect(() => {
        getWalletBalance();
        getQuotesInfo();
        
    }, [])

    const getQuotesInfo = () => {
        getQuotes([currentCoinInfo?.coinName]).then(res => {
            setCoinUsd(res?.quotes?.[currentCoinInfo?.coinName] || 1);
        }).catch(e => {
            console.log(e);
        })
    }

    const getSpecifiedCoinBalance = (id) => {
        return walletBalanceList?.[id] || 0;
    }

    const getKeyPrice = () => {
        if (type === 1) {
            if (keyNum < 1) {
                return;
            }
        }
        else {
            if (keyNum < 1 || keyNum > holdNum) {
                return;
            }
        }
        setGetPriceLoding(true);
        getMomentTradeInfo(userId, keyNum, type === 1).then(res => {
            setMemeTradeInfo({ ...res });
            getMomentClubPackGasPrice(wallets, currentCoinInfo).then(res => {
                console.log("getMomentClubPackGasPrice", res);
                setFastGasPrice(res || 0);
                setGetPriceLoding(false);
            }).catch(e => {
                setGetPriceLoding(false);
                console.log(e);
            })
        }).catch(e => {
            setGetPriceLoding(false)
            console.log(e);
        })
    }

    const amountChangeHandler = (e) => {
        let value = e.target.value.replace(/[^\d]/g, '');
        setKeyNum(Number(value));
    }

    const amountBlurHandler = (e) => {
        setKeyNumInput(false);
        let tempKeyNum = keyNum;
        if (type === 1) {
            tempKeyNum = Math.max(tempKeyNum, 1);
        }
        else {
            tempKeyNum = numberClamp(tempKeyNum, 1, holdNum);
        }
        setKeyNum(tempKeyNum);
    }
    const toggleKeyNumInput = () => {
        setKeyNumInput(true)
        setTimeout(() => {
            inputRef.current.focus({
                cursor: 'all',
            })
        });
    }

    const isNativeCoin = () => {
        if (MomentChainToken?.length > 0) {
            for (let i = 0; i < MomentChainToken?.length; i++) {
                if (MomentChainToken[i].ID === coinId) {
                    return MomentChainToken[i].isNative === 1;
                }
            }
        }
        return true;
    }

    const getWalletBalance = () => {
        let tokenList = [coinId];
        if (!isNativeCoin() && nativeCoinInfo?.ID > 0) {
            tokenList.push(nativeCoinInfo.ID);
        }
        getTokenBalanceOf(walletAddr, tokenList).then(res => {
            let tempBalance = {...walletBalanceList} || {};
            for (let i = 0; i < tokenList.length; i++) {
                tempBalance[tokenList[i].toString()] = res?.balances?.[tokenList[i]];
            }
            setWalletBalanceList(tempBalance);
        }).catch(err => {
            console.error(err.message);
        })
    }

    const [isAnony, seIsAnony] = useState(false)

    useEffect(() => {
        seIsAnony(momentAnonymous)
    }, [momentAnonymous])


    const setMomentAnony = (isMomentAnony) => {
        setAnony(isMomentAnony).then(res => {
            console.log(res);
        }).catch(e => {
            console.error(e);
        })
    }

    return (
        <>
            <div className={`${s.dialog} flex_center_between_col`}>
                <div className={`${s.content} flex_center_start_col`}>
                    <div className={`${s.scrollPart} flex_center_start_col`}>
                        <div className={`${s.chartWrap} ${showChartDetail ? '' : s.chartHide}`}>
                            <div className={`${s.chartTitle} momentFont`} onClick={() => { setShowChartDetail(!showChartDetail) }}>
                                {
                                    showChartDetail ? 
                                        t('Hide Chart') 
                                    : 
                                    <div className={`momentFont fs12 flex_center_center`}>
                                        {`$${clubName} Moment`}
                                        <CustomIcon imgName={`Picture/${currentCoinInfo?.icon}`} className="ml5 mr5" width={17} height={17}/>
                                        {convertWalletBalance(buyPrice)}
                                        {/*<CustomIcon imgName={range < 0 ? 'Picture/UI_Picture_PriceDown_01' : 'Picture/UI_Picture_PriceUP_01'} className="mr5" width={18} height={13}></CustomIcon>
                                        <span className={range < 0 ? 'color-red fs12' : 'color-green fs12'}>{range}%</span>*/}
                                    </div>
                                }

                                <CustomIcon className={s.chartIcon} onClick={() => { setShowChartDetail(!showChartDetail) }} imgName={showChartDetail ? 'Button/UI_Button_Arrow_Up_02' : 'Button/UI_Button_Arrow_Down_02'} width={19} height={10}></CustomIcon>
                            </div>
                            {
                                showChartDetail && 
                                <div className={s.kChartWrap}>
                                    <KChart sourceData={sourceData} coinName={currentCoinInfo?.displayCoinName} clubId={clubId} symbolName={clubName}></KChart>
                                </div>
                            }
                        </div>
                        <div className="flex_center_between  w100p color-white fs12 mt20">
                            <div className={`${s.ownPart} flex_center_start`}>
                                <div className={`fs14 fb momentFont color-moment-light-gray`}>
                                    {t('You Own')}
                                </div>
                                <CustomIcon className="ml5 mr5" width={22} height={22}
                                    imgName={'Picture/UI_Picture_icon_cardpack'} />
                                <div className={`fs16 fb momentFont color-white`}>
                                    {`x ${holdNum || 0}`}
                                </div>
                            </div>
                            <div className={`${s.overview} flex_center_center_col`}>
                                {
                                    !isNativeCoin() && <div className={s.overviewItem}>
                                        <CustomIcon width={18} height={18} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                                        <span className={`${s.balance} color-EEEEEE momentFont fb fs14`}>
                                            {convertWalletBalance(getSpecifiedCoinBalance(nativeCoinInfo?.ID))}
                                        </span>
                                        <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Plus-Yellow-Sq_01'}
                                            onClick={() => {
                                                setShowDepositPanel(true);
                                                setDepositChainTokenInfo(nativeCoinInfo);
                                            }} />
                                    </div>
                                }
                                <div className={s.overviewItem}>
                                    <CustomIcon width={17} height={17} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                                    <span className={`${s.balance} color-EEEEEE momentFont fb fs14`}>
                                        {convertWalletBalance(getSpecifiedCoinBalance(currentCoinInfo?.ID))}
                                    </span>
                                    <CustomIcon width={24} height={24} imgName={'Button/UI_Button_Explore_Create_01'}
                                        onClick={() => {
                                            if (isNativeCoin()) {
                                                setShowDepositPanel(true);
                                                setDepositChainTokenInfo(currentCoinInfo);
                                            }
                                            else if (currentCoinInfo?.ID === LfgMainnetId || currentCoinInfo?.ID === LfgTestnetId) {
                                                setShowSwapLfg(true);
                                            }
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className={`${s.borderWrap} `}>
                            {
                                !showChartDetail && 
                                <div className={s.topBorder}>
                                    <div className={s.item}>
                                        <div className={`${s.grayText}`}>{t('From')}</div>
                                        <div className={s.bg}>
                                            <div className={`textnowrap ${s.grayText}`}>
                                                {sliceAddr(walletAddr, 4)}
                                            </div>
                                            <CustomIcon className="ml5"
                                                onClick={() => copyFn(walletAddr)}
                                                width={12} height={12}
                                                imgName={'Picture/UI_Picture_Icon_Copy_01'} />
                                        </div>
                                    </div>
                                    <div className={s.item}>
                                        <div className={`${s.grayText}`}>{t('To')}</div>
                                        <div className={s.bg}>
                                            <div className={`textnowrap ${s.grayText}`}>
                                                {sliceAddr(curCoinMemeFactoryContract, 4)}
                                            </div>
                                            <CustomIcon className="ml5"
                                                onClick={() => copyFn(curCoinMemeFactoryContract)}
                                                width={12} height={12}
                                                imgName={'Picture/UI_Picture_Icon_Copy_01'} />
                                        </div>
                                    </div>
                                    <div className={s.item}>
                                        <div className={`${s.grayText}`}>{t('Action')}</div>
                                        <div className={`${s.grayText}`}>{transType[type].str} {t('Moment')}</div>
                                    </div>
                                    <div className={s.item}>
                                        <div className={`${s.grayText}`}>{t('Pack1')}</div>
                                        <div className={`${s.grayText} flex_center_between`}>
                                            <CustomIcon className={`mr5`} width={17} height={17} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                                            {convertWalletBalance((Number(price)))}
                                        </div>
                                    </div>
                                    <div className={`${s.item} `}>
                                        <div className={`${s.grayText}`}>{t('Fees')}</div>
                                        <div className={`${s.grayText} flex_center_between`}>
                                            <CustomIcon className={`mr5`} width={17} height={17} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                                            {convertWalletBalance(Math.abs(Number(priceWithFee) - Number(price)) + Number(fastGasPrice))}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={`${s.item2} df jc-spaceBetween mb2`}>
                                <div className={`${s.grayText}`}>{t('Total(inc. fees)')}</div>
                                <div>
                                    <div className="tlr flex_center_end">
                                        <CustomIcon width={17} height={17} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                                        <span className="momentFont color-EEEEEE fs14 fb ml5">
                                            {convertWalletBalance(type === 1 ? (Number(priceWithFee) + Number(fastGasPrice)) : (Number(priceWithFee) - Number(fastGasPrice)))}
                                        </span>
                                    </div>
                                    <div className="tlr mt5">
                                        <span className={`${s.grayText}`}>
                                            USD
                                        </span>
                                        <span className="ml5 momentFont color-EEEEEE fs14 fb">
                                            ${convertWalletBalance((type === 1 ? (Number(priceWithFee) + Number(fastGasPrice)) : (Number(priceWithFee) - Number(fastGasPrice))) * coinUsd)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/*<CustomIcon width={30} height={30}
                                className="modelBottomBtn"
                                imgName={!showMoreInfo ? 'Button/UI_Button_Arrow_Down_01' : 'Button/UI_Button_Arrow_Up_01'}
                                onClick={showMoreInfoFn} />*/}
                        </div>
                    </div>
                </div>
                <div className={`${s.bottomPart} flex_center_end_col mt30`}>
                    <div className={s.amountWrap}>
                        <CustomIcon width={52} height={52} imgName={'Button/UI_Button_Plus_02'}
                            className={`${s.minusBtn}`}
                            onClick={() => controlAmount(0)} />
                        <div className={`${s.inputRoot} flex_center_end_col`} style={{
                            //background: 'rgba(0, 0, 0, 0.3)',
                            width: '70px',
                            height: '24px'
                        }}>
                            <Input ref={inputRef} className={`momentFont`} onChange={amountChangeHandler} onBlur={amountBlurHandler}
                                value={keyNum}
                                style={{ display: keyNumInput ? 'block' : 'none' }} />
                                <div className="flex_end_center momentFont" style={{ display: keyNumInput ? 'none' : 'flex', fontSize: '20px' }}
                                    onClick={toggleKeyNumInput}>
                                    <div className="fs18 momentFont" style={{paddingBottom: `1px`}}>
                                        x
                                    </div>
                                    <div className="fs24 momentFont">
                                        {`${keyNum}`}
                                    </div>
                                </div>
                        </div>
                        <CustomIcon width={52} height={52} imgName={'Button/UI_Button_Plus_01'}
                            className={`${s.plusBtn}`}
                            onClick={() => controlAmount(1)} />
                    </div>
                    <div className="w100p flex_center_center" onClick={() => {
                        if (isAnony){
                            setMomentAnony(false);
                            seIsAnony(false);
                        }
                        else{
                            setMomentAnony(true);
                            seIsAnony(true);
                        }
                    }}>
                        {
                            isAnony ? 
                                <>
                                    <CustomIcon width={14} height={14} imgName={`Picture/UI_Picture_Icon_Tick_w_01`} />
                                </> :
                                <>
                                    <div className={`${s.anonyBtn}`}>
                                    </div>
                                </>
                        }
                        <div className="lh1 ml5 fs12 momentFont color-moment-gray">
                            {t('Stay Anonymous')}
                        </div>
                    </div>
                    <div className={`${s.btnLine} flex_center_start_col`}>
                        {
                            done ?
                                <AntButton
                                    onClick={() => {
                                        closeFn();
                                    }}
                                    className={`${s.buyBtn} btn_public`}>
                                    <div className={`${s.buyBtnText}`}>
                                        {
                                            subFailded ? 
                                                noSuccessText : 
                                                t('Succeed')
                                        }
                                    </div>
                                </AntButton> :
                            curCardKindNum >= cardKindMaxNum ?
                                <AntButton
                                    onClick={() => {
                                        closeFn();
                                    }}
                                    className={`${s.buyBtn} btn_public`}>
                                    <div className={`${s.buyBtnText}`}>
                                        {t('Supply Reached Limit')}
                                    </div>
                                </AntButton> :
                            subLoading ?
                                <AntButton
                                    className={`${s.buyBtn} btn_public`}>
                                    <div className={`flex_center_center`}>
                                        <CustomIcon rotating={true} className="mr5" width={20} height={20}
                                            imgName={'Picture/UI_Picture_Loading_01'} />
                                        <span className={`${s.buyBtnText}`}>{t('PROCESSING')}</span>
                                    </div>
                                </AntButton> :
                            getPriceLoding ?
                                <AntButton
                                    className={`${s.buyBtn} btn_disabled`}>
                                    <div className={`flex_center_center`}>
                                        <CustomIcon rotating={true} className="mr5" width={20} height={20}
                                            imgName={'Picture/UI_Picture_Loading_02'} />
                                        <span className={`${s.buyBtnText} color-moment-light-gray`}>{t('BUY NOW')}</span>
                                    </div>
                                </AntButton> :
                                <AntButton
                                    onClick={() =>{
                                        transFn();
                                    }}
                                    className={`${s.buyBtn} btn_public`}>
                                    <div className={`${s.buyBtnText}`}>
                                        {t('BUY NOW')}
                                    </div>
                                </AntButton>
                        }
                        <CustomIcon width={30} height={30}
                            className={`${s.gasBtn}`}
                            imgName={'Button/UI_Button_Gas_01'}
                            onClick={() => {
                                setShowGasMode(true);
                            }}
                        />
                    </div>
                    <div className={`w100p flex_center_start_col`}>
                        <div className={`${s.cancelBtn} flex_center_center`} onClick={() =>{
                            closeFn();
                        }}>
                            {AutoStrangeRule(t('Cancel'))}
                        </div>
                    </div>
                </div>

                {
                    showPackInAsset && 
                    <div className={`${s.showPackAsset}`} onClick={() =>{
                        if (clubUserId > 0){
                            if (keyNum + curCardKindNum >= cardKindMaxNum){
                                setMemeAssetParam({
                                    page: 1
                                })
                            }
                            else{
                                setMemeAssetParam({
                                    page: 2,
                                    clubUserId: clubUserId,
                                })
                            }
                            navigate(gameMemeClubAssetsPath);
                        }
                    }}>
                        {t('Show This Pack in My Asset')}
                    </div>
                }

                <AntModal
                    width='330px'
                    title=''
                    className="momentModalWrap"
                    centered={true}
                    open={showDepositPanel}
                    onOk={() => setShowDepositPanel(false)}
                    onCancel={() => setShowDepositPanel(false)}
                    zIndex={7777}
                >
                    <ModelDeposit
                        topUpCode={topUpCode}
                        walletAddr={walletAddr}
                        setShowDepositPanel={setShowDepositPanel}
                        chainTokenInfo={depositChainTokenInfo}
                        type={2} />
                </AntModal>
                <AntModal
                    width='330px'
                    title={t('INSUFFICIENT BALANCE')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showInsufficientCrpto}
                    onOk={() => setShowInsufficientCrpto(false)}
                    onCancel={() => setShowInsufficientCrpto(false)}
                    destroyOnClose={true}
                    zIndex={7777}
                >
                    <ModalInsufficientCrpto
                        insufficientAmount={insufficientAmount}
                        userInfo={userInfo} 
                        coinId={coinId}
                        closeFn={() => {
                            setShowInsufficientCrpto(false);
                        }}
                        type={2} />
                </AntModal>
                <AntModal
                    width='330px'
                    title={t('SWAP $MATIC AND $LFG')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showSwapLfg}
                    onOk={() => setShowSwapLfg(false)}
                    onCancel={() => setShowSwapLfg(false)}
                    destroyOnClose={true}
                    zIndex={7777}
                >
                    <ModalSwapLfg
                        userInfo={userInfo}
                        closeFn={() => {
                            setShowSwapLfg(false);
                        }} />
                </AntModal>
                <AntDrawer
                    title={t('Gas Mode')}
                    placement={'right'}
                    onClose={() => setShowGasMode(false)}
                    width={246}
                    open={showGasMode}
                    className="momentGasFilter"
                    zIndex={7778}
                >
                    <GasSetting
                        closeHandle={() => {
                            setShowGasMode(false);
                        }}
                        type={2}
                    />
                </AntDrawer>
            </div>
        </>
    )
}
const mapStateToProps = ({ app }) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo,
        walletBalanceList: app.walletBalanceList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setMemeAssetParam: (params) => {
            return dispatch({
                type: "app/setMemeAssetParam",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(DrawerMomentBuy));
