import React from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { gameShareDetailPath, gameTrillionaireBattleLeaderBoardPath, mainProfilePath } from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelUpFarm = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className={s.modelWrap}>
            <div className={s.item}>{t('Tag @GoTurnUp and @anyone in your tweets')}</div>
            <div className={s.item}>{t('Only your three most recent tweets each day are counted (UTC 0)')}</div>
            <div className={s.item}>
                <Trans
                    i18nKey="For each tweet, you earn 10 social <frie /> per like and 1 social <frie /> per view"
                    defaults="For each tweet, you earn 10 social <frie /> per like and 1 social <frie /> per view"
                    components={{ frie: <CustomIcon width={18} height={18} imgName={"Picture/UI_Picture-Currency_UP_01"} ></CustomIcon> }}
                />
            </div>
            <div className={s.item}>
                <Trans
                    i18nKey="More social <frie /> leads to more $UP <point />"
                    defaults="More social <frie /> leads to more $UP <point />"
                    components={{ 
                        frie: <CustomIcon width={18} height={18} imgName={"Picture/UI_Picture-Currency_UP_01"} ></CustomIcon>,
                        point: <CustomIcon width={18} height={18} imgName={"Picture/UI_Picture-Currency_UP_01"} ></CustomIcon>
                    }}
                />
            </div>
        </div>
    )
}

export default ModelUpFarm
