import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../common/CustomIcon";
import {numberClamp} from "@/utils/common";
import CommonNFTBanner from "@/assets/images/CommonNFTBanner.png";
import UncommonNFTBanner from "@/assets/images/UncommonNFTBanner.png";
import RareNFTBanner from "@/assets/images/RareNFTBanner.png";
import EpicNFTBanner from "@/assets/images/EpicNFTBanner.png";
import LegendaryNFTBanner from "@/assets/images/LegendaryNFTBanner.png";

const ModelGenesisAuctionTip = (
    {
        closeHandle
    }
) => {
    const {t, i18n} = useTranslation();

    const [pageIndex, setPageIndex] = useState(0);

    return (
        <div className={s.wrap}>
            <div className={s.pageControl}>
                <div className={s.prev} onClick={() => {
                    setPageIndex(numberClamp(pageIndex - 1, 0, 2));
                }}>
                    {pageIndex > 0 && <CustomIcon
                        width={14}
                        height={28}
                        imgName={"Button/UI_Button_Arrow_Left_01"}
                    ></CustomIcon>}
                </div>
                <div className={s.next} onClick={() => {
                    setPageIndex(numberClamp(pageIndex + 1, 0, 1));
                }}>
                    {pageIndex < 1 && <CustomIcon
                        width={14}
                        height={28}
                        imgName={"Button/UI_Button_Arrow_Right_01"}
                    ></CustomIcon>}
                </div>
            </div>
            <div className={s.topPart}>
                <div className={s.title}>
                    {
                        pageIndex === 1 ?
                            t('Last 3 Winners\' Fund') :
                            t('NFT Rarity')
                    }
                </div>
                <div className={s.content}>
                    {
                        pageIndex === 1 ?
                            <>
                                <div className={s.contentText}>
                                    {t('In the auction, 50% of all taxes collected (from a total of 2000) will be accumulated in the reward fund.')}
                                </div>
                                <div className={`${s.pieChartRoot}`}>
                                    <div className={s.pieChart}>
                                        <CustomIcon width={202} height={202}
                                                    imgName={'Picture/UI_Picture-AuctionTipPage_01'}
                                        />
                                        <CustomIcon className={s.pieChart10per} width={63} height={35}
                                                    imgName={'Picture/UI_Picture-AuctionTipPage_02'}/>
                                        <CustomIcon className={s.pieChart30per} width={94.5} height={52.5}
                                                    imgName={'Picture/UI_Picture-AuctionTipPage_03'}/>
                                        <CustomIcon className={s.pieChart60per} width={94.5} height={52.5}
                                                    imgName={'Picture/UI_Picture-AuctionTipPage_04'}/>
                                    </div>
                                    <div className={s.pieChartDes}>
                                        <div className={s.pieChartDesOne}>
                                            <CustomIcon width={33} height={33}
                                                        imgName={'Picture/UI_Picture_PurpleCircle_01'}
                                                        name={'Picture/UI_Picture_PurpleCircle_01'}/>
                                            <div className={`${s.pieChartDesValue} mt10`}>
                                                {t('60% of funds:')}
                                            </div>
                                            <div className={`${s.pieChartDesValueI}`}>
                                                #2000
                                            </div>
                                        </div>
                                        <div className={s.pieChartDesOne}>
                                            <CustomIcon width={33} height={33}
                                                        imgName={'Picture/UI_Picture_BlueCircle_01'}
                                                        name={'Picture/UI_Picture_BlueCircle_01'}/>
                                            <div className={`${s.pieChartDesValue} mt10`}>
                                                {t('30% of funds:')}
                                            </div>
                                            <div className={`${s.pieChartDesValueI}`}>
                                                #1999
                                            </div>
                                        </div>
                                        <div className={s.pieChartDesOne}>
                                            <CustomIcon width={33} height={33}
                                                        imgName={'Picture/UI_Picture_RedCircle_01'}
                                                        name={'Picture/UI_Picture_RedCircle_01'}/>
                                            <div className={`${s.pieChartDesValue} mt10`}>
                                                {t('10% of funds:')}
                                            </div>
                                            <div className={`${s.pieChartDesValueI}`}>
                                                #1998
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${s.contentText} mt25`}>
                                    {t('The taxes will be awarded to the winners of the 3 auctions with the latest end time - so stay in the game as long as your can for maximum rewards!')}
                                </div>
                            </> :
                            <>
                                <div className={s.contentText}>
                                    {t('NFT rarity is determined based on the auction’s end time, with later times indicating higher rarity.')}
                                </div>
                                <div className={`${s.contentText} mt20`}>
                                    {t('The rarities are defined as follows:')}
                                </div>
                                <div className={s.rarityRoot}>
                                    <div className={`${s.imgRoot} mt15`}
                                         style={{backgroundImage: `url(${CommonNFTBanner})`}}>
                                        <div className={s.bannerInfoRoot}>
                                            <div className={s.bannerInfoTitle} style={{color: '#FFFFFF'}}>
                                                {t('Common')}
                                            </div>
                                            <div className={s.bannerInfoText}>
                                                {t('Auction ends between')}
                                            </div>
                                            <div className={s.bannerInfoValue}>
                                                1 {t('to')} 1000
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${s.imgRoot} mt10`}
                                         style={{backgroundImage: `url(${UncommonNFTBanner})`}}>
                                        <div className={s.bannerInfoRoot}>
                                            <div className={s.bannerInfoTitle} style={{color: '#95F770'}}>
                                                {t('Uncommon')}
                                            </div>
                                            <div className={s.bannerInfoText}>
                                                {t('Auction ends between')}
                                            </div>
                                            <div className={s.bannerInfoValue}>
                                                1001 {t('to')} 1500
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${s.imgRoot} mt10`}
                                         style={{backgroundImage: `url(${RareNFTBanner})`}}>
                                        <div className={s.bannerInfoRoot}>
                                            <div className={s.bannerInfoTitle} style={{color: '#00CEFF'}}>
                                                {t('Rare')}
                                            </div>
                                            <div className={s.bannerInfoText}>
                                                {t('Auction ends between')}
                                            </div>
                                            <div className={s.bannerInfoValue}>
                                                1501 {t('to')} 1900
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${s.imgRoot} mt10`}
                                         style={{backgroundImage: `url(${EpicNFTBanner})`}}>
                                        <div className={s.bannerInfoRoot}>
                                            <div className={s.bannerInfoTitle} style={{color: '#FF79E8'}}>
                                                {t('Epic')}
                                            </div>
                                            <div className={s.bannerInfoText}>
                                                {t('Auction ends between')}
                                            </div>
                                            <div className={s.bannerInfoValue}>
                                                1901 {t('to')} 1997
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${s.imgRoot} mt10`}
                                         style={{backgroundImage: `url(${LegendaryNFTBanner})`}}>
                                        <div className={s.bannerInfoRoot}>
                                            <div className={s.bannerInfoTitle} style={{color: '#FFFFA7'}}>
                                                {t('Legendary')}
                                            </div>
                                            <div className={s.bannerInfoText}>
                                                {t('Auction ends between')}
                                            </div>
                                            <div className={s.bannerInfoValue}>
                                                1998 {t('to')} 2000
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
            <div className={s.pageRoot}>
                <div
                    className={`${pageIndex === 0 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(0)
                    }}>
                </div>
                <div
                    className={`${pageIndex === 1 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(1)
                    }}>
                </div>
            </div>
        </div>
    )
}
export default memo(ModelGenesisAuctionTip);
