import React, {memo, useState, useMemo, useEffect, useRef} from "react";
import s from "./index.module.less";
import {useTranslation} from 'react-i18next';
import {
    convertWalletBalance, getProfile, sliceStr,
    getAvatarFromProfile
} from "@/utils/common";
import AvatarInfo from "@/common/AvatarInfo";
import {Spin} from "antd";
import {post_red_packet_detail} from "@/utils/red_Packet";
import CustomIcon from "src/common/CustomIcon";


const RedPacketModelView = (
    {
        setOpenRedPacketStatusPanel,
        keyId,
        curentOtherUserInfo,
        isSelfChat,
        statusCurrentRedPacket,
    }
) => {
    const {t, i18n} = useTranslation();

    const {userId, platformData, youOwnKeyNum} = useMemo(() => {
        return curentOtherUserInfo || {};
    }, [curentOtherUserInfo])

    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])

    const [loadingDetail, setLoadingDetail] = useState(true)
    const [amountLeft, setAmountLeft] = useState(0)
    const [amountTotal, setAmountTotal] = useState(0)
    const [sharingLeft, setSharingLeft] = useState(0)
    const [sharingTotal, setSharingTotal] = useState(0)
    const [sharingList, setSharingList] = useState([])
    const [opened, setOpened] = useState(false)
    const [openedAmount, setOpenedAmount] = useState(0)
    const fetchDetail = () => {
        setLoadingDetail(true)
        post_red_packet_detail(statusCurrentRedPacket?.redPacketId).then((res) => {
            setAmountLeft(res?.data?.amountLeft)
            setAmountTotal(res?.data?.amountTotal)
            setSharingLeft(res?.data?.pickNumLeft)
            setSharingTotal(res?.data?.pickNumTotal)
            setSharingList(res?.data?.pickList)
            setOpened(res?.data?.isOpen)
            setOpenedAmount(Number(res?.data?.openedAmount))

            setLoadingDetail(false)
        }).catch(() => {
            setLoadingDetail(false)
        })
    }

    useEffect(() => {
        fetchDetail();
    }, []);

    const parseTime = (t) => {
        let date = new Date(t * 1000);
        return date.toLocaleString();
    }

    const coinType = statusCurrentRedPacket?.coinType;

    const {
        displayName,
    } = statusCurrentRedPacket?.senderProfile;
    const avatarUrl = getAvatarFromProfile(statusCurrentRedPacket?.senderProfile, 0);
    const originalAvatarUrl = getAvatarFromProfile(statusCurrentRedPacket?.senderProfile, 1);

    return (
        <Spin spinning={loadingDetail}>
            <div className={s.wrap}>
                <div className={s.useinfo}>
                    <AvatarInfo size={40} src={originalAvatarUrl || avatarUrl}/>
                    <div className={s.usename}>{t('Sent by')} {sliceStr(displayName, 20)}</div>
                </div>
                <div className={s.Amountinfo}>
                    {opened && <>
                        {coinType === 1 &&
                            <CustomIcon width={30} height={30} className="mr5 "
                                        imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                        name={'Picture/UI_Picture-Currency_MATIC_01'}/>}
                        {coinType === 0 &&
                            <CustomIcon width={30} height={30} className="mr5 "
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                        name={'Picture/UI_Picture-Currency_LFG_01'}/>}
                        {convertWalletBalance(openedAmount)}
                    </>}
                </div>
                {
                    opened && <div style={{paddingBottom: '10px', fontSize: '12px'}}>Platform fee: 1% deducted</div>
                }
                {youOwnKeyNum === 0 && !opened && <div style={{padding: '10px 0'}}>
                    {t('Hold at least 1 key to Open Red Packets')}
                </div>}
                <div className={s.numinfo}>
                    <span>Remaining/Total: {convertWalletBalance(amountLeft)}/{convertWalletBalance(amountTotal)}</span>
                    <span>Left: {sharingLeft}/{sharingTotal}</span>
                </div>
                <div className={s.mainlist}>
                    {sharingList?.map((item) => {
                        const {
                            displayName,
                        } = item?.profile;
                        const avatarUrl = getAvatarFromProfile(item?.profile, 0);
                        const originalAvatarUrl = getAvatarFromProfile(item?.profile, 1);

                        return (
                            <div className={s.userlist}>
                                <div className={s.avatarInfo}>
                                    <AvatarInfo size={36} src={originalAvatarUrl || avatarUrl}/>
                                </div>
                                <div className={s.namedate}>
                                    <span className={s.username}>{sliceStr(displayName, 20)}</span>
                                    <span className={s.userdate}>{parseTime(item?.time)}</span>
                                </div>
                                <div className={s.useramount}>
                                    {coinType === 1 ?
                                        <CustomIcon width={20} height={20} className="mr5 "
                                                    imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                                    name={'Picture/UI_Picture-Currency_MATIC_01'}/> : ''}
                                    {coinType === 0 ?
                                        <CustomIcon width={20} height={20} className="mr5 "
                                                    imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                    name={'Picture/UI_Picture-Currency_LFG_01'}/> : ''}{convertWalletBalance(item?.amount)}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/*<div className={s.btnwrap}>                */}
                {/*    <AntButton className={`btn_public fs18 ${s.btn}`} shape="round">*/}
                {/*        {t('SETUP')}*/}
                {/*    </AntButton>*/}
                {/*</div>*/}
            </div>
        </Spin>
    )
}
export default memo(RedPacketModelView);
