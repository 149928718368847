import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";

import AntModal from "@/common/AntModal";
import { Tabs } from "antd";

import { AutoStrangeRule } from "@/utils/strangeRule";
import Price from "./Price";
import Airdrop from "./Airdrop";
import Attr from "./Attr";

const Setting = (
    {
        showViewFn,
        selCreatChain,
        clubTicker,
        // Airdrop Data start
        coinTotalSupply, setCoinTotalSupply,
        airdropPecentage, setAirdropPecentage,
        receiveNFTAmount, setReceiveNFTAmount,
        receiveNFT, setReceiveNFT,
        airdropType, setAirdropType,
        coinTotalSupplyData,
        airdropPecentageData,
        coinToNFTPecentageData,
        airdropTypeData,
        unpackRatio, setUnpackRatio,
        lastCardPrice,
        // Airdrop Data end

        // Price Data start
        activeCurve, setActiveCurve,
        paramA, setParamA,
        paramB, setParamB,
        cardTotalSupply, setCardTotalSupply,
        totalCardValue,
        momentPriceFormular,
        cardTotalSupplyData,
        liquidityPoolLimitData,
        paramAMin, 
        paramAMax, 
        setParamAMin,
        setParamAMax,
        paramBPercent,
        paramAPercent,
        setParamBPercent,
        setParamAPercent,
        // Price Data end


        // Attr Data start
        withRarity,
        setWithRarity,
        attrs,
        setAttrs,
        momentGenattrsFn
        // Attr Data end
    }
) => {
    const { t, i18n } = useTranslation();
    const [showAirDropTips, setShowAirDropTips] = useState(false)
    const items = [
        {
            key: 1,
            label: `${AutoStrangeRule(t('Price'))}`,
            children: <Price
                selCreatChain={selCreatChain}
                activeCurve={activeCurve}
                clubTicker={clubTicker}
                setActiveCurve={setActiveCurve}
                paramA={paramA}
                setParamA={setParamA}
                paramB={paramB}
                setParamB={setParamB}
                cardTotalSupply={cardTotalSupply}
                setCardTotalSupply={setCardTotalSupply}
                totalCardValue={totalCardValue}
                momentPriceFormular={momentPriceFormular}
                cardTotalSupplyData={cardTotalSupplyData}
                liquidityPoolLimitData={liquidityPoolLimitData}
                paramAMin = {paramAMin}
                paramAMax = {paramAMax}
                setParamAMin = {setParamAMin}
                setParamAMax = {setParamAMax}
                paramBPercent={paramBPercent}
                paramAPercent={paramAPercent}
                setParamBPercent={setParamBPercent}
                setParamAPercent={setParamAPercent}
            />,
            
        },
        {
            key: 2,
            label: <>
                {
                    showAirDropTips ?
                        <div> 
                            {AutoStrangeRule(t('Airdrop'))}
                            <div className="tabsDot">
                            </div>
                        </div>:
                         AutoStrangeRule(t('Airdrop'))
                }

            </>,

            children: <Airdrop
                totalCardValue={totalCardValue}
                clubTicker={clubTicker}
                cardTotalSupply={cardTotalSupply}
                activeCurve={activeCurve}
                coinTotalSupply={coinTotalSupply}
                setCoinTotalSupply={setCoinTotalSupply}
                airdropPecentage={airdropPecentage}
                setAirdropPecentage={setAirdropPecentage}
                receiveNFTAmount={receiveNFTAmount}
                setReceiveNFTAmount={setReceiveNFTAmount}
                receiveNFT={receiveNFT}
                setReceiveNFT={setReceiveNFT}
                airdropType={airdropType}
                setAirdropType={setAirdropType}
                coinTotalSupplyData={coinTotalSupplyData}
                airdropPecentageData={airdropPecentageData}
                unpackRatio={unpackRatio}
                setUnpackRatio={setUnpackRatio}
                airdropTypeData={airdropTypeData}
                selCreatChain={selCreatChain}
                lastCardPrice={lastCardPrice}
                setShowAirDropTips={setShowAirDropTips}
            />,
        },
        {
            key: 3,
            label: `${AutoStrangeRule(t('NFT Attributes'))} `,
            children: <Attr
                withRarity={withRarity}
                clubTicker={clubTicker}
                setWithRarity={setWithRarity}
                coinTotalSupply={coinTotalSupply}
                coinToNFTPecentageData={coinToNFTPecentageData}
                receiveNFTAmount={receiveNFTAmount}
                setReceiveNFTAmount={setReceiveNFTAmount}
                receiveNFT={receiveNFT}
                setReceiveNFT={setReceiveNFT}
                attrs={attrs}
                setAttrs={setAttrs}
                selCreatChain={selCreatChain}
                momentGenattrsFn={momentGenattrsFn}
            />,
        }
    ];
    return (
        <div className={`${s.modalWrap}`}>
            <MainHeader hasRightMenu={false} headerType={2} hasBackBtn={false} title={t('Advanced Settings')} ></MainHeader>
            <div className={s.modal}>

                <div className={s.content}>
                    <div className={s.wrap}>
                        <Tabs
                            defaultActiveKey={1}
                            items={items}
                            className="tptabs"
                            destroyInactiveTabPane={true}>
                        </Tabs>
                    </div>
                </div>
                <div className={s.bottom}>
                    {
                        showAirDropTips && <div className="w100p flex_center_center mb20 fs10 color-yellow">{t('To ensure the value of DNA from card packs ≥ the purchase price, the airdrop ratio must ≥ Minimum Airdrop Ratio.')}</div>
                    }
                    <ButtonFactory
                        size={21}
                        scale={1.35}
                        onClick={showViewFn}
                    >
                        {t('Done')}
                    </ButtonFactory>
                </div>
            </div>

        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Setting));
