import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import CustomIcon from "../../../common/CustomIcon";
import {
    copyFn,
    logoutSelf,
    unit,
    sliceStr,
    getearninfo,
    stringToNumber,
    updateUserInfo,
    getfriendtrade_rank_selfdata,
    getuserinfo,
    refreshAvatar,
    use_platform,
    joinChartPre,
    vCoinString,
    post_global_production_info,
    post_friendtrade_boost_claimcoin,
    post_take_coin,
    formatNumberWithUnit,
    convertWalletBalance,
    checkSigUsed,
    displayFilter,
    getPowerXY,
    getFriendtradeVirtualLfg,
    getFriendtradeChainStake, TransactionError, PrivyLoginStatusError
} from "@/utils/common";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {useTranslation, Trans} from 'react-i18next';
import {lfgStakeBalanceOf} from "@/utils/lfgStake";
import {post_red_packet_expire, redPacketWithdraw} from "@/utils/red_Packet";
import AntButton from "@/common/AntButton";
import {linkPath} from "@/routes/config";
import ButtonFactory from "@/common/ButtonFactory";


const SettingsRedPacket = (
    {}
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();

    const [redPacketLfgBalance, setRedPacketLfgBalance] = useState(0)
    const [redPacketMaticBalance, setRedPacketMaticBalance] = useState(0)
    const redPacketList = useRef([]);

    const [subLoading, setSubLoading] = useState(false);
    const [done, setDone] = useState(false);
    const [subFailed, setSubFailed] = useState(false);
    const [noSuccessText, setNoSuccessText] = useState(t('Failed'));
    const claimAll = () => {
        setSubLoading(true);
        setSubFailed(false);
        setDone(false);
        setNoSuccessText(t('Failed'));

        redPacketWithdraw(wallets, redPacketList.current).then(r => {
            setTimeout(() => {
                refreshData();
            }, 2500);
            setSubLoading(false);
            setDone(true);
        }).catch(e => {
            console.log(e);
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }

            if (e === 'need login') {
                setNoSuccessText(PrivyLoginStatusError)
            }

            setSubLoading(false);
            setDone(true);
            setSubFailed(true)
        })
    }

    const refreshData = () => {
        post_red_packet_expire().then(r => {
            setRedPacketLfgBalance(r?.data?.leftLfg)
            setRedPacketMaticBalance(r?.data?.leftMatic)
            redPacketList.current = r?.data?.redPacketIdList;
        });
    }

    useEffect(() => {
        refreshData();
    }, [])

    return (
        <>
            {(redPacketLfgBalance > 0 || redPacketMaticBalance > 0) &&
                <>
                    <div className={s.bLItem}>
                        <div className={s.il}>
                            {redPacketLfgBalance > 0 && <>
                                <CustomIcon width={22} height={22} className="mr5 "
                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                            name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                <div className="fs18 fb">
                                    {convertWalletBalance(redPacketLfgBalance)}
                                </div>
                            </>}

                            {redPacketMaticBalance > 0 && <>
                                <CustomIcon width={22} height={22} className="mr5 "
                                            imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                            name={'Picture/UI_Picture-Currency_MATIC_01'}/>
                                <div className="fs18 fb">
                                    {convertWalletBalance(redPacketMaticBalance)}
                                </div>
                            </>}

                            &nbsp;{t('Expired Red Packet Balance')}
                        </div>
                        <div className={s.ibtn}>
                            <ButtonFactory
                                size={21}
                                onClick={() => claimAll()}
                            >
                                {!done && <>
                                    {subLoading ? t('PROCESSING') : t('Claim All')}
                                </>}
                                {done && <>
                                    {subFailed ? noSuccessText : t('SUCCESSFUL')}
                                </>}
                            </ButtonFactory>
                        </div>
                    </div>
                </>}
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(SettingsRedPacket));
