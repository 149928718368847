import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../common/CustomIcon";
import {
    convertWalletBalance, 
    numberClamp, 
    getChainTokenInfoById,
} from "@/utils/common";
import { 
    coinList,
    getMemeKingTgeWinnerList,
    getMemeGenImage,
} from "@/utils/memeClub";
import MemeClubPack from "@/common/MemeClubPack";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Slider, Checkbox, Select, Spin} from "antd";
import ButtonFactory from "@/common/ButtonFactory";
import { LoadingOutlined } from "@ant-design/icons";
const { Option } = Select;

const ModalMemeCreateImage = (
    {
        memeClubImgUrl,
        keyWord,
        setMemeClubImage = (url) =>{},
        closeHandle = () =>{},
    }
) => {
    const {t, i18n} = useTranslation();
    const [loadingError, setLoadingError] = useState(0);//1:common error; 1032:reach daily limit; 1033:reach total limit
    const [loading, setLoading] = useState(true);
    const [imageList, setImageList] = useState([]);
    const [choosedIndex, setChoosedIndex] = useState(0);

    const testUrl1 = "https://pbs.twimg.com/profile_images/1706942071406510080/pPQI4KjP_bigger.jpg";
    const testUrl2 = "https://pbs.twimg.com/profile_images/1706943494856826880/vHIvkhYG_bigger.jpg";

    const getAIImage = () =>{
        getMemeGenImage({
            keyWord,
            genType: 1
        }).then(res => {
            setLoading(false);
            let tempImageList = [memeClubImgUrl];
            if (res?.imageUrls?.length > 0){
                for (let i = 0; i < res?.imageUrls?.length; i++){
                    tempImageList.push(res?.imageUrls[i]);
                }
            }
            setImageList(tempImageList);
        }).catch(e => {
            console.error(e);
            if (e === 1032 || e === 1033){
                setLoadingError(e);
            }
            else{
                setLoadingError(1);
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        getAIImage();
    }, [])

    const confirmFn = () =>{
        if (choosedIndex < imageList?.length){
            setMemeClubImage(imageList[choosedIndex]);
            closeHandle();
        }
        else{
            closeHandle();
        }
    }

    return (
        <div className={s.wrap}>
            <div className={s.topPart}>
                <div className={`${s.content} flex_center_start_col`}>
                    {
                        loadingError > 0 ? 
                        <>
                            <CustomIcon className="mt30" width={111} height={95}
                                imgName={'Picture/UI_Picture_NoData_Empty_01'}/>
                            <div className={`${s.failedTitle}`}>
                                {t('Generation failed')}
                            </div>
                            <div className={`${s.failedDes}`}>
                                {
                                    loadingError === 1032 ?
                                    t('You have used up today\'s quota please try again tomorrow') :
                                    loadingError === 1033 ?
                                    t('The image generation limit has been reached') :
                                    t('Please try again later or upload your local image')
                                }
                            </div>
                            <ButtonFactory
                                className={s.closeBtn}
                                size={21}
                                isFixed={true}
                                fixedStyle={{useHeight: true, height: 42}}
                                onClick={() => {
                                    closeHandle();
                                }}
                            >
                                <div className={`${s.closeBtnText}`}>
                                    {t('Confirm')}
                                </div>
                            </ButtonFactory>
                        </> :
                        loading ? 
                        <>
                            <div className={`${s.loadingRoot} flex_center_center_col`}>
                            <Spin
                                className="loadPageSpin"
                                wrapperClassName="loadImgSpinWrapper"
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            fontSize: 90,
                                        }}
                                    />
                                }
                                tip={t('Generating')}
                                spinning={loading}
                            >
                                <div className={s.spinWrap}></div>
                            </Spin>
                                {/* <div className={`${s.loadingText}`}>
                                    {t('Generating')}
                                </div> */}
                            </div>
                        </> :
                        <>
                            <div className={`${s.lineTitle}`}>
                                {t('Original')}
                            </div>
                            <div className={`${s.imageRoot} flex_center_center`}>
                                <div className={`${s.singleImage}`}>
                                    <MemeClubPack 
                                        width={138} 
                                        height={138}
                                        imageUrl={imageList?.[0]} 
                                        packName={''}
                                        className={s.imageItem}
                                        blackShadow={false}>
                                    </MemeClubPack>
                                    {
                                        choosedIndex === 0 &&
                                        <div className={`${s.choosedImage}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_YellowPoint-Hook_01.png)`}}>
                                        </div>
                                    }
                                    <div className={`${s.btnItem}`} onClick={() =>{
                                        setChoosedIndex(0);
                                    }}>
                                    </div>
                                </div>
                            </div>
                            {
                                imageList?.length > 1 && 
                                <>
                                    <div className={`${s.lineTitle}`}>
                                        {t('New')}
                                    </div>
                                    <div className={`${s.imageRoot} mt5 flex_center_center`}>
                                        {
                                            imageList?.length >= 2 && 
                                            <div className={`${s.singleBigImage}`}>
                                                <MemeClubPack 
                                                    width={180} 
                                                    height={180}
                                                    imageUrl={imageList?.[1]} 
                                                    packName={''}
                                                    className={s.imageItem}
                                                    blackShadow={false}>
                                                </MemeClubPack>
                                                {
                                                    choosedIndex === 1 &&
                                                    <div className={`${s.choosedImage}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_YellowPoint-Hook_01.png)`}}>
                                                    </div>
                                                }
                                                <div className={`${s.btnItem}`} onClick={() =>{
                                                    setChoosedIndex(1);
                                                }}>
                                                </div>
                                            </div>
                                        }
                                        {
                                            imageList?.length >= 3 && 
                                            <div className={`${s.singleBigImage}`}>
                                                <MemeClubPack 
                                                    width={180} 
                                                    height={180}
                                                    imageUrl={imageList?.[2]} 
                                                    packName={''}
                                                    className={s.imageItem}
                                                    blackShadow={false}>
                                                </MemeClubPack>
                                                {
                                                    choosedIndex === 2 &&
                                                    <div className={`${s.choosedImage}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_YellowPoint-Hook_01.png)`}}>
                                                    </div>
                                                }
                                                <div className={`${s.btnItem}`} onClick={() =>{
                                                    setChoosedIndex(2);
                                                }}>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            <div className={`${s.generateTitle}`}>
                                {t('Select the image you want to use')}
                            </div>
                            <ButtonFactory
                                className={s.confirmBtn}
                                size={21}
                                isFixed={true}
                                fixedStyle={{useHeight: true, height: 42}}
                                onClick={() => {
                                    confirmFn();
                                }}
                            >
                                <div className={`${s.confirmBtnText}`}>
                                    {t('Confirm')}
                                </div>
                            </ButtonFactory>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
export default memo(ModalMemeCreateImage);
