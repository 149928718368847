import React, {memo, useState, useMemo, useEffect} from "react";
import s from "./index.module.less";
import {Input, Button, Modal, Spin} from "antd";
import {
    vCoinString,
    getTransactionErrorMsgId
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import _ from "lodash";
import {useWallets} from "@privy-io/react-auth";
import Countdown from "../../common/Countdown";
import {checkSigUsed, claimAllPendingLfg, getMintAndStakeRequest, getMintRequest} from "@/utils/lfgMintWeb3";
import ButtonFactory from "src/common/ButtonFactory";

const ModelLfgReview = (
    {
        mintRecord,
        mintAndStakeRecord,
        closeFn,
        getMintRequestFn,
        getMintAndStakeRequestFn
    }
) => {
    const nowTime = new Date().getTime() / 1000;
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const [failedMessage, setFailedMessage] = useState('');
    const mintDetailList = useMemo(() => {
        var list = [];
        if (mintRecord?.orderId && mintRecord.orderId > 0) {
            list.push({
                amount: mintRecord.amount,
                fnType: t('mint'),
                lockedUntil: mintRecord.lockedUntil
            })
        }
        if (mintAndStakeRecord?.orderId && mintAndStakeRecord.orderId > 0) {
            list.push({
                amount: mintAndStakeRecord.amount,
                fnType: t('mint and stake'),
                lockedUntil: mintAndStakeRecord.lockedUntil
            })
        }
        return list;
    }, [mintRecord, mintAndStakeRecord]);

    const [subLoading, setSubLoading] = useState(false);
    const [done, setDone] = useState(false);
    const [isInClaimCD, setIsInClaimCD] = useState(false);

    useEffect(() => {
        updateClaimBtnState();
    }, []);

    const updateClaimBtnState = () => {
        let claimButtonUnlockTime = localStorage.getItem('_TT_lfgReviewBtnUnlockTime');
        let now = new Date().getTime() / 1000;
        setIsInClaimCD(now <= claimButtonUnlockTime);
    }

    const claimAllFn = () => {
        setSubLoading(true);
        claimAllPendingLfg({
            wallets: wallets
        }).then(res => {
            setSubLoading(false);
            setDone(true);
            setFailedMessage(null);
            localStorage.setItem('_TT_lfgReviewBtnUnlockTime', new Date().getTime() / 1000 + 30);
            setTimeout(() => {
                getMintRequestFn();
                getMintAndStakeRequestFn();
            }, 2000);
            console.log('[claimAllPendingLfg]', res);
        }).catch(e => {
            setSubLoading(false);
            setFailedMessage(t(getTransactionErrorMsgId(e?.reason)));
            console.log(e);
        });
    }

    const updateMintList = () => {
        getMintRequestFn();
        getMintAndStakeRequestFn();
    }

    const getClaimState = () => {
        var nowTimeStamp = new Date().getTime() / 1000;
        if (mintRecord?.orderId > 0 && mintRecord?.lockedUntil <= nowTimeStamp) {
            return true;
        }
        if (mintAndStakeRecord?.orderId > 0 && mintAndStakeRecord?.lockedUntil <= nowTimeStamp) {
            return true;
        }
        return false;
    }

    const claimBtnRefesh = () => {
        updateMintList();
        updateClaimBtnState();
    }

    return (
        <div className={s.wrap}>
            <div className={s.des}>
                <div className={`${s.desLine}`}>{t('$LFG: Mint & Withdrawal Status Tips01')}</div>
                <div className={`${s.desLine} color-blue-light`}>{t('$LFG: Mint & Withdrawal Status Tips02')}</div>
            </div>
            <div className={s.tableRoot}>
                <div className={s.tableTitles}>
                    <span className={s.tableCol}>{t('$LFG Amount')}</span>
                    <span className={s.tableCol}>{t('Transaction')}</span>
                    <span className={s.tableCol}>{t('Review Status')}</span>
                </div>
                <div className={`${s.checkBoxes}`}>
                    {mintDetailList?.length > 0 ? mintDetailList?.map((item, index) => (
                            <div key={index} className={s.checkBoxLine}>
                                <div className={s.tableValueCol}>{vCoinString(item.amount)}</div>
                                <div className={s.tableValueCol}>{item.fnType}</div>
                                <div className={s.tableValueCol}>
                                    {
                                        item.lockedUntil <= nowTime ?
                                            <CustomIcon width={14} height={14}
                                                        imgName={'Picture/UI_Picture_GreenRound_Hook_01'}
                                                        name={'Picture/UI_Picture_GreenRound_Hook_01'}/> :
                                            <Countdown workEndTimestamp={item.lockedUntil} refresh={updateMintList}/>
                                    }
                                </div>
                            </div>)) :
                        <div className={s.tableEmpty}>
                            {t('No pending application')}
                        </div>
                    }
                </div>
            </div>
            <div className={s.errorMsgRoot}>
                {
                    failedMessage && <div className={s.errorMessageLine}>
                        <CustomIcon className="m02" width={18} height={18} imgName={'Picture/UI_Picture_RedRound_Cross_01'}
                                    name={'Picture/UI_Picture_RedRound_Cross_01'}/>
                        <span>{failedMessage}</span>
                    </div>
                }
            </div>
            <div className={`modelBottomBtn`}>
                {
                    isInClaimCD ?
                        <ButtonFactory disabled={true} className={`${s.btn} btn_public btn_disabled`} shape="round"
                                       onClick={() => {
                                       }}>
                            {t('TRANSACTION PENDING')} {'('}<Countdown className={s.countdownSecondText} showType={1}
                                                                       workEndTimestamp={localStorage.getItem('_TT_lfgReviewBtnUnlockTime') || 0}
                                                                       refresh={() => {
                                                                           claimBtnRefesh();
                                                                       }}/>{')'}
                        </ButtonFactory> :
                        failedMessage ?
                            <ButtonFactory className={`${s.btn} btn_public`} shape="round" onClick={closeFn}>
                                {t('TRANSACTION FAILED')}
                            </ButtonFactory> :
                            subLoading ?
                                <ButtonFactory disabled={true}>
                                    <CustomIcon rotating={true} className="ml5" width={26} height={26}
                                                imgName={'Picture/UI_Picture_Loading_01'}
                                                name={'Picture/UI_Picture_Loading_01'}/>
                                    <span className="fs18 color-black">{t('PROCESSING')}</span>
                                </ButtonFactory> :
                                done ?
                                    <ButtonFactory onClick={closeFn}>
                                        {t('TRANSACTION SUCCESSFUL')}
                                    </ButtonFactory> :
                                    getClaimState() ?
                                        <ButtonFactory onClick={claimAllFn}>
                                            {t('MINT')}
                                        </ButtonFactory> :
                                        <ButtonFactory disabled={true} onClick={() => {
                                        }}>
                                            {t('MINT')}
                                        </ButtonFactory>
                }
            </div>
        </div>
    )
}
export default memo(ModelLfgReview);
