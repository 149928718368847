import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";

import {Button} from "antd";
import CustomIcon from "../../common/CustomIcon";
import imgUrl from "@/assets/images/FingersCrossed.png";
import {useTranslation, Trans} from 'react-i18next';
import {AutoStrangeRule} from "@/utils/strangeRule";

const ModelStakeIntro = (
    {
        setShowModelWishIntro,
    }
) => {
    const {t, i18n} = useTranslation();
    return (
        <div className={s.wrap}>
            <div className={s.img}>
                <img src={'./UI/Picture/UI_Picture_FingersCrossed_01.png'}/>
            </div>
            <div className="fs18 color-white mt70 fb">
                {AutoStrangeRule(t('STAKE $LFG'))}
            </div>
            <div className={s.text}>
                <div className="fs16 color-white tll mt20">
                    {t('Stake intro 01')}
                </div>
                <div className="fs16 color-white fl tll">
                    {t('Stake intro 02')}
                </div>
                <div className="fs16 color-white fl tll">
                    {t('Stake intro 03')}
                </div>
            </div>

        </div>
    )
}
export default memo(ModelStakeIntro);
