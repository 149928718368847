import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "src/common/CustomIcon";
import {numberClamp} from "@/utils/common";

const ModelShareHelp = () => {
    const {t, i18n} = useTranslation();

    const [pageIndex, setPageIndex] = useState(0);

    return (
        <div className={s.wrap}>
            <div className={s.pageControl}>
                <div className={s.prev} onClick={() => {
                    setPageIndex(numberClamp(pageIndex - 1, 0, 1));
                }}>
                    {pageIndex > 0 && <CustomIcon
                        width={14}
                        height={28}
                        imgName={"Button/UI_Button_Arrow_Left_01"}
                    ></CustomIcon>}
                </div>
                <div className={s.next} onClick={() => {
                    setPageIndex(numberClamp(pageIndex + 1, 0, 1));
                }}>
                    {pageIndex < 1 && <CustomIcon
                        width={14}
                        height={28}
                        imgName={"Button/UI_Button_Arrow_Right_01"}
                    ></CustomIcon>}
                </div>
            </div>
            <div className={s.topPart}>
                <div className={s.title}>
                    {pageIndex === 0 && t('Create X Content & Earn $UP Points')}
                    {pageIndex === 1 && t('Earn Daily $UP Points')}
                </div>
                <div className={s.content}>
                    {pageIndex === 0 &&
                        <>
                            <div className={`${s.imgRoot} mt25`}>
                                <CustomIcon width="100%" height={180}
                                            imgName={'BG/UI_Background-S_UPTipPage_01'}
                                            imgType = {'.webp'}
                                            />
                            </div>
                            <div className={`${s.contentText} mt25`}>
                                <Trans
                                    i18nKey="Create Content & Engage with"
                                    defaults="Create Content & Engage with"
                                    components={{font: <span style={{color: '#fab600'}}></span>}}
                                /><br/>
                                <Trans
                                    i18nKey="@GoTurnUp on X to earn daily $UP  points Airdrops."
                                    defaults="<font>@GoTurnUp</font> on X to earn daily $UP  points Airdrops."
                                    components={{font: <span style={{color: '#fab600'}}></span>}}
                                /><br/>
                                <Trans
                                    i18nKey="Post, Comment & Repost on X Tag"
                                    defaults="Post, Comment & Repost on X Tag <font>@GoTurnUp</font> & Mention <font>$LFG</font>"
                                    components={{font: <span style={{color: '#fab600'}}></span>}}
                                /><br/>
                                <Trans
                                    i18nKey="The more engagement your content gets, the Bigger your $UP Points Airdrop"
                                    defaults="The more engagement your content gets, the Bigger your $UP Points Airdrop"
                                    components={{font: <span style={{color: '#fab600'}}></span>}}
                                />
                            </div>
                        </>
                    }
                    {pageIndex === 1 &&
                        <>
                            <div className={`${s.imgRoot} mt25`}>
                                <CustomIcon width="100%" height={180}
                                            imgName={'BG/UI_Background-S_UPTipPage_02'}
                                            imgType = {'.webp'}
                                            />
                            </div>
                            <div className={`${s.contentText} mt25`}>
                                {t('$UP Points are dropped to users daily at 00:00AM UTC')}
                                <br/><br/>
                                {t('Your daily TURNUP score/global TURNUP score determines your daily $UP point earnings')}
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className={s.pageRoot}>
                <div
                    className={`${pageIndex === 0 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(0)
                    }}>
                </div>
                <div
                    className={`${pageIndex === 1 ? s.checkedPage : s.uncheckedPage}`}
                    onClick={() => {
                        setPageIndex(1)
                    }}>
                </div>
            </div>
        </div>
    )
};
export default memo(ModelShareHelp);
