import React, {memo, useRef, useState, useEffect, useMemo} from "react";
import s from "./index.module.less";
import {
    numberClamp,
} from "@/utils/common";

const MomentClubUnpackCard = ({
    width,
    selectedNftInfo,
    attributeSetting,
    symbolImageUrl,
    rewardAmount = 0,
    onClick = () => {},
    className,
    showTokenId = true,
    showImage = false,
    showEffect = true,
    type = 1,
}) => {
    useEffect(() => {
        document.documentElement.style.setProperty("--unpackcard-width", `${width}px`);
    }, [width]);

    const [showBigImage, setShowBigImage] = useState(false);

    const {
        rarityValue,
        image,
        name,
        token_id,
        attributes,
    } = useMemo(() =>{
        return selectedNftInfo || {};
    }, [selectedNftInfo]);

    /*const convertAmount = (value) =>{
        if (value > 1000000000){
            return `${Math.floor(value/1000000000)}b`;
        }
        else if (value > 1000000){
            return `${Math.floor(value/1000000)}m`;
        }
        else if (value > 1000){
            return `${Math.floor(value/1000)}k`;
        }
        else if (value > 10){
            return Math.floor(value);
        }
        else{
            return value.toFixed(2);
        }
    }*/

    /*const numberAttribute = useMemo(() => {
        let res = [];
        if (attributes?.length > 0){
            for (let i = 0; i < attributes?.length; i++){
                let key = attributes?.[i]?.trait_type?.toLowerCase();
                let value = attributes?.[i]?.value;
                for (let j = 0; j < attributeSetting?.length; j++){
                    if (attributeSetting?.[j]?.attrName?.toLowerCase() === key){
                        if (attributeSetting?.[j]?.type === 1){
                            let tempAttr = {
                                ...attributeSetting[j],
                                value: Number(value),
                            };
                            res.push(tempAttr);
                        }
                        break;
                    }
                }
            }
        }
        return res;
    }, [attributes, attributeSetting]);*/

    /*const stringAttribute = useMemo(() => {
        let res = [];
        if (attributes?.length > 0){
            for (let i = 0; i < attributes?.length; i++){
                let key = attributes?.[i]?.trait_type?.toLowerCase();
                let value = attributes?.[i]?.value;
                for (let j = 0; j < attributeSetting?.length; j++){
                    if (attributeSetting?.[j]?.attrName?.toLowerCase() === key){
                        if (attributeSetting?.[j]?.type === 2){
                            let tempAttr = {
                                ...attributeSetting[j],
                                value: value,
                            };
                            res.push(tempAttr);
                        }
                        break;
                    }
                }
            }
        }
        return res;
    }, [attributes, attributeSetting]);*/

    /*const getNumberAttributeProgress = (item) =>{
        if (item){
            let progress = (item?.value-item?.minNum)*100/(item?.maxNum - item?.minNum);
            if (progress <= 0) {
                return 0;
            }
            else {
                return numberClamp(progress, 10, 100);
            }
        }
        return 0;
    }*/

    return (
        <>
            {
                type === 2 ?
                <div className={`${s.memeClubUnpackCardRoot} ${className}`} onClick={(e) => {
                        if (onClick){
                            e.preventDefault();
                            e.stopPropagation();
                            onClick();
                        }
                    }} 
                    style={{width: `${width}px`}}>
                    {
                        (showEffect && rarityValue > 2) && 
                        <div className={`${s.cardbgEffect}`} style={{backgroundImage: `url(/UI/Gif/memeCardBg_${rarityValue.toString().padStart(2, '0')}.gif)`}}>
                        </div>
                    }
                    <div className={`${s.cardbg}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_Card_${rarityValue?.toString()?.padStart(2, '0') || '00'}.png)`}}>
                    </div>
                    <div className={`${s.cardContent} flex_center_start_col`}>
                        <div 
                            className={`${s.fullImageRoot}`} 
                            style={{
                                backgroundImage: `url(${image})`,
                                maskImage: `url(/UI/Picture/UI_Picture_Card_Mask.png)`
                            }}>
                        </div>
                        {
                            showTokenId &&
                            <div className={`${s.fullCardNamePart} flex_center_center`}>
                                {`#${token_id}`}
                            </div>
                        }
                    </div>
                </div>:
                <div className={`${s.memeClubUnpackCardRoot} ${className}`} onClick={(e) => {
                        if (onClick){
                            e.preventDefault();
                            e.stopPropagation();
                            onClick();
                        }
                    }} 
                    style={{width: `${width}px`}}>
                    {
                        (showEffect && rarityValue > 2) && 
                        <div className={`${s.cardbgEffect}`} style={{backgroundImage: `url(/UI/Gif/memeCardBg_${rarityValue.toString().padStart(2, '0')}.gif)`}}>
                        </div>
                    }
                    <div className={`${s.cardbg}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_Card_${rarityValue?.toString()?.padStart(2, '0') || '00'}.png)`}}>
                    </div>
                    <div className={`${s.cardContent} flex_center_start_col`}>
                        {/*<div className={`${s.cardUpPart} flex_center_start`}>
                            {
                                showImage ? 
                                <div className={`${s.cardImage}`} style={{backgroundImage: `url(${image})`}} onClick={(e) =>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowBigImage(true);
                                }}>
                                    <div className={`${s.cardQualityIcon}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardQuality_${rarityValue?.toString()?.padStart(2, '0') || '00'}.png)`}}>
                                    </div>
                                </div> :
                                <div className={`${s.tempEmpty}`}>
                                </div>
                            }
                        </div>*/}
                        <div 
                            className={`${s.imageRoot}`} 
                            style={{
                                backgroundImage: `url(${image})`}}>
                            
                        </div>
                        <div className={`${s.cardNamePart}`}>
                            {name || token_id}
                        </div>
                        {/*<div className={`${s.nftAttributeRoot} flex_center_start_col`}>
                            {
                                numberAttribute?.length > 0 &&
                                numberAttribute.map((item, index) => {
                                    return (
                                        <div className={`${s.attributeLine} flex_center_between`} key={index}>
                                            <div className={`${s.attibuteLineName}`}>
                                                {item?.attrName}:
                                            </div>
                                            <div className={`${s.attibuteLineNumberValue} flex_center_start`}>
                                                <div className={`${s.attibuteLineNumberValueProgress}`}
                                                    style={{width: `${getNumberAttributeProgress(item)}%`}}>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            {
                                stringAttribute?.length > 0 &&
                                stringAttribute.map((item, index) => {
                                    return (
                                        <div className={`${s.attributeLine} flex_center_between`} key={index}>
                                            <div className={`${s.attibuteLineName}`}>
                                                {item?.attrName}:
                                            </div>
                                            <div className={`${s.attibuteLineStringValue} flex_center_center`}>
                                                <div className={`${s.value}`}>
                                                    {item?.value}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>*/}
                    </div>
                </div>
            }
            {
                showBigImage &&
                <div className={`${s.bigImageRoot}`} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowBigImage(false);
                }}>
                    <div className={`${s.bigImage}`} style={{backgroundImage: `url(${image})`}}>
                    </div>
                </div>
            }
        </>
    )
}
export default memo(MomentClubUnpackCard);
