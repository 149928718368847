import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { Input } from "antd";

import ButtonFactory from "@/common/ButtonFactory";
import { convertWalletBalance, numberClamp } from "@/utils/common";

const ModelSellCoinTips = (
    {
        info,
        tranFn,
        closeFn,
    }
) => {
    const { t, i18n } = useTranslation();

    const {coinBalance,coinToNFTRatio,sellAmount,nftAmount,symbolName} = useMemo(()=>{
        return info || {}
    },[info])
    return (
        <div className={`${s.box}`}>
            <div className="fs10">
                    <Trans
                        i18nKey="For every decrease of xx , one NFT will be burned."
                        defaults="1 NFT is burnt for holding less than <yellow>{{count}}</yellow> ${{symbolName}}. This repeats for every <yellow>{{count}}</yellow> ${{symbolName}}"
                        values={{ 
                            count: convertWalletBalance(coinToNFTRatio) ,
                            symbolName: symbolName
                        }}
                        components={{ yellow: <span className="fs10 color-yellow"></span> }}
                      />
            </div>

            <div className={s.amountWrap}>
                <div className={s.line}>
                    <div className={s.label}>
                        {t('Your ${{symbolName}}: ',{symbolName:symbolName})}
                    </div>
                    <div className={s.val}>
                        {convertWalletBalance(coinBalance)}
                    </div>
                </div>
                <div className={s.line}>
                    <div className={s.label}>
                        {t('${{symbolName}} to generate NFT:',{symbolName:symbolName})}
                    </div>
                    <div className={s.val}>
                        {convertWalletBalance(coinToNFTRatio)}
                    </div>
                </div>
                <div className={s.line}>
                    <div className={s.label}>
                        {t('Selling ${{symbolName}}: ',{symbolName:symbolName})}
                    </div>
                    <div className={s.val}>
                        {convertWalletBalance(sellAmount)}
                    </div>
                </div>
                <div className={s.line}>
                    <div className={s.label}>
                        {t("Total NFT burnt: ")}
                    </div>
                    <div className={s.val}>
                        {nftAmount}
                    </div>
                </div>
                <div className="fs10 mt15">{t('Note: This action is irreversible. Burned NFTs cannot be recovered. Attributes of newly generated NFTs may vary.')}</div>
            </div>
            <ButtonFactory
                onClick={tranFn}
            >
                {t('Continue')}
            </ButtonFactory>
            <div className="fs14 mt15" onClick={closeFn}>{t('Maybe Later')}</div>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelSellCoinTips));
