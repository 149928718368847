import React, {useEffect, useState} from "react";
import s from "./index.module.less";
import CustomIcon from "src/common/CustomIcon";
import ButtonFactory from "src/common/ButtonFactory";

const InputNumber = (
    {
        value,
        min,
        max,
        onChange,
    }
) => {
    useEffect(() => {
        setCount(parseInt(value));
    }, [value]);

    const [count, setCount] = useState(parseInt(value));
    const onMinsFn = () => {
        setCount((v) => {
            if (min && v <= min) return min;
            onChange(v - 1)
            return v - 1
        });
    };
    const onPlusFn = () => {
        setCount((value) => {
            if (max && value >= max) return max;
            onChange(value + 1)
            return value + 1;
        });
    };
    const onChangeFn = () => {
        setCount((value) => {
            if (isNaN(value)) return min;
            if (min && value < min) return min;
            if (max && value > max) return max;

            onChange(value);

            return value;
        });
    };

    const onInput = (e) => {
        const value = parseInt(e.target.value);
        setCount(value);
        onChangeFn();
    };

    return (
        <div className={`${s["input-number"]}`}>
            <CustomIcon
                width={25}
                height={25}
                imgName={"Button/UI_Button_Minus-White-Irr_01"}
                name={"Button/UI_Button_Minus-White-Irr_01"}
                onClick={onMinsFn}
            />
            <input type="number" value={count} onChange={onInput}/>
            <CustomIcon
                width={25}
                height={25}
                imgName={"Button/UI_Button_Plus-Yellow-Irr_01"}
                name={"Button/UI_Button_Plus-Yellow-Irr_01"}
                onClick={onPlusFn}
            />
        </div>
    );
};

export default InputNumber;
