import React, { memo, useState, useMemo, useRef, useEffect } from "react";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import { Button, Select, Checkbox } from "antd";
import { useTranslation, Trans } from 'react-i18next';
import AntButton from "@/common/AntButton";
import { AutoStrangeRule } from "@/utils/strangeRule";

const { Option } = Select;
const MemeClubSort = (
    {
        applyFn = () =>{},
        sortData,
        nativeTokenList,
        sortType,
        setSortType,
        coinId,
        setCoinId,
        isAsc,
        setIsAsc,
        coinList,
    }
) => {
    const { t, i18n } = useTranslation();

    const [currentSortType, setCurrentSortType] = useState(sortType);
    const [currentIsAsc, setCurrentIsAsc] = useState(isAsc);
    const [currentCoinId, setCurrentCoinId] = useState(coinId);

    const [openCoinId, setOpenCoinId] = useState(false);

    useEffect(() => {
        console.log("nativeTokenList", nativeTokenList, currentCoinId);
        if (nativeTokenList?.length > 0){
            let supportCurrentCoin = false;
            for (let i = 0; i < nativeTokenList?.length; i++){
                if (nativeTokenList[i].ID === currentCoinId){
                    supportCurrentCoin = true;
                    break;
                }
            }
            console.log("supportCurrentCoin", supportCurrentCoin);
            if (!supportCurrentCoin){
                setCurrentCoinId(nativeTokenList[0].ID);
            }
        }
    }, [])

    const apply = () => {
        applyFn({
            sortType: currentSortType,
            isAsc: currentIsAsc,
            coinId: currentCoinId,
        });
    }

    return (
        <div className={s.wrap}>
            <div className={`${s.title}`}>
                <CustomIcon width={18} className="mr5" height={18} imgName={'Button/UI_Button_FilterIcon_02'} /> {AutoStrangeRule(t('SORTING'))}
            </div>
            <div className={s.filterCon}>
                <div className={s.sortConlist}>
                    {sortData?.length && sortData.map(item => {
                        const { label, key } = item;
                        return (
                                <div className={s.sortOption} key={key}>
                                    <Checkbox 
                                        onChange={() => {
                                            setCurrentSortType(key);
                                            if(key === 3){
                                                setCurrentIsAsc(false)
                                            }
                                        }}
                                        className="momentCheckBox"
                                        checked={currentSortType === key}>
                                        <span className={`color-moment-light-gray fs12`}>{label}</span>
                                    </Checkbox>
                                </div>
                        )
                    })}

                </div>
            </div>

            <div className={s.bottom}>
                <AntButton 
                    className={`${currentIsAsc === true ? 'btn_white' : 'btn_transparent_moment'} mb10 ${s.commonBtn}`}
                    onClick={() => {
                        currentSortType !== 3 && setCurrentIsAsc(true);
                    }}>
                    <div className={`pr w100p flex_center_start`}>
                        <CustomIcon width={18} height={18} className={`${s.btnIcon}`}
                            imgName={currentIsAsc ? 'Button/UI_Button_FilterIcon_Up_02' : 'Button/UI_Button_FilterIcon_Up_01'}/>
                        <div className={`${!currentIsAsc?'color-white':'color-black'} fs14 fb`}>
                            {t('Ascending')}
                        </div>
                    </div>
                </AntButton>
                <AntButton 
                    className={`${currentIsAsc === false ? 'btn_white' : 'btn_transparent_moment'} mb10 ${s.commonBtn}`}
                    onClick={() => {
                        currentSortType !== 3 && setCurrentIsAsc(false);
                    }}>
                    <div className={`pr w100p flex_center_start`}>
                        <CustomIcon width={18} height={18} className={`${s.btnIcon}`}
                            imgName={!currentIsAsc ? 'Button/UI_Button_FilterIcon_Down_02' : 'Button/UI_Button_FilterIcon_Down_01'}/>
                        <div className={`${currentIsAsc?'color-white':'color-black'} fs14 fb`}>
                            {t('Descending')}
                        </div>
                    </div>
                </AntButton>
                <AntButton className={`${s.commonBtn} btn_public mb2`} onClick={apply}>{t('APPLY')}</AntButton>
                {/* <div onClick={() => setSort('v', 0)} className={`${sortVol === 0 && s.active}`}>Desc</div>
                    <div onClick={() => setSort('v', )} className={`${sortVol === 1 && s.active}`}>↑Asc</div> */}
            </div>

        </div>
    )
}
export default memo(MemeClubSort);
