import React, { memo, useCallback, useEffect, useRef, useState, useMemo } from "react";
import s from "./index.module.less";
import { connect } from "react-redux";
import {
    sliceStr,
    displayFilter,
    getAvatarFromProfile,
    getfriendtrade_takeworkcoin,
    getProfile,
    convertWalletBalance
} from "@/utils/common";
import { Button, Checkbox, Spin, message, Modal } from "antd";
import AvatarInfo from "@/common/AvatarInfo";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import CustomIcon from "src/common/CustomIcon";
import { useTranslation, Trans } from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import MainHeader from "@/common/MainHeader";
import Countdown from "src/common/Countdown";
import { getWorkDetail, getClubsList, dispatchClubs, dispatchClubsShow, getWorkItem } from "@/utils/mission";
import InfiniteScroll from "react-infinite-scroll-component";
import HireWorkResult from "@/components/HireWorkResult";
import WorkResultConfig from "@/utils/json/WorkResultConfig.json"
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
    mainClubSweepClubsPath,
    balancePath,
} from "@/routes/config";
import RewardTypeShow from '@/utils/json/RewardTypeShow.json';
import HireModelDispatchIntro from "@/model/HireModelDispatchIntro";
import HireModelWorkIntro from "@/model/HireModelWorkIntro";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import NftModelYieldIntro from "@/model/NftModelYieldIntro";
const Dispatch = (
    {
        userInfo,
    }
) => {
    // console.log(`HireModelWorkAssign`, unlockWorkIds);
    const navigate = useNavigate();
    const { workId } = useParams();
    const workInfo = getWorkItem(Number(workId));
    const { state } = useLocation()
    const { isShowClubs } = state || {}
    const { t, i18n } = useTranslation();
    const [swiper, setSwiper] = useState(null);
    const [showClubs, setShowClubs] = useState(isShowClubs || false)
    const [showHireModelWorkIntro, setShowHireModelWorkIntro] = useState(false);
    const [clubListCursor, setClubListCursor] = useState(-1)
    const [showNftModelYieldIntro,setShowNftModelYieldIntro] = useState(false)
    const {
        Image,
        UnlockFee,
        ID,
        Energy,
        Name,
        TierRequired,
        WorkSecond,
        UserNumLimit,
        BattlePointLevelRequired,
        ExtraRewardType,
        ExtraRewardNum,
        Profit,
        NFTRarityRequired,
        Type,
        Description,
        NFTCapacity
    } = workInfo
    const [workDetail, setWorkDetail] = useState({})
    const [clubList, setClubList] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [maxBatchSelectNum, setMaxBatchSelectNum] = useState(UserNumLimit)
    const [previewsInfo, setPreviewsInfo] = useState({})
    const [loading, setLoading] = useState(false)
    const [showEarnIntro, setShowEarnIntro] = useState(false)
    const [isNftWork, setIsNftWork] = useState(Type === 3 || Type === 4)
    const workType = Type === 3 || Type === 4 ? 3 : 1;
    const getTimes = (seconds) => {
        const h = parseInt(seconds / 60 / 60);
        const m = parseInt(seconds / 60 % 60);
        return `${h > 0 ? h + 'h' : ''}${m > 0 ? m + 'm' : ''}`
    }
    const { selfData, platformData } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        buyFirstKey,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    // useEffect(() => {
    //     if (swiper) {
    //         let currentWorkList = getWorkListByType(workType);
    //         let index = currentWorkList?.findIndex(v => v.ID === userInfo?.selfData?.lastChooseWorkId);
    //         swiper?.slideTo(index);
    //     }
    // }, [swiper]);

    const [rankInfo, setRankInfo] = useState({});
    // const showRank = (type, amount, currentRate) => {
    //     rankInfo.current = {
    //         type,
    //         amount,
    //         currentRate,
    //         workId: ID
    //     }
    // }

    useEffect(() => {
        if (JSON.stringify(workDetail) !== '{}') {
            const { curUserNum, curUserNumRatio, accuCoin, accuCoinRatio } = workDetail
            setRankInfo({
                coin: {
                    type: 1,
                    amount: accuCoin,
                    currentRate: accuCoinRatio,
                    workId: ID
                },
                user: {
                    type: 2,
                    amount: curUserNum,
                    currentRate: curUserNumRatio,
                    workId: ID
                },
                nft: {
                    type: 3,
                    amount : accuCoin,
                    currentRate: accuCoinRatio,
                    workId: ID
                }
            })
        }
    }, [workDetail])


    const totalWorkList = useMemo(() => {
        const _toClaimList = workDetail?.toClaimList || []
        const _inWorkList = workDetail?.inWorkList || []
        setMaxBatchSelectNum(UserNumLimit - _inWorkList?.length)
        return [..._toClaimList, ..._inWorkList]
    }, [workDetail])
    const getAlreayPersent = (leftSeconds) => {
        return Math.max(100 * (1 - (leftSeconds / WorkSecond)), 10)
    }

    const getWorkDetailFn = () => {
        getWorkDetail(ID).then(res => {
            setWorkDetail(res)
        }).catch(e => {
            console.error(e);
        })
    }

    const getClubsListFn = () => {
        getClubsList(workType, ID).then(res => {
            setClubList(res?.employeeList || [])
        }).catch(e => {
            console.error(e);
        })
    }

    const getMoreClubList = () => {

    }

    useEffect(() => {
        ID && getWorkDetailFn()
        ID && getClubsListFn()
    }, [ID])


    const handleCheckboxChange = (uid) => {
        let t
        if (selectedUsers.includes(uid)) {
            t = selectedUsers.filter((item) => item !== uid)
        } else {
            if (selectedUsers.length >= maxBatchSelectNum) return
            t = [...selectedUsers, uid];
        }
        setSelectedUsers(t)
    }

    const dispatchFn = () => {
        dispatchClubs(ID, selectedUsers).then(res => {
            console.log(res);
            getClubsListFn()
            getWorkDetailFn()
            setShowClubs(false)
            message.success({ content: t('DISPATCH SUCCESS') })
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        if(selectedUsers?.length === 0) {
            setPreviewsInfo({})
            return
        }
        setLoading(true)
        
        dispatchClubsShow(ID, selectedUsers).then(res => {
            setLoading(false)
            setPreviewsInfo(res)
        }).catch(e => {
            setLoading(false)
            console.error(e);
        })
    }, [selectedUsers])
    const [workResult, setWorkResult] = useState({})
    const [showWorkResult, setShowWorkResult] = useState(false)
    const claimClick = (_employeeId, accountType) => {
        const isWish = accountType === 'wish';
        const isNft = accountType === 'nft';
        getfriendtrade_takeworkcoin(_employeeId).then(data => {
            const {
                workResultId,
                selfClaimCoin,
                takeClaimCoin,
                employeeTwitterProfile,
                tweetTopic,
                employeeAllKeyNum,
                holdingKeyNum
            } = data || {};

            setWorkResult({
                profile: getProfile(platformData),
                type: 1,
                employeeId: _employeeId,
                employeeTwitterProfile,
                tweetTopic,
                selfClaimCoin,
                lootCoin: takeClaimCoin,
                resulteInfo: WorkResultConfig.find(v => v.ID === workResultId),
                referralCode: selfData?.referralCode,
                employeeAllKeyNum,
                holdingKeyNum,
                isWish
            })
            getWorkDetailFn()
            setShowWorkResult(true)

        }).catch(err => {
            console.error(err);
        })
    }
    const [showMore, setShowMore] = useState(false)

    return (
        <div className={`${s.HireModelWorkAssign} ${showClubs ? s.top : ''}`}
            style={{ backgroundImage: `url(/UI/Mission/${Image}.webp)` }}>
            <div className="drap-bg-black-5"></div>
            <MainHeader hasRightMenu={false} headerType={2} title={Name}></MainHeader>

            <div className={s.content}>
                {!showClubs &&
                    <div className={s.des}>{Description}</div>
                }
                <div className={`${s.workInfo} ${showClubs ? s.noBg : ''} ${showMore ? s.show : ''}`}>
                    {showClubs && <div className={s.upIcon} onClick={() => {
                        setShowMore(false)
                    }}>
                        <CustomIcon width={22} height={12}
                            imgName={'Button/UI_Button_Arrow_Up_01'}
                        />
                    </div>}
                    <div className={s.infoItem}>
                        <div className={`${s.label}`}>{t('Level Required:')}</div>
                        <div className={s.val}>
                            {/* {isNftWork ?

                                <div
                                    className="rarityWrap"
                                    style={{
                                        backgroundColor: `${getRarityBgColor(NFTRarityRequired)}`,
                                        color: `${getRarityFontColor(NFTRarityRequired)}`
                                    }}>
                                    {getRarityText(NFTRarityRequired)}
                                </div> : */}
                                <CustomIcon width={18} height={18} className="mr5"
                                    imgName={'Picture/Rank/RankStar_' + TierRequired}
                                />
                            {/* } */}
                        </div>
                    </div>
                    <div className={s.infoItem}>
                        <div className={s.label}>{t('Mission Duration:')}</div>
                        <div className={s.val}>
                            <CustomIcon width={18} height={18} className="mr5"
                                imgName={'Picture/UI_Picture_Time_01'}
                            />
                            {getTimes(WorkSecond)}
                        </div>
                    </div>
                    <div className={s.infoItem}>
                        <div className={s.label}>{t('Est Yield:')}</div>
                        <div className={s.val}>
                            <CustomIcon width={18} height={18} className="mr5"
                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                            />
                            {convertWalletBalance(workDetail?.profit)}
                            <CustomIcon width={18} height={18} className="ml5"
                                onClick={() => {
                                    workType === 3 ? setShowNftModelYieldIntro(true):
                                    setShowHireModelWorkIntro(true)
                                }}
                                imgName={'Button/UI_Button_QuestionMark_02'}
                            />
                        </div>
                    </div>
                    <div className={s.infoItem}>
                        <div className={s.label}>{t('Energy consumption:')}</div>
                        <div className={s.val}>
                            <CustomIcon width={18} height={18} className="mr5"
                                imgName={'Picture/UI_Picture-Currency_Thunder_01'}
                            />
                            {Energy}
                        </div>
                    </div>
                    {ExtraRewardNum>0 && <div className={s.infoItem}>
                        <div className={s.label}>{t('Special Rewards:')}</div>
                        <div className={s.val}>
                            <CustomIcon width={18} height={18} className="mr5"
                                imgName={'Picture/UI_Picture-Currency_Thunder_01'}
                            />
                            +{ExtraRewardNum}
                        </div>
                    </div>}

                </div>

                <div className={`${s.workLessInfo} ${showMore || !showClubs ? s.hide : ''}`}>

                    <div className={s.infoItem}>
                        {t('Req')}
                        {/* {isNftWork ?
                            <div
                                className="rarityWrap ml5"
                                style={{
                                    backgroundColor: `${getRarityBgColor(NFTRarityRequired)}`,
                                    color: `${getRarityFontColor(NFTRarityRequired)}`
                                }}>
                                {getRarityText(NFTRarityRequired)}
                            </div> : */}
                            <CustomIcon width={18} height={18} className="ml5"
                                imgName={'Picture/Rank/RankStar_' + TierRequired}
                            />
                        {/* } */}


                    </div>
                    <div className={s.infoItem}>
                        <CustomIcon width={18} height={18} className="mr5"
                            imgName={'Picture/UI_Picture_Time_01'}
                        />
                        {getTimes(WorkSecond)}

                    </div>
                    <div className={s.infoItem}>

                        <CustomIcon width={18} height={18} className="mr5"
                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                        />
                        {convertWalletBalance(workDetail?.profit)}

                    </div>
                    <div className={s.infoItem}>
                        <CustomIcon width={18} height={18} className="mr5"
                            imgName={'Picture/UI_Picture-Currency_Thunder_01'}
                        />
                        {Energy>0?'+'+Energy:Energy}
                    </div>
                    <div className={s.downIcon} onClick={() => {
                        setShowMore(true)
                    }}>
                        <CustomIcon width={22} height={12}
                            imgName={'Button/UI_Button_Arrow_Down_01'}
                        />
                    </div>
                </div>
                {
                    showClubs &&
                    <div className={s.clubs}>
                        <div className={s.ctop}>
                            <div className={s.ctHead}>
                                <div className="df alc jcc mt10">{t('Select Clubs to dispatch')}</div>
                                <div className="df alc jcc">
                                    {/* <CustomIcon width={24} height={33} className="mr10"
                                        imgName={'Button/UI_Button_UpAndDown_01'}
                                    />
                                    <CustomIcon width={28} height={23} className="mr10"
                                        imgName={'Button/UI_Button_ThreeTieRod_01'}
                                    /> */}
                                    {/* <AntButton className="btn_white" onClick={() => {
                                        if (buyFirstKey) {
                                            navigate(`${mainClubSweepClubsPath}/0`);
                                        }
                                        else {
                                            navigate(balancePath);
                                        }
                                    }}>
                                        {buyFirstKey?t('GET MORE'):t('BUY NOW')}
                                    </AntButton> */}
                                </div>
                            </div>
                            <div className={s.listWrap}>
                                <div className={s.clubList} id="clubList">
                                    <InfiniteScroll
                                        dataLength={clubList?.length || 0}
                                        next={getMoreClubList}
                                        hasMore={clubListCursor !== -1}
                                        loader={<div
                                            style={{
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        ><Spin /></div>}
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "flex-start",
                                            width: '100%'
                                        }}
                                        endMessage={
                                            <div className={s.noMore} style={{
                                                textAlign: "center",
                                                width: "100%",
                                            }}>
                                                {t('')}
                                            </div>
                                        }
                                        scrollableTarget={'clubList'}
                                    >
                                        {
                                            clubList?.map(item => {
                                                const { bpLevel, energy, profile, tierId, userId, rarity } = item
                                                const { displayName } = profile
                                                const avatarUrl = getAvatarFromProfile(profile)
                                                let canUse = false;
                                                // if(workType === 1){
                                                    canUse = energy + Energy >= 0 && tierId >= TierRequired && bpLevel >= BattlePointLevelRequired
                                                // } 
                                                // if(workType === 3){
                                                //     canUse = energy + Energy >= 0 && NFTRarityRequired <= rarity
                                                // }
                                                return (
                                                    <div className={s.clubItem} key={userId} onClick={(e) => {
                                                        e.stopPropagation()
                                                        canUse && handleCheckboxChange(userId)
                                                    }}>
                                                        <div className={s.clubAvatar}
                                                            style={{ backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)` }}>
                                                        </div>
                                                        <div className={s.clubAvatar}
                                                            style={{ backgroundImage: `url(${avatarUrl})` }}>
                                                        </div>
                                                        <div className={s.clubCard}
                                                            style={{ backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)` }}>
                                                            <div className={s.cardTop}>
                                                                <div className={s.cardName}>
                                                                    {sliceStr(displayFilter(displayName), 6, true)}
                                                                </div>
                                                                <div className={s.cardRank}>
                                                                  
                                                                        <CustomIcon className="" width={14} height={14}
                                                                        imgName={`Picture/Rank/RankStar_${tierId}`} />
                                                                    <CustomIcon className="ml2" width={14} height={14}
                                                                        imgName={`Picture/BPTier/BPTier_${bpLevel || 1}`} />
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className={s.cardBottom}>
                                                                <div className={s.energy}>
                                                                    <CustomIcon className="" width={20} height={20}
                                                                        imgName={`Picture/UI_Picture-Currency_Thunder_01`} />
                                                                    {energy}
                                                                    <span className="color-red ml5">({Energy>0?'+'+Energy:Energy})</span>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        {canUse ?
                                                            <div className={s.checkbox}>
                                                                <Checkbox onClick={() => handleCheckboxChange(userId)}
                                                                    className="sweepCheckBox"
                                                                    checked={selectedUsers.includes(userId)}
                                                                />
                                                            </div> :
                                                            <div className={s.unUse}>
                                                                {
                                                                    tierId < TierRequired ?
                                                                        <div>
                                                                            {t('Tier Required')}:
                                                                            <CustomIcon className="" width={30}
                                                                                height={30}
                                                                                imgName={`Picture/Rank/RankStar_${TierRequired}`} />
                                                                        </div>
                                                                        :
                                                                        bpLevel < BattlePointLevelRequired ?
                                                                            <div>
                                                                                {t('BP Required')}:
                                                                                <CustomIcon className="" width={30}
                                                                                    height={30}
                                                                                    imgName={`Picture/BPTier/BPTier_${BattlePointLevelRequired || 1}`} />
                                                                            </div> :
                                                                            energy + Energy < 0 &&
                                                                            <div>
                                                                                {t('INSUFFICIENT ENERGY')}
                                                                            </div>
                                                                }

                                                            </div>
                                                        }


                                                    </div>
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                                </div>
                            </div>

                            <div className={s.ctbottom}>
                                <Spin spinning={loading}>
                                    <div className={s.cbI}>
                                        <div className={s.label}>{t('My Expected Earnings:')}</div>
                                        <div className={s.val}>
                                            <CustomIcon width={18} height={18} className="mr5"
                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                            />
                                            {convertWalletBalance(previewsInfo?.totalSelfProfit)}
                                           {workType !== 3 && <CustomIcon width={18} height={18} className="ml5"
                                                onClick={() => {
                                                    setShowEarnIntro(true)
                                                }}
                                                imgName={'Button/UI_Button_QuestionMark_02'}
                                            />} 
                                        </div>
                                    </div>
                                    {ExtraRewardType > 0 &&
                                        <div className={s.cbI}>
                                            <div className={s.label}>{t('Special Rewards：')}</div>
                                            <div className={s.val}>
                                                <CustomIcon width={18} height={18} className="mr5"
                                                    imgName={RewardTypeShow.find(item => item?.id === ExtraRewardType)?.rewardIconPath} />
                                                {ExtraRewardNum * selectedUsers?.length}

                                            </div>
                                        </div>
                                    }

                                </Spin>
                            </div>
                            <ButtonFactory disabled={selectedUsers?.length === 0} className='modelBottomBtn' size={21}
                                scale={1.5} onClick={dispatchFn}>
                                {t('DISPATCH')}
                            </ButtonFactory>
                        </div>
                        <div className={s.cbottom}></div>

                    </div>
                }
                {
                    !showClubs && <div className={s.dispatchedInfo}>
                        <div className={s.dispatchHead}>
                            <div className="color-blue-light">{t('Dispatched clubs')} ({totalWorkList?.length}/{UserNumLimit})</div>
                            <div>
                                <AntButton className={`btn_white ${UserNumLimit <= totalWorkList?.length ? 'btn_disabled' : ''}`}
                                    disabled={UserNumLimit <= totalWorkList?.length}
                                    onClick={() => {
                                        setShowClubs(true)
                                    }}>
                                    {t('DISPATCH')}
                                </AntButton>
                            </div>
                        </div>
                        <div className={s.dispatchedList}>
                            {totalWorkList?.map(item => {
                                const { userId, tierId, bpLevel, profile, workLeftSec, toClaimLfg } = item
                                const { displayName } = profile
                                const avatarUrl = getAvatarFromProfile(profile)
                                return (
                                    <div className={s.dispatchedItem} key={userId}>
                                        <div className={s.clubAvatar}
                                            style={{ backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)` }}>
                                        </div>
                                        <div className={s.clubAvatar} style={{ backgroundImage: `url(${avatarUrl})` }}>
                                        </div>
                                        <div className={s.clubCard}
                                            style={{ backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)` }}>
                                            <div className={s.cardTop}>
                                                <div className={s.cardName}>
                                                    {sliceStr(displayFilter(displayName), 6, true)}
                                                </div>
                                                <div className={s.cardRank}>
                                                    <CustomIcon className="" width={14} height={14}
                                                        imgName={`Picture/Rank/RankStar_${tierId}`} />
                                                    <CustomIcon className="ml2" width={14} height={14}
                                                        imgName={`Picture/BPTier/BPTier_${bpLevel || 1}`} />
                                                </div>
                                            </div>
                                            <div className={s.cardBottom}>
                                                {
                                                    workLeftSec ? <div className={s.leftSecWrap}>
                                                        <div className={s.alreay}
                                                            style={{ width: getAlreayPersent(workLeftSec) + `%` }}></div>
                                                        <div className={s.leftSecond}>
                                                            <Countdown
                                                                refresh={() => {
                                                                    getWorkDetailFn()
                                                                }}
                                                                leftSecond={workLeftSec} />
                                                        </div>
                                                    </div> : <div className={s.claimBtn} onClick={() => {
                                                        claimClick(userId)
                                                    }}>
                                                        <div className="fs12 color-black">{t('CLAIM')}</div>
                                                        <div className="df alc jcc fs12 color-black">
                                                            <CustomIcon width={14} height={14} className="mr5"
                                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                            />
                                                            {convertWalletBalance(toClaimLfg)}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                }
            </div>

            {showWorkResult &&
                <HireWorkResult selfUserId={userInfo?.userId} info={workResult} closeFn={() => {
                    setShowWorkResult(false)
                }} />
            }
            <AntModal
                width='330px'
                title={t('EARNINGS DETAIL')}
                className="confirmModalWrap p0"
                centered={true}
                open={showEarnIntro}
                destroyOnClose={true}
                onOk={() => setShowEarnIntro(false)}
                onCancel={() => setShowEarnIntro(false)}
                zIndex={4000}
            >
                <HireModelDispatchIntro
                    closeHandle={() => {
                        setShowEarnIntro(false)
                    }}
                    info={{
                        totalWorkProfit: previewsInfo?.totalWorkProfit,
                        totalMgrWorkProfit: previewsInfo?.totalMgrWorkProfit,
                        totalEmployeeProfit: previewsInfo?.totalEmployeeProfit,
                        totalKeyShareProfit: previewsInfo?.totalKeyShareProfit,
                        totalSelfProfit: previewsInfo?.totalSelfProfit,
                    }}
                />
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap p0 noImageTitle"
                centered={true}
                open={showHireModelWorkIntro}
                destroyOnClose={true}
                onOk={() => setShowHireModelWorkIntro(false)}
                onCancel={() => setShowHireModelWorkIntro(false)}
                zIndex={4000}
            >

                <div className={s.head}>
                    {t("$LFG production decreases into half half each time the emission milestone is reached.")}
                </div>
                <HireModelWorkIntro
                    rankInfo={rankInfo?.coin}
                />
                <div className={s.head}>
                    {t("250m supply has been added for the second phase. The changes brought about by the second phase have altered the decay phase intervals, resulting in an increase in production efficiency.")}
                </div>
                <HireModelWorkIntro
                    rankInfo={rankInfo?.user}
                />
                {/* <div
                    className={`df alc jcc mb20`}>
                        {t('Est Yield:')} {`${workDetail?.curUserNumRatio} * ${workDetail?.accuCoinRatio} * ${Profit * (WorkSecond / 3600)}  = ${workDetail?.profit}`}
                </div> */}
            </AntModal>

            <AntModal
                width='330px'
                title={t('EARNINGS DETAIL')}
                className="confirmModalWrap p0 noImageTitle"
                centered={true}
                open={showNftModelYieldIntro}
                destroyOnClose={true}
                onOk={() => setShowNftModelYieldIntro(false)}
                onCancel={() => setShowNftModelYieldIntro(false)}
                zIndex={4000}
            >
                <NftModelYieldIntro rankInfo={rankInfo?.nft} NFTCapacity={NFTCapacity} nftSumWeight={workDetail?.nftSumWeight} profit={workDetail?.profit} />
            </AntModal>
        </div>
    );
};
const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default memo(
    connect(mapStateToProps, mapDispatchToProps)(Dispatch)
);
