import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import Banner from "../../../common/Banner";
import {
    stringToNumber,
    convertWalletBalance,
    sliceStr,
    getAvatarFromProfile,
    getTokenBalanceOf,
    copyFn,
    sliceAddr,
    post_live_announce_query,
    post_live_announce_del,
    easeJoinGroup,
} from "@/utils/common"
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntButton from "@/common/AntButton";
import AvatarInfo from "@/common/AvatarInfo";
import { Tabs, Modal, message } from "antd";


// Chat && Live code 
import { leave, rtc, checkCamera, checkMicrophones } from "@/utils/basicVoiceCall";
import MomentChat from "@/common/MomentChat";
import Nosleep from "nosleep.js";
import ModelPermission from "@/model/ModelPermission";
import { gameMemeClubPath } from "@/routes/config";
import KChart from "../KChart";
import TradeKeyPanel from "../ClubDetail/Club/TradeKeyPanel";
import MomentLivePanel from "@/common/MomentLivePanel";
import Charts from "../ClubDetail/TGE/Trade/Charts";

const Chat = (
    {
        userInfo,
        walletBalanceList,
        groupInfo,
        chainInfo,
        setGroupInfo,
        setWalletBalanceList,
        showTransModelFn,
        clubBasicInfo,
        sourceData,
        clubId,
        nativeChain,
        coinChain,
        showChartDetail,
        setShowChartDetail,
        upDate,
        openLivePanel = false,
        setOpenLivePanel = () => { },
        pauseVideo = () => { },
        openBuyDrawer = () => {},
    }
) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()
    const { userId } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const [showLiveTip, setShowLiveTip] = useState(true)
    const { 
        clubInfo, 
        holdNum, 
        inMarket, 
        creatorProfile, 
        creatorUserId, 
        coinTokenAddr, 
        onAlive, 
        isPrive = false, 
        buyPrice,
        sellPrice, 
        historyPrice, 
    } = useMemo(() => {
        return clubBasicInfo || {}
        // return { ...clubBasicInfo, inMarket: true } || {}

    }, [clubBasicInfo])

    const range = historyPrice ? Math.round((sellPrice - historyPrice) / historyPrice * 100) : 0;


    const { clubName, coverUrl, coinId, symbolName } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    // Chat && Live code
    const [groupId, setGroupId] = useState('')
    const isSelfChat = useMemo(() => {
        return userId === Number(creatorUserId)
    }, [userId, creatorUserId])
    const nosleep = new Nosleep();
    useEffect(() => {
        if (onAlive) {
            setShowLivePanel(false);
        }

        if (!onAlive && rtc.joinState) {
            // backFn()
            rtc.joinState = false
        }
        clubId && onAlive && !isSelfChat && setShowLivePanel(true);




    }, [onAlive, isSelfChat, clubId])

    useEffect(() => {
        if (openLivePanel && onAlive) {
            setShowLivePanel(true)
            setOpenLivePanel(false)
        }
    }, [openLivePanel, onAlive])

    useEffect(() => {
        if (clubId) {
            if ((!groupInfo || !groupInfo[clubId] || !groupInfo[clubId]?.group_id || groupInfo[clubId]?.expireTimes < new Date().getTime())) {
                console.log(`start call easeJoinGroupFn`);
                easeJoinGroupFn()
            } else {
                const obj = {};
                const i = groupInfo[clubId];
                obj[clubId + ''] = {
                    ...i,
                    expireTimes: new Date().getTime() + 25 * 60 * 1000
                }
                setGroupInfo(obj)
                setGroupId(groupInfo[clubId]?.group_id)
            }

        }
    }, [clubId])

    const startLive = async () => {
        pauseVideo();
        const hasCamera = await checkCamera();
        console.log(hasCamera);
        if (!hasCamera) {
            setShowPermission(true);
            setShowPermissionType(t('Camera'));
            return
        }
        const hasMicrophone = await checkMicrophones();
        console.log(hasCamera);
        if (!hasMicrophone) {
            setShowPermission(true);
            setShowPermissionType(t('Microphone'));
            return
        }
        if (hasCamera && hasMicrophone) {
            console.log("66666");
            // delete announce time
            post_live_announce_del({
                keyId: parseInt(clubId),
                groupType: 2
            }).then(data => {
                console.log(data);
            }).catch(e => {
                console.log(e);
            });

            setShowLivePanel(true)
        }
    }
    const [showPermission, setShowPermission] = useState(false);
    const [showPermissionType, setShowPermissionType] = useState('');
    const hasOneKey = useMemo(() => {
        if (holdNum) {
            return true
        }
        return false
    }, [holdNum])



    const [pageLoading, setPageLoading] = useState(true);
    const [nextLiveTime, setNextLiveTime] = useState(0);
    const [openTranPanel, setOpenTranPanel] = useState(false);
    const [showLivePanel, setShowLivePanel] = useState(false);

    const [title, setTitle] = useState('');
    useEffect(
        () => {
            clubId && post_live_announce_query({
                keyId: parseInt(clubId),
            }).then(data => {
                setNextLiveTime(data?.startTime || 0);
                setTitle(data?.title);
            }).catch(e => {
                console.log(e);
            });
        }, [clubId]
    )

    useEffect(() => {
        if (groupId) {
            setPageLoading(false)
        }
    }, [groupId])

    const backFn = async () => {
        if (showLivePanel) {
            if (!isSelfChat || !rtc.joinState) {
                await leave();
                setShowLivePanel(false);
            } else {

                Modal.confirm({
                    title: t('End This Live Stream'),
                    content: t('Are you sure you want to end this live stream now?'),
                    okText: t('Confirm'),
                    cancelText: t('Cancel'),
                    className: 'liveModal',
                    onOk: () => {
                        leave();
                        rtc.joinState = false;
                        nosleep.disable();
                        setShowLivePanel(false)
                    }
                });
            }

        } else {
            if (location.key !== "default") {
                navigate(-1);
            } else {
                navigate(gameMemeClubPath, { replace: true });
            }
        }

    }

    const [isLoadingEaseJoinGroup, setIsLoadingEaseJoinGroup] = useState(false);
    const [timerTrigger, setTimerTrigger] = useState(0);
    useEffect(() => {
        timerTrigger && easeJoinGroupFn();
    }, [timerTrigger])

    const easeJoinGroupFn = () => {

        if (!isLoadingEaseJoinGroup && clubId != null) {
            setIsLoadingEaseJoinGroup(true);
            easeJoinGroup(clubId, 1).then(res => {
                setIsLoadingEaseJoinGroup(false);
                const obj = {};
                obj[clubId + ''] = {
                    group_id: res?.groupId,
                    msg_types: ['txt'],
                    expireTimes: new Date().getTime() + 25 * 60 * 1000
                }
                setGroupInfo(obj)
                setGroupId(res?.groupId)
            }).catch(e => {
                setPageLoading(false);
                message.error({ content: `Join Group Failed` })
                setTimeout(() => {
                    setIsLoadingEaseJoinGroup(false);
                    setTimerTrigger(timerTrigger + 1);
                }, 5000);
            })
        }

    }



    return (
        <div className={`${s.modalWrap}`}>
            <div className={`${s.chartWrap} ${showChartDetail ? '' : s.chartHide}`}>
                <div className={`${s.chartTitle} momentFont`} onClick={() => { setShowChartDetail(!showChartDetail) }}>
                    {showChartDetail ? t('Hide Chart') 
                    : 
                    <div className={`momentFont fs12 flex_center_center`}>
                        {`$${clubName} moment`}
                        <CustomIcon imgName={`Picture/${chainInfo?.icon}`} className="ml5 mr5" width={17} height={17}/>
                        {convertWalletBalance(buyPrice)}
                    </div>
                }
                    <CustomIcon className={s.chartIcon} onClick={() => { setShowChartDetail(!showChartDetail) }} imgName={showChartDetail ? 'Button/UI_Button_Arrow_Up_02' : 'Button/UI_Button_Arrow_Down_02'} width={19} height={10}></CustomIcon>
                </div>
                {showChartDetail &&
                    <div className={s.kChartWrap}>
                        {!inMarket ?
                            <KChart sourceData={sourceData} coinName={chainInfo?.displayCoinName} clubId={clubId} symbolName={clubName}></KChart> :
                            <Charts coinChain={coinChain} nativeChain={nativeChain} ></Charts>
                        }
                    </div>
                }
            </div>



            <div className={s.modal}>


                <div className={s.content}>
                    <MomentChat
                        keyId={userId}
                        // hasOneKey={hasOneKey}
                        // isSelfChat={isSelfChat}
                        isSelfChat={isSelfChat}
                        hasOneKey={hasOneKey}
                        groupId={groupId}
                        setPageLoading={setPageLoading}
                        startLive={startLive}
                        setNextLiveTime={setNextLiveTime}
                        setTitle={setTitle}
                        managerId={''}
                        pageLoading={pageLoading}
                        nextLiveTime={nextLiveTime}
                        nextLiveTitle={title}
                        setGroupId={setGroupId}
                        clubId={clubId}
                        inMarket={inMarket}
                        chainInfo={chainInfo}
                    />
                </div>
            </div>

            {showLivePanel && groupId &&
                <MomentLivePanel
                    isSelfChat={isSelfChat}
                    avatarurl={coverUrl}
                    setOpenTranPanel={setOpenTranPanel}
                    backFn={backFn}
                    hasOneKey={hasOneKey}
                    selfUid={userId}
                    keyId={userId}
                    groupId={groupId}
                    groupType={2}
                    nosleep={nosleep}
                    profile={{
                        displayName: clubName,
                        avatarUrl: getAvatarFromProfile(creatorProfile)
                    }}
                    setShowOtherTransContent={() => { }}
                    showLivePanel={showLivePanel}
                    setNeedUpdateVoteInfo={() => { }}
                    showTransModelFn={showTransModelFn}
                    inMarket={inMarket}
                    holdNum={holdNum}
                    clubInfo={clubInfo}
                    creatorUserId={creatorUserId}
                    upDate={upDate}
                    clubBasicInfo={clubBasicInfo}
                    setGroupId={setGroupId}
                    clubId={clubId}
                    chainInfo={chainInfo}
                    sourceData={sourceData}
                />
            }
            
            {
                !inMarket && 
                <>
                    <div className={`${s.line}`}>
                    </div>
                    <TradeKeyPanel 
                        upDate={upDate} 
                        clubBasicInfo={clubBasicInfo} 
                        hasBorder={false}
                        openBuyDrawer={openBuyDrawer}>
                    </TradeKeyPanel>
                </>
            }

            {onAlive && showLiveTip &&

                <div className={`${s.liveBlock} `} onClick={() => {
                    onAlive && setShowLivePanel(true)
                }}>
                    <div className={`${s.bg}`} style={{
                        backgroundImage: `url(${coverUrl})`
                    }}></div>
                    {(!hasOneKey && isPrive) && <div className={s.liveBlockCon}>
                        <CustomIcon imgName="Picture/UI_Picture_Icon_Lock_01" width={26} height={26}></CustomIcon>
                        <div className="fs16">{t('Buy Packs To Access')}</div>
                    </div>}
                    <div className={s.closeBtn} onClick={() => {
                        setShowLiveTip(false)
                    }}>
                        <CustomIcon imgName="Button/UI_Button_Close_01" width={16} height={16}></CustomIcon>
                    </div>
                    <div className={s.liveBtn} onClick={() => {
                        onAlive && setShowLivePanel(true)
                    }}>
                        <CustomIcon imgName={`Picture/UI_Picture_Icon_Live_01`} className="mr5" width={18} height={18}></CustomIcon>
                        <span className={`fs14 fb`}>
                            {t('Live')}
                        </span>
                    </div>
                </div>
            }

            <AntModal
                width='330px'
                title=''
                className="momentModalWrap"
                centered={true}
                open={showPermission}
                destroyOnClose={true}
                onOk={() => setShowPermission(false)}
                onCancel={() => setShowPermission(false)}
            >
                <ModelPermission 
                    type={showPermissionType} 
                    openType={2}
                    closeHandle={() => {
                        setShowPermission(false);
                    }}/>
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        walletBalanceList: app.walletBalanceList,
        groupInfo: app.groupInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setGroupInfo: (params) => {
            return dispatch({
                type: "app/setGroupInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Chat));
