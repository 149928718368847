import React, {memo, useEffect, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../../../common/CustomIcon";
import {connect} from "react-redux";
import {Input, Button, Modal, Spin, Drawer, Slider} from 'antd';
import {
    stringToNumber,
    defaultAvatar,
    convertWalletBalance,
    TransactionError,
} from "@/utils/common"

import {useLocation, useNavigate} from "react-router-dom";
import Countdown from "../../../../common/Countdown";
import BidAuctionRules from "../../../../model/BidAuctionRules";
import {
    getnft_auction,
    nftDetail,
    coinObj,
    getRarityText,
    getRarityBgColor,
    getRarityFontColor,
    getRarityImageBg,
    getRarityRankIcon,
    delay,
    getBidStatus
} from "@/utils/bidNft";
import InfiniteScroll from "react-infinite-scroll-component";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {bid} from "@/utils/bidNftWeb3";
import BidFilter from "src/drawer/BidFilter";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import ModalInsufficientCrpto from "src/model/ModalInsufficientCrpto";
import {
    mainNftBidSweepPath
} from "@/routes/config";
import AuctionSlippage from "@/utils/json/AuctionSlippage.json";
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";
import AntButton from "@/common/AntButton";

const BidAuction = (
    {
        userInfo,
        setShowLink,
        tweetMaticBindResult,
        currentAuctionBatchCfg,
        bidSlippage,
        setFilterBidNftSingle,
        filterBidNftSingle,
        setBidSlippage,
    }
) => {
    const {t, i18n} = useTranslation();
    // const [linkUrl, setLinkUrl] = useState('');
    const navigate = useNavigate();
    const [showIntro, setShowIntro] = useState(false)
    const [list, setList] = useState([])
    const {wallets} = useWallets();
    const [nextCursor, setNextCursor] = useState(0);
    const [loading, setLoading] = useState(false);
    const [upData, setUpdata] = useState(null)
    const [showBidFilter, setShowBidFilter] = useState(false);
    const [filterRank, setFilterRank] = useState(filterBidNftSingle?.rankRange);
    const [filterMaticPrice, setFilterMaticPrice] = useState(filterBidNftSingle?.maticPriceRange);
    const [filterLFGPrice, setFilterLFGPrice] = useState(filterBidNftSingle?.lfgPriceRange);
    const [bidLoading, setBidLoading] = useState(false);
    const [bidLoadingId, setBidLoadingId] = useState('');
    const [genesisAuctionState, setGenesisAuctionState] = useState(1);//1:live,2:ended
    const [filterIncludeTopPriceIsSelf, setFilterIncludeTopPriceIsSelf] = useState(filterBidNftSingle?.includeTopPriceIsSelf)
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const [insufficientAmount, setInsufficientAmount] = useState(0);
    const [rangeRise, setRangeRise] = useState(filterBidNftSingle?.isAsc);
    const [showBidSplippageDetail, setShowBidSplippageDetail] = useState(false);
    const [slippageSliderIndex, setSlippageSliderIndex] = useState(0);

    // const list = useRef(null)
    const {userId, selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo])
    const {balance, lfgBalance} = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const getList = (searchStatus, rankRange, maticPriceRange, lfgPriceRange, includeTopPriceIsSelf, rangeRise, cursor, oriList = []) => {
        if (list.length > 0) setLoading(true)
        getnft_auction(
            searchStatus || 1, //searchStatus
            rankRange || [1, -1], //rankRange
            maticPriceRange || ["0", "-1"], //maticPriceRange
            lfgPriceRange || ["0", "-1"], //lfgPriceRange
            includeTopPriceIsSelf,
            rangeRise,
            cursor || 0  //cursor
        ).then(res => {
            const {auctionList, nextCursor} = res;
            if (auctionList?.length > 0) {
                setList([...oriList, ...auctionList]);
            }
            setNextCursor(nextCursor);
            // list.current = auctionList || []
            setLoading(false)
        }).catch(e => {
            setLoading(false)
            console.error(e);
        })
    }

    const getFilterParam = () => {
        let rankRange = [1, -1];
        if (filterRank?.[0] !== null) {
            rankRange[0] = Math.max(1, filterRank?.[0]);
        }
        if (filterRank?.[1] !== null) {
            rankRange[1] = filterRank?.[1];
        }

        let maticPriceRange = ["0", "-1"];
        if (filterMaticPrice?.[0] !== null) {
            maticPriceRange[0] = filterMaticPrice?.[0].toString();
        }
        if (filterMaticPrice?.[1] !== null) {
            maticPriceRange[1] = filterMaticPrice?.[1].toString();
        }

        let lfgPriceRange = ["0", "-1"];
        if (filterLFGPrice?.[0] !== null) {
            lfgPriceRange[0] = filterLFGPrice?.[0].toString();
        }
        if (filterLFGPrice?.[1] !== null) {
            lfgPriceRange[1] = filterLFGPrice?.[1].toString();
        }
        let includeTopPriceIsSelf = filterIncludeTopPriceIsSelf;
        return {rankRange, maticPriceRange, lfgPriceRange, includeTopPriceIsSelf};
    }

    const resetFilter = () => {
        setFilterRank([]);
        setFilterMaticPrice([]);
        setFilterLFGPrice([]);
        setNextCursor(0);
    }

    const isAuctionStart = () => {
        if (currentAuctionBatchCfg?.endTime !== undefined && currentAuctionBatchCfg?.endTime !== null) {
            let nowTime = new Date().getTime() / 1000;
            return currentAuctionBatchCfg?.endTime <= nowTime;
        }
        return false;
    }

    // useEffect(() => {
    //     // resetFilter();
    //     setList([]);
    //     getList(genesisAuctionState, [1, -1], ["0", "-1"], ["0", "-1"], true, rangeRise, 0);
    // }, [genesisAuctionState])

    useEffect(() => {
        setList([]);
        let param = getFilterParam();
        setNextCursor(0);
        getList(genesisAuctionState, param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, rangeRise, 0);
    }, [rangeRise])

    const getMore = () => {
        let param = getFilterParam();
        getList(genesisAuctionState, param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, rangeRise, nextCursor, list);
    }

    const applyFilter = (filterRank, filterLFGPrice = null, filterMaticPrice = null, includeTopPriceIsSelf = false) => {
        console.log(filterRank, filterLFGPrice, filterMaticPrice);
        let rankRange = [1, -1];
        if (filterRank?.[0] != null) {
            rankRange[0] = Math.max(1, filterRank?.[0]);
        }
        if (filterRank?.[1] != null) {
            rankRange[1] = filterRank?.[1];
        }

        let maticPriceRange = ["0", "-1"];
        if (filterMaticPrice?.[0] != null) {
            maticPriceRange[0] = filterMaticPrice?.[0].toString();
        }
        if (filterMaticPrice?.[1] != null) {
            maticPriceRange[1] = filterMaticPrice?.[1].toString();
        }

        let lfgPriceRange = ["0", "-1"];
        if (filterLFGPrice?.[0] != null) {
            lfgPriceRange[0] = filterLFGPrice?.[0].toString();
        }
        if (filterLFGPrice?.[1] != null) {
            lfgPriceRange[1] = filterLFGPrice?.[1].toString();
        }
        setList([]);
        setFilterLocalData({
            rankRange,
            maticPriceRange,
            lfgPriceRange,
            includeTopPriceIsSelf,
            isAsc: rangeRise
        })
        getList(genesisAuctionState, rankRange, maticPriceRange, lfgPriceRange, includeTopPriceIsSelf, rangeRise, 0);
        setShowBidFilter(false);
    }

    const setListFn = (obj, ind, delayHide = false) => {

        let _l = list;
        _l[ind] = {
            ...list[ind],
            ...obj
        }
        setList(_l)
        setUpdata(new Date())
        if (delayHide) {
            setTimeout(() => {
                _l[ind] = {
                    ...list[ind],
                    bidState: {}
                }
                setList(_l)
                setUpdata(new Date())
                let param = getFilterParam();
                setNextCursor(0)
                getList(genesisAuctionState, param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, rangeRise, 0);
            }, 5000)
        }
    }


    const checkResult = (txHash) => {
        return new Promise((resolve, reject) => {
            getBidStatus(txHash).then(async res => {
                console.log(txHash, res);
                const {result} = res
                if (result?.length === 0) {
                    await delay(2000)
                    checkResult(txHash)
                } else {
                    resolve(result)
                }

            }).catch(e => {
                reject(e)
            })
        })

    }
    const bidNft = (item, ind) => {
        const {coinType, nftSimpleData, biddingInfo} = item || {};
        const {curPriceUserId, endTime, nextPrice} = biddingInfo || {};
        const {tokenAddr, tokenId} = nftSimpleData || {};

        let expectedPrice = (Number(nextPrice) || 0) + 0.0001;
        console.log("expectedPrice", expectedPrice);

        if (coinType === 1 && Number(nextPrice) > Number(balance)) {
            setInsufficientAmount(Number((stringToNumber(nextPrice, 2) - Number(balance)).toFixed(2)));
            setShowInsufficientCrpto(true);
            return;
        } else if (coinType === 0 && Number(nextPrice) > Number(lfgBalance)) {
            setInsufficientAmount(Number((stringToNumber(nextPrice, 2) - Number(lfgBalance)).toFixed(2)));
            setShowSwapLfg(true);
            return;
        }

        if (endTime * 1000 <= new Date().getTime()) {
            let param = getFilterParam();
            setNextCursor(0)
            getList(genesisAuctionState, param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, rangeRise, 0);
            return
        }
        let obj = {};
        obj['bidState'] = {
            isLoading: true,
            hasResult: false,
            isSucc: false
        }
        setListFn(obj, ind)

        // if (subLoading) return
        // setSubLoading(true)
        setBidLoading(true);
        setBidLoadingId(tokenId);

        bid(wallets, tokenAddr, tokenId, expectedPrice, Number(bidSlippage), Number(balance), coinType).then(async res => {

            const resultArr = await checkResult(res?.hash);
            console.log(resultArr);
            if (resultArr[0]?.bidSucc) {
                obj['bidState'] = {
                    isLoading: false,
                    hasResult: true,
                    isSucc: true,
                    failedText: t('Transaction Failed')
                }
                obj['biddingInfo'] = {
                    ...biddingInfo,
                    curPriceUserId: userId
                }
            } else {
                obj['bidState'] = {
                    isLoading: false,
                    hasResult: true,
                    isSucc: false,
                    failedText: t(`BidFailedReason_${resultArr[0]?.errCode}`)
                }
                obj['biddingInfo'] = {
                    ...biddingInfo
                }
            }

            setBidLoading(false);
            setBidLoadingId('');
            setListFn(obj, ind, true)

        }).catch(e => {
            let failedText = t('Transaction Failed');
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        failedText = t(`TransactionError_${transactionErrorElement}`);
                        break;
                    }
                }
            }
            obj['bidState'] = {
                isLoading: false,
                hasResult: true,
                isSucc: false,
                failedText: failedText
            }
            setBidLoading(false);
            setBidLoadingId('');
            setListFn(obj, ind, true)
        })
    }

    const isAvailable = (coinType, nextPrice) => {
        if (balance && Number(balance) < 0.1) return false
        return Number(nextPrice) < (coinType === 0 ? Number(lfgBalance) : Number(balance))
    }

    const getWinnerLabel = (winnerInfo) => {
        if (winnerInfo?.winnerName) {
            return winnerInfo?.winnerName;
        } else if (winnerInfo?.winnerWallet) {
            let walletStr = winnerInfo?.winnerWallet;
            if (walletStr.length < 11) {
                return walletStr;
            } else {
                return `${walletStr.substring(0, 6)}...${walletStr.substring(walletStr - 5)}`;
            }
        } else {
            return t('None');
        }
    }

    const getSlippageCount = () => {
        if (slippageSliderIndex < 0) {
            return 0;
        } else if (slippageSliderIndex >= AuctionSlippage?.length) {
            return AuctionSlippage?.[AuctionSlippage?.length - 1]?.slippageCount || 0;
        } else {
            return AuctionSlippage?.[slippageSliderIndex]?.slippageCount || 0;
        }
    }

    const onSlippageSliderChange = (newValue) => {
        let newBidSlippage = 0;
        if (newValue < 0) {
            newBidSlippage = 0;
        } else if (newValue >= AuctionSlippage?.length) {
            newBidSlippage = AuctionSlippage?.[AuctionSlippage?.length - 1]?.slippageNum || 0;
        } else {
            newBidSlippage = AuctionSlippage?.[newValue]?.slippageNum || 0;
        }
        setBidSlippage(newBidSlippage);
        setSlippageSliderIndex(newValue);
    };

    useEffect(() => {
        let timer = null;
        if (currentAuctionBatchCfg?.auctionBatchType === 1) {
            timer = setInterval(() => {
                getList(genesisAuctionState, [1, -1], ["0", "-1"], ["0", "-1"], true, rangeRise, 0)
            }, 10000)
        }
        return () => {
            timer && clearInterval(timer)
        }
    }, [currentAuctionBatchCfg, genesisAuctionState, rangeRise])

    useEffect(() => {
        if (AuctionSlippage) {
            let haveValue = false;
            for (let i = 0; i < AuctionSlippage?.length; i++) {
                if (AuctionSlippage[i].slippageNum === bidSlippage) {
                    haveValue = true;
                    setSlippageSliderIndex(i);
                }
            }
            if (!haveValue) {
                setBidSlippage(0);
                setSlippageSliderIndex(0);
            }
        }
    }, [])


    const setFilterLocalData = (obj) => {
        console.log(obj);
        setFilterBidNftSingle({
            ...filterBidNftSingle,
            ...obj,
        })
    }

    return (
        <>
            <div className={`${s.modalWrap} publicBg`}>
                <div className={s.block}>
                    <div className={s.head}>
                        <div className={s.headLeft}>
                            <div className={s.bidAuctionSegmented}>
                                <div
                                    className={`${genesisAuctionState === 1 ? s.bidAuctionStateSelected : s.bidAuctionStateUnselect} fb`}
                                    onClick={() => {
                                        if (genesisAuctionState !== 1) {
                                            setGenesisAuctionState(1);
                                            let param = getFilterParam();
                                            setNextCursor(0);
                                            setList([]);
                                            getList(1, param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, rangeRise, 0);
                                        }
                                    }}>
                                    {t(' Live ')}
                                </div>
                                <div
                                    className={`${genesisAuctionState === 2 ? s.bidAuctionStateSelected : s.bidAuctionStateUnselect} fb`}
                                    onClick={() => {
                                        if (genesisAuctionState !== 2) {
                                            setGenesisAuctionState(2);
                                            let param = getFilterParam();
                                            setNextCursor(0);
                                            setList([]);
                                            getList(2, param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, rangeRise, 0);
                                        }
                                    }}>
                                    {t('Ended')}
                                </div>
                            </div>
                            <div className="df alc jcc">
                                <CustomIcon width={26} height={26} className="" onClick={() => {
                                    setShowIntro(true)
                                }}
                                            imgName={'Button/UI_Button_QuestionMark_01'}/>
                            </div>
                        </div>
                        <div className={s.headRight}>
                            {currentAuctionBatchCfg?.auctionBatchType === 2 && <>
                                <CustomIcon className="iconShadow ml15" onClick={() => setShowBidFilter(true)}
                                            width={40}
                                            height={40} imgName={'Button/UI_Button_ThreeTieRod_01'}/>
                                <div className={s.rangeRoot} onClick={() => {
                                    setRangeRise(!rangeRise);
                                    setFilterLocalData({
                                        isAsc: !rangeRise
                                    })
                                }}>
                                    {rangeRise && <CustomIcon width={34} height={30} className="iconShadow"
                                                              imgName={'Button/UI_Button_NFT-Rank_Down_01'}/>}
                                    {!rangeRise && <CustomIcon width={34} height={30} className="iconShadow"
                                                               imgName={'Button/UI_Button_NFT-Rank_Up_01'}/>}
                                </div>
                                <AntButton className={`${s.sweepBtn} btn_public`} onClick={() => {
                                    navigate(mainNftBidSweepPath)
                                }}>
                                    <div style={{fontSize: `16px`, color: `rgba(0, 0, 0, 1)`, fontWeight: 'bold'}}>
                                        {t('Sweep')}
                                    </div>
                                </AntButton>
                            </>}
                        </div>

                    </div>
                    <div className={s.slippageRoot}>
                        <div className={s.showDetailBtn} onClick={() => {
                            setShowBidSplippageDetail(!showBidSplippageDetail)
                        }}>
                            {
                                showBidSplippageDetail ?
                                    <CustomIcon width={18} height={18} className=""
                                                imgName={'Button/UI_Button_Triangle-Yellow_Up_01'}/> :
                                    <CustomIcon width={18} height={18} className=""
                                                imgName={'Button/UI_Button_Triangle-Yellow_Down_01'}/>
                            }
                        </div>
                        <div className={`${s.slippageText} ${!showBidSplippageDetail && 'mb5'}`}>
                            <div className={s.slippageTextSmall}>
                                {t('Bid\'s Price Increase Count Limit')}:
                            </div>
                            <div className={s.slippageTextBig}>
                                {getSlippageCount()}
                            </div>
                            <div className={s.slippageTextSmall}>
                                {t('Bids')}
                            </div>
                        </div>
                        {
                            showBidSplippageDetail && <>
                                <div className={s.slippageText}>
                                    <div className={s.slippageTextSmall2}>
                                        {t('SlippageTips')}
                                    </div>
                                </div>
                                <div className={s.slippageSliderRoot}>
                                    <div className={s.slippageSlider}>
                                        <Slider
                                            className="commonSlider"
                                            min={0}
                                            max={AuctionSlippage?.length ? AuctionSlippage?.length - 1 : 0}
                                            value={slippageSliderIndex}
                                            step={1}
                                            onChange={onSlippageSliderChange}
                                            tooltip={{
                                                formatter: null,
                                            }}
                                        />
                                    </div>
                                    <div className={s.slippageSliderText}>
                                        {`(${t('Max Slippage')} `}
                                        <div className="fs12" style={{
                                            color: '#ffda0a',
                                            width: '40px'
                                        }}>{`${bidSlippage ? bidSlippage : 0}%`}</div>
                                        {`)`}
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                    {/* {!loading ? */}
                    {
                        isAuctionStart() ?
                            <div className={s.bidList} id="acutionList">
                                <InfiniteScroll
                                    // onScroll={onScroll}
                                    // initialScrollY={rankList[type]?.scrollTop || 0}
                                    dataLength={list?.length || 0}
                                    next={getMore}
                                    hasMore={nextCursor !== -1}
                                    loader={<div
                                        style={{
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    ><Spin/></div>}
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                        width: '100%'
                                    }}
                                    endMessage={
                                        <div style={{
                                            textAlign: "center",
                                            width: "100%",
                                            color: "var(--color-gray)",
                                            fontSize: '12px'
                                        }}>{list?.length === 0 ? t('No auction in progress') : t('No More Data')}</div>
                                    }
                                    scrollableTarget={'acutionList'}
                                >
                                    {
                                        list.map((item, ind) => {
                                            const {
                                                winnerInfo,
                                                rankIdx,
                                                status,
                                                biddingInfo,
                                                coinType,
                                                nftSimpleData,
                                                seriesTotalAmount,
                                                bidState
                                            } = item || {};
                                            const {
                                                currentPrice,
                                                startTime,
                                                nextPrice,
                                                endTime,
                                                curPriceUserId
                                            } = biddingInfo || {};
                                            const {tokenAddr, tokenId, name, rarity, image} = nftSimpleData || {};
                                            const {winnerPrice} = winnerInfo || {};

                                            const isHighest = curPriceUserId === userId;
                                            // const bidState = localData?.current[tokenAddr + '' + tokenId] || {};
                                            const {isLoading, hasResult, isSucc, failedText} = bidState || {};
                                            const showPanl = (bidState && JSON.stringify(bidState) !== '{}') || bidLoadingId === tokenId;
                                            return (
                                                <div key={tokenId}
                                                     className={`${s.bidItem} ${ind % 2 === 0 ? s.leftItem : s.rightItem}`}
                                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_NFT-BlueWindows_01.png)`}}>
                                                    {(currentAuctionBatchCfg?.auctionBatchType === 2 && status === 1) &&
                                                        <div className={`${s.bidItemAvatarPart}`}
                                                             style={{backgroundImage: `url(/img/${getRarityImageBg(rarity)}.png)`}}>
                                                        </div>
                                                    }
                                                    <div className={`${s.bidItemAvatarPart}`}
                                                         style={{backgroundImage: `url(${image || defaultAvatar})`}}>
                                                    </div>
                                                    {status === 2 &&
                                                        <div className={`${s.bidItemAvatarPart} ${s.bidItemEndMask}`}>
                                                            <div className={s.winnerBanner}
                                                                 style={{backgroundImage: `url(/img/NFT_Winner.png)`}}>
                                                                <div className={s.winnerBannerLabel}>
                                                                    {getWinnerLabel(winnerInfo)}
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    <div className={s.l} onClick={() => {
                                                        nftDetail(navigate, {tokenAddr, tokenId})
                                                    }}>
                                                        <div className={s.bottomInfo}>
                                                            <div className={s.labelItem}>
                                                                <div className={`${s.val}`}>
                                                                    <CustomIcon width={16} height={16}
                                                                                className="mr5 ml5"
                                                                                imgName={'Picture/UI_Picture_Time_01'}/>
                                                                    {
                                                                        status === 1 ?
                                                                            <div className={s.endTimeSpan}>
                                                                                <Countdown workEndTimestamp={endTime}
                                                                                           refresh={() => {
                                                                                           }}/>
                                                                            </div> :
                                                                            <div className={s.endTextSpan}>
                                                                                {t('Ended')}
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {currentAuctionBatchCfg?.auctionBatchType === 2 ?
                                                                <div className={`${s.rankTag} bidRarity_${rarity}`}
                                                                     style={{
                                                                         backgroundColor: `${getRarityBgColor(rarity)}`,
                                                                         color: `${getRarityFontColor(rarity)}`
                                                                     }}>
                                                                    <CustomIcon width={19} height={19} className=""
                                                                                imgName={getRarityRankIcon(rarity)}/>
                                                                    <div className={s.rankTagText}>
                                                                        {rankIdx || '--'}
                                                                    </div>
                                                                </div> :
                                                                getRarityText(rarity) != null ?
                                                                    <div className={`${s.tag} bidRarity_${rarity}`}
                                                                         style={{
                                                                             backgroundColor: `${getRarityBgColor(rarity)}`,
                                                                             color: `${getRarityFontColor(rarity)}`
                                                                         }}>
                                                                        {getRarityText(rarity)}
                                                                    </div> :
                                                                    <></>
                                                            }

                                                        </div>

                                                        {showPanl && <div className={s.panl}>
                                                            {(bidLoadingId === tokenId && !hasResult) &&
                                                                <div className={s.bidLoading}
                                                                     style={{backgroundImage: `url(/img/UI_Gif_NFT-Hammer_01.gif)`}}>

                                                                </div>}
                                                            <div
                                                                className="fs26 fb color-gray">{(hasResult && bidLoadingId !== tokenId) ? isSucc ? t('Transaction Successful') : failedText : t('Processing')}</div>
                                                        </div>}
                                                    </div>
                                                    <div className={s.r}>
                                                        <div className={s.t}>
                                                            <div className={s.displayname}>{`#${tokenId}`}</div>
                                                            {/* <div className="fs10 tll ">{tokenId} of {seriesTotalAmount}</div> */}
                                                            <div className={s.labelItem}>
                                                                <div className={s.val}>
                                                                    <CustomIcon width={16} height={16} className="mr5"
                                                                                imgName={coinObj[coinType]?.iconName}/>
                                                                    <div>{convertWalletBalance(status === 2 ? winnerPrice : nextPrice)}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className={s.itemBottom}>

                                                            <div className={s.bidBtn}>
                                                                {
                                                                    status === 2 ?
                                                                        <></> :
                                                                        bidLoadingId === tokenId ?
                                                                            <ButtonFactory className={s.lowerBtn}
                                                                                           color={1} size={32}
                                                                                           scale={1.4} isMirror={true}
                                                                                           disabled={true}>
                                                                                <div className={s.cannotBidBtn}>
                                                                                    ···
                                                                                </div>
                                                                            </ButtonFactory> :
                                                                            bidLoading ?
                                                                                <ButtonFactory className={s.lowerBtn}
                                                                                               color={1} size={32}
                                                                                               scale={1.4}
                                                                                               isMirror={true}
                                                                                               disabled={true}>
                                                                                    <div className={s.cannotBidBtn}>
                                                                                        {t('BID')}
                                                                                    </div>
                                                                                </ButtonFactory> :
                                                                                isHighest ?
                                                                                    <ButtonFactory
                                                                                        className={s.lowerBtn} color={2}
                                                                                        size={32} scale={1.4}
                                                                                        isMirror={true}>
                                                                                        <div className={s.canBidBtn}
                                                                                             style={{color: `rgba(255, 255, 255, 1)`}}>
                                                                                            {t('LEADING')}
                                                                                        </div>
                                                                                    </ButtonFactory> :
                                                                                    <ButtonFactory
                                                                                        className={s.lowerBtn} color={1}
                                                                                        size={32} scale={1.4}
                                                                                        isMirror={true} onClick={() => {
                                                                                        bidNft(item, ind)
                                                                                    }}>
                                                                                        <div className={s.canBidBtn}>
                                                                                            {t('BID')}
                                                                                        </div>
                                                                                    </ButtonFactory>
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </InfiniteScroll>


                                {/* {list?.length === 0 && <div>{t('No More Data')}</div>} */}
                            </div> :
                            <div className={s.notStartBid}>
                                <div className={s.bidStartTimeTitle}>
                                    {t('The auction will start in')}
                                </div>
                                <div className={s.bidStartTime}>
                                    <Countdown workEndTimestamp={currentAuctionBatchCfg?.endTime || 0} refresh={() => {
                                    }}/>
                                </div>
                            </div>
                    }
                    {/* :<div className="w100p tlc color-gray">{t('Loading...')}</div>
                     } */}
                </div>

            </div>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap"
                centered={true}
                open={showIntro}
                destroyOnClose={true}
                onOk={() => setShowIntro(false)}
                onCancel={() => setShowIntro(false)}
                zIndex={2000}
            >
                <BidAuctionRules
                    closeHandle={() => {
                        setShowIntro(false)
                    }}
                />
            </AntModal>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowBidFilter(false)}
                width={300}
                open={showBidFilter}
                className="hireFilter"
                destroyOnClose={true}
            >
                <BidFilter
                    closeHandle={() => {
                        setShowBidFilter(false)
                    }}
                    maxRank={2000}
                    filterRank={filterRank}
                    setFilterRank={setFilterRank}
                    filterLFGPrice={filterLFGPrice}
                    setFilterLFGPrice={setFilterLFGPrice}
                    filterMaticPrice={filterMaticPrice}
                    setFilterMaticPrice={setFilterMaticPrice}
                    applyFilter={applyFilter}
                    filterIncludeTopPriceIsSelf={filterIncludeTopPriceIsSelf}
                    setFilterIncludeTopPriceIsSelf={setFilterIncludeTopPriceIsSelf}
                />
            </AntDrawer>
            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg insufficientAmount={insufficientAmount} userInfo={userInfo} closeFn={() => {
                    setShowSwapLfg(false);
                }}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('INSUFFICIENT BALANCE')}
                className="confirmModalWrap"
                centered={true}
                open={showInsufficientCrpto}
                onOk={() => setShowInsufficientCrpto(false)}
                onCancel={() => setShowInsufficientCrpto(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalInsufficientCrpto insufficientAmount={insufficientAmount} userInfo={userInfo} closeFn={() => {
                    setShowInsufficientCrpto(false);
                }}/>
            </AntModal>
        </>

    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        initTopUpAmount: app.initTopUpAmount,
        currentAuctionBatchCfg: app.currentAuctionBatchCfg,
        bidSlippage: app.bidSlippage,
        filterBidNftSingle: app.filterBidNftSingle
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        setShowLink: (params) => {
            return dispatch({
                type: "app/setShowLink",
                payload: params
            });
        },
        setBidSlippage: (params) => {
            return dispatch({
                type: "app/setBidSlippage",
                payload: params
            });
        },
        setFilterBidNftSingle: (params) => {
            return dispatch({
                type: "app/setFilterBidNftSingle",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(BidAuction));
