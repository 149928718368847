import React, { memo, useEffect, useMemo, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getWheelResultArr, doWheel, buyDowheel } from "@/utils/xFarm";
import ButtonFactory from "@/common/ButtonFactory";
import {formatPrizeList,prizeRoll} from "../prize"
import { bornCheck_loot, convertWalletBalance, getTransactionErrorMsgId, stringToNumber } from "@/utils/common";
import ModelUpHowToSpin from "@/model/ModelUpHowToSpin";
import AntModal from "@/common/AntModal";
import ModelUpBonusIntro from "@/model/ModelUpBonusIntro";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import { bornLfg } from "@/utils/lfgMintWeb3";
import { useWallets } from "@privy-io/react-auth";
import { message } from "antd";


const Bonus = (
    {
        userInfo,
        wheelInfo,
        setWheelInfo
    }
) => {
    const {wallets} = useWallets();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [bornNum,setBornNum] = useState(wheelInfo?.nextBuyDoWheelPrice)
    const {tierId,lfgBalance,balance} = useMemo(()=>{
        return userInfo?.selfData
    },[userInfo])

    const isBalanceAvailable = useMemo(() => {
        return lfgBalance >= bornNum
    }, [bornNum])

    useEffect(()=>{
        tierId && setPrizeList(formatPrizeList(getWheelResultArr(tierId)))
    },[tierId])
    const [prizeList,setPrizeList] = useState([])
    const [rotating,setRotating] = useState(false)
    const [subLoading,setSubLoading] = useState(false)
    const [showResult,setShowResult] = useState(false)
    const [resultInfo,setResultInfo] = useState({})
    const [showModel,setShowModel] = useState(false)
    const [showModelIntro,setShowModelIntro] = useState(false)
    const [failedMessage, setFailedMessage] = useState(null);
    const [subFailded, setSubFailded] = useState(false);
    const [done, setDone] = useState(false);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const available = useMemo(()=>{
        if(!wheelInfo) return false
        const {boostItemNum,maxWheelNum,finishWheelNum,isFree} = wheelInfo || {}
        return isFree || (boostItemNum > 0 && finishWheelNum < maxWheelNum)
        
    },[wheelInfo])

    const wheelDisplay = useMemo(()=>{
        if(prizeList?.length){
            return prizeList[0]?.wheelDisplay
        }
    },[prizeList])

    const doWheelResultHandel = (res)=>{
        const ind = prizeList?.findIndex(i=>i?.id === res?.wheelId)
        setResultInfo(prizeList?.find(i=>i?.id=== res?.wheelId))
        setRotating(true)
        prizeRoll(ind,()=>{
            const {wheelId,finishWheelNum,isFree,boostItemNum,wheelVal,nextBuyDoWheelPrice} = res
            setShowResult(true)
            setTimeout(()=>{
                setShowResult(false)
            },2000)
            setWheelInfo({
                ...wheelInfo,
                finishWheelNum,
                isFree,
                boostItemNum,
                wheelVal,
                nextBuyDoWheelPrice
            })
            setSubLoading(false)
            setRotating(false)
        })
    }

    const start = ()=>{
        if(subLoading) return
        setSubLoading(true)
        doWheel().then(res=>{
            doWheelResultHandel(res)
           
        }).catch(e=>{
            setSubLoading(false)
            console.error(e);
        })
        
       
    }
    const [checkExpire, setCheckExpire] = useState(0);
    const [checking, setChecking] = useState(false);
    const checkFn = (orderId) => {
        if (!checkExpire) {
            setCheckExpire(new Date().getTime() + 120000)
        }
        setChecking(true)
        if (checkExpire < new Date().getTime()) {
            bornCheck_loot(orderId).then(res => {
                if (!res?.haveResult) {
                    setTimeout(() => {
                        checkFn(orderId)
                    })
                } else {
                    setChecking(false)
                    setCheckExpire(0)
                    console.log(res);
                    doWheelResultHandel(res?.result || {})
                }
            })
        } else {
            setChecking(false)
            // setShowSelPayType(false);
            message.info({ content: 'Checking Expire' })
        }

    }

    const bornLfgFunc = (
        {
            amount,
            orderId,
            reason,
            mintNow,
            timestamp,
            validFor,
            signature
        }
    ) => {
        bornLfg({
            wallets,
            amount,
            orderId,
            reason,
            mintNow,
            timestamp,
            validFor,
            signature
        }).then(res => {
            console.log(res);

            setSubLoading(false);
            setDone(true);
            setSubFailded(false);
            checkFn(orderId)
        }).catch(e => {
            setFailedMessage(t(getTransactionErrorMsgId(e?.reason)));
            setSubLoading(false);
            setDone(true);
            setSubFailded(true)
        })
    }

    const applyFn = () => {
        if (!isBalanceAvailable || Number(lfgBalance) < 0.1) {
            // setShowSwapLfg(true);
            message.error({content:t('Insufficient balance or gas fee')})
            return
        }
        
        setSubLoading(true);
        buyDowheel().then(res => {

            console.log(res);
            bornLfgFunc(res);
        }).catch(e => {
            console.error(e);
            setSubLoading(false);
        })
        
    }

    return (
        <div className={`${s.wrap} commonBlueBg`}>
            <div className={s.upGrade} onClick={()=>{setShowModelIntro(true)}}>
                <div className={s.l}>
                    <CustomIcon width={30} height={30} imgName={`Picture/Rank/RankStar_${tierId}`} />
                </div>
                <div className={s.r} >
                    <div className="df alc jcc color-black">{t('Level Up')}<CustomIcon width={14} height={14} className="ml5" imgName={`Button/UI_Button_QuestionMark_01`} /></div>
                </div>
            </div>
            <div className={s.game}>
                <div className={`${s.gamePoint} ${rotating ? s.shake : ''}`}>
                    <CustomIcon width={70} height={70} imgName={`Picture/Wheel/UI_Picture_Spin_Nav_0${wheelDisplay}`}></CustomIcon>
                </div>

                <div className={s.gameWrap}>
                    <div className={`${s.luckWhellBgMain} ${s.rotateStyle} ${s['style'+ wheelDisplay]}`} id="luckWhellBgMain">
                        {
                            prizeList?.map(item => {
                                const { style2, id } = item
                                return (
                                    <div key={id} className={s.luckWhellSector} style={style2}>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={s.wheelmain}>
                        <div className={`${s.prizelist} ${s.rotateStyle}`} id="prize-list">
                            {
                                prizeList?.map(item => {
                                    const { style, showStr, id } = item
                                    return (
                                        <div key={id} className={s.prizeitem} style={style}>
                                            <div className={`${s.val} ${s.storke1}`}>{showStr}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={s.gameBg} style={{backgroundImage:`url(./UI/Picture/Wheel/UI_Picture_Spin_0${wheelDisplay}.png)`}}></div>
                    <div className={s.currentVal}>
                        <div className={`color-green ${s.storke4} ${s.title}`} data-content={t('Multiplier Wheel')}>{t('Multiplier Wheel')}</div>
                        <div className={`color-yellow ${s.storke4}  ${s.val}`} data-content={`${Math.round(wheelInfo?.wheelVal * 100)}%`}>{Math.round(wheelInfo?.wheelVal * 100)}%</div>
                    </div>
                    {showResult && <div className={s.result}>
                        <CustomIcon width={20} className="mr5" height={20} imgName={'Picture/UI_Picture_Title-Blue_Rocket_01'} />
                        <div className="fs16">{t('Congrats! You got a')}</div> &nbsp;
                        <div className="color-yellow fs16">{resultInfo?.showStr}&nbsp; {t('bonus!')}</div>
                    </div>}
                </div>
                {/* <Button onClick={()=>{start()}} className="btn_public">Start</Button> */}
                <div className={s.control}>
                    <div className={s.spinTime}>
                        {t('Spin times')} &nbsp;
                        {`${wheelInfo?.finishWheelNum}/${wheelInfo?.maxWheelNum}`}&nbsp;
                        <CustomIcon width={20} onClick={()=>{setShowModel(true)}} height={20} imgName={'Button/UI_Button_QuestionMark_01'} />
                    </div>
                    <div className={s.startBtn}>
                    {available ?
                        <ButtonFactory 
                            size={22} 
                            disabled={rotating}
                            scale={1.5} 
                            loading={rotating}
                            onClick={start}
                            >
                         
                            {wheelInfo?.isFree ? t('Spin (Free)') :
                                <>
                                    <CustomIcon width={20} className="mr5" height={20} imgName={'Picture/UI_Picture_Title-Blue_Rocket_02'}></CustomIcon>
                                    <div className="fs25 color-black">{t('Spin (Booster)')}</div>
                                </>
                            }
                        </ButtonFactory> :
                        <ButtonFactory  
                            size={22}
                            scale={1.5}  
                            disabled={wheelInfo?.finishWheelNum >= wheelInfo?.maxWheelNum || subLoading || checking || rotating}
                            loading={subLoading || checking || rotating}
                            onClick={()=>{
                                applyFn()
                            }}
                        >
                            {wheelInfo?.finishWheelNum >= wheelInfo?.maxWheelNum ? t('Max') :<>
                                <CustomIcon width={20} height={20} className="mr5" imgName={'Picture/UI_Picture_Title-Blue_Rocket_02'}></CustomIcon>
                                 {t('Get Booster')}
                            </>}
                        </ButtonFactory>
                    }
                    </div>
                    
                    <div className={s.boosterInfo}>
                        { wheelInfo?.boostItemNum > 0 ?
                            <>
                                {t('-1 booster')}:
                                <CustomIcon width={20} height={20} className="ml5 mr5" imgName={'Picture/UI_Picture_Title-Blue_Rocket_01'}></CustomIcon>
                                <div className="fs20 color-yellow">× {wheelInfo?.boostItemNum}</div>
                            </>:
                            <div className={s.info}>
                                <div className={s.infoT}>
                                    {t('Next Booster Price')}:
                                    <CustomIcon width={20} height={20} className="ml5 mr5" imgName={'Picture/UI_Picture-Currency_LFG_01'}></CustomIcon>
                                    <div className="fs20 color-yellow">{convertWalletBalance(wheelInfo?.nextBuyDoWheelPrice)}</div>
                                </div>
                                <div className={s.infoB}>
                                    {t('$LFG Balance')}:
                                    <CustomIcon width={20} height={20} className="ml5 mr5" imgName={'Picture/UI_Picture-Currency_LFG_01'}></CustomIcon>
                                    <div className="fs20 color-yellow">{convertWalletBalance(lfgBalance)}</div>
                                </div>
                            </div>
                        }
                        
                        
                    </div>
                </div>

            </div>
            <AntModal
                width='330px'
                title={t('How to Spin')}
                className="confirmModalWrap"
                centered={true}
                open={showModel}
                destroyOnClose={true}
                onOk={() => setShowModel(false)}
                onCancel={() => setShowModel(false)}
            >
                <ModelUpHowToSpin />
            </AntModal>
            <AntModal
                width='330px'
                title={t('Wheel Bonus Detail')}
                className="confirmModalWrap"
                centered={true}
                open={showModelIntro}
                destroyOnClose={true}
                onOk={() => setShowModelIntro(false)}
                onCancel={() => setShowModelIntro(false)}
            >
                <ModelUpBonusIntro currentTireId = {tierId} />
            </AntModal>
            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg insufficientAmount={Number(bornNum - lfgBalance)} userInfo={userInfo} closeFn={() => {
                    setShowSwapLfg(false);
                }}/>
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Bonus));
