import React from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import {gameShareDetailPath, gameTrillionaireBattleLeaderBoardPath, mainProfilePath} from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelUpHowToSpin = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    return (
    <div className={s.modelWrap}>
        <div className={s.item}>{t('Log in daily for a free wheel spin!')}</div>
        <div className={s.item}>{t('Each additional spin costs a Booster, but you can burn $LFG for extra spins.')}</div>
        <div className={s.item}>{t("Wheel resets daily at UTC 0, with a daily spin limit of 10.")}</div>
        <div className={s.item}>{t("Earn Boosters by referring friends")}</div>
    </div>
    )
}

export default ModelUpHowToSpin
