import http from "@/utils/axios";
import {url} from "@/utils/configUri";
import WheelResult from "@/utils/json/WheelResult.json";
import TwitterRandomContent from "@/utils/json/TwitterRandomContent.json";

const topic = '@TURNUPDOTXYZ';

const tag = '#IYKYK';

const getWheelResultArr = (tierId)=>{
    // const item = WorkConfig.find(i=>i?.ID === workId) || {}
    let arr = []
    WheelResult.map(item=>{
        if(item?.tierLevel === tierId){
            arr.push(item)
        }
    })
    return arr
}

const getWheelInfo = ()=> {
    return new Promise((resolve, reject) => {
        http('post', url.wheelinfo, {
 
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const doWheel = ()=> {
    return new Promise((resolve, reject) => {
        http('post', url.doWheel, {
  
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getTopicRanklist = ()=> {
    return new Promise((resolve, reject) => {
        http('post', url.farm_topic_ranklist, {
  
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    
    })
}

const getUserRanklist = ()=> {
    return new Promise((resolve, reject) => {
        http('post', url.farm_user_ranklist, {
  
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })

    })
}

const getfarmTopicTweetlist = (topicTwitterUID)=> {
    return new Promise((resolve, reject) => {
        http('post', url.farm_topic_tweetlist, {
            topicTwitterUID
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const farmReport = ()=> {
    return new Promise((resolve, reject) => {
        http('post', url.farm_report, {
  
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const farmSelfTweetlist  = ()=> {
    return new Promise((resolve, reject) => {
        http('post', url.farm_self_tweetlist, {
  
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const buyDowheel = ()=> {
    return new Promise((resolve, reject) => {
        http('post', url.farm_buy_dowheel, {
  
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const task = ()=>{
    return new Promise((resolve, reject) => {
        http('post', url.farm_task_count, {
  
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const boosterLog = (cursor)=>{
    return new Promise((resolve, reject) => {
        http('post', url.boost_event, {
            cursor: Number(cursor)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}



export {
    getWheelResultArr,
    getWheelInfo,
    doWheel,
    getTopicRanklist,
    getUserRanklist,
    getfarmTopicTweetlist,
    farmReport,
    farmSelfTweetlist,
    TwitterRandomContent,
    buyDowheel,
    boosterLog,
    topic,
    tag,
    task
}