import React, { memo, useEffect, useMemo, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonFactory from "@/common/ButtonFactory";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Spin } from "antd";
import AvatarInfo from "@/common/AvatarInfo";
import AntButton from "@/common/AntButton";
import { convertWalletBalance, getAvatarFromProfile } from "@/utils/common";
import AntModal from "@/common/AntModal";
import ModelUpDetail from "@/model/ModelUpDetail";
const List = (
    {
        list,
        getMoreFn = () => { },
        nextCursor = -1,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showModel ,setShowModel] = useState(false)
    const rankRander = (rankIdx) => {
        if (rankIdx === 1) {
            return <CustomIcon width={25} height={25} imgName={`Picture/UI_Picture_NO-First_01`} />
        }
        if (rankIdx === 2) {
            return <CustomIcon width={25} height={25} imgName={`Picture/UI_Picture_NO-Second_01`} />
        }
        if (rankIdx === 3) {
            return <CustomIcon width={25} height={25} imgName={`Picture/UI_Picture_NO-Third_01`} />
        }
        
        return `${rankIdx}th`
    }
    const getUserInfo = (userInfo) => {
        let avatarUrl, displayName
        avatarUrl = getAvatarFromProfile(userInfo?.profile)
        displayName = userInfo?.profile?.displayName
       
        return {
            displayName,
            avatarUrl
        }
    }

    return (
        <div className={s.wrap}>
            <div className={`${s.head}`}>
                <div className={s.hr}>{t('Rank')}</div>
                <div className={s.hl}>{t('Poster ')}</div>
                <div className={s.hm}>
                    {t('Fever')}
                </div>
                <div className={s.hr}>{t('Muilitifier')} <CustomIcon width={14} onClick={()=>{setShowModel(true)}} className="ml5" height={14} imgName={"Button/UI_Button_QuestionMark_03"} /></div>
            </div>
            <div className={`${s.list}`} id="list">
                <InfiniteScroll
                    dataLength={list?.length || 0}
                    next={getMoreFn}
                    hasMore={nextCursor !== -1}
                    loader={<Spin />}
                    height={"100%"}
                    endMessage={
                        <div style={{
                            textAlign: "center",
                            width: "100%",
                            color: "var(--color-gray)",
                            fontSize: '12px'
                        }}></div>
                    }>
                    {
                       list?.map((item,index) => {
                            const { fevelVal, authorUserInfo,likes,views,tweetRankIdx,showRatioStr } = item;
                            const { twitterUid, userId } = authorUserInfo
                            const {avatarUrl,displayName} = getUserInfo(authorUserInfo);
                            return (
                                <div className={s.item} key={tweetRankIdx}>
                                    <div className={s.rankIdx}>
                                        {rankRander(tweetRankIdx)}
                                    </div>
                                    <div className={s.info}>
                                        <AvatarInfo className="mr5" size={32} src={avatarUrl}></AvatarInfo>
                                        <div className={s.text}>{displayName}</div>
                                    </div>
                                    <div className={s.fire}>
                                        <CustomIcon width={20} height={20} imgName={"Picture/UI_Picture-Currency_UP_01"} />
                                        {convertWalletBalance(fevelVal)}
                                    </div>
                                    <div className={s.btn}>
                                        {showRatioStr}
                                    </div>
                                </div>
                            )
                        })
                    }
                </InfiniteScroll>

            </div>
            <AntModal
                width='330px'
                title={t('Tweets Multiplier')}
                className="confirmModalWrap"
                centered={true}
                open={showModel}
                destroyOnClose={true}
                onOk={() => setShowModel(false)}
                onCancel={() => setShowModel(false)}
            >
                <ModelUpDetail />
            </AntModal>
        </div>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(List));
