import React from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import {gameShareDetailPath, gameTrillionaireBattleLeaderBoardPath, mainProfilePath} from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelUpDetail = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    return (
    <div className={s.modelWrap}>
        
        <div className={s.item}>{t('Fever for each tweet will be aggregated to common mentions')}</div>
        <div className={s.item}>{t('Top 100 tweets of the Top 10 mentioned will get a multiplier at settlement')}</div>
    </div>
    )
}

export default ModelUpDetail
