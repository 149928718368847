import React, {memo, useCallback, useEffect, useMemo, useRef} from "react";
import s from "./index.module.less";
import {ethers, BigNumber} from "ethers";
import {connect} from "react-redux";
import CustomIcon from "../../common/CustomIcon";
import {
    unit,
    stringToNumber,
    sliceStr,
    updateSelfUser,
    TransactionError,
    maticIconUrl,
    lfgIconUrl,
    toAsyncAwait,
    post_batch_price, keyBatchBuy, getuserinfo, getProfile, PrivyLoginStatusError, logoutSelf,
    getAvatarFromUserInfo, sliceAddr, convertWalletBalance
} from "@/utils/common";
import {useState} from "react";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {Button, Modal, message, Spin} from 'antd';
import AvatarInfo from "../../common/AvatarInfo";
import {useTranslation, Trans} from 'react-i18next';
import ModalInsufficientCrpto from "src/model/ModalInsufficientCrpto";
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import ModalSwapLfg from "@/model/ModalSwapLfg";

const HireModelTrans = (
    {
        closeHandle,
        userInfo,
        buyList,
        refreshHomeList,
        done,
        setDone,
        subLoading,
        setSubLoading,
        cardType = 0,//0:players, 1:nfts
    }
) => {
    const {t, i18n} = useTranslation();
    const [keyPrice, setKeyPrice] = useState(0);
    const [keyPriceWithFee, setKeyPriceWithFee] = useState(0);

    const [subFailded, setSubFailded] = useState(false);
    const [noSuccessText, setNoSuccessText] = useState(t('Buy Failed'));
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [getPriceLoding, setGetPriceLoding] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const {walletAddr, selfData} = userInfo;
    const {balance, lfgBalance} = selfData;
    const {logout} = usePrivy();
    const closeFn = () => {
        if (noSuccessText === PrivyLoginStatusError) {
            logout();
            logoutSelf();
        }
        setSubLoading(false)
        setDone(false)
        closeHandle()
    }

    const showMoreInfoFn = () => {
        setShowMoreInfo(!showMoreInfo)
    }

    const isAvailable = useMemo(() => {
        if (cardType === 1){
            return Number(totalPrice) < Number(lfgBalance);
        }else{
            return Number(totalPrice) < Number(balance);
        }
    }, [balance, totalPrice, lfgBalance, cardType])

    const getEst = useMemo(() => {
        if (keyPriceWithFee) {
            const bN = ethers.utils.parseEther(keyPriceWithFee);
            const bN2 = ethers.utils.parseEther(keyPrice);
            return ethers.utils.formatUnits(bN.sub(bN2).abs());
        }
        return 0

    }, [keyPrice, keyPriceWithFee])

    const [loading, setLoading] = useState(true);
    const [fastGasPrice, setFastGasPrice] = useState(0);
    const [maticUsd, setMaticUsd] = useState(0);
    const [contractAddr, setContractAddr] = useState('');

    const walletList = useRef([]);
    const keyIdList = useRef([]);
    const amountList = useRef([]);
    const expectedPricesList = useRef([]);

    const [keyNum, setKeyNum] = useState(0);

    const currencyIcon = useMemo(() => {
        return cardType === 1?lfgIconUrl:maticIconUrl;
    }, [cardType])

    useEffect(() => {
        post_batch_price(buyList).then(data => {

            const buyPricesTotal = data?.buyPriceTotal;
            const buyPricesAfterFeeTotal = data?.buyPriceAfterFeeTotal;
            setTotalPrice(buyPricesAfterFeeTotal)

            setKeyPrice(buyPricesTotal);
            setKeyPriceWithFee(buyPricesAfterFeeTotal);
            setMaticUsd(data?.tokenUsd);
            setContractAddr(data?.contractAddr);
            setFastGasPrice(data?.fastGasPrice);

            walletList.current = Object.values(data?.wallets);
            keyIdList.current = Object.keys(buyList).map(Number);
            amountList.current = Object.values(buyList).map(Number);
            expectedPricesList.current = Object.values(data.buyPricesAfterFee);

            const kns = Object.values(buyList).reduce((a, b) => {
                return Number(a) + Number(b);
            }, 0);

            setKeyNum(kns);
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            console.log(e);
            message.error("Get price failed");
        })
    }, [])


    const {wallets} = useWallets();
    const transFn = () => {
        if (!dataInit || subLoading) return
        if (!isAvailable) {
            if (cardType === 1){
                setShowSwapLfg(true);
            }
            else{
                setShowInsufficientCrpto(true);
            }
            return
        }
        setSubLoading(true);
        //TODOnft
        keyBatchBuy(wallets, keyIdList.current, walletList.current, amountList.current, keyPriceWithFee, expectedPricesList.current).then(res => {
            refreshHomeList();
            setSubLoading(false);
            setDone(true);
            setSubFailded(false);
            setTimeout(
                () => {
                    updateSelfUser()
                }, 2000
            )
        }).catch(e => {
            console.log(e);
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }
            if (e === 'need login') {
                setNoSuccessText(PrivyLoginStatusError)
            }

            setSubLoading(false);
            setDone(true);
            setSubFailded(true)
            setTimeout(
                () => {
                    updateSelfUser()
                }, 2000
            )
        })
    }

    const dataInit = useMemo(() => {
        if (fastGasPrice && balance && walletAddr) {
            return true
        }
        return false
    }, [fastGasPrice, balance, walletAddr])

    const [avatarList, setAvatarList] = useState([]);

    useEffect(() => {
        const keys = Object.keys(buyList);

        const list = [];
        for (let i = 0; i < Math.min(5, keys.length); i++) {
            list.push("");

            getuserinfo(keys[i]).then((data) => {
                const {avatarUrl, originalAvatarUrl} = getAvatarFromUserInfo(data);
                list[i] = originalAvatarUrl || avatarUrl;
            })
        }
        setAvatarList(list);
    }, [])

    const convertValue = (val) =>{
        if (cardType === 1){
            return convertWalletBalance(val);
        }
        else{
            return stringToNumber(Number(val) || 0, 4);
        }
    }

    return (
        <div>
            <div className={s.dialog}>
                <Spin tip={t('Loading...')} spinning={loading}>
                    <div className={s.content}>
                        <div className={`mt20 ${s.avatarbar}`}>
                            {avatarList.map((item, index) => {
                                return index < 5 && <AvatarInfo key={index} size={45} src={item}/>;
                            })}
                        </div>
                        {Object.keys(buyList).length > 5 && (
                            <div className="mt10 color-blue-light">{t('and more...')}</div>
                        )}
                        <div className="fs12 c-title mtb10">
                            {t('Buy xx key(s) to manage yy club(s) for', {
                                keyNum: keyNum,
                                key: keyNum > 1 ? t('keys') : t('key'),
                                clubNum: buyList.length,
                                club: buyList.length > 1 ? t('clubs') : t('club')
                            })}
                        </div>
                        <div className={`${s.topTips} ${s.borderBottom}`}>
                            <CustomIcon
                                width={19}
                                height={19}
                                imgName={currencyIcon}
                            />
                            {convertValue(keyPrice)} {cardType === 1?"$LFG":unit}
                        </div>
                        <div className={`${s.details} mt20`}>
                            <div className="fs12 color-blue-light">{t('Order Details')}</div>
                            <div>
                            <span>
                                <CustomIcon
                                    width={12}
                                    height={12}
                                    imgName={currencyIcon}
                                />
                                <span className="fs12 color-white" style={{marginLeft: "4px"}}>
                                  {convertValue(keyPriceWithFee)}
                                </span>
                            </span>
                                <span
                                    className=" color-blue-light fs12">${convertValue(keyPriceWithFee * maticUsd)}</span>
                            </div>
                        </div>
                        <div className={s.total}>
                            <div className="fs12 color-blue-light"> {t('Total(inc. fees)')}</div>
                            <div>
                          <span>
                            <CustomIcon
                                width={12}
                                height={12}
                                imgName={currencyIcon}
                            />
                            <span
                                className="fs12 color-white"
                                style={{marginLeft: "4px"}}
                            >
                              {convertValue(Number(keyPriceWithFee) + Number(fastGasPrice))}
                            </span>
                          </span>
                                <span
                                    className="fs12 color-blue-light">${convertValue((Number(keyPriceWithFee) + Number(fastGasPrice)) * maticUsd)}</span>
                            </div>
                        </div>
                        <div className={`${s.wallet} ${s.borderWrap}`}>
                            <div className={s.walletLeft}>
                                <CustomIcon
                                    width={16}
                                    height={14}
                                    imgName={"VideoCallIcon"}
                                    onClick={showMoreInfoFn}
                                />
                                <div className="fs12 color-blue-light">{sliceAddr(walletAddr)}</div>
                            </div>
                            <div className={`${s.balance} ${!isAvailable && s.error}`}>
                            <span>
                                <CustomIcon
                                    width={12}
                                    height={12}
                                    imgName={currencyIcon}
                                />
                                <span className={`fs12 ${isAvailable ? 'color-white' : 'color-red'}`}
                                      style={{marginLeft: "4px"}}>
                                    {cardType === 1?convertValue(lfgBalance):convertValue(balance)}
                                </span>
                            </span>
                                &nbsp;
                                <span className={`${isAvailable ? 'color-blue-light' : 'color-red'}`}>
                                {t('Available')}
                            </span>
                            </div>
                        </div>
                    </div>
                </Spin>
                <div style={{height: '20px'}}/>
            </div>
            <div className={`modelBottomBtn`}>
                {!done && <ButtonFactory loading={getPriceLoding} onClick={transFn}>
                    {subLoading ? <div className={s.web3LoadingLine}>
                        <CustomIcon rotating={true} className="ml5" width={16} height={16}
                                    imgName={'Picture/UI_Picture_Loading_01'}/>
                        <span className="fs18 ml5 color-black">{t('PROCESSING')}</span>
                    </div> : t('BUY NOW')}
                </ButtonFactory>}
                {done && <ButtonFactory onClick={closeFn}>
                    {subFailded ? noSuccessText : t('TRANSACTION SUCCESSFUL')}
                </ButtonFactory>}
            </div>

            <AntModal
                width='330px'
                title={t('INSUFFICIENT BALANCE')}
                className="confirmModalWrap"
                centered={true}
                open={showInsufficientCrpto}
                onOk={() => setShowInsufficientCrpto(false)}
                onCancel={() => setShowInsufficientCrpto(false)}
                zIndex={7777}
            >
                <ModalInsufficientCrpto insufficientAmount={Number(totalPrice) - Number(balance)} userInfo={userInfo}
                                        closeFn={() => {
                                            setShowInsufficientCrpto(false);
                                        }}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg insufficientAmount={(Number(totalPrice) - Number(lfgBalance)) || 0}
                              userInfo={userInfo} closeFn={() => {
                    setShowSwapLfg(false);
                }}/>
            </AntModal>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(HireModelTrans));
// export default memo(BuyDialog);
