import React, {memo, useState, useEffect, useMemo} from "react";
import s from "./index.module.less";
import {Button, Modal} from "antd";
import CustomIcon from "../../common/CustomIcon";
import {
    getnft_ownList,
    set_nftavatar
} from "@/utils/bidNft";
import {useTranslation, Trans} from 'react-i18next';
import AvatarInfo from "../../common/AvatarInfo";
import ButtonFactory from "src/common/ButtonFactory";

const ModelChangeAvatar = ({
                               userInfo,
                               refreshIcon = () => {
                               },
                               closeFn
                           }) => {
    const {t, i18n} = useTranslation();
    const [isUseNFT, setIsUseNFT] = useState(false);
    const [selectedAvatarTokenId, setSelectedAvatarTokenId] = useState(0);
    const [selectedAvatarTokenAddr, setSelectedAvatarTokenAddr] = useState('');
    const [nftList, setNftList] = useState([]);
    const [isUpdatingAvatar, setIsUpdatingAvatar] = useState(false);
    const {
        platformData,
        selfData,
        useNftInfo
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {platformMap, useType, defaultName} = useMemo(() => {
        return platformData || {}
    }, [platformData]);

    const {avatarUrl, displayName, originalAvatarUrl, uid} = useMemo(() => {
        if (platformMap && JSON.stringify(platformMap) !== '{}') {
            return platformMap[useType]
        } else {
            return {
                displayName: defaultName
            }
        }
    }, [platformMap, useType, defaultName]);

    useEffect(() => {
        getnft_ownList().then(res => {
            setNftList(res?.nftList);
        }).catch(err => {
            console.error(err.message);
        })
        setIsUseNFT(useNftInfo?.useNft);
        setSelectedAvatarTokenAddr(useNftInfo?.tokenAddr);
        setSelectedAvatarTokenId(useNftInfo?.tokenId);
    }, []);

    const isCurrentNft = (item) => {
        return isUseNFT && selectedAvatarTokenId === item?.tokenId && selectedAvatarTokenAddr === item?.tokenAddr;
    }

    const updateAvatar = () => {
        if (!isUpdatingAvatar) {
            setIsUpdatingAvatar(true);
            set_nftavatar({tokenAddr: selectedAvatarTokenAddr, tokenId: selectedAvatarTokenId}).then(res => {
                setIsUpdatingAvatar(false);
                closeFn();
            }).catch(err => {
                setIsUpdatingAvatar(false);
                console.error(err.message);
                closeFn();
            })
        }
    }

    return (
        <div className={s.wrap}>
            <div className={s.avatarRoot}>
                <div className={s.avatarScroll}>
                    <div className={`${s.avatarItemRoot}`}>
                        <div className={s.avatarIconRoot} onClick={() => {
                            setIsUseNFT(false);
                            setSelectedAvatarTokenAddr('');
                            setSelectedAvatarTokenId(0);
                        }}>
                            <AvatarInfo className={`${isUseNFT ? '' : s.selectedAvatar}`} size={80}
                                        src={originalAvatarUrl || avatarUrl} noBorder={true}/>
                        </div>
                        <div className={s.refreshIcon} onClick={() => {
                            refreshIcon();
                        }}>
                            <CustomIcon width={18} className="" isHaveClick={true} height={18}
                                        imgName={'Button/UI_Button_reload_01'} name={'Button/UI_Button_reload_01'}/>
                        </div>
                    </div>
                    {
                        nftList?.map((item, index) => {
                            return (
                                <div className={s.avatarItemRoot} key={index}>
                                    <div className={s.avatarIconRoot} onClick={() => {
                                        setIsUseNFT(true);
                                        setSelectedAvatarTokenAddr(item?.tokenAddr);
                                        setSelectedAvatarTokenId(item?.tokenId);
                                    }}>
                                        <AvatarInfo className={`${isCurrentNft(item) ? s.selectedAvatar : ''}`}
                                                    size={80} src={item?.image} noBorder={true}/>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="modelBottomBtn">
                <ButtonFactory onClick={() => {
                    updateAvatar();
                }}>
                    {t('UPDATE AVATAR')}
                </ButtonFactory>
            </div>
        </div>
    )
}
export default memo(ModelChangeAvatar);
