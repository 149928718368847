import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";

import { airdropTypeData, getChainInfo, getHolderList } from "@/utils/momentClub";
import { convertWalletBalance, displayFilter, getAvatarFromProfile } from "@/utils/common";
import AvatarInfo from "@/common/AvatarInfo";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import TradeKeyPanel from "../TradeKeyPanel";
import KChart from "../../../KChart";
import { gameMomentClubUserDetailPath } from "@/routes/config";
import { useNavigate } from "react-router-dom";
const Holders = (
    {
        clubBasicInfo,
        clubId,
        chainInfo,
        sourceData,
        showChartDetail,
        setShowChartDetail,
        upDate,
        openBuyDrawer = () => {},
    }
) => {

    const {
        clubInfo,
        buyPrice,
        sellPrice,
        historyPrice,
    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])
    
    const navigate = useNavigate()
    const range = historyPrice ? Math.round((sellPrice - historyPrice) / historyPrice * 100) : 0;

    const {
        airdropType,
        cardsTotalSupply,
        ftTotalSupply,
        clubName,
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    const { t, i18n } = useTranslation();
    const [list, setList] = useState([]);
    const [nextCursor, setNextCursor] = useState(-1)
    const getList = (clubUserId, nextCursor = 0) => {
        getHolderList(clubUserId, nextCursor).then(res => {
            setList(res?.holderList || [])
            setNextCursor(res?.nextCursor)
        }).catch(e => {
            console.error(e);
        })
    }
    useEffect(() => {
        clubId && getList(clubId, 0)
    }, [clubId])

    const { icon } = useMemo(() => {
        return chainInfo || {}
    }, [chainInfo])

    const getMoreList = () => {
        getHolderList(clubId, nextCursor)
    }
    const [chartShow, setChartShow] = useState(true)

    return (
        <div className={s.clubWrap}>
            <div className={`${s.box}`}>
                <div className={`${s.chartWrap} ${showChartDetail ? '' : s.chartHide}`}>
                    <div className={`${s.chartTitle} momentFont`} onClick={() => { setShowChartDetail(!showChartDetail) }}>
                    {showChartDetail?t('Hide Chart')
                    :
                    <div className={`momentFont fs12 flex_center_center`}>
                        {`$${clubName} moment`}
                        <CustomIcon imgName={`Picture/${chainInfo?.icon}`} className="ml5 mr5" width={17} height={17}/>
                        {convertWalletBalance(buyPrice)}
                    </div>}
                        <CustomIcon className={s.chartIcon} onClick={() => { setShowChartDetail(!showChartDetail) }} imgName={showChartDetail ? 'Button/UI_Button_Arrow_Up_02' : 'Button/UI_Button_Arrow_Down_02'} width={19} height={10}></CustomIcon>
                    </div>
                    {showChartDetail && <div className={s.kChartWrap}>
                        <KChart sourceData={sourceData} coinName={chainInfo?.displayCoinName} clubId={clubId} symbolName={clubName}></KChart>
                    </div>
                    }
                </div>
                <div className={s.list}>
                    <InfiniteScroll
                        dataLength={list?.length || 0}
                        next={getMoreList}
                        hasMore={nextCursor !== -1}
                        loader={<div
                            style={{
                                textAlign: "center",
                                width: "100%",
                            }}
                        ><Spin /></div>}
                        height={"100%"}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0",
                            width: "100%"
                        }}

                    >
                        {list && list.length > 0 ? (
                            list?.map((item, index) => {
                                const { userId, profile, holderNum } = item;
                                const { displayName, avatarUrl, coinId,profileDeepLink,platformType } = profile;

                                const _base = (parseInt(ftTotalSupply / cardsTotalSupply * airdropTypeData[airdropType]?.min / 10000) + parseInt(ftTotalSupply / cardsTotalSupply * airdropTypeData[airdropType]?.max / 10000)) / 2
                                return (
                                    <div className={s.item} key={userId + '_' + index} onClick={()=>{
                                        userId>0 && navigate(`${gameMomentClubUserDetailPath}/${userId}`)
                                    }}>
                                        <AvatarInfo className="momentRoundAvatar" src={getAvatarFromProfile(profile)} size={55} isMoment={true}></AvatarInfo>
                                        <div className={s.ir}>
                                            <div className={`momentFont fs14 fb flex_center_start`}>
                                                {displayFilter(displayName)}
                                                {platformType === 1 && profileDeepLink && <CustomIcon width={15} height={15} className="ml5" imgName={'Button/UI_Button_Ball-White_Twitter_On'} />}
                                            </div>
                                            <div className={s.line}>
                                                <div className={s.label}>
                                                    <div className="fs12 momentFont fw-400">{t('Moment Packs Held')}</div>
                                                </div>
                                                <div className={`${s.val} momentFont`}>
                                                    <CustomIcon width={16} height={16} className="ml5 mr5" imgName={'Picture/UI_Picture_icon_cardpack'} />
                                                    {convertWalletBalance(holderNum)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="flex_center_center_col w100p mt20">
                                <CustomIcon width={110} height={95} imgName={`Picture/UI_Picture_NoData_Empty_02`}></CustomIcon>
                                <div className="mt20 fs18 momentFont fw-400" style={{color: `#555555`}}>{t('No Data')}</div>
                            </div>
                        )}
                    </InfiniteScroll>
                </div>
            </div>
            <TradeKeyPanel 
                upDate={()=>{
                    getList(clubId, 0)
                    upDate()
                }} 
                clubBasicInfo={clubBasicInfo}
                sourceData={sourceData}
                openBuyDrawer={openBuyDrawer}>
            </TradeKeyPanel>
        </div>
    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Holders));
