import React, {useState} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {convertScore, siteName} from "@/utils/common";
import s from "./index.module.less";
import {Popover} from "antd";

const ShareDetailQuestExpand = (
    {
        tweets,
        type,
        total,
        placement,
    }
) => {
    const {t, i18n} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const popcontent = (
        <div className="collapsible-content ">

            <ul className={s.ulstyle}>
                <li>{t('Views')}</li>
                <li>{t('Likes')}</li>
                <li>{t('Replies')}</li>
                <li>{t('Repost')}</li>
            </ul>
            <ul className={s.ulstyle} style={{borderTop: "1px solid rgba(112,112,112,0.3)"}}>
                <li>{tweets?.impression_count}</li>
                <li>{tweets?.like_count}</li>
                <li>{tweets?.reply_count}</li>
                <li>{tweets?.retweet_count}</li>
            </ul>
        </div>
    );

    return (
        <Popover content={popcontent} placement={placement} trigger="click">
            <a type="text">
                <div className={s.questbox}>
                    {/* </div> <div className="innerquestbox" onClick={() => setIsOpen(!isOpen)}> */}
                    <div className="innerquestbox">
                        <div
                            style={{backgroundColor: 'rgba(255,255,255,0.4)', borderRadius: '10px', padding: '2px 0'}}>
                            <span className="fs12 qtitle">{t(type)} <br/> </span>
                            <span style={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                lineHeight: "40px"
                            }}>{tweets?.count > total ? total : tweets?.count}</span>
                        </div>
                        <div className={` fs12 ${s.value} tll mt3`}>
                            <span className="fs12">{t('details')}</span>
                            <div className={`mt2 aic ${s.itemInfo}`} style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}>
                                <div className={`${s.infovalue}`}>
                                    <span className='fs14 color-white'>{convertScore(tweets?.total_points)}</span>
                                    <div className='fs12 color-white'>{isOpen ? '▲' : '▼'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isOpen && (
                        <div className="collapsible-content ">
                            <table>
                                <tbody>
                                <tr>
                                    <td>{t('Views')}</td>
                                    <td>{t('Likes')}</td>
                                    <td>{t('Replies')}</td>
                                    <td>{t('Repost')}</td>
                                </tr>
                                <tr>
                                    <td>{tweets?.impression_count}</td>
                                    <td>{tweets?.like_count}</td>
                                    <td>{tweets?.reply_count}</td>
                                    <td>{tweets?.retweet_count}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </a>
        </Popover>
    );
};

export default ShareDetailQuestExpand;
