import React from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { gameShareDetailPath, gameTrillionaireBattleLeaderBoardPath, mainProfilePath } from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelMemeCreatCoinTips = (
    {
        showBtn = true,
        closeHandle = () => {
        }
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className={s.modelWrap}>
            <div>{t('Upon your meme collection launch, a specific coin is minted.')}</div>
            <div className="mt20 mb20">{t('A portion of these coins is airdropped to all pack holders, enhancing their value.')}</div>
            <div>{t('The rest is added to a liquidity pool. together with the funds from pack sales ensuring a robust market for your memes.')}</div>
        </div>
    )
}

export default ModelMemeCreatCoinTips
