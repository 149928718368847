import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";

import AntModal from "@/common/AntModal";
import AntButton from "@/common/AntButton";
import ModelMemeCreatTips from "@/model/ModelMemeCreatTips";
import ModelMemeCreatBasicTips from "@/model/ModelMemeCreatBasicTips";
import ModelMemeCreatCoinTips from "@/model/ModelMemeCreatCoinTips";
import { backFn } from "@/utils/mianPublic";
import MemeClubPack from "@/common/MemeClubPack";
import ModelMemeCreatPreBuy from "@/model/ModelMemeCreatPreBuy";
import ModalMemeCreateImage from "@/model/ModalMemeCreateImage";
import ImgCrop from 'antd-img-crop';
import { Upload } from "antd";
import { uploadImage, momentImageTemplate, websiteAfterAddon } from "@/utils/momentClub";
import { convertWalletBalance, vCoinString, logoutSelf } from "@/utils/common";
const View = (
    {
        userInfo,
        clubName,
        loading,
        clubTicker,
        clubDesc,
        selCreatChain,
        creatFn,
        showSettingFn,
        coinTotalSupply,
        airdropPecentage,
        totalCardValue,
        cardTotalSupply,
        lastCardPrice,
        firstCardPrice,
        receiveNFTAmount,
        receiveNFT,
        momentClubImgUrl,
        setMomentClubImgUrl,
        onBack,
        creatDone,
        creatErrorMsg,
        setCreatErrorMsg,
        walletBalanceList,
        setCreatDone,
        preBuyAmount,
        setPreBuyAmount,
        preBuyTotalValue,
        isAnony,
        setIsAnony,
        resetPreferences,
        selCoinId,
        resetBaseInfo,
        clubTlink,
        clubXlink,
        unpackRatio
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [showModel, setShowModel] = useState(false)
    const [showBasicModel, setShowBasicModel] = useState(false)
    const [showCoinModel, setShowCoinModel] = useState(false)
    const [showPreBuyModel, setShowPreBuyModel] = useState(false)
    const [showGenerateImage, setShowGenerateImage] = useState(false);
    const [imgCoin, setImgCoin] = useState([]);
    const eachCoinValue = useMemo(() => {
        return totalCardValue / (coinTotalSupply * (100 - airdropPecentage) / 100)
    }, [totalCardValue, coinTotalSupply, airdropPecentage])

    const randomImgUrl = () => {
        const img = momentImageTemplate[Math.floor(Math.random() * momentImageTemplate?.length)]?.packImageUrl
        console.log(img);
        setMomentClubImgUrl(img)
    }

    const [aiAttrLoading, setAiAttrLoading] = useState(false)
    const [isGif, setIsGif] = useState(false);


    const getBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => callback(reader.result);
        reader.onerror = (error) => console.error('Error: ', error);
    };
    const upAmount = useRef(0)
    const beforeUpload = (file) => {
        // Check file type and size here if necessary
        // upAmount.current += 1;
        const isGifFile = file.type === 'image/gif';
        // setIsGif(isGifFile);
        // console.log(upAmount.current, isGifFile);
        if (!isGifFile) {

        return new Promise((resolve) => {
            getBase64(file, (base64) => {
                const { type } = file
                uploadImage({
                    imageType: type,
                    imageContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '').replace('data:image/gif;base64,', '')
                }).then(res => {
                    setMomentClubImgUrl(res?.url);
                }).catch(e => {
                    console.error(e);
                })
            });
        })
        }
    };
    const beforeCrop = (file) => {
        const isGifFile = file.type === 'image/gif';
        if (isGifFile) {
            return new Promise((resolve) => {
                getBase64(file, (base64) => {
                    const { type } = file
                    uploadImage({
                        imageType: type,
                        imageContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '').replace('data:image/gif;base64,', '')
                    }).then(res => {
                        setMomentClubImgUrl(res?.url);
                    }).catch(e => {
                        console.error(e);
                    })
                });
            });
        }
    }
    useEffect(() => {
        !momentClubImgUrl && randomImgUrl()
    }, [])

    const evenCardToToken = useMemo(() => {
        return parseInt(coinTotalSupply * (airdropPecentage / 100) / cardTotalSupply * 2)
    }, [cardTotalSupply, coinTotalSupply, airdropPecentage])

    

    return (
        <div className={`${s.modalWrap}`}>
            <MainHeader hasRightMenu={false} title={t('QUICK PREVIEW')} headerType={2} hasBackBtn={true} onBack={onBack}></MainHeader>
            {/* <div className={s.cardBg}></div> */}
            <div className={s.cardBg2}></div>
            <div className={s.modal}>

                <div className={s.content}>
                    {/* <div className={`${s.partTitle} mt30`}>
                        <CustomIcon width={17} height={39} className="mr5" baseUrl={'/UI/'}
                            imgName={'Picture/UI_Picture_Title-Yellow_01'} name={'Button/UI_Button_Arrow_01'} />
                        {AutoStrangeRule(t('CREATE MEMECLUB'))}
                    </div> */}
                    <div className={s.wrap}>
                        <div className={s.avatarInfo}>
                            <MemeClubPack width={104} height={164} imageUrl={momentClubImgUrl} packName={clubTicker} >

                            </MemeClubPack>
                            <div className={s.avatarBtn}>
                                <AntButton className={`${s.generateBtn} btn_blue flex_center_center`} onClick={() =>{
                                    setShowGenerateImage(true);
                                }}>
                                    <div className={`fs12 color-white fb`}>
                                        {t('AI Generate')}
                                    </div>
                                </AntButton>
                                <AntButton className={`${s.uploadBtn}`}>
                                    {/* {!isGif ?

                                        <Upload
                                            showUploadList={false}
                                            accept="image/png, image/jpeg, image/gif"
                                            listType="picture-card"
                                            fileList={imgCoin}
                                            beforeUpload={beforeUpload}
                                        >
                                            <span className="color-black fs14"> {t('Upload')}</span>
                                        </Upload> : */}
                                    <ImgCrop modalTitle={t('Edit image')} rotationSlider
                                        beforeCrop={beforeCrop}
                                        modalClassName="imgCropModalWrap">
                                        <Upload
                                            showUploadList={false}
                                            accept="image/png, image/jpeg, image/gif"
                                            listType="picture-card"
                                            fileList={imgCoin}
                                            beforeUpload={beforeUpload}
                                        >
                                            <span className="color-black fs12 fb">{t('Upload')}</span>
                                        </Upload>
                                    </ImgCrop>
                                    {/* }  */}

                                </AntButton>
                            </div>
                        </div>
                        {
                            (clubXlink || clubTlink) &&
                            <div className={`${s.group} mt10`}>
                                <div className={s.box}>
                                    {clubXlink && <div className="flex_center_start">
                                        <CustomIcon imgName={`Button/UI_Button_White_Twitter_01`} className="ml5" width={20} height={20}></CustomIcon>
                                        <div className={`fs12 ml10 ellipse`}>{clubXlink}</div>
                                    </div>}
                                    {clubTlink && <div className="flex_center_start mt5">
                                        <CustomIcon imgName={`Button/UI_Button_White_TurnUp_01`} className="ml5" width={20} height={20}></CustomIcon>
                                        <div className={`fs12 ml10 ellipse`}>{clubTlink}</div>
                                    </div>}
                                </div>
                            </div>
                        }


                        <div className={`${s.group} ${(clubXlink || clubTlink) ? '' : 'mt10'} `}>
                            <div className={s.label}>
                                {t('Basic Information')}
                                <CustomIcon imgName={`Button/UI_Button_QuestionMark_02`} onClick={() => {
                                    setShowBasicModel(true)
                                }} className="ml5" width={15} height={15}></CustomIcon>
                            </div>
                            <div className={s.box}>
                                <div className={s.line}>
                                    <div className={`color-yellow ellipse`}>{clubName}</div>
                                    {/* <div className={`color-purple`}>{selCreatChain?.name}</div> */}
                                </div>
                                <div className={s.line}>
                                    {/* <div className={`color-white`}>{cardTotalSupply} {t('Cards')} </div> */}
                                    {/* <div className={`color-purple`}>{selCreatChain?.displayCoinName}</div> */}
                                    <div className={`color-blue-light`}>{t('Total Supply:')} <span className="color-white fs12">{convertWalletBalance(cardTotalSupply)} {t('Packs')}</span></div>

                                </div>
                                <div className={s.line}>
                                    {/* <div className={`color-blue-light`}>{t('Last Card’s Price:')} <span className="color-white">{lastCardPrice}{selCreatChain?.displayCoinName}</span></div> */}
                                    <div className={`color-blue-light`}>{t('Liquidity to TGE:')} <span className="color-white fs12">{convertWalletBalance(totalCardValue)} {selCreatChain?.displayCoinName} ({selCreatChain?.name})</span></div>
                                </div>
                                <div className={s.line}>
                                    <div className={`color-blue-light`}>{t('Pack Price:')} <span className="color-white fs12">{convertWalletBalance(firstCardPrice)} - {convertWalletBalance(lastCardPrice)} {selCreatChain?.displayCoinName}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className={s.group}>
                            <div className={s.label}>
                                <div className="">${clubTicker} {t('Tokenomics')}</div>
                                <CustomIcon imgName={`Button/UI_Button_QuestionMark_02`} onClick={() => {
                                    setShowCoinModel(true)
                                }} className="ml5" width={15} height={15}></CustomIcon>
                            </div>
                            <div className={s.box}>
                                <div className={s.line}>
                                    <div className={`color-yellow`}>{t('Coin Supply: ')} &nbsp;{vCoinString(coinTotalSupply)}</div>
                                </div>
                                <div className={s.line}>
                                    <div className={`color-white`}>{t('Airdrop: ')} &nbsp;{airdropPecentage}/{100 - airdropPecentage}</div>
                                </div>
                                <div className={s.line}>
                                    <div className={`color-white`}>{t('Allocation Range per Pack:')}  &nbsp;
                                        {`${Math.floor(evenCardToToken * (1 / (unpackRatio + 1)))} - ${Math.ceil(evenCardToToken * (unpackRatio / (unpackRatio + 1)))}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {receiveNFT &&
                            <div className={s.group}>
                                <div className={s.label}>
                                    <div className="">{t('NFT Generation')}</div>
                                    <CustomIcon imgName={`Button/UI_Button_QuestionMark_02`} onClick={() => {
                                        setShowModel(true)
                                    }} className="ml5" width={15} height={15}></CustomIcon>
                                </div>
                                <div className={s.box}>

                                    <div className={s.line}>
                                        <div className={`color-blue-light`}>{t('NFT Generation Ratio:')}<span className="color-white fs12"> {vCoinString(receiveNFTAmount)} ${clubTicker} {t('held')} </span></div>
                                    </div>
                                    <div className={s.line}>
                                        <div className={`color-blue-light`}>{t('Total NFT Supply:')} <span className="color-white fs12">{vCoinString(Math.floor(coinTotalSupply / receiveNFTAmount))}</span></div>
                                    </div>
                                    <div className={s.line}>
                                        <div className={`color-blue-light`}>{t('Each Pack offer NFT: ')} <span className="color-white fs12">

                                            {`${Math.floor(evenCardToToken * (1 / (unpackRatio + 1)) / receiveNFTAmount)} -
                                        ${Math.ceil(evenCardToToken * (unpackRatio / (unpackRatio + 1)) / receiveNFTAmount)}`}
                                        </span></div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={s.setting}>
                            <AntButton
                                onClick={() => {
                                    if (aiAttrLoading || loading) return
                                    setAiAttrLoading(true)
                                    resetPreferences().then(() => {
                                        setAiAttrLoading(false)
                                    }).catch(e => {
                                        setAiAttrLoading(false)
                                    })
                                }}
                                loading={aiAttrLoading}
                                className={`btn_blue ${s.setBtn} mr5`}>
                                <div className="flex_center_center">
                                    <span className="fs12">{t('Rebuild')}</span>
                                    <CustomIcon imgName={`Button/UI_Button_reload_01`} className="ml5" width={16} height={16}></CustomIcon>
                                </div>

                            </AntButton>
                            <AntButton
                                onClick={() => {
                                    if (loading) return
                                    showSettingFn()
                                }}
                                className={`btn_blue ${s.setBtn}`}>
                                <div className="flex_center_center">
                                    <span className="fs12">{t('Advanced')}</span>
                                    <CustomIcon imgName={`Button/UI_Button_SetUp-Block-White_02`} className="ml5" width={16} height={16}></CustomIcon>
                                </div>
                            </AntButton>
                        </div>
                    </div>
                </div>
                <div className={s.bottom}>
                    {creatDone ?
                        <ButtonFactory
                            size={23}
                            scale={1.3}
                            onClick={() => {
                                setCreatDone(false)
                                if (!creatErrorMsg) {
                                    resetBaseInfo()
                                    backFn(location, navigate)
                                }
                                if(creatErrorMsg === 'need login'){
                                    logoutSelf()
                                }
                                setCreatErrorMsg('')

                            }}
                        >
                            <span className="fs18 color-black">{creatErrorMsg ? creatErrorMsg : t('Creation Success')}</span>
                        </ButtonFactory>

                        :
                        <ButtonFactory
                            size={23}
                            scale={1.3}
                            onClick={() => { setShowPreBuyModel(true) }}
                            loading={loading}
                            disabled={loading}
                        >
                            <span className="fs18 color-black">{loading ? t('loading') : t('CREATE MEMECLUB')}</span>
                        </ButtonFactory>
                    }

                </div>
            </div>
            <AntModal
                width='330px'
                title={t('NFT Generation')}
                className="confirmModalWrap"
                centered={true}
                open={showModel}
                destroyOnClose={true}
                onOk={() => setShowModel(false)}
                onCancel={() => setShowModel(false)}
            >
                <ModelMemeCreatTips closeHandle={() => {
                    setShowModel(false);
                }} showBtn={false} />
            </AntModal>
            <AntModal
                width='330px'
                title={t('Moment Collection')}
                className="confirmModalWrap"
                centered={true}
                open={showBasicModel}
                destroyOnClose={true}
                onOk={() => setShowBasicModel(false)}
                onCancel={() => setShowBasicModel(false)}
            >
                <ModelMemeCreatBasicTips closeHandle={() => {
                    setShowBasicModel(false);
                }} showBtn={false} />
            </AntModal>
            <AntModal
                width='330px'
                title={t('Collection Coin')}
                className="confirmModalWrap"
                centered={true}
                open={showCoinModel}
                destroyOnClose={true}
                onOk={() => setShowCoinModel(false)}
                onCancel={() => setShowCoinModel(false)}
            >
                <ModelMemeCreatCoinTips closeHandle={() => {
                    setShowCoinModel(false);
                }} showBtn={false} />
            </AntModal>
            <AntModal
                width='330px'
                title={t('Collection Coin')}
                className="confirmModalWrap"
                centered={true}
                open={showPreBuyModel}
                destroyOnClose={true}
                onOk={() => setShowPreBuyModel(false)}
                onCancel={() => setShowPreBuyModel(false)}
            >
                <ModelMemeCreatPreBuy
                    max={cardTotalSupply}
                    walletBalanceList={walletBalanceList}
                    preBuyAmount={preBuyAmount}
                    setPreBuyAmount={setPreBuyAmount}
                    preBuyTotalValue={preBuyTotalValue}
                    selCreatChain={selCreatChain}
                    isAnony={isAnony}
                    setIsAnony={setIsAnony}
                    coinId={selCoinId}
                    userInfo={userInfo}
                    creatFn={() => {
                        setShowPreBuyModel(false);
                        creatFn()
                    }}
                />
            </AntModal>
            <AntModal
                width='330px'
                title={t('Generate Image')}
                className="confirmModalWrap"
                centered={true}
                open={showGenerateImage}
                destroyOnClose={true}
                onOk={() => setShowGenerateImage(false)}
                onCancel={() => setShowGenerateImage(false)}
            >
                <ModalMemeCreateImage
                    momentClubImgUrl={momentClubImgUrl}
                    keyWord={`${clubName},${clubDesc}`}
                    setMemeClubImage={(url) => {
                        setMomentClubImgUrl(url);
                    }}
                    closeHandle={() => {
                        setShowGenerateImage(false);
                    }}
                />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(View));
