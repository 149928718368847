import React, {useState} from "react";
import {Checkbox} from "antd";
import TranList from "@/common/TranList";
import HireFriendsTranList from "@/components/HireFriendsTranList";
import s from "./index.module.less";

const HireActivityList = ({isFriends}) => {
    return (
        <div className={s.content}>
            {!isFriends ? <HireFriendsTranList/> : <TranList type={"global"}/>}
        </div>
    );
};

export default HireActivityList;
