import React, {memo, useState, useMemo, useEffect} from "react";
import s from "./index.module.less";
import {Input, Button, Modal, Spin, Tabs} from "antd";
import {
    unit,
    siteName,
    stringToNumber,
    chainName,
    vCoinString,
    post_batch_price,
    keySell,
    getStakeYieldClaimed,
    convertWalletBalance, TransactionError, PrivyLoginStatusError
} from "@/utils/common";
import CustomIcon from "../../../../common/CustomIcon";
import WithdrawDialog from "../../../../model/WithdrawDialog";
import {useTranslation, Trans} from 'react-i18next';
import _ from "lodash";
import {
    lfgProcessRewards,
    lfgStakeGetDeposits,
    lfgStakeGetDepositsLength,
    lfgStakePendingYieldRewards,
    lfgUnStake
} from "@/utils/lfgStake";
import {useWallets} from "@privy-io/react-auth";
import {ethers} from "ethers";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import ModelStakePendingIntro from "@/model/ModelStakePendingIntro";
import ModelStakeUnstakeIntro from "@/model/ModelStakeUnstakeIntro";
import {AutoStrangeRule} from "@/utils/strangeRule";
import AntTabPane from "@/common/AntTabPane";

const ModelStakingPool = (
    {
        userInfo,
        setShowStakingPool,
        refreshStakeInfo,
        setMaskClosable,
        refresh
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const [amount, setAmount] = useState(0);
    const [amountTotal, setAmountTotal] = useState(0);
    const [pageLoading, setPageLoading] = useState(true);
    const [yieldClaimedList, setYieldClaimedList] = useState(null);

    const refreshData = () => {
        setPageLoading(true);
        lfgStakeGetDepositsLength(wallets).then(depositsLength => {
            let wait = depositsLength;
            if (wait === 0) {
                setPageLoading(false);
            }
            setStakingDetails([]);
            console.log(depositsLength);
            for (let i = 0; i < depositsLength; i++) {
                lfgStakeGetDeposits(wallets, i).then(deposit => {
                    if (deposit.StakedUnlockTime > 0) {
                        setStakingDetails(prev => {
                            return [...prev, deposit];
                        })
                    }
                    wait--;
                    if (wait === 0) {
                        setPageLoading(false);
                        sortStakingDetails();
                    }
                }).catch(() => {
                    wait--;
                    if (wait === 0) {
                        setPageLoading(false);
                        sortStakingDetails();
                    }
                })
            }
        }).catch(() => {
            setPageLoading(false);
        })
    }

    const sortStakingDetails = () => {
        setStakingDetails(prev => {
            return prev.sort((a, b) => {
                return a.StakedUnlockTime - b.StakedUnlockTime
            })
        })
    }

    const updateYieldClaimedInfo = () => {
        getStakeYieldClaimed(userInfo?.userId, 0).then(res => {
            setYieldClaimedList(res.yields);
        }).catch(e => {
            console.log(e);
        });
    }

    const [amountYield, setAmountYield] = useState(0);
    const updateYieldAmount = () => {
        lfgStakePendingYieldRewards(wallets).then(res => {
            setAmountYield(res);
        }).catch(e => {
            console.log(e);
        });
    }

    useEffect(() => {
        refreshData();
        updateYieldClaimedInfo();
        updateYieldAmount();
    }, [])

    useEffect(()=>{
        if(refresh){
            refreshData();
            updateYieldClaimedInfo();
            updateYieldAmount();
        }
        
    },[refresh])

    const [stakingDetails, setStakingDetails] = useState([]);

    useEffect(() => {
        const nowTimestamp = Math.floor(Date.now() / 1000);
        let newAmount = 0;
        let newAmountTotal = 0;
        stakingDetails.map((item, index) => {
            if (nowTimestamp > item.StakedUnlockTime) {
                newAmount += Number(item.StakedAmount);
            }
            newAmountTotal += Number(item.StakedAmount);
        })
        setAmountTotal(newAmountTotal);
        setAmount(newAmount);
    }, [stakingDetails]);

    const parseUnlockTime = (t, withUnlock = true) => {
        if (withUnlock) {
            const nowTimestamp = Math.floor(Date.now() / 1000);
            if (nowTimestamp > t) {
                return "Unlock"
            }
        }
        let date = new Date(t * 1000);
        return date.toLocaleString();
    }

    const parseStingToNum = (numString) => {
        if (numString) {
            var bigint = ethers.utils.parseUnits(numString, "wei");
            return Number(ethers.utils.formatUnits(bigint || 0, "ether"));
        } else {
            return 0;
        }
    }

    const [progressList, setProgressList] = useState({})

    const [subLoading, setSubLoading] = useState(false);
    const [done, setDone] = useState(false);

    const [progressStart, setProgressStart] = useState(false)
    const [progressNum, setProgressNum] = useState(0)
    const [progressOkNum, setProgressOkNum] = useState(0)
    const [showPendingModelIntro, setShowPendingModelIntro] = useState(false)
    const [showUnstakeModelIntro, setShowUnstakeModelIntro] = useState(false)

    useEffect(() => {
        if (progressStart && progressNum === progressOkNum) {
            setSubLoading(false);
            setMaskClosable(true);
            setDone(true);
            refreshStakeInfo();
        }
    }, [progressStart, progressNum, progressOkNum]);

    const transferFn = () => {
        if (!(amount) || subLoading) return
        setSubLoading(true);
        setMaskClosable(false);
        const nowTimestamp = Math.floor(Date.now() / 1000);

        let needUnStakeList = [];
        stakingDetails.map((item, index) => {
            if (nowTimestamp > item.StakedUnlockTime) {
                needUnStakeList.push(item);
            }
        })

        const interval = 10000;
        needUnStakeList.forEach((item, index) => {
            setProgressNum((prev) => prev + 1)
            setTimeout(function () {
                setProgressList((prev) => ({...prev, [item.DepositId]: '⌛️'}))
                lfgUnStake(wallets, item.DepositId, item.StakedAmount).then(res => {
                    setProgressList((prev) => ({...prev, [item.DepositId]: '✅'}))
                    setProgressOkNum((prev) => prev + 1)
                }).catch(e => {
                    console.log(e);
                    setProgressList((prev) => ({...prev, [item.DepositId]: '❌'}))
                    setProgressOkNum((prev) => prev + 1)
                })
            }, index * interval);
        })
        setProgressStart(true)
    }

    const [yieldSubLoading, setYieldSubLoading] = useState(false);
    const [yieldDone, setYieldDone] = useState(false);
    const [yieldNoSuccessText, setYieldNoSuccessText] = useState('Failed');
    const [yieldSubFailded, setYieldSubFailded] = useState(false);
    const transferFnYield = () => {
        if (!(amountYield) || yieldSubLoading) return
        setYieldSubLoading(true);
        setMaskClosable(false);

        lfgProcessRewards(wallets).then(res => {
            setYieldDone(true);
            setYieldSubLoading(false);
            setMaskClosable(true);
            refreshData();
            updateYieldClaimedInfo();
            updateYieldAmount();
        }).catch(e => {
            console.log(e);
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setYieldNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }

            if (e === 'need login') {
                setYieldNoSuccessText(PrivyLoginStatusError)
            }

            setYieldSubLoading(false);
            setMaskClosable(true);
            setDone(true);
            setYieldSubFailded(true)
        })
    }

    return (
        <div className={s.wrap}>

            <div className={s.bt}>
                <div className={s.btl}>
                    <div className={`${s.partTitle} fb`}>
                        <CustomIcon width={17} height={39} className=""
                                    imgName={'Picture/UI_Picture_Title-Yellow_01'}
                        />
                        <div className="fs18 ml5">{AutoStrangeRule(t('Staked $LFG'))}</div>
                    </div>
                </div>
                <div className={s.btr}>
                    <CustomIcon
                        width={24}
                        height={24}
                        className="ml5 mr5"
                        imgName="Picture/UI_Picture-Currency_LFG_01"
                    />
                    <div className="fs16">{vCoinString(amountTotal)}</div>
                </div>
            </div>
            <div className={s.box}>

                <div className={`${s.tabbox}`}>
                    <Tabs defaultActiveKey="1" className={`tptabs`}>
                        <AntTabPane tab={AutoStrangeRule(t('YIELD CLAIMED HISTORY'))} key="1">
                            <div className={s.tableTitles}>
                                <span className={`${s.stakedAmount} color-blue-light`}>{t('Claimed Amount')}</span>
                                <span className={`${s.unlockTime} color-blue-light`}>{t('Claimed Time')}</span>
                            </div>
                            <div className={`${s.checkBoxes}`}>
                                {yieldClaimedList?.map((item, index) => (
                                    <div key={index} className={s.stakeItem}>
                                        <div
                                            className={s.stakedAmount}>{convertWalletBalance(Number(item.amount))}</div>
                                        <div className={s.stakedAmount}>{parseUnlockTime(item.claimTime, false)}</div>
                                    </div>
                                ))}
                            </div>
                            <div className={s.tableFoot}>
                                <span className={s.stakedAmount}>{t('Pending Yield')}:</span>
                                <span className={`fs24 color-yellow`}>{vCoinString(amountYield)}</span>
                                <CustomIcon
                                    width={18}
                                    height={18}
                                    imgName="Button/UI_Button_QuestionMark_01"
                                    className='ml5'
                                    onClick={() => {
                                        setShowPendingModelIntro(true)
                                    }}
                                >
                                </CustomIcon>
                            </div>
                            <div className={s.stakeBtn}>
                                {!yieldDone && <ButtonFactory disabled={amountYield <= 0 || yieldSubLoading}
                                                              size={11}
                                                              loading={yieldSubLoading}
                                                              onClick={transferFnYield}>
                                    {yieldSubLoading ? t('PROCESSING') : t('CLAIM YIELD')}
                                </ButtonFactory>}
                                {yieldDone && <ButtonFactory
                                    size={11}
                                    onClick={() => {
                                        setYieldDone(false)
                                        setYieldSubLoading(false)
                                    }}>
                                    {yieldSubFailded ? yieldNoSuccessText : t('TRANSACTION SUCCESSFUL')}
                                </ButtonFactory>}
                            </div>
                        </AntTabPane>
                        <AntTabPane tab={AutoStrangeRule(t('STAKING DETAILS'))} key="2">
                            <div className={s.tableTitles}>
                                <span className={`${s.stakedAmount} color-blue-light`}>{t('Staked Amount')}</span>
                                <span className={`${s.unlockTime} color-blue-light`}>{t('Unlock Time')}</span>
                                <span style={{width: "7%"}}></span>
                            </div>
                            <div className={`${s.checkBoxes}`}>
                                {stakingDetails.map((item, index) => (
                                    <div key={index} className={s.stakeItem}>
                                        <div className={s.stakedAmount}>{vCoinString(item.StakedAmount)}</div>
                                        <div
                                            className={`${Math.floor(Date.now() / 1000) > item.StakedUnlockTime ? s.unlockTimeGray : s.unlockTime}`}>
                                            {parseUnlockTime(item.StakedUnlockTime)}
                                        </div>
                                        <div className={s.totalAmount}>
                                            {progressList[item.DepositId] === '❌' &&
                                                <CustomIcon width={14} height={14}
                                                            imgName={'Picture/UI_Picture_RedRound_Cross_01'}
                                                            name={'Picture/UI_Picture_RedRound_Cross_01'}/>}
                                            {progressList[item.DepositId] === '✅' &&
                                                <CustomIcon width={14} height={14}
                                                            imgName={'Picture/UI_Picture_GreenRound_Hook_01'}
                                                            name={'Picture/UI_Picture_GreenRound_Hook_01'}/>}
                                            {progressList[item.DepositId] === '⌛️' &&
                                                <CustomIcon width={14} height={14}
                                                            imgName={'Picture/UI_Picture_Hourglass_01'}
                                                            name={'Picture/UI_Picture_Hourglass_01'}/>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={s.tableFoot}>
                                <span className={s.stakedAmount}>{t('Unlocked $LFG')}:</span>
                                <span className={`fs24 color-yellow`}>{vCoinString(amount)}</span>
                                <CustomIcon
                                    width={18}
                                    height={18}
                                    imgName="Button/UI_Button_QuestionMark_01"
                                    className='ml5'
                                    onClick={() => {
                                        setShowUnstakeModelIntro(true)
                                    }}
                                >
                                </CustomIcon>
                            </div>
                            <div className={s.stakeBtn}>
                                {!done && <ButtonFactory disabled={!amount || subLoading}
                                                         size={11}
                                                         loading={subLoading}
                                                         onClick={transferFn}>
                                    {subLoading ?
                                        t('PROCESSING') : t('UNSTAKE')}
                                </ButtonFactory>}
                                {done && <ButtonFactory
                                    size={11}
                                    onClick={() => {
                                        setDone(false);
                                        setSubLoading(false)
                                    }}>
                                    {t('TRANSACTION SUCCESSFUL')}
                                </ButtonFactory>}
                            </div>
                        </AntTabPane>
                    </Tabs>
                </div>
            </div>
            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={showPendingModelIntro}
                destroyOnClose={true}
                onOk={() => setShowPendingModelIntro(false)}
                onCancel={() => setShowPendingModelIntro(false)}
                zIndex={4000}
            >
                <ModelStakePendingIntro/>
            </AntModal>
            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={showUnstakeModelIntro}
                destroyOnClose={true}
                onOk={() => setShowUnstakeModelIntro(false)}
                onCancel={() => setShowUnstakeModelIntro(false)}
                zIndex={4000}
            >
                <ModelStakeUnstakeIntro/>
            </AntModal>
        </div>
    )
}
export default memo(ModelStakingPool);
