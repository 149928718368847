import http from "@/utils/axios";
import {url} from "@/utils/configUri";
import WorkConfig from "@/utils/json/WorkConfig.json";

const getWorklist = (workType) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_worklist, {
            workType: Number(workType)   // 1 普通 2 限时 3 nft
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getWorkItem = (workId)=>{
    const item = WorkConfig.find(i=>i?.ID === workId) || {}
    return item
}

const getWorkDetail = (workId)=> {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_workdetail, {
            workId: Number(workId)  
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getClubsList = (workType,workId)=> {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_candispatchlist, {
            workType: Number(workType),
            workId: Number(workId)   
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const dispatchClubsShow = (workId,emplyeeIds)=> {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_dispatch_detail, {
            workId: Number(workId) ,  
            emplyeeIds
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const claimWorkprofit = (workId)=> {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_takeworkprofit, {
            workId: Number(workId) 
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const dispatchClubs = (workId,emplyeeIds)=> {
    return new Promise((resolve, reject) => {
        http('post', url.dispatch_emplyees, {
            workId: Number(workId) ,  
            emplyeeIds
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}



export {
    getWorklist,
    WorkConfig,
    getWorkItem,
    getWorkDetail,
    getClubsList,
    dispatchClubsShow,
    dispatchClubs,
    claimWorkprofit
}