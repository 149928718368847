import React, {memo, useState, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button} from "antd";
import AvatarInfo from "../../common/AvatarInfo";
import {
    PLATFORM_DATA, unit, chgFn, logoutSelf, getProfile, stringToNumber, displayFilter,
    getAvatarFromProfile
} from "@/utils/common"
import CustomIcon from "../../common/CustomIcon";
import {usePrivy} from "@privy-io/react-auth";
import AntButton from "@/common/AntButton";

const ModelLinkWishUser = (
    {
        wishUserInfo,
        onClose
    }
) => {
    const {t, i18n} = useTranslation();
    const {buyPriceObj, youOwnKeyNum, holdersNum, holding, platformData, accountType, wishFee} = wishUserInfo;
    const {useType} = platformData || {};

    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])

    const {sellPrice, historyPrice} = buyPriceObj || {}

    const chg = chgFn(sellPrice, historyPrice);
    const isUp = chg === '-' ? true : chg >= 0 ? true : false;

    const {logout} = usePrivy();

    return (
        <div className={s.wrap}>
            <div className="mt10">
                <AvatarInfo src={getAvatarFromProfile(profile)} size={60}/>
            </div>
            <div className="fs18 fb">{displayFilter(profile?.displayName)}</div>
            <div className="">{profile?.accountName}</div>
            <div className="">{holdersNum > 1 ? t('Members') : t('Member')} <span
                className="fb">{holdersNum ? holdersNum : 0}</span></div>
            <div className={s.item}>
                <div className="fs16 color-gra">{t('Unclaimed value')}</div>
                <div className={s.uv}>
                    <div className="fs16 fb">
                        {wishFee === "" ? 0 : stringToNumber(wishFee, 4)} {unit}
                    </div>
                </div>
            </div>
            <div className={s.item}>
                <div className="color-gra">{t('Current WIsh Price')}</div>
                <div className={s.uv}>
                    <div className="fs16 fb">
                        {stringToNumber(buyPriceObj?.keyPrice, 4)} {unit}
                    </div>
                    <div className={`${s.percent} ${isUp ? s.up : s.down}`}>
                        <div className={`${isUp ? s.upIcon : s.downIcon}`}></div>
                        <div className={`${s.percentVol} fs10 ${isUp ? s.upColor : s.downColor}`}> {chg}%</div>
                    </div>
                </div>
            </div>
            <div className="tlc mtb10">
                <Trans
                    i18nKey="This xx account you are trying to link has Wishes created on TURNUP.Only accounts that have not yet purchased the first key can claim Wishes"
                    defaults="This <sp>{{platform}}</sp> account you are trying to link has Wishes created on TURNUP<br></br>Only accounts that have not yet purchased the first key can claim Wishes"
                    values={{platform: PLATFORM_DATA[useType]?.label}}
                    components={{sp: <span className="fb color-white"></span>, br: <br/>}}
                />
            </div>
            <AntButton className={`btn_public mtb10 ${s.btn}`} onClick={() => {
                logout()
                logoutSelf()
            }}>
                {t('Create Account to Claim the Wish')}
            </AntButton>
            <AntButton className={`btn_white mt20 ${s.btn}`} onClick={() => onClose(false)}>
                {t('Link Another X(Twitter) Account')}
            </AntButton>
            
        </div>
    )
}
export default memo(ModelLinkWishUser);
