import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import s from "./index.module.less";
import { ethers, BigNumber } from "ethers";
import { connect } from "react-redux";
import {useNavigate} from "react-router-dom";
import CustomIcon from "../../common/CustomIcon";
import {
    keyBuy,
    stringToNumber,
    sliceStr,
    keySell,
    updateSelfUser,
    updateOtherUser,
    getPrice,
    TransactionError,
    PrivyLoginStatusError,
    logoutSelf,
    copyFn,
    displayFilter,
    getAvatarFromUserInfo,
    sliceAddr,
    getAvatarFromProfile,
    getTokenBalanceOf,
    getQuotes,
    numberClamp,
    convertWalletBalance,
    getWeb3Config,
} from "@/utils/common";
import { useState } from "react";
import { url } from "@/utils/configUri";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { Button, Input, Modal, Radio, Checkbox } from 'antd';
import http from "@/utils/axios";
import {
    LfgMainnetId,
    LfgTestnetId,
} from "@/utils/env";
import ModelDeposit from "../ModelDeposit";
import ModalInsufficientCrpto from "src/model/ModalInsufficientCrpto";
import { useTranslation, Trans } from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntDrawer from "@/common/AntDrawer";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import MemeChainToken from "@/utils/json/memeChainToken.json";
import {
    momentBuyCard,
    buyMomentClubPack,
    getMomentTradeInfo,
    getMomentClubPackGasPrice,
    setAnony,
    sellMomentClubPack
} from "@/utils/momentClub";
import {
    gameMemeClubAssetsPath
} from "@/routes/config";
import MemeClubPack from "@/common/MemeClubPack";
import IykykAnimate from "@/common/IykykAnimate";
import { getMomentEntropyFee } from "@/utils/momentClubWeb3";

const ModalMomentBuy = (
    {
        userInfo,
        setShowGasMode,
        walletBalanceList,
        setWalletBalanceList,
        closeHandle = () => {},
        type,
        done,
        setDone,
        subLoading,
        setSubLoading,
        tradeMemeClubInfo,
        defaultNum = 1,
        setMemeAssetParam,
        clubId,
        completeFn = () => {},
    }
) => {
    const { t, i18n } = useTranslation();
    const transType = {
        1: {
            fn: buyMomentClubPack,
            str: t('Buy')
        },
        2: {
            fn: sellMomentClubPack,
            str: t('Sell')
        }
    }
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const { wallets } = useWallets();
    const [subFailded, setSubFailded] = useState(false);
    const [noSuccessText, setNoSuccessText] = useState(transType[type]?.str + ' ' + t('Failed'));
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [keyNum, setKeyNum] = useState(defaultNum);
    const [getPriceLoding, setGetPriceLoding] = useState(false);
    const [memeTradeInfo, setMemeTradeInfo] = useState(null);
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [depositChainTokenInfo, setDepositChainTokenInfo] = useState(null);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [coinUsd, setCoinUsd] = useState(1);
    const [fastGasPrice, setFastGasPrice] = useState(0);
    // const [done, setDone] = useState(false);
    // const [subLoading, setSubLoading] = useState(false);
    const [insufficientAmount, setInsufficientAmount] = useState(0);
    const [keyNumInput, setKeyNumInput] = useState(false);
    const [showIykykPanel, setShowIykykPanel] = useState(true);
    const [showPackInAsset, setShowPackInAsset] = useState(false);
    const [curCoinMemeFactoryContract, setCurCoinMemeFactoryContract] = useState('');
    // const [momentEntropyFee,setMomentEntropyFee] = useState('')
    const {
        userId,
        clubName,
        coinId,
        curCardKindNum,
        cardKindMaxNum,
        holdNum,
        imageUrl,
        symbolName,
        ftTotalSupply,
        airdropPercent,
        openPackRatio,
        coinToNFTRatio,
        isFT,
    } = useMemo(() => {
        return tradeMemeClubInfo || {}
    }, [tradeMemeClubInfo]);
    const nativeCoinInfo = useMemo(() => {
        let id = Math.floor(coinId / 100) * 100;
        return MemeChainToken.find(i => i?.ID === id);
    }, [coinId]);
    const currentCoinInfo = useMemo(() => {
        return MemeChainToken.find(i => i?.ID === coinId);
    }, [coinId]);


    useEffect(()=>{
        console.log(`clubId,userId`,clubId,userId);
    },[clubId,userId])

    const {
        clubUserId,
        chainClubId,
        cardNum,
        isBuy,
        price,
        priceWithFee,
    } = useMemo(() => {
        return memeTradeInfo || {}
    }, [memeTradeInfo]);
    const { walletAddr, selfData, topUpCode } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);



    const {
        balance,
        momentAnonymous,
        points
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const { logout } = usePrivy();
    const closeFn = () => {
        if (noSuccessText === PrivyLoginStatusError) {
            logout();
            logoutSelf();
        }
        setSubLoading(false)
        setDone(false)
        closeHandle()
    }
    const dataInit = useMemo(() => {
        if (memeTradeInfo && walletAddr) {
            return true
        }
        return false
    }, [memeTradeInfo, walletAddr]);

    const transFn = () => {
        if (isNativeCoin()) {
            if (Number(priceWithFee) + Number(fastGasPrice) > Number(getSpecifiedCoinBalance(currentCoinInfo?.ID))) {
                setShowInsufficientCrpto(true);
                setInsufficientAmount(Number(priceWithFee) + Number(fastGasPrice) - Number(getSpecifiedCoinBalance(currentCoinInfo?.ID)));
                return;
            }
        }
        else {
            if (Number(fastGasPrice) > Number(getSpecifiedCoinBalance(nativeCoinInfo?.ID))) {
                setShowInsufficientCrpto(true);
                setInsufficientAmount(Number(fastGasPrice) - Number(getSpecifiedCoinBalance(nativeCoinInfo?.ID)));
                return;
            }
        }
        if (!dataInit || subLoading) return
        setSubLoading(true);
        // momentBuyCard({
        //     clubUserId: userId,
        //     buyNum: keyNum,
        //     costPrice: priceWithFee.toString(),
        // }).then(res => {
            buyMomentClubPack({
                wallets: wallets,
                // callId: res?.orderId,
                clubId: Number(chainClubId),
                amount: keyNum,
                expectedPrice: priceWithFee.toString(),
                // entropyFee: momentEntropyFee.toString(),
                // timestamp: res?.timestamp,
                // validFor: res?.validFor,
                // signature: res?.signature,
                coinInfo: currentCoinInfo
            }).then(res => {
                console.log(res);
                setSubLoading(false);
                if (type === 1){
                    //setShowIykykPanel(true);
                    completeFn(res.hash);
                    //setShowPackInAsset(true);
                }
                setDone(true);
                setSubFailded(false);
                setTimeout(
                    () => {
                        updateSelfUser();
                        getWalletBalance();
                    }, 2000
                )
            }).catch(e => {
                console.log(e);
                if (e?.reason != null) {
                    for (const transactionErrorElement in TransactionError) {
                        if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                            setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                            break;
                        }
                    }
                }
    
                if (e === 'need login') {
                    setNoSuccessText(PrivyLoginStatusError)
                }
    
                setSubLoading(false);
                setDone(true);
                setSubFailded(true);
                setTimeout(
                    () => {
                        updateSelfUser();
                        getWalletBalance();
                    }, 2000
                )
            })
        // }).catch(e => {
        //     console.log(e);
        //     if (e?.reason != null) {
        //         for (const transactionErrorElement in TransactionError) {
        //             if (e?.reason.indexOf(transactionErrorElement) !== -1) {
        //                 setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
        //                 break;
        //             }
        //         }
        //     }

        //     if (e === 'need login') {
        //         setNoSuccessText(PrivyLoginStatusError)
        //     }

        //     setSubLoading(false);
        //     setDone(true);
        //     setSubFailded(true);
        //     setTimeout(
        //         () => {
        //             updateSelfUser();
        //             getWalletBalance();
        //         }, 2000
        //     )
        // })


    }

    const showMoreInfoFn = () => {
        setShowMoreInfo(!showMoreInfo)
    }

    const controlAmount = (control) => {
        if (control === 0) {
            if (keyNum > 1) {
                let _keyNum = keyNum - 1;
                setKeyNum(_keyNum);
            }
        }
        else if (control === 1) {
            if (type === 1) {
                let _keyNum = keyNum + 1;
                setKeyNum(_keyNum);
            }
            else {
                if (keyNum < holdNum) {
                    let _keyNum = keyNum + 1;
                    setKeyNum(_keyNum);
                }
            }
        }
    }

    useEffect(() => {
        getKeyPrice();
    }, [keyNum, type, userId])

    const getContractInfo = async () =>{
        const web3Config = await getWeb3Config();
        const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === coinId));
        setCurCoinMemeFactoryContract(contractInfo?.memeFactoryContract);
    }

    // const getMomentEntropyFeeFn = (wallets,coinId)=>{
    //     getMomentEntropyFee(wallets,coinId).then(res=>{
    //         console.log(`getMomentEntropyFee---->`,ethers.utils.formatUnits(res));
    //         setMomentEntropyFee(ethers.utils.formatUnits(res))
    //     }).catch(e=>{
    //         console.error(e);
    //     })
    // } 

    useEffect(() =>{
        getContractInfo();
        // coinId && getMomentEntropyFeeFn(wallets,coinId)
    }, [coinId]);

    useEffect(() => {
        getWalletBalance();
        getQuotesInfo();
        
    }, [])

    const getQuotesInfo = () => {
        getQuotes([currentCoinInfo?.coinName]).then(res => {
            setCoinUsd(res?.quotes?.[currentCoinInfo?.coinName] || 1);
        }).catch(e => {
            console.log(e);
        })
    }

    const getSpecifiedCoinBalance = (id) => {
        return walletBalanceList?.[id] || 0;
    }

    const getKeyPrice = () => {
        if (type === 1) {
            if (keyNum < 1) {
                return;
            }
        }
        else {
            if (keyNum < 1 || keyNum > holdNum) {
                return;
            }
        }
        setGetPriceLoding(true);
        getMomentTradeInfo(userId, keyNum, type === 1).then(res => {
            setMemeTradeInfo({ ...res });
            getMomentClubPackGasPrice(wallets, currentCoinInfo).then(res => {
                console.log("getMomentClubPackGasPrice", res);
                setFastGasPrice(res || 0);
                setGetPriceLoding(false);
            }).catch(e => {
                setGetPriceLoding(false);
                console.log(e);
            })
        }).catch(e => {
            setGetPriceLoding(false)
            console.log(e);
        })
    }

    const amountChangeHandler = (e) => {
        let value = e.target.value.replace(/[^\d]/g, '');
        setKeyNum(Number(value));
    }

    const amountBlurHandler = (e) => {
        setKeyNumInput(false);
        let tempKeyNum = keyNum;
        if (type === 1) {
            tempKeyNum = Math.max(tempKeyNum, 1);
        }
        else {
            tempKeyNum = numberClamp(tempKeyNum, 1, holdNum);
        }
        setKeyNum(tempKeyNum);
    }
    const toggleKeyNumInput = () => {
        setKeyNumInput(true)
        setTimeout(() => {
            inputRef.current.focus({
                cursor: 'all',
            })
        });
    }

    const isNativeCoin = () => {
        if (MemeChainToken?.length > 0) {
            for (let i = 0; i < MemeChainToken?.length; i++) {
                if (MemeChainToken[i].ID === coinId) {
                    return MemeChainToken[i].isNative === 1;
                }
            }
        }
        return true;
    }

    const getWalletBalance = () => {
        let tokenList = [coinId];
        if (!isNativeCoin() && nativeCoinInfo?.ID > 0) {
            tokenList.push(nativeCoinInfo.ID);
        }
        getTokenBalanceOf(walletAddr, tokenList).then(res => {
            let tempBalance = {...walletBalanceList} || {};
            for (let i = 0; i < tokenList.length; i++) {
                tempBalance[tokenList[i].toString()] = res?.balances?.[tokenList[i]];
            }
            setWalletBalanceList(tempBalance);
        }).catch(err => {
            console.error(err.message);
        })
    }

    const [isAnony, seIsAnony] = useState(false)

    useEffect(() => {
        seIsAnony(momentAnonymous)
    }, [momentAnonymous])


    const setMemeAnony = (isMemeAnony) => {
        setAnony(isMemeAnony).then(res => {
            console.log(res);
        }).catch(e => {
            console.error(e);
        })
    }

    return (
        <>
            <div className={s.dialog}>
                <div className={s.content}>
                    <div className={s.title}>
                        {t('Buy Packs 1')}
                    </div>
                    <div className="df jc-spaceBetween ais w100p color-white fs12 mt45">

                        <div className="ml10 flex_center_start fb" style={{ padding: "4px 0px" }}>
                            {t('You Own')}
                            <CustomIcon className="ml5 mr5" width={19} height={19}
                                imgName={'Picture/UI_Picture_Cardpack_01'} />
                            x {holdNum || 0}
                        </div>
                        <div className={`${s.overview} flex_center_center_col`}>
                            {
                                !isNativeCoin() && <div className={s.overviewItem}>
                                    <CustomIcon width={18} height={18} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                                    <span className={`${s.balance} color-yellow`}>
                                        {convertWalletBalance(getSpecifiedCoinBalance(nativeCoinInfo?.ID))}
                                    </span>
                                    <span className={`${s.overviewItemName}`}>
                                        {nativeCoinInfo?.displayCoinName}
                                    </span>
                                    <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Plus-Yellow-Sq_01'}
                                        onClick={() => {
                                            setShowDepositPanel(true);
                                            setDepositChainTokenInfo(nativeCoinInfo);
                                        }} />
                                </div>
                            }
                            <div className={s.overviewItem}>
                                <CustomIcon width={18} height={18} imgName={`Picture/${currentCoinInfo?.icon}`} />
                                <span className={`${s.balance} color-yellow`}>
                                    {convertWalletBalance(getSpecifiedCoinBalance(currentCoinInfo?.ID))}
                                </span>
                                <span className={`${s.overviewItemName}`}>
                                    {currentCoinInfo?.displayCoinName}
                                </span>
                                <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Plus-Yellow-Sq_01'}
                                    onClick={() => {
                                        if (isNativeCoin()) {
                                            setShowDepositPanel(true);
                                            setDepositChainTokenInfo(currentCoinInfo);
                                        }
                                        else if (currentCoinInfo?.ID === LfgMainnetId || currentCoinInfo?.ID === LfgTestnetId) {
                                            setShowSwapLfg(true);
                                        }
                                    }} />
                            </div>
                        </div>
                    </div>
                    <CustomIcon width={50} height={50} className={`${s.packIcon}`} imgName={'Picture/UI_Picture_Cardpack_01'}/>
                    <div className={`${s.buyName} color-white`}>{`$${symbolName}`}</div>
                    {/*<div className={`${s.tgeProgressRoot} flex_center_start`}>
                        <div className={`${s.tgeProgressText}`}>
                            {`${curCardKindNum || 0}/${cardKindMaxNum || 0}`}
                        </div>
                        <div className={`${s.tgeProgressContent}`}
                            style={{
                                width: curCardKindNum >= cardKindMaxNum ? '100%' : `${cardKindMaxNum > 0 ? curCardKindNum * 100 / cardKindMaxNum : 0}%`
                            }}>
                        </div>
                    </div>*/}
                    <div className={s.amountWrap}>
                        <CustomIcon width={35} height={35} imgName={'Button/UI_Button_Minus-White-Irr_01'}
                            style={{ position: 'absolute', left: '90px' }}
                            onClick={() => controlAmount(0)} />
                        <div className="flex_center_end_col" style={{
                            background: 'rgba(0, 0, 0, 0.3)',
                            width: '70px',
                            height: '24px'
                        }}>
                            <Input ref={inputRef} onChange={amountChangeHandler} onBlur={amountBlurHandler}
                                value={keyNum}
                                style={{ display: keyNumInput ? 'block' : 'none' }} />
                            <div className="flex_start_center" style={{ display: keyNumInput ? 'none' : 'flex', fontSize: '20px' }}
                                onClick={toggleKeyNumInput}>
                                {/*<CustomIcon
                                    className="mr5" width={27} height={27} imgName={'Picture/UI_Picture_Cardpack_01'} />*/}
                                x {keyNum}
                            </div>
                        </div>
                        <CustomIcon width={35} height={35} imgName={'Button/UI_Button_Plus-Yellow-Irr_01'}
                            style={{ position: 'absolute', right: '90px' }}
                            onClick={() => controlAmount(1)} />
                    </div>
                    <div className={`${s.borderWrap}`}>
                        <div className={`df jc-spaceBetween mb5`}>
                            <div className="fs14 fl c-title">{t('Total(inc. fees)')}</div>
                            <div>
                                <div className="tlr">
                                    <span className="color-yellow">
                                        {convertWalletBalance(type === 1 ? (Number(priceWithFee) + Number(fastGasPrice)) : (Number(priceWithFee) - Number(fastGasPrice)))}
                                    </span> {currentCoinInfo?.displayCoinName}
                                </div>
                                <div className="fl">USD&nbsp;
                                    <span className="color-yellow">$
                                        <span className="color-yellow">
                                            {convertWalletBalance((type === 1 ? (Number(priceWithFee) + Number(fastGasPrice)) : (Number(priceWithFee) - Number(fastGasPrice))) * coinUsd)}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            showMoreInfo && <div className={s.topBorder}>
                                <div className={s.item}>
                                    <div className="fs12 fl">{t('From')}</div>
                                    <div className={s.bg}>
                                        {sliceAddr(walletAddr, 4)}
                                        <CustomIcon className="ml5"
                                            onClick={() => copyFn(walletAddr)}
                                            width={12} height={13}
                                            imgName={'Button/UI_Button_CopyNumberWordName_01'} />
                                    </div>
                                </div>
                                <div className={s.item}>
                                    <div className="fs12 fl">{t('To')}</div>
                                    <div className={s.bg}>
                                        {sliceAddr(curCoinMemeFactoryContract, 4)}
                                        <CustomIcon className="ml5"
                                            onClick={() => copyFn(curCoinMemeFactoryContract)}
                                            width={12} height={13}
                                            imgName={'Button/UI_Button_CopyNumberWordName_01'} />
                                    </div>
                                </div>
                                <div className={s.item}>
                                    <div className="fs12 fl">{t('Action')}</div>
                                    <div
                                        className={s.bg}>{transType[type].str} {t('Pack')}</div>
                                </div>
                                <div className={s.item} style={{ color: '#c2c2c2' }}>
                                    <div className="fs12 fl">{t('Pack')}</div>
                                    <div className="fs12">{convertWalletBalance((Number(price)))} {currentCoinInfo?.displayCoinName}
                                    </div>
                                </div>
                                <div className={s.item} style={{ color: '#c2c2c2' }}>
                                    <div className="fs12 fl">{t('Fees')}</div>
                                    <div
                                        className="fs12">{convertWalletBalance(Math.abs(Number(priceWithFee) - Number(price)) + Number(fastGasPrice))} {currentCoinInfo?.displayCoinName}
                                    </div>
                                </div>
                                {/* <div className={s.item} style={{ color: '#c2c2c2' }}>
                                    <div className="fs12 fl">{t('Entropy Fees')}</div>
                                    <div
                                        className="fs12">{convertWalletBalance(Math.abs(Number(momentEntropyFee)))} {currentCoinInfo?.displayCoinName}
                                    </div>
                                </div> */}
                            </div>
                        }
                        <CustomIcon width={30} height={30}
                            className="modelBottomBtn"
                            imgName={!showMoreInfo ? 'Button/UI_Button_Arrow_Down_01' : 'Button/UI_Button_Arrow_Up_01'}
                            onClick={showMoreInfoFn} />
                    </div>
                    <div style={{ height: '15px' }}></div>
                </div>

                <div className="mb30 w100p flex_center_center">
                    <Checkbox
                        onChange={(e) => {
                            setMemeAnony(e.target.checked)
                            seIsAnony(e.target.checked)
                        }} className="hireCheckBox"
                        checked={isAnony}>
                        <span className="ml12">{t('Stay Anonymous')}</span>
                    </Checkbox>
                </div>

                {
                    showPackInAsset && 
                    <div className={`${s.showPackAsset}`} onClick={() =>{
                        if (clubUserId > 0){
                            if (keyNum + curCardKindNum >= cardKindMaxNum){
                                setMemeAssetParam({
                                    page: 1
                                })
                            }
                            else{
                                setMemeAssetParam({
                                    page: 2,
                                    clubUserId: clubUserId,
                                })
                            }
                            navigate(gameMemeClubAssetsPath);
                        }
                    }}>
                        {t('Show This Pack in My Asset')}
                    </div>
                }

                <AntModal
                    width='330px'
                    title={t('Get More {{coin}}', {coin: 'Crypto'})}
                    className="confirmModalWrap"
                    centered={true}
                    open={showDepositPanel}
                    onOk={() => setShowDepositPanel(false)}
                    onCancel={() => setShowDepositPanel(false)}
                    zIndex={7777}
                >
                    <ModelDeposit
                        topUpCode={topUpCode}
                        walletAddr={walletAddr}
                        setShowDepositPanel={setShowDepositPanel}
                        chainTokenInfo={depositChainTokenInfo} />
                </AntModal>
                <AntModal
                    width='330px'
                    title={t('INSUFFICIENT BALANCE')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showInsufficientCrpto}
                    onOk={() => setShowInsufficientCrpto(false)}
                    onCancel={() => setShowInsufficientCrpto(false)}
                    destroyOnClose={true}
                    zIndex={7777}
                >
                    <ModalInsufficientCrpto
                        insufficientAmount={insufficientAmount}
                        userInfo={userInfo} 
                        coinId={coinId}
                        closeFn={() => {
                            setShowInsufficientCrpto(false);
                        }}
                        type={2} />
                </AntModal>
                <AntModal
                    width='330px'
                    title={t('SWAP $MATIC AND $LFG')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showSwapLfg}
                    onOk={() => setShowSwapLfg(false)}
                    onCancel={() => setShowSwapLfg(false)}
                    destroyOnClose={true}
                    zIndex={7777}
                >
                    <ModalSwapLfg
                        userInfo={userInfo}
                        closeFn={() => {
                            setShowSwapLfg(false);
                        }} />
                </AntModal>
            </div>
            <CustomIcon width={30} height={30}
                className="modelBottomSetGas"
                imgName={'Button/UI_Button_Gas_01'}
                onClick={() => {
                    setShowGasMode(true);
                }}
            />

            <div className="modelBottomBtn">
                {
                    done ?
                        <ButtonFactory onClick={closeFn}>
                            {
                                subFailded ? 
                                    noSuccessText : 
                                    <div className={`${s.successText} flex_center_center`}>
                                        {t('Succeed')}
                                    </div>
                            }
                        </ButtonFactory> :
                        curCardKindNum >= cardKindMaxNum ?
                            <ButtonFactory disabled={true}>
                                {t('Supply Reached Limit')}
                            </ButtonFactory> :
                            <ButtonFactory width={150} disabled={getPriceLoding} loading={getPriceLoding} onClick={transFn}>
                                {subLoading ? <div className={s.web3LoadingLine}>
                                    <CustomIcon rotating={true} className="ml5" width={16} height={16}
                                        imgName={'Picture/UI_Picture_Loading_01'} />
                                    <span className="fs18 ml5 color-black">{t('PROCESSING')}</span>
                                </div> : type === 1 ? t('BUY NOW') : t('SELL NOW')}
                            </ButtonFactory>
                }
            </div>
        </>
    )
}
const mapStateToProps = ({ app }) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo,
        walletBalanceList: app.walletBalanceList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setMemeAssetParam: (params) => {
            return dispatch({
                type: "app/setMemeAssetParam",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalMomentBuy));
