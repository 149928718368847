import React, {memo, useState, useMemo, useEffect} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {Input, Button, Modal} from "antd";
import {
    officialInviteCode,
    joinChartPre,
    taPush,
    referralcode_bind,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import {useWallets} from "@privy-io/react-auth";
import momentTradeVolSharingRate from "@/utils/json/momentTradeVolSharingRate.json";

const ModalMomentTipsRaferralTrade = (
    {
        title = "How to earn",
        closeFn = () => {}
    }
) => {
    const { t, i18n } = useTranslation();

    return (
        <div className={`${s.wrap} flex_center_start_col`}>
            <div className="w100p flex_center_center fs20 fb mt20 mb20 color-EEEEEE">{t(title)}</div>

            <div className={`${s.des} fs12 color-moment-light-gray`}>
                <p className="mt10">{t('Track your referral trading volume with the progress bar. As your invited members buy or sell moments, the bar will update.')}</p>
                <p className="mt10">{t('Once their total trading volume reaches the goal, you will unlock the next reward, and the progress bar will refresh.')}</p>
            </div>

            <CustomIcon 
                className={`momentCloseBtn`} 
                width={40} 
                height={40} 
                imgName={`Picture/UI_Picture_Icon_Close_02`}
                onClick={() => {
                    closeFn();
                }} 
            />
        </div>
    );
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalMomentTipsRaferralTrade));
