import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import {useNavigate, useParams} from "react-router-dom";
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import MemeClubPack from "@/common/MemeClubPack";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import { Tabs } from "antd";
import SwapTrade from "./SwapTrade";
import { 
    gameMemeClubRevealMemePackPath,
    gameMomentClubRevealMomentPackPath,
} from "@/routes/config";
import {
    maxMemeUnpackNftCount,
} from "@/utils/env";
import { AutoStrangeRule } from "@/utils/strangeRule";
import Chats from "./Charts";
import { 
    getWeb3Config,
    convertWalletBalance,
} from "@/utils/common";
import { momentPreOpenpack,getActiveList } from "@/utils/momentClub";
const Trade = (
    {
        clubId,
        chainInfo,
        coinBalance,
        walletBalanceList,
        reFreshBalance,
        // coinChain = {
        //     chainId:137,
        //     address: '0x8bff1bD27E2789Fe390AcabC379C380a83B68E84',
        //     decimals:18,
        //     icon:"UI_Picture-Currency_LFG_01",
        //     displayCoinName: '$LFG'
        // },
       
        clubBasicInfo,
        setMemeUnpackInfo,
        setMemeUnpackCount,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [list, setList] = useState([])
    const [nextCursor,setNextCursor] = useState(-1)
    const [swapTradeType, setSwapTradeType] = useState(0);
    const [nftRange, setNftRange] = useState([0, 0]);
    const [suitablePackCount, setSuitablePackCount] = useState(0);
    const [reachMaxPack, setReachMaxPack] = useState(false);
    const getList = (clubUserId,nextCursor=0) => {
        
    }

    const [unpackInfo,setUnpackInfo] = useState()

    const getUnpackInfo = (clubUserId)=>{
        momentPreOpenpack(clubUserId,[]).then(res=>{
            setUnpackInfo(res)
        }).catch(e=>{
            console.error(e);
        })
    }
    

    useEffect(() => {
        clubId && getList(clubId,0)
    }, [clubId])

    const getMoreList = ()=>{
        getActiveList(clubId,nextCursor)
    }

    const {
        clubUserId,
        coinTokenAddr,
        swapPoolAddr,
        clubInfo,
        holdNum,
        sellPrice,
        historyPrice,
        buyPrice,
    } = useMemo(()=>{
        return clubBasicInfo || {}
    },[clubBasicInfo])

    const range = historyPrice ? Math.round((sellPrice - historyPrice) / historyPrice * 100) : 0;

    useEffect(() => {
        clubId && holdNum>0 && getUnpackInfo(clubId,0)
    }, [clubId,holdNum])
    

    const {coinToNFTRatio,coverUrl,clubName,symbolName,ftTotalSupply,airdropPercent,cardsTotalSupply,openPackRatio,symbolImageUrl,isFT} = useMemo(()=>{
        return clubInfo || {}
    },[clubInfo])

    const { name ,chainId,icon,displayCoinName} = useMemo(() => {
        return chainInfo || {}
    }, [chainInfo])
    const [coinContract,setCoinContract] = useState('')

    const getContractInfo = async () =>{
        const web3Config = await getWeb3Config();
        const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === chainInfo?.ID));
        setCoinContract( contractInfo?.coinContract)
    }

    useEffect(()=>{
        getContractInfo();
    },[chainInfo])

    useEffect(() => {
        if (clubInfo){
            const {
                coinToNFTRatio,
                ftTotalSupply,
                airdropPercent,
                cardsTotalSupply,
                openPackRatio
            } = clubInfo;
            let range = [];
            let evenCardToToken = parseInt(ftTotalSupply * (airdropPercent / 10000) / cardsTotalSupply * 2);
            let min = evenCardToToken * ( 1 / (openPackRatio + 1));
            let minCount = Math.floor(min / coinToNFTRatio) * holdNum;
            let max = evenCardToToken * ( openPackRatio / (openPackRatio + 1));
            let maxCount = Math.floor(max / coinToNFTRatio) * holdNum;
            range.push(minCount);
            range.push(maxCount);
            setNftRange(range);
            if (maxCount > maxMemeUnpackNftCount){
                setReachMaxPack(true);
                let suitablePackNum = maxMemeUnpackNftCount * coinToNFTRatio / max;
                setSuitablePackCount(Math.max(Math.floor(suitablePackNum), 1));
            }
            else{
                setReachMaxPack(false);
                setSuitablePackCount(holdNum);
            }
        }
    }, [clubInfo, holdNum])

    // coinChain = {
    //     chainId:137,
    //     address: '0x8bff1bD27E2789Fe390AcabC379C380a83B68E84',
    //     decimals:18,
    //     icon:"UI_Picture-Currency_LFG_01",
    //     displayCoinName: '$LFG'
    // },
    // nativeChain = {
    //     chainId:137,
    //     address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    //     decimals:18,
    //     icon:"UI_Picture-Currency_MATIC_01",
    //     displayCoinName: "$MATIC"
    // },
    const coinChain = useMemo(()=>{
        return {
            chainId: chainId,
            address: coinTokenAddr,
            // chainId: 137,
            // address: '0x8bff1bD27E2789Fe390AcabC379C380a83B68E84',
            decimals:18,
            icon:symbolImageUrl,
            displayCoinName:"$"+symbolName
        }
    },[coinTokenAddr,chainId,coverUrl,symbolName,symbolImageUrl])

    const nativeChain = useMemo(()=>{
        return {
            chainId: chainId,
            address: coinContract,
            // chainId: 137,
            // address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
            decimals:18,
            icon:icon,
            displayCoinName:displayCoinName,
            chainName: name.split(' ')[0].toLocaleLowerCase()
        }
    },[coinContract,chainId,icon,displayCoinName,name])
    const nativeBalance = useMemo(()=>{
        // console.log(`walletBalanceList=------>`,walletBalanceList,chainInfo?.ID);
        return walletBalanceList?.[chainInfo?.ID] || 0
    },[walletBalanceList,chainInfo])
     
    const items = [
        {
            key: 1,
            label: `${AutoStrangeRule(t('Buy'))}`,
            children: <SwapTrade 
            swapPoolAddr={swapPoolAddr} 
            coinBalance={coinBalance} 
            chainInfo={chainInfo} 
            coinToNFTRatio={coinToNFTRatio} 
            tradeType={1} 
            nativeChain={nativeChain} 
            reFreshBalance={reFreshBalance}
            nativeBalance={nativeBalance}
            coinChain={coinChain}
            isFT={isFT}
            symbolName={symbolName}
            clubBasicInfo={clubBasicInfo}>
            
            
            </SwapTrade>,
        }, {
            key: 2,
            label: `${AutoStrangeRule(t('Sell'))}`,
            children: <SwapTrade 
            swapPoolAddr={swapPoolAddr} 
            coinBalance={coinBalance} 
            chainInfo={chainInfo} 
            reFreshBalance={reFreshBalance}
            coinToNFTRatio={coinToNFTRatio} 
            tradeType={2}
            nativeBalance={nativeBalance}
            coinChain={coinChain} 
            nativeChain={nativeChain}
            isFT={isFT}
            symbolName={symbolName}
            clubBasicInfo={clubBasicInfo}></SwapTrade>,
        }
    
    ]

    const evenCardToToken = useMemo(()=>{
        return parseInt(ftTotalSupply * (airdropPercent / 10000) / cardsTotalSupply * 2)
    },[ftTotalSupply,airdropPercent,cardsTotalSupply])
    const [chartShow, setChartShow] = useState(true)

    return (
        <div className={`${s.box}`}>
           {holdNum> 0 && <div className={`${s.blockWrap} flex_center_start_col`}>
                <div className="w100p flex_center_between">
                    <div className="momentFont fs12 fw-400 color-moment-gray">{t('You have Packs to Reveal')}</div>
                    <div className="flex_center_center">
                        <CustomIcon className={`mr3`} imgName='Picture/UI_Picture_icon_cardpack' width={20} height={20}></CustomIcon>
                        <span className="momentFont fs16 fb color-moment-light-gray">x&nbsp;{holdNum}</span> 
                    </div>
                </div>
                <div className="w100p flex_center_between mt5">
                    <div className="momentFont fs12 fw-400 color-moment-gray">{t('Total Airdrop Coins')} ${clubName}</div>
                    <div className="flex_center_center">
                    <div className={`${s.offerCoins}`} style={{backgroundImage: `url(${symbolImageUrl})`}}>
                    </div>
                    <span className="momentFont fs16 fb color-moment-light-gray">
                        {`${convertWalletBalance(unpackInfo?.totalOpenCoin)}`}
                    </span>
                    </div>
                </div>
                {/* <div className="w100p flex_center_between">
                    <div className="fs12">{t('NFT Generate')}</div>
                    <div className={`fs18`}>
                        {holdNum}
                    </div>
                </div>     */}
                <AntButton 
                    shape="round" 
                    className={`${s.unpackBtn} btn_public`} 
                    onClick={() => {
                        navigate(`${gameMomentClubRevealMomentPackPath}/${clubUserId}`);
                        setMemeUnpackInfo(null);
                        //setMemeUnpackCount(suitablePackCount);
                    }}>
                    <div className={`momentFont fs18 fb color-gray`}>
                        {
                            reachMaxPack ? t('Open {{count}} Packs', {count: suitablePackCount}) : t('Reveal All Packs')
                        }
                    </div>
                </AntButton>
            </div>
            }
            <div className={`${s.chartWrap} ${chartShow ? '' : s.chartHide}`}>
                <div className={`${s.chartTitle} momentFont`}>
                    {chartShow?t('Hide Chart')
                    :
                    <div className={`momentFont fs12 flex_center_center`}>
                        {`$${clubName} moment`}
                        <CustomIcon imgName={`Picture/${chainInfo?.icon}`} className="ml5 mr5" width={17} height={17}/>
                        {convertWalletBalance(buyPrice)} ${chainInfo?.displayCoinName}
                    </div>}
                    <CustomIcon className={s.chartIcon} onClick={() => { setChartShow(!chartShow) }} imgName={chartShow ? 'Button/UI_Button_Arrow_Up_01' : 'Button/UI_Button_Arrow_Down_01'} width={22} height={12}></CustomIcon>
                </div>
                <div className={s.kChartWrap} style={{ display: chartShow ? "block" : "none" }}>
                    <Chats coinChain={coinChain} nativeChain={nativeChain} />
                </div>
            </div>
            
            {/*<div className={s.tradeWrap}>
                <Tabs
                    defaultActiveKey={1}
                    items={items}
                    className="buyAndSellTabs activeSizeChange"
                    destroyInactiveTabPane={true}>
                </Tabs>
            </div>*/}
            <div className={`${s.tradeWrap} flex_center_start_col`}>
                {
                    swapTradeType === 0 &&
                    <>
                        <div className={`${s.tradeTypeRoot} flex_center_between`}>
                            <div className={`${s.selectedBar} ${s.buyBar} ${s.commonBarType} flex_center_center`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_TabBar_Buy_01.png)`}}>
                                {t('Buy')}
                            </div>
                            <div className={`${s.unselectedBar} ${s.sellBar} ${s.commonBarType} flex_center_center`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_TabBar_Sell_02.png)`}} onClick={() =>{
                                setSwapTradeType(1);
                            }}>
                                {t('Sell')}
                            </div>
                        </div>
                        <SwapTrade 
                            swapPoolAddr={swapPoolAddr} 
                            coinBalance={coinBalance} 
                            chainInfo={chainInfo} 
                            coinToNFTRatio={coinToNFTRatio} 
                            tradeType={1} 
                            nativeChain={nativeChain} 
                            reFreshBalance={reFreshBalance}
                            nativeBalance={nativeBalance}
                            coinChain={coinChain}
                            isFT={isFT}
                            symbolName={symbolName}
                            clubBasicInfo={clubBasicInfo}>
                        </SwapTrade>
                    </>
                }
                {
                    swapTradeType === 1 &&
                    <>
                        <div className={`${s.tradeTypeRoot} flex_center_between`}>
                            <div className={`${s.unselectedBar} ${s.buyBar} ${s.commonBarType} flex_center_center`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_TabBar_Buy_02.png)`}} 
                                onClick={() =>{
                                    setSwapTradeType(0);
                            }}>
                                {t('Buy')}
                            </div>
                            <div className={`${s.selectedBar} ${s.sellBar} ${s.commonBarType} flex_center_center`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_TabBar_Sell_01.png)`}}>
                                {t('Sell')}
                            </div>
                        </div>
                        <SwapTrade 
                            swapPoolAddr={swapPoolAddr} 
                            coinBalance={coinBalance} 
                            chainInfo={chainInfo} 
                            reFreshBalance={reFreshBalance}
                            coinToNFTRatio={coinToNFTRatio} 
                            tradeType={2}
                            nativeBalance={nativeBalance}
                            coinChain={coinChain} 
                            nativeChain={nativeChain}
                            isFT={isFT}
                            symbolName={symbolName}
                            clubBasicInfo={clubBasicInfo}>
                        </SwapTrade>
                    </>
                }
            </div>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMemeUnpackInfo: (params) => {
            return dispatch({
                type: "app/setMemeUnpackInfo",
                payload: params,
            });
        },
        setMemeUnpackCount: (params) => {
            return dispatch({
                type: "app/setMemeUnpackCount",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Trade));
