import React, { memo, useEffect, useMemo, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { Button, Input, InputNumber, Select, Form, List, Card, Modal, Radio, message } from "antd";
import AntModal from "@/common/AntModal"
import AntButton from "@/common/AntButton"
import ButtonFactory from "@/common/ButtonFactory";
const { Option } = Select;


const defaultData = {
  1: {
    enumStrs: [],
    maxNum: 100,
    minNum: 1,
    type: 1,
  },
  2: {
    enumStrs: [
      { enumName: 'Enum', weight: 10 }
    ],
    maxNum: 0,
    minNum: 0,
    type: 2,
  }
}

const TemplateForm = (
  {
    template,
    visible,
    onCancel,
    onCreate,
    onEdit,
    isEdit,
    attrTypeConfig,
    onDelete,
    selectedInd,
  }
) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();



  const [attrData, setAttrData] = useState(null)
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [enumValues, setEnumValues] = useState([]);
  const [attrName, setAttrName] = useState(null)
  const [dataType, setDateType] = useState(1)

  useEffect(() => {
    console.log(template);
    template && setAttrData(template)
  }, [template])

  useEffect(() => {
    if (!attrData) return
    setEnumValues(attrData.enumStrs || [])
    setMinValue(attrData.minNum || 1)
    setMaxValue(attrData.maxNum || 100)
    setAttrName(attrData.attrName || 'Attribute')
    setDateType(attrData?.type || 1)
  }, [attrData])


  const isNumerical = useMemo(() => {
    return dataType === 1;
  }, [dataType])
  const handleAddEnum = () => {
    if (enumValues?.length < 8) {
      setEnumValues([...enumValues, { enumName: '', weight: 10 }]);
    }
  };

  const handleRemoveEnum = (index) => {
    setEnumValues(enumValues.filter((_, i) => i !== index));
  };

  const save = () => {
    if (!attrName) {
      message.error({ content: 'AttrName is empty' })
      return
    }
    const _enumValues = enumValues.filter(i => Object.keys(i)?.length !== 0)
    const values = { minNum: minValue, maxNum: maxValue, enumStrs: _enumValues, attrName, type: dataType };
    isEdit ? onEdit(values) : onCreate(values);
  }

  const changeDataType = (type) => {
    setAttrData({
      ...defaultData[type],
      attrName: attrData?.attrName || 'Attribute',
    })
    // const obj = {
    //     ...defaultData[type],
    //     attrName: attrData?.attrName || 'AttrName',
    //   }

    //   console.log(obj);

  }

  const [loading,setLoading] = useState(false)

  


  return (
    <AntModal
      open={visible}
      width='330px'
      className="confirmModalWrap"
      title={`${isEdit ? t('Edit') : t('Creat')}`}
      onCancel={onCancel}

    >
      <div>

        {/* <div className={s.line}>{t('EnumType')} : {attrTypeConfig[template.type]?.name}</div> */}
        <Radio.Group className="mb10" onChange={(e) => {
          // setDateType(e.target.value);
          changeDataType(e.target.value)
        }} value={dataType}>
          <Radio className="hireCheckBox" value={1}>{t('Numerical')}</Radio>
          <Radio className="hireCheckBox" value={2}>{t('Categorical')}</Radio>
        </Radio.Group>
        <div className={s.line}>
          <div className={s.label}>{t('AttrName')} : </div>
          <div className={s.val}>
            <Input className={s.input} maxLength={16} value={attrName} onChange={(e) => {
              const val = e.target.value;
              const newValue = val.replace(/[^a-zA-Z0-9\s]/g, '');
              setAttrName(newValue)
            }} />
          </div>
        </div>
        {isNumerical ? (
          <div>
            <div className={s.line}>
              <div className={s.label}>{t('Min :')}</div>
              <div className={s.val}>
                <InputNumber className={s.input} min={0} max={maxValue} value={minValue} onChange={setMinValue} />
              </div>

            </div>
            <div className={s.line}>
              <div className={s.label}>{t('Max :')}</div>
              <div className={s.val}>
                <InputNumber className={s.input} max={999999} min={minValue} value={maxValue} onChange={setMaxValue} />
              </div>

            </div>
          </div>
        ) :
          (

            // <p>枚举值:</p>
            <div className={s.attrList}>
              {enumValues.map((value, index) => (
                <div className={s.attrItem} key={index}>
                  <Input className={`${s.input} ${s.f2}`} maxLength={16} value={value?.enumName} onChange={(e) => {
                    const newEnumValues = [...enumValues];
                    const obj = {
                      ...newEnumValues[index] || {}
                    }
                    const val = e.target.value;
                    const newValue = val.replace(/[^a-zA-Z0-9\s]/g, '');
                    obj['enumName'] = newValue
                    newEnumValues[index] = obj;
                    setEnumValues(newEnumValues);
                  }} />
                  <InputNumber className={`${s.input} commonBeforeInputNumber`} value={value?.weight} 
                  addonAfter="%"
                  onBlur={() => {
                    const newEnumValues = [...enumValues];
                    const obj = {
                      ...newEnumValues[index] || {}
                    }
                    obj['weight'] = value?.weight || 10;
                    newEnumValues[index] = obj;
                    setEnumValues(newEnumValues);
                  }}
                  onChange={(value) => {
                    const newEnumValues = [...enumValues];
                    const obj = {
                      ...newEnumValues[index] || {}
                    }
                    obj['weight'] = value;
                    newEnumValues[index] = obj;
                    setEnumValues(newEnumValues);
                  }} />
                  <div className="flex_center_center">
                    <CustomIcon onClick={() => handleRemoveEnum(index)} height={16} width={16} imgName="Button/UI_Picture_Settings_Delete_01" />
                  </div>

                </div>
              ))}

              <div className="flex_center_start w100p">
                {enumValues.length < 8 && <div className={s.addEnum} onClick={handleAddEnum}>{t('+ Add')}</div>}
                {/* <AntButton className={`${s.addEnum} btn_white`} disabled={loading} loading={loading} onClick={getEnums}>{t('+ Auto Generate')}</AntButton> */}
              </div>


            </div>
          )

        }
        <div className="flex_center_end mt15">
          <div className="flex_center_center" onClick={() => onDelete(selectedInd)} >
            <CustomIcon height={16} width={16} imgName="Button/UI_Picture_Settings_Delete_01" ></CustomIcon>{t('Delete')}
          </div>

        </div>
        <div className="flex_center_center mt30">
          <ButtonFactory size={21} scale={1.1} color={3} isMirror={true} onClick={onCancel}>{t('Cancel')}</ButtonFactory>
          <ButtonFactory size={21} scale={1.1} className="ml30" onClick={save}>{isEdit ? t('Save') : t('Creat')}</ButtonFactory>

        </div>

      </div>
    </AntModal>
  );
}

const mapStateToProps = ({ app }) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(TemplateForm));
