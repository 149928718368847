import React, {memo, useMemo, useEffect, useState, useCallback} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Tabs, Button, Modal} from 'antd';
import Navs from "../MainNavs";
import OtherTranList from "../OtherTranList";
import CustomIcon from "../CustomIcon";
import AvatarInfo from "../AvatarInfo";
import ModelTrans from "../../model/ModelTrans";
import {sliceStr} from "@/utils/common";
import {useNavigate, useParams} from "react-router-dom";
import {
    stringToNumber,
    getearninfo,
    getfriendtrade_rank_selfdata,
    getProfile,
    getTranList,
    gettrade_price,
    getAvatarFromUserInfo,
    unit,
    chgFn
} from "@/utils/common";
import {balancePath, publicWishClaim, publicWishClaimPath} from "@/routes/config";
import ButtonFactory from "../ButtonFactory";
import MainHeader from "src/common/MainHeader";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";

const OtherTransRecord = (
    {
        showOtherTransContent,
        setShowOtherTransContent,
        curentOtherUserInfo,
        userInfo,
        setOpenTranPanel,
        backFn,
        isGlobalChatGroup,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const [keyPrice, setKeyPrice] = useState('--')
    const {buyFirstKey, payEntryFee, wishNeedClaim} = useMemo(() => {
        return userInfo?.selfData || {}
    }, [userInfo])
    const {
        accountType,
        youOwnKeyNum,
        holdersNum,
        holding,
        portfolio,
        userId,
        platformData,
        buyPriceObj,

    } = useMemo(() => {
        // console.log(`curentOtherUserInfo`,curentOtherUserInfo);
        return curentOtherUserInfo || {};
    }, [curentOtherUserInfo])

    useState(() => {
        if (params?.userId) {
            gettrade_price(1, params?.userId).then(res => {
                setKeyPrice(res?.keyPrice)
            }).catch(e => {
                console.error(e);
            })
        }
    }, [params])


    const {chg, isUp} = useMemo(() => {
        const {sellPrice, historyPrice} = buyPriceObj || {};

        const chg = chgFn(sellPrice, historyPrice);
        const isUp = chg === '-' ? true : chg >= 0 ? true : false;
        return {chg, isUp}
    }, [buyPriceObj])

    const {platformMap, useType, defaultName} = useMemo(() => {
        return platformData || {};
    }, [platformData])
    const [done, setDone] = useState(false);
    const [subLoading, setSubLoading] = useState(false);


    const items = [
        {
            key: 1,
            label: `${t('Members')}`,
            children: <OtherTranList type={1}/>,
        },
        {
            key: 2,
            label: `${t('Joined')}`,
            children: <OtherTranList type={2}/>,
        },
        {
            key: 3,
            label: `${t('Activities')} `,
            children: <OtherTranList type={3}/>,
        },
        {
            key: 4,
            label: `${t('Trades')}`,
            children: <OtherTranList type={4}/>,
        },
    ];

    const wishItems = [
        {
            key: 1,
            label: `${t('Members')} (${holdersNum || 0})`,
            children: <OtherTranList type={1}/>,
        },
        {
            key: 3,
            label: `${t('Activities')}`,
            children: <OtherTranList type={3}/>,
        },
    ];

    const {displayName, profileDeepLink} = useMemo(() => {
        if (platformMap && JSON.stringify(platformMap) !== '{}') {
            return platformMap[useType]
        } else {
            return {
                displayName: defaultName
            }
        }
    }, [platformMap, useType, defaultName])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(curentOtherUserInfo)
    }, [platformMap, useType, curentOtherUserInfo])

    const [earninfo, setEarninfo] = useState({})
    const [rankIdx, setRankIdx] = useState('')
    const getInfo = () => {
        if (params.userId) {
            getTranList(Number(params.userId), type, 0).then(res => {
                setRankIdx(res.data.claimRankIdx)
                setEarninfo(res.data)
            }).catch(e => {
                console.log(e);
            })
        } else {
            setTimeout(() => getInfo())
        }
    }
    useEffect(() => {
        getInfo()
    }, [params.userId])

    const [showTransModel, setShowTransModel] = useState(false);
    const [type, setType] = useState(1);
    const showTransModelFn = useCallback((type) => {
        // if (!(buyFirstKey && payEntryFee)) {
        if (wishNeedClaim) {
            navigate(publicWishClaimPath)
            return
        } else if (!(buyFirstKey && payEntryFee)) {
            navigate(balancePath)
            return
        }

        // }
        setType(type)
        setShowTransModel(true)
    }, [buyFirstKey, payEntryFee, wishNeedClaim])

    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])

    return (
        <div className={`${s.wrap} publicBg`}>
            <MainHeader hasBackBtn={true}></MainHeader>
            <div className={s.header}>
                <div className={s.htop}>
                    {/* <div className={s.back} onClick={() => backFn()}>
                        <CustomIcon width={28} height={40}
                            imgName={'Button/UI_Button_Exit_01'} />
                    </div> */}
                    <div className={s.right}>
                        <div className={s.r1}>
                            {profileDeepLink ? <a className={s.user} href={profileDeepLink}>
                                    <AvatarInfo size={70} src={avatarUrl}/>
                                </a> :
                                <div className={s.user}>
                                    <AvatarInfo size={70} src={avatarUrl}/>
                                </div>
                            }

                        </div>
                        <div className={s.r2}>
                            <div className={s.r2t}>
                                <div className={`fs16 ${s.fl}`}>
                                    <span className="fs18 mr5 ">{sliceStr(displayName, 14, true)}</span>
                                    {profileDeepLink &&
                                        <CustomIcon width={16} height={16}
                                                    imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                    name={'Button/UI_Button_Ball-White_Twitter_On'}/>}
                                    {accountType === "wish" && <div className={`${s.wishTag} ml5`}>
                                        {/*<CustomIcon width={12} height={13} className="mr5" imgName={'icon_wish'} name={'icon_wish'} />*/}
                                        {t('WISH')}
                                    </div>}
                                </div>
                            </div>
                            <div className={s.r2b}>
                                <div className={s.rr1}>

                                    <div className={`${s.keys} fs14 color-blue-light white-space-pre-wrap`}>
                                        <Trans
                                            i18nKey="You own num key/keys/wish/wishes"
                                            defaults="You own <active>{{count}}</active> {{sth}}"
                                            values={{
                                                count: youOwnKeyNum,
                                                sth: accountType === 'wish' ? youOwnKeyNum > 1 ? 'wishes' : 'wish' : youOwnKeyNum > 1 ? t('keys') : t('key')
                                            }}
                                            components={{active: <span className="color-yellow fs16"></span>}}
                                        />
                                    </div>
                                    <div className="color-blue-light fs14" onClick={() => {
                                        setShowOtherTransContent(true)
                                    }}>
                                        <Trans
                                            i18nKey="xx Member/Members"
                                            defaults="<white>{{memberCount}}</white> {{member}}"
                                            values={{
                                                memberCount: holdersNum,
                                                member: holdersNum > 1 ? t('Members') : t('Member')
                                            }}
                                            components={{white: <span className=" color-white"></span>}}
                                        />
                                        {accountType !== 'wish' && (<>,
                                            <Trans
                                                i18nKey="xx Joined"
                                                defaults="<white>{{joinedCount}}</white> Joined"
                                                values={{joinedCount: holding}}
                                                components={{white: <span className=" color-white"></span>}}
                                            />
                                        </>)}
                                    </div>
                                    {/* <div className="color-gray fs14">{t('Led by name', { name: sliceStr(managerProfile?.displayName, 'all')})}</div> */}
                                </div>
                                <div className={s.rr2}>
                                    {/* <AntButton className={`${s.buy} btn_public widthAuto`} shape="round" onClick={() => {
                                        console.log(`clice TRADE`);

                                        setShowOtherTransContent(true)
                                    }}>
                                        {youOwnKeyNum ? t('TRADE') : t('BUY')}
                                    </AntButton> */}
                                    <div className="fs14">{stringToNumber(buyPriceObj?.keyPrice, 4)} {unit}</div>

                                    <div className={s.percent}>
                                        <div className={`${isUp ? s.upIcon : s.downIcon}`}></div>
                                        <div
                                            className={`${s.percentVol} fs12 ${isUp ? s.upColor : s.downColor}`}> {chg}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {JSON.stringify(curentOtherUserInfo) !== '{}' &&
            <> */}
                        {/* <div className={s.middle} onClick={() => { setShowOtherTransContent(true) }}>
                <div>You own {youOwnKeyNum} key</div>
                <div className="color-gray">{stringToNumber(buyPriceObj?.keyPrice, 4)} {unit}</div>
              </div>
              <div className={s.bottom} onClick={() => { setShowOtherTransContent(true) }}>
                <div className="color-gray">{holdersNum} holders {holding} holding</div>
                <div className="color-gray">Key Price</div>
              </div> */}
                        {/* </>
          } */}
                    </div>
                </div>
            </div>
            <div className={`${s.mid}`}>
                <div className={s.item}>
                    <ButtonFactory
                        size={33}
                        color={3}
                        iconName={'UI_Picture-Currency_MATIC_01'}
                    >
                        <div className="ml10 color-black fs18">{stringToNumber(earninfo?.portfolio, 4)}</div>
                    </ButtonFactory>
                    <div className={s.label}>{t('Portfolio Value')}</div>
                </div>
                <div className={s.item}>
                    <ButtonFactory
                        size={33}
                        color={3}
                        iconName={'UI_Picture-Currency_MATIC_01'}
                    >
                        <div className="ml10 color-black fs18">{stringToNumber(buyPriceObj?.keyPrice, 4)}</div>
                    </ButtonFactory>
                    <div className={s.label}>{t('Key Price')}</div>
                </div>
                <div className={s.item}>
                    <ButtonFactory
                        size={33}
                        color={3}
                        iconName={'UI_Picture-Currency_Rank_01'}
                    >
                        <div className="ml10 color-black fs18">{rankIdx ? rankIdx : '--'}</div>
                    </ButtonFactory>
                    <div className={s.label}>{t('Rank')}</div>
                </div>
                {/* <div className={s.container}>
                    <div className={`fs12 color-gray tll`}>
                        {t('Portfolio Value')}
                    </div>
                    <div className="df jcfe aic mt10">
                        <CustomIcon width={16} height={16} className="mr5"
                            imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                            name={'Picture/UI_Picture-Currency_MATIC_01'} />
                        <div className="fs14 color-white fb df jcc">{stringToNumber(earninfo?.portfolio, 4)}</div>
                    </div>
                </div>
                <div className={s.container}>
                    <div className={`fs12 color-gray tll`}>
                        {t('Key Price')}
                    </div>
                    <div className="df jcfe aic mt10">
                        <CustomIcon width={16} height={16} className="mr5"
                            imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                            name={'Picture/UI_Picture-Currency_MATIC_01'} />
                        <div
                            className="fs14 color-white fb df jcc">{stringToNumber((buyPriceObj?.keyPrice || keyPrice), 4) || '--'}</div>
                    </div>
                </div>
                <div className={s.container}>
                    <div className={`fs12 color-gray tll`}>
                        {t('Rank')}
                    </div>
                    <div className="df jcfe aic mt10">
                        <div className="fs14 color-white fb df jcc">{rankIdx ? rankIdx : '--'}</div>
                    </div>
                </div> */}
            </div>
            <div className={s.content}>
                <Tabs
                    defaultActiveKey={1}
                    items={accountType === 'wish' ? wishItems : items}
                    className="tptabs"
                    destroyInactiveTabPane={true}
                />
            </div>
            <div className={`${s.bottomWrap} ${youOwnKeyNum ? s.hasKey : ''}`}>
                {!youOwnKeyNum ?
                    <>
                        <div className="df aic jcc mb20">
                            <Trans
                                i18nKey="You need a xx to access"
                                defaults="You need a <coinImg></coinImg> to access"
                                values={{what: 'world'}}
                                components={{
                                    coinImg: <CustomIcon className="ml5 mr5" width={16} height={16}
                                                         imgName={'Picture/UI_Picture_Cards_03'}
                                                         name={'Picture/UI_Picture_Cards_03'}/>
                                }}
                            />
                        </div>
                        <AntButton className="fs16 btn_public" onClick={() => {
                            showTransModelFn(1)

                        }} style={{margin: '0 auto'}}>{t('BUY NOW')}</AntButton>
                    </> :
                    <>
                        <div className="color-blue-light">{t('You Own')}</div>
                        <div className="df aic jc-spaceBetween">
                            <ButtonFactory
                                size={32}
                                scale={1.38}
                                isMirror={true}
                                color={3}
                                onClick={() => youOwnKeyNum && showTransModelFn(2)}>
                                {t('SELL')}
                            </ButtonFactory>
                            <div className={s.keyNumber}><CustomIcon className="ml5 mr5" width={45} height={45}
                                                                     imgName={'Picture/UI_Picture_Cards_01'}
                            />x {youOwnKeyNum}</div>
                            <ButtonFactory
                                size={32}
                                scale={1.38}
                                onClick={() => showTransModelFn(1)}>
                                {t('BUY')}
                            </ButtonFactory>
                        </div>
                    </>
                }
            </div>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={showTransModel}
                destroyOnClose={true}
                zIndex={4000}
                onOk={() => setShowTransModel(false)}
                onCancel={() => setShowTransModel(false)}
                keyboard={!subLoading || done}
                maskClosable={!subLoading || done}
            >
                <ModelTrans
                    type={type}
                    keyId={userId}
                    profile={profile}
                    closeHandle={() => {
                        setShowTransModel(false)
                    }}
                    accountType={accountType}
                    done={done}
                    setDone={setDone}
                    subLoading={subLoading}
                    setSubLoading={setSubLoading}
                />
            </AntModal>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        showOtherTransContent: app.showOtherTransContent,
        curentOtherUserInfo: app.curentOtherUserInfo,
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowOtherTransContent: (params) => {
            return dispatch({
                type: "app/setShowOtherTransContent",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(OtherTransRecord));
