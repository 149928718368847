import {useEffect, useState} from "react";
import s from "./index.module.less";
import CustomIcon from "src/common/CustomIcon";

import {useTranslation, Trans} from 'react-i18next';


const ModelClaimAll = (
    {
        info
    }
) => {
    const {t, i18n} = useTranslation();
    const {employeeNum,earnLfg,earnEnergy} = info
    return (
        <div className={s.wrap}>
            <div className="">{t("You have claimed xx Clubs' yield!",{count:employeeNum})}</div>
            <div className="df alc jcc w100p mt10">
                {t('Total Earnings')}: &nbsp; 
                <CustomIcon width={16} height={16} className="ml5 mr5" imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                            />{earnLfg}
                </div>
        </div>
    );
};

export default ModelClaimAll;
