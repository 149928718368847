import React, {memo, useMemo, useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../../common/CustomIcon";
import {Button, Modal, Spin, message} from 'antd';
import {
    getuserinfo,
    getPrice,
    timerDelay,
    CHAT_TYPE,
    get_trivia_run_data,
    getConfig,
    easeJoinGroup,
    get_trivia_cond,
    convertTimestamp,
    post_live_announce_query,
    post_live_announce_del,
    joinChat,
    getProfile,
    displayFilter,
    getAvatarFromProfile
} from "@/utils/common";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import ModelTransaction from "../../../model/ModelTransaction";
import ModelPermission from "../../../model/ModelPermission";
import Nosleep from "nosleep.js";
import LivePanel from "src/common/LivePanel";
import ChatContent from "src/common/ChatContent";
import ChatContentHeader from "src/common/ChatContentHeader";
import {leave, rtc, checkCamera, checkMicrophones} from "@/utils/basicVoiceCall";
import OtherTransRecord from "src/common/OtherTransRecord"
import {LoadingOutlined} from '@ant-design/icons';
import ModelVote from "src/model/Votes/ModelVote";
import ModelVoteControl from "src/model/Votes/ModelVoteControl";
import ModelRank from "src/model/Votes/ModelRank";
import ModelLiveTips from "src/model/Votes/ModelLiveTips";
import Reserve from "@/assets/images/reserve.png";
import RedPacket from "@/common/ChatContent/RedPacket";
import {balancePath, mainHomePath, publicWishClaimPath} from "@/routes/config";
import MainHeader from "src/common/MainHeader";
import AntModal from "@/common/AntModal";

const Chat = (
    {
        setShowOtherTransContent,
        curentOtherUserInfo,
        setCurentOtherUserInfo,
        userInfo,
        showOtherTransContent,
        deleteChatCustomMessage,
        customChatMap,
        curRtcInfo,
        setGroupInfo,
        groupInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const {userId} = useParams();
    const nosleep = new Nosleep();
    const navigate = useNavigate();
    const selfUid = userInfo?.userId;

    const [triviaHostId, setTriviaHostId] = useState(0);

    const [openTranPanel, setOpenTranPanel] = useState(false);

    const [showLivePanel, setShowLivePanel] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [showPermission, setShowPermission] = useState(false);
    const [showPermissionType, setShowPermissionType] = useState('');
    const [showLiveTips, setShowLiveTips] = useState(false);
    const LoadingOk = useRef(false);
    const [groupId, setGroupId] = useState('')
    const [isLoadingEaseJoinGroup, setIsLoadingEaseJoinGroup] = useState(false);
    const [timerTrigger, setTimerTrigger] = useState(0);
    const hasBindTweet = useMemo(() => {
        return userInfo?.platformData?.useType !== 0 || false
    }, [userInfo])
    const isSelfChat = useMemo(() => {
        return selfUid === Number(userId)
    }, [selfUid, userId])

    const calledJoinGroupFn = useRef(false)


    useEffect(() => {
        console.log(`logding------>`, userId);
        calledJoinGroupFn.current = false;
        setCurentOtherUserInfo({})
    }, [])

    useEffect(() => {
        easeJoinGroupFn();
    }, [timerTrigger])

    const easeJoinGroupFn = () => {

        if (!isLoadingEaseJoinGroup && userId != null) {
            setIsLoadingEaseJoinGroup(true);
            easeJoinGroup(userId).then(res => {
                setIsLoadingEaseJoinGroup(false);
                const obj = {};
                obj[userId + ''] = {
                    group_id: res?.groupId,
                    msg_types: ['txt'],
                    expireTimes: new Date().getTime() + 25 * 60 * 1000
                }
                setGroupInfo(obj)
                setGroupId(res?.groupId)
            }).catch(e => {
                setPageLoading(false);
                message.error({content: `Join Group Failed`})
                setTimeout(() => {
                    setIsLoadingEaseJoinGroup(false);
                    setTimerTrigger(timerTrigger + 1);
                }, 5000);
            })
        }

    }


    const {buyFirstKey, payEntryFee, wishNeedClaim} = useMemo(() => {
        return userInfo?.selfData || {}
    }, [userInfo])

    useEffect(() => {
        if (isSelfChat && wishNeedClaim) {
            navigate(publicWishClaimPath)
        }
    }, [isSelfChat, wishNeedClaim])

    useEffect(() => {
        if (userId) {
            getPrice(userId);

            getuserinfo(userId).then(res => {
                // res = res?.data;

                setCurentOtherUserInfo({
                    ...curentOtherUserInfo,
                    ...res
                });

                // console.log(`ChatContent-------`,new Date(),res);
                res.isGlobalChatGroup ? setShowOtherTransContent(false) : res.youOwnKeyNum ? setShowOtherTransContent(false) : setShowOtherTransContent(true)
                LoadingOk.current = true;

                if (res.accountType !== "key") {
                    joinChat(userId, navigate, res.accountType, true)
                }

            }).catch(e => {
                console.log(e);
                LoadingOk.current = true;
            })
            const timer = setInterval(() => {
                getPrice(userId)
            }, timerDelay)
            return () => {
                timer && clearInterval(timer)
            }
        }
    }, [userId])

    useEffect(() => {
        if (groupId && userId && LoadingOk.current) {
            setPageLoading(false)
        }
    }, [groupId, userId, curentOtherUserInfo, LoadingOk])

    const {
        youOwnKeyNum,
        platformData,
        canJoinAgoraChan,
        agora_setchantype,
        managerId,
        isGlobalChatGroup
    } = useMemo(() => {
        return curentOtherUserInfo || {}
    }, [curentOtherUserInfo, userId])

    useEffect(() => {
        LoadingOk.current = false
        setPageLoading(true)

    }, [userId])


    useEffect(() => {
        // console.log(`canJoinAgoraChan`, canJoinAgoraChan);
        // console.log(`curentOtherUserInfo`, curentOtherUserInfo);
        userId && Number(userId) === curentOtherUserInfo?.userId && canJoinAgoraChan && !isSelfChat && setShowLivePanel(true);

        if (!canJoinAgoraChan && rtc.joinState) {
            // backFn()
            rtc.joinState = false
        }


    }, [canJoinAgoraChan, userId])

    useEffect(() => {
        console.log(userId && (youOwnKeyNum || isGlobalChatGroup || canJoinAgoraChan));
        if (userId && (youOwnKeyNum || isGlobalChatGroup || canJoinAgoraChan)) {
            console.log(`groupInfo`, groupInfo);
            if ((!groupInfo || !groupInfo[userId] || !groupInfo[userId]?.group_id || groupInfo[userId]?.expireTimes < new Date().getTime())) {
                console.log(`start call easeJoinGroupFn`);
                easeJoinGroupFn()
            } else {
                const obj = {};
                const i = groupInfo[userId];
                obj[userId + ''] = {
                    ...i,
                    expireTimes: new Date().getTime() + 25 * 60 * 1000
                }
                setGroupInfo(obj)
                setGroupId(groupInfo[userId]?.group_id)
            }

        }
    }, [userId, youOwnKeyNum, isGlobalChatGroup, canJoinAgoraChan])

    // const groupId = useMemo(()=>{
    //     if(groupInfo[userId]?.group_id && groupInfo[userId]?.expireTimes >= new Date().getTime())){

    //     }
    //     return groupInfo[userId]?.group_id || false
    // },[groupInfo,userId,canJoinAgoraChan])

    // useEffect(()=>{
    //   if(!rtc.joinState){
    //     setShowLivePanel(false)
    //   }
    // },[rtc.joinState])
    const startLive = async () => {
        const hasCamera = await checkCamera();
        console.log(hasCamera);
        if (!hasCamera) {
            setShowPermission(true);
            setShowPermissionType(t('Camera'));
            return
        }
        const hasMicrophone = await checkMicrophones();
        console.log(hasCamera);
        if (!hasMicrophone) {
            setShowPermission(true);
            setShowPermissionType(t('Microphone'));
            return
        }
        if (hasCamera && hasMicrophone) {
            // delete announce time
            post_live_announce_del({
                keyId: parseInt(userId),
                groupType: 0
            }).then(data => {
                console.log(data);
            }).catch(e => {
                console.log(e);
            });

            setShowLivePanel(true)
        }
    }

    const hasOneKey = useMemo(() => {
        if (youOwnKeyNum) {
            return true
        }
        return false
    }, [youOwnKeyNum])

    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])

    const location = useLocation();

    const backFn = async () => {
        if (showLivePanel) {
            if (!isSelfChat || !rtc.joinState) {
                await leave();
                setShowLivePanel(false);
            } else {

                Modal.confirm({
                    title: t('End This Live Stream'),
                    content: t('Are you sure you want to end this live stream now?'),
                    okText: t('Confirm'),
                    cancelText: t('Cancel'),
                    className: 'liveModal',
                    onOk: () => {
                        leave();
                        rtc.joinState = false;
                        nosleep.disable();
                        setShowLivePanel(false)
                    }
                });
            }

        } else {
            if (location.key !== "default") {
                navigate(-1);
            } else {
                navigate(mainHomePath, {replace: true});
            }
        }

    }

    const [openVotePanel, setOpenVotePanel] = useState(false);
    const [openVoteControlPanel, setOpenVoteControlPanel] = useState(false);
    const [needUpdateVoteInfo, setNeedUpdateVoteInfo] = useState("1");
    const [needUpdateRedPacketInfo, setNeedUpdateRedPacketInfo] = useState("1");
    const [showRank, setShowRank] = useState(false);
    const [rankAward, setRankAward] = useState({});

    const customMessage = useMemo(() => {
        return customChatMap[CHAT_TYPE.groupChat].get(groupId) || {};
    }, [groupId, customChatMap]);

    const customEvent = {
        needUpdateVoteInfo: (message) => {
            console.log(`---->`, message);
            const {chatType, to, id, customExts} = message;
            var timestamp = new Date().getTime();
            setNeedUpdateVoteInfo(Math.ceil(Math.random() * 1000) + "v" + timestamp);
            deleteChatCustomMessage({
                chatType,
                id,
                fromId: to
            })
        },
        needUpdateRedPacketInfo: (message) => {
            console.log(`---->`, message);
            const {chatType, to, id, customExts} = message;
            var timestamp = new Date().getTime();
            setNeedUpdateRedPacketInfo(Math.ceil(Math.random() * 1000) + "v" + timestamp);
            deleteChatCustomMessage({
                chatType,
                id,
                fromId: to
            })
        }
    }

    useEffect(() => {
        if (customMessage?.list && customMessage.list.length) {
            const message = customMessage.list[0];
            const type = message.customEvent;
            switch (type) {
                case 'TriviaUpdateNotify':
                    customEvent.needUpdateVoteInfo(message)
                    break;
                case 'RedPacketUpdateNotify':
                    customEvent.needUpdateRedPacketInfo(message)
                    break;
                default:
                    break;
            }

        }
    }, [customMessage])

    const [lastVoteInfo, setLastVoteInfo] = useState({});

    const updateVoteInfo = () => {
        get_trivia_run_data(userId).then(data => {
            if (data.state !== 0) {
                setLastVoteInfo(data);
                if (isSelfChat) {
                    setOpenVoteControlPanel(true);
                } else {
                    setOpenVotePanel(true);
                }
            } else {
                setLastVoteInfo({});
                setShowRank(false);
            }
        }).catch(e => {
            console.log(e);
        })
    }

    useEffect(
        () => {
            const timer = setInterval(() => {
                if (openVotePanel || openVoteControlPanel) {

                } else if (triviaHostId === parseInt(userId)) {
                    updateVoteInfo();
                }
            }, 5000);

            return () => {
                timer && clearInterval(timer);
            }
        }
        , [triviaHostId, openVotePanel, openVoteControlPanel]
    )

    useEffect(() => {
        if (triviaHostId === parseInt(userId)) {
            updateVoteInfo();
        }
    }, [triviaHostId])

    useEffect(() => {
        if (triviaHostId === parseInt(userId)) {
            updateVoteInfo();
        }
    }, [needUpdateVoteInfo])


    const [triviaStartTime, setTriviaStartTime] = useState(0);
    const [hasTrivia, setHasTrivia] = useState(false);
    useEffect(() => {
        getConfig().then(data => {
            const triviaStartTime = data?.triviaStartTime * 1000;
            setTriviaStartTime(triviaStartTime);
            let timestamp = new Date().getTime();
            setTriviaHostId(data?.hostId);
            if (data?.hostId === parseInt(userId) && timestamp <= triviaStartTime) {
                get_trivia_cond(userId).then(data => {
                    if (data?.isMeet) {
                        setHasTrivia(true);
                    } else {
                        setHasTrivia(false);
                    }
                    setShowLiveTips(true);
                });
            }
        });
    }, []);

    const [nextLiveTime, setNextLiveTime] = useState(0);
    const [title, setTitle] = useState('');
    useEffect(
        () => {
            post_live_announce_query({
                keyId: parseInt(userId),
            }).then(data => {
                // if (data?.startTime) {
                    setNextLiveTime(data.startTime);
                // }
                setTitle(data?.title);
            }).catch(e => {
                console.log(e);
            });
        }, [userId]
    )
    const [openRedPacketCreatePanel, setOpenRedPacketCreatePanel] = useState(false)
    return (
        <>
            <div className={`${s.wrap} commonBlueBg`}>
                <MainHeader hasBackBtn={true}></MainHeader>
                <ChatContentHeader
                    setOpenTranPanel={setOpenTranPanel}
                    setShowOtherTransContent={setShowOtherTransContent}
                    backFn={backFn}
                />

                <div className={s.content}>
                    {
                        !hasOneKey && !pageLoading && !isGlobalChatGroup &&
                        <div className={s.loackWrap}>
                            <CustomIcon width={22} height={25} imgName={'UI_TT_Icon_Suo'} name={'UI_TT_Icon_Suo'}/>
                            <div className="mtb20 fs15 color-gray">
                                {t('Hold at least one key of xx to send messages', {name: displayFilter(profile?.displayName)})}
                            </div>
                            <div className={s.buyBtn} onClick={() => {
                                if (!(buyFirstKey && payEntryFee)) {
                                    navigate(balancePath)
                                } else if (userInfo?.selfData?.wishNeedClaim && isSelfChat) {
                                    navigate(publicWishClaimPath)
                                } else {
                                    setOpenTranPanel(true)
                                }
                            }}>{t('Buy a key')}
                            </div>
                        </div>
                    }

                    <ChatContent
                        keyId={userId}
                        isSelfChat={isSelfChat}
                        hasBindTweet={hasBindTweet}
                        // liveLoading={liveLoading}
                        // setGroupReady={setGroupReady}
                        hasOneKey={hasOneKey}
                        groupId={groupId}
                        setGroupId={setGroupId}
                        setPageLoading={setPageLoading}
                        startLive={startLive}
                        setNextLiveTime={setNextLiveTime}
                        setTitle={setTitle}
                        managerId={managerId}
                        pageLoading={pageLoading}
                        isGlobalChatGroup={isGlobalChatGroup}
                        curentOtherUserId={curentOtherUserInfo?.userId}
                        setOpenRedPacketCreatePanel={setOpenRedPacketCreatePanel}
                        openRedPacketCreatePanel={openRedPacketCreatePanel}
                        nextLiveTime={nextLiveTime}
                        nextLiveTitle={title}
                    />

                    <div style={{zIndex: 200}}>
                        <RedPacket setOpenRedPacketCreatePanel={setOpenRedPacketCreatePanel}
                                   openRedPacketCreatePanel={openRedPacketCreatePanel} keyId={userId}
                                   isSelfChat={isSelfChat} hasBindTweet={hasBindTweet}
                                   pageLoading={pageLoading} needUpdateRedPacketInfo={needUpdateRedPacketInfo}/>
                    </div>

                </div>

            </div>

            {showLivePanel && groupId &&
                <LivePanel
                    isSelfChat={isSelfChat}
                    avatarurl={getAvatarFromProfile(profile, 1) || getAvatarFromProfile(profile, 0)}
                    setOpenTranPanel={setOpenTranPanel}
                    setShowOtherTransContent={setShowOtherTransContent}
                    backFn={backFn}
                    hasOneKey={hasOneKey}
                    selfUid={selfUid}
                    keyId={userId}
                    groupId={groupId}
                    nosleep={nosleep}
                    profile={profile}
                    agora_setchantype={agora_setchantype}
                    showLivePanel={showLivePanel}
                    setNeedUpdateVoteInfo={setNeedUpdateVoteInfo}
                />
            }
            <AntModal
                width='330px'
                title={t('TRADE KEYS')}
                className="confirmModalWrap"
                centered={true}
                open={openTranPanel}
                destroyOnClose={true}
                zIndex={3000}
                onOk={() => setOpenTranPanel(false)}
                onCancel={() => setOpenTranPanel(false)}
            >
                <ModelTransaction curentOtherUserInfo={curentOtherUserInfo}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('xx Permission Required', {ty: showPermissionType})}
                className="confirmModalWrap"
                centered={true}
                open={showPermission}
                destroyOnClose={true}
                onOk={() => setShowPermission(false)}
                onCancel={() => setShowPermission(false)}
            >
                <ModelPermission type={showPermissionType}/>
            </AntModal>
            {!isSelfChat &&
                <AntModal
                    width='450px'
                    className="confirmModalWrap voteModal noCloseBtn"
                    wrapClassName="voteModalWrap"
                    open={openVotePanel}
                    destroyOnClose={true}
                    mask={false}
                    style={{pointerEvents: 'auto'}}
                    zIndex={2000}
                    onOk={() => setOpenVotePanel(false)}
                >
                    <ModelVote curentOtherUserInfo={curentOtherUserInfo} curRtcInfo={curRtcInfo}
                               setOpenVotePanel={setOpenVotePanel} needUpdateVoteInfo={needUpdateVoteInfo}
                               setNeedUpdateVoteInfo={setNeedUpdateVoteInfo} hostId={userId} lastVoteInfo={lastVoteInfo}
                               setShowRank={setShowRank} setRankAward={setRankAward}/>
                </AntModal>
            }
            {!isSelfChat &&
                <AntModal
                    width='450px'
                    className="confirmModalWrap voteModal rankModal noCloseBtn"
                    wrapClassName="voteModalWrap"
                    open={showRank}
                    destroyOnClose={true}
                    mask={false}
                    zIndex={3000}
                    onOk={() => setShowRank(false)}
                    maskClosable={true}
                >
                    <ModelRank rankAward={rankAward} curRtcInfo={curRtcInfo}/>
                </AntModal>
            }
            {isSelfChat &&
                <AntModal
                    width='450px'
                    title={t('Trivia Control Panel')}
                    className="confirmModalWrap voteModal noCloseBtn"
                    wrapClassName="voteModalWrap"
                    centered={true}
                    open={openVoteControlPanel}
                    destroyOnClose={true}
                    mask={false}
                    style={{pointerEvents: 'auto'}}
                    zIndex={2000}
                    onOk={() => setOpenVoteControlPanel(false)}
                >
                    <ModelVoteControl setOpenVoteControlPanel={setOpenVoteControlPanel}
                                      needUpdateVoteInfo={needUpdateVoteInfo}
                                      setNeedUpdateVoteInfo={setNeedUpdateVoteInfo} hostId={userId}
                                      lastVoteInfo={lastVoteInfo}/>
                </AntModal>
            }

            <AntModal
                width='330px'
                className="confirmModalWrap noCloseBtn"
                // wrapClassName="voteModalWrap liveTips"
                open={showLiveTips && !showLivePanel && !isSelfChat}
                centered={true}
                destroyOnClose={true}
                zIndex={1090}
                onOk={() => setShowLiveTips(false)}
                onCancel={() => setShowLiveTips(false)}
            >
                <ModelLiveTips hasTrivia={hasTrivia} startTime={triviaStartTime}
                               revivalCardHoldCount={userInfo?.selfData?.revivalCardHoldCount || 0}/>
            </AntModal>
            {!showLivePanel && showOtherTransContent &&
                <OtherTransRecord setOpenTranPanel={setOpenTranPanel} backFn={backFn}
                                  isGlobalChatGroup={isGlobalChatGroup}/>}
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        customChatMap: app.customChatMap,
        showCharContent: app.showCharContent,
        curentOtherUserInfo: app.curentOtherUserInfo,
        curRtcInfo: app.curRtcInfo,
        showOtherTransContent: app.showOtherTransContent,
        groupInfo: app.groupInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCharContent: (params) => {
            return dispatch({
                type: "app/setShowCharContent",
                payload: params,
            });
        },
        setShowOtherTransContent: (params) => {
            return dispatch({
                type: "app/setShowOtherTransContent",
                payload: params,
            });
        },
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        deleteChatCustomMessage: (params) => {
            return dispatch({
                type: "app/deleteChatCustomMessage",
                payload: params,
            });
        },
        setGroupInfo: (params) => {
            return dispatch({
                type: "app/setGroupInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Chat));
