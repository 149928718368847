import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {connect} from "react-redux";
import {useTranslation, Trans} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import CustomIcon from "../../common/CustomIcon";
import {
    convertWalletBalance, 
    numberClamp, 
    getChainTokenInfoById,
} from "@/utils/common";
import { 
    coinList,
    getMemeKingTgeWinnerList,
    setMemeTgeNoticeRead,
    getMemeTgeNoticeList,
} from "@/utils/memeClub";
import {
    gameMemeClubDetailPath,
} from "@/routes/config";
import ButtonFactory from "@/common/ButtonFactory";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Slider, Checkbox, Select, Spin} from "antd";

const { Option } = Select;

const ModalMemeAssetNewPack = (
    {
        closeHandle,

        memeAssetUnpackList,
        setMemeAssetUnpackList,
        memeAssetUnpackListQueryLastTime,
        setMemeAssetUnpackListQueryLastTime,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const showList = useMemo(() =>{
        if (memeAssetUnpackList?.length > 0){
            return [...memeAssetUnpackList];
        }
        return [];
    }, [memeAssetUnpackList]);

    const updateUnpackList = () =>{
        getMemeTgeNoticeList().then(res => {
            if (res?.noticeList?.length > 0){
                setMemeAssetUnpackList(res?.noticeList);
            }
            else{
                setMemeAssetUnpackList(null);
            }
            let nowTime = new Date().getTime();
            setMemeAssetUnpackListQueryLastTime(nowTime);
        }).catch(e => {
            console.error(e);
        })
    }
    
    const setAllRead = () => {
        let clubUserIds = [];
        if (memeAssetUnpackList?.length > 0){
            for (let i = 0; i < memeAssetUnpackList?.length; i++){
                clubUserIds.push(memeAssetUnpackList[i].clubUserId);
            }
        }
        if (clubUserIds?.length > 0){
            setMemeTgeNoticeRead(clubUserIds).then(res => {
                updateUnpackList();
            }).catch(e => {
                console.error(e);
            })
        }
    }

    const setOneRead = (clubUserId) =>{
        let clubUserIds = [clubUserId];
        setMemeTgeNoticeRead(clubUserIds).then(res => {
            updateUnpackList();
        }).catch(e => {
            console.error(e);
        })
    }

    return (
        <div className={s.wrap}>
            <div className={`${s.title} flex_center_start_col`}>
                <div className={`${s.titleText}`}>
                    {t('Notification')}
                </div> 
            </div>
            <div className={s.contentPart}>
                <div className={`${s.content} flex_center_start_col`}>
                    {
                        showList?.length > 0 ?
                        <>
                            {
                                showList?.map((item) =>{
                                    const {
                                        clubUserId,
                                        clubUserName,
                                        holdNum,
                                        isRead,
                                        timestamp,
                                    } = item || {};
                                    return (
                                        <div key={clubUserId} className={`${s.notificationItem} flex_center_start_col`} onClick={() =>{
                                            if (!isRead){
                                                setOneRead(clubUserId);
                                            }
                                            navigate(`${gameMemeClubDetailPath}/${clubUserId}`);
                                        }}>
                                            <div className={`${s.achievedText}`}>
                                                <span className="fs12 color-yellow fb">
                                                    {clubUserName}&nbsp;
                                                </span>
                                                <span className="fs12 fb">
                                                    {t('achieved TGE prgress')}
                                                </span>
                                            </div>
                                            <div className={`${s.unpackText} fb`}>
                                                <Trans
                                                    i18nKey="You have <yellow>{{count}} Packs</yellow> to unpack"
                                                    defaults="You have <yellow>{{count}} Packs</yellow> to unpack"
                                                    values={{
                                                        count: holdNum
                                                    }}
                                                    components={{
                                                        yellow: <span className="color-yellow fs12"></span> 
                                                    }}
                                                />
                                            </div>
                                            {
                                                !isRead && 
                                                <div className={`${s.newRoot} flex_center_center`}>
                                                    <span className="fs10 fb color-black">
                                                        {t('NEW')}
                                                    </span>
                                                </div>
                                            }
                                            <div className={`${s.tgeTimeRoot} flex_center_end`}>
                                                {(new Date(timestamp * 1000)).format("yyyy/MM/dd hh:mm")}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </> :
                        <div className={`${s.listNoData} flex_center_center`}>
                            <CustomIcon width={111} height={95} className={`mb30`} imgName={'Picture/UI_Picture_NoData_Empty_01'}></CustomIcon>
                            {t('No Data')}
                        </div>
                    }
                </div>
            </div>
            <div className={`${s.bottomPart} flex_center_start_col`}>
                <ButtonFactory
                    className={s.closeBtn}
                    onClick={() => {
                        setAllRead();
                    }}
                >
                    {t('Set All as Read')}
                </ButtonFactory>
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        memeAssetUnpackList: app.memeAssetUnpackList,
        memeAssetUnpackListQueryLastTime: app.memeAssetUnpackListQueryLastTime,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMemeAssetUnpackList: (params) => {
            return dispatch({
                type: "app/setMemeAssetUnpackList",
                payload: params,
            });
        },
        setMemeAssetUnpackListQueryLastTime: (params) => {
            return dispatch({
                type: "app/setMemeAssetUnpackListQueryLastTime",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalMemeAssetNewPack));
