import React, { memo, useEffect, useState, useMemo } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "../../../../common/CustomIcon";
import { connect } from "react-redux";
import { Spin, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { bannerData } from "@/utils/taskJson";
import { getPowerXY, stringToNumber, unit } from "@/utils/common"
import { useLocation, useNavigate } from "react-router-dom";
import AvatarInfo from "../../../../common/AvatarInfo";
import {
    getnft_self_history,
    nftDetail,
    coinObj,
    getRarityImageBg,
} from "@/utils/bidNft";
import InfiniteScroll from "react-infinite-scroll-component";
import NFTSeriesInfo from "@/utils/json/NFTSeriesInfo.json";
import MainHeader from "src/common/MainHeader";
import ButtonFactory from "src/common/ButtonFactory";
import AntButton from "@/common/AntButton";

const BidHistory = (
    {
        userInfo,
        setShowLink,
        tweetMaticBindResult,
        currentAuctionBatchCfg,
    }
) => {
    const { t, i18n } = useTranslation();
    // const [linkUrl, setLinkUrl] = useState('');
    const allSeries = {
        seriesType: 0,
        name: "All Series"
    }
    const navigate = useNavigate();
    const [loding, setLoading] = useState(true)
    const [showIntro, setShowIntro] = useState(false)
    const [currentSeries, setCurrentSeries] = useState(allSeries);
    const [seriesEarnMatic, setSeriesEarnMatic] = useState(0);
    const [seriesEarnLFG, setSeriesEarnLFG] = useState(0);
    const seriesDropdownItems = useMemo(() => {
        let items = [{
            key: 0,
            label: <span className={currentSeries?.seriesType == 0 ? 'current-item' : 'default-item'}>
                {allSeries?.name}
            </span>,
            onClick: (e) => {
                setCurrentSeries(allSeries);
            }
        }];
        if (NFTSeriesInfo?.length > 0) {
            for (let i = 0; i < NFTSeriesInfo?.length; i++) {
                let item = NFTSeriesInfo[i];
                items.push({
                    key: i + 1,
                    label: <span
                        className={currentSeries?.seriesType == item?.seriesType ? 'current-item' : 'default-item'}>
                        {item.name}
                    </span>,
                    onClick: (e) => {
                        setCurrentSeries(item);
                    }
                })
            }
        }
        return items;
    }, [currentSeries]);
    const timeFn = (time) => {
        time = parseInt(new Date().getTime() / 1000) - time;
        let d = parseInt(time / 60 / 60 / 24);
        let h = parseInt(time / 60 / 60 % 24);
        let m = parseInt(time / 60 % 60);
        let str = '';
        if (d > 0) {
            str = d + "d";
            return str
        }
        if (h > 0) {
            str = h + "h";
            return str
        }
        if (m > 0) {
            str = m + "m";
            return str
        }
        return t('few seconds');

        // return `${d}${h}${m}`
    }
    const [list, setList] = useState([]);
    const [nextCursor, setNextCursor] = useState(0);
    const getHistoryList = (cursor = 0, refresh = true) => {
        getnft_self_history(currentSeries?.seriesType || 0, cursor).then(res => {
            setSeriesEarnMatic(res?.seriesEarnMatic);
            setSeriesEarnLFG(res?.seriesEarnLFG);
            setLoading(false)
            setNextCursor(res?.nextCursor || -1)
            setList(list => {
                const _l = refresh ? res?.historyList || [] : [...list, ...res?.historyList]
                return _l
            })
        }).catch(e => {
            console.error(e)
        })
    }
    useEffect(() => {
        getHistoryList()
    }, [])

    useEffect(() => {
        console.log(list);
    }, [list])

    useEffect(() => {
        setList([]);
        getHistoryList(0, true);
    }, [currentSeries])

    const getMoreHisoryList = () => {
        getHistoryList(nextCursor, false)
    }
    return (
        // <Spin
        //   className="loadPageSpin"
        //   indicator={
        //     <LoadingOutlined
        //       style={{
        //         fontSize: 90,
        //       }}
        //     />
        //   }
        //   tip="Loading..."
        //   spinning={loading}
        // >
        // </Spin>
        <>
            <div className={`${s.wrap} publicBg`}>
                <MainHeader title={t('BidTitleBidHistory')} hasRightMenu={false} headerType={2}></MainHeader>
                <div className={`${s.modalWrap}`}>
                    <Dropdown menu={{ items: seriesDropdownItems }} className={`nftHistoryDropDown ${s.dropdown}`}
                        overlayClassName={"nftHistoryDropDownMenu"}>
                        <AntButton>{currentSeries?.name} <DownOutlined /></AntButton>
                    </Dropdown>
                    <div className={s.dropdownLine}>
                        <div className={s.dropdownTitle}>
                            {t('You have already earned from the auction')}
                        </div>
                    </div>
                    <div className={`${s.dropdownLine} mt10`}>
                        <div className={`aic ${s.itemInfo} ml5`}>
                            <CustomIcon width={24} height={24} className="mr5 "
                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                name={'Picture/UI_Picture-Currency_LFG_01'} />
                            <div
                                className="fs14 color-white">{Number(Number(seriesEarnLFG).toFixed(2)).toLocaleString("en-US")}</div>
                        </div>
                        <div className={`aic ${s.itemInfo} mr5`}>
                            <CustomIcon width={24} height={24} className="mr5 "
                                imgName={'Picture/UI_Picture-Currency_MATIC_01'}
                                name={'Picture/UI_Picture-Currency_MATIC_01'} />
                            <div
                                className="fs14 color-white">{Number(Number(seriesEarnMatic).toFixed(2)).toLocaleString("en-US")}</div>
                        </div>
                    </div>
                    <div className={`${s.block} mt10`}>
                        <div className={s.bidList} id="bidHistoryList">
                            <InfiniteScroll
                                dataLength={list?.length || 0}
                                next={getMoreHisoryList}
                                hasMore={nextCursor !== -1}
                                loader={<Spin />}
                                style={{
                                    width: '100%'
                                }}
                                endMessage={
                                    <div style={{
                                        textAlign: "center",
                                        width: "100%",
                                        color: "var(--color-gray)",
                                        fontSize: '12px'
                                    }}>{loding ? t('Loading...') : t('No More Data')}</div>
                                }
                                scrollableTarget={'bidHistoryList'}
                            >
                                {list.map((item, ind) => {
                                    const { bidId, coinType, myBid, timestamp, nftSimpleData, bidHistoryType } = item
                                    const { tokenAddr, tokenId, name, rarity, image } = nftSimpleData || {};
                                    return (
                                        <div key={ind} className={s.bidItem} onClick={() => {
                                            nftDetail(navigate, { tokenAddr, tokenId })
                                        }}>
                                            <div className={s.l}>
                                                <div className={s.avatar}>
                                                    {
                                                        currentAuctionBatchCfg?.auctionBatchType === 2 &&
                                                        <div className={s.bidItemBg}
                                                            style={{ backgroundImage: `url(/img/${getRarityImageBg(rarity)}.png)` }}>
                                                        </div>
                                                    }
                                                    <AvatarInfo size={40} src={image} className={'roundAvatar'} />

                                                </div>

                                                {/* <div className={s.state}>
                                    Outbid
                                </div> */}
                                            </div>
                                            <div className={s.r}>
                                                <div className="tll">
                                                    {bidHistoryType === 1 &&
                                                        <Trans
                                                            i18nKey="BidHistoryItemBid"
                                                            defaults="You bid for <color>{{bidName}}</color>"
                                                            values={{ bidName: name }}
                                                            components={{
                                                                color: <span
                                                                    className="a"
                                                                ></span>
                                                            }}
                                                        />
                                                    }
                                                    {bidHistoryType === 2 &&
                                                        <Trans
                                                            i18nKey="BidHistoryItemOutBid"
                                                            defaults="You’ve been <redColor>outbid</redColor> for <color>{{bidName}}</color>"
                                                            values={{ bidName: name }}
                                                            components={{
                                                                color: <span
                                                                    className="a"
                                                                ></span>,
                                                                redColor: <span className="color-red"></span>
                                                            }}
                                                        />
                                                    }

                                                    {bidHistoryType === 3 &&
                                                        <Trans
                                                            i18nKey="BidHistoryItemClaim"
                                                            defaults="You claimed <color>{{bidName}}</color>"
                                                            values={{ bidName: name }}
                                                            components={{
                                                                color: <span
                                                                    className="a"
                                                                ></span>
                                                            }}
                                                        />
                                                    }

                                                </div>
                                                <div className="tll mt8 color-blue-light fs12">
                                                    {bidHistoryType === 1 &&
                                                        <Trans
                                                            i18nKey="BidHistoryItemBidIntroForState0"
                                                            defaults="Placed a bid of <color>{{price}}</color>"
                                                            values={{ price: `${stringToNumber(myBid, 2)} ${coinObj[coinType]?.unit}` }}
                                                            components={{
                                                                color: <span
                                                                    className={`fs12 ${coinType === 1 ? 'color-purple' : 'color-lfg'}`}
                                                                ></span>
                                                            }}
                                                        />}
                                                    {bidHistoryType === 2 &&
                                                        <Trans
                                                            i18nKey="BidHistoryItemBidIntroForState1"
                                                            defaults="Received a bid refund of <color>{{price}}</color>"
                                                            values={{ price: `${stringToNumber(myBid, 2)} ${coinObj[coinType]?.unit}` }}
                                                            components={{
                                                                color: <span
                                                                    className={`fs12 ${coinType === 1 ? 'color-purple' : 'color-lfg'}`}
                                                                ></span>
                                                            }}
                                                        />
                                                    }
                                                    {bidHistoryType === 3 &&
                                                        <Trans
                                                            i18nKey="BidHistoryItemBidIntroForState2"
                                                            defaults="with the price of <color>{{price}}</color>"
                                                            values={{ price: `${stringToNumber(myBid, 2)} ${coinObj[coinType]?.unit}` }}
                                                            components={{
                                                                color: <span
                                                                    className={`fs12 ${coinType === 1 ? 'color-purple' : 'color-lfg'}`}
                                                                ></span>
                                                            }}
                                                        />
                                                    }
                                                    <span
                                                        className="color-blue-light">, {t('xx ago', { time: timeFn(timestamp) })}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </InfiniteScroll>


                        </div>
                    </div>

                </div>

            </div>


        </>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        initTopUpAmount: app.initTopUpAmount,
        currentAuctionBatchCfg: app.currentAuctionBatchCfg,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        setShowLink: (params) => {
            return dispatch({
                type: "app/setShowLink",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(BidHistory));
