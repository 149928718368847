import React, {memo, useEffect, useState, useMemo} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../../../common/CustomIcon";
import {connect} from "react-redux";
import {Button, Modal, Spin} from 'antd';
import {useNavigate} from "react-router-dom";
import CityInfo from "@/utils/json/CityInfo.json";
import AvatarInfo from "../../../../common/AvatarInfo";
import {getAvatarFromProfile} from "@/utils/common";
import WarfareDetail from "./WarfareDetail";
import {getCitywarInfolist, iconPath} from "@/utils/warfare";
import MainHeader from "src/common/MainHeader";
import AntModal from "@/common/AntModal";

const WarfareComingSoon = (
    {
        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();

    const [list, setList] = useState([])

    const {userId, managerProfile, selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    // const cityWarUnlockIds = []
    const {cityWarUnlockIds} = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const [linkUrl, setLinkUrl] = useState('');
    const navigate = useNavigate();
    const [showWarfareDetail, setShowWarfareDetail] = useState(false)
    const [selCityId, setSelCityId] = useState('')
    const openDetail = (cityId) => {
        // setSelCityId(cityId)
        // setShowWarfareDetail(true)
    }

    const getList = () => {
        // getCitywarInfolist().then(res => {
        //     // console.log(res?.cityList || []);
        //     setList(res?.cityList || [])
        // }).catch(e => {
        //     console.error(e);
        // })
    }

    useEffect(() => {
        getList()
    }, [])
    return (
        <div className={`${s.modalWrap}`}>
            <MainHeader isGameHeader={true} hasBackBtn={false} leftTitle={t('Ecosystem')} rightMenuIcon ={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={s.content}>
                <div className={"fs16"} style={{marginTop: '250px'}}>{t('Stay Tuned for the Launch of Our')} <br/>
                    {t('Exciting Crypto Planet Wars')}
                </div>
                <div className={"fs30"} style={{marginTop: '70px'}}>{t('Coming Soon...')}</div>
            </div>
        </div>

    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        initTopUpAmount: app.initTopUpAmount,
        currentAuctionBatchCfg: app.currentAuctionBatchCfg,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        setShowLink: (params) => {
            return dispatch({
                type: "app/setShowLink",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(WarfareComingSoon));
