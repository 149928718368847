import React, {memo, useState, useMemo, useEffect, useRef, useCallback} from "react";
import s from "./index.module.less";
import {Modal, Button, Slider, Checkbox, Spin} from "antd";
import CustomIcon from "../../common/CustomIcon";
import AvatarInfo from "../../common/AvatarInfo";
import InputNumber from "../../common/InputNumber"
import {url} from "@/utils/configUri";
import http from "@/utils/axios";
import FilterIcon from "@/assets/images/FilterIcon.png"
import {
    stringToNumber,
    post_batch_price,
    keyBatchBuy,
    TransactionError,
    PrivyLoginStatusError,
    logoutSelf,
    unit,
    keySell,
    displayFilter,
    getAvatarFromProfile,
    convertWalletBalance,
} from "@/utils/common";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import TierConfig from "@/utils/json/TierConfig.json";
import {useTranslation} from "react-i18next";
import ButtonFactory from "src/common/ButtonFactory";
import {connect} from "react-redux";

const ModalBatchSell = (
    {
        userInfo,
        setShowModelTrans,
        nowData,
        refreshPortfolio,
        setShowGasMode,
        cardType = 0,//0:players, 1:nfts
        laterUpdate = () =>{}
    }
) => {
    const {t, i18n} = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [allChecked, setAllChecked] = useState(0)
    const [showData, setShowData] = useState(JSON.parse(JSON.stringify(nowData)))
    const sellList = useRef({});
    const [showSellList, setShowSellList] = useState([]);
    const [subLoading, setSubLoading] = useState(false);
    const [noSuccessText, setNoSuccessText] = useState('Buy Failed');
    const [done, setDone] = useState(false);
    const maxBatchSelectNum = 10

    const formatHeat = (heat) => heat < 0.01 ? '<0.01' : heat.toFixed(2);

    const closeModal = async () => {
        if (done){
            laterUpdate();
        }
        setDone(false)
        sellList.current = {}
        setSelectedRows([])
        refreshPortfolio()
        setShowModelTrans(false)
    };

    useEffect(() => {
        nowData.map((item) => {
            const canSellNum = userInfo?.userId === item.userId ? (item.holdingNum <= 1 ? 1 : item.holdingNum - 1) : item.holdingNum

            setShowSellList((prev) => ({...prev, [item.userId]: canSellNum}))
            sellList.current[item.userId] = canSellNum
        })
    }, [nowData]);

    const handleCheckboxChange = (row) => {
        let t
        if (selectedRows.includes(row)) {
            t = selectedRows.filter((item) => item !== row)
        } else {
            if (selectedRows.length >= maxBatchSelectNum) return
            t = [...selectedRows, row];
        }
        const temp = {}
        const showTemp = {}
        t.forEach(r => {
            const {userId} = r
            showTemp[userId] = temp[userId] = showSellList[userId] || 1
        })
        if (showData.length === t.length) {
            setAllChecked(true)
        } else {
            setAllChecked(false)
        }
        setShowSellList(showSellList)
        sellList.current = temp
        setSelectedRows(t)
    }

    const onCheckedAll = (e) => {
        setAllChecked(e.target.checked)
        if (e.target.checked) {
            sellList.current = showSellList
            setSelectedRows(showData)
        } else {
            sellList.current = {}
            setSelectedRows([])
        }
    }

    const {logout} = usePrivy();
    const closeFn = () => {
        if (noSuccessText === PrivyLoginStatusError) {
            logout();
            logoutSelf();
        }
        closeModal();
    }

    const {wallets} = useWallets();

    const [progressStart, setProgressStart] = useState(false)
    const [progressNum, setProgressNum] = useState(0)
    const [progressOkNum, setProgressOkNum] = useState(0)
    const transFn = () => {
        if (subLoading) return
        setSubLoading(true);
        //TODOnft
        post_batch_price(sellList.current).then(data => {
            const walletList = data?.wallets || {};

            const userIdList = Object.keys(walletList);
            const interval = 5000;
            userIdList.forEach((userId, index) => {
                setTimeout(function () {
                    setProgressList((prev) => ({...prev, [userId]: '⌛️'}))
                    setProgressNum((prev) => prev + 1)
                    keySell(wallets, parseInt(userId), walletList[userId], sellList.current[userId]).then(res => {
                        setProgressList((prev) => ({...prev, [userId]: '✅'}))
                        setProgressOkNum((prev) => prev + 1)
                    }).catch(e => {
                        console.log(e);
                        setProgressList((prev) => ({...prev, [userId]: '❌'}))
                        setProgressOkNum((prev) => prev + 1)
                    })
                }, index * interval);
            })
            setProgressStart(true)
        }).catch(e => {
            setSubLoading(false);
            setDone(true);
        })
    }

    useEffect(() => {
        if (progressStart && progressNum === progressOkNum) {
            setSubLoading(false);
            setDone(true);
        }
    }, [progressStart, progressNum, progressOkNum]);


    const onChangeInputNumber = (value, id) => {
        showSellList[id] = value
        setShowSellList(showSellList)
        if (sellList.current[id]) {
            sellList.current[id] = value
        }
    }

    const [progressList, setProgressList] = useState({})

    return (
        <div className={s.container}>
            <div className={s.wrap}>
                <Spin spinning={subLoading} indicator={<span></span>}>
                    <table>
                        <thead>
                        <tr>
                            <th>
                                <div></div>
                            </th>
                            <th style={{textAlign: "left", paddingLeft: '38px'}}>{t('Clubs')}</th>
                            <th></th>
                            <th>{t('Price')}</th>
                            <th>{t('Number')}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {(showData).map((row, index) => (
                            <tr key={"filter" + index} className={`${selectedRows.includes(row) ? s.checkedBG : ''}`}>
                                <td style={{paddingRight: 10}}>
                                    <Checkbox onClick={() => handleCheckboxChange(row)}
                                              className="hireCheckBox"
                                              checked={selectedRows.includes(row)}
                                    />
                                </td>
                                <td style={{display: "flex", alignItems: "center"}}>
                                    <AvatarInfo
                                        style={{flex: 'none'}}
                                        size={30}
                                        src={getAvatarFromProfile(row?.profile, 1) || getAvatarFromProfile(row?.profile, 0)}
                                    />
                                    <div className={s.ellipseUser} style={{maxWidth: '90px'}}>
                                        {displayFilter(row.profile.displayName)}
                                        <br/>
                                        <span className="color-white fs12">{t('Vol')}: {formatHeat(row.heat)}</span>
                                    </div>
                                </td>
                                <td>
                                    {row.tierId && <CustomIcon className="mr5 mb5" width={12} height={12}
                                                               imgName={`Picture/Rank/RankStar_${row.tierId}`}
                                                               name={`RankStar_${row.tierId}`}/>}
                                </td>
                                <td>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <CustomIcon
                                            width={18}
                                            height={18}
                                            imgName={`Picture/${cardType === 1?"UI_Picture-Currency_LFG_01":"UI_Picture-Currency_MATIC_01"}`}
                                        />
                                        <span style={{marginLeft: '4px'}}>{cardType === 1?convertWalletBalance(row.buyPrice):stringToNumber(row.buyPrice, 4)}</span>
                                    </div>
                                </td>
                                <td className="tlc">
                                    <InputNumber min={1} value={showSellList[row.userId]}
                                                 onChange={($event) => onChangeInputNumber($event, row.userId)}
                                                 max={row.holdingNum}/>
                                </td>
                                <td>
                                    {progressList[row.userId] ? progressList[row.userId] : ''}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Spin>
                <div className={s.sliderContainer}>
                </div>
            </div>
            <div className={s.bottomSettleSweep}>
                <Spin spinning={subLoading}>
                    <div className={s.settlePanel}>
                        <Checkbox style={{height: 'fit-content', whiteSpace: 'nowrap'}} className="hireCheckBox fs12"
                                  checked={allChecked} onChange={onCheckedAll}>{t('Select All')}</Checkbox>
                    </div>
                    <div style={{height: '10px'}}></div>
                </Spin>
            </div>
            <CustomIcon width={30} height={30}
                        className="modelBottomSetGas"
                        imgName={'Button/UI_Button_Gas_01'}
                        onClick={() => {
                            setShowGasMode(true);
                        }}
            />
            <div className="modelBottomBtn">
                {!done && <ButtonFactory onClick={transFn} disabled={!selectedRows.length}>
                    {subLoading ? t('PROCESSING') : t('SELL NOW')}
                </ButtonFactory>}
                {done && <ButtonFactory onClick={closeFn}>
                    {t('TRANSACTION SUCCESSFUL')}
                </ButtonFactory>}
            </div>
        </div>
    )
}

const mapStateToProps = ({app}) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalBatchSell));

