import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { AutoStrangeRule } from "@/utils/strangeRule";
import { airdropTypeData, getChainInfo, getHolderList, detailInfoShowHolderNum, calcTotalValue, momentPriceFormular, calcNumberCardVal, moment_clubcardlist } from "@/utils/momentClub";
import { convertWalletBalance, stringToNumber } from "@/utils/common";
import KChart from "../../../KChart";
import TradeKeyPanel from "../TradeKeyPanel";

const Detail = (
    {
        clubBasicInfo,
        chainInfo,
        clubId,
        sourceData,
        showTransModelFn,
        showChartDetail,
        setShowChartDetail,
        upDate,
        openBuyDrawer = () =>{},
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showMoreMetaData, setShowMoreMetaData] = useState(false)
    const [holder, setHolder] = useState([])
    // const a = useMemo(()=>{},[])

    const {
        curCardKindNum,
        clubInfo,
        clubUserId,
        historyPrice,
        holdNum,
        cardKindMaxNum,
        sellPrice,
        buyPrice,
    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])
    
    const range = historyPrice ? Math.round((sellPrice - historyPrice) / historyPrice * 100) : 0;


    const {
        airdropPercent,
        airdropType,
        coinToNFTRatio,
        ftTotalSupply,
        description,
        attrs,
        coinId,
        imageUrl,
        symbolImageUrl,
        paramA,
        paramB,
        curveType,
        symbolName,
        openPackRatio,
        tgLink,
        twitterLink,
        clubName,
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    const [cardList, setCardList] = useState([]);
    const eth2USDRatio = 3800;




    const totalVal = useMemo(() => {
        if (!coinId) return 0
        // console.log(coinId,curveType);
        // console.log(momentPriceFormular.find(i=>i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType));
        return calcTotalValue(momentPriceFormular.find(i => i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType), paramA, paramB, cardKindMaxNum)
    }, [coinId, paramA, paramB, curveType, cardKindMaxNum])

    const estTotalVal = useMemo(() => {
        if (!coinId) return 0
        // console.log(coinId,curveType);
        // console.log(momentPriceFormular.find(i=>i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType));
        return calcTotalValue(momentPriceFormular.find(i => i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType), paramA, paramB, cardKindMaxNum*5)
    }, [coinId, paramA, paramB, curveType, cardKindMaxNum])

    const curVal = useMemo(() => {
        if (!coinId) return 0
        // console.log(coinId,curveType);
        // console.log(momentPriceFormular.find(i=>i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType));
        return calcTotalValue(momentPriceFormular.find(i => i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType), paramA, paramB, curCardKindNum)
    }, [coinId, paramA, paramB, curveType, cardKindMaxNum])

    const eachCoinValue = useMemo(() => {
        let _ = totalVal / (ftTotalSupply * (100 - airdropPercent) / 100);
        return _ < 0.000001 ? '<0.000001' : _.toFixed(6)
    }, [totalVal])

    const getHolderListFn = (clubUserId, nextCursor) => {
        getHolderList(clubUserId, nextCursor).then(res => {
            const _list = (res?.holderList || []).slice(0, detailInfoShowHolderNum);
            setHolder(_list)
        }).catch(e => {
            console.error(e);
        })
    }

    const foundformular = useMemo(()=>{
        return momentPriceFormular.find(i => i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType) || {};
    },[coinId,clubId])
    // const formular = foundformular ? foundformular.formular : null;
    // const ratioShow = foundformular ? foundformular.ratioShow : null;
    const startPrice = useMemo(()=>{
        if(JSON.stringify(foundformular) === '{}') return 0
        return calcNumberCardVal(curveType, paramA, paramB, 1, foundformular.divParamC, foundformular.mulParamD);
    },[foundformular])

    const tgePrice = useMemo(()=>{
        if(JSON.stringify(foundformular) === '{}') return 0
        return calcNumberCardVal(curveType, paramA, paramB, cardKindMaxNum, foundformular.divParamC, foundformular.mulParamD);
    },[foundformular])

    const estTgePrice = useMemo(()=>{
        if(JSON.stringify(foundformular) === '{}') return 0
        return calcNumberCardVal(curveType, paramA, paramB, cardKindMaxNum*5, foundformular.divParamC, foundformular.mulParamD);
    },[foundformular])

    const getcardlist = () => {
        moment_clubcardlist(clubId).then(res => {
            setCardList(res?.cardList || [])
        }).catch((e) => {
            console.error(e);
        })
    }
    useEffect(() => {
        clubId && getHolderListFn(clubId, 0)
        clubId && getcardlist()
    }, [clubId])

    const attrList = useMemo(() => {
        let attrType1 = [], attrType2 = [];
        attrs?.map(item => {
            const { type } = item;
            if (type === 1) {
                attrType1.push(item)
            }
            if (type === 2) {
                attrType2.push(item)
            }
        })
        const _attrType1 = attrType1.sort((a, b) => {
            return a?.attrName?.toString().length - b?.attrName?.toString().length
        })
        return [
            ..._attrType1,
            ...attrType2
        ]
    }, [attrs])

    const evenCardToToken = useMemo(() => {
        return parseInt(ftTotalSupply * (airdropPercent / 10000) / cardKindMaxNum * 2)
    }, [ftTotalSupply, airdropPercent, cardKindMaxNum])

    const [showBigAvatar, setShowBigAvatar] = useState(false)
    const [chartShow, setChartShow] = useState(true)
    const [showMoreCard, setShowMoreCard] = useState(false)

    const [ownedAmount,setOwnedAmount] = useState(0)
    useEffect(()=>{
        let ownedA = 0;
        
        cardList?.map(item=>{
            const {cardAmount,holdCard} = item;
            if(holdCard>0){
                ownedA+=1
            }
        })
        setOwnedAmount(ownedA)
    },[cardList])
    return (
        <div className={s.clubWrap}>
            <div className={`${s.box}`}>

                <div className={`${s.chartWrap} ${showChartDetail ? '' : s.chartHide}`}>
                    <div className={`${s.chartTitle} momentFont`} onClick={() => { setShowChartDetail(!showChartDetail) }}>
                    {showChartDetail?t('Hide Chart')
                    :
                    <div>
                        {t('Last Price: ')} {convertWalletBalance(sellPrice)} {chainInfo?.displayCoinName}
                        <CustomIcon imgName={range < 0 ? 'Picture/UI_Picture_PriceDown_01' : 'Picture/UI_Picture_PriceUP_01'} className="mr5" width={18} height={13}></CustomIcon>
                        <span className={range < 0 ? 'color-red fs12' : 'color-green fs12'}>{range}%</span>
                    </div>}
                        <CustomIcon className={s.chartIcon} onClick={() => { setShowChartDetail(!showChartDetail) }} imgName={showChartDetail ? 'Button/UI_Button_Arrow_Up_02' : 'Button/UI_Button_Arrow_Down_02'} width={19} height={10}></CustomIcon>
                    </div>
                    {showChartDetail && <div className={s.kChartWrap}>
                        <KChart sourceData={sourceData} coinName={chainInfo?.displayCoinName} clubId={clubId} symbolName={clubName}></KChart>
                    </div>}  
                </div>
                <div className={`w100p flex_center_between mt15`}>
                    <div className={`${s.partTitle} mt0`}>
                        <CustomIcon width={17} height={39} className="mr5"
                            imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                        {AutoStrangeRule(t('Progress'))}
                    </div>

                    <div className="flex_center_center fs10 nowrap">
                        {t('Current Frames: {{current}}/{{total}}', { current: curCardKindNum, total: cardKindMaxNum })}
                    </div>
                </div>
                <div className={s.tgeProGress}>
                    <div className={s.progress}>
                        <div className={s.proWrap}>
                            <div className={s.l}>
                                <div className={s.proVal} style={{
                                    width: `${Math.round(curCardKindNum / cardKindMaxNum * 100)}%`
                                }}>

                                </div>
                                <div className={s.val}>{`${t('{{xx}} Clips left to TGE', { xx: cardKindMaxNum - curCardKindNum })}`}</div>
                            </div>

                        </div>
                    </div>
                    <div className="fs12 mt10 mb10">

                        <Trans
                            i18nKey="TGE will occur once xxx moments"
                            defaults="TGE will occur once <yellow>{{total}}</yellow> moments have been sold. All Liquidity will be auto-added to the DEX, locked, and burnt."
                            values={{
                                total: cardKindMaxNum,
                            }}
                            components={{ yellow: <span className="color-yellow fs12"></span> }}

                        />

                    </div>
                    {/* <div className={s.card}>
                        <div className={s.labelWrap}>
                            <div className="flex_center_start">
                                <div className={s.labelItem}>
                                    <div className={s.labelMy}></div>
                                    <div className="fs10">{t('Owned: {{amount}}', { amount: ownedAmount })}</div>
                                </div>
                                <div className={s.labelItem}>
                                    <div className={s.labelOthers}></div>
                                    <div className="fs10">{t('Others: {{amount}}', { amount: curCardKindNum - ownedAmount })}</div>
                                </div>
                                <div className={s.labelItem}>
                                    <div className={s.labelNone}></div>
                                    <div className="fs10">{t('Remaining: {{amount}}', { amount: cardKindMaxNum - curCardKindNum})}</div>
                                </div>
                            </div>
                            {!showMoreCard && <div className="flex_center_center" onClick={() => { setShowMoreCard(true) }}>
                                <div>{t('Show More')}</div>
                                <CustomIcon width={12} height={7} className="ml5"
                                    imgName={'Button/UI_Button_Arrow_Down_01'} />
                            </div>}
                        </div>
                        {showMoreCard && <div className={s.cardList}>
                            {
                                cardList?.map(item => {
                                    const { cardNo, cardAmount, holdCard } = item;
                                    return (
                                        <div key={cardNo} className={`${s.cardItem} ${cardAmount > 0 ? holdCard > 0 ? s.my : s.other : s.none}`}>{cardNo}</div>
                                    )
                                })
                            }
                        </div>}
                        {showMoreCard && <div className="flex_center_center" onClick={() => { setShowMoreCard(false) }}>
                            <div>{t('Collapse')}</div>
                            <CustomIcon width={12} height={7} className="ml10"
                                imgName={'Button/UI_Button_Arrow_Up_01'} />
                        </div>}
                    </div> */}
                </div>
                <div className={`w100p flex_center_between mt15`}>
                    <div className={s.partTitle}>
                        <CustomIcon width={17} height={39} className="mr5"
                            imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                        {AutoStrangeRule(t('Tokenomics'))}
                    </div>

                    <div className="flex_center_center">
                        {tgLink &&
                            <a href={tgLink}>
                                <CustomIcon width={22} height={22} className=""
                                    imgName={'Button/UI_Button_White_TurnUp_01'} />
                            </a>
                        }

                        {twitterLink &&
                            <a href={twitterLink}>
                                <CustomIcon width={22} height={22} className="ml15"
                                    imgName={'Button/UI_Button_White_Twitter_01'} />
                            </a>
                        }
                    </div>
                </div>
                <div className={`flex_start_start w100p mt15 ${s.infoWrap} ${showBigAvatar ? s.vBig : ''}`}>
                    <img
                        onClick={() => {
                            setShowBigAvatar(!showBigAvatar)
                        }}
                        src={symbolImageUrl}
                        width={showBigAvatar ? 300 : 72}
                        height={showBigAvatar ? 300 : 72}
                        style={{ borderRadius: '8px' }}>
                    </img>
                    <div className={`flex_start_start_col ${s.des}`}>
                        
                        <div className={`fs16`}>{symbolName}</div>
                        <div className={`fs12 fl tll  `}>
                            {description ? description : t(`This person is very lazy and didn't leave anything behind.`)}
                        </div>
                    </div>

                </div>
                <div className={s.blockWrap}>
                    <div className={s.line}>
                        <div className={s.label}>{t('Current Price 1')}</div>
                        <div className={s.val}>
                            <CustomIcon className="ml5 mr5" imgName={`Picture/${chainInfo?.icon}`} width={16} height={16}></CustomIcon>
                            {convertWalletBalance(sellPrice)}
                        </div>
                    </div>
                    <div className={s.line}>
                        <div className={s.label}>{t('Cur.Liquidity Pool 1')}</div>
                        <div className={s.val}>
                            <CustomIcon className="ml5 mr5" imgName={`Picture/${chainInfo?.icon}`} width={16} height={16}></CustomIcon>
                            {convertWalletBalance(curVal)}
                        </div>
                    </div>
                    <div className={s.line}>
                        <div className={s.label}>{t('Total Clips Amount 1')}</div>
                        <div className={s.val}>
                            <CustomIcon width={21} height={21} className="mr5"
                                imgName={'Picture/UI_Picture_Cardpack_01'} />
                            {cardKindMaxNum}
                        </div>
                    </div>
                    <div className={s.line}>
                        <div className={s.label}>{t('TotalAAA 1')} {t('Supply: 1')}</div>
                        <div className={s.val}>
                            <img src={symbolImageUrl} className="mr5" width="18" height="18" style={{ borderRadius: '9px' }}></img>
                            {ftTotalSupply?.toLocaleString()}
                        </div>
                    </div>
                    <div className={s.line}>
                        <div className={s.label}>{t('Airdrop / Liquidity Pool: 1')}</div>
                        <div className={s.val}>{parseInt(airdropPercent / 100)}% ~ {100 - parseInt(airdropPercent / 100)}%</div>
                    </div>
                    {/* <div className={s.line}>
                        <div className={s.label}>{t('DNA Each Pack Offers')}</div>
                        <div className={s.val}>
                            <img src={symbolImageUrl} className="mr5" width="18" height="18" style={{ borderRadius: '9px' }}></img>
                            {`${parseInt(evenCardToToken * (1 / (openPackRatio + 1)))?.toLocaleString()}-
                    ${parseInt(evenCardToToken * (openPackRatio / (openPackRatio + 1)))?.toLocaleString()} `}
                        </div>
                    </div> */}
                    {/* {!clubInfo?.isFT && (
                        <div className={s.line}>
                            <div className={s.label}>{t('Generate 1 NFT for Every:')}</div>
                            <div className={s.val}>
                                <img src={symbolImageUrl} className="mr5" width="18" height="18" style={{ borderRadius: '9px' }}></img>
                                {Math.ceil(coinToNFTRatio)?.toLocaleString()} ${symbolName}
                            </div>
                        </div>
                    )} */}
                    <div className={s.line}>
                        <div className={s.label}>{t('Est.Liquidity Pool 1')}</div>
                        <div className={s.val}>
                            <CustomIcon className="ml5 mr5" imgName={`Picture/${chainInfo?.icon}`} width={16} height={16}></CustomIcon>
                            {convertWalletBalance(estTotalVal)}
                        </div>
                    </div>
                    <div className={s.line}>
                        <div className={s.label}>{t('Est.Token Price 1')}</div>
                        <div className={s.val}>
                            $ {convertWalletBalance(eth2USDRatio*estTotalVal/(ftTotalSupply*(1 - airdropPercent / 10000)))}
                        </div>
                    </div>
                </div>
                {/* <div className={s.progress}>
                <div className={s.proWrap}>
                    <div className={s.l}>
                        <div className={s.proVal} style={{
                            width: `${Math.round(curCardKindNum / cardKindMaxNum * 100)}%`
                        }}>

                        </div>
                        <div className={s.val}>{`${curCardKindNum}/${cardKindMaxNum}`}</div>
                    </div>

                </div>
            </div> */}

                {!clubInfo?.isFT && (
                    <>
                        {/* <div className={`${s.partTitle}`}>
                            <CustomIcon width={17} height={39} className="mr5"
                                imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                            {AutoStrangeRule(t('NFT Info'))}
                        </div> */}
                        {/* <div className="w100p tll fs12 mt15">
                            <Trans
                                i18nKey="MomentClub Details Rules Info"
                                defaults="Generate 1 NFT for every <active>{{count2}} ${{symbolName}}</active> you hold. If you hold less than the required ${{symbolName}} for NFT generation, your NFT will be burnt."
                                values={{
                                    count1: cardKindMaxNum,
                                    symbolName: symbolName,
                                    count2: Math.ceil(coinToNFTRatio)?.toLocaleString()
                                }}
                                components={{ active: <span className="color-yellow fs12"></span> }}
                            />
                        </div> */}
                        {/* <div className={s.metaDataWrap}>
                            <div className="flex_center_between">
                                <div>{t('TGE NFT Attributes:')}</div>
                                <CustomIcon width={21} height={11} className="mr5" onClick={() => {
                                    setShowMoreMetaData(!showMoreMetaData)
                                }}
                                    imgName={showMoreMetaData ? 'Button/UI_Button_Arrow_Up_01' : 'Button/UI_Button_Arrow_Down_01'} />
                            </div>
                            {
                                showMoreMetaData &&
                                <div className={s.list}>
                                    {attrList?.map((item, ind) => {
                                        const { attrName, enumStrs, maxNum, minNum, type } = item;
                                        const vLen = attrName.toString().length;
                                        return (
                                            <div key={ind} className={`${s.attr} ${vLen <= 15 ? s.lenType1 : s.lenType2} ${s['attrType' + type]}`}>
                                                <div className={`${s.label} ${s.metaData}`}>{attrName}</div>
                                                <div className={`${s.val}`}>
                                                    {
                                                        type === 1 ?
                                                            `${convertWalletBalance(minNum)}-${convertWalletBalance(maxNum)}` :
                                                            <>
                                                                {
                                                                    enumStrs?.map((attr, index) => {
                                                                        const { enumName, weight } = attr
                                                                        return (
                                                                            <div className={s.enum} key={index}>
                                                                                {enumName} ({weight})
                                                                            </div>
                                                                        )

                                                                    })
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div> */}
                    </>
                )}


                <div className={`${s.partTitle}`}>
                    <CustomIcon width={17} height={39} className="mr5"
                        imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                    {AutoStrangeRule(t('Price Curve 1'))}
                </div>

                <div className={s.priceCurve}>
                    <div className={s.item}>
                        <img
                            width="100%"
                            src={`./UI/BG/UI_Background_Back_Curve0${curveType}.webp`}
                            style={{ borderRadius: '10px' }}
                        ></img>
                    </div>
                    <div className={s.formularContainer}>
                        <div className={s.formular}>
                            <div className={s.params}>{t('Pack Price 1')} = </div> N^2 / {(10000000000/(paramA*1))?.toFixed(0)}  * 1 {chainInfo?.displayCoinName}
                        </div>
                        <div className={s.formular}>
                            <div className={s.params}>{t('Start Price 1')} = </div> {startPrice|| '--'} {chainInfo?.displayCoinName}
                        </div>
                        <div className={s.formular}>
                            <div className={s.params}>{t('No. {{TGENum}} Price 1', { TGENum: cardKindMaxNum * 5 })} = </div> {estTgePrice || '--'} {chainInfo?.displayCoinName}
                        </div>
                    </div>

                </div>
            </div>
            <TradeKeyPanel 
                upDate={() => { 
                    getcardlist()
                    upDate()
                }} 
                clubBasicInfo={clubBasicInfo}
                sourceData={sourceData}
                openBuyDrawer={openBuyDrawer}>
            </TradeKeyPanel>
        </div>


    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Detail));
