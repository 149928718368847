import React, { useState,useMemo } from "react";
import s from './index.module.less'
import CustomIcon from "src/common/CustomIcon";
import { Button, Slider, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import { convertWalletBalance } from "@/utils/common";
import { memeChainToken } from "@/utils/memeClub";
import {
    LfgMainnetId,
    LfgTestnetId,
} from "@/utils/env";
import memeChainInfo from "@/utils/json/memeChainInfo.json";
import AntModal from "@/common/AntModal";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import ModelDeposit from "../ModelDeposit";
import ModalInsufficientCrpto from "src/model/ModalInsufficientCrpto";
const ModelMemeCreatPreBuy = (
    {
        walletBalanceList,
        min = 0,
        max,
        coinId,
        creatFn,
        preBuyAmount,
        setPreBuyAmount,
        selCreatChain,
        preBuyTotalValue,
        isAnony,
        setIsAnony,
        userInfo,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {walletAddr, selfData, topUpCode} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    // const [isAnony,setIsAnony] = useState(false)
    const getSpecifiedCoinBalance = (id) => {
        return walletBalanceList?.[id] || 0;
    }

    const createFee = useMemo(()=>{
        if(memeChainInfo && selCreatChain){
            return memeChainInfo.find(i=>i?.chainId === selCreatChain?.chainId)?.createFee / 10000
        }
    },[memeChainInfo,selCreatChain])
    const nativeCoinInfo = useMemo(() => {
        let id = Math.floor(coinId/100)*100;
        return memeChainToken.find(i => i?.ID === id);
    }, [coinId]);
    const currentCoinInfo = useMemo(() =>{
        return memeChainToken.find(i => i?.ID === coinId);
    }, [coinId]);
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [depositChainTokenInfo, setDepositChainTokenInfo] = useState(null);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [insufficientAmount, setInsufficientAmount] = useState(0);
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const priceWithFee = useMemo(()=>{
        return (selCreatChain?.isNative ===1 ? Number(preBuyTotalValue):0)  + Number(createFee)
    },[preBuyTotalValue,createFee,selCreatChain])
    const create = ()=>{
        if (isNativeCoin()){
            if (Number(priceWithFee) > Number(getSpecifiedCoinBalance(currentCoinInfo?.ID))){
                setShowInsufficientCrpto(true);
                setInsufficientAmount(Number(priceWithFee) - Number(getSpecifiedCoinBalance(currentCoinInfo?.ID)));
                return;
            }
        }
        else{
            if (Number(priceWithFee) > Number(getSpecifiedCoinBalance(nativeCoinInfo?.ID))){
                setShowInsufficientCrpto(true);
                setInsufficientAmount(Number(getSpecifiedCoinBalance(nativeCoinInfo?.ID)));
                return;
            }
            if (Number(preBuyTotalValue) > Number(getSpecifiedCoinBalance(currentCoinInfo?.ID))){
                setShowSwapLfg(true)
                return;
            }
        }
        creatFn()
    }
    const isNativeCoin = () =>{
        if (memeChainToken?.length > 0){
            for (let i = 0; i < memeChainToken?.length; i++){
                if (memeChainToken[i].ID === coinId){
                    return memeChainToken[i].isNative === 1;
                }
            }
        }
        return true;
    }
    // console.log(walletBalanceList);
    return (
        <div className={s.modelWrap}>

            <div className="df jc-spaceBetween ais w100p color-white fs12 mb30">
                <div className="ml10 flex_center_start fb" style={{ padding: "4px 0px" }}>
                    {t('Your Balance:')}
                </div>
                <div className={`${s.overview} flex_center_center_col`}>
                    {
                        !selCreatChain?.isNative && <div className={s.overviewItem}>
                            <CustomIcon width={18} height={18} imgName={`Picture/${nativeCoinInfo?.icon}`} />
                            <span className={`${s.balance} color-yellow`}>
                                {convertWalletBalance(getSpecifiedCoinBalance(nativeCoinInfo?.ID))}
                            </span>
                            <span className={`${s.overviewItemName}`}>
                                {nativeCoinInfo?.displayCoinName}
                            </span>
                            <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Plus-Yellow-Sq_01'}
                                onClick={() => {
                                    setShowDepositPanel(true);
                                    setDepositChainTokenInfo(nativeCoinInfo);
                                }} />
                        </div>
                    }
                    <div className={s.overviewItem}>
                        <CustomIcon width={18} height={18} imgName={`Picture/${currentCoinInfo?.icon}`} />
                        <span className={`${s.balance} color-yellow`}>
                            {convertWalletBalance(getSpecifiedCoinBalance(currentCoinInfo?.ID))}
                        </span>
                        <span className={`${s.overviewItemName}`}>
                            {currentCoinInfo?.displayCoinName}
                        </span>
                        <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Plus-Yellow-Sq_01'}
                            onClick={() => {
                                if (isNativeCoin()) {
                                    setShowDepositPanel(true);
                                    setDepositChainTokenInfo(currentCoinInfo);
                                }
                                else if (currentCoinInfo?.ID === LfgMainnetId || currentCoinInfo?.ID === LfgTestnetId) {
                                    setShowSwapLfg(true);
                                }
                            }} />
                    </div>
                </div>
            </div>
            <div className="fs16">{t('You can pre-purchase part of the Pack')}</div>
            <div className="fs16 mb20">{t('Pre-buy Pack Amount')} : <span className="fs30">{preBuyAmount}</span></div>
            <Slider
                className="hireSlider commonSlider memeClubSlider"
                min={min}
                max={Number(max - 1)}
                value={preBuyAmount}
                onChange={(value) => {
                    setPreBuyAmount(value);
                }} />
            <div className="flex_center_between mt20 w100p">
                <div className="fs16 color-blue-light">{t('Pre-buy Cost(inc.fees): ')}</div>
                <div>
                    <span className="color-yellow fs16">{convertWalletBalance(preBuyTotalValue)}</span>
                    <span className="fs16 ml5">{selCreatChain?.displayCoinName}</span>
                </div>
            </div>
            <div className="flex_center_between mt10 w100p">
                <div className="fs16 color-blue-light">{t('Create Fee: ')}</div>
                <div>
                    <span className="color-yellow fs16">{convertWalletBalance(createFee)}</span>
                    <span className="fs16 ml5">{nativeCoinInfo?.displayCoinName}</span>
                </div>
            </div>
            <div className={s.line}></div>
            <div className="flex_start_between w100p">
                <div className="fs16 color-blue-light">{t('Total: ')}</div>
                <div className="flex_end_center_col">
                    <div>
                        <span className="color-yellow fs16">{convertWalletBalance(selCreatChain?.isNative === 0 ? preBuyTotalValue : priceWithFee)}</span>
                        <span className="fs16 ml5">{selCreatChain?.displayCoinName}</span>
                    </div>
                    {selCreatChain?.isNative === 0 &&
                        <div>
                            <span className="color-yellow fs16">{convertWalletBalance(priceWithFee)}</span>
                            <span className="fs16 ml5">{memeChainToken?.find(i => i?.ID === Math.round(Math.floor(selCreatChain?.ID / 100) * 100))?.displayCoinName}</span>
                        </div>
                    }
                   
                </div>
            </div>
            <div>

                <Checkbox onChange={(e) => setIsAnony(e.target.checked)} className="hireCheckBox mt10 mb20"
                    checked={isAnony}>
                    <span className="">{t('Create Anonymously')}</span>
                </Checkbox>
            </div>

            <ButtonFactory className="modelBottomBtn"
                onClick={create}
            >
                {t('Create Meme')}
            </ButtonFactory>

            <AntModal
                width='330px'
                title={t('Get More {{coin}}', {coin: 'Crypto'})}
                className="confirmModalWrap"
                centered={true}
                open={showDepositPanel}
                onOk={() => setShowDepositPanel(false)}
                onCancel={() => setShowDepositPanel(false)}
                zIndex={7777}
            >
                <ModelDeposit
                    topUpCode={topUpCode}
                    walletAddr={walletAddr}
                    setShowDepositPanel={setShowDepositPanel}
                    chainTokenInfo={depositChainTokenInfo} />
            </AntModal>
            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg
                    userInfo={userInfo}
                    closeFn={() => {
                        setShowSwapLfg(false);
                    }} />
            </AntModal>
            <AntModal
                    width='330px'
                    title={t('INSUFFICIENT BALANCE')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showInsufficientCrpto}
                    onOk={() => setShowInsufficientCrpto(false)}
                    onCancel={() => setShowInsufficientCrpto(false)}
                    zIndex={7777}
                >
                    <ModalInsufficientCrpto
                        insufficientAmount={insufficientAmount}
                        userInfo={userInfo} 
                        coinId={coinId}
                        closeFn={() => {
                            setShowInsufficientCrpto(false);
                        }}
                    />
                </AntModal>
        </div>
    )
}

export default ModelMemeCreatPreBuy
