import React, {useEffect, useRef, useState} from "react";
import CustomIcon from "src/common/CustomIcon";
import s from "./index.module.less";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import {gameReferralPath} from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelBoost = (
    {
        claimCoin,
        multiplyCardNum,
        boostClick,
        globalProdInfo,
        boostCD
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    return (
        <div className={s.contentArea}>
            <div className="fs12 color-white">{t('Current $LFG Pool')}</div>
            <div className={s.bg}>
                <CustomIcon
                    width={20}
                    height={20}
                    className="mr5"
                    imgName={"Picture/UI_Picture-Currency_LFG_01"}
                    name={"Picture/UI_Picture-Currency_LFG_01"}
                />
                <div className="fs14 color-white fb ">
                    {(parseInt(claimCoin) || "--").toLocaleString("en-US")}
                </div>
            </div>
            <div className="mtb10 fs12 color-blue-light">
                {t('Apply a multiplier to increase your LFG pool! Keep in mind, the multiplier factor can fluctuate randomly.')}
            </div>
            <div className="fs12 color-white" style={{marginBottom: "0px"}}>
                {t('After Applying Multiplier:')}
            </div>
            <div className={s.bg}>
                <CustomIcon
                    width={20}
                    height={20}
                    className="mr5"
                    imgName={"Picture/UI_Picture-Currency_LFG_01"}
                    name={"Picture/UI_Picture-Currency_LFG_01"}
                />
                <div className="fs14 color-white fb">
                    {(
                        parseInt(claimCoin * (1 + globalProdInfo.boostMinRatio)) || "--"
                    ).toLocaleString("en-US")}
                </div>
                &nbsp;-&nbsp;&nbsp;
                <div className="fs14 color-white fb ">
                    {(
                        parseInt(claimCoin * (1 + globalProdInfo.boostMaxRatio)) || "--"
                    ).toLocaleString("en-US")}
                </div>
            </div>
            <div className="w100p df jcc fs12 aic" style={{marginTop: "27px"}}>
                {t('Available Multipliers:')}
                <span className="color-white ml5">{multiplyCardNum}</span>
            </div>
            {!multiplyCardNum && (
                <div className="fs12 color-white"
                     style={{textAlign: 'center'}}>{t('Invite friends to earn more!')}</div>
            )}
            {multiplyCardNum ? <ButtonFactory
                className="w100p"
                onClick={() => {
                    boostClick();
                }}
            >
                {t('APPLY MULTIPLIER')}
            </ButtonFactory> : <ButtonFactory
                className="w100p"
                onClick={() => {
                    navigate(gameReferralPath);
                }}
            >
                {t('Invite Friends')}
            </ButtonFactory>}
            <div className="fs12 mt5 fl color-blue-light">
                {/* {t('When a multiplier is applied, $LFG extract are temporarily suspended for xx hours.', { time: parseInt(boostCD/60/60)})} */}
                {t('BoostTis01 xx hours.', {time: parseInt(boostCD / 60 / 60)})}
                {t('BoostTis02 xx hours.', {time: parseInt(7200 / 60 / 60)})}
            </div>
        </div>
    );
};

export default ModelBoost;
